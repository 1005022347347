<template>
  <div class="project_details">
    <p>
      <el-button
        v-if="!is_edit"
        type="primary"
        size="small"
        @click="edit_click"
      >
        <i class="iconfont icon-branch_edit" style="margin-right: 5px"></i>
        {{ $t("btn.editBtn") }}</el-button
      >
      <el-button v-else type="info" size="small" @click="edit_save"
        ><i class="iconfont icon-exit" style="margin-right: 5px"></i
        >{{ $t("btn.exitEditBtn") }}</el-button
      >
    </p>
    <div class="context_box">
      <el-form
        ref="form"
        :model="form"
        label-width="200px"
        label-position="left"
      >
        <el-form-item>
          <template slot="label">
            <span class="form_label">{{ $t("projectDetails.name") }}</span>
          </template>
          <el-input
            v-if="is_edit"
            v-model="form.projectName"
            size="small"
            class="form_input"
          ></el-input>
          <el-tooltip
            v-else
            v-ak-tooltip-auto-show
            class="item"
            effect="dark"
            placement="right"
            :content="form.projectName"
          >
            <span class="form_span p_name_span">{{ form.projectName }}</span>
          </el-tooltip>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <span class="form_label">{{ $t("projectDetails.avatar") }}</span>
          </template>
          <div style="width: 100px">
            <personal-avatar
              v-if="show_avatar"
              :size="40"
              :avatar="project_inf.avatar"
              :colorid="project_inf.projectId"
              :nickname="project_inf.projectName"
              :flex="'flex-start'"
            ></personal-avatar>
          </div>
          <!-- <span v-if="is_edit" class="avatar_click">点击修改</span> -->
          <el-upload
            v-if="is_edit"
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :http-request="uploadFile"
            :on-error="handleFileError"
            :before-upload="beforeAvatarUpload"
          >
            <div size="small" class="avatar_click">
              {{ $t("projectDetails.editAvatarBtn") }}
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <span class="form_label">{{ $t("projectDetails.id") }}</span>
          </template>
          <!-- <el-input v-if="is_edit" v-model="form.key" size="small" class="form_input"></el-input> -->
          <!-- <span v-else class="form_span">{{project_inf.projectKey}}</span> -->
          <span class="form_span" style="width: auto"
            ><span ref="pro_id">{{ form.projectId }} </span>
            <el-button
              size="small"
              style="margin-left: 100px"
              @click="copy_id"
              >{{ $t("btn.copyBtn") }}</el-button
            >
          </span>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <span class="form_label">{{ $t("projectDetails.key") }}</span>
          </template>
          <!-- <el-input v-if="is_edit" v-model="form.key" size="small" class="form_input"></el-input> -->
          <!-- <span v-else class="form_span">{{project_inf.projectKey}}</span> -->
          <span class="form_span">{{ form.projectKey }}</span>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <span class="form_label">{{ $t("projectDetails.agileType") }}</span>
          </template>
          <el-select
            v-if="is_edit"
            v-model="form.type"
            class="form_input"
            size="small"
            filterable
          >
            <el-option label="Kanban" value="KANBAN"> </el-option>
            <el-option label="Scrum" value="SCRUM"> </el-option>
            <!-- <el-option v-for="(item, index) in project_type_list" :key="index" label="item.label" value="item.value"></el-option> -->
          </el-select>
          <span v-else class="form_span">{{ form.type }}</span>
        </el-form-item>
        <el-form-item
          v-show="hasGitLab || hasGitee || hasGithub || hasSVN"
          v-loading="loading"
          :element-loading-text="$t('loading.loading1')"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.1)"
        >
          <template slot="label">
            <span class="form_label">{{ $t("projectDetails.gitlab") }}</span>
          </template>
          <div class="tag_wrap_class">
            <el-tag
              v-for="tag in repository_list"
              v-show="hasGitLab"
              :key="tag.id"
              :closable="is_edit"
              type="info"
              @close="delete_repository(tag)"
            >
              gitlab / {{ tag.nameWithNamespace }}
            </el-tag>
            <el-tag
              v-for="tag in gitee_repository_list"
              v-show="hasGitee"
              :key="tag.id"
              :closable="is_edit"
              type="info"
              @close="delete_gitee_repository(tag)"
            >
              gitee / {{ tag.fullName }}
            </el-tag>
            <el-tag
              v-for="tag in github_repository_list"
              v-show="hasGithub"
              :key="tag.id"
              :closable="is_edit"
              type="info"
              @close="delete_github_repository(tag)"
            >
              github / {{ tag.fullName }}
            </el-tag>
            <el-button v-if="is_edit" size="mini" @click="add_repository">{{
              $t("projectDetails.addGitlabBtn")
            }}</el-button>
          </div>
        </el-form-item>
        <el-form-item v-show="hasJenkins">
          <template slot="label">
            <span class="form_label">{{ $t("projectDetails.jenkins") }}</span>
          </template>
          <div class="tag_wrap_class">
            <el-tag
              v-for="tag in build_job_list"
              :key="tag.configId"
              :closable="is_edit"
              type="info"
              @close="delete_job(tag)"
            >
              {{ tag.jobName }}
            </el-tag>
            <el-button
              v-if="is_edit"
              size="mini"
              style="margin-top: 6px"
              @click="add_jenkins"
              >{{ $t("projectDetails.editEolinkSettingsBtn") }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item v-show="hasEoLink">
          <template slot="label">
            <span class="form_label">{{ $t("projectDetails.eolink") }}</span>
          </template>
          <div class="tag_wrap_class">
            <el-tag
              v-for="tag in file_type_options.filter((option) => {
                return (
                  eoLink_config.fileTypeList.indexOf(option.fileTypeId) !== -1
                );
              })"
              :key="tag.fileTypeId"
              :closable="false"
              type="info"
            >
              {{ tag.name }}
            </el-tag>
            <el-button
              v-if="is_edit"
              size="mini"
              style="margin-top: 6px"
              @click="add_eolink"
              >{{ $t("projectDetails.editEolinkSettingsBtn") }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item v-show="hasCorax">
          <template slot="label">
            <span class="form_label">{{ $t("corax.coraxConfiguration") }}</span>
          </template>
          <el-select
            v-if="is_edit"
            v-model="coraxForm.testPlanId"
            class="form_input"
            size="small"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in testPlanList"
              :label="item.name_display"
              :value="item.testPlanId"
              :key="item.testPlanId"
            >
            </el-option>
          </el-select>
          <span v-else-if="selectedTestPlan" class="form_corax">{{
            selectedTestPlan.name_display
          }}</span>
        </el-form-item>
        <el-form-item
          v-show="
            hasCedar &&
            ($store.state.project.tenantId ===
              '586ee243-5501-4712-bf0b-0e36b5ea2ff6' ||
              $store.state.project.tenantId ===
                '7db3b28d-c80f-4fbe-895e-6209c6c4362b' ||
              $store.state.project.tenantId ===
                '91fee1e5-8b83-4b57-bb77-6903d7e1361d')
          "
        >
          <template slot="label">
            <span class="form_label">{{ $t("corax.cedarConfiguration") }}</span>
          </template>
          <el-select
            v-if="is_edit"
            v-model="cedarForm.testPlanId"
            class="form_input"
            size="small"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in testPlanList"
              :label="item.name_display"
              :value="item.testPlanId"
              :key="item.testPlanId"
            >
            </el-option>
          </el-select>
          <span v-else-if="selectedCedarTestPlan" class="form_corax">{{
            selectedCedarTestPlan.name_display
          }}</span>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <span class="form_label">{{
              $t("projectDetails.GroupNotification")
            }}</span>
          </template>
          <div class="tag_wrap_class">
            <el-tag
              v-for="tag in message_channels"
              :key="tag.massMessageChannelId"
              :closable="is_edit"
              type="info"
              @click="edit_notice(tag)"
              @close="delete_notice(tag)"
            >
              {{
                message_channel_options.filter((item) => {
                  return item.value === tag.messageChannel;
                })[0].label
              }}
            </el-tag>
            <el-button
              v-if="is_edit"
              size="mini"
              style="margin-top: 6px"
              @click="add_notice"
              >{{ $t("projectDetails.AddConfiguration") }}</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      <!-- <p v-if="!is_edit"><el-button type="primary" size="small" @click="show_template">另存为模板</el-button></p> -->
    </div>
    <el-dialog
      :title="$t('editFileType.workflow.saveAsNewTemplateBtn')"
      :visible.sync="template_dialog"
      width="30%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-form
        ref="form"
        :model="template_form"
        label-width="120px"
        label-position="left"
      >
        <el-form-item>
          <template slot="label">
            <span class="form_label"
              >{{
                $t("editFileType.workflow.saveAsNewTemplateForm.name")
              }}:</span
            >
          </template>
          <el-input
            v-model="template_form.name"
            size="small"
            class="form_input"
            :placeholder="$t('placeholder.input')"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="template_dialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="template_dialog_confrim">{{
          $t("btn.newBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 添加关联仓库对话框 -->
    <el-dialog
      :visible.sync="add_repository_dialog"
      :close-on-click-modal="false"
      :show-close="true"
      width="50%"
    >
      <div slot="title">
        <span style="color: red">&nbsp;*</span
        >{{ $t("projectDetails.addGitlabForm.title1") }}
      </div>
      <div class="add_repository_wrap">
        <div class="company_list">
          <div
            v-for="(item, index) in company_list"
            v-show="item.show"
            :key="index"
            :class="{
              'checked-repository-type': checked_repository_type === item.name,
            }"
            @click="change_repository(item)"
          >
            <img
              v-if="item.show"
              :src="item.logo"
              alt=""
              :class="{ gitee_logo: index === 1 }"
            />
            <span v-if="item.show">{{ item.name }}</span>
          </div>
        </div>
        <div
          v-if="checked_repository_type === $t('projectDetails.message.lab2')"
        >
          <div
            class="add_repository_des"
            v-html="$t('projectDetails.addGitlabForm.note1')"
          ></div>
          <div style="font-size: 16px; font-weight: 700">
            <span style="color: red">&nbsp;*</span
            >{{ $t("projectDetails.addGitlabForm.icon") }}
            <label style="color: rgba(128, 128, 128, 1)">(OAuth)</label>
          </div>
          <div
            style="
              padding: 0 0;
              background: rgba(240, 242, 245, 1);
              margin: 10px 0;
              display: flex;
              justify-content: space-between;
              align-items: baseline;
            "
          >
            <span
              v-if="authentication_success && hasGitLabAuth.length"
              style="color: #67c23a; width: 100%"
            >
              <el-select
                v-model="gitlab_config_id"
                style="width: 100%"
                filterable
                :placeholder="$t('placeholder.dropdown')"
                @change="getGitlabProjectList"
              >
                <el-option
                  v-for="(item, index) in hasGitLabAuth"
                  :key="index"
                  :label="item.name"
                  :value="item.gitlabConfigId"
                >
                </el-option>
              </el-select>
            </span>
            <span v-else>{{ $t("projectDetails.addGitlabForm.note2") }}</span>
            <el-button
              type="text"
              icon="el-icon-circle-plus"
              @click="creat_authentication"
              >{{ $t("projectDetails.addGitlabForm.addAutoBtn") }}</el-button
            >
          </div>
          <div style="font-size: 16px; font-weight: 700">
            <span style="color: red">&nbsp;*</span
            >{{ $t("projectDetails.addGitlabForm.title3") }}
          </div>
          <div style="margin-top: 20px">
            <el-select
              v-model="checked_repository"
              style="width: 100%"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
            >
              <el-option
                v-for="(item, index) in repository_options_list"
                :key="index"
                :label="item.nameWithNamespace"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          v-if="checked_repository_type === $t('projectDetails.message.lab3')"
        >
          <div style="font-size: 16px; font-weight: 700; margin-top: 20px">
            <span style="color: red">&nbsp;*</span
            >{{ $t("projectDetails.GiteeAuthentication") }}
            <label style="color: rgba(128, 128, 128, 1)">(OAuth)</label>
          </div>
          <div
            style="
              padding: 0 10px;
              background: rgba(240, 242, 245, 1);
              margin: 10px 0;
              display: flex;
              justify-content: space-between;
              align-items: baseline;
            "
          >
            <span v-if="authentication_gitee_success" style="color: #67c23a">{{
              $t("projectDetails.AuthenticationSuccessful")
            }}</span>
            <span v-else>{{ $t("projectDetails.NoAuthenticatedGitee") }}</span>
            <el-button
              type="text"
              icon="el-icon-circle-plus"
              @click="creat_authentication_gitee"
              >{{ $t("projectDetails.addGitlabForm.addAutoBtn") }}</el-button
            >
          </div>
          <div style="font-size: 16px; font-weight: 700">
            <span style="color: red">&nbsp;*</span
            >{{ $t("projectDetails.addGitlabForm.title3") }}
          </div>
          <div style="margin-top: 20px">
            <el-select
              v-model="checked_gitee_repository"
              style="width: 100%"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
            >
              <el-option
                v-for="(item, index) in repository_gitee_options_list"
                :key="index"
                :label="item.full_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          v-if="checked_repository_type === $t('projectDetails.message.lab5')"
        >
          <div style="font-size: 16px; font-weight: 700; margin-top: 20px">
            <span style="color: red">&nbsp;*</span
            >{{ $t("projectDetails.GithubAuthentication") }}
            <label style="color: rgba(128, 128, 128, 1)">(OAuth)</label>
          </div>
          <div
            style="
              padding: 0 10px;
              background: rgba(240, 242, 245, 1);
              margin: 10px 0;
              display: flex;
              justify-content: space-between;
              align-items: baseline;
            "
          >
            <span v-if="authentication_github_success" style="color: #67c23a">{{
              $t("projectDetails.AuthenticationSuccessful")
            }}</span>
            <span v-else>{{ $t("projectDetails.NoAuthenticatedGithub") }}</span>
            <el-button
              type="text"
              icon="el-icon-circle-plus"
              @click="creat_authentication_github"
              >{{ $t("projectDetails.addGitlabForm.addAutoBtn") }}</el-button
            >
          </div>
          <div style="font-size: 16px; font-weight: 700">
            <span style="color: red">&nbsp;*</span
            >{{ $t("projectDetails.addGitlabForm.title3") }}
          </div>
          <div style="margin-top: 20px">
            <el-select
              v-model="checked_github_repository"
              style="width: 100%"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
            >
              <el-option
                v-for="(item, index) in repository_github_options_list"
                :key="index"
                :label="item.full_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          v-if="checked_repository_type === $t('projectDetails.message.lab7')"
        >
          <div
            class="add_repository_des"
            v-html="$t('projectDetails.addGitlabForm.note4')"
          ></div>
          <div>
            <div>
              <pre
                style="
                  background-color: #23241f;
                  color: #fff;
                  padding-top: 10px;
                  position: relative;
                "
              >
        <el-button style="position: absolute;
    right: 0;
    top: 0;
" size="small" @click="handleCopyText">{{
          $t("btn.copyBtn")
        }}</el-button>
{{ $t('projectDetails.addGitlabForm.note6') }}
REPOS="$1"  
REV="$2"  

{{ $t('projectDetails.addGitlabForm.note7') }}
COMMITTER=$(svnlook author -r $REV "$REPOS")  
MESSAGE=$(svnlook log -r $REV "$REPOS")  
REPO_NAME=$(basename "$REPOS") 

{{ $t('projectDetails.addGitlabForm.note8') }}
JSON="{  
    \"commitMessage\": \"$(echo $MESSAGE | sed 's/\"/\\\\\"/g')\",  
    \"author\": \"$COMMITTER\",  
    \"repo\": \"$REPO_NAME\",  
    \"revision\": $REV  
}"  

{{ $t('projectDetails.addGitlabForm.note9') }}
REMOTE_URL="{{ VUE_APP_BASE_API }}/file-manage-service/{{ get_pid() }}/svnCommitHook"  

{{ $t('projectDetails.addGitlabForm.note10') }}
curl -X POST -H "Content-Type: application/json" -d "$JSON" "$REMOTE_URL"
              </pre>
            </div>
          </div>
          <div
            class="add_repository_des"
            v-html="$t('projectDetails.addGitlabForm.note5')"
          ></div>
        </div>
      </div>
      <span
        slot="footer"
        v-if="checked_repository_type !== $t('projectDetails.message.lab7')"
        class="dialog-footer"
      >
        <el-button @click="add_repository_dialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="add_repository_confrim">{{
          $t("btn.newBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="authentication_dialog"
      :before-close="authentication_dialog_before_close"
      width="50%"
    >
      <div class="authentication_wrap">
        <div>
          <el-alert
            :title="$t('projectDetails.addGitlabForm.addAutoForm.warnMessage')"
            type="warning"
            :closable="false"
            show-icon
          >
          </el-alert>
        </div>
        <div class="authentication_title">
          <span class="index_class">1</span
          >{{ $t("projectDetails.addGitlabForm.addAutoForm.step1.title") }}
        </div>
        <div class="authentication_des">
          <p>
            1. {{ $t("projectDetails.addGitlabForm.addAutoForm.step1.step1") }}
          </p>
          <p>
            2. {{ $t("projectDetails.addGitlabForm.addAutoForm.step1.step2") }}
          </p>
          <div class="authentication_title">
            {{ $t("projectDetails.addGitlabForm.addAutoForm.step1.url") }}
          </div>
          <div>
            <span ref="redirect_url" class="authentication_code_area">
              {{ default_url }}</span
            >
            <el-button size="small" style="float: right" @click="copy_url">{{
              $t("btn.copyBtn")
            }}</el-button>
          </div>
          <div class="authentication_title">
            {{ $t("projectDetails.addGitlabForm.addAutoForm.step1.scopes") }}
          </div>
          <div>
            <span class="authentication_code_area">api</span>
            <span class="authentication_code_area">read_user</span>
            <span class="authentication_code_area">read_repository</span>
            <span class="authentication_code_area">write_repository</span>
          </div>
        </div>
        <div class="authentication_title">
          <span class="index_class">2</span
          >{{ $t("projectDetails.addGitlabForm.addAutoForm.step2.title") }}
        </div>
        <div class="authentication_des">
          <p>{{ $t("projectDetails.addGitlabForm.addAutoForm.step2.info") }}</p>
          <div
            style="
              font-size: 16px;
              font-weight: 700;
              color: #000;
              margin: 15px 0 10px 0;
            "
          >
            <span style="color: red">&nbsp;*</span>Name
          </div>
          <div>
            <el-input
              v-model="authentication_form.name"
              :placeholder="
                $t('projectDetails.addGitlabForm.addAutoForm.step2.field4')
              "
            ></el-input>
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 700;
              color: #000;
              margin: 15px 0 10px 0;
            "
          >
            <span style="color: red">&nbsp;*</span>Application ID
          </div>
          <div>
            <el-input
              v-model="authentication_form.applicationid"
              :placeholder="
                $t('projectDetails.addGitlabForm.addAutoForm.step2.field1')
              "
            ></el-input>
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 700;
              color: #000;
              margin: 15px 0 10px 0;
            "
          >
            <span style="color: red">&nbsp;*</span>Secret
          </div>
          <div>
            <el-input
              v-model="authentication_form.secret"
              :placeholder="
                $t('projectDetails.addGitlabForm.addAutoForm.step2.field2')
              "
            ></el-input>
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 700;
              color: #000;
              margin: 15px 0 10px 0;
            "
          >
            <span style="color: red">&nbsp;*</span
            >{{ $t("projectDetails.addGitlabForm.addAutoForm.step2.field3") }}
          </div>
          <div>
            <el-input
              v-model="authentication_form.url"
              :placeholder="
                $t('projectDetails.addGitlabForm.addAutoForm.step2.placeholder')
              "
            ></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="authentication_dialog_confrim_cancel">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="authentication_dialog_confrim">{{
          $t("btn.newBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="add_eolink_dialog"
      width="30%"
    >
      <el-form
        ref="add_eolink_form"
        :model="eoLink_config"
        :rules="add_eolink_rules"
        label-width="200px"
        label-position="left"
      >
        <el-form-item prop="spaceKey">
          <span slot="label">
            <span
              >{{ $t("projectDetails.WorkspaceDomainPrefix") }}
              <span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('projectDetails.WorkspaceDomainDes')"
                  placement="top-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
            </span>
          </span>
          <el-input v-model="eoLink_config.spaceKey"></el-input>
        </el-form-item>
        <el-form-item prop="openAPIKey">
          <span slot="label">
            <span
              >Open API Secret Key
              <span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('projectDetails.editEolinkSettingsForm.tip')"
                  placement="top-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
            </span>
          </span>
          <el-input v-model="eoLink_config.openAPIKey"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('projectDetails.editEolinkSettingsForm.filed')"
          prop="fileTypeList"
        >
          <el-select
            v-model="eoLink_config.fileTypeList"
            multiple
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="(option, index) in file_type_options"
              :key="index"
              :label="option.name"
              :value="option.fileTypeId"
            >
              {{ option.name }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="add_eolink_dialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="add_eolink_dialog_confrim">{{
          $t("btn.newBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="add_jenkins_dialog"
      :before-close="handleClose"
      width="25%"
    >
      <el-form
        ref="add_jenkins_form"
        :model="jenkins_config"
        :rules="add_jenkins_rules"
        label-width="200px"
        label-position="left"
      >
        <el-form-item prop="host">
          <span slot="label">
            <span
              >{{ $t("projectDetails.JenkinsAddress") }}
              <span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('projectDetails.editJenkinsSettingsForm.tip4')"
                  placement="top-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
            </span>
          </span>
          <el-input v-model="jenkins_config.host"></el-input>
        </el-form-item>
        <el-form-item prop="userName">
          <span slot="label">
            <span
              >{{ $t("projectDetails.AuthenticationUsername") }}
              <span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('projectDetails.editJenkinsSettingsForm.tip1')"
                  placement="top-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
            </span>
          </span>
          <el-input v-model="jenkins_config.userName"></el-input>
        </el-form-item>
        <el-form-item prop="apiToken">
          <span slot="label">
            <span
              >ApiToken
              <span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('projectDetails.editJenkinsSettingsForm.tip2')"
                  placement="top-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
            </span>
          </span>
          <el-input v-model="jenkins_config.apiToken"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <span v-if="connFailed" class="connFailed">{{
          $t("projectDetails.ConnectionFailed")
        }}</span>
        <el-button
          @click="
            add_jenkins_dialog = false;
            connFailed = false;
          "
          >{{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button type="primary" @click="add_jenkins_dialog_confrim">{{
          $t("btn.next")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- add_build_jobs_dialog -->
    <el-dialog
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="add_build_jobs_dialog"
      :before-close="handleClose"
      width="25%"
    >
      <el-form ref="build_job_form" :model="build_job_form" class="build_job">
        <el-form-item style="margin-left: 30px">
          <span style="font-size: 16px; font-weight: 800">
            {{ $t("projectDetails.EnterBuildJobLink") }}
            <span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('projectDetails.editJenkinsSettingsForm.tip3')"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
          </span>
        </el-form-item>
        <el-form-item
          v-for="(url, index) in build_job_form.jobUrls"
          :key="url.key"
          :prop="'jobUrls.' + index + '.value'"
          style="width: 100%"
          :rules="{ validator: validate_job, trigger: 'blur' }"
        >
          <el-input
            v-model="url.value"
            style="width: 80%; margin-right: 10px"
          ></el-input>
          <i
            class="el-icon-remove-outline"
            style="font-size: 24px; color: red; cursor: pointer"
            @click.prevent="remove_url(url)"
          ></i>
        </el-form-item>
        <el-form-item>
          <i
            class="iconfont icon-add"
            style="
              font-size: 20px;
              margin-top: 5px;
              margin-left: 30px;
              cursor: pointer;
              color: rgb(48, 100, 143);
            "
            @click.stop="add_url"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            add_build_jobs_dialog = false;
            connFailed = false;
            add_jenkins_dialog = true;
          "
          >{{ $t("btn.prev") }}</el-button
        >
        <el-button type="primary" @click="add_build_jobs_confrim">{{
          $t("btn.next")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="add_notice_dialog"
      width="25%"
    >
      <el-alert
        v-if="notice_config.messageChannel === 'DING_TALK'"
        style="margin-bottom: 10px"
        :title="$t('projectDetails.SetIPWhitelistForDingTalk')"
        type="warning"
        :closable="false"
        show-icon
      >
      </el-alert>
      <el-form
        ref="add_notice_form"
        :model="notice_config"
        :rules="add_notice_rules"
        label-width="150px"
        label-position="left"
      >
        <el-form-item prop="messageChannel">
          <span slot="label">
            <span>{{ $t("projectDetails.MessageChannel") }} </span>
          </span>
          <el-select
            v-model="notice_config.messageChannel"
            style="width: 100%"
            :placeholder="$t('projectDetails.SelectMessageChannel')"
            filterable
          >
            <el-option
              v-for="(option, index) in message_channel_options"
              :key="index"
              :label="option.label"
              :value="option.value"
            >
              {{ option.label }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="webhookUrl" prop="webhookUrl">
          <el-input
            v-model="notice_config.webhookUrl"
            :placeholder="$t('projectDetails.EnterWebhookAddress')"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="add_notice_dialog = false">{{
          $t("review.tip13")
        }}</el-button>
        <el-button type="primary" @click="add_noctice_dialog_confrim">{{
          $t("review.tip14")
        }}</el-button>
      </span>
    </el-dialog>
    <label style="display: block">
      <textarea ref="text_area" style="opacity: 0"></textarea>
    </label>
  </div>
</template>

<script>
import { get_authorization } from "@/network/node/index.js";
import {
  testConn,
  testJobConn,
  getJobList,
  saveJob,
  deleteJob,
} from "@/network/jenkins/index";
import { getCoraxConfig, modifyCoraxConfig } from "@/network/corax";
import { getcedarConfig, modifycedarConfig } from "@/network/cedar";
import { get_test_plans } from "@/network/testPlan/index.js";
import config from "@/../config/index";
import personalAvatar from "../../components/personal";
import logo1 from "@/assets/img/company_logo1.png";
import logo2 from "@/assets/img/company_logo2.png";
import logo3 from "@/assets/img/company_logo3.png";
import logo4 from "@/assets/img/company_logo4.jpg";
import {
  request_allPageProjects,
  change_project_inf,
} from "@/network/personal/index.js";
import {
  gitlabProjects,
  gitlabConfigs,
  getGitlabConfigs,
  validation,
  allProjectList,
  savegitlabProjects,
  deletegitlabProjects,
  giteeallProjectList,
  githuballProjectList,
  giteeProjects,
  githubProjects,
  getGiteeProjects,
  getGithubProjects,
  deleteGiteeProjects,
  deleteGithubProjects,
} from "@/network/gitlab";
import { eolinkConfig, geteolinkConfig } from "@/network/eolink/index.js";
import { get_filetype } from "@/network/home/index.js";
import { request_joinedProjects } from "@/network/personal/index.js";
import {
  getMassMessageChannels,
  addMassMessageChannels,
  modifyMassMessageChannels,
  deleteMassMessageChannels,
} from "@/network/messageChannel";
import { ossAuthorization } from "@/utils/oss";
export default {
  components: {
    personalAvatar,
  },
  data() {
    return {
      file_type_options: [],
      message_channel_options: [
        {
          label: this.$t("projectDetails.Feishu"),
          value: "FEISHU",
        },
        {
          label: this.$t("projectDetails.DingTalk"),
          value: "DING_TALK",
        },
        {
          label: this.$t("projectDetails.WeChatWork"),
          value: "ORG_WECHAT",
        },
      ],
      connFailed: false,
      urlFailed: false,
      message_channels: [],
      authentication_success: false,
      authentication_gitee_success: false,
      authentication_github_success: false,
      build_job_form: {
        jobUrls: [
          {
            value: "",
            key: "1",
          },
        ],
      },
      build_job_list: [],
      add_eolink_rules: {
        openAPIKey: [
          {
            required: true,
            message: "该字段不能为空",
            trigger: "change",
          },
        ],
        spaceKey: [
          {
            required: true,
            message: "该字段不能为空",
            trigger: "change",
          },
        ],
      },
      add_jenkins_rules: {
        host: [
          {
            required: true,
            message: this.$t("projectDetails.message.jenkins1"),
            trigger: "change",
          },
        ],
        userName: [
          {
            required: true,
            message: this.$t("projectDetails.message.jenkins2"),
            trigger: "change",
          },
        ],
        apiToken: [
          {
            required: true,
            message: this.$t("projectDetails.message.jenkins3"),
            trigger: "change",
          },
        ],
      },
      add_notice_rules: {
        messageChannel: [
          {
            required: true,
            message: this.$t("projectDetails.SelectMessageChannel"),
            trigger: "change",
          },
        ],
        webhookUrl: [
          {
            required: true,
            message: this.$t("projectDetails.EnterWebhookAddress"),
            trigger: "change",
          },
        ],
      },
      eoLink_config: {
        fileTypeList: [],
        openAPIKey: "",
      },
      jenkins_config: {
        host: "",
        apiToken: "",
        userName: "",
      },
      notice_config: {
        messageChannel: "",
        webhookUrl: "",
      },
      const_form: {
        projectName: "",
        type: "",
        avatar: "",
      },
      project_inf: {},
      loading: false,
      is_edit: false,
      template_dialog: false,
      add_repository_dialog: false,
      add_eolink_dialog: false,
      add_jenkins_dialog: false,
      add_build_jobs_dialog: false,
      add_notice_dialog: false,
      authentication_dialog: false,
      show_avatar: true,
      form: {
        projectName: "",
        type: "",
        avatar: "",
      },
      template_form: {},
      // gitLab认证列表
      hasGitLabAuth: [],
      gitlab_config_id: "",
      checked_repository: "",
      checked_gitee_repository: "",
      checked_github_repository: "",
      authentication_form: {
        applicationid: "",
        secret: "",
        url: "",
        name: "",
      },
      project_type_list: [
        {
          label: "Kanban",
          value: "Kanban",
        },
        {
          label: "Scrum",
          value: "Scrum",
        },
      ],
      repository_list: [],
      gitee_repository_list: [],
      github_repository_list: [],
      eolink: {},
      repository_options_list: [],
      repository_gitee_options_list: [],
      repository_github_options_list: [],
      checked_repository_type: "",
      testPlanList: [],
      selectedTestPlan: {},
      selectedCedarTestPlan: {},
      coraxForm: {
        testPlanId: "",
      },
      cedarForm: {
        testPlanId: "",
      },
    };
  },
  computed: {
    VUE_APP_BASE_API() {
      return process.env.VUE_APP_BASE_API;
    },
    default_url() {
      return (
        config.authentication_url +
        this.get_pid() +
        "/home/gitlab/authentication"
      );
    },
    hasGitLab() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name === "极狐Gitlab"
      );
    },
    hasGitee() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name === this.$t("projectDetails.message.lab4")
      );
    },
    hasGithub() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name === this.$t("projectDetails.message.lab6")
      );
    },
    hasSVN() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name === "SVN"
      );
    },
    company_list() {
      return [
        {
          logo: logo1,
          name: this.$t("projectDetails.message.lab2"),
          show: this.hasGitLab,
        },
        {
          logo: logo2,
          name: this.$t("projectDetails.message.lab3"),
          show: this.hasGitee,
        },
        {
          logo: logo3,
          name: this.$t("projectDetails.message.lab5"),
          show: this.hasGithub,
        },
        {
          logo: logo4,
          name: this.$t("projectDetails.message.lab7"),
          show: this.hasSVN,
        },
      ];
    },
    hasEoLink() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name.toLowerCase() === "eolink"
      );
    },
    hasJenkins() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name.toLowerCase() === "jenkins"
      );
    },
    hasCorax() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name.toLowerCase() === "corax"
      );
    },
    hasCedar() {
      return true;
      // return this.$store.getters.project_third_apps.some(
      //   (item) => item.name.toLowerCase() === "cedar"
      // );
    },
  },
  watch: {
    company_list(newVal) {
      if (newVal.length) {
        newVal.forEach((item) => {
          item.show ? (this.checked_repository_type = item.name) : "";
        });
      }
    },
    "coraxForm.testPlanId": {
      immediate: true, // also run the function immediately, not just when the prop changes
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          let matchingItem = this.testPlanList.find(
            (item) => item.testPlanId === newVal
          );
          this.selectedTestPlan = matchingItem;
        }
      },
    },
    "cedarForm.testPlanId": {
      immediate: true, // also run the function immediately, not just when the prop changes
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          let matchingItem = this.testPlanList.find(
            (item) => item.testPlanId === newVal
          );
          this.selectedCedarTestPlan = matchingItem;
        }
      },
    },
  },
  created() {
    const params = {
      pageNumber: 0,
      pageSize: 1000000,
      fuzzyName: "",
    };
    request_allPageProjects(params).then((res) => {
      for (let i of res.content) {
        if (i.projectId == this.get_pid()) {
          this.project_inf = i;
          this.form["projectName"] = i.projectName;
          this.form["type"] = i.type;
          this.form["projectId"] = i.projectId;
          this.form["projectKey"] = i.projectKey;
        }
      }
    });
  },
  mounted() {
    this.gitlabProjects();
    this.giteeProjects();
    this.githubProjects();
    this.eolinkConfig();
    this.get_filetype();
    this.get_massMessageChannels();
    if (this.hasJenkins === true) {
      this.getJobList();
    }
    this.checked_repository_type = "";
    // console.log(this.$store.commit("CHANGE_THIRD_APPS"));
    if (this.hasCorax) {
      this.getCoraxInfo();
      this.getTestplanList();
    }
    if (this.hasCedar) {
      this.getCedarInfo();
      this.getTestplanList();
    }
  },
  beforeDestroy() {
    this.validation_request_interval
      ? clearInterval(this.validation_request_interval)
      : "";
    this.validation_request_interval_gitee
      ? clearInterval(this.validation_request_interval_gitee)
      : "";
  },
  methods: {
    handleCopyText() {
      // 创建一个 Input标签
      const cInput = document.createElement("input");
      cInput.value = `
${this.$t("projectDetails.addGitlabForm.note6")}
REPOS="$1"
REV="$2"

${this.$t("projectDetails.addGitlabForm.note7")}
COMMITTER=$(svnlook author -r $REV "$REPOS")
MESSAGE=$(svnlook log -r $REV "$REPOS")
REPO_NAME=$(basename "$REPOS")

${this.$t("projectDetails.addGitlabForm.note8")}
JSON="{
    \"commitMessage\": \"$(echo $MESSAGE | sed 's/\"/\\\\\"/g')\",
    \"author\": \"$COMMITTER\",
    \"repo\": \"$REPO_NAME\",
    \"revision\": $REV
}"

${this.$t("projectDetails.addGitlabForm.note9")}
REMOTE_URL="${
        process.env.VUE_APP_BASE_API
      }/file-manage-service/${this.get_pid()}/svnCommitHook"

${this.$t("projectDetails.addGitlabForm.note10")}
curl -X POST -H "Content-Type: application/json" -d "$JSON" "$REMOTE_URL"`;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本域内容;
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效
      document.execCommand("Copy");
      this.$message.success(this.$t("personal.openApi.message3"));
      /// 复制成功后再将构造的标签 移除
      cInput.remove();
    },
    getCoraxInfo() {
      const params = {
        projectId: this.get_pid(),
      };
      getCoraxConfig(params).then((res) => {
        // console.log(res);
        this.coraxForm.testPlanId = res.testPlanId;
      });
    },
    getCedarInfo() {
      const params = {
        projectId: this.get_pid(),
      };
      getcedarConfig(params).then((res) => {
        // console.log(res);
        this.cedarForm.testPlanId = res.testPlanId;
      });
    },
    getTestplanList() {
      get_test_plans(this.get_pid()).then((res) => {
        if (res.COMPLETE && res.IN_PROGRESS) {
          this.testPlanList = [...res.COMPLETE, ...res.IN_PROGRESS];
        } else if (res.COMPLETE) {
          this.testPlanList = [...res.COMPLETE];
        } else if (res.IN_PROGRESS) {
          this.testPlanList = [...res.IN_PROGRESS];
        }
        // console.log(res);
        this.testPlanList.forEach((item) => {
          if (item.status == "NOT_STARTED") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.notStarted") +
              ")";
          } else if (item.status == "IN_PROGRESS") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.inProgress") +
              ")";
          } else if (item.status == "COMPLETE") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.completed") +
              ")";
          }
        });
        let matchingItem = this.testPlanList.find(
          (item) => item.testPlanId === this.coraxForm.testPlanId
        );
        let matchingcaderItem = this.testPlanList.find(
          (item) => item.testPlanId === this.cedarForm.testPlanId
        );
        this.selectedTestPlan = matchingItem;
        this.selectedCedarTestPlan = matchingcaderItem;
        // console.log(this.selectedTestPlan);
      });
    },
    // 选择仓库类型
    change_repository(item) {
      this.checked_repository_type = item.name;
    },
    async get_massMessageChannels() {
      let res = await getMassMessageChannels(this.get_pid()); //获取消息渠道
      this.message_channels = res;
    },
    async get_filetype() {
      let res = await get_filetype(this.get_pid()); //获取这个项目组的文件类型
      this.file_type_options = res;
    },
    eolinkConfig() {
      const params = {
        projectId: this.get_pid(),
      };
      geteolinkConfig(params).then((res) => {
        this.eoLink_config = res;
      });
    },
    geteolinkConfig() {
      this.add_eolink_dialog = true;
    },
    getjenkinsConfig() {
      this.add_jenkins_dialog = true;
    },
    add_url() {
      this.build_job_form.jobUrls.push({
        value: "",
        key: Date.now(),
      });
    },
    remove_url(item) {
      let index = this.build_job_form.jobUrls.indexOf(item);
      if (index !== -1) {
        this.build_job_form.jobUrls.splice(index, 1);
      }
    },
    handleClose() {
      this.connFailed = false;
      this.add_build_jobs_dialog = false;
      this.add_jenkins_dialog = false;
    },
    async validate_job(rule, value, callback) {
      if (value === "") {
        return callback(new Error(this.$t("projectDetails.EnterBuildJobLink")));
      } else {
        if (value) {
          const data = {
            host: this.jenkins_config.host,
            userName: this.jenkins_config.userName,
            apiToken: this.jenkins_config.apiToken,
            jobUrl: value,
          };
          await testJobConn({
            projectId: this.get_pid(),
            data: data,
          }).then((res) => {
            if (res !== null && res.errorMessage !== null) {
              callback(new Error(res.errorMessage));
            } else {
              callback();
            }
          });
        }
      }
    },
    handleAvatarSuccess(res, file) {
      this.$message.success(this.$t("projectDetails.message.success"));
      this.form.avatar = res;
    },
    handleFileError(err) {
      console.log(err);
    },
    beforeAvatarUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message.error(this.$t("projectDetails.message.error"));
      }
      return isLt1M;
    },
    //参数必须是param，才能获取到内容
    async uploadFile(param) {
      const loading = this.$loading({
        lock: true,
        text: this.$t("projectDetails.message.ing"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // // 获取上传的文件名
      let file = param.file;
      //发送请求的参数格式为FormData
      await ossAuthorization.call(this);
      this.client
        .put(
          `${this.$store.state.project.tenantId}/user_avatar/${file.name}`,
          file
        )
        .then((ress) => {
          loading.close();
          this.show_avatar = false;
          this.$set(this.project_inf, "avatar", ress.url);
          this.show_avatar = true;
        })
        .error((r) => {
          loading.close();
          this.$message.error(this.$t("projectDetails.message.error2"));
        });
    },
    edit_save() {
      // console.log(this.project_inf,this.form,this.const_form);
      this.form.avatar = this.project_inf.avatar;
      if (this.form.projectName.length < 1) {
        this.$message.error(this.$t("projectDetails.message.info2"));
        return false;
      }
      // if (this.form.key.length<1){
      //   this.$message.error('请输入项目ID')
      //   return false
      // }
      // if (this.form.key.length>5){
      //   this.$message.error('项目ID长度在1-5之间')
      //   return false
      // }
      // let pattern = new RegExp("[A-Za-z]+");
      // for (var i of this.form.key){
      //   if (!pattern.test(i)){
      //     this.$message.error('项目ID只能是英文字母')
      //     return false
      //   }
      // }

      this.is_edit = !this.is_edit;
      change_project_inf(this.form, this.get_pid()).then((res) => {
        let flag = true;
        this.$store.commit("SET_PROJECT_TYPE", this.form.type);
        this.$store.commit("SET_PROJECTNAME", this.form.projectName); //设置项目name
        request_joinedProjects().then((res) => {
          this.$store.commit("set_joined_projects", res);
          const asyncList = [];
          if (this.hasCorax) {
            const params = {
              projectId: this.get_pid(),
              testPlanId: this.coraxForm.testPlanId,
            };
            asyncList.push(modifyCoraxConfig(params));
          }
          if (this.hasCedar) {
            const params = {
              projectId: this.get_pid(),
              testPlanId: this.cedarForm.testPlanId,
            };
            asyncList.push(modifycedarConfig(params));
          }
          if (!this.hasCorax && !this.hasCedar) {
            flag ? this.$router.go(0) : "";
          }
          Promise.all(asyncList).then((res) => {
            flag ? this.$router.go(0) : "";
          });
        });
      });
    },
    getGitlabProjectList(value) {
      this.checked_repository = [];
      const params = {
        projectId: this.get_pid(),
        gitlabConfigId: value,
      };
      this.allProjectList(params);
    },
    gitlabProjects() {
      this.loading = true;
      const params = {
        projectId: this.get_pid(),
      };
      gitlabProjects(params).then((res) => {
        this.repository_list = res;
        this.loading = false;
        // 同步到vuex
        this.$store.commit("SET_REPOSITORY_LIST", res);
      });
    },
    giteeProjects() {
      this.loading = true;
      const params = {
        projectId: this.get_pid(),
      };
      getGiteeProjects(params).then((res) => {
        this.gitee_repository_list = res;
        this.loading = false;
        // 同步到vuex
        this.$store.commit("SET_GITEE_REPOSITORY_LIST", res);
      });
    },
    githubProjects() {
      this.loading = true;
      const params = {
        projectId: this.get_pid(),
      };
      getGithubProjects(params).then((res) => {
        this.github_repository_list = res;
        this.loading = false;
        // 同步到vuex
        this.$store.commit("SET_GITHUB_REPOSITORY_LIST", res);
      });
    },
    edit_click() {
      this.const_form = JSON.parse(JSON.stringify(this.form));
      this.is_edit = !this.is_edit;
    },
    show_template() {
      this.template_dialog = true;
    },
    template_dialog_confrim() {
      this.template_dialog = false;
    },
    delete_repository(repository) {
      this.$confirm(
        this.$t("projectDetails.message.dele"),
        this.$t("projectDetails.message.title"),
        {
          confirmButtonText: this.$t("btn.deleteBtn"),
          cancelButtonText: this.$t("btn.cancelBtn"),
          confirmButtonClass: "danger_button",
          type: "warning",
        }
      )
        .then(() => {
          const params = {
            projectId: this.get_pid(),
            gitlabConfigId: repository.gitConfigId,
            gitlabProjectId: repository.id,
          };
          deletegitlabProjects(params).then((res) => {
            this.gitlabProjects();
          });
        })
        .catch(() => {});
      // 同步更新vuex仓库
      // this.$store.commit("CHANGE_THIRD_APPS", "极狐Gitlab");
    },
    delete_gitee_repository(repository) {
      this.$confirm(
        this.$t("projectDetails.message.dele"),
        this.$t("projectDetails.message.title"),
        {
          confirmButtonText: this.$t("btn.deleteBtn"),
          cancelButtonText: this.$t("btn.cancelBtn"),
          confirmButtonClass: "danger_button",
          type: "warning",
        }
      )
        .then(() => {
          const params = {
            projectId: this.get_pid(),
            giteeConfigId: repository.gitConfigId,
            giteeProjectId: repository.id,
          };
          deleteGiteeProjects(params).then((res) => {
            this.giteeProjects();
          });
        })
        .catch(() => {});
      // 同步更新vuex仓库
      // this.$store.commit("CHANGE_THIRD_APPS", "Gitee");
    },
    delete_github_repository(repository) {
      this.$confirm(
        this.$t("projectDetails.message.dele"),
        this.$t("projectDetails.message.title"),
        {
          confirmButtonText: this.$t("btn.deleteBtn"),
          cancelButtonText: this.$t("btn.cancelBtn"),
          confirmButtonClass: "danger_button",
          type: "warning",
        }
      )
        .then(() => {
          const params = {
            projectId: this.get_pid(),
            githubConfigId: repository.gitConfigId,
            githubProjectId: repository.id,
          };
          deleteGithubProjects(params).then((res) => {
            this.githubProjects();
          });
        })
        .catch(() => {});
      // 同步更新vuex仓库
      // this.$store.commit("CHANGE_THIRD_APPS", "Github");
    },
    add_repository() {
      this.add_repository_dialog = true;
      this.gitlab_config_id = "";
      this.checked_repository = [];
      this.hasGitLabAuth = [];
      this.checked_gitee_repository = [];
      this.checked_github_repository = [];
      this.repository_options_list = [];
      this.repository_gitee_options_list = [];
      this.repository_github_options_list = [];
      this.authentication_success = false;
      this.authentication_gitee_success = false;
      this.authentication_github_success = false;
      this.getGitlabConfigs();
    },
    getGitlabConfigs() {
      getGitlabConfigs({
        projectId: this.get_pid(),
      }).then((res) => {
        this.hasGitLabAuth = res;
        if (res.length) {
          this.authentication_success = true;
        }
      });
    },
    add_eolink() {
      this.geteolinkConfig();
    },
    add_jenkins() {
      this.getjenkinsConfig();
    },
    add_notice() {
      this.notice_is_edit = false;
      this.add_notice_dialog = true;
      this.notice_config = {
        messageChannel: "",
        webhookUrl: "",
      };
      this.current_channelId = "";
    },
    add_noctice_dialog_confrim() {
      this.$refs["add_notice_form"].validate((valid) => {
        if (valid) {
          const params = {
            projectId: this.get_pid(),
            data: this.notice_config,
          };
          if (this.notice_is_edit) {
            modifyMassMessageChannels(
              Object.assign({}, params, {
                channelId: this.current_channelId,
              })
            ).then((res) => {
              this.get_massMessageChannels();
              this.add_notice_dialog = false;
            });
          } else {
            addMassMessageChannels(params).then((res) => {
              this.add_notice_dialog = false;
              this.get_massMessageChannels();
              this.add_notice_dialog = false;
            });
          }
        }
      });
    },
    edit_notice(tag) {
      if (!this.is_edit) return;
      this.notice_is_edit = true;
      this.add_notice_dialog = true;
      this.notice_config.messageChannel = tag.messageChannel;
      this.notice_config.webhookUrl = tag.webhookUrl;
      this.current_channelId = tag.massMessageChannelId;
    },
    delete_notice(tag) {
      const params = {
        projectId: this.get_pid(),
        channelId: tag.massMessageChannelId,
      };
      deleteMassMessageChannels(params)
        .then((res) => {
          this.get_massMessageChannels();
          return true;
        })
        .catch((err) => {
          return false;
        });
    },
    delete_job(tag) {
      const params = {
        projectId: this.get_pid(),
        configId: tag.configId,
      };
      deleteJob(params)
        .then((res) => {
          this.getJobList();
          return true;
        })
        .catch((err) => {
          return false;
        });
    },
    creat_authentication() {
      (this.authentication_form = {
        applicationid: "",
        secret: "",
        url: "",
        name: "",
      }),
        (this.authentication_dialog = true);
    },
    validation_request_gitee() {
      if (this.validation_request_interval_gitee) {
        clearInterval(this.validation_request_interval_gitee);
      }
      this.validation_request_interval_gitee = setInterval(() => {
        this.giteeConfigId = localStorage.getItem("giteeConfigId");
        if (this.giteeConfigId) {
          const params = {
            projectId: this.get_pid(),
            giteeConfigId: this.giteeConfigId,
          };
          clearInterval(this.validation_request_interval_gitee);
          giteeallProjectList(params).then((res) => {
            this.repository_gitee_options_list = res;
            this.authentication_gitee_success = true;
            localStorage.removeItem("giteeConfigId");
          });
        }
      }, 2000);
    },
    validation_request_github() {
      if (this.validation_request_interval_github) {
        clearInterval(this.validation_request_interval_github);
      }
      this.validation_request_interval_github = setInterval(() => {
        this.githubConfigId = localStorage.getItem("githubConfigId");
        if (this.githubConfigId) {
          const params = {
            projectId: this.get_pid(),
            githubConfigId: this.githubConfigId,
          };
          clearInterval(this.validation_request_interval_github);
          githuballProjectList(params).then((res) => {
            this.repository_github_options_list = res;
            this.authentication_github_success = true;
            localStorage.removeItem("githubConfigId");
          });
        }
      }, 2000);
    },
    creat_authentication_gitee() {
      localStorage.setItem("gitee_pid", this.get_pid());
      const href = `https://gitee.com/oauth/authorize?client_id=${
        config.gitee_clientID
      }&redirect_uri=${
        config.authentication_url + "authenticationGitee"
      }&response_type=code`;
      window.open(href, "_blank");
      this.validation_request_gitee();
    },
    creat_authentication_github() {
      localStorage.setItem("github_pid", this.get_pid());
      const href = `https://github.com/login/oauth/authorize?client_id=${
        config.github_clientID
      }&redirect_uri=${
        config.authentication_url + "authenticationGithub"
      }&scope=repo%20admin:repo_hook`;
      window.open(href, "_blank");
      this.validation_request_github();
    },
    authentication_dialog_before_close() {
      this.validation_request_interval
        ? clearInterval(this.validation_request_interval)
        : "";
      this.validation_request_interval_gitee
        ? clearInterval(this.validation_request_interval_gitee)
        : "";
      this.authentication_dialog = false;
    },
    authentication_dialog_confrim_cancel() {
      this.validation_request_interval
        ? clearInterval(this.validation_request_interval)
        : "";
      this.validation_request_interval_gitee
        ? clearInterval(this.validation_request_interval_gitee)
        : "";
      this.authentication_dialog = false;
    },
    add_repository_confrim() {
      if (
        !this.checked_repository.length &&
        !this.checked_gitee_repository.length &&
        !this.checked_github_repository.length
      ) {
        this.$message({
          type: "warning",
          message: this.$t("projectDetails.message.warn"),
        });
      } else {
        if (
          this.checked_repository_type ===
          this.$t("projectDetails.message.lab2")
        ) {
          const params = {
            projectId: this.get_pid(),
            data: {
              gitProjectIdList: this.checked_repository,
              gitlabConfigId: this.gitlab_config_id,
            },
          };
          savegitlabProjects(params).then((res) => {
            this.add_repository_dialog = false;
            this.gitlabProjects();
          });
        } else if (
          this.checked_repository_type ===
          this.$t("projectDetails.message.lab3")
        ) {
          this.loading = true;
          const params = {
            projectId: this.get_pid(),
            data: {
              giteeConfigId: this.giteeConfigId,
              gitProjectIdList: this.checked_gitee_repository,
            },
          };
          giteeProjects(params).then((res) => {
            this.giteeProjects();
          });
          this.add_repository_dialog = false;
        } else if (
          this.checked_repository_type ===
          this.$t("projectDetails.message.lab5")
        ) {
          this.loading = true;
          const params = {
            projectId: this.get_pid(),
            data: {
              githubConfigId: this.githubConfigId,
              gitProjectIdList: this.checked_github_repository,
            },
          };
          githubProjects(params).then((res) => {
            this.githubProjects();
          });
          this.add_repository_dialog = false;
        }
      }
    },
    add_eolink_dialog_confrim() {
      this.$refs["add_eolink_form"].validate((valid) => {
        if (valid) {
          const params = {
            projectId: this.get_pid(),
            data: this.eoLink_config,
          };
          eolinkConfig(params).then((res) => {
            this.add_eolink_dialog = false;
          });
        }
      });
    },
    add_jenkins_dialog_confrim() {
      this.$refs["add_jenkins_form"].validate((valid) => {
        if (valid) {
          const params = {
            projectId: this.get_pid(),
            data: this.jenkins_config,
          };
          testConn(params).then((res) => {
            if (res === -1) {
              this.connFailed = true;
            } else {
              this.connFailed = false;
              this.add_jenkins_dialog = false;
              this.add_build_jobs_dialog = true;
            }
          });
        }
      });
    },
    // 提交build jobs
    add_build_jobs_confrim() {
      this.$refs["build_job_form"].validate((valid) => {
        if (valid) {
          this.jenkins_config.jobUrls = this.build_job_form.jobUrls.map(
            (item) => {
              return item.value;
            }
          );
          const params = {
            projectId: this.get_pid(),
            data: this.jenkins_config,
          };
          saveJob(params)
            .then((res) => {
              this.$refs["build_job_form"].resetFields();
              this.add_build_jobs_dialog = false;
              this.getJobList();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    },
    // 获取项目里所有的build jobs
    getJobList() {
      const params = {
        projectId: this.get_pid(),
      };
      getJobList(params).then((res) => {
        this.build_job_list = res;
      });
    },
    // 获取gitlab仓库列表
    allProjectList(params) {
      allProjectList(params).then((res) => {
        this.repository_options_list = res;
        this.authentication_success = true;
        this.authentication_dialog = false;
      });
    },
    validation_request(gitlab_config_id) {
      if (this.validation_request_interval) {
        clearInterval(this.validation_request_interval);
      }
      const params = {
        projectId: this.get_pid(),
        gitlabConfigId: gitlab_config_id,
      };
      this.validation_request_interval = setInterval(() => {
        validation(params).then((res) => {
          if (res) {
            clearInterval(this.validation_request_interval);
            this.$message({
              type: "success",
              message: this.$t("projectDetails.addGitlabForm.note3"),
            });
            this.allProjectList(params);
            this.getGitlabConfigs();
          }
        });
      }, 2000);
    },
    authentication_dialog_confrim() {
      if (
        !this.authentication_form.applicationid.length ||
        !this.authentication_form.secret.length ||
        !this.authentication_form.url.length ||
        !this.authentication_form.name.length
      ) {
        this.$message({
          type: "warning",
          message: this.$t("projectDetails.message.warn2"),
        });
      } else {
        let gitlab_url =
          this.authentication_form.url[
            this.authentication_form.url.length - 1
          ] === "/"
            ? this.authentication_form.url.substring(
                0,
                this.authentication_form.url.length - 1
              )
            : this.authentication_form.url;
        const params = {
          projectId: this.get_pid(),
          data: {
            applicationId: this.authentication_form.applicationid,
            gitlabUrl: gitlab_url,
            secret: this.authentication_form.secret,
            name: this.authentication_form.name,
          },
        };
        gitlabConfigs(params).then((res) => {
          this.gitlab_config_id = res;
          const href = `${gitlab_url}/oauth/authorize?client_id=${
            this.authentication_form.applicationid
          }&redirect_uri=${this.default_url}?ids=${
            this.get_pid() + "," + this.gitlab_config_id
          }&response_type=code&state=STATE&scope=api+read_user+read_repository+write_repository`;
          window.open(href, "_blank");
          this.validation_request(this.gitlab_config_id);
        });
      }
    },
    copy_url() {
      let text1 = this.$refs["redirect_url"];
      let text2 = this.$refs["text_area"];
      text2.innerText = text1.innerText;
      text2.select();
      document.execCommand("copy");
      this.$message({
        message: this.$t("projectDetails.copyed"),
        type: "success",
      });
    },
    copy_id() {
      let text1 = this.$refs["pro_id"];
      let text2 = this.$refs["text_area"];
      text2.innerText = text1.innerText;
      text2.select();
      document.execCommand("copy");
      if (this.form.projectId) {
        this.$message({
          message: this.$t("projectDetails.copyed"),
          type: "success",
        });
      }
    },
  },
};
</script>
<style>
.danger_button {
  color: #fff !important;
  background-color: #f56c6c !important;
  border-color: #f56c6c !important;
}
</style>

<style lang="scss" scoped>
::v-deep.el-button {
  font-size: 16px;
  padding: 6px 10px;
}
.project_details {
  & > p {
    margin: 0;
    text-align: right;
  }
  .context_box {
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 36px 0 40px 40px;
    margin-top: 28px;
    height: calc(88vh - 60px);
    overflow-y: scroll;
    .tag_wrap_class {
      text-align: left;
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
      & > span {
        margin: 4px 10px 4px 0;
      }
    }
    & > p {
      margin: 0;
      margin-right: 24px;
      text-align: right;
    }
    .form_label {
      display: inline-block;
      width: 100%;
      text-align: left;
      color: rgba(128, 128, 128, 1);
      font-size: 14px;
      font-weight: 700;
    }
    .form_input {
      width: 500px;
      position: absolute;
      left: 0;
    }
    .form_corax {
      position: absolute;
      left: 0;
      text-align: left;
      overflow: hidden;
    }
    .form_span {
      width: 200px;
      position: absolute;
      text-align: left;
      left: 0;
    }
    .p_name_span {
      width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .avatar_click {
      color: rgba(48, 100, 143, 1);
      font-size: 14px;
      cursor: pointer;
      position: absolute;
      left: 80px;
      top: 0px;
    }
  }
  .add_repository_wrap {
    color: #000;
    .company_list {
      display: flex;
      justify-content: left;
      & > .checked-repository-type {
        border-color: rgb(48, 100, 143);
      }
      & > div {
        width: 110px;
        height: 110px;
        border: 1px solid rgba(207, 207, 207, 1);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        & > img {
          width: 50px;
          height: 50px;
        }
      }
    }
    & > div {
      text-align: left;
    }
    .add_repository_des {
      margin: 30px 0;
    }
  }
  .authentication_wrap {
    text-align: left;
    .index_class {
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: rgba(48, 100, 143, 1);
      color: #fff;
      border-radius: 50%;
      margin-right: 15px;
    }
    .authentication_title {
      color: #000;
      margin: 10px 0 8px 0;
    }
    .authentication_des {
      padding-left: 30px;
      .authentication_code_area {
        display: inline-block;
        margin-right: 12px;
        height: 33px;
        line-height: 33px;
        background: rgba(235, 235, 235, 1);
        padding: 0 12px;
        border-radius: 4px;
      }
    }
  }
  .connFailed {
    margin-right: 20px;
    font-weight: 800;
    color: indianred;
  }
  .build_job {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}
</style>
<style lang="scss">
.test {
  color: $color-primary;
}
</style>
