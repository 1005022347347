<template>
  <div>
    <!-- myDiv 计算文本宽度高度 -->
    <div id="myDiv"></div>
    <!-- myCanvas 也来计算文本宽度高度 -->
    <canvas id="myCanvas" style="display: none"></canvas>

    <div
      style="
        display: flex;
        align-items: flex-start;
        height: 50px;
        justify-content: space-between;
      "
    >
      <div>
        <el-select v-model="now_node" filterable>
          <el-option
            v-for="item in all_nodes"
            :key="item.key"
            style="width: 200px"
            :label="item.topic"
            :value="item.key"
          >
          </el-option>
        </el-select>
        <span @click="goKnowledge">
          <i
            class="iconfont icon-a-fenzu6"
            style="cursor: pointer; margin-left: 15px; font-size: 14px"
          ></i>
        </span>

        <!-- <el-tooltip
          class="item"
          effect="dark"
          :content="$t('tara.tip')"
          placement="top-start"
        >
          <i
            class="el-icon-question"
            style="margin-left: 15px; font-size: 18px"
          ></i>
        </el-tooltip> -->
      </div>
      <div
        type="text"
        style="color: grey; font-size: 20px; cursor: pointer"
        @click="close_dialog"
      >
        <i class="el-icon-close"></i>
      </div>
    </div>

    <div id="label_container" style="height: 50px"></div>

    <div id="label_container2" style="height: calc(70vh - 150px); width: 100%">
      <div id="container" style="height: 100%; width: 100%"></div>
    </div>
    <!-- <div id="label_container1" style="width: 100%; overflow: auto">
      <div style="width: 2100px; height: 1px">&nbsp;</div>
    </div> -->
    <!-- :span-method="objectSpanMethod" -->

    <el-drawer
      v-drawerDrag
      class="tara_drawer"
      :visible.sync="show_table"
      :direction="'btt'"
      append-to-body
      :modal="false"
      :wrapper-closable="false"
      :with-header="false"
      :with-mask="false"
    >
      <div style="display: flex; height: 100%">
        <div style="width: 40px; margin-top: 25px">
          <div style="rotate: 90deg" @click="show_table = false">
            <i
              class="iconfont icon-zhedie1"
              style="font-size: 20px; cursor: pointer"
            ></i>
          </div>
        </div>
        <el-table
          ref="tara_table_graph"
          border
          height="100%"
          style="width: 100%"
          class="tara_table_class"
          :span-method="objectSpanMethod"
          :data="tableData"
          :header-cell-style="headerCellStyle"
          cell-class-name="tara_table_class_cell"
        >
          <el-table-column
            prop="index"
            :label="$t('tara.t64')"
            fixed
            class-name="tara_table_class_topic"
            label-class-name="tara_table_class_topic_header"
          >
            <template slot-scope="scope">
              {{ modify_table(scope.row.index) }}
            </template>
          </el-table-column>

          <el-table-column
            prop="form1"
            :label="$t('hara.label1')"
            width="200"
            fixed
            class-name="tara_table_class_topic"
            label-class-name="tara_table_class_topic_header"
          >
            <template slot-scope="scope">
              {{ modify_table(scope.row.form1) }}
            </template>
          </el-table-column>

          <el-table-column prop="form2" :label="$t('hara.label2')" width="200">
            <template slot-scope="scope">
              {{ modify_table(scope.row.form2) }}
            </template>
          </el-table-column>

          <el-table-column prop="form3" :label="$t('hara.label3')" width="200">
            <template slot-scope="scope">
              {{ modify_table(scope.row.form3) }}
            </template>
          </el-table-column>

          <el-table-column prop="SEVERITY" :label="$t('hara.t2')" width="150">
            <template slot-scope="scope">
              {{ SEVERITY_MAP[$t("hara.lang")][scope.row.SEVERITY] }}
            </template>
          </el-table-column>

          <el-table-column
            prop="CONTROLLABILITY"
            :label="$t('hara.t4')"
            width="150"
          >
            <template slot-scope="scope">
              {{
                CONTROLLABILITY_MAP[$t("hara.lang")][scope.row.CONTROLLABILITY]
              }}
            </template>
          </el-table-column>

          <el-table-column prop="EXPOSURE" :label="$t('hara.t3')" width="150">
            <template slot-scope="scope">
              {{ EXPOSURE_MAP[$t("hara.lang")][scope.row.EXPOSURE] }}
            </template>
          </el-table-column>

          <el-table-column prop="level" :label="$t('hara.t5')" width="100">
            <template slot-scope="scope">
              {{ modify_table(scope.row.level) }}
            </template>
          </el-table-column>

          <el-table-column prop="form4" :label="$t('hara.label4')" width="200">
            <template slot-scope="scope">
              {{ modify_table(scope.row.form4) }}
            </template>
          </el-table-column>

          <el-table-column prop="ftti" label="FTTI" width="100">
            <template slot-scope="scope">
              {{
                modify_table(scope.row.ftti) +
                (modify_table(scope.row.ftti).length > 0 ? " ms" : "")
              }}
            </template>
          </el-table-column>
          <el-table-column prop="SAFETY" :label="$t('hara.t6')" width="250">
            <template slot-scope="scope">
              {{ modify_table(scope.row.SAFETY) }}
            </template>
          </el-table-column>
          <el-table-column prop="assignee" :label="$t('tara.t13')" width="150">
            <template slot-scope="scope">
              {{ modify_table(scope.row.assignee) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="nodeKeyList"
            :label="$t('tara.t51')"
            width="250"
          >
            <template slot-scope="scope">
              <div
                v-html="modify_table_relation_n(scope.row.nodeKeyList)"
              ></div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <div
      style="
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        height: 50px;
      "
    >
      <i
        class="el-icon-download"
        style="font-size: 20px; margin-right: 25px; cursor: pointer"
        @click="export_excel()"
      ></i>
      <el-button
        v-if="!show_table"
        type="primary"
        @click="show_table_fun(true)"
        >{{ $t("tara.t15") }}</el-button
      >
      <el-button v-else type="primary" @click="hide_table_fun">{{
        $t("tara.t16")
      }}</el-button>
    </div>

    <!-- 删除节点 -->
    <el-dialog
      :title="$t('tara.t17')"
      :visible.sync="del_dialog"
      width="25%"
      height="8vh"
      style="text-align: left"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i>
      <span>&nbsp;{{ $t("tara.t18") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="confirm_del">
          {{ $t("btn.deleteBtn") }}
        </el-button>
        <el-button @click="del_dialog = false">
          {{ $t("btn.cancelBtn") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 2 -->
    <el-dialog
      :title="$t('hara.label2')"
      :visible.sync="dialog2"
      width="30%"
      class="tara_form2"
      append-to-body
      @close="close_dialog2"
    >
      <div>
        <div style="display: flex; align-items: center; margin-bottom: 20px">
          <el-input
            v-model="hara_form_2"
            type="textarea"
            :rows="4"
            :placeholder="$t('hara.p1')"
            style="width: 100%"
          ></el-input>
        </div>
        <el-tooltip
          ref="tooltip"
          class="item"
          :content="$t('tara.t34')"
          placement="top"
        >
          <span>
            <el-popover ref="popover1" placement="right">
              <el-cascader-panel
                v-model="over1"
                class="tara_jilian——long"
                :options="hara_mf_data"
                @change="push_text_form3"
              ></el-cascader-panel>
              <el-button
                slot="reference"
                type="text"
                icon="el-icon-circle-plus-outline"
                circle
                style="margin-bottom: 15px; padding: 0"
              ></el-button>
            </el-popover>
          </span>
        </el-tooltip>
      </div>
    </el-dialog>

    <!-- 3 -->
    <el-dialog
      :title="$t('hara.label3')"
      :visible.sync="dialog3"
      width="30%"
      class="tara_form4"
      append-to-body
      @close="close_dialog3"
    >
      <div>
        <div class="select">
          <span>{{ $t("hara.t1") }}</span>
          <el-input
            v-model="hara_form_3.topic"
            :placeholder="$t('hara.p2')"
            style="width: 100%"
          ></el-input>
        </div>
        <div class="select">
          <span>{{ $t("hara.t2") }}</span>

          <el-select
            v-model="hara_form_3.SEVERITY"
            :placeholder="$t('tara.t27')"
            style="width: 100%"
            clearable
            @change="get_level(hara_form_3)"
          >
            <el-option
              v-for="item in SEVERITY_SELECT[$t('hara.lang')]"
              v-show="item.value != '0'"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select">
          <span>{{ $t("hara.t4") }}</span>
          <el-select
            v-model="hara_form_3.CONTROLLABILITY"
            :placeholder="$t('tara.t27')"
            clearable
            style="width: 100%"
            @change="get_level(hara_form_3)"
          >
            <el-option
              v-for="item in CONTROLLABILITY_SELECT[$t('hara.lang')]"
              v-show="item.value != '0'"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select">
          <span>{{ $t("hara.t3") }}</span>
          <el-select
            v-model="hara_form_3.EXPOSURE"
            clearable
            :placeholder="$t('tara.t27')"
            style="width: 100%"
            @change="get_level(hara_form_3)"
          >
            <el-option
              v-for="item in EXPOSURE_SELECT[$t('hara.lang')]"
              v-show="item.value != '0'"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select">
          <span>{{ $t("hara.t5") }}</span>
          <el-input
            v-model="hara_form_3.level"
            style="width: 100%"
            disabled
          ></el-input>
        </div>
      </div>
    </el-dialog>

    <!-- 4 -->
    <el-dialog
      :title="$t('hara.label4')"
      :visible.sync="dialog4"
      width="30%"
      class="tara_form4"
      append-to-body
      @close="close_dialog4"
    >
      <div>
        <div class="select">
          <span>{{ $t("hara.label4") }}</span>

          <el-input
            v-model="hara_form_4.topic"
            :placeholder="$t('hara.p3')"
            style="width: 100%"
            clearable
          ></el-input>
        </div>

        <div class="select">
          <span>FTTI</span>
          <el-input
            v-model="hara_form_4.ftti"
            type="text"
            oninput="value=value.replace(/[^\d.]/g,'')"
            :placeholder="$t('hara.p4')"
            style="width: 100%"
            clearable
          >
            <template slot="append">ms</template></el-input
          >
          <!-- <el-input
            v-model.number="hara_form_4.ftti"
            
            :placeholder="$t('hara.p4')"
            style="width: 100%"
            clearable
          >
            <template slot="append">ms</template></el-input
          > -->
        </div>

        <div class="select">
          <span>{{ $t("hara.t6") }}</span>

          <el-input
            v-model="hara_form_4.SAFETY"
            :placeholder="$t('hara.p5')"
            style="width: 100%"
            clearable
          ></el-input>
        </div>
        <div class="select">
          <span>{{ $t("hara.t5") }}</span>
          <el-input
            v-model="hara_form_4.level"
            style="width: 100%"
            disabled
          ></el-input>
        </div>
        <div class="select">
          <span>{{ $t("tara.t50") }}</span>

          <el-select
            v-model="hara_form_4.assignee"
            popper-class="tara_user_select"
            :placeholder="$t('tara.t27')"
            style="width: 100%"
            filterable
            clearable
          >
            <el-option
              v-for="opt in user_list"
              v-show="!opt.deleted && !opt.hide"
              :key="opt.value"
              :label="opt.label"
              :value="opt.value"
            >
              <personal-avatar
                class="avatar"
                :avatar="opt.avatar"
                :colorid="opt.userAccountId"
                :nickname="opt.label"
                :size="20"
              >
              </personal-avatar>
              <span class="select_item">{{ opt.label }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="select">
          <span>{{ $t("tara.t51") }}</span>

          <el-select
            v-model="hara_form_4.nodeKeyList"
            multiple
            filterable
            remote
            reserve-keyword
            :placeholder="$t('nodeDetail.relationChart.placeholder')"
            :loading-text="$t('loading.loading2')"
            :remote-method="get_unrelation_node"
            :loading="relation_loading"
            style="width: 100%"
            popper-class="tara_relation_select"
          >
            <el-option
              v-for="item in relation_options"
              :key="item.nodeKey"
              :label="`${item.nodeKey}: ${cut(item.topic)}`"
              :value="item.nodeKey"
            >
              <!-- ms8613代码定位 -->
              <select-icon :item="item"> </select-icon>
            </el-option>
          </el-select>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Graph } from "@antv/x6";
import Hierarchy from "@antv/hierarchy";
import { getFile } from "@/network/home/index.js";
import { get_allProjectMember } from "@/network/node/index.js";
import {
  get_node_hara,
  init_node_hara,
  del_node_hara,
  chenge_node_hara,
  get_all_node,
  get_CustomizeOptions,
} from "@/network/hara/index.js";
import get_hara_data from "@/store/hara.js";
import { searchNodesByFuzzyKey } from "@/network/fileOperation/index.js";
import vmson from "@/utils/vmson";
import PersonalAvatar from "@/components/personal";
import selectIcon from "@/components/selectIcon";
import excel from "@/utils/export";
import { mapGetters } from "vuex";

export default {
  components: {
    PersonalAvatar,
    selectIcon,
  },
  props: {},
  data() {
    return {
      graph: "",
      label_graph: "",
      node_width: 250,
      node_padding_width: 20,
      btn_width: 20,
      y: 0,
      width: 300,
      height: 300,
      graph_tree_data: {}, // 节点下的所有tara节点
      all_nodes: [], // 整个文件下的所有节点
      now_node: "",
      loading: false, // tara loading
      riskNumFlag: false, // tara loading
      init_status: false, // antv是否加载完成
      tara_node_id: "",

      show_table: false,
      del_dialog: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      form1: {
        topic: "",
        secret: "",
        complete: "",
        available: "",
      },

      hara_form_2: "",
      hara_form_3: {
        topic: "",
        SEVERITY: "",
        CONTROLLABILITY: "",
        EXPOSURE: "",
        level: "",
      },
      hara_form_4: {
        topic: "",
        ftti: "",
        SAFETY: "",
        level: "",
        assignee: "",
        nodeKeyList: [],
      },

      form2: {
        safety: "",
        property: "",
        function: "",
        privacy: "",
      },
      form2_no_listen: {
        harmScene: "",
        harmLevel: "0",
      },
      form3: "",
      form4: {
        timeConsuming: "",
        professionalKnowledge: "",
        projectKnowledge: "",
        opportunity: "",
        equipment: "",
      },
      form4_no_listen: {
        attackRoute: "",
        attackLevel: "",
      },
      hara_mf_data: [],
      form5: {
        riskNum: "",
        strategy: "",
        measures: "",
        status: "",
        objectives: "",
        requirements: "",
        statement: "",
        assignee: "",
        nodeKeyList: [],
      },
      over1: "",
      over2: "",
      over3: "",
      tara_data: {},
      user_list: [],
      relation_loading: false,
      relation_options: [],

      template_obj: {
        index: 0,
        form1: "",
        form2: "",
        form3: "",
        SEVERITY: "",
        CONTROLLABILITY: "",
        EXPOSURE: "",
        level: "",
        form4: "",
        ftti: "",
        SAFETY: "",
        assignee: "",
        nodeKeyList: [],
      },

      table_header_name: [
        "index",
        "form1",
        "form2",
        "form3",
        "SEVERITY",
        "CONTROLLABILITY",
        "EXPOSURE",
        "level",
        "form4",
        "ftti",
        "SAFETY",
        "assignee",
        "nodeKeyList",
      ],
      tableData: [],
      mergeObj: {},
      params: {},
      value_type: {
        1: "FUNCTIONS",
        2: "MALFUNCTION",
        3: "HAZARD",
        4: "SAFETY_GOAL",
      },

      impact_map_map: {
        ch: {
          0: " ",
          1: "可忽略不计",
          2: "中等水平",
          3: "主要的",
          4: "严重的",
        },
        en: {
          0: " ",
          1: "Negligible",
          2: "Moderate",
          3: "Major",
          4: "Severe",
        },
      },
      timeConsuming_map: {
        ch: {
          0: "少于一周",
          1: "少于一个月",
          4: "少于六个月",
          10: "少于三年",
          19: "多于三年",
        },
        en: {
          0: "Less than a week",
          1: "Less than one month",
          4: "Less than six months",
          10: "Less than three years",
          19: "More than three years",
        },
      },
      professionalKnowledge_map: {
        ch: {
          0: "外行：普通人照着教程一步一步做就能攻击",
          3: "熟手：可以使用常用的攻击工具的人",
          6: "专家：富有经验的安全专家",
          8: "多个专家：一群有经验的安全专家",
        },
        en: {
          0: "Non expert: Ordinary people can attack by following the tutorial step by step.",
          3: "Proficient: someone who can use commonly used attack tools",
          6: "Expert: Experienced security expert",
          8: "Multiple experts: a group of experienced security experts",
        },
      },
      projectKnowledge_map: {
        ch: {
          0: "组件的信息是公开的",
          3: "组件的信息是受限的，比如只在供应商之间传递的内部信息",
          7: "机密信息，只被内部特定团队获取",
          11: "高机密信息：只被内部少部分人获取",
        },
        en: {
          0: "The information of the component is public",
          3: "The information of components is limited, such as internal information that is only transmitted between suppliers",
          7: "Confidential information, only obtained by specific internal teams",
          11: "Highly confidential information: only obtained by a small number of internal personnel",
        },
      },
      opportunity_map: {
        ch: {
          0: "无限制的，可用性，时间窗口，远程控制等方面",
          1: "容易的",
          4: "中等的",
          10: "困难的",
        },
        en: {
          0: "Unrestricted, availability, time window, remote control, etc.",
          1: "Easy",
          4: "Medium",
          10: "Difficult",
        },
      },
      equipment_map: {
        ch: {
          0: "普通设备",
          4: "特殊设备，通过一定方式可以得到",
          7: "定制设备，需要专门定制生产",
          9: "多种定制设备",
        },
        en: {
          0: "Normal device",
          4: "Special equipment can be obtained through certain means",
          7: "Customized equipment requires specialized customized production",
          9: "Multiple customized devices",
        },
      },
      attack_feasibility: {
        ch: {
          A: "高",
          B: "中",
          C: "低",
          D: "很低",
        },
        en: {
          A: "High",
          B: "Medium",
          C: "Low",
          D: "Very low",
        },
      },
      strategy_map: {
        ch: {
          APPROVE: "接受风险",
          LESS: "降低风险",
          AVOID: "规避风险",
          TARNS: "转移风险",
        },
        en: {
          APPROVE: "Acceptance",
          LESS: "Reduction",
          AVOID: "Avoidance",
          TARNS: "Transference",
        },
      },
      status_map: {
        ch: {
          TO_DO: "未开始",
          IN_PROGRESS: "进行中",
          DONE: "已完成",
        },
        en: {
          TO_DO: "TO DO",
          IN_PROGRESS: "IN PROGRESS",
          DONE: "DONE",
        },
      },
      name_map: {},
      export_table_header: {
        en: {
          index: 0,
          form1: "",
          form2: "",
          form3: "",
          SEVERITY: "",
          CONTROLLABILITY: "",
          EXPOSURE: "",
          level: "",
          form4: "",
          ftti: "",
          SAFETY: "",
          assignee: "",
          nodeKeyList: [],
        },
        zh: {
          index: "节点编号",
          form1: "功能",
          form2: "故障",
          form3: "风险",
          SEVERITY: "严重度",
          CONTROLLABILITY: "可控性",
          EXPOSURE: "暴露度",
          level: "功能安全等级",
          form4: "安全目标",
          ftti: "FTTI",
          SAFETY: "安全状态",
          assignee: "责任人",
          nodeKeyList: "关联任务",
        },
      },
      export_table_title: {
        zh: [
          "节点编号",
          "功能",
          "故障",
          "风险",
          "严重度",
          "可控性",
          "暴露度",
          "功能安全等级",
          "安全目标",
          "FTTI",
          "安全状态",
          "责任人",
          "关联任务",
        ],
        en: [
          "Index",
          "Function",
          "Malfunction",
          "Risk",
          "Severity",
          "Controllability",
          "Exposure",
          "Functional security level",
          "Security objectives",
          "FTTI",
          "Security status",
          "Assignee",
          "Associated tasks",
        ],
      },
      all_data: {},

      EXPOSURE_SELECT: {
        zh: [
          { value: "E1", label: "E1-非常低的概率" },
          { value: "E2", label: "E2-低概率" },
          { value: "E3", label: "E3-中等概率" },
          { value: "E4", label: "E4-高概率" },
        ],

        en: [
          { value: "E1", label: "E1-Very low probability" },
          { value: "E2", label: "E2-Low probability" },
          { value: "E3", label: "E3-Medium probability" },
          { value: "E4", label: "E4-High probability" },
        ],
      },

      SEVERITY_SELECT: {
        zh: [
          { value: "S0", label: "S0-无伤害" },
          { value: "S1", label: "S1-轻度和中度伤害" },
          { value: "S2", label: "S2-严重伤害" },
          { value: "S3", label: "S3-致命伤害" },
        ],
        en: [
          { value: "S0", label: "S0-No injuries" },
          { value: "S1", label: "S1-Light and moderate injuries" },
          { value: "S2", label: "S2-Severe injuries" },
          { value: "S3", label: "S3-Life-threatening injuries" },
        ],
      },
      CONTROLLABILITY_SELECT: {
        zh: [
          { value: "C0", label: "C0-完全可控" },
          { value: "C1", label: "C1-简单可控" },
          { value: "C2", label: "C2-一般可控" },
          { value: "C3", label: "C3-很难控制或不可控" },
        ],
        en: [
          { value: "C0", label: "C0-Controllable in general" },
          { value: "C1", label: "C1-Simply controllable" },
          { value: "C2", label: "C2-Normally controllable" },
          { value: "C3", label: "C3-Difficult to control or uncontrollable" },
        ],
      },

      EXPOSURE_MAP: {
        zh: {
          E1: "E1-非常低的概率",
          E2: "E2-低概率",
          E3: "E3-中等概率",
          E4: "E4-高概率",
        },
        en: {
          E1: "E1-Very low probability",
          E2: "E2-Low probability",
          E3: "E3-Medium probability",
          E4: "E4-High probability",
        },
      },

      SEVERITY_MAP: {
        zh: {
          S0: "S0-无伤害",
          S1: "S1-轻度和中度伤害",
          S2: "S2-严重伤害",
          S3: "S3-致命伤害",
        },
        en: {
          S0: "S0-No injuries",
          S1: "S1-Light and moderate injuries",
          S2: "S2-Severe injuries",
          S3: "S3-Life-threatening injuries",
        },
      },

      CONTROLLABILITY_MAP: {
        zh: {
          C0: "C0-完全可控",
          C1: "C1-简单可控",
          C2: "C2-一般可控",
          C3: "C3-很难控制或不可控",
        },
        en: {
          C0: "C0-Controllable in general",
          C1: "C1-Simply controllable",
          C2: "C2-Normally controllable",
          C3: "C3-Difficult to control or uncontrollable",
        },
      },

      level_table: {
        S1: {
          E1: {
            C0: "QM",
            C1: "QM",
            C2: "QM",
            C3: "QM",
          },
          E2: {
            C0: "QM",
            C1: "QM",
            C2: "QM",
            C3: "QM",
          },
          E3: {
            C0: "QM",
            C1: "QM",
            C2: "QM",
            C3: "A",
          },
          E4: {
            C0: "QM",
            C1: "QM",
            C2: "A",
            C3: "B",
          },
        },
        S2: {
          E1: {
            C0: "QM",
            C1: "QM",
            C2: "QM",
            C3: "QM",
          },
          E2: {
            C0: "QM",
            C1: "QM",
            C2: "QM",
            C3: "A",
          },
          E3: {
            C0: "QM",
            C1: "QM",
            C2: "A",
            C3: "B",
          },
          E4: {
            C0: "QM",
            C1: "A",
            C2: "B",
            C3: "C",
          },
        },
        S3: {
          E1: {
            C0: "QM",
            C1: "QM",
            C2: "QM",
            C3: "A",
          },
          E2: {
            C0: "QM",
            C1: "QM",
            C2: "A",
            C3: "B",
          },
          E3: {
            C0: "QM",
            C1: "A",
            C2: "B",
            C3: "C",
          },
          E4: {
            C0: "QM",
            C1: "B",
            C2: "C",
            C3: "D",
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({ all_users: "user_list" }),
    file_key() {
      return this.$route.params.file_key;
    },
    p_id() {
      return this.get_pid();
    },
  },
  watch: {
    form2: {
      deep: true,
      handler() {
        this.form2_no_listen.harmLevel = Math.max(
          this.form2.safety ? this.form2.safety : 0,
          this.form2.property ? this.form2.property : 0,
          this.form2.function ? this.form2.function : 0,
          this.form2.privacy ? this.form2.privacy : 0
        ).toString();
      },
    },
    form4: {
      deep: true,
      handler() {
        if (
          this.form4.timeConsuming &&
          this.form4.professionalKnowledge &&
          this.form4.projectKnowledge &&
          this.form4.opportunity &&
          this.form4.equipment &&
          this.form4.timeConsuming.length > 0 &&
          this.form4.professionalKnowledge.length > 0 &&
          this.form4.projectKnowledge.length > 0 &&
          this.form4.opportunity.length > 0 &&
          this.form4.equipment.length > 0
        ) {
          let res_num =
            Math.max(this.form4.timeConsuming) +
            Math.max(this.form4.professionalKnowledge) +
            Math.max(this.form4.equipment) +
            Math.max(this.form4.opportunity) +
            Math.max(this.form4.projectKnowledge);
          if (res_num < 14) {
            this.form4_no_listen.attackLevel = "A";
          } else if (res_num < 20) {
            this.form4_no_listen.attackLevel = "B";
          } else if (res_num < 25) {
            this.form4_no_listen.attackLevel = "C";
          } else {
            this.form4_no_listen.attackLevel = "D";
          }
        } else {
          this.form4_no_listen.attackLevel = "";
        }
      },
    },
    now_node(n, o) {
      //调接口获取数据
      this.params = {
        nodeKey: n,
        projectId: this.p_id,
      };

      get_node_hara(this.params).then((res) => {
        if (res.toString().length == 0) {
          let data = {
            id: this.generate_id(),
            layer: 1,
            parentId: "",
            data: {
              topic: "",
            },
            analysisType: "FUNCTIONS",
            children: [],
          };
          init_node_hara(this.params, data);
          this.graph_tree_data = data;
          this.graph.clearCells();
          this.init_nodes_edges(this.graph_tree_data);
        } else {
          this.graph_tree_data = res;
          this.graph.clearCells();
          this.init_nodes_edges(this.graph_tree_data);
        }
      });
    },
  },
  mounted() {
    function addLabelAndValue(array) {
      array.forEach((item) => {
        item.label = item.topic;
        item.value = item.topic;

        if (Array.isArray(item.children)) {
          addLabelAndValue(item.children);
          if (item.children.length === 0) {
            delete item.children;
          }
        }
      });

      return array;
    }
    //获取当前文件节点信息
    this.name_map = {};
    getFile(this.p_id, this.file_key).then((res) => {
      this.all_nodes = res.data;
      this.all_nodes.forEach((item) => {
        this.name_map[item.key] = item.topic;
      });
      if (this.$route.query.hara) {
        this.now_node = this.$route.query.node_key
          ? this.$route.query.node_key
          : this.all_nodes[0].key;
      } else {
        this.now_node = this.all_nodes[0].key;
      }
      this.init();
    });
    get_CustomizeOptions(this.p_id, "ATTACK_PATH").then((res) => {
      let arr =
        res && res.options && res.options.children ? res.options.children : [];

      const newData = addLabelAndValue(arr);
      this.hara_mf_data = [
        ...get_hara_data(this.$t("tara.tara_lang")),
        ...newData,
      ];
    });

    get_allProjectMember(this.p_id).then((res) => {
      let my = {};
      let li = [];
      res.forEach((ele) => {
        let obj = {
          label: ele.nickname,
          value: ele.accountId,
          userAccountId: ele.accountId,
          avatar: ele.avatar,
          deleted: ele.deleted,
        };
        ele.accountId == this.$store.getters.userAccountId
          ? (my = obj)
          : li.push(obj);
      });
      this.user_list = [];
      this.user_list.push(my);
      this.user_list.push(...li);
    });
  },
  methods: {
    get_level(data) {
      if (
        data.EXPOSURE.length > 0 &&
        data.CONTROLLABILITY.length > 0 &&
        data.SEVERITY.length > 0
      ) {
        if (data.SEVERITY == "S0" || data.CONTROLLABILITY == "C0") {
          this.hara_form_3.level = "NA";
        } else {
          this.hara_form_3.level =
            this.level_table[data.SEVERITY][data.EXPOSURE][
              data.CONTROLLABILITY
            ];
        }
      } else {
        this.hara_form_3.level = " ";
      }
    },
    return_level(data) {
      if (
        data.EXPOSURE.length > 0 &&
        data.CONTROLLABILITY.length > 0 &&
        data.SEVERITY.length > 0
      ) {
        if (data.SEVERITY == "S0" || data.CONTROLLABILITY == "C0") {
          return "NA";
        } else {
          return this.level_table[data.SEVERITY][data.EXPOSURE][
            data.CONTROLLABILITY
          ];
        }
      } else {
        return " ";
      }
    },
    tableScrollToRow(tableElement, rowIndex) {
      const theTableRows = tableElement.bodyWrapper.querySelectorAll(
        ".el-table__body tbody .el-table__row"
      );
      let scrollTop = 0;
      for (let i = 0; i < theTableRows.length; i++) {
        if (i === rowIndex) {
          break;
        }
        scrollTop += theTableRows[i].offsetHeight;
      }
      tableElement.bodyWrapper.scrollTop = scrollTop;
    },
    tableScrollToColumn(tableElement, columnIndex, fixedNum = 2) {
      const theTableRow = tableElement.bodyWrapper.querySelector(
        ".el-table__body tbody .el-table__row"
      );
      let scrollLeft = 0;
      if (!theTableRow) {
        return;
      }
      for (let i = fixedNum; i < theTableRow.children.length; i++) {
        if (i == columnIndex) {
          break;
        }
        scrollLeft += theTableRow.children[i].offsetWidth;
      }
      tableElement.bodyWrapper.scrollLeft = scrollLeft;
    },
    close_dialog() {
      vmson.$emit("close_hara_dialog");
    },
    generate_id() {
      return "hara" + Date.now() + Date.now().toString(36);
    },

    export_excel() {
      get_all_node({
        projectId: this.p_id,
        fileKey: this.file_key,
      }).then((res) => {
        this.mergeObj = {
          index: [],
          form1: [],
          form2: [],
          form3: [],
          SEVERITY: [],
          CONTROLLABILITY: [],
          EXPOSURE: [],
          level: [],
          form4: [],
          ftti: [],
          SAFETY: [],
          assignee: [],
          nodeKeyList: [],
        };
        this.tableData = [];

        for (let i in res) {
          let l = this.calc_table(
            parseInt(i) + 1,
            res[i].analysisDetail,
            res[i].nodeKey
          );
          this.tableData = [...this.tableData, ...l[0]];
          for (let key in this.mergeObj) {
            this.mergeObj[key] = [...this.mergeObj[key], ...l[1][key]];
          }
        }
        let l = [this.mergeObj, this.tableData];

        let table = l[1];
        let merge_me = l[0];
        let m = [
          // { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } }, row 行 c 列
          // { s: { c: 0, r: 0 }, e: { c: 0, r: 1 } },
          // { s: { c: 1, r: 0 }, e: { c: 1, r: 1 } },
          // { s: { c: 5, r: 0 }, e: { c: 5, r: 1 } },
          // { s: { c: 10, r: 0 }, e: { c: 10, r: 1 } },
          // { s: { c: 11, r: 0 }, e: { c: 11, r: 1 } },
          // { s: { c: 12, r: 0 }, e: { c: 12, r: 1 } },
          // { s: { c: 19, r: 0 }, e: { c: 19, r: 1 } },
          // { s: { c: 20, r: 0 }, e: { c: 20, r: 1 } },
          // { s: { c: 21, r: 0 }, e: { c: 21, r: 1 } },
          // { s: { c: 22, r: 0 }, e: { c: 22, r: 1 } },
          // { s: { c: 23, r: 0 }, e: { c: 23, r: 1 } },
          // { s: { c: 24, r: 0 }, e: { c: 24, r: 1 } },
          // { s: { c: 25, r: 0 }, e: { c: 25, r: 1 } },
          // { s: { c: 26, r: 0 }, e: { c: 26, r: 1 } },
          // { s: { c: 27, r: 0 }, e: { c: 27, r: 1 } },
          // { s: { c: 2, r: 0 }, e: { c: 4, r: 0 } },
          // { s: { c: 6, r: 0 }, e: { c: 9, r: 0 } },
          // { s: { c: 13, r: 0 }, e: { c: 18, r: 0 } },
        ];
        let ind = 0;
        for (let i of this.table_header_name) {
          for (let j in merge_me[i]) {
            if (merge_me[i][j] > 1) {
              m.push({
                s: { r: parseInt(j) + 1, c: ind },
                e: { r: parseInt(j) + parseInt(merge_me[i][j]), c: ind },
              });
            }
          }
          ind++;
          // { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } }, row 行 c 列
        }
        // 将workbook装化成blob对象
        const params = {
          title: this.export_table_title[this.$t("hara.lang")],
          key: this.table_header_name,
          data: [...table], // 数据源
          autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
          filename: this.$t("hara.export") + "-" + this.$route.params.file_key,
          merge: m,
        };
        excel.exportArrayToExcel(params);
      });
    },

    modify_table_relation_n(text) {
      if (typeof text == "object") {
        if (text.length < 1) {
          return "";
        }
        let t = "";
        for (let i of text) {
          t += i;
          t += "\n";
        }
        return t;
      } else {
        let t = "";
        for (let i of text.split("\n")) {
          t += i;
          t += "<br>";
        }
        return t;
      }
    },
    modify_table_relation(text) {
      if (typeof text == "object") {
        if (!text) {
          return "";
        }
        if (text.length < 1) {
          return "";
        }
        let t = "";
        for (let i of text) {
          t += i;
          t += "\n";
        }
        return t;
      } else {
        return text;
      }
    },
    modify_data(key, text) {
      if (key == "attackLevel") {
        return this.attack_feasibility[this.$t("tara.tara_lang")][text];
      }
      if (key == "nodeKeyList") {
        return this.modify_table_relation(text);
      }

      if (key == "assignee") {
        return this.all_users[text] ? this.all_users[text].nickname : "";
      }
      if (key == "timeConsuming") {
        return this.timeConsuming_map[this.$t("tara.tara_lang")][text];
      }

      if (key == "professionalKnowledge") {
        return this.professionalKnowledge_map[this.$t("tara.tara_lang")][text];
      }

      if (key == "projectKnowledge") {
        return this.projectKnowledge_map[this.$t("tara.tara_lang")][text];
      }
      if (key == "opportunity") {
        return this.opportunity_map[this.$t("tara.tara_lang")][text];
      }
      if (key == "equipment") {
        return this.equipment_map[this.$t("tara.tara_lang")][text];
      }

      if (key == "strategy") {
        return this.strategy_map[this.$t("tara.tara_lang")][text];
      }
      if (key == "status") {
        return this.status_map[this.$t("tara.tara_lang")][text];
      }
      if (key == "safety") {
        return this.impact_map_map[this.$t("tara.tara_lang")][text];
      }
      if (key == "property") {
        return this.impact_map_map[this.$t("tara.tara_lang")][text];
      }
      if (key == "function") {
        return this.impact_map_map[this.$t("tara.tara_lang")][text];
      }
      if (key == "privacy") {
        return this.impact_map_map[this.$t("tara.tara_lang")][text];
      }
      if (key == "harmLevel") {
        return this.impact_map_map[this.$t("tara.tara_lang")][text];
      }
      return text;
    },
    modify_table(text) {
      // if (text.toString().indexOf("@") > -1) {
      //   return text.slice(text.indexOf("@") + 1, text.length);
      // }
      return text;
    },
    calc_table(index, taraData, tableData_key) {
      let tableData = [];
      let hang_map = {};

      function calc_row(data) {
        if (data.children && data.children.length > 0) {
          let n = 0;
          for (let i of data.children) {
            n += calc_row(i);
          }
          hang_map[data.id] = n;
          return n;
        } else {
          hang_map[data.id] = 1;
          return 1;
        }
      }
      calc_row(taraData);

      let tem = this.template_obj;
      tem.index = index;

      for (let i = 0; i < hang_map[taraData.id]; i++) {
        tableData.push(JSON.parse(JSON.stringify(tem)));
      }

      let star = {};

      star[taraData.id] = 0;
      let stack = [taraData];
      while (stack.length > 0) {
        let node = stack.shift();
        let num = 0;
        if (node.children && node.children.length > 0) {
          for (let i of node.children) {
            star[i.id] = star[i.parentId] + num;
            num += hang_map[i.id];
            stack.push(i);
          }
        }
      }

      let key_map = {
        1: ["index", "form1"],
        2: ["form2"],
        3: ["form3", "SEVERITY", "CONTROLLABILITY", "EXPOSURE", "level"],
        4: ["form4", "ftti", "SAFETY", "assignee", "nodeKeyList"],
      };
      let mergeObj = {
        index: [],
        form1: [],
        form2: [],
        form3: [],
        SEVERITY: [],
        CONTROLLABILITY: [],
        EXPOSURE: [],
        level: [],
        form4: [],
        ftti: [],
        SAFETY: [],
        assignee: [],
        nodeKeyList: [],
      };
      let ceng = [taraData];
      taraData.parentId = taraData.id;
      for (let layer = 1; layer < 5; layer++) {
        let next_ceng = [];
        for (let i in ceng) {
          for (let k of key_map[layer]) {
            if (mergeObj[k].length != star[ceng[i].id]) {
              for (
                let o = 0;
                o < Math.abs(mergeObj[k].length - star[ceng[i].id]);
                o++
              ) {
                mergeObj[k].push(1);
              }
            }

            mergeObj[k].push(hang_map[ceng[i].id]);
            for (let h = 0; h < hang_map[ceng[i].id] - 1; h++) {
              mergeObj[k].push(0);
            }
          }

          for (
            let j = star[ceng[i].id];
            j < hang_map[ceng[i].id] + star[ceng[i].id];
            j++
          ) {
            for (let key in ceng[i].data) {
              if (key == "topic") {
                tableData[j]["form" + layer] = this.modify_data(
                  "form" + layer,
                  ceng[i].data["topic"]
                );
              } else {
                tableData[j][key] = this.modify_data(key, ceng[i].data[key]);
              }
            }
          }
          next_ceng = [...next_ceng, ...(ceng[i].children || [])];
        }
        if (layer == 1) {
          for (let j in tableData) {
            tableData[j].form1 = this.name_map[tableData_key];
          }
        }
        ceng = next_ceng;
      }

      let n = hang_map[taraData.id];
      for (let key in mergeObj) {
        for (let i = 0; i < Math.abs(mergeObj[key].length - n); i++) {
          mergeObj[key].push(1);
        }
      }
      return [tableData, mergeObj];
    },
    show_table_fun(flag) {
      get_all_node({
        projectId: this.p_id,
        fileKey: this.file_key,
      }).then((res) => {
        this.all_data = res;
        this.mergeObj = {
          index: [],
          form1: [],
          form2: [],
          form3: [],
          SEVERITY: [],
          CONTROLLABILITY: [],
          EXPOSURE: [],
          level: [],
          form4: [],
          ftti: [],
          SAFETY: [],
          assignee: [],
          nodeKeyList: [],
        };
        this.tableData = [];

        for (let i in res) {
          let l = this.calc_table(
            parseInt(i) + 1,
            res[i].analysisDetail,
            res[i].nodeKey
          );
          this.tableData = [...this.tableData, ...l[0]];
          for (let key in this.mergeObj) {
            this.mergeObj[key] = [...this.mergeObj[key], ...l[1][key]];
          }
        }
        if (flag) {
          this.show_table = true;
        } else {
          return [this.mergeObj, this.tableData];
        }
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 判断列的属性
      if (this.table_header_name.indexOf(column.property) !== -1) {
        // 判断其值是不是为0
        if (this.mergeObj[column.property][rowIndex]) {
          return [this.mergeObj[column.property][rowIndex], 1];
        } else {
          // 如果为0则为需要合并的行
          return [0, 0];
        }
      }
    },
    hide_table_fun() {
      this.show_table = false;
      let dom = document.getElementsByClassName("x6-graph-scroller");
      if (dom) {
        for (let i of dom) {
          i.style.height = "calc(70vh - 100px)";
        }
      }
    },
    push_text_form3() {
      this.$refs["popover1"].doClose();
      for (let i of this.over1) {
        this.hara_form_2 += "\n";
        this.hara_form_2 += i;
      }

      this.over1 = [];
    },
    push_text_form4() {
      let flag = this.form4_no_listen.attackRoute.length > 0;
      for (let i of this.over2) {
        if (i != "空") {
          this.form4_no_listen.attackRoute += "\n";
          this.form4_no_listen.attackRoute += i;
        }
      }
      if (!flag) {
        this.form4_no_listen.attackRoute =
          this.form4_no_listen.attackRoute.slice(
            1,
            this.form4_no_listen.attackRoute.length
          );
      }

      this.$refs["popover2"].doClose();
      this.over2 = [];
    },
    push_text_form5() {
      let flag = this.form5.measures.length > 0;

      for (let i of this.over3) {
        if (i != "空") {
          this.form5.measures += "\n";
          this.form5.measures += i;
        }
      }
      // this.form5.measures = this.form5.measures.slice(
      //   0,
      //   this.form5.measures.length - 1
      // );
      if (!flag) {
        this.form5.measures = this.form5.measures.slice(
          1,
          this.form5.measures.length
        );
      }
      this.$refs["popover3"].doClose();
      this.over3 = [];
    },
    close_dialog1() {
      this.change_node(this.form1);
    },
    close_dialog2() {
      let datra = {
        topic: this.hara_form_2,
      };
      this.change_node(datra);
    },
    close_dialog3() {
      this.findChildren(this.tara_node_id, this.hara_form_3.level);
      this.change_node(this.hara_form_3);
    },
    close_dialog4() {
      this.change_node(this.hara_form_4);
    },
    add_node(id) {
      let value_map = this.value_type;
      let data = {
        id: this.generate_id(),
        layer: 0,
        parentId: "",
        children: [],
        data: {},
      };

      function traverse(node) {
        if (node.id === id) {
          data.layer = parseInt(node.layer) + 1;
          data.analysisType = value_map[parseInt(node.layer) + 1];
          data.parentId = node.id;
          node.children.push(data);

          return true;
        }
        if (node.children && node.children.length > 0) {
          for (let child of node.children) {
            if (traverse(child)) {
              return true;
            }
          }
        }

        return false;
      }

      if (traverse(this.graph_tree_data)) {
        if (data.layer == 4) {
          data.data.level = this.findParents(data.parentId);
        }
        init_node_hara(this.params, data).then(() => {
          get_node_hara(this.params).then((res) => {
            this.graph_tree_data = res;
            this.graph.clearCells();
            this.init_nodes_edges(this.graph_tree_data);
          });
        });
      }
    },
    change_node(data) {
      let canshu = {
        projectId: this.params.projectId,
        nodeKey: this.params.nodeKey,
        id: this.tara_node_id,
      };

      chenge_node_hara(canshu, data).then(() => {
        if (this.show_table) {
          this.show_table_fun(true);
        }
        get_node_hara(this.params).then((res) => {
          this.graph_tree_data = res;
          this.graph.clearCells();
          this.init_nodes_edges(this.graph_tree_data);
        });
      });
    },

    change_node_risk_num(data, layer_id) {
      let canshu = {
        projectId: this.params.projectId,
        nodeKey: this.params.nodeKey,
        id: layer_id,
      };

      chenge_node_tara(canshu, data).then(() => {
        if (this.show_table) {
          this.show_table_fun(true);
        }
      });
    },
    del_node(id) {
      this.del_dialog = true;
    },
    confirm_del() {
      let data = {
        projectId: this.params.projectId,
        nodeKey: this.params.nodeKey,
        id: this.tara_node_id,
      };
      del_node_hara(data).then(() => {
        get_node_hara(this.params).then((res) => {
          this.graph_tree_data = res;
          this.graph.clearCells();
          this.init_nodes_edges(this.graph_tree_data);
          this.del_dialog = false;
        });
      });
    },
    show_dialog(id) {
      function traverse(node) {
        // 如果当前节点的id和给定的id相同，就修改它的topic，并返回true
        if (node.id === id) {
          let res = JSON.parse(JSON.stringify(node));
          return res;
        }
        // 如果当前节点有子节点，就遍历它们，如果有一个子节点返回true，就说明找到了匹配的id，也返回true
        if (node.children && node.children.length > 0) {
          for (let child of node.children) {
            let d = traverse(child);
            if (d) {
              return d;
            }
          }
        }
        // 如果没有找到匹配的id，就返回false
        return false;
      }
      let dialog_data = traverse(this.graph_tree_data);
      this.tara_node_id = id;
      if (dialog_data.layer == 1) {
        return false;
      }
      if (dialog_data.layer == 2) {
        let data = JSON.parse(JSON.stringify(dialog_data.data));
        this.hara_form_2 = data.topic ? data.topic : "";
        this.dialog2 = true;
      }
      if (dialog_data.layer == 3) {
        let data = JSON.parse(JSON.stringify(dialog_data.data));

        this.hara_form_3 = {
          topic: data.topic ? data.topic : "",
          SEVERITY: data.SEVERITY ? data.SEVERITY : "",
          CONTROLLABILITY: data.CONTROLLABILITY ? data.CONTROLLABILITY : "",
          EXPOSURE: data.EXPOSURE ? data.EXPOSURE : "",
        };
        this.hara_form_3.level = this.return_level(this.hara_form_3);
        this.dialog3 = true;
      }
      if (dialog_data.layer == 4) {
        let data = JSON.parse(JSON.stringify(dialog_data.data));
        this.hara_form_4 = {
          topic: data.topic ? data.topic : "",
          ftti: data.ftti ? data.ftti : "",
          SAFETY: data.SAFETY ? data.SAFETY : "",
          level: data.level ? data.level : " ",
          assignee: data.assignee ? data.assignee : "",
          nodeKeyList: data.nodeKeyList ? data.nodeKeyList : [],
        };
        this.dialog4 = true;
      }
    },
    findParents(nodeId) {
      // 定义一个数组，用来存储父节点
      let parents = {};
      let dao = "";
      // 定义一个辅助函数，用来递归遍历树
      function traverse(node) {
        // 如果节点的id和目标id相同，说明找到了目标节点
        if (node.id === nodeId) {
          dao = node;
          // 返回true，表示找到了目标节点
          return true;
        }
        // 如果节点有子节点，遍历每个子节点
        if (node.children && node.children.length > 0) {
          for (let i = 0; i < node.children.length; i++) {
            // 如果子节点中有目标节点，说明当前节点是目标节点的父节点
            if (traverse(node.children[i])) {
              // 把当前节点的id加入到父节点数组中
              parents[node.layer.toString()] = node.data;
              // 返回true，表示找到了目标节点
              return true;
            }
          }
        }
        // 如果没有找到目标节点，返回false
        return false;
      }
      // 从根节点开始遍历树
      traverse(this.graph_tree_data);
      return dao.data.level ? dao.data.level : " ";
    },
    findChildren(nodeId, level) {
      let da = false;
      function traverse(node) {
        if (node.id === nodeId) {
          da = node;
        }
        if (node.children && node.children.length > 0) {
          for (let i = 0; i < node.children.length; i++) {
            traverse(node.children[i]);
          }
        }
      }
      traverse(this.graph_tree_data);
      if (da && da.children.length > 0) {
        let obj = da.children[0];
        obj.data.level = level;
        let canshu = {
          projectId: this.params.projectId,
          nodeKey: this.params.nodeKey,
          id: obj.id,
        };
        chenge_node_hara(canshu, obj.data).then(() => {
          if (this.show_table) {
            this.show_table_fun(true);
          }
        });
      }
    },
    headerCellStyle(obj) {
      if (obj.rowIndex == 0) {
        return { background: "#B4D0F0", color: "#000000" };
      } else {
        return { color: "#000000" };
      }
    },
    modify_node_data(obj) {
      if (obj.children && Array.isArray(obj.children)) {
        for (let i in obj.children) {
          obj.children[i] = this.modify_node_data(obj.children[i]);
        }
      }
      // return this.create_node(obj);
      if (parseInt(obj.layer) == 4) {
        return this.create_leaf_node(obj);
      } else if (parseInt(obj.layer) == 1) {
        return this.create_root_node(obj);
      } else {
        return this.create_node(obj);
      }
    },
    modify_node_data_x(obj) {
      obj.x = (obj.data.layer - 1) * 400;
      if (this.y > obj.y) {
        this.y = obj.y;
      }
      if (obj.children && Array.isArray(obj.children)) {
        for (let i of obj.children) {
          this.modify_node_data_x(i);
        }
      }
    },
    init_nodes_edges(data) {
      let use_data = JSON.parse(JSON.stringify(data));
      use_data = this.modify_node_data(use_data);
      this.riskNumFlag = false;
      let result = Hierarchy.mindmap(use_data, {
        direction: "H",
        getHeight(d) {
          return d.height;
        },
        getHGap(d) {
          return 20;
        },
        getVGap() {
          return 20;
        },
        getSide: () => {
          return "right";
        },
      });
      this.y = 0;
      this.modify_node_data_x(result);

      let l = [];
      l.push(result);
      // 循环添加节点
      while (l.length > 0) {
        let n = l.shift();
        let d = n.data;
        d.x = n.x + 10;
        d.y = n.y - this.y + 10;

        this.graph.addNode(d); // 添加节点
        if (n.data.parentId) {
          this.graph.addEdge(this.create_edge(n.data.id, n.data.parentId)); // 添加边
        }
        l = [...l, ...n.children];
      }

      // this.graph.centerContent(); // 视图居中
      // this.graph.zoomToFit({ maxScale: 1 }); // 视图放缩
    },
    init() {
      this.label_graph = new Graph({
        container: document.getElementById("label_container"),
        scroller: true,
        interacting: false,
        autoResize: true,
      });
      let label_list = [
        this.$t("hara.label1"),
        this.$t("hara.label2"),
        this.$t("hara.label3"),
        this.$t("hara.label4"),
      ];
      for (let i in label_list) {
        // 0 1 2 3 4
        let label_width = this.calculate_node_width_height(label_list[i]);
        this.label_graph.addNode({
          x: 400 * i + 10,
          y: 5,
          width: label_width + 50,
          height: 40,
          id: "label" + i,
          shape: "html",
          html() {
            const con = document.createElement("div"); //
            const node = document.createElement("div"); // 节点

            con.style.display = "flex";
            con.style.alignItems = "center";

            node.id = "label" + i;
            node.classList = ["tara_label_buling"];

            node.style.width = label_width + 50 + "px";
            node.style.height = "40px";
            node.style.background = "rgb(245,245,245)";
            node.style.display = "flex";
            node.style.justifyContent = "center";
            node.style.alignItems = "center";
            node.style.border = "1px solid rgb(245,245,245)";
            node.style.borderRadius = "25px";

            node.style.padding = "5px 10px";
            node.style.wordBreak = "break-all";
            node.style.font = "14px Arial";
            node.style.color = "black";
            node.style.lineHeight = "25px";
            node.style.cursor = "pointer";
            node.innerText = label_list[i];

            con.appendChild(node);
            return con;
          },
        });
      }
      this.graph = new Graph({
        // 具体参数见官方文档 https://x6.antv.vision/zh/docs/api/graph/graph
        container: document.getElementById("container"),
        scroller: true,
        interacting: false,
        autoResize: true,
        mousewheel: {
          enabled: true,
          modifiers: ["ctrl", "meta"],
        },
        // panning: {
        //   enabled: true,
        //   modifiers: ["ctrl", "meta"],
        // },
        // panning: true,
      });

      this.init_label();

      this.graph.on("scale", ({ sx, sy, ox, oy }) => {
        this.label_graph.zoomTo(sx);
      });
      // 点击节点事件
      this.label_graph.on("cell:click", ({ e, x, y, node, view }) => {
        if (!this.show_table) {
          return;
        }
        if (view.cell.id == "label0" && this.show_table) {
          this.tableScrollToColumn(this.$refs.tara_table_graph, 0, 0);
        }
        if (view.cell.id == "label1" && this.show_table) {
          this.tableScrollToColumn(this.$refs.tara_table_graph, 2, 2);
        }
        if (view.cell.id == "label2" && this.show_table) {
          this.tableScrollToColumn(this.$refs.tara_table_graph, 3, 2);
        }
        if (view.cell.id == "label3" && this.show_table) {
          this.tableScrollToColumn(this.$refs.tara_table_graph, 8, 2);
        }
      });

      setTimeout(() => {
        // document.getElementById("label_container2").onmousewheel = function (
        //   event
        // ) {
        //   if (event.shiftKey) {
        //     for (let i = 0; i < 100; i++)
        //       document.getElementById("label_container1").scrollLeft +=
        //         event.deltaY / 100;
        //     // let toScroll = event.deltaY < 0 ? -150 : 150; // 根据滚动方向设置滚动量
        //     // smoothScroll(document.getElementById("label_container1"), toScroll);
        //   }
        //   // 处理滚轮事件的逻辑
        // };
        let le = document.querySelectorAll(".x6-graph-scroller")[1].scrollLeft;

        let top_position =
          document.querySelectorAll(".x6-graph-scroller")[0].scrollLeft;
        let bottom_position =
          document.querySelectorAll(".x6-graph-scroller")[1].scrollLeft;

        document.querySelectorAll(".x6-graph-scroller")[0].style.overflow =
          "hidden";

        document
          .querySelectorAll(".x6-graph-scroller")[1]
          .addEventListener("scroll", function (e) {
            // 当页面发生滚动时触发该函数
            document.querySelectorAll(".x6-graph-scroller")[0].scrollLeft =
              top_position +
              document.querySelectorAll(".x6-graph-scroller")[1].scrollLeft -
              le;
          });
      }, 1000);
      // 点击节点事件
      this.graph.on("cell:click", ({ e, x, y, node, view }) => {
        let target = e.target.id.split("@");
        let obj_id = view.cell.id;
        if (target[0] == "add_btn") {
          this.add_node(obj_id);
        } else if (target[0] == "del_btn") {
          this.tara_node_id = obj_id;
          this.del_node(obj_id);
        } else if (target[0] == "node") {
          this.tara_node_id = obj_id;
          this.show_dialog(this.tara_node_id);
        }
      });

      // hover节点事件
      this.graph.on("node:mouseenter", ({ e, x, y, node, view }) => {
        let obj_id = view.cell.id;

        if (obj_id.slice(0, -1) == "label") {
          return false;
        }
        if (document.getElementById("del_btn@" + obj_id)) {
          document.getElementById("del_btn@" + obj_id).style.display = "block";
        }

        if (
          !(
            view.cell.store.data.layer.toString() == "3" &&
            view.cell.store.data.children.length == 1
          )
        ) {
          if (document.getElementById("add_btn@" + obj_id)) {
            document.getElementById("add_btn@" + obj_id).style.display =
              "block";
          }
        }
      });

      // hover节点事件
      this.graph.on("cell:mouseleave", ({ e, x, y, node, view }) => {
        let obj_id = view.cell.id;
        if (document.getElementById("del_btn@" + obj_id)) {
          document.getElementById("del_btn@" + obj_id).style.display = "none";
        }
        if (document.getElementById("add_btn@" + obj_id)) {
          document.getElementById("add_btn@" + obj_id).style.display = "none";
        }
      });

      this.init_status = true;
    },
    calculate_node_width_height(text) {
      // 根据文本，计算每个节点的宽
      const canvas = document.getElementById("myCanvas");
      const ctx = canvas.getContext("2d");
      ctx.font = "18px Arial";
      ctx.fillText(text, 0, 0);
      const width = ctx.measureText(text).width;

      return width;
    },
    new_calculate_node_width_height(text) {
      // 根据文本，计算每个节点的高
      const div = document.getElementById("myDiv");
      div.innerText = text;
      return div.clientHeight;
    },
    create_edge(source_node, target_node) {
      let data = {
        source: source_node, // 起始节点 id
        target: target_node, // 目标节点 id
        markup: [
          {
            tagName: "path",
            selector: "stroke",
          },
        ],
        attrs: {
          stroke: {
            fill: "none",
            stroke: "#8f8f8f",
            connection: true,
            strokeWidth: 2,
            strokeLinecap: "round",
          },
        },

        router: {
          name: "er",
          args: {
            direction: "R",
            offser: "center",
          },
        },
        connector: "rounded",
      };
      return data;
    },
    create_node(tree_node) {
      tree_node.topic = tree_node.data.topic;
      if (!tree_node.topic) {
        tree_node.topic = "New node";
      }
      let that = this;

      let width = this.calculate_node_width_height(tree_node.topic);

      let node_height =
        10 + this.new_calculate_node_width_height(tree_node.topic);
      let node_width =
        (width > this.node_width ? this.node_width : width) +
        this.node_padding_width +
        2;
      let data = {
        width: node_width,
        height: node_height,
        shape: "html",
        id: tree_node.id,
        layer: tree_node.layer,
        parentId: tree_node.parentId,
        data: tree_node.data ? tree_node.data : {},
        children: tree_node.children,

        html() {
          const con = document.createElement("div"); //
          const node = document.createElement("div"); // 节点
          const add_btn = document.createElement("div"); //
          const del_btn = document.createElement("div"); //
          const add_btn_i = document.createElement("div"); //
          const del_btn_i = document.createElement("div"); //

          con.style.display = "flex";
          con.style.alignItems = "center";

          node.id = "node@" + tree_node.id;
          node.style.minWidth = node_width + "px";
          node.style.height = node_height + "px";
          node.style.background = "#428bca";
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.alignItems = "center";
          node.style.border = "1px solid #357ebd";
          node.style.borderRadius = "5px";
          node.style.padding = "5px 10px";
          node.style.wordBreak = "break-all";
          node.style.font = "18px Arial";
          node.style.color = "white";
          node.style.lineHeight = "25px";
          node.style.cursor = "pointer";
          node.innerText = tree_node.topic;

          add_btn.style.minWidth = that.btn_width + "px";
          add_btn.style.cursor = "pointer";
          add_btn.id = "add_btn@" + tree_node.id;
          add_btn.style.height = node_height + "px";
          add_btn.style.color = "white";
          add_btn.style.font = "26px Arial";
          // btn.style.visibility = "hidden";
          add_btn.style.display = "none";
          add_btn.style.backgroundColor = "white";

          add_btn_i.innerText = "+";
          add_btn_i.style.color = "white";
          add_btn_i.style.border = "1px solid rgb(66,139,202)";
          add_btn_i.style.width = that.btn_width + "px";
          add_btn_i.style.height = that.btn_width + "px";
          add_btn_i.style.borderRadius = "50%";
          add_btn_i.style.display = "flex";
          add_btn_i.style.alignItems = "center";
          add_btn_i.style.justifyContent = "center";
          add_btn_i.style.backgroundColor = "rgb(66,139,202)";
          add_btn_i.id = "add_btn@";

          del_btn.style.minWidth = that.btn_width + "px";
          del_btn.style.cursor = "pointer";
          del_btn.id = "del_btn@" + tree_node.id;
          del_btn.style.height = node_height + "px";
          del_btn.style.color = "white";
          del_btn.style.font = "26px Arial";
          // del_btn.style.visibility = "hidden";
          del_btn.style.display = "none";
          del_btn.style.marginLeft = "-20px";
          del_btn.style.backgroundColor = "white";

          del_btn_i.innerText = "-";
          del_btn_i.style.color = "white";
          del_btn_i.style.border = "1px solid #F56C6C";
          del_btn_i.style.width = that.btn_width + "px";
          del_btn_i.style.height = that.btn_width + "px";
          del_btn_i.style.borderRadius = "50%";
          del_btn_i.style.display = "flex";
          del_btn_i.style.alignItems = "center";
          del_btn_i.style.justifyContent = "center";
          del_btn_i.style.backgroundColor = "#F56C6C";
          del_btn_i.id = "del_btn@";

          add_btn.appendChild(add_btn_i);
          del_btn.appendChild(del_btn_i);
          con.appendChild(del_btn);
          con.appendChild(node);
          con.appendChild(add_btn);
          return con;
        },
      };
      return data;
    },
    create_leaf_node(tree_node) {
      tree_node.topic = tree_node.data.topic;
      if (!tree_node.topic) {
        tree_node.topic = "New node";
      }
      let that = this;

      let width = this.calculate_node_width_height(tree_node.topic);

      let node_height =
        10 + this.new_calculate_node_width_height(tree_node.topic);
      let node_width =
        (width > this.node_width ? this.node_width : width) +
        this.node_padding_width +
        2;
      let data = {
        width: node_width,
        height: node_height,
        shape: "html",
        id: tree_node.id,
        layer: tree_node.layer,
        parentId: tree_node.parentId,
        data: tree_node.data ? tree_node.data : {},
        children: tree_node.children,

        html() {
          const con = document.createElement("div"); //
          const node = document.createElement("div"); // 节点
          const del_btn = document.createElement("div"); //
          const del_btn_i = document.createElement("div"); //

          con.style.display = "flex";
          con.style.alignItems = "center";

          node.id = "node@" + tree_node.id;
          node.style.minWidth = node_width + "px";
          node.style.height = node_height + "px";
          node.style.background = "#428bca";
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.alignItems = "center";
          node.style.border = "1px solid #357ebd";
          node.style.borderRadius = "5px";
          node.style.padding = "5px 10px";
          node.style.wordBreak = "break-all";
          node.style.font = "18px Arial";
          node.style.color = "white";
          node.style.lineHeight = "25px";
          node.style.cursor = "pointer";
          node.innerText = tree_node.topic;

          del_btn.style.minWidth = that.btn_width + "px";
          del_btn.style.cursor = "pointer";
          del_btn.id = "del_btn@" + tree_node.id;
          del_btn.style.height = node_height + "px";
          del_btn.style.color = "white";
          del_btn.style.font = "26px Arial";
          // del_btn.style.visibility = "hidden";
          del_btn.style.display = "none";
          del_btn.style.marginLeft = "-20px";
          del_btn.style.backgroundColor = "white";

          del_btn_i.innerText = "-";
          del_btn_i.style.color = "white";
          del_btn_i.style.border = "1px solid #F56C6C";
          del_btn_i.style.width = that.btn_width + "px";
          del_btn_i.style.height = that.btn_width + "px";
          del_btn_i.style.borderRadius = "50%";
          del_btn_i.style.display = "flex";
          del_btn_i.style.alignItems = "center";
          del_btn_i.style.justifyContent = "center";
          del_btn_i.style.backgroundColor = "#F56C6C";
          del_btn_i.id = "del_btn@";

          del_btn.appendChild(del_btn_i);
          con.appendChild(del_btn);
          con.appendChild(node);
          return con;
        },
      };
      return data;
    },
    create_root_node(tree_node) {
      tree_node.topic = this.name_map[this.now_node];
      let that = this;
      let width = this.calculate_node_width_height(tree_node.topic);

      let node_height =
        10 + this.new_calculate_node_width_height(tree_node.topic);
      let node_width =
        (width > this.node_width ? this.node_width : width) +
        this.node_padding_width +
        2;

      let data = {
        width: node_width,
        height: node_height,
        shape: "html",
        id: tree_node.id,
        layer: tree_node.layer,
        parentId: tree_node.parentId,
        data: tree_node.data ? tree_node.data : {},
        children: tree_node.children,

        html() {
          const con = document.createElement("div"); //
          const node = document.createElement("div"); // 节点
          const add_btn = document.createElement("div"); //
          const add_btn_i = document.createElement("div"); //

          con.style.display = "flex";
          con.style.alignItems = "center";

          node.id = "node@" + tree_node.id;
          node.style.minWidth = node_width + "px";
          node.style.height = node_height + "px";
          node.style.background = "#428bca";
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.alignItems = "center";
          node.style.border = "1px solid #357ebd";
          node.style.borderRadius = "5px";
          node.style.padding = "5px 10px";
          node.style.wordBreak = "break-all";
          node.style.font = "18px Arial";
          node.style.color = "white";
          node.style.lineHeight = "25px";
          node.style.cursor = "pointer";
          node.innerText =
            tree_node.topic.length > 0 ? tree_node.topic : "new node";

          add_btn.style.minWidth = that.btn_width + "px";
          add_btn.style.cursor = "pointer";
          add_btn.id = "add_btn@" + tree_node.id;
          add_btn.style.height = node_height + "px";
          add_btn.style.color = "white";
          add_btn.style.font = "26px Arial";
          // btn.style.visibility = "hidden";
          add_btn.style.display = "none";
          add_btn.style.backgroundColor = "white";

          add_btn_i.innerText = "+";
          add_btn_i.style.color = "white";
          add_btn_i.style.border = "1px solid rgb(66,139,202)";
          add_btn_i.style.width = that.btn_width + "px";
          add_btn_i.style.height = that.btn_width + "px";
          add_btn_i.style.borderRadius = "50%";
          add_btn_i.style.display = "flex";
          add_btn_i.style.alignItems = "center";
          add_btn_i.style.justifyContent = "center";
          add_btn_i.style.backgroundColor = "rgb(66,139,202)";
          add_btn_i.id = "add_btn@";

          add_btn.appendChild(add_btn_i);
          con.appendChild(node);
          con.appendChild(add_btn);
          return con;
        },
      };
      return data;
    },
    init_label() {
      if (true) {
        return;
      }
      let label_list = [
        this.$t("tara.t55"),
        this.$t("tara.t56"),
        this.$t("tara.t57"),
        this.$t("tara.t58"),
        this.$t("tara.t59"),
      ];
      for (let i in label_list) {
        // 0 1 2 3 4
        let label_width = this.calculate_node_width_height(label_list[i]);
        let d = this.graph.addNode({
          shape: "rect",
          x: 400 * i + 10,
          y: 50,
          width: label_width,
          height: 40,
          label: label_list[i],
          id: "label" + i,
          markup: [
            {
              tagName: "text",
              selector: "label",
            },
          ],
          attrs: {
            label: {
              fontSize: 18,
              textAnchor: "middle",
              textVerticalAnchor: "middle",
              fontFamily: "Arial, helvetica, sans-serif",
            },
          },
        });
      }
    },
    cut(str) {
      if (str.length > 15) {
        return str.slice(0, 14) + "...";
      } else {
        return str;
      }
    },
    get_unrelation_node(query) {
      this.relation_options = [];
      if (query !== "") {
        this.relation_loading = true;
        let related_nodes = this.form5.nodeKeyList.map((ele) => {
          return ele.nodeKey;
        });
        searchNodesByFuzzyKey(query)
          .then((res) => {
            this.relation_options = res.filter((ele) => {
              if (related_nodes.indexOf(ele.nodeKey) == -1) {
                return ele;
              }
            });
            this.relation_loading = false;
          })
          .catch((err) => console.log(err));
      } else {
        this.relation_options = [];
      }
    },
    goKnowledge() {
      const nowLang = localStorage.getItem("lang") || "zh";
      if (nowLang == "zh") {
        window.open(
          "https://ms.ytdevops.com/pubKnowledge/1db7562f-6f55-4d30-907c-390163fe43b8?elementId=01HAP7XWPX4TES423C3RVZGXD6",
          "_blank"
        );
      } else if (nowLang == "en") {
        window.open(
          "https://ms.ytdevops.com/pubKnowledge/c53738e5-f7bb-4b39-a194-843f86659a96?elementId=01HSFYYRQJTB352QFBNAS5C5TB",
          "_blank"
        );
      }
    },
  },
};
</script>
<style lang="scss">
.tara_table_class_topic.tara_table_class_cell {
  vertical-align: top !important;
}
// .tara_table_class_topic_header {
//   vertical-align: center !important;
// }
// .tara_table_class_topic {
//   th:not(:first-child) {
//     vertical-align: top !important;
//   }
//   /* 样式设置 */
// }
.tara_jilian {
  border: none !important;
  .el-cascader-node__label {
    word-break: break-word !important;
  }
}
.tara_jilian——long {
  height: 350px;
  .el-cascader-menu__wrap {
    height: 350px;
  }
}
.el-table .cell {
  word-break: break-word !important;
}
.tara_table_class_cell {
  .cell {
    word-break: break-word !important;
  }
  word-break: break-word !important;
}
.tara_drawer {
  pointer-events: none !important;
  .el-drawer {
    pointer-events: auto !important;
  }
}
.tara_relation_select {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 10px !important;
  }
}
.tara_user_select {
  .el-select-dropdown__item {
    display: flex;
    align-items: center;
    .avatar {
      display: flex;
    }
    .select_item {
      margin-left: 14px;
    }
  }
}
#tara_cascader_panel {
  .el-cascader-node__label {
    white-space: normal;
    overflow: auto;
    text-overflow: ellipsis;
    word-break: break-all;
    text-align: start;
  }
  .el-cascader-node {
    width: 15vw;
    height: auto;
  }
}
</style>
<style lang="scss" scoped>
.bottom-resize {
  cursor: n-resize;
  width: 100%;
  height: 6px;
  font-size: 32px;
  user-select: none;
  position: absolute;
  z-index: 1;
}
.bottom-resize:hover {
  background-color: #0090f1;
}
.bottom-resize.acitve {
  background-color: #0090f1;
}
.tara_form1 {
  .radio {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    font-size: 16px;
  }
}
.tara_form2 {
  .select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    font-size: 16px;
    span {
      width: 100px;
      text-align: start;
    }
  }
}
.tara_form4 {
  .select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    font-size: 16px;
    span {
      width: 200px;
      text-align: start;
    }
  }
}
// .tara_table_class {
//   .cell {
//     word-break: break-word !important;
//   }
// }
.el-table .cell {
  word-wrap: break-word !important;
}
.tara_table_class_cell {
  word-wrap: break-word !important;
}
</style>
<style scoped>
.tara_label_buling :hover {
  color: blue;
}
::v-deep .el-table tbody tr:hover > td {
  background-color: transparent !important;
}
#myDiv {
  font: 18px Arial; /*no*/
  max-width: 250px; /*no*/
  line-height: 25px; /*no*/
  position: absolute;
  visibility: hidden;
  word-break: break-all;
}
.myDiv {
  font: 18px Arial; /*no*/
  max-width: 250px; /*no*/
  line-height: 25px; /*no*/
  word-break: break-all;
}
.fta_res {
  background-color: rgb(236, 236, 236);
  margin-right: 25px;
}
#container {
  width: 100%;
  /* height: 100%; */
}
.container {
  width: 100%;
}
</style>
