import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'

// 节点存档接口
export function saveArchive(params) {
    return request({
        url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/nodes/archive`,
        method: 'post',
        data: params.data
    })
}

// 节点存档List
export function getArchive(params) {
    return request({
        url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/nodes/${params.nodeKey}/archive`,
        method: 'get',
    })
}
// 节点存档详细信息
export function getArchiveDetail(params) {
    return request({
        url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/nodes/${params.nodeKey}/archive/${params.archiveId}`,
        method: 'get',
    })
}
// 获取两个节点存档详细信息
export function getTwoArchiveDetail(params) {
    return request({
        url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/nodes/${params.nodeKey}/archive/compare?sourceArchiveId=${params.sourceArchiveId}&targetArchiveId=${params.targetArchiveId}`,
        method: 'get',
    })
}
// 版本回退
export function rollbackVersion(params) {
    return request({
        url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/nodes/${params.nodeKey}/rollback?archiveId=${params.archiveId}`,
        method: 'post',
    })
}
// 文件存档查看接口
export function getFileArchive(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/files/${params.fileKey}/archive`,
        method: 'get',
    })
}
// 文件存档接口 
export function fileArchive(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/files/${params.fileKey}/archive`,
        method: 'post',
        data: params.data
    })
}