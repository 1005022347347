<template>
  <div style="position: relative">
    <div class="menu">
      <div
        class="item pingshenmenu"
        :class="topClass == 0 ? 'bottom-border' : ''"
        @click="changeMode('review')"
      >
        {{ $t("changeRequest.needReview") }}
      </div>
      <div
        class="item pingshenmenu"
        :class="topClass == 1 ? 'bottom-border' : ''"
        @click="changeMode('confirm')"
      >
        {{ $t("changeRequest.needConfirm") }}
      </div>
      <div
        class="item pingshenmenu"
        :class="topClass == 2 ? 'bottom-border' : ''"
        @click="changeMode('issue')"
      >
        {{ $t("changeRequest.myIssue") }}
      </div>
      <div
        class="item pingshenmenu"
        :class="topClass == 3 ? 'bottom-border' : ''"
        @click="changeMode('all')"
      >
        {{ $t("changeRequest.allChange") }}
      </div>
      <i
        class="iconfont"
        :class="is_show_chart ? 'icon-chart_on' : 'icon-chart hover-icon'"
        style="
          position: relative;
          right: 0.082rem;
          color: rgb(48, 100, 143);
          top: 6px;
          font-size: 16px;
          cursor: pointer;
          height: fit-content;
        "
        @click="show_chart"
      >
      </i>
    </div>
    <!-- 待我审核 -->
    <el-table
      v-show="(mode === 'review') & !is_show_chart"
      v-loading="isLoading"
      height="80vh"
      :data="currentReviewData"
      style="width: 100%; background: white"
      :header-cell-style="{
        color: '#383838',
      }"
      :cell-style="changeCellStyle"
      @sort-change="hadleChangeRowreview"
    >
      <el-table-column
        prop="name"
        v-if="mode === 'review'"
        :label="$t('changeRequest.title')"
        :min-width="480"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            style="cursor: pointer"
            @click="jumpToRequestDetail(scope.row)"
            >{{ scope.row.name }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="createdBy"
        v-if="mode === 'review'"
        :label="$t('addMyColla.table.title3')"
        :min-width="280"
      >
        <template slot-scope="scope">
          <span style="display: flex; align-items: center; gap: 10px">
            <personal-avatar
              :placement="'right'"
              :size="33"
              :avatar="
                (getUser(scope.row.createdBy) &&
                  getUser(scope.row.createdBy).avatar) ||
                ''
              "
              :nickname="
                (getUser(scope.row.createdBy) &&
                  getUser(scope.row.createdBy).nickname) ||
                ''
              "
              :colorid="scope.row.createdBy"
              style="justify-content: left"
            ></personal-avatar>
            <span>{{ getUser(scope.row.createdBy).nickname }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createdBy"
        v-if="mode === 'review'"
        :label="$t('addMyColla.table.title13')"
        :min-width="280"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.createdAt }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="mode === 'review'"
      style="margin-top: 10px"
      :current-page="reviewingPageInfo.currentPage + 1"
      background
      :page-sizes="[10, 20, 50]"
      :page-size="reviewingPageInfo.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="reviewingPageInfo.total"
      @current-change="changeReviewingPage"
    >
    </el-pagination>
    <!-- 待我确认 -->
    <el-table
      v-show="(mode === 'confirm') & !is_show_chart"
      v-loading="isLoading"
      :data="confirmData"
      height="80vh"
      style="width: 100%; background: white"
      :header-cell-style="{
        color: '#383838',
      }"
      :cell-style="changeCellStyle"
      @sort-change="hadleChangeRowconfirm"
    >
      <el-table-column
        prop="name"
        v-if="mode === 'confirm'"
        :label="$t('changeRequest.title')"
        :min-width="480"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            style="cursor: pointer"
            @click="jumpToRequestDetail(scope.row)"
            >{{ scope.row.name }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="createdBy"
        v-if="mode === 'confirm'"
        :label="$t('addMyColla.table.title3')"
        :min-width="280"
      >
        <template slot-scope="scope">
          <span style="display: flex; align-items: center; gap: 10px">
            <personal-avatar
              :placement="'right'"
              :size="33"
              :avatar="
                (getUser(scope.row.createdBy) &&
                  getUser(scope.row.createdBy).avatar) ||
                ''
              "
              :nickname="
                (getUser(scope.row.createdBy) &&
                  getUser(scope.row.createdBy).nickname) ||
                ''
              "
              :colorid="scope.row.createdBy"
              style="justify-content: left"
            ></personal-avatar>
            <span>{{ getUser(scope.row.createdBy).nickname }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createdBy"
        v-if="mode === 'confirm'"
        :label="$t('addMyColla.table.title13')"
        sortable="custom"
        :min-width="280"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.createdAt }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="(mode === 'confirm') & !is_show_chart"
      style="margin-top: 10px"
      :current-page="confirmPageInfo.currentPage + 1"
      background
      :page-sizes="[10, 20, 50]"
      :page-size="confirmPageInfo.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="confirmPageInfo.total"
      @current-change="changeConfirmPage"
    >
    </el-pagination>
    <!-- 我发起的 -->
    <el-table
      v-show="(mode === 'issue') & !is_show_chart"
      v-loading="isLoading"
      :data="issueData"
      height="80vh"
      style="width: 100%; background: white"
      :header-cell-style="{
        color: '#383838',
      }"
      :cell-style="changeCellStyle"
      @sort-change="hadleChangeRowself"
    >
      <el-table-column
        prop="name"
        v-if="mode === 'issue'"
        :label="$t('changeRequest.title')"
        :min-width="280"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            style="cursor: pointer"
            @click="jumpToRequestDetail(scope.row)"
            >{{ scope.row.name }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        v-if="mode === 'issue'"
        prop="status"
        :label="$t('addMyColla.table.title2')"
        :min-width="200"
      >
        <template slot-scope="scope">
          <span
            class="pingshen-status-tag"
            :style="{
              borderStyle: 'solid',
            }"
            :class="{
              status_review: scope.row.status == 'REVIEWING',
              status_merge: scope.row.status == 'MERGED',
              status_approve: scope.row.status == 'APPROVED',
              status_refuse: scope.row.status == 'REFUSED',
            }"
          >
            {{ matchStatusToName(scope.row.status) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createdBy"
        v-if="mode === 'issue'"
        :label="$t('addMyColla.table.title3')"
        :min-width="200"
      >
        <template slot-scope="scope">
          <span style="display: flex; align-items: center; gap: 10px">
            <personal-avatar
              :placement="'right'"
              :size="33"
              :avatar="
                (getUser(scope.row.createdBy) &&
                  getUser(scope.row.createdBy).avatar) ||
                ''
              "
              :nickname="
                (getUser(scope.row.createdBy) &&
                  getUser(scope.row.createdBy).nickname) ||
                ''
              "
              :colorid="scope.row.createdBy"
              style="justify-content: left"
            ></personal-avatar>
            <span>{{ getUser(scope.row.createdBy).nickname }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createdBy"
        v-if="mode === 'issue'"
        :label="$t('addMyColla.table.title13')"
        sortable="custom"
        :min-width="280"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.createdAt }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="owner"
        v-if="mode === 'issue'"
        :label="$t('addMyColla.table.title5')"
        :min-width="250"
        align="center"
      >
        <template slot-scope="scope">
          <el-tooltip
            :disabled="
              scope.row.reviewerList.length ===
              scope.row.reviewerList.length -
                scope.row.unfinishedReviewerList.length
            "
            effect="dark"
            placement="top"
          >
            <div slot="content">
              <span>{{
                $t("addMyColla.table.title6") +
                getNickNames(scope.row.unfinishedReviewerList)
              }}</span>
            </div>
            <span style="cursor: pointer" @click="openPinShenDialog(scope.row)">
              <span style="color: green">{{
                scope.row.reviewerList.length -
                scope.row.unfinishedReviewerList.length
              }}</span>
              <span style="margin: 0 4px">/</span>
              <span>{{ scope.row.reviewerList.length }}</span>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        :label="$t('DFMEA.More')"
        v-if="mode === 'issue'"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-dropdown>
            <span class="el-dropdown-link" style="color: #84aace">
              <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
              &nbsp;&nbsp;
              <i
                class="iconfont icon-a-216gengduo-shuxiang"
                style="color: rgb(48, 100, 143)"
              />
              &nbsp;&nbsp;
            </span>
            <el-dropdown-menu slot="dropdown" style="width: 210px">
              <div>
                <div
                  :class="{
                    noagain: scope.row.status !== 'REVIEWING',
                    operate_button: scope.row.status == 'REVIEWING',
                  }"
                  @click="sendAgain(scope.row)"
                >
                  <i style="margin-right: 10px">
                    <i class="iconfont icon-fasong" />
                  </i>
                  <span>{{ $t("DFMEA.Reminder") }}</span>
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="(mode === 'issue') & !is_show_chart"
      style="margin-top: 10px"
      :current-page="sendingPageInfo.currentPage + 1"
      background
      :page-sizes="[10, 20, 50]"
      :page-size="sendingPageInfo.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="sendingPageInfo.total"
      @current-change="changeSendingPage"
    >
    </el-pagination>
    <el-dialog
      :title="$t('addNodeDetail.res')"
      :visible.sync="pingshenDialog"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div style="height: 600px; overflow: scroll; margin-bottom: 20px">
        <el-table
          :data="pingshenData"
          v-loading="isDialogLoading"
          style="width: 100%; background: white"
          :header-cell-style="{
            color: '#383838',
          }"
          :cell-style="changeCellStyle"
          width="600"
        >
          <el-table-column prop="createdBy" :label="$t('addNodeDetail.people')">
            <template slot-scope="scope">
              <span style="display: flex; align-items: center; gap: 10px">
                <personal-avatar
                  :placement="'right'"
                  :size="33"
                  :avatar="
                    (getUser(scope.row.createdBy) &&
                      getUser(scope.row.createdBy).avatar) ||
                    ''
                  "
                  :nickname="
                    (getUser(scope.row.createdBy) &&
                      getUser(scope.row.createdBy).nickname) ||
                    ''
                  "
                  :colorid="scope.row.createdBy"
                  style="justify-content: left"
                ></personal-avatar>
                <span>{{ getUser(scope.row.createdBy).nickname }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="approved" :label="$t('addNodeDetail.res')">
            <template slot-scope="scope">
              <span
                class="pingshen-status-tag"
                :style="{
                  borderStyle: 'solid',
                }"
                :class="{
                  status_approve: scope.row.approved,
                  status_refuse: !scope.row.approved,
                }"
              >
                {{
                  scope.row.approved
                    ? $t("changeRequest.approve")
                    : $t("changeRequest.refuse")
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="message" :label="$t('addNodeDetail.comment')">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 全部变更 -->
    <el-table
      v-show="mode === 'all'"
      v-loading="isLoading"
      height="80vh"
      :data="allData"
      style="width: 100%; background: white"
      :header-cell-style="{
        color: '#383838',
      }"
      :cell-style="changeCellStyle"
      @sort-change="hadleChangeRowall"
    >
      <el-table-column
        v-if="mode === 'all'"
        prop="nodeKey"
        :label="$t('changeRequest.id')"
        :min-width="150"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            style="cursor: pointer; color: rgb(255, 195, 0); font-weight: 700"
            @click="rowClicked(scope.row)"
            >{{ scope.row.nodeKey }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        v-if="mode === 'all'"
        prop="name"
        :label="$t('changeRequest.title')"
        :min-width="280"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span style="cursor: pointer" @click="openRequestDialog(scope.row)">{{
            scope.row.name
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="mode === 'all'"
        prop="status"
        :label="$t('addMyColla.table.title2')"
        :min-width="200"
      >
        <template slot-scope="scope">
          <span
            class="pingshen-status-tag"
            :style="{
              borderStyle: 'solid',
            }"
            :class="{
              status_review: scope.row.status == 'REVIEWING',
              status_merge: scope.row.status == 'MERGED',
              status_approve: scope.row.status == 'APPROVED',
              status_refuse: scope.row.status == 'REFUSED',
            }"
          >
            {{ matchStatusToName(scope.row.status) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="mode === 'all'"
        prop="createdBy"
        :label="$t('addMyColla.table.title3')"
        :min-width="150"
      >
        <template slot-scope="scope">
          <span style="display: flex; align-items: center; gap: 10px">
            <personal-avatar
              :placement="'right'"
              :size="33"
              :avatar="
                (getUser(scope.row.createdBy) &&
                  getUser(scope.row.createdBy).avatar) ||
                ''
              "
              :nickname="
                (getUser(scope.row.createdBy) &&
                  getUser(scope.row.createdBy).nickname) ||
                ''
              "
              :colorid="scope.row.createdBy"
              style="justify-content: left"
            ></personal-avatar>
            <span>{{ getUser(scope.row.createdBy).nickname }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createdBy"
        v-if="mode === 'all'"
        :label="$t('addMyColla.table.title13')"
        sortable="custom"
        :min-width="280"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.createdAt }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="mode === 'all'"
        prop="owner"
        :label="$t('addMyColla.table.title5')"
        :min-width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-tooltip
            :disabled="
              scope.row.reviewerList.length ===
              scope.row.reviewerList.length -
                scope.row.unfinishedReviewerList.length
            "
            effect="dark"
            placement="top"
          >
            <div slot="content">
              <span>{{
                $t("addMyColla.table.title6") +
                getNickNames(scope.row.unfinishedReviewerList)
              }}</span>
            </div>
            <span style="cursor: pointer" @click="openPinShenDialog(scope.row)">
              <span style="color: green">{{
                scope.row.reviewerList.length -
                scope.row.unfinishedReviewerList.length
              }}</span>
              <span style="margin: 0 4px">/</span>
              <span>{{ scope.row.reviewerList.length }}</span>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="(mode === 'all') & !is_show_chart"
      style="margin-top: 10px"
      :current-page="allPageInfo.currentPage + 1"
      background
      :page-sizes="[10, 20, 50]"
      :page-size="allPageInfo.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="allPageInfo.total"
      @current-change="changeAllPage"
    >
    </el-pagination>
    <el-select
      v-show="is_show_chart"
      v-model="selectedOption"
      style="position: absolute; right: 0; top: 6%"
      size="mini"
      @change="updateBarChart"
    >
      <el-option
        v-for="(item, index) in chartOptions"
        :key="index"
        :value="index"
        :label="
          $t('changeRequest.option1') +
          (item.chartType == 'MOST_TIMES_AMOUNT_NODES'
            ? item.amount
            : item.ratio) +
          (item.chartType == 'MOST_TIMES_AMOUNT_NODES'
            ? $t('changeRequest.option2')
            : $t('changeRequest.option3'))
        "
      >
        {{
          $t("changeRequest.option1") +
          (item.chartType == "MOST_TIMES_AMOUNT_NODES"
            ? item.amount
            : item.ratio) +
          (item.chartType == "MOST_TIMES_AMOUNT_NODES"
            ? $t("changeRequest.option2")
            : $t("changeRequest.option3"))
        }}
      </el-option>
    </el-select>
    <!-- 图表 -->
    <div
      v-show="is_show_chart"
      id="echarts_main_wrap"
      ref="echarts_main_wrap"
      class="echarts_main_wrap"
    >
      <div class="bar1">
        <div id="bar1" ref="bar1"></div>
      </div>
      <div class="bar1">
        <div id="bar2" ref="bar2"></div>
      </div>
    </div>
    <el-dialog
      :title="
        pieHeader + $t('changeRequest.pieHeader') + $t('changeRequest.title')
      "
      :visible.sync="pieDialog"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div style="margin-bottom: 20px">
        <el-table
          :data="pieClickData"
          style="width: 100%; background: white"
          :header-cell-style="{
            color: '#383838',
          }"
          :cell-style="changeCellStyle"
          width="600"
          :height="600"
        >
          <el-table-column
            prop="nodeKey"
            :label="$t('changeRequest.id')"
            :width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                style="
                  cursor: pointer;
                  color: rgb(255, 195, 0);
                  font-weight: 700;
                "
                @click="rowClicked(scope.row)"
                >{{ scope.row.nodeKey }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('changeRequest.title')"
            :min-width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                @click="openRequestDialog(scope.row)"
                >{{ scope.row.name }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            :label="$t('addMyColla.table.title2')"
            :min-width="150"
          >
            <template slot-scope="scope">
              <span
                class="pingshen-status-tag"
                :style="{
                  borderStyle: 'solid',
                }"
                :class="{
                  status_review: scope.row.status == 'REVIEWING',
                  status_merge: scope.row.status == 'MERGED',
                  status_approve: scope.row.status == 'APPROVED',
                  status_refuse: scope.row.status == 'REFUSED',
                }"
              >
                {{ matchStatusToName(scope.row.status) }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 10px"
          :current-page="pieDialogPageInfo.currentPage + 1"
          background
          :page-sizes="[10, 20, 50]"
          :page-size="pieDialogPageInfo.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="pieDialogPageInfo.total"
          @current-change="changepieDialogPage"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <change-request-dialog
      :merge_visible="mergeDialog"
      :change-request-id="mergeId"
      :reviewer-list-all="reviewerList"
      :node-key="mergeNodeKey"
      @closeDialog="closeRequestDialog"
    />
  </div>
</template>

<script>
import PersonalAvatar from "@/components/personal";
import {
  getChangeRequest,
  getSentRequest,
  sendReminder,
  pingshenDetails,
  getAllRequest,
  sortByInitiator,
  getRequestNodeAndTimes,
  initiatorRequests,
} from "@/network/changeRequests/index.js";
import { get_node_use_node_exist } from "@/network/node/index.js";
import { mapGetters } from "vuex";
import { MenuItemGroup, Message } from "element-ui";
import { getFile } from "@/network/home/index.js";
import { get_file } from "@/network/baseline/index";
import { limits_of_authority_disable } from "@/utils/limits";
import ChangeRequestDialog from "./changeRequestDialog.vue";

export default {
  name: "HomeChangeRequest",
  components: {
    PersonalAvatar,
    ChangeRequestDialog,
  },
  data() {
    return {
      activeIndex: "/home/my_collaboration",
      confirmData: [],
      issueData: [],
      reviewData: [],
      currentReviewData: [],
      currentConfirmData: [],
      allData: [],
      pingshenData: [],
      mode: "review",
      sortOrderself: "id,desc",
      sortOrderall: "id,desc",
      sortOrderconfirm: "id,desc",
      sortOrderreview: "id,desc",
      sendingPageInfo: {
        // 我发出的邀请 分页
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      reviewingPageInfo: {
        // 全部评审请求 分页
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      confirmPageInfo: {
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      allPageInfo: {
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      pieDialogPageInfo: {
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      chartingPageInfo: {
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      pieClickData: [],
      pieCompleteData: [],
      chartOptions: [
        {
          amount: 200,
          chartType: "MOST_TIMES_AMOUNT_NODES",
        },
        {
          amount: 100,
          chartType: "MOST_TIMES_AMOUNT_NODES",
        },
        {
          amount: 50,
          chartType: "MOST_TIMES_AMOUNT_NODES",
        },
        {
          amount: 20,
          chartType: "MOST_TIMES_AMOUNT_NODES",
        },
        {
          ratio: 20,
          chartType: "MOST_TIMES_RATIO_NODES",
        },
        {
          ratio: 10,
          chartType: "MOST_TIMES_RATIO_NODES",
        },
      ],
      selectedOption: 1,
      pingshenDialog: false,
      isLoading: false,
      isDialogLoading: false,
      pieDialog: false,
      pieHeader: "",
      mergeDialog: false,
      mergeId: "",
      mergeNodeKey: "",
      reviewerList: [],
      isReviewing: false, //控制全部评审请求中右侧小图标是否显示
      is_show_chart: false, //控制 全部评审请求 页面中，视图是否显示
      myChart_bar1: "", //用于echarts 视图暂存变量 bar1
      myChart_bar2: "", //用于echarts 视图暂存变量 bar2
      topClass: 0,
      pieChart_data: [],
      currentPieData: {},
      nodeReviewPage: {
        // 饼状图评审通过节点 分页
        page: 0,
        page_size: 10,
        review_type: 0,
      },
      nodeReviewPage_total: 0, // 饼状图评审通过节点 分页总数
      pieTitle: "", // 饼状图列表标题
      bodyData: {
        filter: {
          queryConditions: [
            {
              isCustom: false,
              name: "fileTypeId",
              value: [],
            },
          ],
        },
      },
      barData: [], // 饼状图 data 数据
    };
  },
  computed: {
    ...mapGetters(["user_list", "fileType"]),
  },
  created() {
    this.getReviewingData();
    // this.getConfirmData();
    // this.getSentData();
    this.getPieChartData();
    this.getInitBarChartData();
  },
  beforeDestroy() {
    /* 页面组件销毁的时候，别忘了移除绑定的监听resize事件，否则的话，多渲染几次
      容易导致内存泄漏和额外CPU或GPU占用哦*/
    window.removeEventListener("resize", () => {
      this.myChart_bar1.resize();
    });
  },
  methods: {
    limits_of_authority_disable,
    //节点点击事件
    rowClicked(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${row.nodeKey}`,
      });
      window.open(href, "_blank");
    },
    setJudgmentorder(e) {
      if (e.order != null) {
        if (e.order == "descending") {
          return "id,desc";
        }
        if (e.order == "ascending") {
          return "id";
        }
      } else {
        return "id,desc";
      }
    },
    hadleChangeRowall(e) {
      this.sortOrderall = this.setJudgmentorder(e);
      this.getAllData();
    },
    hadleChangeRowself(e) {
      this.sortOrderself = this.setJudgmentorder(e);
      this.getSentData();
    },
    hadleChangeRowconfirm(e) {
      this.sortOrderconfirm = this.setJudgmentorder(e);
      this.getConfirmData();
    },
    hadleChangeRowreview(e) {
      this.sortOrderreview = this.setJudgmentorder(e);
      this.getReviewingData();
    },
    jumpToRequestDetail(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${row.nodeKey}?changeRequests=${
          row.changeRequestId
        }`,
      });
      window.open(href, "_blank");
    },
    openRequestDialog(row) {
      get_node_use_node_exist(this.get_pid(), row.nodeKey).then((res) => {
        if (res) {
          this.mergeDialog = true;
          this.mergeId = row.changeRequestId;
          this.reviewerList = row.reviewerList;
          this.mergeNodeKey = row.nodeKey;
        } else {
          this.$message({
            message: this.$t("notice.card.del"),
            type: "warning",
          });
        }
      });
    },
    closeRequestDialog() {
      this.mergeDialog = false;
      this.mergeId = "";
      this.mergeNodeKey = "";
    },
    getPieChartData() {
      const params = {
        projectId: this.get_pid(),
      };
      sortByInitiator(params).then((res) => {
        this.pieChart_data = [];
        res.forEach((item) => {
          this.pieChart_data.push({
            value: item.count,
            name: this.getUser(item.initiator).nickname,
            initiator: item.initiator,
          });
        });
      });
    },
    getInitBarChartData() {
      const params = {
        projectId: this.get_pid(),
        query: {
          amount: 100,
          chartType: "MOST_TIMES_AMOUNT_NODES",
        },
      };
      getRequestNodeAndTimes(params).then((res) => {
        this.barData = res;
      });
    },
    updateBarChart() {
      const params = {
        projectId: this.get_pid(),
        query: this.chartOptions[this.selectedOption],
      };
      getRequestNodeAndTimes(params).then((res) => {
        this.barData = res;
        this.bar_charts();
      });
    },
    // 获取我发出的邀请 列表数据
    getReviewingData() {
      this.isLoading = true;
      const params = {
        projectId: this.get_pid(),
        query: {
          changeRequestShowType: "WAIT_REVIEW",
          page: this.reviewingPageInfo.currentPage,
          size: this.reviewingPageInfo.pageSize,
          sort: this.sortOrderreview,
        },
      };
      getChangeRequest(params).then((res) => {
        this.reviewingPageInfo.total = res.totalElements;
        this.isLoading = false;
        this.currentReviewData = res.content;
      });
    },
    //饼状图弹窗数据获取
    getPieDialogData() {
      const params = {
        projectId: this.get_pid(),
        query: {
          accountId: this.currentPieData.initiator,
          page: this.pieDialogPageInfo.currentPage,
          size: this.pieDialogPageInfo.pageSize,
        },
      };
      initiatorRequests(params).then((res) => {
        this.pieDialogPageInfo.total = res.totalElements;
        this.pieClickData = res.content;
      });
    },
    getConfirmData() {
      this.isLoading = true;
      const params = {
        projectId: this.get_pid(),
        query: {
          changeRequestShowType: "WAIT_CONFIRM",
          page: this.confirmPageInfo.currentPage,
          size: this.confirmPageInfo.pageSize,
          sort: this.sortOrderconfirm,
        },
      };
      getChangeRequest(params).then((res) => {
        this.confirmPageInfo.total = res.totalElements;
        this.isLoading = false;
        this.confirmData = res.content;
      });
    },
    getSentData() {
      this.isLoading = true;
      const params = {
        projectId: this.get_pid(),
        query: {
          page: this.sendingPageInfo.currentPage,
          size: this.sendingPageInfo.pageSize,
          sort: this.sortOrderself,
        },
      };
      getSentRequest(params).then((res) => {
        this.issueData = res.content;
        this.sendingPageInfo.total = res.totalElements;
        this.isLoading = false;
      });
    },
    getAllData() {
      this.isLoading = true;
      const params = {
        projectId: this.get_pid(),
        query: {
          page: this.allPageInfo.currentPage,
          size: this.allPageInfo.pageSize,
          sort: this.sortOrderall,
        },
      };
      getAllRequest(params).then((res) => {
        this.allData = res.content;
        this.allPageInfo.total = res.totalElements;
        this.isLoading = false;
      });
    },
    matchStatusToName(status) {
      const statusMap = {
        REVIEWING: this.$t("changeRequest.reviewing"),
        MERGED: this.$t("changeRequest.merge"),
        APPROVED: this.$t("changeRequest.approve"),
        REFUSED: this.$t("changeRequest.refuse"),
      };
      return statusMap[status];
    },
    getNickNames(ids) {
      let names = [];
      ids.forEach((id) => {
        names.push(this.getUser(id).nickname);
      });
      return names.join(", ");
    },
    openPinShenDialog(row) {
      this.isDialogLoading = true;
      const params = {
        projectId: this.get_pid(),
        nodeKey: row.nodeKey,
        changeRequestId: row.changeRequestId,
      };
      pingshenDetails(params).then((res) => {
        this.pingshenData = res;
        this.isDialogLoading = false;
      });
      this.pingshenDialog = true;
    },
    changeSendingPage(pageNumber) {
      this.sendingPageInfo.currentPage = pageNumber - 1;
      this.getSentData();
    },
    changeReviewingPage(pageNumber) {
      this.reviewingPageInfo.currentPage = pageNumber - 1;
      this.getReviewingData();
    },
    changeConfirmPage(pageNumber) {
      this.confirmPageInfo.currentPage = pageNumber - 1;
      this.getConfirmData();
    },
    changeAllPage(pageNumber) {
      this.allPageInfo.currentPage = pageNumber - 1;
      this.getAllData();
    },
    changepieDialogPage(pageNumber) {
      this.pieDialogPageInfo.currentPage = pageNumber - 1;
      this.getPieDialogData();
    },
    sendAgain(row) {
      if (row.status == "REVIEWING") {
        const params = {
          projectId: this.get_pid(),
          changeRequestId: row.changeRequestId,
        };
        sendReminder(params).then((res) => {
          this.$message({
            type: "success",
            message: this.$t("DFMEA.ms3"),
          });
        });
      }
    },
    getUser(userId) {
      return this.matchUserInfo(userId);
    },
    // 点击切换评审页面内容 我收到的邀请 我发出的邀请 全部评审请求
    changeMode(mode) {
      this.mode = mode;
      // this.topClass = mode == "review" ? 0 : mode == "confirm" ? 1 : mode == "issue" ? 2 : 3;
      if (mode == "review") {
        this.topClass = 0;
        this.getReviewingData();
      } else if (mode == "confirm") {
        this.topClass = 1;
        this.getConfirmData();
      } else if (mode == "issue") {
        this.topClass = 2;
        this.getSentData();
      } else if (mode == "all") {
        this.topClass = 3;
        this.getAllData();
      }
      this.is_show_chart = false;
    },
    changeCellStyle(row) {
      if (row.column.label === "ID") {
        return "color: #FFC300; font-weight: 700;"; // 修改的样式
      } else {
        return "color:#636363; font-weight: 700";
      }
    },
    // 控制数据试图显隐
    show_chart() {
      this.mode = "svgimg";
      this.topClass = 4;
      // 处在看板页面
      if (!this.is_show_chart) {
        this.$nextTick(() => {
          this.init_charts();
        });
      }
      this.is_show_chart = true;
      this.updateBarChart();
    },
    // 初始化图表
    init_charts() {
      this.pie_charts();
      this.bar_charts();
      let element = this.$refs["echarts_main_wrap"];
      this.observer = new ResizeObserver(() => {
        this.myChart_bar1.resize();
        this.myChart_bar2.resize();
      });
      this.observer.observe(element);
    },
    // 饼状图
    pie_charts() {
      this.myChart_bar1 = this.$echarts.init(document.getElementById("bar1"));
      let option = {
        tooltip: {
          formatter: "{b} <br/> <b>{c}</b> <br/><b>{d}%</b>",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          align: "left",
          right: "5%",
          top: "10%",
          width: "20%",
          height: "80%",
          textStyle: {
            width: 150,
            overflow: "breakAll",
            rich: {
              title: {
                align: "left",
              },
              value: {
                align: "right",
              },
            },
          },
          tooltip: {
            show: true,
          },
        },
        series: [
          {
            left: -200,
            type: "pie",
            minAngle: 5,
            data: this.pieChart_data,
            radius: [0, "50%"],
            label: {
              show: true,
              position: "outside",
              formatter: "{b}: {c}\n{d}%",
              width: 80,
              textStyle: {
                fontSize: 12,
                fontWeight: "normal",
              },
            },
            labelLine: {
              show: true,
              length: 10, // 扇形边上的线条长度
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
          },
        ],
        title: {
          text: this.$t("changeRequest.initiator"),
          textStyle: {
            fontFamily: "Source Han Sans CN",
            color: "rgba(56, 56, 56, 1)",
          },
        },
      };
      this.myChart_bar1.setOption(option);
      this.myChart_bar1.off("click");
      this.myChart_bar1.on("click", (params) => {
        this.pieHeader = params.data.name;
        this.currentPieData = params.data;
        this.getPieDialogData();
        this.pieDialog = true;
      });
    },
    // 柱形图
    bar_charts() {
      this.myChart_bar2 = this.$echarts.init(document.getElementById("bar2"));
      const option = {
        tooltip: {},
        title: {
          text: this.$t("changeRequest.times"),
          textStyle: {
            fontFamily: "Source Han Sans CN",
            color: "rgba(56, 56, 56, 1)",
          },
        },
        xAxis: {
          type: "category",
          data: this.barData.map((item) => {
            return item.nodeKey;
          }),
          axisLine: {
            lineStyle: {
              color: "rgba(235, 238, 245, 1)",
            },
          },
          nameTextStyle: {
            color: "rgba(128, 128, 128, 1)",
          },
          splitLine: {
            lineStyle: {
              color: "#EBEEF5",
            },
          },
          axisTick: {
            lineStyle: {
              color: "rgba(166, 166, 166, 1)",
            },
            alignWithLabel: true,
          },
          axisLabel: {
            color: "rgba(128, 128, 128, 1)",
            formatter: function (value) {
              let texts = value;
              if (texts.length > 10) {
                // 限制长度自设
                texts =
                  texts.substr(0, 10) + "\n" + texts.substr(10, value.length);
              }
              return texts;
            },
            interval: 0,
          },
          name: this.$t("changeRequest.id"),
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#EBEEF5",
            },
          },
          axisLabel: {
            color: "rgba(128, 128, 128, 1)",
          },
          nameTextStyle: {
            color: "rgba(128, 128, 128, 1)",
            fontSize: "14",
          },
          name: this.$t("changeRequest.times"),
        },
        grid: {
          left: "10%",
          right: "15%",
          top: "20%",
        },
        series: {
          type: "bar",
          barWidth: 42,
          data: this.barData.map((item) => {
            return item.timesOfChange;
          }),
          itemStyle: {
            color: "rgba(222, 238, 250, 1)",
          },
        },
        dataZoom: [
          {
            show: true,
            height: 5,
            xAxisIndex: [0],
            bottom: 5,
            showDetail: false,
            showDataShadow: false,
            borderColor: "transparent",
            textStyle: {
              fontSize: 0,
            },
            endValue: 4, //从0开始的相当于5个
            backgroundColor: "rgba(0,0,0,0)",
            borderWidth: 0,
            handleSize: "0%",
          },
        ],
      };
      this.myChart_bar2.setOption(option);
      this.myChart_bar2.off("click");
      this.myChart_bar2.on("click", (params) => {
        this.rowClicked(this.barData[params.dataIndex]);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.title-relative {
  position: relative;
  margin-left: 20px;
  cursor: pointer;
  .point-absolute {
    position: absolute;
    width: 5px;
    height: 5px;
    left: -12px;
    top: -2px;
    border-radius: 50%;
    background-color: red;
  }
}
.menu {
  display: flex;
  gap: 40px;
  margin-bottom: 25px;

  .item {
    font-size: 18px;
    color: #777;
    cursor: pointer;
  }
  .svgClass {
    position: relative;
    font-size: 16px;
    right: 0.082rem;
    color: rgba(255, 255, 255, 0);
    width: 0.1042rem;
    height: 0.1042rem;
    cursor: pointer;
  }
}
.bottom-border {
  border-bottom: 2px solid rgba(48, 100, 143, 1);
}
.pingshenmenu {
  position: relative;
  .number-ab {
    position: absolute;
    top: -7px;
    right: -15px;
    background-color: red;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    font-size: 14px;
    align-items: center;
    color: #fff;
    justify-content: center;
  }
}

.pingshen-status-tag {
  padding: 4px 16px;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #fff;
  border-width: 1px;
}
.status_approve {
  border: 1px solid rgba(67, 207, 124, 1);
  color: rgba(67, 207, 124, 1);
}
.status_review {
  border: 1px solid rgba(109, 127, 160, 1);
  color: rgba(109, 127, 160, 1);
}
.status_refuse {
  border: 1px solid rgba(212, 48, 48, 1);
  color: rgba(212, 48, 48, 1);
}
.status_merge {
  border: 1px solid rgba(166, 166, 166, 1);
  color: rgba(166, 166, 166, 1);
}
.wrong {
  width: 24px;
  height: 24px;
  margin-left: 20px;
}
.right {
  width: 24px;
  height: 24px;
  margin-left: 20px;
}
.img-list {
  display: flex;

  .img-box {
    height: 36px;
    width: 36px;
    box-sizing: content-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    margin-right: -8px;
    img {
      height: 100%;
      border-radius: 50%;
    }
  }
}
.hidden-img {
  display: flex;
  gap: 4px;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}
::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #568cb8;
}
.echarts_main_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  & > div {
    width: 49%;
    margin-top: 50px;
    height: 450px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 12px 0 0 20px;
    & > div {
      width: 100%;
      height: 430px;
    }
  }
  .bar1 {
    overflow: hidden;
    width: 48%;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    margin-top: 30px;
    padding: 15px;
  }
}
.el-dropdown-menu {
  width: 200px;
  //height: 250px;
  label {
    width: 100%;
  }
}
.select {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: hidden;
}
.checkbox {
  margin-left: 15px;
  margin-bottom: 10px;
  font-size: 10px;
}
.btn {
  width: 59px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  text-align: center;
  border-radius: 4px;
  background-color: rgba(229, 229, 229, 1);
  border-color: rgba(229, 229, 229, 1);
  color: rgba(84, 85, 86, 1);
}
</style>
<style scoped>
.sortable-column-header {
  display: flex;
  align-items: center;
}
.caret-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.clickable-rows::before {
  height: 0px;
}
.clickable-rows {
  cursor: default;
  height: 75vh;
  overflow: auto;
  background: white;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.noagain {
  display: block;
  margin: 15px 20px;
  text-align: left;
  color: #67656563;
  cursor: not-allowed;
}
.operate_button {
  display: block;
  margin: 15px 20px;
  text-align: left;
  cursor: pointer;
  color: #656667;
}
.operate_button:hover {
  color: #46b4f4;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
}
/deep/ .el-table .el-table__cell.gutter {
  background: #f0f2f5 !important;
}
.email_to {
  color: #707070;
  padding-bottom: 10px;
  border-bottom: 3px solid rgba(48, 100, 143, 1);
  cursor: pointer;
}
.email_to_hover {
  color: #a6a6a6;
  border-bottom: 3px solid rgba(48, 100, 143, 0);
  cursor: pointer;
}
/**密码登录按钮样式 */
.pass_to {
  color: #a6a6a6;
  padding-bottom: 10px;
  border-bottom: 3px solid rgba(48, 100, 143, 0);
  cursor: pointer;
}
.pass_to_hover {
  color: #707070;
  border-bottom: 3px solid rgba(48, 100, 143, 1);
  cursor: pointer;
  padding-bottom: 10px;
}
.font_size {
  font-size: 18px;
  font-weight: 600;
}
.point {
  margin-left: 10px;
}
.hidden-img {
  display: flex;
}
</style>

<style scoped>
/deep/ .el-loading-mask {
  pointer-events: none;
}
</style>
