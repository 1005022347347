<template>
  <div
    class="senior-search-wrap"
    :style="{
      'z-index': zIndex,
    }"
  >
    <div
      ref="jsEditorElement"
      contenteditable="false"
      class="jsEditorElement"
      v-html="showStr"
      :class="{
        error_border: errorMessage.length,
      }"
      @click="openDialog"
    ></div>
    <i class="iconfont icon-zs search-icon"></i>
    <el-dialog
      :title="$t('addMyColla.table.title11')"
      :visible.sync="dialogVisible"
      width="60%"
      append-to-body
      @close="closeDialog"
    >
      <div class="dialog-wrap">
        <div v-for="(item, index) in searchList" :key="index">
          <!-- or and -->
          <el-select
            v-if="index"
            v-model="item.keywords"
            class="select-wrap"
            size="small"
            style="width: 8%; flex-shrink: 0"
            filterable
            :disabled="!index"
            @change="buildSearchStr"
          >
            <el-option
              v-for="item in keywordsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div
            v-if="!index"
            style="width: 8%; margin-right: 10px; flex-shrink: 0"
          ></div>
          <span style="display: inline-block; min-width: 150px">
            <el-button
              type="danger"
              icon="el-icon-minus"
              style="float: left"
              @click="deleteBracket(item, 'bracketLeft', '(')"
            ></el-button>
            <el-button
              type="primary"
              style="width: 38px; float: left"
              @click="addBracket(item, 'bracketLeft', '(')"
              >(</el-button
            >
            <span class="bracket-wrap" style="margin-left: 10px">{{
              item.bracketLeft
            }}</span>
          </span>
          <!-- key -->
          <el-select
            v-model="item.keyObj"
            class="select-wrap"
            size="small"
            value-key="value"
            filterable
            :disabled="item.keywords === 0 && index"
            @change="
              (value) => {
                keyWordsChange(value, item);
              }
            "
          >
            <el-option
              v-for="item in fieldsOptions"
              :key="item.value"
              :label="item.label"
              :value="item"
            >
            </el-option>
          </el-select>
          <!--  operators -->
          <el-select
            v-model="item.operators"
            class="select-wrap"
            size="small"
            style="width: 14%"
            :disabled="item.key.length === 0"
            filterable
            @change="
              (value) => {
                changeOperators(value, item);
              }
            "
          >
            <el-option
              v-for="item in item.keyObj.fieldType === 'NUMBER' ||
              item.keyObj.fieldType === 'DATE'
                ? operatorsOptionsNumber
                : operatorsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!--  operators -->
          <el-select
            v-if="
              resizeSelect &&
              item.keyObj.fieldType !== 'DATE' &&
              item.keyObj.fieldType !== 'NUMBER'
            "
            v-model="item.value"
            class="select-wrap"
            size="small"
            :disabled="
              item.key.length === 0 ||
              !item.operators.length ||
              item.operators === 'IS NULL' ||
              item.operators === 'IS NOT NULL'
            "
            filterable
            :multiple="item.operators === 'IN' || item.operators === 'NOT IN'"
            style="width: 50%"
            @change="buildSearchStr"
          >
            <el-option
              v-for="item in item.valueOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-date-picker
            v-if="resizeSelect && item.keyObj.fieldType === 'DATE'"
            v-model="item.value"
            :disabled="item.key.length === 0 || !item.operators.length"
            type="date"
            size="small"
            class="select-wrap"
            style="width: 50%"
            value-format="yyyy-MM-dd"
            @change="buildSearchStr"
          >
          </el-date-picker>

          <el-input-number
            v-if="resizeSelect && item.keyObj.fieldType === 'NUMBER'"
            size="small"
            :disabled="item.key.length === 0 || !item.operators.length"
            class="select-wrap"
            v-model="item.value"
            style="width: 50%"
            @change="buildSearchStr"
          ></el-input-number>
          <div
            v-if="!resizeSelect"
            style="width: 50%; margin-right: 10px"
          ></div>
          <span style="display: inline-block; min-width: 150px">
            <span class="bracket-wrap" style="margin-right: 10px">{{
              item.bracketRight
            }}</span>
            <el-button
              type="danger"
              style="float: right; margin-left: 10px"
              icon="el-icon-minus"
              @click="deleteBracket(item, 'bracketRight', ')')"
            ></el-button>
            <el-button
              type="primary"
              style="width: 38px; float: right"
              @click="addBracket(item, 'bracketRight', ')')"
              >)</el-button
            >
          </span>
          <span
            ><i
              v-if="index"
              class="el-icon-delete"
              style="
                font-size: 20px;
                font-weight: bold;
                vertical-align: -webkit-baseline-middle;
                margin: 0 0 0 10px;
                cursor: pointer;
              "
              @click="deleteCondition(index)"
            ></i>
            <i
              v-else
              class="el-icon-delete"
              style="
                font-size: 20px;
                font-weight: bold;
                vertical-align: -webkit-baseline-middle;
                margin: 0 0 0 10px;
                cursor: pointer;
                visibility: hidden;
              "
            ></i
          ></span>
        </div>
      </div>
      <div v-if="errorMessage.length" class="error_wrap">
        {{ errorMessage[0] }}
      </div>
      <div class="bottom-button-wrap">
        <el-button type="primary" icon="el-icon-plus" @click="addCondition">{{
          $t("node.sendiorSearch.addFilterConditions")
        }}</el-button>
        <el-button type="primary" @click="searchFn" style="float: right">{{
          $t("placeholder.search")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { offset, position } from "caret-pos";
import {
  all_fields_get,
  getSystemFields,
  get_filetype_List,
} from "@/network/fileType/index.js";

import { mqlValidation, get_Labels } from "@/network/node";
import { mapGetters } from "vuex";
import { ulid } from "ulid";
export default {
  data() {
    return {
      // 已经插入的数据
      searchList: [],
      //   字段选项
      fieldsOptions: [],
      //  文件类型values
      filetypeValuesList: [],
      //   操作选项
      operatorsOptions: [
        {
          type: "operators",
          value: "=",
          label: "=",
        },
        {
          type: "operators",
          value: "!=",
          label: "!=",
        },
        {
          type: "operators",
          value: "IN",
          label: "IN",
        },
        {
          type: "operators",
          value: "NOT IN",
          label: "NOT IN",
        },
        {
          type: "values",
          value: "IS NULL",
          label: "IS NULL",
        },
        {
          type: "values",
          value: "IS NOT NULL",
          label: "IS NOT NULL",
        },
      ],
      operatorsOptionsNumber: [
        {
          type: "operators",
          value: " = ",
          label: " = ",
        },
        {
          type: "operators",
          value: " != ",
          label: " != ",
        },
        {
          type: "operators",
          value: " > ",
          label: " > ",
        },
        {
          type: "operators",
          value: " >= ",
          label: " >= ",
        },
        {
          type: "operators",
          value: " < ",
          label: " < ",
        },
        {
          type: "operators",
          value: " <= ",
          label: " <= ",
        },
      ],
      //   字段值选项
      valuesOptions: [],
      //   关键字选项
      keywordsOptions: [
        {
          type: "keywords",
          value: "OR",
          label: "OR",
        },
        {
          type: "keywords",
          value: "AND",
          label: "AND",
        },
      ],
      // 是否显示弹窗
      isShowDialog: false,
      // 错误信息
      errorMessage: [],
      // 弹窗相关
      dialogVisible: false,
      // 刷新组件
      resizeSelect: true,
      searchStr: "",
      showStr: "",
      zIndex: 999,
      project_labels: [],
    };
  },
  computed: {
    ...mapGetters([
      "project_user_list",
      "status",
      "baseLine",
      "testPlan",
      "version",
      "sprint",
    ]),
  },
  async mounted() {
    await this.getFieldsOptions();
    this.get_Labels();
  },
  methods: {
    // 获取所有labelOptions
    get_Labels() {
      get_Labels(this.get_pid())
        .then((res) => {
          this.project_labels = res;
        })
        .catch((err) => console.log(err));
    },
    // 回填数据
    backfill(List) {
      this.searchList = List;
      this.buildSearchStr();
    },
    closeDialog() {
      this.zIndex = 999;
    },
    // 实时渲染字符串
    buildSearchStr() {
      this.searchStr = "";
      this.showStr = "";
      this.searchList.forEach((item) => {
        if (item.key) {
          //  组合发给后台的str
          item.keywords ? (this.searchStr += ` ${item.keywords} `) : "";
          this.searchStr += item.bracketLeft;
          this.searchStr += item.key ? ` ${item.key.value} ` : "";
          this.searchStr += ` ${item.operators} `;
          if (
            item.operators === "IS NULL" ||
            item.operators === "IS NOT NULL"
          ) {
            this.searchStr += item.bracketRight;
          } else if (
            item.operators === "IN" ||
            (item.operators === "NOT IN" && item.value)
          ) {
            this.searchStr += item.key.isCustom ? `'[` : "(";
            item.value.forEach((value, index) => {
              if (index == item.value.length - 1) {
                this.searchStr += item.key.isCustom
                  ? `"${value}"`
                  : `'${value}'`;
              } else {
                this.searchStr += item.key.isCustom
                  ? `"${value}",`
                  : `'${value}',`;
              }
            });
            this.searchStr += item.key.isCustom ? `]'` : ")";
            this.searchStr += item.bracketRight;
          } else if (item.key.fieldType === "NUMBER" && item.value) {
            this.searchStr += `'${item.value}'`;
            this.searchStr += item.bracketRight;
          } else if (item.value) {
            this.searchStr += item.key.isCustom
              ? `'"${item.value}"'`
              : `'${item.value}'`;
            this.searchStr += item.bracketRight;
          }
          // 组合展示给用户的str
          item.keywords ? (this.showStr += ` ${item.keywords} `) : "";
          this.showStr += item.bracketLeft;
          this.showStr += item.key ? ` ${item.key.label} ` : "";
          this.showStr += ` ${item.operators} `;
          if (
            item.operators === "IS NULL" ||
            item.operators === "IS NOT NULL"
          ) {
            this.showStr += item.bracketRight;
          } else if (
            item.operators === "IN" ||
            (item.operators === "NOT IN" && item.value)
          ) {
            this.showStr += "(";
            item.value.forEach((value, index) => {
              if (index == item.value.length - 1) {
                item.valueOptions.forEach((option) => {
                  if (option.value === value) {
                    this.showStr += `${option.label}`;
                  }
                });
              } else {
                item.valueOptions.forEach((option) => {
                  if (option.value === value) {
                    this.showStr += `${option.label},`;
                  }
                });
              }
            });
            this.showStr += ")";
            this.showStr += item.bracketRight;
          } else if (item.key.fieldType === "NUMBER" && item.value) {
            this.showStr += `${item.value}`;
            this.showStr += item.bracketRight;
          } else if (item.value && item.key.fieldType === "DATE") {
            this.showStr += `${item.value}`;
            this.showStr += item.bracketRight;
          } else if (item.value) {
            item.valueOptions.forEach((option) => {
              if (option.value === item.value) {
                this.showStr += `${option.label}`;
              }
            });
            this.showStr += item.bracketRight;
          }
        }
      });
    },
    // 确认
    searchFn() {
      const params = {
        data: {
          content: this.searchStr,
        },
      };
      mqlValidation(params).then((res) => {
        this.errorMessage = res;
        if (!this.errorMessage.length) {
          this.$emit("mqlFilter", this.searchStr, this.searchList);
          this.dialogVisible = false;
        }
      });
    },
    // 删除条件
    deleteCondition(index) {
      this.searchList.splice(index, 1);
      this.buildSearchStr();
    },
    // 新增条件
    addCondition() {
      const obj = {
        key: "",
        keyObj: "",
        value: [],
        keywords: "",
        operators: "",
        bracketLeft: "",
        bracketRight: "",
        valueOptions: [],
      };
      this.searchList.push(obj);
      this.buildSearchStr();
    },
    // 字段选择处理
    keyWordsChange(value, item) {
      item.key = value;
      this.getBuildList(item);
      // 重置选中状态
      this.resetValues(item);
      this.buildSearchStr();
    },
    // 比较符选择处理
    changeOperators(value, item) {
      this.resizeSelect = false;
      if (value === "IN" || value === "NOT IN") {
        this.$set(item, "value", []);
      } else {
        this.$set(item, "value", "");
      }
      setTimeout(() => {
        this.resizeSelect = true;
      });
      this.buildSearchStr();
    },
    // 获取所有字段
    async getFieldsOptions() {
      const res = await get_filetype_List(this.get_pid());
      // 处理项目中所有字段
      const arr = await Promise.all([
        getSystemFields(),
        all_fields_get(this.get_pid()),
        get_filetype_List(this.get_pid()),
      ]);
      const allFields = [
        ...arr[0].filter((item) => {
          return (
            [
              "fileTypeId",
              "createdBy",
              "status",
              "assignee",
              "baseline",
              "version",
              "testPlan",
              "sprint",
              "createdAt",
            ].indexOf(item.fieldName) !== -1
          );
        }),
        ...arr[1].filter((item) => {
          return (
            [
              "SELECT",
              "SELECT_MULTI",
              "SINGLE_USER_SELECT",
              "MULTI_USER_SELECT",
              "DATE",
              "NUMBER",
              "LABEL",
            ].indexOf(item.fieldType) !== -1
          );
        }),
      ];
      this.filetypeValuesList = [];
      arr[2].forEach((item) => {
        const obj = {
          label: item.name,
          value: item.fileTypeId,
          type: "values",
        };
        this.filetypeValuesList.push(obj);
      });
      this.fieldsOptions = allFields.map((item) => {
        return {
          label: item.name,
          value: item.isCustom ? item.fieldId : item.fieldName,
          fieldType: item.fieldType,
          type: "fields",
          extraData: item.extraData,
          isCustom: item.isCustom,
        };
      });
    },
    // 打开弹窗
    openDialog() {
      this.dialogVisible = true;
      this.zIndex = 3000;
      if (!this.searchList.length) {
        this.addCondition();
      }
    },
    // 添加或者删除括号
    addBracket(item, key, Bracket) {
      item[key] += Bracket;
      this.buildSearchStr();
    },
    deleteBracket(item, key, Bracket) {
      this.$set(item, key, item[key].replace(Bracket, ""));
      this.buildSearchStr();
    },
    // 获取各种value
    async getBuildList(item) {
      const templateList = [];
      if (
        item.keyObj.isCustom &&
        (item.keyObj.fieldType === "SELECT" ||
          item.keyObj.fieldType === "SELECT_MULTI")
      ) {
        item.keyObj.extraData.forEach((item) => {
          const obj = {
            label: item.label,
            value: item.value,
            type: "values",
          };
          templateList.push(obj);
        });
      } else if (
        item.keyObj.isCustom &&
        (item.keyObj.fieldType === "SINGLE_USER_SELECT" ||
          item.keyObj.fieldType === "MULTI_USER_SELECT")
      ) {
        Object.values(this.project_user_list).forEach((item) => {
          const obj = {
            label: item.nickname,
            value: item.accountId,
            type: "values",
          };
          templateList.push(obj);
        });
      } else if (item.keyObj.fieldType === "LABEL") {
        templateList.push(...this.project_labels);
      } else if (item.keyObj.value === "fileTypeId") {
        const res = await get_filetype_List(this.get_pid());
        res.forEach((item) => {
          const obj = {
            label: item.name,
            value: item.fileTypeId,
            type: "values",
          };
          templateList.push(obj);
        });
      } else if (
        item.keyObj.value === "createdBy" ||
        item.keyObj.value === "assignee"
      ) {
        Object.values(this.project_user_list).forEach((item) => {
          const obj = {
            label: item.nickname,
            value: item.accountId,
            type: "values",
          };
          templateList.push(obj);
        });
      } else if (item.keyObj.value === "status") {
        Object.values(this.status).forEach((item) => {
          const obj = {
            label: item.name,
            value: item.statusId,
            type: "values",
          };
          templateList.push(obj);
        });
      } else if (item.keyObj.value === "baseline") {
        Object.values(this.baseLine).forEach((item) => {
          const obj = {
            label: item.name,
            value: item.baselineId,
            type: "values",
          };
          templateList.push(obj);
        });
      } else if (item.keyObj.value === "version") {
        Object.values(this.version).forEach((item) => {
          const obj = {
            label: item.name,
            value: item.versionId,
            type: "values",
          };
          templateList.push(obj);
        });
      } else if (item.keyObj.value === "testPlan") {
        Object.values(this.testPlan).forEach((item) => {
          const obj = {
            label: item.name,
            value: item.testPlanId,
            type: "values",
          };
          templateList.push(obj);
        });
      } else if (item.keyObj.value === "sprint") {
        Object.values(this.sprint).forEach((item) => {
          const obj = {
            label: item.name,
            value: item.sprintId,
            type: "values",
          };
          templateList.push(obj);
        });
      }
      item.valueOptions = JSON.parse(JSON.stringify(templateList));
    },
    // 重置选中字段
    resetValues(item) {
      item.operators = "";
      item.value = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.error_wrap {
  color: #f56c6c;
}
.error_border {
  border: 1px solid #f56c6c !important;
}
.jsEditorElement {
  outline: none;
  width: 100%;
  flex: none;
  word-break: break-all;
  min-height: 28px;
  max-height: 50px;
  line-height: 27px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  width: 780px;
  padding: 0 15px;
  background-color: #fff;
  color: #606266;
  font-family: Arial;
  cursor: pointer;
  overflow-y: scroll;
}
.jsEditorElement:focus {
  border-color: #409eff;
  outline: 0;
}
.jsEditorElement:focus::before {
  content: "";
}
.senior-search-wrap {
  height: 37px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
  .search-icon {
    position: absolute;
    right: 10px;
    top: 9px;
    color: #c0c4cc;
  }
}
.dialog-wrap {
  & > div {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    .select-wrap {
      &:first-of-type {
        margin: 0 10px 0 0;
      }
      &:not(:first-of-type):not(:first-of-type) {
        margin: 0 10px;
      }
    }
  }
}
.bracket-wrap {
  display: inline-block;
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: sub;
  font-size: 18px;
}
.bottom-button-wrap {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
