var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-setting-table"},[_c('el-table',{staticClass:"clickable-rows",staticStyle:{"margin-top":"28px"},attrs:{"height":"82vh","data":_vm.userList,"header-cell-style":{
      fontWeight: '700',
      color: '#383838',
    },"cell-style":{ color: '#636363', 'font-weight': '700' }}},[_vm._v(" > "),_c('el-table-column',{attrs:{"label":_vm.$t('user.userTable.name'),"min-width":"30%"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"avatar-with-name-center"},[_c('personal-avatar',{staticStyle:{"margin-right":"10px"},attrs:{"size":35,"avatar":scope.row.avatar,"nickname":scope.row.name,"tool-tip-disabled":true,"colorid":scope.row.accountId}}),_c('span',[_vm._v(_vm._s(scope.row.name))])],1)]}}])}),_c('el-table-column',{attrs:{"prop":"role","label":_vm.$t('user.userTable.role'),"min-width":"30%","placeholder":_vm.$t('user.userTable.placeholder')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{ref:'roleSelect' + scope.row.accountId,attrs:{"filterable":""},on:{"change":function($event){return _vm.handleSelectChange(
              $event,
              scope,
              'roleSelect' + scope.row.accountId
            )},"focus":function($event){return _vm.setSelectWidth($event, ("roleSelect" + (scope.row.accountId)))}},model:{value:(scope.row.roleId),callback:function ($$v) {_vm.$set(scope.row, "roleId", $$v)},expression:"scope.row.roleId"}},_vm._l((_vm.rolesList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"project","label":_vm.$t('user.userTable.position'),"min-width":"30%"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{ref:'jobSelect' + scope.row.accountId,attrs:{"filterable":"","placeholder":_vm.$t('user.userTable.placeholder2')},on:{"change":function (val) {
              _vm.handleJobSelectChange(
                val,
                scope,
                'jobSelect' + scope.row.accountId
              );
            },"focus":function($event){return _vm.setSelectWidth($event, ("jobSelect" + (scope.row.accountId)))}},model:{value:(scope.row.jobId),callback:function ($$v) {_vm.$set(scope.row, "jobId", $$v)},expression:"scope.row.jobId"}},_vm._l((_vm.jobsList),function(item){return _c('el-option',{key:item.jobId,attrs:{"label":item.name,"value":item.jobId}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('btn.optionBtn'),"min-width":"10%"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',[_c('span',{staticClass:"el-dropdown-link",staticStyle:{"color":"#84aace"}},[_vm._v("    "),_c('i',{staticClass:"iconfont icon-a-216gengduo-shuxiang",staticStyle:{"color":"rgb(48, 100, 143)"}}),_vm._v("    ")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"},nativeOn:{"click":function($event){return _vm.handleDeleteMember(scope.$index, scope.row)}}},[_vm._v(" "+_vm._s(_vm.$t("user.userTable.options.remove"))+" ")])],1)],1)]}}])})],1),_c('el-dialog',{attrs:{"title":_vm.$t('user.userTable.options.transfer'),"visible":_vm.transferDialog,"modal":false,"width":"25%"},on:{"update:visible":function($event){_vm.transferDialog=$event}}},[_c('span',[_c('el-form',{ref:"form",attrs:{"label-position":"left","label-width":"100px"}},[_c('el-form-item',[_c('template',{slot:"label"},[_c('el-tooltip',{directives:[{name:"ak-tooltip-auto-show",rawName:"v-ak-tooltip-auto-show"}],staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('user.userTable.options.transferInfo3')}},[_c('div',{staticStyle:{"white-space":"nowrap","text-overflow":"ellipsis","overflow":"hidden"}},[_vm._v(" "+_vm._s(_vm.$t("user.userTable.options.transferInfo3"))+" ")])])],1),_c('el-select',{staticStyle:{"float":"right"},attrs:{"clearable":""},model:{value:(_vm.transferTo),callback:function ($$v) {_vm.transferTo=$$v},expression:"transferTo"}},_vm._l((_vm.userList.filter(function (user) {
                return user.accountId !== _vm.currentAccountId;
              })),function(item){return _c('el-option',{key:item.accountId,attrs:{"label":item.name,"value":item.accountId}})}),1)],2)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.deleteUser}},[_vm._v(_vm._s(_vm.$t("btn.deleteBtn")))]),_c('el-button',{on:{"click":function($event){_vm.transferDialog = false}}},[_vm._v(_vm._s(_vm.$t("btn.cancelBtn")))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }