<template>
  <div>
    <el-header style="height: 60px">
      <home-topbar :is-home="true" :is-personal="false"></home-topbar>
    </el-header>
    <div id="line_word_wrap" class="line_word_wrap">
      <top-bar :model-view="true" :fileData="fileData"></top-bar>

      <div class="line_word_content">
        <!--        <div class="left">-->
        <div class="catalog_wrap">
          <!--        </div>-->
          <el-tree
            :data="tree_data_line_word"
            :default-expand-all="true"
            :expand-on-click-node="false"
          >
            <div slot-scope="{ node, data }" :id="data.key">
              <!-- <span
                v-show="data.layer < 5"
                style="color: #2a82e4; margin-right: 5px"
                >{{ index_map[data.id] }}</span
              > -->
              <div class="word_line" @click="click_node(data.key)">
                <span
                  style="color: #004caa; font-weight: bold; font-size: 13px"
                  >{{ data.key }}</span
                >

                <span style="margin: 0px 10px">{{ data.topic }}</span>
                <div class="icon">
                  <i
                    class="iconfont icon-drawer"
                    @click.stop="show_drawer(data)"
                  ></i>
                </div>
              </div>

              <!-- <span
                v-if="see_review || process_review"
                :key="f6"
                :style="high_ligth_review(data)"
                >{{ data.topic }}</span
              >
              <span v-else>{{ data.topic }}</span> -->
            </div>
          </el-tree>

          <!-- <div class="hide">
            <div>
              <i class="iconfont icon-shouqi1"> </i>
            </div>
          </div> -->
        </div>
        <div class="resize" :title="$t('addAlert.collapse')"></div>
        <!--        <div class="right">-->
        <ModelView :nodeData="original_data"></ModelView>
        <!--        </div>-->
      </div>
    </div>

    <NodeDrawer
      :drawer.sync="drawer"
      :current_node="handle_node"
      :file_icon_name="file_icon_name"
      :drawer_loading="drawer_loading"
      :file_icon="file_icon_icon"
      :members-show="membersShow"
      :members="members"
      :show-test-plan="showTestPlan"
      @loading-finished="drawer_loading = false"
      @close="before_close_drawer"
    />
  </div>
</template>

<script>
import TopBar from "@/components/topbar";
import HomeTopbar from "@/components/homeTopbar";
import NodeDrawer from "@/components/nodeDrawer";
import ModelView from "./index.vue";
import vmson from "@/utils/vmson";
import { mapGetters } from "vuex";
import {
  get_node_use_node_key,
  get_allProjectMember,
} from "@/network/node/index.js";
import { getFile, getReleaseElements } from "@/network/home/index.js";
import { get_filetype_detail } from "@/network/fileType/index.js";

export default {
  name: "Lineword",
  components: {
    TopBar,
    HomeTopbar,
    NodeDrawer,
    ModelView,
  },
  data() {
    return {
      original_data: [],
      fileData: {},
      word_all_nodes: {},
      rawer_loading: false,
      handle_node: {},
      drawer: false,
      file_icon_icon: "",
      file_icon_name: "",
      membersShow: false,
      members: [],
      drawer_loading: false,
      get_data_loading: false,
    };
  },
  computed: {
    ...mapGetters(["fileType"]),
    showTestPlan() {
      console.log(this.original_data[0]);

      if (
        this.original_data[0] &&
        this.original_data[0].fileTypeId &&
        this.fileType
      ) {
        return this.fileType[this.original_data[0].fileTypeId]
          ? this.fileType[this.original_data[0].fileTypeId].testCase
          : false;
      }
      return false;
    },
    tree_data_line_word() {
      const arr = [];
      function search_children(data, root) {
        const items = [];
        for (let i = 0; i < data.length; i++) {
          if (root == data[i].parentid) {
            data[i].children = search_children(data, data[i].id);
            const temp_item = Object.assign(data[i], {
              label: data[i].topic,
              attachments: data[i].attachments,
            });
            items.push(temp_item);
          }
        }
        return items;
      }
      this.original_data.forEach((item) => {
        if (item.isroot) {
          arr.push(
            Object.assign(item, {
              label: item.topic,
              attachments: item.attachments,
            })
          );
          arr[0].children = search_children(this.original_data, "root");
        }
      });
      return arr;
    },
  },
  watch: {},
  created() {
    this.get_file_data();
  },
  mounted() {
    vmson.$on("highlight_left_meau_model_view", (s) => {
      document.querySelectorAll(".el-tree-node__content").forEach((ele) => {
        if (ele.classList.contains("high_light_model_view_key")) {
          ele.classList.remove("high_light_model_view_key");
        }
      });
      s.forEach((item) => {
        if (item && document.getElementById(item)) {
          document
            .getElementById(item)
            .parentNode.classList.add("high_light_model_view_key");
        }
      });
    });
    get_allProjectMember(this.get_pid()).then((res) => {
      this.members = res;
      this.membersShow = true;
    });
    this.bindMoveLine();
  },

  beforeDestroy() {},
  methods: {
    bindMoveLine() {
      const resizeFn = (element, position) => {
        let maxAllowWidth = window.innerWidth / 2;
        let minAllowWidth = 200;
        let resize = document.querySelector(".resize");
        resize.style[position] = "0";
        resize.addEventListener("mousedown", function (e) {
          let startX = e.pageX;
          let width = element.offsetWidth;

          resize.classList.add("active");
          document.addEventListener("mousemove", move);
          let timer = null;

          function move(e) {
            let moveX; // 左右这里切换计算顺序即可
            if (position === "left") moveX = startX - e.pageX;
            if (position === "right") moveX = e.pageX - startX;
            clearTimeout(timer);
            timer = setTimeout(() => {
              // element.style.maxWidth = width + moveX + "px";
              if (
                maxAllowWidth >= width + moveX &&
                width + moveX >= minAllowWidth
              ) {
                element.style.minWidth = width + moveX + "px";
              }
            }, 5);
          }

          document.addEventListener(
            "mouseup",
            function () {
              document.removeEventListener("mousemove", move);
              resize.classList.remove("active");
            },
            { once: true }
          );
        });
      };
      let left = document.querySelector(".catalog_wrap");
      resizeFn(left, "right");
    },
    before_close_drawer() {
      this.drawer = false;
      this.$nextTick(() => {
        this.get_file_data();
      });

      let intervalId = setInterval(() => {
        console.log("timer");
        if (this.get_data_loading) {
          clearInterval(intervalId);
          this.intervalId = null;
          vmson.$emit("model_view_outstanding_v2");
        }
      }, 500);
    },
    click_node(node) {
      vmson.$emit("left_meau_clicked_model_view", node);
    },
    show_drawer(node) {
      const jobAuthorities = this.$store.getters.jobAuthorities;
      if (jobAuthorities.indexOf("NODE_DETAIL_READ") === -1) {
        this.$message({
          message: this.$t("board.message.error"),
          type: "warning",
        });
        return;
      }
      this.drawer_loading = true;
      this.$nextTick(() => {
        get_node_use_node_key(this.get_pid(), node.key).then((res) => {
          this.handle_node = res;
          console.log(res);
          this.drawer = true;
        });
      });
    },
    get_file_data() {
      this.get_data_loading = false;

      let project_id = this.get_pid();
      let filr_key = this.$route.params.file_key;

      const get_file = getFile(project_id, filr_key);
      const get_release = getReleaseElements(project_id, filr_key);
      this.word_all_nodes = {};

      Promise.all([get_file, get_release]).then((result) => {
        get_filetype_detail(project_id, result[0].fileTypeId).then((res) => {
          this.file_icon_icon = res.icon;
          this.file_icon_name = res.name;
        });
        console.log(result[0]);
        this.file_icon_key = result[0].meta.key;
        this.topic = result[0].meta.name;
        result[0].data.forEach((item) => {
          result[1].forEach((i) => {
            if (i.nodeKey === item.key) {
              if (i.sprintId) {
                item["sprintId"] = i.sprintId;
              }
              i.baselineItemDtoList &&
                i.baselineItemDtoList.forEach((baseline) => {
                  if (baseline.lock && baseline.status === "ALREADY_STARTED") {
                    item["lock"] = baseline.lock;
                  }
                });
            }
          });
          this.word_all_nodes[item.id] = item;
        });
        result[0].data.sort(function (m, n) {
          if (m.index < n.index) return -1;
          else if (m.index > n.index) return 1;
          else return 0;
        });
        this.original_data = result[0].data;
        this.fileData = result[0];
        this.get_data_loading = true;
      });
    },
  },
};
</script>
<style lang="scss">
.high_light_model_view_key {
  background: #c5e1fc;
}
</style>
<style lang="scss" scoped>
.line_word_content {
  display: flex;
}
.resize {
  cursor: e-resize;
  min-width: 6px;
  font-size: 32px;
  user-select: none;
}

.resize.active {
  background-color: #0090f1;
}

.resize:hover {
  background-color: #0090f1;
}
.word_line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.catalog_wrap {
  overflow-y: auto;
  height: calc(100vh - 180px);
  min-width: 200px;
  width: 200px;
  // overflow: auto;

  .el-tree {
    // height: calc(100% - 25px);
    height: 100%;
  }
  .hide {
    height: 25px;
    cursor: pointer;
    font-size: 20px;
    text-align: left;
    width: 100%;
    i {
      font-size: 20px;
    }
  }
  .hide :hover {
    background: #a6a6a6;
  }
}
</style>
