import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 获取文件类型覆盖度
export function get_fileType_coverage(projectId, fileTypeId) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes/${fileTypeId}/nodeCoverage`,
    method: 'post'
  })
}

// 文件覆盖度检测
export function get_file_coverage(projectId, fileKey, relationFileTypeIds) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/${fileKey}/nodeCoverage?${relationFileTypeIds}`,
    // params: {
    //   relationFileTypeIds
    // },
    method: 'get'
  })
}

// 忽略文件覆盖度检测
export function ignoreNodeCoverage(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/nodeCoverage/ignore`,
    method: 'post',
    data: params.body,
  })
}

export function getNodeCoverage(params) {
  return request({
    url: `/${serviceConfig['mindmap-service']}/${params.projectId}/nodeCoverage/ignore`,
    method: 'get',
  })
}
// 上面这个接口的分页版 特性环境
export function getNodeCoverage_page(projectId, params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/vmodel/nodeCoverage/ignore?page=${params.page}&size=${params.size}`,
    method: 'post',
    data: params.data
  })
}

export function deleteNodeCoverage(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/nodeCoverage/ignore`,
    method: 'delete',
    data: params.body,
  })
}