<template>
  <div>
    <el-header style="height: 60px">
      <home-topbar :is-home="true" :is-personal="false"></home-topbar>
    </el-header>
    <div id="line_word_wrap" class="line_word_wrap">
      <top-bar
        :model-view="true"
        :tree-view="true"
        :fileData="fileData"
      ></top-bar>

      <div class="line_word_content">
        <div class="catalog_wrap">
          <el-tree
            :data="tree_data_line_word"
            :default-expand-all="true"
            :expand-on-click-node="false"
            @node-click="click_node"
          >
            <div :id="data.key" slot-scope="{ node, data }">
              <div class="word_line" @click="click_node(data)">
                <span
                  v-show="data.layer < 5"
                  style="color: #2a82e4; margin-right: 5px"
                  >{{ index_map[data.id] }}</span
                >
                <!-- <span
                  style="color: #004caa; font-weight: bold; font-size: 13px"
                  >{{ data.key }}</span
                > -->
                <span style="margin: 0px 10px">{{ data.topic }}</span>
              </div>
            </div>
          </el-tree>
        </div>
        <div class="resize" :title="$t('addAlert.collapse')"></div>
        <!--        <div class="right">-->

        <div
          v-if="drawer_loading && icon_loading"
          :key="drawkey"
          style="background-color: #f0f2f5"
          class="drawer_wrap"
        >
          <!-- 节点key 名称 状态 -->
          <el-row class="header_content">
            <el-col :span="10" :offset="2" class="header_node">
              <span style="display: flex">
                <div class="box_item_l">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="file_icon_name"
                    placement="top"
                  >
                    <span style="background-color: #014caa">
                      <i
                        v-if="file_icon !== '-1'"
                        class="iconfont"
                        :class="'icon-a-' + file_icon"
                        style="color: #fff"
                      ></i>
                      <span v-else style="color: white; font-weight: 700">{{
                        file_icon_name.slice(0, 1)
                      }}</span>
                    </span>
                  </el-tooltip>
                </div>
                <div
                  class="box_item_r"
                  @mouseenter="copy_zdh_key_open"
                  @mouseleave="copy_zdh_key_close"
                >
                  <span
                    class="box_font"
                    style="cursor: pointer"
                    @click="jump_node(current_node)"
                    >{{ current_node.key
                    }}<i
                      v-show="show_copy_zdh_key_file_node"
                      class="iconfont icon-a-Frame1 copy_zdh_key"
                      style="padding-left: 5px; color: #004caa; cursor: pointer"
                      @click.stop="copy_zdh_key_file_node(current_node.key)"
                  /></span>
                </div>
              </span>
              <span
                style="display: flex; justify-content: center; align-items: top"
              >
                <i
                  style="
                    font-size: 20px;
                    margin-left: 16px;
                    cursor: pointer;
                    color: rgb(48, 100, 143);
                    padding-top: 5px;
                  "
                  class="iconfont icon-node_link"
                  @click="nodeLink"
                >
                </i>
              </span>
              <el-tooltip
                v-if="lock_line.length"
                effect="light"
                :content="
                  $t('scrum.newSprint.info1') +
                  `${lock_line.join(',')}` +
                  $t('scrum.newSprint.info2')
                "
                placement="bottom"
              >
                <div class="baseline_lock">
                  <i
                    class="iconfont icon-locked"
                    style="color: rgb(48, 100, 143)"
                  >
                  </i>
                </div>
              </el-tooltip>
            </el-col>

            <el-tooltip placement="left">
              <div slot="content" v-html="addbr(current_node.topic)"></div>
              <el-col :span="14">
                <div
                  class="node-name"
                  @mouseenter="isShowTooltip($event, 'node_name')"
                >
                  <span
                    v-show="node_name_span"
                    ref="node_name"
                    v-limits-of-authority="'NODE_EDIT'"
                    onselectstart="return false;"
                    style="-moz-user-select: none; white-space: break-spaces"
                    @dblclick="
                      current_node.lock ? '' : edit_nodename(current_node.topic)
                    "
                    >{{ current_node.topic }}</span
                  >
                  <el-input
                    v-show="!node_name_span"
                    ref="input_panel"
                    v-model="input_name"
                    type="textarea"
                    :rows="2"
                    :placeholder="$t('placeholder.input')"
                    @keyup.enter="away"
                    @blur="away"
                  ></el-input>
                </div>
              </el-col>
            </el-tooltip>
            <el-col :offset="4" :span="3">
              <node-status-select
                v-if="current_node.key"
                ref="node_status_select"
                :node-info="current_node"
                :node-key="current_node.key"
                @show_workflow="show_workflow"
                @headCallBack="headCallBack"
              ></node-status-select>
            </el-col>
          </el-row>
          <el-row>
            <node-base-info-row
              v-if="membersShow"
              ref="base-info"
              :node-info="current_node"
              :project-id="get_pid()"
              :members="members"
              :is-sprint="false"
            ></node-base-info-row>
          </el-row>

          <el-row v-if="membersShow" style="margin-top: 20px">
            <node-release-info-row
              ref="release-info"
              :node-key="current_node.key"
              :node-info="current_node"
            ></node-release-info-row>
          </el-row>
          <el-row v-if="showTestPlan" style="margin-top: 20px">
            <node-test-plan
              :node-key="current_node.key"
              :lock="current_node.lock"
              :node-uuid="current_node.nodeUuid"
            ></node-test-plan>
          </el-row>
          <el-row style="margin-top: 20px">
            <gantt-node-detail-row
              :node-key="current_node.key"
            ></gantt-node-detail-row>
          </el-row>
          <el-row style="margin-top: 20px">
            <node-description-row
              :lock="current_node.lock"
              :project-id="get_pid()"
              :node-key="current_node.key"
              :description="current_node.description"
              :attachments="current_node.attachments"
              @update_description_content="update_description_content"
            ></node-description-row>
          </el-row>
          <el-row style="margin-top: 35px">
            <relation-graph-row
              :node_key="current_node.key"
              :node-info="current_node"
            ></relation-graph-row>
          </el-row>
          <el-row v-if="show_third_web" style="margin-top: 35px">
            <NodeThirdWebRow
              :node_key="current_node.key"
              :node-info="current_node"
            ></NodeThirdWebRow>
          </el-row>
          <el-row style="margin-top: 35px">
            <activity-row
              ref="ar"
              :members="members"
              :node-key="current_node.key"
              :node-uuid="current_node.nodeUuid"
              :file-test="showTestPlan"
              :node-info="current_node"
            ></activity-row>
          </el-row>
        </div>

        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/topbar";
import HomeTopbar from "@/components/homeTopbar";
import vmson from "@/utils/vmson";
import { mapGetters } from "vuex";
import {
  get_node_use_node_key,
  get_allProjectMember,
} from "@/network/node/index.js";
import { getFile, getReleaseElements } from "@/network/home/index.js";
import { get_filetype_detail } from "@/network/fileType/index.js";
import ViewWorkflow from "@/components/mindmap/ViewWorkflow";
import NodeStatusSelect from "@/components/mindmap/node_detail/NodeStatusSelect.vue";
import NodeBaseInfoRow from "@/components/mindmap/node_detail/NodeBaseInfoRow.vue";
import NodeReleaseInfoRow from "@/components/mindmap/node_detail/NodeReleaseInfoRow.vue";
import NodeDescriptionRow from "@/components/mindmap/node_detail/NodeDescriptionRow.vue";
import NodeTestPlan from "@/components/mindmap/node_detail/NodeTestPlan.vue";
import GanttNodeDetailRow from "@/components/mindmap/node_detail/GanttNodeDetailRow.vue";
import RelationGraphRow from "@/components/mindmap/node_detail/RelationGraphRow.vue";
import ActivityRow from "@/components/mindmap/node_detail/ActivityRow.vue";
import NodeThirdWebRow from "@/components/mindmap/node_detail/NodeThirdWebRow.vue";
import { get_component_license } from "@/network/license/index.js";
import { only_set_property } from "@/network/node/index.js";
import { get_file } from "@/network/baseline/index.js";
import { inProgressBaselines } from "@/network/merge/index";
export default {
  components: {
    TopBar,
    HomeTopbar,
    NodeBaseInfoRow,
    NodeDescriptionRow,
    NodeStatusSelect,
    NodeThirdWebRow,
    NodeReleaseInfoRow,
    NodeTestPlan,
    GanttNodeDetailRow,
    RelationGraphRow,
    ActivityRow,
  },
  data() {
    return {
      original_data: [],
      fileData: {},
      word_all_nodes: {},
      rawer_loading: false,
      handle_node: {},
      drawer: false,
      file_icon_icon: "",
      file_icon_name: "",
      membersShow: false,
      members: [],
      get_data_loading: false,
      current_node: null,
      show_third_web: false,
      show_copy_zdh_key_file_node: false,
      node_name_span: true,
      input_name: "",
      input_name1: "",
      drawer_loading: false,
      index_map: {},
      icon_loading: false,
      drawkey: 0,
    };
  },
  computed: {
    ...mapGetters(["fileType"]),
    tree_data_line_word() {
      const arr = [];
      function search_children(data, root) {
        const items = [];
        for (let i = 0; i < data.length; i++) {
          if (root == data[i].parentid) {
            data[i].children = search_children(data, data[i].id);
            const temp_item = Object.assign(data[i], {
              label: data[i].topic,
              attachments: data[i].attachments,
            });
            items.push(temp_item);
          }
        }
        return items;
      }
      this.original_data.forEach((item) => {
        if (item.isroot) {
          arr.push(
            Object.assign(item, {
              label: item.topic,
              attachments: item.attachments,
            })
          );
          arr[0].children = search_children(this.original_data, "root");
        }
      });
      return arr;
    },
    showTestPlan() {
      if (
        this.original_data[0] &&
        this.original_data[0].fileTypeId &&
        this.fileType
      ) {
        return this.fileType[this.original_data[0].fileTypeId]
          ? this.fileType[this.original_data[0].fileTypeId].testCase
          : false;
      }
      return false;
    },
  },
  watch: {
    tree_data_line_word() {
      let d =
        this.tree_data_line_word.length > 0
          ? this.tree_data_line_word[0].children
          : [];
      let m = {};
      m["root"] = "1";
      d = JSON.parse(JSON.stringify(d));
      while (d.length > 0) {
        let n = d.shift();
        if (n.layer < 5) {
          m[n.id] = m[n.parentid] + "." + n.index;
          d = [...d, ...(n.children || [])];
        }
      }
      this.index_map = m;
    },
    current_node(newVal) {
      this.lock_line = [];
      const params = {
        projectId: this.get_pid(),
        nodeKey: newVal.key,
      };
      inProgressBaselines(params).then((res) => {
        res &&
          res.forEach((line) => {
            if (line.lock) {
              this.lock_line.push(line.name);
            }
          });

        this.drawer_loading = true;
        this.drawkey++;
      });
    },
  },
  created() {
    this.get_file_data();
    get_component_license().then((res) => {
      for (let j of res) {
        if (j.feature == "THIRD_LINK") {
          this.show_third_web = true;
        }
      }
    });
  },
  mounted() {
    vmson.$on("highlight_left_meau_model_view", (s) => {
      document.querySelectorAll(".el-tree-node__content").forEach((ele) => {
        if (ele.classList.contains("high_light_model_view_key")) {
          ele.classList.remove("high_light_model_view_key");
        }
      });
      s.forEach((item) => {
        if (item && document.getElementById(item)) {
          document
            .getElementById(item)
            .parentNode.classList.add("high_light_model_view_key");
        }
      });
    });
    get_allProjectMember(this.get_pid()).then((res) => {
      this.members = res;
      this.membersShow = true;
    });
    this.bindMoveLine();
  },

  beforeDestroy() {},
  methods: {
    copy_zdh_key_open() {
      this.show_copy_zdh_key_file_node = true;
    },
    copy_zdh_key_close() {
      this.show_copy_zdh_key_file_node = false;
    },
    copy_zdh_key_file_node(key) {
            if (navigator.clipboard) {
        navigator.clipboard.writeText(key);
      } else {
        const textarea = document.createElement("textarea");
        textarea.value = key;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      }

      this.$message({
        type: "success",
        message: this.$t("AI.tip14"),
      });
    },
    nodeLink() {
      get_file(this.get_pid(), this.current_node.fileId).then((result) => {
        const { href } = this.$router.resolve({
          path: `/${this.get_pid()}/myMind/${result.key}`,
          query: {
            node_id: this.current_node.id, // 节点id
          },
        });
        window.open(href, "_blank");
      });
    },
    jump_node(item) {
      const { href } = this.$router.resolve({
        name: "node_detail",
        params: {
          projectId: this.get_pid(),
          nodeKey: item.key,
        },
      });
      window.open(href, "_blank");
    },
    blur_all(event) {
      if (event.target.nodeName === "DIV") {
        this.$refs["base-info"].item_blur();
        this.$refs["release-info"].item_blur();
        this.$refs["node_status_select"].showWorkflow = false;
      }
    },
    addbr(topic) {
      // 设置变量存储返回值
      let newstr = "";
      // 如果长度超过20，就要截取插入字符
      if (topic && topic.length > 20) {
        // 第一次截取
        newstr = topic.slice(0, 20) + "<br/>";
        // 闭包再次调用，如果截取后的字段长度依然超过20，再次调用，如果没有直接返回当前值
        return newstr + this.addbr(topic.slice(20));
      } else {
        // 直接返回当前值
        return topic;
      }
    },
    /*双击节点名进行编辑 */
    edit_nodename(name) {
      this.node_name_span = false;
      this.input_name = name;
      this.$nextTick(() => {
        this.$refs["input_panel"].focus();
      });
      this.input_name1 = this.input_name;
    },
    show_workflow() {
      this.workflow_dialog_visible = true;
    },
    headCallBack() {
      this.$emit("loading-finished");
    },
    away() {
      this.node_name_span = true;
      if (/^\s*$/.test(this.input_name)) {
        this.name = this.input_name1;
      } else {
        this.name = this.input_name;
      }
      /*访问接口进行节点名称修改 */
      get_node_use_node_key(this.get_pid(), this.current_node.key).then(
        (res) => {
          this.$emit("update_current_node", res);
          let obj = res;
          obj["topic"] = this.name;
          const params = {
            projectId: this.get_pid(),
            nodeKey: res.key,
            data: {
              isCustom: false,
              fieldId: "topic",
              value: this.name,
            },
          };
          only_set_property(params).then((res) => {
            this.current_node.topic = this.name;
          });
        }
      );
    },
    update_description_content(description) {
      this.$emit("update_description_content", description);
      // this.current_node.description = description;
    },
    isShowTooltip(e, refname) {
      const clientHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;
      const pWidth = refname
        ? this.$refs[refname].parentNode.offsetWidth
        : e.target.children[0].offsetWidth;
      const cWidth = refname
        ? this.$refs[refname].parentNode.offsetWidth
        : e.target.children[0].offsetWidth;
      // 文字超出隐藏并弹出tooltip提示，文字未超出则不弹出tooltip提示的判断条件
      if (scrollHeight > clientHeight && pWidth >= cWidth) {
        this.is_show_name = false;
        this.is_show_topic = false;
      } else {
        this.is_show_name = true;
        this.is_show_topic = true;
      }
    },
    bindMoveLine() {
      const resizeFn = (element, position) => {
        let maxAllowWidth = window.innerWidth / 2;
        let minAllowWidth = 200;
        let resize = document.querySelector(".resize");
        resize.style[position] = "0";
        resize.addEventListener("mousedown", function (e) {
          let startX = e.pageX;
          let width = element.offsetWidth;

          resize.classList.add("active");
          document.addEventListener("mousemove", move);
          let timer = null;

          function move(e) {
            let moveX; // 左右这里切换计算顺序即可
            if (position === "left") moveX = startX - e.pageX;
            if (position === "right") moveX = e.pageX - startX;
            clearTimeout(timer);
            timer = setTimeout(() => {
              // element.style.maxWidth = width + moveX + "px";
              if (
                maxAllowWidth >= width + moveX &&
                width + moveX >= minAllowWidth
              ) {
                element.style.minWidth = width + moveX + "px";
              }
            }, 5);
          }

          document.addEventListener(
            "mouseup",
            function () {
              document.removeEventListener("mousemove", move);
              resize.classList.remove("active");
            },
            { once: true }
          );
        });
      };
      let left = document.querySelector(".catalog_wrap");
      resizeFn(left, "right");
    },
    click_node(node) {
      // this.drawer_loading = false;
      // this.current_node = node;
      this.get_file_data(node.id, node.key);
    },

    get_file_data(id, node_key) {
      id = id ? id : "root";
      this.get_data_loading = false;

      let project_id = this.get_pid();
      let filr_key = this.$route.params.file_key;

      const get_file = getFile(project_id, filr_key);
      const get_release = getReleaseElements(project_id, filr_key);
      this.word_all_nodes = {};

      Promise.all([get_file, get_release]).then((result) => {
        get_filetype_detail(project_id, result[0].fileTypeId).then((res) => {
          this.file_icon = res.icon;
          this.file_icon_name = res.name;
          this.icon_loading = true;
        });
        this.file_icon_key = result[0].meta.key;
        this.topic = result[0].meta.name;
        result[0].data.forEach((item) => {
          if (id == item.id) {
            this.current_node = item;
          }
          result[1].forEach((i) => {
            if (i.nodeKey === item.key) {
              if (i.sprintId) {
                item["sprintId"] = i.sprintId;
              }
              i.baselineItemDtoList &&
                i.baselineItemDtoList.forEach((baseline) => {
                  if (baseline.lock && baseline.status === "ALREADY_STARTED") {
                    item["lock"] = baseline.lock;
                  }
                });
            }
          });
          this.word_all_nodes[item.id] = item;
        });
        result[0].data.sort(function (m, n) {
          if (m.index < n.index) return -1;
          else if (m.index > n.index) return 1;
          else return 0;
        });
        this.original_data = result[0].data;
        this.fileData = result[0];

        // this.current_node = this.original_data[0];
        this.get_data_loading = true;

        this.$nextTick(() => {
          if (node_key) {
            let tree = document.querySelectorAll(".el-tree-node__content");
            let flag = null;
            for (let i of tree) {
              i.style.background = "white";
              for (let n of i.children) {
                if (n.id == node_key) {
                  flag = i;
                }
              }
            }
            console.log(flag);
            flag.style.background = "#c0c4cc";
          }
        });
      });
    },
  },
};
</script>
<style lang="scss">
.high_light_model_view_key {
  background: #c5e1fc;
}
</style>
<style lang="scss" scoped>
.drawer_wrap {
  padding: 20px;
  width: 100%;
  height: 80vh;
  overflow: auto;
}

.header_content {
  display: flex;
  justify-content: flex-start;
  align-items: top;
  text-align: left;
  margin: 20px auto 0 auto;
  .header_node {
    display: flex;
    align-items: top;
    margin-left: 0 !important;
    // margin-right: 20px;
  }
  ::v-deep .el-input__inner {
    width: 180px;
  }
}
.top-node-id {
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 195, 0, 1);
  background-color: rgba(229, 229, 229, 1);
  width: 120px;
  height: 28px;
  border-radius: 2px;
  text-align: center;
  span {
    vertical-align: middle;
    line-height: 28px;
  }
}

.node-name {
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: -webkit-ellipsis-lastline;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.baseline_lock {
  color: rgba(166, 166, 166, 1);
  font-size: 14px;
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}
.box_item_l {
  height: 30px;
  border: 1px solid #014caa;
  border-radius: 5px 0 0 5px;
  padding: 6px 14px;
  background-color: #014caa;
  display: flex;
  justify-items: center;
  align-items: center;
}
.box_item_r {
  height: 30px;
  border: 1px solid rgba(219, 219, 219, 1);
  border-radius: 0 5px 5px 0;
  padding: 0 14px;
  display: flex;
  justify-items: center;
  align-items: center;
  .box_font {
    font-size: 18px;
    color: rgba(255, 195, 0, 1);
    font-weight: 700;
  }
}
.line_word_content {
  display: flex;
}
.resize {
  cursor: e-resize;
  min-width: 6px;
  font-size: 32px;
  user-select: none;
}

.resize.active {
  background-color: #0090f1;
}

.resize:hover {
  background-color: #0090f1;
}
.word_line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.catalog_wrap {
  overflow-y: auto;
  height: calc(100vh - 180px);
  min-width: 200px;
  width: 200px;
  // overflow: auto;

  .el-tree {
    // height: calc(100% - 25px);
    height: 100%;
  }
  .hide {
    height: 25px;
    cursor: pointer;
    font-size: 20px;
    text-align: left;
    width: 100%;
    i {
      font-size: 20px;
    }
  }
  .hide :hover {
    background: #a6a6a6;
  }
}
</style>
