<template>
  <div class="home">
    <!--  图标链接  -->
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    />
    <el-header style="height: 60px">
      <home-topbar :is-home="true" :is-personal="false" @child="getheight">
      </home-topbar>
    </el-header>
    <el-container>
      <el-aside ref="aside" class="left-menu" style="width: auto">
        <el-menu
          ref="menu"
          class="el-menu-vertical-demo"
          text-color="#f0f2f5"
          :default-active="activeIndex"
          active-text-color="#f0f2f5"
          :collapse="isCollapse"
          @open="toMenuPage"
          @select="handleSelect"
        >
          <draggable
            v-if="showmenu"
            style="break-inside: avoid"
            chosen-class="chosen"
            group="chart"
            animation="300"
            :scroll="true"
            @start="onStart"
            @end="onEnd"
          >
            <template v-for="(menuItem, index) in menuList">
              <el-submenu
                v-if="
                  menuItem.menuIndex &&
                  menuItem.name !== 'safe' &&
                  menuItem.name !== 'shortcuts'
                "
                v-show="(menuItem.role && role === 'MANAGER') || !menuItem.role"
                :index="menuItem.menuIndex"
                :key="index"
              >
                <template slot="title">
                  <div>
                    <i :class="`iconfont ${menuItem.icon}`"></i>
                    <span slot="title" v-if="!isCollapse">{{
                      menuItem.title
                    }}</span>
                  </div>
                </template>
                <div
                  v-for="(submenuItem, submenuIndex) in menuItem.children"
                  :key="submenuIndex"
                >
                  <el-menu-item
                    v-if="!submenuItem.children"
                    v-show="!submenuItem.menuIndex"
                    :key="submenuIndex"
                    :index="submenuItem.url"
                  >
                    <i :class="`iconfont ${submenuItem.icon}`"></i>
                    <span slot="title">{{ submenuItem.title }}</span>
                  </el-menu-item>
                  <el-submenu
                    v-if="submenuItem.children"
                    :index="submenuItem.menuIndex"
                  >
                    <template slot="title">
                      <div>
                        <i :class="`iconfont ${submenuItem.icon}`"></i>
                        <span slot="title">{{ submenuItem.title }}</span>
                      </div>
                    </template>
                    <el-menu-item
                      v-for="(
                        secondMenuItem, secondMenuIndex
                      ) in submenuItem.children"
                      :key="secondMenuIndex"
                      :index="secondMenuItem.url"
                    >
                      <span slot="title">{{ secondMenuItem.title }}</span>
                    </el-menu-item>
                  </el-submenu>
                </div>
              </el-submenu>
              <el-submenu
                v-if="menuItem.menuIndex && menuItem.name === 'safe'"
                v-show="showDFMEA || showtara || showhara"
                :index="menuItem.menuIndex"
                class="safe"
                :key="index"
              >
                <template slot="title">
                  <div>
                    <i class="iconfont icon-gongnenganquan"></i>
                    <span slot="title" v-if="!isCollapse"
                      >{{ $t("menu.FunctionalSafety") }}

                      <span
                        v-show="unHandleDFMEA > 0 || unHandleTARA > 0"
                        class="safe_number"
                      ></span>
                    </span>
                  </div>
                </template>
                <div
                  v-for="(submenuItem, submenuIndex) in menuItem.children"
                  :key="submenuIndex"
                >
                  <el-menu-item
                    v-show="
                      (submenuItem.name === 'dfmea' && showDFMEA) ||
                      (submenuItem.name === 'hara' && showhara) ||
                      (submenuItem.name === 'tara' && showtara)
                    "
                    :index="submenuItem.url"
                    class="dfmeamenu"
                  >
                    <i :class="`iconfont ${submenuItem.icon}`"></i>
                    <span slot="title"
                      >{{ submenuItem.title }}
                      <div
                        v-if="submenuItem.name === 'dfmea'"
                        v-show="unHandleDFMEA > 0"
                        class="number"
                      >
                        {{ unHandleDFMEA > 99 ? "99+" : unHandleDFMEA }}
                      </div>
                      <div
                        v-if="submenuItem.name === 'tara'"
                        v-show="unHandleTARA > 0"
                        class="number"
                        style="color: white"
                      >
                        {{ unHandleTARA > 99 ? "99+" : unHandleTARA }}
                      </div>
                    </span>
                  </el-menu-item>
                </div>
              </el-submenu>
              <el-submenu
                v-if="menuItem.menuIndex && menuItem.name === 'shortcuts'"
                :index="menuItem.menuIndex"
                :key="index"
              >
                <template slot="title">
                  <div>
                    <i class="iconfont icon-a-huaban12"></i>
                    <span slot="title" v-if="!isCollapse">{{
                      $t("menu.shortcuts")
                    }}</span>
                  </div>
                </template>
                <el-menu-item
                  v-for="(item, index) in shortcutsList"
                  :key="item.url"
                  :index="getUlid()"
                >
                  <div @click.stop.prevent="openOthersUrl(item.url)">
                    <i class="iconfont icon-a-huaban6"></i>
                    <span
                      slot="title"
                      style="
                        display: inline-block;
                        width: 60%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                      >{{ item.name }}
                    </span>
                    <i
                      v-show="role === 'MANAGER'"
                      class="iconfont icon-a-huaban5"
                      style="float: right"
                      @click.stop.prevent="deleteShortcuts(item, index)"
                    ></i>
                  </div>
                </el-menu-item>
                <el-menu-item v-show="role === 'MANAGER'" :index="getUlid()">
                  <div @click.stop.prevent="addShortcuts">
                    <i class="iconfont icon-a-huaban3"></i>
                  </div>
                  <span slot="title"></span>
                </el-menu-item>
              </el-submenu>
              <el-menu-item
                v-if="!menuItem.menuIndex"
                :index="menuItem.url"
                :key="index"
                :class="{
                  pingshenmenu:
                    menuItem.name === 'pingshen' ||
                    menuItem.name === 'changeRequest',
                }"
                v-show="
                  (menuItem.name === 'knowledge' &&
                    jobAuthorities &&
                    jobAuthorities.indexOf('KNOWLEDGE_ACCESS') !== -1) ||
                  (menuItem.name !== 'knowledge' &&
                    ((menuItem.role && role === 'MANAGER') || !menuItem.role))
                "
              >
                <i :class="`iconfont ${menuItem.icon}`"></i>
                <span slot="title"
                  >{{ menuItem.title }}
                  <div
                    v-if="menuItem.name === 'pingshen'"
                    v-show="unHandleTask > 0 && !isCollapse"
                    class="number"
                  >
                    {{ unHandleTask > 99 ? "99+" : unHandleTask }}
                  </div>
                  <div
                    v-if="menuItem.name === 'changeRequest'"
                    v-show="unHandleRequest > 0 && !isCollapse"
                    class="number"
                  >
                    {{ unHandleRequest > 99 ? "99+" : unHandleRequest }}
                  </div>
                </span>
              </el-menu-item>
            </template>
          </draggable>
        </el-menu>
        <div
          v-if="show_guide && show_all_guide && !isCollapse"
          class="guide_zdh"
          @click="goToYindao"
        >
          <div class="header_guide">
            <div class="p1">{{ $t("guide_new.t1") }}</div>
            <div class="p2" @click.stop="hide_guide">
              <i class="el-icon-close"></i>
            </div>
          </div>
          <div class="b">
            {{ $t("guide_new.t3") }} {{ b }} M {{ $t("guide_new.t4") }}
          </div>
          <el-progress
            class="progress"
            :percentage="percent"
            :show-text="false"
            :stroke-width="12"
            color="#2A82E4"
          ></el-progress>
          <div class="process">{{ $t("guide_new.t67") }}{{ percent }}%</div>
        </div>
        <div v-if="show_guide && show_all_guide && isCollapse" class="guide_btn">
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">
              {{ $t("guide_new.t1") }}<br />{{ $t("guide_new.t68") }}
            </div>
            <i
              class="iconfont icon-xinshouyindao"
              style="font-size: 40px; color: #fff"
              @click="goToYindao"
            ></i>
          </el-tooltip>
        </div>
        <div v-if="show_guide && !show_all_guide" class="guide_btn">
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">
              {{ $t("guide_new.t1") }}<br />{{ $t("guide_new.t68") }}
            </div>
            <i
              class="iconfont icon-xinshouyindao"
              style="font-size: 40px; color: #fff"
              @click="goToYindao"
            ></i>
          </el-tooltip>
        </div>
        <el-menu
          text-color="#f0f2f5"
          class="test-collapse"
          :collapse="isCollapse"
        >
          <el-menu-item
            style="display: flex; align-items: center"
            index="1"
            @click="collpaseAside"
          >
            <i
              v-if="!isCollapse"
              class="iconfont icon-shouqi1"
              style="font-size: 20px; margin-right: 12px; color: #fff"
            ></i>
            <i
              v-else
              class="iconfont icon-zhedie1"
              style="font-size: 20px; color: #fff"
            ></i>
            <span v-show="isCollapse" slot="title">{{
              $t("menu.unfold")
            }}</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main :style="is_guide ? 'padding:0' : 'padding-left: 35px'">
        <router-view :user-list="userList"></router-view>
      </el-main>
    </el-container>
    <AIdialog></AIdialog>
    <el-dialog
      :visible.sync="addShortcutsdialog"
      width="25%"
      :title="$t('menu.addShortcut')"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-form
        ref="shortcutsForm"
        label-width="80px"
        label-position="left"
        :model="shortcutsForm"
        :rules="shortcutsRules"
      >
        <el-form-item :label="$t('menu.link')" prop="url">
          <el-input v-model="shortcutsForm.url"></el-input>
        </el-form-item>
        <el-form-item :label="$t('menu.name')" prop="name">
          <el-input v-model="shortcutsForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="info"
          size="small"
          @click="addShortcutsdialog = false"
          >{{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button type="primary" size="small" @click="saveShortcuts">{{
          $t("btn.newBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import vmson from "@/utils/vmson";
import HomeTopbar from "@/components/homeTopbar";
import AIdialog from "@/components/AIdialog";
import { get_tenant_user_list } from "@/network/user/index.js";
import { getUnhandleTask } from "../network/collaboration/index.js";
import { getCount } from "@/network/changeRequests/index.js";
import { get_allRiskAnalysisPage } from "@/network/tara/index.js";
import { notComplete } from "@/network/safety/index";
import { get_component_license } from "@/network/license";
import { getShortcuts, addShortcuts, deleteShortcuts } from "@/network/home";
import { mapGetters } from "vuex";
import { get_ed, get_b } from "@/network/guide/index";
import { ulid } from "ulid";
import draggable from "vuedraggable";
//临时代码
import config from "@/../config/index";

export default {
  name: "Home",
  components: { HomeTopbar, AIdialog, draggable },
  data() {
    return {
      percent: 0,
      b: 0,
      noActive: "2",
      isCollapse: false,
      dynamicTags: [],
      inputVisible: false,
      projectId: [],
      inputValue: "",
      value: null,
      userList: [],
      imageUrl:
        "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
      nickUrl: [],
      top: 1,
      num: 1,
      height: 1,
      activeIndex: "",
      haveUnread: false,
      mindMapId: null,
      show_guide: true,
      show_all_guide: true,
      user_create_time: "",
      form: {
        //新建文件列表
        name: "",
        region: "",
      },
      form_name: "",
      form_options: [],
      fileTypeId: "",
      role: "",
      unHandleTask: 0,
      unHandleDFMEA: 0,
      unHandleTARA: 0,
      unHandleRequest: 0,
      licenseList: [],
      shortcutsList: [],
      addShortcutsdialog: false,
      shortcutsForm: {
        url: "",
        name: "",
      },
      shortcutsRules: {
        name: [
          {
            required: true,
            message: this.$t("projectDetails.message.warn2"),
            trigger: "blur",
          },
        ],
        url: [
          {
            required: true,
            message: this.$t("projectDetails.message.warn2"),
            trigger: "blur",
          },
        ],
      },
      // 左菜单数据体
      showmenu: true,
      menuList: [],
      tempMenuList: [
        {
          url: "/home/my_file",
          icon: "icon-my_file",
          title: this.$t("menu.file"),
        },
        {
          url: "/home/node",
          icon: "icon-jiedian",
          title: this.$t("menu.node"),
        },
        {
          menuIndex: "1",
          icon: "icon-fabu_w",
          title: this.$t("menu.release"),
          children: [
            {
              url: "/home/release/baseline",
              icon: "icon-jixian",
              title: this.$t("menu.baseline"),
            },
            {
              url: "/home/release/version",
              icon: "icon-banben",
              title: this.$t("menu.version"),
            },
            {
              url: "/home/release/test",
              icon: "icon-jiance",
              title: this.$t("menu.test"),
            },
          ],
        },
        {
          url: "/home/my_collaboration",
          icon: "icon-my_colla",
          title: this.$t("addMyColla.review"),
          name: "pingshen",
        },
        {
          url: "/home/my_change_request",
          icon: "icon-biangeng_w",
          title: this.$t("menu.changeRequest"),
          name: "changeRequest",
        },
        {
          menuIndex: "4",
          icon: "icon-gongnenganquan",
          title: this.$t("menu.FunctionalSafety"),
          name: "safe",
          children: [
            {
              url: "/home/functionalSafety/dfmea",
              name: "dfmea",
              icon: "icon-dfmea",
              title: this.$t("menu.DFMEA"),
            },
            {
              url: "/home/functionalSafety/hara",
              name: "hara",
              icon: "icon-hara",
              title: "HARA",
            },
            {
              url: "/home/functionalSafety/tara",
              name: "tara",
              icon: "icon-tara",
              title: "TARA",
            },
          ],
        },
        {
          url: "/home/board",
          icon: "icon-minjiekanban",
          title: this.$t("menu.board"),
        },
        {
          url: "/home/gantt",
          icon: "icon-a-gantt2",
          title: this.$t("gantt.info1"),
        },
        {
          url: "/knowledge",
          icon: "icon-knowledge",
          title: this.$t("certificate.KnowledgeBase"),
          name: "knowledge",
        },
        {
          url: "/home/chart",
          icon: "icon-shujutongji",
          title: this.$t("menu.chart"),
        },
        {
          menuIndex: "5",
          icon: "icon-a-huaban12",
          title: this.$t("menu.shortcuts"),
          name: "shortcuts",
        },
        {
          url: "/home/bin",
          icon: "icon-huishouzhan",
          title: this.$t("menu.bin"),
          name: "bin",
          role: true,
        },
        {
          menuIndex: "2",
          icon: "icon-a-setting1",
          title: this.$t("menu.setting"),
          role: true,
          children: [
            {
              url: "/home/setting/user",
              icon: "icon-user1",
              title: this.$t("menu.user"),
            },
            {
              url: "/home/permission",
              icon: "icon-permission",
              title: this.$t("menu.permission"),
            },
            {
              menuIndex: "3",
              icon: "icon-wenjian",
              title: this.$t("menu.settingFile"),
              children: [
                {
                  url: "/home/setting/file_type/work_flow",
                  title: this.$t("menu.fileType"),
                },
                {
                  url: "/home/setting/file_type/field_lab",
                  title: this.$t("menu.fieldLab"),
                },
                {
                  url: "/home/setting/file_type/pingshen",
                  title: this.$t("addMyColla.review"),
                },
              ],
            },
            {
              url: "/home/change_request",
              icon: "icon-biangengqingqiu",
              title: this.$t("menu.changeRequest"),
            },
            {
              url: "/home/project_details",
              icon: "icon-xiangmupeizhi",
              title: this.$t("menu.projectDetails"),
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "jobAuthorities",
      "showDfmea",
      "showTARA",
      "showHARA",
      "userAccountId",
    ]),
    geturl() {
      return this.$route.params.projectId;
    },
    showtara() {
      if (this.licenseList.indexOf("TARA") !== -1 || this.showTARA) {
        return true;
      } else {
        return false;
      }
    },
    showhara() {
      if (this.licenseList.indexOf("HARA") !== -1 || this.showHARA) {
        return true;
      } else {
        return false;
      }
    },
    showDFMEA() {
      if (this.licenseList.indexOf("DFMEA") !== -1 || this.showDfmea) {
        return true;
      } else {
        return false;
      }
    },
    is_guide() {
      if (this.$route.path.includes("/home/guide")) {
        return true;
      }
      return false;
    },
  },
  watch: {
    "$store.state.project": {
      handler: function () {
        if (this.get_pid()) {
          getUnhandleTask(this.get_pid()).then((res) => {
            this.unHandleTask = res;
          });
          let params = {
            page: 0,
            size: 9999,
            assignee: this.userAccountId,
            status: "TO_DO",
          };
          get_allRiskAnalysisPage(this.get_pid(), params).then((res) => {
            this.unHandleTARA = res.totalElements;
          });
          notComplete({
            projectId: this.get_pid(),
          }).then((res) => {
            this.unHandleDFMEA = res;
          });
          getCount({
            projectId: this.get_pid(),
          }).then((res) => {
            this.unHandleRequest = res;
          });
        }
      },
      deep: true,
    },
    "$store.getters.projectRole": {
      handler: function () {
        this.role =
          this.$store.getters.projectRole ||
          sessionStorage.getItem("projectRole");
      },
      immediate: true,
    },
    $route: {
      handler: function () {
        //监听路由，实时转换选中的tab
        if (this.$route.path.includes("/home/chart")) {
          this.activeIndex = "/home/chart";
        } else if (this.$route.path.includes("/home/release")) {
          this.activeIndex =
            "/home/release/" + this.$route.path.split("/").slice(-2, -1)[0];
        } else if (this.$route.path.includes("/home/board")) {
          this.activeIndex = "/home/board";
        } else if (this.$route.path.includes("/home/gantt")) {
          this.activeIndex = "/home/gantt";
        } else if (this.$route.path.includes("/home/functionalSafety/dfmea")) {
          this.activeIndex = "/home/functionalSafety/dfmea";
        } else if (this.$route.path.includes("/home/functionalSafety/tara")) {
          this.activeIndex = "/home/functionalSafety/tara";
        } else if (this.$route.path.includes("/home/functionalSafety/hara")) {
          this.activeIndex = "/home/functionalSafety/hara";
        } else if (this.$route.path.includes("/home/bin")) {
          this.activeIndex = "/home/bin";
        } else if (this.$route.path.includes("/home/permission")) {
          this.activeIndex = "/home/permission";
        } else if (
          this.$route.path.includes("/home/setting/file_type/work_flow")
        ) {
          this.activeIndex = "/home/setting/file_type/work_flow";
        } else if (
          this.$route.path.includes("/home/setting/file_type/field_lab")
        ) {
          this.activeIndex = "/home/setting/file_type/field_lab";
        } else if (
          this.$route.path.includes("/home/setting/file_type/pingshen")
        ) {
          this.activeIndex = "/home/setting/file_type/pingshen";
        } else if (this.$route.path.includes("/home/change_request")) {
          this.activeIndex = "/home/change_request";
        } else if (
          this.$route.path.includes("/home/setting/file_type/template_set")
        ) {
          this.activeIndex = "/home/setting/file_type/work_flow";
        } else {
          this.activeIndex = this.$route.path.replace(
            "/" + this.$route.params.projectId,
            ""
          );
        }
        this.getShortcuts();
      },
      immediate: true,
    },
    isCollapse(newValue) {
      this.$store.commit("SET_COLLAPSE", newValue);
    },
    show_guide() {
      let dom = document.querySelector(".el-menu-vertical-demo");

      if (!this.show_guide) {
        dom.style.height = "88vh";
      } else {
        dom.style.height =
          "calc( 88vh - " +
          document.querySelector(".guide_zdh").clientHeight +
          "px )";
      }
    },
  },
  mounted() {
    get_ed().then((res) => {
      this.percent = Math.round((100 * res.length) / 11, 2);
      if (res.length == 11) {
        this.show_guide = false;
      }
    });
    get_b().then((res) => {
      this.b = res;
    });
    let this_ = this;
    vmson.$on("close_Collapse", (val) => {
      this_.isCollapse = true;
    });
    vmson.$on("change_tara_status", (val) => {
      let params = {
        page: 0,
        size: 9999,
        assignee: this.userAccountId,
        status: "TO_DO",
      };
      get_allRiskAnalysisPage(this.get_pid(), params).then((res) => {
        this.unHandleTARA = res.totalElements;
      });
    });
    if (this.get_pid()) {
      getUnhandleTask(this.get_pid()).then((res) => {
        this.unHandleTask = res;
      });
      notComplete({
        projectId: this.get_pid(),
      }).then((res) => {
        this.unHandleDFMEA = res;
      });
      let params = {
        page: 0,
        size: 9999,
        assignee: this.userAccountId,
        status: "TO_DO",
      };
      get_allRiskAnalysisPage(this.get_pid(), params).then((res) => {
        this.unHandleTARA = res.totalElements;
      });
      getCount({
        projectId: this.get_pid(),
      }).then((res) => {
        this.unHandleRequest = res;
      });
    }
    // window.addEventListener('resize', ()=>{
    //   setTimeout(()=>{
    //     this.$refs.slide.style.width=parseFloat(this.$refs.first_item.offsetWidth)-20+"px"
    //     this.$refs.slide.style.height=parseFloat(this.$refs.first_item.offsetHeight)+"px"
    //   },500)
    // })
    // this.slide_init()
    notComplete({
      projectId: this.get_pid(),
    }).then((res) => {
      this.unHandleDFMEA = res;
    });
    getCount({
      projectId: this.get_pid(),
    }).then((res) => {
      this.unHandleRequest = res;
    });
    this.showDFMEAfn();
    this.getShortcuts();
    this.getMenuList();
    this.$nextTick(() => {
      let dom = document.querySelector(".el-menu-vertical-demo");
      if (this.show_guide) {
        dom.style.height =
          "calc( 88vh - " +
          document.querySelector(".guide_zdh").clientHeight +
          "px )";
      }
    });
  },
  created() {
    /**获取用户列表 */
    get_tenant_user_list().then((res) => {
      res.content.forEach((ele) => {
        if (ele.accountId == this.$store.state.user.userAccountId) {
          const inputDate = new Date(ele.createdAt.replace(/-/g, "/")); // 有些浏览器可能不支持 '-' 分隔符，所以替换为 '/'

          // 2. 获取当前时间的 Date 对象
          const currentDate = new Date();

          // 3. 计算两个日期之间的时间差（以毫秒为单位）
          const timeDifference = currentDate - inputDate;

          // 4. 将时间差转换为天数（1天 = 24小时 * 60分钟 * 60秒 * 1000毫秒）
          const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

          // 5. 检查是否大于14天
          if (daysDifference > 14) {
            this.show_guide = false;
          }
        }
      });

      this.userList = res.content;
    });

    // 将状态管理中的思维导图名称置空
    this.$store.commit("SET_MINDMAP_NAME", "");
  },
  methods: {
    openOthersUrl(url) {
      window.open(url, "_blank");
    },
    getUlid() {
      return ulid();
    },
    hide_guide() {
      this.show_all_guide = false;
      this.$nextTick(() => {
        let dom = document.querySelector(".el-menu-vertical-demo");
        if (this.show_guide) {
          dom.style.height =
            "calc( 88vh - " +
            document.querySelector(".guide_btn").clientHeight +
            "px )";
        }
      });
    },
    // 获取初始化左菜单
    getMenuList() {
      if (localStorage.getItem("menuList")) {
        const storageMenuList = JSON.parse(localStorage.getItem("menuList"));
        this.menuList = [];
        storageMenuList.forEach((storageMenu) => {
          this.tempMenuList.forEach((tempMenu) => {
            if (storageMenu.icon === tempMenu.icon) {
              this.menuList.push(tempMenu);
            }
          });
        });
      } else {
        this.menuList = JSON.parse(JSON.stringify(this.tempMenuList));
      }
    },
    moveIndex(arr, fromIndex, toIndex) {
      for (let i = 0; i < arr.length; i++) {
        let item = arr[i];
        if (i == fromIndex) {
          arr.splice(i, 1);
          arr.splice(toIndex, 0, item);
          break;
        }
      }
      return arr;
    },
    // 拖拽左菜单
    onStart(event) {
      this.draggStartIndex = event.oldIndex;
    },
    onEnd(event) {
      this.showmenu = false;
      this.draggEndIndex = event.newIndex;
      const arr = JSON.parse(JSON.stringify(this.menuList));
      this.$nextTick(() => {
        setTimeout(() => {
          this.menuList = this.moveIndex(
            arr,
            this.draggStartIndex,
            this.draggEndIndex
          );
          this.showmenu = true;
          localStorage.setItem("menuList", JSON.stringify(this.menuList));
        });
      });
    },
    // 获取快捷方式
    getShortcuts() {
      const params = {
        projectId: this.get_pid(),
      };
      getShortcuts(params).then((res) => {
        this.shortcutsList = res;
      });
    },
    // 快捷方式相关方法
    saveShortcuts() {
      this.$refs["shortcutsForm"].validate((valid) => {
        if (valid) {
          const regex = /^(https?:\/\/)/; // 定义正则表达式
          const hasHTTP = regex.test(this.shortcutsForm.url);
          if (!hasHTTP) {
            this.shortcutsForm.url = "https://" + this.shortcutsForm.url;
          }
          const params = {
            projectId: this.get_pid(),
            data: this.shortcutsForm,
          };
          addShortcuts(params).then((res) => {
            this.getShortcuts();
            this.addShortcutsdialog = false;
          });
        } else {
          return false;
        }
      });
    },
    deleteShortcuts(item, index) {
      this.$confirm(
        this.$t("chart.topBar.deleteVersionForm.info"),
        this.$t("chart.topBar.deleteVersionForm.title"),
        {
          confirmButtonText: this.$t("btn.deleteBtn"),
          confirmButtonClass: "delete_button_class",
          cancelButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "delete_cancel_class",
          type: "warning",
        }
      )
        .then(() => {
          const params = {
            projectId: this.get_pid(),
            shortcutId: item.shortcutId,
          };
          deleteShortcuts(params).then((res) => {
            this.getShortcuts();
            this.$message({
              type: "success",
              message: this.$t("baseline.message.deleteSuccess"),
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("canvas.messages.deleteInfo"),
          });
        });
    },
    addShortcuts() {
      this.shortcutsForm = {
        url: "",
        name: "",
      };
      this.addShortcutsdialog = true;
    },
    //////
    showDFMEAfn() {
      get_component_license().then((res) => {
        this.licenseList = [];
        for (let item of res) {
          if (item.effective) {
            this.licenseList.push(item.feature);
          }
        }
      });
    },
    getheight(h) {
      this.height = h;
    },
    setNoActive(key) {
      this.noActive = "2";
    },
    collpaseAside() {
      this.isCollapse = !this.isCollapse;
      this.$nextTick(() => {
        if (this.show_guide && document.querySelector(".guide_zdh")) {
          setTimeout(() => {
            let dom = document.querySelector(".el-menu-vertical-demo");
            dom.style.height =
              "calc( 88vh - " +document.querySelector(".guide_zdh").clientHeight +"px )";
          }, 1000);
        }

          if (this.show_guide && document.querySelector(".guide_btn")) {
            setTimeout(() => {
               let dom = document.querySelector(".el-menu-vertical-demo");
          dom.style.height =
            "calc( 88vh - " +document.querySelector(".guide_btn").clientHeight +"px )";
            }, 1000);
        }
      })
    },
    goToYindao() {
      console.log("gotoyin");

      this.$router.push({
        path: "/" + this.get_pid() + `/home/guide`,
      });
    },
    toMenuPage(index) {
      if (index == "/home/setting/file_type/work_flow") {
        this.$router.push("/home/setting/file_type/work_flow");
      }
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    errorHandler() {
      return true;
    },
    handleSelect(key) {
      if (!key) {
        return;
      }
      if (key == "/home/chart") {
        if (this.$route.name !== "chart") {
          this.$router.push({
            name: "chart",
            // 通过aside点击进入，不需要验证分享权限
            params: { cur_dashboard: "enterOwn" },
          });
        }
      } else if (key.indexOf("release") !== -1) {
        if (key.split("/")[3] !== this.$route.name) {
          this.$router.push({
            name: key.split("/")[3],
            // 通过aside点击进入，不需要验证分享权限
            params: { cur_id: "enterOwn" },
          });
        }
      } else if (key == "/home/board") {
        if (this.$route.name !== "board") {
          this.$router.push({
            name: "board",
            // 通过aside点击进入，不需要验证分享权限
            params: { sprintId: "enterOwn" },
          });
        }
      } else if (key == "/home/gantt") {
        if (this.$route.name !== "gantt") {
          this.$router.push({
            name: "gantt",
            // 通过aside点击进入，不需要验证分享权限
            params: { cur_gant_id: "enterOwn" },
          });
        }
      } else {
        this.$router.push("/" + this.$route.params.projectId + key);
      }
    },
  },
};
</script>

<style scope lang="scss">
.slide_style {
  position: absolute;
  left: 10px; /* no*/
  top: 55.99px; /* no*/
  // width: 317.5px; /* no*/
  height: 55.99px; /* no*/
  opacity: 1;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.26);
  z-index: 999;

  display: none;
}
.safe {
  span {
    position: relative;
    .safe_number {
      position: absolute;
      top: -8px;
      right: -10px;
      color: #fff;
      background-color: #f56c6c;
      border-radius: 10px;
      height: 8px;
      width: 8px;
      display: flex;
      // padding: 0 6px;
      align-items: center;
      justify-content: center;
    }
  }
}
.pingshenmenu {
  span {
    position: relative;
    .number {
      position: absolute;
      top: -8px;
      right: -20px;
      color: #fff;
      background-color: #f56c6c;
      border-radius: 10px;
      height: 16px;
      display: flex;
      font-size: 12px;
      padding: 0 6px;
      align-items: center;
      justify-content: center;
    }
  }
}
.dfmeamenu {
  span {
    position: relative;
    .number {
      position: absolute;
      top: -8px;
      right: -20px;
      color: #fff;
      background-color: #f56c6c;
      border-radius: 10px;
      height: 16px;
      display: flex;
      font-size: 12px;
      padding: 0 6px;
      align-items: center;
      justify-content: center;
    }
  }
}
.taramenu {
  span {
    position: relative;
    .number {
      position: absolute;
      top: -8px;
      right: -20px;
      background-color: rgb(245, 108, 108);
      border-radius: 10px;
      height: 16px;
      display: flex;
      font-size: 12px;
      padding: 0 6px;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
<style lang="scss">
.el-menu-vertical-demo {
  overflow-y: scroll;
  height: 88vh;
  //edge 浏览器隐藏滚动条
  scrollbar-width: none;
  .iconfont {
    font-size: 18px;
    margin: 0 12px 0 5px;
    color: white;
  }
  .el-submenu__title i {
    color: white;
  }
  text-align: left !important;
  .el-menu-item.is-active {
    background-color: transparent;
  }

  &:not(.el-menu--collapse) {
    width: 270px;
  }
}
.el-menu--popup {
  svg {
    margin-right: 12px !important;
  }
}
.left-menu {
  .el-menu-item {
    height: 52px;
    line-height: 52px;
  }
  .el-submenu__title {
    height: 52px;
    line-height: 52px;
  }
}
.el-submenu .el-menu {
  background-color: transparent;
}
// 补充的代码

.el-submenu__title:hover {
  background-color: transparent;
}

.el-menu--vertical .el-menu--popup .el-menu-item {
  background-color: rgba(1, 76, 170, 1);
}
.el-menu--vertical .el-menu--popup .el-menu-item i {
  color: #fff;
  margin-right: 20px;
}
.el-menu--vertical .el-submenu__title i {
  color: #fff;
  margin-right: 20px;
}

.el-menu--vertical .el-menu--popup .el-menu-item:hover {
  background-color: rgba(1, 76, 170, 0.8) !important;
}

.el-menu--vertical .el-submenu .el-submenu__title {
  background-color: rgba(1, 76, 170, 1);
}

.el-menu--vertical .el-submenu .el-submenu__title:hover {
  background-color: rgba(1, 76, 170, 0.8) !important;
}

.el-menu--vertical .el-menu--popup .el-menu-item {
  background-color: rgba(1, 76, 170, 1);
}

.el-menu--vertical .el-menu--popup .el-menu-item:hover {
  background-color: rgba(1, 76, 170, 0.8) !important;
}

.el-menu--vertical .el-submenu .el-submenu__title {
  background-color: rgba(1, 76, 170, 1);
}

.el-menu--vertical .el-submenu .el-submenu__title:hover {
  background-color: rgba(1, 76, 170, 0.8) !important;
}
</style>
<style lang="scss" scoped>
/*.box2{
  &:hover{
    background-color: rgb(38, 80, 114);
  }
}*/
.test-collapse {
  overflow-y: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  &:not(.el-menu--collapse) {
    width: 270px;
  }
  .el-menu-item.is-active {
    background-color: transparent !important;
  }

  .el-menu-item:hover {
    background-color: rgb(38, 80, 114);
    //  修改aside最下面的
  }
}
.collapse-box {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  color: #f0f2f5;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px;
  // display:none;
}
.home {
  position: relative;
  .default-button {
    width: 100%;
    height: 45px;
    background: #34afe0;
    color: white;
    border: 0;
    transition-duration: 0.3s;
  }

  .default-button:hover {
    background: #0074b7;
  }

  .el-aside {
    background: linear-gradient(
      180deg,
      rgba(1, 76, 170, 1) 31.67%,
      rgba(3, 122, 188, 1) 100%
    );
  }

  .el-main {
    background-color: #f0f2f5;
    color: #333;
    overflow: hidden;
  }

  .el-menu {
    border: none;
    // ::v-deep .el-submenu__title {
    //  padding-left: 0.1042rem !important;

    // }
    background-color: transparent !important;
    overflow-x: hidden;
  }
  .el-menu-vertical-demo .el-menu-item.is-active {
    position: relative;
  }
  .el-menu-vertical-demo .el-menu-item.is-active::before {
    position: absolute;
    content: "";
    top: 5%;
    right: 5%;
    bottom: 5%;
    left: 5%;
    z-index: 1;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.26);
  }
  .el-menu-item {
    color: #f0f2f5;
    text-align: left;
    background-color: transparent;
  }
  el-submenu {
    text-align: left;
    background-color: transparent;
  }

  .el-dropdown-menu {
    padding: 15px;
    text-align: left;
  }
  .el-header {
    padding: 0px; /* PERF 建议全局修改默认样式 */
  }

  .head-content {
    display: flex;
  }

  .el-container {
    height: 95vh;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  // .el-form-item {
  //   margin-bottom: 15px;
  // }
  .dialog-title {
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    overflow: hidden;
  }
  .element-item-group__title {
    height: 0px !important;
  }
  .settingClass.el-submenu {
    height: 45px;
  }
  .typeClass.el-submenu {
    height: 45px;
  }
}
.member-input-box ::v-deep.el-scrollbar__wrap {
  overflow-x: hidden;
}
.guide_btn {
  text-align: left;
  cursor: pointer;
  padding-left: 20px;
  margin-top: 10px;
  img {
    // border: 1px solid #909399;
    border-radius: 50%;
    padding: 5px;
    width: 50px;
    height: 50px;
  }
}
.guide_zdh {
  // height: 300px;
  background: white;
  margin: 0 auto;
  border-radius: 4px;
  width: 97%;
  cursor: pointer;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  .header_guide {
    padding: 10px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .p1 {
      font-size: 18px;
      font-weight: bold;
    }
    .p2 {
      font-size: 18px;
      color: #909399;
    }
  }
  .b {
    font-size: 14px;
    text-align: left;
    padding-bottom: 5px;
    padding-left: 10px;
  }
  .progress {
    padding: 0 10px;
  }
  .process {
    text-align: left;
    padding: 10px;
    padding-top: 5px;
    font-size: 14px;
  }
}
</style>
<style scoped>
/*滚动条bai整体*/
::-webkit-scrollbar {
  opacity: 0;
  width: 0px; /*滚动条宽度*/
}
/*滚动条按钮*/
::-webkit-scrollbar-button {
  background: rgba(255, 255, 255, 0.26);
  background: transparent;
}
::-webkit-scrollbar-track {
  /* background-color: rgba(48, 100, 143, 1); 滑道全部 */
  background-color: transparent;
}
::-webkit-scrollbar-track-piece {
  /* background-color: rgba(48, 100, 143, 1); 滑道 */
  background-color: transparent;
  -webkit-border-radius: 4px; /*滑道圆角宽度*/
}
::-webkit-scrollbar-thumb {
  /* background-color: rgba(48, 100, 143, 1); */

  /* border: solid 1px rgba(48, 100, 143, 1); 滑动条边框 */
  border-radius: 4px; /*滑动条圆角宽度*/
}
/*横竖滚动条交角*/
::-webkit-scrollbar-corner {
  /* background-color: rgba(48, 100, 143, 1); */
  background-color: transparent;
}
/*横竖滚动条交角图案*/
::-webkit-resizer {
  /*background-image: url(/public/img/resizer-inactive.png);*/
  background-repeat: no-repeat;
  background-position: bottom right;
}
/*鼠标滑过滑动条*/
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(48, 100, 143, 1);
  /* background-color: transparent!important; */
}
</style>
