<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <div class="aboveTable">
      <div v-if="done_num + not_sum > 0" class="finish-performance">
        <span style="margin-right: 4px">{{ $t("test.status") }} &nbsp;</span>
        <div
          style="
            height: 15px;
            border-radius: 20px;
            background-color: rgba(255, 87, 51, 0.3);
            width: 200px;
            display: flex;
            align-items: center;
          "
        >
          <div
            v-if="done_num > 0"
            :style="
              'color:rgb(0 167 11);height:16px;border-radius: 20px;display: flex;justify-content: center;align-items: center;background-color:rgb(188,232,208);width:' +
              (done_num / (not_sum + done_num)) * 100 +
              '%'
            "
          >
            {{ done_num }}
          </div>
          <div
            v-if="not_sum > 0"
            :style="
              'color:rgba(255, 87, 51, 1);height:16px;display: flex;justify-content: center;align-items: center;width:' +
              (100 - (done_num / (not_sum + done_num)) * 100) +
              '%'
            "
          >
            {{ not_sum }}
          </div>
        </div>
      </div>
    </div>

    <div class="test-nodes" style="flex: 1">
      <el-table
        v-loading="node_list_loading"
        :data="current_nodes_table"
        :header-cell-style="{
          color: '#383838',
          fontWeight: 'blod',
          fontSize: '14px',
          backgroundColor: 'transparent',
        }"
        :cell-style="set_cell_style"
        height="90%"
        :cell-class-name="tableCellClassName"
        @filter-change="filterChange"
        @cell-dblclick="test"
      >
        <el-table-column
          label="ID"
          align="left"
          header-align="left"
          min-width="120"
        >
          <template slot-scope="scope">
            <div style="display: flex">
              <el-tooltip
                effect="light"
                placement="top-end"
                v-ak-tooltip-auto-show
                :content="scope.row.nodeKey"
              >
                <span
                  v-limits-of-authority="'NODE_DETAIL_READ'"
                  style="
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-weight: bold;
                    font-size: 14px;
                  "
                  @click="route_to_node_details(scope.row)"
                  >{{ scope.row.nodeKey }}</span
                >
              </el-tooltip>
              <div style="display: inline-block">
                <div
                  v-limits-of-authority="'FILE_READ'"
                  style="display: inline-block"
                >
                  <i
                    style="
                      font-size: 12px;
                      margin-left: 16px;
                      cursor: pointer;
                      color: rgb(48, 100, 143);
                    "
                    class="iconfont icon-node_link"
                    @click="route_to_node(scope.row)"
                  ></i>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('test.nodeTable.title')"
          min-width="200"
          align="left"
          header-align="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="font-weight: bold; font-size: 14px; cursor: pointer"
              @click="show_drawer(scope.row.nodeKey)"
            >
              {{ scope.row.topic }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('test.nodeTable.result')"
          min-width="150"
          align="left"
          header-align="left"
        >
          <template slot-scope="scope">
            <el-input
              type="textarea"
              :ref="scope.row.index + ',' + scope.column.index"
              v-model="scope.row.testResult"
              :size="'mini'"
              @blur="hideInput($event, scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('test.attachments')"
          align="left"
          header-align="left"
        >
          <template slot-scope="scope">
            <el-button
              icon="el-icon-upload"
              type="info"
              style="border: none !important"
              size="small"
              @click="show_file_dialog(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('test.nodeTable.status')"
          min-width="100"
          align="left"
          header-align="left"
          :filters="filtersList"
        >
          <template slot="header">
            <el-button
              type="info"
              style="border: none !important"
              size="small"
              @click.stop="dialogVisible = true"
            >
              <i
                class="iconfont icon-branch_edit"
                style="color: rgb(48, 100, 143)"
              ></i>
            </el-button>
            {{ $t("test.nodeTable.status") }}
          </template>
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.testCaseStatus"
              style="width: 150px"
              size="small"
              filterable
              @change="execute_test_status($event, scope.row)"
              :style="'background:' + get_select_coloe(scope.row)"
              class="zdh_el_select_text_excecute"
            >
              <el-option
                v-for="(item, index) in result_options_build(scope.row)"
                :key="index"
                :label="item.statusName"
                :value="item.status"
              >
                <div style="display: flex; align-items: center">
                  <div
                    style="
                      float: left;
                      width: 25px;
                      height: 25px;
                      margin-right: 10px;
                    "
                    :style="'background: ' + item.color"
                  ></div>

                  <div style="float: left">{{ item.statusName }}</div>
                </div>
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('test.nodeTable.bug')"
          align="left"
          header-align="left"
          min-width="150"
        >
          <template slot-scope="scope">
            <div
              v-for="item in scope.row.bugNodes"
              :key="item.nodeKey"
              style="
                display: flex;
                justify-content: left;
                align-items: center;
                padding: 5px;
              "
            >
              <div v-limits-of-authority="'NODE_DETAIL_READ'">
                <div
                  class="bug_node_font"
                  style="
                    font-weight: 600;
                    cursor: pointer;
                    position: relative;
                    margin-right: 12px;
                  "
                  @click="route_to_node_details(item)"
                >
                  {{ item.nodeKey }}
                </div>
              </div>
              <el-tooltip effect="dark" placement="top" v-ak-tooltip-auto-show>
                <div slot="content">{{ status[item.status].name }}</div>
                <div
                  v-if="scope.row.test_result != $t('test.notExecuted')"
                  :class="{
                    status: true,
                    status_todo: status[item.status].statusCategory == 'TODO',
                    status_progress:
                      status[item.status].statusCategory == 'IN_PROGRESS',
                    status_open: status[item.status].statusCategory == 'DONE',
                  }"
                >
                  <span style="white-space: nowrap">{{
                    status[item.status].name
                  }}</span>
                </div>
              </el-tooltip>
              <el-popconfirm
                :title="$t('canvas.testPlan.deleteRelationBugMess')"
                icon="el-icon-info"
                confirm-button-type="none"
                cancel-button-type="danger"
                :confirm-button-text="$t('btn.cancelBtn')"
                :cancel-button-text="$t('btn.deleteBtn')"
                @cancel="deleteBugNodes(item, scope.row)"
              >
                <span class="deleteRelations" slot="reference" @click.stop="">
                  <i class="iconfont icon-baseline-delete"></i>
                </span>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" header-align="left" width="50">
          <template slot-scope="scope">
            <i
              v-limits-of-authority="'NODE_CREATE'"
              class="iconfont icon-add"
              style="cursor: pointer; color: rgb(48, 100, 143)"
              @click="open_dialog(scope.row)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin-top: 20px">
      <el-pagination
        :page-size="pageSize"
        :pager-count="pageCount"
        :current-page.sync="pageCurrent"
        class="header-pagination"
        style="text-align: end"
        :background="false"
        small
        layout="prev, pager, next"
        :total="filtersData.length"
      >
      </el-pagination>
    </div>
    <!-- 关联已有bug -->
    <el-dialog
      :visible.sync="ownVisible"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      @close="closeHandler('ownRuleForm')"
    >
      <div slot="title">
        <span class="title-text">{{ $t("test.nodeTable.bug") }}</span>
      </div>
      <div class="dialog-content">
        <el-form
          ref="ownRuleForm"
          :model="ownRuleForm"
          hide-required-asterisk
          :rules="rule2s"
        >
          <el-form-item prop="list">
            <el-select
              v-model="ownRuleForm.list"
              :placeholder="$t('canvas.testPlan.selectRelationBugMess')"
              popper-class="relationOwnBug"
              size="small"
              style="width: 100%"
              multiple
              collapse-tags
              filterable
              remote
              :reserve-keyword="true"
              :remote-method="fuzzyBugNodes"
              :loading="bugLoading"
              @visible-change="changeSelectBugNodes"
            >
              <el-option :value="'add'">
                <template #default>
                  <div
                    @click.stop="newBugFile()"
                    style="
                      width: 100%;
                      height: 100%;
                      text-align: center;
                      color: rgb(48, 100, 143);
                    "
                  >
                    {{ $t("canvas.testPlan.newBug") }} +
                  </div>
                </template>
              </el-option>
              <el-option
                v-for="(item, index) in bugNodesListShow"
                :key="item.nodeKey"
                :label="item.nodeKey + ':' + item.topic"
                :value="item.nodeKey + ':' + item.projectId"
                >{{ item.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="closeAddBugNodes">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="confirmAddBugNodes">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 关联新建bug -->
    <el-dialog
      :visible.sync="is_dialog_visible"
      width="25%"
      class="createpro"
      :close-on-click-modal="false"
      :show-close="true"
      @close="closeHandler('ruleForm')"
    >
      <div class="createpro-header">
        <el-tabs v-model="activeName">
          <el-tab-pane :label="$t('test.newFile.title')" name="first">
            <div class="dialog-content">
              <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                hide-required-asterisk
              >
                <el-form-item prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    size="small"
                    :placeholder="$t('test.newFile.place')"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item prop="type">
                  <el-select
                    v-model="ruleForm.type"
                    :placeholder="$t('test.newFile.place2')"
                    size="small"
                    filterable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in ruleForm.type_options"
                      :key="index"
                      :label="item.name"
                      :value="item.fileTypeId"
                      >{{ item.name }}
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('homeTopBar.newFileForm.newNode')"
            name="second"
          >
            <el-form
              ref="ruleForm2"
              :model="addNodeForm"
              hide-required-asterisk
              :rules="rule3s"
            >
              <el-form-item prop="nodeKey">
                <el-select
                  v-model="addNodeForm.nodeKey"
                  :placeholder="$t('canvas.testPlan.selectRelationBugMess2')"
                  popper-class="relationOwnBug"
                  size="small"
                  style="width: 100%"
                  collapse-tags
                  filterable
                  remote
                  :reserve-keyword="true"
                  :remote-method="fuzzyNewBugNodes"
                  :loading="bugLoading"
                  @visible-change="changeSelectBugNodes"
                >
                  <el-option
                    v-for="(item, index) in newBugNodesListShow"
                    :key="item.nodeKey"
                    :label="item.nodeKey + ':' + item.topic"
                    :value="item.nodeKey + ':' + item.projectId"
                    >{{ item.name }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="nodeName">
                <el-input
                  v-model="addNodeForm.nodeName"
                  size="small"
                  :placeholder="$t('addEditCopy.node')"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer">
        <el-button type="info" @click="is_dialog_visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="createProgram_comfirm">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      id="dialogidz"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
      top="15vh"
      width="50%"
    >
      <span
        slot="title"
        style="font-size: 16px; font-weight: bold; padding-left: 10px"
      >
        {{ $t("test.setStatus.title") }}
      </span>
      <el-alert :title="$t('test.setStatus.alert')" type="info" show-icon>
      </el-alert>

      <el-table :data="tableData" style="width: 100%" height="50vh">
        <el-table-column :label="$t('test.setStatus.name')" align="left">
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <div
                :style="
                  'max-width:25px;max-height:25px;min-width:25px;min-height:25px;background-color:' +
                  scope.row.color
                "
              ></div>
              <span style="padding-left: 5px; padding-right: 5px">
                {{ scope.row.statusName }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('test.setStatus.beizhu')">
          <template slot-scope="scope">
            <p>
              {{ scope.row.statusRemark }}
            </p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('test.setStatus.type')" width="150">
          <template slot-scope="scope">
            <p>
              {{
                scope.row.complete
                  ? $t("canvas.testPlan.status.completed")
                  : $t("canvas.testPlan.status.uncompleted")
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('test.setStatus.option')" width="100">
          <template v-if="!scope.row.isSystem" slot-scope="scope">
            <el-button
              type="info"
              size="small"
              style="margin-right: 10px; border: none !important"
              @click="
                innerVisible = true;
                form = JSON.parse(JSON.stringify(scope.row));
              "
            >
              <i
                class="iconfont icon-branch_edit"
                style="color: rgb(48, 100, 143)"
              ></i>
            </el-button>
            <el-popconfirm
              :title="$t('test.setStatus.info')"
              cancel-button-type="info"
              @confirm="del_state(scope)"
            >
              <el-button
                slot="reference"
                type="info"
                size="small"
                style="border: none !important"
              >
                <i class="iconfont icon-delete-red"></i>
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: left; margin-left: 10px; margin-top: 10px">
        <i
          style="text-align: left; cursor: pointer; color: #555"
          class="iconfont icon-add"
          @click="
            innerVisible1 = true;
            form = {
              statusName: '',
              color: '#316089',
              complete: '',
              statusRemark: '',
            };
          "
        />
      </div>
      <el-dialog
        width="25%"
        :title="$t('test.editStatus.title')"
        :close-on-click-modal="false"
        :show-close="true"
        :visible.sync="innerVisible"
        append-to-body
      >
        <span slot="title" style="font-size: 16px; font-weight: bold">
          {{ $t("test.editStatus.title") }}
        </span>
        <el-form label-position="left" label-width="100px" :model="form">
          <el-form-item :label="$t('test.editStatus.name')" class="is-required">
            <el-input v-model="form.statusName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('test.setStatus.beizhu')">
            <el-input
              v-model="form.statusRemark"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('test.editStatus.color')"
            class="is-required"
          >
            <el-color-picker v-model="form.color"></el-color-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="innerVisible = false">{{
            $t("btn.cancelBtn")
          }}</el-button>
          <el-button type="primary" @click="edit_state()">{{
            $t("btn.confirmBtn")
          }}</el-button>
        </span>
      </el-dialog>
      <el-dialog
        width="25%"
        :title="$t('test.addStatus.title')"
        :close-on-click-modal="false"
        :show-close="true"
        :visible.sync="innerVisible1"
        append-to-body
      >
        <span slot="title" style="font-size: 16px; font-weight: bold">
          {{ $t("test.addStatus.title") }}
        </span>

        <el-form label-position="left" label-width="100px" :model="form">
          <el-form-item :label="$t('test.addStatus.name')" class="is-required">
            <el-input v-model="form.statusName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('test.setStatus.beizhu')">
            <el-input
              v-model="form.statusRemark"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('test.addStatus.type')" class="is-required">
            <el-select
              v-model="form.complete"
              filterable
              :placeholder="$t('placeholder.dropdown')"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('test.addStatus.color')" class="is-required">
            <el-color-picker v-model="form.color"></el-color-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="innerVisible1 = false">{{
            $t("btn.cancelBtn")
          }}</el-button>
          <el-button type="primary" @click="edit_state()">{{
            $t("btn.newBtn")
          }}</el-button>
        </span>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="dialogVisible = false">{{
          $t("btn.closeBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      id="dialogidz"
      :visible.sync="is_show_file_dialog"
      top="15vh"
      width="50%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <span
        slot="title"
        style="font-size: 16px; font-weight: bold; padding-left: 10px"
      >
        {{ $t("test.attachments") }}
      </span>
      <el-table
        :data="file_dialog_list"
        style="width: 100%"
        height="50vh"
        :header-cell-style="{
          color: '#383838',
          'font-weight': '700',
        }"
        :cell-style="{
          color: '#636363',
          'font-weight': '700',
        }"
      >
        <el-table-column :label="$t('test.setStatus.fileName')" align="left">
          <template slot-scope="scope">
            <span> {{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('test.setStatus.option')" width="200">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: flex-start">
              <el-button
                v-if="isImg(scope.row)"
                icon="el-icon-view"
                type="info"
                size="small"
                style="border: none !important"
                @click="see_img(config.oss_url + scope.row.url)"
              >
              </el-button>
              <el-button
                v-if="isPDF(scope.row)"
                icon="el-icon-view"
                type="info"
                size="small"
                style="border: none !important"
                @click="see_pdf(config.oss_url + scope.row.url)"
              >
              </el-button>
              <el-button
                type="info"
                size="small"
                icon="el-icon-download"
                style="border: none !important"
                @click="download_file(config.oss_url + scope.row.url)"
              >
              </el-button>
              <el-button
                type="info"
                size="small"
                style="border: none !important"
                @click="del_file(scope.row)"
              >
                <i class="iconfont icon-delete-red"></i>
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: left; margin-left: 10px; margin-top: 10px">
        <el-upload
          class="upload-demo"
          multiple
          action=""
          :show-file-list="false"
          :http-request="upload_file"
        >
          <el-button
            size="small"
            type="info"
            style="border: none !important"
            @click="upload_list = []"
          >
            <i
              style="text-align: left; cursor: pointer; color: #555"
              class="iconfont icon-add"
            />
          </el-button>
        </el-upload>
      </div>

      <el-dialog
        :title="$t('addFileList.title1')"
        :visible.sync="dialogImgVisible"
        :close-on-click-modal="false"
        :show-close="true"
        width="50%"
        append-to-body
      >
        <el-image
          :src="currentSrc"
          style="width: 100%; height: 100%"
          :preview-src-list="imgList"
        >
        </el-image>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>

      <el-dialog
        :title="$t('addFileList.title2')"
        :visible.sync="dialogPdfVisible"
        :close-on-click-modal="false"
        :show-close="true"
        width="80%"
        :top="'1vh'"
        append-to-body
      >
        <iframe :src="currentSrc" style="width: 100%; height: 800px"></iframe>
        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>

      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="is_show_file_dialog = false">{{
          $t("btn.closeBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  execute_test_node,
  get_test_nodes,
  postTestcaseBugRelations,
  deleteTestcaseBugRelations,
  getBugNodes,
} from "@/network/test/index.js";
import { get_authorization } from "@/network/node/index.js";
import { get_filetype } from "@/network/home/index.js";
import {
  testCaseStatus,
  add_testCaseStatus,
  change_testCaseStatus,
  del_testCaseStatus,
} from "@/network/testPlan/index.js";
import { createNewFile, getFile } from "@/network/home/index.js";
import { get_file } from "@/network/baseline/index.js";
import vmson from "@/utils/vmson";
import { mapGetters } from "vuex";
import config from "@/../config/index";
import { get_node_use_node_key } from "@/network/node/index.js";
import { add_node } from "@/network/mindmap/index.js";
export default {
  name: "TestExcecutingMode",
  props: {
    cur_test_id: {
      type: String,
      default: "",
    },
    test_status: {
      type: String,
      default: "",
    },
    update_node: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowTooltip: false,
      options: [
        { label: this.$t("canvas.testPlan.status.completed"), value: true },
        { label: this.$t("canvas.testPlan.status.uncompleted"), value: false },
      ],
      form: { statusName: "", color: "", complete: "", statusRemark: "" },
      tableData: [],
      innerVisible: false,
      innerVisible1: false,
      dialogVisible: false,
      testResultEdit: undefined,
      currentCell: null,
      nodes_list: [],
      nodes_listCopy: [],
      testResultModels: [],
      is_dialog_visible: false,
      is_show_file_dialog: false,
      dialogPdfVisible: false,
      dialogImgVisible: false,
      current_row: "",
      currentSrc: "",
      imgList: [],
      file_dialog_list: [],
      // 创建文件弹窗
      ruleForm: {
        name: "",
        test_plan: "",
        test_case: "",
        type: "",
        type_options: [],
        test_plan_list: [],
        test_case_list: [],
      },
      node_list_loading: false,
      current_row_index: 0,
      upload_list: [],
      // 创建文件弹窗的规则
      rules: {
        name: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "change",
          },
        ],
      },
      rule2s: {
        list: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "change",
          },
        ],
      },
      rule3s: {
        nodeKey: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "change",
          },
        ],
        nodeName: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "blur",
          },
        ],
      },
      done_num: 0,
      not_sum: 0,
      config: config,
      pageSize: 20,
      pageCount: 5,
      pageCurrent: 1,
      current_nodes_table: [],
      // 过滤的选项
      filtersList: [],
      filtersData: [],
      selectedFilter: [],
      ownVisible: false,
      ownRuleForm: {
        list: [],
      },
      addNodeForm: {
        nodeKey: "",
        nodeName: "",
      },
      ownRules: [],
      bugNodesList: [],
      bugNodesListShow: [],
      newBugNodesListShow: [],
      bugNodesFuzzyKey: "",
      bugLoading: false,
      currentRowBugList: [],
      currentRow: {},
      // 新建bug弹窗
      activeName: "first",
    };
  },
  computed: {
    ...mapGetters(["execution_status", "status"]),
  },
  watch: {
    update_node(newVal) {
      if (!newVal) {
        this.node_list_loading = true;
        get_test_nodes(this.get_pid(), this.cur_test_id).then((res) => {
          this.nodes_list = res;
          this.node_list_loading = false;
        });
      }
    },
    pageCurrent: {
      handler(newv, oldv) {
        this.current_nodes_table = this.filtersData.slice(
          (newv - 1) * this.pageSize,
          newv * this.pageSize
        );
      },
    },
    nodes_list: {
      handler(newv, oldv) {
        this.filtersData = newv;
        this.current_nodes_table = this.filtersData.slice(
          (this.pageCurrent - 1) * this.pageSize,
          this.pageCurrent * this.pageSize
        );
      },
      immediate: true,
    },
    tableData: {
      handler(newv, oldv) {
        this.filtersList = [];
        for (let item of newv) {
          this.filtersList.push({ text: item.statusName, value: item.status });
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {
    this.get_table_date();
    // 获取当前项目下已有的可关联的bug
    this.getBugNodes();
    let _this = this;
    vmson.$on("update_test_table", (val) => {
      get_test_nodes(_this.get_pid(), _this.cur_test_id).then((res) => {
        _this.nodes_list = res;
        this.compute_not_yet();
        _this.nodes_listCopy = Object.assign({}, res);
        _this.node_list_loading = false;
      });
    });
  },
  methods: {
    get_select_coloe(a) {
      return this.get_map(a.testCaseStatus).color;
    },
    show_drawer(row) {
      this.$emit("show_drawer", row);
    },
    isPDF(file) {
      return file.name.endsWith(".pdf") || file.name.endsWith(".PDF");
    },
    isImg(file) {
      return (
        file.name.endsWith(".png") ||
        file.name.endsWith(".jpg") ||
        file.name.endsWith(".jpeg") ||
        file.name.endsWith(".PNG") ||
        file.name.endsWith(".JPG") ||
        file.name.endsWith(".JPEG")
      );
    },
    see_img(url) {
      this.dialogImgVisible = true;
      this.currentSrc = url;
      this.imgList = [];
      this.current_row.attachments.forEach((file) => {
        if (this.isImg(file)) {
          this.imgList.push(config.oss_url + file.url);
        }
      });
    },
    see_pdf(url) {
      this.dialogPdfVisible = true;
      this.currentSrc = url;
    },
    del_file(deleteFile) {
      let f = [];
      this.file_dialog_list.forEach((file) => {
        if (deleteFile.url == file.url) {
        } else {
          f.push(file);
        }
      });
      let execute_data = {
        attachments: f,
      };
      execute_test_node(
        this.get_pid(),
        this.cur_test_id,
        this.current_row.nodeKey,
        execute_data
      ).then((res) => {
        this.file_dialog_list = f;
        this.nodes_list.forEach((item) => {
          if (item.nodeKey === this.current_row.nodeKey) {
            item.attachments = f;
            this.current_row = item;
          }
        });
      });
    },
    download_file(url) {
      window.open(url);
    },
    show_file_dialog(row) {
      this.current_row_index = row.index;
      this.is_show_file_dialog = true;
      this.file_dialog_list = row.attachments;
      this.current_row = row;
    },
    upload_file(param) {
      this.upload_list.push(param);
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        let row = this.current_row;
        const loading = this.$loading({
          lock: true,
          text: this.$t("personal.edit.ing"),
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        //发送请求的参数格式为FormData
        const OSS = require("ali-oss");
        get_authorization().then(async (res) => {
          this.client = new OSS({
            region: "oss-cn-shanghai",
            accessKeyId: res.accessKeyId,
            accessKeySecret: res.accessKeySecret,
            stsToken: res.securityToken,
            refreshSTSToken: async () => {
              const info = await fetch("/file-manage-service/stsCredentials");
              return {
                accessKeyId: info.accessKeyId,
                accessKeySecret: info.accessKeySecret,
                stsToken: info.securityToken,
              };
            },
            refreshSTSTokenInterval: 10000000,
            bucket: "ytdevops-automind",
          });
          const promiseList = [];
          await this.upload_list.forEach(async (item) => {
            promiseList.push(
              this.client.put(
                `/${
                  this.$store.state.project.tenantId
                }/node_accessors/${Date.now()}${item.file.name}`,
                item.file
              )
            );
            // promiseList.push(this.client.put("/node_accessors/" + Date.now() + item.file.name, item.file))
          });
          Promise.all(promiseList).then((reslist) => {
            reslist.forEach((ress, index) => {
              row.attachments.push({
                name: this.upload_list[index].file.name,
                url:
                  "/" +
                  this.$store.state.project.tenantId +
                  "/node_accessors/" +
                  ress.url.split("/node_accessors/")[1],
              });
            });
            let execute_data = {
              attachments: row.attachments,
            };
            execute_test_node(
              this.get_pid(),
              this.cur_test_id,
              row.nodeKey,
              execute_data
            ).then((res) => {
              get_test_nodes(this.get_pid(), this.cur_test_id).then((res) => {
                this.nodes_list = res;
                this.nodes_listCopy = Object.assign({}, res);
                this.file_dialog_list =
                  this.nodes_list[this.current_row_index].attachments;
                this.node_list_loading = false;
                loading.close();
              });
            });
          });
        });
      }, 500);
    },
    get_map(key) {
      for (let i of this.tableData) {
        if (i.status == key) {
          return i;
        }
      }
    },
    result_options_build(data) {
      let a = [];
      // if (this.get_map(data.testCaseStatus).complete) {
      //   this.tableData.forEach((e) => {
      //     if (e.complete) {
      //       a.push(e);
      //     }
      //   });
      // } else {
      a = JSON.parse(JSON.stringify(this.tableData));
      // }
      return a;
    },
    del_state(row) {
      del_testCaseStatus(this.get_pid(), row.row.status).then((r) => {
        this.$store.dispatch("setExecutionStatus", this.get_pid());
        this.tableData.splice(row.$index, 1);
      });
    },
    edit_state() {
      if (!this.form.statusName) {
        this.$message.warning(this.$t("test.message.warn1"));
        return false;
      }
      if (!this.form.color) {
        this.$message.warning(this.$t("test.message.warn2"));
        return false;
      }
      if (this.form.complete.toString().length < 3) {
        this.$message.warning(this.$t("test.message.warn3"));
        return false;
      }
      if (this.form.status) {
        let d = {
          statusName: this.form.statusName,
          statusRemark: this.form.statusRemark,
          color: this.form.color,
          complete: this.form.complete,
        };
        change_testCaseStatus(this.get_pid(), this.form.status, d).then((r) => {
          testCaseStatus(this.get_pid()).then((res) => {
            this.tableData = res;
            const obj = {};
            res.forEach((item) => {
              obj[item.status] = item;
            });
            this.$store.commit("setExecutionStatus", obj);
          });
        });
      } else {
        add_testCaseStatus(this.get_pid(), this.form).then((r) => {
          this.form.status = r;
          this.tableData.push(this.form);
          this.$store.dispatch("setExecutionStatus", this.get_pid());
        });
      }
      this.innerVisible1 = false;
      this.innerVisible = false;
    },
    //编辑测试结果
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex;
      column.index = columnIndex;
    },
    test(row, column) {
      this.currentCell = row.index + "," + column.index;
      setTimeout(() => {
        const ipt = this.$refs[row.index + "," + column.index];
        ipt.focus();
      });
    },
    hideInput(e, row) {
      const execute_data = {
        testResult: row.testResult,
      };
      // this.$refs[row.nodeKey].textContent = row.testResult;
      if (row.testResult === "") {
        execute_test_node(this.get_pid(), this.cur_test_id, row.nodeKey, {
          testResult: " ",
        });
      } else {
        execute_test_node(
          this.get_pid(),
          this.cur_test_id,
          row.nodeKey,
          execute_data
        );
      }
      this.currentCell = null;
    },
    // 点击节点key跳转到节点详情
    route_to_node_details(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${row.nodeKey}`,
      });
      window.open(href, "_blank");
    },
    // 点击节点key旁的图标跳转到节点在图中的位置
    route_to_node(row) {
      get_file(this.get_pid(), row.fileId).then((result) => {
        getFile(this.get_pid(), result.key)
          .then((res) => {
            this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
            this.$store.commit("SET_FILE_ID", res.fileId);
            this.$store.commit("SET_FILE_KEY", res.fileKey);
            this.$store.commit("SET_MINDMAP_NAME", res.fileName);
            this.$store.commit("SET_OPEN_STATE", "exist");
            this.$store.commit("SET_FILETYPE_ID", res.fileTypeId);
            //跳转到思维导图
            const { href } = this.$router.resolve({
              path: `/${this.get_pid()}/myMind/${result.key}`,
              query: {
                node_id: row.id, // 节点id
              },
            });
            window.open(href, "_blank");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    //创建bug类型
    async createProgram_comfirm() {
      if (this.activeName === "first") {
        this.$refs["ruleForm"].validate(async (valid) => {
          if (valid) {
            //新建文件 确定
            try {
              let res = await createNewFile(
                //创建文件
                this.get_pid(),
                this.ruleForm.name,
                this.ruleForm.type,
                this.ruleForm.test_case,
                this.ruleForm.test_plan
              );
              this.is_dialog_visible = false;
              this.ownVisible = false;
              this.getBugNodes();
              this.get_table_date();
              this.$store.commit("SET_FILE_KEY", res);
              this.$store.commit("SET_FILETYPE_ID", this.ruleForm.type);
              this.$store.commit("SET_MINDMAP_NAME", this.ruleForm.name);
            } catch (error) {
              console.log(error);
              return;
            }

            this.$message({
              message: this.$t("homeTopBar.newFileForm.message"),
              type: "success",
              duration: 5 * 1000,
            });

            let res = await getFile(
              //获取文件内容
              this.get_pid(),
              this.$store.getters.file_key
            );
            this.$store.commit("SET_FILE_ID", res.data[0].fileId);
            this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
            this.$store.commit("SET_OPEN_STATE", "exist");
            this.$store.commit("SET_MAP_PERMISSION", "owner");
            // 打开新标签页
            this.get_table_date();
            const new_route = this.$router.resolve(
              `/${this.get_pid()}/myMind/${this.$store.getters.file_key}`
            );
            window.open(new_route.href, "_blank");
            this.form_name = "";
            this.fileTypeId = "";
            this.form_options = [];
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
      }
      this.$refs["ruleForm2"].validate(async (valid) => {
        if (valid) {
          const bugProjectId = this.addNodeForm.nodeKey.split(":")[1];
          const bugKey = this.addNodeForm.nodeKey.split(":")[0];
          get_node_use_node_key(bugProjectId, bugKey).then((nodeInfo) => {
            let newNode = {
              fileUuid: nodeInfo.fileId,
              parentid: nodeInfo.id,
              dataList: [
                {
                  name: this.addNodeForm.nodeName,
                },
              ],
            };
            add_node(this.get_pid(), newNode).then((res) => {
              let data = {
                projectId: this.get_pid(),
                testPlanId: this.ruleForm.test_plan,
                testcase: this.ruleForm.test_case,
                bugProjectId: res[0].projectId,
                bug: res[0].key,
              };
              postTestcaseBugRelations(data).then((res) => {
                this.get_table_date();
                this.ownVisible = false;
                this.is_dialog_visible = false;
                this.$refs["ownRuleForm"].resetFields();
                this.$message({
                  message: this.$t("homeTopBar.newFileForm.message"),
                  type: "success",
                  duration: 5 * 1000,
                });
              });
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    get_table_date(params) {
      let flag = params && params.load ? true : false;
      this.node_list_loading = flag ? false : true;
      testCaseStatus(this.get_pid()).then((ress) => {
        this.tableData = ress;
        get_test_nodes(this.get_pid(), this.cur_test_id).then((res) => {
          this.nodes_list = res;
          this.compute_not_yet();
          this.nodes_listCopy = Object.assign({}, res);
          this.node_list_loading = false;
        });
      });
    },
    async execute_test_status(event, row) {
      const execute_data = {
        status: event,
      };
      execute_test_node(
        this.get_pid(),
        this.cur_test_id,
        row.nodeKey,
        execute_data
      ).then(async (res) => {
        if (event === "FAILED") {
          const self = this;
          this.currentRow = row;
          let typeList = [];
          const res1 = await get_filetype(this.get_pid());
          typeList = res1.filter((item) => {
            return item.bug;
          });
          const notify = this.$notify({
            title: this.$t("canvas.testPlan.message.executionFailed"),
            dangerouslyUseHTMLString: true,
            type: "warning",
            position: "bottom-right",
            customClass: "notify-creat",
            offset: 400,
            duration: 3000,
            message: `<span>${this.$t(
              "canvas.testPlan.message.click"
            )}<span style="color: blue; cursor: pointer">${this.$t(
              "canvas.testPlan.message.automaticallyCreat"
            )}</span></span>`,
            onClick: async function () {
              notify.close();
              if (typeList.length) {
                let res = await createNewFile(
                  //创建文件
                  self.get_pid(),
                  row.testResult && row.testResult !== " "
                    ? row.testResult
                    : row.topic + self.$t("canvas.testPlan.notWork"),
                  typeList[0].fileTypeId,
                  row.nodeKey,
                  self.cur_test_id
                );
                self.getBugNodes();
                self.get_table_date();
                self.compute_not_yet();
                self.$store.commit("SET_FILE_KEY", res);
                self.$store.commit(
                  "SET_FILETYPE_ID",
                  self.typeList[0].fileTypeId
                );
                self.$store.commit(
                  "SET_MINDMAP_NAME",
                  row.testResult && row.testResult !== " "
                    ? row.testResult
                    : row.topic + self.$t("canvas.testPlan.notWork")
                );
              }
            },
          });
        }
        this.getBugNodes({ load: true });
        this.get_table_date({ load: true });
        this.compute_not_yet();
      });
    },
    // 给节点表格设置样式
    set_cell_style({ columnIndex }) {
      let style = "";
      if (columnIndex == 0) {
        style += "color:rgba(255, 195, 0, 1);";
      } else {
        style += "color:#636363;";
      }
      return style;
    },
    // 打开新建文件弹窗
    open_dialog(row) {
      this.ownVisible = true;
      this.currentRowBugList = row.bugNodes.map((item) => {
        return item.nodeKey;
      });
      if (Array.isArray(this.bugNodesList)) {
        this.bugNodesListShow = this.bugNodesList.filter((item) => {
          return this.currentRowBugList.indexOf(item.nodeKey) == -1;
        });
      }
      // this.is_dialog_visible = true;
      (this.ruleForm = {
        name: "",
        test_plan: "",
        test_case: "",
        type: "",
        type_options: [],
        test_plan_list: [],
        test_case_list: [],
      }),
        get_filetype(this.get_pid()).then((res) => {
          this.ruleForm.type_options = res.filter((item) => {
            return item.bug;
          });
        }); //获取这个项目组的文件类型
      this.ruleForm.test_plan_list = Object.values(
        JSON.parse(sessionStorage.getItem("_testPlan"))
      );
      this.ruleForm.test_plan_list.forEach((item) => {
        if (item.status == "NOT_STARTED") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.notStarted") +
            ")";
        } else if (item.status == "IN_PROGRESS") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.inProgress") +
            ")";
        } else if (item.status == "COMPLETE") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.completed") +
            ")";
        }
      });
      this.ruleForm.test_plan = this.cur_test_id;
      get_test_nodes(this.get_pid(), this.ruleForm.test_plan).then((res) => {
        this.ruleForm.test_case_list = res;
        this.ruleForm.test_case = row.nodeKey;
      });
    },
    //计算未完成数量
    compute_not_yet() {
      let not_yet_num = [];
      this.nodes_list.forEach((e) => {
        if (!this.execution_status[e.testCaseStatus].complete) {
          not_yet_num.push(e);
        }
      });
      this.not_sum = not_yet_num.length;
      this.done_num = this.nodes_list.length - this.not_sum;
    },
    filterChange(filters) {
      this.pageCurrent = 1;
      this.selectedFilter = Object.values(Object.values(filters))[0];
      if (this.selectedFilter.length > 0) {
        let selected = [];
        this.nodes_list.forEach((item) => {
          if (this.selectedFilter.indexOf(item.testCaseStatus) != -1) {
            selected.push(item);
          }
        });
        this.filtersData = selected;
        this.current_nodes_table = this.filtersData.slice(
          (this.pageCurrent - 1) * this.pageSize,
          this.pageCurrent * this.pageSize
        );
      } else {
        this.filtersData = this.nodes_list;
        this.current_nodes_table = this.filtersData.slice(
          (this.pageCurrent - 1) * this.pageSize,
          this.pageCurrent * this.pageSize
        );
      }
    },
    getBugNodes(params) {
      let flag = params && params.load ? true : false;
      this.bugLoading = flag ? false : true;
      getBugNodes(this.get_pid(), this.bugNodesFuzzyKey).then((res) => {
        this.bugNodesList = res;
        if (Array.isArray(this.bugNodesList)) {
          this.bugNodesListShow = this.bugNodesList.filter((item) => {
            return this.currentRowBugList.indexOf(item.nodeKey) == -1;
          });
        }
        this.bugLoading = false;
      });
    },
    getNewBugNodes(params) {
      getBugNodes(this.get_pid(), this.bugNodesFuzzyKey).then((res) => {
        this.bugNodesList = res;
        if (Array.isArray(this.bugNodesList)) {
          this.newBugNodesListShow = JSON.parse(
            JSON.stringify(this.bugNodesList)
          );
        }
        this.bugLoading = false;
      });
    },
    newBugFile() {
      this.is_dialog_visible = true;
      this.addNodeForm = {
        nodeKey: "",
        nodeName: "",
      };
    },
    changeSelectBugNodes(flag) {},
    confirmAddBugNodes() {
      this.$refs["ownRuleForm"].validate((valid) => {
        if (valid) {
          this.ownRuleForm.list.forEach((item) => {
            let data = {
              projectId: this.get_pid(),
              testPlanId: this.ruleForm.test_plan,
              testcase: this.ruleForm.test_case,
              bugProjectId: item.split(":")[1],
              bug: item.split(":")[0],
            };
            postTestcaseBugRelations(data).then((res) => {
              this.get_table_date();
              this.ownVisible = false;
              this.$refs["ownRuleForm"].resetFields();
            });
          });
        } else {
          return false;
        }
      });
    },
    closeAddBugNodes() {
      this.ownVisible = false;
    },
    fuzzyBugNodes(value) {
      this.bugNodesFuzzyKey = value;
      this.getBugNodes();
    },
    fuzzyNewBugNodes(value) {
      this.bugNodesFuzzyKey = value;
      this.getNewBugNodes();
    },
    // 解除关联bug
    deleteBugNodes(item, row) {
      let data = {
        projectId: this.get_pid(),
        testPlanId: this.cur_test_id,
        testcase: row.nodeKey,
        bugProjectId: item.bugProjectId ? item.bugProjectId : this.get_pid(),
        bug: item.nodeKey,
      };
      deleteTestcaseBugRelations(data).then((res) => {
        this.get_table_date();
      });
    },
    closeHandler(form) {
      this.$refs[form].resetFields();
    },
  },
};
</script>
<style lang="scss">
#dialogidz {
  .el-dialog__body {
    padding-top: 0 !important;
  }
}

.notify-creat {
  .el-notification__content {
    text-align: left;
  }
}
</style>
<style lang="scss" scoped>
.test-nodes {
  margin-top: 8px;
  padding: 8px 0;
  border-radius: 8px;
  background-color: #fff;
  ::v-deep .el-table tr td:nth-child(3) .cell {
    white-space: pre-line;
  }
  .el-table__body-wrapper {
    overflow: hidden !important;
  }
  .excecute_status {
    // display: flex;
    width: 80px;
    height: 25px;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 25px;
    // align-items: center;
    // justify-content: center;
    span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  ::v-deep .el-table__row {
    td:nth-child(2) {
      > div {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.status {
  margin-left: 5px;
  width: 90px;
  height: 25px;
  border-radius: 4px;
  // color: #fff;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.status_todo {
  border: 1px solid rgba(166, 166, 166, 1);
  color: rgba(166, 166, 166, 1);
}
.status_open {
  color: rgba(67, 207, 124, 1);
  border: 1px solid rgba(67, 207, 124, 1);
}
.status_progress {
  color: rgba(42, 130, 228, 1);
  border: 1px solid rgba(42, 130, 228, 1);
}
.bug_node_font {
  height: 14px;
  color: rgba(38, 50, 129, 1);
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}
.finish-performance {
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 16px;
  color: rgba(128, 128, 128, 1);
  margin: 10px 0;
}
::v-deep .el-select__tags-text {
  display: inline-block;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.aboveTable {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.aboveTable > :only-child {
  margin-left: auto; /* 使唯一的子元素靠右 */
}
::v-deep .el-pagination {
  .btn-prev {
    background: transparent !important;
  }
  .btn-next {
    background: transparent !important;
  }
  li {
    background: transparent !important;
  }
}
::v-deep .el-tag:first-child {
  display: flex;
  align-items: center;
}
.deleteRelations {
  color: #ff5733;
  font-size: 14px;
  margin-left: 5px;
}
.relationOwnBug .el-select-dropdown__list li:nth-child(1) {
  background-color: rgba(77, 160, 255, 0.35);
  border-radius: 6px 6px 0px 0px;
}
.createpro-header {
  .el-tabs__active-bar {
    background-color: #30648f;
  }

  .is-active {
    color: #30648f;
  }

  .el-tabs__item:hover {
    color: #30648f;
  }
  color: rgba(49, 100, 143, 1);
  font-family: "思源黑体";
  font-size: 16px;
  margin-top: -30px;

  .is-active {
    color: #30648f;
  }
  ::v-deep .el-select {
    .tags-select-input {
      height: 40px;
      .el-select__tags {
        height: 40px;
        white-space: nowrap;
        overflow: hidden;
        flex-wrap: wrap;
      }
    }

    .el-select__tags-text {
      display: inline-block;
      max-width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .el-tag__close.el-icon-close {
      top: -7px;
    }
  }
}
</style>

<style lang="scss">
.zdh_el_select_text_excecute {
  border-radius: 5px;

  input::placeholder {
    color: white;
  }
  input:focus {
    outline: none;
    caret-color: transparent;
  }

  input {
    background: transparent;
    color: white;
    border: none;
    text-align: center;
  }
  .el-input__suffix {
    .el-input__suffix-inner {
      i {
        color: white;
      }
    }
  }
}
.createpro {
  .el-dialog__body {
    padding-bottom: 0 !important;
  }
}
.createpro .el-form {
  margin-top: 20px;
}
</style>
