<template>
  <div
    v-loading="loading"
    class="node_wrap"
    :element-loading-text="$t('loading.loading1')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <div class="searchBar">
      <!-- 左侧部分 -->
      <div
        v-show="searchType.type == 'simple'"
        class="header_filter animate__animated animate__flipInX"
        style="z-index: 999"
      >
        <el-input
          v-model="searchParams.search_box"
          ref="searchBox"
          :placeholder="$t('placeholder.search')"
          style="width: 160px"
          size="mini"
          suffix-icon="el-icon-search"
          @keyup.enter.native="fuzzy_search"
          @blur="fuzzy_search"
        ></el-input>
        <el-tooltip
          v-if="!isShoucangChecked"
          effect="dark"
          :content="$t('file.table.options.collectList')"
          placement="bottom"
        >
          <i
            class="iconfont icon-shoucang hover-icon"
            @click="toggleShoucang"
          />
        </el-tooltip>
        <i
          v-else
          class="iconfont icon-shoucangClick hover-icon"
          style="color: rgb(255, 195, 0); margin: 0 20px; cursor: pointer"
          @click="toggleShoucang"
        />
        <span id="zdh_guide_gtp9">
          <!-- 类型 -->
          <el-dropdown
            trigger="click"
            :hide-on-click="false"
            style="cursor: pointer"
            :class="{ ischecked: searchParams.fileTypeId.length }"
            @visible-change="update_type"
          >
            <span class="el-dropdown-link center_span"
              >{{ $t("node.top.type")
              }}<i class="el-icon-arrow-down el-icon--right el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <div
                style="display: flex; flex-direction: column; z-index: 99999999"
              >
                <!-- 类型搜索框 -->
                <el-input
                  :placeholder="$t(`node.top.type`)"
                  class="search_box"
                  v-model="searchKey.searchTypeKey"
                >
                </el-input>
                <el-checkbox
                  v-model="checkAll.fileTypeId"
                  class="checkbox"
                  :indeterminate="isIndeterminate.fileTypeId"
                  @change="handleCheckAllChange($event, 'fileTypeId')"
                >
                  &nbsp;&nbsp;&nbsp;{{
                    $t("file.table.options.dialog.box1")
                  }}</el-checkbox
                >

                <el-checkbox-group
                  v-model="searchParams.fileTypeId"
                  class="select"
                  :style="{
                    height: filteredTypeList.length > 6 ? 150 + 'px' : 'auto',
                  }"
                  @change="selecteFiletype('fileTypeId')"
                >
                  <el-checkbox
                    v-for="type in filteredTypeList"
                    :key="type.name"
                    :label="type.fileTypeId"
                    class="checkbox"
                  >
                    &nbsp;&nbsp;&nbsp;{{ type.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </span>

        <span>
          <!-- 创建人 -->
          <el-dropdown
            trigger="click"
            :hide-on-click="false"
            style="cursor: pointer"
            :class="{ ischecked: searchParams.createdBy.length }"
            @visible-change="update_type"
          >
            <span class="el-dropdown-link center_span"
              >{{ $t("node.top.creator")
              }}<i class="el-icon-arrow-down el-icon--right el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <div style="display: flex; flex-direction: column">
                <!-- 创建人搜索框 -->
                <el-input
                  :placeholder="$t(`node.top.creator`)"
                  class="search_box"
                  v-model="searchKey.searchCreatorKey"
                >
                </el-input>
                <el-checkbox
                  v-model="checkAll.createdBy"
                  class="checkbox"
                  :indeterminate="isIndeterminate.createdBy"
                  @change="handleCheckAllChange($event, 'createdBy')"
                >
                  &nbsp;&nbsp;&nbsp;{{
                    $t("file.table.options.dialog.box1")
                  }}</el-checkbox
                >

                <el-checkbox-group
                  v-model="searchParams.createdBy"
                  class="select"
                  :style="{
                    height:
                      filteredCreatorList.length > 6 ? 150 + 'px' : 'auto',
                  }"
                  @change="selecteFiletype('createdBy')"
                >
                  <el-checkbox
                    v-for="type in filteredCreatorList"
                    :key="type.name"
                    :label="type.accountId"
                    class="checkbox"
                  >
                    &nbsp;&nbsp;&nbsp;{{ type.nickname }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
        <span>
          <el-dropdown
            trigger="click"
            :hide-on-click="false"
            style="cursor: pointer"
            :class="{ ischecked: searchParams.status.length }"
            @visible-change="update_type"
          >
            <span class="el-dropdown-link center_span"
              >{{ $t("node.top.status")
              }}<i class="el-icon-arrow-down el-icon--right el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <div style="display: flex; flex-direction: column">
                <!-- 状态搜索框 -->
                <el-input
                  :placeholder="$t(`node.top.status`)"
                  class="search_box"
                  v-model="searchKey.searchStatusKey"
                >
                </el-input>
                <el-checkbox
                  v-model="checkAll.status"
                  class="checkbox"
                  :indeterminate="isIndeterminate.status"
                  @change="handleCheckAllChange($event, 'status')"
                >
                  &nbsp;&nbsp;&nbsp;{{
                    $t("file.table.options.dialog.box1")
                  }}</el-checkbox
                >
                <el-checkbox-group
                  v-model="searchParams.status"
                  class="select"
                  :style="{
                    height: filteredStatusList.length > 6 ? 150 + 'px' : 'auto',
                  }"
                  @change="selecteFiletype('status')"
                >
                  <el-checkbox
                    v-for="type in filteredStatusList"
                    :key="type.name"
                    :label="type.statusId"
                    class="checkbox"
                  >
                    &nbsp;&nbsp;&nbsp;{{ type.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
        <span>
          <el-dropdown
            trigger="click"
            :hide-on-click="false"
            style="cursor: pointer"
            :class="{ ischecked: searchParams.assignee.length }"
            @visible-change="update_type"
          >
            <span class="el-dropdown-link center_span"
              >{{ $t("node.top.responsibility")
              }}<i class="el-icon-arrow-down el-icon--right el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <div style="display: flex; flex-direction: column">
                <!-- 负责人搜索框 -->
                <el-input
                  :placeholder="$t(`node.top.responsibility`)"
                  class="search_box"
                  v-model="searchKey.searchAssigneeKey"
                >
                </el-input>
                <el-checkbox
                  v-model="checkAll.assignee"
                  class="checkbox"
                  :indeterminate="isIndeterminate.assignee"
                  @change="handleCheckAllChange($event, 'assignee')"
                >
                  &nbsp;&nbsp;&nbsp;{{
                    $t("file.table.options.dialog.box1")
                  }}</el-checkbox
                >

                <el-checkbox-group
                  v-model="searchParams.assignee"
                  class="select"
                  :style="{
                    height:
                      filteredAssigneeList.length > 6 ? 150 + 'px' : 'auto',
                  }"
                  @change="selecteFiletype('assignee')"
                >
                  <el-checkbox
                    v-for="type in filteredAssigneeList"
                    :key="type.name"
                    :label="type.accountId"
                    class="checkbox"
                  >
                    &nbsp;&nbsp;&nbsp;{{ type.nickname }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
        <!-- 自定义筛选字段 -->
        <span v-for="(item, index) in custom_filters" :key="index">
          <!-- 筛选项为下拉框 -->
          <el-dropdown
            v-if="filter_is_select(item.fieldType) == 'SELECT'"
            trigger="click"
            :hide-on-click="false"
            style="cursor: pointer"
            :class="{ ischecked: custom_filters_value[item.fieldId].length }"
          >
            <span class="center_span"
              >{{ item.name
              }}<i
                class="el-icon-close el-icon--right el-icon--right"
                @click="close_filter(item.name, item.fieldId, 'SELECT', item)"
              ></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <div style="display: flex; flex-direction: column">
                <!-- 其他搜索框 -->
                <el-input
                  :placeholder="item.name"
                  class="search_box"
                  v-model="searchKey['search' + item.fieldId + 'Key']"
                  @input="changeSelectList(item.fieldId)"
                >
                </el-input>
                <el-checkbox
                  v-model="checkAll[item.fieldId]"
                  class="checkbox"
                  :indeterminate="isIndeterminate[item.fieldId]"
                  @change="handleCheckAllChange($event, item)"
                >
                  &nbsp;&nbsp;&nbsp;{{
                    $t("file.table.options.dialog.box1")
                  }}</el-checkbox
                >
                <el-checkbox-group
                  v-if="
                    item.fieldType == 'MULTI_USER_SELECT' ||
                    item.fieldType == 'SINGLE_USER_SELECT'
                  "
                  v-model="custom_filters_value[item.fieldId]"
                  class="select"
                  :style="{
                    height:
                      moreSelectList[item.fieldId].length > 6
                        ? 150 + 'px'
                        : 'auto',
                  }"
                  @change="
                    moreSelecteFiletype(
                      item.fieldId,
                      item.fieldId,
                      'SELECT',
                      item
                    )
                  "
                >
                  <el-checkbox
                    v-for="type in moreSelectList[item.fieldId]"
                    :key="type.name"
                    :label="type.accountId"
                    class="checkbox"
                  >
                    <span
                      style="
                        display: inline-block;
                        max-width: 300px;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: middle;
                      "
                    >
                      &nbsp;&nbsp;&nbsp;{{ type.nickname }}
                    </span>
                  </el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group
                  v-else-if="item.fieldType === 'LABEL'"
                  v-model="custom_filters_value[item.fieldId]"
                  class="select"
                  :style="{}"
                  @change="
                    moreSelecteFiletype(
                      item.fieldId,
                      item.fieldId,
                      'SELECT',
                      item
                    )
                  "
                >
                  <el-checkbox
                    v-for="type in allLabelOptions"
                    :key="type.value"
                    :label="type.value"
                    :value="type.value"
                    class="checkbox"
                  >
                    <span
                      style="
                        display: inline-block;
                        max-width: 300px;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: middle;
                      "
                    >
                      &nbsp;&nbsp;&nbsp;{{ type.label }}
                      {{ type.name }}
                    </span>
                  </el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group
                  v-else-if="
                    [
                      'baseline',
                      'version',
                      'testPlan',
                      'sprint',
                      'coverage',
                      'fileUuid',
                    ].indexOf(item.fieldId) === -1
                  "
                  v-model="custom_filters_value[item.fieldId]"
                  class="select"
                  :style="{
                    height:
                      moreSelectList[item.fieldId].length > 6
                        ? 150 + 'px'
                        : 'auto',
                  }"
                  @change="
                    moreSelecteFiletype(
                      item.fieldId,
                      item.fieldId,
                      'SELECT',
                      item
                    )
                  "
                >
                  <el-checkbox
                    v-for="type in moreSelectList[item.fieldId]"
                    :key="type.value"
                    :label="type.value"
                    :value="type.value"
                    class="checkbox"
                  >
                    <span
                      style="
                        display: inline-block;
                        max-width: 300px;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: middle;
                      "
                    >
                      &nbsp;&nbsp;&nbsp;{{ type.label }}
                      {{ type.name }}
                    </span>
                  </el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group
                  v-else
                  v-model="custom_filters_value[item.fieldId]"
                  class="select"
                  :style="{
                    height:
                      moreSelectList[item.fieldId].length > 6
                        ? 150 + 'px'
                        : 'auto',
                  }"
                  @change="
                    moreSelecteFiletype(
                      item.fieldId,
                      item.fieldId,
                      'SELECT',
                      item
                    )
                  "
                >
                  <el-checkbox
                    v-for="type in moreSelectList[item.fieldId]"
                    :key="
                      item.fieldId === 'fileUuid'
                        ? type.id
                        : type[item.fieldId + 'Id']
                    "
                    :label="
                      item.fieldId === 'fileUuid'
                        ? type.id
                        : type[item.fieldId + 'Id']
                    "
                    :value="
                      item.fieldId === 'fileUuid'
                        ? type.id
                        : type[item.fieldId + 'Id']
                    "
                    class="checkbox"
                  >
                    <span
                      style="
                        display: inline-block;
                        max-width: 300px;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: middle;
                      "
                    >
                      &nbsp;&nbsp;&nbsp;{{
                        item.fieldId === "fileUuid" ? type.key : ""
                      }}
                      {{ type.name }}
                    </span>
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 筛选条件为日期 -->
          <div
            v-else-if="filter_is_select(item.fieldType) == 'DATE'"
            class="filter_tag"
          >
            <el-popover
              placement="bottom"
              width="200"
              :class="{ ischecked: form_date[item.fieldId] }"
            >
              <el-date-picker
                v-model="form_date[item.fieldId]"
                class="custom_datepicker"
                type="date"
                :placeholder="$t('placeholder.date')"
                size="mini"
                @change="moreSelecteFiletype(item.name, item.fieldId, 'DATE')"
              >
              </el-date-picker>
              <span slot="reference" class="center_span"
                >{{ item.name
                }}<i
                  style="cursor: pointer"
                  class="el-icon-close el-icon--right el-icon--right"
                  @click.stop="close_filter(item.name, item.fieldId, 'DATE')"
                ></i
              ></span>
            </el-popover>
          </div>
          <!-- 筛选条件为数字 -->
          <div
            v-else-if="filter_is_select(item.fieldType) == 'NUMBER'"
            style="display: inline-block"
            class="filter_tag"
            @hide="varify_number(item.name, item.fieldId)"
          >
            <el-popover
              placement="bottom"
              width="200"
              :class="{
                ischecked:
                  form_number[item.fieldId] || form_number[item.fieldId] === 0,
              }"
            >
              <el-input
                v-model="form_number[item.fieldId]"
                :placeholder="$t('placeholder.number')"
                size="mini"
                oninput="value=value.replace(/[^\-?\d.]/g,'')"
                @change="varify_number(item.name, item.fieldId)"
              >
              </el-input>

              <span slot="reference" class="center_span"
                >{{ item.name
                }}<i
                  style="cursor: pointer"
                  class="el-icon-close el-icon--right el-icon--right"
                  @click.stop="close_filter(item.name, item.fieldId, 'NUMBER')"
                ></i
              ></span>
            </el-popover>
          </div>
          <!-- 筛选条件为日期选择 -->
          <el-dropdown
            v-if="filter_is_select(item.fieldType) == 'DATE_RANGE'"
            trigger="click"
            :hide-on-click="false"
            style="cursor: pointer"
            :class="{
              ischecked:
                (item.fieldId === 'createdAt' && isChangingCreatedAt) ||
                (item.fieldId === 'updatedAt' && isChangingUpdatedAt),
            }"
          >
            <span class="center_span"
              >{{ item.name
              }}<i
                class="el-icon-close el-icon--right el-icon--right"
                @click="close_filter(item.name, item.fieldId, 'SELECT', item)"
              ></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <div
                style="
                  min-width: 250px;
                  display: flex;
                  justify-content: center;
                  padding: 0 15px;
                "
              >
                <date-range-picker
                  v-if="item.fieldId === 'createdAt'"
                  v-model="searchParams.createdAt"
                  :ranges="false"
                  single-date-picker="range"
                  :locale-data="localeDate"
                  @update="selecteFiletype('createdAt')"
                >
                </date-range-picker>
                <date-range-picker
                  v-if="item.fieldId === 'updatedAt'"
                  ref="picker"
                  v-model="searchParams.updatedAt"
                  :ranges="false"
                  single-date-picker="range"
                  :locale-data="localeDate"
                  @update="selecteFiletype('updatedAt')"
                >
                </date-range-picker>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
        <!-- 更多 -->
        <span>
          <el-dropdown
            trigger="click"
            style="cursor: pointer"
            placement="bottom-start"
          >
            <span class="blue_button"> {{ $t("node.top.moreBtn") }} </span>
            <el-dropdown-menu slot="dropdown" :append-to-body="false">
              <div>
                <el-checkbox
                  v-model="moreCheckAll"
                  class="checkbox"
                  :indeterminate="moreIndeterminate"
                  @change="handleCheckAllChange($event, 'more')"
                >
                  &nbsp;&nbsp;&nbsp;{{
                    $t("file.table.options.dialog.box1")
                  }}</el-checkbox
                >
                <el-checkbox-group v-model="more_filters" class="select">
                  <el-checkbox
                    v-for="type in custom_filter_list"
                    v-show="
                      (type.name === 'Sprint' && project_type === 'SCRUM') ||
                      type.name !== 'Sprint'
                    "
                    :key="type.fieldId"
                    :label="type.fieldId"
                    class="checkbox"
                    @change="handleCheckedFiltersChange"
                  >
                    <span
                      style="
                        display: inline-block;
                        max-width: 300px;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: middle;
                      "
                    >
                      &nbsp;&nbsp;&nbsp;{{ type.name }}
                    </span></el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
        <!-- 清除筛选条件 -->
        <span
          style="color: rgba(1, 76, 170, 1); cursor: pointer"
          @click="clear_filters"
        >
          <i class="iconfont icon-reverse1 link-font-color"> </i>&nbsp;{{
            $t("node.top.clearBtn")
          }}
        </span>
      </div>
      <senior-search
        v-if="searchType.type === 'senior'"
        ref="seniorSearch"
        class="animate__animated animate__flipInX"
        @mqlFilter="mqlFilter"
      ></senior-search>
      <ai-search
        v-if="searchType.type === 'AI'"
        class="animate__animated animate__flipInX"
      ></ai-search>
      <div class="header_button">
        <el-button
          type="primary"
          size="mini"
          icon="iconfont icon-recover"
          @click="changeSearchType"
          >{{ searchType.label }}</el-button
        >
      </div>
      <!-- 右侧筛选部分 -->
      <div class="select_filter">
        <span
          class="blue_button"
          id="zdh_guide_golbal_tip9_btn1"
          style="float: right"
          @click="save_filter_fn"
        >
          <!-- 保存筛选条件 -->
          {{ text_construction_map[text_construction] }}
        </span>
        <span
          v-if="text_construction === 1"
          class="blue_button"
          style="float: right"
          @click="saveAs"
        >
          {{ $t("file.top.anoSaveBtn") }}
        </span>
        <!-- 右侧筛选下拉框 -->
        <span class="filter_wrap">
          <el-select
            ref="filter_dialog"
            key="filter_dialog"
            v-model="conditions_filter"
            clearable
            filterable
            :placeholder="$t('file.top.select')"
            size="mini"
            class="right_input"
            @visible-change="update_filter"
            @change="choose_filter"
            @clear="clear_filter"
          >
            <el-option
              v-for="item in filteroptions"
              :key="item.queryConditionId"
              :label="item.name"
              :value="item.queryConditionId"
            >
            </el-option>
            <el-option
              v-for="item in other_options"
              v-show="false"
              :key="item.queryConditionId"
              :label="item.name"
              :value="item.queryConditionId"
            >
            </el-option>
            <el-option :label="$t('file.top.viewAll')" value=" ">
              <div
                style="width: 100%; color: #336690"
                @click.stop="
                  more_condition_dialog = true;
                  currentPage = 1;
                "
              >
                <span>{{ $t("file.top.viewAll") }}</span>
              </div>
            </el-option>
          </el-select>
        </span>
      </div>
    </div>
    <!-- 批量操作 -->
    <el-row
      :gutter="10"
      type="flex"
      justify="end"
      class="bulk"
      style="text-align: right"
    >
      <el-col :span="2">
        <div style="display: inline-block">
          <span>
            <button
              v-if="is_bulk"
              class="word_button"
              type="submit"
              @click="copyKeys"
            >
              <i
                class="iconfont icon-a-Frame1"
                style="font-size: 16px"
              />&nbsp;&nbsp;{{ $t("btn.CopyKeyBtn") }}
            </button>
          </span>
        </div>
      </el-col>
      <el-col :span="2">
        <div style="display: inline-block">
          <span v-limits-of-authority="'NODE_EDIT'">
            <button
              v-if="is_bulk"
              :disabled="limits_of_authority_disable('NODE_EDIT')"
              class="word_button"
              type="submit"
              @click="AddRelatedNodes"
            >
              <i
                class="iconfont icon-correlate"
                style="font-size: 16px"
              />&nbsp;&nbsp;{{ $t("btn.AddrelatednodesBtn") }}
            </button>
          </span>
        </div>
      </el-col>
      <el-col :span="2">
        <div style="display: inline-block">
          <span>
            <button
              v-if="is_bulk"
              class="word_button"
              type="submit"
              @click="saveVersionDialog"
            >
              <i
                class="iconfont icon-lingcunwei"
                style="font-size: 14px"
              />&nbsp;&nbsp;{{ $t("canvas.rightClick.BatchArchiving") }}
            </button>
          </span>
        </div>
      </el-col>
      <el-col :span="2">
        <div style="display: inline-block">
          <span v-limits-of-authority="'NODE_EDIT'">
            <button
              v-if="is_bulk"
              :disabled="limits_of_authority_disable('NODE_EDIT')"
              class="word_button"
              type="submit"
              @click="blur_modify"
            >
              <i
                class="iconfont icon-branch_edit"
                style="font-size: 12px"
              />&nbsp;&nbsp;{{ $t("btn.modify") }}
            </button>
          </span>
        </div>
      </el-col>
      <el-col :span="2">
        <div style="display: inline-block">
          <span v-limits-of-authority="'NODE_EXPORT'">
            <button
              v-if="is_bulk"
              :disabled="limits_of_authority_disable('NODE_EXPORT')"
              class="word_button"
              type="submit"
              @click="blur_export"
            >
              <i
                class="iconfont icon-export_primary"
                style="font-size: 12px"
              />&nbsp;&nbsp;
              {{ $t("file.table.options.export") }}
            </button>
          </span>
        </div>
      </el-col>
      <el-col :span="is_bulk ? 2 : 3">
        <button class="word_button" type="button" @click="batchFn">
          <i
            :class="{ 'el-icon-edit-outline': !is_bulk }"
            style="font-style: normal"
          >
            <i
              v-if="is_bulk"
              class="iconfont icon-exit"
              style="margin-right: 5px; font-size: 14px"
            ></i>
          </i>
          {{ is_bulk ? $t("btn.exitBtn") : $t("btn.batchBtn") }}
        </button>
      </el-col>
      <el-col :span="2">
        <div class="settings">
          <el-dropdown
            trigger="click"
            style="cursor: pointer"
            class="notDrag"
            placement="bottom-start"
          >
            <span
              class="word_button"
              style="display: flex; align-items: center"
            >
              <i
                class="notDrag iconfont icon-a-31shezhi"
                style="font-size: 20px; margin-right: 5px"
              />
              {{ $t("menu.setting") }}
            </span>
            <el-dropdown-menu
              slot="dropdown"
              :append-to-body="true"
              class="notDrag"
            >
              <el-checkbox-group v-model="more_column" class="select">
                <el-checkbox
                  v-for="type in otherCol"
                  v-show="
                    (type.name === 'Sprint' && project_type === 'SCRUM') ||
                    type.name !== 'Sprint'
                  "
                  :key="type.fieldId"
                  :label="type.fieldId"
                  class="checkbox"
                >
                  &nbsp;&nbsp;&nbsp; {{ type.name || type.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>
    <div class="bulk"></div>
    <div class="table_wrap">
      <!-- 节点列表 -->
      <el-table
        ref="myTable"
        :data="tableData"
        border
        height="77vh"
        width="100%"
        class="clickable-rows"
        :cell-style="changeCellStyle"
        :header-cell-style="{
          color: '#383838',
          'font-weight': '700',
        }"
        @selection-change="handleSelectionChange"
        @select-all="judge_selectAll"
        @header-dragend="HeaderDragend"
      >
        <el-table-column v-if="is_bulk" type="selection">
          <!--如点击了批量操作，勾选框会显示/隐藏-->
        </el-table-column>

        <el-table-column
          v-for="col in dropCol"
          v-show="getColumnInfo(col.fieldId).width"
          :key="col.id"
          :label="getColumnInfo(col.fieldId).label"
          :prop="getColumnInfo(col.fieldId).prop"
          :min-width="col.minWidth"
          :isnamecolumn="col.id"
          show-overflow-tooltip
          :class-name="col.id"
        >
          <template slot="header">
            <span v-if="getColumnInfo(col.fieldId).prop === 'key'">
              <span class="sortable-column-header" @click="sortData('key')"
                >Key
                <div class="caret-wrapper">
                  <i
                    :class="{
                      'el-icon-caret-top': true,
                      'el-icon-caret-colored':
                        sortBy == 'key' && keySort == false,
                    }"
                  ></i>
                  <i
                    :class="{
                      'el-icon-caret-bottom': true,
                      'el-icon-caret-colored':
                        sortBy == 'key' && keySort == true,
                    }"
                  ></i>
                </div>
              </span>
            </span>
            <span v-if="getColumnInfo(col.fieldId).prop === 'topic'">
              <span class="sortable-column-header"
                >{{ $t("node.table.name") }}
              </span>
            </span>
            <span v-if="getColumnInfo(col.fieldId).prop === 'assignee'">
              <span class="sortable-column-header" @click="sortData('assignee')"
                >{{ $t("node.top.responsibility") }}
                <div class="caret-wrapper">
                  <i
                    :class="{
                      'el-icon-caret-top': true,
                      'el-icon-caret-colored':
                        sortBy == 'assignee' && assigneeSort == false,
                    }"
                  ></i>
                  <i
                    :class="{
                      'el-icon-caret-bottom': true,
                      'el-icon-caret-colored':
                        sortBy == 'assignee' && assigneeSort == true,
                    }"
                  ></i>
                </div>
              </span>
            </span>
            <span v-if="getColumnInfo(col.fieldId).prop === 'createdByName'">
              <span
                class="sortable-column-header"
                @click="sortData('createdBy')"
                >{{ $t("node.top.creator") }}
                <div class="caret-wrapper">
                  <i
                    :class="{
                      'el-icon-caret-top': true,
                      'el-icon-caret-colored':
                        sortBy == 'createdBy' && createdBySort == false,
                    }"
                  ></i>
                  <i
                    :class="{
                      'el-icon-caret-bottom': true,
                      'el-icon-caret-colored':
                        sortBy == 'createdBy' && createdBySort == true,
                    }"
                  ></i>
                </div>
              </span>
            </span>
            <span v-if="getColumnInfo(col.fieldId).prop === 'statusName'">
              <span class="sortable-column-header" @click="sortData('status')"
                >{{ $t("node.top.status") }}
                <div class="caret-wrapper">
                  <i
                    :class="{
                      'el-icon-caret-top': true,
                      'el-icon-caret-colored':
                        sortBy == 'status' && statusSort == false,
                    }"
                  ></i>
                  <i
                    :class="{
                      'el-icon-caret-bottom': true,
                      'el-icon-caret-colored':
                        sortBy == 'status' && statusSort == true,
                    }"
                  ></i>
                </div>
              </span>
            </span>
            <span v-if="getColumnInfo(col.fieldId).prop === 'createdBy'">
              <!--              {{ $t("node.table.createdTime") }}-->
              <span
                class="sortable-column-header"
                @click="sortData('createdBy')"
                >{{ $t("node.table.creator") }}
                <div class="caret-wrapper">
                  <i
                    :class="{
                      'el-icon-caret-top': true,
                      'el-icon-caret-colored':
                        sortBy == 'createdBy' && createdBySort == false,
                    }"
                  ></i>
                  <i
                    :class="{
                      'el-icon-caret-bottom': true,
                      'el-icon-caret-colored':
                        sortBy == 'createdBy' && createdBySort == true,
                    }"
                  ></i>
                </div>
              </span>
            </span>
            <span v-if="getColumnInfo(col.fieldId).prop === 'createdAt'">
              <!--              {{ $t("node.table.createdTime") }}-->
              <span
                class="sortable-column-header"
                @click="sortData('createdAt')"
                >{{ $t("node.table.createdTime") }}
                <div class="caret-wrapper">
                  <i
                    :class="{
                      'el-icon-caret-top': true,
                      'el-icon-caret-colored':
                        sortBy == 'createdAt' && createdAtSort == false,
                    }"
                  ></i>
                  <i
                    :class="{
                      'el-icon-caret-bottom': true,
                      'el-icon-caret-colored':
                        sortBy == 'createdAt' && createdAtSort == true,
                    }"
                  ></i>
                </div>
              </span>
            </span>
            <span v-if="getColumnInfo(col.fieldId).prop === 'updatedAt'">
              <span
                class="sortable-column-header"
                @click="sortData('updatedAt')"
                >{{ $t("node.table.updatedTime") }}
                <div class="caret-wrapper">
                  <i
                    :class="{
                      'el-icon-caret-top': true,
                      'el-icon-caret-colored':
                        sortBy == 'updatedAt' && updatedAtSort == false,
                    }"
                  ></i>
                  <i
                    :class="{
                      'el-icon-caret-bottom': true,
                      'el-icon-caret-colored':
                        sortBy == 'updatedAt' && updatedAtSort == true,
                    }"
                  ></i>
                </div>
              </span>
            </span>
            <span v-if="getColumnInfo(col.fieldId).isCus">
              {{ get_header_name(col.fieldId) }}
            </span>
          </template>
          <template slot-scope="scope">
            <span v-if="getColumnInfo(col.fieldId).prop === 'key'">
              <div style="display: inline-block" class="hover-class">
                <span
                  v-limits-of-authority="'NODE_DETAIL_READ'"
                  style="cursor: pointer"
                >
                  <a
                    style="color: rgb(255, 195, 0)"
                    :href="'/' + get_pid() + '/nodes/key/' + scope.row.key"
                    >{{ scope.row.key }}</a
                  >
                </span>
              </div>
              <div style="display: inline-block">
                <div
                  v-limits-of-authority="'FILE_READ'"
                  style="display: inline-block"
                >
                  <a
                    :href="
                      '/' +
                      get_pid() +
                      '/myMind/' +
                      scope.row.fileKey +
                      '?node_id=' +
                      scope.row.id
                    "
                  >
                    <i
                      style="
                        font-size: 12px;
                        margin-left: 16px;
                        cursor: pointer;
                        color: rgb(48, 100, 143);
                      "
                      class="iconfont icon-node_link"
                    >
                    </i>
                  </a>
                </div>
              </div>
            </span>
            <div
              v-else-if="getColumnInfo(col.fieldId).prop === 'topic'"
              style="width: 99%"
            >
              <div
                v-limits-of-authority="'NODE_DETAIL_READ'"
                style="
                  cursor: pointer;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                class="hover-class"
                @click="edit(scope.row)"
              >
                {{ scope.row.topic }}
              </div>
            </div>
            <div
              style="display: flex; align-items: center"
              v-else-if="getColumnInfo(col.fieldId).prop === 'statusName'"
            >
              <!-- <status-tag v-model="scope.row.statusName" :style_catogry="'plain'" :use="false" :status_type="scope.row.statusCategory" :size="'small'">
              </status-tag> -->
              <status-select
                v-model="scope.row.statusName"
                :node-key="scope.row.key"
                :status_type="status[scope.row.status].statusCategory"
              ></status-select>
            </div>
            <span v-else-if="getColumnInfo(col.fieldId).prop === 'createdAt'">
              {{ new Date(scope.row.createdAt).Format("yyyy-MM-dd hh:mm:ss") }}
            </span>
            <span v-else-if="getColumnInfo(col.fieldId).prop === 'updatedAt'">
              {{ new Date(scope.row.updatedAt).Format("yyyy-MM-dd hh:mm:ss") }}
            </span>
            <span
              v-else-if="
                getColumnInfo(col.fieldId).isCus &&
                col.fieldId === 'nodeRelationList'
              "
            >
              <span
                v-if="nodeRelationListEdit === scope.row.key"
                class="node-relation"
              >
                <el-select
                  v-show="reloadSelect"
                  id="nodeKeyListEdit"
                  ref="nodeKeyListEdit"
                  v-model="scope.row.nodeRelationListName"
                  :loading="optionsLoading"
                  filterable
                  remote
                  multiple
                  reserve-keyword
                  :remote-method="getNodeList"
                  @change="
                    (val) => {
                      nodeRelationChange(scope.row, val);
                    }
                  "
                  @remove-tag="
                    (val) => {
                      removeNodeRelation(scope.row, val);
                    }
                  "
                  @visible-change="
                    (val) => {
                      if (!val) {
                        addRelationNode(scope.row);
                      }
                    }
                  "
                >
                  <el-option
                    v-for="item in publicOptions"
                    :key="item.nodeKey"
                    :value="item.nodeKey"
                  >
                    <!-- ms8613代码定位 -->
                    <select-icon :item="item"> </select-icon>
                  </el-option>
                </el-select>
              </span>
              <span
                v-else
                style="
                  display: inline-block;
                  min-width: 100px;
                  min-height: 30px;
                "
                @click.stop="showEdit(scope.row)"
              >
                <a
                  v-for="(item, index) in getnodeRelationName(
                    scope.row.nodeRelationList
                  )"
                  :key="index"
                  :href="`/${item.pid}/nodes/key/${item.key}`"
                  onclick="window.event.cancelBubble=true;"
                  target="_blank"
                >
                  {{ item.key }}
                  <span
                    v-if="
                      index !==
                      getnodeRelationName(scope.row.nodeRelationList).length - 1
                    "
                    >,</span
                  >
                </a>
              </span>
            </span>
            <span
              v-else-if="
                getColumnInfo(col.fieldId).isCus &&
                [
                  'baseline',
                  'version',
                  'testPlan',
                  'sprint',
                  'fileName',
                ].indexOf(col.fieldId) === -1
              "
            >
              {{ get_cus_value(scope.row, col.fieldId, col) }}
            </span>
            <span
              v-else-if="
                getColumnInfo(col.fieldId).isCus &&
                [
                  'baseline',
                  'version',
                  'testPlan',
                  'sprint',
                  'fileName',
                ].indexOf(col.fieldId) !== -1
              "
            >
              {{ getReleastName(col.fieldId, scope.row) }}
            </span>
            <span v-else>{{ scope.row[getColumnInfo(col.fieldId).prop] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <div
        v-if="is_bulk"
        style="font-weight: 400; color: #606266; padding-top: 10px"
      >
        {{ $t("canvas.p1") }}
        <span style="margin-left: 10px; margin-right: 10px">{{
          pages.total_element
        }}</span>
        {{ $t("canvas.p2") }}
      </div>
      <div v-else-if="!isShoucangChecked" class="my-page">
        <el-pagination
          :current-page="pages.currentPage"
          background
          :page-sizes="[10, 20, 50]"
          :page-size="20"
          layout="total, prev, pager, next, jumper"
          :total="pages.total_element"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <div v-else class="my-page">
        <el-pagination
          :current-page="currentShoucangPage"
          background
          :page-sizes="[10, 20, 50]"
          :page-size="20"
          layout="total, prev, pager, next, jumper"
          :total="totalShoucangPage"
          @current-change="handleShoucangChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="$t('node.top.confirmForm.title')"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="save_filter_dialog"
      custom-class="edit_condition"
    >
      <el-form ref="form" label-position="left" label-width="100px">
        <el-form-item>
          <span slot="label" class="red_star">{{
            $t("node.top.confirmForm.name")
          }}</span>
          <el-input v-model="save_filter_input"></el-input>
        </el-form-item>
        <el-form-item :label="$t('node.top.confirmForm.notificationFrequency')">
          <el-select v-model="save_filter_week" clearable style="width: 30%">
            <el-option
              v-for="item in weekOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-cascader
            v-model="save_filter_time"
            :options="timeOptions"
            :props="{
              multiple: true,
            }"
            clearable
            style="width: 70%"
          ></el-cascader>
        </el-form-item>
        <el-form-item :label="$t('node.top.confirmForm.notificationObject')">
          <!-- 多用户下拉框 -->
          <el-select
            v-model="save_filter_target"
            multiple
            filterable
            clearable
            style="width: 100%"
          >
            <el-option-group
              v-for="(group, index) in filterGroupandUser"
              :key="group.label"
              :label="group.label"
            >
              <el-option
                v-for="user in group.options"
                :key="user.value"
                :label="user.label"
                :value="user.value"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="info"
          size="small"
          @click="save_filter_dialog = false"
          >{{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button type="primary" size="small" @click="save_filter_confirm">{{
          currentId ? $t("btn.saveBtn") : $t("btn.newBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 筛选条件弹窗 -->
    <el-dialog
      :title="$t('file.dialog.condition')"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="more_condition_dialog"
    >
      <el-table
        :header-cell-style="{
          color: '#383838',
          'font-weight': '700',
        }"
        :cell-style="{
          color: '#636363',
          'font-weight': '700',
        }"
        :data="
          all_options.slice(
            (currentPage - 1) * dialog_pagesize,
            currentPage * dialog_pagesize
          )
        "
        style="width: 100%"
        class="dialog_table"
      >
        <el-table-column
          prop="name"
          :label="$t('file.dialog.name')"
          min-width="300"
        >
          <template slot-scope="scope">
            <span
              v-if="editing_row.queryConditionId !== scope.row.queryConditionId"
              style="cursor: pointer"
              @click="click_condition(scope)"
              >{{ scope.row.name }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="createdBy"
          :label="$t('file.dialog.creator')"
          min-width="130"
        >
          <template slot-scope="scope">
            <span>{{ name_span(scope.row.createdBy) }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('file.dialog.option')">
          <template slot-scope="scope">
            <span
              v-if="scope.row.createdBy === userAccountId"
              type="text"
              size="small"
              style="
                color: rgb(48, 100, 143);
                cursor: pointer;
                margin-right: 6px;
                font-weight: normal;
              "
              @click="editRow(scope)"
            >
              <i class="iconfont icon-branch_edit"></i>
            </span>
            <span
              v-if="scope.row.createdBy === userAccountId"
              type="text"
              size="small"
              style="color: #f56c6c; cursor: pointer; font-weight: normal"
              @click="delete_row(scope)"
            >
              <i class="iconfont icon-delete"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>

      <div style="text-align: center; margin-top: 10px; margin-bottom: 24px">
        <el-pagination
          background
          layout="prev, pager, next,total"
          :total="all_options.length"
          :page-size="dialog_pagesize"
          @current-change="dialog_current_change"
        ></el-pagination>
      </div>
    </el-dialog>

    <!-- 节点页批量导出excel弹窗 -->
    <el-dialog
      width="25%"
      :visible="dialogVisible"
      :before-close="handleClose"
      custom-class="excel_export_dialog"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('file.table.options.dialog.title')"
    >
      <div v-loading="excel_loading">
        <div
          style="
            background-color: rgba(228, 235, 241, 0.62);
            margin-bottom: 10px;
            font-size: 12px;
            font-color: rgba(128, 128, 128, 1);
            padding: 10px;
            border-radius: 4px;
            width: 100%;
            line-height: 20px;
            text-align: left;
          "
        >
          <i
            class="iconfont icon-help"
            style="font-size: 18px; color: rgb(48, 100, 143)"
          ></i>
          {{ $t("node.excel.tip1") }}
        </div>

        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;
          "
        >
          <div
            style="
              margin-right: 20px;
              font-size: 16px;
              font-weight: 400;
              text-align: left;
              color: gray;
            "
          >
            {{ $t("btn.export_tem") }}
          </div>
          <el-select
            v-model="template"
            :placeholder="$t('placeholder.dropdown')"
            clearable
          >
            <el-option
              v-for="item in tem_options"
              :key="item.templateId"
              :label="item.name"
              :value="item.templateId"
            >
            </el-option>
          </el-select>
        </div>
        <el-checkbox v-model="all_fields_item" @change="judge_all"
          >{{ $t("file.table.options.dialog.box1") }}
        </el-checkbox>
        <el-checkbox v-model="must_check" disabled>{{
          $t("file.table.options.dialog.box3")
        }}</el-checkbox>
        <el-checkbox v-model="must_check" disabled>{{
          $t("file.table.options.dialog.box4")
        }}</el-checkbox>
        <el-checkbox-group
          v-model="submit_list"
          text-color=" rgba(66, 159, 255, 1)"
        >
          <el-checkbox
            v-for="(item, index) in checkList"
            :key="index"
            :label="item.fieldId"
            >{{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
        <div
          slot="footer"
          style="padding: 20px 0; display: flex; justify-content: flex-end"
        >
          <el-button @click="dialogVisible = false">{{
            $t("file.table.options.dialog.box5")
          }}</el-button>
          <el-button type="primary" @click="submit_Excel">{{
            $t("file.table.options.dialog.box6")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 节点页批量修改字段 -->
    <el-dialog
      width="25%"
      :visible.sync="branchEditDialogVisible"
      :close-on-click-modal="false"
      :title="$t('file.table.options.dialog.title2')"
    >
      <div>
        <div>
          <el-form ref="newFileForm">
            <el-form-item>
              <el-select
                v-model="branchEditselected"
                style="width: 100%"
                filterable
                clearable
                :placeholder="$t('placeholder.dropdown')"
                @change="branchEditselectedChange"
              >
                <el-option
                  v-for="item in branchEditFieldList"
                  :key="item.value"
                  :label="item.label"
                  :value="{
                    value: item.value,
                    type: item.type,
                    extraData: item.extraData,
                    isCustom: item.isCustom,
                  }"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div
          v-if="
            branchEditselected.value === 'baseline_id' ||
            branchEditselected.value === 'version_id' ||
            branchEditselected.value === 'test_plan_id'
          "
          style="margin-bottom: 10px; margin-top: -6px"
        >
          <el-alert
            :title="$t('node.batchField.alert')"
            type="warning"
            :closable="false"
            show-icon
          >
          </el-alert>
        </div>
        <div v-if="branchEditselected.value">
          <el-form>
            <el-form-item v-if="branchEditselected.type === 'NUMBER'">
              <el-input
                v-model="fieldValue"
                type="number"
                :placeholder="$t('placeholder.number')"
              >
              </el-input>
            </el-form-item>
            <el-form-item v-else-if="branchEditselected.type === 'TEXT'">
              <!-- TEXT LINK -->
              <el-input
                v-model="fieldValue"
                :placeholder="$t('placeholder.input')"
              >
              </el-input>
            </el-form-item>
            <el-form-item v-else-if="branchEditselected.type === 'TEXTAREA'">
              <!-- TEXTAREA -->
              <el-input
                v-model="fieldValue"
                type="textarea"
                autosize
                :placeholder="$t('placeholder.input')"
              >
              </el-input>
            </el-form-item>
            <el-form-item v-else-if="branchEditselected.type === 'DATE'">
              <!-- DATE -->
              <el-date-picker
                v-model="fieldValue"
                value-format="yyyy-MM-dd"
                type="date"
                style="width: 100%"
                :placeholder="$t('placeholder.date')"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item v-else-if="branchEditselected.type === 'SELECT'">
              <!-- SELECT -->
              <el-select
                v-model="fieldValue"
                style="width: 100%"
                filterable
                clearable
              >
                <el-option
                  v-for="item in fieldExtraData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-else-if="branchEditselected.type === 'SELECT_MULTI'"
            >
              <!-- SELECT_MULTI VERSION -->
              <el-select
                v-model="fieldValues"
                style="width: 100%"
                multiple
                filterable
              >
                <el-option
                  v-for="item in fieldExtraData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-else-if="branchEditselected.type === 'SINGLE_USER_SELECT'"
            >
              <!-- SINGLE_USER_SELECT -->
              <el-select
                v-model="fieldValue"
                style="width: 100%"
                popper-class="user_select"
                filterable
                clearable
              >
                <el-option
                  v-for="opt in members"
                  v-show="!opt.deleted && !opt.hide"
                  :key="opt.accountId"
                  :label="opt.nickname"
                  :value="opt.accountId"
                >
                  <personal-avatar
                    class="avatar"
                    :avatar="opt.avatar"
                    :colorid="opt.accountId"
                    :nickname="opt.nickname"
                    :size="20"
                  >
                  </personal-avatar>
                  <span class="select_item">{{ opt.nickname }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-else-if="branchEditselected.type === 'MULTI_USER_SELECT'"
            >
              <!-- MULTI_USER_SELECT -->
              <el-select
                v-model="fieldValues"
                style="width: 100%"
                popper-class="user_select"
                multiple
                filterable
                clearable
              >
                <el-option
                  v-for="opt in members"
                  v-show="!opt.deleted && !opt.hide"
                  :key="opt.accountId"
                  :label="opt.nickname"
                  :value="opt.accountId"
                >
                  <personal-avatar
                    class="avatar"
                    :avatar="opt.avatar"
                    :colorid="opt.accountId"
                    :nickname="opt.nickname"
                    :size="20"
                  >
                  </personal-avatar>
                  <span class="select_item">{{ opt.nickname }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-else-if="branchEditselected.type === 'LABEL'">
              <!-- LABEL -->
              <el-select
                v-model="fieldValue"
                style="width: 100%"
                :placeholder="$t('placeholder.dropdown')"
                :loading-text="$t('loading.loading2')"
                multiple
                allow-create
                filterable
              >
                <el-option> </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="branchEditDialogVisible = false">{{
          $t("file.table.options.dialog.box5")
        }}</el-button>
        <el-button type="primary" @click="branchEditConfirm">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 批量关联节点 -->
    <el-dialog
      :visible.sync="addrelatedDialog"
      width="25%"
      center
      class="createpro"
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      id="zdh_guide_tip1_dig1"
    >
      <div class="createpro-header">
        <el-tabs v-model="addLinkNodeTab">
          <el-tab-pane
            :label="$t('nodeDetail.relationChart.addRelatedNode')"
            name="accurate"
          >
            <el-select
              v-model="addrelatedValue"
              style="width: 100%; margin-top: 10px"
              multiple
              filterable
              remote
              reserve-keyword
              collapse-tags
              :placeholder="$t('nodeDetail.relationChart.placeholder')"
              :loading-text="$t('loading.loading2')"
              :remote-method="get_unrelation_node"
              :loading="addrelatedLoading"
              @visible-change="clearData"
              popper-class="select-icon-height-auto-class"
            >
              <el-option
                v-for="item in addrelatedOptions"
                :key="item.nodeKey"
                :label="`${item.nodeKey}: ${cut(item.topic)}`"
                :value="item.nodeKey"
              >
                <!-- ms8613代码定位 -->
                <select-icon :item="item"> </select-icon>
              </el-option>
            </el-select>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('nodeDetail.relationChart.Batchassociatednodes')"
            name="second"
          >
            <div style="margin-top: 10px">
              <el-input
                v-model="batchNodeStr"
                type="textarea"
                size="medium"
                style="margin-right: 10px"
                @input="batchNodeInput"
                :placeholder="$t('nodeDetail.relationChart.placeholder2')"
              ></el-input>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="cancelAddRelated"
          >{{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button
          type="primary"
          size="small"
          class="button-confirm"
          :loading="batchLoading"
          @click="confirmAddRelated"
          >{{ $t("btn.confirmBtn") }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('baseline.archive.title1')"
      :visible.sync="nodeVersionDialog"
      :modal="false"
      top="30vh"
      width="25%"
    >
      <el-form
        ref="nodeVersionform"
        :model="nodeVersionForm"
        label-width="140px"
        label-position="left"
      >
        <el-form-item
          :label="$t('node.version.versionName')"
          prop="version"
          :rules="{
            required: true,
            trigger: 'blur',
            message: $t('placeholder.notNull'),
          }"
        >
          <el-input v-model="nodeVersionForm.version"></el-input>
        </el-form-item>
        <el-form-item :label="$t('baseline.archive.remark')" prop="desc">
          <el-input v-model="nodeVersionForm.desc" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="nodeVersionDialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          :loading="saveVersionBtnLoading"
          @click="saveVersion"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </span>
    </el-dialog>
    <NodeDrawer
      :drawer.sync="drawer"
      :current_node="current_node"
      :file_icon_name="file_icon_name"
      :drawer_loading="drawer_loading"
      :file_icon="file_icon"
      :members-show="membersShow"
      :members="members"
      :show-test-plan="showTestPlan"
      @close="before_close_drawer"
      @loading-finished="drawer_loading = false"
      @update_description_content="update_description_content"
      @update_current_node="update_current_node"
    />
  </div>
</template>

<script>
import { get_templates } from "@/network/template/index.js";
import { mapGetters } from "vuex";
import { get_filetype, people_get, getFile } from "@/network/home/index.js";
import { get_allreadyuse_status_list } from "@/network/workflow/index.js";
import {
  get_allProjectMember,
  getBatchField,
  putBatchField,
  add_relation_node,
  add_batch_relation_node,
  cancel_relation_node,
  mqlFilter,
  get_Labels,
} from "@/network/node/index.js";
import { searchNodesByFuzzyKey } from "@/network/fileOperation/index.js";
import { get_node_use_node_key } from "@/network/node/index.js";
import {
  get_file_List,
  save_condition,
  save_senior_condition,
  condition_get,
  condition_delete,
  usage,
  condition_edit,
  get_file_List_datachart,
} from "@/network/nodePage/index.js";
import { all_fields_get, getSystemFields } from "@/network/fileType/index.js";
import StatusSelect from "@/components/statusSelect";
import PersonalAvatar from "@/components/personal";
import { getUserCollections } from "@/network/collections/index.js";
import Sortable from "sortablejs";
import { limits_of_authority_disable } from "@/utils/limits";
import {
  get_excel_nodeField,
  upload_blur_excelField,
} from "@/network/exportExcel/index.js";

import { saveArchive } from "@/network/nodeVersion";
import { downloadLink } from "@/utils/download.js";
import DateRangePicker from "vue2-daterange-picker";
import selectIcon from "@/components/selectIcon";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import NodeDrawer from "@/components/nodeDrawer";
import aiSearch from "./components/aiSearch";
import seniorSearch from "./components/seniorSearch";
// 局部导入animate.css
import "animate.css";
export default {
  components: {
    StatusSelect,
    DateRangePicker,
    PersonalAvatar,
    selectIcon,
    NodeDrawer,
    aiSearch,
    seniorSearch,
  },
  data() {
    return {
      // 查询相关
      searchTypeList: [
        {
          type: "simple",
          label: this.$t("node.top.senior"),
        },
        {
          type: "senior",
          label: this.$t("node.top.simple"),
        },
        // {
        //   type: "AI",
        //   label: " AI搜索",
        // },
      ],
      searchTypeIndex: 0,
      searchType: {
        type: "simple",
        label: this.$t("node.top.senior"),
      },
      // 抽屉相关
      drawer: false,
      current_node: {},
      drawer_loading: false,
      file_icon_name: "",
      file_icon: "",
      // 日期选择插件配置中文
      localeDate: {
        direction: "ltr",
        format: "yyyy/mm/dd",
        separator: " - ",
        applyLabel: this.$t("btn.confirmBtn"),
        cancelLabel: this.$t("btn.cancelBtn"),
        weekLabel: "W",
        customRangeLabel: this.$t("btn.Customize"),
        daysOfWeek: [
          this.$t("node.date.Sun"),
          this.$t("node.date.Mon"),
          this.$t("node.date.Tue"),
          this.$t("node.date.Wed"),
          this.$t("node.date.Thur"),
          this.$t("node.date.Fri"),
          this.$t("node.date.Sat"),
        ],
        monthNames: [
          this.$t("node.date.January"),
          this.$t("node.date.February"),
          this.$t("node.date.March"),
          this.$t("node.date.April"),
          this.$t("node.date.May"),
          this.$t("node.date.June"),
          this.$t("node.date.July"),
          this.$t("node.date.August"),
          this.$t("node.date.September"),
          this.$t("node.date.October"),
          this.$t("node.date.November"),
          this.$t("node.date.December"),
        ],
        firstDay: 1,
      },
      // 全局loading
      loading: false,
      totalShoucangPage: 0,
      currentShoucangPage: 0,
      fileTypes: [],
      isShoucangChecked: false,
      conditions_filter: "",
      searchParams: {
        search_box: "",
        fileTypeId: [],
        status: [],
        createdBy: [],
        assignee: [],
        filter: "",
        createdAt: {},
        updatedAt: {},
      },
      isChangingCreatedAt: false,
      isChangingUpdatedAt: false,
      // 全选相关
      checkAll: {
        fileTypeId: false,
        createdBy: false,
        status: false,
        assignee: false,
      },
      isIndeterminate: {
        fileTypeId: false,
        createdBy: false,
        status: false,
        assignee: false,
      },
      moreCheckAll: false,
      moreIndeterminate: false,
      conditions: [],
      template: "",
      tem_options: [],
      type_list: [], // 全部节点类型
      status_list: [], // 全部状态类型
      user_list1: [], // 全部创始人
      user_list_filter: [], //过滤离职的
      all_user_list: [],
      tableData: [],
      filteroptions: [],
      keySort: true,
      topicSort: true,
      assigneeSort: false,
      createdBySort: false,
      statusSort: false,
      createdAtSort: true,
      updatedAtSort: false,
      sortBy: "key",
      pages: {
        currentPage: 1,
        total_element: 0,
      },
      //勾选节点
      selectingMultiple: false,
      selected: [],
      save_filter_dialog: false,
      save_filter_input: "",
      save_filter_week: "",
      save_filter_time: [],
      save_filter_target: [],
      filterGroupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      weekOptions: [],
      timeOptions: [],
      // 正在编辑的条件id
      currentId: "",
      // 更多筛选
      custom_filter_list: [],
      more_filters: [],
      more_column: [],
      tmp: [],
      custom_filters_value: {},
      form_date: {},
      form_number: {},
      dropCol: [
        {
          id: this.randomString(10),
          fieldId: 1,
          width: 150,
          minWidth: 150,
        },
        {
          id: this.randomString(10),
          fieldId: 2,
          width: 150,
          minWidth: 150,
        },
        {
          id: this.randomString(10),
          fieldId: 3,
          minWidth: 350,
        },
        {
          id: this.randomString(10),
          fieldId: 4,
          width: 180,
          minWidth: 150,
        },
        {
          id: this.randomString(10),
          fieldId: 5,
          width: 180,
          minWidth: 180,
        },
        {
          id: this.randomString(10),
          fieldId: 6,
          width: 180,
          minWidth: 180,
        },
        {
          id: this.randomString(10),
          fieldId: 7,
          width: 180,
          minWidth: 180,
        },
      ],
      dropColMap: [
        {
          prop: "fileTypeName",
          label: this.$t("node.table.type"),
          width: 150,
          minWidth: 150,
          fieldId: 1,
        },
        { prop: "key", label: "key", width: 150, minWidth: 150, fieldId: 2 },
        {
          prop: "topic",
          label: this.$t("node.table.name"),
          minWidth: 350,
          fieldId: 3,
        },
        {
          prop: "assignee",
          label: this.$t("node.table.responsibility"),
          width: 180,
          minWidth: 150,
          fieldId: 4,
        },
        {
          prop: "createdBy",
          label: this.$t("node.table.creator"),
          width: 180,
          minWidth: 180,
          fieldId: 5,
        },
        {
          prop: "statusName",
          label: this.$t("node.table.status"),
          width: 180,
          minWidth: 180,
          fieldId: 6,
        },
        {
          prop: "createdAt",
          label: this.$t("node.table.createdTime"),
          width: 180,
          minWidth: 180,
          fieldId: 7,
        },
        {
          prop: "updatedAt",
          label: this.$t("node.table.updatedTime"),
          width: 180,
          minWidth: 180,
          fieldId: 8,
        },
        {
          prop: "fileName",
          label: this.$t("node.table.fileName"),
          width: 180,
          minWidth: 180,
          fieldId: 9,
        },
      ],
      all_options: [], // 包含其他人创建的筛选条件
      other_options: [],
      more_condition_dialog: false,
      dialog_pagesize: 10, //指定展示多少条
      dialog_total: 0, //指定展示多少条
      currentPage: 1, //当前页码
      editing_row: {}, //编辑列
      id_name_map: new Map(), // id与name的映射
      checked_filter: {},
      time_out: null, //定时器
      text_construction_map: [
        this.$t("file.top.createBtn"),
        this.$t("file.top.saveBtn"),
        this.$t("file.top.anoSaveBtn"),
      ],
      otherCol: [],
      must_check: true,
      is_bulk: false,
      bulk_number: 0,

      totalPages: 0,
      scrollDistance: 1000,
      excelField: {},
      dialogVisible: false,
      submit_list: [],
      checkList: [],
      all_fields_item: false,
      systemFields_list: [],
      allFields_list: [],
      isall: false,
      excel_loading: false,
      // 批量修改字段相关
      branchEditDialogVisible: false,
      branchEditselected: {},
      branchEditFieldList: [],
      allFields: {},
      // 具体字段的选中值
      fieldValue: "",
      fieldValues: [],
      // 单选多选下拉框的options
      fieldExtraData: [],
      searchKey: {
        searchAssigneeKey: "",
        searchTypeKey: "",
        searchCreatorKey: "",
        searchStatusKey: "",
      },
      // defaultFileNameList
      defaultFileNameList: [],
      // 模糊搜索loading
      optionsLoading: false,
      reloadSelect: true,
      publicOptions: [],
      tempNodeList: [],
      nodeRelationListEdit: "",
      seniorSearchStr: "",
      seniorSearchList: [],
      shouldIgnoreNextChange: false,
      // 关联节点相关
      addrelatedDialog: false,
      addLinkNodeTab: "accurate",
      addrelatedValue: [],
      addrelatedOptions: [],
      addrelatedLoading: false,
      batchNodeStr: "",
      batchLoading: false,
      // 所有标签
      allLabelOptions: [],
      // 节点批量存档相关
      nodeVersionDialog: false,
      saveVersionBtnLoading: false,
      nodeVersionForm: {
        version: "",
        desc: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "testPlan",
      "version",
      "sprint",
      "baseLine",
      "fileType",
      "user_list",
      "status",
      "project_user_list",
      "project_type",
    ]),
    showTestPlan() {
      if (this.current_node && this.current_node.fileTypeId && this.fileType) {
        return this.fileType[this.current_node.fileTypeId]
          ? this.fileType[this.current_node.fileTypeId].testCase
          : false;
      }
      return false;
    },
    // 责任人选项列表
    filteredAssigneeList() {
      if (this.searchKey.searchInputKey === "") {
        return this.user_list_filter;
      }
      return this.user_list_filter.filter(
        (item) => item.nickname.indexOf(this.searchKey.searchAssigneeKey) >= 0
      );
    },
    // 类型选项列表
    filteredTypeList() {
      if (this.searchKey.searchTypeKey === "") {
        return this.type_list;
      }
      return this.type_list.filter(
        (item) => item.name.indexOf(this.searchKey.searchTypeKey) >= 0
      );
    },
    // 状态选项列表
    filteredStatusList() {
      if (this.searchKey.searchStatusKey === "") {
        return this.status_list;
      }
      return this.status_list.filter(
        (item) => item.name.indexOf(this.searchKey.searchStatusKey) >= 0
      );
    },
    // 创建人选项列表
    filteredCreatorList() {
      if (this.searchKey.searchCreatorKey === "") {
        return this.user_list_filter.slice(0, -1);
      }
      return this.user_list_filter
        .slice(0, -1)
        .filter(
          (item) => item.nickname.indexOf(this.searchKey.searchCreatorKey) >= 0
        );
    },

    members() {
      return Object.values(this.project_user_list);
    },
    release_map() {
      const obj = {
        baseline: [
          ...Object.values(this.baseLine),
          { baselineId: null, name: "null" },
        ],
        version: [
          ...Object.values(this.version),
          { versionId: null, name: "null" },
        ],
        testPlan: [
          ...Object.values(this.testPlan),
          { testPlanId: null, name: "null" },
        ],
        sprint: [
          ...Object.values(this.sprint),
          { sprintId: null, name: "null" },
        ],
        coverage: [
          { coverageId: true, name: this.$t("canvas.wordView.can_cover") },
          { coverageId: false, name: this.$t("canvas.wordView.no_need") },
        ],
        fileUuid: this.defaultFileNameList,
      };
      return obj;
    },
    text_construction() {
      if (this.checked_filter && this.checked_filter.name) {
        const flag = this.filteroptions.some((item) => {
          return item.queryConditionId === this.checked_filter.queryConditionId;
        });
        return flag ? 1 : 2;
      } else {
        return 0;
      }
    },
    custom_filters() {
      let arr = [];
      this.more_filters.forEach((name) => {
        const obj = JSON.parse(
          JSON.stringify(
            Object.assign(
              {},
              this.custom_filter_list.find((item) => name == item.fieldId)
            )
          )
        );
        obj.extraData ? obj.extraData.push({ value: null, label: "null" }) : "";
        arr.push(obj);
      });
      return arr;
    },
    moreSelectList() {
      let obj = {};
      this.custom_filters.forEach((item) => {
        if (
          item.fieldType == "MULTI_USER_SELECT" ||
          item.fieldType == "SINGLE_USER_SELECT"
        ) {
          obj[item.fieldId] = this.user_list_filter;
        } else if (
          [
            "baseline",
            "version",
            "testPlan",
            "sprint",
            "coverage",
            "fileUuid",
          ].indexOf(item.fieldId) === -1
        ) {
          obj[item.fieldId] = item.extraData;
        } else {
          obj[item.fieldId] = this.release_map[item.fieldId];
        }
      });
      return obj;
    },
    userAccountId() {
      return this.$store.getters.userAccountId;
    },
    membersShow() {
      return this.members.length ? true : false;
    },
  },
  watch: {
    more_column(newValue, oldValue) {
      if (newValue.length < oldValue.length) {
        const minus = oldValue.filter((col) => !newValue.includes(col));
        minus.forEach((value) => {
          const idx = this.findIndex(value);
          if (idx !== -1) {
            this.dropCol.splice(idx, 1);
          }
        });
      } else {
        newValue.forEach((col) => {
          if (!this.isInDropCol(col)) {
            this.dropCol.push({
              id: this.randomString(10),
              fieldId: col,
              minWidth: 150,
              isCus: true,
            });
          }
        });
      }
      localStorage.setItem(
        `dropCol:${this.get_pid()}`,
        JSON.stringify(this.dropCol)
      );
    },
    more_filters() {
      this.more_filters.forEach((name) => {
        if (!this.custom_filters_value[name]) {
          let type = this.custom_filter_list.find(
            (ele) => ele.fieldId == name
          ).fieldType;
          if (this.filter_is_select(type) == "SELECT") {
            this.custom_filters_value[name] = [];
            this.searchKey["search" + name + "Key"] = "";
          }
        }
      });
    },
    scrollDistance(newValue) {
      if (newValue <= 1) {
        if (this.bulk_number < this.totalPages) {
          this.bulk_number++;
          this.bulk_getTableData();
        }
      }
    },
    save_filter_dialog(newVaule) {
      if (!newVaule) {
        this.currentId = "";
      }
    },
  },
  updated() {
    // 解决动态增加列，表格高度减少的问题
    this.$nextTick(() => {
      this.$refs.myTable.doLayout();
      // el-table添加ref="tableName"
    });
  },
  async mounted() {
    get_templates().then((res) => {
      this.tem_options = [
        ...res.filter((obj) => obj.exportTemplateType === "EXCEL"),
      ];
      this.template = "";
    });
    this.loading = true;
    get_file_List_datachart(
      true,
      0,
      20,
      this.get_pid(),
      "fileKey",
      [],
      ""
    ).then((res) => {
      this.defaultFileNameList = res.content;
    });
    await this.get_custom_filter();
    await this.get_status_list(); // 获取状态下拉
    await this.get_filetypes(); //获取类型下拉
    await this.get_responsibles(); //获取创建人下拉
    await this.update_filter(true);
    if (this.$route.query.fieldId) {
      for (let key in this.fileType) {
        if (this.fileType[key].name === this.$route.query.value) {
          this.searchParams.fileTypeId.push(key);
          this.selecteFiletype("fileTypeId");
          this.conditions.value.push(key);
        }
      }
      this.choose_filter(
        this.$route.query.queryConditionId,
        this.$route.query.fieldId,
        this.$route.query.value
      );
      this.conditions_filter = this.$route.query.queryConditionId;
    } else if (this.$route.query.conditions) {
      const conditions = JSON.parse(this.$route.query.conditions);
      const queryConditionId = this.$route.query.queryConditionId;
      this.choose_filter(queryConditionId, null, null, conditions);
      this.conditions_filter = queryConditionId;
    } else if (this.$route.query.queryConditionId) {
      const queryConditionId = this.$route.query.queryConditionId;
      this.choose_filter(queryConditionId, null, null, null);
      this.conditions_filter = queryConditionId;
    } else {
      //获取表格数据
      this.getTableData();
    }
    this.get_allProjectMember();
    this.columnDrop();
    if (JSON.parse(localStorage.getItem(`dropCol:${this.get_pid()}`))) {
      this.dropCol = JSON.parse(
        localStorage.getItem(`dropCol:${this.get_pid()}`)
      );
    }
    // 获取用户组
    this.getGroupList();
    // 获取周
    this.getWeekOptions();
    // 获取时间
    this.getTimeOptions();
    // 获取所有label
    this.getAllLabelOptions();
  },
  methods: {
    limits_of_authority_disable,
    // 节点批量存档
    saveVersion() {
      this.$refs["nodeVersionform"].validate(async (valid) => {
        if (valid) {
          const arr = [];
          this.selected.forEach((node) => {
            const obj = {
              nodeKey: node.key,
              remark: this.nodeVersionForm.desc,
              archiveName: `${
                this.nodeVersionForm.version
              }[${new Date().getTime()}]`,
            };
            arr.push(obj);
          });
          this.saveVersionBtnLoading = true;
          const params = {
            projectId: this.get_pid(),
            data: arr,
          };
          saveArchive(params)
            .then((res) => {
              this.saveVersionBtnLoading = false;
              this.nodeVersionDialog = false;
              this.$message({
                type: "success",
                message: this.$t("chart.message.saveSuccess"),
              });
              this.nodeVersionForm = {
                version: "",
                desc: "",
              };
            })
            .catch((err) => {
              this.saveVersionBtnLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    getAllLabelOptions() {
      get_Labels(this.get_pid())
        .then((res) => {
          this.allLabelOptions = res;
        })
        .catch((err) => console.log(err));
    },
    batchNodeInput(value) {
      this.batchNodeStr = value.replace(/[\n\s，、/\\]+/g, ",");
    },
    clearData() {
      if (this.addrelatedValue.length == 0) this.addrelatedOptions = [];
    },
    get_unrelation_node(query) {
      if (query !== "") {
        this.addrelatedLoading = true;
        let related_nodes = [];
        searchNodesByFuzzyKey(query)
          .then((res) => {
            this.addrelatedOptions = res.filter((ele) => {
              if (related_nodes.indexOf(ele.nodeKey) == -1) {
                return ele;
              }
            });
            this.addrelatedLoading = false;
          })
          .catch((err) => console.log(err));
      } else {
        this.addrelatedOptions = [];
      }
    },
    confirmAddRelated() {
      this.batchLoading = true;
      const params = {
        projectId: this.get_pid(),
        data: {
          sourceNodeList: [],
          targetNodeKeyList: [],
        },
      };
      this.selected.forEach((item) => {
        const obj = {
          projectId: item.projectId,
          nodeKey: item.key,
          fileTypeId: item.fileTypeId,
        };
        params.data.sourceNodeList.push(obj);
      });
      if (this.addLinkNodeTab == "accurate") {
        params.data.targetNodeKeyList = this.addrelatedValue;
      } else {
        params.data.targetNodeKeyList = this.batchNodeStr
          .split(",")
          .filter((item) => {
            return item;
          });
      }
      add_batch_relation_node(params)
        .then(() => {
          this.batchLoading = false;
          this.addrelatedValue = [];
          this.batchNodeStr = "";
          this.addrelatedDialog = false;
          this.$message.success(this.$t("nodeDetail.third.addsucc"));
        })
        .catch((err) => {
          this.batchLoading = false;
          console.log(err);
        });
    },
    cancelAddRelated() {
      this.addrelatedValue = [];
      this.batchNodeStr = "";
      this.addrelatedDialog = false;
    },
    copyKeys() {
      if (!this.selected.length) {
        this.$message.warning(this.$t("canvas.AI.tip5"));
        return;
      }
      // 创建一个 Input标签
      const cInput = document.createElement("input");
      cInput.value = ``;
      this.selected.forEach((item) => {
        cInput.value += item.key;
        cInput.value += ",";
      });
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本域内容;
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效
      document.execCommand("Copy");
      this.$message.success(this.$t("personal.openApi.message3"));
      /// 复制成功后再将构造的标签 移除
      cInput.remove();
    },
    saveVersionDialog() {
      if (!this.selected.length) {
        this.$message.warning(this.$t("canvas.AI.tip5"));
        return;
      }
      this.nodeVersionDialog = true;
    },
    AddRelatedNodes() {
      if (!this.selected.length) {
        this.$message.warning(this.$t("canvas.AI.tip5"));
        return;
      }
      this.addrelatedDialog = true;
    },
    saveAs() {
      this.save_filter_input = "";
      this.save_filter_week = "";
      this.save_filter_time = [];
      this.save_filter_target = [];
      this.save_filter_dialog = true;
    },
    getTimeOptions() {
      const arr = this.$t("node.top.confirmForm.weeks");
      arr.forEach((item, index) => {
        const week = {
          value: index + 1,
          label: item,
          children: [],
        };
        for (let i = 8; i < 24; i++) {
          const time = {
            label: `${i < 10 ? "0" + i : i}: 00`,
            value: i,
          };
          week.children.push(time);
        }
        this.timeOptions.push(week);
      });
    },
    getWeekOptions() {
      for (let i = 1; i < 53; i++) {
        const obj = {
          value: i,
          label: `${this.$t("node.top.confirmForm.every")} ${i} ${this.$t(
            "node.top.confirmForm.week"
          )}`,
        };
        this.weekOptions.push(obj);
      }
    },
    getGroupList() {
      const group = Object.values(this.$store.getters.user_group_list);
      const user = Object.values(this.$store.getters.project_user_list);
      const filteredArray = user.filter((obj) => obj.deleted == 0);
      filteredArray.map((item, index) => {
        this.filterGroupandUser[0].options.push({
          value: item.accountId,
          label: item.nickname,
        });
      });
      group.map((item, index) => {
        this.filterGroupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
      });
    },
    changeSearchType() {
      this.seniorSearchStr = "";
      if (this.searchTypeIndex === 1) {
        this.searchTypeIndex = 0;
      } else {
        this.searchTypeIndex++;
      }
      this.searchType = this.searchTypeList[this.searchTypeIndex];
      this.conditions_filter = "";
      this.clear_filter();
      this.choose_filter("");
    },
    before_close_drawer() {
      this.getTableData();
      this.drawer = false;
    },
    update_description_content(description) {
      this.current_node.description = description;
    },
    update_current_node(e) {
      this.getTableData();
    },
    cut(str) {
      if (str.length > 15) {
        return str.slice(0, 14) + "...";
      } else {
        return str;
      }
    },
    // 模糊搜索关联节点
    away() {
      this.nodeRelationListEdit = "";
    },
    showEdit(row) {
      this.nodeRelationListEdit = row.key;
      this.$nextTick(() => {
        this.$refs["nodeKeyListEdit"][0].focus();
      });
    },
    removeNodeRelation(row, val) {
      let data = {
        sourceNodeKey: row.key,
        targetNodeKey: val,
        sourceProjectId: this.get_pid(),
        targetProjectId: "",
      };
      row.nodeRelationList.forEach((item) => {
        if (item.targetNodeKey === val) {
          data.targetProjectId = item.targetProjectId;
        }
      });
      cancel_relation_node(this.get_pid(), row.key, data)
        .then(() => {})
        .catch((err) => console.log(err));
    },
    nodeRelationChange(row, val) {
      this.reloadSelect = false;
      this.$nextTick(() => {
        row.nodeRelationListName = val;
        this.reloadSelect = true;
      });
      this.tempNodeList = [];
      val.forEach((item) => {
        this.publicOptions.forEach((option) => {
          if (option.nodeKey === item) {
            this.tempNodeList.push(option);
          }
        });
      });
    },
    addRelationNode(row) {
      const arr = [];
      this.tempNodeList.forEach((item) => {
        arr.push({
          sourceFileTypeId: row.fileTypeId,
          targetFileTypeId: item.fileTypeId,
          sourceNodeKey: row.key,
          targetNodeKey: item.nodeKey,
          sourceProjectId: this.get_pid(),
          targetProjectId: item.projectId,
        });
      });
      add_relation_node(this.get_pid(), row.key, arr).then(() => {
        this.getTableData();
        this.nodeRelationListEdit = "";
      });
    },
    getNodeList(query) {
      if (query !== "") {
        this.optionsLoading = true;
        searchNodesByFuzzyKey(query)
          .then((res) => {
            this.publicOptions = res;
            this.optionsLoading = false;
          })
          .catch((err) => console.log(err));
      } else {
        this.publicOptions = [];
      }
    },
    HeaderDragend(newWidth, oldWidth, column, event) {
      this.dropCol.forEach((item) => {
        if (item.id === column.className) {
          item.minWidth = newWidth;
          item.width = newWidth;
          localStorage.setItem(
            `dropCol:${this.get_pid()}`,
            JSON.stringify(this.dropCol)
          );
        }
      });
    },
    // 全选
    handleCheckAllChange(val, suffix) {
      switch (suffix) {
        case "more":
          this.more_filters = val
            ? this.custom_filter_list.map((item) => {
                return item.fieldId;
              })
            : [];
          this.moreIndeterminate = false;
          break;
        case "fileTypeId":
          this.searchParams[suffix] = val
            ? this.type_list.map((item) => {
                return item.fileTypeId;
              })
            : [];
          this.isIndeterminate[suffix] = false;
          this.selecteFiletype(suffix);
          break;
        case "createdBy":
          this.searchParams[suffix] = val
            ? this.user_list_filter.slice(0, -1).map((item) => {
                return item.accountId;
              })
            : [];
          this.isIndeterminate[suffix] = false;
          this.selecteFiletype(suffix);
          break;
        case "status":
          this.searchParams[suffix] = val
            ? this.status_list.map((item) => {
                return item.statusId;
              })
            : [];
          this.isIndeterminate[suffix] = false;
          this.selecteFiletype(suffix);
          break;
        case "assignee":
          this.searchParams[suffix] = val
            ? this.user_list_filter.map((item) => {
                return item.accountId;
              })
            : [];
          this.isIndeterminate[suffix] = false;
          this.selecteFiletype(suffix);
          break;
        // 用户自定义
        default:
          if (
            suffix.fieldType == "MULTI_USER_SELECT" ||
            suffix.fieldType == "SINGLE_USER_SELECT"
          ) {
            this.custom_filters_value[suffix.fieldId] = val
              ? this.user_list_filter.map((item) => {
                  return item.accountId;
                })
              : [];
          } else if (
            [
              "baseline",
              "version",
              "testPlan",
              "sprint",
              "coverage",
              "fileUuid",
            ].indexOf(suffix.fieldId) === -1
          ) {
            this.custom_filters_value[suffix.fieldId] = val
              ? suffix.extraData.map((item) => {
                  return item.value;
                })
              : [];
          } else {
            this.custom_filters_value[suffix.fieldId] = val
              ? this.release_map[suffix.fieldId].map((item) => {
                  return suffix.fieldId === "fileUuid"
                    ? item.id
                    : item[suffix.fieldId + "Id"];
                })
              : [];
          }
          this.isIndeterminate[suffix.fieldId] = false;
          this.moreSelecteFiletype(
            suffix.fieldId,
            suffix.fieldId,
            "SELECT",
            suffix
          );
      }
    },
    handleCheckedFiltersChange() {
      const checkedCount = this.more_filters.length;
      this.moreCheckAll = checkedCount === this.custom_filter_list.length;
      this.moreIndeterminate =
        checkedCount > 0 && checkedCount < this.custom_filter_list.length;
    },
    // 获取表格中所有release的name
    getReleastName(fieldId, row) {
      const map = {
        baseline: "baselineIdList",
        version: "versionIdList",
        testPlan: "testPlanIdList",
        sprint: "sprintId",
      };
      const store_map = {
        baseline: "baseLine",
        version: "version",
        testPlan: "testPlan",
        sprint: "sprint",
        fileName: "fileName",
      };
      if (typeof row[map[fieldId]] === "string") {
        // sprint
        if (row[map[fieldId]] && this[store_map[fieldId]][row[map[fieldId]]]) {
          if (
            this[store_map[fieldId]][row[map[fieldId]]].status == "NOT_STARTED"
          ) {
            return row[map[fieldId]]
              ? this[store_map[fieldId]][row[map[fieldId]]] &&
                  `${
                    this[store_map[fieldId]][row[map[fieldId]]].name
                  }(${this.$t("canvas.status.st1")})`
              : "";
          } else if (
            this[store_map[fieldId]][row[map[fieldId]]].status == "IN_PROGRESS"
          ) {
            return row[map[fieldId]]
              ? this[store_map[fieldId]][row[map[fieldId]]] &&
                  `${
                    this[store_map[fieldId]][row[map[fieldId]]].name
                  }(${this.$t("canvas.status.st2")})`
              : "";
          } else if (
            this[store_map[fieldId]][row[map[fieldId]]].status == "ENDED"
          ) {
            return row[map[fieldId]]
              ? this[store_map[fieldId]][row[map[fieldId]]] &&
                  `${
                    this[store_map[fieldId]][row[map[fieldId]]].name
                  }(${this.$t("canvas.status.st3")})`
              : "";
          }
        }

        return row[map[fieldId]]
          ? this[store_map[fieldId]][row[map[fieldId]]] &&
              this[store_map[fieldId]][row[map[fieldId]]].name
          : "";
      } else {
        if (fieldId == "fileName") {
          return row.fileName;
        }
        const arr = [];
        row[map[fieldId]] &&
          row[map[fieldId]].forEach((item) => {
            if (
              this[store_map[fieldId]][item] &&
              store_map[fieldId] == "baseLine"
            ) {
              // 基线
              if (this[store_map[fieldId]][item].status == "NOT_STARTED") {
                // 未开始
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st1"
                      )})`
                    )
                  : "";
                // this[store_map[fieldId]][item]
                // ? arr.push(
                //     `${this[store_map[fieldId]][item].name}(${
                //       this[store_map[fieldId]][item].status
                //     })`
                //   )
                // : "";
              } else if (
                this[store_map[fieldId]][item].status == "ALREADY_STARTED"
              ) {
                // 已开始
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st4"
                      )})`
                    )
                  : "";
              } else if (this[store_map[fieldId]][item].status == "COMPLETE") {
                // 已开始
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st3"
                      )})`
                    )
                  : "";
              }
            } else if (
              this[store_map[fieldId]][item] &&
              store_map[fieldId] == "version"
            ) {
              // 版本
              if (this[store_map[fieldId]][item].status) {
                // 已发布
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st5"
                      )})`
                    )
                  : "";
              } else {
                // 未发布
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st6"
                      )})`
                    )
                  : "";
              }
            } else if (
              this[store_map[fieldId]][item] &&
              store_map[fieldId] == "testPlan"
            ) {
              // 测试计划
              if (this[store_map[fieldId]][item].status == "NOT_STARTED") {
                // 未开始
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st1"
                      )})`
                    )
                  : "";
              } else if (
                this[store_map[fieldId]][item].status == "IN_PROGRESS"
              ) {
                // 已开始
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st2"
                      )})`
                    )
                  : "";
              } else if (this[store_map[fieldId]][item].status == "COMPLETE") {
                // 已开始
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st3"
                      )})`
                    )
                  : "";
              }
            }
          });
        return arr.join(", ");
      }
    },
    // 关联节点key
    getnodeRelationName(list) {
      const arr = [];
      list &&
        list.forEach((item) => {
          arr.push({
            key: item.targetNodeKey,
            pid: item.targetProjectId,
          });
        });
      return arr;
    },
    getnodeRelationNameList(list) {
      const arr = [];
      list &&
        list.forEach((item) => {
          arr.push(item.targetNodeKey);
        });
      return arr;
    },
    findIndex(fieldId) {
      let index = -1;
      this.dropCol.forEach((col, idx) => {
        if (col.fieldId === fieldId) {
          index = idx;
        }
      });
      return index;
    },
    getColumnInfo(fieldId) {
      // 自定义字段映射
      const systemIds = [1, 2, 3, 4, 5, 6, 9];
      const col = this.dropColMap.filter((col) => col.fieldId === fieldId)[0];

      col.id = this.randomString(10);
      if (!systemIds.includes(col.fieldId)) {
        col.isCus = true;
        col.minWidth = 150;
      }
      if (fieldId == "fileName") {
        col.minWidth = 250;
      }
      return col;
    },
    randomString(len) {
      len = len || 32;
      const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      let maxPos = chars.length;
      let pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    columnDrop() {
      const _this = this;
      const wrapperTr = document.querySelector(".el-table__header-wrapper tr");
      this.sortable = Sortable.create(wrapperTr, {
        filter: ".notDrag",
        onEnd: (evt) => {
          const old = _this.dropCol[evt.oldIndex];
          const newE = _this.dropCol[evt.newIndex];
          newE.id = this.randomString(10);
          old.id = this.randomString(10);
          const swapArrData = (index1, index2, arr) => {
            //index1,index2代表需要交换的数组索引
            const temp = arr.splice(index2, 1, arr[index1]);
            arr[index1] = temp[0];
            return arr;
          };
          swapArrData(evt.oldIndex, evt.newIndex, this.dropCol);
          localStorage.setItem(
            `dropCol:${this.get_pid()}`,
            JSON.stringify(this.dropCol)
          );
          // saveColumn(this.$store.getters.p_id, this.dropCol);
        },
      });
    },
    // 保存筛选条件按钮事件
    save_filter_fn() {
      this.save_filter_input = "";
      if (this.text_construction === 1) {
        condition_edit(this.get_pid(), this.checked_filter.queryConditionId, {
          mqlCondition: {
            mql: this.seniorSearchStr,
            frontEndContent: JSON.stringify(this.seniorSearchList),
          },
          nodeFilterNotification: this.checked_filter.nodeFilterNotificationDto,
          nodeQueryType: this.searchType.type == "simple" ? "NORMAL" : "MQL",
          queryConditions: this.conditions,
          name: this.checked_filter.name,
        }).then((res) => {
          this.$message({
            type: "success",
            message: this.$t("chart.message.saveSuccess"),
          });
        });
      } else {
        this.save_filter_input = "";
        this.save_filter_week = "";
        this.save_filter_time = [];
        this.save_filter_target = [];
        this.save_filter_dialog = true;
      }
    },
    // 点击条件事件
    click_condition(scope) {
      clearTimeout(this.time_out);
      this.time_out = setTimeout(() => {
        this.more_condition_dialog = false;
        this.conditions_filter = scope.row.queryConditionId;
        this.choose_filter(scope.row.queryConditionId);
      }, 300);
    },
    // 编辑筛选条件
    editRow(scope) {
      this.currentId = scope.row.queryConditionId;
      this.save_filter_input = scope.row.name;
      this.save_filter_week = scope.row.nodeFilterNotificationDto
        ? scope.row.nodeFilterNotificationDto.weekDurations
        : "";
      this.save_filter_time = scope.row.nodeFilterNotificationDto
        ? scope.row.nodeFilterNotificationDto.notifyTimeList.map((item) => {
            return [item.dayInWeek, item.hourInDay];
          })
        : [];
      this.save_filter_target = scope.row.nodeFilterNotificationDto
        ? [
            ...scope.row.nodeFilterNotificationDto.userList,
            ...scope.row.nodeFilterNotificationDto.userGroupList,
          ]
        : [];
      this.save_filter_dialog = true;
    },
    // 删除筛选条件
    delete_row(scope) {
      usage(this.get_pid(), scope.row.queryConditionId).then((res) => {
        if (!res.chartDtoList.length) {
          condition_delete(this.get_pid(), scope.row.queryConditionId)
            .then((res) => {
              this.all_options.splice(scope.$index, 1);
              if (scope.row.queryConditionId === this.conditions_filter) {
                this.conditions_filter = "";
                this.clear_filter();
                this.choose_filter("");
              }
              this.$message({
                type: "success",
                message: this.$t("chart.message.deleteSuccess1"),
              });
            }) //删除文件
            .catch(() => {
              this.$message({
                type: "info",
                message: this.$t("chart.message.deleteError"),
              });
            });
        } else {
          const arr = [];
          res.chartDtoList.forEach((item) => {
            arr.push(item.name);
          });
          this.$message({
            type: "warning",
            message:
              this.$t("chart.message.info1") +
              `（${arr.join("、")}）` +
              this.$t("chart.message.info2"),
          });
        }
      });
    },
    // 改变更多筛选框选项
    changeSelectList(id) {
      this.$forceUpdate();
      let tempKey = this.searchKey["search" + id + "Key"];
      if (tempKey === "") {
        if (id === "fileUuid") {
          get_file_List_datachart(
            true,
            0,
            20,
            this.get_pid(),
            "fileKey",
            [],
            ""
          ).then((res) => {
            this.$nextTick(() => {
              this.defaultFileNameList = res.content;
            });
          });
        } else {
          this.custom_filters.forEach((item) => {
            if (
              item.fieldId == id &&
              (item.fieldType == "MULTI_USER_SELECT" ||
                item.fieldType == "SINGLE_USER_SELECT")
            ) {
              this.moreSelectList[id] = this.user_list_filter;
            } else if (
              item.fieldId == id &&
              [
                "baseline",
                "version",
                "testPlan",
                "sprint",
                "coverage",
                "fileUuid",
              ].indexOf(item.fieldId) === -1
            ) {
              this.moreSelectList[id] = item.extraData;
            } else if (item.fieldId == id) {
              this.moreSelectList[id] = this.release_map[id];
            }
          });
        }
      } else {
        if (id === "fileUuid") {
          get_file_List_datachart(
            true,
            0,
            20,
            this.get_pid(),
            "fileKey",
            [],
            tempKey
          ).then((res) => {
            this.$nextTick(() => {
              this.defaultFileNameList = res.content;
            });
          });
        } else {
          this.moreSelectList[id] = this.moreSelectList[id].filter((item) => {
            if (item.name) {
              return item.name.indexOf(tempKey) >= 0;
            } else if (item.nickname) {
              return item.nickname.indexOf(tempKey) >= 0;
            } else if (item.value) {
              return item.value.indexOf(tempKey) >= 0;
            }
          });
        }
      }
    },
    name_span(id) {
      return this.matchUserNickname(id, "");
    },
    //全部筛选条件换页
    dialog_current_change(currentPage) {
      this.currentPage = currentPage;
    },
    clearSearchParams() {
      //清空搜索条件
      this.searchParams.search_box = "";
      this.searchParams.fileTypeId = [];
      this.searchParams.status = [];
      this.searchParams.assignee = [];
      this.searchParams.createdBy = [];
      this.conditions = [];
    },
    handleShoucangChange(pageNumber) {
      this.getCollectionData(pageNumber - 1);
    },
    getStatusName(id) {
      const status = this.status_list.filter(
        (status) => status.statusId === id
      )[0];
      return status ? status.name : "";
    },
    getUserName(id) {
      if (id === "1000") return this.$t("placeholder.open");
      return this.matchUserNickname(id, "");
    },
    getFileTypeName(fileTypeId) {
      const file = this.fileTypes.filter(
        (item) => item.fileTypeId === fileTypeId
      )[0];
      return file ? file.name : "";
    },
    async toggleShoucang() {
      this.isShoucangChecked = !this.isShoucangChecked;
      this.clearSearchParams();
      if (this.isShoucangChecked) {
        await this.getCollectionData(0);
      } else {
        await this.getTableData(1);
      }
    },
    async getCollectionData(pageNumber) {
      this.tableData = [];
      const fileTypes = await get_filetype(this.get_pid());
      this.fileTypes = fileTypes;
      const collections = await getUserCollections("node", pageNumber, 20);
      this.currentShoucangPage = 0;
      this.totalShoucangPage = collections.total;
      if (collections.data) {
        collections.data = collections.data.filter(
          (collection) => collection.projectId === this.get_pid()
        );
        collections.data.forEach((collection) => {
          const obj = {};
          obj.fileTypeName = this.getFileTypeName(collection.fileTypeId);
          obj.topic = collection.topic;
          obj.key = collection.key;
          obj.assignee = this.getUserName(collection.assignee);
          obj.createdBy = this.getUserName(collection.createdBy);
          obj.statusName = this.getStatusName(collection.status);
          this.tableData.push({ ...collection, ...obj });
        });
      }
    },
    get_allProjectMember() {
      this.all_user_list = [];
      get_allProjectMember(this.get_pid()).then((res) => {
        res.forEach((ele) => {
          let obj = {
            label: ele.nickname,
            value: ele.accountId,
            userAccountId: ele.accountId,
            avatar: ele.avatar,
          };
          this.all_user_list.push(obj);
        });
      });
    },
    get_user_name(params) {
      let newarr = typeof params === "object" ? [] : "";
      typeof params === "object" && params
        ? params.forEach((item) => {
            Object.values(this.user_list).forEach((user) => {
              if (user.accountId === item) {
                newarr.push(user.nickname);
              }
            });
          })
        : Object.values(this.user_list).forEach((user) => {
            if (user.accountId === params) {
              newarr = user.nickname;
            }
          });
      return newarr;
    },
    get_cus_value(row, id, col) {
      let value = "";
      row.content &&
        row.content.forEach((item) => {
          if (item.fieldId === id) {
            if (item.extraData && item.extraData.length) {
              if (item.fieldType === "SELECT") {
                item.extraData.forEach((extra) => {
                  if (extra.value === item.value) {
                    value = extra.label;
                  }
                });
              } else if (item.fieldType === "SELECT_MULTI") {
                item.value ? (value = item.value.join("，")) : (value = "");
              }
            } else if (
              item.fieldType === "MULTI_USER_SELECT" ||
              item.fieldType === "SINGLE_USER_SELECT"
            ) {
              value = this.get_user_name(item.value).join("，");
            } else if (item.fieldType === "DATE") {
              value = new Date(item.value).Format("yyyy-MM-dd");
            } else if (item.fieldType === "LABEL") {
              item.value ? (value = item.value.join("，")) : (value = "");
            } else {
              value = item.value;
            }
          }
        });
      return value;
    },
    get_header_name(id) {
      let name = "";
      this.dropColMap.forEach((item) => {
        if (item.fieldId === id) {
          name = item.name;
        }
      });
      return name;
    },
    // 获取表格数据
    getTableData(num) {
      this.loading = true;
      const sort_map = {
        key: this.keySort,
        topic: this.topicSort,
        assignee: this.assigneeSort,
        createdBy: this.createdBySort,
        status: this.statusSort,
        createdAt: this.createdAtSort,
        updatedAt: this.updatedAtSort,
      };
      if (this.searchType.type === "senior") {
        const params = {
          projectId: this.get_pid(),
          data: {
            content: this.seniorSearchStr,
            sortBy: this.sortBy,
            desc: sort_map[this.sortBy],
          },
          pageSize: 20,
          pageNumber: num ? num - 1 : this.pages.currentPage - 1,
        };
        this.getmqlFilterData(params);
      } else {
        get_file_List(
          sort_map[this.sortBy],
          num ? num - 1 : this.pages.currentPage - 1,
          20,
          this.get_pid(),
          this.sortBy,
          this.conditions,
          this.searchParams.search_box
        ).then((res) => {
          this.tableData = res.content;
          this.tableData.forEach((item) => {
            item.fileTypeName = this.fileType[item.fileTypeId].name;
            item.createdBy = this.name_span(item.createdBy);
            item.assigneeId = item.assignee;
            item.assignee = this.name_span(item.assignee);
            item.statusName = this.getStatusName(item.status);
            item.nodeRelationListName = this.getnodeRelationNameList(
              item.nodeRelationList
            );
          });
          num ? (this.pages.currentPage = num) : "";
          this.pages.total_element = res.totalElements;
          this.loading = false;
        });
      }
    },
    bulk_getTableData(flag) {
      this.loading = true;
      const sort_map = {
        key: this.keySort,
        assignee: this.assigneeSort,
        createdBy: this.createdBySort,
        status: this.statusSort,
        createdAt: this.createdAtSort,
        updatedAt: this.updatedAtSort,
      };
      if (this.searchType.type === "senior" && this.seniorSearchStr) {
        const params = {
          projectId: this.get_pid(),
          data: {
            content: this.seniorSearchStr,
            sortBy: this.sortBy,
            desc: sort_map[this.sortBy],
          },
          pageSize: 100,
          pageNumber: this.bulk_number,
        };
        this.getmqlFilterData(params);
      } else {
        get_file_List(
          sort_map[this.sortBy],
          this.bulk_number,
          100,
          this.get_pid(),
          this.sortBy,
          this.conditions,
          this.searchParams.search_box
        ).then((res) => {
          if (flag) {
            this.tableData = [];
          } else {
            this.judge_selectAll();
          }
          res.content.forEach((item) => {
            item.fileTypeName = this.fileType[item.fileTypeId].name;
            item.createdBy = this.name_span(item.createdBy);
            item.assignee = this.name_span(item.assignee);
            item.statusName = this.getStatusName(item.status);
          });
          this.tableData.push(...res.content);

          if (this.isall) {
            this.$refs.myTable.toggleAllSelection();
          }
          this.totalPages = res.totalPages;
          this.pages.total_element = res.totalElements;
          this.loading = false;
        });
      }
    },
    // 搜索框事件
    fuzzy_search() {
      this.$refs.searchBox.blur();
      this.$nextTick(() => {
        this.getTableData(1);
      });
    },
    //下拉选择改变时触发
    selecteFiletype(name, getTable) {
      this.isShoucangChecked = false;
      let flag = true;
      this.conditions.forEach((item, index) => {
        if (item.name === name) {
          item.value = this.searchParams[name];
          flag = false;
          // 如果选择条件不为时间范围
          if (this.searchParams[name] && !this.searchParams[name].startDate) {
            // 如果选择条件为0 删除
            if (!this.searchParams[name].length) {
              this.conditions.splice(index, 1);
            }
          }
        }
      });
      // 如果没有查找到对应选项 新增一条
      if (flag) {
        const obj = {
          name,
          isCustom: false,
          value: this.searchParams[name],
        };
        this.conditions.push(obj);
      }
      // 全选
      let checkedCount =
        this.searchParams[name] && this.searchParams[name].length;
      switch (name) {
        case "fileTypeId":
          this.checkAll[name] = checkedCount === this.type_list.length;
          this.isIndeterminate[name] =
            checkedCount > 0 && checkedCount < this.type_list.length;
          break;
        case "createdBy":
          this.checkAll[name] =
            checkedCount === this.user_list_filter.slice(0, -1).length;
          this.isIndeterminate[name] =
            checkedCount > 0 &&
            checkedCount < this.user_list_filter.slice(0, -1).length;
          break;
        case "status":
          this.checkAll[name] = checkedCount === this.status_list.length;
          this.isIndeterminate[name] =
            checkedCount > 0 && checkedCount < this.status_list.length;
          break;
        case "assignee":
          this.checkAll[name] = checkedCount === this.user_list_filter.length;
          this.isIndeterminate[name] =
            checkedCount > 0 && checkedCount < this.user_list_filter.length;
          break;
        case "createdAt":
        case "updatedAt":
          // 修改时间格式
          if (name == "createdAt") {
            this.isChangingCreatedAt = true;
          } else if (name == "updatedAt") {
            this.isChangingUpdatedAt = true;
          }
          this.conditions.forEach((v) => {
            if (v.name === "createdAt" || v.name === "updatedAt") {
              if (v.value.startDate && !(v.value instanceof Array)) {
                let startDate = v.value.startDate;
                let endDate = v.value.endDate;
                startDate.setHours(0, 0, 0);
                endDate.setHours(23, 59, 59);
                startDate = startDate.Format("yyyy-MM-ddThh:mm:ss+08:00");
                endDate = endDate.Format("yyyy-MM-ddThh:mm:ss+08:00");
                v.value = [startDate, endDate];
              } else if (!v.value.startDate && !(v.value instanceof Array)) {
                v.value = [];
              }
            }
          });
          break;
      }
      getTable ? "" : this.getTableData(1);
    },
    //更多渲染的筛选项的下拉选择改变时触发
    moreSelecteFiletype(name, fieldId, type = "SELECT", selectItem, getTable) {
      let flag = true;
      this.conditions.forEach((item, index) => {
        if (item.name === fieldId) {
          flag = false;
          if (type == "SELECT") {
            item.value = this.custom_filters_value[name];
            if (!item.value.length) {
              this.conditions.splice(index, 1);
            }
          } else {
            item.value =
              type == "DATE"
                ? [this.form_date[fieldId]]
                : [this.form_number[fieldId]];
            if (!item.value[0]) {
              this.conditions.splice(index, 1);
            }
          }
        }
      });
      if (flag) {
        const obj = {
          name: fieldId,
          isCustom:
            ["baseline", "version", "testPlan", "sprint", "fileUuid"].indexOf(
              fieldId
            ) !== -1
              ? false
              : true,
        };
        if (type == "SELECT") {
          obj.value = this.custom_filters_value[name];
        } else if (type == "DATE") {
          obj.value = [this.form_date[fieldId]];
        } else {
          obj.value = [this.form_number[fieldId]];
        }
        this.conditions.push(obj);
      }
      if (type === "SELECT" && selectItem) {
        let checkedCount = this.custom_filters_value[name]
          ? this.custom_filters_value[name].length
          : 0;
        if (
          selectItem.fieldType == "MULTI_USER_SELECT" ||
          selectItem.fieldType == "SINGLE_USER_SELECT"
        ) {
          this.checkAll[fieldId] =
            checkedCount === this.user_list_filter.length;
          this.isIndeterminate[fieldId] =
            checkedCount > 0 && checkedCount < this.user_list_filter.length;
        } else if (
          [
            "baseline",
            "version",
            "testPlan",
            "sprint",
            "coverage",
            "fileUuid",
          ].indexOf(fieldId) === -1
        ) {
          this.checkAll[fieldId] =
            checkedCount === selectItem.extraData?.length;
          this.isIndeterminate[fieldId] =
            checkedCount > 0 && checkedCount < selectItem.extraData?.length;
        } else {
          this.checkAll[fieldId] =
            checkedCount === this.release_map[name].length;
          this.isIndeterminate[fieldId] =
            checkedCount > 0 && checkedCount < this.release_map[name].length;
        }
      }
      getTable ? "" : this.getTableData(1);
    },
    //下拉框出现或者隐藏时触发
    update_type() {},
    // 获取下拉内容
    async update_filter(flag) {
      if (flag) {
        this.all_options = [];
        this.other_options = [];
        this.filteroptions = [];
        const res = await condition_get(this.get_pid());
        res.forEach((item) => {
          if (item.createdBy === this.userAccountId) {
            this.filteroptions = [...item.nodeQueryConditionDtoList];
            this.all_options = [
              ...item.nodeQueryConditionDtoList,
              ...this.all_options,
            ];
          } else {
            this.other_options = [
              ...this.other_options,
              ...item.nodeQueryConditionDtoList,
            ];
            this.all_options = [
              ...this.all_options,
              ...item.nodeQueryConditionDtoList,
            ];
          }
        });
      }
    },
    // 筛选项事件
    choose_filter(id, fieldId, queryValue, conditions) {
      this.$nextTick(() => {
        if (this.shouldIgnoreNextChange) {
          this.shouldIgnoreNextChange = false;
          return;
        }
        this.searchParams.fileTypeId = [];
        this.searchParams.status = [];
        this.searchParams.createdBy = [];
        this.searchParams.assignee = [];
        this.conditions = [];
        this.custom_filters_value = {};
        this.form_date = {};
        this.form_number = {};
        this.more_filters = [];
        this.checked_filter = this.all_options.filter((item) => {
          if (item.queryConditionId === id) {
            this.conditions = item.conditions;
          }
          return item.queryConditionId === id;
        })[0];
        if (this.checked_filter && this.checked_filter.queryType === "MQL") {
          this.choose_senior_filter(this.checked_filter, id);
        } else {
          if (id) {
            this.searchTypeIndex = 0;
            this.searchType = this.searchTypeList[this.searchTypeIndex];
          }
          this.checked_filter &&
            this.checked_filter.conditions.forEach((item) => {
              if (
                !item.isCustom &&
                [
                  "baseline",
                  "version",
                  "testPlan",
                  "sprint",
                  "createdAt",
                  "updatedAt",
                  "fileUuid",
                ].indexOf(item.name) === -1
              ) {
                //默认值回填
                this.searchParams[item.name] = item.value;
                // 恢复全选框状态
                const checkedCount = this.searchParams[item.name].length;
                if (item.name == "created_by") {
                  item.name = "createdBy";
                }
                if (item.name == "file_type_id") {
                  item.name = "fileTypeId";
                }
                switch (item.name) {
                  case "fileTypeId":
                    this.checkAll[item.name] =
                      checkedCount === this.type_list.length;
                    this.isIndeterminate[item.name] =
                      checkedCount > 0 && checkedCount < this.type_list.length;
                    break;
                  case "createdBy":
                    this.checkAll[item.name] =
                      checkedCount ===
                      this.user_list_filter.slice(0, -1).length;
                    this.isIndeterminate[item.name] =
                      checkedCount > 0 &&
                      checkedCount < this.user_list_filter.slice(0, -1).length;
                    break;
                  case "status":
                    this.checkAll[item.name] =
                      checkedCount === this.status_list.length;
                    this.isIndeterminate[item.name] =
                      checkedCount > 0 &&
                      checkedCount < this.status_list.length;
                    break;
                  case "assignee":
                    this.checkAll[item.name] =
                      checkedCount === this.user_list_filter.length;
                    this.isIndeterminate[item.name] =
                      checkedCount > 0 &&
                      checkedCount < this.user_list_filter.length;
                    break;
                }
              } else {
                if (item.name == "createdAt") {
                  this.isChangingCreatedAt = true;
                  this.searchParams.createdAt.startDate = item.value[0];
                  this.searchParams.createdAt.endDate = item.value[1];
                }
                if (item.name == "updatedAt") {
                  this.isChangingUpdatedAt = true;
                  this.searchParams.updatedAt.startDate = item.value[0];
                  this.searchParams.updatedAt.endDate = item.value[1];
                }
                // 自定义字段回填
                const obj = this.custom_filter_list.find((field) => {
                  return field.fieldId === item.name;
                });
                this.$set(this.form_date, obj.fieldId, item.value[0]);
                this.$set(this.form_number, obj.fieldId, item.value[0]);
                if (item.name == "coverage") {
                  this.$set(
                    this.custom_filters_value,
                    obj.fieldId,
                    item.value.map((item) => {
                      return Boolean(item);
                    })
                  );
                } else if (item.name == "fileUuid") {
                  get_file_List_datachart(
                    true,
                    0,
                    20,
                    this.get_pid(),
                    "fileKey",
                    [
                      {
                        name: "fileUuid",
                        isCustom: false,
                        value: item.value,
                      },
                    ],
                    ""
                  ).then((res) => {
                    this.$nextTick(() => {
                      this.defaultFileNameList = res.content;
                    });
                  });
                  this.$set(this.custom_filters_value, obj.fieldId, item.value);
                } else {
                  this.$set(this.custom_filters_value, obj.fieldId, item.value);
                }
                this.more_filters.push(obj.fieldId);
                // 恢复全选框状态
                const checkedCount = this.custom_filters_value[obj.fieldId]
                  ? this.custom_filters_value[obj.fieldId].length
                  : 0;
                if (
                  obj.fieldType == "MULTI_USER_SELECT" ||
                  obj.fieldType == "SINGLE_USER_SELECT"
                ) {
                  this.checkAll[obj.fieldId] =
                    checkedCount === this.user_list_filter.length;
                  this.isIndeterminate[obj.fieldId] =
                    checkedCount > 0 &&
                    checkedCount < this.user_list_filter.length;
                } else if (
                  [
                    "baseline",
                    "version",
                    "testPlan",
                    "sprint",
                    "coverage",
                  ].indexOf(obj.fieldId) === -1
                ) {
                  // 选项里有null 要+1
                  this.checkAll[obj.fieldId] =
                    checkedCount ===
                    (obj.extraData ? obj.extraData.length + 1 : 0);
                  this.isIndeterminate[obj.fieldId] =
                    checkedCount > 0 &&
                    checkedCount <
                      (obj.extraData ? obj.extraData.length + 1 : 0);
                } else {
                  this.checkAll[obj.fieldId] =
                    checkedCount === this.release_map[obj.fieldId].length;
                  this.isIndeterminate[obj.fieldId] =
                    checkedCount > 0 &&
                    checkedCount < this.release_map[obj.fieldId].length;
                }
              }
            });
          if (!fieldId && !queryValue && !conditions) {
            let url = window.location.href;
            const newQuery = `queryConditionId=${id}`;
            const newUrl = url.split("?")[0] + "?" + newQuery;
            history.pushState(null, null, newUrl);
          }
          // 带参数跳转
          if (fieldId) {
            if (fieldId == "created_by") {
              fieldId = "createdBy";
            }

            if (fieldId == "file_type_id") {
              fieldId = "fileTypeId";
            }

            if (
              fieldId &&
              ["fileTypeId", "status", "createdBy", "assignee"].indexOf(
                fieldId
              ) !== -1
            ) {
              this.searchParams[fieldId] && this.searchParams[fieldId].length
                ? this.searchParams[fieldId].push(
                    queryValue === "null" ? null : queryValue
                  )
                : (this.searchParams[fieldId] = [
                    queryValue === "null" ? null : queryValue,
                  ]);
              let flag = false;
              switch (fieldId) {
                case "fileTypeId":
                  this.type_list.find((item) => {
                    return item.fileTypeId === queryValue;
                  })
                    ? (flag = true)
                    : null;
                  break;
                case "createdBy":
                  Object.values(this.user_list)
                    .slice(0, -1)
                    .find((item) => {
                      return item.accountId === queryValue;
                    })
                    ? (flag = true)
                    : null;
                  break;
                case "status":
                  this.status_list.find((item) => {
                    return item.statusId === queryValue;
                  })
                    ? (flag = true)
                    : null;
                  break;
                case "assignee":
                  Object.values(this.user_list).find((item) => {
                    return (
                      queryValue === "null" || item.accountId === queryValue
                    );
                  })
                    ? (flag = true)
                    : null;
                  break;
              }
              if (flag) {
                this.selecteFiletype(fieldId);
              }
            } else {
              this.custom_filters_value[fieldId] &&
              this.custom_filters_value[fieldId].length
                ? this.custom_filters_value[fieldId].push(
                    queryValue === "null" || !queryValue ? null : queryValue
                  )
                : this.$set(this.custom_filters_value, fieldId, [
                    queryValue === "null" || !queryValue ? null : queryValue,
                  ]);
              this.more_filters.indexOf(fieldId) === -1
                ? this.more_filters.push(fieldId)
                : "";
              const selectItem = this.custom_filter_list.find(
                (item) => fieldId == item.fieldId
              );

              // 判断筛选内容是否包含在选项中
              let flag = false;
              if (queryValue === "null") {
                flag = true;
              } else if (
                selectItem.fieldType == "MULTI_USER_SELECT" ||
                selectItem.fieldType == "SINGLE_USER_SELECT"
              ) {
                Object.values(this.user_list).find((item) => {
                  return item.accountId === queryValue;
                })
                  ? (flag = true)
                  : null;
              } else if (
                ["baseline", "version", "testPlan", "sprint"].indexOf(
                  selectItem.fieldId
                ) === -1
              ) {
                selectItem.extraData.find((item) => {
                  return item.value === queryValue;
                })
                  ? (flag = true)
                  : null;
              } else {
                this.release_map[selectItem.fieldId].find((item) => {
                  return item[selectItem.fieldId + "Id"] === queryValue;
                })
                  ? (flag = true)
                  : null;
              }
              if (flag) {
                this.moreSelecteFiletype(
                  fieldId,
                  fieldId,
                  "SELECT",
                  selectItem
                );
              }
            }
          } else if (conditions) {
            conditions.forEach((item, conditionsIndex) => {
              if (
                [
                  "fileTypeId",
                  "status",
                  "createdBy",
                  "assignee",
                  "created_by",
                  "file_type_id",
                ].indexOf(item.fieldId) !== -1
              ) {
                if (item.fieldId == "created_by") {
                  item.fieldId = "createdBy";
                }
                if (item.fieldId == "file_type_id") {
                  item.fieldId = "fileTypeId";
                }
                this.searchParams[item.fieldId] &&
                this.searchParams[item.fieldId].length
                  ? this.searchParams[item.fieldId].push(
                      item.value === "null" ? null : item.value
                    )
                  : (this.searchParams[item.fieldId] = [
                      item.value === "null" ? null : item.value,
                    ]);
                let flag = false;
                switch (item.fieldId) {
                  case "fileTypeId":
                    this.type_list.find((obj) => {
                      return obj.fileTypeId === item.value;
                    })
                      ? (flag = true)
                      : null;
                    break;
                  case "createdBy":
                    Object.values(this.user_list).find((obj) => {
                      return obj.accountId === item.value;
                    })
                      ? (flag = true)
                      : null;
                    break;
                  case "status":
                    this.status_list.find((obj) => {
                      return obj.statusId === item.value;
                    })
                      ? (flag = true)
                      : null;
                    break;
                  case "assignee":
                    Object.values(this.user_list).find((obj) => {
                      return (
                        item.value === "null" || obj.accountId === item.value
                      );
                    })
                      ? (flag = true)
                      : null;
                    break;
                }
                if (flag) {
                  this.selecteFiletype(
                    item.fieldId,
                    conditionsIndex === conditions.length - 1 ? false : true
                  );
                }
              } else {
                this.custom_filters_value[item.fieldId] &&
                this.custom_filters_value[item.fieldId].length
                  ? this.custom_filters_value[item.fieldId].push(
                      item.value === "null" || !item.value ? null : item.value
                    )
                  : this.$set(this.custom_filters_value, item.fieldId, [
                      item.value === "null" || !item.value ? null : item.value,
                    ]);
                this.more_filters.indexOf(item.fieldId) === -1
                  ? this.more_filters.push(item.fieldId)
                  : "";
                const selectItem = this.custom_filter_list.find(
                  (obj) => item.fieldId == obj.fieldId
                );
                // 判断筛选内容是否包含在选项中
                let flag = false;
                if (item.value === "null") {
                  flag = true;
                } else if (
                  selectItem.fieldType == "MULTI_USER_SELECT" ||
                  selectItem.fieldType == "SINGLE_USER_SELECT"
                ) {
                  Object.values(this.user_list).find((obj) => {
                    return obj.accountId === item.value;
                  })
                    ? (flag = true)
                    : null;
                } else if (
                  ["baseline", "version", "testPlan", "sprint"].indexOf(
                    selectItem.fieldId
                  ) === -1
                ) {
                  selectItem.extraData.find((obj) => {
                    return obj.value === item.value;
                  })
                    ? (flag = true)
                    : null;
                } else {
                  this.release_map[selectItem.fieldId].find((obj) => {
                    return obj[selectItem.fieldId + "Id"] === item.value;
                  })
                    ? (flag = true)
                    : null;
                }
                if (flag) {
                  this.moreSelecteFiletype(
                    item.fieldId,
                    item.fieldId,
                    "SELECT",
                    selectItem,
                    conditionsIndex === conditions.length - 1 ? false : true
                  );
                }
              }
              // 分割线
            });
          } else {
            this.getTableData(1);
          }
          // 更多+ 全选框
          this.handleCheckedFiltersChange();
        }
      });
    },
    clear_filter() {
      this.shouldIgnoreNextChange = true;
      this.conditions_filter = "";
      this.checked_filter = {};
      this.conditions = [];
      this.clear_filters();
    },
    //确定保存筛选条件
    save_filter_confirm() {
      const userGroupList = [];
      const userList = [];
      const notifyTimeList = this.save_filter_time.map((item) => {
        return {
          dayInWeek: item[0],
          hourInDay: item[1],
        };
      });
      this.save_filter_target.forEach((user) => {
        if (user.length > 20) {
          userGroupList.push(user);
        } else {
          userList.push(user);
        }
      });
      const nodeFilterNotification = {
        weekDurations: this.save_filter_week,
        notifyTimeList: notifyTimeList,
        userGroupList: userGroupList,
        userList: userList,
      };
      if (this.save_filter_input !== "") {
        const params = {
          projectId: this.get_pid(),
          data: {
            mqlCondition: {
              mql: this.seniorSearchStr,
              frontEndContent: JSON.stringify(this.seniorSearchList),
            },
            nodeFilterNotification: nodeFilterNotification,
            nodeQueryType: this.searchType.type == "simple" ? "NORMAL" : "MQL",
            queryConditions: this.conditions,
            name: this.save_filter_input,
          },
        };
        if (this.currentId) {
          condition_edit(this.get_pid(), this.currentId, params.data).then(
            (res) => {
              this.all_options = [];
              this.other_options = [];
              this.filteroptions = [];
              condition_get(this.get_pid()).then((res) => {
                res.forEach((item) => {
                  if (item.createdBy === this.userAccountId) {
                    this.filteroptions = [...item.nodeQueryConditionDtoList];
                    this.all_options = [
                      ...item.nodeQueryConditionDtoList,
                      ...this.all_options,
                    ];
                  } else {
                    this.other_options = [
                      ...this.other_options,
                      ...item.nodeQueryConditionDtoList,
                    ];
                    this.all_options = [
                      ...this.all_options,
                      ...item.nodeQueryConditionDtoList,
                    ];
                  }
                });
                this.conditions_filter = id;
                this.choose_filter(id);
              });
              this.save_filter_dialog = false;
            }
          );
        } else {
          save_senior_condition(params).then((id) => {
            this.all_options = [];
            this.other_options = [];
            this.filteroptions = [];
            condition_get(this.get_pid()).then((res) => {
              res.forEach((item) => {
                if (item.createdBy === this.userAccountId) {
                  this.filteroptions = [...item.nodeQueryConditionDtoList];
                  this.all_options = [
                    ...item.nodeQueryConditionDtoList,
                    ...this.all_options,
                  ];
                } else {
                  this.other_options = [
                    ...this.other_options,
                    ...item.nodeQueryConditionDtoList,
                  ];
                  this.all_options = [
                    ...this.all_options,
                    ...item.nodeQueryConditionDtoList,
                  ];
                }
              });
              this.conditions_filter = id;
              this.choose_filter(id);
            });
            this.save_filter_dialog = false;
            this.$message({
              message: this.$t("addMyFileHead.info3"),
              type: "success",
            });
          });
        }
      } else {
        this.$message({
          message: this.$t("addMyFileHead.info4"),
          type: "error",
        });
      }
    },
    sortData(field) {
      const sortField = `${field}Sort`;
      this[sortField] = !this[sortField];
      this.sortBy = field;
      this.getTableData();
    },
    //节点点击事件
    rowClicked(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${row.key}`,
      });
      window.open(href, "_blank");
    },
    edit(row) {
      get_node_use_node_key(this.get_pid(), row.key).then((r) => {
        this.drawer = true;
        this.current_node = r;
      });
      // this.current_node = Object.assign({}, row, {
      //   assignee: row.assigneeId,
      // });
    },
    choose_senior_filter(filter, id) {
      this.searchTypeIndex = 1;
      this.searchType = this.searchTypeList[this.searchTypeIndex];
      let url = window.location.href;
      const newQuery = `queryConditionId=${id}`;
      const newUrl = url.split("?")[0] + "?" + newQuery;
      history.pushState(null, null, newUrl);
      this.pages.currentPage = 1;
      this.seniorSearchStr = filter.mqlCondition ? filter.mqlCondition.mql : "";
      this.seniorSearchList = filter.mqlCondition
        ? JSON.parse(filter.mqlCondition.frontEndContent)
        : [];
      this.getTableData(1);
      this.$nextTick(() => {
        const list = JSON.parse(filter.mqlCondition.frontEndContent);
        this.$refs.seniorSearch.backfill(list);
      });
    },
    mqlFilter(str, arr) {
      this.pages.currentPage = 1;
      this.seniorSearchStr = str;
      this.seniorSearchList = arr;
      this.getTableData(1);
    },
    getmqlFilterData(params) {
      mqlFilter(params).then((res) => {
        this.tableData = res.content;
        this.tableData.forEach((item) => {
          item.fileTypeName = this.fileType[item.fileTypeId].name;
          item.createdBy = this.name_span(item.createdBy);
          item.assigneeId = item.assignee;
          item.assignee = this.name_span(item.assignee);
          item.statusName = this.getStatusName(item.status);
          item.nodeRelationListName = this.getnodeRelationNameList(
            item.nodeRelationList
          );
        });
        this.pages.total_element = res.totalElements;
        this.loading = false;
      });
    },
    //分页
    handleCurrentChange(page) {
      this.pages.currentPage = page;
      this.getTableData();
    },
    // 协作
    showCollaborationDialog() {},
    //复制
    cloneOnly() {},
    //删除
    DeleteOnly() {},
    //下载
    DownLoad() {},
    //批量操作选择
    handleSelectionChange(selection) {
      this.selected = selection;
      this.judge_selectAll();
    },
    changeCellStyle(row, column, rowIndex, columnIndex) {
      if (row.column.label === "key") {
        return "color: #FFC300;font-weight: 700;"; // 修改的样式
      } else {
        return "color:#636363;font-weight: 700;";
      }
    },
    async get_status_list() {
      await get_allreadyuse_status_list(this.get_pid()).then((res) => {
        this.searchParams.status = [];
        this.status_list = res;
      });
    },
    // 获取节点类型下拉内容
    async get_filetypes() {
      //获取文件类型
      await get_filetype(this.get_pid()).then((res) => {
        this.searchParams.checkedtypes = [];
        this.type_list = res;
      });
    },
    async get_responsibles() {
      //获取创建人
      await people_get(this.get_pid()).then((res) => {
        this.searchParams.checkedusers = [];
        this.user_list1 = res; //请求的结果传递
        this.user_list_filter = this.user_list1.filter(
          (user) => user.deleted === 0
        );
        this.user_list_filter.push({
          accountId: null,
          name: "null",
          nickname: "null",
          deleted: 0,
        });
      });
    },
    isInDropCol(id) {
      for (const col of this.dropCol) {
        if (col.fieldId === id) {
          return true;
        }
      }
      return false;
    },
    // 获取更多筛选字段
    async get_custom_filter() {
      const cusColumn = await all_fields_get(this.get_pid());
      // 处理批量导出所需数据
      this.allFields = {};
      const allFieldsArray = JSON.parse(JSON.stringify(cusColumn));
      allFieldsArray.forEach((item) => {
        this.allFields[item.fieldId] = item;
      });
      cusColumn.unshift(
        {
          name: this.$t("menu.baseline"),
          fieldId: "baseline",
          fieldType: "SELECT",
        },
        {
          name: this.$t("menu.version"),
          fieldId: "version",
          fieldType: "SELECT",
        },
        {
          name: this.$t("nodeDetail.publish.testPlan"),
          fieldId: "testPlan",
          fieldType: "SELECT",
        },
        {
          name: "Sprint",
          fieldId: "sprint",
          fieldType: "SELECT",
        },
        {
          name: this.$t("canvas.rightClick.node"),
          fieldId: "nodeRelationList",
          fieldType: "nodeRelation",
        },
        {
          name: this.$t("canvas.viewDropDown.coverage"),
          fieldId: "coverage",
          fieldType: "SELECT",
        },
        {
          name: this.$t("node.table.fileName"),
          fieldId: "fileUuid",
          fieldType: "SELECT",
        }
      );
      cusColumn.push(
        {
          name: this.$t("node.table.createdTime"),
          fieldId: "createdAt",
          fieldType: "DATE_RANGE",
        },
        {
          name: this.$t("node.table.updatedTime"),
          fieldId: "updatedAt",
          fieldType: "DATE_RANGE",
        }
      );
      this.custom_filter_list = cusColumn.filter((item) => {
        return (
          [
            "SELECT",
            "SELECT_MULTI",
            "MULTI_USER_SELECT",
            "SINGLE_USER_SELECT",
            "DATE_RANGE",
            "LABEL",
          ].indexOf(item.fieldType) !== -1
        );
      });
      if (localStorage.getItem(`dropCol:${this.get_pid()}`)) {
        const cols = JSON.parse(
          localStorage.getItem(`dropCol:${this.get_pid()}`)
        );
        this.dropCol = cols;
      }
      this.dropColMap = [...this.dropColMap, ...cusColumn];
      this.dropColMap.forEach((col) => {
        const flag = this.otherCol.some((item) => {
          return item.name == col.name || item.name == col.label;
        });
        if (!flag) {
          this.otherCol.push({
            fieldId: col.fieldId,
            name: col.name || col.label,
          });
        }
        if (this.isInDropCol(col.fieldId)) {
          this.more_column.push(col.fieldId);
        }
      });
    },
    filter_is_select(type) {
      switch (type) {
        case "SELECT":
          return "SELECT";
        case "SELECT_MULTI":
          return "SELECT";
        case "MULTI_USER_SELECT":
          return "SELECT";
        case "SINGLE_USER_SELECT":
          return "SELECT";
        case "VERSION":
          return "SELECT";
        case "LABEL":
          return "SELECT";
        case "DATE":
          return "DATE";
        case "NUMBER":
          return "NUMBER";
        case "DATE_RANGE":
          return "DATE_RANGE";
      }
    },
    clear_filters() {
      if (this.searchType.type === "senior") {
        this.seniorSearchStr = "";
        this.seniorSearchList = [];
        this.$refs.seniorSearch.backfill([]);
      } else {
        this.searchParams = {
          search_box: "",
          fileTypeId: [],
          status: [],
          createdBy: [],
          assignee: [],
          createdAt: {},
          updatedAt: {},
        };
        this.conditions = [];
        this.custom_filters_value = {};
        this.more_filters = [];
        this.form_date = {};
        this.form_number = {};
        this.conditions_filter = "";
        this.checked_filter = {};
        this.checkAll = {
          fileTypeId: false,
          createdBy: false,
          status: false,
          assignee: false,
        };
        this.isIndeterminate = {
          fileTypeId: false,
          createdBy: false,
          status: false,
          assignee: false,
        };
        this.moreCheckAll = false;
        this.moreIndeterminate = false;
      }
      this.getTableData(1);
    },
    close_filter(name, fieldId, type = "SELECT", selectItem) {
      this.more_filters.splice(
        this.more_filters.findIndex((item) => item === fieldId),
        1
      );
      let obj = this.conditions.find((item) => item.name == fieldId);
      if (obj) {
        if (type == "SELECT") {
          this.custom_filters_value[fieldId] = [];
          this.moreSelecteFiletype(fieldId, fieldId, type, selectItem);
        }
        if (type == "DATE") {
          this.form_date[fieldId] = "";
          this.moreSelecteFiletype(name, fieldId, type);
        }
        if (type == "NUMBER") {
          this.form_number[fieldId] = "";
          this.moreSelecteFiletype(name, fieldId, type);
        }
        if (obj.name === "createdAt") {
          this.searchParams.createdAt = {};
          this.selecteFiletype("createdAt");
          // this.searchParams.createdAt = {
          //   startDate: new Date(),
          //   endDate: new Date(),
          // };
          this.isChangingCreatedAt = false;
        }
        if (obj.name === "updatedAt") {
          this.searchParams.updatedAt = {};
          this.selecteFiletype("updatedAt");
          // this.searchParams.updatedAt = {
          //   startDate: new Date(),
          //   endDate: new Date(),
          // };
          this.isChangingUpdatedAt = false;
        }
      }
      this.handleCheckedFiltersChange();
    },
    varify_number(name, fieldId) {
      if (this.form_number[fieldId]) {
        let num = Number(this.form_number[fieldId]);
        if (Number.isNaN(num)) {
          this.$message.warning(this.$t("chart.message.warn"));
          return;
        } else {
          this.form_number[fieldId] = num;
          this.moreSelecteFiletype(name, fieldId, "NUMBER");
        }
      }
    },
    // 批量操作节点
    batchFn() {
      this.is_bulk ? this.quit_bulik() : this.click_bulk();
    },
    click_bulk() {
      this.tableListener();
      this.bulk_getTableData(true);

      this.is_bulk = true;
    },
    // 退出批量操作节点
    quit_bulik() {
      let dom = this.$refs.myTable.bodyWrapper;
      let _this = this;
      dom.removeEventListener("scroll", _this.tableListener_fn);
      this.bulk_number = 0;
      this.tableData = [];

      this.getTableData(0);
      this.is_bulk = false;
    },
    // 监听表格下滑
    tableListener() {
      let dom = this.$refs.myTable.bodyWrapper;
      dom.addEventListener("scroll", this.tableListener_fn);
    },
    tableListener_fn() {
      let dom = this.$refs.myTable.bodyWrapper;
      this.scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
    },

    // 点击批量导出
    blur_export() {
      if (this.selected.length != 0) {
        this.dialogVisible = true;
        Promise.all([
          get_excel_nodeField(this.$route.params.projectId),
          getSystemFields(),
          all_fields_get(this.$route.params.projectId),
        ]).then(
          (res) => {
            // 需展示字段
            this.excelField = res[0] || {};
            // 所有系统字段
            this.systemFields_list = res[1] || [];
            // 所有自定义字段
            this.allFields_list = res[2] || [];

            // 筛选系统字段
            this.systemFields_list.forEach((item) => {
              if (
                this.excelField.systemFieldsIdList.indexOf(item.fieldId) != -1
              ) {
                this.checkList.push({
                  label: item.name,
                  fieldId: item.fieldId,
                });
              }
            });

            // 筛选自定义字段
            this.allFields_list.forEach((item) => {
              if (
                this.excelField.costomFieldsIdList.indexOf(item.fieldId) != -1
              ) {
                this.checkList.push({
                  label: item.name,
                  fieldId: item.fieldId,
                });
              }
            });
          },
          (error) => {}
        );
      } else {
        this.$message({
          type: "error",
          message: this.$t("node.excel.tip2"),
        });
      }
    },
    // 批量修改字段相关
    blur_modify() {
      if (this.selected.length > 100) {
        this.$message({
          type: "error",
          message: this.$t("node.excel.tip5"),
        });
        return;
      }
      if (this.selected.length != 0) {
        this.getBatchField();
        this.branchEditDialogVisible = true;
        // 打开弹窗时清空选中值
        this.branchEditselected = {};
        this.fieldValue = "";
        this.fieldValues = [];
      } else {
        this.$message({
          type: "error",
          message: this.$t("node.excel.tip4"),
        });
      }
    },
    branchEditConfirm() {
      if (
        (!this.fieldValue && !this.fieldValues.length) ||
        !this.branchEditselected
      ) {
        this.$message({
          type: "warning",
          message: this.$t("node.batchField.msg"),
        });
        return;
      }
      const params = {
        projectId: this.get_pid(),
        data: {
          nodeKeyList: this.selected.map((item) => {
            return item.key;
          }),
          isCustom: this.branchEditselected.isCustom,
          fieldId: this.branchEditselected.value,
          value: this.fieldValue || this.fieldValues,
        },
      };
      putBatchField(params).then((res) => {
        if (res) {
          this.$message({
            message: this.$t("canvas.messages.editSuccess"),
            type: "success",
          });
        } else {
          this.$message({
            type: "warning",
            message: this.$t("canvas.messages.editWarning"),
          });
        }
        this.branchEditselected = {};
        this.fieldValue = "";
        this.fieldValues = [];
        this.branchEditDialogVisible = false;
      });
    },
    getBatchField() {
      const systemFieldsMap = {
        assignee: {
          label: this.$t("node.batchField.assignee"),
          type: "SINGLE_USER_SELECT",
          extraData: null,
          isCustom: false,
        },
        baseline_id: {
          label: this.$t("node.batchField.baseline"),
          type: "SELECT_MULTI",
          extraData: Object.values(this.baseLine).map((item) => {
            return {
              label: item.name,
              value: item.baselineId,
            };
          }),
          isCustom: false,
        },
        version_id: {
          label: this.$t("node.batchField.version"),
          type: "SELECT_MULTI",
          extraData: Object.values(this.version).map((item) => {
            return {
              label: item.name,
              value: item.versionId,
            };
          }),
          isCustom: false,
        },
        test_plan_id: {
          label: this.$t("node.batchField.testplan"),
          type: "SELECT_MULTI",
          extraData: Object.values(this.testPlan).map((item) => {
            return {
              label: item.name,
              value: item.testPlanId,
            };
          }),
          isCustom: false,
        },
        sprint_id: {
          label: this.$t("node.batchField.sprint"),
          type: "SELECT",
          extraData: Object.values(this.sprint).map((item) => {
            return {
              label: item.name,
              value: item.sprintId,
            };
          }),
          isCustom: false,
        },
        story_point: {
          label: this.$t("node.batchField.storypoint"),
          type: "NUMBER",
          extraData: null,
          isCustom: false,
        },
      };
      getBatchField({
        projectId: this.get_pid(),
      }).then((res) => {
        this.branchEditFieldList = [];
        res.systemFieldsIdList &&
          res.systemFieldsIdList.forEach((item) => {
            if (systemFieldsMap[item]) {
              const obj = Object.assign(systemFieldsMap[item], {
                value: item,
              });
              this.branchEditFieldList.push(obj);
            }
          });
        res.costomFieldsIdList &&
          res.costomFieldsIdList.forEach((item) => {
            if (this.allFields[item]) {
              const obj = {
                label: this.allFields[item].name,
                value: this.allFields[item].fieldId,
                type: this.allFields[item].fieldType,
                extraData: this.allFields[item].extraData,
                isCustom: true,
              };
              this.branchEditFieldList.push(obj);
            }
          });
      });
    },
    branchEditselectedChange(value) {
      this.branchEditselected = {};
      this.$nextTick(() => {
        this.fieldValue = "";
        this.fieldValues = [];
        this.branchEditselected = value;
      });
      this.$nextTick(() => {
        this.fieldExtraData = value.extraData ? value.extraData : [];
      });
    },
    //////
    submit_Excel() {
      this.excel_loading = true;
      let sort_map = {
        key: this.keySort,
        assignee: this.assigneeSort,
        createdBy: this.createdBySort,
        status: this.statusSort,
        createdAt: this.createdAtSort,
        updatedAt: this.updatedAtSort,
      };
      let queryConditions = [];
      let totalNum = 0;
      let blur_data = {
        queryNodeDto: {
          fuzzyKey: this.searchParams.search_box,
          queryConditions: queryConditions,
          desc: sort_map[this.sortBy],
          sortby: this.sortBy,
        },
        excelHeadChoicesDto: {
          systemFieldsIdList: [],
          costomFieldsIdList: [],
          exportTemplateId: this.template,
        },
      };
      if (this.isall) {
        // 全选导出
        blur_data.queryNodeDto.queryConditions = this.conditions;
        totalNum = this.pages.total_element;
      } else {
        // 点选导出
        totalNum = this.selected.length;
        blur_data.queryNodeDto.queryConditions = [
          { name: "nodeKey", value: [], isCustom: false },
        ];
        this.selected.forEach((item) => {
          blur_data.queryNodeDto.queryConditions[0].value.push(item.key);
        });
      }
      this.systemFields_list.forEach((item) => {
        if (this.submit_list.indexOf(item.fieldId) != -1) {
          blur_data.excelHeadChoicesDto.systemFieldsIdList.push(item.fieldId);
        }
      });
      this.allFields_list.forEach((item) => {
        if (this.submit_list.indexOf(item.fieldId) != -1) {
          blur_data.excelHeadChoicesDto.costomFieldsIdList.push(item.fieldId);
        }
      });

      upload_blur_excelField(
        this.$route.params.projectId,
        totalNum,
        blur_data
      ).then((res) => {
        this.template = "";
        this.excel_loading = false;
        this.dialogVisible = false;
        this.checkList = [];
        this.submit_list = [];
        this.all_fields_item = false;
        this.$message({
          showClose: true,
          message: this.$t("homeTopBar.asyncTask.message"),
          type: "success",
          duration: 5000,
        });
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.submit_list = [];
      this.checkList = [];
      this.all_fields_item = false;
    },
    judge_all(val) {
      if (val) {
        // 点击全选
        this.checkList.forEach((item) => {
          this.submit_list.push(item.fieldId);
        });
      } else {
        this.submit_list = [];
      }
    },
    // 节点点击全选
    judge_selectAll() {
      if (this.selected.length == this.tableData.length) {
        this.isall = true;
      } else {
        this.isall = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.createpro-header {
  color: rgba(49, 100, 143, 1);
  font-family: "思源黑体";
  font-size: 16px;
  margin-top: -30px;

  .is-active {
    color: #30648f;
  }
  ::v-deep .el-select {
    .tags-select-input {
      height: 40px;
      .el-select__tags {
        height: 40px;
        white-space: nowrap;
        overflow: hidden;
        flex-wrap: wrap;
      }
    }

    .el-select__tags-text {
      display: inline-block;
      max-width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .el-tag__close.el-icon-close {
      top: -7px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: right;
}
.red_star {
  position: relative;

  &::before {
    content: "*";
    color: red;
    position: absolute;
    left: -8px;
  }
}
::v-deep.el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: hidden;
}
.el-table--border::after,
.el-table--group::after {
  width: 0;
}
.node-relation {
  ::v-deep .el-select {
    .el-tag {
      display: flex;
      align-items: center;
      width: fit-content;
      .el-select__tags-text {
        display: inline-block;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.word_button {
  height: 30px;
  border: 0;
  background: transparent;
  margin-top: -3px;
  white-space: nowrap;
  cursor: pointer;
  color: rgba(1, 76, 170, 1);
  font-family: "思源黑体";
}

.word_button:hover {
  color: #0074b7;
}

.ischecked {
  ::v-deep .center_span {
    border: 1px solid #336690;
    background-color: #336690;
    color: #fff;
  }
}

.icon-shoucang {
  margin: 0 20px;
  cursor: pointer;
}

.sortable-column-header {
  display: flex;
  align-items: center;
  // cursor: pointer;
}

.caret-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.el-icon-caret-top {
  transform: translate(0, 3px);
}

.el-icon-caret-bottom {
  transform: translate(0, -3px);
}

.el-icon-caret-colored {
  color: #068bff;
}

.clickable-rows {
  background: white;
  overflow-x: auto;
}

.clickable-rows::before {
  height: 0px;
}

.filter_wrap {
  // height: auto;
  // .right_input {
  //   height: 100%;
  // }
  // ::v-deep .el-input__inner {
  //   background-color: #e5e5e5 !important;
  //   font-size: 13px;
  //   border-radius: none;
  // }
  // ::v-deep .el-input__inner::-webkit-input-placeholder {
  //   // color: #606266 !important;
  //   text-align: center;
  // }
  ::v-deep .el-table__body-wrapper {
    border-radius: 8px;
  }
}

::v-deep .excel_export_dialog {
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: hidden scroll;
  }

  .el-checkbox {
    padding-bottom: 16px;
    display: flex;

    .el-checkbox__label {
      font-size: 16px;
      font-weight: 400;
      // color: rgba(56, 56, 56, 1);
      text-align: left;
      color: rgba(128, 128, 128, 1);
    }
  }
}
::v-deep .search_box {
  align-self: start;
  height: 28px;
  width: 150px;
  margin: 2px 15px 2px 15px;
  .el-input__inner {
    height: 28px;
  }
}
.checkbox {
  margin-left: 15px;
  margin-bottom: 10px;
  font-size: 10px;
}

.select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;

  .checkbox {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
    width: 100%;
  }
}

.center_span {
  background-color: #e5e5e5;
  padding: 6px 8px;
  font-size: 13px;
  border-radius: 4px;
  color: #606266;

  i {
    margin-left: 10px;
  }
}

.node_wrap {
  .searchBar {
    text-align: left;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .header_filter {
      // flex: 70%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > span {
        margin: 10px 10px;
      }

      .filter_tag {
        display: inline-block;
      }
    }
    .header_button {
      height: 40px;
      line-height: 40px;
    }
    .select_filter {
      flex: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > span {
        margin: 0 10px;
      }
    }

    .blue_button {
      color: rgba(1, 76, 170, 1);
      border: 1px solid rgba(1, 76, 170, 1);
      border-radius: 4px;
      padding: 4px;
      cursor: pointer;
      margin: 0 0.0521rem;
    }

    .right_input {
      width: 150px;
    }
  }
}

.operate_button {
  display: block;
  width: 80px;
  margin: 15px 20px;
  text-align: center;
  cursor: pointer;
  color: #656667;
  position: relative;
}

.operate_button:hover {
  color: #46b4f4;
}

.operate_button_1 {
  display: block;
  width: 80px;
  margin: 15px 20px;
  text-align: center;
  cursor: pointer;
  color: #656667;
  position: relative;
  background: #fcfeff;
}

.operate_button_1 div div:nth-child(n):hover {
  color: #46b4f4;
}

.operate_button_load {
  position: absolute;
  padding-top: 10px;
  padding-bottom: 10px;
  left: 80px;
  top: 0px;
  width: 120px;
  display: none;
  background: #fcfeff;
}

.operate_button_1:hover .operate_button_load {
  display: block;
}

.operate_button_load div {
  line-height: 30px;
}

.my-page {
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: -42px;
  transform: translateX(-50%);
}

.dialog_table {
  ::v-deep .el-table__body-wrapper {
    width: fit-content;
    min-height: auto;
  }
}

.table_wrap {
  position: relative;
}

::v-deep.el-scrollbar__wrap {
  overflow-x: auto;
}

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #568cb8;
}

::v-deep.el-input__inner:focus {
  border-color: #568cb8;
}

/*  */
::v-deep.el-input__inner:focus {
  border-color: #568cb8;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  visibility: hidden;
}

::v-deep .el-table__header-wrapper {
  width: fit-content;
}

::v-deep .el-table__body-wrapper {
  width: fit-content;
}

.bulk {
  .text {
    color: rgba(1, 76, 170, 1);
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    padding-left: 3px;
  }
}
</style>
<style scoped>
/deep/ .daterangepicker .btn-success {
  background-color: #015aaf;
}
/deep/ .daterangepicker .btn-success:hover {
  cursor: pointer;
}
/deep/ .daterangepicker .btn-secondary:hover {
  cursor: pointer;
}
/deep/ .el-loading-mask {
  pointer-events: none;
}
</style>
<style lang="scss">
.createpro-header {
  .el-tabs__active-bar {
    background-color: #30648f;
  }

  .is-active {
    color: #30648f;
  }

  .el-tabs__item:hover {
    color: #30648f;
  }
}
.edit_condition {
  .el-form-item__label {
    // line-height: normal;
  }
}
.el-date-editor.custom_datepicker {
  width: 100%;
}

.table_wrap {
  position: relative;
}

.settings {
  position: absolute;
  right: 10px;
  z-index: 1000;
}

.el-dropdown-menu {
  z-index: 9999999 !important;
  pointer-events: all;

  .el-checkbox-group {
    max-height: 500px;
    overflow-y: auto;
  }
}
.user_select {
  .el-select-dropdown__item {
    display: flex;
    align-items: center;
    .avatar {
      display: flex;
    }
    .select_item {
      margin-left: 14px;
    }
  }
}
</style>
