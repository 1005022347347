<template>
  <div>
    <div class="legend_wrap">
      <div v-for="(item, index) in legendData" :key="index">
        <span
          :style="{
            backgroundColor: colorList[index % 10],
          }"
        ></span>
        <span>
          <el-tooltip
            v-ak-tooltip-auto-show
            class="item"
            effect="dark"
            :content="item.name"
          >
            <span class="legend_name_wrap">{{ item.name }}</span>
          </el-tooltip>
        </span>
        <span>{{ item.count }}</span>
      </div>
    </div>
    <div class="table_wrap">
      <el-table :data="tableData" :height="400" style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          :filters="filterFileType"
          :filter-method="filterHandler"
          width="150px"
        >
          <template slot="header">
            <span class="table_header_style">{{ $t("node.table.type") }}</span>
          </template>
          <template slot-scope="scope">
            <el-tooltip
              v-ak-tooltip-auto-show
              class="item"
              effect="dark"
              :content="getFileType(scope.row.fileTypeId)"
            >
              <span
                style="
                  color: #2c3e50;
                  display: inline-block;
                  padding: 2px 6px;
                  border-radius: 4px;
                "
                :style="{
                  backgroundColor:
                    legendColorMap[getFileType(scope.row.fileTypeId)],
                }"
              >
                {{ getFileType(scope.row.fileTypeId) }}
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip width="300px">
          <template slot="header"
            ><span class="table_header_style">Key</span></template
          >
          <template slot-scope="scope">
            <span>
              <div style="display: inline-block" class="hover-class">
                <span style="cursor: pointer">
                  <a
                    style="color: rgb(255, 195, 0)"
                    :href="
                      '/' +
                      scope.row.projectId +
                      '/nodes/key/' +
                      scope.row.nodeKey
                    "
                    target="_blank"
                    >{{ scope.row.nodeKey }}</a
                  >
                </span>
              </div>
              <div style="display: inline-block">
                <div style="display: inline-block">
                  <a
                    :href="
                      '/' +
                      scope.row.projectId +
                      '/myMind/' +
                      scope.row.fileKey +
                      '?node_id=' +
                      scope.row.id
                    "
                    target="_blank"
                  >
                    <i
                      style="
                        font-size: 12px;
                        margin-left: 16px;
                        cursor: pointer;
                        color: rgb(48, 100, 143);
                      "
                      class="iconfont icon-node_link hover-icon"
                    >
                    </i>
                  </a>
                </div>
              </div>
            </span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip width="500px">
          <template slot="header">
            <span class="table_header_style">{{
              $t("node.table.name")
            }}</span></template
          >
          <template slot-scope="scope">
            <span style="color: #2c3e50">
              {{ scope.row.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip>
          <template slot="header"
            ><span class="table_header_style">{{
              $t("node.table.responsibility")
            }}</span>
          </template>
          <template slot-scope="scope">
            <span style="color: #2c3e50">
              {{
                user_list[scope.row.assignee]
                  ? user_list[scope.row.assignee].nickname
                  : ""
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip width="200px">
          <template slot="header"
            ><span class="table_header_style"
              >{{ $t("node.table.status") }}
            </span></template
          >
          <template slot-scope="scope">
            <span style="color: #2c3e50">
              <status-tag
                v-model="scope.row.status.name"
                :style_catogry="'plain'"
                :status_type="scope.row.status.statusCategory"
                :size="'small'"
              >
              </status-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column width="180px">
          <template slot="header"
            ><span class="table_header_style">{{ $t("btn.optionBtn") }}</span>
          </template>
          <template slot-scope="scope">
            <span
              style="color: #ff4823; cursor: pointer"
              @click="removeLink(scope.row)"
            >
              {{ $t("model_view.remove_link_visiable") }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="add_relation">
      <el-tabs v-model="addLinkNodeTab">
        <el-tab-pane
          :label="$t('nodeDetail.relationChart.addRelatedNode')"
          name="accurate"
        >
          <el-form
            v-limits-of-authority="'NODE_EDIT'"
            :inline="true"
            size="medium"
            style="margin-top: 10px; text-align: left"
          >
            <el-form-item>
              <el-select
                v-model="value"
                multiple
                filterable
                remote
                reserve-keyword
                collapse-tags
                style="width: 400px"
                :placeholder="$t('nodeDetail.relationChart.placeholder')"
                :loading-text="$t('loading.loading2')"
                :remote-method="get_unrelation_node"
                :loading="loading"
                @visible-change="clearData"
                popper-class="select-icon-height-auto-class"
              >
                <el-option
                  v-for="item in options"
                  :key="item.nodeKey"
                  :label="`${item.nodeKey}: ${cut(item.topic)}`"
                  :value="item.nodeKey"
                >
                  <!-- ms8613代码定位 -->
                  <select-icon :item="item"> </select-icon>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" @click="add_relation">{{
                $t("btn.confirmBtn")
              }}</el-button>
            </el-form-item>
          </el-form></el-tab-pane
        >
        <el-tab-pane
          :label="$t('nodeDetail.relationChart.Batchassociatednodes')"
          name="second"
        >
          <div style="margin-top: 10px; text-align: left">
            <el-input
              v-model="batchNodeStr"
              type="textarea"
              size="medium"
              style="width: 400px; margin-right: 10px; margin-bottom: 10px"
              @input="batchNodeInput"
              :placeholder="$t('nodeDetail.relationChart.placeholder2')"
            ></el-input>
            <el-button
              size="mini"
              type="primary"
              :loading="batchLoading"
              @click="add_batch_relation"
              >{{ $t("btn.confirmBtn") }}</el-button
            >
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import StatusTag from "@/components/statusTag";
import {
  nodeRelationsTable,
  add_relation_node,
  add_batch_relation_node,
  cancel_relation_node,
} from "@/network/node/index.js";
import { searchNodesByFuzzyKey } from "@/network/fileOperation/index.js";
import { mapGetters } from "vuex";
import selectIcon from "@/components/selectIcon";
import vmson from "@/utils/vmson";
export default {
  components: {
    StatusTag,
    selectIcon,
  },
  props: {
    nodeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    fileTypeId: {
      type: String,
      default: "",
    },
    node_key: {
      type: String,
      default: "",
    },
    node_relation_data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tableData: [],
      legendData: [],
      legendColorMap: {},
      colorList: [
        "rgba(255, 249, 199, 1)",
        "rgba(226, 241, 255, 1)",
        "rgba(218, 255, 221, 1)",
        "rgba(238, 218, 218, 1)",
        "rgba(206, 242, 242, 1)",
        "rgba(245, 203, 179, 1)",
        "rgba(213, 197, 235, 1)",
        "rgba(221, 232, 158, 1)",
        "rgba(166, 237, 208, 1)",
        "rgba(158, 195, 240, 1)",
      ],
      filterFileType: [],
      addLinkNodeTab: "accurate",
      value: [],
      options: [],
      allOptions: [],
      batchNodeStr: "",
      loading: false,
      batchLoading: false,
    };
  },
  computed: {
    ...mapGetters(["fileType", "user_list"]),
  },
  watch: {
    options(newValue, oldValue) {
      oldValue.forEach((item) => {
        if (!this.inAllOptions(item.nodeKey)) {
          this.allOptions.push(item);
        }
      });
    },
  },
  mounted() {
    this.nodeRelationsTable();
  },
  methods: {
    cut(str) {
      if (str.length > 15) {
        return str.slice(0, 14) + "...";
      } else {
        return str;
      }
    },
    batchNodeInput(value) {
      this.batchNodeStr = value.replace(/[\n\s，、/\\]+/g, ",");
    },
    inAllOptions(nodeKey) {
      let flag = false;
      this.allOptions.forEach((option) => {
        if (option.nodeKey === nodeKey) {
          flag = true;
        }
      });
      return flag;
    },
    add_relation() {
      this.$emit("hideLoading", true);
      let arr = [];
      this.options.forEach((option) => {
        if (!this.inAllOptions(option.nodeKey)) {
          this.allOptions.push(option);
        }
      });
      this.allOptions.forEach((item) => {
        if (this.value.indexOf(item.nodeKey) != -1) {
          arr.push({
            sourceFileTypeId: this.nodeInfo
              ? this.nodeInfo.fileTypeId
              : this.fileTypeId,
            targetFileTypeId: item.fileTypeId,
            sourceNodeKey: this.node_key,
            targetNodeKey: item.nodeKey,
            sourceProjectId: this.get_pid(),
            targetProjectId: item.projectId,
          });
        }
      });
      add_relation_node(this.get_pid(), this.node_key, arr)
        .then(() => {
          this.value = [];
          this.nodeRelationsTable();
          // 节点关联关系变化事件
          vmson.$emit("node_relation_change");
        })
        .catch((err) => {
          this.$emit("hideLoading", false);
          console.log(err);
        });
    },
    add_batch_relation() {
      this.batchLoading = true;
      const params = {
        projectId: this.get_pid(),
        data: {
          sourceNodeList: [
            {
              projectId: this.get_pid(),
              nodeKey: this.node_key,
              fileTypeId: this.nodeInfo
                ? this.nodeInfo.fileTypeId
                : this.fileTypeId,
            },
          ],
          targetNodeKeyList: this.batchNodeStr.split(",").filter((item) => {
            return item;
          }),
        },
      };
      add_batch_relation_node(params)
        .then(() => {
          this.batchNodeStr = "";
          this.nodeRelationsTable();
          // 节点关联关系变化事件
          vmson.$emit("node_relation_change");
          this.batchLoading = false;
        })
        .catch((err) => {
          this.$emit("hideLoading", false);
          this.batchLoading = false;
          console.log(err);
        });
    },
    clearData() {
      if (this.value.length == 0) this.options = [];
    },
    get_unrelation_node(query) {
      if (query !== "") {
        this.loading = true;
        let related_nodes = this.node_relation_data.nodeRelationGraphList.map(
          (ele) => {
            return ele.nodeKey;
          }
        );
        searchNodesByFuzzyKey(query)
          .then((res) => {
            this.options = res.filter((ele) => {
              if (related_nodes.indexOf(ele.nodeKey) == -1) {
                return ele;
              }
            });
            this.loading = false;
          })
          .catch((err) => console.log(err));
      } else {
        this.options = [];
      }
    },
    filterHandler(value, row, column) {
      if (
        this.fileType[row.fileTypeId] &&
        this.fileType[row.fileTypeId].name === value
      ) {
        return true;
      } else if (
        !this.fileType[row.fileTypeId] ||
        !this.legendColorMap[this.fileType[row.fileTypeId].name]
      ) {
        if (value === "Any file type" || value === "任意类型") {
          return true;
        }
      }
    },
    removeLink(row) {
      let data = {
        sourceNodeKey: this.node_key,
        targetNodeKey: row.nodeKey,
        sourceProjectId: this.get_pid(),
        targetProjectId: row.projectId,
      };
      cancel_relation_node(this.get_pid(), this.node_key, data).then((res) => {
        this.nodeRelationsTable();
      });
    },
    nodeRelationsTable() {
      this.tableData = [];
      this.legendData = [];
      this.legendColorMap = {};
      this.filterFileType = [];
      nodeRelationsTable(this.get_pid(), this.node_key).then((res) => {
        this.tableData = res.targetNodeList;
        this.legendData = res.numberOfNodesOfEachTypeList;
        res.numberOfNodesOfEachTypeList.forEach((item, index) => {
          this.legendColorMap[item.name] = this.colorList[index % 10];
          const obj = {
            text: item.name,
            value: item.name,
          };
          this.filterFileType.push(obj);
        });
      });
    },
    getFileType(fileTypeId) {
      if (
        this.fileType[fileTypeId] &&
        this.legendColorMap[this.fileType[fileTypeId].name]
      ) {
        return this.fileType[fileTypeId].name;
      } else {
        return this.$t("node.table.anyType");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.legend_wrap {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  & > div {
    display: flex;
    margin-right: 32px;
    align-items: center;
    & > span {
      margin: 0 2px;
      height: 20px;
      &:first-of-type {
        display: inline-block;
        width: 20px;
        height: 12px;
        vertical-align: middle;
      }
    }
  }
  .legend_name_wrap {
    display: inline-block;
    max-width: 100px;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.table_wrap {
  .table_header_style {
    font-size: 14px;
    color: #2c3e50;
    font-weight: bold;
  }
}
</style>
