var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.wrapShow),expression:"wrapShow"},{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"edit-wrap",attrs:{"id":"edit-wrap"},on:{"click":function($event){$event.preventDefault();return _vm.clickeWrap.apply(null, arguments)}}},[_c('div',{attrs:{"id":"standalone-container"}},[_c('toolbar',{attrs:{"quill":_vm.quill,"client":_vm.client,"container":"toolbar-container"}}),_c('div',{attrs:{"id":"editor-container-wrap"},on:{"scroll":_vm.warpScroll}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.connected),expression:"connected"}],staticClass:"editor-container",attrs:{"id":"editor-container"},on:{"click":_vm.checktext,"keydown":_vm.handleTextChange}})]),_vm._l((_vm.userCursorList),function(cursor,index){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(cursor.show),expression:"cursor.show"}],key:index,staticClass:"cursor_span",class:{
        twinkle: cursor.animation,
      },style:({
        top: cursor.top + 'px',
        left: cursor.left + 'px',
        'background-color': cursor.color,
        color: cursor.color,
      })},[_c('i',{style:({ 'background-color': cursor.color }),on:{"mouseenter":function($event){cursor.showName = true;
          cursor.animation = false;},"mouseout":function($event){cursor.showName = false;
          cursor.animation = true;}}}),(cursor.showName)?_c('span',[_vm._v(_vm._s(cursor.userName))]):_vm._e()])})],2),_c('div',{attrs:{"id":"menu"}},[_c('div',_vm._l((_vm.rightMenu),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.menuClick(index)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)]),_c('label',{staticStyle:{"display":"block"}},[_c('textarea',{ref:"text_area",staticStyle:{"opacity":"0"}})]),_c('div',{staticClass:"goTop"},[_c('i',{staticClass:"iconfont icon-a-huaban18",on:{"click":_vm.goTop}})])])}
var staticRenderFns = []

export { render, staticRenderFns }