<template>
  <div
    v-loading
    class="nodePopover"
    @mouseenter="enter_popover"
    @mouseleave="leave_popover"
  >
    <el-row
      v-loading="fullscreenLoading"
      style="height: 100%; position: relative"
    >
      <i
        class="el-icon-document hover-icon"
        style="
          font-size: 23px;
          vertical-align: middle;
          color: rgb(48, 100, 143);
          position: absolute;
          right: 40px;
          bottom: -24px;
          cursor: pointer;
        "
        @click="showDes"
      />
      <create-new-file
        ref="creatNewFile"
        style="position: absolute; right: 6px; bottom: -25px"
        :node_info="node_info"
      >
        <i
          class="iconfont icon-tianjia hover-icon"
          style="
            font-size: 26px;
            vertical-align: middle;
            color: rgb(48, 100, 143);
          "
        />
      </create-new-file>
      <header>
        <el-button type="text">
          <div
            class="node_key"
            @click="toNodeDetailPage"
            @mouseenter="copy_zdh_key_open"
            @mouseleave="copy_zdh_key_close"
          >
            {{ node_key
            }}<i
              v-show="show_copy_zdh_key_file_node"
              @click.stop="copy_zdh_key_file_node(node_key)"
              class="iconfont icon-a-Frame1 copy_zdh_key"
            />
          </div>
        </el-button>
        <el-tooltip
          v-ak-tooltip-auto-show
          effect="dark"
          placement="top"
          :content="author"
        >
          <div class="author">
            {{ $t("canvas.floatWindow.creator") }}: {{ author }}
          </div>
        </el-tooltip>

        <div class="workflow">
          <el-button
            slot="reference"
            :class="{
              status_todo: status_type == 'TODO',
              status_progress: status_type == 'IN_PROGRESS',
              status_open: status_type == 'DONE',
            }"
            size="mini"
            :loading="status_changing"
            @click="show_workflow"
          >
            <span
              style="
                width: 80%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
              "
            >
              {{ status }}
            </span>
            <i
              style="font-weight: 800"
              :class="[
                showWorkflow ? 'el-icon-arrow-up' : 'el-icon-arrow-down',
              ]"
            ></i>
          </el-button>
          <div v-if="showWorkflow" class="workflow_select">
            <template v-if="workflows.length != 0">
              <div
                v-for="(item, index) in workflows"
                :key="index"
                class="workflow_item"
              >
                <div
                  v-limits-of-authority="'NODE_EDIT'"
                  @click="
                    changeWorkflow(
                      item.statusId,
                      item.statusCategory,
                      item.name
                    )
                  "
                >
                  <span
                    style="
                      display: inline-block;
                      width: 100%;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                    >{{ item.name }}</span
                  >
                </div>
              </div>
              <div class="workflow_item view_workflow" @click="view_workflow">
                <span>{{ $t("canvas.floatWindow.workflowView") }}</span>
              </div>
            </template>
            <template v-else>
              <div class="workflow_none">
                <span>{{ $t("canvas.floatWindow.workflowNone") }}</span>
              </div>
              <div class="workflow_item view_workflow" @click="view_workflow">
                <span>{{ $t("canvas.floatWindow.workflowView") }}</span>
              </div>
            </template>
          </div>
        </div>
        <div class="relation" @click="showDialog">
          <i
            class="iconfont icon-relation hover-icon"
            style="font-size: 15px; color: rgb(48, 100, 143)"
          />
        </div>
      </header>
      <div class="body">
        <el-scrollbar style="height: 100%">
          <el-form
            ref="propertyForm"
            v-limits-of-authority="'NODE_EDIT'"
            label-width="125px"
            :model="form_bind"
            :hide-required-asterisk="true"
            label-position="left"
          >
            <!-- 系统字段：责任人 -->
            <el-form-item
              v-if="user_list.length > 0"
              key="assignee"
              :label="$t('canvas.floatWindow.form.responsibility')"
            >
              <el-select
                v-model="popover_content.assignee"
                popper-class="user_select"
                :loading="user_loading"
                :loading-text="$t('loading.loading2')"
                :filter-method="filterMethod"
                filterable
                clearable
                @change="change_assignee"
                @click.native="keepPopover"
                @focus="keepPopover"
                @blur.native.capture="hidePopover"
                @visible-change="set_user('chang_assignee')"
              >
                <el-option
                  v-for="opt in filterUserList"
                  v-show="!opt.deleted && !opt.hide"
                  :key="opt.value"
                  :label="opt.label"
                  :value="opt.value"
                >
                  <personal-avatar
                    class="avatar"
                    :avatar="opt.avatar"
                    :colorid="opt.userAccountId"
                    :nickname="opt.label"
                    :size="20"
                  >
                  </personal-avatar>
                  <span class="select_item">{{ opt.label }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <!-- bug类型相关属性 -->
            <template v-if="false">
              <el-form-item
                key="test_plan"
                :label="$t('nodeDetail.publish.testPlan')"
              >
                <el-select
                  v-model="popover_content.testPlanId"
                  filterable
                  clearable
                  @change="
                    (val) => {
                      set_property_value(val, false, 'bug_test_plan');
                    }
                  "
                  @visible-change="hidePopover_select"
                  @focus="keepPopover"
                  @blur.native.capture="hidePopover"
                >
                  <el-option
                    v-for="opt in test_plan_list"
                    :key="opt.testPlanId"
                    :label="`${opt.name} (${get_plan_status(opt.status)})`"
                    :value="opt.testPlanId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                key="test_case"
                :label="$t('canvas.floatWindow.testcase')"
              >
                <el-select
                  v-model="popover_content.testCase"
                  :disabled="!popover_content.testPlanId"
                  filterable
                  clearable
                  multiple
                  @change="
                    (val) => {
                      set_property_value(val, false, 'bug_test_case');
                    }
                  "
                  @visible-change="hidePopover_select"
                  @focus="keepPopover"
                  @blur.native.capture="hidePopover"
                >
                  <el-option
                    v-for="(option, index) in test_case_list"
                    :key="index"
                    :label="`${option.nodeKey} ${option.topic}`"
                    :value="option.nodeKey"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <template v-for="(item, index) in formLabel">
              <el-form-item
                v-if="item.show && item.fieldType !== 'DRAWIO'"
                :key="index"
              >
                <template slot="label">
                  <el-tooltip
                    effect="dark"
                    :content="item.label"
                    :disabled="show_name"
                    placement="left"
                  >
                    <div
                      class="label_overflow"
                      @mouseover="show_name_tip(`label_name-${index}`)"
                    >
                      <span
                        :ref="`label_name-${index}`"
                        :class="{ red_star: item.required }"
                        >{{ item.label }}</span
                      >
                    </div>
                  </el-tooltip>
                </template>

                <el-input
                  v-if="item.fieldType == 'TEXT' || item.fieldType == 'LINK'"
                  v-model="form_bind[item.key]"
                  :disabled="is_lock"
                  @blur="
                    hidePopover_input(form_bind[item.key], true, item.fieldId)
                  "
                  @focus="keepPopover"
                >
                </el-input>

                <el-input
                  v-if="item.fieldType == 'NUMBER'"
                  v-model.number="form_bind[item.key]"
                  type="number"
                  style="width: 80%"
                  :placeholder="$t('placeholder.number')"
                  :disabled="is_lock"
                  @blur="
                    hidePopover_input(form_bind[item.key], true, item.fieldId)
                  "
                  @focus="keepPopover"
                >
                </el-input>
                <el-tooltip
                  v-if="item.fieldType == 'NUMBER'"
                  v-ak-tooltip-auto-show
                  effect="dark"
                  :content="item.unit"
                  placement="right"
                >
                  <span
                    style="
                      display: inline-block;
                      margin-left: 5%;
                      width: 15%;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      height: 32px;
                      line-height: 32px;
                      vertical-align: middle;
                    "
                  >
                    {{ item.unit }}</span
                  >
                </el-tooltip>
                <el-input
                  v-if="item.fieldType == 'TEXTAREA'"
                  v-model="form_bind[item.key]"
                  type="textarea"
                  autosize
                  :placeholder="$t('placeholder.input')"
                  :disabled="is_lock"
                  @blur="
                    hidePopover_input(form_bind[item.key], true, item.fieldId)
                  "
                  @focus="keepPopover"
                >
                </el-input>
                <el-date-picker
                  v-if="item.fieldType == 'DATE'"
                  v-model="form_bind[item.key]"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :placeholder="$t('placeholder.date')"
                  :disabled="is_lock"
                  @focus="keepPopover"
                  @change="
                    (val) => {
                      set_property_value(val, true, item.fieldId);
                    }
                  "
                >
                </el-date-picker>
                <el-select
                  v-if="item.fieldType == 'SELECT'"
                  v-model="form_bind[item.key]"
                  filterable
                  clearable
                  :disabled="is_lock"
                  @focus="keepPopover"
                  @change="
                    (val) => {
                      set_property_value(val, true, item.fieldId);
                    }
                  "
                >
                  <el-option
                    v-for="opt in item.extraData"
                    :key="opt.value"
                    :label="opt.label"
                    :value="opt.value"
                  >
                  </el-option>
                </el-select>

                <el-select
                  v-if="
                    item.fieldType == 'SELECT_MULTI' ||
                    item.fieldType == 'VERSION'
                  "
                  v-model="form_bind[item.key]"
                  :placeholder="$t('placeholder.dropdown')"
                  multiple
                  filterable
                  :disabled="is_lock"
                  @focus="keepPopover"
                  @change="
                    (val) => {
                      set_property_value(val, true, item.fieldId);
                    }
                  "
                >
                  <el-option
                    v-for="opt in item.extraData"
                    :key="opt.value"
                    :label="opt.label"
                    :value="opt.value"
                  >
                  </el-option>
                </el-select>

                <!-- 单用户下拉框 -->
                <el-select
                  v-if="item.fieldType == 'SINGLE_USER_SELECT'"
                  v-model="form_bind[item.key]"
                  popper-class="user_select"
                  :loading="user_loading"
                  :loading-text="$t('loading.loading2')"
                  :filter-method="filterMethod"
                  filterable
                  clearable
                  :disabled="is_lock"
                  @focus="keepPopover"
                  @visible-change="singleUserVisibleChange"
                  @change="
                    (val) => {
                      set_property_value(val, true, item.fieldId);
                    }
                  "
                >
                  <el-option
                    v-for="opt in filterUserList"
                    v-show="!opt.deleted && !opt.hide"
                    :key="opt.value"
                    :label="opt.label"
                    :value="opt.value"
                  >
                    <personal-avatar
                      class="avatar"
                      :avatar="opt.avatar"
                      :colorid="opt.userAccountId"
                      :nickname="opt.label"
                      :size="20"
                    >
                    </personal-avatar>
                    <span class="select_item">{{ opt.label }}</span>
                  </el-option>
                </el-select>

                <!-- 多用户下拉框 -->
                <el-select
                  v-if="item.fieldType == 'MULTI_USER_SELECT'"
                  v-model="form_bind[item.key]"
                  popper-class="user_select"
                  multiple
                  filterable
                  :filter-method="groupandUserfilterMethod"
                  :loading="user_loading"
                  :loading-text="$t('loading.loading2')"
                  :disabled="is_lock"
                  @focus="keepPopover"
                  @visible-change="mutUserVisibleChange"
                  @change="
                    (val) => {
                      setMultiUser(val, true, item.fieldId);
                    }
                  "
                >
                  <el-option-group
                    v-for="(group, index) in filterGroupandUser"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="user in group.options"
                      :key="user.value"
                      :label="user.label"
                      :value="user.value + index"
                    >
                      <span class="select_item">{{ user.label }}</span>
                    </el-option>
                  </el-option-group>
                </el-select>

                <!-- 可创建条目 -->
                <el-select
                  v-if="item.fieldType == 'LABEL'"
                  v-model="form_bind[item.key]"
                  :placeholder="$t('placeholder.dropdown')"
                  :loading="label_loading"
                  :loading-text="$t('loading.loading2')"
                  multiple
                  allow-create
                  filterable
                  :disabled="is_lock"
                  @visible-change="get_Label_data"
                  @focus="keepPopover"
                  @change="
                    (val) => {
                      set_property_value(val, true, item.fieldId);
                    }
                  "
                >
                  <el-option
                    v-for="opt in project_labels"
                    :key="opt.value"
                    :label="opt.label"
                    :value="opt.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <div v-if="file_test" class="file_test">
              <el-tooltip
                effect="dark"
                :content="$t('canvas.testPlan.floatWindow.result')"
                :disabled="show_name"
                placement="left"
              >
                <div
                  class="label_overflow"
                  style="width: 125px"
                  @mouseover="show_name_tip3('label_name-result')"
                >
                  <p
                    style="
                      text-align: left;
                      padding-right: 0;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width: 125px;
                    "
                  >
                    <span
                      :ref="'label_name-result'"
                      style="
                        font-size: 16px;
                        padding-right: 0;
                        text-align: left;
                      "
                      >{{ $t("canvas.testPlan.floatWindow.result") }}</span
                    >
                  </p>
                </div>
              </el-tooltip>
              <!-- <p>{{ $t("canvas.testPlan.floatWindow.result") }}</p> -->
              <div class="gray_icon">
                <el-input
                  v-if="select_nodes()"
                  v-model="test_result"
                  @change="save_test_result"
                  @blur="hidePopover_input_gray_icon"
                  @focus="keepPopover"
                >
                </el-input>
                <el-input
                  v-else
                  disabled
                  prefix-icon="el-icon-warning"
                  :value="$t('canvas.testPlan.floatWindow.editError')"
                  @focus="keepPopover"
                >
                </el-input>
              </div>
            </div>

            <div v-if="file_test" class="file_test">
              <el-tooltip
                effect="dark"
                :content="$t('canvas.testPlan.floatWindow.status')"
                :disabled="show_name"
                placement="left"
              >
                <div
                  class="label_overflow"
                  style="width: 125px"
                  @mouseover="show_name_tip3('label_name-status')"
                >
                  <p
                    style="
                      text-align: left;
                      padding-right: 0;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    <span
                      :ref="'label_name-status'"
                      style="
                        font-size: 16px;
                        padding-right: 0;
                        text-align: left;
                      "
                      >{{ $t("canvas.testPlan.floatWindow.status") }}</span
                    >
                  </p>
                </div>
              </el-tooltip>
              <!-- <p>{{ $t("canvas.testPlan.floatWindow.status") }}</p> -->
              <div v-if="select_nodes()" class="file_test_e">
                <el-select
                  v-model="test_status"
                  class="select"
                  size="small"
                  @change="execute_test_status()"
                  @click.native="keepPopover"
                  @focus="keepPopover"
                  @blur.native.capture="hidePopover"
                >
                  <el-option
                    v-for="(item, index) in test_options"
                    v-show="item.complete"
                    :key="index"
                    :label="item.statusName"
                    :value="item.status"
                  >
                  </el-option>
                </el-select>

                <i
                  class="iconfont icon-add"
                  style="cursor: pointer; color: rgb(48, 100, 143)"
                  @click="submit()"
                >
                </i>
              </div>

              <div v-else class="gray_icon">
                <el-input
                  :disabled="true"
                  prefix-icon="el-icon-warning"
                  :value="$t('canvas.testPlan.floatWindow.executeError')"
                  @focus="keepPopover"
                >
                </el-input>
              </div>
            </div>
          </el-form>
        </el-scrollbar>
      </div>
    </el-row>
  </div>
</template>

<script>
import vmson from "@/utils/vmson";
import {
  set_workflow,
  get_allProjectMember,
  get_node_detail,
  get_Labels,
  only_set_property,
  get_workflow_list,
  multiLayers_change_assignee,
  set_workflow_myself,
} from "@/network/node/index.js";
import { mapGetters } from "vuex";
import PersonalAvatar from "@/components/personal";
import CreateNewFile from "@/components/createNewFile";
import { getFile, get_filetype, createNewFile } from "@/network/home/index.js";
import {
  get_filetype_List,
  get_filetype_detail,
} from "@/network/fileType/index.js";
import { enter_process } from "@/network/testPlan/index.js";
import { get_test_nodes, execute_test_node } from "@/network/test/index.js";
import { get_group_user_list } from "@/network/addGroup/index.js";
import { pinyin } from "pinyin-pro";
import Vue from "vue";
export default {
  inject: ["reloadPopover"],
  components: {
    PersonalAvatar,
    CreateNewFile,
  },
  props: {
    nodeUuid: {
      type: String,
      default: "",
    },
    layer: {
      type: Number,
      default: 1,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      test_result: "",
      test_status: "",
      result_options: [
        {
          label: this.$t("canvas.testPlan.executeStatus.UN_EXECUTED"),
          value: "UN_EXECUTED",
        },
        {
          label: this.$t("canvas.testPlan.executeStatus.APPROVED"),
          value: "APPROVED",
        },
        {
          label: this.$t("canvas.testPlan.executeStatus.BLOCKED"),
          value: "BLOCKED",
        },
        {
          label: this.$t("canvas.testPlan.executeStatus.FAILED"),
          value: "FAILED",
        },
        {
          label: this.$t("canvas.testPlan.executeStatus.NA"),
          value: "NA",
        },
      ],
      groupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      filterGroupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      file_test: false,
      popover_content: {},
      form: {},
      showWorkflow: false,
      formLabel: [],
      fullscreenLoading: true,
      cur_status: null,
      cur_status_type: null,
      user_loading: true,
      label_loading: true,
      user_list: [],
      filterUserList: [],
      project_labels: [],
      status_changing: false,
      status_lsit: null,
      current_statusId: null,
      show_name: true,
      is_edit: false,
      test_plan_list: [],
      test_case_list: [],
      bug_filetype_ids: [],
      is_lock: false,
      testCaseStatus: "",
      show_copy_zdh_key_file_node: false,
      fileResult: {},
      asilOption: [],
      hazardOption: [],
      // 获取节点详情
      node_info: {},
    };
  },
  computed: {
    ...mapGetters({
      file_type_id: "file_type_id",
      allstatus: "status",
      all_user_list: "user_list",
    }),
    test_options() {
      let testStatus = Object.values(
        JSON.parse(sessionStorage.getItem("_execution_status"))
      );
      let li = [];
      testStatus.forEach((e) => {
        e.complete ? li.push(e) : "";
      });
      return testStatus;
    },
    get_author() {
      return this.matchUserNickname(
        this.popover_content.createdBy,
        this.$t("board.status.unAssigned")
      );
    },
    header() {
      let obj = {};
      obj.nodeId = this.popover_content.id;
      obj.nodeUuid = this.popover_content.nodeUuid;
      obj.node_key = this.popover_content.key;
      obj.author = this.get_author;
      obj.status_id = this.popover_content.status;
      this.popover_content.status
        ? ((obj.status = this.allstatus[this.popover_content.status].name),
          (obj.status_type =
            this.allstatus[this.popover_content.status].statusCategory))
        : "";
      obj.workflows = this.status_lsit;
      return obj;
    },
    status() {
      return this.cur_status != null ? this.cur_status : this.header.status;
    },
    status_type() {
      return this.cur_status_type != null
        ? this.cur_status_type
        : this.header.status_type;
    },
    node_key() {
      return this.header.node_key;
    },
    author() {
      return this.header.author;
    },
    workflows() {
      return this.header.workflows.length > 0 ? this.header.workflows : [];
    },
    submit_form() {
      let obj = JSON.parse(JSON.stringify(this.popover_content));
      let form_item_val = Object.values(this.form); // 对象的value数组化
      obj.content.forEach((ele, index) => {
        ele.value = form_item_val[index];
      });
      return obj;
    },
    form_bind() {
      return this.form;
    },
    is_bug() {
      let flag = this.bug_filetype_ids.includes(
        this.popover_content.fileTypeId
      );
      if (flag && !this.test_plan_list.length) {
        this.get_testplan_list();
      }
      return flag;
    },
  },
  watch: {
    nodeUuid() {
      this.reloadPopover();
    },
    popover_content(newValue) {
      this.formLabel = [];
      let form_value = [];
      this.formLabel = newValue.content.map((ele, index) => {
        ele.label = ele.name;
        ele.key = `form_item${index}`;
        form_value.push(ele.value); // value可以是字符串，可以是数组
        return ele;
      });
      let obj = {};
      this.formLabel.forEach((ele, index) => {
        obj[ele.key] = form_value[index];
      });
      this.form = obj;

      if (this.select_nodes()) {
        get_test_nodes(
          this.get_pid(),
          document
            .getElementById("global_variable_select_testplan")
            .innerText.replace(/^\s*|\s*$/g, "")
        ).then((res) => {
          for (let i of res) {
            if (i.nodeKey == this.node_key) {
              this.testCaseStatus = i.testCaseStatus;
              this.test_result = i.testResult;
              this.test_status = i.testCaseStatus;
            }
          }
          this.fullscreenLoading = false;
        });
      } else {
        this.fullscreenLoading = false;
      }
    },
    is_bug() {
      if (this.is_bug && this.popover_content.testPlanId) {
        get_test_nodes(this.get_pid(), this.popover_content.testPlanId).then(
          (res) => {
            this.test_case_list = res;
          }
        );
      }
    },
  },
  mounted() {
    this.get_user_list();
    this.initial();
    get_filetype_List(this.get_pid()).then((res) => {
      this.bug_filetype_ids = res
        .filter((item) => item.bug)
        .map((ele) => ele.fileTypeId);
      for (let i of res) {
        if (i["fileTypeId"] == this.$store.getters.file_type_id) {
          this.file_icon = i.icon;
          this.file_icon_name = i.name;
          this.file_test = i.testCase;
        }
      }
    });
    this.getGroupList();
  },
  methods: {
    copy_zdh_key_open() {
      this.show_copy_zdh_key_file_node = true;
    },
    copy_zdh_key_close() {
      this.show_copy_zdh_key_file_node = false;
    },
    copy_zdh_key_file_node(key) {
            if (navigator.clipboard) {
        navigator.clipboard.writeText(key);
      } else {
        const textarea = document.createElement("textarea");
        textarea.value = key;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      }

      this.$message({
        type: "success",
        message: this.$t("AI.tip14"),
      });
    },
    // 展开描述
    showDes() {
      this.$emit("showDes", this.node_info);
    },
    // 过滤方法
    getPinyinMatch(inputValue, list, label) {
      /* 输入内容拼音转换 */
      // 完整拼音
      const pyInput = pinyin(inputValue, {
        toneType: "none",
        type: "array",
      }).join("");
      // 拼音首字母
      const headerPyInput = pinyin(inputValue, {
        pattern: "first",
        type: "array",
      }).join("");
      return list.filter((e) => {
        const pyE = pinyin(e[label ? label : "nickname"], {
          toneType: "none",
          type: "array",
        })
          .join("")
          .slice(0, pyInput.length);
        // 列表中每项的拼音首字母
        const headerPyE = pinyin(e[label ? label : "nickname"], {
          pattern: "first",
          type: "array",
        })
          .join("")
          .slice(0, headerPyInput.length);
        // 过滤匹配
        return (
          e[label ? label : "nickname"].includes(inputValue) || pyE === pyInput
          // ||
          // headerPyE === headerPyInput
        );
      });
    },
    singleUserVisibleChange() {
      this.filterUserList = JSON.parse(JSON.stringify(this.user_list));
    },
    mutUserVisibleChange() {
      this.filterGroupandUser = JSON.parse(JSON.stringify(this.groupandUser));
    },
    filterMethod(val) {
      this.filterUserList = this.getPinyinMatch(val, this.user_list, "label");
    },
    groupandUserfilterMethod(val) {
      if (!val) {
        this.filterGroupandUser = JSON.parse(JSON.stringify(this.groupandUser));
      } else {
        this.$set(
          this.filterGroupandUser[0],
          "options",
          this.getPinyinMatch(val, this.groupandUser[0].options, "label")
        );
        this.$set(
          this.filterGroupandUser[1],
          "options",
          this.getPinyinMatch(val, this.groupandUser[1].options, "label")
        );
      }
    },
    getGroupList() {
      const group = Object.values(this.$store.getters.user_group_list);
      const user = Object.values(this.$store.getters.project_user_list);
      const filteredArray = user.filter((obj) => obj.deleted == 0);
      filteredArray.map((item, index) => {
        this.groupandUser[0].options.push({
          value: item.accountId,
          label: item.nickname,
        });
        this.filterGroupandUser[0].options.push({
          value: item.accountId,
          label: item.nickname,
        });
      });
      group.map((item, index) => {
        this.groupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
        this.filterGroupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
      });
    },
    async change_assignee() {
      try {
        await multiLayers_change_assignee({
          projectId: this.get_pid(),
          nodeKeys: [this.node_key],
          data: {
            accountId: this.popover_content.assignee,
          },
        });
        await this.updateFileAndMindmap();
      } catch (error) {
        console.log(error);
      }
    },
    save_test_result() {
      if (
        !document
          .getElementById("global_variable_select_testplan")
          .innerText.replace(/^\s*|\s*$/g, "")
      ) {
        this.$message.warning(this.$t("canvas.testPlan.message.selectWarn"));
        return false;
      }
      const execute_data = {
        testResult: this.test_result,
      };
      execute_test_node(
        this.get_pid(),
        document
          .getElementById("global_variable_select_testplan")
          .innerText.replace(/^\s*|\s*$/g, ""),
        this.node_key,
        execute_data
      );
    },
    submit() {
      this.$emit("getTreeData", this.node_key);
    },
    select_nodes() {
      let s_n = JSON.parse(
        document
          .getElementById("global_variable_select_testplan_nodes")
          .innerText.replace(/^\s*|\s*$/g, "")
      );
      for (let i of s_n) {
        if (i == this.node_key) {
          return true;
        }
      }
      return false;
    },
    execute_test_status() {
      const execute_data = {
        status: this.test_status,
      };
      execute_test_node(
        this.get_pid(),
        document
          .getElementById("global_variable_select_testplan")
          .innerText.replace(/^\s*|\s*$/g, ""),
        this.node_key,
        execute_data
      ).then(async (res) => {
        get_test_nodes(
          this.get_pid(),
          document
            .getElementById("global_variable_select_testplan")
            .innerText.replace(/^\s*|\s*$/g, "")
        ).then((res) => {
          const target_node = document.querySelector(
            `.nodeid-${this.popover_content.id}`
          );
          this.$vmson.$emit("operation", "***");
          let fflag = true;
          let testStatus = JSON.parse(
            sessionStorage.getItem("_execution_status")
          );
          for (let i of res) {
            if (!testStatus[i.testCaseStatus].complete && fflag) {
              fflag = false;
              enter_process(
                this.get_pid(),
                document
                  .getElementById("global_variable_select_testplan")
                  .innerText.replace(/^\s*|\s*$/g, "")
              ).then((res) => {
                this.$store.dispatch("setTestPlan", this.get_pid());
                this.$vmson.$emit("operation", "***");
              });
            } else if (
              testStatus[i.testCaseStatus].complete &&
              i.nodeKey === this.node_key
            ) {
              target_node.style.border = "4px solid #67C23A";
              target_node.style.boxSizing = "content-box";
            } else if (
              !testStatus[i.testCaseStatus].complete &&
              i.nodeKey === this.node_key
            ) {
              target_node.style.border = "4px solid rgba(255, 87, 51, 1)";
              target_node.style.boxSizing = "content-box";
            }
          }
        });
        if (this.test_status === "FAILED") {
          const res1 = await get_filetype(this.get_pid());
          const typeList = res1.filter((item) => {
            return item.bug;
          });
          const self = this;
          const notify = this.$notify({
            title: this.$t("canvas.testPlan.message.executionFailed"),
            dangerouslyUseHTMLString: true,
            type: "warning",
            position: "top-right",
            customClass: "notify-creat",
            duration: 3000,
            message: `<span>${this.$t(
              "canvas.testPlan.message.click"
            )}<span style="color: blue; cursor: pointer">${this.$t(
              "canvas.testPlan.message.automaticallyCreat"
            )}</span></span>`,
            onClick: async function () {
              notify.close();
              if (typeList.length) {
                let res = await createNewFile(
                  //创建文件
                  self.get_pid(),
                  self.test_result && self.test_result !== " "
                    ? self.test_result
                    : self.node_info.topic + self.$t("canvas.testPlan.notWork"),
                  typeList[0].fileTypeId,
                  self.node_key,
                  document
                    .getElementById("global_variable_select_testplan")
                    .innerText.replace(/^\s*|\s*$/g, "")
                );
                self.$message({
                  message: self.$t("homeTopBar.newFileForm.message"),
                  type: "success",
                  duration: 5 * 1000,
                });
              }
            },
          });
        }
      });
    },
    async initial() {
      await this.getData();
      await this.get_status();
    },
    intersection(arr1, arr2) {
      const set = new Set(arr1);
      const result = arr2.filter((item) => set.has(item));
      return Array.from(new Set(result));
    },
    setGroupandUser(res) {
      let objRe = res;
      let list = [];
      const group = Object.values(this.$store.getters.user_group_list);
      const user = Object.values(this.$store.getters.project_user_list);
      const filteredArray = user.filter((obj) => obj.deleted == 0);
      filteredArray.map((item, index) => {
        list.push(item.accountId);
      });
      objRe.content.map((item, index) => {
        if (item.fieldType == "MULTI_USER_SELECT") {
          if (item.value) {
            let changeList = this.intersection(item.value, list);
            changeList.forEach((flag, num) => {
              changeList[num] = flag + "0";
            });
            item.value = changeList;
          }
        }
      });
      return objRe;
    },
    async getData() {
      await get_node_detail(this.get_pid(), this.nodeUuid).then((res) => {
        this.is_lock = res.lock;
        this.popover_content = this.setGroupandUser(res);
        this.current_statusId = res.status;
        this.$emit("set_nodekey", res.key);
        this.$vmson.$emit("layerNumber", res.layerNumber);
        this.node_info = this.popover_content;
      });
      this.$emit("loadingEvent", true);
    },
    async get_status() {
      let params = {
        projectId: this.get_pid(),
        fileTypeId: this.file_type_id,
        layer: this.layer,
        currentStatusId: this.current_statusId,
      };
      await get_workflow_list({
        ...params,
      }).then((res) => {
        this.status_lsit = res.filter((item) => {
          return item.name != this.popover_content.statusName;
        });
      });
    },
    get_user_list() {
      get_allProjectMember(this.get_pid()).then((res) => {
        let my = {};
        let li = [];
        res.forEach((ele) => {
          let obj = {
            label: ele.nickname,
            value: ele.accountId,
            userAccountId: ele.accountId,
            avatar: ele.avatar,
            deleted: ele.deleted,
          };
          ele.accountId == this.$store.getters.userAccountId
            ? (my = obj)
            : li.push(obj);
        });
        const all_user_list = Object.values(this.all_user_list);
        this.user_list = [];
        this.user_list.push(my);
        this.user_list.push(...li);
        this.user_list = this.user_list.concat(
          ...all_user_list.filter((x) => {
            (x.label = x.nickname),
              (x.value = x.accountId),
              (x.userAccountId = x.accountId),
              (x.hide = true);
            return this.user_list.every(
              (y) => y.userAccountId !== x.userAccountId
            );
          })
        );
        this.filterUserList = JSON.parse(JSON.stringify(this.user_list));
        this.user_loading = false;
      });
    },
    setMultiUser(val, isCustom, key) {
      this.mergedUsergroup(val, this.get_pid()).then((res) => {
        this.set_property_value(res, isCustom, key);
      });
    },
    set_user(val) {
      this.singleUserVisibleChange();
      if (val == "chang_assignee") {
        return false;
      }
      if (!val) {
        this.set_property_value();
      } else {
        this.popover_content.oldAssignee = this.popover_content.assignee || "";
      }
    },
    get_Label_data(val) {
      if (val) {
        get_Labels(this.get_pid())
          .then((res) => {
            this.label_loading = false;
            this.project_labels = res;
          })
          .catch((err) => console.log(err));
      }
    },
    show_workflow() {
      if (!this.showWorkflow) {
        this.get_status();
      }
      this.showWorkflow = !this.showWorkflow;
    },
    changeWorkflow(val, category, name) {
      this.showWorkflow = false;
      this.status_changing = true;
      let params = {
        nodeId: this.header.nodeId,
        nodeUuid: this.header.nodeUuid,
        targetStatus: val,
      };
      set_workflow_myself(this.get_pid(), params).then(
        (res) => {
          if (res.length != 0) {
            // 此时有验证条件未通过
            this.$nextTick(() => {
              this.$emit("change_text", res);
            });
          } else {
            // 验证条件通过
            set_workflow(this.get_pid(), params)
              .then(() => {
                this.current_statusId = val;
                this.cur_status = name;
                this.cur_status_type = category;
                this.status_changing = false;
                // 更新exist_mindmap
                const now_mind = this.$store.getters.exist_mindmap;
                const target_node = now_mind.data.find((item) => {
                  return item.nodeUuid == params.nodeUuid;
                });
                Vue.set(target_node, "status", val);
                Vue.set(target_node, "statusCategory", category);
                Vue.set(target_node, "statusName", name);
                this.$store.commit("SET_EXIST_MINDMAP", now_mind);
                vmson.$emit("jmshow", "***");
                this.$emit("change_single_node_status", {
                  node_id: this.header.nodeId,
                  status_id: val,
                  status_name: name,
                });
              })
              .catch((err) => {
                console.log(err);
                this.status_changing = false;
              });
          }
        },
        (error) => {
          // Message({
          //   message: "校验器未通过，请检查工作流验证条件",
          //   type: "error",
          //   duration: 5 * 1000,
          // });
        }
      );
    },
    showDialog() {
      this.$emit("showRelationDialog", this.node_key);
    },
    async mergedUsergroup(arr, projectId) {
      let account = [];
      let group = [];
      arr &&
        arr.map((item, index) => {
          if (item.substr(-1) == 0) {
            account.push(item.substr(0, item.length - 1));
          } else {
            group.push(item.substr(0, item.length - 1));
          }
        });
      const params = {
        projectId: projectId,
        accountIdList: account,
        groupIdList: group,
      };
      return await get_group_user_list(params);
    },
    async set_property_value(val, isCustom, key) {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.submit_form.key,
        data: {
          isCustom: isCustom,
          fieldId: key,
          value: val,
        },
      };
      await only_set_property(params);
      await this.updateFileAndMindmap();
      const now_mind = this.$store.getters.exist_mindmap;
      const target_node = now_mind.data.find((item) => {
        return item.nodeUuid == this.submit_form.nodeUuid;
      });
      Vue.set(target_node, "content", this.submit_form.content);
      if (key === "bug_test_plan" && val) {
        get_test_nodes(this.get_pid(), this.popover_content.testPlanId).then(
          (res) => {
            this.test_case_list = res;
            this.$set(this.popover_content, "testCase", []);
          }
        );
      } else if (key === "bug_test_plan" && !val) {
        this.$set(this.popover_content, "testCase", []);
        this.test_case_list = [];
      }
    },
    async updateFileAndMindmap() {
      const res = await getFile(this.get_pid(), this.$route.params.file_key);
      this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
      vmson.$emit("jmshow", res);
    },
    keepPopover() {
      this.is_edit = true;
    },
    hidePopover() {
      this.is_edit = false;
    },
    hidePopover_input_gray_icon() {
      this.hidePopover();
    },
    hidePopover_input(val, isCustom, key) {
      this.hidePopover();
      this.set_property_value(val, isCustom, key);
    },
    hidePopover_select(val, flag) {
      if (val) {
        this.keepPopover();
      } else {
        this.hidePopover();
      }
    },
    toNodeDetailPage() {
      const routeData = this.$router.resolve({
        name: "node_detail",
        params: {
          projectId: this.get_pid(),
          nodeKey: this.node_key,
        },
      });
      window.open(routeData.href, "_blank");
    },
    view_workflow() {
      this.$parent.workflow_dialog_visible = true;
    },
    show_name_tip(refName) {
      const tag = this.$refs[refName][0];
      const parentWidth = tag.parentNode.offsetWidth; // 获取元素父级可视宽度
      const contentWidth = tag.offsetWidth; // 获取元素可视宽度
      this.show_name = contentWidth < parentWidth;
    },
    show_name_tip2(refName) {
      const tag = this.$refs[refName];
      const parentWidth = tag.parentNode.offsetWidth; // 获取元素父级可视宽度
      const contentWidth = tag.offsetWidth; // 获取元素可视宽度
      this.show_name = contentWidth < parentWidth;
    },
    show_name_tip3(refName) {
      const tag = this.$refs[refName];
      const parentWidth = tag.parentNode.offsetWidth; // 获取元素父级可视宽度
      const contentWidth = tag.offsetWidth; // 获取元素可视宽度
      this.show_name = contentWidth + 10 < parentWidth;
    },
    /*移入 */
    enter_popover() {
      clearTimeout(this.leave_pop);
      this.$parent.isEnterPopover = true;
      clearTimeout(this.$parent.mouse_out_timer_hide_popover);
      this.$parent.mouse_out_timer_hide_popover = null;
    },
    /*移出 */
    leave_popover() {
      setTimeout(() => {
        if (!this.$refs["creatNewFile"].createProgramVisible) {
          this.showWorkflow = false;
          this.$parent.isEnterPopover = false;
          if (this.is_edit == false) {
            this.$parent.popover_visible = false;
          }
        }
      });
    },
    get_testplan_list() {
      let li = Object.values(JSON.parse(sessionStorage.getItem("_testPlan")));
      this.test_plan_list = [];
      li.forEach((item) => {
        if (item.status == "NOT_STARTED") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.notStarted") +
            ")";
          this.test_plan_list.push(item);
        } else if (item.status == "IN_PROGRESS") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.inProgress") +
            ")";
          this.test_plan_list.push(item);
        } else if (item.status == "COMPLETE") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.completed") +
            ")";
          this.test_plan_list.push(item);
        }
      });
    },
    get_plan_status(status) {
      switch (status) {
        case "COMPLETE":
          return this.$t("canvas.testPlan.status.completed");
        case "IN_PROGRESS":
          return this.$t("canvas.testPlan.status.inProgress");
        case "NOT_STARTED":
          return this.$t("canvas.testPlan.status.notStarted");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nodePopover {
  z-index: 999; // 画布是10,topbar组件是99,关联弹窗遮>=2012
  position: absolute;
  min-width: 480px;
  transition: left 0.4s cubic-bezier(0.23, 1, 0.32, 1),
    top 0.4s cubic-bezier(0.23, 1, 0.32, 1);

  background: rgba(153 194 242 / 70%);
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur(15.5px);
  -webkit-backdrop-filter: blur(15.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  color: #000000;
  font-size: 14px;
  padding-bottom: 30px;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid rgba(166, 166, 166, 0.47);
    padding: 0 20px 0 4px;

    & > * {
      margin-right: 10px;
      margin-left: 10px;
    }

    .node_key {
      color: rgba(235, 166, 5, 1);
      font-weight: 700;
    }

    .author {
      color: rgba(48, 100, 143, 1);
      width: 138px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .workflow {
      position: relative;
      width: 120px;
      .el-button {
        width: 100%;
        height: 27px;
        font-weight: 800;
        /*no*/
        // background: rgba(48, 100, 143, 1);
        font-size: 12px;
        color: #fff;
        padding: 3px 7px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .status_todo {
        background-color: rgba(166, 166, 166, 1);
        border: 1px solid rgba(166, 166, 166, 1);
        color: white;
      }
      .status_open {
        color: white;
        background-color: rgba(67, 207, 124, 1);
        border: 1px solid rgba(67, 207, 124, 1);
      }
      .status_progress {
        color: white;
        background-color: rgba(42, 130, 228, 1);
        border: 1px solid rgba(42, 130, 228, 1);
      }

      .workflow_select {
        max-height: 200px;
        overflow-y: scroll;
        z-index: 12;
        width: 120px;
        background: #fff;
        position: absolute;
        padding: 10px 10px;
        margin-top: 6px;
        border-radius: 6px;
        right: 0;
        text-align: left;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        border: 1px solid #e4e7ed;

        .workflow_item {
          height: 32px;
          align-items: center;
          width: 100%;
          span {
            width: 100%;
            height: 100%;
            line-height: 32px;
            cursor: pointer;
          }

          &:hover {
            background: rgba(9, 30, 66, 0.06);
          }

          .view_workflow {
            border-top: 1px solid rgba(128, 128, 128, 1);
          }
        }

        .workflow_none {
          height: 32px;
          line-height: 32px;
          padding: 0px 12px;
          flex: 1;
        }
      }
    }

    .relation {
      font-size: 12px;
      /*no*/
      cursor: pointer;
    }
  }

  .body {
    height: 300px;

    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
      margin-right: -35px !important;

      .el-scrollbar__view {
        .el-form {
          .el-form-item {
            margin-top: 16px;
            margin-bottom: 0px;

            .el-form-item__label {
              font-size: 16px;
              padding: 0 5px 0 20px;

              .label_overflow {
                display: flex;

                span {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }

              .red_star {
                position: relative;
                padding-right: 8px;

                &::after {
                  content: "*";
                  color: red;
                  position: absolute;
                  right: 0px;
                }
              }
            }

            .el-form-item__content {
              width: 300px;

              line-height: 46px;
              text-align: left;

              .el-input {
                width: 300px;

                .el-input__inner {
                  min-height: 34px;
                }
              }

              .el-select {
                .el-input__suffix {
                  height: auto;
                }
              }

              .el-textarea {
                width: 300px;
                .el-textarea__inner {
                  height: 40px !important;
                  /*no*/
                }
              }
            }
          }
        }
      }
    }
    ::v-deep .el-select {
      .tags-select-input {
        height: 40px;
        .el-select__tags {
          height: 40px;
          white-space: nowrap;
          overflow: hidden;
          flex-wrap: wrap;
        }
      }

      .el-select__tags-text {
        display: inline-block;
        max-width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-tag__close.el-icon-close {
        top: -7px;
      }
    }
  }
}
.copy_zdh_key {
  padding-left: 5px;
  color: #004caa;
  cursor: pointer;
}
.v-modal {
  z-index: 13;
}

.user_select {
  .el-select-dropdown__item {
    display: flex;
    align-items: center;

    .avatar {
      display: flex;
    }

    .select_item {
      margin-left: 14px;
    }
  }
}

.file_test {
  display: flex;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;

  p {
    color: #606266;
    padding-left: 20px;
    padding-right: 45px;
  }

  .file_test_e {
    width: 300px;
    display: flex;
    align-items: center;

    .select {
      width: 80%;

      input {
        height: 50px;
      }
    }

    .icon {
      margin-left: 15px;
      width: 30px;
      height: 30px;
    }
  }
}
</style>

<style lang="scss">
.gray_icon {
  width: 300px;

  input {
    font-size: 12px;
  }

  .el-input--prefix {
    .el-input__prefix {
      color: orange !important;
    }
  }
}
</style>
