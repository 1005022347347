<template>
  <div class="create-file-box">
    <div class="head-create-file">
      <div v-limits-of-authority="'NODE_CREATE'">
        <div @click="handleNewMindMap">
          <slot></slot>
        </div>
      </div>
    </div>
    <el-dialog
      id="zdh_guide_tip1_dig1"
      :visible.sync="createProgramVisible"
      width="30%"
      center
      class="createpro"
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
      top="3vh"
      :before-close="close_create_handle"
    >
      <div class="createpro-header">
        <el-tabs v-model="activeName">
          <el-tab-pane
            :label="
              istop
                ? $t('homeTopBar.newFileForm.tab1')
                : $t('homeTopBar.newFileForm.tab3')
            "
            name="first"
          >
            <el-form ref="newFileForm">
              <el-form-item>
                <el-input
                  v-model="form_name"
                  autocomplete="off"
                  :placeholder="$t('homeTopBar.newFileForm.name')"
                >
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="fileTypeId"
                  :placeholder="$t('homeTopBar.newFileForm.type')"
                  filterable
                  class="createpro-select"
                  popper-class="zdh_guide_class_tip1"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="(option, index) in form_options"
                    :key="index"
                    :label="option.name"
                    :value="option.fileTypeId"
                    >{{ option.name }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="false">
                <el-select
                  v-model="test_plan"
                  :placeholder="$t('homeTopBar.newFileForm.plan')"
                  class="createpro-select"
                  clearable
                  filterable
                  @change="change_testcase"
                >
                  <el-option
                    v-for="opt in test_plan_list"
                    :key="opt.testPlanId"
                    :label="`${opt.name} (${get_plan_status(opt.status)})`"
                    :value="opt.testPlanId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="false">
                <el-select
                  v-model="test_case"
                  :placeholder="$t('homeTopBar.newFileForm.case')"
                  class="createpro-select"
                  :disabled="test_plan == ''"
                  filterable
                  multiple
                  clearable
                >
                  <el-option
                    v-for="(option, index) in test_case_list"
                    :key="index"
                    :label="`${option.nodeKey} ${option.topic}`"
                    :value="option.nodeKey"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <p id="zdh_guide_tip3_btn2" style="text-align: left">
                <el-input
                  v-model="selectTemplate.fileTemplateName"
                  type="text"
                  maxlength="6"
                  :disabled="true"
                  :placeholder="$t('homeTopBar.newFileForm.templatePlace')"
                  style="width: calc(100% - 60px); height: 40px"
                  class="getCode-input"
                />
                <el-button
                  icon="iconfont icon-a-4"
                  style="width: 50px; height: 40px; float: right"
                  @click="showTemplate"
                ></el-button>
              </p>
            </el-form>
          </el-tab-pane>
          <el-tab-pane
            v-if="istop"
            :label="$t('homeTopBar.newFileForm.tab2')"
            name="second"
          >
            <el-form ref="newFileForm">
              <el-form-item>
                <el-input
                  v-model="m_file_name"
                  autocomplete="off"
                  :placeholder="$t('homeTopBar.newFileForm.name')"
                >
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="m_file_type_id"
                  :placeholder="$t('homeTopBar.newFileForm.type')"
                  class="createpro-select"
                  filterable
                >
                  <el-option
                    v-for="(option, index) in form_options"
                    :key="index"
                    :label="option.name"
                    :value="option.fileTypeId"
                  >
                    {{ option.name }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-tab-pane>

          <el-tab-pane v-if="istop" name="four">
            <span slot="label"
              >{{ $t("homeTopBar.newFileForm.tab1") }}
              <span
                style="
                  color: #1675e1;
                  background: #c7daee;
                  border: 1px solid #1675e1;
                  border-radius: 5px;
                  padding: 2px 6px;
                "
                >AI</span
              >
            </span>

            <div
              v-show="
                ai_create_file_step == 'yi' || ai_create_file_step == 'er'
              "
              style="
                border-radius: 4px;
                border: 1px solid #dcdfe6;
                padding: 5px 15px;
                margin-top: 10px;
                text-align: center;
                color: #383838;
              "
            >
              {{ $t("AI.tip40") }}
              <br />
              {{ $t("AI.tip41") }}
            </div>
            <el-form
              v-show="
                ai_create_file_step == 'yi' || ai_create_file_step == 'er'
              "
              ref="newFileForm"
            >
              <el-form-item>
                <el-select
                  v-model="m_file_name_old"
                  style="width: 100%"
                  filterable
                  remote
                  reserve-keyword
                  :placeholder="$t('AI.tip42')"
                  :remote-method="search_ai_file_remoteMethod"
                  :loading="search_ai_file_loading"
                >
                  <!-- <div v-for="item in search_ai_file_options" :key="item.id">
<select-icon :item="item" :file="true">
                        </select-icon>
                </div> -->
                  <!-- <el-option
                      v-for="item in options"
                      :key="item.nodeKey"
                      :label="`${item.nodeKey}: ${cut(item.topic)}`"
                      :value="item.nodeKey"
                    >
                      <select-icon :item="item"> </select-icon>
                    </el-option> -->
                  <el-option
                    v-for="item in search_ai_file_options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id + '@' + item.fileTypeId"
                  >
                    <select-icon
                      :item="item"
                      :file="true"
                      :zdhcreate="true"
                    ></select-icon>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="m_file_name"
                  autocomplete="off"
                  :placeholder="$t('homeTopBar.newFileForm.name')"
                >
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="m_file_type_id"
                  :placeholder="$t('homeTopBar.newFileForm.type')"
                  class="createpro-select"
                  filterable
                >
                  <el-option
                    v-for="(option, index) in form_options"
                    :key="index"
                    :label="option.name"
                    :value="option.fileTypeId"
                  >
                    {{ option.name }}
                  </el-option>
                </el-select>
              </el-form-item>
              <div
                v-show="ai_create_file_step == 'yi'"
                class="dialog-footer"
                style="margin-bottom: 20px"
              >
                <el-button type="info" @click="createMultiplyProgram_cancel"
                  >{{ $t("btn.cancelBtn") }}
                </el-button>
                <el-button
                  type="primary"
                  size="small"
                  class="button-confirm"
                  @click="ai_create_file_step1"
                  >{{ $t("btn.newBtn") }}
                </el-button>
              </div>
            </el-form>

            <div class="dialog-footer">
              <el-button
                v-show="ai_create_file_step == 'er'"
                type="primary"
                size="small"
                class="button-confirm"
                :disabled="ad_zdh_robotTyping"
                @click="ai_create_file_step1"
                >{{ $t("AI.tip43") }}
              </el-button>
            </div>

            <div
              v-show="ai_create_file_step == 'er'"
              id="markdown_zdh_ai_create_file_1"
              :class="ad_zdh_robotTyping ? 'ai_res_blink' : ''"
              style="
                border-radius: 4px;
                border: 1px solid #dcdfe6;
                padding: 5px 15px;
                margin: 20px 0;
                text-align: center;
                color: #383838;
                height: 250px;
                overflow: auto;
                text-align: left;
              "
              v-html="ai_zdh_res"
            ></div>

            <div
              v-show="ai_create_file_step == 'er'"
              class="dialog-footer"
              style="margin: 20px 0"
            >
              <el-button
                type="info"
                :disabled="ad_zdh_robotTyping"
                @click="ai_create_file_bumanyi"
                >{{ $t("AI.tip44") }}
              </el-button>
              <el-button
                type="primary"
                size="small"
                class="button-confirm"
                :disabled="ad_zdh_robotTyping"
                @click="ai_create_file_manyi"
                >{{ $t("AI.tip45") }}
              </el-button>
            </div>

            <div
              v-show="ai_create_file_step == 'san'"
              id="markdown_zdh_ai_create_file"
              style="
                border-radius: 4px;
                border: 1px solid #dcdfe6;
                padding: 5px 15px;
                margin: 20px 0;
                text-align: center;
                color: #383838;
                height: 250px;
                overflow: auto;
                text-align: left;
              "
              v-html="ai_zdh_res_shang"
            ></div>

            <div v-show="ai_create_file_step == 'san'">
              <div v-if="languageType != 'en-US'">
                <div>
                  {{ $t("knowledge.knowledge_ai.bumanyi_form1") }}
                  <el-input
                    v-model="bumanyi_form.a"
                    size="mini"
                    :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
                    style="
                      width: 150px;
                      margin-left: 5px;
                      margin-right: 5px;
                      margin-bottom: 15px;
                    "
                  ></el-input>
                </div>
                <div>
                  {{ $t("knowledge.knowledge_ai.bumanyi_form2")
                  }}<el-input
                    v-model="bumanyi_form.b"
                    :placeholder="$t('knowledge.knowledge_ai.placeholder5')"
                    size="mini"
                    style="
                      width: 200px;
                      margin-left: 5px;
                      margin-right: 5px;
                      margin-bottom: 15px;
                    "
                  ></el-input>
                </div>
                <div>
                  {{ $t("knowledge.knowledge_ai.bumanyi_form3")
                  }}<el-input
                    v-model="bumanyi_form.c"
                    size="mini"
                    :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
                    style="
                      width: 200px;
                      margin-left: 5px;
                      margin-right: 5px;
                      margin-bottom: 15px;
                    "
                  ></el-input
                  >{{ $t("knowledge.knowledge_ai.bumanyi_form4") }}
                </div>
                <div>
                  {{ $t("knowledge.knowledge_ai.bumanyi_form5") }}
                  <el-input
                    v-model="bumanyi_form.d"
                    size="mini"
                    :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
                    style="
                      width: 200px;
                      margin-left: 5px;
                      margin-right: 5px;
                      margin-bottom: 15px;
                    "
                  ></el-input>
                </div>
              </div>
              <div v-else>
                <div>
                  {{ $t("knowledge.knowledge_ai.bumanyi_form1") }}
                  <el-input
                    v-model="bumanyi_form.a"
                    size="mini"
                    :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
                    style="
                      width: 150px;
                      margin-left: 5px;
                      margin-right: 5px;
                      margin-bottom: 15px;
                    "
                  ></el-input>
                </div>
                <div>
                  I hope to

                  <el-input
                    v-model="bumanyi_form.b"
                    :placeholder="$t('knowledge.knowledge_ai.placeholder5')"
                    size="mini"
                    style="
                      width: 200px;
                      margin-left: 5px;
                      margin-right: 5px;
                      margin-bottom: 15px;
                    "
                  ></el-input>
                  the above content
                </div>
                <div>
                  To achieve the goal of

                  <el-input
                    v-model="bumanyi_form.c"
                    size="mini"
                    :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
                    style="
                      width: 200px;
                      margin-left: 5px;
                      margin-right: 5px;
                      margin-bottom: 15px;
                    "
                  ></el-input>
                </div>
                <div>
                  {{ $t("knowledge.knowledge_ai.bumanyi_form5") }}
                  <el-input
                    v-model="bumanyi_form.d"
                    size="mini"
                    :placeholder="$t('knowledge.knowledge_ai.placeholder4')"
                    style="
                      width: 200px;
                      margin-left: 5px;
                      margin-right: 5px;
                      margin-bottom: 15px;
                    "
                  ></el-input>
                </div>
              </div>
            </div>

            <div class="dialog-footer">
              <el-button
                v-show="ai_create_file_step == 'san'"
                type="primary"
                size="small"
                class="button-confirm"
                style="margin: 20px 0"
                :disabled="ad_zdh_robotTyping"
                @click="bumanyi_youhua"
                >{{ $t("AI.tip43") }}
              </el-button>
            </div>

            <div
              v-show="ai_create_file_step == 'si'"
              id="markdown_zdh_ai_create_file"
              style="
                border-radius: 4px;
                border: 1px solid #dcdfe6;
                padding: 5px 15px;
                margin: 20px 0;
                text-align: center;
                color: #383838;
                height: 250px;
                overflow: auto;
                text-align: left;
              "
              v-html="ai_zdh_res_shang"
            ></div>

            <div
              v-show="ai_create_file_step == 'si'"
              id="markdown_zdh_ai_create_file_2"
              :class="ad_zdh_robotTyping ? 'ai_res_blink' : ''"
              style="
                border-radius: 4px;
                border: 1px solid #dcdfe6;
                padding: 5px 15px;
                margin: 20px 0;
                text-align: center;
                color: #383838;
                height: 250px;
                overflow: auto;
                text-align: left;
              "
              v-html="ai_zdh_res"
            ></div>

            <div
              v-show="ai_create_file_step == 'si'"
              class="dialog-footer"
              style="margin: 20px 0"
            >
              <el-button
                type="info"
                :disabled="ad_zdh_robotTyping"
                @click="ai_create_file_bumanyi"
                >{{ $t("AI.tip44") }}
              </el-button>
              <el-button
                type="primary"
                size="small"
                class="button-confirm"
                :disabled="ad_zdh_robotTyping"
                @click="ai_create_file_manyi"
                >{{ $t("AI.tip45") }}
              </el-button>
            </div>
          </el-tab-pane>

          <el-tab-pane
            v-if="
              istop &&
              ($store.state.project.tenantId ===
                '586ee243-5501-4712-bf0b-0e36b5ea2ff6' ||
                $store.state.project.tenantId ===
                  '7db3b28d-c80f-4fbe-895e-6209c6c4362b')
            "
            label="MappingGPT"
            name="third"
          >
            <el-form
              ref="newGPTFileForm"
              style="padding-left: 10px"
              :model="mappingGptForm"
              :rules="mappingGptRules"
              :hide-required-asterisk="false"
              label-position="left"
              label-width="auto"
              class="gptform"
            >
              <el-form-item prop="idea" class="showStar">
                <template slot="label">
                  <span>{{
                    $t("homeTopBar.newFileForm.mgpt.requirement")
                  }}</span>
                </template>
                <el-input v-model="mappingGptForm.idea"> </el-input>
              </el-form-item>
              <el-form-item prop="repoUrl" class="showStar">
                <template slot="label">
                  <span
                    >{{ $t("homeTopBar.newFileForm.mgpt.repositoryUrl") }}
                    <!-- 提示icon -->
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">
                        {{ $t("homeTopBar.newFileForm.mgpt.tip1") }}
                      </div>
                      <i
                        id="dddd8"
                        class="iconfont icon-help"
                        style="font-size: 16px"
                      ></i> </el-tooltip
                  ></span>
                </template>
                <el-input v-model="mappingGptForm.repoUrl"> </el-input>
              </el-form-item>
              <el-form-item
                v-show="mappingGptForm.repoUrl"
                prop="gitType"
                class="showStar"
              >
                <template slot="label">
                  <span>{{
                    $t("homeTopBar.newFileForm.mgpt.repositoryType")
                  }}</span>
                </template>
                <el-select
                  v-model="mappingGptForm.gitType"
                  :disabled="!mappingGptForm.repoUrl"
                >
                  <el-option label="Gitlab" value="GITLAB"></el-option>
                  <el-option label="Github" value="GITHUB"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-show="mappingGptForm.repoUrl"
                prop="gitAccessToken"
                class="showStar"
              >
                <template slot="label">
                  <span
                    >Token
                    <!-- 提示icon -->
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">
                        {{ $t("homeTopBar.newFileForm.mgpt.tip2") }}
                      </div>
                      <i
                        id="dddd8"
                        class="iconfont icon-help"
                        style="font-size: 16px"
                      ></i>
                    </el-tooltip>
                  </span>
                </template>
                <el-input
                  v-model="mappingGptForm.gitAccessToken"
                  :disabled="!mappingGptForm.repoUrl"
                >
                </el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div v-if="activeName == 'first'" slot="footer" class="dialog-footer">
        <el-button type="info" @click="createProgram_cancel"
          >{{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button
          type="primary"
          size="small"
          class="button-confirm"
          @click="createProgram_comfirm"
          >{{ $t("btn.newBtn") }}
        </el-button>
      </div>
      <div v-if="activeName == 'second'" slot="footer" class="dialog-footer">
        <el-button type="info" @click="createMultiplyProgram_cancel"
          >{{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button
          type="primary"
          size="small"
          class="button-confirm"
          @click="createMultiplyProgram_comfirm"
          >{{ $t("btn.newBtn") }}
        </el-button>
      </div>
      <div v-if="activeName == 'third'" slot="footer" class="dialog-footer">
        <el-button type="info" @click="createGPTProgram_cancel"
          >{{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button
          type="primary"
          size="small"
          class="button-confirm"
          @click="createGPTProgram_comfirm"
          >{{ $t("btn.newBtn") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 上传excel弹窗 -->
    <el-dialog
      :visible.sync="uplodaVisible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      center
      class="upexcel"
    >
      <div v-loading="isLoading">
        <div>
          <el-select
            v-model="importType"
            size="mini"
            style="margin-bottom: 10px"
          >
            <el-option
              v-for="item in importTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <p
          v-if="importType === 'excel'"
          style="font-weight: bold; color: #30648f"
        >
          {{ $t("homeTopBar.newFileForm.excel.p5") }}
        </p>
        <p v-if="importType === 'excel'">
          {{ $t("homeTopBar.newFileForm.excel.p1") }}
        </p>
        <p v-if="importType === 'excel'">
          {{ $t("homeTopBar.newFileForm.excel.p2") }}
        </p>
        <p v-if="importType === 'excel'">
          {{ $t("homeTopBar.newFileForm.excel.p6") }}
        </p>
        <p
          v-if="importType === 'word'"
          style="font-weight: bold; color: #30648f"
        >
          {{ $t("homeTopBar.newFileForm.excel.p7") }}
        </p>
        <p v-if="importType === 'word'">
          {{ $t("homeTopBar.newFileForm.excel.p8") }}
        </p>
        <p v-if="importType === 'word'">
          {{ $t("homeTopBar.newFileForm.excel.p9") }}
        </p>
        <p
          v-if="importType === 'xmind'"
          style="font-weight: bold; color: #30648f"
        >
          {{ $t("homeTopBar.newFileForm.excel.p11") }}
        </p>
        <p v-if="importType === 'xmind'">
          {{ $t("homeTopBar.newFileForm.excel.p12") }}
        </p>
        <p
          v-if="importType === 'freemind'"
          style="font-weight: bold; color: #30648f"
        >
          {{ $t("homeTopBar.newFileForm.excel.p13") }}
        </p>
        <p v-if="importType === 'freemind'">
          {{ $t("homeTopBar.newFileForm.excel.p14") }}
        </p>
        <br />
        <p>
          <span v-if="importType === 'excel' || importType === 'word'">{{
            $t("homeTopBar.newFileForm.excel.p3")
          }}</span>
          <a v-if="importType === 'excel'" :href="$t('excelTemplate')">
            {{ $t("homeTopBar.newFileForm.excel.p4") }}
          </a>
          &nbsp; &nbsp; &nbsp;
          <a v-if="importType === 'word'" :href="$t('wordTemplate')">
            {{ $t("homeTopBar.newFileForm.excel.p10") }}
          </a>
        </p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="info"
          style="margin-right: 10px"
          @click="createExcel_cancel"
          >{{ $t("btn.cancelBtn") }}
        </el-button>

        <el-upload
          class="upload-demo"
          action="#"
          accept=".xlsx, .docx, .xmind, .mm"
          :http-request="upload_attachment_handler"
          :show-file-list="false"
          :before-upload="judge_howBig"
        >
          <el-button size="small" type="primary"
            >{{ $t("btn.importBtn") }}
          </el-button>
        </el-upload>
      </div>
    </el-dialog>

    <!-- 上传excel键值对弹窗 -->
    <el-dialog
      v-loading="isLoading1"
      :visible.sync="excelVisible"
      width="25%"
      center
      class="upexcel"
      :show-close="true"
      :close-on-click-modal="false"
    >
      <div slot="title" style="display: flex; align-items: center">
        <span>{{ $t("homeTopBar.newFileForm.excel.excelField") }}</span>
        <i
          class="iconfont icon-a-left_arrow11"
          style="justify-self: center; margin: 0 10px"
        />
        <span>{{ $t("homeTopBar.newFileForm.excel.sysField") }}</span>
      </div>
      <div>
        <div
          class=""
          style="max-height: 60vh; overflow: hidden scroll; margin-bottom: 20px"
        >
          <div v-for="(i, index) in headList" :key="i" class="grid-excel">
            <div class="" style="line-height: 16px">{{ i }}</div>
            <div class="">
              <el-select
                v-model="f_data[index]"
                :placeholder="$t('placeholder.dropdown')"
                style="width: 100%"
                filterable
                clearable
              >
                <el-option
                  v-for="item in fields"
                  :key="item.fieldId"
                  :label="item.name"
                  :value="[item.name, item]"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="info"
          style="margin-right: 5px"
          @click="createExcelKey_cancel"
          >{{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button
          type="primary"
          class="button-confirm"
          @click="createExcelKey_comfirm"
          >{{ $t("btn.newBtn") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 模板库 -->
    <templateLibrary
      ref="templateLibrary"
      @usetemplate="usetemplate"
    ></templateLibrary>
  </div>
</template>

<script>
import selectIcon from "@/components/selectIcon";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { aiRobotCreate, aiRobotPPayFeatureStatus } from "@/network/ai/index.js";
import { ossAuthorization } from "@/utils/oss";
import templateLibrary from "@/views/templateLibrary";
import { ed } from "@/network/guide/index.js";
import {
  createNewFile,
  get_filetype,
  getFile,
  createNewFileMgpt,
  mgptPayFeatureStatus,
} from "@/network/home/index.js";
import { applyTemplate } from "@/network/templateLibrary";
import vmson from "@/utils/vmson";
// const driverObj = require("@/plugins/tourDriver"); // 导入driverObj

import { get_test_nodes } from "@/network/test/index.js";
import {
  createFileWithWord,
  upload_excel,
  upload_excel_key,
  uploadXmind,
  uploadFreemind,
} from "@/network/node/index.js";
import { get_filetype_detail } from "@/network/fileType/index.js";
import { ulid } from "ulid";
import { mapGetters } from "vuex";
import { get_file_List } from "@/network/home/index.js";
import { add_relation_node } from "@/network/node/index.js";
export default {
  components: { templateLibrary, selectIcon },
  props: {
    istop: {
      type: Boolean,
      default: false,
    },
    node_info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      const_fields: [],
      f_data: [],
      fields: [],
      excelRedisUUID: "",
      headList: [],
      search_ai_file_loading: false,
      search_ai_file_options: [],
      ai_zdh_uid: "",
      ai_zdh_res: "",
      ai_zdh_res_shang: "",
      ai_zdh_res_origin: "",
      ad_zdh_robotTyping: false,
      bumanyi_form: {
        a: "",
        b: "",
        c: "",
        d: "",
      },
      m_file_name_old: "",
      activeName: "first",
      uplodaVisible: false,
      excelVisible: false,
      m_file_name: "",
      m_file_type_id: "",
      loading: true,
      createProgramVisible: false, //新建文件弹窗
      form_name: "",
      fileTypeId: "",
      form_options: [],
      // bug类型文件相关
      test_plan: "",
      test_case: "",
      test_case_list: [],
      test_plan_list: [],
      isLocale: sessionStorage.getItem("isLocale") || "false",
      // 无用操作
      nothing: null,
      isLoading: false,
      isLoading1: false,
      ai_create_file_step: "yi",
      // 模板
      selectTemplate: {},
      mappingGptForm: {
        idea: "",
        repoUrl: "",
        gitAccessToken: "",
        gitType: "",
      },
      mappingGptRules: {
        idea: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("homeTopBar.newFileForm.mgpt.mgptM1"),
          },
        ],
        gitType: [
          {
            required: true,
            trigger: "change",
            message: this.$t("homeTopBar.newFileForm.mgpt.mgptM2"),
          },
        ],
        gitAccessToken: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("homeTopBar.newFileForm.mgpt.mgptM3"),
          },
        ],
      },
      importType: "excel",
      importTypeOptions: [
        {
          label: "excel",
          value: "excel",
        },
        {
          label: "word",
          value: "word",
        },
        {
          label: "xmind",
          value: "xmind",
        },
        {
          label: "freemind",
          value: "freemind",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["token", "fileType", "testPlan"]),
    ...mapGetters([
      "execution_status",
      "version",
      "testPlan",
      "sprint",
      "baseLine",
      "theme",
    ]),
    // 新建文件是否为测试用例类型
    is_bug() {
      let obj = this.form_options.find(
        (item) => item.fileTypeId === this.fileTypeId
      );
      return obj ? obj.bug : false;
    },
    languageType() {
      let type = "";
      const lang = localStorage.getItem("lang");
      if (lang === "en") {
        type = "en-US";
      } else if (lang === "zh") {
        type = "zh-CN";
      }
      return type;
    },
  },
  watch: {
    f_data() {
      //监听用户已选择的数据，并把已选择的从列表里删去
      let list = [];
      for (let i of this.f_data) {
        if (i.length > 0) {
          list.push(i[1]);
        }
      }
      let list2 = [];
      for (let i of this.const_fields) {
        let f = true;
        for (let j of this.f_data) {
          if (i["name"] == j[0]) {
            f = false;
          }
        }
        if (f) {
          list2.push(i);
        }
      }
      this.fields = list2;
    },
  },
  mounted() {
  },
  methods: {
    // 新建文件弹窗部分
    async handleNewMindMap() {
      if (this.$route.query.tool_tip == "global_tip_1") {
        document.querySelector(".driver-overlay").style.zIndex = 999;

        this.$nextTick(() => {
          vmson.$emit("global_tip", "1@next");
        });
      }
      if (this.$route.query.tool_tip == "global_tip_3") {
        this.$nextTick(() => {
          setTimeout(() => {
            vmson.$emit("global_tip", "3@next");
          }, 100);
        });
      }
      //打开新建文件弹窗
      this.createProgramVisible = true;
      this.get_testplan_list();
      this.$nextTick(() => {
        this.$refs.newFileForm.resetFields();
        this.fileTypeId = "";
        this.form_name = "";
        this.test_case = "";
        this.test_plan = "";
        this.m_file_name = "";
        this.m_file_type_id = "";
        this.selectTemplate = {};
      });
      try {
        let res = await get_filetype(this.get_pid()); //获取这个项目组的文件类型
        this.form_options = res;
      } catch (error) {
        console.log(error);
      }
    },
    // 展示模板
    showTemplate() {
      this.$refs["templateLibrary"].show();
      this.$nextTick(() => {
        setTimeout(() => {
          vmson.$emit("global_tip", "3@next");
        }, 100);
      });
    },
    usetemplate(item) {
      this.selectTemplate = item;
    },
    change_testcase(val) {
      if (val) {
        get_test_nodes(this.get_pid(), this.test_plan).then((res) => {
          this.test_case_list = res;
          this.test_case = "";
        });
      } else {
        this.test_case = "";
      }
    },

    createProgram_cancel() {
      //新建文件 取消
      this.createProgramVisible = false;
      this.form_name = "";
      this.fileTypeId = "";
      this.form_options = [];
    },
    createMultiplyProgram_cancel() {
      //新建excel 取消
      this.createProgramVisible = false;
      this.m_file_name = "";
      this.m_file_type_id = "";
      this.form_options = [];
    },
    createExcelKey_cancel() {
      //上传excel键值对 取消
      this.uplodaVisible = false;
      this.createProgramVisible = false;
      this.excelVisible = false;
      this.m_file_name = "";
      this.m_file_type_id = "";
      this.excelRedisUUID = "";
      this.headList = [];
      this.fields = [];
      this.form_options = [];
    },
    createExcel_cancel() {
      //上传excel 取消
      this.uplodaVisible = false;
      this.createProgramVisible = false;
      this.m_file_name = "";
      this.m_file_type_id = "";
      this.form_options = [];
    },
    createGPTProgram_cancel() {
      this.createProgramVisible = false;
      this.$refs["newGPTFileForm"].resetFields();
    },
    async createProgram_comfirm() {
      //新建文件 确定
      if (!this.form_name) {
        this.$message({
          message: this.$t("homeTopBar.newFileForm.name"),
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }
      if (!this.fileTypeId) {
        this.$message({
          message: this.$t("homeTopBar.newFileForm.type"),
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }
      this.createProgramVisible = false;
      if (this.$route.query.tool_tip == "global_tip_1") {
        vmson.$emit("global_tip", "1@end");
      }
      if (this.$route.query.tool_tip == "global_tip_3") {
        vmson.$emit("global_tip", "3@end");
      }
      try {
        let res = {};
        if (this.selectTemplate.fileTemplateId) {
          res = await applyTemplate({
            fileTemplateId: this.selectTemplate.fileTemplateId,
            data: {
              projectId: this.get_pid(),
              fileName: this.form_name,
              fileTypeId: this.fileTypeId,
              testPlanId: this.test_plan,
              testCase: this.test_case,
            },
          });
        } else {
          res = await createNewFile(
            //创建文件
            this.get_pid(),
            this.form_name,
            this.fileTypeId,
            this.test_case,
            this.test_plan
          );
        }
        this.$store.commit("SET_FILE_KEY", res);
        this.$store.commit("SET_FILETYPE_ID", this.fileTypeId);
        this.$store.commit("SET_MINDMAP_NAME", this.form_name);
      } catch (error) {
        console.log(error);
        return;
      }

      let res = await getFile(
        //获取文件内容
        this.get_pid(),
        this.$store.getters.file_key
      );

      this.$store.commit("SET_FILE_ID", res.data[0].fileId);
      this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
      this.$store.commit("SET_OPEN_STATE", "exist");
      this.$store.commit("SET_MAP_PERMISSION", "owner");
      // 打开新标签页
      this.form_name = "";
      this.fileTypeId = "";
      this.form_options = [];
      // this.isLoading = true;

      // 判断是不是顶部的新建文件弹窗，如果是则不执行关联节点
      if (!this.istop) {
        let arr = [];
        arr.push({
          sourceFileTypeId: this.node_info.fileTypeId,
          targetFileTypeId: res.fileTypeId,
          sourceNodeKey: this.node_info.key,
          targetNodeKey: res.data[0].key,
          sourceProjectId: this.get_pid(),
          targetProjectId: res.projectId,
        });

        add_relation_node(this.get_pid(), this.nodeKey, arr)
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      }

      setTimeout(() => {
        this.isLoading = false;
        this.$message({
          message: this.$t("homeTopBar.newFileForm.message"),
          type: "success",
          duration: 5 * 1000,
        });

        let new_route = "";
        if (this.$route.query.tool_tip == "global_tip_1") {
          ed({ guideType: "NEW_FILE" }).then((b) => {
            new_route = this.$router.resolve({
              path: `/${this.get_pid()}/myMind/${this.$store.getters.file_key}`,
              query: { popupDialogVisible: b.reward },
            });
            if (!b.success) {
              new_route = this.$router.resolve({
                path: `/${this.get_pid()}/myMind/${
                  this.$store.getters.file_key
                }`,
              });
            }
            window.open(new_route.href, "_blank");
          });
        } else if (this.$route.query.tool_tip == "global_tip_3") {
          ed({ guideType: "NEW_FILE_WITH_TEMPLAE" }).then((b) => {
            new_route = this.$router.resolve({
              path: `/${this.get_pid()}/myMind/${this.$store.getters.file_key}`,
              query: { popupDialogVisible: b.reward },
            });
            if (!b.success) {
              new_route = this.$router.resolve({
                path: `/${this.get_pid()}/myMind/${
                  this.$store.getters.file_key
                }`,
              });
            }
            window.open(new_route.href, "_blank");
          });
        } else {
          let para_guide = "NEW_FILE";
          if (this.selectTemplate.fileTemplateId) {
            para_guide = "NEW_FILE_WITH_TEMPLAE";
          }
          ed({ guideType: para_guide }).then((b) => {
            new_route = this.$router.resolve({
              path: `/${this.get_pid()}/myMind/${this.$store.getters.file_key}`,
              query: { popupDialogVisible: b.reward },
            });
            if (!b.success) {
              new_route = this.$router.resolve({
                path: `/${this.get_pid()}/myMind/${
                  this.$store.getters.file_key
                }`,
              });
            }
            window.open(new_route.href, "_blank");
          });
        }
      }, 0);
    },
    close_create_handle() {
      this.createProgramVisible = false;
      this.search_ai_file_options = [];

      this.ai_zdh_res_origin = "";
      this.ai_zdh_res = "";
      this.ai_zdh_res_shang = "";

      this.m_file_type_id = "";
      this.m_file_name = "";
      this.ai_create_file_step = "yi";
      this.m_file_name_old = "";
      this.ai_zdh_uid = "";

      this.ad_zdh_robotTyping = false;

      this.bumanyi_form = {
        a: "",
        b: "",
        c: "",
        d: "",
      };
    },
    search_ai_file_remoteMethod(query) {
      if (query !== "") {
        this.search_ai_file_loading = true;
        get_file_List(true, 0, 50, this.get_pid(), "fileKey", [], query).then(
          async (res) => {
            console.log(res);
            this.search_ai_file_options = res.content;
            this.search_ai_file_loading = false;
          }
        );
      } else {
        this.search_ai_file_options = [];
      }
    },
    ai_create_file_bumanyi() {
      this.ai_create_file_step = "san";
      if (this.languageType == "en-US") {
        this.ai_zdh_res_shang =
          '<div style="color: #707070;font-weight: bold;margin: 10px 0;font-size: large;"> Previous AI response </div> ' +
          this.ai_zdh_res;
      } else {
        this.ai_zdh_res_shang =
          '<div style="color: #707070;font-weight: bold;margin: 10px 0;font-size: large;"> 上一步AI的回答 </div> ' +
          this.ai_zdh_res;
      }

      this.ai_zdh_res = "";
    },
    bumanyi_youhua() {
      if (this.bumanyi_form.b.length < 1) {
        this.$message({
          type: "warning",
          message: this.$t("AI.tip46"),
        });

        return;
      }
      this.ai_create_file_step = "si";

      let content = "";
      if (this.languageType == "en-US") {
        if (this.bumanyi_form.a.length > 1) {
          content += `As a ${this.bumanyi_form.a},`;
        }
        if (this.bumanyi_form.b.length > 1) {
          content += `I hope to ${this.bumanyi_form.b} the above content`;
        }
        if (this.bumanyi_form.c.length > 1) {
          content += `,To achieve the goal of ${this.bumanyi_form.c}`;
        }
        if (this.bumanyi_form.d.length > 1) {
          content += `.In Addition, I Have Another Requirement: ${this.bumanyi_form.d}.`;
        }
      } else {
        if (this.bumanyi_form.a.length > 1) {
          content += `作为一名${this.bumanyi_form.a}，`;
        }
        if (this.bumanyi_form.b.length > 1) {
          content += `我希望对上述内容进行${this.bumanyi_form.b}`;
        }
        if (this.bumanyi_form.c.length > 1) {
          content += `，以达成${this.bumanyi_form.c}目的。`;
        }
        if (this.bumanyi_form.d.length > 1) {
          content += `除此之外，我还有一项要求：${this.bumanyi_form.d}`;
        }
      }

      let self = this;
      const obj = {
        type: "2",
        content: "",
        originContent: "",
        isRobot: true,
        contextMenuVisible: false,
      };
      this.ai_zdh_uid = ulid();
      const div = document.getElementById("markdown_zdh_ai_create_file_2");
      this.ad_zdh_robotTyping = true;
      fetchEventSource(
        `${self.wsurl}${
          self.wsurl ? "" : "/prod-api"
        }/file-manage-service/${self.get_pid()}/aiRobot/content/optimize`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: self.token,
            "Accept-Language": self.languageType,
          },
          body: JSON.stringify({
            sessionId: self.ai_zdh_uid,
            originContent: self.ai_zdh_res_origin,
            optimizePrompt: content,
          }),
          openWhenHidden: true,
          onmessage(event) {
            self.ad_zdh_robotTyping = true;
            const data = JSON.parse(event.data);
            if (data.value) {
              obj.originContent += data.value;
              obj.content += data.value
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replaceAll("\\n", "</br>")
                .replaceAll(" ", "&ensp;");
            }
            self.ai_zdh_res = obj.content;
            self.ai_zdh_res_origin = obj.originContent;
            setTimeout(() => {
              div.scrollTop = div.scrollHeight;
            }, 500);
          },
          onerror(err) {
            self.ad_zdh_robotTyping = false;
            throw err;
          },
          onclose() {
            self.ad_zdh_robotTyping = false;
          },
        }
      );
    },
    ai_create_file_manyi() {
      let pa = {
        projectId: this.get_pid(),
        data: {
          content: this.ai_zdh_res_origin,
          fileTypeId: this.m_file_type_id,
          fileName: this.m_file_name,
        },
      };
      aiRobotPPayFeatureStatus().then((res) => {
        if (res) {
          aiRobotCreate(pa)
            .then((res) => {
              this.$message({
                type: "success",
                message: this.$t("homeTopBar.asyncTask.message"),
              });
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: this.$t("AI.tip18"),
              });
            });
        } else {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=ai" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        }
      });

      this.close_create_handle();
    },
    async ai_create_file_step1() {
      await aiRobotPPayFeatureStatus().then((res) => {
        if (!res) {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=ai" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
          return;
        } else {
          if (this.m_file_name_old.length < 1) {
            this.$message({
              type: "warning",
              message: this.$t("AI.tip47"),
            });
            return;
          }
          if (this.m_file_name.length < 1) {
            this.$message({
              type: "warning",
              message: this.$t("AI.tip48"),
            });
            return;
          }
          if (this.m_file_type_id.length < 1) {
            this.$message({
              type: "warning",
              message: this.$t("AI.tip49"),
            });
            return;
          }
      this.ad_zdh_robotTyping = true;
      this.ai_create_file_step = "er";
      let self = this;
      const obj = {
        type: "2",
        content: "",
        originContent: "",
        isRobot: true,
        contextMenuVisible: false,
      };
      this.ai_zdh_uid = ulid();
      const div = document.getElementById("markdown_zdh_ai_create_file_1");
      fetchEventSource(
        `${self.wsurl}${
          self.wsurl ? "" : "/prod-api"
        }/file-manage-service/${self.get_pid()}/aiRobot/content/generate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: self.token,
            "Accept-Language": self.languageType,
          },
          body: JSON.stringify({
            sessionId: self.ai_zdh_uid,
            sourceFileTypeName: self.fileType[self.m_file_name_old.split("@")[1]].name,
            targetFileTypeName: self.fileType[self.m_file_type_id].name,
            fileUuid: self.m_file_name_old.split("@")[0],
          }),
          openWhenHidden: true,
          onmessage(event) {
            self.ad_zdh_robotTyping = true;

                const data = JSON.parse(event.data);
                if (data.value) {
                  obj.originContent += data.value;
                  obj.content += data.value
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;")
                    .replaceAll("\\n", "</br>")
                    .replaceAll(" ", "&ensp;");
                }
                self.ai_zdh_res = obj.content;
                self.ai_zdh_res_origin = obj.originContent;
                setTimeout(() => {
                  div.scrollTop = div.scrollHeight;
                }, 500);
              },
              onerror(err) {
                self.ad_zdh_robotTyping = false;
                throw err;
              },
              onclose() {
                self.ad_zdh_robotTyping = false;
              },
            }
          );
        }
      });
    },
    createMultiplyProgram_comfirm() {
      //新建批量上传文件
      if (!this.m_file_name) {
        this.$message({
          message: this.$t("homeTopBar.newFileForm.name"),
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }
      if (!this.m_file_type_id) {
        this.$message({
          message: this.$t("homeTopBar.newFileForm.type"),
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }
      get_filetype_detail(this.get_pid(), this.m_file_type_id).then((res) => {
        let li = {};
        this.fields = [];
        this.fields.push({
          name: this.$t("homeTopBar.newFileForm.field1"),
          fieldId: "assignee",
        });
        this.fields.push({
          name: this.$t("homeTopBar.newFileForm.field2"),
          fieldId: "description",
        });
        res.fileTypeLayerList.forEach((i) => {
          i.fieldList.forEach((item) => {
            li[item.fieldId] ? "" : (li[item.fieldId] = item);
          });
        });
        this.fields.push(...Object.values(li));
      });
      this.uplodaVisible = true;
      this.isLoading = false;
    },
    createGPTProgram_comfirm() {
      mgptPayFeatureStatus().then((res) => {
        if (res) {
          if (!this.mappingGptForm.repoUrl) {
            this.$refs.newGPTFileForm.validateField("idea", (errorMsg) => {
              if (!errorMsg) {
                const params = {
                  projectId: this.get_pid(),
                  data: {
                    idea: this.mappingGptForm.idea,
                  },
                };
                createNewFileMgpt(params).then((res) => {
                  this.createProgramVisible = false;
                  this.$refs["newGPTFileForm"].resetFields();
                  this.$message({
                    type: "success",
                    message: this.$t("homeTopBar.asyncTask.message"),
                  });
                });
              }
            });
          } else {
            this.$refs["newGPTFileForm"].validate((valid) => {
              if (valid) {
                const params = {
                  projectId: this.get_pid(),
                  data: this.mappingGptForm,
                };
                createNewFileMgpt(params).then((res) => {
                  this.createProgramVisible = false;
                  this.$refs["newGPTFileForm"].resetFields();
                  this.$message({
                    type: "success",
                    message: this.$t("homeTopBar.asyncTask.message"),
                  });
                });
              } else {
                return false;
              }
            });
          }
        } else {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=MGPT" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        }
      });
    },
    get_testplan_list() {
      if (this.$route.params.projectId) {
        this.test_plan_list = Object.values(this.testPlan);
        this.test_plan_list.forEach((item) => {
          if (item.status == "NOT_STARTED") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.notStarted") +
              ")";
          } else if (item.status == "IN_PROGRESS") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.inProgress") +
              ")";
          } else if (item.status == "COMPLETE") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.completed") +
              ")";
          }
        });
      }
    },
    get_plan_status(status) {
      switch (status) {
        case "COMPLETE":
          return this.$t("canvas.testPlan.status.completed");
        case "IN_PROGRESS":
          return this.$t("canvas.testPlan.status.inProgress");
        case "NOT_STARTED":
          return this.$t("canvas.testPlan.status.notStarted");
      }
    },
    creatExcelFn() {
      let l = [];
      for (let i in this.headList) {
        if (this.f_data[i]) {
          if (
            this.f_data[i][0] == this.$t("canvas.viewDropDown.responsibility")
          ) {
            let dic = {};
            dic["excelName"] = this.headList[i];
            dic["filedName"] = "assignee";
            dic["fieldId"] = "assignee";
            l.push(dic);
          } else if (
            this.f_data[i][0] == this.$t("canvas.wordView.node.desc")
          ) {
            let dic = {};
            dic["excelName"] = this.headList[i];
            dic["filedName"] = "description";
            dic["fieldId"] = "description";
            l.push(dic);
          } else {
            let dic = {};
            dic["excelName"] = this.headList[i];
            dic["filedName"] = this.f_data[i][0];
            dic["fieldId"] = this.f_data[i][1].fieldId;
            l.push(dic);
          }
        }
      }

      let d = {};
      d["excelRedisUUID"] = this.excelRedisUUID;
      d["fileName"] = this.m_file_name;
      d["fileTypeId"] = this.m_file_type_id;
      d["mappingFieldList"] = l;
      this.isLoading1 = true;
      upload_excel_key(this.get_pid(), d)
        .then((res) => {
          this.$store.commit("SET_FILE_KEY", res);
          this.$store.commit("SET_FILETYPE_ID", this.m_file_type_id);
          this.$store.commit("SET_MINDMAP_NAME", this.m_file_name);
          this.isLoading1 = true;
          setTimeout(() => {
            this.excelVisible = false;
            this.isLoading1 = false;
            this.$message({
              message: this.$t("homeTopBar.newFileForm.message"),
              type: "success",
              duration: 5 * 1000,
            });
            const new_route = this.$router.resolve({
              path: `/${this.get_pid()}/myMind/${res}`,
            });
            window.open(new_route.href, "_blank");
          }, 0);
        })
        .catch((err) => {
          this.isLoading1 = false;
          self.$message.error(
            self.$t("homeTopBar.newProjectForm.message.uploadError")
          );
        });
    },
    createExcelKey_comfirm() {
      let flag = true;
      //创建excel键值对
      for (let i in this.f_data) {
        if (this.f_data[i].length < 1) {
          flag = false;
        }
      }
      //构建上传接口data
      if (!flag) {
        this.$confirm(
          this.$t("file.dialog.tip1"),
          this.$t("file.dialog.tip2"),
          {
            confirmButtonText: this.$t("btn.confirmBtn"),
            cancelButtonText: this.$t("btn.cancelBtn"),
            type: "warning",
          }
        )
          .then(() => {
            this.creatExcelFn();
          })
          .catch(() => {});
      } else {
        this.creatExcelFn();
      }
    },

    async upload_attachment_handler(params) {
      //上传excel
      const self = this;
      self.isLoading = true;
      let file = params.file;
      await ossAuthorization.call(self);
      self.client
        .put(`import_excel_word/${ulid()}${file.name}`, file)
        .then((ossres) => {
          const arr = params.file.name.split(".");
          if (arr[arr.length - 1] === "xls" || arr[arr.length - 1] === "xlsx") {
            upload_excel(self.get_pid(), {
              ossFileKey: ossres.name,
            })
              .then((res) => {
                //打开excel键值对窗口
                self.createProgramVisible = false;
                self.excelRedisUUID = res.excelRedisUUID;
                self.headList = res.headList;
                if (res.headList.length < 1) {
                  let d = {};
                  d["excelRedisUUID"] = self.excelRedisUUID;
                  d["fileName"] = self.m_file_name;
                  d["fileTypeId"] = self.m_file_type_id;
                  d["mappingFieldList"] = [];
                  upload_excel_key(self.get_pid(), d)
                    .then((res) => {
                      self.$store.commit("SET_FILE_KEY", res);
                      self.$store.commit(
                        "SET_FILETYPE_ID",
                        self.m_file_type_id
                      );
                      self.$store.commit("SET_MINDMAP_NAME", self.m_file_name);
                      setTimeout(() => {
                        self.uplodaVisible = false;
                        self.isLoading = false;
                        self.$message({
                          message: self.$t("homeTopBar.newFileForm.message"),
                          type: "success",
                          duration: 5 * 1000,
                        });
                        const new_route = this.$router.resolve({
                          path: `/${this.get_pid()}/myMind/${res}`,
                        });
                        self.isLoading = false;
                        window.open(new_route.href, "_blank");
                      }, 0);
                    })
                    .catch((err) => {
                      self.isLoading = false;
                      console.log(err);
                    });
                } else {
                  self.uplodaVisible = false;
                  self.isLoading = false;
                  self.excelVisible = true;
                  // 构建浮窗字段
                  self.const_fields = JSON.parse(JSON.stringify(self.fields));
                  self.f_data = [];
                  for (let i in self.headList) {
                    self.f_data.push("");
                  }
                }
              })
              .catch((err) => {
                self.isLoading = false;
                self.$message.error(
                  self.$t("homeTopBar.newProjectForm.message.uploadError")
                );
              });
          } else if (arr[arr.length - 1] === "xmind") {
            uploadXmind(self.get_pid(), {
              name: self.m_file_name,
              fileTypeId: self.m_file_type_id,
              ossFileKey: ossres.name,
            }).then(async (res) => {
              self.isLoading = false;
              self.uplodaVisible = false;
              self.$store.commit("SET_FILE_KEY", res);
              self.$store.commit("SET_FILETYPE_ID", self.m_file_type_id);
              self.$store.commit("SET_MINDMAP_NAME", self.m_file_name);
              let res1 = await getFile(
                //获取文件内容
                self.get_pid(),
                self.$store.getters.file_key
              );
              self.$store.commit("SET_FILE_ID", res1.data[0].fileId);
              self.$store.commit("SET_EXIST_MINDMAP", res1); //文件内容
              self.$store.commit("SET_OPEN_STATE", "exist");
              self.$store.commit("SET_MAP_PERMISSION", "owner");
              const new_route = self.$router.resolve(
                `/${self.get_pid()}/myMind/${this.$store.getters.file_key}`
              );
              window.open(new_route.href, "_blank");
            });
          } else if (arr[arr.length - 1] === "mm") {
            uploadFreemind(self.get_pid(), {
              name: self.m_file_name,
              fileTypeId: self.m_file_type_id,
              ossFileKey: ossres.name,
            }).then(async (res) => {
              self.isLoading = false;
              self.uplodaVisible = false;
              self.$store.commit("SET_FILE_KEY", res);
              self.$store.commit("SET_FILETYPE_ID", self.m_file_type_id);
              self.$store.commit("SET_MINDMAP_NAME", self.m_file_name);
              let res1 = await getFile(
                //获取文件内容
                self.get_pid(),
                self.$store.getters.file_key
              );
              self.$store.commit("SET_FILE_ID", res1.data[0].fileId);
              self.$store.commit("SET_EXIST_MINDMAP", res1); //文件内容
              self.$store.commit("SET_OPEN_STATE", "exist");
              self.$store.commit("SET_MAP_PERMISSION", "owner");
              const new_route = self.$router.resolve(
                `/${self.get_pid()}/myMind/${this.$store.getters.file_key}`
              );
              window.open(new_route.href, "_blank");
            });
          } else {
            self.isLoading = true;
            let file = new FormData();
            file.append("uploadFile", params.file);
            file.append("fileName", self.m_file_name);
            file.append("fileTypeId", self.m_file_type_id);
            createFileWithWord(self.get_pid(), {
              fileName: self.m_file_name,
              fileTypeId: self.m_file_type_id,
              ossFileKey: ossres.name,
            })
              .then((res) => {
                self.$message({
                  showClose: true,
                  message: this.$t("homeTopBar.asyncTask.message"),
                  type: "success",
                  duration: 5000,
                });
                self.isLoading = false;
                self.uplodaVisible = false;
              })
              .catch((err) => {
                self.isLoading = false;
                self.$message.error(
                  self.$t("homeTopBar.newProjectForm.message.uploadError")
                );
              })
              .finally(() => {
                self.isLoading = false;
                self.excelVisible = false;
                self.createProgramVisible = false;
              });
          }
        })
        .error((r) => {
          self.isLoading = false;
          self.$message.error(
            self.$t("homeTopBar.newProjectForm.message.uploadError")
          );
        });
    },
    judge_howBig(file) {
      if (file.size / 1024 / 1024 > 20) {
        this.$message({
          type: "error",
          message: this.$t("homeTopBar.newFileForm.limit"),
          duration: "5000",
        });
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.gptform {
  ::v-deep .el-form-item__label-wrap {
    margin-left: 0px !important;
  }
}
/* 新建文件弹窗样式 */
.createpro {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
}

.createpro-header {
  color: rgba(49, 100, 143, 1);
  font-family: "思源黑体";
  font-size: 16px;
  margin-top: -30px;

  .is-active {
    color: #30648f;
  }
  ::v-deep .el-select {
    .tags-select-input {
      height: 40px;
      .el-select__tags {
        height: 40px;
        white-space: nowrap;
        overflow: hidden;
        flex-wrap: wrap;
      }
    }

    .el-select__tags-text {
      display: inline-block;
      max-width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .el-tag__close.el-icon-close {
      top: -7px;
    }
  }
}
.ai_res_blink:after {
  -webkit-animation: blink 1s steps(2, start) infinite;
  animation: blink 1s steps(2, start) infinite;
  content: "▋";
  margin-left: 1px;
  vertical-align: baseline;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.createpro .el-form {
  margin-top: 20px;
}

.createpro-select {
  width: 100%;
}

.dialog-footer {
  display: flex;
  justify-content: right;
}

.dialog-footer .button-confirm {
  background-color: rgba(1, 76, 170, 1);
  color: #f8fafc;
}

.dialog-footer .button-confirm:hover {
  background-color: #5596cb;
}

.head-img:hover {
  cursor: pointer;
}
::v-deep .create_project {
  .el-dialog__header {
    padding-right: 40px;
    padding-left: 40px;
  }

  .el-dialog__body {
    padding: 30px 40px;

    .avatar {
      .el-form-item__content {
        display: flex;
        align-items: center;
      }

      .upload_avatar {
        margin-left: 16px;
        font-size: 12px;
        color: #30648f;
      }
    }
  }

  .input_box {
    position: relative;
  }

  .click_change {
    display: inline-block;
    position: absolute;
    margin-left: 5px;
  }

  .members_it_ic {
    position: absolute;
    right: 8px;
    top: 15px;
    background: white;
  }

  .user_avatar {
    display: inline-block;
    position: relative;
    top: 4px;
  }
}
</style>
<style lang="scss">
.bell {
  .el-badge__content {
    background-color: #f56c6c;
    border-radius: 10px;
    color: #fff;
    display: flex;
    font-size: 12px;
    height: 16px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    border: none;
  }
}

.createpro {
  .el-dialog__body {
    padding-bottom: 0 !important;
  }
}

.el-dialog__headerbtn {
  z-index: 99999;
}
.tara_relation_select {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 10px !important;
  }
}
.dialog-footer-excel {
  .el-button--primary:focus {
    background-color: rgba(1, 76, 170, 1);
    border-color: rgba(1, 76, 170, 1);
  }
}

.upexcel {
  a {
    user-select: none;
    -webkit-appearance: none;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #60a3d9 !important;
    font-style: normal;
  }

  p {
    font-size: 1em;
    line-height: 140%;
  }
}

.grid-excel {
  display: grid;
  grid-template-columns: 30% 70%;
  // grid-column-gap: 10%;
  align-items: center;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.createpro-header {
  .el-tabs__active-bar {
    background-color: #30648f;
  }

  .is-active {
    color: #30648f;
  }

  .el-tabs__item:hover {
    color: #30648f;
  }
}

.dropdown_big {
  padding: 0;
  // li {
  //   padding: 10px 15px;
  // }
  li:nth-child(1) {
    border-radius: 6px 6px 0px 0px;
    background-color: rgba(77, 160, 255, 0.35);
  }
}
.homeTopbar_dropdown_offTop {
  top: 38px !important;
}
</style>

<style>
.zdh_guide_class_tip1 {
  z-index: 9999999 !important;
  pointer-events: all;
}
</style>
