<template>
  <el-dialog
    :visible="dialogVisible"
    :close-on-click-modal="false"
    :show-close="true"
    width="25%"
    class="workflowAndDialog"
    @close="closeDialog"
  >
    <div class="content-title">
      <i
        style="
          color: rgba(56, 56, 56, 1);
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          text-align: left;
          display: flex;
          flex: 1;
        "
        ><i class="iconfont icon-andDialog" style="font-size: 24px; color: red"/>
        <div>
          <div style="padding-left: 19px">
            {{ $t("editFileType.workflow.message.ms1") }}
          </div>
          <div style="padding-left: 19px">
            {{ $t("editFileType.workflow.message.ms2") }}
          </div>
        </div>
      </i>
    </div>
    <div class="content-content">
      <div v-for="(item, index) in andConditionText" :key="index">
        {{ `${index + 1}.${item}` }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "workflowAndDialog",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    andConditionText: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    closeDialog() {
      this.$emit("update:dialogVisible", false);
    },
  },
};
</script>

<style lang="scss">
.content-content {
  padding-left: 40px;
  > div {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: rgba(128, 128, 128, 1);
    padding-bottom: 22px;
  }
  div:nth-child(1) {
    padding-top: 24px;
  }
}
</style>
