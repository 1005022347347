const getters = {
  token: (state) => state.user.token,
  count: (state) => state.user.count,
  email: (state) => state.user.email,
  name: (state) => state.user.name,
  avatar: (state) => state.user.avatar,
  roles: (state) => state.user.roles,
  jobAuthorities: (state) => state.user.jobAuthorities,
  // 获取右上角是否有通知
  notice: (state) => state.notice.notice,
  updateNum: (state) => state.notice.all,
  userAccountId: (state) => state.user.userAccountId,
  mindmaps: (state) => state.mindmap.mindmaps,
  exist_mindmap: (state) => state.mindmap.exist_mindmap,
  is_copy: (state) => state.mindmap.is_copy,
  permission: (state) => state.mindmap.permission,
  templates: (state) => state.mindmap.templates,
  file_id: (state) => state.mindmap.file_id,
  mindmap_name: (state) => state.mindmap.mindmap_name,
  current_time: (state) => state.mindmap.current_time,
  open_state: (state) => state.mindmap.open_state,
  type: (state) => state.mindmap.type,
  sheet: (state) => state.mindmap.sheet,
  typed: (state) => state.mindmap.typed,
  mindmap_owner: (state) => state.mindmap.mindmap_owner, // 原文件所有者 email
  loading_review: (state) => state.mindmap.loading_review,
  review_res_flag: (state) => state.mindmap.review_res_flag,
  user_info: (state) => state.user.userInfo,
  p_avatar: (state) => state.project.avatar,
  p_avatar_url: (state) => state.project.avatarUrl,
  p_id: (state) => state.project.id,
  p_user: (state) => state.project.user,
  project_type: (state) => state.project.project_type,
  manager_list: (state) => state.project.managerList,
  member_count: (state) => state.project.memberCount,
  project_key: (state) => state.project.projectKey,
  project_name: (state) => state.project.projectName,
  project_third_apps: (state) => state.project.projectThirdApps,
  tenant_id: (state) => state.project.tenantId,
  projectRole: (state) => state.store_project.projectRole,
  file_type_id: (state) => state.mindmap.file_type_id, //导图文件类型id
  file_key: (state) => state.mindmap.file_key, //文件key
  page_layer: (state) => state.setting.page_layer,
  page_typeid: (state) => state.setting.page_typeid,
  page_name: (state) => state.setting.page_name,
  cancel_auto: (state) => state.mindmap.cancel_auto,
  current_spirnt: (state) => state.board.current_spirnt,
  //基线开始---
  baselineId: (state) => state.release.baselineId,
  baseline_status: (state) => state.release.baseline_status,
  is_edit: (state) => state.release.is_edit,
  collapse: (state) => state.setting.isCollapse,
  //---基线结束

  execution_status: (state) => state.store_project.execution_status,
  status: (state) => state.store_project.status,
  testPlan: (state) => state.store_project.testPlan,
  version: (state) => state.store_project.version,
  sprint: (state) => state.store_project.sprint,
  baseLine: (state) => state.store_project.baseLine,
  t_joined_projects: (state) => state.store_tenant.joined_projects,
  user_list: (state) => state.store_tenant.user,
  user_group_list: (state) => state.store_tenant.userGroup,
  special_users: (state) => state.store_tenant.specialUsers,
  watermask_switch: (state) => state.store_tenant.watermask_switch,
  all_file_type: (state) => state.store_tenant.all_file_type,
  watermask_value: (state) => state.store_tenant.watermask_value,
  fileType: (state) => state.store_project.fileType,
  project_user_list: (state) => state.store_project.project_user_list,
  ShowHara: (state) => state.store_project.ShowHara,
  showDfmea: (state) => state.store_project.showDfmea,
  showTARA: (state) => state.store_project.showTARA,
  showHARA: (state) => state.store_project.showHARA,
  pingshenNumberIsToUpdate: (state) => state.pingshen.pingshenNumberIsToUpdate,
  // 知识库
  knowledge_edit: (state) => state.knowledge.knowledge_edit,
  knowledge_edit_mode: (state) => state.knowledge.knowledge_edit_mode,
  // 换肤相关
  theme: (state) => state.theme.theme
};
export default getters;