<template>
  <div v-if="is_file_exist" id="myMind" style="position: relative">
    <!-- <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    /> -->
    <!-- <el-container> -->
    <el-header style="height: 60px">
      <home-topbar :is-home="true" :is-personal="false"></home-topbar>
      <topbar
        v-if="isGetData"
        ref="topbar"
        @canvasbox="canvasbig"
        @canvasbut="canvassmall"
        @nodeLocationChange="nodeLocationChange"
        @nodebuchong="nodebuchong"
        @ignoreCoverage="ignoreCoverage"
        @changeNodeTextIndent="changeNodeTextIndent"
        @DFMEAView="DFMEAView"
        @asyncNodes="asyncNodes"
        :ignore-list="ignoreList"
        :fileData="fileData"
      ></topbar>
    </el-header>
    <el-main>
      <mindMap
        v-if="isGetData"
        id="mymap"
        ref="mindMap"
        @closeReview="isReview"
        @topbarBtnIshow="topbarBtnIshow"
        @ignoreList="changeIgnore"
        :fileData="fileData"
      ></mindMap>
    </el-main>
    <!-- </el-container> -->
    <AIdialog></AIdialog>
  </div>
</template>

<script>
import mindMap from "./components/mindmap";
import topbar from "@/components/topbar";
import HomeTopbar from "@/components/homeTopbar";
import AIdialog from "@/components/AIdialog";
import vmson from "@/utils/vmson";
import { get_file_exist } from "@/network/review/index.js";
import { Message } from "element-ui";
import { getFile } from "@/network/home/index.js";
export default {
  name: "MyMind",
  components: { mindMap, topbar, HomeTopbar, AIdialog },
  data() {
    return {
      is_file_exist: false,
      num: 1, //为了只执行一次
      ignoreList: [],
      isGetData: false,
      fileData: {},
    };
  },
  created() {
    this.created_fn();
  },
  mounted() {
    getFile(this.get_pid(), this.$route.params.file_key).then((res) => {
      this.fileData = res;
      this.isGetData = true;
    });
  },
  methods: {
    asyncNodes(noreused) {
      this.$refs.mindMap.asyncNodes(noreused);
    },
    // DFMEA模式
    DFMEAView() {
      this.$refs.mindMap.DFMEAView();
    },
    // Ai补充
    nodebuchong() {
      this.$refs.mindMap.nodebuchong();
    },
    // 覆盖度忽略
    ignoreCoverage(e) {
      this.$refs.mindMap.nodeCoverage(e);
    },
    // 点击按钮移动文件
    nodeLocationChange(direction) {
      this.$refs.mindMap.node_location_changeFn(direction);
    },
    /**笨蛋放大法 */
    canvasbig() {
      this.$refs.mindMap.clickChangeSize(true); //优化缩放方法
      this.$nextTick(() => {
        vmson.$emit("jmshow", "***");
      });
    },
    /**笨蛋缩小法 */
    canvassmall() {
      this.$refs.mindMap.clickChangeSize(false);
      this.$nextTick(() => {
        vmson.$emit("jmshow", "***");
      });
    },
    async created_fn() {
      // 获取文件是否存在
      this.is_file_exist = await get_file_exist(
        this.get_pid(),
        this.$route.params.file_key
      );
      // 文件不存在,跳转文件页,弹出警告
      if (!this.is_file_exist) {
        this.$router.push({
          path: `/${this.get_pid()}/home/my_file`,
        });

        Message({
          message: this.$t("myMind.CurrentFileIsDeleted"),
          type: "error",
          duration: 5 * 1000,
        });
      }
    },
    // 用于消息跳转后判断评审条目完成情况，控制头部按钮显示
    topbarBtnIshow() {
      this.$refs.topbar.btn_isShow = true;
    },
    isReview() {
      // 在评审全部完成后自动跳转到 查看评审结果
      this.$refs.topbar.btn_isShow = true;
      if (this.num == 1) {
        this.num = 0;
        this.$nextTick(() => {
          this.$refs.topbar.in_review();
        });
      }
    },
    /* 笨蛋修改节点文字对齐方式 父组件传事件 */
    changeNodeTextIndent(type) {
      // 调用子组件事件
      this.$refs.mindMap.changeNodeTextIndent(type);
    },
    // 无需覆盖的项目数量变化
    changeIgnore(e) {
      this.ignoreList = e;
    },
  },
};
</script>

<style>
.el-header {
  /* text-align: center; */
  /* width: 100vw; */
  padding: 0px;
  /* PERF 建议全局修改默认样式 */
}
</style>
