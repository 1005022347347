import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
export function get_filetype_List(projectId) {
  //获得项目文件类型列表
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes`,
    method: "get",
  });
}

export function get_filetype_detail(projectId, fileTypeId) {
  //获得文件类型详情
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes/${fileTypeId}`,
    method: "get",
  });
}

export function filetype_copy(projectId, fileTypeId) {
  //复制文件类型
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes/${fileTypeId}/copy`,
    method: "post",
  });
}

export function filetype_layer_add(projectId, fileTypeId) {
  //新增层级
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes/${fileTypeId}/layers`,
    method: "post",
  });
}

export function filetype_layer_delete(projectId, fileTypeId, layerNumber) {
  //删除层级
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes/${fileTypeId}/layers/${layerNumber}`,
    method: "delete",
  });
}

export function filetype_modify(projectId, fileTypeId, content) {
  //修改文件类型基本信息
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes/${fileTypeId}`,
    data: content,
    method: "put",
  });
}

export function fields_get(projectId, fileTypeId, layerNumber) {
  //获得所有字段(已筛选)
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes/${fileTypeId}/layers/${layerNumber}/selectFields`,
    method: "get",
  });
}

export function fields_add(projectId, fileTypeId, layerNumber, content) {
  //添加已有字段
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes/${fileTypeId}/layers/${layerNumber}/fieldReferences`,
    method: "post",
    data: content,
  });
}

export function fields_delete(
  projectId,
  fileTypeId,
  layerNumber,
  fieldReferenceId
) {
  //删除字段
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes/${fileTypeId}/layers/${layerNumber}/fieldReferences/${fieldReferenceId}`,
    method: "delete",
  });
}

export function fieldReference_modify(
  projectId,
  fileTypeId,
  layerNumber,
  content
) {
  //编辑关联类型
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes/${fileTypeId}/layers/${layerNumber}/relationTypes`,
    method: "put",
    data: content,
  });
}

export function field_modify(
  projectId,
  fileTypeId,
  layerNumber,
  fieldReferenceId,
  content
) {
  //修改文件类型层级的字段信息
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes/${fileTypeId}/layers/${layerNumber}/fieldReferences/${fieldReferenceId}`,
    method: "put",
    data: content,
  });
}

export function field_order(projectId, fileTypeId, layerNumber, content) {
  //字段的拖拽排序
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes/${fileTypeId}/layers/${layerNumber}/fieldReferences/order`,
    method: "put",
    data: content,
  });
}

export function all_fields_get(projectId) {
  //获得所有字段(字段库)
  return request({
    url: `${serviceConfig['file-manage-service']}/${projectId}/fields`,
    method: "get",
  });
}
export function change_fields_defaultValue(projectId, fieldId, data) {
  //字段默认值修改
  return request({
    url: `${serviceConfig['file-manage-service']}/${projectId}/field/${fieldId}/defaultValue`,
    method: "put",
    data: data
  });
}
export function field_rename(projectId, fieldId, data) {
  //字段重命名
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/field/${fieldId}/modifyField`,
    method: "put",
    data,
  });
}

export function resetUnit(params) {
  //改数字类型单位
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/field/${params.fieldId}/unit`,
    method: "put",
    data: params.data,
  });
}
export function field_create(projectId, content) {
  //创建新字段
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fields`,
    method: "post",
    data: content,
  });
}

export function delete_field_single(params) {
  //创建单个字段
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/field/${params.fieldId}`,
    method: "delete",
  });
}

export function file_type_create(projectId, file_data) {
  //新建文件类型
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypes`,
    method: "post",
    data: file_data,
  });
}
export function file_type_delete(params) {
  //新建文件类型
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/fileTypes/${params.fileTypeId}`,
    method: "delete",
  });
}
export function add_extraDataList(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/field/${params.fieldId}/extraDataList`,
    data: params.data,
    method: "post",
  });
}
export function delete_extraDataList(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/field/${params.fieldId}/extraDataList`,
    params: params.data,
    method: "delete",
  });
}
export function edit_extraDataList(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/field/${params.fieldId}/extraDataList`,
    data: params.data,
    method: "put",
  });
}
export function searchByKeys(params) {
  // tag 无影响
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/searchByKeys`,
    data: params.data,
    method: 'post',
  })
}
export function getSystemFields() {
  return request({
    url: `/${serviceConfig['file-manage-service']}/getSystemFields`,
    method: 'get',
  })
}
export function get_notice_filed(params) {
  //获得字段通知设置
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/field/${params.fieldId}/notifyConfig`,
    method: "get",
  });
}
export function notice_edit_filed(params) {
  //修改字段通知设置
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/field/${params.fieldId}/notifyConfig?needNotify=${params.needNotify}`,
    method: "put",
  });
}
// 获取状态时长规则
export function getStatusDurationRuleByProjectId(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/statusDurationRule`,
    method: "get",
  });
}
// 创建状态时长规则
export function CreateStatusDurationRule(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/statusDurationRule`,
    data: params.data,
    method: "post",
  });
}
// 删除状态时长规则
export function DeleteStatusDurationRule(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/statusDurationRule/${params.ruleUuid} `,
    method: "delete",
  });
}
// 启用状态时长规则
export function EnableStatusDurationRule(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/statusDurationRule/${params.ruleUuid}/enable`,
    method: "put",
  });
}
// 禁用状态时长规则
export function DisableStatusDurationRule(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/statusDurationRule/${params.ruleUuid}/disable`,
    method: "put",
  });
}
// 获取文件类型的职位权限
export function getJobAuths(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/filetypes/${params.fileTypeId}/jobAuths`,
    method: "get",
  });
}
// 修改文件类型的职位权限 
export function setJobAuths(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/filetypes/${params.fileTypeId}/jobAuths`,
    method: "PUT",
    data: params.data
  });
}
// 删除文件类型的职位权限
export function deleteJobAuths(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/filetypes/${params.fileTypeId}/jobAuths/${params.jobId}`,
    method: "DELETE",
  });
}
// 修改文件类型顺序
export function changeFileTypeOrder(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/fileTypes/index`,
    method: "put",
    data: params.list
  });
}
// 批量关联文件层级
export function relatedFileType(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/field/${params.fieldId}/fileType`,
    method: "post",
    data: params.data
  });
}

