import * as XLSX from 'xlsx'
function autoWidthFunc(ws, data) {
    // set worksheet max width per col
    const colWidth = data.map(row => row.map(val => {
        var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");//检测字符串是否包含汉字
        if (val == null) {
            return { wch: 10 }
        } else if (reg.test(val)) {
            return { wch: val.toString().length * 2 }
        } else {
            return { wch: val.toString().length }
        }
    }))
    // start in the first row
    const result = colWidth[0]
    for (let i = 1; i < colWidth.length; i++) {
        for (let j = 0; j < colWidth[i].length; j++) {
            if (result[j].wch < colWidth[i][j].wch) {
                result[j].wch = colWidth[i][j].wch
            }
        }
    }
    ws['!cols'] = result
}

function jsonToArray(key, jsonData) {
    return jsonData.map(v => key.map(j => { return v[j] }))
}

const exportArrayToExcel = ({ key, data, title, filename, autoWidth, merge }) => {
    const wb = XLSX.utils.book_new()
    const arr = jsonToArray(key, data)
    arr.forEach((row, index) => {
        if (row.length > 0) {
            if (row[row.length - 1].length > 0) {
                row[row.length - 1] = row[row.length - 1].replace(/\n/g, ', ')
                row[row.length - 1] =row[row.length - 1].slice(0, row[row.length - 1].length - 2)
            }
            
            // const lastCellIndex = row.length - 1;
            // const url = row[lastCellIndex];  // 假设最后一列是 URL
            // if (url && typeof url === 'string' && url.startsWith('http')) {
            //     row[lastCellIndex] = {
            //         t: 's',  // 字符串类型
            //         v: '链接', // 显示的文本（可以自定义）
            //         h: url   // 超链接地址
            //     };
            // }
        }
    });
    arr.unshift(title)

    const ws = XLSX.utils.aoa_to_sheet(arr)
    ws['!merges'] = merge;
    if (autoWidth) {
        autoWidthFunc(ws, arr)
    }
    XLSX.utils.book_append_sheet(wb, ws, filename)
    XLSX.writeFile(wb, filename + '.xlsx')
}

export default {
    exportArrayToExcel
}