import { sessionRead, sessionSave } from '@/utils/session';
import { get_test_plans, testCaseStatus } from '@/network/testPlan/index.js'
import { get_baseline_list } from '@/network/baseline/index'
import { get_all_versions } from '@/network/version/index.js'
import { get_sprints } from "@/network/quick/index.js";
import { get_filetype_List } from "@/network/fileType/index.js";
import { get_status_list } from "@/network/workflow/index.js";
import {
  get_allProjectMember
} from "@/network/mindmap/index.js";

const store_project = {
  state: {
    execution_status: sessionRead('_execution_status') ? JSON.parse(sessionRead('_execution_status')) : {},
    status: sessionRead('_status') ? JSON.parse(sessionRead('_status')) : {},
    testPlan: sessionRead('_testPlan') ? JSON.parse(sessionRead('_testPlan')) : {},
    version: sessionRead('_version') ? JSON.parse(sessionRead('_version')) : {},
    sprint: sessionRead('_sprint') ? JSON.parse(sessionRead('_sprint')) : {},
    baseLine: sessionRead('_baseLine') ? JSON.parse(sessionRead('_baseLine')) : {},
    fileType: sessionRead('_fileType') ? JSON.parse(sessionRead('_fileType')) : {},
    project_user_list: sessionRead('_project_user_list') ? JSON.parse(sessionRead('_project_user_list')) : {},
    ShowHara: sessionRead('_ShowHara') ? sessionRead('_ShowHara') : false,
    showDfmea: sessionRead('_showDfmea') ? sessionRead('_showDfmea') : false,
    showTARA: sessionRead('_showTARA') ? sessionRead('_showTARA') : false,
    showHARA: sessionRead('_showHARA') ? sessionRead('_showHARA') : false,
    projectRole: "" || sessionStorage.getItem("projectRole"),
  },
  mutations: {
    setExecutionStatus(state, obj) {
      state.execution_status = obj;
      sessionSave('_execution_status', JSON.stringify(obj));
    },
    setStatus(state, obj) {
      state.status = obj;
      sessionSave('_status', JSON.stringify(obj));
    },
    setTestPlan(state, obj) {
      state.testPlan = obj;
      sessionSave('_testPlan', JSON.stringify(obj));
    },
    setVersion(state, obj) {
      state.version = obj;
      sessionSave('_version', JSON.stringify(obj));
    },
    setSprint(state, obj) {
      state.sprint = obj;
      sessionSave('_sprint', JSON.stringify(obj));
    },
    setBaseLine(state, obj) {
      state.baseLine = obj;
      sessionSave('_baseLine', JSON.stringify(obj));
    },
    setFileType(state, obj) {
      state.fileType = obj;
      sessionSave('_fileType', JSON.stringify(obj));
    },
    setProjectUserList(state, obj) {
      state.project_user_list = obj;
      sessionSave('_project_user_list', JSON.stringify(obj));
    },
    set_execution_status: (state, data) => {
      state.execution_status = data;
    },
    setShowHara: (state, data) => {
      sessionSave('_ShowHara', data);
      state.ShowHara = data;
    },
    setShowDfmea: (state, data) => {
      sessionSave('_showDfmea', data);
      state.showDfmea = data;
    },
    setShowTARA: (state, data) => {
      sessionSave('_showTARA', data);
      state.showTARA = data;
    },
    setShowHARA: (state, data) => {
      sessionSave('_showHARA', data);
      state.showHARA = data;
    },
    SET_PROJECTROLE: (state, projectRole) => {
      sessionStorage.setItem("projectRole", projectRole);
      state.projectRole = projectRole;
    },
  },
  actions: {
    setExecutionStatus({ commit }, projectId) {
      testCaseStatus(projectId).then((res) => {
        const obj = {};
        res.forEach((item) => {
          obj[item.status] = item;
        })
        commit('setExecutionStatus', obj)
      })
    },
    setStatus({ commit }, projectId) {
      get_status_list(projectId).then((res) => {
        const obj = {};
        res.forEach((item) => {
          obj[item.statusId] = item;
        })
        commit('setStatus', obj)
      })
    },
    setTestPlan({ commit }, projectId) {
      get_test_plans(projectId).then((res) => {
        const obj = {};
        const list = [...res.NOT_STARTED || [], ...res.IN_PROGRESS || [], ...res.COMPLETE || []];
        list.forEach((item) => {
          obj[item.testPlanId] = item;
        })
        commit('setTestPlan', obj)
      })
    },
    setVersion({ commit }, projectId) {
      get_all_versions(projectId).then((res) => {
        const obj = {};
        res.forEach((item) => {
          obj[item.versionId] = item;
        })
        commit('setVersion', obj)
      })
    },
    setSprint({ commit }, projectId) {
      get_sprints({ projectId: projectId }).then((res) => {
        const obj = {};
        const list = [...(res.NOT_STARTED || []), ...(res.IN_PROGRESS || []), ...(res.ENDED || [])];
        list.forEach((item) => {
          obj[item.sprintId] = item;
        })
        commit('setSprint', obj)
      })
    },
    setBaseLine({ commit }, projectId) {
      get_baseline_list(projectId).then((res) => {
        const obj = {};
        const list = [...(res.NOT_STARTED || []), ...(res.ALREADY_STARTED || []), ...(res.COMPLETE || [])];
        list.forEach((item) => {
          obj[item.baselineId] = item;
        })
        commit('setBaseLine', obj)
      })
    },
    setFileType({ commit }, projectId) {
      get_filetype_List(projectId).then((res) => {
        const obj = {};
        res.forEach((item) => {
          obj[item.fileTypeId] = item;
        })
        commit('setFileType', obj)
      })
    },
    setProjectUserList({ commit }, projectId) {
      get_allProjectMember(projectId).then((res) => {
        const obj = {};
        res.forEach((item) => {
          obj[item.accountId] = item;
        })
        commit('setProjectUserList', obj)
        const current_user_id = JSON.parse(localStorage.getItem("store")).user
          .userAccountId;
        res.forEach((user) => {
          if (user.accountId === current_user_id) {
            commit("SET_PROJECTROLE", user.projectRole);
          }
        });
      })
    },
  }
}

export default store_project
