<template>
  <div
    v-if="is_show_node"
    v-loading="loading"
    class="node_wrap"
    style="height: 100%; position: relative"
    :element-loading-text="$t('loading.loading1')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <div class="main">
      <el-container>
        <el-header style="height: 60px">
          <home-topbar :is-node-detail-page="true"></home-topbar>
        </el-header>
        <el-main
          v-show="!limits_of_authority_disable('NODE_DETAIL_READ')"
          style="padding: 0"
        >
          <div style="height: 10px; width: 100%"></div>
          <el-row id="zdh_guide_golbal_tip5_1" class="header_content">
            <el-col :span="8" :offset="2" class="header_node">
              <!-- <div class="top-node-id">
                <span>{{ nodeKey }}</span>
              </div> -->
              <span style="display: flex">
                <div class="box_item_l">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="file_icon_name"
                    placement="top"
                  >
                    <span style="background-color: #014caa">
                      <i
                        v-if="file_icon !== '-1'"
                        class="iconfont"
                        :class="'icon-a-' + file_icon"
                        style="color: #fff"
                      ></i>
                      <span v-else style="color: white; font-weight: 700">{{
                        file_icon_name.slice(0, 1)
                      }}</span>
                    </span>
                  </el-tooltip>
                </div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="top"
                  :disabled="!is_show_origin_node"
                >
                  <div
                    slot="content"
                    style="cursor: pointer"
                    @click="route_origin"
                  >
                    {{ "Copy from " + origin_node }}
                  </div>
                  <div
                    class="box_item_r"
                    @mouseenter="copy_zdh_key_open"
                    @mouseleave="copy_zdh_key_close"
                  >
                    <span class="box_font"
                      >{{ nodeKey
                      }}<i
                        v-show="show_copy_zdh_key_file_node"
                        class="iconfont icon-a-Frame1 copy_zdh_key"
                        @click.stop="copy_zdh_key_file_node(nodeKey)"
                    /></span>
                  </div>
                </el-tooltip>
              </span>
              <i
                v-if="!isCollected"
                class="iconfont icon-shoucang hover-icon"
                style="cursor: pointer"
                :title="$t('knowledge.Favorite')"
                @click="toggleShoucang"
              />
              <i
                v-else
                class="iconfont icon-shoucangClick hover-icon"
                style="color: rgb(255, 195, 0); margin: 0 15px; cursor: pointer"
                :title="$t('knowledge.Unfavorite')"
                @click="toggleShoucang"
              />
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">
                  <div v-for="(ite, index) in node_path_zdh" :key="ite.nodeKey">
                    <span v-for="space in index > 20 ? 20 : index" :key="space">
                      &nbsp; &nbsp;</span
                    >
                    {{ ite.topic.slice(0, 20) }}
                  </div>
                </div>
                <i
                  class="iconfont icon-relation hover-icon"
                  style="
                    cursor: pointer;
                    color: rgb(48, 100, 143);
                    margin-right: 15px;
                  "
                  @click="to_mindmap()"
                ></i
              ></el-tooltip>
              <i
                v-if="documentInfo.documentId"
                class="iconfont icon-he_16zhengjianbianhaoguanli"
                style="cursor: pointer; color: rgb(48, 100, 143)"
                :title="$t('knowledge.JumpToKnowledgeBase')"
                @click="to_knowledge()"
              ></i>
              <el-tooltip
                v-if="is_show_diff"
                effect="dark"
                :content="$t('nodeDetail.message.suspectTip')"
                placement="bottom"
              >
                <span
                  style="
                    display: inline-block;
                    margin-right: 15px;
                    white-space: nowrap;
                    padding: 4px 10px;
                    font-size: 16px;
                    color: #3f5e82;
                    background-color: #d1d5e3;
                    border-radius: 6px;
                  "
                >
                  {{ $t("nodeDetail.message.suspect") }}
                </span>
              </el-tooltip>
              <i
                v-if="is_show_origin_node"
                class="iconfont icon-synchronization"
                style="
                  cursor: pointer;
                  color: rgb(48, 100, 143);
                  margin-right: 15px;
                "
                @click="to_sourceNodeSync()"
              ></i>
              <el-tooltip
                v-if="node_info.lock"
                effect="light"
                :content="
                  $t('scrum.newSprint.info1') +
                  `${lock_line.join(',')}` +
                  $t('scrum.newSprint.info2')
                "
                placement="bottom"
              >
                <div class="baseline_lock">
                  <i class="iconfont icon-locked"> </i>
                </div>
              </el-tooltip>
            </el-col>
            <el-col :span="7" class="node-name">
              <el-tooltip
                v-if="node_name_span"
                placement="bottom"
                :disabled="is_show_name"
              >
                <div slot="content">{{ name }}</div>
                <div class="node_name" @mouseenter="isShowTooltip">
                  <div
                    ref="node_name"
                    v-limits-of-authority="'NODE_EDIT'"
                    onselectstart="return false;"
                    style="-moz-user-select: none; white-space: break-spaces"
                    @dblclick="node_info.lock ? '' : edit(name)"
                  >
                    {{ name }}
                  </div>
                </div>
              </el-tooltip>
              <div v-else>
                <el-input
                  ref="input_panel"
                  v-model="input_name"
                  type="textarea"
                  :rows="2"
                  :placeholder="$t('placeholder.input')"
                  @keyup.enter="away"
                  @blur="away()"
                ></el-input>
              </div>
            </el-col>
            <el-col
              v-if="cascader_show"
              :span="8"
              :pull="1"
              class="change_require"
            >
              <el-cascader
                v-if="node_info.lock"
                ref="branch_ref"
                v-model="branch_model"
                class="highlight_cascader"
                size="small"
                filterable
                clearable
                popper-class="cascader_class"
                :options="branch_options"
                @visible-change="branch_visible_change"
                @change="branch_change"
              >
                <template slot-scope="{ data }">
                  <div>
                    <i
                      v-if="branch_map[data.value]"
                      class="iconfont"
                      :class="branch_map[data.value]"
                      style="margin-right: 10px; font-size: 15px"
                    ></i>
                    <span
                      :style="{
                        marginLeft: data.label == '删除' ? '-8px' : '0',
                      }"
                      >{{ data.label }}</span
                    >
                  </div>
                </template>
              </el-cascader>
              <el-cascader
                v-if="node_info.lock"
                ref="merge_ref"
                v-model="merge_model"
                class="highlight_cascader"
                clearable
                filterable
                size="small"
                :options="merge_options"
                @visible-change="merge_visible_change"
                @change="merge_change"
              >
              </el-cascader>
            </el-col>
            <el-col v-if="!node_info.lock" :span="2" :pull="2">
              <el-button
                size="mini"
                type="primary"
                style="height: 27px; line-height: 15px; width: 100px"
                @click="saveNodeVersion"
                >{{ $t("baseline.archive.title1") }}</el-button
              >
            </el-col>
            <el-col :span="2" :pull="1">
              <node-status-select
                v-if="node_status_show"
                ref="node_status_select"
                :node-info="node_info"
                :node-key="nodeKey"
                @show_workflow="show_workflow"
              ></node-status-select>
            </el-col>

            <el-col
              :span="1"
              :pull="1"
              style="right: 3%; margin-left: 10px; line-height: 28px"
            >
              <el-popover
                placement="bottom"
                width="300"
                trigger="click"
                popper-class="popper"
              >
                <div style="margin: 0 12px">
                  <div style="color: rgba(0, 76, 170, 1); margin-top: 12px">
                    {{ $t("review.tip7") }}
                  </div>
                  <div v-for="(item, index) in review_list" :key="index">
                    <el-tooltip
                      :disabled="!isShowTip[index]"
                      effect="dark"
                      placement="top-start"
                    >
                      <div slot="content">
                        <span>{{ item.content }}</span>
                      </div>
                      <p
                        style="
                          color: rgba(128, 128, 128, 1);
                          overflow: hidden;
                          width: 100%;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                        "
                        @mouseenter="visibilityChange($event, index)"
                      >
                        {{ index + 1 + "、" + item.content }}
                      </p>
                    </el-tooltip>
                  </div>
                </div>
                <span slot="reference">
                  <i
                    v-show="is_show_review"
                    class="iconfont icon-a-zhankai2 hover-icon"
                    style="cursor: pointer; color: rgb(48, 100, 143)"
                    @click="show_review"
                  />
                </span>
              </el-popover>
            </el-col>
          </el-row>
          <el-row class="node-detail-body">
            <el-col class="scrollbar-area">
              <el-scrollbar style="height: 100%">
                <div
                  ref="container"
                  class="container zdh_dot_point"
                  @click="blur_all($event)"
                >
                  <div
                    style="
                      position: absolute;
                      top: 18px;
                      left: 93.2%;
                      z-index: 10;
                    "
                  >
                    <create-new-file :node_info="node_info">
                      <i
                        class="iconfont icon-tianjia hover-icon"
                        style="
                          font-size: 26px;
                          vertical-align: middle;
                          color: rgb(48, 100, 143);
                        "
                      />
                    </create-new-file>
                  </div>

                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      left: 93.2%;
                      z-index: 10;
                    "
                  >
                    <div
                      v-for="item in global_zdh_dot"
                      :key="item"
                      style="cursor: pointer"
                      @click="go_to_dot_zdh(item)"
                    >
                      <el-button
                        v-if="global_zdh_icon == item"
                        size="mini"
                        circle
                        style="
                          background: rgba(24, 79, 169, 0.6);
                          border-color: rgba(24, 79, 169, 0.6);
                        "
                      ></el-button>
                      <el-button
                        v-else
                        size="mini"
                        circle
                        style="
                          background: hsla(0, 0%, 80%, 0.6);
                          border-color: hsla(0, 0%, 80%, 0.6);
                        "
                      ></el-button>
                    </div>
                  </div>

                  <el-row id="zdh_guide_golbal_tip5_2">
                    <el-col :span="20" :offset="2" class="main-collapse">
                      <node-base-info-row
                        v-if="all_project_member_show"
                        ref="base-info"
                        :node-info="node_info"
                        :project-id="projectId"
                        :members="all_project_member"
                      ></node-base-info-row>
                    </el-col>
                  </el-row>
                  <el-row id="zdh_guide_golbal_tip5_3" style="margin-top: 35px">
                    <el-col :span="20" :offset="2" class="main-collapse">
                      <node-release-info-row
                        v-if="node_status_show"
                        ref="release-info"
                        :node-key="nodeKey"
                        :node-info="node_info"
                      ></node-release-info-row>
                    </el-col>
                  </el-row>
                  <el-row v-if="file_test" style="margin-top: 35px">
                    <el-col :span="20" :offset="2" class="main-collapse">
                      <node-test-plan
                        :node-key="nodeKey"
                        :lock="node_info.lock"
                        :node-uuid="node_info.nodeUuid"
                      ></node-test-plan>
                    </el-col>
                  </el-row>
                  <el-row id="zdh_guide_golbal_tip5_4" style="margin-top: 35px">
                    <el-col :span="20" :offset="2" class="main-collapse">
                      <gantt-node-detail-row
                        :node-key="nodeKey"
                      ></gantt-node-detail-row>
                    </el-col>
                  </el-row>
                  <el-row id="zdh_guide_golbal_tip5_5" style="margin-top: 35px">
                    <el-col :span="20" :offset="2" class="main-collapse">
                      <node-description-row
                        :key="f5"
                        :lock="node_info.lock"
                        :project-id="projectId"
                        :node-key="nodeKey"
                        :description="node_description"
                        :attachments="attachment_list"
                        @update_description_content="update_description_content"
                      ></node-description-row>
                    </el-col>
                  </el-row>
                  <el-row id="zdh_guide_golbal_tip5_6" style="margin-top: 35px">
                    <el-col :span="20" :offset="2" class="main-collapse">
                      <relation-graph-row
                        v-if="node_status_show"
                        :node_key="nodeKey"
                        :node-info="node_info"
                      ></relation-graph-row>
                    </el-col>
                  </el-row>
                  <el-row
                    v-if="show_third_web && await_node_info"
                    style="margin-top: 35px"
                  >
                    <el-col :span="20" :offset="2" class="main-collapse">
                      <NodeThirdWebRow
                        :node_key="nodeKey"
                        :node-info="node_info"
                      ></NodeThirdWebRow>
                    </el-col>
                  </el-row>
                  <el-row style="margin-top: 35px">
                    <el-col
                      id="zdh_guide_golbal_tip5_7"
                      :span="20"
                      :offset="2"
                      class="main-collapse"
                    >
                      <activity-row
                        v-if="all_project_member_show"
                        ref="ar"
                        :members="all_project_member"
                        :node-key="nodeKey"
                        :node-uuid="node_info.nodeUuid"
                        :file-test="file_test"
                        :node-info="node_info"
                      ></activity-row>
                    </el-col>
                  </el-row>
                </div>
              </el-scrollbar>
            </el-col>
          </el-row>
        </el-main>
        <div v-show="limits_of_authority_disable('NODE_DETAIL_READ')">
          <no-permission></no-permission>
        </div>
      </el-container>
      <view-workflow
        v-if="workflow_dialog_visible && layerNumber && config.fileTypeId"
        :file-type-id="config.fileTypeId"
        :layer-number="layerNumber"
      ></view-workflow>
      <div
        v-if="node_info.lock"
        ref="request_button"
        class="request_position"
        @click="add_request"
      >
        <i
          ref="request_button"
          class="iconfont icon-add"
          style="margin-right: 10px; color: rgb(48, 100, 143)"
        ></i
        ><span>{{ $t("addNodeDetail.title") }}</span>
      </div>
    </div>
    <el-dialog
      :title="merge_dialog_title"
      :visible.sync="merge_visible"
      width="40%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div
        class="modal_line"
        :style="{
          'margin-bottom': merge_type === '1' ? '24px' : 0,
        }"
      >
        <el-form
          ref="request_form"
          :model="request_form"
          :rules="
            merge_type === '4' && !switchFlag ? reques_rules4 : reques_rules
          "
          label-width="110px"
          style="max-height: 340px; overflow-y: scroll; padding-left: 10px"
        >
          <el-form-item prop="name">
            <div slot="label">
              {{ $t("addNodeDetail.lab1") }}
            </div>
            <el-input
              v-if="merge_type === '4'"
              v-model="request_form.name"
            ></el-input>
            <div v-else style="text-align: left; margin-left: 10px">
              {{ request_form.name }}
            </div>
          </el-form-item>
          <el-form-item prop="reviewerList">
            <div slot="label">
              {{ $t("addNodeDetail.lab2") }}
            </div>
            <div
              v-if="(merge_type === '4' && switchFlag) || merge_type !== '4'"
              class="reviewer_list"
            >
              <span
                v-for="(item, index) in build_users_list"
                :key="index"
                class="reviewer"
                >{{ item.label }}</span
              >
              <label
                v-if="merge_type !== '4' && build_users_list.length"
                style="
                  font-size: 16px;
                  color: rgba(42, 130, 228, 1);
                  float: right;
                  cursor: pointer;
                "
                @click="show_review_results"
              >
                {{ $t("addNodeDetail.lab3") }}
              </label>
              <span
                v-if="!switchFlag && !build_users_list.length"
                style="color: red"
                >{{ $t("addNodeDetail.info") }}</span
              >
            </div>
            <div v-if="merge_type === '4' && !switchFlag">
              <el-select
                v-model="request_form.reviewerList"
                :placeholder="$t('addNodeDetail.place')"
                multiple
                filterable
                style="width: 100%"
              >
                <el-option-group
                  v-for="(group, index) in groupandUser"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="user in group.options"
                    :key="user.value"
                    :label="user.label"
                    :value="user.value + index"
                  >
                  </el-option>
                </el-option-group>
                <!-- <el-option
                  v-for="opt in users_list"
                  v-show="!opt.deleted"
                  :key="opt.value"
                  :label="opt.label"
                  :value="opt.value"
                >
                </el-option> -->
              </el-select>
            </div>
          </el-form-item>
          <el-form-item :label="$t('addNodeDetail.lab4')">
            <el-input
              v-if="merge_type === '4'"
              v-model="request_form.description"
              type="textarea"
            ></el-input>
            <div v-else style="text-align: left; margin-left: 10px">
              {{ request_form.description }}
            </div>
          </el-form-item>
          <el-form-item v-if="merge_type === '4'" prop="relation_node">
            <div slot="label">{{ $t("mindmap.ChangeImpactScope") }}</div>
            <div style="text-align: left; margin-left: 10px">
              <span
                v-for="(item, index) in relation_node"
                :key="index"
                style="color: rgb(42, 130, 228); cursor: pointer"
                @click="jumpToNode(item)"
              >
                {{ item.nodeKey }},
              </span>
            </div>
          </el-form-item>
          <el-form-item v-else prop="accountRelation_node">
            <div slot="label">{{ $t("mindmap.ChangeImpactScope") }}</div>
            <div style="text-align: left; margin-left: 10px">
              <span
                v-for="(item, index) in accountRelation_node"
                :key="index"
                style="cursor: pointer"
                :style="{
                  color: item.isRelation ? '#ff9300' : 'rgb(42, 130, 228)',
                }"
                @click="jumpToNode(item)"
              >
                {{ item.nodeKey }},
              </span>
            </div>
          </el-form-item>
          <el-form-item prop="impactAnalysis">
            <div slot="label">
              {{ $t("mindmap.ChangeImpactAnalysis") }}
            </div>
            <el-input
              v-if="merge_type === '4'"
              v-model="request_form.impactAnalysis"
              type="textarea"
            ></el-input>
            <div v-else style="text-align: left; margin-left: 10px">
              {{ request_form.impactAnalysis }}
            </div>
          </el-form-item>
          <el-form-item prop="otherRelevantDocuments">
            <div slot="label">
              {{ $t("mindmap.otherRelevantDocuments") }}
            </div>
            <el-upload
              v-if="merge_type === '4'"
              action="#"
              multiple
              :http-request="upload_attachment_handler"
              :on-remove="handleRemove"
              :show-file-list="true"
              :file-list="copyAttachmentList"
              style="text-align: left"
            >
              <el-button size="small" type="primary"
                >{{ $t("nodeDetail.description.info2") }}
              </el-button>
            </el-upload>
            <div v-else style="text-align: left; margin-left: 10px">
              <a
                v-for="(item, index) in request_form.attachmentList"
                :key="index"
                :href="item.url"
                target="_blank"
              >
                {{ item.name }}
                <br />
              </a>
            </div>
          </el-form-item>
          <el-form-item
            v-for="(item, index) in fieldReferenceList"
            :key="index"
            :required="item.required"
            :prop="item.fieldItemDto ? item.fieldItemDto.fieldId : ''"
            :show-message="false"
            :rules="[{ required: item.required, trigger: ['blur', 'change'] }]"
          >
            <div slot="label">
              <el-tooltip
                class="item"
                effect="dark"
                :content="
                  item.fieldItemDto ? item.fieldItemDto.name : item.name
                "
                placement="top-end"
                v-ak-tooltip-auto-show
              >
                <span
                  style="
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    width: 110px;
                  "
                >
                  {{ item.fieldItemDto ? item.fieldItemDto.name : item.name }}
                </span>
              </el-tooltip>
            </div>
            <el-input
              v-if="
                merge_type === '4' &&
                (item.fieldItemDto.fieldType == 'TEXT' ||
                  item.fieldItemDto.fieldType == 'LINK')
              "
              :placeholder="$t('placeholder.input')"
              v-model="request_form[item.fieldItemDto.fieldId]"
            >
            </el-input>
            <el-input
              v-if="
                merge_type === '4' && item.fieldItemDto.fieldType == 'NUMBER'
              "
              v-model="request_form[item.fieldItemDto.fieldId]"
              type="number"
              :placeholder="$t('placeholder.number')"
            >
            </el-input>
            <el-input
              v-if="
                merge_type === '4' && item.fieldItemDto.fieldType == 'TEXTAREA'
              "
              v-model="request_form[item.fieldItemDto.fieldId]"
              type="textarea"
              autosize
              :placeholder="$t('placeholder.input')"
            >
            </el-input>
            <el-date-picker
              v-if="merge_type === '4' && item.fieldItemDto.fieldType == 'DATE'"
              v-model="request_form[item.fieldItemDto.fieldId]"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              type="date"
              :placeholder="$t('placeholder.date')"
            >
            </el-date-picker>

            <el-select
              v-if="
                merge_type === '4' && item.fieldItemDto.fieldType == 'SELECT'
              "
              v-model="request_form[item.fieldItemDto.fieldId]"
              style="width: 100%"
              filterable
              clearable
            >
              <el-option
                v-for="opt in item.fieldItemDto.extraData"
                :key="opt.value"
                :label="opt.label"
                :value="opt.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-if="
                merge_type === '4' &&
                item.fieldItemDto.fieldType == 'SELECT_MULTI'
              "
              v-model="request_form[item.fieldItemDto.fieldId]"
              style="width: 100%"
              multiple
              filterable
            >
              <el-option
                v-for="opt in item.fieldItemDto.extraData"
                :key="opt.value"
                :label="opt.label"
                :value="opt.value"
              >
              </el-option>
            </el-select>
            <!-- 单用户下拉框 -->
            <el-select
              v-if="
                merge_type === '4' &&
                item.fieldItemDto.fieldType == 'SINGLE_USER_SELECT'
              "
              v-model="request_form[item.fieldItemDto.fieldId]"
              popper-class="user_select"
              :loading-text="$t('loading.loading2')"
              :filter-method="filterMethod"
              @visible-change="singleUserVisibleChange"
              style="width: 100%"
              filterable
              clearable
            >
              <el-option
                v-for="opt in filterUserList"
                v-show="!opt.deleted && !opt.hide"
                :key="opt.accountId"
                :label="opt.nickname"
                :value="opt.accountId"
              >
                <personal-avatar
                  class="avatar"
                  :avatar="opt.avatar"
                  :colorid="opt.accountId"
                  :nickname="opt.nickname"
                  :size="20"
                >
                </personal-avatar>
                <span class="select_item">{{ opt.nickname }}</span>
              </el-option>
            </el-select>
            <!-- 多用户下拉框 -->
            <el-select
              v-if="
                merge_type === '4' &&
                item.fieldItemDto.fieldType == 'MULTI_USER_SELECT'
              "
              v-model="request_form[item.fieldItemDto.fieldId]"
              popper-class="user_select"
              multiple
              filterable
              :filter-method="groupandUserfilterMethod"
              @visible-change="mutUserVisibleChange"
              :loading-text="$t('loading.loading2')"
              clearable
              style="width: 100%"
            >
              <el-option-group
                v-for="(group, index) in filterGroupandUser"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="user in group.options"
                  :key="user.value"
                  :label="user.label"
                  :value="user.value + index"
                >
                </el-option>
              </el-option-group>
            </el-select>
            <!-- 可创建条目 -->
            <el-select
              v-if="
                merge_type === '4' && item.fieldItemDto.fieldType == 'LABEL'
              "
              v-model="request_form[item.fieldItemDto.fieldId]"
              :placeholder="$t('placeholder.dropdown')"
              :loading-text="$t('loading.loading2')"
              multiple
              allow-create
              filterable
              style="width: 100%"
              @visible-change="get_Label_data"
            >
              <el-option
                v-for="opt in project_labels"
                :key="opt.value"
                :label="opt.label"
                :value="opt.value"
              >
              </el-option>
            </el-select>
            <div v-else style="text-align: left; margin-left: 10px">
              <!--展示的值 -->
              <div v-if="item.fieldType == 'LINK'">
                <a
                  :href="
                    item.value.indexOf('https://') === -1 ||
                    item.value.indexOf('http://') === -1
                      ? `https://${item.value}`
                      : item.value
                  "
                  target="_blank"
                  >{{ item.value }}</a
                >
              </div>
              <div v-if="item.fieldType == 'TEXT'">{{ item.value }}</div>
              <div v-if="item.fieldType == 'NUMBER'">{{ item.value }}</div>
              <div v-if="item.fieldType == 'TEXTAREA'">{{ item.value }}</div>
              <div v-if="item.fieldType == 'DATE'">{{ item.value }}</div>
              <div v-if="item.fieldType == 'SELECT'">
                {{ item.value }}
              </div>
              <div v-if="item.fieldType == 'SELECT_MULTI'">
                {{ item.value.join(",") }}
              </div>
              <div v-if="item.fieldType == 'SINGLE_USER_SELECT'">
                {{ matchUserNickname(item.value) }}
              </div>
              <div v-if="item.fieldType == 'MULTI_USER_SELECT'">
                {{ getAlluserNickName(item.value) }}
              </div>
              <div v-if="item.fieldType == 'LABEL'">
                {{ item.value.join(",") }}
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="table_border">
          <el-table
            :height="300"
            :data="request_tableData"
            header-row-class-name="header_row_class"
            style="width: 100%"
          >
            <el-table-column
              prop="field"
              align="center"
              :label="$t('addNodeDetail.field')"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="old"
              align="center"
              :label="$t('addNodeDetail.now')"
              width="180"
            >
              <template slot-scope="scope">
                <span
                  v-if="scope.row.field === $t('addNodeDetail.desc')"
                  v-html="scope.row.old"
                ></span>
                <span v-else-if="scope.row.fieldType == 'DRAWIO'">
                  <el-image
                    v-if="scope.row.old && scope.row.old.length === 1"
                    style="width: 100px; height: 100px"
                    :src="scope.row.old[0]"
                    :preview-src-list="scope.row.old"
                  >
                  </el-image>
                  <span
                    v-if="scope.row.old && scope.row.old.length > 1"
                    class="img-viewer-text"
                    @click="showImgViewer(scope.row.old)"
                    >+{{ scope.row.old.length }}</span
                  >
                </span>
                <span v-else-if="scope.row.fieldType == 'attachments'">
                  <file-list
                    ref="fileList"
                    :files="scope.row.old"
                    :project-id="projectId"
                    :node-key="nodeKey"
                    :deletebtn="false"
                    :show_all="true"
                  ></file-list>
                </span>

                <span v-else-if="scope.row.fieldType === 'DATE'">
                  {{
                    scope.row.old
                      ? new Date(scope.row.old).Format("yyyy-MM-dd")
                      : ""
                  }}</span
                >
                <span v-else-if="scope.row.fieldType === 'SELECT_MULTI'">
                  {{
                    typeof scope.row.old === "object"
                      ? scope.row.old.join("，")
                      : scope.row.old
                  }}
                </span>
                <span
                  v-else-if="
                    scope.row.field === $t('nodeDetail.testStep.testStep')
                  "
                >
                  <view-test-step :data="scope.row.old"></view-test-step>
                </span>
                <span v-else>{{
                  scope.row.fieldType === "SINGLE_USER_SELECT" ||
                  scope.row.fieldType === "MULTI_USER_SELECT"
                    ? get_user_name(scope.row.old)
                    : scope.row.old
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="new"
              align="center"
              :label="$t('addNodeDetail.change')"
            >
              <template slot-scope="scope">
                <span
                  v-if="scope.row.field === $t('addNodeDetail.desc')"
                  v-html="scope.row.new"
                ></span>
                <span v-else-if="scope.row.fieldType == 'DRAWIO'">
                  <el-image
                    v-if="scope.row.new && scope.row.new.length === 1"
                    style="width: 100px; height: 100px"
                    :src="scope.row.new[0]"
                    :preview-src-list="scope.row.new"
                  >
                  </el-image>
                  <span
                    v-if="scope.row.new && scope.row.new.length > 1"
                    class="img-viewer-text"
                    @click="showImgViewer(scope.row.new)"
                    >+{{ scope.row.new.length }}</span
                  >
                </span>
                <span v-else-if="scope.row.fieldType == 'attachments'">
                  <file-list
                    ref="fileList"
                    :files="scope.row.new"
                    :project-id="projectId"
                    :node-key="nodeKey"
                    :deletebtn="false"
                    :show_all="true"
                  ></file-list>
                </span>

                <span v-else-if="scope.row.fieldType === 'DATE'">
                  {{
                    scope.row.new
                      ? new Date(scope.row.new).Format("yyyy-MM-dd")
                      : ""
                  }}</span
                >
                <span v-else-if="scope.row.fieldType === 'SELECT_MULTI'">
                  {{
                    typeof scope.row.new === "object"
                      ? scope.row.new.join("，")
                      : scope.row.new
                  }}
                </span>
                <span
                  v-else-if="
                    scope.row.field === $t('nodeDetail.testStep.testStep')
                  "
                >
                  <view-test-step :data="scope.row.new"></view-test-step>
                </span>
                <span v-else>
                  {{
                    scope.row.fieldType === "SINGLE_USER_SELECT" ||
                    scope.row.fieldType === "MULTI_USER_SELECT"
                      ? get_user_name(scope.row.new)
                      : scope.row.new
                  }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span v-if="merge_type !== '1' && ifShowBtn == true" slot="footer">
        <span v-if="merge_type === '3'" class="footer_notice"
          >{{ $t("addNodeDetail.info2") }}<label>{{ threshold }}%</label>,
          {{ $t("addNodeDetail.info3") }}？</span
        >
        <el-button
          v-if="merge_type !== '3'"
          :type="merge_type === '4' ? '' : 'danger'"
          @click="merge_reject"
          >{{ merge_dialog_cancel }}</el-button
        >
        <el-button
          v-if="merge_type == '3'"
          :type="merge_type === '4' ? '' : 'danger'"
          @click="merge_confirm(false)"
          >{{ $t("addNodeDetail.message17") }}</el-button
        >
        <el-button type="primary" @click="merge_confirm(true)">{{
          merge_dialog_confirm
        }}</el-button>
      </span>
      <span
        v-if="
          merge_type === '1' &&
          request_form.status === 'REFUSED' &&
          ifShowBtn == true
        "
        slot="footer"
      >
        <el-button type="primary" @click="reCreatCopy">{{
          $t("mindmap.EditAgain")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('addNodeDetail.title')"
      :visible.sync="creat_visible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
    >
      <div class="modal_line">
        <el-form
          ref="creat_form"
          :model="creat_form"
          :rules="creat_rules"
          label-width="120px"
        >
          <el-form-item prop="name">
            <div slot="label">
              {{ $t("addNodeDetail.lab1") }}
            </div>
            <el-input
              v-model="creat_form.name"
              :validate-event="false"
            ></el-input>
          </el-form-item>
          <el-form-item prop="checked_group" class="bgzd">
            <div slot="label">
              {{ $t("addNodeDetail.lab5") }}
            </div>
            <div class="creat_all_check">
              <!-- <el-checkbox v-model="creat_check_all" :validate-event="false" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox> -->
            </div>
            <div
              class="creat_single_check"
              style="height: 200px; overflow: scroll"
            >
              <el-checkbox-group v-model="creat_form.checked_group">
                <p v-for="(item, index) in check_box_list" :key="index">
                  <el-checkbox :validate-event="false" :label="item.label">{{
                    item.label
                  }}</el-checkbox>
                </p>
              </el-checkbox-group>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="creat_visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="creat_confirm">{{
          $t("addNodeDetail.next")
        }}</el-button>
      </span>
    </el-dialog>
    <el-img-viewer
      v-if="isViewerVisible"
      style="z-index: 100000"
      class="viewer"
      :url-list="currentSrcList"
      :on-close="closeImgViewer"
    ></el-img-viewer>
    <el-dialog
      title=""
      :fullscreen="true"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="edit_visible"
      custom-class="edit_dialog"
    >
      <div class="edit_dialog_wrap">
        <p style="text-align: left">{{ $t("addNodeDetail.edit") }}</p>
        <div>
          <EditCopy
            v-if="edit_visible"
            ref="edit_copy"
            :edit-form="edit_form"
            :node-key="nodeKey"
            :project-id="projectId"
            :checked-copyid="checked_copyid"
          >
          </EditCopy>
        </div>
      </div>
      <span slot="footer" style="padding-right: 100px">
        <el-button @click="cancel_copy">{{ $t("btn.cancelBtn") }}</el-button>
        <el-button type="primary" @click="save_copy">{{
          $t("btn.saveBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('addNodeDetail.res')"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="review_results_view"
      custom-class="edit_dialog"
    >
      <div class="review_records_class modal_line">
        <div>
          <span>{{ $t("addNodeDetail.people") }}</span>
          <span>{{ $t("addNodeDetail.res") }}</span>
          <span>{{ $t("addNodeDetail.comment") }}</span>
        </div>
        <div v-for="(item, index) in review_records_list" :key="index">
          <span>{{ get_user_name(item.createdBy) }}</span>
          <span>
            <span v-if="item.approved">
              <i class="iconfont icon-approved"></i>
            </span>
            <span v-else>
              <i class="iconfont icon-refuse"></i>
            </span>
            {{
              item.approved ? $t("addNodeDetail.pass") : $t("addNodeDetail.rej")
            }}
          </span>
          <span>{{ item.message }}</span>
        </div>
      </div>
      <span slot="footer" style="padding-right: 100px"> </span>
    </el-dialog>
    <el-dialog
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="rejct_visible"
      custom-class="edit_dialog"
    >
      <div slot="title">
        {{ $t("addNodeDetail.reason") }}
      </div>
      <div>
        <el-form ref="reject_ref" :model="reject_obj" :rules="reject_rules">
          <el-form-item prop="reject_message">
            <el-input
              v-model="reject_obj.reject_message"
              type="text"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="rejct_visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="reject_confirm">{{
          $t("addNodeDetail.upload")
        }}</el-button>
      </span>
    </el-dialog>
    <AIdialog></AIdialog>
    <div class="goTop">
      <i class="iconfont icon-a-huaban18" @click="goTop"></i>
    </div>
    <!-- 移动文件弹窗 -->
    <source-node-sync-dialog
      ref="movedialog"
      :source-node="sourceNode"
      :node-info="node_info"
    ></source-node-sync-dialog>

    <el-dialog
      :title="$t('baseline.archive.title1')"
      :visible.sync="nodeVersionDialog"
      width="25%"
    >
      <el-form
        ref="nodeVersionform"
        :model="nodeVersionForm"
        label-width="140px"
      >
        <el-form-item
          :label="$t('node.version.versionName')"
          prop="version"
          :rules="{
            required: true,
            trigger: 'blur',
            message: $t('placeholder.notNull'),
          }"
        >
          <el-input v-model="nodeVersionForm.version"></el-input>
        </el-form-item>
        <el-form-item :label="$t('baseline.archive.remark')" prop="desc">
          <el-input v-model="nodeVersionForm.desc" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="nodeVersionDialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          :loading="saveVersionBtnLoading"
          @click="saveVersion"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { download_drawio } from "@/network/fileOperation/index.js";
import { limits_of_authority_disable } from "@/utils/limits";
import HomeTopbar from "@/components/homeTopbar";
import noPermission from "./components/noPermission";
import {
  get_node_use_node_key,
  get_allProjectMember,
  get_file,
  get_node_nodeOriginInfo,
  get_node_use_node_exist,
  get_relation_data,
  only_set_property,
  get_node_path,
  get_Labels,
} from "@/network/node/index.js";
import { get_group_user_list } from "@/network/addGroup/index.js";
import FileList from "@/components/mindmap/node_detail/FileList";
import NodeBaseInfoRow from "@/components/mindmap/node_detail/NodeBaseInfoRow.vue";
import NodeDescriptionRow from "../../components/mindmap/node_detail/NodeDescriptionRow.vue";
import GanttNodeDetailRow from "../../components/mindmap/node_detail/GanttNodeDetailRow.vue";
import NodeTestPlan from "../../components/mindmap/node_detail/NodeTestPlan.vue";
import NodeReleaseInfoRow from "../../components/mindmap/node_detail/NodeReleaseInfoRow.vue";
import RelationGraphRow from "../../components/mindmap/node_detail/RelationGraphRow.vue";
import NodeThirdWebRow from "../../components/mindmap/node_detail/NodeThirdWebRow.vue";
import ActivityRow from "../../components/mindmap/node_detail/ActivityRow.vue";
import NodeStatusSelect from "../../components/mindmap/node_detail/NodeStatusSelect.vue";
import ViewWorkflow from "@/components/mindmap/ViewWorkflow";
import CreateNewFile from "@/components/createNewFile";
import ViewTestStep from "../../components/mindmap/node_detail/ViewTestStep.vue";
import { get_component_license } from "@/network/license/index.js";
import {
  get_node_copies,
  set_node_copies,
  get_copies_detail,
  delete_copies_detail,
  commit_request,
  get_requests_list,
  get_requests_detail,
  reviewRecords,
  setreviewRecords,
  confirmMerge,
  inProgressBaselines,
} from "@/network/merge/index";
import { mapGetters } from "vuex";
import { getchangeRequestConfig } from "@/network/changeRequest/index.js";
import { get_filetype_List } from "@/network/fileType/index.js";
import EditCopy from "./components/EditCopy";
import {
  addIntoCollection,
  deleteCollection,
  getUserCollections,
} from "@/network/collections/index.js";
import { Message } from "element-ui";
import { getReviewConfig } from "@/network/collaboration/index.js";
import { mappingNodes } from "@/network/knowledge";
import { saveArchive } from "@/network/nodeVersion";
import AIdialog from "@/components/AIdialog";
import sourceNodeSyncDialog from "@/components/sourceNodeSyncDialog";
import html2canvas from "html2canvas";
import { ossAuthorization } from "@/utils/oss";
import { ulid } from "ulid";
export default {
  components: {
    HomeTopbar,
    noPermission,
    FileList,
    NodeBaseInfoRow,
    NodeReleaseInfoRow,
    NodeDescriptionRow,
    RelationGraphRow,
    NodeThirdWebRow,
    ActivityRow,
    NodeStatusSelect,
    ViewWorkflow,
    EditCopy,
    NodeTestPlan,
    GanttNodeDetailRow,
    AIdialog,
    CreateNewFile,
    ViewTestStep,
    sourceNodeSyncDialog,
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  props: {
    projectId: {
      type: String,
      default: "",
    },
    nodeKey: {
      type: String,
      default: "",
    },
  },
  provide() {
    return {
      nodeKey: this.nodeKey,
      projectId: this.projectId,
    };
  },
  inject: ["reload"],
  data() {
    return {
      show_third_web: false,
      f5: 0,
      groupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      filterGroupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      isShowTip: [],
      isViewerVisible: false,
      currentSrcList: [],
      isCollected: false,
      all_project_member_show: false,
      all_project_member: [],
      node_status_show: false,
      file_test: false,
      fileid: "",
      filekey: "",
      file_icon_name: "",
      // 选中的copyid
      checked_copyid: "",
      // 选中的merge
      check_changeRequestId: "",
      reject_obj: {
        reject_message: "",
      },
      cascader_show: true,
      value: "",
      name: "",
      show_name: false,
      node_status: {
        name: "",
        statusId: "",
        statusCategory: "",
      },
      node_info: {},
      node_description: "",
      await_node_info: false,
      attachment_list: [],
      config: {
        fileTypeId: null,
        layer: null,
        projectId: null,
      },
      is_show_review: false,
      review_list: [],
      //工作流弹窗
      layerNumber: null,
      workflow_dialog_visible: false,
      review_results_view: false,
      rejct_visible: false,
      node_path_zdh: [],
      //节点名称编辑框显示
      node_name_span: true,
      input_name: "",
      loading: true,
      branch_model: [],
      show_copy_zdh_key_file_node: false,
      merge_model: [],
      request_button_show: false,
      request_form: {},
      creat_form: {
        name: "",
        checked_group: [],
      },
      branch_map: {
        edit: "icon-branch_edit",
        merge: "icon-a-branch_merge1",
        delete: "icon-temp-delete",
        request: "icon-add",
      },
      branch_options: [],
      merge_options: [
        {
          label: this.$t("addNodeDetail.my"),
          value: this.$t("addNodeDetail.my"),
          children: [],
        },
        {
          label: this.$t("addNodeDetail.wait"),
          value: this.$t("addNodeDetail.wait"),
          children: [],
        },
        {
          label: this.$t("addNodeDetail.wait2"),
          value: this.$t("addNodeDetail.wait2"),
          children: [],
        },
      ],
      reques_rules: {
        name: [{ required: true, message: this.$t("addNodeDetail.message1") }],
        impactAnalysis: [
          {
            required: true,
            message: this.$t("mindmap.PleaseEnterChangeImpactAnalysis"),
          },
        ],
      },
      reques_rules4: {
        name: [{ required: true, message: this.$t("addNodeDetail.message1") }],
        reviewerList: [
          { required: true, message: this.$t("mindmap.PleaseSelectReviewers") },
        ],
        impactAnalysis: [
          {
            required: true,
            message: this.$t("mindmap.PleaseEnterChangeImpactAnalysis"),
          },
        ],
      },
      reject_rules: {
        reject_message: [
          { required: true, message: this.$t("addNodeDetail.message2") },
        ],
      },
      creat_rules: {
        name: [{ required: true, message: this.$t("addNodeDetail.message3") }],
        checked_group: [
          { required: true, message: this.$t("addNodeDetail.message4") },
        ],
      },
      merge_visible: false,
      creat_visible: false,
      edit_form: {},
      edit_visible: false,
      merge_dialog_title: this.$t("addNodeDetail.message5"),
      merge_dialog_cancel: this.$t("btn.cancelBtn"),
      merge_dialog_confirm: this.$t("addNodeDetail.upload"),
      merge_type: null,
      isIndeterminate: true,
      creat_check_all: false,
      loading_status: true,
      reviewerList: [],
      users_list: [],
      review_records_list: [],
      fieldReferenceList: [],
      threshold: 0,
      switchFlag: false,
      lock_line: [],
      file_icon: "",
      file_type_id: "",
      is_show_name: true,
      is_show_origin_node: false,
      is_show_diff: false,
      origin_node: "",
      origin_pid: "",
      is_show_node: false,
      // 关联节点数据
      relation_node: [],
      // 与本account关联的节点
      accountRelation_node: [],
      // 关联知识库数据
      documentInfo: {},
      // 从通知中心过来，表示是否要显示变更详情最下面的按钮
      ifShowBtn: true,
      // 测试步骤详情
      testProcedure: [],
      // 原节点数据结构
      sourceNode: {},
      nodeVersionDialog: false,
      saveVersionBtnLoading: false,
      nodeVersionForm: {
        version: "",
        desc: "",
      },
      global_zdh_dot: 0,
      global_zdh_icon: 1,
      filterUserList: [],
      project_labels: [],
      copyAttachmentList: [],
    };
  },
  computed: {
    ...mapGetters(["user_list", "user_group_list"]),
    request_tableData() {
      const arr = [];
      this.edit_form.systemFields &&
        this.edit_form.systemFields.forEach((sys) => {
          let obj = {};
          if (sys.fieldName === "topic") {
            obj = {
              field: this.$t("addNodeDetail.message6"),
              old: this.node_info.topic || "",
              new: sys.value || "",
            };
          } else if (sys.fieldName === "description") {
            obj = {
              field: this.$t("addNodeDetail.desc"),
              old: this.node_info.description || "",
              new: sys.value || "",
            };
          } else if (sys.fieldName === "storyPoint") {
            obj = {
              field: this.$t("addEditCopy.storyPoint"),
              old: this.node_info.storyPoint || "",
              new: sys.value || "",
            };
          } else if (sys.fieldName === "testDetails") {
            obj = {
              field: this.$t("nodeDetail.testStep.testStep"),
              old: this.testProcedure || [],
              new: sys.value || [],
            };
          } else if (sys.fieldName === "attachments") {
            obj = {
              field: this.$t("addNodeDetail.attachments"),
              fieldType: "attachments",
              old: this.node_info.attachments || "",
              new: sys.value || "",
            };
          }
          arr.push(obj);
        });
      this.edit_form.customFields &&
        this.edit_form.customFields.forEach((cus) => {
          if (cus.fieldType === "DRAWIO") {
            let record = {};
            record["beforeSrcList"] = [];
            record["afterSrcList"] = [];
            const reader = function (file) {
              return new Promise(function (resolve, reject) {
                const reader = new FileReader();
                reader.onload = function () {
                  resolve(reader.result);
                };
                reader.readAsText(file);
              });
            };
            const asyncFuncs = [];
            const getImgs = async function (list, type) {
              for (const item of list) {
                asyncFuncs.push(
                  download_drawio(item.data).then((res) => {
                    return { res, type };
                  })
                );
                // blobToDataURL(i, (baseData)=>{
                //   record[`${type}SrcList`].push(baseData);
                // })
              }
            };
            const oldItem = this.node_info.content.find((item) => {
              return item.name === cus.name;
            }).value;
            const curItem = cus.value;
            if (oldItem) {
              getImgs(
                typeof oldItem == "string" ? JSON.parse(oldItem) : oldItem,
                "before"
              );
            }
            if (curItem) {
              getImgs(
                typeof curItem == "string" ? JSON.parse(curItem) : curItem,
                "after"
              );
            }
            Promise.all(asyncFuncs).then((res) => {
              const afuncs = [];
              res.forEach((item) => {
                afuncs.push(
                  reader(item.res).then((res) => {
                    return { res, type: item.type };
                  })
                );
              });
              Promise.all(afuncs).then((res) => {
                res.forEach((item) => {
                  this.$nextTick(() => {
                    record[`${item.type}SrcList`].push(item.res);
                  });
                });
              });
            });

            const obj = {
              field: cus.name,
              old: record["beforeSrcList"],
              new: record["afterSrcList"],
              fieldType: cus.fieldType,
            };
            arr.push(obj);
          } else {
            const obj = {
              field: cus.name,
              old:
                this.node_info.content.find((item) => {
                  return item.fieldId === cus.fieldId;
                })?.value || "",
              new: cus.value || "",
              fieldType: cus.fieldType,
            };
            arr.push(obj);
          }
        });
      return arr;
    },
    build_users_list() {
      const users = [];
      this.reviewerList.forEach((reviewer) => {
        this.users_list.forEach((one) => {
          if (one.value === reviewer) {
            users.push(one);
          }
        });
      });
      return users;
    },
    check_box_list() {
      const arr = [];
      arr.push({
        fieldName: "topic",
        label: this.$t("addNodeDetail.message6"),
        value: this.node_info.topic,
      });
      arr.push({
        fieldName: "description",
        label: this.$t("addNodeDetail.desc"),
        value: this.node_info.description,
      });
      arr.push({
        fieldName: "storyPoint",
        label: this.$t("addNodeDetail.styPoint"),
        value: this.node_info.storyPoint,
      });
      if (this.file_test) {
        this.getTestProcedure();
        arr.push({
          fieldName: "testDetails",
          label: this.$t("nodeDetail.testStep.title"),
          value: this.testProcedure || [],
        });
      }
      // storyPoint
      this.node_info.content &&
        this.node_info.content.forEach((item) => {
          const obj = Object.assign(item, {
            label: item.name,
          });
          arr.push(obj);
        });
      return arr;
    },
    check_box_customFields() {
      const arr = [];
      const maparr = [
        this.$t("addNodeDetail.message6"),
        this.$t("addNodeDetail.desc"),
        this.$t("addNodeDetail.styPoint"),
        this.$t("nodeDetail.testStep.title"),
      ];
      this.creat_form.checked_group.forEach((label) => {
        this.check_box_list.forEach((box) => {
          if (label === box.label && maparr.indexOf(label) == -1) {
            arr.push(box);
          }
        });
      });
      return arr;
    },
    check_box_systemFields() {
      const arr = [];
      const maparr = [
        this.$t("addNodeDetail.message6"),
        this.$t("addNodeDetail.desc"),
        this.$t("addNodeDetail.styPoint"),
        this.$t("nodeDetail.testStep.title"),
      ];
      this.creat_form.checked_group.forEach((label) => {
        this.check_box_list.forEach((box) => {
          if (label === box.label && maparr.indexOf(label) != -1) {
            arr.push(box);
          }
        });
      });
      for (let i of arr) {
        if (i.fieldName == "description") {
          arr.push({
            fieldName: "attachments",
            value: this.attachment_list,
          });
          return arr;
        }
      }

      return arr;
    },
  },
  watch: {
    attachment_list() {
      this.f5 += 1;
    },
  },

  created() {
    get_component_license().then((res) => {
      for (let j of res) {
        if (j.feature == "THIRD_LINK") {
          this.show_third_web = true;
        }
      }
    });
    get_node_nodeOriginInfo(this.get_pid(), this.nodeKey).then((res) => {
      if (res.sourceNode) {
        this.sourceNode = res.sourceNode;
        this.is_show_origin_node = res.sourceNode.key;
        this.origin_node = res.sourceNode.key;
        this.origin_pid = res.sourceNode.projectId;
        this.is_show_diff = res.diff;
      }
    });
    this.getGroupList();
  },
  mounted() {
    this.mounted_fn();
    setTimeout(() => {
      const scrollableDiv = document.getElementById("scrollableDiv");

      // scrollableDiv.addEventListener("scroll", (event) => {
      //   console.log("Div 正在滚动");

      //   // 你可以在这里添加其他逻辑，例如获取滚动位置
      //   const scrollTop = scrollableDiv.scrollTop;
      //   console.log(`滚动位置：${scrollTop}`);
      // });

      // 选择所有目标 div 元素
      const divs = document.querySelector(".zdh_dot_point").children;

      // 创建 Intersection Observer
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = Array.from(divs).indexOf(entry.target);
            this.global_zdh_icon = index - 1;
          }
        });
      });

      // 观察每个 div
      divs.forEach((div) => observer.observe(div));
    }, 2000);
    // 选择要观察的元素
    get_node_path(this.get_pid(), this.nodeKey).then((res) => {
      this.node_path_zdh = res;
    });
  },
  beforeDestroy() {
    const doc = document.getElementsByClassName("el-scrollbar__wrap")[0];
    doc.removeEventListener("scroll", this.showTop);
  },
  methods: {
    handleRemove(file, fileList) {
      this.copyAttachmentList = fileList;
    },
    async upload_attachment_handler(params) {
      const self = this;
      let file = params.file;
      await ossAuthorization.call(self);
      self.client.put(`copyfile/${ulid()}${file.name}`, file).then((ossres) => {
        console.log(ossres);
        const obj = {
          name: file.name,
          url: `copyfile/${ossres.url.split("/copyfile/")[1]}`,
        };
        this.copyAttachmentList.push(obj);
      });
    },
    getAlluserNickName(value) {
      const obj = {};
      const arr = [];
      value.forEach((idIndex) => {
        const id = idIndex.slice(0, -1);
        if (this.user_list[id] && !obj[id]) {
          arr.push(this.user_list[id].nickname);
          obj[id] = true;
        }
        this.user_group_list.forEach((group) => {
          if (group.groupId === id) {
            group.accountIdList.forEach((userId) => {
              if (this.user_list[userId] && !obj[userId]) {
                arr.push(this.user_list[userId].nickname);
                obj[userId] = true;
              }
            });
          }
        });
      });
      return arr.join(",");
    },
    get_Label_data(val) {
      if (val) {
        get_Labels(this.get_pid())
          .then((res) => {
            this.project_labels = res;
          })
          .catch((err) => console.log(err));
      }
    },
    // 过滤方法
    getPinyinMatch(inputValue, list, label) {
      /* 输入内容拼音转换 */
      // 完整拼音
      const pyInput = pinyin(inputValue, {
        toneType: "none",
        type: "array",
      }).join("");
      // 拼音首字母
      const headerPyInput = pinyin(inputValue, {
        pattern: "first",
        type: "array",
      }).join("");
      return list.filter((e) => {
        const pyE = pinyin(e[label ? label : "nickname"], {
          toneType: "none",
          type: "array",
        })
          .join("")
          .slice(0, pyInput.length);
        // 列表中每项的拼音首字母
        const headerPyE = pinyin(e[label ? label : "nickname"], {
          pattern: "first",
          type: "array",
        })
          .join("")
          .slice(0, headerPyInput.length);
        // 过滤匹配
        return (
          e[label ? label : "nickname"].includes(inputValue) || pyE === pyInput
          // 是否提取首字母匹配
          // ||
          // headerPyE === headerPyInput
        );
      });
    },
    groupandUserfilterMethod(val) {
      if (!val) {
        this.filterGroupandUser = JSON.parse(JSON.stringify(this.groupandUser));
      } else {
        this.$set(
          this.filterGroupandUser[0],
          "options",
          this.getPinyinMatch(val, this.groupandUser[0].options, "label")
        );
        this.$set(
          this.filterGroupandUser[1],
          "options",
          this.getPinyinMatch(val, this.groupandUser[1].options, "label")
        );
      }
    },
    mutUserVisibleChange() {
      this.filterGroupandUser = JSON.parse(JSON.stringify(this.groupandUser));
    },
    filterMethod(val) {
      this.filterUserList = this.getPinyinMatch(val, this.user_list);
    },
    singleUserVisibleChange() {
      this.filterUserList = JSON.parse(JSON.stringify(this.user_list));
    },
    // 节点存档
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
          reject(new Error("blobToBase64 error"));
        };
      });
    },
    saveNodeVersion() {
      this.nodeVersionDialog = true;
    },
    async getImgUrl() {
      const self = this;
      html2canvas(self.$refs.container)
        .then((canvas) => {
          // 将canvas转为blob对象
          canvas.toBlob(async (blob) => {
            await ossAuthorization.call(self);
            self.client
              .put(
                `${
                  self.$store.state.project.tenantId
                }/nodeVersion/${ulid()}.png`,
                blob
              )
              .then((res) => {
                self.saveVersionBtnLoading = false;
                self.saveVersionFn(res.url);
              })
              .error((r) => {
                self.$message.error(
                  self.$t("homeTopBar.newProjectForm.message.uploadError")
                );
              });
          }, "image/png");
        })
        .catch(() => {});
    },
    saveVersion() {
      this.$refs["nodeVersionform"].validate(async (valid) => {
        if (valid) {
          this.saveVersionBtnLoading = true;
          const params = {
            projectId: this.get_pid(),
            data: [
              {
                nodeKey: this.node_info.key,
                remark: this.nodeVersionForm.desc,
                archiveName: this.nodeVersionForm.version,
              },
            ],
          };
          saveArchive(params)
            .then((res) => {
              this.saveVersionBtnLoading = false;
              this.nodeVersionDialog = false;
              this.$refs["ar"].reloadNodeVersion();
              this.$message({
                type: "success",
                message: this.$t("chart.message.saveSuccess"),
              });
            })
            .catch((err) => {
              this.saveVersionBtnLoading = false;
            });
          // this.getImgUrl();
        } else {
          return false;
        }
      });
    },
    saveVersionFn(url) {
      const snapshotImageUri = url;
      console.log(snapshotImageUri);
    },
    // 同步源节点
    to_sourceNodeSync() {
      this.$refs.movedialog.open([this.nodeKey]);
    },
    goTop() {
      const doc = document.getElementsByClassName("el-scrollbar__wrap")[0];
      if (doc) {
        doc.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    // 获取测试步骤详情
    getTestProcedure() {
      this.testProcedure = this.node_info.testDetails?.map((obj) => ({
        preConditions: obj.preConditions,
        testingProcedure: obj.testingProcedure,
        expectedResult: obj.expectedResult,
      }));
    },
    getGroupList() {
      const group = Object.values(this.user_group_list);
      const user = Object.values(this.user_list);
      const filteredArray = user.filter((obj) => obj.deleted == 0);
      filteredArray.map((item, index) => {
        this.groupandUser[0].options.push({
          value: item.accountId,
          label: item.nickname,
        });
      });
      group.map((item, index) => {
        this.groupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
      });
    },
    // 获取是否关联知识库
    getDocumentInfo() {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeKey,
      };
      mappingNodes(params).then((res) => {
        this.documentInfo = res;
      });
    },
    visibilityChange(event, index) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        // this.isShowTooltip[index] = true;
        this.$set(this.isShowTip, index, true);
      } else {
        // 否则为不溢出
        // this.isShowTooltip[index] = false;
        this.$set(this.isShowTip, index, false);
      }
    },
    show_review() {},
    route_origin() {
      const { href } = this.$router.resolve({
        path: `/${this.origin_pid}/nodes/key/${this.origin_node}`,
      });
      window.open(href, "_blank");
    },
    //判断三行出...
    isShowTooltip(e) {
      const clientHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;
      // 文字超出隐藏并弹出tooltip提示，文字未超出则不弹出tooltip提示的判断条件
      if (
        (scrollHeight > clientHeight &&
          this.$refs.node_name.parentNode.offsetWidth >=
            this.$refs.node_name.offsetWidth) ||
        (scrollHeight == clientHeight &&
          this.$refs.node_name.parentNode.offsetWidth >
            this.$refs.node_name.offsetWidth)
      ) {
        this.is_show_name = false;
      } else {
        this.is_show_name = true;
      }
    },
    toggleShoucang() {
      const data = [{ type: "node", value: this.nodeKey }];
      if (this.isCollected) {
        //取消收藏
        deleteCollection(this.projectId, data);
      } else {
        //添加收藏
        addIntoCollection(this.projectId, data);
      }
      this.isCollected = !this.isCollected;
    },
    limits_of_authority_disable,
    blur_all(event) {
      if (event.target.nodeName === "DIV") {
        this.$refs["base-info"].item_blur();
        this.$refs["release-info"].item_blur();
        this.$refs["node_status_select"].showWorkflow = false;
      }
    },
    to_mindmap() {
      const routeData = this.$router.resolve({
        path: `/${this.get_pid()}/myMind/${this.filekey}`,
        query: { node_id: this.node_info.id },
      });
      window.open(routeData.href, "_blank");
    },
    to_knowledge() {
      const routeData = this.$router.resolve({
        path: `/${this.get_pid()}/knowledge`,
        query: {
          documentId: this.documentInfo.documentId,
          elementId: this.documentInfo.lineId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    showTop($e) {
      const goTop = document.getElementsByClassName("goTop")[0];
      if ($e.target) {
        if ($e.target.scrollTop != 0) {
          goTop.style.display = "block";
        } else {
          goTop.style.display = "none";
        }
      }
    },
    async mounted_fn() {
      // 获取节点是否存在
      this.is_show_node = await get_node_use_node_exist(
        this.get_pid(),
        this.nodeKey
      );
      this.$nextTick(() => {
        const goTop = document.getElementsByClassName("goTop")[0];
        goTop.style.display = "none";
        const doc = document.getElementsByClassName("el-scrollbar__wrap")[0];
        if (doc) {
          doc.addEventListener("scroll", this.showTop);
        }
      });
      // 节点不存在,跳转文件页,弹出警告
      if (!this.is_show_node) {
        this.$router.push({
          path: `/${this.get_pid()}/home/my_file`,
        });
        Message({
          message: this.$t("mindmap.CurrentNodeDeleted"),
          type: "error",
          duration: 5 * 1000,
        });
        return;
      } else {
        // 节点存在
        let res;
        try {
          res = await get_node_use_node_key(this.get_pid(), this.nodeKey);
        } catch (err) {
          this.loading = false;
          setTimeout(() => {
            this.$router.push({
              path: `/${this.get_pid()}/home/my_file`,
            });
          }, 2000);
        }
        const mem = await get_allProjectMember(this.projectId);
        const collections = await getUserCollections("node");
        const collectionKeyList = [];
        if (collections.data) {
          collections.data.forEach((collection) => {
            collectionKeyList.push(collection.key);
          });
          if (collectionKeyList.indexOf(this.nodeKey) !== -1) {
            this.isCollected = true;
          } else {
            this.isCollected = false;
          }
        } else {
          this.isCollected = false;
        }
        this.all_project_member = mem;
        this.node_info = res;
        this.await_node_info = true;
        this.node_status_show = true;
        this.all_project_member_show = true;
        this.fileid = res.fileId;
        this.file_type_id = res.fileTypeId;
        get_filetype_List(this.get_pid()).then((res) => {
          for (let i of res) {
            if (i["fileTypeId"] == this.file_type_id) {
              this.file_icon = i.icon;
              this.file_icon_name = i.name;
              this.file_test = i.testCase;
            }
          }
          getReviewConfig(this.get_pid()).then((configs) => {
            // this.is_show_review
            this.review_list = [];
            configs.forEach((item) => {
              let has_file = false;
              item.fileTypeList.forEach((file) => {
                if (file.fileTypeId === this.file_type_id) {
                  this.is_show_review = true;
                  has_file = true;
                }
              });
              if (has_file) {
                this.review_list = this.review_list.concat(item.itemList);
              }
            });
          });
        });

        get_file(this.get_pid(), this.fileid).then((res) => {
          this.filekey = res.key;
        });
        this.name = res.topic;
        // this.node_status.statusId = res.status;
        // this.node_status.name = res.statusName;
        // this.node_status.statusCategory = res.statusCategory;
        Object.keys(this.config).forEach((name) => {
          this.config[name] = res[name];
        });

        this.layerNumber = res.layerNumber;
        this.propertyContent = res.content;
        this.node_description = res.description;
        this.attachment_list = res.attachments;
        this.loading = false;
        if (res.lock) {
          this.lock_line = [];
          const params = {
            projectId: this.get_pid(),
            nodeKey: this.nodeKey,
          };
          inProgressBaselines(params).then((res) => {
            res &&
              res.forEach((line) => {
                if (line.lock) {
                  this.lock_line.push(line.name);
                }
              });
          });
          this.get_allProjectMember();
          this.get_node_copies();
          this.getchangeRequestConfig();
          this.get_requests_list();
        } else {
          if (this.$route.query.changeRequests != undefined) {
            this.$message({
              type: "info",
              message: this.$t("knowledge.NodeunlockedexpiredTip"),
            });
          }
        }
        !limits_of_authority_disable("KNOWLEDGE_ACCESS")
          ? this.getDocumentInfo()
          : "";
      }
      setTimeout(() => {
        this.global_zdh_dot =
          document.querySelector(".zdh_dot_point").children.length - 2;
        // const elements = document.querySelectorAll(".main-container");
        // console.log(elements);

        // const observer = new IntersectionObserver(
        //   (entries) => {
        //     entries.forEach((entry) => {
        //       if (entry.isIntersecting) {
        //         const index = Array.from(elements).indexOf(entry.target);
        //         this.global_zdh_icon = index + 1;
        //         console.log(index);
        //       }
        //     });
        //   },
        //   {
        //     threshold: 0.5,
        //   }
        // );

        // elements.forEach((element) => observer.observe(element));

        //  this.global_zdh_icon = 1
      }, 1000);
    },
    go_to_dot_zdh(i) {
      this.global_zdh_icon = i;
      let c = document.querySelector(".zdh_dot_point").children[i + 1];
      c.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    update_description_content(description) {
      this.node_info.description = description;
    },
    // 查看详情
    show_review_results() {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeKey,
        changeRequestId: this.check_changeRequestId,
      };
      reviewRecords(params).then((res) => {
        this.review_records_list = res;
        this.review_results_view = true;
      });
    },
    get_user_name(params) {
      let newarr = typeof params === "object" ? [] : "";
      typeof params === "object"
        ? params.forEach((item) => {
            this.users_list.forEach((user) => {
              if (user.value === item) {
                newarr.push(user.label);
              }
            });
          })
        : this.users_list.forEach((user) => {
            if (user.value === params) {
              newarr = user.label;
            }
          });
      return typeof newarr === "object" ? newarr.join("，") : newarr;
    },
    // 获取所有成员
    get_allProjectMember() {
      get_allProjectMember(this.get_pid()).then((res) => {
        let my = {};
        let li = [];
        res.forEach((ele) => {
          let obj = {
            label: ele.nickname,
            value: ele.accountId,
            userAccountId: ele.accountId,
            avatar: ele.avatar,
            deleted: ele.deleted,
          };
          ele.accountId == this.$store.getters.userAccountId
            ? (my = obj)
            : li.push(obj);
        });
        this.users_list = [];
        this.users_list.push(my);
        this.users_list.push(...li);
      });
    },
    // 获取变更委员会配置
    getchangeRequestConfig() {
      getchangeRequestConfig(this.get_pid()).then((res) => {
        this.reviewerList = res.reviewerList;
        this.switchFlag = res.switchFlag;
        this.threshold = res.rule.threshold;
        this.fieldReferenceList = res.fieldReferenceList;
      });
    },
    // 获取副本列表
    get_node_copies() {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeKey,
      };
      get_node_copies(params).then((res) => {
        this.branch_options = [];
        res.forEach((item) => {
          const obj = {
            value: item.copyId,
            label: item.name,
            children: [
              {
                value: "edit",
                label: this.$t("addNodeDetail.message7"),
              },
              {
                value: "merge",
                label: this.$t("addNodeDetail.message8"),
              },
              {
                value: "delete",
                label: this.$t("btn.deleteBtn"),
              },
            ],
          };
          this.branch_options.push(obj);
        });
      });
    },
    // 复选框全选
    handleCheckAllChange(val) {
      this.creat_form.checked_group = [];
      if (val) {
        this.check_box_list.forEach((item) => {
          this.creat_form.checked_group.push(item.label);
        });
      }
      this.isIndeterminate = false;
    },
    /*判断事件流是否加载完毕，控制loading的显示与隐藏 */
    headCall: function (status_name) {
      //回调方法，接收子组件传的参数
      if (status_name) this.loading_status = false;
    },

    /*双击节点名进行编辑 */
    edit(name) {
      this.node_name_span = false;
      this.input_name = name;
      this.$nextTick(() => {
        this.$refs["input_panel"].focus();
      });
    },
    away() {
      this.node_name_span = true;
      if (!/^\s*$/.test(this.input_name)) {
        this.name = this.input_name;
      }
      /*访问接口进行节点名称修改 */
      get_node_use_node_key(this.get_pid(), this.nodeKey).then((res) => {
        this.node_info = res;
        let obj = this.node_info;
        obj["topic"] = this.name;
        const params = {
          projectId: this.get_pid(),
          nodeKey: res.key,
          data: {
            isCustom: false,
            fieldId: "topic",
            value: this.name,
          },
        };
        only_set_property(params).then((res) => {});
      });
    },

    show_workflow() {
      this.workflow_dialog_visible = true;
    },
    // 获取合并请求列表
    get_requests_list() {
      if (
        this.ifShowBtn &&
        this.$route.query.changeRequests &&
        this.$route.query.changeRelated == "true"
      ) {
        this.ifShowBtn = false;
        this.get_copies_detail_table([0, this.$route.query.changeRequests]);
      }
      const arr = ["INITIATED", "WAIT_REVIEW", "WAIT_CONFIRM"];
      const map = {
        APPROVED: this.$t("addNodeDetail.message9"),
        MERGED: this.$t("addNodeDetail.message10"),
        REFUSED: this.$t("addNodeDetail.message11"),
        REVIEWING: this.$t("addNodeDetail.message12"),
      };
      arr.forEach((type, index) => {
        const params = {
          nodeKey: this.nodeKey,
          projectId: this.get_pid(),
          showType: type,
        };
        this.merge_options[index].children = [];
        get_requests_list(params).then((res) => {
          res.forEach((req) => {
            const obj = {
              label: !index ? `${req.name}(${map[req.status]})` : req.name,
              value: req.changeRequestId,
            };
            this.merge_options[index].children.push(obj);
            if (type == "WAIT_REVIEW") {
              if (
                this.$route.query.changeRequests &&
                this.$route.query.changeRequests == req.changeRequestId
              ) {
                this.ifShowBtn = true;
                this.check_changeRequestId = this.$route.query.changeRequests;
                setTimeout(() => {
                  this.merge_type = "2";
                }, 2000);
                this.merge_dialog_title = this.$t("addNodeDetail.message5");
                this.merge_dialog_confirm = this.$t("addNodeDetail.message13");
                this.merge_dialog_cancel = this.$t("addNodeDetail.message14");
                this.get_copies_detail_table([
                  0,
                  this.$route.query.changeRequests,
                ]);
              }
            }
            if (type == "WAIT_CONFIRM") {
              if (
                this.$route.query.changeRequests &&
                this.$route.query.changeRequests == req.changeRequestId
              ) {
                this.ifShowBtn = true;
                this.check_changeRequestId = this.$route.query.changeRequests;
                this.merge_type = "3";
                this.merge_dialog_title = this.$t("addNodeDetail.message5");
                this.merge_dialog_confirm = this.$t("addNodeDetail.message8");
                this.merge_dialog_cancel = this.$t("addNodeDetail.message14");
                this.get_copies_detail_table([
                  0,
                  this.$route.query.changeRequests,
                ]);
              }
            }
            if (type == "INITIATED") {
              if (
                this.$route.query.changeRequests &&
                this.$route.query.changeRequests == req.changeRequestId
              ) {
                this.ifShowBtn = true;
                this.check_changeRequestId = this.$route.query.changeRequests;
                this.merge_type = "1";
                this.merge_dialog_title = this.$t("addNodeDetail.message5");
                this.merge_dialog_confirm = this.$t("addNodeDetail.message13");
                this.merge_dialog_cancel = this.$t("addNodeDetail.message14");
                this.get_copies_detail_table([
                  0,
                  this.$route.query.changeRequests,
                ]);
              }
            }
          });
        });
      });
    },
    reject_confirm() {
      this.$refs["reject_ref"].validate((valid) => {
        if (valid) {
          const params = {
            nodeKey: this.node_info.key,
            projectId: this.get_pid(),
            changeRequestId: this.check_changeRequestId,
            data: {
              approved: false,
              message: this.reject_obj.reject_message,
            },
          };
          setreviewRecords(params).then((res) => {
            this.rejct_visible = false;
            this.merge_visible = false;
            this.get_requests_list();
          });
        }
      });
    },
    merge_reject() {
      if (this.merge_type === "2") {
        this.rejct_visible = true;
      } else {
        this.merge_visible = false;
      }
    },
    async mergedUsergroup(arr, projectId) {
      let account = [];
      let group = [];
      if (arr) {
        arr.map((item, index) => {
          if (item.substr(-1) == 0) {
            account.push(item.substr(0, item.length - 1));
          } else {
            group.push(item.substr(0, item.length - 1));
          }
        });
      }
      const params = {
        projectId: projectId,
        accountIdList: account,
        groupIdList: group,
      };
      return await get_group_user_list(params);
    },
    getExtraFieldContentList(form) {
      const arr = [];
      this.fieldReferenceList.forEach((item) => {
        const obj = Object.assign({}, item.fieldItemDto, {
          fieldReferenceId: item.fieldReferenceId,
          required: item.required,
          orderNumber: item.sort,
        });
        obj.value = form[item.fieldItemDto.fieldId];
        arr.push(obj);
      });
      return arr;
    },
    async merge_confirm(e) {
      const meild = await this.mergedUsergroup(
        this.request_form.reviewerList,
        this.get_pid()
      );
      let replyList = this.request_form.reviewerList;
      if (this.merge_type === "4") {
        this.$refs["request_form"].validate((valid) => {
          if (valid) {
            let form = {
              description: this.request_form.description,
              impactAnalysis: this.request_form.impactAnalysis,
              name: this.request_form.name,
              reviewerList: meild,
              extraFieldContentList: this.getExtraFieldContentList(
                this.request_form
              ),
              attachmentList: this.copyAttachmentList,
            };
            this.request_form.reviewerList = replyList;
            const params = {
              nodeKey: this.node_info.key,
              projectId: this.get_pid(),
              copyId: this.checked_copyid,
              data: form,
            };
            commit_request(params).then((res) => {
              this.merge_visible = false;
              this.get_requests_list();
              this.get_node_copies();
            });
          } else {
            this.$message({
              type: "error",
              message: this.$t("chart.message.error"),
            });
          }
        });
      } else if (this.merge_type === "2") {
        const params = {
          nodeKey: this.node_info.key,
          projectId: this.get_pid(),
          changeRequestId: this.check_changeRequestId,
          data: {
            approved: true,
            message: "",
          },
        };
        setreviewRecords(params).then((res) => {
          this.merge_visible = false;
          this.mounted_fn();
          this.$router.push({
            path: `/${this.$route.params.projectId}/nodes/key/${this.$route.params.nodeKey}`,
          });
          this.reload();
          // this.$forceUpdate();
        });
      } else if (this.merge_type === "3") {
        const params = {
          nodeKey: this.node_info.key,
          projectId: this.get_pid(),
          changeRequestId: this.check_changeRequestId,
          agreed: e,
        };
        confirmMerge(params).then((res) => {
          this.merge_visible = false;
          this.mounted_fn();
          this.$router.push({
            path: `/${this.$route.params.projectId}/nodes/key/${this.$route.params.nodeKey}`,
          });
          this.reload();
          this.$forceUpdate();
        });
      }
    },
    // 重新创建副本
    reCreatCopy() {
      const params = {
        nodeKey: this.node_info.key,
        projectId: this.get_pid(),
        data: {
          name: this.edit_form.name,
          customFields: this.edit_form.customFields,
          systemFields: this.edit_form.systemFields,
        },
      };
      set_node_copies(params).then((res) => {
        this.get_node_copies();
        this.get_copies_detail(res);
      });
    },
    //创建节点副本
    set_node_copies() {
      const params = {
        nodeKey: this.node_info.key,
        projectId: this.get_pid(),
        data: {
          name: this.creat_form.name,
          customFields: this.check_box_customFields,
          systemFields: this.check_box_systemFields,
        },
      };
      set_node_copies(params).then((res) => {
        this.get_node_copies();
        this.get_copies_detail(res);
      });
    },
    // 保存节点副本
    save_copy() {
      this.$refs["edit_copy"].validate_form((valid) => {
        if (valid) {
          this.$refs["edit_copy"].edit_copies_detail((res) => {
            if (res) {
              this.get_node_copies();
              this.edit_visible = false;
              this.reload(); // 刷新数据
              // this.$forceUpdate();
            }
          });
        }
      });
    },
    copy_zdh_key_open() {
      this.show_copy_zdh_key_file_node = true;
    },
    copy_zdh_key_close() {
      this.show_copy_zdh_key_file_node = false;
    },
    copy_zdh_key_file_node(key) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(key);
      } else {
        const textarea = document.createElement("textarea");
        textarea.value = key;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      }

      this.$message({
        type: "success",
        message: this.$t("AI.tip14"),
      });
    },
    cancel_copy() {
      this.edit_visible = false;
      this.reload(); // 刷新数据
      // this.$forceUpdate();
    },
    async creat_confirm() {
      this.$refs["creat_form"].validate((valid) => {
        if (valid) {
          this.set_node_copies();
        }
      });
    },
    set_request_posotion(flag) {
      const button = this.$refs["request_button"];
      const dom = document.querySelector(".cascader_class");
      dom.appendChild(button);
      this.request_button_show = flag;
    },
    branch_visible_change(flag) {
      this.set_request_posotion(flag);
    },
    merge_visible_change(flag) {
      this.set_request_posotion(flag);
    },
    add_request() {
      this.merge_dialog_confirm = this.$t("addNodeDetail.upload");
      this.merge_dialog_cancel = this.$t("btn.cancelBtn");
      this.creat_visible = true;
      this.creat_form = {
        name: "",
        checked_group: [],
      };
    },
    // 获取副本详情
    get_copies_detail(copyId) {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.node_info.key,
        copyId: copyId,
      };
      get_copies_detail(params).then((res) => {
        this.edit_form = res;
        this.creat_visible = false;
        this.checked_copyid = res.copyId;
        this.edit_visible = true;
      });
    },
    // 删除副本节点
    delete_copies_detail(copyId) {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.node_info.key,
        copyId: copyId,
      };
      delete_copies_detail(params).then((res) => {
        this.get_node_copies();
      });
    },
    //分支下拉框change事件
    branch_change(val) {
      this.checked_copyid = val[0];
      if (val.length >= 2 && val[1] === "edit") {
        this.get_copies_detail(val[0]);
      } else if (val.length >= 2 && val[1] === "delete") {
        this.delete_copies_detail(val[0]);
      } else if (val.length >= 2 && val[1] === "merge") {
        this.getchangeRequestConfig();
        const params = {
          projectId: this.get_pid(),
          nodeKey: this.node_info.key,
          copyId: val[0],
        };
        get_copies_detail(params).then((res) => {
          this.edit_form = res;
          this.merge_type = "4";
          this.merge_dialog_title = this.$t("addNodeDetail.message15");
          this.merge_dialog_confirm = this.$t("addNodeDetail.upload");
          this.merge_dialog_cancel = this.$t("btn.cancelBtn");
          this.merge_visible = true;
          this.request_form = {};
          this.$nextTick(() => {
            this.$refs["request_form"]
              ? this.$refs["request_form"].clearValidate()
              : "";
          });
        });
        this.get_relation_data();
      }
      this.branch_model.splice(0);
      this.cascader_show = false;
      setTimeout(() => {
        this.cascader_show = true;
      });
    },
    // 获取关联节点
    get_relation_data() {
      get_relation_data(this.get_pid(), this.node_info.key)
        .then((res) => {
          this.relation_node = res.nodeRelationGraphList.filter((item) => {
            return item.nodeKey !== this.node_info.key;
          });
        })
        .catch((err) => console.log(err));
    },
    // 点击关联节点跳转
    jumpToNode(params) {
      const { href } = this.$router.resolve({
        path: `/${params.projectId}/nodes/key/${params.nodeKey}`,
      });
      window.open(href, "_blank");
    },
    get_copies_detail_table(leaf) {
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeKey,
        changeRequestId: leaf[1],
      };
      get_requests_detail(params).then((res) => {
        this.request_form = res;
        this.fieldReferenceList = res.extraFieldContentList;
        if (!this.switchFlag) {
          this.reviewerList = res.reviewerList;
        }
        const params = {
          projectId: this.get_pid(),
          nodeKey: this.node_info.key,
          copyId: res.copyId,
        };
        get_copies_detail(params).then((res) => {
          this.edit_form = res;
          this.merge_visible = true;
          this.$nextTick(() => {
            this.$refs["request_form"]
              ? this.$refs["request_form"].clearValidate()
              : "";
          });
          get_relation_data(this.get_pid(), this.node_info.key).then((res) => {
            let tempList = res.nodeRelationGraphList.filter((item) => {
              return item.nodeKey !== this.node_info.key;
            });
            tempList.forEach((element) => {
              element.isRelation = false;
            });
            tempList
              .filter((item) => {
                return (
                  item.assignee == this.$store.getters.userAccountId ||
                  item.createdBy == this.$store.getters.userAccountId
                );
              })
              .forEach((item) => {
                tempList.forEach((element) => {
                  if (element.nodeKey == item.nodeKey) {
                    element.isRelation = true;
                  }
                });
              });
            this.accountRelation_node = tempList;
          });
        });
      });
    },
    // 请求合并下拉框事件
    merge_change(leaf) {
      this.check_changeRequestId = leaf[1];
      if (leaf[0] === this.$t("addNodeDetail.my")) {
        this.merge_type = "1";
        this.merge_dialog_title = this.$t("addNodeDetail.message16");
        this.get_copies_detail_table(leaf);
      } else if (leaf[0] === this.$t("addNodeDetail.wait")) {
        this.merge_type = "2";
        this.merge_dialog_title = this.$t("addNodeDetail.message5");
        this.merge_dialog_confirm = this.$t("addNodeDetail.message13");
        this.merge_dialog_cancel = this.$t("addNodeDetail.message14");
        this.get_copies_detail_table(leaf);
      } else {
        this.merge_type = "3";
        this.merge_dialog_title = this.$t("addNodeDetail.message5");
        this.merge_dialog_confirm = this.$t("addNodeDetail.message8");
        this.merge_dialog_cancel = this.$t("addNodeDetail.message17");
        this.get_copies_detail_table(leaf);
      }
      this.request_form = {};
      this.merge_model.splice(0);
      this.cascader_show = false;
      setTimeout(() => {
        this.cascader_show = true;
      });
    },
    showImgViewer(srcList) {
      this.currentSrcList = srcList;
      this.isViewerVisible = true;
    },
    closeImgViewer() {
      this.isViewerVisible = false;
    },
  },
};
</script>

<style>
.el-popper.popper {
  width: 300px;
  border-radius: 12px;
  max-height: 50%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.el-popper.popper::-webkit-scrollbar {
  background-color: transparent;
  /* width: 5px; */
}
.el-popper.popper::-webkit-scrollbar-button:vertical:end:increment {
  display: block;
  /* height: 8px; */
  background-color: transparent;
}
.el-popper.popper::-webkit-scrollbar-button:vertical:start:increment {
  display: block;
  /* height: 8px; */
  background-color: transparent;
}
</style>

<style lang="scss" scoped>
.node_name {
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: -webkit-ellipsis-lastline;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  vertical-align: top;
}
.icon-shoucang {
  margin: 0 20px;
  cursor: pointer;
}
.node_wrap {
  background-color: #f0f2f5;
  ::v-deep {
    .el-form-item__label {
      text-align: left;
    }
    .el-form-item.is-required:not(.is-no-asterisk)
      > .el-form-item__label:before {
      //display: none;
    }
    .el-dialog__body {
      position: relative;
    }
    .el-dialog.is-fullscreen {
      background-color: #ebeced;
      height: 93%;
      margin-top: 3%;
    }
  }
}
.bgzd {
  ::v-deep {
    .el-form-item__content {
      line-height: 30px !important;
      text-align: left;
      padding: 0 0 0 10px;
      border: 1px solid rgba(229, 229, 229, 1);
    }
  }
}
.edit_dialog_wrap {
  padding: 0 100px;
  & > p {
    color: rgba(0, 0, 0, 1);
    font-size: 24px;
  }
  & > div {
    height: 70vh;
    background-color: #fff;
    border-radius: 8px;
    overflow-y: scroll;
  }
}
.footer_notice {
  color: rgba(128, 128, 128, 1);
  font-size: 16px;
  float: left;
  & > label {
    color: rgb(255, 87, 51);
  }
}
.request_position {
  position: absolute;
  width: calc(100% + 2px);
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  bottom: -60px;
  left: -1px;
  background-color: #fff;
  border: 1px solid #e4e7ed;
  cursor: pointer;
}
.back-col {
  .back {
    font-size: 20px;
    color: rgba(128, 128, 128, 1);
  }
  .el-button {
    padding: 0;
  }
}

.main {
  text-align: start;
  ::v-deep .collapse-header {
    font-size: 14px;
  }
  .header_content {
    // transform: translateY(10px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 30px;
    text-align: left;
    .header_node {
      display: flex;
      align-items: center;
    }
    ::v-deep .el-input__inner {
      width: 180px;
    }
    .change_require {
      text-align: right;
      & > * {
        margin-right: 36px;
      }
    }
  }
  .top-node-id {
    font-size: 16px;
    font-weight: 700;
    color: rgba(255, 195, 0, 1);
    background-color: rgba(229, 229, 229, 1);
    width: 100px;
    height: 28px;
    border-radius: 2px;
    text-align: center;
    span {
      vertical-align: middle;
      line-height: 28px;
    }
  }

  .node-name {
    font-size: 20px;
  }
  .baseline_lock {
    color: rgba(166, 166, 166, 1);
    font-size: 14px;
    margin-left: 17px;
  }
  .main-collapse {
    padding-left: 30px;
  }
  .creat_all_check {
    width: 100%;
    height: 30px;
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 2px 2px 0 0;
    vertical-align: middle;
    padding-left: 20px;
  }
  .creat_single_check {
    width: 100%;
    height: 200px;
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 0 0 2px 2px;
    border-top: none;
    overflow-y: scroll;
    padding-left: 20px;
  }
}

.scrollbar-area {
  height: 100%;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}

.node-detail-body {
  margin-top: 18px;
  height: calc(100vh - 160px);
}
.reviewer_list {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
.reviewer {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  background: rgba(229, 229, 229, 1);
  padding: 0 6px;
  margin: 4px;
  border-radius: 4px;
}
.review_records_class {
  & > div {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    &:first-of-type {
      font-size: 16px;
      line-height: 30px;
    }
    & > span {
      display: inline-block;
      width: 33%;
      line-height: 20px;
      text-align: center;
    }
  }
}
.table_border {
  border: 1px solid #ebeef5;
}
.header_row_class {
  font-weight: bold;
}

.box_item_l {
  height: 30px;
  border: 1px solid #014caa;
  border-radius: 5px 0 0 5px;
  padding: 6px 14px;
  background-color: #014caa;
  display: flex;
  justify-items: center;
  align-items: center;
}
.box_item_r {
  height: 30px;
  border: 1px solid rgba(219, 219, 219, 1);
  border-radius: 0 5px 5px 0;
  padding: 0 14px;
  display: flex;
  justify-items: center;
  align-items: center;
  .box_font {
    font-size: 18px;
    color: rgba(255, 195, 0, 1);
    font-weight: 700;
  }
}
.copy_zdh_key {
  padding-left: 5px;
  color: #004caa;
  cursor: pointer;
}
.img-viewer-text {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  color: #003b73;
}
.goTop {
  position: absolute;
  bottom: 40px;
  right: 50px;
  border-radius: 50%;
  background-color: white;
  i {
    cursor: pointer;
    font-size: 30px;
    font-weight: 600;
    color: rgba(145, 147, 153, 1);
  }
}
</style>
