<template>
  <div>
    <el-dialog
      :title="$t(`node.sourceNodeSync.title`)"
      :visible.sync="fileMoveVisible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      class="movedialog"
      @close="closeMoveDialog"
    >
      <div class="move-main">
        <div>
          <div class="move-main__text">
            {{ $t("file.table.options.moveForm.fieldSetting") }}
          </div>
          <div class="move-main__item move-main__label">
            <span>{{ $t("node.sourceNodeSync.target") }}</span>
            <span>{{ $t("node.sourceNodeSync.origin") }}</span>
          </div>
          <div v-loading="isDataLoading" class="item-list">
            <div
              v-for="item in targetProjectField"
              :key="item.fieldId"
              class="move-main__item"
            >
              <label v-if="!isDataLoading">{{ item.name }}</label>
              <el-select
                v-if="!isDataLoading"
                v-model="item.selectedSourceFieldId"
                :placeholder="$t('placeholder.dropdown')"
                filterable
                clearable
                @change="force"
              >
                <el-option
                  v-for="sourceField in item.sourceFieldList"
                  v-show="isNotSelected(item.fieldId, sourceField.fieldId)"
                  :key="sourceField.fieldId"
                  :label="sourceField.name"
                  :value="sourceField.fieldId"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="fileMoveVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="move">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('addMindMap.title2')"
      class="dialog"
      :visible.sync="analyzeFieldsVisible"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <p v-if="lackFieldList.length > 0" style="margin-top: 0">
        <span class="strong">{{ lackFieldList.toString() }} </span>
        {{ $t("node.sourceNodeSync.tip1") }}
      </p>
      <p v-if="dataNotEnoughFieldList.length > 0" style="margin-top: 0">
        <span class="strong">{{ dataNotEnoughFieldList.toString() }}</span>
        {{ $t("node.sourceNodeSync.tip2") }}
      </p>
      <p v-if="showTips3(targetProjectField)" style="margin-top: 0">
        {{ $t("node.sourceNodeSync.tip3") }}
      </p>
      <p style="margin-top: 0">
        {{ $t("node.sourceNodeSync.sureToSynchronize") }}
        <span style="color: red">
          {{ $t("node.sourceNodeSync.sureToSynchronize2") }}</span
        >
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="analyzeFieldsVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button :loading="submitLoading" type="primary" @click="submit">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { request_allPageProjects } from "@/network/personal/index.js";
import { all_fields_get, get_filetype_List } from "@/network/fileType/index.js";
import { move, moveFileIntoProject } from "@/network/moveFile/index.js";
import { get_file, sourceNodeSync } from "@/network/node/index.js";

export default {
  name: "MoveFileDialog",
  //父组件方法
  props: {
    sourceNode: {
      type: Object,
      default() {
        return {};
      },
    },
    nodeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      analyzeFieldsVisible: false,
      fileMoveVisible: false,
      selectedProject: "",
      allProjects: [],
      isProjectSelected: false,
      selectedFileType: "",
      fileTypeList: [],
      isFileTypeSelected: false,
      isDataLoading: true,
      isDeleted: "1",
      targetProjectField: [],
      sourceProjectField: [],
      fileKeyList: [],
      lackFieldList: [],
      dataNotEnoughFieldList: [],
      submitLoading: false,
    };
  },
  watch: {
    async selectedProject(newValue) {
      if (newValue) {
        this.isProjectSelected = false;
        this.isFileTypeSelected = false;
        this.selectedFileType = "";
        this.fileTypeList = await get_filetype_List(newValue);
        this.isProjectSelected = true;
      }
    },
    async selectedFileType(newValue) {
      if (newValue) {
      }
    },
  },

  async mounted() {},
  methods: {
    // 移动文件时是否展示《目标项目字段未完全指定，确定移动吗？》
    showTips3(list) {
      return list.some((item) => {
        return !item.selectedSourceFieldId;
      });
    },
    async init() {
      this.allProjects = this.$store.getters.t_joined_projects;
      this.allProjects = this.allProjects.filter(
        (project) => project.projectId !== this.get_pid()
      );
      this.allProjects.sort((before, after) =>
        after.createdAt > before.createdAt ? 1 : -1
      );
      this.isDataLoading = true;
      this.isFileTypeSelected = true;
      const res = await get_file(this.get_pid(), this.nodeInfo.fileId);
      const fieldData = await moveFileIntoProject(
        this.get_pid(),
        res.key,
        this.sourceNode.fileTypeId
      );
      this.targetProjectField = fieldData.targetProject;
      this.sourceProjectField = fieldData.sourceProject;
      this.targetProjectField.forEach((field) => {
        field.sourceFieldList = [];
        field.selectedSourceFieldId = "";
        this.sourceProjectField.forEach((sourceField) => {
          if (field.fieldType === sourceField.fieldType) {
            field.sourceFieldList.push(sourceField);
            if (field.name === sourceField.name) {
              field.selectedSourceFieldId = sourceField.fieldId;
            }
          }
        });
      });
      this.targetProjectField.sort((before, after) =>
        before.selectedSourceFieldId !== "" ? -1 : 1
      );
      this.isDataLoading = false;
    },
    open(item) {
      this.fileKeyList = item;
      this.fileMoveVisible = true;
      this.init();
    },
    closeMoveDialog() {
      this.fileMoveVisible = false;
      this.selectedProject = "";
      this.selectedFileType = "";
      this.isProjectSelected = false;
      this.isFileTypeSelected = false;
    },
    force() {
      this.$forceUpdate();
    },
    isInclude(targetArr, sourceArr) {
      if (!sourceArr) {
        if (!targetArr) {
          return true;
        } else {
          return false;
        }
      }
      let f = true;
      for (const sourceItem of sourceArr) {
        let flag = false;
        for (const targetItem of targetArr) {
          if (targetItem.label === sourceItem.label) {
            flag = true;
          }
        }
        if (!flag) {
          f = false;
          break;
        }
      }
      return f;
    },
    move() {
      this.dataNotEnoughFieldList = [];
      this.lackFieldList = [];
      this.analyzeFieldsVisible = true;
      const allSelectedSourceFieldList = [];
      this.sourceProjectField.forEach((sourceField) => {
        let hasFieldTypeInTargetFieldList = false;
        for (const targetField of this.targetProjectField) {
          if (targetField.fieldType === sourceField.fieldType) {
            hasFieldTypeInTargetFieldList = true;
            if (
              targetField.selectedSourceFieldId === sourceField.fieldId &&
              allSelectedSourceFieldList.indexOf(
                targetField.selectedSourceFieldId
              ) === -1
            ) {
              const f = this.isInclude(
                targetField.extraData,
                sourceField.extraData
              );
              if (!f) {
                allSelectedSourceFieldList.push(sourceField);
              }
            }
          }
        }
        if (!hasFieldTypeInTargetFieldList) {
          this.lackFieldList.push(sourceField.name);
        }
      });
      allSelectedSourceFieldList.forEach((field) => {
        if (
          !this.lackFieldList.includes(field.name) &&
          !this.dataNotEnoughFieldList.includes(field.name)
        ) {
          this.dataNotEnoughFieldList.push(field.name);
        }
      });
    },
    async submit() {
      this.submitLoading = true;
      const data = {};
      data.deleteOrigin = this.isDeleted === "1" ? false : true;
      data.targetFileTypeId = this.selectedFileType;
      data.targetProjectId = this.selectedProject;

      const fieldSettingList = [];
      this.targetProjectField.forEach((targetField) => {
        if (targetField.selectedSourceFieldId && targetField.fieldId) {
          fieldSettingList.push({
            targetFieldId: targetField.selectedSourceFieldId,
            sourceFieldId: targetField.fieldId,
          });
        }
      });
      data.fieldSettingList = fieldSettingList;
      const params = {
        projectId: this.get_pid(),
        data: {
          fieldSettingList: data.fieldSettingList,
          newNodeKeyList: this.fileKeyList,
        },
      };
      sourceNodeSync(params).then((res) => {
        this.$message({
          type: "success",
          message: this.$t("node.sourceNodeSync.success"),
        });
        this.submitLoading = false;
        this.analyzeFieldsVisible = false;
        this.closeMoveDialog();
        this.$router.go(0);
      });
    },
    isNotSelected(id, fieldId) {
      for (const field of this.targetProjectField) {
        if (fieldId === field.selectedSourceFieldId && id !== field.fieldId) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
.op {
  text-align: left;

  span {
    margin-right: 20px;
  }
}

.move-main {
  border: 1px solid rgba(229, 229, 229, 1);
  margin-bottom: 15px;

  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
  }

  &__header {
    padding: 10px 20px;
  }

  &__text {
    padding: 15px;
    background: rgba(240, 242, 245, 1);
  }

  &__label {
    padding: 15px 20px;
    border-bottom: 1px solid rgba(229, 229, 229, 1);
  }

  .item-list {
    max-height: 300px;
    overflow: auto;
    padding: 10px 0;

    .move-main__item {
      padding: 5px 25px;
      column-gap: 20px;
    }
  }
}

.strong {
  font-weight: 600;
  color: #30648f;
}
</style>
