<template>
    <el-alert
        :title="$t('placeholder.index')"
        type="info"
        effect="dark"
        :closable="false"
        show-icon
        center
        >
    </el-alert>
</template>

<script>
export default {

}
</script>

<style>

</style>
