<template>
  <div>
    <!-- 二维报表 -->
    <div v-if="show" class="two-dimension-table">
      <el-table
        :data="value02Set(currentChartData.entryList)"
        :row-style="rowStyle"
        :row-class-name="rowClassName"
        ref="table"
        max-height="340"
      >
        <el-table-column
          label="数据"
          fixed="left"
          align="right"
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <el-tooltip :content="header1">
              <span>{{ header1 }}</span>
            </el-tooltip>
          </template>
          <el-table-column
            prop="key"
            label="数据指标"
            width="120"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <el-tooltip :content="header2">
                <span>{{ header2 }}</span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{ get_comtent2(scope.row.key) }}
            </template>
          </el-table-column>
        </el-table-column>
        <template v-for="(col, i) in valueSet(currentChartData.entryList)">
          <el-table-column
            :key="i"
            :label="col.label"
            align="center"
            min-width="0"
            :show-overflow-tooltip="true"
          >
            <template slot="header" slot-scope="scope">
              <el-tooltip
                :content="get_comtent1(scope.column.label)"
                :disabled="!isShowTooltip"
              >
                <span @mouseenter="visibilityChange($event)">{{
                  get_comtent1(scope.column.label)
                }}</span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <span
                class="data-penetration"
                @click="dataPenetration(col, scope.row)"
              >
                {{
                  entryListTransform(currentChartData.entryList)[0][
                    col.label + "," + scope.row.key
                  ] || 0
                }}
              </span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    currentChartData: {
      type: Object,
      default() {
        return {};
      },
    },
    fieldMap: {
      type: Map,
      default() {
        return new Map();
      },
    },
  },
  data() {
    return {
      show: true,
      isShowTooltip: false,
    };
  },
  computed: {
    ...mapGetters(["user_list", "all_file_type", "fileType"]),
    header1() {
      return this.fieldMap.get(this.currentChartData.fieldId);
    },
    header2() {
      return this.fieldMap.get(this.currentChartData.fieldId02);
    },
  },
  watch: {
    currentChartData: {
      deep: true,
      handler() {
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      },
    },
  },
  mounted() {},
  methods: {
    get_comtent2(text) {
      if (this.header2 == "文件类型" || this.header2 == "File type") {
        if (this.all_file_type[text]) {
          return this.all_file_type[text].name;
        }
      }
      if (!text) {
        return "null";
      }
      return text;
    },
    get_comtent1(text) {
      if (this.header1 == "文件类型" || this.header1 == "File type") {
        if (this.all_file_type[text]) {
          return this.all_file_type[text].name;
        }
      }
      if (!text) {
        return "null";
      }
      return text;
    },
    resetKeyWord(key) {
      if (key == "sprint_id") {
        return "sprint";
      }
      if (key == "test_plan_id") {
        return "testPlan";
      }
      if (key == "version_id") {
        return "version";
      }
      if (key == "baseline_id") {
        return "baseline";
      }
      return key;
    },
    resetValue(value) {
      return value ? value : null;
    },
    // 数据穿透
    dataPenetration(col, row) {
      if (
        col.label === this.$t("chart.message.ms28") &&
        row.key === this.$t("chart.message.ms28")
      ) {
      } else if (col.label === this.$t("chart.message.ms28")) {
        if (this.currentChartData.statisticObject == "FILE") {
          this.$router.push({
            path: `/${this.get_pid()}/home/my_file`,
            query: {
              queryConditionId: this.currentChartData.queryConditionId,
              conditions: JSON.stringify([
                {
                  fieldId: this.currentChartData.fieldId02,
                  value: resetValue(row.value),
                },
              ]),
            },
          });
        } else {
          this.$router.push({
            path: `/${this.get_pid()}/home/node`,
            query: {
              queryConditionId: this.currentChartData.queryConditionId,
              conditions: JSON.stringify([
                {
                  fieldId: this.resetKeyWord(this.currentChartData.fieldId02),
                  value: this.resetValue(row.value),
                },
              ]),
            },
          });
        }
      } else if (row.key === this.$t("chart.message.ms28")) {
        if (this.currentChartData.statisticObject == "FILE") {
          this.$router.push({
            path: `/${this.get_pid()}/home/my_file`,
            query: {
              queryConditionId: this.currentChartData.queryConditionId,
              conditions: JSON.stringify([
                {
                  fieldId: this.currentChartData.fieldId,
                  value: col.value,
                },
              ]),
            },
          });
        } else {
          this.$router.push({
            path: `/${this.get_pid()}/home/node`,
            query: {
              queryConditionId: this.currentChartData.queryConditionId,
              conditions: JSON.stringify([
                {
                  fieldId: this.resetKeyWord(this.currentChartData.fieldId),
                  value: this.resetValue(col.value),
                },
              ]),
            },
          });
        }
      } else {
        if (this.currentChartData.statisticObject == "FILE") {
          this.$router.push({
            path: `/${this.get_pid()}/home/my_file`,
            query: {
              queryConditionId: this.currentChartData.queryConditionId,
              conditions: JSON.stringify([
                {
                  fieldId: this.currentChartData.fieldId,
                  value: col.value,
                },
                {
                  fieldId: this.currentChartData.fieldId02,
                  value: row.value,
                },
              ]),
            },
          });
        } else {
          this.$router.push({
            path: `/${this.get_pid()}/home/node`,
            query: {
              queryConditionId: this.currentChartData.queryConditionId,
              conditions: JSON.stringify([
                {
                  fieldId: this.resetKeyWord(this.currentChartData.fieldId),
                  value: this.resetValue(col.value),
                },
                {
                  fieldId: this.resetKeyWord(this.currentChartData.fieldId02),
                  value: this.resetValue(row.value),
                },
              ]),
            },
          });
        }
      }
    },
    //获取二维数据中横轴的取值范围
    valueSet(arr) {
      const set = new Set();
      const map = {};
      arr.forEach((item, index) => {
        map[
          this.user_list[item.actualValue]
            ? this.user_list[item.actualValue].nickname
            : item.actualValue
        ] = item.value;
        set.add(
          this.user_list[item.actualValue]
            ? this.user_list[item.actualValue].nickname
            : item.actualValue
        );
      });
      set.add(this.$t("chart.message.ms28"));
      return Array.from(set).map((item, index) => {
        return { label: item, value: map[item] };
      });
    },
    //获取二维数据中纵轴的取值范围
    value02Set(arr) {
      const set = new Set();
      const map = {};
      arr.forEach((item, index) => {
        map[
          this.user_list[item.actualValue02]
            ? this.user_list[item.actualValue02].nickname
            : item.actualValue02
        ] = item.value02;
        set.add(
          this.user_list[item.actualValue02]
            ? this.user_list[item.actualValue02].nickname
            : item.actualValue02
        );
      });
      set.add(this.$t("chart.message.ms28"));
      return Array.from(set).map((item, index) => {
        return { key: item, value: map[item] };
      });
    },
    entryListTransform(arr) {
      const map = {};
      let sum1 = new Map();
      let sum2 = new Map();
      let sum = 0;
      arr.forEach((item, index) => {
        sum += item.count;
        let k1 = this.user_list[item.actualValue]
          ? this.user_list[item.actualValue].nickname
          : item.actualValue;
        let k2 = this.user_list[item.actualValue02]
          ? this.user_list[item.actualValue02].nickname
          : item.actualValue02;
        map[k1 + "," + k2] = item.count;
        if (!sum1.has(k1)) {
          sum1.set(k1, item.count);
        } else {
          sum1.set(k1, sum1.get(k1) + item.count);
        }
        if (!sum2.has(k2)) {
          sum2.set(k2, item.count);
        } else {
          sum2.set(k2, sum2.get(k2) + item.count);
        }
      });
      arr.forEach((item, index) => {
        let k2 = this.user_list[item.actualValue02]
          ? this.user_list[item.actualValue02].nickname
          : item.actualValue02;
        map[this.$t("chart.message.ms28") + "," + k2] = sum2.get(k2);
      });
      for (const sum1Key of sum1.keys()) {
        map[sum1Key + "," + this.$t("chart.message.ms28")] = sum1.get(sum1Key);
      }
      map[this.$t("chart.message.ms28") + "," + this.$t("chart.message.ms28")] =
        sum;
      return [map, sum1, sum2];
    },
    getSummary(param, item) {
      if (item.entryList) {
        item.paralleledCount = item.entryList.reduce((prev, cur) => {
          return prev + cur.count;
        }, 0);
      }
      let cnt = this.entryListTransform(item.entryList)[1];
      let sums = [
        this.$t("chart.message.ms28"),
        ...cnt.values(),
        item.paralleledCount,
      ];
      return sums;
    },
    getFieldname(val) {
      return this.fieldMap.get(val);
    },
    visibilityChange(event) {
      const ev = event.relatedTarget;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },
    rowStyle({ row, rowIndex }) {
      if (row.key === this.$t("chart.message.ms28")) {
        return {
          backgroundColor: "#5470c6",
          color: "#fff",
        };
      } else {
        return {
          backgroundColor: "#fff",
        };
      }
    },
    rowClassName({ row, rowIndex }) {
      if (row.key === this.$t("chart.message.ms28")) {
        return "sumRow";
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-table__body tr.hover-row > td {
  color: #fff;
  background-color: #5470c6;
}

.data-penetration {
  cursor: pointer;
}
</style>
<style lang="scss">
.two-dimension-table {
  margin: 30px 4% 15px 2%;
  .el-table th {
    overflow: initial;
  }
  .el-table .cell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .el-table thead.is-group th {
    background: rgba(232, 239, 247, 1);
    max-height: 20px;
  }
  .el-table th > .cell {
    font-size: 14px;
    color: #2e3444;
    font-weight: 400;
  }

  .el-table thead tr:first-of-type th:first-of-type {
    border-bottom: none;
    padding: 0;
    padding-top: 5px;
    background-color: rgba(84, 112, 198, 1);
  }
  .el-table thead tr:nth-of-type(2) th:first-of-type {
    border-bottom: none;
    padding: 0;
    padding-bottom: 8px;
    background-color: rgba(84, 112, 198, 1);
  }
  .el-table thead tr:first-of-type th:first-of-type > .cell,
  .el-table thead tr:nth-of-type(2) th:first-of-type > .cell {
    color: #fff;
    font-weight: 600;
    width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .el-table thead tr:last-of-type th:first-of-type:before {
    content: "";
    position: absolute;
    width: 4px;
    background-color: #fff;
    display: block;
    z-index: 1;
  }
  .el-table thead tr:last-of-type th:first-of-type:before {
    min-height: 300px;
    bottom: 0;
    right: 0;
    transform: rotate(-64deg);
    transform-origin: bottom;
  }
  .el-table tbody tr td:first-of-type {
    background-color: rgba(232, 239, 247, 1);
  }
  .el-table tbody tr td:first-of-type .cell {
    font-size: 14px;
    color: #2e3444;
    text-align: center;
  }
  .el-table thead tr:first-of-type th:last-of-type > .cell,
  .el-table tbody tr td:last-of-type .cell,
  .el-table__footer-wrapper tbody tr td:first-of-type .cell {
    color: white;
    font-weight: 600;
  }
  .el-table thead tr:first-of-type th:last-of-type,
  .el-table tbody tr td:last-of-type,
  .el-table__footer-wrapper tbody tr td:first-of-type,
  .el-table__footer-wrapper tbody td {
    background-color: rgba(84, 112, 198, 1);
    color: white;
    font-weight: 600;
    border: none;
  }
}
</style>
