<template>
  <div style="width: 100%; display: flex">
    <div
      class="left-components-box"
      :style="'width:' + (is_hide_tool ? '70px' : '200px')"
    >
      <el-collapse v-model="activeNameTool" v-show="!is_hide_tool">
        <el-collapse-item
          v-for="collapse_item in item_list"
          :key="collapse_item.id"
          :name="collapse_item.id"
        >
          <template slot="title">
            <div
              style="
                font-size: 16px;
                margin-left: 10px;
                color: #707070;
                font-weight: bold;
              "
            >
              {{ collapse_item.catalog }}
            </div>
          </template>
          <div class="container">
            <!-- @click="add_item(item.value)"   draggable="true"
              @dragend="handleDragEnd($event, item)"-->

            <div
              v-for="item in collapse_item.itemList"
              :key="item.value"
              draggable="true"
              @click="add_item(item.value)"
              @dragstart="handleDragStart($event, item)"
            >
              <el-tooltip
                :content="item.label"
                placement="top"
                :open-delay="500"
              >
                <div class="icon">
                  <i
                    :class="'iconfont ' + item.icon"
                    style="font-size: 24px; font-weight: bold"
                  ></i>
                </div>
              </el-tooltip>
            </div>

            <div
              v-if="collapse_item.id == 'index-1'"
              @click="add_item('base12')"
            >
              <el-tooltip content="自由绘图" placement="top" :open-delay="500">
                <div class="icon">
                  <i
                    :class="'iconfont icon-huatu'"
                    :style="
                      'font-size: 24px; font-weight: bold;' +
                      (is_free_draw ? 'color:rgb(96,163,217)' : '')
                    "
                  ></i>
                </div>
              </el-tooltip>
            </div>

            <div @click="add_item('upload')" id="66666666">
              <el-tooltip
                :content="$t('model_view.is_show_model_dialog')"
                placement="top"
                :open-delay="500"
              >
                <div class="icon">
                  <i class="iconfont icon-lingcunwei"></i>
                </div>
              </el-tooltip>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>

      <div class="line_tool" v-show="is_hide_tool">
        <div class="btn">
          <i
            class="iconfont icon-a-siweidaotu2"
            style="font-size: 24px; font-weight: bold; cursor: pointer"
            @click="open_moban_dialog"
          ></i>
        </div>
        <div class="top">
          <div
            v-for="item in item_list[0].itemList"
            :key="item.value"
            draggable="true"
            @click="add_item(item.value)"
            @dragstart="handleDragStart($event, item)"
          >
            <el-tooltip
              :content="item.label"
              placement="right"
              :open-delay="500"
            >
              <div class="icon">
                <i
                  :class="'iconfont ' + item.icon"
                  style="font-size: 24px; font-weight: bold"
                ></i>
              </div>
            </el-tooltip>
          </div>
          <div @click="add_item('base12')">
            <el-tooltip
              :content="$t('model_view.base12')"
              placement="top"
              :open-delay="500"
            >
              <div class="icon">
                <i
                  :class="'iconfont icon-huatu'"
                  :style="
                    'font-size: 24px; font-weight: bold;' +
                    (is_free_draw ? 'color:rgb(96,163,217)' : '')
                  "
                ></i>
              </div>
            </el-tooltip>
          </div>
          <div @click="add_item('upload')">
            <el-tooltip
              :content="$t('model_view.is_show_model_dialog')"
              placement="top"
              :open-delay="500"
            >
              <div class="icon">
                <i
                  class="iconfont icon-lingcunwei"
                  style="font-weight: 900"
                ></i>
              </div>
            </el-tooltip>
          </div>
        </div>
        <!-- <div class="line"></div>
        <div class="bottom">
          <div
            v-for="item in item_list[1].itemList"
            :key="item.value"
            draggable="true"
            @click="add_item(item.value)"
            @dragstart="handleDragStart($event, item)"
          >
            <el-tooltip
              :content="item.label"
              placement="right"
              :open-delay="500"
            >
              <div class="icon">
                <i
                  :class="'iconfont ' + item.icon"
                  style="font-size: 24px; font-weight: bold"
                ></i>
              </div>
            </el-tooltip>
          </div>
        </div> -->
      </div>

      <!-- <div class="hide" @click="hide_tool">
        <div v-if="is_hide_tool">
          <i class="iconfont icon-zhedie1"> </i>
        </div>

        <div v-else>
          <i class="iconfont icon-shouqi1"> </i>
        </div>
      </div> -->
    </div>
    <div
      id="center-canvas-container"
      class="center-canvas-container"
      :style="'width: 100vw'"
      @drop="handleDrop($event, 'container1')"
    ></div>
    <div id="create_canvas"></div>
    <!-- <canvas
      v-show="isDrawing"
      class="overlay-canvas"
      id="drawingCanvas"
    ></canvas> -->

    <el-dialog
      v-if="show_right_operate"
      id="modelView_right_operate"
      v-draggable
      class="modelView_right_operate"
      custom-class="modelView_right_operate_dialog_body"
      :visible.sync="show_right_operate"
      width="260px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="operate_dialog_name[edit_cell]"
    >
      <el-collapse
        v-model="activeNameTool_node_edge"
        class="modelView_right_operate_cardBody"
      >
        <el-collapse-item
          v-if="edit_cell == 'node'"
          :title="$t('model_view.t1')"
          name="1"
        >
          <el-input
            v-model="edit_node_obj.label"
            style="margin-top: 10px"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :placeholder="$t('placeholder.input')"
          >
          </el-input>
          <!-- 字体大小 -->
          <div class="list">
            <span> {{ $t("model_view.font_size") }} </span>
            <el-select
              v-model="edit_node_obj.font_size"
              :placeholder="$t('placeholder.dropdown')"
              size="mini"
            >
              <el-option
                v-for="item in select.font_size"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 字体装饰 -->
          <div class="list">
            <span> {{ $t("model_view.font_decoration") }} </span>
            <el-select
              v-model="edit_node_obj.font_decoration"
              :placeholder="$t('placeholder.dropdown')"
              size="mini"
            >
              <el-option
                v-for="item in select.font_decoration"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 字体粗细 -->
          <div class="list">
            <span> {{ $t("model_view.font_weight") }} </span>
            <el-select
              v-model="edit_node_obj.font_weight"
              :placeholder="$t('placeholder.dropdown')"
              size="mini"
            >
              <el-option
                v-for="item in select.font_weight"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 文字对齐 -->
          <div class="list">
            <span> {{ $t("model_view.text_align") }}</span>
            <el-radio-group v-model="edit_node_obj.text_align" size="mini">
              <el-radio-button label="start">
                {{ $t("model_view.start") }}</el-radio-button
              >
              <el-radio-button label="center">
                {{ $t("model_view.center") }}</el-radio-button
              >
              <el-radio-button label="end">
                {{ $t("model_view.end") }}</el-radio-button
              >
            </el-radio-group>
          </div>
          <!-- 水平位置 -->
          <!-- <div class="list">
            <span> {{ $t("model_view.justify_content") }}</span>
            <el-radio-group v-model="edit_node_obj.justify_content" size="mini">
              <el-radio-button label="start">
                {{ $t("model_view.start") }}</el-radio-button
              >
              <el-radio-button label="center">
                {{ $t("model_view.center") }}</el-radio-button
              >
              <el-radio-button label="end">
                {{ $t("model_view.end") }}</el-radio-button
              >
            </el-radio-group>
          </div> -->
          <!-- 纵向位置 -->
          <div class="list">
            <span> {{ $t("model_view.align_items") }}</span>
            <el-radio-group v-model="edit_node_obj.align_items" size="mini">
              <el-radio-button label="start">
                {{ $t("model_view.up") }}</el-radio-button
              >
              <el-radio-button label="center">
                {{ $t("model_view.center") }}</el-radio-button
              >
              <el-radio-button label="end">
                {{ $t("model_view.down") }}</el-radio-button
              >
            </el-radio-group>
          </div>
          <!-- 字体颜色 -->
          <div class="list">
            <span> {{ $t("model_view.font_color") }}</span>

            <el-color-picker
              v-model="edit_node_obj.font_color"
              show-alpha
            ></el-color-picker>
          </div>
          <!-- 斜体 -->
          <div class="list">
            <span> {{ $t("model_view.font_style") }}</span>

            <el-radio-group v-model="edit_node_obj.font_style" size="mini">
              <el-radio-button label="italic">{{
                $t("model_view.italicoo")
              }}</el-radio-button>
              <el-radio-button label="normal">{{
                $t("model_view.italicno")
              }}</el-radio-button>
            </el-radio-group>
          </div>
          <!-- 缩进 -->
          <div class="list">
            <span> {{ $t("model_view.font_style") }}</span>

            <el-input-number
              v-model="edit_node_obj.font_indent"
              size="mini"
              :step="0.5"
              step-strictly
            ></el-input-number>
          </div>
          <!-- 行距 -->
          <div class="list">
            <span> {{ $t("model_view.line_height") }}</span>
            <el-input-number
              v-model="edit_node_obj.line_height"
              size="mini"
              :step="0.5"
              step-strictly
            ></el-input-number>
          </div>
        </el-collapse-item>
        <el-collapse-item
          v-if="edit_cell == 'node'"
          :title="$t('model_view.t2')"
          name="2"
        >
          <!-- 背景颜色 -->
          <div class="list">
            <span> {{ $t("model_view.background_color") }}</span>

            <el-color-picker
              v-model="edit_node_obj.background_color"
              show-alpha
            ></el-color-picker>
          </div>
          <!-- 边框颜色 -->
          <div class="list">
            <span> {{ $t("model_view.border_color") }}</span>

            <el-color-picker
              v-model="edit_node_obj.border_color"
              show-alpha
            ></el-color-picker>
          </div>
          <!-- 边框粗细 -->
          <div class="list">
            <span> {{ $t("model_view.border_weight") }}</span>
            <el-input-number
              v-model="edit_node_obj.border_weight"
              size="mini"
            ></el-input-number>
          </div>
          <!-- 边框样式 -->
          <div
            v-if="edit_node_obj.border_type.toString() != 'false'"
            class="list"
          >
            <span> {{ $t("model_view.border_weight") }}</span>
            <el-select
              v-model="edit_node_obj.border_type"
              :placeholder="$t('placeholder.dropdown')"
              size="mini"
            >
              <el-option
                v-for="item in select.border_type"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 圆角 -->
          <div
            v-if="edit_node_obj.border_radius.toString() != 'false'"
            class="list"
          >
            <span> {{ $t("model_view.border_radius") }}</span>
            <el-input-number
              v-model="edit_node_obj.border_radius"
              size="mini"
            ></el-input-number>
          </div>
          <!-- 阴影颜色 -->
          <div
            v-if="edit_node_obj.shadow_color.toString() != 'false'"
            class="list"
          >
            <span> {{ $t("model_view.shadow_color") }}</span>
            <el-color-picker
              v-model="edit_node_obj.shadow_color"
              show-alpha
            ></el-color-picker>
          </div>
          <!-- 阴影X偏移量 -->
          <div class="list">
            <span> {{ $t("model_view.shadow_x") }}</span>
            <el-slider
              v-model="edit_node_obj.shadow_x"
              :min="-30"
              :max="30"
              style="width: 100%"
            >
            </el-slider>
          </div>
          <!-- 阴影Y偏移量 -->
          <div class="list">
            <span> {{ $t("model_view.shadow_y") }}</span>
            <el-slider
              v-model="edit_node_obj.shadow_y"
              :min="-30"
              :max="30"
              style="width: 100%"
            >
            </el-slider>
          </div>
          <!-- 阴影模糊 -->
          <div class="list">
            <span> {{ $t("model_view.shadow_blur") }}</span>

            <el-slider
              v-model="edit_node_obj.shadow_blur"
              :min="0"
              :max="40"
              style="width: 100%"
            >
            </el-slider>
          </div>
          <!-- 阴影大小 -->
          <div
            v-if="edit_node_obj.shadow_size.toString() != 'false'"
            class="list"
          >
            <span> {{ $t("model_view.shadow_size") }}</span>
            <el-slider
              v-model="edit_node_obj.shadow_size"
              :min="0"
              :max="30"
              style="width: 100%"
            >
            </el-slider>
          </div>
        </el-collapse-item>
        <el-collapse-item
          v-if="edit_cell == 'node'"
          :title="$t('model_view.t3')"
          name="3"
        >
          <el-button-group style="width: 100%; margin-top: 20px">
            <el-button
              class="position_button_zdh"
              icon="el-icon-upload2"
              style="width: 50%"
              @click="to_top"
              >{{ $t("model_view.to_top") }}</el-button
            >
            <el-button
              style="width: 50%"
              class="position_button_zdh_end"
              @click="to_bottom"
              >{{ $t("model_view.to_bottom") }}<i class="el-icon-download"></i
            ></el-button>
          </el-button-group>
          <el-button-group style="width: 100%">
            <el-button
              icon="el-icon-caret-top"
              class="position_button_zdh"
              style="width: 50%"
              @click="up_Zindex"
              >{{ $t("model_view.up_Zindex") }}</el-button
            >
            <el-button
              style="width: 50%"
              class="position_button_zdh_end"
              @click="down_Zindex"
              >{{ $t("model_view.down_Zindex")
              }}<i class="el-icon-caret-bottom"></i
            ></el-button>
          </el-button-group>
        </el-collapse-item>
        <el-collapse-item
          v-if="edit_cell == 'edge'"
          :title="$t('model_view.t4')"
          name="4"
        >
          <!-- 连线颜色 -->
          <div class="list">
            <span> {{ $t("model_view.stroke") }} </span>
            <el-color-picker
              v-model="edit_edge_obj.stroke"
              show-alpha
            ></el-color-picker>
          </div>
          <!-- 连线粗细 -->
          <div class="list">
            <span> {{ $t("model_view.strokeWidth") }} </span>
            <el-input-number
              v-model="edit_edge_obj.strokeWidth"
              size="mini"
            ></el-input-number>
          </div>

          <!-- 箭头样式 -->
          <div class="list">
            <span> {{ $t("model_view.targetMarker") }} </span>
            <el-select
              v-model="edit_edge_obj.targetMarker"
              :placeholder="$t('placeholder.dropdown')"
              size="mini"
            >
              <el-option
                v-for="item in select.targetMarker"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 线条样式 -->
          <div class="list">
            <span> {{ $t("model_view.strokeDasharray") }} </span>
            <el-select
              v-model="edit_edge_obj.strokeDasharray"
              :placeholder="$t('placeholder.dropdown')"
              size="mini"
            >
              <el-option
                v-for="item in select.strokeDasharray"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 连接器样式 -->
          <div class="list" v-show="edit_edge_obj.id != 'base11'">
            <span> {{ $t("model_view.connector") }} </span>
            <el-select
              v-model="edit_edge_obj.connector"
              :placeholder="$t('placeholder.dropdown')"
              size="mini"
            >
              <el-option
                v-for="item in select.connector"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 路由样式 -->
          <!-- <div class="list">
            <span> 路由样式 </span>
            <el-select
              v-model="edit_edge_obj.strokeLinejoin"
              placeholder="请选择"
              size="mini"
            >
              <el-option
                v-for="item in select.strokeLinejoin"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
        </el-collapse-item>

        <el-collapse-item
          v-if="edit_cell == 'table'"
          :title="$t('model_view.t5')"
          name="5"
        >
          <el-input
            v-model="edit_table_obj.text"
            style="margin-top: 10px"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            :placeholder="$t('placeholder.input')"
          >
          </el-input>
          <!-- 字体大小 -->
          <div class="list">
            <span> {{ $t("model_view.font_size") }} </span>
            <el-select
              v-model="edit_table_obj.fontSize"
              :placeholder="$t('placeholder.dropdown')"
              size="mini"
            >
              <el-option
                v-for="item in select.font_size"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 字体粗细 -->
          <div class="list">
            <span> {{ $t("model_view.font_weight") }} </span>
            <el-select
              v-model="edit_table_obj.fontWeight"
              :placeholder="$t('placeholder.dropdown')"
              size="mini"
            >
              <el-option
                v-for="item in select.font_weight"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 水平位置 -->
          <!-- <div class="list">
            <span> {{ $t("model_view.justify_content") }} </span>
            <el-radio-group v-model="edit_table_obj.justifyContent" size="mini">
              <el-radio-button label="start">
                {{ $t("model_view.start") }}</el-radio-button
              >
              <el-radio-button label="center">
                {{ $t("model_view.center") }}</el-radio-button
              >
              <el-radio-button label="end">
                {{ $t("model_view.end") }}</el-radio-button
              >
            </el-radio-group>
          </div> -->
          <!-- 纵向位置 -->
          <div class="list">
            <span> {{ $t("model_view.align_items") }} </span>
            <el-radio-group v-model="edit_table_obj.alignItems" size="mini">
              <el-radio-button label="start">
                {{ $t("model_view.up") }}</el-radio-button
              >
              <el-radio-button label="center">
                {{ $t("model_view.center") }}</el-radio-button
              >
              <el-radio-button label="end">
                {{ $t("model_view.down") }}</el-radio-button
              >
            </el-radio-group>
          </div>
          <!-- 字体颜色 -->
          <div class="list">
            <span> {{ $t("model_view.font_color") }} </span>
            <el-color-picker
              v-model="edit_table_obj.color"
              show-alpha
            ></el-color-picker>
          </div>
          <!-- 背景颜色 -->
          <div class="list">
            <span> {{ $t("model_view.background_color") }} </span>
            <el-color-picker
              v-model="edit_table_obj.backgroundColor"
              show-alpha
            ></el-color-picker>
          </div>
          <!-- 边框颜色 -->
          <div class="list">
            <span> {{ $t("model_view.border_color") }} </span>
            <el-color-picker
              v-model="edit_table_obj.borderColor"
              show-alpha
            ></el-color-picker>
          </div>
          <!-- 边框粗细 -->
          <div class="list">
            <span> {{ $t("model_view.border_weight") }} </span>
            <el-input-number
              v-model="edit_table_obj.borderSize"
              size="mini"
            ></el-input-number>
          </div>
          <!-- 边框样式 -->
          <div v-if="edit_node_obj.borderType" class="list">
            <span> {{ $t("model_view.border_type") }} </span>
            <el-select
              v-model="edit_table_obj.border_type"
              :placeholder="$t('placeholder.dropdown')"
              size="mini"
            >
              <el-option
                v-for="item in select.border_type"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-collapse-item>

        <!-- <el-collapse-item v-if="edit_cell == 'edge'" title="位置设置" name="5">
          <el-button-group style="width: 100%; margin-top: 20px">
            <el-button icon="el-icon-upload2" style="width: 50%" @click="to_top"
              >移至最前</el-button
            >
            <el-button style="width: 50%" @click="to_bottom"
              >移至最后<i class="el-icon-download"></i
            ></el-button>
          </el-button-group>
          <el-button-group style="width: 100%">
            <el-button
              icon="el-icon-caret-top"
              style="width: 50%"
              @click="up_Zindex"
              >上移一层</el-button
            >
            <el-button style="width: 50%" @click="down_Zindex"
              >下移一层<i class="el-icon-caret-bottom"></i
            ></el-button>
          </el-button-group>
        </el-collapse-item> -->
      </el-collapse>

      <!-- <el-button
        v-show="edit_cell == 'edge'"
        style="width: 100%; margin: 15px 0"
        @click="save_edit_edge_obj"
      >
        保存
      </el-button> -->

      <!-- <el-button
        v-show="edit_cell == 'node'"
        style="width: 100%; margin: 15px 0"
        @click="save_edit_node_obj"
      >
        保存
      </el-button> -->
    </el-dialog>
    <div
      v-if="showMenu"
      class="context-menu"
      id="context-menu"
      @click="showMenu = false"
    >
      <div class="item" @click="add_link_visiable()">
        {{ $t("model_view.add_link_visiable") }}
      </div>
      <div
        class="item"
        @click="remove_link_visiable()"
        v-show="show_delete_link_visi"
      >
        {{ $t("model_view.remove_link_visiable") }}
      </div>
      <!-- <li @click="menuItemClicked('close')">关闭</li>   -->
    </div>

    <el-dialog
      :visible.sync="is_show_model_dialog"
      width="70%"
      :close-on-click-modal="false"
      id="zdh_model_view_moban"
    >
      <div slot="title" class="title-wrap">
        <el-row>
          <el-col :span="10" style="display: flex">
            <span class="title-logo-warp">
              <img
                class="title-logo"
                src="../../assets/templateLibrary_Logo.png"
              />
            </span>
            <span class="title-font">{{
              $t("templateLibrary.templateLibrary")
            }}</span></el-col
          >
          <el-col :span="8">
            <span class="title-search">
              <el-input
                v-model="searchValue"
                :placeholder="$t('templateLibrary.searchTemplate')"
                suffix-icon="el-icon-search"
                style="margin-top: -8px"
                @input="searchChange"
              >
              </el-input></span
          ></el-col>
        </el-row>
      </div>
      <div style="display: flex">
        <div style="border-right: 3px solid #e5e5e5; padding: 0 20px">
          <div
            v-for="item in catalog_moban"
            :key="item.key"
            :class="
              choose_moban == item.key
                ? 'catalog_moban_zdh_active'
                : 'catalog_moban_zdh'
            "
            @click="choose_moban_fun(item.key)"
          >
            {{ item.name }}
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            height: 566px;
            overflow-y: auto;
          "
        >
          <div
            v-for="item in moban_list"
            :key="item.fileArchitectureDiagramsId"
            class="moban_item"
          >
            <div style="font-size: 18px; margin: 5px 0">{{ item.name }}</div>
            <!-- <img :src="item.img" alt="" /> -->
            <el-image
              style="
                width: 230px;
                height: 200px;
                border: 2px solid #e5e5e5;
                border-radius: 4px;
                margin: 10px 0;
              "
              :src="item.overviewImage"
              :preview-src-list="[item.overviewImage]"
            >
            </el-image>
            <el-button
              v-show="user_id == item.createdBy"
              type="danger"
              @click="dek_online_moban(item)"
              >{{ $t("btn.deleteBtn") }}</el-button
            >

            <!-- <el-button type="primary" @click="add_high_model(item)">{{
              $t("model_view.is_show_model_dialog")
            }}</el-button> -->
            <el-button
              type="primary"
              @click="add_high_model(item.fileArchitectureDiagramsId)"
              >{{ $t("templateLibrary.use") }}</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('model_view.add_link_visiable')"
      :visible.sync="add_linkFormVisible"
      width="25%"
      height="8vh"
    >
      <el-select
        style="width: 100%"
        v-model="add_link_key"
        :placeholder="$t('placeholder.dropdown')"
        filterable
        :filter-method="filter_select"
      >
        <el-option
          v-for="(item, index) in select_nodeData"
          :key="index"
          :value="item.key"
          >{{ item.key + "@" + item.label }}</el-option
        >
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="add_linkFormVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="add_link">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="remove_linkFormVisible"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i
      ><span>{{ $t("model_view.remove_link") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="remove_link">{{
          $t("btn.confirmBtn")
        }}</el-button>
        <el-button @click="remove_linkFormVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      v-if="outstanding_detail_dialog_show"
      id="outstanding_detail_dialog"
      v-draggable
      class="outstanding_detail_dialog"
      custom-class="outstanding_detail_dialog_body"
      :visible.sync="outstanding_detail_dialog_show"
      width="260px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="clear_outstanding"
    >
      <span slot="title">
        {{ outstanding_name }}
      </span>
      <el-table
        ref="outstanding_dialog_table_word"
        :data="modify_table_data(outstanding_map)"
        class="outstanding_dialog_table_class"
        :show-header="false"
        cell-class-name="outstanding_dialog_table_class_cell"
        :row-key="
          (row) => {
            return row.color;
          }
        "
      >
        <!-- <el-table-column type="selection" width="30" :reserve-selection="true">
        @selection-change="handle_select_outstanding"

        </el-table-column> -->
        <el-table-column prop="name" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="outstanding_name">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="color" width="30">
          <template slot-scope="scope">
            <div
              class="outstanding_dialog_color"
              :style="'background-color:#' + scope.row.color"
            ></div>
          </template>
        </el-table-column>
        <el-table-column prop="num" width="50"> </el-table-column>
      </el-table>
    </el-dialog>
    <div id="admin_show_delete_moban" style="display: none">1</div>
    <el-dialog
      :title="$t('model_view.save_moban')"
      :visible.sync="show_upload_json_dialog"
      width="25%"
      height="8vh"
    >
      <el-form
        ref="form"
        :model="moban_upload_form"
        class="moban_upload_form_zdh"
      >
        <el-form-item
          :label="$t('model_view.moban3')"
          class="is-required"
          style="display: flex; width: 100%"
        >
          <el-input v-model="moban_upload_form.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('model_view.moban2')" class="is-required">
          <el-select
            style="width: 100%"
            v-model="moban_upload_form.type"
            :placeholder="$t('model_view.moban1')"
          >
            <el-option
              :label="item.name"
              :value="item.key"
              v-for="item in catalog_moban"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('model_view.moban4')">
          <el-input
            v-model="moban_upload_form.desc"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show_upload_json_dialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="add_moban_upload">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  get_model_view,
  put_model_view,
  put_model_view_template,
  get_all_model_view_template,
  get_id_model_view_template,
  get_fuzz_model_view_template,
  del_id_model_view_template,
} from "@/network/home/index.js";
import { ossAuthorization } from "@/utils/oss";
import { ulid } from "ulid";
import { Graph, Shape } from "@antv/x6";
import vmson from "@/utils/vmson";
import { mapGetters } from "vuex";
import { loadJson } from "@/utils/loadJsonFile";
export default {
  props: ["nodeData"],
  data() {
    return {
      activeNameTool_node_edge: ["1", "4", "5"],
      activeNameTool: ["index-1", "index-2"],
      item_list: [
        {
          catalog: this.$t("model_view.base"),
          id: "index-1",
          itemList: [
            {
              label: this.$t("model_view.base1"),
              value: "base1",
              icon: "icon-juxing",
            },

            {
              label: this.$t("model_view.base2"),
              value: "base2",
              icon: "icon-a-huaban22",
            },
            {
              label: this.$t("model_view.base3"),
              value: "base3",
              icon: "icon-yuanxing",
            },
            {
              label: this.$t("model_view.base4"),
              value: "base4",
              icon: "icon-yuanjiaojuxing",
            },
            {
              label: this.$t("model_view.base5"),
              value: "base5",
              icon: "icon-zhengfangxing",
            },
            {
              label: this.$t("model_view.base6"),
              value: "base6",
              icon: "icon-zi",
            },
            {
              label: this.$t("model_view.base8"),
              value: "base8",
              icon: "icon-tixing",
            },
            {
              label: this.$t("model_view.base9"),
              value: "base9",
              icon: "icon-a-huaban9",
            },
            {
              label: this.$t("model_view.base7"),
              value: "base7",
              icon: "icon-a-left_arrow1",
            },
            {
              label: this.$t("model_view.base11"),
              value: "base11",
              icon: "icon-quxian",
            },
            {
              label: this.$t("model_view.base14"),
              value: "base14",
              icon: "icon-gerenzhongxinanniu",
            },
            {
              label: this.$t("model_view.base13"),
              value: "base13",
              icon: "icon-a-Frame32",
            },

            // {
            //   label: "导出",
            //   value: "export",
            //   icon: "icon-a-huaban110",
            // },
            // {
            //   label: this.$t("model_view.is_show_model_dialog"),
            //   value: "upload",
            //   icon: "icon-lingcunwei",
            // },
          ],
        },
        {
          catalog: "模板",
          id: "index-2",
          itemList: [
            {
              label: "导出",
              value: "export",
              icon: "icon-a-huaban110",
            },
            {
              label: "上传",
              value: "uoload",
              icon: "icon-lingcunwei",
            },
            {
              label: "模板1",
              value: "high2",
              icon: "icon-a-siweidaotu2",
            },
            {
              label: "模板2",
              value: "high3",
              icon: "icon-a-siweidaotu2",
            },
            {
              label: "模板3",
              value: "high4",
              icon: "icon-a-siweidaotu2",
            },
            {
              label: "模板4",
              value: "high5",
              icon: "icon-a-siweidaotu2",
            },
          ],
        },
      ],
      graph: "",
      drag_item: "",
      show_right_operate: false,
      timeOut: null,
      moban_list: [],
      moban_all: {
        cata1: [
          {
            label: this.$t("model_view.high3"),
            value: "high3",
            icon: "icon-a-siweidaotu2",
            img: "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/1725796724017m2.png",
          },
          {
            label: this.$t("model_view.high4"),
            value: "high4",
            icon: "icon-a-siweidaotu2",
            img: "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/1725797077429m3.png",
          },
          {
            label: this.$t("model_view.high8"),
            value: "high8",
            icon: "icon-a-siweidaotu2",
            img: "https://automind-oss.ytdevops.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/1728267090210QQ%E6%88%AA%E5%9B%BE20241007101021.png",
          },
        ],
        cata2: [
          {
            label: this.$t("model_view.high6"),
            value: "high6",
            icon: "icon-a-siweidaotu2",
            img: "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/1725797936004m5.png",
          },
          {
            label: this.$t("model_view.high11"),
            value: "high11",
            icon: "icon-a-siweidaotu2",
            img: "https://automind-oss.ytdevops.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/1728267090210QQ%E6%88%AA%E5%9B%BE20241007101002.png",
          },
        ],
        cata3: [
          {
            label: this.$t("model_view.high2"),
            value: "high2",
            icon: "icon-a-siweidaotu2",
            img: "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/1725796724018%E5%90%97.png",
          },
          {
            label: this.$t("model_view.high5"),
            value: "high5",
            icon: "icon-a-siweidaotu2",
            img: "https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/1725797077429m4.png",
          },
          {
            label: this.$t("model_view.high7"),
            value: "high7",
            icon: "icon-a-siweidaotu2",
            img: "https://automind-oss.ytdevops.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/1728267090207QQ%E6%88%AA%E5%9B%BE20241007100925.png",
          },
        ],
        cata4: [
          {
            label: this.$t("model_view.high9"),
            value: "high9",
            icon: "icon-a-siweidaotu2",
            img: "https://automind-oss.ytdevops.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/1728267090211QQ%E6%88%AA%E5%9B%BE20241007101042.png",
          },
          {
            label: this.$t("model_view.high10"),
            value: "high10",
            icon: "icon-a-siweidaotu2",
            img: "https://automind-oss.ytdevops.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/1728267090212QQ%E6%88%AA%E5%9B%BE20241007101059.png",
          },
          {
            label: this.$t("model_view.high12"),
            value: "high12",
            icon: "icon-a-siweidaotu2",
            img: "https://automind-oss.ytdevops.com/586ee243-5501-4712-bf0b-0e36b5ea2ff6/node_accessors/1728267090213QQ%E6%88%AA%E5%9B%BE20241007101110.png",
          },
        ],
      },
      select: {
        connector: [
          { label: this.$t("model_view.connector1.n1"), value: "normal" },
          { label: this.$t("model_view.connector1.n2"), value: "rounded" },
          // { label: "", value: "smooth" },
          { label: this.$t("model_view.connector1.n3"), value: "jumpover" },
        ],
        strokeDasharray: [
          { label: this.$t("model_view.strokeDasharray1.n1"), value: 0 },
          { label: this.$t("model_view.strokeDasharray1.n2"), value: 5 },
        ],
        strokeLinejoin: [
          {
            label: this.$t("model_view.strokeLinejoin.normal"),
            value: "normal",
          },
          { label: this.$t("model_view.strokeLinejoin.orth"), value: "orth" },
          {
            label: this.$t("model_view.strokeLinejoin.oneSide"),
            value: "oneSide",
          },
          {
            label: this.$t("model_view.strokeLinejoin.manhattan"),
            value: "manhattan",
          },
          { label: this.$t("model_view.strokeLinejoin.metro"), value: "metro" },
          { label: this.$t("model_view.strokeLinejoin.er"), value: "er" },
        ],
        targetMarker: [
          { label: this.$t("model_view.targetMarker1.null"), value: "null" },
          { label: this.$t("model_view.targetMarker1.block"), value: "block" },
          {
            label: this.$t("model_view.targetMarker1.classic"),
            value: "classic",
          },
          {
            label: this.$t("model_view.targetMarker1.diamond"),
            value: "diamond",
          },
          { label: this.$t("model_view.targetMarker1.cross"), value: "cross" },
          {
            label: this.$t("model_view.targetMarker1.circle"),
            value: "circle",
          },
          {
            label: this.$t("model_view.targetMarker1.circlePlus"),
            value: "circlePlus",
          },
          {
            label: this.$t("model_view.targetMarker1.ellipse"),
            value: "ellipse",
          },
        ],
        font_size: [
          { label: 12, value: 12 },
          { label: 14, value: 14 },
          { label: 16, value: 16 },
          { label: 18, value: 18 },
          { label: 20, value: 20 },
          { label: 22, value: 22 },
          { label: 24, value: 24 },
          { label: 26, value: 26 },
          { label: 28, value: 28 },
        ],
        font_weight: [500, 600, 700, 800, 900],
        font_decoration: [
          { label: this.$t("model_view.font_decoration1.none"), value: "none" },
          {
            label: this.$t("model_view.font_decoration1.overline"),
            value: "overline",
          },
          {
            label: this.$t("model_view.font_decoration1.line"),
            value: "line-through",
          },
          {
            label: this.$t("model_view.font_decoration1.underline"),
            value: "underline",
          },
        ],
        border_type: [
          { label: this.$t("model_view.border_type1.none"), value: "none" },
          { label: this.$t("model_view.border_type1.dotted"), value: "dotted" },
          { label: this.$t("model_view.border_type1.dashed"), value: "dashed" },
          { label: this.$t("model_view.border_type1.solid"), value: "solid" },
          { label: this.$t("model_view.border_type1.double"), value: "double" },
          { label: this.$t("model_view.border_type1.groove"), value: "groove" },
          { label: this.$t("model_view.border_type1.ridge"), value: "ridge" },
          { label: this.$t("model_view.border_type1.inset"), value: "inset" },
          { label: this.$t("model_view.border_type1.outset"), value: "outset" },
        ],
      },
      operate_dialog_name: {
        node: this.$t("model_view.operate_dialog_name.node"),
        edge: this.$t("model_view.operate_dialog_name.edge"),
        table: this.$t("model_view.operate_dialog_name.table"),
      },
      show_upload_json_dialog: false,
      admin_show_delete_moban: false,
      user_id: "",
      moban_upload_form: {
        name: "",
        type: "",
        desc: "",
      },
      edit_node: "", // 目前正在编辑的节点
      edit_edge: "", // 目前正在编辑的边
      edit_table: "", // 目前正在编辑的边
      edit_cell: "", // 目前正在编辑边还是节点？
      edit_edge_obj: {},
      edit_table_obj: {},
      edit_node_obj: {
        label: "",
        font_size: 18,
        font_decoration: "none",
        font_weight: 500,
        font_color: "#000",
        font_style: "关闭",
        font_indent: 0,
        align_items: "中",
        justify_content: "中",
        text_align: "左",
      },
      ports: {
        four_points: (id) => {
          let items = id
            ? [
                {
                  id: id ? id.top : "",
                  group: "top",
                },
                {
                  id: id ? id.right : "",

                  group: "right",
                },
                {
                  id: id ? id.bottom : "",
                  group: "bottom",
                },
                {
                  id: id ? id.left : "",
                  group: "left",
                },
              ]
            : [
                {
                  group: "top",
                },
                {
                  group: "right",
                },
                {
                  group: "bottom",
                },
                {
                  group: "left",
                },
              ];
          return {
            groups: {
              top: {
                position: "top",
                attrs: {
                  circle: {
                    r: 6,
                    magnet: true,
                    stroke: "#fff",
                    strokeWidth: 2,
                    fill: "#428BCA",
                    style: {
                      visibility: "visible",
                    },
                  },
                },
              },
              right: {
                position: "right",
                attrs: {
                  circle: {
                    r: 6,
                    magnet: true,
                    stroke: "#fff",
                    strokeWidth: 2,
                    fill: "#428BCA",
                    style: {
                      visibility: "visible",
                    },
                  },
                },
              },
              bottom: {
                position: "bottom",
                attrs: {
                  circle: {
                    r: 6,
                    magnet: true,
                    stroke: "#fff",
                    strokeWidth: 2,
                    fill: "#428BCA",
                    style: {
                      visibility: "visible",
                    },
                  },
                },
              },
              left: {
                position: "left",
                attrs: {
                  circle: {
                    r: 6,
                    magnet: true,
                    stroke: "#fff",
                    strokeWidth: 2,
                    fill: "#428BCA",
                    style: {
                      visibility: "visible",
                    },
                  },
                },
              },
            },
            items,
          };
        },
      },
      isNodeUpdated: false,
      isEdgeUpdated: false,
      showMenu: false,
      add_linkFormVisible: false,
      remove_linkFormVisible: false,
      is_show_model_dialog: false,
      add_link_key: "",
      select_nodeData: [],
      is_hide_tool: true,
      outstanding_name: "",
      outstanding_type: "",
      outstanding_map: {},
      outstanding_detail_dialog_show: false,
      otherFile_map: {},
      on_load: false,
      autosave_timeout: null,
      last_change_time: "",
      is_edit_node_topic: false,
      last_edit_node: null,
      show_delete_link_visi: true,
      points: [],
      all_points: [],
      isDrawing: false,
      last_tool_edge: null,
      is_free_draw: false,
      do_not_interacting: false,
      last_table: "",
      handle_table: "",
      searchValue: "",
      choose_moban: "cata1",
      catalog_moban: [
        { name: this.$t("model_view.cata1"), key: "cata1" },
        { name: this.$t("model_view.cata2"), key: "cata2" },
        { name: this.$t("model_view.cata3"), key: "cata3" },
        { name: this.$t("model_view.cata4"), key: "cata4" },
      ],
      can_edit_base_list: [
        "base1",
        "base2",
        "base3",
        "base4",
        "base5",
        "base6",
        "base8",
        "base9",
      ],
    };
  },
  computed: {
    ...mapGetters(["status", "user_list"]),
  },
  watch: {
    edit_edge_obj: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.isEdgeUpdated) {
          this.save_edit_edge_obj();
        }
      },
    },
    edit_node_obj: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.isNodeUpdated) {
          console.log("casfef");

          this.save_edit_node_obj();
        }
      },
    },
    edit_table_obj: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.isNodeUpdated) {
          this.save_table_node_obj();
        }
      },
    },
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听器，避免内存泄漏
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("paste", this.handlePaste);

    vmson.$off("left_meau_clicked_model_view");
    vmson.$off("model_view_outstanding");
    vmson.$off("save_model_view");
  },
  mounted() {
    this.user_id = this.$store.getters.userAccountId;
    get_all_model_view_template().then((res) => {
      res = res.content;
      const groupedByType = res.reduce((acc, item) => {
        if (!acc[item.type]) {
          acc[item.type] = [];
        }
        acc[item.type].push(item);
        return acc;
      }, {});
      console.log(groupedByType);

      this.moban_all = groupedByType;
      this.moban_list = this.moban_all["cata1"];
    });
    document.addEventListener("paste", this.handlePaste);
    document.addEventListener("keydown", this.handleKeyDown);
    /**
     * 初始化Graph对象，用于在指定容器内创建和管理图形界面。
     * 配置包括网格显示、连接行为、元素大小调整、旋转、选择模式、对齐线、鼠标滚轮缩放、滚动条等。
     */
    this.graph = new Graph({
      container: document.getElementById("center-canvas-container"),
      // 显示网格背景
      grid: true,
      //group
      embedding: {
        enabled: true,
        validate({ child, parent, childView, parentView }) {
          return parent.id.split("@")[1] == "table";
        },
      },
      autoResize: true,
      connecting: {
        // 开启对齐辅助线功能，使得连接线更容易对齐到节点或其他元素
        snap: true,
        highlight: true,
        allowLoop: false,
        createEdge(data) {
          return this.createEdge({
            shape: "edge",
            attrs: {
              line: {
                stroke: "rgb(0,0,0)",
              },
            },
            // router: {
            //   name: "manhattan",
            //   args: {
            //     // excludeTerminals: ["source"],
            //     padding: 50,
            //   },
            // },
          });
        },
      },
      resizing: {
        // 允许用户通过拖动调整元素大小
        enabled: true,
      },
      rotating: {
        // 允许用户旋转元素
        enabled: true,
      },
      selecting: {
        // 启用选择功能
        enabled: true,
        // 启用框选模式，允许用户拖动鼠标框选多个元素
        rubberband: true,
        rubberEdge: true,
        showNodeSelectionBox: true,
        showEdgeSelectionBox: true,
        // filter(node) {
        //   return node.id.split("@")[1] != "table";
        // },
      },
      snapline: true, // 启用对齐线功能，帮助用户更精确地放置元素
      mousewheel: {
        // 启用鼠标滚轮缩放
        enabled: true,
        // 指定触发缩放的修饰键，这里是Ctrl或Meta（Mac上的Command键）
        modifiers: ["ctrl", "meta"],
      },
      scroller: {
        // 启用滚动条
        enabled: true,
        // 自定义滚动条的类名，用于CSS样式定制
        className: "my-scroller",
        // 当容器大小改变时自动调整滚动条
        autoResize: true,
        // 显示滚动条所在页面的可视部分（如果容器被嵌入到页面中）
        pageVisible: true,
        // 在打印时添加分页符（如果容器内容超出单页）
        pageBreak: true,
      },
      history: true,
      interacting: (cellView) => {
        if (this.do_not_interacting) {
          return false;
        }
        if (cellView.cell.id.split("@")[1]) {
          if (
            this.can_edit_base_list.includes(cellView.cell.id.split("@")[1])
          ) {
            if (cellView.cell.store.data.data.edit) {
              return false;
            }
          }
        }
        return true;
      },
      // 后期开发shift 直线移动
      // translating: {
      //   restrict: (params) => {
      //     return {
      //       x: -2000,
      //       y: params.cell.store.data.position.y,
      //       width: 999999,
      //       height: 0,
      //     };
      //   },
      // },
    });

    this.graph.on("cell:change:*", (args) => {
      if (this.on_load) {
        if (this.autosave_timeout) {
          clearTimeout(this.autosave_timeout);
        }
        this.autosave_timeout = setTimeout(() => this.save(), 1000);
      }
      if (args.key == "size") {
        args.cell.prop("data/width", args.current.width);
        args.cell.prop("data/height", args.current.height);
      }
    });
    this.graph.on("edge:mouseenter", ({ e, cell }) => {
      this.last_tool_edge = cell;
      if (e.altKey) {
        cell.addTools([
          {
            name: "vertices",
            args: {
              attrs: { fill: "#666" },
            },
          },
        ]);
      }
      cell.addTools([
        {
          name: "source-arrowhead",
          args: {
            tagName: "ellipse",
            attrs: {
              fill: "#428BCA",
              rx: 6, // 椭圆箭头的 x 半径
              ry: 6, // 椭圆箭头的 y 半径
            },
          },
        },
        {
          name: "target-arrowhead",
          args: {
            tagName: "ellipse",
            attrs: {
              fill: "#428BCA",
              rx: 6, // 椭圆箭头的 x 半径
              ry: 6, // 椭圆箭头的 y 半径
            },
          },
        },
        // {
        //   name: "segments",
        //   args: {
        //     snapRadius: 20,
        //     attrs: {
        //       fill: "#428BCA",
        //     },
        //   },
        // },
      ]);
    });
    this.graph.on("edge:mouseleave", ({ cell }) => {
      this.last_tool_edge = null;
      cell.removeTools();
    });
    // this.graph.on("resize", ({ width, height }) => {
    //   document.getElementById("drawingCanvas").style.width = width + "px";
    //   document.getElementById("drawingCanvas").style.height = height + "px";
    // });
    this.graph.on("node:move", ({ e, x, y, node, view }) => {
      if (e.ctrlKey) {
        console.log("node_copy", [...this.graph.getSelectedCells(), node]);

        this.graph_copy_methods([...this.graph.getSelectedCells(), node]);
      }
    });
    this.graph.on("edge:move", ({ e, x, y, edge, view }) => {
      if (e.ctrlKey) {
        console.log("node_copy", [...this.graph.getSelectedCells(), edge]);

        this.graph_copy_methods([...this.graph.getSelectedCells(), edge]);
      }
    });
    this.graph.on("edge:click", ({ e, x, y, edge, view }) => {
      if (this.is_edit_node_topic) {
        let val =
          document.getElementById("only_textarea").value.length > 0
            ? document.getElementById("only_textarea").value
            : "";
        this.last_edit_node.prop("data/label", val);
        this.last_edit_node.prop("data/edit", false);
        this.last_edit_node = null;
        this.is_edit_node_topic = false;
        this.graph.enableSelection();

        return;
      }
      this.showMenu = false;
      this.changePortsVisible(false);
      this.edit_cell = "edge";
      this.show_right_operate = true;
      let edgeData = edge.prop();
      this.isEdgeUpdated = false;
      this.edit_edge_obj = {
        id: edgeData.id.split("@")[1],
        strokeWidth: edgeData.attrs.line.strokeWidth,
        stroke: edgeData.attrs.line.stroke,
        targetMarker:
          edgeData.attrs.line.targetMarker == null
            ? "null"
            : edgeData.attrs.line.targetMarker,
        strokeLinejoin: edgeData.attrs.lines.strokeLinejoin,
        connector: edgeData.connector ? edgeData.connector.name : "normal",
        strokeDasharray: edgeData.attrs.line.strokeDasharray
          ? edgeData.attrs.line.strokeDasharray
          : 0,
      };
      this.edit_edge = edge;

      setTimeout(() => {
        this.isEdgeUpdated = true;
      }, 0);
    });
    this.graph.on("node:click", ({ e, x, y, node, view }) => {
      console.log("click _node", e);
      if (e.target.id.split("@")[0] == "tableBtn") {
        console.log(e.target.id, node, "tableBtn");
        let op_list = e.target.id.split("@");
        let table_data = node.store.data.data.table_data;
        if (op_list[3] == "add") {
          if (op_list[2] == "rowIndex") {
            if (op_list[4] == "before") {
              table_data[op_list[1]];
              let index_t = parseInt(op_list[1]);
              let elementToCopy = table_data[index_t];
              table_data.splice(index_t, 0, elementToCopy);
              index_t++;
              let rows = node.store.data.rowHeights;
              elementToCopy = rows[index_t];
              rows.splice(index_t, 0, elementToCopy);
              console.log(rows, table_data);

              node.prop("rowHeights", rows);
              node.prop(
                "height",
                rows.reduce((sum, current) => sum + current, 0)
              );
              node.prop(
                "size/height",
                rows.reduce((sum, current) => sum + current, 0)
              );
              node.prop("data/table_data", table_data);
              node.prop("data/edit", !node.prop("data/edit"));
            }
            if (op_list[4] == "after") {
              table_data[op_list[1]];
              let index_t = parseInt(op_list[1]);
              let elementToCopy = table_data[index_t];
              table_data.splice(index_t + 1, 0, elementToCopy);
              index_t++;
              let rows = node.store.data.rowHeights;
              elementToCopy = rows[index_t];
              rows.splice(index_t + 1, 0, elementToCopy);
              console.log(rows, table_data);

              node.prop("rowHeights", rows);
              node.prop(
                "height",
                rows.reduce((sum, current) => sum + current, 0)
              );
              node.prop(
                "size/height",
                rows.reduce((sum, current) => sum + current, 0)
              );
              node.prop("data/table_data", table_data);

              node.prop("data/edit", !node.prop("data/edit"));
            }
          }
          if (op_list[2] == "colIndex") {
            if (op_list[4] == "left") {
              let index_t = parseInt(op_list[1]);
              for (let i = 0; i < table_data.length; i++) {
                console.log(table_data[i]);

                table_data[i].splice(index_t, 0, table_data[i][index_t]);
                console.log(table_data[i]);
              }
              index_t++;
              // let rows = node.store.data.rowHeights;
              let clos = node.store.data.colWidths;
              clos.splice(index_t + 1, 0, clos[index_t]);
              node.prop("colWidths", clos);
              node.prop(
                "width",
                clos.reduce((sum, current) => sum + current, 0)
              );
              node.prop(
                "size/width",
                clos.reduce((sum, current) => sum + current, 0)
              );
              node.prop("data/table_data", table_data);
              node.prop("data/edit", !node.prop("data/edit"));
            }

            if (op_list[4] == "right") {
              let index_t = parseInt(op_list[1]);

              for (let i = 0; i < table_data.length; i++) {
                console.log(table_data[i]);

                table_data[i].splice(index_t + 1, 0, table_data[i][index_t]);
                console.log(table_data[i]);
              }
              index_t++;

              // let rows = node.store.data.rowHeights;
              let clos = node.store.data.colWidths;
              clos.splice(index_t + 1, 0, clos[index_t]);
              node.prop("colWidths", clos);
              node.prop(
                "width",
                clos.reduce((sum, current) => sum + current, 0)
              );
              node.prop(
                "size/width",
                clos.reduce((sum, current) => sum + current, 0)
              );
              node.prop("data/table_data", table_data);
              node.prop("data/edit", !node.prop("data/edit"));
            }
          }
        }
        if (op_list[3] == "delete") {
          if (op_list[2] == "rowIndex") {
            let index_t = parseInt(op_list[1]);
            table_data.splice(index_t, 1);
            index_t++;
            let rows = node.store.data.rowHeights;
            rows.splice(index_t, 1);
            node.prop("rowHeights", rows);
            node.prop(
              "height",
              rows.reduce((sum, current) => sum + current, 0)
            );
            node.prop(
              "size/height",
              rows.reduce((sum, current) => sum + current, 0)
            );
            node.prop("data/table_data", table_data);
            node.prop("data/edit", !node.prop("data/edit"));
          }

          if (op_list[2] == "colIndex") {
            let index_t = parseInt(op_list[1]);
            for (let i = 0; i < table_data.length; i++) {
              table_data[i].splice(index_t, 1);
            }
            index_t++;
            // let rows = node.store.data.rowHeights;
            let clos = node.store.data.colWidths;
            clos.splice(index_t, 1);

            // table_data.splice(index_t, 1);

            node.prop("colWidths", clos);
            node.prop(
              "width",
              clos.reduce((sum, current) => sum + current, 0)
            );
            node.prop(
              "size/width",
              clos.reduce((sum, current) => sum + current, 0)
            );
            node.prop("data/table_data", table_data);
            node.prop("data/edit", !node.prop("data/edit"));
          }
        }
        this.graph.cleanSelection();
      }
      if (this.is_edit_node_topic) {
        if (node.id != this.last_edit_node.id) {
          let val =
            document.getElementById("only_textarea").value.length > 0
              ? document.getElementById("only_textarea").value
              : "";
          this.last_edit_node.prop("data/label", val);
          this.last_edit_node.prop("data/edit", false);
          this.last_edit_node = null;
          this.is_edit_node_topic = false;
          this.graph.enableSelection();
        }

        return;
      }
      this.showMenu = false;
      if (node.shape == "image") {
        this.edit_node = node;
        return;
      }
      if (
        node.id.split("@")[0] == "third" &&
        node.id.split("@")[1] != "table"
      ) {
        this.edit_node = node;
        return;
      }

      if (
        node.id.split("@")[0] == "third" &&
        node.id.split("@")[1] == "table" &&
        e.target.id.split("@")[0] == "tableCell"
      ) {
        this.edit_table = node;
        let nodeData = node.prop().data;
        nodeData = nodeData.table_data;
        nodeData =
          nodeData[parseInt(e.target.id.split("@")[1])][
            parseInt(e.target.id.split("@")[2])
          ];
        this.edit_cell = "table";
        this.show_right_operate = true;
        console.log(nodeData, "nodeDatacell");
        this.isNodeUpdated = false;
        const styles = {
          row: parseInt(e.target.id.split("@")[1]),
          col: parseInt(e.target.id.split("@")[2]),
          backgroundColor: nodeData.backgroundColor,
          color: nodeData.color,
          fontSize: nodeData.fontSize,
          fontWeight: nodeData.fontWeight,
          text: nodeData.text, // 注意：通常text不是样式属性，而是内容属性
          borderSize: nodeData.borderSize, // 注意：在某些框架中可能需要转换为px等单位
          borderType: nodeData.borderType, // 注意：某些CSS属性或框架可能不支持直接设置borderType，可能需要转换为borderStyle
          borderColor: nodeData.borderColor,
          alignItems: nodeData.alignItems, // 注意：alignItems通常用于Flex布局的子项，确保使用场景正确
          justifyContent: nodeData.justifyContent, // 同上，justifyContent也通常用于Flex容器的子项
        };
        this.edit_table_obj = styles;
        setTimeout(() => {
          this.isNodeUpdated = true;
        }, 0);
        return;
      }
      console.log(node.id.split("@"));

      this.changePortsVisible(false);
      this.edit_cell = "node";
      this.show_right_operate = true;
      let nodeData = node.prop().data;
      console.log(nodeData, "nodeData");

      this.isNodeUpdated = false;
      this.edit_node_obj = {
        label: nodeData.label,
        font_size: nodeData.font_size,
        font_decoration: nodeData.font_decoration,
        font_weight: nodeData.font_weight,
        font_color: nodeData.font_color,
        font_style: nodeData.font_style,
        font_indent: nodeData.font_indent,
        text_align: nodeData.text_align,
        align_items: nodeData.align_items,
        justify_content: nodeData.justify_content,
        line_height: nodeData.line_height,
        background_color: nodeData.background_color,
        border_color: nodeData.border_color,
        border_weight: nodeData.border_weight,
        border_type: nodeData.border_type,
        border_radius: nodeData.border_radius,
        shadow_color: nodeData.shadow_color,
        shadow_x: nodeData.shadow_x,
        shadow_y: nodeData.shadow_y,
        shadow_blur: nodeData.shadow_blur,
        shadow_size: nodeData.shadow_size,
      };
      this.edit_node = node;

      setTimeout(() => {
        this.isNodeUpdated = true;
      }, 0);
    });

    this.graph.on("node:dblclick", ({ e, x, y, node, view }) => {
      console.log(node);
      this.graph.cleanSelection();

      if (node.id.split("@")[0] == "third") {
        return;
      }
      if (node.shape == "image") {
        return;
      }
      node.prop("data/edit", true);
      this.show_right_operate = false;

      this.is_edit_node_topic = true;
      this.last_edit_node = node;
      this.graph.disableSelection();
      this.$nextTick(() => {
        const textarea = document.getElementById("only_textarea");
        if (textarea) {
          textarea.focus();
          // 获取文本区域的文本长度
          const length = textarea.value.length;
          // 将光标移动到文本末尾
          // textarea.selectionStart = length;
          textarea.selectionEnd = length;
        }
      });
    });
    this.graph.on("blank:click", () => {
      if (this.is_edit_node_topic) {
        let val =
          document.getElementById("only_textarea").value.length > 0
            ? document.getElementById("only_textarea").value
            : "";
        this.last_edit_node.prop("data/label", val);
        this.last_edit_node.prop("data/edit", false);
        this.last_edit_node = null;
        this.is_edit_node_topic = false;
        this.graph.enableSelection();
      }
    });

    this.graph.on("node:mouseup", ({ e, x, y, node }) => {
      console.log(node, "nouseup");

      if (
        node.id.split("@")[1] == "table" &&
        this.handle_table &&
        this.handle_table.target.id.split("@")[0] == "colrowIndex"
      ) {
        // 获取触发事件的单元格
        this.do_not_interacting = false;

        console.log("tab", this.handle_table);

        const clickedCell = this.handle_table;
        let lll = clickedCell.target.id.split("@");
        const table = document.getElementById(
          "third@table@" + clickedCell.target.id.split("@")[lll.length - 1]
        );
        console.log(table);

        // 获取所有行
        const rows = table.rows;

        // 遍历每一行获取高度
        const rowHeights = Array.from(rows).map((row) => row.offsetHeight);

        // 获取所有列的宽度
        const colWidths = [];
        if (rows.length > 0) {
          const cells = rows[0].cells;
          for (let i = 0; i < cells.length; i++) {
            colWidths.push(cells[i].offsetWidth);
          }
        }

        // 输出行高和列宽
        console.log("行高：", rowHeights);
        console.log("列宽：", colWidths);
        node.prop("rowHeights", rowHeights);
        node.prop("colWidths", colWidths);
        node.prop(
          "height",
          rowHeights.reduce((sum, current) => sum + current, 0)
        );
        node.prop(
          "size/height",
          rowHeights.reduce((sum, current) => sum + current, 0)
        );
        node.prop(
          "width",
          colWidths.reduce((sum, current) => sum + current, 0)
        );
        node.prop(
          "size/width",
          colWidths.reduce((sum, current) => sum + current, 0)
        );
        node.prop("data/edit", true);
        // node.prop(
        //   "size/width",
        //   colWidths.reduce((sum, current) => sum + current, 0)
        // );
        console.log(colWidths.reduce((sum, current) => sum + current, 0));
      }
    });

    this.graph.on("node:mousedown", ({ e, cell, view }) => {
      this.do_not_interacting = false;
      console.log(cell.id.split("@")[1] == "table");

      if (cell.id.split("@")[1] == "table") {
        this.handle_table = e;
        this.do_not_interacting = true;
      }
      console.log({ e, cell, view });
    });
    this.graph.on("node:mouseenter", ({ e, node, view }) => {
      console.log();
      if (node.id.split("@")[1] == "table") {
        this.last_table = node;
        node.prop("data/hiden", "visible");
      }
      this.changePortsVisible(true);
    });
    this.graph.on("node:mouseleave", ({ e, node, view }) => {
      if (node.id.split("@")[1] == "table") {
        node.prop("data/hiden", "hidden");
      }
      this.changePortsVisible(false);
    });
    this.graph.on("blank:mousedown", () => {
      this.do_not_interacting = false;
      this.showMenu = false;
      if (this.last_table) {
        this.last_table.prop("data/hiden", "hidden");
      }
      this.changePortsVisible(false);
    });
    // this.graph.on("cell:contextmenu", (e) => {
    //   this.contextmenuVisible(e);
    // });
    this.graph.on("edge:contextmenu", (e) => {
      console.log(this.get_selected_nodes());

      if (this.get_selected_nodes().length == 0) {
        return;
      }

      this.contextmenuVisible(e);
    });
    this.graph.on("node:contextmenu", (e, a, b, c) => {
      if (this.get_selected_nodes().length == 0) {
        this.graph.select(e.cell);
      }

      this.contextmenuVisible(e);
    });
    this.graph.on("blank:contextmenu", (e) => {
      if (this.get_selected_nodes().length == 0) {
        return;
      }
      this.contextmenuVisible(e);
    });
    this.graph.on("selection:changed", ({ cell, options }) => {
      let s = new Set();
      this.get_selected_nodes().forEach((item) => {
        if (this.graph.getCellById(item.id)) {
          if (this.graph.getCellById(item.id).prop("data/key")) {
            s.add(this.graph.getCellById(item.id).prop("data/key"));
          }
        }
      });
      vmson.$emit("highlight_left_meau_model_view", s);
    });
    // this.graph.on("cell:changed", ({ cell, options }) => {
    //   console.log(cell, options);
    // });

    vmson.$on("left_meau_clicked_model_view", (key) => {
      let l = [];
      this.graph.getCells().forEach((item) => {
        if (item.store.data.shape != "edge") {
          if (item.store.data.data.key == key) {
            l.push(item);
          }
        }
      });
      this.graph.resetSelection(l);
    });
    vmson.$on("model_view_outstanding_v2", () => {
      if (this.outstanding_detail_dialog_show) {
        vmson.$emit("model_view_outstanding", this.outstanding_type);
      }
    });
    vmson.$on("model_view_outstanding", (type) => {
      // 删除
      this.outstanding_type = type;
      // 获取 key_to_cell
      let key_to_cell = {};
      this.graph.getCells().forEach((item) => {
        if (item.id.split("@")[0] == "node") {
          if (item.store.data.data.key) {
            if (item.store.data.data.key in key_to_cell) {
              key_to_cell[item.store.data.data.key].push(item);
            } else {
              key_to_cell[item.store.data.data.key] = [item];
            }
          }
        }
      });
      if (type == "status") {
        this.outstanding_name = this.$t("canvas.wordView.status");

        let result_map = {};
        this.nodeData.forEach((item) => {
          if (item.key in key_to_cell) {
            if (item.status in result_map) {
              result_map[item.status].num += key_to_cell[item.key].length;
              result_map[item.status].node.push(item.key);
            } else {
              result_map[item.status] = {
                num: key_to_cell[item.key].length,
                node: [item.key],
              };
            }
          }
        });

        let color_map = {
          TODO: ["E87A90", "F759AB", "D75455", "FF4D4F", "FF7A45"],
          IN_PROGRESS: ["2651A6", "4968A6", "4B7BA6", "6B7FF2", "597EF7"],
          DONE: ["19BF5B", "55AA55", "0CAD0C", "23B85E", "2D882D"],
        };
        this.outstanding_map = {};
        for (let i of Object.keys(result_map)) {
          this.outstanding_map[i] = {
            name: this.status[i].name,
            color: color_map[this.status[i].statusCategory].shift(),
            num: result_map[i].num,
            val: i,
          };
          result_map[i].node.forEach((node_key) => {
            if (key_to_cell[node_key]) {
              key_to_cell[node_key].forEach((cell_item) => {
                cell_item.prop(
                  "data/outstanding_color",
                  "#" + this.outstanding_map[i].color
                );
              });
            }
          });
        }
        this.outstanding_detail_dialog_show = true;
      }

      if (type == "assignee") {
        this.outstanding_name = this.$t("canvas.wordView.assignee");

        let result_map = {};
        this.nodeData.forEach((item) => {
          if (item.key in key_to_cell) {
            if (!item.assignee) {
              item.assignee = "null";
            }
            if (item.assignee in result_map) {
              result_map[item.assignee].num += key_to_cell[item.key].length;
              result_map[item.assignee].node.push(item.key);
            } else {
              result_map[item.assignee] = {
                num: key_to_cell[item.key].length,
                node: [item.key],
              };
            }
          }
        });
        let color_map = [
          "f07c82",
          "5c2223",
          "eeb8c3",
          "813c85",
          "0f59a4",
          "101f30",
          "134857",
          "1ba784",
          "248067",
          "8cc269",
          "fed71a",
          "835e1d",
          "f26b1f",
          "cf4813",
          "862617",
        ];
        this.outstanding_map = {};
        for (let i of Object.keys(result_map)) {
          if (i != "null") {
            this.outstanding_map[i] = {
              name: this.user_list[i].nickname,
              color: color_map.shift(),
              num: result_map[i].num,
              val: i,
            };

            result_map[i].node.forEach((node_key) => {
              if (key_to_cell[node_key]) {
                key_to_cell[node_key].forEach((cell_item) => {
                  cell_item.prop(
                    "data/outstanding_color",
                    "#" + this.outstanding_map[i].color
                  );
                });
              }
            });
          }
        }
        if ("null" in result_map) {
          this.outstanding_map["null"] = {
            name: this.$t("canvas.wordView.null"),
            color: "5f5e5f",
            num: result_map["null"].num,
            val: "null",
          };

          result_map["null"].node.forEach((node_key) => {
            if (key_to_cell[node_key]) {
              key_to_cell[node_key].forEach((cell_item) => {
                cell_item.prop("data/outstanding_color", "#5f5e5f");
              });
            }
          });
        }
        this.outstanding_detail_dialog_show = true;
      }

      if (type == "sprintId") {
        this.outstanding_name = this.$t("canvas.wordView.sprintId");
        let sprint_list = Object.values(
          JSON.parse(sessionStorage.getItem("_sprint"))
        );
        let sprint_map = {};
        for (let i of sprint_list) {
          sprint_map[i.sprintId] = i;
        }

        let result_map = {};
        this.nodeData.forEach((item) => {
          if (item.key in key_to_cell) {
            if (!item.sprintId) {
              item.sprintId = "null";
            }
            if (item.sprintId in result_map) {
              result_map[item.sprintId].num += key_to_cell[item.key].length;
              result_map[item.sprintId].node.push(item.key);
            } else {
              result_map[item.sprintId] = {
                num: key_to_cell[item.key].length,
                node: [item.key],
              };
            }
          }
        });
        let color_map = [
          "f07c82",
          "5c2223",
          "eeb8c3",
          "813c85",
          "0f59a4",
          "101f30",
          "134857",
          "1ba784",
          "248067",
          "8cc269",
          "fed71a",
          "835e1d",
          "f26b1f",
          "cf4813",
          "862617",
        ];
        this.outstanding_map = {};
        for (let i of Object.keys(result_map)) {
          if (i != "null") {
            this.outstanding_map[i] = {
              name: sprint_map[i].name,
              color: color_map.shift(),
              num: result_map[i].num,
              val: i,
            };

            result_map[i].node.forEach((node_key) => {
              if (key_to_cell[node_key]) {
                key_to_cell[node_key].forEach((cell_item) => {
                  cell_item.prop(
                    "data/outstanding_color",
                    "#" + this.outstanding_map[i].color
                  );
                });
              }
            });
          }
        }
        if ("null" in result_map) {
          this.outstanding_map["null"] = {
            name: this.$t("canvas.wordView.null"),
            color: "5f5e5f",
            num: result_map["null"].num,
            val: "null",
          };

          result_map["null"].node.forEach((node_key) => {
            if (key_to_cell[node_key]) {
              key_to_cell[node_key].forEach((cell_item) => {
                cell_item.prop("data/outstanding_color", "#5f5e5f");
              });
            }
          });
        }
        this.outstanding_detail_dialog_show = true;
      }

      if (type.split("@")[0] == "otherFile") {
        this.otherFile_map = {};
        let result_map = {};
        this.nodeData.forEach((item) => {
          if (item.key in key_to_cell) {
            let flag = true;
            for (let i of item.content) {
              if (i.fieldId == type.split("@")[1]) {
                this.outstanding_name = i.name;
                flag = false;
                if (!i.value) {
                  i.value = this.$t("canvas.wordView.null");
                }
                this.otherFile_map[item.key] = i.value;
                if (i.value in result_map) {
                  result_map[i.value].num += key_to_cell[item.key].length;
                  result_map[i.value].node.push(item.key);
                } else {
                  result_map[i.value] = {
                    num: key_to_cell[item.key].length,
                    node: [item.key],
                  };
                }
              }
            }
            if (flag) {
              this.otherFile_map[item.key] = this.$t("node.message.message1");
              if (this.$t("node.message.message1") in result_map) {
                result_map[this.$t("node.message.message1")].num +=
                  key_to_cell[item.key].length;
                result_map[this.$t("node.message.message1")].node.push(
                  item.key
                );
              } else {
                result_map[this.$t("node.message.message1")] = {
                  num: key_to_cell[item.key].length,
                  node: [item.key],
                };
              }
            }
          }
        });

        let color_map = [
          "f07c82",
          "5c2223",
          "eeb8c3",
          "813c85",
          "0f59a4",
          "101f30",
          "134857",
          "1ba784",
          "248067",
          "8cc269",
          "fed71a",
          "835e1d",
          "f26b1f",
          "cf4813",
          "862617",
        ];

        this.outstanding_map = {};
        for (let i of Object.keys(result_map)) {
          if (i != "null") {
            this.outstanding_map[i] = {
              name: i,
              color: color_map.shift(),
              num: result_map[i].num,
              val: i,
            };

            result_map[i].node.forEach((node_key) => {
              if (key_to_cell[node_key]) {
                key_to_cell[node_key].forEach((cell_item) => {
                  cell_item.prop(
                    "data/outstanding_color",
                    "#" + this.outstanding_map[i].color
                  );
                });
              }
            });
          }
        }
        if ("null" in result_map) {
          this.outstanding_map["null"] = {
            name: this.$t("canvas.wordView.null"),
            color: "5f5e5f",
            num: result_map["null"].num,
            val: "null",
          };

          result_map["null"].node.forEach((node_key) => {
            if (key_to_cell[node_key]) {
              key_to_cell[node_key].forEach((cell_item) => {
                cell_item.prop("data/outstanding_color", "#5f5e5f");
              });
            }
          });
        }
        this.outstanding_detail_dialog_show = true;
      }
    });
    vmson.$on("save_model_view", () => {
      this.save(true);
    });

    let project_id = this.get_pid();
    let filr_key = this.$route.params.file_key;
    get_model_view(project_id, filr_key).then((res) => {
      if (res.diagram) {
        let json_data = res.diagram;
        let shape = "";

        json_data.cells.sort((a, b) => {
          // 定义排序顺序
          const order = {
            html: 1, // 最前面
            edge: 3, // 最后面
            default: 2, // 中间
          };

          // 获取a和b的排序值
          const orderA = order[a.shape] || order["default"];
          const orderB = order[b.shape] || order["default"];

          // 按顺序排序
          return orderA - orderB;
        });
        const methods = {
          base1: this.add_base1,
          base2: this.add_base2,
          base3: this.add_base3,
          base4: this.add_base4,
          base5: this.add_base5,
          base6: this.add_base6,
          base7: this.add_base7,
          base8: this.add_base8,
          base9: this.add_base9,
          base11: this.add_base11,
        };

        for (let item of json_data.cells) {
          if (item.shape == "html") {
            console.log(item);
            if (item.id.split("@")[1] == "table") {
              this.add_high1(null, item);

              continue;
            }

            shape = item.id.split("@")[1];
            let ports = {};
            item.ports.items.forEach((ele) => {
              ports[ele.group] = ele.id;
            });
            item.data.outstanding_color = "";
            item.data.edit = false;

            if (methods[shape]) {
              methods[shape](
                item.position,
                item.data,
                item.id,
                item.size.width,
                item.size.height,
                ports
              );
            } else {
              console.log("esle", item);
            }
          }
          if (item.shape == "image") {
            this.graph.addNode(item);
          }
          if (item.shape == "polyline") {
            this.graph.addNode(item);
          }
          if (item.shape == "path") {
            this.graph.addNode(item);
          }
          if (item.shape == "edge") {
            shape = item.id.split("@")[1];
            if (methods[shape]) {
              methods[shape](null, item);
            } else {
              this.graph.addEdge(item);
            }
          }
        }
      }
      this.on_load = true;
    });
  },
  methods: {
    dek_online_moban(val) {
      del_id_model_view_template(val.fileArchitectureDiagramsId).then((res) => {
        get_all_model_view_template().then((res) => {
          res = res.content;
          const groupedByType = res.reduce((acc, item) => {
            if (!acc[item.type]) {
              acc[item.type] = [];
            }
            acc[item.type].push(item);
            return acc;
          }, {});
          this.moban_all = groupedByType;
          this.moban_list = this.moban_all[this.choose_moban];
          this.$message({
            message: "删除成功",
            type: "success",
          });
        });
      });
    },
    open_moban_dialog() {
      this.is_show_model_dialog = true;
      let flag =
        document.getElementById("admin_show_delete_moban").innerText.length > 2;
      this.admin_show_delete_moban = flag;
      // get_all_model_view_template().then((res) => {
      //   this.is_show_model_dialog = true;
      //   const groupedByType = res.reduce((acc, item) => {
      //     if (!acc[item.type]) {
      //       acc[item.type] = [];
      //     }
      //     acc[item.type].push(item);
      //     return acc;
      //   }, {});
      //   this.moban_all = groupedByType;
      //   this.moban_list = this.moban_all["cata1"];
      // });
    },
    add_moban_upload() {
      if (this.moban_upload_form.name.length < 1) {
        this.$message({
          message: this.$t("model_view.moban5"),
          type: "warning",
        });
        return;
      }
      this.graph.toPNG(async (dataUri) => {
        this.show_upload_json_dialog = false;
        await ossAuthorization.call(this);
        this.client
          .put(`template_model_view/${ulid()}`, this.base64ToBlob(dataUri))
          .then((ossres) => {
            let data = {
              name: this.moban_upload_form.name,
              overviewImage: ossres.url,
              diagram: this.graph.toJSON(),
              description: this.moban_upload_form.desc,
              type: this.moban_upload_form.type,
            };
            put_model_view_template(data).then((res) => {
              get_all_model_view_template().then((res) => {
                res = res.content;
                const groupedByType = res.reduce((acc, item) => {
                  if (!acc[item.type]) {
                    acc[item.type] = [];
                  }
                  acc[item.type].push(item);
                  return acc;
                }, {});
                this.moban_all = groupedByType;
                this.moban_list = this.moban_all[this.choose_moban];
              });
              this.$message({
                message: this.$t("model_view.moban6"),
                type: "success",
              });
            });
          });
      });
    },
    base64ToBlob(base64) {
      let arr = base64.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    fuzzyQuery(t) {
      get_fuzz_model_view_template(t).then((res) => {
        res = res.content;
        this.moban_list = res;
      });
    },
    choose_moban_fun(key) {
      this.choose_moban = key;
      this.moban_list = this.moban_all[key];
    },
    searchChange(val) {
      let self = this;
      if (this.timeOut) {
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }
      this.timeOut = setTimeout(() => {
        if (val) {
          self.fuzzyQuery(val);
        } else {
          get_all_model_view_template().then((res) => {
            res = res.content;
            const groupedByType = res.reduce((acc, item) => {
              if (!acc[item.type]) {
                acc[item.type] = [];
              }
              acc[item.type].push(item);
              return acc;
            }, {});
            self.moban_all = groupedByType;
            self.moban_list = self.moban_all[self.choose_moban];
          });
        }
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }, 500);
    },
    graph_copy_methods(cells) {
      let shape = "";
      const methods = {
        base1: this.add_base1,
        base2: this.add_base2,
        base3: this.add_base3,
        base4: this.add_base4,
        base5: this.add_base5,
        base6: this.add_base6,
        base7: this.add_base7,
        base8: this.add_base8,
        base9: this.add_base9,
        base11: this.add_base11,
      };

      let copy_cells = [];

      // 排序函数
      function sortCells(a, b) {
        // 首先根据shape是否为edge来排序
        if (a.shape === "edge") {
          return 1; // 将shape为edge的元素放在后面
        }
        if (b.shape === "edge") {
          return -1; // 将shape不为edge的元素放在前面
        }
        // 如果两个元素都不是edge，则根据zIndex排序
        if (a.shape == "image") {
          console.log(a.zIndex, "ssss");
        }
        return a.zIndex - b.zIndex;
      }

      // 使用sort函数进行排序
      cells.forEach((ele) => {
        copy_cells.push(JSON.parse(JSON.stringify(ele.store.data)));
      });

      copy_cells.sort(sortCells);
      console.log(copy_cells);

      copy_cells.forEach((item) => {
        console.log(item);

        if (item.shape == "html") {
          shape = item.id.split("@")[1];
          if (item.id.split("@")[1] == "table") {
            this.add_high1(null, item);

            return;
          }
          let ports = {};
          item.ports.items.forEach((ele) => {
            ports[ele.group] = ele.id;
          });
          item.data.outstanding_color = "";
          item.data.edit = false;

          if (methods[shape]) {
            methods[shape](
              item.position,
              item.data,
              null,
              item.size.width,
              item.size.height,
              ports
            );
          }
        }
        if (item.shape == "image") {
          item.id = this.uid("third", "image");
          this.graph.addNode(item);
        }
        if (item.shape == "polyline") {
          item.id = this.uid("third", "polyline");
          this.graph.addNode(item);
        }
        if (item.shape == "path") {
          item.id = this.uid("third", "path");

          this.graph.addNode(item);
        }
        if (item.shape == "edge") {
          shape = item.id.split("@")[1];
          if (methods[shape]) {
            item.id = this.uid("edge", shape);
            methods[shape](null, item);
          } else {
            item.id = this.uid("edge", "normalEdge");
            this.graph.addEdge(item);
          }
        }
      });
    },
    save(message) {
      console.log("save", Date.parse(new Date()), this.graph.toJSON());
      put_model_view(
        this.get_pid(),
        this.$route.params.file_key,
        this.graph.toJSON()
      ).then(() => {
        if (message) {
          this.$message({
            message: this.$t("node.message.operationSuccess"),
            type: "success",
          });
        }
      });
    },
    clear_outstanding() {
      this.graph.getCells().forEach((cell_item) => {
        if (cell_item.store.data.shape != "edge") {
          cell_item.prop("data/outstanding_color", "");
        }
      });
    },
    modify_table_data(data) {
      let l = [];

      let null_flag = null;
      for (let i of Object.keys(data)) {
        if (data[i].color == "5f5e5f") {
          null_flag = data[i];
        } else {
          l.push(data[i]);
        }
      }
      l.sort(function (a, b) {
        return b.num - a.num;
      });
      if (null_flag) {
        l.push(null_flag);
      }
      return l;
    },
    hide_tool() {
      this.is_hide_tool = !this.is_hide_tool;
    },
    remove_link() {
      this.remove_linkFormVisible = false;
      this.graph.getSelectedCells().forEach((item) => {
        if (item.id.split("@")[0] == "node") {
          this.graph.getCellById(item.id).prop("data/key", "");
        }
      });
      this.$nextTick(() => {
        vmson.$emit("model_view_outstanding", this.outstanding_type);
      });
    },
    remove_link_visiable() {
      this.showMenu = false;
      this.add_link_key = "";
      this.remove_linkFormVisible = true;
    },
    add_link() {
      this.add_linkFormVisible = false;
      this.graph.getSelectedCells().forEach((item) => {
        if (item.id.split("@")[0] == "node") {
          this.graph.getCellById(item.id).prop("data/key", this.add_link_key);
        }
      });
      this.$nextTick(() => {
        vmson.$emit("model_view_outstanding", this.outstanding_type);
      });
    },
    add_link_visiable() {
      this.showMenu = false;
      this.add_link_key = "";
      this.add_linkFormVisible = true;
    },
    contextmenuVisible(e) {
      this.show_delete_link_visi = true;
      let flg = true;
      this.graph.getSelectedCells().forEach((ite) => {
        if (ite.id.split("@")[0] == "node") {
          if (ite.store.data.data.key.length > 0) {
            flg = false;
          }
        }
      });
      if (flg) {
        this.show_delete_link_visi = false;
      }
      this.select_nodeData = this.nodeData;
      let event = e.e;
      this.showMenu = true;
      this.$nextTick(() => {
        // 阻止默认的右键菜单
        event.preventDefault();
        const menu = document.getElementById("context-menu");
        menu.style.left = `${event.clientX}px`;
        menu.style.top = `${event.clientY}px`;
      });
    },
    filter_select(text) {
      this.add_link_key = text;
      this.select_nodeData = this.nodeData.filter((obj) => {
        if (obj.key.toString().includes(text)) {
          return true;
        }
        if (obj.label.toString().includes(text)) {
          return true;
        }
        return false;
      });
    },

    exportJson() {
      let a = JSON.stringify(this.graph.toJSON());
      let blob = new Blob([a], { type: "text/plain;charset=utf-8" });
      let url = URL.createObjectURL(blob);
      let aTag = document.createElement("a");
      aTag.href = url;
      aTag.download = "example.json";
      document.body.appendChild(aTag);
      aTag.click();
      document.body.removeChild(aTag);
      URL.revokeObjectURL(url);
    },
    handleKeyDown(event) {
      console.log(event);
      if (event.key == "Escape") {
        if (this.is_free_draw) {
          this.is_free_draw = false;
          this.end_free_draw();
        }
      }
      console.log(event);

      if (event.altKey) {
        if (this.last_tool_edge) {
          this.last_tool_edge.addTools([
            {
              name: "vertices",
              args: {
                attrs: { fill: "#666" },
              },
            },
          ]);
        }
      }

      if (event.key === "Delete" || event.key === "Backspace") {
        if (
          event.target.tagName == "INPUT" ||
          event.target.tagName == "TEXTAREA"
        ) {
          return;
        }
        this.graph.getSelectedCells().forEach((ek) => {
          console.log(ek.id, "删除");

          this.graph.removeCell(ek);
        });
        this.$nextTick(() => {
          this.save();
        });
      }
      // 检查是否按下了Ctrl（或Command）和Z或Y
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "z" ||
          event.key === "y" ||
          event.key === "Z" ||
          event.key === "Y")
      ) {
        event.preventDefault(); // 阻止默认行为（如果需要）

        if (event.key === "z" || event.key === "Z") {
          this.graph.undo();
          this.$nextTick(() => {
            this.save();
          });
        } else if (event.key === "y" || event.key === "Y") {
          this.graph.redo();
          this.$nextTick(() => {
            this.save();
          });
        }
      }
    },
    changePortsVisible(visible) {
      const container = document.getElementById("center-canvas-container");
      const ports = container.querySelectorAll(".x6-port-body");
      for (let i = 0, len = ports.length; i < len; i = i + 1) {
        ports[i].style.visibility = visible ? "visible" : "hidden";
      }
    },
    async upload_attachment_handler(file) {
      const loading = this.$loading({
        lock: true,
        text: this.$t("loading.loading7"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const self = this;
      await ossAuthorization.call(self);
      self.client
        .put(`upload_third_html/${ulid()}${file.name}`, file)
        .then((ossres) => {
          loading.close();
          let item_position = self.get_add_item_position();
          self.graph.addNode({
            shape: "image",
            url: ossres.url,
            x: item_position.x - 150,
            y: item_position.y - 150,
            width: 300,
            height: 300,
            imageUrl: ossres.url,
          });
        });
    },
    handlePaste(e) {
      // 检查粘贴板是否有图片
      const items = (e.clipboardData || window.clipboardData).items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          // 找到了图片
          this.upload_attachment_handler(items[i].getAsFile());
        }
      }
    },
    get_selected_nodes() {
      let cells = this.graph.getSelectedCells();
      if (cells.length == 0) {
        return [];
      }
      let l = [];
      cells.forEach((ele) => {
        if (ele.id.split("@")[0] == "node") {
          l.push(ele);
        }
      });

      return l;
    },
    save_edit_edge_obj() {
      //   const result = Object.assign(
      //     {},
      //     this.edit_node.prop().data,
      //     this.edit_node_obj
      //   );
      //   console.log(result, this.edit_node_obj);
      //   this.edit_node.setProp("data", result);

      // edge.prop('target', { x: 300, y: 300 }) // 修改终点
      this.edit_edge.attr("line/stroke", this.edit_edge_obj.stroke);
      this.edit_edge.attr("line/strokeWidth", this.edit_edge_obj.strokeWidth);
      this.edit_edge.attr(
        "line/targetMarker",
        this.edit_edge_obj.targetMarker == "null"
          ? null
          : this.edit_edge_obj.targetMarker
      );
      this.edit_edge.attr(
        "lines/strokeLinejoin",
        this.edit_edge_obj.strokeLinejoin
      );
      this.edit_edge.attr(
        "line/strokeDasharray",
        this.edit_edge_obj.strokeDasharray
      );

      this.edit_edge.prop("connector/name", this.edit_edge_obj.connector);
      this.edit_edge.prop("data", this.edit_edge_obj);
      console.log(this.edit_edge, "this.edit_edge");

      // this.edit_edge.connector("name", this.edit_edge_obj.connector);
    },
    to_top() {
      this.edit_node.toFront();
    },
    to_bottom() {
      this.edit_node.toBack();
    },
    up_Zindex() {
      this.edit_node.prop("zIndex", this.edit_node.prop().zIndex + 1);
    },
    down_Zindex() {
      this.edit_node.prop("zIndex", this.edit_node.prop().zIndex - 1);
    },
    save_table_node_obj() {
      console.log(
        this.edit_table_obj,
        this.edit_table,
        this.edit_table.prop("data/table_data")
      );
      let da = this.edit_table.prop("data/table_data");
      da[this.edit_table_obj.row][this.edit_table_obj.col] =
        this.edit_table_obj;
      this.edit_table.prop("data/table_data", da);
      this.edit_table.prop("data/edit", !this.edit_table.prop("data/edit"));
    },
    save_edit_node_obj() {
      const result = Object.assign(
        {},
        this.edit_node.prop().data,
        this.edit_node_obj
      );
      this.edit_node.setProp("data", result);
    },
    handleDragStart(e, item) {
      this.drag_item = item.value;
    },
    handleDrop(e, item) {
      this.add_item(this.drag_item, e);
    },
    // 拖拽添加节点
    handleDragEnd(e, item) {
      console.log("handleDragEnd", e.clientX, e.clientY, item);
      console.log(item);
      console.log("drop ee", this.graph.clientToLocal(e.clientX, e.clientY));

      let pos = this.graph.clientToLocal(e.clientX, e.clientY);
      let data = {
        width: 50,
        height: 50,
        shape: "html",
        x: pos.x,
        y: pos.y,
        id: "re" + this.global.ind,
        data: {
          label: "节点" + this.global.ind,
        },
        html(cell) {
          const { label } = cell.getData();
          const div = document.createElement("div");
          div.style.width = "50px";
          div.style.height = "56px";
          div.style.fontSize = "22px";
          div.innerText = label;
          return div;
        },
      };
      this.graph.addNode(data);
    },
    add_base10(pos, add_node_Data, id, width, height, ports) {
      let data = {
        x: pos.x,
        y: pos.y,
        id: id ? id : this.uid("node", "base10"),
        width: width ? width : 60,
        height: height ? height : 160,
        shape: "html",
        ports: [],

        data: add_node_Data
          ? add_node_Data
          : {
              label: "new node",
              key: "",
              width: 60,
              height: 160,
              border_color: "rgba(66,139,202, 1)",
              border_weight: 5,
              border_type: "false",
              border_radius: "false",
              background_color: "rgba(230,244,255, 1)",
              font_color: "false",
              font_weight: "false",
              font_size: "false",
              font_decoration: "false",
              font_style: "false",
              font_indent: "false",
              line_height: "false",
              align_items: "false",
              justify_content: "false",
              text_align: "false",
              shadow_color: "false",
              shadow_x: "false",
              shadow_y: "false",
              shadow_blur: "false",
              shadow_size: "false",
              outstanding_color: "",
            },
        html: {
          render(node) {
            const data = node.getData();

            return `<div style="position: relative; width: 100%; height: 100%">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100 210" fill="none">
                      <path d="M50,50 m-25,0 a25,25 0 1,0 50,0 a25,25 0 1,0 -50,0 M50 75 L50 150 M50 90 L10 110 M50 90 L90 110 M50 150 L10 200 M50 150 L90 200" stroke="rgba(0, 0, 0, 1)" stroke-width="1"></path>
                    </svg>

                        <div
                            style="
                                display: flex;
                                align-items: ${data.align_items};
                                justify-content: ${data.justify_content};
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                word-wrap: break-word;
                                text-align: ${data.text_align};
                                color: ${data.font_color};
                                font-size: ${data.font_size}px;
                                font-weight: ${data.font_weight};
                                font-style: ${data.font_style};
                                text-decoration: ${data.font_decoration};
                                text-indent: ${data.font_indent}em;
                                line-height: ${data.line_height}px;
                            "
                        >
                            ${data.label}
                        </div>
                    </div>

                  <!--
                    <div style='
                      ${data.key.length > 0 ? "" : "display:none;"}
                      position:fixed;
                      top:-24px;
                      left: ${data.outstanding_color ? "0" : "-7"}px;
                      font-size:13px;
                      font-weight:bold;
                      border-radius: 75px;
                      padding: 2px 10px;
                      ${
                        data.outstanding_color
                          ? "color:white;background:" + data.outstanding_color
                          : "color:#A3A3A3;"
                      }
                '>${data.key}</div> -->
                    `;
          },
          shouldComponentUpdate(node) {
            // 控制节点重新渲染
            return node.hasChanged("data");
          },
        },
      };
      this.graph.addNode(data);
      this.changePortsVisible(false);
      this.$nextTick(() => {
        this.save();
      });
    },
    add_base14(pos) {
      this.graph.addNode({
        id: this.uid("third", "match"),
        shape: "path",
        x: pos.x,
        y: pos.y,
        width: 80,
        height: 180,
        path: "M 30 30 C 30 30 30 10 50 10 C 70 10 70 30 70 30 C 70 30 70 50 50 50 C 30 50 30 30 30 30 M 50 50 L 50 100 M 50 60 L 30 80 M 50 60 L 70 80 M 50 100 L 30 150 M 50 100 L 70 150",
        attrs: {
          body: {
            fill: "none",
            stroke: "black",
          },
        },
      });
    },
    // 曲线
    add_base11(pos, add_node_Data) {
      if (add_node_Data) {
        this.graph.addEdge(add_node_Data);
      } else {
        this.graph.addEdge({
          id: this.uid("edge", "base11"),
          shape: "edge",
          connector: { name: "smooth" },
          source: { x: pos.x, y: pos.y },
          target: { x: pos.x + 150, y: pos.y + 150 },
        });
      }

      this.changePortsVisible(false);
      this.$nextTick(() => {
        this.save();
      });
    },
    // 三角形
    add_base9(pos, add_node_Data, id, width, height, ports) {
      let data = {
        x: pos.x,
        y: pos.y,
        id: id ? id : this.uid("node", "base9"),
        width: width ? width : 225,
        height: height ? height : 60,
        shape: "html",
        ports: this.ports.four_points(ports),

        data: add_node_Data
          ? add_node_Data
          : {
              label: "new node",
              key: "",
              width: 225,
              height: 60,
              border_color: "rgba(66,139,202, 1)",
              border_weight: 5,
              border_type: "false",
              border_radius: "false",
              background_color: "rgba(230,244,255, 1)",
              font_color: "#000",
              font_weight: 600,
              font_size: 18,
              font_decoration: "none",
              font_style: "normal",
              font_indent: 0,
              line_height: 25,
              align_items: "center",
              justify_content: "center",
              text_align: "center",
              shadow_color: "false",
              shadow_x: 0,
              shadow_y: 0,
              shadow_blur: 0,
              shadow_size: "false",
              outstanding_color: "",
              edit: false,
            },
        html: {
          render(node) {
            const data = node.getData();

            return `<div style="position: relative; width: 100%; height: 100%">
                        <svg
                            style="position: absolute; z-index: -1; left: 0"
                            width="100%"
                            height="100%"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <defs>
                                <filter id="drop-shadow" height="130%">
                                    <feGaussianBlur in="SourceAlpha" stdDeviation="${
                                      data.shadow_blur
                                    }" />
                                    <feOffset dx="${data.shadow_x}" dy="${
              data.shadow_y
            }" result="offsetblur" />
                                    <feComponentTransfer>
                                        <feFuncA type="linear" slope="0.5" />
                                    </feComponentTransfer>
                                    <feMerge>
                                        <feMergeNode />
                                        <feMergeNode in="SourceGraphic" />
                                    </feMerge>
                                </filter>
                            </defs>
                            <polygon 60 160
                                points="${data.width / 2},5 ${data.width - 5},${
              data.height - 5
            } 5,${data.height - 5}"
                                style="
                                    fill: ${data.background_color};
                                    stroke: ${data.border_color};
                                    stroke-width: ${data.border_weight};
                                    stroke-dasharray: ${data.border_type};
                                    filter: url(#drop-shadow);
                                "
                            />
                        </svg>
                        <div
                            style="
                                display: flex;
                                align-items: ${data.align_items};
                                justify-content: ${data.justify_content};
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                word-wrap: break-word;
                                text-align: ${data.text_align};
                                color: ${data.font_color};
                                font-size: ${data.font_size}px;
                                font-weight: ${data.font_weight};
                                font-style: ${data.font_style};
                                text-decoration: ${data.font_decoration};
                                text-indent: ${data.font_indent}em;
                                line-height: ${data.line_height}px;
                            "
                        >
                        ${
                          data.edit
                            ? " <textarea id='only_textarea' style='width: 100%;font-size:18px;height: 100%;resize:none;'>" +
                              data.label +
                              "</textarea>"
                            : "<pre style='white-space: break-spaces;width:100%'>" +
                              data.label +
                              "</pre>"
                        }
                        </div>
                    </div>


                    <div style='
                      ${data.key.length > 0 ? "" : "display:none;"}
                      position:fixed;
                      top:-24px;
                      left: ${data.outstanding_color ? "0" : "-7"}px;
                      font-size:13px;
                      font-weight:bold;
                      border-radius: 75px;
                      padding: 2px 10px;
                      ${
                        data.outstanding_color
                          ? "color:white;background:" + data.outstanding_color
                          : "color:#A3A3A3;"
                      }
                '>${data.key}</div>
                    `;
          },
          shouldComponentUpdate(node) {
            // 控制节点重新渲染
            return node.hasChanged("data");
          },
        },
      };
      this.graph.addNode(data);
      this.changePortsVisible(false);
      this.$nextTick(() => {
        this.save();
      });
    },
    // 梯形
    add_base8(pos, add_node_Data, id, width, height, ports) {
      let data = {
        x: pos.x,
        y: pos.y,
        id: id ? id : this.uid("node", "base8"),
        width: width ? width : 160,
        height: height ? height : 60,
        shape: "html",
        ports: this.ports.four_points(ports),

        data: add_node_Data
          ? add_node_Data
          : {
              label: "new node",
              key: "",
              width: 160,
              height: 60,
              border_color: "rgba(66,139,202, 1)",
              border_weight: 5,
              border_type: "false",
              border_radius: "false",
              background_color: "rgba(230,244,255, 1)",
              font_color: "#000",
              font_weight: 600,
              font_size: 18,
              font_decoration: "none",
              font_style: "normal",
              font_indent: 0,
              line_height: 25,
              align_items: "center",
              justify_content: "center",
              text_align: "center",
              shadow_color: "false",
              shadow_x: 0,
              shadow_y: 0,
              shadow_blur: 0,
              shadow_size: "false",
              outstanding_color: "",
              edit: false,
            },
        html: {
          render(node) {
            const data = node.getData();

            return `<div style="position: relative; width: 100%; height: 100%">
                        <svg
                            style="position: absolute; z-index: -1; left: 0"
                            width="100%"
                            height="100%"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <defs>
                                <filter id="drop-shadow" height="130%">
                                    <feGaussianBlur in="SourceAlpha" stdDeviation="${
                                      data.shadow_blur
                                    }" />
                                    <feOffset dx="${data.shadow_x}" dy="${
              data.shadow_y
            }" result="offsetblur" />
                                    <feComponentTransfer>
                                        <feFuncA type="linear" slope="0.5" />
                                    </feComponentTransfer>
                                    <feMerge>
                                        <feMergeNode />
                                        <feMergeNode in="SourceGraphic" />
                                    </feMerge>
                                </filter>
                            </defs>
                            <polygon 60 160
                                points="${data.width / 4},5 ${
              (data.width / 4) * 3
            },5 ${data.width - 5},${data.height - 5} 5,${data.height - 5}"
                                style="
                                    fill: ${data.background_color};
                                    stroke: ${data.border_color};
                                    stroke-width: ${data.border_weight};
                                    stroke-dasharray: ${data.border_type};
                                    filter: url(#drop-shadow);
                                "
                            />
                        </svg>
                        <div
                            style="
                                display: flex;
                                align-items: ${data.align_items};
                                justify-content: ${data.justify_content};
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                word-wrap: break-word;
                                text-align: ${data.text_align};
                                color: ${data.font_color};
                                font-size: ${data.font_size}px;
                                font-weight: ${data.font_weight};
                                font-style: ${data.font_style};
                                text-decoration: ${data.font_decoration};
                                text-indent: ${data.font_indent}em;
                                line-height: ${data.line_height}px;
                            "
                        >
                               ${
                                 data.edit
                                   ? " <textarea id='only_textarea' style='width: 100%;font-size:18px;height: 100%;resize:none;'>" +
                                     data.label +
                                     "</textarea>"
                                   : "<pre style='white-space: break-spaces;width:100%'>" +
                                     data.label +
                                     "</pre>"
                               }
                        </div>
                    </div>


                    <div style='
                      ${data.key.length > 0 ? "" : "display:none;"}
                      position:fixed;
                      top:-24px;
                      left: ${data.outstanding_color ? "0" : "-7"}px;
                      font-size:13px;
                      font-weight:bold;
                      border-radius: 75px;
                      padding: 2px 10px;
                      ${
                        data.outstanding_color
                          ? "color:white;background:" + data.outstanding_color
                          : "color:#A3A3A3;"
                      }
                '>${data.key}</div>
                    `;
          },
          shouldComponentUpdate(node) {
            // 控制节点重新渲染
            return node.hasChanged("data");
          },
        },
      };
      this.graph.addNode(data);
      this.changePortsVisible(false);
      this.$nextTick(() => {
        this.save();
      });
    },
    // 箭头
    add_base7(pos, add_node_Data) {
      if (add_node_Data) {
        console.log(add_node_Data, "add_node_Data");
        if (add_node_Data.data && add_node_Data.data.targetMarker) {
          if (add_node_Data.attrs.line) {
            add_node_Data.attrs.line.targetMarker =
              add_node_Data.data.targetMarker == "null"
                ? null
                : add_node_Data.data.targetMarker;
          } else {
            add_node_Data.attrs.line = {
              targetMarker:
                add_node_Data.data.targetMarker == "null"
                  ? null
                  : add_node_Data.data.targetMarker,
            };
          }
        }
        this.graph.addEdge(add_node_Data);
      } else {
        this.graph.addEdge({
          id: this.uid("edge", "base7"),
          shape: "edge",
          source: { x: pos.x, y: pos.y },
          target: { x: pos.x + 150, y: pos.y },
          attrs: {
            line: {
              // stroke: "#1890ff",
              strokeDasharray: 0,
              // targetMarker: null,
            },
          },
          connector: {
            name: "normal",
            args: {
              radius: 10,
            },
          },
        });
      }

      this.changePortsVisible(false);
      this.$nextTick(() => {
        this.save();
      });
    },
    // 文字
    add_base6(pos, add_node_Data, id, width, height, ports) {
      let data = {
        x: pos.x,
        y: pos.y,
        id: id ? id : this.uid("node", "base6"),
        width: width ? width : 100,
        height: height ? height : 20,
        shape: "html",
        ports: [],

        data: add_node_Data
          ? add_node_Data
          : {
              label: "new node",
              key: "",
              width: 100,
              height: 20,
              border_color: "",
              border_weight: 0,
              border_type: "solid",
              border_radius: 15,
              background_color: "",
              font_color: "#000",
              font_weight: 600,
              font_size: 18,
              font_decoration: "none",
              font_style: "normal",
              font_indent: 0,
              line_height: 25,
              align_items: "center",
              justify_content: "center",
              text_align: "center",
              shadow_color: "#000",
              shadow_x: 0,
              shadow_y: 0,
              shadow_blur: 0,
              shadow_size: 0,
              outstanding_color: "",
              edit: false,
            },
        html: {
          render(node) {
            const data = node.getData();
            console.log(data, 88888);
            //   <div style='height: ${
            //   data.height + (data.outstanding_color ? 30 : 0)
            // }px;width: ${data.width + (data.outstanding_color ? 26 : 0)}px;${
            //   data.outstanding_color
            //     ? "padding:20px 10px 4px 10px;position:fixed;top:-23px;left:-13px;border-radius:5px;border:3px solid " +
            //       data.outstanding_color
            //     : ""
            // };background:${data.outstanding_color}'>;
            return `
            <div style='
                        display: flex;
                        justify-content: ${data.justify_content};
                        align-items: ${data.align_items};
                        word-wrap: break-word;
                        text-align: ${data.text_align};
                        color:${data.font_color};
                        font-size:${data.font_size}px;
                        font-weight:${data.font_weight};
                        font-style:${data.font_style};
                        text-decoration:${data.font_decoration};
                        text-indent:${data.font_indent}em;
                        line-height:${data.line_height}px;
                        background:${data.background_color};
                        width:100%;
                        height:100%;
                        border:${data.border_weight}px ${data.border_type}  ${
              data.border_color
            };
                        border-radius:${data.border_radius}px;
                        box-shadow:${data.shadow_color} ${data.shadow_x}px ${
              data.shadow_y
            }px ${data.shadow_blur}px ${data.shadow_size}px;
                    '>
                                ${
                                  data.edit
                                    ? " <textarea id='only_textarea' style='width: 100%;font-size:18px;height: 100%;resize:none;'>" +
                                      data.label +
                                      "</textarea>"
                                    : "<pre style='white-space: break-spaces;width:100%'>" +
                                      data.label +
                                      "</pre>"
                                }
                 </div>
                <div style='
                      ${data.key.length > 0 ? "" : "display:none;"}
                      position:fixed;
                      top:-24px;
                      left: ${data.outstanding_color ? "0" : "-7"}px;
                      font-size:13px;
                      font-weight:bold;
                      border-radius: 75px;
                      padding: 2px 10px;
                      ${
                        data.outstanding_color
                          ? "color:white;background:" + data.outstanding_color
                          : "color:#A3A3A3;"
                      }
                '>${data.key}</div>`;
          },
          shouldComponentUpdate(node) {
            // 控制节点重新渲染
            return node.hasChanged("data");
          },
        },
      };
      this.graph.addNode(data);
      this.changePortsVisible(false);
      this.$nextTick(() => {
        this.save();
      });
    },
    // 正方形
    add_base5(pos, add_node_Data, id, width, height, ports) {
      let data = {
        x: pos.x,
        y: pos.y,
        id: id ? id : this.uid("node", "base5"),
        width: width ? width : 100,
        height: height ? height : 100,
        shape: "html",
        ports: this.ports.four_points(ports),

        data: add_node_Data
          ? add_node_Data
          : {
              label: "new node",
              key: "",
              width: 100,
              height: 100,
              border_color: "rgba(66,139,202, 1)",
              border_weight: 5,
              border_type: "solid",
              border_radius: 0,
              background_color: "rgba(230,244,255, 1)",
              font_color: "#000",
              font_weight: 600,
              font_size: 18,
              font_decoration: "none",
              font_style: "normal",
              font_indent: 0,
              line_height: 25,
              align_items: "center",
              justify_content: "center",
              text_align: "center",
              shadow_color: "#000",
              shadow_x: 0,
              shadow_y: 0,
              shadow_blur: 0,
              shadow_size: 0,
              outstanding_color: "",
              edit: false,
            },
        html: {
          render(node) {
            const data = node.getData();
            console.log(data, 88888);
            //   <div style='height: ${
            //   data.height + (data.outstanding_color ? 30 : 0)
            // }px;width: ${data.width + (data.outstanding_color ? 26 : 0)}px;${
            //   data.outstanding_color
            //     ? "padding:20px 10px 4px 10px;position:fixed;top:-23px;left:-13px;border-radius:5px;border:3px solid " +
            //       data.outstanding_color
            //     : ""
            // };background:${data.outstanding_color}'>;
            return `
            <div style='
                        display: flex;
                        justify-content: ${data.justify_content};
                        align-items: ${data.align_items};
                        word-wrap: break-word;
                        text-align: ${data.text_align};
                        color:${data.font_color};
                        font-size:${data.font_size}px;
                        font-weight:${data.font_weight};
                        font-style:${data.font_style};
                        text-decoration:${data.font_decoration};
                        text-indent:${data.font_indent}em;
                        line-height:${data.line_height}px;
                        background:${data.background_color};
                        width:100%;
                        height:100%;
                        border:${data.border_weight}px ${data.border_type}  ${
              data.border_color
            };
                        border-radius:${data.border_radius}px;
                        box-shadow:${data.shadow_color} ${data.shadow_x}px ${
              data.shadow_y
            }px ${data.shadow_blur}px ${data.shadow_size}px;
                    '>
                                 ${
                                   data.edit
                                     ? " <textarea id='only_textarea' style='width: 100%;font-size:18px;height: 100%;resize:none;'>" +
                                       data.label +
                                       "</textarea>"
                                     : "<pre style='white-space: break-spaces;width:100%'>" +
                                       data.label +
                                       "</pre>"
                                 }
                 </div>
                <div style='
                      ${data.key.length > 0 ? "" : "display:none;"}
                      position:fixed;
                      top:-24px;
                      left: ${data.outstanding_color ? "0" : "-7"}px;
                      font-size:13px;
                      font-weight:bold;
                      border-radius: 75px;
                      padding: 2px 10px;
                      ${
                        data.outstanding_color
                          ? "color:white;background:" + data.outstanding_color
                          : "color:#A3A3A3;"
                      }
                '>${data.key}</div>`;
          },
          shouldComponentUpdate(node) {
            // 控制节点重新渲染
            return node.hasChanged("data");
          },
        },
      };
      this.graph.addNode(data);
      this.changePortsVisible(false);
      this.$nextTick(() => {
        this.save();
      });
    },
    //  椭圆形
    add_base4(pos, add_node_Data, id, width, height, ports) {
      let data = {
        x: pos.x,
        y: pos.y,
        id: id ? id : this.uid("node", "base4"),
        width: width ? width : 160,
        height: height ? height : 60,
        shape: "html",
        ports: this.ports.four_points(ports),
        data: add_node_Data
          ? add_node_Data
          : {
              label: "new node",
              key: "",
              width: 160,
              height: 60,
              border_color: "rgba(66,139,202, 1)",
              border_weight: 5,
              border_type: "solid",
              border_radius: "false",
              background_color: "rgba(230,244,255, 1)",
              font_color: "#000",
              font_weight: 600,
              font_size: 18,
              font_decoration: "none",
              font_style: "normal",
              font_indent: 0,
              line_height: 25,
              align_items: "center",
              justify_content: "center",
              text_align: "center",
              shadow_color: "#000",
              shadow_x: 0,
              shadow_y: 0,
              shadow_blur: 0,
              shadow_size: 0,
              outstanding_color: "",
              edit: false,
            },
        html: {
          render(node) {
            const data = node.getData();
            return `<div style='
                        display: flex;
                        word-wrap: break-word;
                        text-align: ${data.text_align};
                        justify-content: ${data.justify_content};
                        align-items: ${data.align_items};
                        color:${data.font_color};
                        font-size:${data.font_size}px;
                        font-weight:${data.font_weight};
                        font-style:${data.font_style};
                        text-decoration:${data.font_decoration};
                        text-indent:${data.font_indent}em;
                        background:${data.background_color};
                        width:100%;
                        height:100%;
                        border:${data.border_weight}px ${data.border_type}  ${
              data.border_color
            };
                        border-radius:50%;
                        line-height:${data.line_height}px;
                        box-shadow:${data.shadow_color} ${data.shadow_x}px ${
              data.shadow_y
            }px ${data.shadow_blur}px ${data.shadow_size}px;
                    '>
                               ${
                                 data.edit
                                   ? " <textarea id='only_textarea' style='width: 100%;font-size:18px;height: 100%;resize:none;'>" +
                                     data.label +
                                     "</textarea>"
                                   : "<pre style='white-space: break-spaces;width:100%'>" +
                                     data.label +
                                     "</pre>"
                               }
                    </div>
                      <div style='
                      ${data.key.length > 0 ? "" : "display:none;"}
                      position:fixed;
                      top:-24px;
                      left: ${data.outstanding_color ? "0" : "-7"}px;
                      font-size:13px;
                      font-weight:bold;
                      border-radius: 75px;
                      padding: 2px 10px;
                      ${
                        data.outstanding_color
                          ? "color:white;background:" + data.outstanding_color
                          : "color:#A3A3A3;"
                      }
                '>${data.key}</div>
                    `;
          },
          shouldComponentUpdate(node) {
            // 控制节点重新渲染
            return node.hasChanged("data");
          },
        },
      };
      this.graph.addNode(data);

      this.changePortsVisible(false);

      this.$nextTick(() => {
        this.save();
      });
    },
    //  圆形
    add_base3(pos, add_node_Data, id, width, height, ports) {
      let data = {
        x: pos.x,
        y: pos.y,
        id: id ? id : this.uid("node", "base3"),
        width: width ? width : 120,
        height: height ? height : 120,
        shape: "html",
        ports: this.ports.four_points(ports),
        data: add_node_Data
          ? add_node_Data
          : {
              label: "new node",
              key: "",
              width: 120,
              height: 120,
              border_color: "rgba(66,139,202, 1)",
              border_weight: 5,
              border_type: "solid",
              border_radius: "false",
              background_color: "rgba(230,244,255, 1)",
              font_color: "#000",
              font_weight: 600,
              font_size: 18,
              font_decoration: "none",
              font_style: "normal",
              font_indent: 0,
              line_height: 25,
              align_items: "center",
              justify_content: "center",
              text_align: "center",
              shadow_color: "#000",
              shadow_x: 0,
              shadow_y: 0,
              shadow_blur: 0,
              shadow_size: 0,
              outstanding_color: "",
              edit: false,
            },
        html: {
          render(node) {
            const data = node.getData();
            return `<div style='
                        display: flex;
                        word-wrap: break-word;
                        text-align: ${data.text_align};
                        justify-content: ${data.justify_content};
                        align-items: ${data.align_items};
                        color:${data.font_color};
                        font-size:${data.font_size}px;
                        font-weight:${data.font_weight};
                        font-style:${data.font_style};
                        text-decoration:${data.font_decoration};
                        text-indent:${data.font_indent}em;
                        background:${data.background_color};
                        width:100%;
                        height:100%;
                        border:${data.border_weight}px ${data.border_type}  ${
              data.border_color
            };
                        border-radius:50%;
                        line-height:${data.line_height}px;
                        box-shadow:${data.shadow_color} ${data.shadow_x}px ${
              data.shadow_y
            }px ${data.shadow_blur}px ${data.shadow_size}px;
                    '>
                                   ${
                                     data.edit
                                       ? " <textarea id='only_textarea' style='width: 100%;font-size:18px;height: 100%;resize:none;'>" +
                                         data.label +
                                         "</textarea>"
                                       : "<pre style='white-space: break-spaces;width:100%'>" +
                                         data.label +
                                         "</pre>"
                                   }
                    </div>
                      <div style='
                      ${data.key.length > 0 ? "" : "display:none;"}
                      position:fixed;
                      top:-24px;
                      left: ${data.outstanding_color ? "0" : "-7"}px;
                      font-size:13px;
                      font-weight:bold;
                      border-radius: 75px;
                      padding: 2px 10px;
                      ${
                        data.outstanding_color
                          ? "color:white;background:" + data.outstanding_color
                          : "color:#A3A3A3;"
                      }
                '>${data.key}</div>
                    `;
          },
          shouldComponentUpdate(node) {
            // 控制节点重新渲染
            return node.hasChanged("data");
          },
        },
      };
      this.graph.addNode(data);

      this.changePortsVisible(false);

      this.$nextTick(() => {
        this.save();
      });
    },
    // 菱形
    add_base2(pos, add_node_Data, id, width, height, ports) {
      let data = {
        x: pos.x,
        y: pos.y,
        id: id ? id : this.uid("node", "base2"),
        width: width ? width : 160,
        height: height ? height : 60,
        shape: "html",
        ports: this.ports.four_points(ports),

        data: add_node_Data
          ? add_node_Data
          : {
              label: "new node",
              key: "",
              width: 160,
              height: 60,
              border_color: "rgba(66,139,202, 1)",
              border_weight: 5,
              border_type: "false",
              border_radius: "false",
              background_color: "rgba(230,244,255, 1)",
              font_color: "#000",
              font_weight: 600,
              font_size: 18,
              font_decoration: "none",
              font_style: "normal",
              font_indent: 0,
              line_height: 25,
              align_items: "center",
              justify_content: "center",
              text_align: "center",
              shadow_color: "false",
              shadow_x: 0,
              shadow_y: 0,
              shadow_blur: 0,
              shadow_size: "false",
              outstanding_color: "",
              edit: false,
            },
        html: {
          render(node) {
            const data = node.getData();

            return `<div style="position: relative; width: 100%; height: 100%">
                    <svg
                        style="position: absolute; z-index: -1; left: 0"
                        width="100%"
                        height="100%"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <defs>
                        <filter id="drop-shadow" height="130%">
                            <feGaussianBlur in="SourceAlpha" stdDeviation="${
                              data.shadow_blur
                            }" />
                            <!-- 模糊效果 -->
                            <feOffset dx="${data.shadow_x}" dy="${
              data.shadow_y
            }" result="offsetblur" />
                            <!-- 阴影偏移 -->
                            <feComponentTransfer>
                            <feFuncA type="linear" slope="0.5" />
                            <!-- 阴影透明度 -->
                            </feComponentTransfer>
                            <feMerge>
                            <feMergeNode />
                            <!-- 阴影本身 -->
                            <feMergeNode in="SourceGraphic" />
                            <!-- 原始图形 -->
                            </feMerge>
                        </filter>
                        </defs>
                        <polygon
                        points="${data.border_weight * 0.75},${
              data.height / 2
            } ${data.width / 2},${data.border_weight * 0.5} ${
              data.width - data.border_weight * 0.75
            },${data.height / 2} ${data.width / 2},${
              data.height - data.border_weight * 0.5
            }"
                        style="
                            fill: ${data.background_color};
                            stroke: ${data.border_color};
                            stroke-width:${data.border_weight};
                            stroke-dasharray:${data.border_type} ;
                            filter: url(#drop-shadow);
                        "
                        />
                    </svg>
                    <div
                        style="
                        display: flex;
                        align-items:  ${data.align_items};
                        justify-content: ${data.justify_content};
                        position: absolute;
                        width: 100%;
                        height: 100%;

                        word-wrap: break-word;
                        text-align: ${data.text_align};
                        color:${data.font_color};
                        font-size:${data.font_size}px;
                        font-weight:${data.font_weight};
                        font-style:${data.font_style};
                        text-decoration:${data.font_decoration};
                        text-indent:${data.font_indent}em;
                        line-height:${data.line_height}px;
                        "
                    >
                                   ${
                                     data.edit
                                       ? " <textarea id='only_textarea' style='width: 100%;font-size:18px;height: 100%;resize:none;'>" +
                                         data.label +
                                         "</textarea>"
                                       : "<pre  style='white-space: break-spaces;width:100%'>" +
                                         data.label +
                                         "</pre>"
                                   }
                    </div>
                    </div>

                    <div style='
                      ${data.key.length > 0 ? "" : "display:none;"}
                      position:fixed;
                      top:-24px;
                      left: ${data.outstanding_color ? "0" : "-7"}px;
                      font-size:13px;
                      font-weight:bold;
                      border-radius: 75px;
                      padding: 2px 10px;
                      ${
                        data.outstanding_color
                          ? "color:white;background:" + data.outstanding_color
                          : "color:#A3A3A3;"
                      }
                '>${data.key}</div>
                    `;
          },
          shouldComponentUpdate(node) {
            // 控制节点重新渲染
            return node.hasChanged("data");
          },
        },
      };
      this.graph.addNode(data);
      this.changePortsVisible(false);
      this.$nextTick(() => {
        this.save();
      });
    },
    // 矩形
    add_base1(pos, add_node_Data, id, width, height, ports) {
      let data = {
        x: pos.x,
        y: pos.y,
        id: id ? id : this.uid("node", "base1"),
        width: width ? width : 160,
        height: height ? height : 60,
        shape: "html",
        ports: this.ports.four_points(ports),

        data: add_node_Data
          ? add_node_Data
          : {
              label: "new node",
              key: "",
              width: 160,
              height: 60,
              border_color: "rgba(66,139,202, 1)",
              border_weight: 5,
              border_type: "solid",
              border_radius: 15,
              background_color: "rgba(230,244,255, 1)",
              font_color: "#000",
              font_weight: 600,
              font_size: 18,
              font_decoration: "none",
              font_style: "normal",
              font_indent: 0,
              line_height: 25,
              align_items: "center",
              justify_content: "center",
              text_align: "center",
              shadow_color: "#000",
              shadow_x: 0,
              shadow_y: 0,
              shadow_blur: 0,
              shadow_size: 0,
              outstanding_color: "",
              edit: false,
            },
        html: {
          render(node) {
            const data = node.getData();
            console.log(data, 88888);
            //   <div style='height: ${
            //   data.height + (data.outstanding_color ? 30 : 0)
            // }px;width: ${data.width + (data.outstanding_color ? 26 : 0)}px;${
            //   data.outstanding_color
            //     ? "padding:20px 10px 4px 10px;position:fixed;top:-23px;left:-13px;border-radius:5px;border:3px solid " +
            //       data.outstanding_color
            //     : ""
            // };background:${data.outstanding_color}'>;
            return `
            <div style='
                        display: flex;
                        justify-content: ${data.justify_content};
                        align-items: ${data.align_items};
                        word-wrap: break-word;
                        text-align: ${data.text_align};
                        color:${data.font_color};
                        font-size:${data.font_size}px;
                        font-weight:${data.font_weight};
                        font-style:${data.font_style};
                        text-decoration:${data.font_decoration};
                        text-indent:${data.font_indent}em;
                        line-height:${data.line_height}px;
                        background:${data.background_color};
                        width:100%;
                        height:100%;
                        font-family: "思源黑体" !impoerant;
                        border:${data.border_weight}px ${data.border_type}  ${
              data.border_color
            };
                        border-radius:${data.border_radius}px;
                        box-shadow:${data.shadow_color} ${data.shadow_x}px ${
              data.shadow_y
            }px ${data.shadow_blur}px ${data.shadow_size}px;
                    '>
                               ${
                                 data.edit
                                   ? " <textarea id='only_textarea' style='width: 100%;font-size:18px;height: 100%;resize:none;'>" +
                                     data.label +
                                     "</textarea>"
                                   : "<pre style='white-space: break-spaces;width:100%'>" +
                                     data.label +
                                     "</pre>"
                               }

                 </div>
                <div style='
                      ${data.key.length > 0 ? "" : "display:none;"}
                      position:fixed;
                      top:-24px;
                      left: ${data.outstanding_color ? "0" : "-7"}px;
                      font-size:13px;
                      font-weight:bold;
                      border-radius: 75px;
                      padding: 2px 10px;
                      ${
                        data.outstanding_color
                          ? "color:white;background:" + data.outstanding_color
                          : "color:#A3A3A3;"
                      }
                '>${data.key}</div>`;
          },
          shouldComponentUpdate(node) {
            // 控制节点重新渲染
            return node.hasChanged("data");
          },
        },
      };
      let res = this.graph.addNode(data);
      this.changePortsVisible(false);
      this.$nextTick(() => {
        this.save();
        return res;
      });
    },
    // 矩形
    add_high1(pos, add_node_Data) {
      console.log(add_node_Data);

      const rowHeights = add_node_Data
        ? add_node_Data.rowHeights.filter((_, index) => index !== 0)
        : [50, 50, 50];
      const colWidths = add_node_Data
        ? add_node_Data.colWidths.filter((_, index) => index !== 0)
        : [100, 100, 100];

      console.log(rowHeights, colWidths, add_node_Data);

      const tdata = add_node_Data
        ? add_node_Data.data.table_data
        : [
            [
              {
                backgroundColor: "rgba(100,100,100,1)",
                color: "rgba(0,0,0,1)",
                fontSize: "16",
                fontWeight: "400",
                text: "Cell 1",
                borderSize: "1",
                borderType: "solid",
                borderColor: "rgba(0,0,0,1)",
                alignItems: "center",
                justifyContent: "center",
              },
              {
                backgroundColor: "rgba(100,100,100,1)",
                color: "rgba(0,0,0,1)",
                fontSize: "16",
                fontWeight: "400",
                text: "Cell 2",
                borderSize: "1",
                borderType: "solid",
                borderColor: "rgba(0,0,0,1)",
                alignItems: "center",
                justifyContent: "center",
              },
              {
                backgroundColor: "rgba(100,100,100,1)",
                color: "rgba(0,0,0,1)",
                fontSize: "16",
                fontWeight: "400",
                text: "Cell 3",
                borderSize: "1",
                borderType: "solid",
                borderColor: "rgba(0,0,0,1)",
                alignItems: "center",
                justifyContent: "center",
              },
            ],
            [
              {
                backgroundColor: "rgba(255,255,255,1)",
                color: "rgba(0,0,0,1)",
                fontSize: "16",
                fontWeight: "400",
                text: "Cell 1",
                borderSize: "1",
                borderType: "solid",
                borderColor: "rgba(0,0,0,1)",
                alignItems: "center",
                justifyContent: "center",
              },
              {
                backgroundColor: "rgba(255,255,255,1)",
                color: "rgba(0,0,0,1)",
                fontSize: "16",
                fontWeight: "400",
                text: "Cell 2",
                borderSize: "1",
                borderType: "solid",
                borderColor: "rgba(0,0,0,1)",
                alignItems: "center",
                justifyContent: "center",
              },
              {
                backgroundColor: "rgba(255,255,255,1)",
                color: "rgba(0,0,0,1)",
                fontSize: "16",
                fontWeight: "400",
                text: "Cell 3",
                borderSize: "1",
                borderType: "solid",
                borderColor: "rgba(0,0,0,1)",
                alignItems: "center",
                justifyContent: "center",
              },
            ],
          ];
      let ind = add_node_Data ? add_node_Data.id : this.uid("third", "table");
      let that = this;
      let data = {
        x: add_node_Data ? add_node_Data.position.x : pos.x,
        y: add_node_Data ? add_node_Data.position.y : pos.y,
        id: ind,
        width: add_node_Data ? add_node_Data.size.width : 310,
        height: add_node_Data ? add_node_Data.size.height : 110,
        rowHeights: [10, ...rowHeights],
        colWidths: [10, ...colWidths],
        shape: "html",
        data: { table_data: tdata, edit: false, hiden: "hidden" },
        html: {
          render(node) {
            const data = node.getData();
            return that.generateTableString(
              node.store.data.rowHeights.filter((_, index) => index !== 0),
              node.store.data.colWidths.filter((_, index) => index !== 0),
              data.table_data,
              ind,
              data.hiden
            );
          },
          shouldComponentUpdate(node) {
            // 控制节点重新渲染
            return node.hasChanged("data");
          },
        },
      };
      console.log("high", data);

      let res = this.graph.addNode(data);
      this.changePortsVisible(false);
      this.$nextTick(() => {
        this.save();
        return res;
      });
    },
    strart_free_draw() {
      let can = document.createElement("canvas");
      can.width = window.innerWidth;
      // parseInt(
      //   document.getElementById("center-canvas-container").style.width
      // );
      can.height = window.innerHeight;
      //  parseInt(
      //   document.getElementById("center-canvas-container").style.height
      // );
      can.className = "overlay-canvas";
      can.id = "drawingCanvas";
      can.style.border = "none";
      can.style.position = "fixed";
      can.style.left =
        parseInt(document.querySelector(".catalog_wrap").offsetWidth) +
        6 +
        parseInt(document.querySelector(".left-components-box").offsetWidth) +
        "px";

      document.getElementById("create_canvas").appendChild(can);

      this.points = [];
      // document.getElementById("drawingCanvas").style.width =
      //   document.getElementById("center-canvas-container").style.width;
      // document.getElementById("drawingCanvas").style.height =
      //   document.getElementById("center-canvas-container").style.height;
      this.initCanvasDrawing();
    },
    end_free_draw() {
      document
        .getElementById("create_canvas")
        .removeChild(document.getElementById("drawingCanvas"));
      this.all_points.forEach((ele) => {
        let box = this.getBoundingBox(ele);

        // // 5、优化拖拽体验 代码
        this.graph.addNode({
          id: this.uid("third", "polyline"),
          shape: "polyline",
          x: box.topLeft.x,
          y: box.topLeft.y,
          width: box.width,
          height: box.height,
          label: "",
          points: ele,
          attrs: {
            body: {
              fill: "none",
              stroke: "#000",
            },
          },
        });

        // this.graph.addEdge({
        //   source: ele[0],
        //   target: ele[ele.length - 1],
        //   id: this.uid("edge", "free"),
        //   attrs: {
        //     line: {
        //       targetMarker: null,
        //     },
        //   },
        //   vertices: ele,
        // });
      });
      this.all_points = [];
      this.destroyCanvasDrawing();
      this.save();
    },
    getBoundingBox(elements) {
      const minX = Math.min(...elements.map((point) => point.x));
      const minY = Math.min(...elements.map((point) => point.y));
      const maxX = Math.max(...elements.map((point) => point.x));
      const maxY = Math.max(...elements.map((point) => point.y));

      const width = maxX - minX;
      const height = maxY - minY;

      return {
        topLeft: { x: minX, y: minY },
        width: width,
        height: height,
      };
    },
    /**
     * 获取添加项目（如节点）的位置。
     * 如果拖拽事件（drag_drop）存在，则根据拖拽位置计算添加位置；
     * 否则，根据图表的滚动条位置和窗口大小计算一个默认位置。
     *
     * @param {Object} drag_drop - 拖拽事件对象，包含clientX和clientY属性，表示拖拽时的鼠标位置。
     * @returns {Object} - 返回一个包含x和y属性的对象，表示添加项目的位置。
     */
    get_add_item_position(drag_drop) {
      // 如果拖拽事件存在，根据拖拽位置计算添加位置
      if (drag_drop) {
        console.log(
          this.graph.clientToLocal(drag_drop.clientX, drag_drop.clientY)
        );

        // 将拖拽的客户端坐标转换为图表内部的坐标
        return this.graph.clientToLocal(drag_drop.clientX, drag_drop.clientY);
      }
      // 如果没有拖拽事件，则计算一个基于图表滚动条位置和窗口大小的默认位置
      // 这里假设默认位置在图表可视区域的左下方，但稍微偏离中心一些
      return this.graph.graphToLocal(
        // 左侧位置：基于滚动条左侧位置，减去窗口宽度的35%
        this.graph.getScrollbarPosition().left - window.innerWidth * 0.35 - 100,
        // 顶部位置：基于滚动条顶部位置，减去窗口高度的50%
        this.graph.getScrollbarPosition().top - window.innerHeight * 0.5 - 150
      );
    },
    add_high_model(data) {
      get_id_model_view_template(data).then((res) => {
        this.load_high_base(res);
      });
      this.is_show_model_dialog = false;
      // const high_methods = {
      //   high2: this.load_high_base,
      //   high3: this.load_high_base,
      //   high4: this.load_high_base,
      //   high5: this.load_high_base,
      //   high6: this.load_high_base,
      //   high7: this.load_high_base,
      //   high8: this.load_high_base,
      //   high9: this.load_high_base,
      //   high10: this.load_high_base,
      //   high11: this.load_high_base,
      //   high12: this.load_high_base,
      //   high13: this.load_high_base,
      // };
      // console.log(data);

      // if (high_methods[data.value]) {
      //   high_methods[data.value](data.value);
      // }
      // this.is_show_model_dialog = false;
    },
    add_item(data, drag_drop) {
      let item_position = this.get_add_item_position(drag_drop);
      const methods = {
        base1: this.add_base1,
        base2: this.add_base2,
        base3: this.add_base3,
        base4: this.add_base4,
        base5: this.add_base5,
        base6: this.add_base6,
        base7: this.add_base7,
        base8: this.add_base8,
        base9: this.add_base9,
        base10: this.add_base10,
        base11: this.add_base11,
        base14: this.add_base14,
        base13: this.add_high1,
      };
      if (methods[data]) {
        methods[data](item_position);
      }
      if (data == "base12") {
        if (this.is_free_draw) {
          this.end_free_draw();
          this.is_free_draw = false;
        } else {
          this.is_free_draw = true;
          this.strart_free_draw();
        }
      }

      if (data == "export") {
        // this.add_high1(this.get_add_item_position(drag_drop));
        this.exportJson();
      }
      if (data == "upload") {
        this.show_upload_json_dialog = true;
        this.moban_upload_form = {
          name: "",
          type: "cata4",
          desc: "",
        };
      }
      // if (data == "high3") {
      //   // put_model_view(
      //   //   this.get_pid(),
      //   //   this.$route.params.file_key,
      //   //   this.graph.toJSON()
      //   // );
      // }
    },
    load_high_base(data) {
      // let json_data = loadJson(data);
      let json_data = data;
      let shape = "";
      json_data.cells.sort((a, b) => {
        const order = {
          html: 1, // 最前面
          edge: 3, // 最后面
          default: 2, // 中间
        };
        const orderA = order[a.shape] || order["default"];
        const orderB = order[b.shape] || order["default"];
        return orderA - orderB;
      });
      const methods = {
        base1: this.add_base1,
        base2: this.add_base2,
        base3: this.add_base3,
        base4: this.add_base4,
        base5: this.add_base5,
        base6: this.add_base6,
        base7: this.add_base7,
        base8: this.add_base8,
        base9: this.add_base9,
        base11: this.add_base11,
      };
      for (let item of json_data.cells) {
        if (item.shape == "html") {
          shape = item.id.split("@")[1];
          if (item.id.split("@")[1] == "table") {
            this.add_high1(null, item);

            continue;
          }
          let ports = {};
          item.ports.items.forEach((ele) => {
            ports[ele.group] = ele.id;
          });
          item.data.outstanding_color = "";
          item.data.edit = false;

          if (methods[shape]) {
            methods[shape](
              item.position,
              item.data,
              item.id,
              item.size.width,
              item.size.height,
              ports
            );
          }
        }
        if (item.shape == "image") {
          this.graph.addNode(item);
        }
        if (item.shape == "polyline") {
          this.graph.addNode(item);
        }
        if (item.shape == "path") {
          this.graph.addNode(item);
        }
        if (item.shape == "edge") {
          shape = item.id.split("@")[1];
          if (methods[shape]) {
            methods[shape](null, item);
          } else {
            this.graph.addEdge(item);
          }
        }
      }
    },
    handleMouseDown(e) {
      this.isDrawing = true;
      this.points.push({ x: e.offsetX, y: e.offsetY });
    },
    handleMouseMove(e) {
      if (!this.isDrawing) return;
      this.ctx.beginPath();
      if (this.points.length > 1) {
        this.ctx.moveTo(
          this.points[this.points.length - 1].x,
          this.points[this.points.length - 1].y
        );
      }
      this.ctx.lineTo(e.offsetX, e.offsetY);
      this.ctx.stroke();
      this.points.push({ x: e.offsetX, y: e.offsetY });
    },
    handleMouseUp(e) {
      this.isDrawing = false;
      // 注意：this.points = points; 这行在Vue组件中是不必要的，因为points已经是响应式数据
      let translatedPoints = [];
      let local_ = this.graph.clientToLocal(e.clientX, e.clientY);
      // let graph_ = this.graph.localToGraph(local_.x, local_.y);
      let graph_ = this.graph.clientToLocal(e.clientX, e.clientY);
      let scal = this.graph.zoom();
      let scal_point = this.points[this.points.length - 1];
      let dx = graph_.x - scal_point.x;
      let dy = graph_.y - scal_point.y;
      this.points.forEach((point) => {
        // 计算平移后的 x 和 y 坐标
        let newX = point.x + dx;
        let newY = point.y + dy;

        newX = graph_.x + (newX - graph_.x) / scal;
        newY = graph_.y + (newY - graph_.y) / scal;

        // ((newX-scal_point.x)**2+(newY-scal_point.y)**2)**0.5
        // 创建一个新对象来表示平移后的点，并将其添加到 translatedPoints 数组中
        translatedPoints.push({ x: newX, y: newY });
      });
      this.all_points.push(translatedPoints);
      this.points = [];
    },
    initCanvasDrawing() {
      this.canvas = document.getElementById("drawingCanvas");
      this.ctx = this.canvas.getContext("2d");

      this.canvas.addEventListener("mousedown", this.handleMouseDown);
      this.canvas.addEventListener("mousemove", this.handleMouseMove);
      this.canvas.addEventListener("mouseup", this.handleMouseUp);
    },
    destroyCanvasDrawing() {
      if (this.canvas) {
        this.canvas.removeEventListener("mousedown", this.handleMouseDown);
        this.canvas.removeEventListener("mousemove", this.handleMouseMove);
        this.canvas.removeEventListener("mouseup", this.handleMouseUp);
      }
    },

    generateTableString(rowHeights, colWidths, data, ind, hiden) {
      // 更新 rowHeights 和 colWidths 以包含横条和竖条的尺寸
      const headerHeight = 10; // 顶部横条的高度
      const headerWidth = 10; // 左侧竖条的宽度

      // 修改行高和列宽
      const updatedRowHeights = [headerHeight, ...rowHeights];
      const updatedColWidths = [headerWidth, ...colWidths];

      let tableString =
        "<table class='never_same_table_by_zdh' id=" +
        ind +
        ' style="border-collapse: collapse; table-layout: fixed;cursor:pointer">';

      // 生成表头行
      tableString += `<tr style='visibility:${hiden}'>`;

      // 左上角单元格（空白）
      tableString += `<td class='lefttableString' style="width: 10px; height: 10px;"></td>`;

      // 添加表头的每一列
      for (let colIndex = 0; colIndex < colWidths.length; colIndex++) {
        tableString += `
            <td id='colrowIndex@${ind}' style="width: ${colWidths[colIndex]}px;height:10px">
                <div style="display: flex; width: 100%; height: 100%;">
                    <div id="tableBtn@${colIndex}@colIndex@add@left" style="background:#174fa9;color:white;font-size:20px;height:10px;width:33%">
                    </div>
                    <div id="tableBtn@${colIndex}@colIndex@delete@delete" style="background:#F56C6C;color:white;font-size:20px;height:10px;width:33%">
                    </div>
                    <div id="tableBtn@${colIndex}@colIndex@add@right" style="background:#174fa9;color:white;font-size:20px;height:10px;width:33%">
                    </div>
                </div>
            </td>
        `;
      }

      tableString += "</tr>";

      // 生成表格内容行
      for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
        let row = data[rowIndex];
        tableString += `<tr>`;

        // 第一列的每个单元格
        tableString += `
            <td id='colrowIndex@${ind}' style="width:10px;height: ${
          updatedRowHeights[rowIndex + 1]
        }px;visibility:${hiden}">
            <div style="width:100%;height: 100%;display: flex;
              flex-direction: column;
              align-items: flex-end;">
                    <div id="tableBtn@${rowIndex}@rowIndex@add@before" style="height:33%;background:#174fa9;color:white;font-size:20px;width:10px"></div>
                    <div id="tableBtn@${rowIndex}@rowIndex@delete@delete" style="height:33%;background:#F56C6C;color:white;font-size:20px;width:10px"></div>
                    <div id="tableBtn@${rowIndex}@rowIndex@add@after" style="height:33%;background:#174fa9;color:white;font-size:20px;width:10px"></div>
            </div> </td>
        `;

        // 其他单元格
        for (let colIndex = 0; colIndex < row.length; colIndex++) {
          let cell = row[colIndex];
          let {
            backgroundColor,
            color,
            fontSize,
            fontWeight,
            text,
            borderSize,
            borderType,
            borderColor,
            alignItems,
            justifyContent,
          } = cell;

          tableString += `
                <td style="
                    width: ${colWidths[colIndex + 1]}px;
                    background-color: ${backgroundColor};
                    color: ${color};
                    font-size: ${fontSize}px;
                    font-weight: ${fontWeight};
                    border: ${borderSize}px ${borderType} ${borderColor};
                ">
                    <pre id='tableCell@${rowIndex}@${colIndex}' style='white-space: break-spaces;width:100%;height:100%;display:flex;align-items: ${alignItems};
                    justify-content: ${justifyContent};'>${text}</pre>
                </td>`;
        }

        tableString += "</tr>";
      }

      tableString += "</table>";
      tableString = "<div style='width:10px'>" + tableString + "</div>";
      return tableString;
    },

    uid(type, item) {
      const timestamp = Date.now();
      const randomNum = Math.floor(Math.random() * 1000000); // 生成一个0到999999之间的随机数
      return type + "@" + item + "@" + timestamp + "-" + randomNum;
    },
  },
};
</script>

<style lang="scss" scoped>
.context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #e4e7ed;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  z-index: 1000;
  font-size: 16px;
  color: #707070;
  width: 200px;
  .item {
    padding: 5px;

    height: 35px;
    display: flex;
    align-items: center;
    // justify-content: center;
    cursor: pointer;
    border: 2px solid white;
  }
  .item:hover {
    border: 2px solid #4a77e0;
    border-radius: 5px;
  }
}
::v-deep .el-collapse-item__header:hover {
  background: rgb(237, 238, 238);
}
.title-font {
  font-size: 24px;
  margin-left: 10px;
  font-weight: 700;
  font-family: "element-icons";
}
.left-components-box {
  z-index: 999;
  height: calc(100vh - 180px);
  .hide {
    height: 25px;
    cursor: pointer;
    font-size: 20px;
    text-align: left;
    width: 100%;
    i {
      font-size: 20px;
    }
  }
  .hide :hover {
    background: #a6a6a6;
  }
  .line_tool {
    z-index: 999;
    background: white;
    height: calc(100% - 25px);
    display: flex;
    flex-direction: column;
    align-items: center;
    .line {
      height: 1px;
      width: 100%;
      background: #707070;
    }
    .top {
      height: 90%;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .btn {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .el-collapse {
    border-top: none !important;
    border-bottom: none !important;
    height: calc(100% - 25px);
  }
  .container {
    display: flex;
    flex-wrap: wrap;
  }
  .icon {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    margin: 0 10px;
  }
  .icon:hover {
    background: rgb(230, 231, 231);
    border: 1px solid rgb(230, 231, 231);
    border-radius: 5px;
  }
}
.position_button_zdh {
  font-size: 14px;
  display: flex;
}
.position_button_zdh_end {
  font-size: 14px;

  display: flex;
  justify-content: flex-end;
}

.overlay-canvas {
  border: none;
}
.center-canvas-container {
  background: white;
  width: 100%;
  height: 100vh;
}
.modelView_right_operate {
  ::v-deep .el-dialog__body {
    padding: 0 5px 0 10px !important;
  }
  .modelView_right_operate_cardBody {
    max-height: 50vh;
    overflow: auto;
  }
  .list {
    margin: 10px 0;
    display: flex;
    align-items: center;
    ::v-deep .el-radio-group {
      display: flex;
    }
    span {
      min-width: 100px;
      text-align: left;
    }
  }
}
::v-deep .modelView_right_operate_dialog_body {
  pointer-events: auto !important;
  top: 100px;
  right: 50px;
  position: absolute;
}

::v-deep .modelView_right_operate {
  pointer-events: none;
}

.catalog_moban_zdh {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  // text-indent: 30px;
  width: 160px;
  cursor: pointer;
  margin: 20px 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  padding: 10px;
  color: black;
}
.catalog_moban_zdh:hover {
  background: #d0d0d0;
}

.catalog_moban_zdh_active {
  background: rgba(6, 64, 166, 0.2);
  color: #0640a6;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  // text-indent: 30px;
  width: 160px;
  cursor: pointer;
  margin: 20px 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 10px;
}
</style>

<style lang="scss">
#zdh_model_view_moban {
  .el-dialog__header {
    border-bottom: 3px solid #e5e5e5;
  }
  .el-dialog__body {
    padding: 0 !important;
  }
}
canvas {
}
.outstanding_detail_dialog {
  pointer-events: none;
}
.outstanding_line {
  margin-bottom: 15px;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
}

.outstanding_dialog_table_class td,
.outstanding_dialog_table_class th.is-leaf {
  border: none;
}

.outstanding_dialog_table_class::before {
  width: 0;
}

.outstanding_dialog_table_class {
  margin-bottom: 10px;
}
.outstanding_dialog_table_class_cell {
  text-align: start;
  font-size: 18px;
  .cell {
    padding: 0 !important;
  }
  .outstanding_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.outstanding_dialog_color {
  width: 18px;
  height: 18px;
  // background-color: #0090f1;
  margin-right: 10px;
}
.outstanding_detail_dialog_body {
  .el-dialog__body {
    padding-right: 0 !important;
  }
  .el-dialog__header {
    padding-bottom: 0 !important;
  }
}
.outstanding_node {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 25px;
}
.moban_item {
  width: 250px;
  height: 310px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  margin: 20px;
  img {
    // height: 200px;
    width: 90%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}
</style>
<style lang="scss">
.moban_upload_form_zdh {
  .el-form-item {
    display: flex;
  }
  .el-form-item__content {
    flex-grow: 1;
  }
}
.never_same_table_by_zdh {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 2px solid #000;
    /* padding: 40px 20px; */
    width: 100px;
    height: 50px;
  }
  /* :not(:last-child) */
  /* th:not(:last-child),
td:not(:last-child) {
  resize: auto;
  overflow: auto;
} */
  .lefttableString {
    resize: none !important;
    border: none !important;
    background-color: white !important;
    width: 10px !important;
    height: 10px !important;
  }
  tr:first-child td:not(:first-child) {
    border: none !important;
    resize: horizontal;
    overflow: auto;
    cursor: pointer;
  }
  td:first-child {
    border: none !important;
    resize: vertical;
    overflow: auto;
    cursor: pointer;
  }
}
</style>
