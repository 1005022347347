let hara_data = [
    {
        ch: "一级-致命故障",
        en: "Level 1- Fatal Failure",
        value: "",
        label: "",
        children: [
            {
                ch: "MCU直流母线过压故障",
                en: "MCU DC bus overvoltage fault",
                value: "",
                label: "",
            },
            {
                ch: "BMS一级故障",
                en: "BMS Level 1 Fault",
                value: "",
                label: "",
            },
        ],
    },
    {
        ch: "二级-严重故障",
        en: "Level 2- Severe Fault",
        value: "",
        label: "",
        children: [
            {
                ch: "MCU相电流过流、IGBT、旋变等故障",
                en: "MCU phase current overcurrent, IGBT, rotary transformer and other faults",
                value: "",
                label: "",
            },
            {
                ch: "电机节点丢失故障",
                en: "Motor node loss fault",
                value: "",
                label: "",
            },
            {
                ch: "档位信号故障",
                en: "Gear signal malfunction",
                value: "",
                label: "",
            },
        ],
    },
    {
        ch: "三级-一般故障",
        en: "Level 3- General Fault",
        value: "",
        label: "",
        children: [
            {
                ch: "加速踏板信号故障",
                en: "Accelerator pedal signal malfunction",
                value: "",
                label: "",
            },
            {
                ch: "MCU电机超速保护",
                en: "MCU motor overspeed protection",
                value: "",
                label: "",
            },
            {
                ch: "跛行故障",
                en: "Limp malfunction",
                value: "",
                label: "",
            },
            {
                ch: "SOC<1",
                en: "SOC<1",
                value: "",
                label: "",
            },
            {
                ch: "BMS单体欠压",
                en: "BMS monomer undervoltage",
                value: "",
                label: "",
            },
            {
                ch: "低压欠压故障",
                en: "Low voltage undervoltage fault",
                value: "",
                label: "",
            },

        ],
    },
    {
        ch: "四级-轻微故障",
        en: "Level 4- Minor Fault",
        value: "",
        label: "",
        children: [
            {
                ch: "MCU电机系统温度传感器故障",
                en: "MCU motor system temperature sensor failure",
                value: "",
                label: "",
            },
            {
                ch: "直流欠压故障",
                en: "DC undervoltage fault",
                value: "",
                label: "",
            },
            {
                ch: "VCU硬件故障",
                en: "VCU hardware failure",
                value: "",
                label: "",
            },
            {
                ch: "DCDC异常",
                en: "DCDC abnormality",
                value: "",
                label: "",
            },
        ],
    },
    {
        ch: "Loss of function",
        en: "Loss of function",
        value: "",
        label: "",
        children: [
            {
                ch: "方向盘无法感知扭矩",
                en: "The steering wheel cannot sense torque",
                value: "",
                label: "",
                children: [
                    {
                        ch: "意外的车辆横向运动/意外的偏航",
                        en: "Unexpected lateral vehicle movement/unexpected yaw",
                        value: "",
                        label: "",
                    }, {
                        ch: "车辆横向运动不足/偏航不足",
                        en: "Insufficient lateral movement/yaw of the vehicle",
                        value: "",
                        label: "",
                    }, {
                        ch: "意外失去转向助力",
                        en: "Accidental loss of steering assistance",
                        value: "",
                        label: "",
                    },
                ]
            }, {
                ch: "感测到的扭矩大于实际扭矩",
                en: "The sensed torque is greater than the actual torque",
                value: "",
                label: "",
                children: [
                    {
                        ch: "意外的车辆横向运动/意外的偏航",
                        en: "Unexpected lateral vehicle movement/unexpected yaw",
                        value: "",
                        label: "",
                    },
                ]
            },
        ],
    },
    {
        ch: "More than intended",
        en: "More than intended",
        value: "",
        label: "",
        children: [
            {
                ch: "所需要的扭矩频率小于感知扭矩的频率",
                en: "The required torque frequency is less than the frequency of perceived torque",
                value: "",
                label: "",
            },
        ],
    },
    {
        ch: "Less than intended",
        en: "Less than intended",
        value: "",
        label: "",
        children: [
            {
                ch: "感知的扭矩小于实际扭矩",
                en: "The perceived torque is less than the actual torque",
                value: "",
                label: "",
                children: [
                    {
                        ch: "车辆横向运动不足/偏航不足",
                        en: "Insufficient lateral movement/yaw of the vehicle",
                        value: "",
                        label: "",
                    },
                ]
            }, {
                ch: "比需要的频率更少的感知扭矩",
                en: "Less perceived torque than required frequency",
                value: "",
                label: "",
                children: [
                    {
                        ch: "意外的车辆横向运动/意外的偏航",
                        en: "Unexpected lateral vehicle movement/unexpected yaw",
                        value: "",
                        label: "",
                    }, {
                        ch: "车辆横向运动不足/偏航不足",
                        en: "Insufficient lateral movement/yaw of the vehicle",
                        value: "",
                        label: "",
                    },
                ]
            },
        ],
    },
    {
        ch: "Intermittent",
        en: "Intermittent",
        value: "",
        label: "",
        children: [
            {
                ch: "间歇性地感知扭矩",
                en: "Intermittently sensing torque",
                value: "",
                label: "",
                children: [
                    {
                        ch: "意外的车辆横向运动/意外的偏航",
                        en: "Unexpected lateral vehicle movement/unexpected yaw",
                        value: "",
                        label: "",
                    },
                ]
            },
        ],
    },
    {
        ch: "Incorrect direction",
        en: "Incorrect direction",
        value: "",
        label: "",
        children: [
            {
                ch: "感应相反方向的扭矩",
                en: "Sensing torque in the opposite direction",
                value: "",
                label: "",
                children: [
                    {
                        ch: "意外的车辆横向运动/意外的偏航",
                        en: "Unexpected lateral vehicle movement/unexpected yaw",
                        value: "",
                        label: "",
                    },
                ]
            },
        ],
    },
    {
        ch: "Not requested",
        en: "Not requested",
        value: "",
        label: "",
        children: [
            {
                ch: "没有转向输入时感应扭矩",
                en: "Sensing torque without steering input",
                value: "",
                label: "",
                children: [
                    {
                        ch: "意外的车辆横向运动/意外的偏航",
                        en: "Unexpected lateral vehicle movement/unexpected yaw",
                        value: "",
                        label: "",
                    },
                ]
            },
        ],
    },
    {
        ch: "Locked function",
        en: "Locked function",
        value: "",
        label: "",
        children: [
            {
                ch: "无论转向输入如何,都能感应恒定扭矩",
                en: "Regardless of the steering input, it can sense a constant torque",
                value: "",
                label: "",
                children: [
                    {
                        ch: "意外的车辆横向运动/意外的偏航",
                        en: "Unexpected lateral vehicle movement/unexpected yaw",
                        value: "",
                        label: "",
                    }, {
                        ch: "车辆横向运动不足/偏航不足",
                        en: "Insufficient lateral movement/yaw of the vehicle",
                        value: "",
                        label: "",
                    },
                ]
            },
        ],
    },
]

function modify_ch(data) {
    data.value = data.ch
    data.label = data.ch
    if (data.children) {
        for (let i of data.children) {
            modify_ch(i)
        }
    }
}

function modify_en(data) {
    data.value = data.en
    data.label = data.en
    if (data.children) {
        for (let i of data.children) {
            modify_en(i)
        }
    }
}
function get_hara_data(lang) {
    if (lang == "ch") {
        for (let i of hara_data) {
            modify_ch(i)
        }
    } else {
        for (let i of hara_data) {
            modify_en(i)
        }
    }

    return hara_data;
}
export default get_hara_data;
