<template>
  <div
    ref="quick_wrap"
    v-loading="loading"
    class="quick_wrap"
    :element-loading-text="$t('loading.loading1')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
    @dragover="outer_dragover($event)"
  >
    <div class="quick_main_wrap">
      <el-collapse-transition>
        <div v-show="show_top">
          <p class="title">{{ project_type }}</p>
          <div ref="top_options" class="top_options">
            <span class="left_wrap">
              <el-select
                v-if="project_type === 'SCRUM'"
                v-model="cur_sprint_id"
                size="small"
                filterable
                :disabled="is_show_chart"
                style="padding-right: 10px"
                popper-class="select_own"
                @change="sprint_change"
              >
                <el-option
                  :key="'add'"
                  class="test-add-option"
                  :value="cur_sprint_id"
                >
                  <div style="width: 100%">
                    <div
                      style="
                        width: 100%;
                        display: flex;
                        justify-content: center;
                      "
                      class="test-add"
                      @click="sprint_add"
                    >
                      <i class="iconfont icon-add"></i>
                    </div>
                  </div>
                </el-option>
                <el-option
                  v-for="item in scrum_options"
                  :key="item.sprintId"
                  :label="item.build_name"
                  :value="item.sprintId"
                >
                </el-option>
              </el-select>

              <i
                v-if="project_type === 'SCRUM' && !is_show_chart"
                style="
                  width: 20px;
                  height: 20px;
                  cursor: pointer;
                  font-size: 23px;
                  color: rgb(48, 100, 143);
                "
                class="iconfont icon-chart"
                @click="show_chart"
              />
              <i
                v-if="project_type === 'SCRUM' && is_show_chart"
                style="
                  width: 20px;
                  height: 20px;
                  cursor: pointer;
                  font-size: 23px;
                  color: rgb(48, 100, 143);
                "
                class="iconfont icon-chart_on"
                @click="show_chart"
              />
              <span
                v-if="top_options.status !== 'ENDED' && !is_show_chart"
                class="type_button"
                :style="{
                  'margin-left': project_type === 'SCRUM' ? '70px' : '0px',
                }"
                :class="top_options.type_model === 1 ? 'button_checked' : ''"
                @click="type_click(1)"
                >{{ $t("board.fileFilter") }}</span
              >
              <span
                v-if="top_options.status !== 'ENDED' && !is_show_chart"
                class="type_button"
                :class="top_options.type_model === 2 ? 'button_checked' : ''"
                @click="type_click(2)"
                >{{ $t("board.responsibilityFilter") }}</span
              >
              <div
                v-if="
                  top_options.status !== 'ENDED' &&
                  top_options.type_model === 2 &&
                  !is_show_chart
                "
                class="user_img_wrap"
              >
                <span
                  v-for="(user, index) in limit_user_dtoList"
                  :key="index"
                  @click="user_click(user, index)"
                >
                  <div
                    v-if="
                      user.avatar &&
                      (user.avatar.indexOf('.png') != -1 ||
                        user.avatar.indexOf('.jpg') != -1 ||
                        user.avatar.indexOf('.svg') != -1)
                    "
                  >
                    <personalAvatar
                      :avatar="user.avatar"
                      :size="top_options.user_model === index ? 40 : 30"
                      :nickname="user.nickname"
                      :placement="'top'"
                    ></personalAvatar>
                  </div>
                  <div v-else class="item">
                    <personalAvatar
                      :size="top_options.user_model === index ? 40 : 30"
                      :nickname="
                        user.nickname === $t('board.status.unAssigned')
                          ? $t('board.status.unAssigned')
                          : user.nickname
                      "
                      :colorid="user.accountId"
                      :placement="'top'"
                    ></personalAvatar>
                  </div>
                </span>
                <i
                  v-if="user_dtoList.length - 1 > user_range1"
                  class="iconfont icon-threepoint"
                  style="cursor: pointer; color: rgb(48, 100, 143)"
                  @click="change_userlist"
                />
              </div>
            </span>
            <div
              v-if="top_options.status !== 'ENDED' && !is_show_chart"
              class="right_button_wrap"
            >
              <i
                v-if="
                  top_options.status !== 'NOT_STARTED' &&
                  project_type === 'SCRUM'
                "
                class="iconfont icon-clocks"
                style="width: 20px; height: 20px; margin-bottom: -2px"
              ></i>
              <span
                v-if="
                  top_options.status !== 'NOT_STARTED' &&
                  project_type === 'SCRUM'
                "
                >{{ $t("scrum.clock.part1") }}{{ top_options.left_day
                }}{{ $t("scrum.clock.part2") }}</span
              >
              <el-button
                v-if="project_type === 'SCRUM'"
                type="primary"
                @click="complete"
              >
                <i
                  class="iconfont"
                  :class="
                    top_options.status === 'NOT_STARTED'
                      ? 'icon-play'
                      : 'icon-checkmark'
                  "
                  style="margin-right: 2px; font-weight: 700"
                ></i
                >{{
                  top_options.status === "NOT_STARTED"
                    ? $t("btn.startBtn")
                    : $t("btn.completeBtn")
                }}
              </el-button>
              <el-button
                v-if="project_type === 'SCRUM'"
                type="primary"
                @click="edit"
              >
                <i class="iconfont icon-branch_edit"></i>
                {{ $t("btn.editBtn") }}
              </el-button>
              <i
                class="set_button iconfont icon-a-31shezhi hover-icon"
                style="font-size: 20px; width: fit-content; height: fit-content"
                @click="jump_setting"
              >
              </i>
            </div>
            <div
              v-if="top_options.status === 'ENDED' && !is_show_chart"
              class="right_button_wrap"
              style="font-size: 14px; color: rgba(128, 128, 128, 1)"
            >
              {{ top_options.startDateTime }}- {{ top_options.endDateTime }}
            </div>
          </div>
          <el-collapse
            v-if="!is_show_chart"
            v-model="activeNames"
            class="scroll_wrap"
            @change="handleChange"
          >
            <el-collapse-item
              class="collapse_item"
              name="1"
              style="margin-bottom: 0"
            >
              <div slot="title" class="collapse-title">
                {{ $t("scrum.goal") }}:
              </div>
              <div v-if="project_type === 'SCRUM'" class="sprint_target">
                <el-scrollbar
                  style="
                    max-height: 160px;
                    overflow-y: scroll;
                    overflow-x: hidden;
                  "
                  ><span v-html="newDescription"></span
                ></el-scrollbar>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-collapse-transition>
      <div v-if="!is_show_chart" class="sprint_options_hide" @click="hide_top">
        <span v-show="show_top"
          ><i
            class="iconfont icon-fold_up"
            style="color: #000; font-size: 15px; margin-top: 5px"
          />
          {{ $t("switch.PutAway") }}</span
        >
        <span v-show="!show_top"
          ><i
            class="iconfont icon-fold_down"
            style="color: #000; font-size: 15px"
          />
          {{ $t("switch.Expand") }}</span
        >
      </div>
      <div
        class="scroll_wrap"
        :style="{
          'overflow-y': 'hidden',
        }"
      >
        <!-- 添加节点 -->
        <div
          v-if="top_options.status !== 'ENDED' && !is_show_chart"
          class="add_node_wrap"
        >
          <div id="quick_select">
            <el-select
              v-model="multiple_node"
              multiple
              size="small"
              :placeholder="$t('scrum.place')"
              filterable
              clearable
              remote
              style="margin-right: 10px"
              :remote-method="get_nodes_options"
              :popper-append-to-body="false"
              :loading="search_loading"
              popper-class="select-icon-height-auto-class"
              reserve-keyword
            >
              <el-option
                v-for="item in multiple_node_options"
                :key="item.nodeKey"
                :value="item.nodeKey"
              >
                <!-- ms8613代码定位 -->
                <select-icon :item="item"> </select-icon>
              </el-option>
            </el-select>
          </div>
          <span>
            <el-button
              v-limits-of-authority="'NODE_EDIT'"
              :disabled="limits_of_authority_disable('NODE_EDIT')"
              type="primary"
              @click="node_add_confirm"
            >
              {{ $t("btn.addBtn") }}</el-button
            >
          </span>
        </div>

        <div
          v-if="top_options.status !== 'ENDED' && !is_show_chart"
          ref="block_wrap"
          class="block_wrap"
        >
          <div
            v-for="(item, index) in scrum_column_list"
            :key="index"
            :style="{
              color: item.color,
              backgroundColor: item.backgroundColor,
              width: `calc((100% - ${
                (scrum_column_list.length - 1) * 29
              }px) / ${scrum_column_list.length})`,
            }"
          >
            <span>{{ item.name }}</span>
            <span
              v-if="
                blocks_options[index] === 0 ||
                story_point_options[index] == 0 ||
                blocks_options[index] ||
                story_point_options[index]
              "
              style="display: flex; align-items: center"
            >
              <i
                class="iconfont icon-board_num"
                style="
                  display: inline-block;
                  margin-bottom: 2px;
                  margin-right: 6px;
                "
              />
              <span
                >{{ blocks_options[index] }}&nbsp;&nbsp;, &nbsp;&nbsp;{{
                  story_point_options[index]
                }}SP</span
              >
              <el-tooltip class="item" :content="$t('board.notice')">
                <i
                  class="iconfont icon-help"
                  style="display: inline-block; margin-left: 6px"
                />
              </el-tooltip>
            </span>
          </div>
        </div>

        <div
          v-if="top_options.status !== 'ENDED' && !is_show_chart"
          ref="user_block_wrap"
          class="user_block_wrap"
          @scroll="handle_scroll"
        >
          <div class="fake_scroll">
            <div
              v-for="(user_item, user_index) in build_scrum_column_list"
              :key="user_index"
            >
              <collapse
                v-if="user_item.isShow"
                :id="'id' + user_item.id"
                arrow_position="right"
                style="margintop: 6px"
              >
                <span slot="title" style="color: rgba(128, 128, 128, 1)">{{
                  user_item.name
                }}</span>
                <div class="inner_user_block">
                  <div
                    v-for="(column_item, column_index) in scrum_column_list"
                    :key="column_index"
                    :style="{
                      color: column_item.color,
                      backgroundColor: column_item.backgroundColor,
                      width: `calc((100% - ${
                        (scrum_column_list.length - 1) * 29
                      }px) / ${scrum_column_list.length})`,
                    }"
                    :class="{
                      accept_drag:
                        is_drag &&
                        user_index === drag_item.user_index &&
                        can_edit_status_check(column_item),
                      accept_drag_focus:
                        is_drag &&
                        drag_item.user_index === user_index &&
                        target_column_index == column_index,
                    }"
                    @dragover="dragover(user_index, column_index)"
                  >
                    <div
                      v-if="
                        user_item.data[column_index] &&
                        !(
                          is_drag &&
                          user_index === start_userindex &&
                          column_index !== drag_item.column_index &&
                          can_edit_status_check(column_item)
                        )
                      "
                      class="node_list"
                    >
                      <transition-group tag="div" name="moveitem">
                        <div
                          v-for="(item, index) in user_item.data[column_index]
                            .dataList"
                          :key="item.key"
                          style="position: relative"
                        >
                          <div
                            v-if="
                              index < user_item.data[column_index].showNumber
                            "
                            :id="item.nodeUuid"
                            draggable
                            @dragstart="
                              dragging($event, user_index, column_index, index)
                            "
                            @dragend="dragend"
                            @click="show_drawer(item)"
                          >
                            <p class="tooltip_span_fp">
                              <span style="display: flex">
                                <div class="box_item_l">
                                  <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="fileType[item.fileTypeId].name"
                                    placement="top"
                                  >
                                    <span style="background-color: #014caa">
                                      <i
                                        v-if="
                                          get_file_icon(item.fileTypeId) !==
                                          '-1'
                                        "
                                        class="iconfont"
                                        :class="
                                          'icon-a-' +
                                          get_file_icon(item.fileTypeId)
                                        "
                                        style="color: #fff; line-height: normal"
                                      ></i>
                                      <span
                                        v-else
                                        style="color: white; font-weight: 700"
                                        >{{
                                          fileType[item.fileTypeId].name.slice(
                                            0,
                                            1
                                          )
                                        }}</span
                                      >
                                    </span>
                                  </el-tooltip>
                                </div>
                                <div class="box_item_r">
                                  <span
                                    class="box_font"
                                    @click.stop="jump_node(item)"
                                    >{{ item.key }}</span
                                  >
                                </div>
                              </span>
                              <span>
                                <el-tooltip
                                  v-if="
                                    item.assignee &&
                                    top_options.type_model === 1
                                  "
                                  effect="dark"
                                  :content="get_assigneeName(item.assignee)"
                                  placement="top"
                                >
                                  <el-avatar
                                    v-if="get_avatar(item.assignee)"
                                    fit="fill"
                                    :src="get_avatar(item.assignee)"
                                    :size="28"
                                    shape="circle"
                                    style="
                                      background-color: transparent;
                                      vertical-align: bottom;
                                    "
                                  >
                                  </el-avatar>
                                  <el-avatar
                                    v-else
                                    shape="circle"
                                    :size="28"
                                    :style="{
                                      backgroundColor:
                                        avatar_color[
                                          Math.abs(
                                            user_index -
                                              (avatar_color.length - 1)
                                          )
                                        ],
                                    }"
                                    style="vertical-align: bottom"
                                  >
                                    {{
                                      get_assigneeName(item.assignee)
                                        .slice(0, 1)
                                        .toUpperCase()
                                    }}
                                  </el-avatar>
                                </el-tooltip>
                                <span v-if="top_options.type_model === 2">
                                  <span style="position: relative">
                                    <i
                                      class="iconfont icon-bingji hover-icon-color"
                                      style="
                                        color: rgb(48, 100, 143);
                                        font-size: 10px;
                                        cursor: pointer;
                                        vertical-align: middle;
                                        position: absolute;
                                        left: -36px;
                                        top: 1px;
                                      "
                                      @click.stop="
                                        nodeUp(
                                          item,
                                          user_item.data[column_index]
                                            .dataList[0],
                                          false,
                                          'top',
                                          user_item.data[column_index]
                                            .dataList[0],
                                          user_item.data[column_index].dataList
                                        )
                                      "
                                    ></i>
                                    <i
                                      class="iconfont icon-bingji1 hover-icon-color"
                                      style="
                                        color: rgb(48, 100, 143);
                                        font-size: 9px;
                                        cursor: pointer;
                                        vertical-align: middle;
                                        position: absolute;
                                        left: -36px;
                                        top: 14px;
                                      "
                                      @click.stop="
                                        nodeUp(
                                          item,
                                          null,
                                          false,
                                          'bottom',
                                          user_item.data[column_index].dataList[
                                            user_item.data[column_index]
                                              .dataList.length - 1
                                          ],
                                          user_item.data[column_index].dataList
                                        )
                                      "
                                    ></i>
                                  </span>
                                  <span style="position: relative">
                                    <i
                                      class="iconfont icon-a-lujing5 hover-icon-color"
                                      style="
                                        color: rgb(48, 100, 143);
                                        cursor: pointer;
                                        font-size: 6px;
                                        margin-left: 10px;
                                        vertical-align: middle;
                                        position: absolute;
                                        left: -20px;
                                        top: 2px;
                                        height: 16px;
                                      "
                                      @click.stop="
                                        nodeUp(
                                          item,
                                          user_item.data[column_index].dataList[
                                            index - 1
                                          ],
                                          true,
                                          'up',
                                          user_item.data[column_index]
                                            .dataList[0],
                                          user_item.data[column_index].dataList
                                        )
                                      "
                                    ></i>
                                    <i
                                      class="iconfont icon-a-lujing6 hover-icon-color"
                                      style="
                                        color: rgb(48, 100, 143);
                                        cursor: pointer;
                                        font-size: 6px;
                                        margin-left: 10px;
                                        vertical-align: middle;
                                        position: absolute;
                                        left: -20px;
                                        top: 17px;
                                        height: 16px;
                                      "
                                      @click.stop="
                                        nodeUp(
                                          item,
                                          user_item.data[column_index].dataList[
                                            index + 2
                                          ],
                                          false,
                                          'down',
                                          user_item.data[column_index].dataList[
                                            user_item.data[column_index]
                                              .dataList.length - 1
                                          ],
                                          user_item.data[column_index].dataList
                                        )
                                      "
                                    ></i>
                                  </span>
                                </span>
                                <i
                                  v-limits-of-authority="'FILE_READ'"
                                  class="iconfont icon-node_link hover-icon"
                                  style="
                                    cursor: pointer;
                                    vertical-align: middle;
                                    margin: auto 16px;
                                    stroke: none;
                                    font-size: 24px;
                                    color: rgb(48, 100, 143);
                                  "
                                  @click.stop="nodeLink(item)"
                                ></i>
                                <div
                                  style="
                                    display: inline-block;
                                    min-width: 30px;
                                    vertical-align: middle;
                                  "
                                >
                                  <span> {{ item.storyPoint }}</span>
                                </div>
                              </span>
                            </p>
                            <div>
                              <el-tooltip
                                effect="dark"
                                placement="top"
                                :disabled="is_show_topic"
                              >
                                <div
                                  slot="content"
                                  v-html="addbr(item.topic)"
                                ></div>
                                <div>
                                  <div
                                    class="tooltip_span"
                                    @mouseenter="isShowTooltip($event)"
                                  >
                                    <span>{{ item.topic }}</span>
                                  </div>
                                </div>
                              </el-tooltip>
                            </div>
                          </div>
                        </div>
                      </transition-group>
                      <span
                        v-if="
                          user_item.data[column_index].showNumber <
                            user_item.data[column_index].dataList.length &&
                          project_type !== 'SCRUM' &&
                          !is_drag &&
                          blocks_options[column_index] <
                            scrum_column_list[column_index].number
                        "
                        @click="add_show_number(user_index, column_index)"
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </span>
                    </div>
                    <div
                      v-if="
                        is_drag &&
                        user_index === start_userindex &&
                        column_index !== drag_item.column_index &&
                        can_edit_status_check(column_item)
                      "
                      class="status_list"
                      :style="{ 'padding-top': target_offsetTop + 'px' }"
                    >
                      <div
                        v-for="(
                          status, status_index
                        ) in column_item.statusDtoList"
                        :key="status_index"
                      >
                        <div
                          v-if="can_edit_status.indexOf(status.statusId) !== -1"
                          @dragover="
                            dragover_status(status_index, column_index)
                          "
                          @dragleave="dragover_leave"
                        >
                          {{ status.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </collapse>
            </div>
          </div>
        </div>

        <div v-else>
          <div
            @mouseenter="summaryOnmouserEnter"
            @mouseleave="summaryOnmouserLeave"
          >
            <el-collapse v-show="is_show_chart" v-model="activeNames">
              <el-collapse-item name="2">
                <div slot="title" class="table1-title collapse-title">
                  <span style="margin-right: 20px; font-weight: 700">{{
                    $t("quick.SprintDataSummary")
                  }}</span>
                </div>
                <div class="table1">
                  <el-table
                    :data="allSprintCurrentData"
                    style="width: 100%"
                    :header-cell-style="{
                      color: '#383838',
                      'font-weight': '700',
                    }"
                    height="280"
                    ref="allSprintTable"
                    @selection-change="handleSelectionChange"
                    @select-all="handleSelectionChange"
                  >
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column
                      prop="Sprint"
                      label="Sprint"
                      width="200"
                      show-overflow-tooltip
                    >
                      <template slot-scope="scope">
                        {{ sprint[scope.row.sprintId].name }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="status"
                      :label="$t('file.table.status')"
                      width="120"
                    >
                      <template slot-scope="scope">
                        <el-tooltip
                          effect="dark"
                          placement="top"
                          :disabled="!isStatusShowTooltip"
                        >
                          <div slot="content">
                            {{ map_arr[sprint[scope.row.sprintId].status] }}
                          </div>
                          <span
                            :class="{
                              status_s: true,
                              status_todo_s:
                                sprint[scope.row.sprintId].status ==
                                'NOT_STARTED',
                              status_progress_s:
                                sprint[scope.row.sprintId].status ==
                                'IN_PROGRESS',
                              status_open_s:
                                sprint[scope.row.sprintId].status == 'ENDED',
                            }"
                            @mouseenter="visibilityChange($event)"
                          >
                            <span style="white-space: nowrap">{{
                              map_arr[sprint[scope.row.sprintId].status]
                            }}</span>
                          </span>
                        </el-tooltip>
                      </template>
                    </el-table-column>
                    <el-table-column prop="" :label="$t('quick.nodeStatus')">
                      <template slot-scope="scope">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          placement="top"
                          :disabled="!scope.row.sprintStatusDistributionList"
                          popper-class="table1-bar-popper"
                        >
                          <div slot="content">
                            <div
                              v-for="(item, index) in scope.row
                                .sprintStatusDistributionList"
                              :key="index"
                              class="table1-bar-line"
                            >
                              <span
                                class="table1-bar-tip-icon"
                                :class="{
                                  status_todo:
                                    status[item.status].statusCategory ==
                                    'TODO',
                                  status_progress:
                                    status[item.status].statusCategory ==
                                    'IN_PROGRESS',
                                  status_open:
                                    status[item.status].statusCategory ==
                                    'DONE',
                                }"
                              ></span
                              >{{ status[item.status].name }}: {{ item.count }}
                            </div>
                            <div>
                              {{ $t("quick.Total") }}:
                              {{
                                getCount(scope.row.sprintStatusDistributionList)
                              }}
                            </div>
                          </div>
                          <div
                            class="table1-bar"
                            :style="{
                              width: getWrapWidth(
                                scope.row.sprintStatusDistributionList
                              ),
                            }"
                          >
                            <span
                              v-for="(item, index) in scope.row
                                .sprintStatusDistributionList"
                              :key="item.status"
                              :style="{
                                width: getWidth(
                                  item,
                                  scope.row.sprintStatusDistributionList
                                ),
                              }"
                              :class="{
                                radius_border_left: index === 0,
                                radius_border_right:
                                  index ===
                                  scope.row.sprintStatusDistributionList
                                    .length -
                                    1,
                                radius_border_both:
                                  index === 0 &&
                                  index ===
                                    scope.row.sprintStatusDistributionList
                                      .length -
                                      1,
                                status_todo:
                                  status[item.status].statusCategory == 'TODO',
                                status_progress:
                                  status[item.status].statusCategory ==
                                  'IN_PROGRESS',
                                status_open:
                                  status[item.status].statusCategory == 'DONE',
                              }"
                            ></span>
                          </div>
                        </el-tooltip>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div
                    class="table1-footer"
                    style="margin-top: 6px; text-align: center"
                  >
                    <el-pagination
                      :current-page="table1pages.current"
                      background
                      :page-size="10"
                      layout="total, prev, pager, next, jumper"
                      :total="table1pages.total"
                      @current-change="table1pagesHandleCurrentChange"
                    >
                    </el-pagination>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div
            class="table-scroll-wrap"
            :style="{
              height:
                top_options.status == 'ENDED' && !is_show_chart
                  ? show_top
                    ? activeNames.indexOf('1') === -1
                      ? '66vh'
                      : '46vh'
                    : '84vh'
                  : activeNames.indexOf('2') !== -1
                  ? '36vh'
                  : '71vh',
            }"
          >
            <!-- 图表 -->
            <div ref="echarts_main_wrap" class="echarts_main_wrap">
              <div v-show="table1Check.length === 1 || !this.is_show_chart">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: auto;
                    padding-right: 30px;
                  "
                >
                  <h1>{{ $t("board.chart.burn") }}</h1>
                  <span class="blue_button" @click="filter_weekend(1)">
                    {{
                      has_weekend_line1
                        ? $t("quick.FilterWeekends")
                        : $t("quick.CancelFilter")
                    }}
                  </span>
                </div>
                <p>{{ $t("board.chart.story") }}</p>
                <div ref="line1"></div>
              </div>
              <div v-show="table1Check.length === 1 || !this.is_show_chart">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: auto;
                    padding-right: 30px;
                  "
                >
                  <h1>{{ $t("board.chart.burn2") }}</h1>
                  <span class="blue_button" @click="filter_weekend(2)">
                    {{
                      has_weekend_line2
                        ? $t("quick.FilterWeekends")
                        : $t("quick.CancelFilter")
                    }}
                  </span>
                </div>
                <p>{{ $t("board.chart.story2") }}</p>
                <div ref="line2"></div>
              </div>
              <div class="bar1">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: auto;
                    padding-right: 30px;
                  "
                >
                  <span style="display: flex; align-items: center">
                    <h1>{{ $t("board.chart.story2") }}</h1>
                    <span v-if="countBar1" style="margin-left: 10px"
                      >（{{ $t("quick.Total") }}：{{ countBar1 }} SP）</span
                    >
                  </span>
                  <div class="caret-wrapper">
                    <i
                      :class="{
                        'el-icon-caret-top': true,
                        'el-icon-caret-colored': bar1SortStatus === 'asc',
                      }"
                      @click="changeSortStatus('asc', 'bar1SortStatus')"
                    ></i>
                    <i
                      :class="{
                        'el-icon-caret-bottom': true,
                        'el-icon-caret-colored': bar1SortStatus === 'desc',
                      }"
                      @click="changeSortStatus('desc', 'bar1SortStatus')"
                    ></i>
                  </div>
                </div>
                <p style="display: inline-block; margin-right: 20px">
                  {{ $t("board.chart.story3") }}
                </p>
                <div ref="bar1"></div>
              </div>
              <div class="bar1">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: auto;
                    padding-right: 30px;
                  "
                >
                  <span style="display: flex; align-items: center">
                    <h1>{{ $t("board.chart.assignee") }}</h1>
                    <span v-if="countBar2" style="margin-right: 10px"
                      >（{{ $t("quick.Total") }}：{{ countBar2 }}）</span
                    >
                  </span>
                  <div class="caret-wrapper">
                    <i
                      :class="{
                        'el-icon-caret-top': true,
                        'el-icon-caret-colored': bar2SortStatus === 'asc',
                      }"
                      @click="changeSortStatus('asc', 'bar2SortStatus')"
                    ></i>
                    <i
                      :class="{
                        'el-icon-caret-bottom': true,
                        'el-icon-caret-colored': bar2SortStatus === 'desc',
                      }"
                      @click="changeSortStatus('desc', 'bar2SortStatus')"
                    ></i>
                  </div>
                </div>
                <p style="display: inline-block; margin-right: 20px">
                  {{ $t("board.chart.num") }}
                </p>
                <div ref="bar2"></div>
              </div>
            </div>
            <!-- 已完成任务 -->
            <collapse style="margin-top: 20px" class="csllapse_task">
              <span slot="title" style="color: rgba(128, 128, 128, 1)">{{
                $t("board.chart.task")
              }}</span>
              <el-table
                style="border-radius: 10px"
                :data="complete_table_data"
                height="60vh"
                :header-cell-style="{
                  color: '#383838',
                  'font-weight': '700',
                }"
                :cell-style="{
                  color: '#636363',
                  'font-weight': '700',
                }"
                @sort-change="onSortChange"
              >
                <el-table-column
                  prop="fileTypeId"
                  :label="$t('board.chart.type')"
                  cursor="pointer"
                  :width="120"
                >
                </el-table-column>
                <el-table-column
                  prop="key"
                  sortable
                  label="key"
                  cursor="pointer"
                  :width="200"
                >
                  <template slot-scope="scope">
                    <div>
                      <div style="display: inline-block">
                        <span
                          v-limits-of-authority="'NODE_DETAIL_READ'"
                          style="cursor: pointer; color: rgb(255, 195, 0)"
                          @click="jump_node(scope.row)"
                          >{{ scope.row.key }}</span
                        >
                      </div>
                      <div style="display: inline-block">
                        <div
                          v-limits-of-authority="'FILE_READ'"
                          style="display: inline-block"
                        >
                          <i
                            style="
                              font-size: 12px;
                              margin-left: 16px;
                              cursor: pointer;
                              color: rgb(48, 100, 143);
                            "
                            class="iconfont icon-node_link"
                            @click="nodeLink(scope.row)"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="topic"
                  :label="$t('board.chart.name')"
                  show-overflow-tooltip
                  cursor="pointer"
                >
                  <template slot-scope="scope">
                    <span
                      style="cursor: pointer"
                      @click="show_drawer(scope.row)"
                      >{{ scope.row.topic }}</span
                    ></template
                  >
                </el-table-column>
                <el-table-column
                  prop="assignee"
                  sortable
                  :label="$t('board.chart.ass')"
                  cursor="pointer"
                  :width="160"
                >
                </el-table-column>
                <el-table-column
                  prop="status"
                  sortable
                  :label="$t('board.chart.status')"
                  cursor="pointer"
                  :width="160"
                >
                  <template slot-scope="scope">
                    <status-tag
                      v-model="scope.row.status"
                      :style_catogry="'plain'"
                      :status_type="scope.row.statusCategory"
                      :size="'small'"
                    >
                    </status-tag>
                  </template>
                </el-table-column>
              </el-table>
            </collapse>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('scrum.newSprint.title')"
      :visible.sync="add_sprint_dialog"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
    >
      <el-form
        ref="form"
        :model="add_sprint_item"
        :rules="add_sprint_rules"
        label-width="100px"
        label-position="left"
      >
        <el-form-item :label="$t('scrum.newSprint.name')" prop="name">
          <el-input v-model="add_sprint_item.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('scrum.newSprint.goal')">
          <el-input
            v-model="add_sprint_item.description"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="add_sprint_dialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="add_sprint_confirm">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('scrum.newSprint.set')"
      :visible.sync="start_sprint_dialog"
      :close-on-click-modal="false"
      :show-close="true"
      width="35%"
    >
      <el-form
        ref="start_form"
        :model="start_sprint_item"
        :rules="start_sprint_rules"
        label-width="130px"
        label-position="left"
      >
        <el-form-item :label="$t('scrum.newSprint.name2')" prop="name">
          <el-input v-model="start_sprint_item.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('scrum.newSprint.time')" prop="time">
          <el-date-picker
            v-model="start_sprint_item.time"
            type="datetimerange"
            range-separator="-"
            :start-placeholder="$t('scrum.newSprint.start')"
            :end-placeholder="$t('scrum.newSprint.end')"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 100%"
            @change="start_sprint_time_settings"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('scrum.newSprint.goal')">
          <el-input
            v-model="start_sprint_item.description"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="start_sprint_dialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="start_sprint_confirm">{{
          $t("btn.startBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('scrum.newSprint.com')"
      :visible.sync="complete_sprint_dialog"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
    >
      <el-form
        ref="complete_form"
        :model="complete_sprint_item"
        :rules="complete_sprint_rules"
        label-width="180px"
        label-position="left"
      >
        <el-form-item :label="$t('scrum.newSprint.move')" prop="name">
          <el-select
            v-model="complete_sprint_item.name"
            :placeholder="$t('placeholder.dropdown')"
            filterable
          >
            <el-option
              v-for="(item, index) in complete_sprint_item_select_list"
              :key="index"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="complete_sprint_dialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="complete_sprint_confirm">{{
          $t("btn.completeBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <NodeDrawer
      :drawer.sync="drawer"
      :current_node="current_node"
      :file_icon_name="file_icon_name"
      :is_show_name="is_show_name"
      :drawer_loading="drawer_loading"
      :file_icon="file_icon"
      :members-show="membersShow"
      :members="members"
      :show-test-plan="showTestPlan"
      @close="before_close_drawer"
      @loading-finished="drawer_loading = false"
      @update_description_content="update_description_content"
      @update_current_node="update_current_node"
    />
  </div>
</template>

<script>
import Collapse from "@/components/mindmap/node_detail/Collapse.vue";
import NodeStatusSelect from "@/components/mindmap/node_detail/NodeStatusSelect.vue";
import ViewWorkflow from "@/components/mindmap/ViewWorkflow";
import {
  get_sprints,
  get_sprints_info,
  add_sprints,
  scrumBoard_detail,
  change_sprint_status,
  get_sprint_nodes,
  add_sprint_nodes,
  edit_sprint,
  node_move,
  kanban_nodes, //获取看板下所有节点
  add_kanban_nodes,
  kanban_detail,
  resultStatistics,
  completeNodes,
  storyPointStatistics,
  verticalOrder,
  verticalOrderKANBAN,
  getBatchAnalyzeNodeStatus,
} from "@/network/quick/index.js";
import { get_status_list } from "@/network/workflow/index.js";
import {
  set_workflow,
  set_workflow_myself,
  get_workflow_list,
  get_node_use_node_key,
} from "@/network/node/index.js";
import { mapGetters } from "vuex";
import {
  get_nodes_by_fuzzykey_sprint,
  get_nodes_by_fuzzykey_KANBAN,
} from "@/network/node/index.js";
import { getFile, get_filetype } from "@/network/home/index.js";
import { inProgressBaselines } from "@/network/merge/index";
import { get_file } from "@/network/baseline/index.js";
import { get_filetype_List } from "@/network/fileType/index.js";
import NodeBaseInfoRow from "@/components/mindmap/node_detail/NodeBaseInfoRow.vue";
import NodeReleaseInfoRow from "@/components/mindmap/node_detail/NodeReleaseInfoRow.vue";
import NodeDrawer from "@/components/nodeDrawer";
import StatusTag from "@/components/statusTag";
import selectIcon from "@/components/selectIcon";
import { getSprintBurnChart } from "@/network/charts/index.js";
import { mapActions } from "vuex";
import personalAvatar from "@/components/personal";
import { limits_of_authority_disable } from "@/utils/limits";

export default {
  name: "Quick",
  components: {
    selectIcon,
    StatusTag,
    Collapse,
    personalAvatar,
    NodeBaseInfoRow,
    NodeStatusSelect,
    ViewWorkflow,
    NodeReleaseInfoRow,
    NodeDrawer,
  },
  data() {
    return {
      // 是否显示上部
      show_top: false,
      is_show_chart: false,
      has_weekend_line1: true,
      has_weekend_line2: true,
      xAxis: {},
      yAxis: {},
      grid: {},
      bar1Series: {},
      bar2Series: {},
      bar1SortStatus: "",
      bar2SortStatus: "",
      user_dtoList: [],
      complete_table_data: [],
      file_icon_name: "",
      file_icon: "",
      cur_sprint_id: "",
      current_node: {},
      loading: false,
      drawer: false,
      target_offsetTop: 0,
      // 选中的角色
      selected_user: {},
      // 可以改变成的状态
      can_edit_status: [],
      // 颜色数组
      color_list: [
        "#E4EBD2",
        "#D9E8D3",
        "#DCE6D9",
        "#DAE6E7",
        "#D0E8E8",
        "#D2E2F0",
        "#D5DCEF",
        "#DADFEA",
        "#E3DEE9",
        "#DED6EB",
        "#EBDBE0",
        "#EDD6E4",
        "#E8D6D7",
        "#EED5D6",
        "#EEECD3",
        "#EEE7D3",
        "#EBE1DA",
        "#EEE2D4",
        "#EEDBD4",
        "#E0DED2",
      ],
      target_column_index: 0,
      // 搜索节点时loading
      search_loading: false,
      // scrum列
      scrum_column_list: [],
      // 开始弹窗
      start_sprint_dialog: false,
      //新增弹窗
      add_sprint_dialog: false,
      // 完成时的弹窗
      complete_sprint_dialog: false,
      // 新增sprint的对象
      add_sprint_item: {
        name: "",
        description: "",
      },
      // 开始弹窗对象
      start_sprint_item: {},
      //完成时的弹窗
      complete_sprint_item: {},
      // 新增弹窗的规则
      add_sprint_rules: {
        name: [
          {
            required: true,
            message: this.$t("placeholder.name"),
            trigger: "blur",
          },
        ],
      },
      complete_sprint_rules: {
        name: [
          {
            required: true,
            message: this.$t("placeholder.dropdown"),
            trigger: "blur",
          },
        ],
      },
      start_sprint_rules: {
        name: [
          {
            required: true,
            message: this.$t("placeholder.name"),
            trigger: "blur",
          },
        ],
        time: [
          {
            required: true,
            message: this.$t("placeholder.time"),
            trigger: "blur",
          },
        ],
      },
      // 上部过滤选项的绑定值
      top_options: {
        select_model: "",
        name: "",
        status: "",
        type_model: 2,
        user_model: null,
        left_day: 10,
        description: "",
        startDateTime: "",
        endDateTime: "",
      },
      // 多选添加节点
      multiple_node: "",
      multiple_node_options: [],
      // 上部下拉选项的options
      scrum_options: [],
      // 节点分类
      table_data: {
        user: [],
        file: [],
      },
      // 过滤周末
      chartFilterWeekend: [],
      // 全部数据
      chartAll: [],
      //标记是否开始拖拽
      is_drag: false,
      //开始拖拽时记录拖拽元素的index
      drag_item: {},
      user_range: 10,
      node_name_span: true,
      input_name: "",
      workflow_dialog_visible: false,
      drawer_loading: false,
      allfiletype: [],
      allstatus: [],
      is_show_name: true,
      is_show_topic: true,
      countBar1: "",
      countBar2: "",
      // 获取顶部宽度用来判断 渲染的头像个数
      top_options_width: 0,
      // 使用的头像数
      user_range1: 0,
      activeNames: ["1"],
      start_userindex: "",
      // 汇总表格
      table1Check: [],
      table1pages: {
        current: 1,
        total: 0,
      },
      allSprintCurrentData: [],
      currentMaxNum: 0,
      isStatusShowTooltip: false,
      lockSelect: false,
      map_arr: {
        IN_PROGRESS: this.$t("board.status.inProgress"),
        NOT_STARTED: this.$t("board.status.notStarted"),
        ENDED: this.$t("board.status.completed"),
      },
    };
  },
  watch: {
    build_scrum_column_list(oldv, newv) {
      if (this.top_node_arr) {
        let target;
        if (this.top_options.type_model === 1) {
          target = document.querySelector(`#id${this.top_node_arr.fileId}`);
        } else {
          target = document.getElementById(`${this.top_node_arr.nodeUuid}`);
        }
        this.$nextTick(() => {
          let high_light = document.getElementById(
            `${this.top_node_arr.nodeUuid}`
          );
          if (high_light) {
            high_light.classList.remove("high_light");
            setTimeout(() => {
              high_light.classList.add("high_light");
            });
          }
          if (target) {
            this.$nextTick(() => {
              let page = document.querySelector(".user_block_wrap"); // ***指你要滚动的锚点的页面父元素
              let height =
                target.parentNode.parentNode.parentNode.parentNode.parentNode
                  .parentNode.parentNode.offsetTop; // 计算需要向上移动的距离
              page.scroll({
                top: height,
                behavior: "smooth", // 平滑移动
              });
            });
          }
        });
      }
      this.loading = false;
    },
    // cur_sprint_id() {
    //   // 记录sprint
    //   sessionStorage.setItem("cur_sprint_id", this.cur_sprint_id);
    // },
    cur_sprint_id() {
      sessionStorage.setItem("cur_sprint_id", this.cur_sprint_id);
      this.$router.push({
        name: "board",
        params: { sprintId: this.cur_sprint_id },
      });
    },
  },
  computed: {
    ...mapGetters([
      "project_type",
      "current_spirnt",
      "userAccountId",
      "fileType",
      "sprint",
      "project_user_list",
      "status",
    ]),
    complete_sprint_item_select_list() {
      const arr = [];
      this.scrum_options.forEach((item) => {
        if (
          item.status !== "ENDED" &&
          item.sprintId !== this.top_options.select_model
        ) {
          const obj = {
            label: item.build_name,
            value: item.sprintId,
          };
          arr.push(obj);
        }
      });
      return arr;
    },
    can_edit_status_check() {
      return function (column_item) {
        let flag = false;
        column_item.statusDtoList.forEach((item) => {
          if (this.can_edit_status.indexOf(item.statusId) !== -1) {
            flag = true;
          }
        });
        return flag;
      };
    },
    three_line() {
      return function (description) {
        let str = "";
        str =
          description.length >= 38
            ? description.substring(0, 38) + "<span>...</span>"
            : description;
        return str;
      };
    },
    scrum_column_list_sort() {
      const arr = this.scrum_column_list.map((item) => {
        return item.columnId;
      });
      return arr;
    },
    build_scrum_column_list: {
      get() {
        let arr = [];
        this.active_data.forEach((part) => {
          part.data = part.data.sort((a, b) => {
            return (
              this.scrum_column_list_sort.indexOf(a.columnId) -
              this.scrum_column_list_sort.indexOf(b.columnId)
            );
          });
        });
        if (
          this.top_options.type_model === 2 &&
          (this.selected_user.accountId ||
            this.selected_user.nickname ===
              this.$t("board.status.unAssigned")) &&
          this.top_options.user_model !== null
        ) {
          this.active_data.forEach((item) => {
            if (item.name === this.selected_user.nickname) {
              item.isShow = true;
            } else {
              item.isShow = false;
            }
            arr.push(item);
          });
        } else {
          this.active_data.forEach((item) => {
            item.isShow = true;
            arr.push(item);
          });
        }
        return arr;
      },
      set(newval) {},
    },
    active_data() {
      if (this.top_options.type_model === 1) {
        return this.table_data.file;
      } else {
        return this.table_data.user;
      }
    },
    blocks_options() {
      const arr = [];
      this.build_scrum_column_list.forEach((part) => {
        if (part.isShow) {
          part.data.forEach((column, column_index) => {
            if (!arr[column_index]) {
              arr[column_index] = 0;
            }
            arr[column_index] += column.dataList.length;
          });
        }
      });
      return arr;
    },
    story_point_options() {
      const arr = [];
      this.build_scrum_column_list.forEach((part) => {
        if (part.isShow) {
          part.data.forEach((column, column_index) => {
            if (!arr[column_index]) {
              arr[column_index] = 0;
            }
            column.dataList.forEach((data) => {
              arr[column_index] += data.storyPoint;
            });
          });
        }
      });
      return arr;
    },
    avatar_color() {
      const arr = [];
      this.user_dtoList.forEach((item) => {
        const r = Math.floor(Math.random() * 155);
        const g = Math.floor(Math.random() * 155);
        const b = Math.floor(Math.random() * 155);
        const rgb = `rgb(${r},${g},${b})`;
        arr.push(rgb);
      });
      return arr;
    },
    // 限制责任人数量
    limit_user_dtoList() {
      const arr = this.user_dtoList.length ? [...this.user_dtoList] : [];
      const unassigned = arr.pop();
      const newarr = arr.slice(
        this.user_range - this.user_range1,
        this.user_range
      );
      unassigned ? newarr.push(unassigned) : "";
      return newarr;
    },
    newDescription() {
      let arrstr = this.top_options.description || "";
      return arrstr.replace(/\n/g, "<br>");
    },
    showTestPlan() {
      if (this.current_node && this.current_node.fileTypeId && this.fileType) {
        return this.fileType[this.current_node.fileTypeId]
          ? this.fileType[this.current_node.fileTypeId].testCase
          : false;
      }
      return false;
    },
    members() {
      return Object.values(this.project_user_list);
    },
    membersShow() {
      return this.members.length ? true : false;
    },
  },
  created() {
    // const cur_sprint_id = sessionStorage.getItem("cur_sprint_id");
    // this.cur_sprint_id = cur_sprint_id;
    // this.$nextTick(() => {
    //   this.cur_sprint_id = this.scrum_options[0].sprintId;
    // });
  },
  mounted() {
    this.mounted_fn();
    this.$nextTick(() => {
      this.handleResize();
      window.addEventListener("resize", this.handleResize);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    limits_of_authority_disable,
    summaryOnmouserEnter() {
      this.timeOut = setTimeout(() => {
        if (this.activeNames.indexOf("2") == -1) {
          this.activeNames.push("2");
        }
      }, 500);
    },
    summaryOnmouserLeave() {
      clearTimeout(this.timeOut);
      if (this.activeNames.indexOf("2") !== -1) {
        this.activeNames.splice(this.activeNames.indexOf("2"), 1);
      }
    },
    visibilityChange(event) {
      const ev = event.target.children[0];
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight && this.use) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isStatusShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isStatusShowTooltip = false;
      }
    },
    getCount(all) {
      let total = 0;
      all &&
        all.forEach((single) => {
          total += single.count;
        });
      return total;
    },
    getWidth(item, all) {
      let total = 0;
      all &&
        all.forEach((single) => {
          total += single.count;
        });
      return (item.count / total) * 100 + "%";
    },
    getWrapWidth(all) {
      let total = 0;
      all &&
        all.forEach((single) => {
          total += single.count;
        });
      return (total / this.currentMaxNum) * 100 + "%";
    },
    handleSelectionChange(item) {
      if (this.lockSelect) return;
      const has = this.allSprintCurrentData.filter((single) => {
        let flag = false;
        item.forEach((checked) => {
          if (checked.sprintId === single.sprintId) {
            flag = true;
          }
        });
        if (flag) {
          return true;
        }
      });
      const nohas = this.allSprintCurrentData.filter((single) => {
        let flag = false;
        item.forEach((checked) => {
          if (checked.sprintId === single.sprintId) {
            flag = true;
          }
        });
        if (!flag) {
          return true;
        }
      });
      nohas.forEach((item) => {
        if (this.table1Check.indexOf(item.sprintId) !== -1) {
          this.table1Check.splice(this.table1Check.indexOf(item.sprintId), 1);
        }
      });
      has.forEach((item) => {
        if (this.table1Check.indexOf(item.sprintId) === -1) {
          this.table1Check.push(item.sprintId);
        }
      });
      this.init_charts();
    },
    table1pagesHandleCurrentChange(currnet) {
      this.initAllSprint(currnet);
    },
    // 节点上移
    nodeUp(clickNode, targetNode, flag, messageFlag, boundaryNode, columnData) {
      if (clickNode && boundaryNode && clickNode.key === boundaryNode.key) {
        this.$message({
          type: "warning",
          message: this.$t("node.message.operationSuccess5"),
        });
        return;
      }
      if (targetNode && clickNode && targetNode.key === clickNode.key) {
        return;
      }
      if (flag && !targetNode) {
        return;
      }
      const params = {
        projectId: this.get_pid(),
        sprintId: this.top_options.select_model,
        data: {
          backNodeKey: targetNode ? targetNode.key : "",
          nodeKey: clickNode.key,
        },
      };
      if (this.project_type === "SCRUM") {
        verticalOrder(params).then((res) => {
          this.$message({
            type: "success",
            message: this.getMoveMessage(messageFlag),
          });
          this.get_sprint_nodes();
        });
      } else {
        verticalOrderKANBAN(params).then((res) => {
          this.$message({
            type: "success",
            message: this.getMoveMessage(messageFlag),
          });
          this.get_kanban_nodes();
        });
      }
    },
    getMoveMessage(messageFlag) {
      const map = {
        top: this.$t("node.message.operationSuccess1"),
        bottom: this.$t("node.message.operationSuccess2"),
        up: this.$t("node.message.operationSuccess3"),
        down: this.$t("node.message.operationSuccess4"),
      };
      return map[messageFlag];
    },
    // 计算顶部栏的宽度，用来展示头像的个数
    handleResize() {
      const width = this.$refs.top_options.offsetWidth;
      this.top_options_width = width;
      let imgWidth = this.top_options_width - 250 - 435 - 90;
      let num = Math.floor(imgWidth / 40);
      if (num > 1 && num <= 10) {
        this.user_range = num - 1;
      } else {
        this.user_range = 10;
      }
      this.user_range1 = this.user_range;
    },
    ...mapActions(["setSprint"]),
    //判断三行出...
    isShowTooltip(e, refname) {
      const clientHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;
      const pWidth = refname
        ? this.$refs[refname].parentNode.offsetWidth
        : e.target.children[0].offsetWidth;
      const cWidth = refname
        ? this.$refs[refname].parentNode.offsetWidth
        : e.target.children[0].offsetWidth;
      // 文字超出隐藏并弹出tooltip提示，文字未超出则不弹出tooltip提示的判断条件
      if (scrollHeight > clientHeight && pWidth >= cWidth) {
        this.is_show_name = false;
        this.is_show_topic = false;
      } else {
        this.is_show_name = true;
        this.is_show_topic = true;
      }
    },
    addbr(topic) {
      // 设置变量存储返回值
      let newstr = "";
      // 如果长度超过20，就要截取插入字符
      if (topic.length > 20) {
        // 第一次截取
        newstr = topic.slice(0, 20) + "<br/>";
        // 闭包再次调用，如果截取后的字段长度依然超过20，再次调用，如果没有直接返回当前值
        return newstr + this.addbr(topic.slice(20));
      } else {
        // 直接返回当前值
        return topic;
      }
    },
    blur_all(event) {
      if (event.target.nodeName === "DIV") {
        this.$refs["base-info"].item_blur();
        this.$refs["release-info"].item_blur();
        this.$refs["node_status_select"].showWorkflow = false;
      }
    },
    // 隐藏上部
    hide_top() {
      this.show_top = !this.show_top;
    },
    filter_weekend(line) {
      if (line === 1) {
        this.has_weekend_line1 = !this.has_weekend_line1;
        if (!this.has_weekend_line1) {
          this.init_line1(
            this.xAxis,
            this.yAxis,
            this.grid,
            this.chartFilterWeekend
          );
        } else {
          this.init_line1(this.xAxis, this.yAxis, this.grid, this.chartAll);
        }
      } else if (line === 2) {
        this.has_weekend_line2 = !this.has_weekend_line2;
        if (!this.has_weekend_line2) {
          this.init_line2(
            this.xAxis,
            this.yAxis,
            this.grid,
            this.chartFilterWeekend
          );
        } else {
          this.init_line2(this.xAxis, this.yAxis, this.grid, this.chartAll);
        }
      }
    },
    sortSeries(series, mode) {
      if (mode === "asc") {
        //asc;
        for (let i = 0; i < series.dataList.length - 1; i++) {
          for (let j = 0; j < series.dataList.length - 1; j++) {
            if (series.dataList[j] > series.dataList[j + 1]) {
              let tempData = series.dataList[j];
              series.dataList[j] = series.dataList[j + 1];
              series.dataList[j + 1] = tempData;
              tempData = series.assigneeList[j];
              series.assigneeList[j] = series.assigneeList[j + 1];
              series.assigneeList[j + 1] = tempData;
            }
          }
        }
      } else {
        for (let i = 0; i < series.dataList.length - 1; i++) {
          for (let j = 0; j < series.dataList.length - 1; j++) {
            if (series.dataList[j] < series.dataList[j + 1]) {
              let tempData = series.dataList[j];
              series.dataList[j] = series.dataList[j + 1];
              series.dataList[j + 1] = tempData;
              tempData = series.assigneeList[j];
              series.assigneeList[j] = series.assigneeList[j + 1];
              series.assigneeList[j + 1] = tempData;
            }
          }
        }
      }
    },
    init_bar1(xAxis, yAxis, grid, series, res) {
      const chart = this.$refs.bar1;
      this.bar1 = this.$echarts.init(chart);
      this.bar1SortStatus = "desc";
      this.sortSeries(this.bar1Series, "desc");
      const new_xAxis = Object.assign(
        {},
        {},
        {
          data: res.assigneeList.map((item) => {
            return this.matchUserNickname(
              item,
              this.$t("board.status.unAssigned")
            );
          }),
          axisLine: {
            lineStyle: {
              color: "#EBEEF5",
            },
          },
          nameTextStyle: {
            color: "rgba(128, 128, 128, 1)",
          },
          splitLine: {
            lineStyle: {
              color: "#EBEEF5",
            },
          },
          axisTick: {
            lineStyle: {
              color: "#CCCCCC",
            },
          },
          axisLabel: {
            interval: 0,
            color: "rgba(128, 128, 128, 1)",
            formatter: (value) => {
              if (value.includes(".")) {
                return value.split(".").join("\n");
              } else {
                return value;
              }
            },
          },
          name: this.$t("board.chart.ass"),
          boundaryGap: ["20%", "10%"],
        }
      );
      const new_series = [
        Object.assign({}, series[0], {
          type: "bar",
          barWidth: 20,
          itemStyle: {
            color: "rgba(222, 238, 250, 1)",
          },
          data: this.bar1Series.dataList,
        }),
      ];
      const option = {
        xAxis: new_xAxis,
        yAxis: { type: "value" },
        series: new_series,
        tooltip: {},
        grid: {
          top: "10%",
          right: "15%",
          bottom: "20%",
        },
        dataZoom: [
          {
            show: true,
            height: 5,
            xAxisIndex: [0],
            bottom: 5,
            showDetail: false,
            showDataShadow: false,
            borderColor: "transparent",
            textStyle: {
              fontSize: 0,
            },
            endValue: 6, //从0开始的相当于5个
            backgroundColor: "rgba(0,0,0,0)",
            borderWidth: 0,
            handleSize: "0%",
          },
        ],
      };
      this.bar1.setOption(option);
    },
    changeSortStatus(status, entity) {
      this[entity] = status;
      if (entity === "bar1SortStatus") {
        this.sortSeries(this.bar1Series, status);
        const new_xAxis = Object.assign({}, this.xAxis, {
          data: this.bar1Series.assigneeList.map((item) => {
            return this.matchUserNickname(
              item,
              this.$t("board.status.unAssigned")
            );
          }),
          axisLine: {
            lineStyle: {
              color: "#EBEEF5",
            },
          },
          nameTextStyle: {
            color: "rgba(128, 128, 128, 1)",
          },
          splitLine: {
            lineStyle: {
              color: "#EBEEF5",
            },
          },
          axisTick: {
            lineStyle: {
              color: "#CCCCCC",
            },
          },
          axisLabel: {
            color: "rgba(128, 128, 128, 1)",
            interval: 0,
            formatter: (value) => {
              if (value.includes(".")) {
                return value.split(".").join("\n");
              } else {
                return value;
              }
            },
          },
          name: this.$t("board.chart.ass"),
          boundaryGap: ["20%", "10%"],
        });
        const new_series = [
          Object.assign(
            {},
            {},
            {
              type: "bar",
              barWidth: 20,
              itemStyle: {
                color: "rgba(222, 238, 250, 1)",
              },
              data: this.bar1Series.dataList,
            }
          ),
        ];
        const option = {
          xAxis: new_xAxis,
          yAxis: this.yAxis,
          tooltip: {},
          series: new_series,
          dataZoom: [
            {
              show: true,
              height: 5,
              xAxisIndex: [0],
              bottom: 20,
              showDetail: false,
              showDataShadow: false,
              borderColor: "transparent",
              textStyle: {
                fontSize: 0,
              },
              endValue: 6, //从0开始的相当于5个
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              handleSize: "0%",
            },
          ],
        };
        this.bar1.setOption({ ...option }, true);
      } else {
        //bar2
        this.sortSeries(this.bar2Series, status);
        const new_xAxis = Object.assign(
          {},
          {},
          {
            data: this.bar2Series.assigneeList.map((item) => {
              return this.matchUserNickname(
                item,
                this.$t("board.status.unAssigned")
              );
            }),
            axisLine: {
              lineStyle: {
                color: "#EBEEF5",
              },
            },
            nameTextStyle: {
              color: "rgba(128, 128, 128, 1)",
            },
            splitLine: {
              lineStyle: {
                color: "#EBEEF5",
              },
            },
            axisTick: {
              lineStyle: {
                color: "#CCCCCC",
              },
            },
            axisLabel: {
              color: "rgba(128, 128, 128, 1)",
              interval: 0,
              formatter: (value) => {
                if (value.includes(".")) {
                  return value.split(".").join("\n");
                } else {
                  return value;
                }
              },
            },
            name: this.$t("board.chart.ass"),
            boundaryGap: ["20%", "10%"],
          }
        );
        const new_series = [
          Object.assign(
            {},
            {},
            {
              type: "bar",
              barWidth: 20,
              itemStyle: {
                color: "rgba(222, 238, 250, 1)",
              },
              data: this.bar2Series.dataList,
            }
          ),
        ];
        const option = {
          xAxis: new_xAxis,
          yAxis: this.yAxis,
          tooltip: {},
          series: new_series,
          dataZoom: [
            {
              show: true,
              height: 5,
              xAxisIndex: [0],
              bottom: 20,
              showDetail: false,
              showDataShadow: false,
              borderColor: "transparent",
              textStyle: {
                fontSize: 0,
              },
              endValue: 6, //从0开始的相当于5个
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              handleSize: "0%",
            },
          ],
        };
        this.bar2.setOption({ ...option }, true);
      }
    },
    compare(propertyName, sort) {
      return function (obj1, obj2) {
        let value1 = obj1[propertyName];
        let value2 = obj2[propertyName];
        if (typeof value1 === "string" && typeof value2 === "string") {
          const res = value1.localeCompare(value2, "zh");
          return sort === "ascending" ? -res : res;
        } else {
          if (value1 <= value2) {
            return sort === "ascending" ? -1 : 1;
          } else if (value1 > value2) {
            return sort === "ascending" ? 1 : -1;
          }
        }
      };
    },
    // 已完成表格排序方法
    onSortChange({ prop, order }) {
      this.complete_table_data.sort(this.compare(prop, order));
    },
    headCallBack() {
      this.drawer_loading = false;
    },
    show_workflow() {
      this.workflow_dialog_visible = true;
    },
    /*双击节点名进行编辑 */
    edit_nodename(name) {
      this.node_name_span = false;
      this.input_name = name;
      this.$nextTick(() => {
        this.$refs["input_panel"].focus();
      });
    },
    mounted_fn() {
      // 按类型获取sprints列表
      this.loading = true;
      if (this.project_type === "SCRUM") {
        this.get_sprints(true);
      } else {
        this.get_kanban_column();
      }
    },
    before_close_drawer() {
      this.mounted_fn();
      this.drawer = false;
    },
    update_description_content(description) {
      this.current_node.description = description;
    },
    update_current_node(e) {
      this.current_node = e;
    },
    show_drawer(node) {
      const jobAuthorities = this.$store.getters.jobAuthorities;
      if (jobAuthorities.indexOf("NODE_DETAIL_READ") === -1) {
        this.$message({
          message: this.$t("board.message.error"),
          type: "warning",
        });
        return;
      }
      this.drawer_loading = true;

      this.$nextTick(() => {
        get_node_use_node_key(this.get_pid(), node.key).then((res) => {
          this.current_node = res;
          this.drawer = true;
        });
      });
    },
    get_file_icon(id) {
      return this.fileType[id] ? this.fileType[id].icon : "";
    },
    get_avatar(id) {
      let avatar = "";
      this.user_dtoList.forEach((user) => {
        if (user.accountId === id) {
          avatar = user.avatar;
        }
      });
      return avatar;
    },
    get_assigneeName(id) {
      let nickname = "";
      this.user_dtoList.forEach((user) => {
        if (user.accountId === id) {
          nickname = user.nickname;
        }
      });
      return nickname;
    },
    add_show_number(user_index, column_index) {
      this.build_scrum_column_list[user_index].data[
        column_index
      ].showNumber += 20;
    },
    // 获取看板列
    get_kanban_column() {
      const params = {
        projectId: this.get_pid(),
      };
      kanban_detail(params).then((res) => {
        if (res) {
          this.scrum_column_list = [];
          res.boardColumnList.forEach((column, index) => {
            const obj = {
              name: column.name,
              columnId: column.columnId,
              statusDtoList: column.statusDtoList,
              color: `#000`,
              number: column.number,
              backgroundColor: this.color_list[index],
            };
            this.scrum_column_list.push(obj);
          });
          this.get_kanban_nodes();
        }
      });
    },
    // 获取看板所有节点
    get_kanban_nodes() {
      const params = {
        projectId: this.get_pid(),
      };
      kanban_nodes(params).then((res) => {
        const self = this;
        this.table_data.user = [];
        this.table_data.file = [];
        this.user_dtoList = res.userDtoList.sort(function (a, b) {
          if (a.accountId === self.userAccountId) {
            return 1;
          }
        });
        this.user_dtoList.push({
          accountId: "",
          avatar: "",
          createdAt: "",
          createdBy: "",
          email: "",
          nickname: this.$t("board.status.unAssigned"),
          project: null,
        });
        if (this.top_node_arr) {
          this.scrum_column_list.forEach((statusDtoList) => {
            statusDtoList.statusDtoList.forEach((status) => {
              if (status.statusId === this.top_node_arr.status) {
                this.top_node_arr.columnId = statusDtoList.columnId;
              }
            });
          });
          res.columnNodesDtoList.forEach((column) => {
            if (column.columnId === this.top_node_arr.columnId) {
              column.dataList = column.dataList.filter((item) => {
                return item.key !== this.top_node_arr.key;
              });
              column.dataList.unshift(this.top_node_arr);
            }
          });
        }
        this.user_dtoList.forEach((user) => {
          const user_obj = {
            name: "",
            id: user.accountId,
            data: [],
          };
          user_obj.name = user.nickname;
          res.columnNodesDtoList.forEach((column) => {
            const column_obj = {
              columnId: column.columnId,
              showNumber: 20,
              dataList: [],
            };
            column.dataList.forEach((node) => {
              if (node.assignee == user.accountId) {
                column_obj.dataList.push(node);
              }
              if (
                user.nickname === this.$t("board.status.unAssigned") &&
                !node.assignee
              ) {
                column_obj.dataList.push(node);
              }
            });
            user_obj.data.push(column_obj);
          });
          this.table_data.user.push(user_obj);
        });
        res.fileList.forEach((file) => {
          const file_obj = {
            name: "",
            id: file.id,
            data: [],
          };
          file_obj.name = file.name;
          res.columnNodesDtoList.forEach((column) => {
            const column_obj = {
              columnId: column.columnId,
              showNumber: 20,
              dataList: [],
            };
            column.dataList.forEach((node) => {
              if (node.fileId === file.id) {
                column_obj.dataList.push(node);
              }
            });
            file_obj.data.push(column_obj);
          });
          this.table_data.file.push(file_obj);
        });
      });
    },
    // 滚动事件
    handle_scroll() {
      this.$refs.block_wrap.scrollLeft = this.$refs.user_block_wrap.scrollLeft;
    },
    // 搜索节点
    get_nodes_options(query) {
      this.search_loading = true;
      if (this.project_type === "SCRUM") {
        get_nodes_by_fuzzykey_sprint(this.get_pid(), query).then((res) => {
          this.search_loading = false;
          this.multiple_node_options = res;
        });
      } else {
        get_nodes_by_fuzzykey_KANBAN(this.get_pid(), query).then((res) => {
          this.search_loading = false;
          this.multiple_node_options = res;
        });
      }
    },
    // 获取指定sprint下面的所有节点
    get_sprint_nodes() {
      const params = {
        projectId: this.get_pid(),
        sprintId: this.top_options.select_model,
      };
      get_sprint_nodes(params).then((res) => {
        const self = this;
        this.table_data.user = [];
        this.table_data.file = [];
        this.user_dtoList = res.userDtoList.sort(function (a, b) {
          if (a.accountId === self.userAccountId) {
            return -1;
          }
        });
        this.user_dtoList.push({
          accountId: "",
          avatar: "",
          createdAt: "",
          createdBy: "",
          email: "",
          nickname: this.$t("board.status.unAssigned"),
          project: null,
        });
        this.user_dtoList.forEach((user) => {
          const user_obj = {
            name: "",
            data: [],
          };
          user_obj.name = user.nickname;
          res.columnNodesDtoList.forEach((column) => {
            const column_obj = {
              columnId: column.columnId,
              showNumber: 5000,
              dataList: [],
            };
            column.dataList.forEach((node) => {
              if (node.assignee == user.accountId) {
                column_obj.dataList.push(node);
              }
              if (
                user.nickname === this.$t("board.status.unAssigned") &&
                !node.assignee
              ) {
                column_obj.dataList.push(node);
              }
            });
            user_obj.data.push(column_obj);
          });
          this.table_data.user.push(user_obj);
        });
        res.fileList.forEach((file) => {
          const file_obj = {
            name: "",
            data: [],
          };
          file_obj.name = file.name;
          res.columnNodesDtoList.forEach((column) => {
            const column_obj = {
              columnId: column.columnId,
              showNumber: 5000,
              dataList: [],
            };
            column.dataList.forEach((node) => {
              if (node.fileId === file.id) {
                column_obj.dataList.push(node);
              }
            });
            file_obj.data.push(column_obj);
          });
          this.table_data.file.push(file_obj);
        });
      });
    },
    // 获取面板详情
    scrumBoard_detail() {
      const params = {
        projectId: this.get_pid(),
      };
      scrumBoard_detail(params).then((res) => {
        if (res) {
          this.scrum_column_list = [];
          res.boardColumnList.forEach((column, index) => {
            const obj = {
              name: column.name,
              columnId: column.columnId,
              statusDtoList: column.statusDtoList,
              color: `#000`,
              number: 5000,
              backgroundColor: this.color_list[index],
            };
            this.scrum_column_list.push(obj);
          });
          this.get_sprint_nodes();
        }
      });
    },
    // 获取sprint设置信息
    get_sprints_info() {
      const params = {
        projectId: this.get_pid(),
        sprintId: this.top_options.select_model,
      };
      get_sprints_info(params).then((res) => {
        const map_arr = {
          IN_PROGRESS: this.$t("board.status.inProgress"),
          NOT_STARTED: this.$t("board.status.notStarted"),
          ENDED: this.$t("board.status.completed"),
        };
        this.top_options.description = res.description;
        this.top_options.name = res.name;
        this.top_options.left_day = res.remainDays;
        this.top_options.build_name =
          res.name + "(" + map_arr[res.status] + ")";
        this.$store.commit(
          "SET_CURRENT_SPIRNT",
          JSON.stringify(this.top_options)
        );
      });
    },
    // 按类型获取sprints列表
    get_sprints(flag) {
      this.scrum_options = [];
      const map_arr = {
        IN_PROGRESS: this.$t("board.status.inProgress"),
        NOT_STARTED: this.$t("board.status.notStarted"),
        ENDED: this.$t("board.status.completed"),
      };
      const params = {
        projectId: this.get_pid(),
      };
      get_sprints(params).then((res) => {
        const obj = {};
        const list = [
          ...(res.NOT_STARTED || []),
          ...(res.IN_PROGRESS || []),
          ...(res.ENDED || []),
        ];
        list.forEach((item) => {
          obj[item.sprintId] = item;
        });
        this.$store.commit("setSprint", obj);
        Object.keys(map_arr).forEach((status) => {
          if (res[status]) {
            res[status].forEach((sprint) => {
              sprint.build_name =
                sprint.name + "(" + map_arr[sprint.status] + ")";
              this.scrum_options.push(sprint);
              if (this.top_options.select_model === sprint.sprintId) {
                this.top_options.build_name = sprint.build_name;
                this.top_options.status = sprint.status;
                this.top_options.name = sprint.name;
                this.top_options.select_model = sprint.sprintId;
                this.top_options.startDateTime = sprint.startDateTime;
                this.top_options.endDateTime = sprint.endDateTime;
                this.$store.commit(
                  "SET_CURRENT_SPIRNT",
                  JSON.stringify(this.top_options)
                );
                if (
                  sprint.status === "ENDED" ||
                  (this.is_show_chart && sprint.status === "IN_PROGRESS")
                ) {
                  this.$nextTick(() => {
                    this.init_charts();
                  });
                } else {
                  this.is_show_chart = false;
                }
              }
            });
          }
        });
        if (this.scrum_options.length && flag) {
          let obj = {};
          if (
            this.$route.params.sprintId &&
            this.$route.params.sprintId != "enterOwn"
          ) {
            this.$store.commit(
              "SET_CURRENT_SPIRNT",
              JSON.stringify({
                select_model: this.$route.params.sprintId,
              })
            );
          }
          const current_spirnt = JSON.parse(this.current_spirnt);
          let has_current_spirnt = false;
          this.scrum_options.forEach((item) => {
            if (item.sprintId === current_spirnt.select_model) {
              has_current_spirnt = true;
            }
          });
          if (this.$route.params.data && this.$route.params.data.select_model) {
            obj = this.$route.params.data;
          } else if (current_spirnt.select_model && has_current_spirnt) {
            obj = current_spirnt;
          } else {
            obj = this.scrum_options[0];
          }
          const id = obj.select_model || obj.sprintId;
          const spritObj = this.sprint[id];
          this.top_options.name = spritObj.name;
          this.top_options.build_name = spritObj.build_name;
          this.top_options.select_model =
            spritObj.select_model || spritObj.sprintId;
          this.top_options.status = spritObj.status;
          this.top_options.startDateTime = spritObj.startDateTime;
          this.top_options.endDateTime = spritObj.endDateTime;
          if (
            this.top_options.status === "ENDED" ||
            (this.is_show_chart && this.top_options.status === "IN_PROGRESS")
          ) {
            this.$nextTick(() => {
              this.init_charts();
            });
          } else {
            this.is_show_chart = false;
          }
          this.get_sprints_info();
          // 获取面板详情
          this.scrumBoard_detail();
        }
        this.cur_sprint_id = this.top_options.select_model;
      });
    },
    // 跳转到setting
    jump_setting() {
      this.$router.push({
        name: "board_setting",
        params: {
          data: this.top_options,
        },
      });
    },
    // 确认添加sprit
    add_sprint_confirm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const params = {
            projectId: this.get_pid(),
            data: this.add_sprint_item,
          };
          add_sprints(params).then((res) => {
            this.setSprint(this.get_pid());
            this.get_sprints(false);
            this.add_sprint_dialog = false;
            // this.cur_sprint_id = top_options;

            this.$message({
              type: "success",
              message: this.$t("quick.AddedSuccessfully"),
              duration: "5000",
            });
          });
        } else {
        }
      });
    },
    //dropdown 事件
    type_click(index) {
      if (this.top_node_arr && this.top_node_arr.nodeUuid) {
        let high_light = document.getElementById(
          `${this.top_node_arr.nodeUuid}`
        );
        if (high_light) {
          high_light.classList.remove("high_light");
        }
      }
      this.top_node_arr = null;
      this.top_options.type_model = index;
    },
    change_userlist() {
      this.top_options.user_model = null;
      const length = this.user_dtoList.length - 1;
      if (this.user_range < length) {
        this.user_range = this.user_range + this.user_range1;
      } else {
        this.user_range = this.user_range1;
      }
    },
    user_click(user, index) {
      this.selected_user = user;
      if (this.top_options.user_model === index) {
        this.top_options.user_model = null;
      } else {
        this.top_options.user_model = index;
      }
    },
    show_chart() {
      // 处在看板页面
      if (!this.is_show_chart) {
        this.$nextTick(() => {
          this.table1Check = [this.top_options.select_model];
          this.init_charts();
          this.initAllSprint();
        });
      }
      this.is_show_chart = !this.is_show_chart;
    },
    // 完成按钮事件
    complete() {
      if (this.top_options.status === "NOT_STARTED") {
        this.start_sprint_dialog = true;
        const time = [];
        if (this.top_options.startDateTime) {
          time.push(
            ...[this.top_options.startDateTime, this.top_options.endDateTime]
          );
        }
        this.$set(this.start_sprint_item, "name", this.top_options.name);
        this.$set(this.start_sprint_item, "time", time);
        this.$set(
          this.start_sprint_item,
          "description",
          this.top_options.description
        );
      } else {
        let flag = false;
        this.build_scrum_column_list.forEach((part) => {
          part.data.forEach((column) => {
            column.dataList.forEach((node) => {
              if (node.statusCategory !== "DONE") {
                flag = true;
              }
            });
          });
        });
        if (flag) {
          this.complete_sprint_dialog = true;
        } else {
          const params = {
            projectId: this.get_pid(),
            sprintId: this.top_options.select_model,
            data: {
              status:
                this.top_options.status === "NOT_STARTED"
                  ? "IN_PROGRESS"
                  : "ENDED",
            },
          };
          storyPointStatistics({
            projectId: this.get_pid(),
            sprintId: this.top_options.select_model,
          }).then((res) => {
            change_sprint_status(params).then((res) => {
              this.get_sprints(false);
            });
          });
        }
      }
      // NOT_STARTED
    },
    //开始弹窗事件日期change事件
    start_sprint_time_settings(val) {},
    // 开始弹窗确认事件
    start_sprint_confirm() {
      this.$refs["start_form"].validate((valid) => {
        if (valid) {
          const params = {
            projectId: this.get_pid(),
            sprintId: this.top_options.select_model,
            data: {
              description: this.start_sprint_item.description,
              endDateTime: this.start_sprint_item.time[1],
              name: this.start_sprint_item.name,
              startDateTime: this.start_sprint_item.time[0],
            },
          };
          edit_sprint(params).then((res) => {
            const params = {
              projectId: this.get_pid(),
              sprintId: this.top_options.select_model,
              data: {
                status:
                  this.top_options.status === "NOT_STARTED"
                    ? "IN_PROGRESS"
                    : "ENDED",
              },
            };
            change_sprint_status(params).then((res) => {
              this.setSprint(this.get_pid());
              this.get_sprints(false);
              this.get_sprints_info();
              this.start_sprint_dialog = false;
              storyPointStatistics({
                projectId: this.get_pid(),
                sprintId: this.top_options.select_model,
              }).then((res) => {});
            });
          });
        } else {
        }
      });
    },
    // 完成弹窗确认事件
    complete_sprint_confirm() {
      this.$refs["complete_form"].validate((valid) => {
        if (valid) {
          const params = {
            projectId: this.get_pid(),
            data: {
              sourceSprintId: this.top_options.select_model,
              targetSprintId: this.complete_sprint_item.name,
            },
          };
          storyPointStatistics({
            projectId: this.get_pid(),
            sprintId: this.top_options.select_model,
          }).then((res) => {
            node_move(params).then((res) => {
              const params = {
                projectId: this.get_pid(),
                sprintId: this.top_options.select_model,
                data: {
                  status:
                    this.top_options.status === "NOT_STARTED"
                      ? "IN_PROGRESS"
                      : "ENDED",
                },
              };
              change_sprint_status(params).then((res) => {
                this.get_sprints(false);
                this.complete_sprint_dialog = false;
              });
            });
          });
        }
      });
    },
    // 编辑按钮事件
    edit() {
      this.$router.push({
        name: "board_edit",
        params: {
          data: this.top_options,
        },
      });
    },
    async node_add_confirm() {
      if (this.multiple_node) {
        if (this.project_type === "SCRUM") {
          const params = {
            projectId: this.get_pid(),
            sprintId: this.top_options.select_model,
            data: this.multiple_node,
          };
          add_sprint_nodes(params).then((res) => {
            this.get_sprint_nodes();
            this.multiple_node = "";
            this.multiple_node_options = [];
          });
        } else {
          if (this.top_node_arr && this.top_node_arr.nodeUuid) {
            let high_light = document.getElementById(
              `${this.top_node_arr.nodeUuid}`
            );
            if (high_light) {
              high_light.classList.remove("high_light");
            }
          }
          const params = {
            projectId: this.get_pid(),
            data: this.multiple_node,
          };
          add_kanban_nodes(params).then(async (res1) => {
            // const res = await get_node_use_node_key(
            //   this.get_pid(),
            //   this.multiple_node
            // );
            // this.top_node_arr = res;
            this.get_kanban_nodes();
            this.multiple_node = "";
            this.multiple_node_options = [];
          });
        }
      }
    },
    user_block_wrap_change() {},
    mousemovefn(e) {
      const wrap = this.$refs.quick_wrap;
      if (e.pageX < wrap.clientWidth / 4) {
        this.$refs.user_block_wrap.scrollLeft > 2
          ? (this.$refs.user_block_wrap.scrollLeft -= 2)
          : "";
      }
      if (e.pageX > (wrap.clientWidth / 4) * 3) {
        this.$refs.user_block_wrap.scrollLeft += 2;
      }
      if (e.pageY < wrap.clientHeight / 4) {
        this.$refs.user_block_wrap.scrollTop > 2
          ? (this.$refs.user_block_wrap.scrollTop -= 2)
          : "";
      }
      if (e.pageY > (wrap.clientHeight / 6) * 5) {
        this.$refs.user_block_wrap.scrollTop += 2;
      }
      this.$refs.block_wrap.scrollLeft = this.$refs.user_block_wrap.scrollLeft;
    },
    outer_dragover(event) {
      this.mousemovefn(event);
    },
    dragging(event, user_index, column_index, from) {
      this.is_drag = true;
      this.start_userindex = user_index;
      this.target_offsetTop = event.target.offsetParent.offsetTop;
      this.can_edit_status = [];
      const node =
        this.build_scrum_column_list[user_index].data[column_index].dataList[
          from
        ];
      let high_light = document.getElementById(`${node.nodeUuid}`);
      if (high_light) {
        high_light.classList.remove("high_light");
      }
      get_workflow_list({
        projectId: this.get_pid(),
        fileTypeId: node.fileTypeId,
        layer: node.layer,
        currentStatusId: node.status,
      }).then((res) => {
        res.forEach((item) => {
          this.can_edit_status.push(item.statusId);
        });
        this.drag_item.user_index = user_index;
        this.drag_item.column_index = column_index;
        this.drag_item.from = from;
        this.drag_item.status_index = null;
        this.drag_item.status_column_index = null;
      });
    },
    dragover(user_index, column_index) {
      this.drag_item.target_user_index = user_index;
      this.drag_item.target_column_index = column_index;
      this.target_column_index = column_index;
    },
    dragover_status(status_index, column_index) {
      this.drag_item.status_index = status_index;
      this.drag_item.status_column_index = column_index;
    },
    dragover_leave() {
      setTimeout(() => {
        this.drag_item.status_index = null;
        this.drag_item.status_column_index = null;
      }, 650);
    },
    dragend() {
      if (this.drag_item.target_user_index !== this.drag_item.user_index) {
        this.$message({
          message: this.$t("board.message.error1"),
          type: "warning",
        });
        this.is_drag = false;
      } else if (
        !this.can_edit_status_check(
          this.scrum_column_list[this.drag_item.target_column_index]
        )
      ) {
        this.$message({
          message: this.$t("board.message.error2"),
          type: "warning",
        });
        this.is_drag = false;
      } else if (
        this.blocks_options[this.drag_item.target_column_index] >=
          this.scrum_column_list[this.drag_item.target_column_index].number &&
        this.drag_item.column_index !== this.drag_item.target_column_index
      ) {
        this.$message({
          message: this.$t("board.message.error3"),
          type: "warning",
        });
        this.is_drag = false;
      } else {
        let move_item;
        if (typeof this.drag_item.status_column_index === "number") {
          // 乐观更新
          const originalObj = this.deepClone(this.table_data);
          if (this.top_options.type_model === 1) {
            move_item = this.table_data.file[this.drag_item.user_index].data[
              this.drag_item.column_index
            ].dataList.splice(this.drag_item.from, this.drag_item.from + 1);
            this.table_data.file[this.drag_item.target_user_index].data[
              this.drag_item.target_column_index
            ].dataList.push(move_item[0]);
          } else {
            move_item = this.table_data.user[this.drag_item.user_index].data[
              this.drag_item.column_index
            ].dataList.splice(this.drag_item.from, this.drag_item.from + 1);
            this.table_data.user[this.drag_item.target_user_index].data[
              this.drag_item.target_column_index
            ].dataList.push(move_item[0]);
          }
          this.is_drag = false;

          const params = {
            nodeId: move_item[0].id,
            nodeUuid: move_item[0].nodeUuid,
            targetStatus:
              this.scrum_column_list[this.drag_item.status_column_index]
                .statusDtoList[this.drag_item.status_index].statusId,
          };

          set_workflow_myself(this.get_pid(), params).then((res) => {
            if (res.length != 0) {
              // 此时有验证条件未通过
              // 对弹窗进行改错

              this.$message({
                showClose: true,
                message: res,
                duration: 0,
                type: "error",
              });
              this.table_data = originalObj;
            } else {
              // 验证条件通过
              set_workflow(this.get_pid(), params)
                .then((res) => {
                  this.project_type === "SCRUM"
                    ? this.get_sprint_nodes()
                    : this.get_kanban_column();
                })
                .catch((error) => {
                  // 如果失败了就复原
                  this.table_data = originalObj;
                });
            }
          });
        } else {
          this.is_drag = false;
        }
      }
    },
    sprint_change(val) {
      this.scrum_options.forEach((item) => {
        if (item.sprintId == val) {
          val = item;
        }
      });

      this.top_options.select_model = val.sprintId || val.select_model;
      this.top_options.name = val.name;
      this.top_options.build_name = val.build_name;
      this.top_options.status = val.status;
      this.top_options.startDateTime = val.startDateTime;
      this.top_options.endDateTime = val.endDateTime;
      this.get_sprints_info();
      this.get_sprint_nodes();
      if (
        val.status === "ENDED" ||
        (this.is_show_chart && val.status === "IN_PROGRESS")
      ) {
        this.$nextTick(() => {
          this.init_charts();
        });
      } else {
        this.is_show_chart = false;
      }
    },
    sprint_add() {
      this.add_sprint_dialog = true;
      this.$nextTick(() => {
        this.$refs["form"] ? this.$refs["form"].clearValidate() : "";
      });
    },
    init_line1(xAxis, yAxis, grid, series) {
      const dataSeries = series;
      const dates = [];
      const data = [];
      const is_show_theory =
        series.length !== 0 && series[0].storyPointCount !== 0;

      const endTime = this.top_options.endDateTime
        ? this.top_options.endDateTime.split(" ")[0]
        : undefined;
      let flag = false;
      for (const seryItem of dataSeries) {
        if (seryItem.date == endTime) {
          flag = true;
        }
      }
      if (!flag && is_show_theory) {
        dataSeries.push({
          date: endTime,
        });
      }
      dataSeries.sort((before, after) => (after.date < before.date ? 1 : -1));
      dataSeries.forEach((sery) => {
        dates.push(sery.date);
        data.push(sery.storyPointCount);
      });
      const new_xAxis = Object.assign({}, xAxis, {
        data: dates,
        name: this.$t("board.message.info"),
        boundaryGap: ["20%", "10%"],
      });
      const new_series = Object.assign({}, series[0], {
        name: this.$t("quick.BurnDownChart"),
        type: "line",
        data: data,
        connectNulls: true,
        itemStyle: {
          normal: {
            color: "#5B6FC0",
            lineStyle: {
              color: "#5B6FC0",
            },
          },
        },
      });
      const theory_series = Object.assign({}, series[0], {
        name: this.$t("quick.IdealBurnDownChart"),
        type: "line",
        data: [
          [dates[0], data[0]],
          [endTime, 0],
        ],
        symbol: "rect",
        itemStyle: {
          normal: {
            color: "#9ECA7F",
            lineStyle: {
              color: "#9ECA7F",
              width: 2,
              type: "dashed",
            },
          },
        },
      });
      const option = {
        xAxis: new_xAxis,
        yAxis,
        tooltip: {},
        grid,
        series: is_show_theory ? [theory_series, new_series] : new_series,
        legend: {
          data: [
            this.$t("quick.BurnDownChart"),
            this.$t("quick.IdealBurnDownChart"),
          ],
          show: is_show_theory,
        },
      };
      const chart = this.$refs.line1;
      this.line1 = this.$echarts.init(chart);
      this.line1.setOption(option, true);
    },
    init_line2(xAxis, yAxis, grid, series) {
      const dates = [];
      const data = [];
      series.forEach((sery) => {
        dates.push(sery.date);
        data.push(sery.completeCount);
      });
      const new_xAxis = Object.assign({}, xAxis, {
        data: dates,
        name: this.$t("board.message.info"),
        boundaryGap: ["20%", "10%"],
      });
      const new_series = [
        Object.assign({}, series[0], {
          type: "line",
          data: data,
        }),
      ];
      const option = {
        xAxis: new_xAxis,
        yAxis,
        tooltip: {},
        grid,
        series: new_series,
      };
      const chart = this.$refs.line2;
      this.line2 = this.$echarts.init(chart);
      this.line2.setOption(option);
    },
    init_bar2(xAxis, yAxis, grid, series, res) {
      const chart = this.$refs.bar2;
      this.bar2 = this.$echarts.init(chart);
      this.bar2SortStatus = "desc";
      this.sortSeries(this.bar2Series, "desc");
      const new_xAxis = Object.assign(
        {},
        {},
        {
          data: res.assigneeList.map((item) => {
            return this.matchUserNickname(
              item,
              this.$t("board.status.unAssigned")
            );
          }),
          axisLine: {
            lineStyle: {
              color: "#EBEEF5",
            },
          },
          nameTextStyle: {
            color: "rgba(128, 128, 128, 1)",
          },
          splitLine: {
            lineStyle: {
              color: "#EBEEF5",
            },
          },
          axisTick: {
            lineStyle: {
              color: "#CCCCCC",
            },
          },
          axisLabel: {
            color: "rgba(128, 128, 128, 1)",
            interval: 0,
            formatter: (value) => {
              if (value.includes(".")) {
                return value.split(".").join("\n");
              } else {
                return value;
              }
            },
          },
          name: this.$t("board.chart.ass"),
          boundaryGap: ["20%", "10%"],
        }
      );
      const new_series = [
        Object.assign({}, series[0], {
          type: "bar",
          barWidth: 20,
          data: this.bar2Series.dataList,
          itemStyle: {
            color: "rgba(222, 238, 250, 1)",
          },
        }),
      ];
      const option = {
        xAxis: new_xAxis,
        yAxis,
        grid: {
          top: "10%",
          right: "15%",
          bottom: "20%",
        },
        tooltip: {},
        series: new_series,
        dataZoom: [
          {
            show: true,
            height: 5,
            xAxisIndex: [0],
            bottom: 5,
            showDetail: false,
            showDataShadow: false,
            borderColor: "transparent",
            textStyle: {
              fontSize: 0,
            },
            endValue: 6, //从0开始的相当于5个
            backgroundColor: "rgba(0,0,0,0)",
            borderWidth: 0,
            handleSize: "0%",
          },
        ],
      };
      this.bar2.setOption(option);
    },
    //  初始化头部表格
    initAllSprint(current) {
      let defaultIndex = 0;
      if (!current) {
        this.scrum_options.forEach((sprint, index) => {
          if (sprint.sprintId === this.top_options.select_model) {
            defaultIndex = index;
          }
        });
      }
      this.table1pages.total = this.scrum_options.length;
      this.table1pages.current = current || Math.floor(defaultIndex / 10) + 1;
      const sprintIds = [];
      this.allSprintCurrentData = [];
      for (
        let i = (this.table1pages.current - 1) * 10;
        i < this.table1pages.current * 10;
        i++
      ) {
        if (this.scrum_options[i]) {
          sprintIds.push(this.scrum_options[i].sprintId);
        }
      }
      const params = {
        projectId: this.get_pid(),
        data: {
          sprintIds: sprintIds,
        },
      };
      getBatchAnalyzeNodeStatus(params).then((res) => {
        this.currentMaxNum = 0;
        this.allSprintCurrentData = res;
        res.forEach((item) => {
          let total = 0;
          item.sprintStatusDistributionList &&
            item.sprintStatusDistributionList.forEach((single) => {
              total += single.count;
            });
          if (total > this.currentMaxNum) {
            this.currentMaxNum = total;
          }
        });
        this.$nextTick(() => {
          this.lockSelect = true;
          if (!current) {
            res.forEach((row) => {
              if (row.sprintId === this.top_options.select_model) {
                this.$refs.allSprintTable.toggleRowSelection(row, true);
                this.table1Check = [this.top_options.select_model];
              }
            });
          } else {
            res.forEach((row) => {
              if (this.table1Check.indexOf(row.sprintId) !== -1) {
                this.$refs.allSprintTable.toggleRowSelection(row, true);
              }
            });
          }
          this.lockSelect = false;
        });
      });
    },
    // 初始化图表
    async init_charts() {
      if (!this.table1Check.length && this.is_show_chart) {
        return;
      }
      const xAxis = {
        type: "category",
        boundaryGap: false,
        nameTextStyle: {
          color: "rgba(128, 128, 128, 1)",
        },
        axisLine: {
          lineStyle: {
            color: "#EBEEF5",
          },
        },
        splitLine: {
          lineStyle: {
            color: "#EBEEF5",
          },
        },
        axisTick: {
          lineStyle: {
            color: "#CCCCCC",
          },
        },
        axisLabel: {
          color: "rgba(128, 128, 128, 1)",
        },
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      };
      const yAxis = {
        type: "value",
        axisLine: {
          show: true,
          lineStyle: {
            color: "#EBEEF5",
          },
        },
        axisLabel: {
          color: "rgba(128, 128, 128, 1)",
        },
      };
      const grid = {
        top: "10%",
        right: "15%",
        bottom: "10%",
      };
      this.xAxis = xAxis;
      this.yAxis = yAxis;
      this.grid = grid;
      const params = {
        projectId: this.get_pid(),
      };
      let series = {
        chartItemList: [],
      };
      series = await getSprintBurnChart(
        this.get_pid(),
        this.table1Check.length
          ? this.table1Check[0]
          : [this.top_options.select_model]
      );

      const bar1res = await resultStatistics(
        Object.assign({}, params, {
          query: {
            type: "STORY_POINT",
            ids: this.table1Check.length
              ? this.table1Check
              : [this.top_options.select_model],
          },
        })
      );
      const bar2res = await resultStatistics(
        Object.assign({}, params, {
          query: {
            type: "COMPLETE_COUNT",
            ids: this.table1Check.length
              ? this.table1Check
              : [this.top_options.select_model],
          },
        })
      );
      this.countBar1 =
        bar1res && bar1res.dataList && bar1res.dataList.length !== 0
          ? bar1res.dataList.reduce((prev, curr) => {
              return prev + curr;
            })
          : "";
      this.countBar2 =
        bar2res && bar2res.dataList && bar2res.dataList.length !== 0
          ? bar2res.dataList.reduce((prev, curr) => {
              return prev + curr;
            })
          : "";
      this.bar1Series = bar1res;
      this.bar2Series = bar2res;
      this.complete_table_data = [];
      this.allfiletype = await get_filetype(this.get_pid());
      this.allstatus = await get_status_list(this.get_pid());
      completeNodes(
        params,
        this.table1Check.length
          ? this.table1Check
          : [this.top_options.select_model]
      ).then((res) => {
        res.forEach((item) => {
          const obj = Object.assign(item, {
            fileTypeId: this.allfiletype.filter(
              (file) => file.fileTypeId === item.fileTypeId
            )[0].name,
            assignee: this.matchUserNickname(item.assignee, ""),
            status: this.allstatus.filter(
              (status) => status.statusId === item.status
            )[0].name,
            statusCategory: this.allstatus.filter(
              (status) => status.statusId === item.status
            )[0].statusCategory,
          });
          this.complete_table_data.push(obj);
        });
      });
      // 过滤重复项
      series.chartItemList = series.chartItemList.filter(function (
        item,
        index,
        self
      ) {
        return self.findIndex((el) => el.date == item.date) === index;
      });
      series.chartItemList.sort((before, after) =>
        after.date < before.date ? 1 : -1
      );
      // 过滤周末
      this.chartFilterWeekend = series.chartItemList.filter((item) => {
        return (
          new Date(item.date).getDay() !== 0 &&
          new Date(item.date).getDay() !== 6
        );
      });
      this.chartAll = series.chartItemList;
      this.init_line1(xAxis, yAxis, grid, series.chartItemList);
      this.init_line2(xAxis, yAxis, grid, series.chartItemList);
      this.init_bar1(xAxis, yAxis, grid, series, bar1res);
      this.init_bar2(xAxis, yAxis, grid, series, bar2res);
      let element = this.$refs["echarts_main_wrap"];
      this.observer = new ResizeObserver(() => {
        this.line1.resize();
        this.line2.resize();
        this.bar1.resize();
        this.bar2.resize();
      });
      this.observer.observe(element);
    },
    //跳转节点
    jump_node(item) {
      const { href } = this.$router.resolve({
        name: "node_detail",
        params: {
          projectId: this.get_pid(),
          nodeKey: item.key,
        },
      });
      window.open(href, "_blank");
    },
    nodeLink(row) {
      get_file(this.get_pid(), row.fileId).then((result) => {
        getFile(this.get_pid(), result.key).then((res) => {
          this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
          this.$store.commit("SET_FILE_ID", result.id);
          this.$store.commit("SET_FILE_KEY", result.key);
          this.$store.commit("SET_MINDMAP_NAME", result.name);
          this.$store.commit("SET_OPEN_STATE", "exist");
          this.$store.commit("SET_FILETYPE_ID", result.fileTypeId);
          //跳转到思维导图
          const { href } = this.$router.resolve({
            path: `/${this.get_pid()}/myMind/${result.key}`,
            query: {
              node_id: row.id, // 节点id
            },
          });
          window.open(href, "_blank");
        });
      });
    },
    // 深复制对象
    deepClone(source) {
      if (!source) return;
      let target;
      if (typeof source === "object") {
        // 根据source类型判断是新建一个数组还是对象
        target = Array.isArray(source) ? [] : {};
        // 遍历source，并且判断是source的属性才拷贝
        for (let key in source) {
          // eslint-disable-next-line no-prototype-builtins
          if (source.hasOwnProperty(key)) {
            if (typeof source[key] !== "object") {
              target[key] = source[key];
            } else {
              // 如果内部属性存在复杂数据类型，使用递归实现深拷贝
              target[key] = this.deepClone(source[key]);
            }
          }
        }
      } else {
        target = source;
      }
      return target;
    },
    handleChange() {},
  },
};
</script>
<style lang="scss" scoped>
@keyframes light {
  from {
    box-shadow: 0px 0px 7px 2px rgb(55 159 254 / 80%);
  }
  to {
    box-shadow: 0px 1px 0px 0px rgba(9, 13, 20, 1);
  }
}
.moveitem-move {
  transition: 0.5s;
}
.status_todo_s {
  background-color: rgba(166, 166, 166, 1);
  border: 1px solid rgba(166, 166, 166, 1);
  color: white;
}
.status_open_s {
  color: white;
  background-color: rgba(67, 207, 124, 1);
  border: 1px solid rgba(67, 207, 124, 1);
}
.status_progress_s {
  color: white;
  background-color: rgba(42, 130, 228, 1);
  border: 1px solid rgba(42, 130, 228, 1);
}
.status_s {
  height: 32px;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  font-size: 12px;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.high_light {
  animation: light 0.5s 20;
}
.scroll_wrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}
.html_fixed > p {
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.popper_height {
  max-height: 500px;
  max-width: 500px;
  overflow: auto;
}
.csllapse_task {
  ::v-deep .collapse-main {
    margin-left: 0px;
  }
}
</style>
<style lang="scss" scoped>
.radius_border_left {
  border-radius: 2px 0 0 2px;
}
.radius_border_right {
  border-radius: 0 2px 2px 0;
}
.radius_border_both {
  border-radius: 2px 2px 2px 2px;
}
.status_todo {
  background-color: rgba(166, 166, 166, 1);
}
.status_open {
  background-color: rgba(67, 207, 124, 1);
}
.status_progress {
  background-color: rgba(42, 130, 228, 1);
}
.table1 {
  width: 100%;
  height: 316px;
}
.table1-bar {
  height: 16px;
  width: 100%;
  vertical-align: middle;
  line-height: 16px;
  & > span {
    display: inline-block;
    height: 16px;
  }
}
.table-scroll-wrap {
  height: 48vh;
  overflow-y: scroll;
  margin-top: 20px;
}
.jump_icon_class {
  font-size: 20px; /*no*/
}
.tooltip_span_fp {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  & > span:first-of-type {
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
    .box_item_l {
      height: 30px;
      border: 1px solid #014caa;
      border-radius: 5px 0 0 5px;
      padding: 6px 14px;
      background-color: #014caa;
      display: flex;
      justify-items: center;
      align-items: center;
    }
    .box_item_r {
      height: 30px;
      border: 1px solid rgba(219, 219, 219, 1);
      border-radius: 0 5px 5px 0;
      padding: 0 6px;
      display: flex;
      justify-items: center;
      align-items: center;
      .box_font {
        font-size: 18px;
        color: rgba(255, 195, 0, 1);
        font-weight: 700;
      }
    }
  }
  & > span:last-of-type {
    min-width: 120px;
    text-align: right;
  }
}
.tooltip_span {
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: -webkit-ellipsis-lastline;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  & > span {
    color: gray;
    font-size: 0.0938rem;
  }
}
// 复写elementUI样式
.quick_wrap {
  text-align: left;
  height: fit-content;
  overflow-y: hidden;
  height: 100%;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .backlog_back {
    background-color: rgba(255, 195, 0, 0.15);
  }
  .not_started_back {
    background-color: rgba(229, 229, 229, 1);
  }
  .in_progress_back {
    background-color: rgba(165, 214, 63, 0.15);
  }
  .completed_back {
    background-color: rgba(42, 130, 228, 0.15);
  }
  .title {
    color: rgba(56, 56, 56, 1);
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
  }
  .quick_main_wrap {
    padding-left: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .top_options {
      margin-bottom: 22px;
      display: flex;
      justify-content: space-between;
      .el-dropdown-link {
        width: 263px;
        height: 32px;
        // border: 1px solid rgba(166, 166, 166, 1);
        display: inline-block;
        line-height: 32px;
        border-radius: 4px;
        text-indent: 8px;
        background-color: rgba(255, 255, 255, 1);
        ::v-deep {
          .el-icon--right {
            float: right;
            margin-right: 6px;
            vertical-align: middle;
            line-height: 32px;
          }
        }
      }
      .select_class {
        height: 50px;
        overflow: auto;
      }
      .type_button {
        display: inline-block;
        width: fit-content;
        height: 32px;
        line-height: 32px;
        padding: 0 6px;
        background: rgba(229, 229, 229, 1);
        border-radius: 4px;
        text-align: center;
        color: rgba(128, 128, 128, 1);
        font-size: 12px;
        margin: 0 6px;
        cursor: pointer;
        &:nth-of-type(1) {
        }
      }
      .more_user {
        font-size: 30px;
        margin-left: 5px;
      }
      .button_checked {
        background: rgba(56, 56, 56, 1);
        color: #fff;
      }
      .user_img_wrap {
        display: inline-block;
        & > span {
          display: inline-block;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: -10px;
          margin-left: 2px;
          margin-right: 2px;
          cursor: pointer;
          &:nth-of-type(1) {
            margin-left: 28px;
          }
          & > img {
            width: 28px;
            height: 28px;
          }
        }
      }
      .right_button_wrap {
        display: inline-block;
        position: relative;
        float: right;
        & > .set_button {
          color: rgba(1, 76, 170, 1);
          width: 24px;
          height: 24px;
          position: absolute;
          top: -36px;
          right: 10px;
          cursor: pointer;
        }
        & > span {
          font-size: 14px;
          color: rgba(128, 128, 128, 1);
          margin-right: 6px;
        }
        & > div {
          display: inline-block;
          height: 32px;
          line-height: 32px;
          padding: 0 4px;
          text-align: center;
          border: 1px solid rgba(1, 76, 170, 1);
          color: rgba(1, 76, 170, 1);
          cursor: pointer;
          font-size: 14px;
          border-radius: 4px;
          margin: 0 6px;
        }
      }
    }
    .sprint_target {
      width: 100%;
      line-height: 20px;
      padding: 20px 10px 10px 10px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px 8px 0 0;
      color: rgba(128, 128, 128, 1);
      font-size: 16px;
      word-break: break-all;
      & > span {
        font-size: 16px;
        margin-left: 6px;
      }
    }
    .sprint_options_hide {
      background-color: #fff;
      height: 25px;
      line-height: 30px;
      border-radius: 0 0 8px 8px;
      text-align: center;

      cursor: pointer;
      &:hover {
        background-color: #f9fafc;
        color: #409eff;
      }
    }
    .block_wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      overflow: hidden;
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      & > div {
        height: 42px;
        line-height: 42px;
        margin-right: 29px;
        border-radius: 4px;
        padding: 0 10px;
        flex-shrink: 0;
        min-width: 350px;
        &:last-of-type {
          margin-right: 0;
        }
        & > span {
          display: inline-block;
          &:nth-of-type(1) {
            float: left;
          }
          &:nth-of-type(2) {
            float: right;
          }
        }
      }
      .backlog_font_color {
        color: rgba(217, 166, 0, 1);
        font-size: 18px;
      }
      .not_started_font_color {
        color: rgba(128, 128, 128, 1);
        font-size: 18px;
      }
      .in_progress_font_color {
        color: rgba(136, 176, 52, 1);
        font-size: 18px;
      }
      .completed_font_color {
        color: rgba(42, 130, 228, 1);
        font-size: 18px;
      }
    }
    .add_node_wrap {
      display: flex;
      justify-content: end;
      margin-top: 24px;
      align-items: center;
      ::v-deep .el-select-dropdown {
        max-width: 400px;
      }
      & > span {
        display: inline-block;
        color: rgba(144, 147, 153, 1);
        font-size: 16px;
        line-height: 30px;
      }
      .node_add_confirm {
        width: 60px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        // background: rgba(48, 100, 143, 1);
        // border-radius: 4px;
        // color: #fff;
        cursor: pointer;
        margin-left: 12px;
      }
      .button_style {
        opacity: 1 !important;
        border-radius: 6px !important;
        background: rgba(2, 78, 171, 1) !important;
        text-align: center !important;
        /* line-height: 45px; */
        font-size: 16px !important ;
        /* line-height: 0px; */
        color: rgba(255, 255, 255, 1) !important;
      }
    }
    .user_block_wrap {
      margin-top: 20px;
      // height: 520px;
      overflow: scroll;
      position: relative;
      flex: 1;
      // height: 90%;
      &::-webkit-scrollbar {
        // display: none; /* Chrome Safari */
      }
      .fake_scroll {
        position: relative;
        height: 45vh;
      }
    }
    .echarts_main_wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow-x: hidden;
      & > div {
        width: 48%;
        margin-top: 0px;
        height: 450px;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        padding: 12px 0 0 20px;
        & > p {
          color: rgba(128, 128, 128, 1);
          font-size: 14px;
          margin: 0;
        }
        & > div {
          width: 100%;
          height: 280px;
        }
      }
      .bar1 {
        width: 48%;
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        margin-top: 30px;
        padding: 12px 0 0 20px;
        & > p {
          color: rgba(128, 128, 128, 1);
          font-size: 14px;
          margin: 0;
        }
      }
    }
    .inner_user_block {
      display: flex;
      justify-content: space-between;
      & > div {
        position: relative;
        padding: 12px;
        border-radius: 4px;
        margin-right: 29px;
        min-width: 350px;
        flex-shrink: 0;
        &:last-of-type {
          margin-right: 0;
        }
        & > .status_list {
          position: relative;
          border-radius: 4px;
          width: 100%;
          min-height: 100%;
          border: 2px dashed rgb(48, 100, 143);
          // background-color: red;
          background-color: rgba(155, 155, 155);
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          & > div > div {
            height: 100px;
            line-height: 100px;
            width: calc(100% - 12px);
            color: #fff;
            border: 4px dashed rgba(64, 169, 255, 1);
            background-color: rgba(64, 169, 255, 0.3);
            border-radius: 4px;
            text-align: center;
            margin: 6px;
          }
        }
        .node_list {
          & > div {
            & > span {
              & > span {
                cursor: pointer;
                display: inline-block;
                height: 40px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                position: relative;
                &:nth-of-type(1) {
                  width: 100%;
                  z-index: 3;
                }
                &:nth-of-type(2) {
                  width: 96%;
                  margin-left: 2%;
                  top: -30px;
                  z-index: 2;
                }
                &:nth-of-type(3) {
                  width: 92%;
                  margin-left: 4%;
                  top: -60px;
                  z-index: 1;
                }
              }
            }
          }
        }
        & > .node_list > div > div > div {
          width: 100%;
          height: 108px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          margin-bottom: 6px;
          padding: 6px 15px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          & > p {
            margin: 0;
            height: 30px;
            &::before {
              content: "";
              width: 100%;
              height: 1px;
              background-color: rgba(229, 229, 229, 1);
              position: absolute;
              top: 42px;
              left: 0;
            }
            & > span {
              &:nth-of-type(1) {
                color: rgba(56, 56, 56, 1);
                font-size: 18px;
                float: left;
              }
              &:nth-of-type(2) {
                color: rgba(255, 195, 0, 1);
                font-size: 18px;
                float: right;
              }
            }
          }
          & > div {
            clear: both;
            display: flex;
            margin: 16px 0;
            & > span {
              color: rgba(128, 128, 128, 1);
              font-size: 18px;
              display: inline-block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              img {
                width: 26px;
                height: 26px;
                vertical-align: middle;
              }
              &:nth-of-type(1) {
                width: 100%;
              }
              &:nth-of-type(2) {
                width: 65%;
              }
            }
          }
        }
      }
      .accept_drag {
        background-color: #fff;
        border: 2px dashed rgb(48, 100, 143);
      }
      .accept_drag_focus {
        box-shadow: 0 0 10px rgba(81, 203, 238, 1);
        --box-shadow: 0 0 10px rgba(81, 203, 238, 1);
      }
    }
  }
}
.drawer_wrap {
  padding: 20px;
  // height: 100%;
  .header_content {
    display: flex;
    justify-content: flex-start;
    align-items: top;
    text-align: left;
    margin: 20px auto 0 auto;
    .header_node {
      display: flex;
      align-items: top;
      margin-left: 0 !important;
      // margin-right: 20px;
    }
    ::v-deep .el-input__inner {
      width: 180px;
    }
  }
  .top-node-id {
    font-size: 16px;
    font-weight: 700;
    color: rgba(255, 195, 0, 1);
    background-color: rgba(229, 229, 229, 1);
    width: 120px;
    height: 28px;
    border-radius: 2px;
    text-align: center;
    span {
      vertical-align: middle;
      line-height: 28px;
    }
  }

  .node-name {
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -webkit-ellipsis-lastline;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .baseline_lock {
    color: rgba(166, 166, 166, 1);
    font-size: 14px;
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
  }
  .box_item_l {
    height: 30px;
    border: 1px solid #014caa;
    border-radius: 5px 0 0 5px;
    padding: 6px 14px;
    background-color: #014caa;
    display: flex;
    justify-items: center;
    align-items: center;
  }
  .box_item_r {
    height: 30px;
    border: 1px solid rgba(219, 219, 219, 1);
    border-radius: 0 5px 5px 0;
    padding: 0 14px;
    display: flex;
    justify-items: center;
    align-items: center;
    .box_font {
      font-size: 18px;
      color: rgba(255, 195, 0, 1);
      font-weight: 700;
    }
  }
}
.caret-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.el-icon-caret-top {
  transform: translate(0, 3px);
}

.el-icon-caret-bottom {
  transform: translate(0, -3px);
}

.el-icon-caret-colored {
  color: #068bff;
}

.left_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.blue_button {
  color: rgba(1, 76, 170, 1);
  border: 1px solid rgba(1, 76, 170, 1);
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  margin: 0 15px 0 0;
}
.el-popper {
  height: 400px;
  overflow-y: scroll;
}
::v-deep.el-select-dropdown__item.test-add-option::after {
  content: "";
}
::v-deep .el-scrollbar__wrap {
  overflow: hidden auto;
  margin-bottom: 0 !important;
}
</style>

<style lang="scss">
.table1-bar-line {
  height: 20px;
  line-height: 20px;
}
.table1-bar-tip-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 4px;
  vertical-align: sub;
}
#quick_select {
  .el-select .el-select-dropdown {
    transform: translateX(-50px);
  }
}
.select_own .el-select-dropdown__list {
  padding: 0;
  margin: 0;
  li:nth-child(1) {
    background-color: rgba(77, 160, 255, 0.35);
    border-radius: 6px 6px 0px 0px;
  }
}
.select-icon-height-auto-class {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 1px !important;
  }
}
</style>
<style lang="scss" scoped>
.collapse_item {
  margin-bottom: 10px;
  margin-top: 17px;
  border-radius: 8px;
}
.collapse-title {
  display: flex;
  align-items: center;

  height: 27px;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 500;
  left: 40px;
  order: 1;
}
.el-collapse-item__header {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  order: -1;
  background: rgba(229, 229, 229, 1);
  border-radius: 8px !important;
}
::v-deep .el-collapse-item__arrow {
  width: 20px;
  height: 20px;
  background: rgba(229, 229, 229, 1);
  margin-left: 5px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-collapse-item__header {
  border-radius: 8px;
}
.select-icon-height-auto-class {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 10px !important;
  }
}
::v-deep .el-collapse-item__content {
  padding-bottom: 5px;
}
</style>
