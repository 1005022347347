<template>
  <div v-if="basedata.length > 0" v-loading="isload">
    <el-carousel
      trigger="click"
      :autoplay="false"
      :class="{ limitHeight: limitHeight }"
      class="con"
      indicator-position="none"
      arrow="always"
      @change="c"
    >
      <el-carousel-item v-for="(item, index) in basedata" :key="index">
        <div class="box" :class="{ limitHeight: limitHeight }">
          <div class="tool">
            <p class="block_title">
              {{ +(current + 1) + "/" + basedata.length }}
            </p>
            <div class="line"></div>

            <el-button
              type="info"
              style="
                width: 100%;
                border: none;
                background-color: rgb(230, 230, 230);
              "
              @click="add_draw"
              ><i class="iconfont icon-add"
            /></el-button>
            <div class="line"></div>

            <el-button
              type="info"
              style="
                width: 100%;
                border: none;
                background-color: rgb(230, 230, 230);
              "
              @click="edit_draw(item.id)"
              ><img src="@/assets/img/edit.png"
            /></el-button>
            <div class="line"></div>
            <el-button
              type="info"
              style="
                width: 100%;
                border: none;
                background-color: rgb(230, 230, 230);
              "
            >
              <img src="@/assets/img/zoom.png" @click="zoom(index)" />
            </el-button>
            <div class="line"></div>

            <el-button
              type="info"
              style="
                width: 100%;
                border: none;
                background-color: rgb(230, 230, 230);
              "
              @click="del_draw(item.id)"
              ><img src="@/assets/img/del.png"
            /></el-button>
            <div class="line"></div>
            <el-tooltip v-if="!id_edit" effect="dark" :content="item.name">
              <div @click="id_edit = true">
                {{
                  item.name.length > 12
                    ? item.name.slice(0, 12) + "..."
                    : item.name
                }}
              </div>
            </el-tooltip>
            <div v-else>
              <input
                v-model="item.name"
                type="text"
                @keyup.enter="away()"
                @blur="away()"
              />
            </div>
          </div>
          <div class="img" :class="{ limitHeight: limitHeight }">
            <img
              :id="item.id"
              :key="once[index]"
              :src="item.data"
              :class="{ limitImgHeight: limitHeight }"
              style="width: 90%"
            />
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-img-viewer
      preview-teleported
      class="viewer"
      v-if="visiblelist"
      :url-list="[itemdata]"
      :on-close="closeImgViewer"
    ></el-img-viewer>
  </div>
  <div v-else>
    <img
      v-limits-of-authority="'NODE_EDIT'"
      src="@/assets/img/drawio.png"
      class="svgimg1"
      @click="add_draw"
    />
  </div>
</template>

<script>
import { get_node_use_node_key } from "@/network/node/index.js";
import {
  upload_drawio,
  download_drawio,
} from "@/network/fileOperation/index.js";
import { Drawio } from "@/plugins/drawio.js";
import { ossAuthorization } from "@/utils/oss";

export default {
  components: {
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  props: {
    nodeKey: {
      type: String,
      default: "",
    },
    custom: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    isCopy: {
      type: Boolean,
      default: false,
    },
    limitHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      node: {},
      basedata: [],
      once: [],
      valueChange: {
        fieldReferenceId: "",
        value: [],
      },
      current: 0,
      new_graph: "",
      itemdata: "",
      visiblelist: false,
      id_edit: false,
      client: "",
      isload: false,
      lock: false,
    };
  },

  created() {
    this.$store.commit("SET_IS_COPY", this.isCopy);
    // 获取阿里云oss授权
    ossAuthorization.call(this);
    let tais_ = this;
    if (this.custom) {
      this.basedata = this.value || [];
      if (this.value) {
        this.basedata = [];
        // console.log("JSON.parse(this.value)", JSON.parse(this.value));
        JSON.parse(this.value).forEach(async (ele) => {
          let res = await download_drawio(ele.data);
          const reader = new FileReader();
          reader.readAsText(res);
          reader.onload = function (e) {
            tais_.basedata.push({
              id: ele.id,
              name: ele.name,
              data: e.target.result,
            });
          };
        });
      } else {
        tais_.basedata = [];
      }
    }
  },
  mounted() {
    this.valueChange.fieldReferenceId = this.custom;
    for (let i in this.basedata) {
      this.once.push(0);
    }
    let this_ = this;
    window.addEventListener("message", function (e) {
      try {
        if (this_.$store.getters.is_copy) {
          if (typeof e.data == "string") {
            let event = JSON.parse(e.data).event;
            let msg = "";
            let data = "";
            if (event == "export") {
              msg = JSON.parse(e.data).message.originelement;
              data = JSON.parse(e.data).data;
              this_.new_graph = "";
              this_.binddata(msg, data);
            }
            if (event == "save") {
              this_.new_graph = "";
            }
            if (event == "exit") {
              // console.log(this_.new_graph);

              let l = [];
              if (this_.new_graph) {
                this_.basedata.forEach((item) => {
                  item.id == this_.new_graph ? "" : l.push(item);
                });
                this_.basedata = l;
              }
              // console.log(l, this_.basedata);
            }
          }
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    });
  },
  methods: {
    async putObject(data) {
      try {
        const result = await this.client.put(
          `${this.$store.state.project.tenantId}/drawio/exampleobject.txt`,
          data
        );
      } catch (e) {
        console.log(e);
      }
    },
    up_base64(base64) {
      let type = "text/plain;";
      let file = new File([base64], "drawio", { type });
      let form = new FormData();
      form.append("attachment", file);
      upload_drawio(form).then((res) => {
        return res;
      });
    },
    get_base64(url) {
      // console.log(url);
      if (url) {
        download_drawio(url).then((res) => {
          const reader = new FileReader();
          reader.readAsText(res);
          reader.onload = function (e) {
            // console.log(e.target.result);
            return e.target.result;
          };
        });
      }
    },

    away() {
      this.id_edit = false;
      this.save_draw_change();
    },
    async save_draw_change(id) {
      ossAuthorization.call(this);
      this.isload = true;
      if (this.custom) {
        try {
          let bd = [];
          for (let ele of this.basedata) {
            let type = "text/plain;";
            let myDate = Date.now().toString().slice(2, -1);
            let j = "draw" + myDate;
            let file = new File([ele.data], "drawio", { type });
            const res = await this.client.put(
              `${this.$store.state.project.tenantId}/drawio/${j}`,
              file
            );
            bd.push({ id: ele.id, name: ele.name, data: res.url });
            this.valueChange.fieldReferenceId = this.custom;
            this.valueChange.value = JSON.stringify(bd);
          }
          this.isload = false;
          if (this.valueChange.value && this.valueChange.value.length === 0) {
            this.valueChange.value = null;
          }
          this.save_value_change();
          return false;
        } catch {
          this.$message.warning(this.$t("nodeDetail.message.error3"));
        }
      }
    },
    save_value_change() {
      this.$emit("valueChange", this.valueChange);
    },
    c(e) {
      this.current = e;
    },
    binddata(id, data) {
      let flag = false;
      get_node_use_node_key(this.get_pid(), this.nodeKey).then((res) => {
        this.node = res;
        if (this.custom) {
          for (let i of res.content) {
            if (i.fieldReferenceId == this.custom) {
              if (i.value) {
                JSON.parse(i.value).forEach((ele) => {
                  // 如果是node原有的图 要新换一个id
                  if (ele.id === id) {
                    flag = true;
                  }
                });
              }
            }
          }
        }
        for (let i in this.basedata) {
          if (this.basedata[i].id == id) {
            this.basedata[i].data = data;
            if (flag) {
              let myDate =
                new Date().getTime().toString() +
                "random" +
                Math.ceil(Math.random() * 100).toString() +
                "user" +
                this.$store.getters.userAccountId;
              let new_id = "draw" + myDate;
              this.basedata[i].id = new_id;
            }
            this.save_draw_change();
            return;
          }
        }
      });
    },
    edit_draw(id) {
      this.new_graph = id;
      Drawio.editElement(document.getElementById(id));
    },
    zoom(i) {
      this.visiblelist = true;
      this.itemdata = this.basedata[i].data;
    },
    closeImgViewer() {
      this.visiblelist = false;
    },
    del_draw(id) {
      for (let i in this.basedata) {
        this.once[i] += 1;
        if (this.basedata[i].id == id) {
          this.basedata.splice(i, 1);
          this.save_draw_change();
          return;
        }
      }
    },
    add_draw() {
      this.once.push(0);
      let myDate =
        new Date().getTime().toString() +
        "random" +
        Math.ceil(Math.random() * 100).toString() +
        "user" +
        this.$store.getters.userAccountId;
      let i = "draw" + myDate;
      this.basedata.push({
        name: this.$t("nodeDetail.message.info"),
        // data:'https://automind-oss.ytdevops.com/node_accessors/2d6abeb21665374267159-zyz',
        data: "data:image/svg+xml;base64,",
        // 'data':"data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIxcHgiIGhlaWdodD0iMXB4IiB2aWV3Qm94PSItMC41IC0wLjUgMSAxIiBjb250ZW50PSImbHQ7bXhmaWxlIGhvc3Q9JnF1b3Q7ZW1iZWQuZGlhZ3JhbXMubmV0JnF1b3Q7IG1vZGlmaWVkPSZxdW90OzIwMjItMDgtMzFUMDc6MjI6NDUuNTkwWiZxdW90OyBhZ2VudD0mcXVvdDs1LjAgKFdpbmRvd3MgTlQgMTAuMDsgV2luNjQ7IHg2NCkgQXBwbGVXZWJLaXQvNTM3LjM2IChLSFRNTCwgbGlrZSBHZWNrbykgQ2hyb21lLzEwNS4wLjAuMCBTYWZhcmkvNTM3LjM2JnF1b3Q7IGV0YWc9JnF1b3Q7cXdxYjgtbUN3VVVBLWZEek1VY2MmcXVvdDsgdmVyc2lvbj0mcXVvdDsyMC4yLjcmcXVvdDsgdHlwZT0mcXVvdDtlbWJlZCZxdW90OyZndDsmbHQ7ZGlhZ3JhbSBpZD0mcXVvdDt3YnlTV3F0Y00xUDFURmNkVTF6MyZxdW90OyBuYW1lPSZxdW90O+esrCAxIOmhtSZxdW90OyZndDtkZEhORW9JZ0VBRGdwK0d1VUZabnM3cDA4dENaa1UyWVFaZEJHcTJuVHdmTUdPdkU4dTNDOGtOWTNneG55NDI4b2dCTmFDSUd3bzZFMG5TYjBIR1k1T2tsMnpBUHRWVWlGQzFRcWhjRVRJSStsSUF1S25TSTJpa1RZNFZ0QzVXTGpGdUxmVngyUngxM05ieUdGWlFWMTJ1OUtlR2sxejNkTFg0QlZjdTVjNW9kZktiaGMzRzRTU2U1d1A2TFdFRlliaEdkajVvaEJ6MDkzdnd1ZnQzcFQvWnpNQXV0KzdGZ0RKYTl4MG4wUTZ4NEF3PT0mbHQ7L2RpYWdyYW0mZ3Q7Jmx0Oy9teGZpbGUmZ3Q7Ij48ZGVmcy8+PGcvPjwvc3ZnPg==",
        id: i,
      });
      this.$nextTick(() => {
        this.edit_draw(i);
      });
    },
  },
};
</script>

<style lang="scss">
.r2 {
  cursor: not-allowed !important;
  // pointer-events:
  // pointer-events:none !important;
}
.con {
  .el-carousel__container {
    height: 100%;
  }
}
</style>
<style lang="scss" scoped>
.f {
  display: flex;
  align-items: center;
}
.block_title {
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  margin: 0;
  // margin-right: 25px;
}
.limitHeight {
  height: 406px !important; /*no*/
}
.con {
  width: 95%;
  height: 500px;
}
.box {
  width: 100%;
  height: 500px;
  border: 1px solid rgba(207, 207, 207, 1);
  border-radius: 6px 6px 6px 6px;

  .tool {
    img {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }

    .svgimg {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }

    .p {
      font-size: 16px;
      color: rgba(128, 128, 128, 1);
    }

    .line {
      height: 100%;
      width: 1px;
      background-color: rgba(204, 204, 204, 1);
    }

    height: 40px;
    width: 100%;
    border-bottom: 1px solid rgba(204, 204, 204, 1);
    background-color: rgba(230, 230, 230, 1);
    display: grid;
    grid-template-columns: 15% 1px 8% 1px 8% 1px 8% 1px 8% 1px auto;
    align-items: center;
    justify-items: center;
  }

  .img {
    width: 100%;
    height: 450px;
    padding: 10px;
    display: flex;
    justify-content: center;
    // align-items: center;
    overflow: auto;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
    .limitImgHeight {
      max-height: 90%;
    }
  }
}
.svgimg1 {
  width: 25px;
  height: 25px;
  // margin-top: -4px;
  cursor: pointer;
}
</style>

<style>
.viewer .el-image-viewer__canvas img {
  background-color: rgb(255, 255, 255);
  padding: 30px;
}
</style>
