<template>
  <div
    v-loading="loading"
    class="my-main"
    :element-loading-text="$t('loading.loading1')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <!-- 左侧筛选行 -->
    <HomeMyfilehead
      v-if="statusListFinished"
      :status-list="statusList"
      :key-sort="keysort"
      :sort-by="sortby"
    >
    </HomeMyfilehead>
    <!--批量操作行  -->
    <el-row :gutter="10" type="flex" justify="end" style="text-align: right">
      <el-col :span="3">
        <div>
          <!-- 批量添加至基线 -->
          <span v-limits-of-authority="'DATUM_LINE_CREATE_EDIT_COPY'">
            <button
              v-if="selectingMultiple"
              :disabled="
                limits_of_authority_disable('DATUM_LINE_CREATE_EDIT_COPY')
              "
              class="word_button link-font-color"
              type="submit"
              @click="addToBaseLineMultiple"
            >
              <i class="iconfont icon-file_type" style="font-size: 13px" />
              {{ $t("file.table.options.addToBaseLine") }}
            </button>
          </span>
        </div>
      </el-col>
      <el-col :span="2">
        <div>
          <!-- 批量移动 -->
          <span v-limits-of-authority="'FILE_DELETE'">
            <button
              v-if="selectingMultiple"
              :disabled="limits_of_authority_disable('FILE_DELETE')"
              class="word_button link-font-color"
              type="submit"
              @click="moveMultiple"
            >
              <i class="iconfont icon-move" style="font-size: 10px" />
              {{ $t("file.table.options.move") }}
            </button>
          </span>
        </div>
      </el-col>
      <el-col :span="2">
        <!-- 批量删除 -->
        <span v-limits-of-authority="'FILE_DELETE'">
          <button
            v-if="selectingMultiple"
            :disabled="limits_of_authority_disable('FILE_DELETE')"
            class="word_button link-font-color"
            type="submit"
            @click="removeMultiple"
          >
            <i class="iconfont icon-temp-delete" style="font-size: 12px"> </i
            >{{ $t("btn.deleteBtn") }}
          </button>
        </span>
      </el-col>
      <!-- 批量操作/退出批量操作 -->
      <el-col :span="selectingMultiple ? 2 : 3" style="text-align: right">
        <button
          class="word_button link-font-color"
          type="button"
          @click="selectMultipleClicked"
        >
          <i
            :class="{ 'iconfont icon-edit': !selectingMultiple }"
            style="font-size: 12px"
          >
            <i
              v-if="selectingMultiple"
              class="iconfont icon-exit"
              style="margin-right: 5px; font-size: 14px"
            ></i>
          </i>
          {{ selectingMultiple ? $t("btn.exitBtn") : $t("btn.batchBtn") }}
        </button>
      </el-col>
    </el-row>
    <!-- 文件列表 -->
    <div @mouseover="closeExport">
      <el-table
        ref="homeTable"
        v-loading="isDownloadFinished"
        border
        height="77vh"
        :data="tableData"
        class="clickable-rows"
        style="width: 100%; background: white"
        :cell-style="changeCellStyle"
        :header-cell-style="{
          color: '#383838',
          'font-weight': '700',
        }"
        @selection-change="handleSelectionChange"
        @header-dragend="HeaderDragend"
      >
        <el-table-column v-if="selectingMultiple" type="selection">
          <!--如点击了批量操作，勾选框会显示/隐藏-->
        </el-table-column>
        <el-table-column
          v-for="col in dropCol"
          :key="col.id"
          resizable
          :prop="col.prop"
          :min-width="col.minWidth"
          show-overflow-tooltip
          :label="col.label"
        >
          <!--如点击了批量操作，勾选框会显示/隐藏-->
          <template v-if="col.prop === 'file_key'" slot="header">
            <div class="sortable-column-header" @click="filekey_sort">
              Key
              <div class="caret-wrapper">
                <i
                  :class="{
                    'el-icon-caret-top': true,
                    'el-icon-caret-colored':
                      sortby == 'fileKey' && desc == false,
                  }"
                ></i>
                <i
                  :class="{
                    'el-icon-caret-bottom': true,
                    'el-icon-caret-colored':
                      sortby == 'fileKey' && desc == true,
                  }"
                ></i>
              </div>
            </div>
          </template>
          <template v-if="col.prop === 'createdBy'" slot="header">
            <div class="sortable-column-header" @click="createdBy_sort">
              {{ $t("file.table.creator") }}
              <div class="caret-wrapper">
                <i
                  :class="{
                    'el-icon-caret-top': true,
                    'el-icon-caret-colored':
                      sortby == 'createdBy' && desc == false,
                  }"
                ></i>
                <i
                  :class="{
                    'el-icon-caret-bottom': true,
                    'el-icon-caret-colored':
                      sortby == 'createdBy' && desc == true,
                  }"
                ></i>
              </div>
            </div>
          </template>
          <template v-if="col.prop === 'update_date'" slot="header">
            <div class="sortable-column-header" @click="updatetime_sort">
              {{ $t("file.table.lastUpdated") }}
              <div class="caret-wrapper">
                <i
                  :class="{
                    'el-icon-caret-top': true,
                    'el-icon-caret-colored':
                      sortby == 'updatedAt' && desc == false,
                  }"
                ></i>
                <i
                  :class="{
                    'el-icon-caret-bottom': true,
                    'el-icon-caret-colored':
                      sortby == 'updatedAt' && desc == true,
                  }"
                ></i>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <span v-if="col.prop === 'file_key'">
              <div style="cursor: pointer" class="hover-class">
                <div v-limits-of-authority="'FILE_READ'">
                  <a
                    style="color: rgb(255, 195, 0)"
                    :href="'/' + get_pid() + '/myMind/' + scope.row.file_key"
                    >{{ scope.row[col.prop] }}</a
                  >
                </div>
              </div>
            </span>
            <div v-else-if="col.prop === 'file_name'" style="width: 99%">
              <div
                v-if="flag != scope.row.file_key"
                onselectstart="return false;"
                style="cursor: pointer"
                class="hover-class"
                @dblclick="dbClick($event, scope.row)"
              >
                <div
                  v-limits-of-authority="'FILE_READ'"
                  style="overflow: hidden; text-overflow: ellipsis"
                >
                  <a
                    style="color: rgb(99, 99, 99)"
                    :href="'/' + get_pid() + '/myMind/' + scope.row.file_key"
                    >{{ scope.row[col.prop] }}</a
                  >
                </div>
              </div>
              <input
                v-if="flag == scope.row.file_key"
                ref="input_panel"
                v-model="input_name"
                type="text"
                @keyup.enter="away"
                @blur="away"
              />
            </div>
            <span v-else-if="col.prop === 'status'">
              <status-tag
                v-model="scope.row.status"
                :style_catogry="'plain'"
                :use="false"
                :status_type="scope.row.statusCategory"
                :size="'small'"
              ></status-tag>
            </span>
            <span
              v-else-if="col.label === $t('file.dialog.option')"
              style="cursor: pointer"
            >
              <el-dropdown trigger="hover">
                <span class="el-dropdown-link" style="color: #84aace">
                  <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                  &nbsp;&nbsp;
                  <i
                    class="iconfont icon-a-216gengduo-shuxiang"
                    style="color: rgb(48, 100, 143)"
                  />
                  &nbsp;&nbsp;
                </span>
                <el-dropdown-menu slot="dropdown">
                  <!-- <div>
                    <div v-limits-of-authority="'NODE_EDIT'" class="operate_button"
                         @click="showCollaborationDialog(scope.row)">
                      <i>
                        <svg-icon icon-class="collaboration" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </i>
                      <span>{{ $t('file.table.options.collaboration') }}</span>
                    </div>
                  </div> -->
                  <div>
                    <div
                      v-limits-of-authority="'NODE_CREATE'"
                      class="operate_button"
                      @click="cloneOnly(scope.row)"
                      @mouseover="closeExport"
                    >
                      <i>
                        <i
                          class="iconfont icon-a-Frame1"
                        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </i>
                      <span>{{ $t("file.table.options.paste") }}</span>
                    </div>
                  </div>

                  <div>
                    <div class="operate_button" @mouseover="closeExport">
                      <i
                        v-if="!scope.row.isCollected"
                        @click="addCollection(scope.row)"
                      >
                        <i
                          class="iconfont icon-a-Frame2"
                        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </i>
                      <i v-else @click="addCollection(scope.row)">
                        <i
                          class="iconfont icon-shoucangClick"
                          style="
                            color: rgb(255, 195, 0);
                            cursor: pointer;
                            font-size: 12px;
                          "
                        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </i>

                      <span
                        v-if="scope.row.isCollected"
                        @click="addCollection(scope.row)"
                        >{{ $t("file.table.options.nonCollect") }}</span
                      >
                      <span v-else @click="addCollection(scope.row)">{{
                        $t("file.table.options.collect")
                      }}</span>
                    </div>
                  </div>
                  <div>
                    <div class="operate_button" @click="archiveFile(scope.row)">
                      <i>
                        <i
                          style="font-size: 12px"
                          class="iconfont icon-lingcunwei"
                        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </i>
                      <span>{{  $t("baseline.archive.title1"), }}</span>
                    </div>
                  </div>
                  <!-- 导出 -->
                  <div>
                    <div class="operate_button_1" @mouseover="showExport">
                      <i>
                        <i
                          class="iconfont icon-a-huaban41"
                        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </i>
                      <span>{{ $t("file.table.options.export") }}</span>
                      <div v-show="isShowExport" class="operate_button_load">
                        <div v-limits-of-authority="'FILE_DOWNLOAD'">
                          <i
                            class="el-icon-folder"
                            props="2"
                            @click="exportExcel(scope.row, false)"
                            >&nbsp;&nbsp; excel</i
                          >
                        </div>
                        <div v-limits-of-authority="'FILE_DOWNLOAD'">
                          <i
                            class="el-icon-folder"
                            props="3"
                            @click="exportWord(scope.row)"
                            >&nbsp;&nbsp; word</i
                          >
                        </div>
                        <div v-limits-of-authority="'FILE_DOWNLOAD'">
                          <i
                            class="el-icon-folder"
                            props="3"
                            @click="exportExcel(scope.row, true)"
                            >&nbsp;&nbsp; csv&nbsp;&nbsp;&nbsp;&nbsp;</i
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      v-limits-of-authority="'FILE_DELETE'"
                      class="operate_button"
                      @click="moveFileIntoProject(scope.row)"
                      @mouseover="closeExport"
                    >
                      <i>
                        <i
                          class="iconfont icon-a-huaban31"
                        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i
                      >
                      <span>{{ $t("file.table.options.move") }}</span>
                    </div>
                  </div>
                  <div>
                    <div
                      v-limits-of-authority="'FILE_DELETE'"
                      class="operate_button"
                      @click="DeleteOnly(scope.row)"
                      @mouseover="closeExport"
                    >
                      <i>
                        <i
                          class="iconfont icon-a-huaban51"
                        />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i
                      >
                      <span>{{ $t("file.table.options.delete") }}</span>
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
            <span v-else>
              {{ scope.row[col.prop] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <div v-if="!isShoucangChecked" class="my-page">
      <el-pagination
        :current-page="currentPage"
        background
        :page-sizes="[8, 10]"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="total_element"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <div v-else class="my-page">
      <el-pagination
        :current-page="currentShoucangPage"
        background
        :page-sizes="[8, 10]"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
        :total="totalShoucangElement"
        @current-change="handleCurrentShoucangChange"
      >
      </el-pagination>
    </div>
    <!-- 权限弹窗 -->
    <HomeMyfilepermission
      :visible.sync="FilePermissionVisible"
      :clickrow="permissionrow"
    ></HomeMyfilepermission>
    <!--协作弹窗  -->
    <collaboration-dialog
      :visible.sync="collabDialogVisible"
    ></collaboration-dialog>
    <!-- 移动文件弹窗 -->
    <move-file-dialog ref="movedialog"></move-file-dialog>
    <!-- 添加至基线 -->
    <AddToBaseLineDialog ref="addbasedialog"></AddToBaseLineDialog>
    <!-- 导出excel弹窗 -->
    <el-dialog
      :title="
        isCsv
          ? $t('file.table.options.dialog.title4')
          : $t('file.table.options.dialog.title')
      "
      width="25%"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
      :before-close="handleClose"
      custom-class="excel_export_dialog"
    >
      <div
        v-if="!isCsv"
        style="
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 20px;
        "
      >
        <div
          style="
            margin-right: 20px;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            color: gray;
          "
        >
          {{ $t("btn.export_tem") }}
        </div>
        <el-select
          v-model="template"
          :placeholder="$t('placeholder.dropdown')"
          clearable
        >
          <el-option
            v-for="item in tem_options"
            :key="item.templateId"
            :label="item.name"
            :value="item.templateId"
          >
          </el-option>
        </el-select>
      </div>
      <el-checkbox v-model="all_fields_item" @change="judge_all">{{
        $t("file.table.options.dialog.box1")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box2")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box3")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box4")
      }}</el-checkbox>
      <el-checkbox-group
        v-model="submit_list"
        text-color=" rgba(66, 159, 255, 1)"
      >
        <el-checkbox
          v-for="(item, index) in checkList"
          :key="index"
          :label="item.fieldId"
          >{{ item.label }}</el-checkbox
        >
      </el-checkbox-group>
      <span slot="footer">
        <el-button @click="dialogVisible = false">{{
          $t("file.table.options.dialog.box5")
        }}</el-button>
        <el-button type="primary" @click="submit_Excel">{{
          $t("file.table.options.dialog.box6")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('file.table.options.dialog.title3')"
      width="25%"
      :visible="worddialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
      custom-class="excel_export_dialog"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        "
      >
        <div
          style="
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            color: gray;
          "
        >
          {{ $t("btn.export_tem") }}
        </div>
        <el-select
          v-model="template_word"
          :placeholder="$t('placeholder.dropdown')"
          style="width: 80%"
          clearable
        >
          <el-option
            v-for="item in tem_options_word"
            :key="item.templateId"
            :label="item.name"
            :value="item.templateId"
          >
          </el-option>
        </el-select>
      </div>

      <span slot="footer">
        <el-button @click="worddialogVisible = false">{{
          $t("file.table.options.dialog.box5")
        }}</el-button>
        <el-button type="primary" @click="handle_word">{{
          $t("file.table.options.dialog.box6")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('baseline.archive.title1')"
      :visible.sync="nodeVersionDialog"
      width="25%"
    >
      <el-form
        ref="nodeVersionform"
        :model="nodeVersionForm"
        label-width="140px"
        label-position="left"
      >
        <el-form-item
          :label="$t('node.version.versionName')"
          prop="version"
          :rules="{
            required: true,
            trigger: 'blur',
            message: $t('placeholder.notNull'),
          }"
        >
          <el-input v-model="nodeVersionForm.version"></el-input>
        </el-form-item>
        <el-form-item :label="$t('baseline.archive.remark')" prop="desc">
          <el-input v-model="nodeVersionForm.desc" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="nodeVersionDialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          :loading="saveVersionBtnLoading"
          @click="saveVersion"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
  <!--  -->
</template>
<script>
import Sortable from "sortablejs";
import CollaborationDialog from "@/components/collaborationDialog";
import vmson from "@/utils/vmson";
import StatusTag from "@/components/statusTag";
import MoveFileDialog from "@/components/moveFileDialog";

import HomeMyfilehead from "./components/homeMmyfile_head";
import { get_templates } from "@/network/template/index.js";
import AddToBaseLineDialog from "./components/addToBaseLineDialog.vue";
import Vue from "vue";
import HomeMyfilepermission from "./components/homemyfilePermission";
import "@/components/svgIcon";
import {
  get_file_List,
  getFile,
  refileName,
  removeFile,
  refileCopy,
  batchDel,
  get_filetype,
} from "@/network/home/index.js";
import { limits_of_authority_disable } from "@/utils/limits";
import {
  addIntoCollection,
  deleteCollection,
  getUserCollections,
} from "@/network/collections/index.js";
import { get_allreadyuse_status_list } from "@/network/workflow/index.js";
import { all_fields_get, getSystemFields } from "@/network/fileType/index.js";
import { mapGetters } from "vuex";
import { activated } from "@/network/user/index.js";
import { fileArchive } from "@/network/nodeVersion";
import {
  get_excel_field,
  post_excel_fieldSelect,
  exportWordFile,
  exportCsvFile,
} from "@/network/exportExcel/index.js";
import { downloadLink } from "@/utils/download.js";
export default {
  inject: ["reload"],
  name: "MyFile",
  components: {
    CollaborationDialog,
    HomeMyfilehead,
    HomeMyfilepermission,
    StatusTag,
    MoveFileDialog,
    AddToBaseLineDialog,
  },
  props: ["value"],
  //传递方法给子组件
  provide() {
    return {
      get_filesList: this.get_filesList,
      currentPage: this.currentPage,
    };
  },
  data() {
    return {
      fileMoveVisible: false,
      statusSort: false,
      statusListFinished: false,
      statusList: [],
      currentShoucangPage: 0,
      totalShoucangElement: 0,
      isShoucangChecked: false,
      fileTypes: [],
      isDownloadFinished: false,
      flag: "", //控制输入框的显示
      input_name: "", //输入框的文件类型新名称
      condition: [], //列表显示条件
      fuzzyKey: "", //模糊搜索词
      loading: false,
      isOnlyOne: true,
      currentPage: 1,
      total_element: 0,
      isrename: false, //是否在重命名  用于区分是文件名还是输入框
      rename: "", //绑定的重命名的输入框
      originname: "", //原来的名字
      clickrow: "", //点击的那一行  重命名的时候使用的
      FilePermissionVisible: false, //文件权限弹窗
      permissionrow: {}, //传给文件权限弹窗点击的哪一行
      tableData: [], //文件列表
      selectingMultiple: false,
      selected: [], //批量操作用的
      activeIndex: "/home/my_file",
      template: "",
      worddialogVisible: false,
      word_row: "",
      template_word: "",
      tem_options: [],
      tem_options_word: [],
      collabDialogVisible: false, //协作弹窗
      keysort: true,
      createdBysort: false,
      updatetimesort: false,
      sortby: "fileKey", //默认文件Key排序
      desc: true, //默认降序
      time: null, //解决单双击问题冲突
      dropCol: [
        {
          id: this.randomString(10),
          prop: "file_type",
          width: 150,
          label: this.$t("file.table.type"),
          minWidth: 150,
        },
        {
          id: this.randomString(10),
          prop: "file_key",
          width: 150,
          label: "Key",
          minWidth: 150,
        },
        {
          id: this.randomString(10),
          prop: "file_name",
          width: 362,
          label: this.$t("file.table.name"),
          minWidth: 302,
        },
        {
          id: this.randomString(10),
          prop: "status",
          width: 150,
          label: this.$t("file.table.status"),
          minWidth: 150,
        },
        {
          id: this.randomString(10),
          prop: "createdBy",
          width: 150,
          label: this.$t("file.table.creator"),
          minWidth: 180,
        },
        {
          id: this.randomString(10),
          prop: "update_date",
          width: 200,
          label: this.$t("file.table.lastUpdated"),
          minWidth: 180,
        },
        {
          id: this.randomString(10),
          prop: "",
          minWidth: 80,
          width: 80,
          label: this.$t("file.dialog.option"),
        },
      ],
      must_check: true,
      dialogVisible: false,
      all_fields_item: "",
      checkList: [],
      submit_list: [],
      excel_dialog_fields: {},
      systemFields_list: [],
      allFields_list: [],
      exportExcel_data: {},
      isShowExport: false,
      isCsv: false,
      // 文件存档相关
      tempRow: {},
      nodeVersionDialog: false,
      saveVersionBtnLoading: false,
      nodeVersionForm: {
        version: "",
        desc: "",
      },
    };
  },
  computed: {
    ...mapGetters(["user_list", "fileType"]),
    collapse() {
      return this.$store.getters.collapse;
    },
  },
  watch: {
    async "$route.params.projectId"(newValue) {
      let flag = 0;
      this.$store.getters.t_joined_projects.forEach((e) => {
        if (e.projectId == newValue) {
          flag++;
        }
      });
      if (flag > 0) {
        this.loading = true;
        this.fuzzyKey = "";
        this.get_filesList(0, 20);
        this.statusList = await get_allreadyuse_status_list(newValue);
        //项目切换后，确定批量移动功能为关闭状态
        this.$nextTick(() => {
          this.selectingMultiple = false;
        });
      }
    },
  },
  mounted() {
    get_templates().then((res) => {
      this.tem_options = [
        ...res.filter((obj) => obj.exportTemplateType === "EXCEL"),
      ];

      this.tem_options_word = [
        ...res.filter((obj) => obj.exportTemplateType != "EXCEL"),
      ];
      this.template = "";
      this.template_word = "";
    });
    activated().then((res) => {});
    if (localStorage.getItem(`dropCol:file:${this.get_pid()}`)) {
      const cols = JSON.parse(
        localStorage.getItem(`dropCol:file:${this.get_pid()}`)
      );
      cols.forEach((oldCol) => {
        this.dropCol.forEach((newCol) => {
          if (oldCol.prop === newCol.prop) {
            Object.assign(oldCol, {
              label: newCol.label,
            });
          }
          if (!oldCol.prop && !newCol.prop) {
            Object.assign(oldCol, {
              label: newCol.label,
            });
          }
        });
      });
      this.dropCol = cols;
    }
    this.columnDrop();
    vmson.$on("homemyfile_update", () => {
      this.loading = true;
      this.fuzzyKey = "";
      this.get_filesList(0, 20); //替换的
    });
    vmson.$on("condition_update", (res, search_box) => {
      this.loading = true;
      this.condition = res;
      this.fuzzyKey = search_box;
      this.get_filesList(0, 20); //替换的
    });
    vmson.$on("condition_update_data", (res) => {
      this.loading = true;
      this.condition = res;
      this.fuzzyKey = "";
      this.get_filesList(0, 20); //替换的
    });
    vmson.$on("clear_condition", (res) => {
      this.loading = true;
      this.condition = res;
      this.fuzzyKey = "";
    });
    vmson.$on("fuzzy_search", (res) => {
      this.loading = true;
      this.fuzzyKey = res;
      this.get_filesList(0, 20);
    });
    vmson.$on("getUserCollection", async (isShoucangChecked) => {
      this.isShoucangChecked = isShoucangChecked;
      if (isShoucangChecked) {
        await this.getCollectionData(0);
      } else {
        this.get_filesList(0, 20);
      }
    });
  },
  async created() {
    this.loading = true;
    if (this.get_pid() != "") {
      let flag = 0;
      this.$store.getters.t_joined_projects.forEach((e) => {
        if (e.projectId == this.get_pid()) {
          flag++;
        }
      });
      if (flag > 0) {
        const statusList = await get_allreadyuse_status_list(this.get_pid());
        this.statusList = statusList;
        this.statusListFinished = true;
        if (!(this.$route.query.conditions || this.$route.query.fieldId)) {
          this.get_filesList(this.currentPage - 1, 20); //替换的
        }
      }
    }
  },
  methods: {
    // 文件存档
    archiveFile(row) {
      this.nodeVersionDialog = true;
      this.nodeVersionForm = {
        version: "",
        desc: "",
      };
      this.tempRow = row;
    },
    saveVersion() {
      this.$refs["nodeVersionform"].validate(async (valid) => {
        if (valid) {
          this.saveVersionBtnLoading = true;
          const params = {
            projectId: this.get_pid(),
            fileKey: this.tempRow.file_key,
            data: {
              archiveName: this.nodeVersionForm.version,
              remark: this.nodeVersionForm.desc,
            },
          };
          fileArchive(params)
            .then((res) => {
              this.saveVersionBtnLoading = false;
              this.nodeVersionDialog = false;
              this.$message({
                type: "success",
                message: this.$t("homeTopBar.asyncTask.message"),
              });
            })
            .catch((err) => {
              this.saveVersionBtnLoading = false;
            });
          // this.getImgUrl();
        } else {
          return false;
        }
      });
    },
    handle_word() {
      exportWordFile(
        this.get_pid(),
        this.word_row.file_key,
        this.template_word
      ).then((res) => {
        this.template_word = "";
        this.worddialogVisible = false;
        this.$message({
          showClose: true,
          message: this.$t("homeTopBar.asyncTask.message"),
          type: "success",
          duration: 5000,
        });
      });
    },
    exportWord(row) {
      this.worddialogVisible = true;
      this.word_row = row;
    },
    HeaderDragend(newWidth, oldWidth, column, event) {
      this.dropCol.forEach((item) => {
        if (item.label == column.label) {
          item.minWidth = newWidth;
          item.width = newWidth;
          localStorage.setItem(
            `dropCol:file:${this.get_pid()}`,
            JSON.stringify(this.dropCol)
          );
        }
      });
    },
    randomString(len) {
      len = len || 32;
      const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      let maxPos = chars.length;
      let pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    columnDrop() {
      const _this = this;
      const wrapperTr = document.querySelector(".el-table__header-wrapper tr");

      this.sortable = Sortable.create(wrapperTr, {
        onEnd: (evt) => {
          // 跳过显示的列数量，如开头我们用了一个多选框
          // const oldItem = _this.dropCol[evt.oldIndex];
          // const oldLabel = _this.dropCol[evt.oldIndex].label;
          // _this.dropCol[evt.oldIndex].label =  _this.dropCol[evt.newIndex].label;
          // _this.dropCol[evt.newIndex].label = oldLabel;
          // _this.dropCol.splice(evt.oldIndex, 1);
          // _this.dropCol.splice(evt.newIndex, 0, oldItem);

          // 可拖拽
          const old = _this.dropCol[evt.oldIndex];
          const newE = _this.dropCol[evt.newIndex];
          newE.id = this.randomString(10);
          old.id = this.randomString(10);
          const swapArrData = (index1, index2, arr) => {
            //index1,index2代表需要交换的数组索引
            const temp = arr.splice(index2, 1, arr[index1]);
            arr[index1] = temp[0];
            return arr;
          };
          if (
            evt.oldIndex == this.dropCol.length - 1 ||
            evt.newIndex == this.dropCol.length - 1
          ) {
            this.$message({
              type: "warning",
              message: this.$t("file.table.message1"),
              duration: "5000",
            });
            return;
          } else {
            swapArrData(evt.oldIndex, evt.newIndex, this.dropCol);
            localStorage.setItem(
              `dropCol:file:${this.get_pid()}`,
              JSON.stringify(this.dropCol)
            );
          }
        },
      });
    },
    async moveFileIntoProject(item) {
      this.$refs.movedialog.open([item]);
    },
    async handleCurrentShoucangChange(pageNumber) {
      await this.getCollectionData(pageNumber - 1);
    },
    async getCollectionData(pageNumber) {
      this.tableData = [];
      this.loading = true;
      const fileTypes = await get_filetype(this.get_pid());
      this.fileTypes = fileTypes;
      const collections = await getUserCollections("file", pageNumber, 20);
      this.currentShoucangPage = 0;
      this.totalShoucangElement = collections.total;
      if (collections.data) {
        collections.data = collections.data.filter(
          (collection) => collection.projectId === this.get_pid()
        );
        collections.data.forEach((collection) => {
          const obj = {};
          obj.file_type = this.getFileTypeName(collection.fileTypeId);
          obj.file_name = collection.name;
          obj.file_key = collection.key;
          obj.createdBy = this.getUserName(collection.createdBy);
          const date = new Date(collection.updatedAt).Format(
            "yyyy-MM-dd hh:mm:ss"
          );
          obj.update_date = date;
          obj.file_type_id = collection.fileTypeId;
          obj.isCollected = true;
          obj.status = this.getStatusName(collection.status);
          obj.statusCategory = this.getStatusCategory(collection.status);
          this.tableData.push(obj);
        });
      }
      this.loading = false;
    },
    getStatusCategory(id) {
      const status = this.statusList.filter(
        (status) => status.statusId === id
      )[0];
      return status ? status.statusCategory : "";
    },
    getStatusName(id) {
      const status = this.statusList.filter(
        (status) => status.statusId === id
      )[0];
      return status ? status.name : "";
    },
    getUserName(id) {
      if (id === "1000") return this.$t("placeholder.open");
      return this.matchUserNickname(id, "");
    },
    getFileTypeName(fileTypeId) {
      const file = this.fileTypes.filter(
        (item) => item.fileTypeId === fileTypeId
      )[0];
      return file ? file.name : "";
    },
    addCollection(row) {
      const data = [{ value: row["file_key"], type: "file" }];
      if (row.isCollected) {
        deleteCollection(this.get_pid(), data);
      } else {
        addIntoCollection(this.get_pid(), data);
      }
      row.isCollected = !row.isCollected;
    },
    status_sort() {
      this.statusSort = !this.statusSort; //向下为true  向上为false
      this.desc = this.statusSort;
      this.sortby = "status";
      this.get_filesList(this.currentPage - 1, 20); //替换的
    },
    limits_of_authority_disable,
    exportExcel(row, isCsv) {
      this.exportExcel_data = row;
      this.isCsv = isCsv;
      Promise.all([
        get_excel_field(this.$route.params.projectId, row["file_type_id"]),
        getSystemFields(),
        all_fields_get(this.$route.params.projectId),
      ]).then(
        (res) => {
          // 需展示字段
          this.excel_dialog_fields = res[0] || {};
          // 所有系统字段
          this.systemFields_list = res[1] || [];
          // 所有自定义字段
          this.allFields_list = res[2] || [];

          // 筛选系统字段
          this.systemFields_list.forEach((item) => {
            if (
              this.excel_dialog_fields.systemFieldsIdList.indexOf(
                item.fieldId
              ) != -1
            ) {
              this.checkList.push({ label: item.name, fieldId: item.fieldId });
            }
          });

          // 筛选自定义字段
          this.allFields_list.forEach((item) => {
            if (
              this.excel_dialog_fields.costomFieldsIdList.indexOf(
                item.fieldId
              ) != -1
            ) {
              this.checkList.push({ label: item.name, fieldId: item.fieldId });
            }
          });

          this.dialogVisible = true;
        },
        (error) => {}
      );
    },
    submit_Excel() {
      // this.isDownloadFinished = true;
      let post_data = {
        systemFieldsIdList: [],
        costomFieldsIdList: [],
        exportTemplateId: this.template,
      };
      this.systemFields_list.forEach((item) => {
        if (this.submit_list.indexOf(item.fieldId) != -1) {
          post_data.systemFieldsIdList.push(item.fieldId);
        }
      });
      this.allFields_list.forEach((item) => {
        if (this.submit_list.indexOf(item.fieldId) != -1) {
          post_data.costomFieldsIdList.push(item.fieldId);
        }
      });
      if (this.isCsv) {
        let params = {
          projectId: this.get_pid(),
          fileKey: this.exportExcel_data["file_key"],
          data: {
            systemFieldsIdList: post_data.systemFieldsIdList,
            costomFieldsIdList: post_data.costomFieldsIdList,
            exportTemplateId: "",
          },
        };
        exportCsvFile(params).then((res) => {
          this.template = "";
          this.isDownloadFinished = false;
          this.dialogVisible = false;
          this.checkList = [];
          this.submit_list = [];
          this.all_fields_item = false;
          this.$message({
            showClose: true,
            message: this.$t("homeTopBar.asyncTask.message"),
            type: "success",
            duration: 5000,
          });
        });
      } else {
        post_excel_fieldSelect(
          this.get_pid(),
          this.exportExcel_data["file_key"],
          post_data
        ).then(async (res) => {
          this.template = "";
          this.isDownloadFinished = false;
          this.dialogVisible = false;
          this.checkList = [];
          this.submit_list = [];
          this.all_fields_item = false;
          this.$message({
            showClose: true,
            message: this.$t("homeTopBar.asyncTask.message"),
            type: "success",
            duration: 5000,
          });
          // await ossAuthorization.call(this);
          // this.client.delete(res);
        });
      }
    },
    away(e) {
      let cur_key = this.flag;
      let value = e.target.value;
      this.flag = "";
      if (value == "") {
        this.flag = "";
      } else if (this.flag_name == value) {
        return;
      } else if (cur_key != "") {
        refileName(this.get_pid(), cur_key, { name: value }).then((res) => {
          this.get_filesList(this.currentPage - 1, 20);
          this.flag = "";
          this.$message({
            type: "success",
            message: this.$t("canvas.messages.renameSuccess"),
          });
          this.input_name = "";
        });
      }
    },
    dbClick(e, row) {
      e.preventDefault();
      clearTimeout(this.time);
      // 触发dbclick事件,传入sonmsg值
      this.edit(row);
    },
    edit(row) {
      this.flag_name = row.file_name;
      this.input_name = row.file_name;
      this.flag = row.file_key;
      setTimeout(() => {
        this.$refs.input_panel.focus();
      }, 220);
    },
    get_filesList(pageNumber, pageSize) {
      //获取文件列表
      this.loading = true;
      get_file_List(
        this.desc,
        pageNumber,
        pageSize,
        this.get_pid(),
        this.sortby,
        this.condition,
        this.fuzzyKey
      ).then(async (res) => {
        const fileTypes = await get_filetype(this.get_pid());
        this.fileTypes = fileTypes;
        const userCollections = await getUserCollections("file");
        const collectionKeyList = [];
        if (userCollections.data) {
          userCollections.data.forEach((collection) => {
            collectionKeyList.push(collection.key);
          });
        }
        this.tableData = [];
        this.currentPage = pageNumber + 1;
        this.total_element = res.totalElements;
        if (res.totalElements <= pageSize) {
          this.isOnlyOne = true;
        }
        for (let i = 0; i < res.content.length; i++) {
          let update_date = "";
          if (res.content[i].updatedAt != null) {
            update_date = res.content[i].updatedAt;
            update_date = [
              res.content[i].updatedAt.slice(0, 10), //
              res.content[i].updatedAt.slice(11, 19), //
            ].join(" ");
          }
          let filetype = "";
          filetype = this.getFileTypeName(res.content[i].fileTypeId);
          let filename = "";
          if (res.content[i].name != null) {
            filename = res.content[i].name;
          }
          let filekey = "";
          if (res.content[i].key != null) {
            filekey = res.content[i].key;
          }
          let createBy = "";
          createBy = this.matchUserNickname(res.content[i].createdBy, "");
          let obj = {
            update_date: update_date,
            file_type: filetype,
            file_name: filename,
            file_key: res.content[i].key,
            file_id: res.content[i].id,
            file_type_id: res.content[i].fileTypeId,
            createdBy: createBy,
            status: this.getStatusName(res.content[i].status),
            statusCategory: this.getStatusCategory(res.content[i].status),
            isCollected: false,
          };
          if (
            collectionKeyList.length > 0 &&
            collectionKeyList.indexOf(res.content[i].key) !== -1
          ) {
            obj.isCollected = true;
          }
          this.tableData.push(obj);
        }

        this.loading = false;
      });
    },

    filekey_sort() {
      //文件key排序
      this.keysort = !this.keysort; //向下为true  向上为false
      this.desc = this.keysort;
      this.sortby = "fileKey";
      this.get_filesList(this.currentPage - 1, 20); //替换的
    },
    createdBy_sort() {
      //创建人排序
      this.createdBysort = !this.createdBysort; //向下为true  向上为false
      this.desc = this.createdBysort;
      this.sortby = "createdBy";
      this.get_filesList(this.currentPage - 1, 20); //替换的
    },
    updatetime_sort() {
      this.updatetimesort = !this.updatetimesort;
      this.desc = this.updatetimesort;
      this.sortby = "updatedAt";
      this.get_filesList(this.currentPage - 1, 20); //替换的
    },
    //
    handleCurrentChange(val) {
      this.currentPage = val;
      //分页器那里
      this.loading = true;
      this.get_filesList(val - 1, 20); //替换的
    },

    edit_file_permission(row) {
      //打开权限弹窗
      this.permissionrow = row;
      this.FilePermissionVisible = true;
    },

    changeCellStyle(row, column, rowIndex, columnIndex) {
      if (row.column.label === "Key") {
        return "color: #FFC300;font-weight: 700;"; // 修改的样式
      } else {
        return "color:#636363;font-weight: 700;";
      }
    },
    nodeClick(row) {
      let that = this;
      // 开启延时器，300ms的间隔区分单击和双击，解决双击时执行两次单击事件
      clearTimeout(that.time);
      that.time = setTimeout(() => {
        this.rowClicked(row);
      }, 300);
    },
    rowClicked(row) {
      // 点击某行对应的文件后打开该文件 row, column, cell, event
      getFile(this.get_pid(), row.file_key)
        .then((res) => {
          if (res !== undefined) {
            this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
            this.$store.commit("SET_FILE_ID", row.file_id);
            this.$store.commit("SET_FILE_KEY", row.file_key);
            //this.$store.commit("SET_MAP_PERMISSION", "owner");
            this.$store.commit("SET_MINDMAP_NAME", row.file_name);
            this.$store.commit("SET_OPEN_STATE", "exist");
            this.$store.commit("SET_FILETYPE_ID", row.file_type_id);
            //跳转到思维导图
            const { href } = this.$router.resolve({
              // name: "myMind",
              path: `/${this.get_pid()}/myMind/${row.file_key}`,
              // params: {
              //   file_key: row.file_key,
              //   row
              // }
            });
            window.open(href, "_blank");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectMultipleClicked() {
      //显示/隐藏 批量操作勾选框
      this.selectingMultiple = !this.selectingMultiple;
      if (this.selectingMultiple) {
        this.dropCol[2].width = 322;
      } else {
        this.dropCol[2].width = 362;
      }
    },

    handleSelectionChange(selection) {
      //批量操作选择
      this.selected = selection;
    },

    async addToBaseLineMultiple() {
      if (this.selected.length > 0) {
        this.$refs.addbasedialog.init(this.selected);
      }
    },

    async moveMultiple() {
      if (this.selected.length > 0) {
        // moveFileIntoProject(this.get_pid(), row.file_key).then((res) => {
        //   if (this.currentPage === 1) {
        //     this.get_filesList(0, 20);
        //   }
        //   if (this.tableData.length > 1) {
        //     this.get_filesList(this.currentPage - 1, 20);
        //   } else {
        //     this.get_filesList(this.currentPage - 2, 20);
        //   }
        // })

        // 获取选中的项目
        const fileKeys = this.selected.map((item) => {
          return item.file_type;
        });

        // 判断文件类型是否相同
        const sameType = fileKeys.every((item) => {
          return fileKeys[0] == item;
        });

        if (sameType) {
          // 相同 允许移动
          // 传递了一个文件数组
          this.$refs.movedialog.open(this.selected);
        } else {
          // 不同 弹出警告
          this.$message({
            type: "warning",
            message: this.$t("canvas.messages.moveWarn"),
          });
        }
      }
    },

    removeMultiple() {
      //批量删除
      if (this.selected.length > 0) {
        this.$confirm(
          this.$t("file.table.options.deleteForm.desc"),
          this.$t("file.table.options.deleteForm.title"),
          {
            confirmButtonText: this.$t("btn.confirmBtn"),
            confirmButtonClass: "delete_button_class",
            cancelButtonText: this.$t("btn.cancelBtn"),
            cancelButtonClass: "delete_cancel_class",
            type: "warning",
          }
        )
          .then(() => {
            //通知后端从数据库中移到回收站/标为被回收
            const fileKeys = this.selected.map((item) => {
              return item.file_key;
            });
            const params = {
              projectId: this.get_pid(),
              fileKeys: fileKeys,
            };
            batchDel(params)
              .then((res) => {
                if (res.failedFileKeyList.length) {
                  this.$message({
                    type: "warning",
                    dangerouslyUseHTMLString: true,
                    message: this.$t("canvas.messages.delSuccess1"),
                  });
                } else {
                  this.$message({
                    type: "success",
                    message: this.$t("canvas.messages.delSuccess"),
                  });
                }
                this.get_filesList(this.currentPage - 1, 20);
              })
              .catch((err) => {
                this.$message({
                  type: "info",
                  message: this.$t("canvas.messages.delError"),
                });
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.$t("canvas.messages.delCancel"),
            });
          });
      }
    },

    cloneMultiple() {
      //   //通知后端从数据库中克隆并更新table
      //   if (this.selected.length > 0) {
      //     if (this.postRemoveOrClone(2) === 1) {
      //       this.$message({
      //         type: "success",
      //         message: "复制成功",
      //       });
      //     } else {
      //       this.$message({
      //         type: "info",
      //         message: "复制失败",
      //       });
      //     }
      //   }
    },

    async DeleteOnly(row) {
      //单个删除
      this.$confirm(
        this.$t("file.table.options.deleteForm.desc"),
        this.$t("file.table.options.deleteForm.title"),
        {
          confirmButtonText: this.$t("btn.confirmBtn"),
          confirmButtonClass: "delete_button_class",
          cancelButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "delete_cancel_class",
          type: "warning",
        }
      )
        .then(() => {
          removeFile(this.get_pid(), row.file_key).then((res) => {
            if (this.currentPage === 1) {
              this.get_filesList(0, 20);
            } else if (this.tableData.length > 1) {
              this.get_filesList(this.currentPage - 1, 20);
            } else {
              this.get_filesList(this.currentPage - 2, 20);
            }
          }); //删除文件
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("canvas.messages.deleteInfo"),
          });
        });
    },
    async cloneOnly(row) {
      //单个复制
      this.loading = true;
      try {
        let res = await refileCopy(this.get_pid(), row.file_key);
      } catch (error) {
        this.loading = false;
      }
      this.$message({
        type: "success",
        message: this.$t("file.table.options.message"),
      });
      const tableContainer = this.$refs.homeTable.$el.querySelector(
        ".el-table__body-wrapper"
      );
      tableContainer.scrollTop = 0;
      // setTimeout(() => {
      this.get_filesList(this.currentPage - 1, 20);
      // }, 100);
    },

    showCollaborationDialog(row) {
      this.collabDialogVisible = true;
    },

    //
    //重命名
    Rename(row) {
      this.rename = row.file_name;
      this.originname = row.file_name;
      this.clickrow = row.file_key;
      this.isrename = true;

      this.$nextTick(function () {
        this.$refs.inputname.focus();
      });
    },

    //重命名失去焦点
    async handleBlur() {
      this.isrename = false;
      if (this.originname == this.rename) return;
      this.loading = true;
      try {
        let res = await refileName(this.get_pid(), this.clickrow, {
          name: this.rename,
        });
      } catch (error) {
        this.loading = false;
        return;
      }
      this.$message({
        type: "success",
        message: this.$t("canvas.messages.renameSuccess"),
      });
      this.get_filesList(this.currentPage - 1, 20);
    },

    handleClose() {
      this.dialogVisible = false;
      this.submit_list = [];
      this.checkList = [];
      this.all_fields_item = false;
    },
    judge_all(val) {
      if (val) {
        // 点击全选
        this.checkList.forEach((item) => {
          this.submit_list.push(item.fieldId);
        });
      } else {
        this.submit_list = [];
      }
    },
    showExport() {
      this.isShowExport = true;
    },
    closeExport() {
      this.isShowExport = false;
    },
  },
};
</script>

<style scoped lang="scss">
.op {
  text-align: left;

  span {
    margin-right: 20px;
  }
}

.move-main {
  border: 1px solid rgba(229, 229, 229, 1);
  margin-bottom: 15px;

  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
  }

  &__header {
    padding: 10px 20px;
  }

  &__text {
    padding: 15px;
    background: rgba(240, 242, 245, 1);
  }

  &__label {
    padding: 15px 20px;
    border-bottom: 1px solid rgba(229, 229, 229, 1);
  }

  .item-list {
    max-height: 300px;
    overflow: auto;
    padding: 10px 0;

    .move-main__item {
      padding: 5px 25px;
      column-gap: 20px;
    }
  }
}

.excel_export_dialog {
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: hidden scroll;
  }

  .el-checkbox {
    padding-bottom: 16px;
    display: flex;
    color: rgba(128, 128, 128, 1);

    .el-checkbox__label {
      font-size: 16px;
      font-weight: 400;
      // color: rgba(56, 56, 56, 1);
      text-align: left;
    }
  }
}
</style>

<style scoped>
.sortable-column-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.caret-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.el-icon-caret-top {
  transform: translate(0, 3px);
}

.el-icon-caret-bottom {
  transform: translate(0, -3px);
}

.el-icon-caret-colored {
  color: #068bff;
}

.clickable-rows {
  /* cursor: default; */
  width: 100%;
  height: 77vh;
  overflow: hidden;
  background: white;
}

.clickable-rows::before {
  height: 0px;
}

.my-main >>> .el-table {
  margin-top: -10px;
}

/* 三个点 */
.word_button {
  height: 30px;
  border: 0;
  background: transparent;
  margin-bottom: 10px;
  white-space: nowrap;
  cursor: pointer;
  color: rgba(1, 76, 170, 1);
  font-family: "思源黑体";
}

.word_button:hover {
  color: #0074b7;
}

.operate_button {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 15px 20px;
  text-align: center;
  cursor: pointer;
  color: #656667;
  position: relative;
}

.operate_button:hover {
  color: #46b4f4;
}

.operate_button_1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 15px 20px;
  text-align: center;
  cursor: pointer;
  color: #656667;
  position: relative;
  background: #fcfeff;
}

.operate_button_1 div div:nth-child(n):hover {
  color: #46b4f4;
}

.operate_button_load {
  position: absolute;
  padding-top: 10px;
  padding-bottom: 10px;
  left: -120px;
  box-shadow: -1px 4px 8px rgba(0, 0, 0, 0.1);
  top: 0px;
  width: 120px;
  /* display: none; */
  background: #fcfeff;
}

/* .operate_button_1:hover .operate_button_load {
  display: block;
} */

.operate_button_load div {
  line-height: 30px;
}

.inputname {
  font-size: 15px;

  border-radius: 4px;
  border: 1px solid #c8cccf;
  color: #6a6f77;
  outline: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 0 6px rgba(0, 0, 0, 0.04);
}

.my-page {
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -40px;
}

.permission_dialog {
  height: 6vh;
}

/deep/ .el-loading-mask {
  pointer-events: none;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #568cb8;
}

/deep/ .el-input__inner:focus {
  border-color: #568cb8;
}

/*  */
/deep/ .el-input__inner:focus {
  border-color: #568cb8;
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}

/* /deep/ .el-table__body-wrapper {
  overflow-x: hidden !important;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 70vh;
} */

/deep/ .el-table .el-table__cell.gutter {
  background: #f0f2f5 !important;
}

/deep/ .movedialog .el-dialog__body {
  padding: 0 30px;
}

.my-main {
  position: relative;
}

.i {
  cursor: pointer;
}
</style>
