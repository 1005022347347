<template>
  <el-collapse
    v-model="activeNames"
    class="scroll_wrap"
    style=""
    @change="handleChange"
  >
    <el-collapse-item ref="bug-overview" class="collapse_item" name="1">
      <div slot="title" class="collapse-title">
        {{ $t("test.checked_options.bug") }}({{ bug_number
        }}{{ $t("addNum2") }})
      </div>
      <bug-overview
        :cur_test_id="cur_test_id"
        :is_edit="is_edit"
      ></bug-overview>
    </el-collapse-item>
    <el-collapse-item ref="test-caseview" class="collapse_item" name="2">
      <div slot="title" class="collapse-title">
        {{ $t("test.checked_options.tesecase") }}({{ test_case_number
        }}{{ $t("addNum2") }})
      </div>
      <test-caseview
        :cur_test_id="cur_test_id"
        :is_edit="is_edit"
      ></test-caseview>
    </el-collapse-item>
    <el-collapse-item ref="coverage-report" class="collapse_item" name="4">
      <div slot="title" class="collapse-title">
        {{ $t("test.checked_options.coverage") }}
      </div>
      <div style="width: 100%" class="collapse-content no-change-color">
        <el-table
          v-loading="node_list_loading"
          :border="true"
          :data="nodes_cover_merge_list"
          :header-cell-style="{
            color: '#383838',
            fontWeight: 'blod',
            fontSize: '16px',
            backgroundColor: 'transparent',
          }"
          :cell-style="set_cell_style"
          :span-method="arraySpanMethod"
        >
          <el-table-column
            :label="$t('test.checked_options.node')"
            align="left"
            header-align="left"
          >
            <template slot-scope="scope">
              <div style="display: flex">
                <div>
                  <el-tooltip
                    v-ak-tooltip-auto-show
                    effect="light"
                    placement="top-end"
                    :content="scope.row.testNode ? scope.row.testNode.key : ''"
                  >
                    <span
                      class="nodeKey"
                      style="
                        cursor: pointer;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-weight: bold;
                      "
                      @click="route_to_node_details(scope.row)"
                      >{{
                        scope.row.testNode ? scope.row.testNode.key : ""
                      }}</span
                    >
                  </el-tooltip>
                  <div style="display: inline-block">
                    <div
                      v-if="scope.row.testNode"
                      v-limits-of-authority="'FILE_READ'"
                      style="display: inline-block"
                    >
                      <i
                        style="
                          font-size: 12px;
                          margin-left: 16px;
                          cursor: pointer;
                          color: rgb(48, 100, 143);
                        "
                        class="iconfont icon-node_link"
                        @click="route_to_node(scope.row)"
                      ></i>
                    </div>
                  </div>
                </div>

                <el-tooltip
                  v-ak-tooltip-auto-show
                  class="item"
                  effect="dark"
                  placement="top-start"
                >
                  <div slot="content">
                    {{ scope.row.testNode ? scope.row.testNode.topic : "" }}
                  </div>
                  <span
                    style="text-align: center; cursor: pointer"
                    :class="exporting ? 'not_cover_topic' : 'cover-topic'"
                    @click="show_drawer(scope.row.testNode.key)"
                    >{{
                      scope.row.testNode ? scope.row.testNode.topic : ""
                    }}</span
                  >
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('test.checked_options.testcase')"
            align="left"
            header-align="left"
          >
            <template slot-scope="scope">
              <div style="display: flex">
                <span
                  v-limits-of-authority="'NODE_DETAIL_READ'"
                  class="topic"
                  style="cursor: pointer; display: inline_block"
                  @click="route_to_node_details(scope.row)"
                  >{{ scope.row.nodeKey }}</span
                >
                <div v-if="scope.row.nodeKey" style="display: inline-block">
                  <div
                    v-limits-of-authority="'FILE_READ'"
                    style="display: inline-block"
                  >
                    <i
                      style="
                        font-size: 12px;
                        margin-left: 16px;
                        cursor: pointer;
                        color: rgb(48, 100, 143);
                      "
                      class="iconfont icon-node_link"
                      @click="route_to_node(scope.row)"
                    ></i>
                  </div>
                </div>
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <div slot="content">
                    {{ scope.row.topic }}
                  </div>
                  <span
                    style="text-align: center; cursor: pointer"
                    :class="exporting ? 'not_cover_topic' : 'cover-topic'"
                    @click="show_drawer(scope.row.nodeKey)"
                    >{{ scope.row.topic }}</span
                  >
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('test.nodeTable.status')"
            align="left"
            header-align="left"
            width="100"
          >
            <template slot-scope="scope" style="overflow: auto">
              <div
                style="
                  display: flex;
                  justify-content: left;
                  height: 23px;
                  margin-bottom: 5px;
                "
              >
                <el-tooltip
                  effect="dark"
                  placement="top"
                  v-ak-tooltip-auto-show
                >
                  <div slot="content">
                    {{
                      scope.row.testCaseStatus &&
                      execution_status[scope.row.testCaseStatus]
                        ? execution_status[scope.row.testCaseStatus].statusName
                        : ""
                    }}
                  </div>
                  <div
                    class="excecute_status"
                    :style="
                      'background-color:' +
                      (scope.row.testCaseStatus &&
                      execution_status[scope.row.testCaseStatus]
                        ? execution_status[scope.row.testCaseStatus].color
                        : '')
                    "
                  >
                    <span style="word-break: keep-all !important">
                      {{
                        scope.row.testCaseStatus &&
                        execution_status[scope.row.testCaseStatus]
                          ? execution_status[scope.row.testCaseStatus]
                              .statusName
                          : ""
                      }}
                    </span>
                  </div>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('test.nodeTable.bug')"
            align="left"
            header-align="left"
            width="200"
          >
            <template v-if="!scope.row.nobugs" slot-scope="scope">
              <div
                style="
                  display: flex;
                  justify-content: left;
                  align-items: left;
                  padding: 5px;
                  align-items: baseline;
                "
              >
                <el-tooltip
                  v-ak-tooltip-auto-show
                  effect="dark"
                  placement="top"
                  :content="scope.row.bugNodeKey"
                >
                  <div
                    v-limits-of-authority="'NODE_DETAIL_READ'"
                    class="bug_node_font"
                    style="font-weight: 600; cursor: pointer; margin-right: 4px"
                    @click="
                      route_to_node_details({
                        nodeKey: scope.row.bugNodeKey,
                      })
                    "
                  >
                    {{ scope.row.bugNodeKey }}
                  </div>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  placement="top"
                  v-ak-tooltip-auto-show
                >
                  <div slot="content">
                    {{ status[scope.row.bugStatus].name }}
                  </div>
                  <div
                    :class="{
                      status: true,
                      status_todo:
                        status[scope.row.bugStatus].statusCategory == 'TODO',
                      status_progress:
                        status[scope.row.bugStatus].statusCategory ==
                        'IN_PROGRESS',
                      status_open:
                        status[scope.row.bugStatus].statusCategory == 'DONE',
                    }"
                  >
                    <span style="white-space: nowrap">{{
                      status[scope.row.bugStatus].name
                    }}</span>
                  </div>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-collapse-item>
    <el-collapse-item ref="test-result" class="collapse_item" name="5">
      <div slot="title" class="collapse-title">
        {{ $t("test.checked_options.result") }}
      </div>
      <div class="preLine" style="width: 100%">
        <el-table
          v-loading="node_list_loading1"
          :data="nodes_list"
          :header-cell-style="{
            color: '#383838',
            fontWeight: 'blod',
            fontSize: '16px',
            backgroundColor: 'transparent',
          }"
          :cell-style="set_cell_style"
        >
          <el-table-column
            :label="$t('test.testCaseID')"
            :width="exporting ? 'auto' : '150'"
            align="left"
            header-align="left"
          >
            <template slot-scope="scope">
              <div style="display: flex; align-items: center">
                <el-tooltip
                  v-ak-tooltip-auto-show
                  effect="light"
                  placement="top-end"
                  :content="scope.row.nodeKey"
                >
                  <span
                    v-limits-of-authority="'NODE_DETAIL_READ'"
                    class="nodeKey"
                    style="
                      cursor: pointer;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      font-weight: bold;
                    "
                    @click="route_to_node_details(scope.row)"
                    >{{ scope.row.nodeKey }}</span
                  >
                </el-tooltip>
                <div style="display: inline-block">
                  <div
                    v-limits-of-authority="'FILE_READ'"
                    style="display: inline-block"
                  >
                    <i
                      style="
                        font-size: 12px;
                        margin-left: 16px;
                        cursor: pointer;
                        color: rgb(48, 100, 143);
                      "
                      class="iconfont icon-node_link"
                      @click="route_to_node(scope.row)"
                    ></i>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="topic"
            :label="$t('test.nodeTable.title')"
            :min-width="exporting ? 'auto' : '300'"
            align="left"
            header-align="left"
            :show-overflow-tooltip="exporting ? false : true"
          >
            <template slot-scope="scope">
              <span
                style="cursor: pointer"
                class="topic"
                @click="show_drawer(scope.row.nodeKey)"
                >{{ scope.row.topic }}</span
              ></template
            >
          </el-table-column>
          <el-table-column
            :label="$t('test.nodeTable.result')"
            :min-width="exporting ? 'auto' : '300'"
            :show-overflow-tooltip="exporting ? false : true"
            prop="testResult"
            align="left"
            header-align="left"
          >
          </el-table-column>
          <el-table-column
            :label="$t('test.attachments')"
            :min-width="exporting ? 'auto' : '200'"
            align="left"
            header-align="left"
          >
            <template slot-scope="scope">
              <file-list
                ref="fileList"
                :files="scope.row.attachments"
                :deletebtn="false"
                :testplan-id="'0123'"
              ></file-list>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('test.nodeTable.status')"
            :min-width="exporting ? 'auto' : '120'"
            align="left"
            header-align="left"
          >
            <template slot-scope="scope">
              <div style="display: flex; justify-content: left">
                <el-tooltip
                  effect="dark"
                  placement="top"
                  v-ak-tooltip-auto-show
                >
                  <div slot="content" style="width: 200px">
                    {{
                      scope.row.testCaseStatus &&
                      execution_status[scope.row.testCaseStatus]
                        ? execution_status[scope.row.testCaseStatus].statusName
                        : ""
                    }}
                  </div>
                  <div
                    class="excecute_status"
                    :style="
                      'background-color:' +
                      (scope.row.testCaseStatus &&
                      execution_status[scope.row.testCaseStatus]
                        ? execution_status[scope.row.testCaseStatus].color
                        : '')
                    "
                  >
                    <span style="word-break: keep-all !important">{{
                      scope.row.testCaseStatus &&
                      execution_status[scope.row.testCaseStatus]
                        ? execution_status[scope.row.testCaseStatus].statusName
                        : ""
                    }}</span>
                  </div>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('test.nodeTable.bug')"
            :min-width="exporting ? 'auto' : '200'"
            align="left"
            header-align="left"
          >
            <template slot-scope="scope">
              <div
                v-for="item in scope.row.bugNodes"
                :key="item.nodeKey"
                style="
                  display: flex;
                  justify-content: left;
                  align-items: center;
                  padding: 5px;
                "
              >
                <div
                  v-limits-of-authority="'NODE_DETAIL_READ'"
                  class="bug_node_font"
                  style="font-weight: 600; cursor: pointer; margin-right: 4px"
                  @click="route_to_node_details(item)"
                >
                  {{ item.nodeKey }}
                </div>
                <el-tooltip
                  effect="dark"
                  placement="top"
                  v-ak-tooltip-auto-show
                >
                  <div slot="content">{{ status[item.status].name }}</div>
                  <div
                    v-if="scope.row.test_result != $t('test.notExecuted')"
                    :class="{
                      status: true,
                      status_todo: status[item.status].statusCategory == 'TODO',
                      status_progress:
                        status[item.status].statusCategory == 'IN_PROGRESS',
                      status_open: status[item.status].statusCategory == 'DONE',
                    }"
                  >
                    <span style="white-space: nowrap">{{
                      status[item.status].name
                    }}</span>
                  </div>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('test.nodeTable.executor')"
            :min-width="exporting ? 'auto' : '140'"
            align="left"
            header-align="left"
          >
            <template slot-scope="scope">
              <span class="topic">{{
                matchUserNickname(scope.row.executor)
              }}</span></template
            >
          </el-table-column>
        </el-table>
      </div>
    </el-collapse-item>
    <el-collapse-item ref="bug-detail" class="collapse_item" name="6">
      <div slot="title" class="collapse-title">
        {{ $t("test.checked_options.detailBug") }}
      </div>
      <div style="width: 100%">
        <el-table
          v-loading="node_list_loading1"
          :data="bug_list"
          :header-cell-style="{
            color: '#383838',
            fontWeight: 'blod',
            fontSize: '16px',
            backgroundColor: 'transparent',
          }"
          :cell-style="set_cell_style"
        >
          <el-table-column
            label="ID"
            width="150"
            align="left"
            header-align="left"
          >
            <template slot-scope="scope">
              <div style="display: flex; align-items: center">
                <el-tooltip
                  v-ak-tooltip-auto-show
                  effect="light"
                  placement="top-end"
                  :content="scope.row.nodeKey"
                >
                  <span
                    v-limits-of-authority="'NODE_DETAIL_READ'"
                    class="nodeKey"
                    style="
                      cursor: pointer;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      font-weight: bold;
                    "
                    @click="route_to_node_details(scope.row)"
                    >{{ scope.row.nodeKey }}</span
                  >
                </el-tooltip>
                <div style="display: inline-block">
                  <div
                    v-limits-of-authority="'FILE_READ'"
                    style="display: inline-block"
                  >
                    <i
                      style="
                        font-size: 12px;
                        margin-left: 16px;
                        cursor: pointer;
                        color: rgb(48, 100, 143);
                      "
                      class="iconfont icon-node_link"
                      @click="route_to_node(scope.row)"
                    ></i>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="topic"
            :label="$t('test.nodeTable.title')"
            align="left"
            min-width="400"
            header-align="left"
            :show-overflow-tooltip="exporting ? false : true"
          >
            <template slot-scope="scope">
              <span
                class="topic"
                style="cursor: pointer"
                @click="show_drawer(scope.row.nodeKey)"
                >{{ scope.row.topic }}</span
              ></template
            >
          </el-table-column>
          <el-table-column
            :label="$t('test.nodeTable.assign')"
            show-overflow-tooltip
            prop="assigneeName"
            align="left"
            header-align="left"
          >
          </el-table-column>
          <el-table-column
            :label="$t('test.nodeTable.status1')"
            align="left"
            min-width="100"
            header-align="left"
          >
            <template slot-scope="scope">
              <status-tag
                v-model="status[scope.row.status].name"
                :style_catogry="'plain'"
                :status_type="status[scope.row.status].statusCategory"
                :size="'small'"
              >
              </status-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-collapse-item>
    <el-collapse-item
      v-if="hasEoLink && test_report_visible"
      ref="api-test-result"
      class="collapse_item"
      name="7"
    >
      <div slot="title" class="collapse-title">
        {{ $t("test.checked_options.api") }}
      </div>
      <test-report
        :cur_test_id="cur_test_id"
        @hide_test_report="hide_test_report"
      ></test-report>
    </el-collapse-item>
    <el-collapse-item
      v-if="rowData != null && hasCorax && hasCoraxAvailable"
      ref="corax-report"
      class="collapse_item"
      name="8"
    >
      <div slot="title" class="collapse-title">
        {{ $t("corax.StaticCodeScanReport") }}
      </div>
      <CoraxReport :row-data="rowData" />
    </el-collapse-item>
  </el-collapse>
</template>
<script>
import { test_coverage, get_test_nodes } from "@/network/test/index.js";
import { getFile } from "@/network/home/index.js";
import { get_file } from "@/network/baseline/index.js";
import bugOverview from "./components/bugOverview.vue";
import testCaseview from "./components/testCaseview.vue";
import testReport from "./components/testReport.vue";
import CoraxReport from "@/components/coraxReport";
import StatusTag from "@/components/statusTag";
import { mapGetters } from "vuex";
import FileList from "@/components/mindmap/node_detail/FileList";
import { boolean } from "is-type-of";

export default {
  components: {
    bugOverview,
    testCaseview,
    testReport,
    StatusTag,
    FileList,
    CoraxReport,
  },
  props: {
    cur_test_id: {
      type: String,
      default: "",
    },
    is_edit: {
      type: Boolean,
      default: false,
    },
    rowData: {
      type: Object,
      default() {
        return null;
      },
    },
    hasCorax: {
      type: Boolean,
      default: false,
    },
    update_node: {
      type: Boolean,
      default: false,
    },
    exporting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeNames: ["1", "2", "3", "4", "5", "6", "7", "8"],
      //覆盖报告节点
      nodes_cover_list: [],
      // 最终可合并的表格
      nodes_cover_merge_list: [],
      //测试详情节点
      nodes_list: [],
      bug_list: [],
      node_list_loading: false,
      node_list_loading1: false,
      test_report_visible: true,
    };
  },
  computed: {
    ...mapGetters(["execution_status", "status"]),
    test_case_number() {
      return this.nodes_list.length;
    },
    bug_number() {
      let number = 0;
      const map = {};
      this.nodes_cover_list.forEach((item) => {
        item.testCaseList.forEach((testcase) => {
          testcase.bugNodes.forEach((bug) => {
            if (!map[bug.nodeKey]) {
              number++;
              map[bug.nodeKey] = 1;
            }
          });
        });
      });
      return number;
    },
    hasEoLink() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name.toLowerCase() === "eolink"
      );
    },
    hasCoraxAvailable() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name.toLowerCase() === "corax"
      );
    },
  },
  watch: {
    cur_test_id() {
      this.node_list_loading = true;
      this.node_list_loading1 = true;
      this.test_coverage();
      this.get_test_nodes();
    },
    update_node(newVal) {
      if (!newVal) {
        this.node_list_loading = true;
        this.node_list_loading1 = true;
        this.test_coverage();
        this.get_test_nodes();
      }
    },
  },
  mounted() {
    this.test_coverage();
    this.get_test_nodes();
  },
  methods: {
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if ("nodeMergeSpan" in row && columnIndex === 0) {
        return [row.nodeMergeSpan, 1];
      } else if (!("nodeMergeSpan" in row) && columnIndex === 0) {
        return [0, 0];
      } else if (
        row.testCaseMergeSpan &&
        (columnIndex === 1 || columnIndex === 2)
      ) {
        return [row.testCaseMergeSpan, 1];
      } else if (
        !row.testCaseMergeSpan &&
        (columnIndex === 1 || columnIndex === 2)
      ) {
        return [0, 0];
      } else {
        return [1, 1];
      }
    },
    show_drawer(row) {
      this.$emit("show_drawer", row);
    },
    hide_test_report() {
      this.test_report_visible = false;
      this.$emit("hide_test_report");
    },
    // 点击节点key跳转到节点详情
    route_to_node_details(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${row.nodeKey || row.testNode.key}`,
      });
      window.open(href, "_blank");
    },
    // 点击节点key旁的图标跳转到节点在图中的位置
    route_to_node(row) {
      get_file(this.get_pid(), row.fileId || row.testNode.fileId).then(
        (result) => {
          getFile(this.get_pid(), result.key)
            .then((res) => {
              this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
              this.$store.commit("SET_FILE_ID", res.fileId);
              this.$store.commit("SET_FILE_KEY", res.fileKey);
              this.$store.commit("SET_MINDMAP_NAME", res.fileName);
              this.$store.commit("SET_OPEN_STATE", "exist");
              this.$store.commit("SET_FILETYPE_ID", res.fileTypeId);
              //跳转到思维导图
              const { href } = this.$router.resolve({
                path: `/${this.get_pid()}/myMind/${result.key}`,
                query: {
                  node_id: row.id || row.testNode.id, // 节点id
                },
              });
              window.open(href, "_blank");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      );
    },
    test_coverage() {
      test_coverage(this.get_pid(), this.cur_test_id).then((res) => {
        this.nodes_cover_list = res.filter((item) => {
          return !(!item.testCaseList.length && !item.testNode);
        });
        this.nodes_cover_merge_list = [];
        this.nodes_cover_list.forEach((item, nodeIndex) => {
          if (!item.testCaseList.length) {
            this.nodes_cover_merge_list.push(
              Object.assign(
                {},
                {
                  testNode: item.testNode,
                  nobugs: true,
                  nodeIndex: nodeIndex,
                  testCaseMergeSpan: 1,
                }
              )
            );
          }
          item.testCaseList.forEach((testCase, testCaseIndex) => {
            if (!testCase.bugNodes.length) {
              this.nodes_cover_merge_list.push(
                Object.assign({}, testCase, {
                  testNode: item.testNode,
                  nobugs: true,
                  nodeIndex: nodeIndex,
                  testCaseMergeSpan: 1,
                })
              );
            } else {
              testCase.bugNodes.forEach((bug, bugIndex) => {
                let testCaseMergeSpan = 0;
                if (!bugIndex) {
                  testCaseMergeSpan = testCase.bugNodes.length;
                }
                this.nodes_cover_merge_list.push(
                  Object.assign({}, testCase, {
                    bugAssignee: bug.assignee,
                    bugCreatedBy: bug.createdBy,
                    bugFileId: bug.fileId,
                    bugNodeKey: bug.nodeKey,
                    bugStatus: bug.status,
                    bugTopic: bug.topic,
                    testNode: item.testNode,
                    nobugs: false,
                    nodeIndex: nodeIndex,
                    testCaseMergeSpan: testCaseMergeSpan,
                  })
                );
              });
            }
          });
        });
        // 判断合并行数
        let setIndex = 0;
        this.nodes_cover_list.forEach((item) => {
          let nodeMergeSpan = 0;
          if (!item.testCaseList.length) {
            nodeMergeSpan += 1;
          }
          item.testCaseList.forEach((testCase) => {
            if (testCase.bugNodes.length) {
              nodeMergeSpan += testCase.bugNodes.length;
            } else {
              nodeMergeSpan += 1;
            }
          });
          this.nodes_cover_merge_list[setIndex].nodeMergeSpan = nodeMergeSpan;
          setIndex += nodeMergeSpan;
        });
        this.node_list_loading = false;
      });
    },
    get_test_nodes() {
      get_test_nodes(this.get_pid(), this.cur_test_id).then((res) => {
        this.bug_list = [];
        const map = {};
        res.forEach((item) => {
          if (item.bugNodes && item.bugNodes.length) {
            item.bugNodes.forEach((bug) => {
              const obj = Object.assign({}, bug);
              obj.assigneeName = this.matchUserNickname(obj.assignee, "");
              if (!map[bug.nodeKey]) {
                this.bug_list.push(obj);
                map[bug.nodeKey] = 1;
              }
            });
          }
        });
        this.nodes_list = res;
        this.node_list_loading1 = false;
      });
    },
    set_cell_style() {},
    handleChange(val) {},
  },
};
</script>
<style lang="scss" scoped>
// 测试结果换行
.preLine {
  ::v-deep .el-table tr td:nth-child(3) .cell {
    white-space: pre-line;
  }
}
.not_cover_topic {
  flex: 1;
  display: inline-block;
  padding-left: 15px;
  text-align: left !important;
  color: #636363;
  font-size: 14px;
  font-weight: 600;
  vertical-align: middle;
}
.status {
  margin-left: 5px;
  width: 90px;
  height: 25px;
  border-radius: 4px;
  // color: #fff;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.status_todo {
  border: 1px solid rgba(166, 166, 166, 1);
  color: rgba(166, 166, 166, 1);
}
.status_open {
  color: rgba(67, 207, 124, 1);
  border: 1px solid rgba(67, 207, 124, 1);
}
.status_progress {
  color: rgba(42, 130, 228, 1);
  border: 1px solid rgba(42, 130, 228, 1);
}
</style>
<style scoped>
.tooltip_span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.collapse_item {
  margin-bottom: 10px;

  border-radius: 8px;
}
.collapse-title {
  display: flex;
  align-items: center;

  height: 27px;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 500;
  left: 40px;
  order: 1;
}

.el-collapse-item__header {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  order: -1;
  background: rgba(229, 229, 229, 1);
  border-radius: 8px !important;
}
/deep/ .el-collapse-item__arrow {
  width: 20px;
  height: 20px;
  background: rgba(229, 229, 229, 1);
  margin-left: 5px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/ .el-collapse-item__header {
  border-radius: 8px;
}
.nodeKey {
  color: rgba(255, 195, 0, 1);
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}
.topic {
  color: #636363;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.bug_node_font {
  color: #636363;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
<style lang="scss" scoped>
.scroll_wrap {
  overflow-y: hidden;
  padding-top: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
}
.excecute_status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  // height: 25px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  padding: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 25px;
  line-height: 25px;
  // align-items: center;
  // justify-content: center;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.no-change-color {
  ::v-deep .el-table tbody tr:hover > td {
    background-color: #fff !important;
  }
}
::v-deep .collapse-content {
  .el-table__row {
    td:first-child {
      .cell {
        span:first-child {
          min-width: 60px;
        }
        span:nth-child(2) {
        }
        .cover-topic {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex: 1;
          display: inline-block;
          padding-left: 15px;
          text-align: left !important;
          color: #636363;
          font-size: 14px;
          font-weight: 600;
          vertical-align: middle;
        }
      }
    }
    td:nth-child(2) {
      .cell {
        display: flex;
        flex-direction: column;
        > div {
          display: flex;
          align-items: center;
          span:first-child {
            min-width: 60px;
          }
          span:nth-child(2) {
          }
          .cover-topic {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1;
            display: block;
            padding-left: 15px;
            text-align: left !important;
            color: #636363;
            font-size: 14px;
            font-weight: 600;
            cursor: auto;
          }
        }
      }
    }
    td:nth-child(3) {
    }
  }
}
</style>
