<template>
  <div ref="jsAtEditorElement" class="jsAtEditorElement">
    <div
      ref="jsEditorElement"
      contenteditable="true"
      :data-content="$t('nodeDetail.activity.comment.placeholder')"
      class="jsEditorElement"
      @focus="onFocus"
      @keyup="onInputText"
      @keydown="onInputKeyDown"
      @blur="closeDialog"
      @mouseup="doToggleDialog"
      @paste="doOnPaste"
      @compositionstart="onCompositionStart"
      @compositionend="onCompositionEnd"
    ></div>
    <div
      ref="jsDialogElement"
      v-loading="dialogLoading"
      :class="['atDialogClass']"
      :style="[
        atDialogPos,
        { visibility: isShowDialog ? 'visible' : 'hidden' },
      ]"
      @mousedown="doNotBlur"
    >
      <div v-if="lists.length == 0" class="persons-dialog-nothing">
        <!-- <img src="@/assets/img/nothing.png" alt="" /> -->
        <span>{{ $t("nodeDetail.activity.comment.place") }}</span>
      </div>
      <el-scrollbar v-else ref="scrollbarRef" style="height: 250px">
        <ul class="persons-dialog-lists">
          <li
            v-for="(item, index) in lists"
            v-show="!item.deleted"
            :key="item.accountId"
            :class="{ person_item: true, 'active-hover': activeIndex == index }"
            @mousedown="selectPerson(item)"
          >
            <personal-avatar
              :avatar="item.avatar"
              :colorid="item.accountId"
              :nickname="item.name"
              :size="20"
              :isProjectDropdown="true"
              style="margin-right: 5px"
            ></personal-avatar>
            <span>{{ item.nickname }}</span>
          </li>
        </ul>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { get_allProjectMember } from "@/network/mindmap/index.js";
import PersonalAvatar from "@/components/personal";
import { position } from "caret-pos";
import { ossAuthorization } from "@/utils/oss";
import { pinyin } from "pinyin-pro";
export default {
  name: "At",
  components: {
    PersonalAvatar,
  },
  props: {
    placeholderText: {
      type: String,
      default() {
        return this.$t("addAt.default");
      },
    },
  },
  data() {
    return {
      // 是否显示选人弹窗
      isShowDialog: false,
      // 用户列表
      lists: [],
      // 当前选中的人
      activeIndex: 0,
      preventKeyUp: false,
      // 光标位置信息
      editorRange: null,
      // 选人弹窗应该出现的位置
      atDialogPos: {
        top: "0px",
        left: "0px",
      },
      // 选人弹窗loading
      dialogLoading: false,
      // 是否正在输入中文
      isInputChinese: false,
    };
  },
  computed: {},
  watch: {
    activeIndex: function () {
      this.$refs["scrollbarRef"].wrap.scrollTop = (this.activeIndex - 5) * 40;
    },
  },
  methods: {
    // 输入框聚焦
    onFocus() {
      this.$emit("focus");
    },
    // 键盘按下
    onInputKeyDown(e) {
      if (this.isShowDialog && !this.isInputChinese) {
        if (e.code == "ArrowDown" || e.code === "ArrowUp") {
          preventAfterAction.call(this, e);
          // 上下移动光标，用于调整 dialog 里的人
          if (e.code == "ArrowDown") {
            this.activeIndex++;
            if (this.activeIndex === this.lists.length) {
              this.activeIndex = 0;
            }
          }
          if (e.code == "ArrowUp") {
            this.activeIndex--;
            if (this.activeIndex === -1) {
              this.activeIndex = this.lists.length - 1;
            }
          }
        } else if (e.code === "Enter") {
          preventAfterAction.call(this, e);
          // 如果有弹窗，则代表确认选人
          this.isShowDialog = false;
          this.selectPerson(this.lists[this.activeIndex]);
        } else if (e.code === "Escape") {
          preventAfterAction.call(this, e);
          this.isShowDialog = false;
        }
      } else {
        // 回车给拦掉，什么都不做；ctrl+回车发送
        if (e.code === "Enter") {
          if (e.ctrlKey) {
            this.sendAtInfo();
          } else {
            const rangeInfo = this.getEditorRange();
            if (rangeInfo) {
              rangeInfo.range.deleteContents();
              let newTextNode = null;
              if (
                this.getEditorRange().selection.anchorNode.data.length >=
                  this.getEditorRange().range.endOffset &&
                this.getEditorRange().selection.anchorNode.data != "\n\n"
              ) {
                newTextNode = document.createTextNode("\n\n");
              } else {
                newTextNode = document.createTextNode("\n");
              }
              this.insertHtmlAtCaret(
                newTextNode,
                rangeInfo.selection,
                rangeInfo.range
              );
              rangeInfo.selection.collapseToEnd();
              //   this.insertHtmlAtCaret(
              //   [btn, bSpaceNode],
              //   this.editorRange.selection,
              //   this.editorRange.range
              // );
            }
          }
          preventAfterAction.call(this, e);
        }
      }

      function preventAfterAction(e) {
        e.preventDefault();
        this.preventKeyUp = true;
      }
    },
    // 按键弹起
    onInputText(e) {
      if (this.preventKeyUp) {
        this.preventKeyUp = false;
        return;
      }
      this.preventKeyUp = false;
      // 这是输入了@，那就直接弹选人浮层
      if (e.code == "Digit2" && e.shiftKey) {
        this.showDefaultDialog();
      } else {
        // 这里是输入的不是@，但是可能前方有@，因此需要进行检测看看是否要展示选人浮层
        this.doToggleDialog();
      }
    },
    // 获取当前光标信息
    getEditorRange() {
      let range = null;
      let selection = null;
      if (window.getSelection) {
        selection = window.getSelection();
        if (selection.getRangeAt && selection.rangeCount) {
          range = selection.getRangeAt(0);
          return {
            range,
            selection,
          };
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    doToggleDialog() {
      const rangeInfo = this.getEditorRange();
      if (!rangeInfo || !rangeInfo.range || !rangeInfo.selection) {
        this.closeDialog();
        return;
      }
      const curNode = rangeInfo.range.endContainer;
      if (!curNode || curNode.nodeName !== "#text") {
        this.closeDialog();
        return;
      }
      const searchStr = curNode.textContent.slice(
        0,
        rangeInfo.selection.focusOffset
      );
      // 判断光标位置前方是否有at，只有一个at则展示默认dialog，除了at还有关键字则展示searchDialog
      const keywords = /@([^@\s]*)$/.exec(searchStr);
      if (keywords && keywords.length >= 2) {
        // 展示搜索选人
        const key_words = keywords[1];
        const allMathStr = keywords[0];
        if (allMathStr === "@") {
          this.showDefaultDialog();
        } else {
          if (key_words.length <= 10) {
            this.showSearchDialog(key_words);
          } else {
            this.closeDialog();
          }
        }
        // 重点：记下弹窗前光标位置range
        this.editorRange = rangeInfo;
      } else {
        // 关掉选人
        this.closeDialog();
      }
    },
    // 确定弹窗位置
    getDialogPosition() {
      const editor = this.$refs["jsEditorElement"];
      const pos = position(editor);
      let boxHight = this.$refs["jsDialogElement"].offsetHeight;

      this.atDialogPos.top = `-${boxHight}px`;
      this.atDialogPos.left = `${pos.left + 5}px`;
    },
    // 默认弹窗
    showDefaultDialog() {
      this.getDialogPosition();
      this.isShowDialog = true;
      this.dialogLoading = true;
      get_allProjectMember(this.get_pid()).then((res) => {
        this.lists = res
          .map((item) => {
            if (!item.nickname.endsWith(this.$t("addAt.depart"))) {
              return item;
            }
          })
          .filter((item) => item);
        this.activeIndex = 0;
        this.dialogLoading = false;
      });
    },
    getPinyinMatch(item, inputValue) {
      /* 输入内容拼音转换 */
      // 完整拼音
      const pyInput = pinyin(inputValue, {
        toneType: "none",
        type: "array",
      }).join("");
      // 拼音首字母
      const headerPyInput = pinyin(inputValue, {
        pattern: "first",
        type: "array",
      }).join("");
      const pyE = pinyin(item.nickname, { toneType: "none", type: "array" })
        .join("")
        .slice(0, pyInput.length);
      // 列表中每项的拼音首字母
      const headerPyE = pinyin(item.nickname, {
        pattern: "first",
        type: "array",
      })
        .join("")
        .slice(0, headerPyInput.length);
      // 过滤匹配
      return (
        item.nickname.includes(inputValue) || pyE === pyInput
        // ||
        // headerPyE === headerPyInput
      );
    },
    // 按人名搜索弹窗
    showSearchDialog(keywords) {
      this.getDialogPosition();
      this.isShowDialog = true;
      this.dialogLoading = true;
      get_allProjectMember(this.get_pid()).then((res) => {
        this.lists = res
          .map((item) => {
            if (!item.nickname.endsWith(this.$t("addAt.depart"))) {
              return item;
            }
          })
          .filter((item) => {
            return item && this.getPinyinMatch(item, keywords);
          });
        this.activeIndex = 0;
        this.dialogLoading = false;
      });
    },

    closeDialog() {
      this.isShowDialog = false;
    },

    // 选人
    selectPerson(person) {
      this.isShowDialog = false;
      if (!person) return;
      const editor = this.$refs["jsEditorElement"];
      if (editor) {
        editor.focus();
        // 删掉草稿start
        const editorRange = this.editorRange.range;
        if (!editorRange) return;
        const textNode = editorRange.endContainer; // 拿到末尾文本节点
        const endOffset = editorRange.endOffset; // 光标位置
        // 找出光标前的at符号位置
        const textNodeValue = textNode.nodeValue;
        const expRes = /@([^@]*)$/.exec(textNodeValue);
        if (expRes && expRes.length > 1) {
          editorRange.setStart(textNode, expRes.index);
          editorRange.setEnd(textNode, endOffset);
          editorRange.deleteContents(); // 删除草稿end
          const btn = document.createElement("button");
          btn.dataset["person"] = JSON.stringify({
            accountId: person.accountId,
            nickname: person.nickname,
          });
          // @人名的样式
          btn.style =
            "font-size:16px;background-color:transparent; border:none;color:#409EFF";
          btn.textContent = `@${person.nickname}`;
          btn.contentEditable = "false";
          btn.addEventListener(
            "click",
            () => {
              return false;
            },
            false
          );
          btn.tabindex = "-1";
          const bSpaceNode = document.createTextNode("\u200b"); // 不可见字符，为了放光标方便
          this.insertHtmlAtCaret(
            [btn, bSpaceNode],
            this.editorRange.selection,
            this.editorRange.range
          );
        }
      }
    },

    insertHtmlAtCaret(html, selection, range) {
      if (range && selection) {
        const el = document.createElement("div");
        if (typeof html === "string") {
          el.innerHTML = html;
        } else if (Array.isArray(html)) {
          html.forEach((item) => {
            el.appendChild(item);
          });
        } else {
          el.appendChild(html);
        }
        const frag = document.createDocumentFragment();
        let node;
        let lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);
        frag.contentEditable = "true";
        if (lastNode) {
          selection.extend(lastNode, lastNode.length || 1);
          selection.collapseToEnd();
        }
      }
    },

    // 防止点击dialog后失焦
    doNotBlur(e) {
      e.preventDefault();
    },

    // 中文输入开始
    onCompositionStart() {
      this.isInputChinese = true;
    },
    // 中文输入结束
    onCompositionEnd(e) {
      if (this.isShowDialog) {
        // 重置光标位置，因为此时中文会填进去。。
        this.editorRange = this.getEditorRange();
      }
      this.isInputChinese = false;
    },

    // 拦截粘贴操作
    doOnPaste(e) {
      let pastedText = undefined;
      let image = undefined;
      let imgNode = document.createElement("img");
      const editor = this.$refs["jsEditorElement"];
      const items = (e.clipboardData || window.clipboardData).items;
      if (items[0].type.includes("image")) {
        image = items[0].getAsFile();
        //发送请求的参数格式为FormData
        (async () => {
          await ossAuthorization.call(this);
          this.client
            .put(
              `${this.$store.state.project.tenantId}/comment/${image.lastModified}${image.name}`,
              image
            )
            .then((ress) => {
              imgNode.src = ress.url;
              imgNode.style.maxWidth = "100%";
              imgNode.style.objectFit = "scale-down";
              editor.append(imgNode);
            })
            .error((r) => {
              this.$message.error(
                this.$t("homeTopBar.newProjectForm.message.uploadError")
              );
            });
        })();
      } else {
        if (window.clipboardData && window.clipboardData.getData) {
          pastedText = window.clipboardData.getData("Text"); // IE
        } else if (e.clipboardData && e.clipboardData.getData) {
          pastedText = e.clipboardData.getData("text/plain");
        }
      }

      // 放到光标位置
      const rangeInfo = this.getEditorRange();
      if (rangeInfo && pastedText) {
        rangeInfo.range.deleteContents();
        const newTextNode = document.createTextNode(pastedText);
        this.insertHtmlAtCaret(
          newTextNode,
          rangeInfo.selection,
          rangeInfo.range
        );
      }
      if (rangeInfo && image) {
        rangeInfo.range.deleteContents();
        const frag = document.createDocumentFragment();
        rangeInfo.range.insertNode(imgNode);
        frag.contentEditable = "true";
        rangeInfo.selection.collapseToEnd();
      }
      e.preventDefault();
      return false;
    },
    // 获得结构信息
    getAtInfoStructure() {
      // 结构化转换
      let atInfos = [];
      let nodesList = this.$refs["jsEditorElement"].childNodes;
      nodesList.forEach((item, index) => {
        if (item.nodeName === "#text") {
          let str = item.nodeValue;
          if (str && str.length > 0) {
            let lastChar = str[str.length - 1];
            if (lastChar.charCodeAt(0) === 0x200b) {
              // 零宽字符去掉
              str = str.slice(0, -1);
            }
          }
          if (str) {
            atInfos.push({ type: "text", data: str });
          }
        } else if (item.nodeName === "BR") {
          atInfos.push({ type: "text", data: "\n" });
        } else if (item.nodeName === "BUTTON") {
          atInfos.push({ type: "at", data: JSON.parse(item.dataset.person) });
        } else if (item.nodeName === "SPAN") {
          atInfos.push({ type: "text", data: item.textContent });
        } else if (item.nodeName === "IMG") {
          atInfos.push({ type: "file", data: item.src });
        }
      });
      // 文本碎片合并
      atInfos = this._defragmentation(atInfos);
      return atInfos;
    },
    // 文本碎片合并
    _defragmentation(msgs) {
      const newMsgs = [];
      msgs.forEach((msg) => {
        const last = newMsgs[newMsgs.length - 1];
        if (last && last.type && last.type === "text" && msg.type === "text") {
          last.data = last.data + msg.data;
        } else {
          if (msg && msg.type === "text" && msg.renderLength == 0) return;
          newMsgs.push(msg);
        }
      });
      return newMsgs;
    },

    // 在头部@某人
    at_sb_at_beginning(person) {
      // 添加@某人
      const btn = document.createElement("button");
      btn.dataset["person"] = JSON.stringify({
        accountId: person.accountId,
        nickname: person.nickname,
      });
      // @人名的样式
      btn.style =
        "font-size:16px;background-color:transparent; border:none;color:#409EFF";
      btn.textContent = `@${person.nickname}`;
      btn.contentEditable = false;
      btn.addEventListener(
        "click",
        () => {
          return false;
        },
        false
      );
      btn.tabindex = "-1";
      const bSpaceNode = document.createTextNode("\u200b"); // 不可见字符，为了放光标方便
      const editor = this.$refs["jsEditorElement"];
      if (editor.children.length == 0) {
        editor.appendChild(btn);
        editor.appendChild(bSpaceNode);
      } else {
        editor.insertBefore(btn, editor.children[0]);
        editor.insertBefore(bSpaceNode, editor.children[0]);
      }
    },

    // 将编辑的值发送给父组件
    sendAtInfo() {
      this.$emit("getInfo", this.getAtInfoStructure());
    },

    // 清空输入
    input_clear() {
      const editor = this.$refs["jsEditorElement"];
      editor.innerHTML = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.jsAtEditorElement {
  display: flex;
  position: relative;
  text-align: left;
}

.jsEditorElement {
  outline: none;
  width: 100%;
  flex: none;
  word-break: break-all;
  white-space: pre-wrap;
}

.jsEditorElement:empty::before {
  content: attr(data-content);
  color: rgba(166, 166, 166, 1);
  font-size: 16px;
}

.jsEditorElement:focus::before {
  content: "";
}

.atDialogClass {
  z-index: 999;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 6px 24px 0 rgba(31, 35, 44, 0.1);
  border: 1px solid rgba(166, 166, 166, 0.5);
  border-radius: 6px;
  width: 200px;

  .persons-dialog-nothing {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }

    span {
      color: rgba(166, 166, 166, 1);
      font-size: 14px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .persons-dialog-lists {
    list-style: none;
    padding: 20px 10px;
    margin: 0;

    .person_item {
      display: flex;
      align-items: center;
      padding: 10px 50px 5px 10px;
      margin: 2px 0;

      &.active-hover {
        background-color: rgba(166, 166, 166, 0.3);
        border-radius: 4px;
      }

      &:hover {
        background-color: rgba(166, 166, 166, 0.1);
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
</style>
