<template>
  <div class="change_request">
    <p v-if="!is_edit">
      <el-button type="primary" size="small" @click="edit_click">
        <i class="iconfont icon-branch_edit" style="margin-right: 5px"></i>
        {{ $t("btn.editBtn") }}</el-button
      >
    </p>
    <p v-else>
      <el-button type="info" size="small" @click="quit_edit"
        ><i class="iconfont icon-exit" style="margin-right: 5px"></i>
        {{ $t("btn.exitEditBtn") }}</el-button
      >
      <el-button type="primary" size="small" @click="quit_edit_save"
        ><i class="iconfont icon-branch_edit" style="margin-right: 5px"></i
        >{{ $t("changeRequest.saveAndExitBtn") }}</el-button
      >
    </p>
    <div v-if="!is_edit" class="unedit" style="margin-top: 28px">
      <div>
        <span style="font-size: 14px; width: 160px"
          >{{ $t("changeRequest.field1") }}
        </span>
        <span
          v-if="!form.switch"
          style="
            font-size: 14px !important;
            color: #777 !important;
            min-width: 400px;
          "
        >
          {{ $t("changeRequest.placeholder1") }}
        </span>
        <div>
          <div
            v-for="(item, index) in form.user1"
            :key="index"
            style="font-size: 14px"
          >
            <personal-avatar
              v-if="form.switch"
              :size="40"
              :avatar="matchUserInfo(item).avatar || ''"
              :nickname="matchUserInfo(item).nickname"
              :colorid="matchUserInfo(item).accountId"
              style="margin: 0 auto"
            ></personal-avatar>
            <span v-if="form.switch">{{ matchUserInfo(item).nickname }}</span>
          </div>
        </div>
      </div>
      <div>
        <span style="font-size: 14px; width: 160px">{{
          $t("changeRequest.field2")
        }}</span>
        <span
          v-if="form.radio === 'AUTO'"
          style="font-size: 14px !important; color: #777 !important"
          >{{ $t("changeRequest.placeholder2") }} {{ form.num1 }}
          {{ $t("changeRequest.placeholder3") }}</span
        >
        <span v-else style="font-size: 14px"
          >{{ $t("changeRequest.placeholder2") }} {{ form.num2 }}
          {{ $t("changeRequest.placeholder4") }}
          <span v-for="(item, index) in form.user2" :key="index">
            <span style="color: rgba(48, 100, 143, 1); font-weight: bold">{{
              matchUserInfo(item).nickname
            }}</span
            >&nbsp;&nbsp;<span v-if="index < form.user2.length - 1">{{
              $t("or")
            }}</span
            >&nbsp;&nbsp;
          </span>
          {{ $t("changeRequest.placeholder5") }}
        </span>
      </div>
      <div>
        <span style="font-size: 14px; width: 160px"
          >{{ $t("changeRequest.field3") }}
          <el-tooltip class="item" effect="dark" placement="bottom">
            <div slot="content">
              {{ $t("changeRequest.field3tip") }}
            </div>
            <i
              id="dddd8"
              class="iconfont icon-help"
              style="font-size: 16px"
            ></i> </el-tooltip
        ></span>

        <div style="display: flex; flex-direction: column; overflow: hidden">
          <div
            style="
              font-size: 14px;
              color: rgb(112, 112, 112);
              margin-bottom: 6px;
            "
          >
            <span
              style="display: inline-block; width: 200px; font-weight: bold"
            >
              {{ $t("editFileType.field.table.name") }}</span
            >
            <span
              style="display: inline-block; width: 200px; font-weight: bold"
            >
              {{ $t("editFileType.field.table.required") }}</span
            >
          </div>

          <div
            style="
              max-height: 260px;
              overflow-y: scroll;
              isplay: flex;
              flex-direction: column;
            "
          >
            <div
              style="font-size: 14px; display: flex; align-items: center"
              v-for="(item, index) in filedsForm.role_arr"
              :key="index"
            >
              <span
                style="
                  display: inline-block;
                  width: 200px;
                  text-align: center;
                  height: 30px;
                "
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.fieldItemDto.name"
                  placement="top-end"
                  v-ak-tooltip-auto-show
                >
                  <span
                    style="
                      cursor: pointer;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      display: inline-block;
                      width: 200px;
                    "
                  >
                    {{ item.fieldItemDto.name }}</span
                  >
                </el-tooltip>
              </span>

              <span
                style="
                  display: inline-block;
                  width: 200px;
                  text-align: center;
                  height: 30px;
                "
              >
                {{ item.required ? "✔" : "" }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="isedit">
      <div>
        <span style="position: relative">
          <el-switch
            v-model="form.switch"
            class="switch_button"
            active-color="#43CF7C"
          >
          </el-switch>
          <span style="font-size: 14px; margin-left: 6px">{{
            $t("changeRequest.field1")
          }}</span>
        </span>
        <div>
          <el-select
            v-model="form.user1"
            size="small"
            multiple
            filterable
            :placeholder="$t('placeholder.dropdown')"
            style="width: 426px"
            :disabled="!form.switch"
          >
            <el-option
              v-for="user in allUserList"
              v-show="
                !user.deleted &&
                PUserList.some((item) => {
                  return item.accountId === user.accountId;
                })
              "
              :key="user.accountId"
              :label="user.nickname"
              :value="user.accountId"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <span style="font-size: 14px; margin-top: -30px">{{
          $t("changeRequest.field2")
        }}</span>
        <div style="font-size: 14px">
          <p>
            <el-radio v-model="form.radio" label="AUTO"
              >{{ $t("changeRequest.auto") }}：{{
                $t("changeRequest.placeholder2")
              }}<el-input-number
                v-model="form.num1"
                :step="1"
                :precision="0"
                size="mini"
                :min="1"
                :max="100"
              ></el-input-number
              >{{ $t("changeRequest.placeholder3") }}</el-radio
            >
          </p>
          <p>
            <el-radio v-model="form.radio" label="MANUAL"
              >{{ $t("changeRequest.byHand") }}：{{
                $t("changeRequest.placeholder2")
              }}<el-input-number
                v-model="form.num2"
                :step="1"
                :precision="0"
                size="mini"
                :min="1"
                :max="100"
              ></el-input-number
              >{{ $t("changeRequest.placeholder4") }}
              <el-select
                v-model="form.user2"
                multiple
                filterable
                size="mini"
                :placeholder="$t('placeholder.dropdown')"
              >
                <el-option
                  v-for="user in user_list"
                  v-show="
                    !user.deleted &&
                    PUserList.some((item) => {
                      return item.accountId === user.accountId;
                    })
                  "
                  :key="user.accountId"
                  :label="user.nickname"
                  :value="user.accountId"
                >
                </el-option> </el-select
              >{{ $t("changeRequest.placeholder5") }}</el-radio
            >
          </p>
        </div>
      </div>
      <div>
        <span style="position: relative">
          <span style="font-size: 14px; margin-left: 6px"
            >{{ $t("changeRequest.field3") }}
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content">
                {{ $t("changeRequest.field3tip") }}
              </div>
              <i
                id="dddd8"
                class="iconfont icon-help"
                style="font-size: 16px"
              ></i> </el-tooltip
          ></span>
        </span>
        <div style="overflow-y: hidden">
          <div style="text-align: center; width: 40%">
            <div class="ziduan-box" style="position: relative">
              <!-- 表头部分 -->
              <div
                style="
                  padding-bottom: 12px;
                  clear: both;
                  padding-top: 15px;
                  width: 100%;
                  display: flex;
                  justify-content: space-around;
                "
              >
                <span
                  style="
                    font-size: 14px;
                    color: #707070;
                    flex: 1;
                    font-weight: bold;
                  "
                  >{{ $t("editFileType.field.table.name") }}</span
                >
                <span
                  style="
                    font-size: 14px;
                    color: #707070;
                    flex: 1;
                    font-weight: bold;
                  "
                  >{{ $t("editFileType.field.table.required") }}</span
                >
              </div>
              <!-- 表内容部分 -->
              <div
                class="ziduan"
                style="
                  max-height: 400px;
                  clear: both;
                  width: 100%;
                  margin-bottom: 40px;
                "
              >
                <el-form ref="editPermissionForm" :model="filedsForm">
                  <el-scrollbar
                    ref="myscroll"
                    style="max-height: 260px; width: 100%; overflow-y: scroll"
                  >
                    <draggable
                      v-model="filedsForm.role_arr"
                      dragable="true"
                      handle=".mydrag"
                      @update="datadragEnd"
                    >
                      <transition-group>
                        <el-form-item
                          v-for="(item, index) in filedsForm.role_arr"
                          :key="index"
                          style="font-size: 13px"
                        >
                          <div
                            style="
                              white-space: nowrap;
                              text-overflow: ellipsis;
                              overflow: hidden;
                              /* show-overflow-tooltip: true; */
                              text-overflow: ellipsis;
                              text-align: left;
                              display: inline-block;
                              color: #707070;
                              vertical-align: middle;
                              /* float: left; */
                              padding-left: 10px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              flex: 1;
                            "
                          >
                            <div class="mydrag">
                              <i
                                class="iconfont icon-a-huaban33"
                                style="font-weight: 400; color: #606266"
                              />
                            </div>
                            <el-tooltip
                              v-ak-tooltip-auto-show
                              effect="light"
                              placement="top-end"
                              style="flex: 1"
                            >
                              <div slot="content">
                                {{ item.fieldItemDto.name }}
                              </div>
                              <div>
                                <div
                                  style="
                                    font-size: 14px;
                                    color: #333;
                                    text-align: center;
                                  "
                                  class="el-tag-nameBox"
                                >
                                  {{ item.fieldItemDto.name }}
                                </div>
                              </div>
                            </el-tooltip>
                            <i
                              class="el-icon-close"
                              style="flex: 1; cursor: pointer"
                              @click="handleCloseziduan(item)"
                            ></i>
                          </div>
                          <div
                            class="checkbox add-ziduanBox-2"
                            style="flex: 1 1 0%"
                          >
                            <el-checkbox
                              v-model="item.required"
                              label="isMust"
                              name="permission"
                              @change="
                                (value) => {
                                  changeRequired(value, item);
                                }
                              "
                              ><br
                            /></el-checkbox>
                          </div>
                        </el-form-item>
                      </transition-group>
                    </draggable>
                  </el-scrollbar>
                </el-form>
              </div>
              <div
                v-if="!inputVisible"
                style="padding-left: 3.5vw; position: absoult; bottom: -30px"
              >
                <el-button
                  style="
                    height: 34px;
                    width: 120px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  @click="showInput"
                >
                  <i font-size="15" class="iconfont icon-add" /><span
                    style="color: #a6a6a6"
                    >&nbsp;{{ $t("editFileType.field.newFieldBtn") }}</span
                  >
                </el-button>
              </div>
              <!-- 新增条目部分 -->
              <div v-if="inputVisible" class="add-ziduanBox">
                <div class="add-ziduanBox-1">
                  <el-select
                    ref="fieldSelect"
                    multiple
                    v-model="new_fields"
                    size="small"
                    filterable
                    collapse-tags
                    @visible-change="get_fields"
                    popper-class="select_own"
                  >
                    <el-option
                      v-for="(item, index) in fields"
                      :key="index"
                      :label="item.name"
                      :value="item.fieldId"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="checkbox add-ziduanBox-2">
                  <el-checkbox-group>
                    <el-checkbox label="isMust" name="permission"
                      ><br
                    /></el-checkbox>
                    <el-checkbox label="hoverShow" name="permission"
                      ><br
                    /></el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonalAvatar from "@/components/personal";
import {
  getchangeRequestConfig,
  changeCommittee,
  changeRule,
  changeRequestAddfield,
  changeRequestDeletefield,
  changeRequestChangefield,
  changeRequestSortfield,
} from "@/network/changeRequest/index.js";
import { all_fields_get } from "@/network/fileType/index.js";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
export default {
  components: {
    PersonalAvatar,
    draggable,
  },
  data() {
    return {
      is_edit: false,
      form: {
        user1: [],
        user2: [],
        radio: "AUTO",
        switch: false,
        num1: 0,
        num2: 0,
      },
      filedsForm: {
        role_arr: [],
      },
      inputVisible: false,
      new_fields: [],
      fields: [],
    };
  },
  computed: {
    ...mapGetters(["user_list", "project_user_list"]),
    allUserList() {
      return Object.values(this.user_list);
    },
    PUserList() {
      return Object.values(this.project_user_list);
    },
  },
  mounted() {
    this.getchangeRequestConfig();
  },
  methods: {
    changeRequired(value, item) {
      const params = {
        projectId: this.get_pid(),
        fieldReferenceId: item.fieldReferenceId,
        data: {
          fieldId: item.fieldItemDto.fieldId,
          required: value,
        },
      };
      changeRequestChangefield(params).then((res) => {
        this.getchangeRequestConfig();
      });
    },
    get_fields(flag) {
      if (flag) {
        all_fields_get(this.get_pid()).then((res) => {
          this.fields = res.filter((item) => {
            let flag = true;
            this.filedsForm.role_arr.forEach((alreadyBind) => {
              if (alreadyBind.fieldItemDto.fieldId === item.fieldId) {
                flag = false;
              }
            });
            if (item.fieldType === "DRAWIO") {
              flag = false;
            }
            return flag;
          });
        });
      } else {
        if (this.new_fields.length) {
          const params = {
            projectId: this.get_pid(),
            data: {
              fieldIdList: this.new_fields,
            },
          };
          changeRequestAddfield(params).then((res) => {
            this.inputVisible = false;
            this.new_fields = [];
            this.getchangeRequestConfig();
          });
        }
      }
    },
    showInput() {
      this.new_fields = [];
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.fieldSelect.focus();
      });
    },
    datadragEnd(evt) {
      let list = [];
      for (let i in this.filedsForm.role_arr) {
        list.push(this.filedsForm.role_arr[i].fieldReferenceId);
      }
      const params = {
        projectId: this.get_pid(),
        data: list,
      };
      changeRequestSortfield(params).then((res) => {
        this.getchangeRequestConfig();
      });
    },
    handleCloseziduan(item) {
      const params = {
        projectId: this.get_pid(),
        fieldReferenceId: item.fieldReferenceId,
      };
      changeRequestDeletefield(params).then((res) => {
        this.getchangeRequestConfig();
      });
    },
    async getchangeRequestConfig(flag) {
      const res = await getchangeRequestConfig(this.get_pid());
      this.form.user1 = res.reviewerList;
      this.form.switch = res.switchFlag;
      this.form.radio = res.rule.mergeType;
      this.form.user2 = res.rule.confirmUsers;
      this.filedsForm.role_arr = res.fieldReferenceList;
      if (res.rule.mergeType === "AUTO") {
        this.form.num1 = res.rule.threshold;
      } else {
        this.form.num2 = res.rule.threshold;
      }
      if (flag) {
        this.is_edit = !this.is_edit;
      }
    },
    edit_click() {
      this.is_edit = !this.is_edit;
    },
    quit_edit() {
      this.getchangeRequestConfig(true);
    },
    async quit_edit_save() {
      if (this.form.switch && !this.form.user1.length) {
        this.$message({
          type: "warning",
          message: this.$t("changeRequest.info"),
        });
        return;
      }
      const params1 = {
        projectId: this.get_pid(),
        data: {
          reviewerList: this.form.user1,
          switchFlag: this.form.switch,
        },
      };
      const params2 = {
        projectId: this.get_pid(),
        data: {
          confirmUsers: this.form.user2,
          mergeType: this.form.radio,
          threshold:
            this.form.radio === "AUTO" ? this.form.num1 : this.form.num2,
        },
      };
      await changeCommittee(params1);
      await changeRule(params2);
      this.getchangeRequestConfig();
      this.is_edit = !this.is_edit;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-ziduanBox {
  width: 100%;
  display: flex;
  align-items: center;
  .add-ziduanBox-1 {
    flex: 2;
    ::v-deep .el-select {
      .el-tag:first-child {
        display: flex;
        align-items: center;
        .el-select__tags-text {
          display: inline-block;
          width: 110px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .add-ziduanBox-2 {
    flex: 2;
    .el-checkbox-group {
      display: flex;
      justify-content: space-around;
    }
  }
}
.el-tag-nameBox {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  /*禁止换行显示*/
  white-space: nowrap;
}
.mydrag {
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 4px;
}
.add-ziduanBox-2 {
  flex: 2;
  .el-checkbox-group {
    display: flex;
    justify-content: space-around;
  }
}
::v-deep.el-button {
  font-size: 16px;
  padding: 6px 10px;
}
.change_request {
  .switch_button {
  }
  & > p {
    margin: 0;
    text-align: right;
    margin-bottom: 5px;
  }
  & > div {
    width: 100%;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    text-align: left;
    padding-left: 20px;
    & > div {
      padding: 30px 0;
      display: flex;
      align-items: center;
      & > span:first-of-type {
        display: inline-block;
        color: rgba(128, 128, 128, 1);
        font-size: 16px;
        font-weight: 700;
        width: 195px;
        flex-shrink: 0;
      }
      border-bottom: 1px solid rgba(229, 229, 229, 1);
      & > div {
        display: inline-block;
        vertical-align: middle;
        overflow-x: scroll;
        white-space: nowrap;
        width: 80%;
        &::-webkit-scrollbar {
          width: 6px;
          height: 8px;
          background-color: #ebeef5;
        }
        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #ccc;
          border-radius: 4px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background: rgba(255, 255, 255, 1);
        }
        & > div {
          display: inline-block;
          vertical-align: middle;
          margin: 0 36px 0 0;
          & > span {
            display: inline-block;
            margin-top: 4px;
            min-width: 40px; /*no*/
            text-align: center;
          }
        }
      }
    }
  }
  .isedit {
    & > div {
      &:first-of-type {
        padding-top: 50px;
        & > div {
          vertical-align: baseline;
          overflow: hidden;
        }
      }
      &:last-of-type {
        & > span {
          vertical-align: top;
        }
        & > div {
          margin-top: -20px;
        }
      }
    }
  }
}
</style>
<style scoped>
/deep/.el-form-item__content {
  line-height: 27px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
