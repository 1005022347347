<template>
  <div class="loginBox">
    <div class="login">
      <div class="login-imag">
        <div class="loginLogo">
          <img
            class="loginLogo-1"
            src="../../assets/img/newLogin/newLoginName.png"
            alt=""
          />
          <div class="shadowBox">
            <img
              class="loginLogo-2"
              src="../../assets/img/newLogin/newLoginCar1.png"
              alt=""
            />
          </div>
        </div>
        <div class="introduce">
          <span>Agile·ASPICE·AI<br />{{ $t("addLogin.introduce") }}</span>
        </div>
        <div class="solution">
          <span style="color: #004caa; font-size: 16px">{{
            $t("addLogin.solution")
          }}</span>
          <div>
            <img
              style="mix-blend-mode: darken"
              src="../../assets/img/newLogin/1.png"
              alt=""
            />
            <img
              style="mix-blend-mode: darken"
              src="../../assets/img/newLogin/2.png"
              alt=""
            />
            <img
              style="mix-blend-mode: darken"
              src="../../assets/img/newLogin/3.png"
              alt=""
            />
            <img
              style="mix-blend-mode: darken"
              src="../../assets/img/newLogin/4.png"
              alt=""
            />
            <img
              style="mix-blend-mode: darken"
              src="../../assets/img/newLogin/5.png"
              alt=""
            />
            <img
              style="mix-blend-mode: darken"
              src="../../assets/img/newLogin/6.png"
              alt=""
            />
            <img
              style="mix-blend-mode: darken"
              src="../../assets/img/newLogin/7.png"
              alt=""
            />
            <img
              style="mix-blend-mode: darken"
              src="../../assets/img/newLogin/8.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-main>
        <div class="main_box">
          <router-view class="re_from"></router-view>
          <languageLogin class="language-option"></languageLogin>
        </div>
      </el-main>
    </div>
  </div>
</template>

<script>
import languageLogin from "./components/languageLogin.vue";
export default {
  name: "Login",
  components: {
    languageLogin,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style>
.main_box .title {
  position: absolute;
  left: 17%;
  top: 8%;
  font-size: 26px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
}
/* .main_box .subtitle {
  position: absolute;
  left: 36%;
  top: 19.5%;
  font-size: 40px;
  font-weight: 700;
  text-align: left;
} */
</style>
<style lang="scss" scoped>
.loginBox {
  display: flex;
}
.language-option {
  position: absolute;
  top: 30px;
  transform: translateX(30px);
}
.logo_origin {
  height: 10px;
  font-size: 8px;
  font-weight: 400;
  float: left;
  bottom: 10px;
  right: 20px;
  position: absolute;
  color: rgba(255, 255, 255, 0.5);
}
.login {
  overflow: hidden;
  width: 50vw;
  height: 100vh;
  max-width: 50vw;
  min-width: 50vh;
  box-sizing: border-box;
  .login-imag {
    width: 100%;
    height: 100%;
    // background-image: url('../../assets/img/newLogin/newLogin.jpg');
    background-image: url("../../assets/img/newLogin/bg.png");

    background-size: 50vw 100vh;
    background-position: center;
    background-repeat: no-repeat;
  }
  .loginLogo {
    border: none;
    outline: none;
    margin-right: auto;
    margin-left: auto;
    padding-top: 34px;
    width: 480px;
    // width: 31vw;
    // height: 91vh;
    // max-width: 31vw;
    // max-height: 91vh;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    // image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */

    .loginLogo-1 {
      width: 480px;
    }
    .loginLogo-2 {
      // margin-top: 52px;
      width: 395px;
      box-shadow: 68px 48px 36px #4365857a;
      border-radius: 43px;
      margin-top: 20px;
    }
  }
}

.shadowBox {
  position: relative;
  z-index: 1;
}
.shadowBox::after {
  content: " ";
  position: absolute;
  right: 30px;
  bottom: -6px;
  background-color: #436585;
  box-shadow: 16px 12px 20px #436585;
  width: 392px;
  height: 405px;
  z-index: -1;
  border-radius: 59px;
}
.el-main {
  overflow: hidden;
  width: 50vw;
  max-width: 50vw;
  min-width: 50vh;
  box-sizing: border-box;
  height: 100vh;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_box {
  width: 50vw;
}

.re_from {
  height: 100%;
  overflow: hidden;
}

.dev_logo {
  width: 55%;
  height: 100%;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.6);
  overflow: hidden;
}
.dev_logo_flex {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
}

.dev_logo_img {
  display: block;
  width: 400px;
  height: 136px;
}

.el-row {
  margin-bottom: 20px;
}
.rightLogo {
  position: absolute;
  top: 30px;
  right: 30px;
}
.introduce {
  margin: 40px 0 30px 0;
  color: rgba(0, 76, 170, 1);
  font-size: 16px;
  span {
    line-height: 30px;
    // word-wrap: break-word;
    white-space: pre-wrap;
  }
}
.solution {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 740px;
  margin: 0 auto;
  span {
    margin-bottom: 5px;
    font-size: 12px;
    align-self: flex-start;
    color: rgba(96, 98, 102, 1);
  }
  & > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    img {
      background-color: white;
      width: 22%;
      margin-bottom: 10px;
    }
  }
}
</style>
