<template>
  <div class="relation_dialog">
    <el-dialog
      title=""
      :visible="visible"
      :close-on-click-modal="false"
      :show-close="true"
      top="13vh"
      width="70%"
      @close="close"
    >
      <relation-echarts
        v-loading="loading"
        :node_key="cur_node_key"
        :file-type-id="fileTypeId"
        :isdialog="true"
        @hideLoading="hideLoading"
      />
    </el-dialog>
  </div>
</template>

<script>
import RelationEcharts from "@/components/mindmap/RelationEcharts";
export default {
  components: { RelationEcharts },
  props: {
    relation_dialog: Boolean,
    cur_node_key: String,
    fileTypeId: String,
  },
  data() {
    return {
      visible: this.relation_dialog,
      relation_data: null,
      node_relation_data: null,
      loading: false,
    };
  },
  computed: {},
  mounted() {},

  methods: {
    close() {
      this.visible = false;
      this.$emit("closeRelation");
    },
    addNode() {
      this.$router.push({
        path: "/addNode",
        query: { nodeKey: this.cur_node_key },
      });
    },
    hideLoading(val) {
      this.loading = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.relation_dialog {
  ::v-deep.el-dialog {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 20px;
    }
    .el-dialog__footer {
      padding-right: 20px;
      .go_add_relation {
        cursor: pointer;
        font-size: 20px;
      }
    }
  }
}
</style>
