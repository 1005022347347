<template>
  <div class="r_c_window">
    <div v-show="show" class="flex">
      <div class="left">
        <el-scrollbar ref="scroll" style="height: 43vh">
          <div
            v-for="(item, index) in filteredOptions"
            :key="index"
            class="li"
            @click="click_item(item)"
          >
            <div
              v-if="item_click == item.label"
              v-limits-of-authority="
                item.label === $t('canvas.rightClick.moveNode')
                  ? 'NODE_MOVE'
                  : 'NODE_EDIT'
              "
              style="color: rgb(66, 139, 202); font-weight: 600"
            >
              {{ item.label }}
            </div>
            <div
              v-else
              v-limits-of-authority="
                item.label === $t('canvas.rightClick.moveNode')
                  ? 'NODE_MOVE'
                  : 'NODE_EDIT'
              "
            >
              {{ item.label }}
            </div>
            <i
              v-if="item_click == item.label"
              v-show="
                item.children &&
                !(item.label === $t('canvas.rightClick.addRelatedNode'))
              "
              class="el-icon-arrow-right"
              style="color: rgb(66, 139, 202)"
            ></i>
            <i
              v-else
              v-show="
                item.children &&
                !(item.label === $t('canvas.rightClick.addRelatedNode'))
              "
              class="el-icon-arrow-right"
            ></i>
          </div>
        </el-scrollbar>
      </div>
      <div v-if="right_show" v-loading="right_loading" class="right">
        <el-scrollbar style="height: 43vh">
          <div
            v-for="(child, index) in select_children"
            v-show="child.fieldType != 'DRAWIO'"
            :key="index"
            class="li"
            @click="click_children(child)"
          >
            <div class="align_left">
              <div
                v-show="selected == child.value"
                style="color: rgb(66, 139, 202); font-weight: 600"
              >
                ✔
              </div>
            </div>
            <el-tooltip
              v-if="
                selected == child.value &&
                item_click != $t('canvas.rightClick.dfmeaTable')
              "
              v-ak-tooltip-auto-show
              effect="dark"
              :content="child.label"
            >
              <div style="color: rgb(66, 139, 202); font-weight: 600">
                {{ child.label }}
              </div>
            </el-tooltip>
            <el-tooltip
              v-else
              v-ak-tooltip-auto-show
              effect="dark"
              :content="child.label"
            >
              <div>
                {{ child.label }}
              </div>
            </el-tooltip>
          </div>
        </el-scrollbar>
      </div>
    </div>

    <!-- 移动节点弹窗 -->
    <el-dialog
      :visible.sync="moveNodesDialogVisible"
      :modal="false"
      top="30vh"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <span slot="title">
        {{ $t("canvas.rightClick.moveNode") }}
      </span>
      <div style="text-align: left">
        <span style="font-size: 16px">{{
          $t("canvas.rightClick.moveNodeForm.edit")
        }}</span>
        <el-select
          v-model="moveNodesID"
          :placeholder="$t('canvas.rightClick.moveNodeForm.placeholder')"
          size="small"
          filterable
          remote
          :remote-method="get_move_nodes_options"
          :popper-append-to-body="true"
          :loading="moveNodesOptionsLoading"
          style="margin-top: 16px"
          popper-class="select-icon-height-auto-class"
        >
          <el-option
            v-for="item in moveNodesOptions"
            :key="item.nodeKey"
            :label="`${item.nodeKey} ${item.topic}`"
            :value="item.nodeKey"
          >
            <!-- <span class="add_nodes">{{ item.nodeKey + " " + item.topic }}</span> -->
            <select-icon :item="item"> </select-icon>
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="moveNodesDialogVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="confirmMoveNodes">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 状态切换弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :modal="false"
      :close-on-click-modal="false"
      :show-close="true"
      top="30vh"
      width="50%"
    >
      <span slot="title" style="font-size: 16px; font-weight: bold">
        {{ $t("canvas.rightClick.statusChange") }}
      </span>
      <el-table
        :data="tableData"
        height="250"
        :span-method="objectSpanMethod"
        border
        style="width: 100%"
      >
        <el-table-column prop="layer" label=" " align="center" width="180">
          <template slot-scope="scope">
            {{ $t("canvas.rightClick.statusChangeForm.num") }}
            {{ scope.row.layer }}
            {{ $t("canvas.rightClick.statusChangeForm.layer") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="statusName"
          align="center"
          :label="$t('canvas.rightClick.statusChangeForm.original')"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="newState"
          align="center"
          :label="$t('canvas.rightClick.statusChangeForm.new')"
        >
          <template slot-scope="scope">
            <el-select
              v-model="info_status[scope.$index]"
              :placeholder="$t('placeholder.dropdown')"
              filterable
              @change="change_status($event, scope.row)"
            >
              <el-option
                v-for="item in scope.row.can_change_status_list"
                :key="item.statusId"
                :label="item.name"
                :value="item.statusId"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="saveState">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 其他字段编辑弹窗 -->
    <el-dialog
      :visible.sync="otherDialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
      :modal="false"
      top="30vh"
      width="25%"
      custom-class="otherDialog"
      @close="closeOtherDialog"
    >
      <span slot="title">
        {{ $t("canvas.rightClick.responsibilityForm.title") }}
      </span>
      <div>
        <span style="margin-bottom: 16px; text-align: start; display: block">{{
          checkedLeaf.label
        }}</span>
        <!-- 单用户下拉框 -->
        <el-select
          v-if="
            checkedLeaf.fieldType == 'SINGLE_USER_SELECT' ||
            checkedLeaf.fieldType == 'SELECT'
          "
          v-model="checkedLeaf.newValue"
          popper-class="user_select"
          :loading="user_loading"
          :loading-text="$t('loading.loading2')"
          filterable
          clearable
        >
          <el-option
            v-for="opt in user_list"
            v-show="!opt.deleted"
            :key="opt.value"
            :label="opt.label"
            :value="opt.value"
          >
            <span class="select_item">{{ opt.label }}</span>
          </el-option>
        </el-select>
        <!-- 多用户下拉框 -->
        <el-select
          v-if="
            checkedLeaf.fieldType == 'MULTI_USER_SELECT' ||
            checkedLeaf.fieldType == 'SELECT_MULTI'
          "
          v-model="checkedLeaf.newValue"
          popper-class="user_select"
          :loading="user_loading"
          :loading-text="$t('loading.loading2')"
          filterable
          clearable
          multiple
        >
          <el-option
            v-for="opt in user_list"
            v-show="!opt.deleted"
            :key="opt.value"
            :label="opt.label"
            :value="opt.value"
          >
            <span class="select_item">{{ opt.label }}</span>
          </el-option>
        </el-select>
        <!-- 日期选择框 -->
        <el-date-picker
          v-if="checkedLeaf.fieldType == 'DATE'"
          v-model="checkedLeaf.newValue"
          type="date"
          :placeholder="$t('placeholder.date')"
        >
        </el-date-picker>
        <!-- 单行文本输入框 -->
        <el-input
          v-if="checkedLeaf.fieldType == 'TEXT'"
          v-model="checkedLeaf.newValue"
        ></el-input>
        <!-- 多行文本输入框 -->
        <el-input
          v-if="checkedLeaf.fieldType == 'TEXTAREA'"
          v-model="checkedLeaf.newValue"
          type="textarea"
        ></el-input>
        <!-- 数字输入框 -->
        <el-input
          v-if="checkedLeaf.fieldType == 'NUMBER'"
          v-model="checkedLeaf.newValue"
          autocomplete="off"
          oninput="value=value.replace(/[^\-?\d.]/g,'')"
        ></el-input>
        <!-- 标签下拉框 -->
        <el-select
          v-if="checkedLeaf.fieldType == 'LABEL'"
          v-model="checkedLeaf.newValue"
          popper-class="user_select"
          :loading="user_loading"
          :loading-text="$t('loading.loading2')"
          filterable
          clearable
          multiple
          allow-create
        >
          <el-option
            v-for="opt in user_list"
            v-show="!opt.deleted"
            :key="opt.value"
            :label="opt.label"
            :value="opt.value"
          >
            <span class="select_item">{{ opt.label }}</span>
          </el-option>
        </el-select>
        <!-- 链接输入框 -->
        <el-input
          v-if="checkedLeaf.fieldType == 'LINK'"
          v-model="checkedLeaf.newValue"
        ></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="otherDialogVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="saveOthers">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 批量关联节点弹窗 -->
    <el-dialog
      :visible.sync="relation_visible"
      :modal="false"
      top="30vh"
      width="25%"
      :before-close="relation_close"
      custom-class="relation-dialog"
      class="createpro"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div class="createpro-header">
        <el-tabs v-model="relationTabsModel">
          <el-tab-pane
            :label="$t('canvas.rightClick.addRelatedNode')"
            name="first"
          >
            <div style="text-align: left">
              <div class="add_relation">
                <el-form
                  v-limits-of-authority="'NODE_EDIT'"
                  :inline="true"
                  class="demo-form-inline"
                  size="medium"
                >
                  <el-form-item>
                    <el-select
                      v-model="relation_value"
                      multiple
                      filterable
                      remote
                      reserve-keyword
                      collapse-tags
                      :placeholder="$t('nodeDetail.relationChart.placeholder')"
                      :loading-text="$t('loading.loading2')"
                      :remote-method="get_unrelation_node"
                      :loading="relation_loading"
                      style="width: 100%"
                      @visible-change="relation_clearData"
                      popper-class="select-icon-height-auto-class"
                    >
                      <el-option
                        v-for="item in relation_options"
                        :key="item.nodeKey"
                        :label="`${item.nodeKey}：${cut(item.topic)}`"
                        :value="item.nodeKey"
                      >
                        <!-- ms8613代码定位 -->
                        <select-icon :item="item"> </select-icon>
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      size="mini"
                      type="primary"
                      :loading="batchLoading"
                      @click="add_relation"
                      >{{ $t("btn.confirmBtn") }}</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('nodeDetail.relationChart.Batchassociatednodes')"
            name="second"
          >
            <div
              style="margin-top: 10px; margin-bottom: 20px; text-align: left"
            >
              <el-input
                v-model="batchNodeStr"
                type="textarea"
                size="medium"
                style="width: 70%; margin-right: 10px; display: inline-block"
                @input="batchNodeInput"
                :placeholder="$t('nodeDetail.relationChart.placeholder2')"
              ></el-input>
              <el-button
                size="mini"
                type="primary"
                :loading="batchLoading"
                @click="add_batch_relation"
                >{{ $t("btn.confirmBtn") }}</el-button
              >
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>

    <!-- 责任人修改弹窗 -->
    <el-dialog
      :visible.sync="assignee_visible"
      :modal="false"
      top="30vh"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <span slot="title">
        {{ $t("canvas.rightClick.responsibilityForm.title") }}
      </span>
      <div style="text-align: left">
        <span>{{ $t("canvas.rightClick.responsibilityForm.edit") }}</span>
        <!-- 单用户下拉框 -->
        <el-select
          v-model="assignee_checked"
          popper-class="user_select"
          :loading="user_loading"
          :loading-text="$t('loading.loading2')"
          filterable
          clearable
          style="margin-top: 16px"
        >
          <el-option
            v-for="opt in user_list"
            v-show="!opt.deleted"
            :key="opt.value"
            :label="opt.label"
            :value="opt.value"
          >
            <span class="select_item">{{ opt.label }}</span>
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="assignee_visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="save_assignee">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <!-- 执行测试计划弹窗 -->
    <el-dialog
      :visible.sync="test_show"
      :modal="false"
      top="30vh"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <span slot="title">
        {{ $t("canvas.rightClick.responsibilityForm.title") }}
      </span>
      <div style="text-align: left">
        <span>{{ $t("canvas.btn.executeTestPlan") }}</span>
        <!-- 单用户下拉框 -->
        <el-select
          v-model="test_checked"
          style="margin-top: 16px"
          popper-class="user_select"
          filterable
        >
          <el-option
            v-for="opt in test_options"
            :key="opt.status"
            :label="opt.statusName"
            :value="opt.status"
          >
            <span class="select_item">{{ opt.statusName }}</span>
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="test_show = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="save_test">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('baseline.archive.title1')"
      :visible.sync="nodeVersionDialog"
      :modal="false"
      top="30vh"
      width="25%"
    >
      <el-form
        ref="nodeVersionform"
        :model="nodeVersionForm"
        label-width="140px"
        label-position="left"
      >
        <el-form-item
          :label="$t('node.version.versionName')"
          prop="version"
          :rules="{
            required: true,
            trigger: 'blur',
            message: $t('placeholder.notNull'),
          }"
        >
          <el-input v-model="nodeVersionForm.version"></el-input>
        </el-form-item>
        <el-form-item :label="$t('baseline.archive.remark')" prop="desc">
          <el-input v-model="nodeVersionForm.desc" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="nodeVersionDialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          :loading="saveVersionBtnLoading"
          @click="saveVersion"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { limits_of_authority_disable } from "@/utils/limits";
import { get_multiLayers_context } from "@/network/mindmap/index.js";
import {
  get_allProjectMember,
  get_node_use_node_key,
  move_nodes_though_files,
  get_nodes_by_fuzzykey,
  multiLayers_change_status,
  multiLayers_change_assignee,
  get_workflow_list,
  putBatchField,
} from "@/network/node/index.js";
import {
  getAllGanttChart,
  get_ganttnode_by_node,
  move_ganttnode_to_newchart,
  add_ganttnode_by_nodekey,
  deleteNodeList,
} from "@/network/gantt/index.js";
import { saveArchive } from "@/network/nodeVersion";
import { getFile, getReleaseElements } from "@/network/home/index.js";
import { Message } from "element-ui";
import vmson from "@/utils/vmson";
import { mapGetters } from "vuex";
import { isNumber } from "@antv/x6/lib/util/lang/lang";
import { get_test_nodes, execute_test_node } from "@/network/test/index.js";
import {
  add_sprint_nodes,
  delete_sprint_nodes,
  kanbanFetch,
  add_kanban_nodes,
  delete_kanban_nodes,
} from "@/network/quick/index.js";
import { add_nodes as baseline_add_nodes } from "@/network/baseline/index.js";
import { add_nodes as version_add_nodes } from "@/network/version/index.js";
import { enter_process } from "@/network/testPlan/index.js";
import { add_test_node } from "@/network/test/index";
import { get_file_exist } from "@/network/review/index.js";
import {
  add_relation_node,
  add_batch_relation_node,
} from "@/network/node/index.js";
import { searchNodesByFuzzyKey } from "@/network/fileOperation/index.js";
import { get_filetype_detail } from "@/network/fileType/index.js";
import selectIcon from "@/components/selectIcon";

//临时代码
import config from "@/../config/index";

export default {
  name: "Rightclick",
  components: { selectIcon },
  props: {
    nodeData: {
      type: Array,
      default: () => [],
    },
    last_node: {
      type: Object,
      default: () => {},
    },
    jm: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      relationTabsModel: "first",
      batchNodeStr: "",
      batchLoading: false,
      allOptions: [],
      testCaseStatus_options: [],
      relation_visible: false,
      relation_options: [],
      relation_value: [],
      relation_loading: false,
      others_data: [],
      sprint_data: [],
      item_click: "",
      right_loading: false,
      selected: "",
      selected_const: "",
      select_children: [],
      right_show: false,
      test_checked: "",
      test_show: false,
      show: false,
      //cascader选中内容
      cascader_model: "",
      // 修改责任人弹窗
      assignee_visible: false,
      assignee_checked: "",
      // 表格合并相关
      multiLayers_change_status_params: {
        projectId: "",
        nodeKeys: [],
        data: [],
      },
      info_status: [],
      used_tabel_data: [],
      temp_index: 0,
      node_keys: [],
      // 右键级联框选中的是否可以被取消选中
      // isRightCanDelete: true,
      // 批量修改状态的数据
      status_list: [],
      dialogVisible: false,
      // 移动节点弹窗是否可见
      moveNodesDialogVisible: false,
      // 要移动节点的ID
      moveNodesID: "",
      // 移动节点模糊搜索加载状态
      moveNodesOptionsLoading: false,
      // 移动节点模糊搜索的选项
      moveNodesOptions: [],
      otherDialogVisible: false,
      // 重新渲染
      refresh: 0,
      options: [
        {
          value: "zdh_pingshen",
          label: this.$t("canvas.reviewRequestForm.tip9"),
        },
        {
          value: "stateChange",
          label: this.$t("canvas.rightClick.statusChange"),
        },
        {
          value: "assignee",
          label: this.$t("canvas.rightClick.responsibility"),
        },
        {
          value: this.$t("mindmap.SpecifySprint"),
          label: this.$t("mindmap.SpecifySprint"),
          children: [],
        },
        {
          value: this.$t("mindmap.SpecifyGanttChart"),
          label: this.$t("mindmap.SpecifyGanttChart"),
          children: [],
        },
        {
          value: this.$t("canvas.rightClick.baseline"),
          label: this.$t("canvas.rightClick.baseline"),
          children: [],
        },
        {
          value: this.$t("canvas.rightClick.targetVersion"),
          label: this.$t("canvas.rightClick.targetVersion"),
          children: [],
        },
        {
          value: "others",
          label: this.$t("canvas.rightClick.others"),
          children: [],
        },
        {
          value: this.$t("canvas.rightClick.testPlan"),
          label: this.$t("canvas.rightClick.testPlan"),
          children: [],
        },
        {
          value: this.$t("canvas.btn.executeTestPlan"),
          label: this.$t("canvas.btn.executeTestPlan"),
        },
        {
          value: this.$t("canvas.rightClick.addRelatedNode"),
          label: this.$t("canvas.rightClick.addRelatedNode"),
        },
        {
          value: "moveNodes",
          label: this.$t("canvas.rightClick.moveNode"),
        },
        {
          value: "copyKey",
          label: this.$t("canvas.rightClick.copyKey"),
        },
        {
          value: "BatchArchiving",
          label: this.$t("canvas.rightClick.BatchArchiving"),
        },
      ],
      // 状态下拉框内容
      statusOptions: [
        {
          label: "Open",
          value: "open",
        },
      ],
      // 其他字段选中的leaf
      checkedLeaf: {
        newValue: "",
      },
      // 用户列表
      user_list: [],
      user_loading: false,
      test_nodes: [],
      fileResult: {},
      KANBAN_value: false,
      // 节点批量存档相关
      nodeVersionDialog: false,
      saveVersionBtnLoading: false,
      nodeVersionForm: {
        version: "",
        desc: "",
      },
    };
  },
  watch: {
    nodeData(newValue) {
      this.show = false;
      if (newValue.length === 1) {
        let num = newValue[0].data.layer - 1;
        if (num + 1 > this.fileResult.fileTypeLayerList.length) {
          num = this.fileResult.fileTypeLayerList.length - 1;
        }
      }
    },
    relation_options(newValue, oldValue) {
      oldValue.forEach((item) => {
        if (!this.inAllOptions(item.nodeKey)) {
          this.allOptions.push(item);
        }
      });
    },
  },
  computed: {
    ...mapGetters(["project_type", "file_type_id", "status", "fileType"]),
    test_options() {
      let executionStatusItem = sessionStorage.getItem("_execution_status");
      let testStatus = executionStatusItem
        ? Object.values(JSON.parse(executionStatusItem))
        : [];
      let li = [];
      testStatus.forEach((e) => {
        e.complete ? li.push(e) : "";
      });
      return li;
    },
    filteredOptions() {
      return this.options.filter((item) => {
        if (
          item.value == this.$t("canvas.btn.executeTestPlan") ||
          item.value == this.$t("canvas.rightClick.testPlan")
        ) {
          const typeMatch = this.fileType[this.file_type_id];
          return typeMatch && typeMatch.testCase;
        }
        return true;
      });
    },
    build_status_list() {
      this.status_list.forEach((item) => {
        const obj = {};
        item.current_status = item.current_status.reduce(function (item, next) {
          obj[next.status] ? "" : (obj[next.status] = true && item.push(next));
          return item;
        }, []);
      });
      this.status_list.forEach((item) => {
        item.current_status.forEach((current) => {
          const arr = [];
          const obj = {};
          current["can_change_status_list"].forEach((item) => {
            if (!obj[item.statusId]) {
              arr.push(item);
              obj[item.statusId] = true;
            }
          });
          current["can_change_status_list"] = arr;
        });
      });
      return this.status_list;
    },
    tableData: {
      get() {
        const table_data = [];
        this.build_status_list.forEach((item) => {
          item.current_status.forEach((current) => {
            const obj = current;
            obj.layer = item.layer;
            obj.change_checked = "";
            table_data.push(obj);
          });
        });
        return table_data;
      },
      set(val) {},
    },
    merge_obj() {
      const obj = {
        spanArr: [],
        span: 0,
      };
      for (let i = 0; i < this.tableData.length; i++) {
        if (i === 0) {
          //第一行必须存在，以第一行为基准
          obj.spanArr.push(1);
          obj.span = 0;
        } else {
          if (this.tableData[i].layer == this.tableData[i - 1].layer) {
            obj.spanArr[obj.span] += 1;
            obj.spanArr.push(0);
          } else {
            obj.spanArr.push(1);
            obj.span = i;
          }
        }
      }
      return obj;
    },
    //临时代码
    isshowgantt() {
      if (config.allow_gantt) {
        return true;
      } else {
        return (
          this.$store.state.project.tenantId === config.allow_gantt_tenantId
        );
      }
    },
  },
  mounted() {
    const params = {
      projectId: this.get_pid(),
    };
    this.getlayerConfig();
  },
  methods: {
    // 节点批量存档
    saveVersion() {
      this.$refs["nodeVersionform"].validate(async (valid) => {
        if (valid) {
          const arr = [];
          this.nodeData.forEach((node) => {
            const obj = {
              nodeKey: node.data.key,
              remark: this.nodeVersionForm.desc,
              archiveName: `${
                this.nodeVersionForm.version
              }[${new Date().getTime()}]`,
            };
            arr.push(obj);
          });
          this.saveVersionBtnLoading = true;
          const params = {
            projectId: this.get_pid(),
            data: arr,
          };
          saveArchive(params)
            .then((res) => {
              this.saveVersionBtnLoading = false;
              this.nodeVersionDialog = false;
              this.$message({
                type: "success",
                message: this.$t("chart.message.saveSuccess"),
              });
              this.nodeVersionForm = {
                version: "",
                desc: "",
              };
            })
            .catch((err) => {
              this.saveVersionBtnLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    batchNodeInput(value) {
      this.batchNodeStr = value.replace(/[\n\s，、/\\]+/g, ",");
    },
    add_batch_relation() {
      this.batchLoading = true;
      const params = {
        projectId: this.get_pid(),
        data: {
          sourceNodeList: [],
          targetNodeKeyList: this.batchNodeStr.split(",").filter((item) => {
            return item;
          }),
        },
      };
      this.nodeData.forEach((node) => {
        const obj = {
          projectId: this.get_pid(),
          nodeKey: node.data.key,
          fileTypeId: node.data.fileTypeId,
        };
        params.data.sourceNodeList.push(obj);
      });
      add_batch_relation_node(params)
        .then(() => {
          this.batchNodeStr = "";
          // 节点关联关系变化事件
          this.batchLoading = false;
          this.$message.success(
            this.$t("homeTopBar.newProjectForm.message.addSuccess")
          );
          sessionStorage.removeItem("relation_visible");
          // 节点关联关系变化事件
          vmson.$emit("node_relation_change");
          this.relation_visible = false;
        })
        .catch((err) => {});
    },
    async kanbanFetch(node) {
      const params = {
        projectId: this.get_pid(),
        data: [node.data.key],
      };
      await kanbanFetch(params).then((res) => {
        if (!res) {
          this.KANBAN_value = false;
        } else {
          this.KANBAN_value = true;
        }
      });
    },
    getlayerConfig() {
      get_filetype_detail(
        this.get_pid(),
        this.$store.getters.file_type_id
      ).then((res) => {
        this.fileResult = res;
      });
    },
    relation_close(done) {
      sessionStorage.removeItem("relation_visible");
      done();
    },
    shouldShow(item) {
      if (
        item.value == this.$t("canvas.btn.executeTestPlan") ||
        item.value == this.$t("canvas.rightClick.testPlan")
      ) {
        const matchingType = this.fileType.find(
          (fileTypeItem) =>
            fileTypeItem.id === this.file_type_id && fileTypeItem.testCase
        );
        return Boolean(matchingType);
      }
      return true;
    },
    cut(str) {
      if (str.length > 15) {
        return str.slice(0, 14) + "...";
      } else {
        return str;
      }
    },
    relation_clearData() {
      if (this.relation_value.length == 0) this.relation_options = [];
    },
    get_unrelation_node(query) {
      if (query !== "") {
        this.relation_loading = true;
        searchNodesByFuzzyKey(query)
          .then((res) => {
            this.relation_options = res;
            this.relation_loading = false;
          })
          .catch((err) => (this.relation_loading = false));
      } else {
        this.relation_options = [];
      }
    },
    inAllOptions(nodeKey) {
      let flag = false;
      this.allOptions.forEach((option) => {
        if (option.nodeKey === nodeKey) {
          flag = true;
        }
      });
      return flag;
    },
    add_relation() {
      this.batchLoading = true;
      let arr = [];
      this.relation_options.forEach((option) => {
        if (!this.inAllOptions(option.nodeKey)) {
          this.allOptions.push(option);
        }
      });
      this.allOptions.forEach((item) => {
        if (this.relation_value.indexOf(item.nodeKey) !== -1) {
          arr.push({
            sourceFileTypeId: this.file_type_id,
            targetFileTypeId: item.fileTypeId,
            targetNodeKey: item.nodeKey,
            sourceProjectId: this.get_pid(),
            targetProjectId: item.projectId,
          });
        }
      });
      let brr = JSON.parse(JSON.stringify(arr));
      const params = {
        projectId: this.get_pid(),
        data: {
          sourceNodeList: [],
          targetNodeKeyList: [],
        },
      };
      this.nodeData.forEach((node) => {
        const obj = {
          projectId: this.get_pid(),
          nodeKey: node.data.key,
          fileTypeId: node.data.fileTypeId,
        };
        params.data.sourceNodeList.push(obj);
      });
      brr.forEach((node) => {
        params.data.targetNodeKeyList.push(node.targetNodeKey);
      });
      add_batch_relation_node(params)
        .then(() => {
          this.batchNodeStr = "";
          // 节点关联关系变化事件
          this.batchLoading = false;
          this.$message.success(
            this.$t("homeTopBar.newProjectForm.message.addSuccess")
          );
          sessionStorage.removeItem("relation_visible");
          // 节点关联关系变化事件
          vmson.$emit("node_relation_change");
          this.relation_visible = false;
        })
        .catch((err) => {});
    },
    limits_of_authority_disable,
    click_children(child) {
      if (
        this.item_click == this.$t("mindmap.SpecifySprint") &&
        child.value == this.selected_const
      ) {
        this.selected = "";
        this.selected_const = "";
        this.delete_node_sprint(child.value);
      } else if (
        this.item_click == this.$t("mindmap.SpecifyGanttChart") &&
        child.value == this.selected_const
      ) {
        this.delete_node_gantt(child.value);
      } else if (this.item_click == "zdh_pingshen") {
        console.log(child);
        // const arr = this.nodeData.length ? this.nodeData : [this.last_node];
        vmson.$emit("many_pingshen_word",{type:child.value});
      } else if (
        this.item_click == this.$t("mindmap.SpecifyGanttChart") &&
        this.selected_const &&
        child.value !== this.selected_const
      ) {
        // 移动
        let sourceGanttChartUuid = this.selected;
        this.selected = "";
        this.selected_const = "";
        this.change_node_ganttChart(child.value, true, sourceGanttChartUuid);
      } else {
        this.selected = child.value;
        this.selected_const = JSON.parse(JSON.stringify(child.value));
        if (this.item_click == this.$t("canvas.rightClick.baseline")) {
          this.add_baseline(child.value);
        }
        if (this.item_click == this.$t("canvas.rightClick.targetVersion")) {
          this.add_version(child.value);
        }
        if (this.item_click == this.$t("canvas.rightClick.testPlan")) {
          this.add_testplan(child.value);
        }
        if (this.item_click == this.$t("mindmap.SpecifySprint")) {
          this.change_node_sprint(child.value);
        }
        if (this.item_click == this.$t("mindmap.SpecifyGanttChart")) {
          this.change_node_ganttChart(child.value, false);
        }
        if (this.item_click == this.$t("canvas.rightClick.others")) {
          let nodeData = child;
          Object.assign(this.checkedLeaf, nodeData, { newValue: "" });
          this.user_list = [];
          if (nodeData.fieldType === "SINGLE_USER_SELECT") {
            this.getUserList();
          } else if (
            nodeData.fieldType === "SELECT" ||
            nodeData.fieldType === "SELECT_MULTI"
          ) {
            this.user_list = this.checkedLeaf.extraData;
          } else if (nodeData.fieldType === "MULTI_USER_SELECT") {
            this.getUserList();
          }
          this.otherDialogVisible = true;
          this.show = false;
        }
      }
    },
    click_item(item) {
      const flag =
        item.label === this.$t("canvas.rightClick.moveNode")
          ? this.limits_of_authority_disable("NODE_MOVE")
          : this.limits_of_authority_disable("NODE_EDIT");
      if (flag) return;
      this.right_show = false;
      if (item.value == "assignee") {
        this.getUserList();
        this.assignee_visible = true;
        this.show = false;
      }
      if (item.value == this.$t("canvas.rightClick.addRelatedNode")) {
        // if (this.nodeData.length > 1) {
        //   this.relation_visible = true;
        //   sessionStorage.setItem("relation_visible", 1);
        //   this.show = false;
        // } else {
        //   vmson.$emit("showRelationDialog", this.nodeData[0].data.key);
        //   this.show = false;
        // }
        this.relation_visible = true;
        // sourceNodeKey
        this.relation_value = [];
        sessionStorage.setItem("relation_visible", 1);
        this.show = false;
      }
      if (item.value == "stateChange") {
        this.tableData = [];
        this.info_status = [];
        this.getLayers();
        this.dialogVisible = true;
        this.show = false;
      }
      if (item.value == "zdh_pingshen") {
        this.item_click = "zdh_pingshen";
        this.selected = "";
        this.selected_const = "";
        this.right_show = true;
        this.select_children = [];
        this.select_children = item.children;
      }
      if (item.value == "moveNodes") {
        if (this.nodeData.length > 1) {
          this.$message.warning(this.$t("canvas.messages.error3"));
        } else {
          this.moveNodesDialogVisible = true;
          this.show = false;
        }
      }
      if (item.value == this.$t("canvas.btn.executeTestPlan")) {
        if (
          !document
            .getElementById("global_variable_select_testplan")
            .innerText.replace(/^\s*|\s*$/g, "")
        ) {
          this.$message.warning(this.$t("canvas.testPlan.message.selectWarn"));
          return false;
        }
        get_test_nodes(
          this.get_pid(),
          document
            .getElementById("global_variable_select_testplan")
            .innerText.replace(/^\s*|\s*$/g, "")
        ).then((res) => {
          this.test_nodes = [];
          res.forEach((node) => {
            this.test_nodes.push(node.nodeKey);
          });
          this.test_show = true;
          this.show = false;
        });
      }
      if (item.value == this.$t("canvas.rightClick.targetVersion")) {
        this.item_click = this.$t("canvas.rightClick.targetVersion");
        this.selected = "";
        this.selected_const = "";
        this.right_show = true;
        this.right_loading = true;
        this.select_children = [];
        let list = Object.values(
          JSON.parse(sessionStorage.getItem("_version"))
        );
        list.forEach((item) => {
          if (!item.status) {
            const obj = Object.assign({}, item, {
              label: item.name + "(" + this.switch_status(item.status) + ")",
              value: item.versionId,
            });
            this.select_children.push(obj);
          }
        });
        this.right_loading = false;
      }
      if (item.value == this.$t("canvas.rightClick.baseline")) {
        this.item_click = this.$t("canvas.rightClick.baseline");
        this.selected = "";
        this.selected_const = "";
        this.right_show = true;
        this.right_loading = true;
        this.select_children = [];
        Object.values(JSON.parse(sessionStorage.getItem("_baseLine"))).forEach(
          (e) => {
            e.status == "COMPLETE"
              ? ""
              : this.select_children.push({
                  label: e.name + "(" + this.switch_status(e.status) + ")",
                  value: e.baselineId,
                  status: e.status,
                });
          }
        );

        this.select_children.sort((a, b) => {
          if (
            a.status === "ALREADY_STARTED" &&
            b.status !== "ALREADY_STARTED"
          ) {
            return -1;
          } else if (
            a.status !== "ALREADY_STARTED" &&
            b.status === "ALREADY_STARTED"
          ) {
            return 1;
          } else {
            return 0;
          }
        });

        this.right_loading = false;
      }
      if (item.value == this.$t("mindmap.SpecifySprint")) {
        this.item_click = this.$t("mindmap.SpecifySprint");
        this.selected = "";
        this.selected_const = "";
        this.right_show = true;
        this.right_loading = true;
        const map_arr = {
          IN_PROGRESS: this.$t("canvas.testPlan.status.inProgress"),
          NOT_STARTED: this.$t("canvas.testPlan.status.notStarted"),
          ENDED: this.$t("canvas.testPlan.status.completed"),
        };
        this.sprint_data = [];
        Object.values(JSON.parse(sessionStorage.getItem("_sprint"))).forEach(
          (e) => {
            e.status == "ENDED"
              ? ""
              : this.sprint_data.push({
                  label: e.name + "(" + map_arr[e.status] + ")",
                  value: e.sprintId,
                  status: e.status,
                });
          }
        );

        this.sprint_data.sort((a, b) => {
          if (a.status === "IN_PROGRESS" && b.status !== "IN_PROGRESS") {
            return -1;
          } else if (a.status !== "IN_PROGRESS" && b.status === "IN_PROGRESS") {
            return 1;
          } else {
            return 0;
          }
        });

        const arr = this.nodeData.length ? this.nodeData : [this.last_node];
        if (arr.length === 1) {
          get_node_use_node_key(this.get_pid(), arr[0].data.key).then((res) => {
            if (res.sprintId) {
              this.selected = res.sprintId;
              this.selected_const = res.sprintId;
              this.right_loading = false;
              this.select_children = JSON.parse(
                JSON.stringify(this.sprint_data)
              );

              let element = this.select_children.filter(
                (item) => item.value === this.selected
              )[0];

              if (element) {
                this.select_children = this.select_children.filter(
                  (item) => item.value !== this.selected
                );
                this.select_children.unshift(element);
              }
            } else {
              this.right_loading = false;
              this.select_children = JSON.parse(
                JSON.stringify(this.sprint_data)
              );
            }
          });
        } else {
          this.right_loading = false;
          this.select_children = JSON.parse(JSON.stringify(this.sprint_data));
        }
      }
      if (item.value == this.$t("mindmap.SpecifyGanttChart")) {
        this.item_click = this.$t("mindmap.SpecifyGanttChart");
        this.selected = "";
        this.selected_const = "";
        this.selected_ganttItem = "";
        this.right_show = true;
        this.right_loading = true;
        let ganttChartList = [];
        const arr = this.nodeData.length ? this.nodeData : [this.last_node];
        getAllGanttChart(this.get_pid())
          .then((res) => {
            res.forEach((item) => {
              let chart_gantt = {
                label: item.name,
                value: item.ganttChartId,
              };
              ganttChartList.push(chart_gantt);
            });
            this.right_loading = false;
            this.select_children = ganttChartList;
          })
          .then((res) => {
            return get_ganttnode_by_node(this.get_pid(), arr[0].data.key);
          })
          .then((res) => {
            if (arr.length === 1) {
              this.selected = res.ganttItemInfo.ganttChartUuid;
              this.selected_const = res.ganttItemInfo.ganttChartUuid;
              this.selected_ganttItem = res.ganttItemInfo.ganttItemUuid;
            }
          });
      }
      if (item.value == this.$t("canvas.rightClick.testPlan")) {
        this.item_click = this.$t("canvas.rightClick.testPlan");
        this.selected = "";
        this.selected_const = "";
        this.right_show = true;
        this.right_loading = true;
        let li = Object.values(JSON.parse(sessionStorage.getItem("_testPlan")));
        let list = [];
        li.forEach((item) => {
          if (item.status == "NOT_STARTED") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.notStarted") +
              ")";
            list.push(item);
          } else if (item.status == "IN_PROGRESS") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.inProgress") +
              ")";
            list.push(item);
          } else if (item.status == "COMPLETE") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.completed") +
              ")";
          }
        });
        this.select_children = [];
        list.forEach((item) => {
          const obj = Object.assign({}, item, {
            label: item.name + "(" + this.switch_status(item.status) + ")",
            value: item.testPlanId,
          });
          this.select_children.push(obj);
        });
        this.right_loading = false;
      }
      if (item.value == "others") {
        this.item_click = this.$t("canvas.rightClick.others");
        this.selected = "";
        this.selected_const = "";
        this.right_show = true;
        this.select_children = JSON.parse(JSON.stringify(this.others_data));
      }
      if (item.value == this.$t("mindmap.RemovefromKanban")) {
        const params = {
          projectId: this.get_pid(),
          nodeKeys: this.nodeData.map((item) => {
            return item.data.key;
          }),
        };
        delete_kanban_nodes(params).then((res) => {
          this.$message({
            type: "success",
            message: this.$t("quick.Removalsuccessful"),
          });

          this.$emit("closeRight", true);
        });
      }
      if (item.value == this.$t("mindmap.JoinKanban")) {
        const params = {
          projectId: this.get_pid(),
          data: this.nodeData.map((item) => {
            return item.data.key;
          }),
        };
        add_kanban_nodes(params).then((res) => {
          this.$message({
            type: "success",
            message: this.$t("quick.Joinsuccessfully"),
          });

          this.$emit("closeRight", true);
        });
      }
      if (item.value == "copyKey") {
        // 创建一个 Input标签
        const cInput = document.createElement("input");
        cInput.value = ``;
        this.nodeData.forEach((item) => {
          cInput.value += item.data.key;
          cInput.value += ",";
        });
        document.body.appendChild(cInput);
        cInput.select(); // 选取文本域内容;
        // 执行浏览器复制命令
        // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
        // Input要在正常的编辑状态下原生复制方法才会生效
        document.execCommand("Copy");
        this.$message.success(this.$t("personal.openApi.message3"));
        /// 复制成功后再将构造的标签 移除
        cInput.remove();
        this.$emit("closeRight", true);
      }

      if (item.value == "BatchArchiving") {
        this.nodeVersionDialog = true;
      }
    },
    save_test() {
      if (!this.test_checked) {
        this.$message.warning(this.$t("placeholder.dropdown"));
        return false;
      }
      this.test_show = false;
      const arr = this.nodeData.length ? this.nodeData : [this.last_node];
      const execute_data = {
        status: this.test_checked,
      };
      let num = 0;
      for (let i of arr) {
        let key = i.data.key;
        if (this.test_nodes.indexOf(key) > -1) {
          num += 1;
          execute_test_node(
            this.get_pid(),
            document
              .getElementById("global_variable_select_testplan")
              .innerText.replace(/^\s*|\s*$/g, ""),
            key,
            execute_data
          ).then((res) => {
            get_test_nodes(
              this.get_pid(),
              document
                .getElementById("global_variable_select_testplan")
                .innerText.replace(/^\s*|\s*$/g, "")
            ).then((res) => {
              this.$vmson.$emit("close_right_word", "***");
              this.$vmson.$emit("operation", "***");
              let fflag = true;
              let testStatus = JSON.parse(
                sessionStorage.getItem("_execution_status")
              );
              for (let i of res) {
                if (!testStatus[i.testCaseStatus].complete && fflag) {
                  fflag = false;
                  enter_process(
                    this.get_pid(),
                    document
                      .getElementById("global_variable_select_testplan")
                      .innerText.replace(/^\s*|\s*$/g, "")
                  ).then((res) => {
                    this.$store.dispatch("setTestPlan", this.get_pid());
                    this.$vmson.$emit("operation", "***");
                  });
                }
              }
            });
          });
        }
      }
      if (num == 0) {
        this.$message.info(this.$t("canvas.messages.error4"));
      } else if (num == arr.length) {
        this.$message.success(this.$t("canvas.messages.success7"));
      } else {
        this.$message.success(
          this.$t("canvas.messages.info4") +
            `${num}` +
            this.$t("canvas.messages.info5") +
            `${arr.length - num}` +
            this.$t("canvas.messages.info6")
        );
      }
    },
    get_file() {
      let res = {};
      Promise.all([
        getFile(this.get_pid(), this.$store.getters.file_key),
        getReleaseElements(this.get_pid(), this.$route.params.file_key),
      ]).then((result) => {
        // console.log("result", result);
        result[0].data.forEach((item) => {
          result[1].forEach((i) => {
            if (i.nodeKey === item.key) {
              if (i.sprintId) {
                item["sprintId"] = i.sprintId;
              }
              i.baselineItemDtoList &&
                i.baselineItemDtoList.forEach((baseline) => {
                  if (baseline.lock && baseline.status === "ALREADY_STARTED") {
                    item["lock"] = baseline.lock;
                  }
                });
            }
          });
        });
        vmson.$emit("close_right_word", "***");
        res = result[0];
        this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
      });
    },
    get_test() {
      let li = Object.values(JSON.parse(sessionStorage.getItem("_testPlan")));
      let list = [];
      li.forEach((item) => {
        if (item.status == "NOT_STARTED") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.notStarted") +
            ")";
          list.push(item);
        } else if (item.status == "IN_PROGRESS") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.inProgress") +
            ")";
          list.push(item);
        } else if (item.status == "COMPLETE") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.completed") +
            ")";
        }
      });
      this.options[5].children = [];
      list.forEach((item) => {
        const obj = Object.assign({}, item, {
          label: item.name + "(" + this.switch_status(item.status) + ")",
          value: item.testPlanId,
        });
        this.options[5].children.push(obj);
      });
    },
    add_testplan(testplanId) {
      const arr = this.nodeData.length ? this.nodeData : [this.last_node];
      const keys = [];
      arr.forEach((item) => {
        keys.push(item.data.key);
      });
      add_test_node(this.get_pid(), keys, testplanId).then((res) => {
        this.get_file();
        this.show = false;
        this.$message({
          type: "success",
          message: this.$t("canvas.messages.success6"),
        });
      });
    },
    get_version() {
      this.options[4].children = [];
      let list = Object.values(JSON.parse(sessionStorage.getItem("_version")));
      list.forEach((item) => {
        if (!item.status) {
          const obj = Object.assign({}, item, {
            label: item.name + "(" + this.switch_status(item.status) + ")",
            value: item.versionId,
          });
          this.options[4].children.push(obj);
        }
      });
    },
    add_version(versionId) {
      const arr = this.nodeData.length ? this.nodeData : [this.last_node];
      const keys = [];
      arr.forEach((item) => {
        keys.push(item.data.key);
      });
      version_add_nodes(this.get_pid(), versionId, keys).then((res) => {
        this.get_file();
        this.show = false;
        this.$message({
          type: "success",
          message: this.$t("canvas.messages.success5"),
        });
      });
    },
    add_baseline(baselineId) {
      const arr = this.nodeData.length ? this.nodeData : [this.last_node];
      const keys = [];
      arr.forEach((item) => {
        keys.push(item.data.key);
      });
      baseline_add_nodes(this.get_pid(), baselineId, keys).then((res) => {
        this.get_file();
        this.show = false;
        this.$message({
          type: "success",
          message: this.$t("canvas.messages.success4"),
        });
      });
    },
    delete_node_sprint(sprintId) {
      const params = {
        projectId: this.get_pid(),
        sprintId: sprintId,
        nodeKey: this.nodeData[0].data.key,
      };
      delete_sprint_nodes(params).then((res) => {
        this.show = false;
        this.$message({
          type: "success",
          message: this.$t("canvas.messages.success3"),
        });
        this.get_file();
      });
    },
    delete_node_gantt() {
      let params = {
        projectId: this.get_pid(),
        ganttItemIdList: [this.selected_ganttItem],
        ganttChartId: this.selected,
      };
      deleteNodeList(params).then((res) => {
        this.$message.success(this.$t("mindmap.NodeRemovedFromGantt"));
        this.selected = "";
        this.selected_const = "";
        this.selected_ganttItem = "";
      });
    },
    change_node_sprint(sprintId) {
      const arr = this.nodeData.length ? this.nodeData : [this.last_node];
      const keys = [];
      arr.forEach((item) => {
        keys.push(item.data.key);
      });
      const params = {
        projectId: this.get_pid(),
        sprintId: sprintId,
        data: keys,
      };
      add_sprint_nodes(params).then((res) => {
        this.show = false;
        this.$message({
          type: "success",
          message: this.$t("canvas.messages.success2"),
        });
        this.get_file();
      });
    },
    change_node_ganttChart(chartId, isMoving, sourceGanttChartUuid) {
      if (isMoving) {
        //移动节点
        move_ganttnode_to_newchart(
          this.get_pid(),
          this.selected_ganttItem,
          chartId,
          sourceGanttChartUuid
        ).then((res) => {
          this.show = false;
          this.$message.success(this.$t("mindmap.MoveNodeToGanttSuccess"));
          this.selected = chartId;
          this.selected_const = chartId;
        });
      } else {
        //添加节点
        const arr = this.nodeData.length ? this.nodeData : [this.last_node];
        const keys = [];
        arr.forEach((item) => {
          keys.push(item.data.key);
        });
        add_ganttnode_by_nodekey(this.get_pid(), this.selected, keys).then(
          (res) => {
            this.show = false;
            if (res.successNodeKey) {
              this.$message.success(this.$t("mindmap.AddNodeToGanttSuccess"));
            }
            if (res.ignoreNodeKey) {
              this.$nextTick(() => {
                this.$message.info(
                  `${res.ignoreNodeKey}` + this.$t("mindmap.NodeExistInGantt")
                );
              });
            }
          }
        );
      }
    },
    get_sprint() {
      if (this.project_type === "SCRUM") {
        const map_arr = {
          IN_PROGRESS: this.$t("canvas.testPlan.status.inProgress"),
          NOT_STARTED: this.$t("canvas.testPlan.status.notStarted"),
          ENDED: this.$t("canvas.testPlan.status.completed"),
        };
        this.sprint_data = [];
        Object.values(JSON.parse(sessionStorage.getItem("_sprint"))).forEach(
          (e) => {
            e.status == "ENDED"
              ? ""
              : this.sprint_data.push({
                  label: e.name + "(" + map_arr[e.status] + ")",
                  value: e.sprintId,
                });
          }
        );
        const arr = this.nodeData.length ? this.nodeData : [this.last_node];
        if (arr.length === 1) {
          get_node_use_node_key(this.get_pid(), arr[0].data.key).then((res) => {
            if (res.sprintId) {
              this.selected = res.sprintId;
              this.selected_const = res.sprintId;
              this.item_click = this.$t("mindmap.SpecifySprint");
              this.right_show = true;
              this.select_children = JSON.parse(
                JSON.stringify(this.sprint_data)
              );
            }
          });
        }
      } else {
        this.options.splice(2, 1);
      }
    },
    // 改变状态方法
    change_status(e, row) {
      let flag = true;
      this.multiLayers_change_status_params.data.forEach((item) => {
        if (item.layer === row.layer) {
          flag = false;
          //判断是否已经有了这个sourceid
          const has = item.transformList.filter((one) => {
            return one.sourceStatusId == row.status;
          });
          has.length
            ? (has[0].targetStatusId = e)
            : item.transformList.push({
                sourceStatusId: row.status,
                targetStatusId: e,
              });
        }
      });
      if (flag) {
        this.multiLayers_change_status_params.data.push({
          layer: row.layer,
          transformList: [
            {
              sourceStatusId: row.status,
              targetStatusId: e,
            },
          ],
        });
      }
    },
    //获取层数
    only_getLayers(node) {
      if (this.nodeData.length) {
        const arr = [];
        this.nodeData.forEach((item) => {
          arr[item.data.layer] = item.data.layer;
        });
        return arr.filter((num) => {
          return isNumber(num);
        });
      } else {
        return [node.data.layer];
      }
    },
    //获取层数
    getLayers() {
      const arr = [];
      this.multiLayers_change_status_params = {
        projectId: "",
        nodeKeys: [],
        data: [],
      };
      this.node_keys = [];
      const node_data = this.nodeData.length ? this.nodeData : [this.last_node];
      node_data.forEach(async (item) => {
        this.node_keys.push(item.data);
        const obj = {
          status: item.data.status,
          statusName: this.status[item.data.status].name,
          can_change_status_list: [],
        };
        if (!arr[item.data.layer]) {
          arr[item.data.layer] = {
            layer: item.data.layer,
            current_status: [obj],
          };
        } else {
          arr[item.data.layer].current_status.push(obj);
        }
        const res = await get_workflow_list({
          projectId: this.get_pid(),
          fileTypeId: item.data.fileTypeId,
          layer: item.data.layer,
          currentStatusId: item.data.status,
        });
        arr[item.data.layer].current_status.forEach((current) => {
          if (current.status === item.data.status) {
            current.can_change_status_list.push(...res);
          }
        });
        this.status_list = arr.filter((item) => {
          if (item) return item;
        });
      });
    },
    getUserList() {
      this.user_loading = true;
      this.user_list = [];
      get_allProjectMember(this.get_pid())
        .then((res) => {
          let my = {};
          let li = [];
          res.forEach((ele) => {
            let obj = {
              label: ele.nickname,
              value: ele.accountId,
              userAccountId: ele.accountId,
              avatar: ele.avatar,
              deleted: ele.deleted,
            };
            ele.accountId == this.$store.getters.userAccountId
              ? (my = obj)
              : li.push(obj);
          });
          this.user_list = [];
          this.user_list.push(my);
          this.user_list.push(...li);
          this.user_loading = false;
        })
        .catch((err) => console.log(err));
    },
    //确定保存状态
    saveState() {
      const layer_list = this.multiLayers_change_status_params.data.map(
        (item) => {
          return item.layer;
        }
      );
      const params_node_keys = this.node_keys.filter((item) => {
        if (layer_list.indexOf(item.layer) !== -1) {
          return true;
        }
      });
      const params = this.multiLayers_change_status_params;
      params.nodeKeys = params_node_keys.map((item) => {
        return item.key;
      });
      params.projectId = this.get_pid();
      multiLayers_change_status(params).then((res) => {
        if (res.ok) {
          // 转换成功
          this.dialogVisible = false;
          getFile(this.get_pid(), this.$store.getters.file_key).then((res) => {
            this.$message({
              type: "success",
              message: this.$t("addLogin.resetEmail.message.p1"),
              duration: "5000",
            });
            this.$store.commit("SET_EXIST_MINDMAP", res);
            // this.info_status=[]
            vmson.$emit("close_right_word", "***");
            this.$emit("closeRight", true);
          });
        } else {
          // 转换失败
          this.dialogVisible = false;
          this.$message({
            type: "error",
            message: res.message,
            duration: "5000",
          });
        }
      });
    },
    //确定保存其他状态
    saveOthers() {
      const nodeKeys = this.nodeData.map((item) => {
        return item.data.key;
      });
      // 多用户和多选项为空的判断
      if (
        this.checkedLeaf.fieldType == "MULTI_USER_SELECT" ||
        this.checkedLeaf.fieldType == "SELECT_MULTI"
      ) {
        let tepData = this.checkedLeaf.newValue || [];
        const params = {
          projectId: this.get_pid(),
          data: {
            nodeKeyList: nodeKeys,
            isCustom: this.checkedLeaf.isCustom,
            fieldId: this.checkedLeaf.fieldId,
            value: tepData,
          },
        };
        putBatchField(params).then((res) => {
          if (res) {
            this.$message({
              message: this.$t("canvas.messages.editSuccess"),
              type: "success",
            });
          } else {
            this.$message({
              type: "warning",
              message: this.$t("canvas.messages.editWarning"),
            });
          }
          this.otherDialogVisible = false;
          // this.$emit("closeRight", true);
          getFile(this.get_pid(), this.$store.getters.file_key).then((res) => {
            this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
            vmson.$emit("close_right_word", "***");
            // vmson.$emit("mindmap_update");
            vmson.$emit("node_relation_change");
            this.$emit("closeRight", true);
          });
        });
      } else {
        const params = {
          projectId: this.get_pid(),
          data: {
            nodeKeyList: nodeKeys,
            isCustom: this.checkedLeaf.isCustom,
            fieldId: this.checkedLeaf.fieldId,
            value: this.checkedLeaf.newValue,
          },
        };
        putBatchField(params).then((res) => {
          if (res) {
            this.$message({
              message: this.$t("canvas.messages.editSuccess"),
              type: "success",
            });
          } else {
            this.$message({
              type: "warning",
              message: this.$t("canvas.messages.editWarning"),
            });
          }
          this.otherDialogVisible = false;
          // this.$emit("closeRight", true);
          getFile(this.get_pid(), this.$store.getters.file_key).then((res) => {
            this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
            vmson.$emit("close_right_word", "***");
            // vmson.$emit("mindmap_update");
            vmson.$emit("node_relation_change");
            this.$emit("closeRight", true);
          });
        });
      }
    },
    clearCheckedNodes() {
      this.cascader_model = "";
      this.$refs.panel.clearCheckedNodes();
    },
    //状态改变弹窗展示
    stateChange() {
      this.tableData = [];
      this.info_status = [];
      this.getLayers();
      this.show = false;
      this.dialogVisible = true;
      this.clearCheckedNodes();
    },
    // 移动节点弹窗展示
    moveNodes() {
      if (this.nodeData.length > 1) {
        this.$alert(
          this.$t("canvas.messages.error3"),
          this.$t("canvas.fileDropDown.deleteForm.title"),
          {
            type: "warning",
          }
        );
        this.clearCheckedNodes();
      } else {
        this.show = false;
        this.moveNodesDialogVisible = true;
        this.clearCheckedNodes();
      }
    },
    // 远程获取模糊搜索的结果
    get_move_nodes_options(query) {
      this.moveNodesOptionsLoading = true;
      get_nodes_by_fuzzykey(this.get_pid(), query).then((res) => {
        this.moveNodesOptionsLoading = false;
        this.moveNodesOptions = res.filter((item) => {
          return item.fileId !== this.nodeData[0].data.fileId;
        });
      });
    },
    // 确定移动节点
    async confirmMoveNodes() {
      const { fileTypeId, fileId, layer, key } = this.nodeData[0].data;

      const res = await get_node_use_node_key(this.get_pid(), this.moveNodesID);
      this.$confirm(
        // this.$t('canvas.messages.info1') + `${this.moveNodesID}` + this.$t('canvas.messages.info2') + `${this.moveNodesID}` + this.$t('canvas.messages.info3') ,
        this.$t("mindmap.ConfirmMoveNode1") +
          `${this.moveNodesID}` +
          this.$t("mindmap.ConfirmMoveNode2") +
          `<br>` +
          this.$t("mindmap.ConfirmMoveNode3"),
        this.$t("canvas.fileDropDown.deleteForm.title"),
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t("btn.confirmBtn"),
          cancelButtonText: this.$t("btn.cancelBtn"),
          type: "warning",
        }
      ).then(() => {
        let sourceNodeId = (
          new Date().getTime().toString(16) +
          Math.random().toString(16).substr(2)
        ).substr(2, 16);
        move_nodes_though_files(
          this.get_pid(),
          key,
          fileId,
          res.layer + 1,
          this.moveNodesID,
          res.fileId,
          res.isroot ? sourceNodeId : undefined
        ).then(async (res) => {
          this.$message({
            type: "success",
            message: this.$t("canvas.messages.success1"),
          });
          // 获得新导图并渲染
          this.is_file_exist = await get_file_exist(
            this.get_pid(),
            this.$route.params.file_key
          );
          // 文件不存在,跳转文件页,弹出警告
          if (!this.is_file_exist) {
            this.$router.push({
              path: `/${this.get_pid()}/home/my_file`,
            });
          } else {
            vmson.$emit("close_right_word", "***");
            this.$emit("closeRight", true);
          }
        });
        this.moveNodesDialogVisible = false;
        this.moveNodesID = "";
        this.moveNodesOptions = [];
        /**右键浮窗消失 */
        let right_cl = document.getElementById("right_c_w");
        right_cl.style.display = "none";
      });
    },
    //责任人字段修改
    assignee() {
      this.getUserList();
      this.assignee_visible = true;
      this.show = false;
      this.clearCheckedNodes();
    },
    save_assignee() {
      const node_data = this.nodeData.length ? this.nodeData : [this.last_node];
      const node_keys = [];
      node_data.forEach((item) => {
        node_keys.push(item.data.key);
      });
      multiLayers_change_assignee({
        projectId: this.get_pid(),
        nodeKeys: node_keys,
        data: {
          accountId: this.assignee_checked,
        },
      }).then((res) => {
        this.assignee_visible = false;
        Message({
          message: this.$t("canvas.messages.editSuccess"),
          type: "success",
          duration: 5 * 1000,
        });
        // this.$emit("closeRight", true);
        getFile(this.get_pid(), this.$store.getters.file_key).then((res) => {
          this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
          vmson.$emit("close_right_word", "***");
          this.$emit("closeRight", true);
        });
      });
    },
    //其他字段修改
    othersChange(nodeData) {
      Object.assign(this.checkedLeaf, nodeData, { newValue: "" });
      this.user_list = [];
      if (nodeData.fieldType === "SINGLE_USER_SELECT") {
      } else if (nodeData.fieldType === "SELECT") {
        this.user_list = this.checkedLeaf.extraData;
      }
      this.otherDialogVisible = true;
      this.show = false;
      this.clearCheckedNodes();
    },
    //初始化右键菜单树
    async buildTree(node) {
      if (this.project_type !== "SCRUM" && this.nodeData.length <= 1) {
        await this.kanbanFetch(node);
      }

      this.options = [
        {
          value: "stateChange",
          label: this.$t("canvas.rightClick.statusChange"),
        },
        {
          value: "assignee",
          label: this.$t("canvas.rightClick.responsibility"),
        },
        {
          value: this.$t("mindmap.SpecifyGanttChart"),
          label: this.$t("mindmap.SpecifyGanttChart"),
          children: [],
        },
        {
          value: this.$t("canvas.rightClick.baseline"),
          label: this.$t("canvas.rightClick.baseline"),
          children: [],
        },
        {
          value: this.$t("canvas.rightClick.targetVersion"),
          label: this.$t("canvas.rightClick.targetVersion"),
          children: [],
        },
        {
          value: "others",
          label: this.$t("canvas.rightClick.others"),
          children: [],
        },
        {
          value: this.$t("canvas.rightClick.testPlan"),
          label: this.$t("canvas.rightClick.testPlan"),
          children: [],
        },
        {
          value: this.$t("canvas.btn.executeTestPlan"),
          label: this.$t("canvas.btn.executeTestPlan"),
        },
        {
          value: this.$t("canvas.rightClick.addRelatedNode"),
          label: this.$t("canvas.rightClick.addRelatedNode"),
        },
        {
          value: "moveNodes",
          label: this.$t("canvas.rightClick.moveNode"),
        },
        {
          value: "copyKey",
          label: this.$t("canvas.rightClick.copyKey"),
        },
        {
          value: "BatchArchiving",
          label: this.$t("canvas.rightClick.BatchArchiving"),
        },
      ];
      console.log(
        document
          .getElementById("global_variable_in_process_review")
          .innerText.replace(/^\s*|\s*$/g, "") == "true" &&
          document
            .getElementById("global_variable_allowedBatchExec_word")
            .innerText.replace(/^\s*|\s*$/g, "") == "true"
      );

      if (
        document
          .getElementById("global_variable_in_process_review")
          .innerText.replace(/^\s*|\s*$/g, "") == "true" &&
        document
          .getElementById("global_variable_allowedBatchExec_word")
          .innerText.replace(/^\s*|\s*$/g, "") == "true"
      ) {
        this.options.splice(0, 0, {
          value: "zdh_pingshen",
          label: this.$t("canvas.reviewRequestForm.tip9"),
          children: [
            {
              value: "pass",
              label: this.$t("canvas.reviewRequestForm.yes"),
            },
            {
              value: "fail",
              label: this.$t("canvas.reviewRequestForm.no"),
            },
          ],
        });
      }

      if (this.project_type !== "SCRUM") {
        if (this.nodeData.length > 1) {
          this.options.splice(
            2,
            0,
            {
              value: this.$t("mindmap.JoinKanban"),
              label: this.$t("mindmap.JoinKanban"),
            },
            {
              value: this.$t("mindmap.RemovefromKanban"),
              label: this.$t("mindmap.RemovefromKanban"),
            }
          );
        } else {
          this.options.splice(2, 0, {
            value: this.KANBAN_value
              ? this.$t("mindmap.RemovefromKanban")
              : this.$t("mindmap.JoinKanban"),
            label: this.KANBAN_value
              ? this.$t("mindmap.RemovefromKanban")
              : this.$t("mindmap.JoinKanban"),
          });
        }
      } else {
        this.options.splice(2, 0, {
          value: this.$t("mindmap.SpecifySprint"),
          label: this.$t("mindmap.SpecifySprint"),
          children: [],
        });
      }
      this.$nextTick(() => {
        this.show = true;
        this.right_show = false;
      });
      this.item_click = "";
      let flag_test = document
        .getElementById("global_variable_file_test")
        .innerText.replace(/^\s*|\s*$/g, "");
      if (flag_test == "false") {
      } else {
        this.get_test();
      }
      this.$nextTick(() => {
        // this.clearCheckedNodes();
        const params = {
          projectId: this.nodeData[0].data.projectId,
          fileTypeId: this.nodeData[0].data.fileTypeId,
          layerNumbers: this.only_getLayers(node),
        };
        get_multiLayers_context(params).then((res) => {
          this.others_data = [];
          const obj = {};
          res.forEach((parent) => {
            parent.filedItemDtos.forEach((item) => {
              if (!obj[item.fieldId]) {
                obj[item.fieldId] = 1;
                const childObj = Object.assign(item, {
                  label: item.name,
                  value: item.name,
                });
                this.others_data.push(childObj);
              }
            });
          });
        });
      });
      // eslint-disable-next-line no-empty
      // this.get_version();
    },
    check_node_lock(data) {
      let flag = true;
      data.forEach((node) => {
        // console.log(node.data);
        if (node.data.lock) {
          flag = false;
        }
      });
      return flag;
    },
    handleClickNode(leaf) {
      // console.log(leaf);
      let node = this.$refs.panel.getCheckedNodes(true)[0];
      // 初始化
      if (node && leaf._isCancel === undefined) {
        leaf._isCancel = true;
      } else if (!node && leaf._isCancel === undefined) {
        leaf._isCancel = false;
      }
      if (!leaf._isCancel) {
        // 选中
        node = leaf;
        if (!leaf.path.length) return;
        if (!node.hasChildren && this[node.value]) {
          this[node.value]();
        } else if (node.data.fieldType) {
          let flag = true;
          this.nodeData.forEach((node) => {
            if (node.data.lock) {
              flag = false;
            }
          });
          if (flag) {
            this.$nextTick(() => {
              this.othersChange(node.data);
            });
          } else {
            Message({
              message: this.$t("canvas.rightClick.message"),
              type: "warning",
              duration: 5 * 1000,
            });
          }
        }
        if (
          leaf.path.length === 2 &&
          leaf.path[0] === this.$t("mindmap.SpecifySprint")
        ) {
          // console.log("执行选中逻辑");
          this.cascader_model = [...leaf.path];
          this.change_node_sprint(leaf.path[1]);
          leaf._isCancel = true;
        }
        if (
          leaf.path.length === 2 &&
          leaf.path[0] === this.$t("canvas.rightClick.baseline")
        ) {
          this.add_baseline(leaf.path[1]);
          this.cascader_model = [];
        }
        if (
          leaf.path.length === 2 &&
          leaf.path[0] === this.$t("canvas.rightClick.targetVersion")
        ) {
          this.add_version(leaf.path[1]);
          this.cascader_model = [];
        }
        if (
          leaf.path.length === 2 &&
          leaf.path[0] === this.$t("canvas.rightClick.testPlan")
        ) {
          this.add_testplan(leaf.path[1]);
          this.cascader_model = [];
        }
        if (leaf.path[0] === this.$t("canvas.btn.executeTestPlan")) {
          if (
            !document
              .getElementById("global_variable_select_testplan")
              .innerText.replace(/^\s*|\s*$/g, "")
          ) {
            this.$message.warning(
              this.$t("canvas.testPlan.message.selectWarn")
            );
            return false;
          }
          get_test_nodes(
            this.get_pid(),
            document
              .getElementById("global_variable_select_testplan")
              .innerText.replace(/^\s*|\s*$/g, "")
          ).then((res) => {
            this.test_nodes = [];
            res.forEach((node) => {
              this.test_nodes.push(node.nodeKey);
            });
            this.test_show = true;
            this.show = false;
            this.cascader_model = [];
          });
        }
      } else {
        // 取消选中
        // console.log("执行取消逻辑");
        // console.log(this.cascader_model, "this.cascader_model");
        let springId = this.cascader_model[1];
        this.clearCheckedNodes();
        this.$refs.panel.activePath = [];
        this.delete_node_sprint(springId);
        leaf._isCancel = false;
        this.refresh++;
      }
    },
    //表格合并方法
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row_1 = this.merge_obj.spanArr[rowIndex];
        const _col_1 = _row_1 > 0 ? 1 : 0;
        return {
          rowspan: _row_1,
          colspan: _col_1,
        };
      }
    },
    switch_status(val) {
      switch (val) {
        case "IN_PROGRESS":
          return this.$t("canvas.testPlan.status.inProgress");
        case "ENDED":
          return this.$t("canvas.testPlan.status.completed");
        case "ALREADY_STARTED":
          return this.$t("canvas.testPlan.status.started");
        case "NOT_STARTED":
          return this.$t("canvas.testPlan.status.notStarted");
        case "COMPLETE":
          return this.$t("canvas.testPlan.status.ended");
        case true:
          return this.$t("canvas.testPlan.status.published");
        case false:
          return this.$t("canvas.testPlan.status.notPublished");
      }
    },
    closeOtherDialog() {
      this.checkedLeaf.newValue = "";
    },
  },
};
</script>
<style lang="scss">
.r_c_window {
  .flex {
    .el-scrollbar__wrap {
      overflow-x: hidden !important;
    }
  }
}
.createpro {
  .el-dialog__body {
    padding-bottom: 0 !important;
  }
}
.createpro-header {
  .el-tabs__active-bar {
    background-color: #30648f;
  }

  .is-active {
    color: #30648f;
  }

  .el-tabs__item:hover {
    color: #30648f;
  }
}
</style>
<style lang="scss" scoped>
/* 新建文件弹窗样式 */
.createpro {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
}

.createpro-header {
  color: rgba(49, 100, 143, 1);
  font-family: "思源黑体";
  font-size: 16px;
  margin-top: -30px;

  .is-active {
    color: #30648f;
  }
  ::v-deep .el-select {
    .tags-select-input {
      height: 40px;
      .el-select__tags {
        height: 40px;
        white-space: nowrap;
        overflow: hidden;
        flex-wrap: wrap;
      }
    }

    .el-select__tags-text {
      display: inline-block;
      max-width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .el-tag__close.el-icon-close {
      top: -7px;
    }
  }
}
.r_c_window {
  position: fixed;
  top: 0;
  z-index: 900;
  // display: none;
  background: white;
}
.flex {
  display: flex;
  .left {
    width: 250px;
    border: 1px solid #e4e7ed;
    border-radius: 5px;
    color: #606266;
    padding: 5px 0;
    font-size: 18px;
    .li {
      cursor: pointer;
      height: 50px;
      text-align: left;
      padding: 0 40px 0 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .li:hover {
      background: #f5f7fa;
    }
  }
  .right {
    width: 250px;
    border: 1px solid #e4e7ed;
    border-radius: 5px;
    color: #606266;
    padding: 5px 0;
    font-size: 18px;
    .li {
      cursor: pointer;
      height: 50px;
      text-align: left;
      display: grid;
      grid-template-columns: 40px auto 20px;
      align-items: center;
      justify-items: start;
      .align_left {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .li:hover {
      background: #f5f7fa;
    }
  }
}
.field-info {
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  ::v-deep {
    .el-input {
      width: 100%;
    }
    .el-select {
      width: 100%;
    }
  }
  & > .text_area {
    width: 100%;
  }
  & > div {
    width: calc(33% - 40px);
    display: flex;
    justify-content: space-between;
  }
  &__item {
    display: flex;
    align-items: start;
    margin: 0 40px 20px 0;
    // :active + .disable_edit {
    //   animation: light 2s 20;
    // }
    &__label {
      width: 200px;
      padding: 0 6px;
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      span {
        // width: 100%;
        margin-right: 10px;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: 30px;
        line-height: 30px;
      }
    }
  }
}
.not_edit {
  cursor: pointer;
  width: 100%;
  height: 100%;
  min-height: 30px;
  line-height: 30px;
  font-size: 16px;
  align-items: start;
  text-indent: 10px;
  &:hover {
    background-color: #e5e5e5;
  }
}
.is_edit {
  width: 100%;
}
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
::v-deep .el-dialog__header {
  text-align: left;
  padding-left: 20px;
}
::v-deep.el-cascader-menu__wrap {
  height: 280px;
  text-align: left;
}
::v-deep .el-radio__input {
  width: 100%;
  z-index: 99;
}
::v-deep .el-radio {
  width: 100%;
}
::v-deep .el-cascader-node__label {
  left: 30px;
  position: absolute;
  display: inline-block;
  width: 60%;
}
::v-deep .el-radio__inner {
  top: -18px;
  left: 0px;
  border-radius: 0;
  border: 0;
  height: 34px;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  position: absolute;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: transparent;
  &::after {
    left: 0 !important;
    content: "✔";
    color: #409eff;
    line-height: 5px;
  }
}
::v-deep .el-cascader-menu__list {
  background: white;
  text-align: left;
}
.add_nodes {
  display: inline-block;
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
::v-deep .relation-dialog {
  .el-form {
    display: flex;
    flex-direction: column;
    .el-form-item {
      width: 100%;
      .el-form-item__content {
        width: 100%;
      }
    }
  }
  .el-button {
    float: right;
    height: 36px;
  }
}
.otherDialog {
  .el-dialog__body {
    display: flex;
    flex-direction: column;
    .el-select {
      width: 100%;
    }
    .el-date-editor.el-input {
      width: 100%;
    }
  }
}
::v-deep .el-scrollbar__view {
  .li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    div:nth-child(2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
}
::v-deep .el-dialog__body {
  .el-select {
    width: 100% !important;
    display: block;
  }
}
.select-icon-height-auto-class {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 10px !important;
  }
}
</style>
