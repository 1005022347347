let threat_map = {
    ch: [
        {
            label: "伪装",
            value: "伪装",
        },
        {
            label: "篡改",
            value: "篡改",
        },
        {
            label: "拒绝承认",
            value: "拒绝承认",
        },
        {
            label: "信息的泄漏",
            value: "信息的泄漏",
        },
        {
            label: "拒绝服务",
            value: "拒绝服务",
        },
        {
            label: "提升权限",
            value: "提升权限",
        },
    ],
    en: [
        {
            label: "Spoofing",
            value: "Spoofing",
        },
        {
            label: "Tampering",
            value: "Tampering",
        },
        {
            label: "Repudiation",
            value: "Repudiation",
        },
        {
            label: "Information Disclosure",
            value: "Information Disclosure",
        },
        {
            label: "Denial of Services",
            value: "Denial of Services",
        },
        {
            label: "Elevarion of Privileges",
            value: "Elevarion of Privileges",
        },
    ],
}
let impact_map = {
    ch: [
        {
            label: "严重的",
            value: "4",
        },
        {
            label: "主要的",
            value: "3",
        },
        {
            label: "中等水平",
            value: "2",
        },
        {
            label: "可忽略不计",
            value: "1",
        },
        {
            label: " ",
            value: "0",
        },
    ],
    en: [
        {
            label: "Severe",
            value: "4",
        },
        {
            label: "Major",
            value: "3",
        },
        {
            label: "Moderate",
            value: "2",
        },
        {
            label: "Negligible",
            value: "1",
        },
        {
            label: " ",
            value: "0",
        },
    ]
};
let timeConsuming = {
    ch: [
        {
            label: "少于一周",
            value: "0",
        },
        {
            label: "少于一个月",
            value: "1",
        },
        {
            label: "少于六个月",
            value: "4",
        },
        {
            label: "少于三年",
            value: "10",
        },
        {
            label: "多于三年",
            value: "19",
        },
    ],
    en: [
        {
            label: "Less than a week",
            value: "0",
        },
        {
            label: "Less than one month",
            value: "1",
        },
        {
            label: "Less than six months",
            value: "4",
        },
        {
            label: "Less than three years",
            value: "10",
        },
        {
            label: "More than three years",
            value: "19",
        },
    ]
};
let professionalKnowledge = {
    ch: [
        {
            label: "外行：普通人照着教程一步一步做就能攻击",
            value: "0",
        },
        {
            label: "熟手：可以使用常用的攻击工具的人",
            value: "3",
        },
        {
            label: "专家：富有经验的安全专家",
            value: "6",
        },
        {
            label: "多个专家：一群有经验的安全专家",
            value: "8",
        },
    ],
    en: [

        {
            label: "Layman",
            value: "0",
        },
        {
            label: "Proficient",
            value: "3",
        },
        {
            label: "Expert",
            value: "6",
        },
        {
            label: "Multiple experts",
            value: "8",
        },
    ]
};
let projectKnowledge = {
    ch: [
        {
            label: "组件的信息是公开的",
            value: "0",
        },
        {
            label: "组件的信息是受限的，比如只在供应商之间传递的内部信息",
            value: "3",
        },
        {
            label: "机密信息，只被内部特定团队获取",
            value: "7",
        },
        {
            label: "高机密信息：只被内部少部分人获取",
            value: "11",
        },

    ],
    en: [
        {
            label: "The information of the component is public",
            value: "0",
        },
        {
            label: "The information of components is limited, such as internal information that is only transmitted between suppliers",
            value: "3",
        },
        {
            label: "Confidential information, only obtained by specific internal teams",
            value: "7",
        },
        {
            label: "Highly confidential information: only obtained by a small number of internal personnel",
            value: "11",
        },

    ]
};
let opportunity = {
    ch: [
        {
            label: "无限制的，可用性，时间窗口，远程控制等方面",
            value: "0",
        },
        {
            label: "容易的",
            value: "1",
        },
        {
            label: "中等的",
            value: "4",
        },
        {
            label: "困难的",
            value: "10",
        },
    ],
    en: [
        {
            label: "Unrestricted, availability, time window, remote control, etc.",
            value: "0",
        },
        {
            label: "Easy",
            value: "1",
        },
        {
            label: "Medium",
            value: "4",
        },
        {
            label: "Difficult",
            value: "10",
        },
    ]
};
let equipment = {
    ch: [
        {
            label: "普通设备",
            value: "0",
        },
        {
            label: "特殊设备，通过一定方式可以得到",
            value: "4",
        },
        {
            label: "定制设备，需要专门定制生产",
            value: "7",
        },
        {
            label: "多种定制设备",
            value: "9",
        },
    ],
    en: [
        {
            label: "Normal device",
            value: "0",
        },
        {
            label: "Special equipment can be obtained through certain means",
            value: "4",
        },
        {
            label: "Customized equipment requires specialized customized production",
            value: "7",
        },
        {
            label: "Multiple customized devices",
            value: "9",
        },
    ]
};
let attackRoute = [
    {
        ch: "与车型相关的后端服务器的威胁",
        value: "",
        en: "Threats regarding back-endservers related to vehicles in the field",
        label: "",
        children: [
            {
                ch: "后端服务器作为攻击车辆或提取数据的方式",
                value: "",
                en: "Back-end servers used as a means to attack a vehicle or extract data",
                label: "",
                children: [
                    {
                        ch: "员工滥用权限 (内部攻击)",
                        value: "",
                        en: "Abuse of privileges by staff (insider attack)",
                        label: "",
                    },
                    {
                        ch: "未经授权的互联网接入服务器 (例如通过启用后门、未修补的系统软件漏洞、SOL 攻击或其他方式)",
                        value: "",
                        en: "Unauthorized internet access to the server (enabled for example by backdoors, unpatched system software vulnerabilities, SOl attacks or other means)",
                        label: "",
                    },
                    {
                        ch: "未经授权的物理接入服务器 (例如，通过连接到服务器的 USB 存储或其他介质进行)",
                        value: "",
                        en: "Unauthorized physical access to the server (conducted by for example USB sticks or other media connecting to the server)",
                        label: "",
                    },
                ],
            },
            {
                ch: "后端服务器上的服务中断，影响车辆运行",
                value: "",
                en: "Services from back-end server being disrupted, affecting the operation of a vehicle",
                label: "",
                children: [
                    {
                        ch: "攻击后端服务器使其停止运行，例如，通过阻止其与车辆交互或者阻止车辆所依赖的服务",
                        value: "",
                        en: "Attack on backend server stops it functioning, for example it prevents it from interacting with vehicles and providing services they rely on",
                        label: "",
                    },
                ],
            },
            {
                ch: "后端服务器持有的车辆相关的数据丢失或泄露(“数据泄露”)",
                value: "",
                en: "Vehicle related data held on back-end servers being lost or compromised ('data breach')",
                label: "",
                children: [
                    {
                        ch: "员工滥用权限(内部攻击)",
                        value: "",
                        en: "Abuse of privileges by staff (insider attack)",
                        label: "",
                    },
                    {
                        ch: "云中的信息丢失。当数据由第三方云服务提供商存储时，敏感数据可能会由于攻击或事故而丢失",
                        value: "",
                        en: "Loss of information in the cloud. Sensitive data may be lost due to attacks or accidents when data is stored by third-party cloud service providers",
                        label: "",
                    },
                    {
                        ch: "未经授权的互联网接入服务器(例如通过启用后门、未修补的系统软件漏洞、SQL 攻击或其他方式)",
                        value: "",
                        en: "Unauthorized internet access to the server(enabled for example by backdoors, unpatched system software vulnerabilities, SOL attacks or other means)",
                        label: "",
                    },
                    {
                        ch: "未经授权的物理接入服务器(例如，通过连接到服务器的 USB 存储或其他介质进行)",
                        value: "",
                        en: "Unauthorized physical access to the server conducted for example by USB sticks or other media connecting to the server)",
                        label: "",
                    },
                    {
                        ch: "意外共享数据导致的信息泄露(如管理错误)",
                        value: "",
                        en: "Information breach by unintended sharing of data(e.g. admin errors)",
                        label: "",
                    },
                ],
            },
        ],
    },
    // 2
    {
        ch: "跟车辆通信信道有关的威胁",
        value: "",
        en: "Threats to vehicles regarding their communication channels",
        label: "",
        children: [
            {
                ch: "欺骗车辆接收到的消息或数据",
                value: "",
                en: "Spoofing of messages or data received by the vehicle",
                label: "",
                children: [
                    {
                        ch: "通过模拟进行消息欺骗(例如，排队期间的802.1lp V2X、GNSS 消息等)",
                        value: "",
                        en: "Spoofing of messages by impersonation (e.g.802.11p V2X during platooning, GNSS messages.etc.)",
                        label: "",
                    },
                    {
                        ch: "Sybil攻击(为了欺骗其他车辆，就像路上有很名车辆一样)",
                        value: "",
                        en: "Sybil attack (in order to spoof other vehicles as if there are many vehicles on the road)",
                        label: "",
                    },
                ],
            },
            {
                ch: "用于对车辆持有的代码/数据进行未经授权的操纵、删除或其他修改的通信信道",
                value: "",
                en: "Communication channels used to conduct unauthorized manipulation, deletion or other amendments to vehicle held code/data",
                label: "",
                children: [
                    {
                        ch: "通信信道允许代码注入，例如，被篡改的软件二进制可能被注入通信流",
                        value: "",
                        en: "Communications channels permit code injection, for example tampered software binary might be injected into the communication stream",
                        label: "",
                    },
                    {
                        ch: "通信信道允许操纵车载数据/代码",
                        value: "",
                        en: "Communications channels permit manipulate of vehicle held data/code",
                        label: "",
                    },
                    {
                        ch: "通信信道允许覆盖车载数据/代码",
                        value: "",
                        en: "Communications channels permit overwrite of vehicle held data/code",
                        label: "",
                    },
                    {
                        ch: "通信信道允许擦除车载数据/代码",
                        value: "",
                        en: "Communications channels permit erasure of vehicle held data/code",
                        label: "",
                    },
                    {
                        ch: "通信信道允许将数据/代码引入车辆",
                        value: "",
                        en: "Communications channels permit introduction of data/code to the vehicle ( write data code)",
                        label: "",
                    },
                ],
            },
            {
                ch: "通信信道允许接受不可信/不可靠的消息，或者容易受到会话劫持/重放攻击",
                value: "",
                en: "Communication channels permit untrusted/unreliable messages to be accepted or are vulnerable to session hijacking/replay attacks",
                label: "",
                children: [
                    {
                        ch: "从不可靠或不可信的来源接收信息",
                        value: "",
                        en: "Accepting information from an unreliable or untrusted source",
                        label: "",
                    },
                    {
                        ch: "中间人攻击/会话劫持",
                        value: "",
                        en: "Man in the middle attack/ session hijacking",
                        label: "",
                    },
                    {
                        ch: "重放攻击，例如，对通信网关的攻击允许攻击者降级 ECU 的软件或网关的固件",
                        value: "",
                        en: "Replay attack, for example an attack against a communication gateway allows the attacker to downgrade software of an ECU or firmware of the gateway",
                        label: "",
                    },
                ],
            },
            {
                ch: "信息很容易地被暴露。例如，通过窃听通信或允许未经授权访问敏感文件或文件夹",
                value: "",
                en: "Information can be readily disclosed. For example, through eavesdropping on communications or through allowing unauthorized access to sensitive files or folders",
                label: "",
                children: [
                    {
                        ch: "拦截信息/千扰辐射/监控通信",
                        value: "",
                        en: "Interception of information / interfering radiations monitoring communications",
                        label: "",
                    },
                    {
                        ch: "未经授权访问文件或数据",
                        value: "",
                        en: "Gaining unauthorized access to files or data",
                        label: "",
                    },
                ],
            },
            {
                ch: "通过通信信道进行的拒绝服务攻击，以中断车辆功能",
                value: "",
                en: "Denial of service attacks via communication channels to disrupt vehicle functions",
                label: "",
                children: [
                    {
                        ch: "向车辆信息系统发送大量垃圾数据，使其无法正常提供服务",
                        value: "",
                        en: "Sending a large number of garbage data to vehicle information system, so that it is unable to provide services in the normal manner",
                        label: "",
                    },
                    {
                        ch: "黑洞攻击，为了中断车辆之间的通信，攻击者能够阻止车辆之间的消息",
                        value: "",
                        en: "Black hole attack, in order to disrupt communication between vehicles the attacker is able to block messages between the vehicles",
                        label: "",
                    },
                ],
            },
            {
                ch: "非特权用户能够获得对车辆系统的特权访问",
                value: "",
                en: "An unprivileged user is able to gain privileged access to vehicle systems",
                label: "",
                children: [
                    {
                        ch: "非特权用户能够获得特权访问，例如 root 访问",
                        value: "",
                        en: "An unprivileged user is able to gain privileged access, for example root access",
                        label: "",
                    },
                ],
            },
            {
                ch: "嵌入通信介质中的病毒能够感染车辆系统",
                value: "",
                en: "Viruses embedded in communication media are able to infect vehicle systems",
                label: "",
                children: [
                    {
                        ch: "嵌入通信媒介的病毒感染车辆系统",
                        value: "",
                        en: "Virus embedded in communication media infects vehicle systems",
                        label: "",
                    },
                ],
            },
            {
                ch: "车辆接收的消息例如 (X2V或诊断消息) 或在车辆内传输的消息包含恶意内容",
                value: "",
                en: "Messages received by the vehicle (for example X2V or diagnostic messages), or transmitted within it, contain malicious content",
                label: "",
                children: [
                    {
                        ch: "恶意的内部(如 CAN) 消息",
                        value: "",
                        en: "Malicious internal (e.g. CAN) messages",
                        label: "",
                    },
                    {
                        ch: "恶意 V2X 消息，如基础设施到车辆或车辆-车辆的消息(如 CAM、DENM)",
                        value: "",
                        en: "Malicious V2X messages, e.g. infrastructure to vehicle or vehicle-vehicle messages (e.g. CAM DENM)",
                        label: "",
                    },
                    {
                        ch: "恶意诊断消息",
                        value: "",
                        en: "Malicious diagnostic messages",
                        label: "",
                    },
                    {
                        ch: "恶意专有消息(例如，那些通常由 OEM 或组件系统/功能供应商发送的消息)",
                        value: "",
                        en: "Malicious proprietary messages (e.g. those normally sent from OEM or component/system/function supplier)",
                        label: "",
                    },
                ],
            },
        ],
    },
    //3
    {
        ch: "跟车辆的升级过程有关的威胁",
        value: "",
        en: "Threats to vehicles regarding their update procedures",
        label: "",
        children: [
            {
                ch: "更新过程的误用或破坏",
                value: "",
                en: "Misuse or compromise of update procedures",
                label: "",
                children: [
                    {
                        ch: "OTA 软件升级程序的损害。这包括制造系统更新程序或固件",
                        value: "",
                        en: "Compromise of over the air software update procedures. This includes fabricating the system update program or firmware",
                        label: "",
                    },
                    {
                        ch: "本地/物理软件更新程序的损害。这包括制造系统更新程序或固件",
                        value: "",
                        en: "Compromise of local/physical software update procedures. This includes fabricating the system update program or firmware",
                        label: "",
                    },
                    {
                        ch: "虽然更新过程是完整的，但软件在更新过程之前被操纵(因此已损坏)",
                        value: "",
                        en: "The software is manipulated before the update process (and is therefore corrupted), although the update process is intact",
                        label: "",
                    },
                    {
                        ch: "软件提供程序的加密密钥泄露，允许无效更新",
                        value: "",
                        en: "Compromise of cryptographic keys of the software provider to allow invalid update",
                        label: "",
                    },
                ],
            },
            {
                ch: "拒绝合法的更新时可能的",
                value: "",
                en: "It is possible to deny legitimate updates",
                label: "",
                children: [
                    {
                        ch: "针对更新服务器或网络的拒绝服务攻击，以阻止关键软件更新的推出和/或客户特定功能的解锁 ",
                        value: "",
                        en: "Denial of Service attack against update server or network to prevent rollout of critical software updates and/or unlock of customer specific features",
                        label: "",
                    },
                ],
            },
        ],
    },
    //4
    {
        ch: "非预期的人为操作给网络攻击造成便利的威胁",
        value: "",
        en: "Threats to vehicles regarding unintended human actions facilitating a cyber attack",
        label: "",
        children: [
            {
                ch: "合法行为体能够采取可能无意中助长网络攻击的行动",
                value: "",
                en: "Legitimate actors are able to take actions that would unwittingly facilitate a cyber attack",
                label: "",
                children: [
                    {
                        ch: "无辜受害者(如所有者、操作员或维护工程师)被骗采取行动，无意中加载恶意软件或启动攻击",
                        value: "",
                        en: "Innocent victim (e.g. owner, operator or maintenance engineer) being tricked into taking an action to unintentionally load malware or enable an attack",
                        label: "",
                    },
                    {
                        ch: "未遵守规定的安全程序",
                        value: "",
                        en: "Defined security procedures are not followed",
                        label: "",
                    },
                ],
            },
        ],
    },
    //5
    {
        ch: "车辆外部连接相关的威胁",
        value: "",
        en: "Threats to vehicles regarding their external connectivity and connections",
        label: "",
        children: [
            {
                ch: "操纵车辆功能的连通性可实现网络攻击，者可能包括远程信息处理:允许远程操作的系统:以及使用近程无线通信的系统",
                value: "",
                en: "Manipulation of the connectivity of vehicle functions enables a cyber-attack, this can include telematics; systems that permit remote operations; and systems using short range wireless communications",
                label: "",
                children: [
                    {
                        ch: "操纵设计用于远程操作系统的功能，如遥控钥匙、防盗锁和充电桩",
                        value: "Manipulation of functions designed to remotely operate systems, such as remote key, immobilizer and charging pile",
                        en: "",
                        label: "",
                    },
                    {
                        ch: "操纵车辆远程信息处理(例如，操纵敏感货物的温度测量，远程解锁货舱门)",
                        value: "",
                        en: "Manipulation of vehicle telematics (e.g. manipulate temperature measurement of sensitive goods, remotely unlock cargo doors)",
                        label: "",
                    },
                    {
                        ch: "干扰近程无线系统或传感器",
                        value: "",
                        en: "Interference with short range wireless systems or sensors",
                        label: "",
                    },
                ],
            },
            {
                ch: "托管的第三方软件，例如娱乐应用程序，被用作攻击车辆系统的手段",
                value: "",
                en: "Hosted 3rd party software, e.g entertainment applications. used as a means to attack vehicle systems",
                label: "",
                children: [
                    {
                        ch: "损坏的应用程序或软件安全性差的应用程序，被用作攻击车辆系统",
                        value: "",
                        en: "Corrupted applications, or those with poor software security, used as a method to attack vehicle systems",
                        label: "",
                    },
                ],
            },
            {
                ch: "连接到外部接口的设备，如JSB 端口、OBD 端口，被用作攻击车辆系统",
                value: "",
                en: "Devices connected to external interfaces e.g. USB ports, OBD port, used as a means to attack vehicle systems",
                label: "",
                children: [
                    {
                        ch: "外部接口，诸如 USB 或其它端口，被用作攻击的点，例如通过代码注入",
                        value: "",
                        en: "External interfaces such as USB or other ports used as a point of attack, for example through code injection",
                        label: "",
                    },
                    {
                        ch: "被病毒感染的媒介连接到车辆系统",
                        value: "",
                        en: "Media infected with a virus connected to a vehicle system",
                        label: "",
                    },
                    {
                        ch: "诊断接入(例如OBD端口中的加)用于给攻击提供便利，例如操纵车辆参数(直接或间接)",
                        value: "",
                        en: "Diagnostic access (e.g. dongles in OBD port) used to facilitate an attack, e.g. manipulate vehicle parameters (directly or indirectly)",
                        label: "",
                    },
                ],
            },
        ],
    },
    //6
    {
        ch: "车辆数据/代码相关的威胁",
        value: "",
        en: "Threats to vehicle data/code",
        label: "",
        children: [
            {
                ch: "车辆数据/代码提取",
                value: "",
                en: "Extraction of vehicle data/code",
                label: "",
                children: [
                    {
                        ch: "从车辆系统中提取版权或专有软件(产品盗版)",
                        value: "",
                        en: "Extraction of copyright or proprietary software from vehicle systems (product piracy)",
                        label: "",
                    },
                    {
                        ch: "未经授权访问车主的隐私信息，如个人标识、支付账户信息、地址簿信息、位置信息、车辆电子ID 等。",
                        value: "",
                        en: "Unauthorized access to the owner's privacy information such as personal identity, payment account information, address book information. location information, vehicle's electronic ID, etc",
                        label: "",
                    },
                    {
                        ch: "密钥提取",
                        value: "",
                        en: "Extraction of cryptographic keys",
                        label: "",
                    },
                ],
            },
            {
                ch: "车辆数据/代码操纵",
                value: "",
                en: "Manipulation of vehicle data/code",
                label: "",
                children: [
                    {
                        ch: "非法/未经授权修改车辆电子 ID",
                        value: "",
                        en: "Illegal/unauthorized changes to vehicle's electronic ID",
                        label: "",
                    },
                    {
                        ch: "身份欺骗。例如，如果用户希望在与计费系统或者制造商后台通信时显示另一身份",
                        value: "",
                        en: "Identity fraud. For example, if a user wants to display another identity when communicating with toll systems, manufacturer backend",
                        label: "",
                    },
                    {
                        ch: "躲避监控系统的行为(例如，黑客/篡改/阻止消息，如 ODR 跟踪器数据或运行次数)",
                        value: "",
                        en: "Action to circumvent monitoring systems (e.g. hacking' tampering/ blocking of messages such as ODR Tracker data, or number of runs)",
                        label: "",
                    },
                    {
                        ch: "算改车辆行驶数据的数据操纵(如里程、行驶速度、行驶方向等)",
                        value: "",
                        en: "Data manipulation to falsify vehicle's driying data (e.g. mileage, driving speed, driving directions, etc.)",
                        label: "",
                    },
                    {
                        ch: "未经授权修改系统诊断数据",
                        value: "",
                        en: "Unauthorized changes to system diagnostic data",
                        label: "",
                    },
                ],
            },
            {
                ch: "数据/代码擦除",
                value: "",
                en: "Erasure of data/code",
                label: "",
                children: [
                    {
                        ch: "未经授权删除/操纵系统事件日志",
                        value: "",
                        en: "Unauthorized deletion/manipulation of system event logs",
                        label: "",
                    },
                ],
            },
            {
                ch: "引入恶意软件",
                value: "",
                en: "Introduction of malware",
                label: "",
                children: [
                    {
                        ch: "引入恶意软件或者恶意软件行为",
                        value: "",
                        en: "Introduce malicious software or malicious software activity",
                        label: "",
                    },
                ],
            },
            {
                ch: "引入新的软件或者覆盖已存在的软件",
                value: "",
                en: "Introduction of new software or overwrite existing software",
                label: "",
                children: [
                    {
                        ch: "车辆控制系统或信息系统软件的伪造",
                        value: "",
                        en: "Fabrication of software of the vehicle control system or information system",
                        label: "",
                    },
                ],
            },
            {
                ch: "系统或操作中断",
                value: "",
                en: "Disruption of systems or operations",
                label: "",
                children: [
                    {
                        ch: "拒绝服务，例如，这可能通过 CAN 总线洪泛攻击从内部网络触发，或者通过高速消息发送引发ECU 故障",
                        value: "",
                        en: "Denial of service, for example this may be triggered on the internal network by flooding a CAN bus, or by provoking faults on an ECU via a high rate of messaging",
                        label: "",
                    },
                ],
            },
            {
                ch: "操纵车辆参数",
                value: "",
                en: "Manipulation of vehicle parameters",
                label: "",
                children: [
                    {
                        ch: "未经授权访问以算改车辆关键功能的配置参数如制动数据、气囊弹出闽值等。",
                        value: "",
                        en: "Unauthorized access of falsify the configuration parameters of vehicle's key functions, such as brake data airbag deployed threshold, etc.",
                        label: "",
                    },
                    {
                        ch: "未经授权访问以篡改充电参数，例如充电电压、充电功率、电池温度等等。",
                        value: "",
                        en: "Unauthorized access of falsify the charging parameters, such as charging voltage, charging power, battery temperature, etc.",
                        label: "",
                    },
                ],
            },
        ],
    },
    //7
    {
        ch: "未得到充分保护或强化的潜在漏洞可能会被利用",
        value: "",
        en: " Potential vulnerabilities that could be exploited if not sufficiently protected or hardened",
        label: "",
        children: [
            {
                ch: "密码技术可能受到侵害或者应用不足",
                value: "",
                en: "Cryptographic technologies can be compromised or are insufficiently applied",
                label: "",
                children: [
                    {
                        ch: "短密钥和长密钥有效期的组合使得攻击者能够破坏加密",
                        value: "",
                        en: "Combination of short encryption keys and long period of validity enables attacker to break encryption",
                        label: "",
                    },
                    {
                        ch: "保护敏感系统时加密算法没有被充分利用",
                        value: "",
                        en: "Insufficient use of cryptographic algorithms to protect sensitive systems",
                        label: "",
                    },
                    {
                        ch: "使用已经或者即将被弃用的加密算法",
                        value: "",
                        en: "Using already or soon to be deprecated cryptographic algorithms",
                        label: "",
                    },
                ],
            },
            {
                ch: "零部件或供应件可能受到侵害，从而是车辆遭受攻击",
                value: "",
                en: "Parts or supplies could be compromised to permit vehicles to be attacked",
                label: "",
                children: [
                    {
                        ch: "硬件或软件，被设计成可以被攻击，或者未达到阻止攻击的设计标准",
                        value: "",
                        en: "Hardware or software, engineered to enable an attack or fails to meet design criteria to stop an attack",
                        label: "",
                    },
                ],
            },
            {
                ch: "软硬件开发允许漏洞存在",
                value: "",
                en: "Software or hardware development permits vulnerabilities",
                label: "",
                children: [
                    {
                        ch: "软件缺陷。软件缺陷的存在可能是潜在的可利用漏洞的基础。如果未对软件进行测试，以验证已知的缺陷代码/错误不存在，并降低存在未知的缺陷代码/错误的风险，则尤其会如此",
                        value: "",
                        en: "Software bugs. The presence of software bugs can be a basis for potential exploitable vulnerabilities. This is particularly true if software has not been tested to verify that known bad code/bugs is not present and reduce the risk of unknown bad code/bugs being present",
                        label: "",
                    },
                    {
                        ch: "利用开发遗留 (如调试端口、JTAG 端、微处理器、开发证书、开发人员密码等) 可允许访问ECU 或允许攻击者获得更高权限",
                        value: "",
                        en: "Using remainders from development (e.g. debug ports, JTAG ports, microprocessors, development certificates, developer passwords, ...) can permit access to ECUs or permit attackers to gain higher privileges",
                        label: "",
                    },
                ],
            },
            {
                ch: "网络设计引入漏洞",
                value: "",
                en: "Network design introduces vulnerabilities",
                label: "",
                children: [
                    {
                        ch: "多余的联网端口保持开放，提供了对网络系统的访问",
                        value: "",
                        en: "Superfluous internet ports left open, providing access to network systems",
                        label: "",
                    },
                    {
                        ch: "绕过网络隔离以获得控制。具体示例是使用未受保护的网关或接入点(如卡车拖车网关)，以绕过保护并访问其他网段以执行恶意行为，如发送任意的 CAN 总线消息",
                        value: "",
                        en: "Circumvent network separation to gain control.Specific example is the use of unprotected gateways.or access points (such as truck-trailer gateways), tocircumvent protections and gain access to othernetwork segments to perform malicious acts, such assending arbitrary CAN bus messages",
                        label: "",
                    },
                ],
            },
            {
                ch: "可能发生无意的数据传输",
                value: "",
                en: "Unintended transfer of data can occur",
                label: "",
                children: [
                    {
                        ch: "信息泄露。个人数据可能在车辆易主的时候被泄露(例如，被售卖，或者被出租给新的租户)",
                        value: "",
                        en: "Information breach. Personal data may be leaked when the car changes user (e.g. is sold or is used as hire vehicle with new hirers)",
                        label: "",
                    },
                ],
            },
            {
                ch: "对系统的物理操纵可能导致攻击",
                value: "",
                en: "Physical manipulation of systems can enable an attack",
                label: "",
                children: [
                    {
                        ch: "操纵电子硬件，例如未经授权添加到车辆上的电子硬件，可以实现“中间人”攻击",
                        value: "",
                        en: "Manipulation of electronic hardware, e.g.unauthorized electronic hardware added to a vehicle to enable 'man-in-the-middle' attack",
                        label: "",
                    },
                    {
                        ch: "使用未经授权的电子硬件替换经授权的电子硬件(如传感器)",
                        value: "",
                        en: "Replacement of authorized electronic hardware(e.g., sensors) with unauthorized electronic hardware",
                        label: "",
                    },
                    {
                        ch: "操纵传感器收集的信息(例如，使用磁铁篡改连接到变速箱的霍尔效应传感器)",
                        value: "",
                        en: "Manipulation of the information collected by a sensor (for example, using a magnet to tamper with the Hall effect sensor connected to the gearbox)",
                        label: "",
                    },
                ],
            },
        ],
    },
];
let measures1 = [
    {
        ch: "跟“车辆通信信道”有关的威胁",
        value: "",
        en: "Threats to 'Vehicle communication channels'",
        label: "",
        children: [
            {
                ch: "通过模拟进行消息欺骗(例如，排队中的 802.11p V2X、GNSS 消息等)",
                value: "",
                en: "Spoofing of messages (e.g. 802.11p V2X during platooning, GNSS messages, etc.) by impersonation",
                label: "",
                children: [
                    {
                        ch: "车辆应当验证其接收到的消息的真实性和完整性",
                        value: "",
                        en: "The vehicle shall verify the authenticity and integrity of messages it receives",
                        label: "",
                    },
                ],
            },
            {
                ch: "Sybil 攻击(为了欺骗其他车辆，就像路上有很多车辆一样)",
                value: "",
                en: "Sybil attack (in order to spoof other vehicles as if there are many vehicles on the road)",
                label: "",
                children: [
                    {
                        ch: "应实施安全控制以存储加密密钥(例如，使用便件安全模块)",
                        value: "",
                        en: "Security controls shall be implemented for storing cryptographic keys (e.g., use of Hardware Security Modules)",
                        label: "",
                    },
                ],
            },
            {
                ch: "通信信道允许代码注入，例如，被篡改的软件二进制可能被注入通信流",
                value: "",
                en: "Communication channels permit code injection into vehicle held data/code, for example tampered software binarymight be injected into the communication stream",
                label: "",
                children: [
                    {
                        ch: "车辆应验证其接收到的消息的真实性和完整性",
                        value: "",
                        en: "The vehicle shall verify the authenticity and integrity of messages it receives",
                        label: "",
                    },
                    {
                        ch: "系统应通过设计实现安全，以将风险降至最低",
                        value: "",
                        en: "Systems shall implement security by design to minimize risks",
                        label: "",
                    },
                ],
            },
            {
                ch: "通信信道允许操纵车载数据/代码",
                value: "",
                en: "Communication channels permit manipulation of vehicle held data/code",
                label: "",
                children: [
                    {
                        ch: "应采用访问控制技术和设计来保护系统数据/代码",
                        value: "",
                        en: "Access control techniques and designs shall be applied to protect system data/code",
                        label: "",
                    },
                ],
            },
            {
                ch: "通信信道允许覆盖车载数据/代码",
                value: "",
                en: "Communication channels permit overwrite of vehicle held data/code",
                label: "",
                children: [
                    {
                        ch: "应采用访问控制技术和设计来保护系统数据/代码",
                        value: "",
                        en: "Access control techniques and designs shall be applied to protect system data/code",
                        label: "",
                    },
                ],
            },
            {
                ch: "通信信道允许擦除车载数据/代码",
                value: "",
                en: "Communication channels permit erasure of vehicle held data/code",
                label: "",
                children: [
                    {
                        ch: "应采用访问控制技术和设计来保护系统数据/代码",
                        value: "",
                        en: "Access control techniques and designs shall be applied to protect system data/code",
                        label: "",
                    },
                ],
            },
            {
                ch: "通信信道允许将数据/代码引入车辆",
                value: "",
                en: "Communication channels permit introduction of data/code to vehicle systems ( write data code)",
                label: "",
                children: [
                    {
                        ch: "应采用访问控制技术和设计来保护系统数据/代码",
                        value: "",
                        en: "Access control techniques and designs shall be applied to protect system data/code",
                        label: "",
                    },
                ],
            },
            {
                ch: "从不可靠或不可信的来源接收信息",
                value: "",
                en: "Accepting information from an unreliable or untrusted source",
                label: "",
                children: [
                    {
                        ch: "车辆应验证其接收到的消息的真实性和完整性",
                        value: "",
                        en: "The vehicle shall verify the authenticity and integrity of messages it receives",
                        label: "",
                    },
                ],
            },
            {
                ch: "中间人攻击/会话劫持",
                value: "",
                en: "Man in the middle attack/session hijacking",
                label: "",
                children: [
                    {
                        ch: "车辆应验证其接收到的消息的真实性和完整性",
                        value: "",
                        en: "The vehicle shall verify the authenticity and integrity of messages it receives",
                        label: "",
                    },
                ],
            },
            {
                ch: "重放攻击，例如，对通信网关的攻击允许攻击者降级 ECU 的软件或网关的固件",
                value: "",
                en: "Replay attack, for example an attackagainst a communication gateway allows the attacker to downgrade software of an ECU or firmware of the gateway",
                label: "",
                children: [
                    {
                        ch: "车辆应验证其接收到的消息的真实性和完整性",
                        value: "",
                        en: "The vehicle shall verify the authenticity and integrity of messages it receives",
                        label: "",
                    },
                ],
            },
            {
                ch: "拦截信息/千扰辐射/监控通信",
                value: "",
                en: "Interception of information / interfering radiations y monitoring communications",
                label: "",
                children: [
                    {
                        ch: "应保护向车辆传入或从车辆传出的机密数据",
                        value: "",
                        en: "Confidential data transmitted to or from the vehicle shall be protected",
                        label: "",
                    },
                ],
            },
            {
                ch: "未经授权访问文件或数据",
                value: "",
                en: "Gaining unauthorized access to files or data",
                label: "",
                children: [
                    {
                        ch: "通过系统设计和访问控制，未经授权的人员不可能访问个人或系统的关键数据。相关安全控制的实例，可以在 OWASP 中找到。",
                        value: "",
                        en: "Through system design and access control it should not be possible for unauthorized personnel to access personal or system critical data. Example of Security Controls can be found inOWASP",
                        label: "",
                    },
                ],
            },
            {
                ch: "向车辆信息系统发送大量垃圾数据,使其无法正常提供服务",
                value: "",
                en: "Sending a large number of garbage data to vehicle information system, so that it is unable to provide services in the normal manner",
                label: "",
                children: [
                    {
                        ch: "应采取措施检测拒绝服务攻击并从中恢复",
                        value: "",
                        en: "Measures to detect and recover from a denial of service attack shall be employed",
                        label: "",
                    },
                ],
            },
            {
                ch: "黑洞攻击，为了中断车辆之间的通信，攻击者能够阻止车辆之间的消息",
                value: "",
                en: "Black hole attack, disruption of communication between vehicles by blocking the transfer of messages to other vehicles",
                label: "",
                children: [
                    {
                        ch: "应采取措施检测拒绝服务攻击并从中恢复",
                        value: "",
                        en: "Measures to detect and recover from a denial of service attack shall be employed",
                        label: "",
                    },
                ],
            },
            {
                ch: "非特权用户能够获得特权访问，例如root访问",
                value: "",
                en: "An unprivileged user is able to gain privileged access, for example root access",
                label: "",
                children: [
                    {
                        ch: "应采取措施防止和检测未经授权的访问",
                        value: "",
                        en: "Measures to prevent and detect unauthorized access shall be employed",
                        label: "",
                    },
                ],
            },
            {
                ch: "嵌入通信媒介的病毒感染车辆系统",
                value: "",
                en: "Virus embedded in communication media infects vehicle systems",
                label: "",
                children: [
                    {
                        ch: "应考虑保护系统免遭嵌入式病毒/恶意软件的措施",
                        value: "",
                        en: "Measures to protect systems against embedded viruses/malware should be considered",
                        label: "",
                    },
                ],
            },
            {
                ch: "恶意的内部 (如 CAN) 消息",
                value: "",
                en: "Malicious internal (e.g. CAN) messages",
                label: "",
                children: [
                    {
                        ch: "应考虑检测恶意内部消息或活动的措施",
                        value: "",
                        en: "Measures to detect malicious internal messages or activity should be considered",
                        label: "",
                    },
                ],
            },
            {
                ch: "恶意 V2X 消息，如基础设施到车辆或车辆-车辆的消息 (如 CAM、DENM)",
                value: "",
                en: "Malicious V2X messages, e.g. infrastructure to vehicle or vehicle - vehicle messages (e.g. CAM, DENM)",
                label: "",
                children: [
                    {
                        ch: "车辆应验证其接收到的消息的真实性和完整性",
                        value: "",
                        en: "The vehicle shall verify the authenticity and integrity of messages it receives",
                        label: "",
                    },
                ],
            },
            {
                ch: "恶意诊断消息",
                value: "",
                en: "Malicious diagnostic messages",
                label: "",
                children: [
                    {
                        ch: "车辆应验证其接收到的消息的真实性和完整性",
                        value: "",
                        en: "The vehicle shall verify the authenticity and integrity of messages it receives",
                        label: "",
                    },
                ],
            },
            {
                ch: "恶意专有消息 (例如，那些通常由OEM 或组件/系统/功能供应商发送的消息)",
                value: "",
                en: "Malicious proprietary messages (e.g those normally sent from OEM or component/system/function supplier",
                label: "",
                children: [
                    {
                        ch: "车辆应验证其接收到的消息的真实性和完整性",
                        value: "",
                        en: "The vehicle shall verify the authenticity and integrity of messages it receives",
                        label: "",
                    },
                ],
            },
        ],
    },
    {
        ch: "“更新程序”相关的缓解措施",
        value: "",
        en: "Threats to 'Update process'",
        label: "",
        children: [
            {
                ch: "OTA 软件升级程序的损害。这包括制造系统更新程序或固件",
                value: "",
                en: "Compromise of over the air software update procedures. This includes fabricating the system update program or firmware",
                label: "",
                children: [
                    {
                        ch: "应采用安全的软件更新程序",
                        value: "",
                        en: "Secure software update procedures shall be employed",
                        label: "",
                    },
                ],
            },
            {
                ch: "本地/物理软件更新程序的损害。这包括制造系统更新程序或固件",
                value: "",
                en: "Compromise of local/physical software update procedures. This includes fabricating the system update program or firmware",
                label: "",
                children: [
                    {
                        ch: "应采用安全的软件更新程序",
                        value: "",
                        en: "Secure software update procedures shall be employed",
                        label: "",
                    },
                ],
            },
            {
                ch: "虽然更新过程是完整的，但软件在更新过程之前被操纵(因此已损坏)",
                value: "",
                en: "The software is manipulated before the update process (and is therefore corrupted) although the update process is intact",
                label: "",
                children: [
                    {
                        ch: "应采用安全的软件更新程序",
                        value: "",
                        en: "Secure software update procedures shall be employed",
                        label: "",
                    },
                ],
            },
            {
                ch: "软件提供程序的加密密钥泄露，允许无效更新",
                value: "",
                en: "Compromise of cryptographic keys of the software provider to allow invalid update",
                label: "",
                children: [
                    {
                        ch: "应实施安全控制措施存储加密密钥",
                        en: "Security controls shall be implemented for storing cryptographic keys",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "针对更新服务器或网络的拒绝服务攻击，以阻止关键软件更新的推出和/或客户特定功能的解锁",
                value: "",
                en: "Denial of Service attack against update server or network to prevent rollout of critical software updates and/or unlock of customer specific features",
                label: "",
                children: [
                    {
                        ch: "应当在后端系统实施安全控制措施。如果后端服务器对提供服务至关重要，则应在系统中断的情况下有恢复措施。可以在 OWASP中找到示例的安全控制措施。",
                        value: "",
                        en: "Security Controls shall be applied to back-end systems.Where back-end servers are critical to the provision of services there are recovery measures in case of system outage. Example Security Controls can be found in OWASP",
                        label: "",
                    },
                ],
            },
        ],
    },
    {
        ch: "“无意的人为操作”有关的威胁",
        value: "",
        en: "Threats relating to 'Unintended human actions'",
        label: "",
        children: [
            {
                ch: "无辜受害者(如所有者、操作员或维护工程师)被骗采取行动，无意中加载恶意软件或启动攻击",
                en: "Innocent victim (e.g. owner, operator or maintenance engineer) is tricked into taking an action to unintentionally load malware or enable an attack",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应根据最小访问权限原则，实施定义和控制用户角色和访问权限的缓解措施",
                        en: "Measures shall be implemented for defining and controlling user roles and access privileges, based on the principle of least access privilege",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "未遵守规定的安全程序",
                en: "Defined security procedures are not followed",
                value: "",
                label: "",
                children: [
                    {
                        ch: "组织应确保定义并遵循安全程序，包括记录导安全功能管理相关的活动和访问",
                        en: "Organizations shall ensure security procedures are defined and followed including logging of actions and access related to the management of the security functions",
                        value: "",
                        label: "",
                    },
                ],
            },
        ],
    },
    {
        ch: "“外部连接”有关的威胁",
        value: "",
        en: "Threats to External connectivity and connections",
        label: "",
        children: [
            {
                ch: "操纵设计用于远程操作系统的功能，如遥控钥匙、防盗锁和充电桩",
                en: "Manipulation of functions designed to remotely operate vehicle systems, such as remote key, immobiliser, and charging pile",
                value: "",
                label: "",
                children: [
                    {
                        ch: "具有远程接入的系统应当实施安全控制",
                        en: "Security controls shall be applied to systems that have remote access",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "操纵车辆远程信息处理(例如，操纵敏感货物的温度测量，远程解锁货舱门)",
                en: "Manipulation of vehicle telematics (e.g.manipulate temperature measurement oi sensitive goods, remotely unlock cargo doors)",
                value: "",
                label: "",
                children: [
                    {
                        ch: "具有远程接入的系统应当实施安全控制",
                        en: "Security controls shall be applied to systems that have remote access",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "干扰近程无线系统或传感器",
                en: "Interference with short range wireless systems or sensors",
                value: "",
                label: "",
                children: [
                    {
                        ch: "具有远程接入的系统应当实施安全控制",
                        en: "Security controls shall be applied to systems that have remote access",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "损坏的应用程序或软件安全性差的应用程序，被用作攻击车辆系统",
                en: "Corrupted applications, or those with poor software security, used as a method to attack vehicle systems",
                value: "",
                label: "",
                children: [
                    {
                        ch: "软件应当实施安全评估、认证和完整性保护",
                        en: "Software shall be security assessed, authenticated and integrity protected",
                        value: "",
                        label: "",
                    },
                    {
                        ch: "应采用安全控制措施，以最大限度地降低那些预计安装到车辆上的第三方软件的风险",
                        en: "Security controls shall be applied to minimise the risk from third party software that is intended or foreseeable to be hosted on the vehicle",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "外部接口，诸如 USB 或其它端口，被用作攻击的点，例如通过代码注入",
                en: "External interfaces such as USB or other ports used as a point of attack, for example through code injection",
                value: "",
                label: "",
                children: [
                    {
                        ch: "对于外部接口应当实施安全控告",
                        en: "Security controls shall be applied to external interfaces",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "被病毒感染的媒介连接到车辆系统",
                en: "Media infected with viruses connected to the vehicle",
                value: "",
                label: "",
                children: [
                    {
                        ch: "对于外部接口应当实施安全控告",
                        en: "Security controls shall be applied to external interfaces",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "诊断接入(例如，OBD 端口中的加密狗) 用于给攻击提供便利，例如操纵车辆参数(直接或间接)",
                en: "Diagnostic access (e.g. dongles in OBD port) used to facilitate an attack, e.g. manipulate vehicle parameters (directly or indirectly)",
                value: "",
                label: "",
                children: [
                    {
                        ch: "对于外部接口应当实施安全控告",
                        en: "Security controls shall be applied to external interfaces",
                        value: "",
                        label: "",
                    },
                ],
            },
        ],
    },
    {
        ch: "“攻击的潜在目标或动机”相关的威胁",
        value: "",
        en: "Threats to Potential targets of, or motivations for, an attack",
        label: "",
        children: [
            {
                ch: "从车辆系统中提取版权或专有软件(产品盗版)",
                en: "Extraction of copyright or proprietary software from vehicle systems (product piracy / stolen software)",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应实施访问控制技术和设计来保护系统数据代码。OWASP 中可以找到安全控制相关的实例",
                        en: "Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "未经授权访问车主的隐私信息，如个人标识、支付账户信息、地址簿信息、位置信息、车辆电子 ID 等。",
                en: "Unauthorized access to the owner's privacy information such as personal identity. payment account information, address book information location information. vehicle's electronic ID, etc.",
                value: "",
                label: "",
                children: [
                    {
                        ch: "通过系统设计和访问控制，使得未经授权的人员不可能访问个人或系统的关键数据。在 OWASP 中可以找到安全控制相关的示例",
                        en: "Through system design and access control it should not be possible for unauthorized personnel to access personal or system critical data. Examples of Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "密钥提取",
                en: "Extraction of cryptographic keys",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应实施安全控制来存储加密密钥，例如安全模块",
                        en: "Security controls shall be implemented for storing cryptographic keys e.g. Security Modules",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "非法/未经授权修改车辆电子 ID",
                en: "Illegal/unauthorised changes to vehicle's electronic ID",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应实施访问控制技术和设计来保护系统数据代码。OWASP 中可以找到安全控制相关的实例",
                        en: "Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "身份欺骗。例如，如果用户希望在与计费系统或者制造商后台通信时显示另一身份",
                en: "Identity fraud. For example, if a user wants to display another identity when communicating with toll systems, manufacturer backend",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应实施访问控制技术和设计来保护系统数据代码。OWASP 中可以找到安全控制相关的实例",
                        en: "Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "躲避监控系统的行为(例如，黑客/篡改阻止消息，如 ODR 跟踪器数据或运行次数)",
                en: "Action to circumvent monitoring systems (e.g. hacking' tampering' blocking of messages such as ODR Tracker data. or number of runs)",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应实施访问控制技术和设计来保护系统数据代码。OWASP 中可以找到安全控制相关的实例",
                        en: "Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                    {
                        ch: "应当通过关联来自不同信息源的数据，来缓解对传感器或传输数据的数据操纵攻击",
                        en: "Data manipulation attacks on sensors or transmitted data could be mitigated by correlating the data from different sources of information",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "篡改车辆行驶数据的数据操纵(如里程、行驶速度、行驶方向等)",
                en: "Data manipulation to falsify vehicle's driving data (e.g. mileage, driving speed driving directions, etc.)",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应实施访问控制技术和设计来保护系统数据代码。OWASP 中可以找到安全控制相关的实例",
                        en: "Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                    {
                        ch: "应当通过关联来自不同信息源的数据，来缓解对传感器或传输数据的数据操纵攻击",
                        en: "Data manipulation attacks on sensors or transmitted data could be mitigated by correlating the data from different sources of information",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "未经授权修改系统诊断数据",
                en: "Unauthorised changes to system diagnostic data",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应实施访问控制技术和设计来保护系统数据代码。OWASP 中可以找到安全控制相关的实例",
                        en: "Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                    {
                        ch: "应当通过关联来自不同信息源的数据，来缓解对传感器或传输数据的数据操纵攻击",
                        en: "Data manipulation attacks on sensors or transmitted data could be mitigated by correlating the data from different sources of information",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "未经授权删除/操纵系统事件日志",
                en: "Unauthorized deletion/manipulation of system event logs",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应实施访问控制技术和设计来保护系统数据代码。OWASP 中可以找到安全控制相关的实例",
                        en: "Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "引入恶意软件或者恶意软件行为",
                en: "Introduce malicious software or malicious software activity",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应实施访问控制技术和设计来保护系统数据代码。OWASP 中可以找到安全控制相关的实例",
                        en: "Access control techni1ues and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "车辆控制系统或信息系统软件的伪造",
                en: "Fabrication of software of the vehicle control system or information system",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应实施访问控制技术和设计来保护系统数据代码。OWASP 中可以找到安全控制相关的实例",
                        en: "Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "拒绝服务，例如，这可能通过CAN总线洪泛攻击从内部网络触发，或者通过高速消息发送引发ECU故障",
                en: "Denial of service, for example this may be triggered on the internal network by flooding a CAN bus, or by provoking faults on an ECU via a high rate of messaging",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应采取措施以检测拒绝服务攻击并从中恢复",
                        en: "Measures to detect and recover from a denial of service attack shall be employed",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "未经授权访问以篡改车辆关键功能的配置参数，如制动数据、气囊弹出阈值等。",
                en: "Unauthorized access to falsify configuration parameters of vehicle's key functions, such as brake data, airbag deployed threshold,etc.",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应实施访问控制技术和设计来保护系统数据代码。OWASP 中可以找到安全控制相关的实例",
                        en: "Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "未经授权访问以篡改充电参数，例如充电电压、充电功率、电池温度等等。",
                en: "Unauthorized access to falsify charging parameters, such as charging voltage,charging power, battery temperature, etc.",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应实施访问控制技术和设计来保护系统数据代码。OWASP 中可以找到安全控制相关的实例",
                        en: "Access control techniques and designs shall be applied to protect system data/code. Example Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                ],
            },
        ],
    },
    {
        ch: "“如果未得到充分保护或强化，刚可能会被利用的潜在漏洞”相关的威励",
        value: "",
        en: "Threats to Potential vulnerabilities that could be exploited if not sufficiently protected or hardened",
        label: "",
        children: [
            {
                ch: "短密钥和长密钥有效期的组合使得攻击者能够破坏加密",
                en: "Combination of short encryption keys and long period of validity enables attacker to break encryption",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应当遵循软硬件开发的网络安全最佳实践",
                        en: "Cybersecurity best practices for software and hardware development shall be followed",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "保护敏感系统时加密算法没有被充分利用",
                en: "Insufficient use of cryptographic algorithms to protect sensitive systems",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应当遵循软硬件开发的网络安全最佳实践",
                        en: "Cybersecurity best practices for software and hardware development shall be followed",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "使用已经或者即将被弃用的加密算法",
                en: "Using deprecated cryptographic algorithms",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应当遵循软硬件开发的网络安全最佳实践",
                        en: "Cybersecurity best practices for software and hardware development shall be followed",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "硬件或软件，被设计成可以被攻击，或者未达到阻止攻击的设计标准",
                en: "Hardware or software, engineered to enable an attack or fail to meet design criteria to stop an attack",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应当遵循软硬件开发的网络安全最佳实践",
                        en: "Cybersecurity best practices for software and hardware development shall be followed",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "软件缺陷的存在可能是潜在的可利用漏洞的基础。如果未对软件进行测试，以验证已知的缺陷代码/错误不存在，并降低存在未知的缺陷代码/错误的风险，则尤其会如此",
                en: "The presence of software bugs can be a basis for potential exploitable vulnerabilities. This is particularly true if software has not been tested to verify that known bad code/bugs is not present and reduce the risk of unknown bad code/bugs being present",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应当遵循软硬件开发的网络安全最佳实践",
                        en: "Cybersecurity best practices for software and hardware development shall be followed",
                        value: "",
                        label: "",
                    },
                    {
                        ch: "充分覆盖的网络安全测试",
                        en: "Cybersecurity testing with adequate coverage",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "利用开发遗留(如调试端口、JTAG 端口、微处理器、开发证书、开发人员密码等) 可允许访问 ECU或允许攻击者获得更高权限",
                en: "Using remainders from development (e.g.debug ports, JTAG ports, microprocessors, development certificates, developer passwords, ...) can permit an attacker toaccess ECUs or gain higher privileges",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应当遵循软硬件开发的网络安全最佳实践",
                        en: "Cybersecurity best practices for software and hardware development shall be followed",
                        value: "",
                        label: "",
                    },
                    {
                        ch: "充分覆盖的网络安全测试",
                        en: "Cybersecurity testing with adequate coverage",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "多余的联网端口保持开放，提供了对网终系统的访问",
                en: "Superfluous internet ports left open, providing access to network systems",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应当遵循软硬件开发的网络安全最佳实践",
                        en: "Cybersecurity best practices for software and hardware development shall be followed",
                        value: "",
                        label: "",
                    },
                    {
                        ch: "充分覆盖的网络安全测试",
                        en: "Cybersecurity testing with adequate coverage",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "绕过网络隔离以获得控制。具体示例是使用未受保护的网关或接入点（如卡车拖车网关），以绕过保护并访问其他网段以执行恶意行为，如发送任意的CAN总线消息",
                en: "Circumvent network separation to gaincontrol. Specific example is the use of unprotected gateways, or access points (such as truck-trailer gateways), to circumvent protections and gain access to other network segments to perform malicious acts, such as sending arbitrary CAN bus messages",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应当遵循软硬件开发的网络安全最佳实践",
                        en: "Cybersecurity best practices for software and hardware development shall be followed",
                        value: "",
                        label: "",
                    },
                    {
                        ch: "应当遵循系统设计和系统集成的网络安全最佳实践",
                        en: "Cybersecurity best practices for system design and system integration shall be followed",
                        value: "",
                        label: "",
                    },
                ],
            },
        ],
    },
    {
        ch: "“车辆数据丢失/数据滥露”有关的威胁",
        value: "",
        en: "Threats of Data loss/data breach from vehicle",
        label: "",
        children: [
            {
                ch: "信息泄露。个人数据可能在车辆易主的时候被泄露（例如，被售卖，或者被出租给新的租户)",
                en: "Information breach. Personal data may be breached when the car changes user (e.g. is sold or is used as hire vehicle with new hirers)",
                value: "",
                label: "",
                children: [
                    {
                        ch: "存储个人数据应当遵循保护数据完整性与保密性的最佳实践",
                        en: "Best practices for the protection of data integrity and confidentiality shall be followed for storing personal data.",
                        value: "",
                        label: "",
                    },
                ],
            },
        ],
    },
    {
        ch: "跟“对系统进行物理操作以现攻击”有关的威胁",
        value: "",
        en: "Threats to 'Physical manipulation of systems to enable an attack'",
        label: "",
        children: [
            {
                ch: "操纵OEM硬件，例如未经授权添加到车辆上的电子硬件，可以实现“中间人”攻击",
                en: "Manipulation of OEM hardware, e.g. unauthorised hardware added to a vehicle to enable 'man-in-the-middle' attack",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应采取措施防止和检测未经授权的访问",
                        en: "Measures to prevent and detect unauthorized access shall be employed",
                        value: "",
                        label: "",
                    },
                ],
            },
        ],
    },
    {
        ch: "“后台务器”有关的缓解措施",
        value: "",
        en: "Threats to 'Back end servers'",
        label: "",
        children: [
            {
                ch: "员工滥用特权(内部攻击)",
                en: "Abuse of privileges by staff (insider attack)",
                value: "",
                label: "",
                children: [
                    {
                        ch: "在后台系统实施安全控制以最小化内部攻击的风险",
                        en: "Security Controls are applied to back-end systems to minimise the risk of insider attack",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "未经授权从互联网访问服务器(例如通过后门、未修补的系统软件漏洞、SQL攻击或其他方式启用)",
                en: "Unauthorised internet access to the server (enabled for example by backdoors, unpatched system software vulnerabilities, SQL attacks or other means)",
                value: "",
                label: "",
                children: [
                    {
                        ch: "在后台系统实施安全控制以最小化未经授权的访问。相关安全控制的示例可以在OWASP中找到。",
                        en: "Security Controls are applied to back-end systems to minimise unauthorised access. Example SecurityControls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "未经授权对服务器的物理访问（例如，通过连接到服务器的USB记忆棒或其他介质进行)",
                en: "Unauthorised physical access to the server (conducted by for example USB sticks or other media connecting to the server)",
                value: "",
                label: "",
                children: [
                    {
                        ch: "通过系统设计与访问控制，使得未经授权的访问个人或系统的关键数据称为不可能。",
                        en: "Through system design and access control it should not be possible for unauthorised personnel to access personal or system critical data",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "对后端服务器的攻击会使其停止运行，例如，会阻止其与车辆交互并提供车辆所依赖的服务",
                en: "Attack on back-end server stops it functioning, for example it prevents it from interacting with vehicles and providing services they rely on",
                value: "",
                label: "",
                children: [
                    {
                        ch: "在后台系统实施安全控制。如果后台服务器对提供服务至关重要，则在系统中断的情况下要有恢复措施。相关的安全控制示例可以在oWASP找到。",
                        en: "Security Controls are applied to back-end systems. Where back-end servers are critical to the provision of services there are recovery measures in case of system outage. Example Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "云上的信息丢失。当数据由第三方云服务提供商存储时，敏感数据可能会由于攻击或事故而丢失",
                en: "Loss of information in the cloud.Sensitive data may be lost due to attacks or accidents when data is stored by third-party cloud service providers",
                value: "",
                label: "",
                children: [
                    {
                        ch: "实施安全控制以最小化云计算有关的风险。相关的安全控制示例可以在OWASP和NCSC云计算指南中找到。",
                        en: "Security Controls are applied to minimise risks associated with cloud computing. Example SecurityControls can be found in OWASP and NCSC cloud computing guidance",
                        value: "",
                        label: "",
                    },
                ],
            },
            {
                ch: "意外共享数据导致的信息泄露（例如，管理错误、将数据存储在维修站的服务器中)",
                en: "Information breach by unintended sharing of data (e.g. admin errors, storing data in servers in garages)",
                value: "",
                label: "",
                children: [
                    {
                        ch: "在后台系统实施安全控制防止数据泄露。安全控制的示例可以在OWASP找到。",
                        en: "Security Controls are applied to back-end systems to prevent data breaches. Example Security Controls can be found in OWASP",
                        value: "",
                        label: "",
                    },
                ],
            },
        ],
    },
    {
        ch: "“无意的人为作”有关的威胁",
        value: "",
        en: "Threats relating to 'Unintended human actions'",
        label: "",
        children: [
            {
                ch: "无辜的受害者（如所有者、操作员或维护工程师）被诱骗采取行动，无意加载恶意软件或启动攻击",
                en: "Innocent victim (e.g. owner, operator or maintenance engineer) is tricked into taking an action to unintentionally load malware or enable an attack",
                value: "",
                label: "",
                children: [
                    {
                        ch: "应根据最小访问权限原则，实施相关措施定义和控制用户角色和访问权限",
                        en: "Measures shall be implemented for defining and controlling user roles and access privileges, based on the principle of least access privilege",
                        value: "",
                        label: "",
                    },
                ],
            }, {
                ch: "定义的安全程序没有被遵守",
                en: "Defined security procedures are not followed",
                value: "",
                label: "",
                children: [
                    {
                        ch: "组织应确保定义并遵循了安全程序，包括记录与安全功能管理相关的行动和访问",
                        en: "Organizations shall ensure security procedures are defined and followed including logging of actions and access related to the management of the security functions",
                        value: "",
                        label: "",
                    },
                ],
            },
        ],
    },
    {
        ch: "“物理的数据丢失”有关的威胁",
        value: "",
        en: "Threats of 'Physical loss of data'",
        label: "",
        children: [
            {
                ch: "由第三方造成的损害。在发生交通事故或盗窃的情况下，由于物理的损坏，敏感数据可能会丢失或受损",
                en: "Damage caused by a third party. Sensitive data maybe lost or compromised due to physical damages in cases of traffic accident or theft",
                value: "",
                label: "",
                children: [
                    {
                        ch: "存储个人数据是应当遵循保护数据完整性和保密性的最佳实践。",
                        en: "Best practices for the protection of data integrity and confidentiality shall be followed for storing personal data.",
                        value: "",
                        label: "",
                    },
                    {
                        ch: "安全控制的示例可以在ISO/SC27/WG5中找到。",
                        en: "Example Security Controls can be found in ISO/SC27/WG5",
                        value: "",
                        label: "",
                    },
                ],
            }, {
                ch: "因DRM(数字版权管理）冲突造成的损失。用户数据可能会因为DRM问题被删除",
                en: "Loss from DRM (digital right management)conflicts. User data may be deleted due to DRM issues",
                value: "",
                label: "",
                children: [
                    {
                        ch: "存储个人数据是应当遵循保护数据完整性和保密性的最佳实践。",
                        en: "Best practices for the protection of data integrity and confidentiality shall be followed for storing personal data.",
                        value: "",
                        label: "",
                    },
                    {
                        ch: "安全控制的示例可以在ISO/SC27/WG5中找到。",
                        en: "Example Security Controls can be found in ISO/SC27/WG5",
                        value: "",
                        label: "",
                    },
                ],
            }, {
                ch: "由于IT组件的磨损，敏感数据的完整性可能会丢失，从而导致潜在的级联问题（例如，在密钥更换的情况下)",
                en: "The (integrity of) sensitive data may be lost due to IT components wear and tear, causing potential cascading issues (in case of key alteration, for example)",
                value: "",
                label: "",
                children: [
                    {
                        ch: "存储个人数据是应当遵循保护数据完整性和保密性的最佳实践。",
                        en: "Best practices for the protection of data integrity and confidentiality shall be followed for storing personal data.",
                        value: "",
                        label: "",
                    },
                    {
                        ch: "安全控制的示例可以在ISO/SC27/WG5中找到。",
                        en: "Example Security Controls can be found in ISO/SC27/WG5",
                        value: "",
                        label: "",
                    },
                ],
            },
        ],
    }
];
let strategy = {
    ch: [
        {
            label: "接受风险",
            value: "APPROVE",
        },
        {
            label: "降低风险",
            value: "LESS",
        },
        {
            label: "规避风险",
            value: "AVOID",
        },
        {
            label: "转移风险",
            value: "TARNS",
        },
    ],
    en: [
        {
            label: "Acceptance",
            value: "APPROVE",
        },
        {
            label: "Reduction",
            value: "LESS",
        },
        {
            label: "Avoidance",
            value: "AVOID",
        },
        {
            label: "Transference",
            value: "TARNS",
        },
    ]
};
let state = {
    ch: [
        {
            label: "未开始",
            value: "TO_DO",
        },
        {
            label: "进行中",
            value: "IN_PROGRESS",
        },
        {
            label: "已完成",
            value: "DONE",
        },
    ],
    en: [
        {
            label: "TO DO",
            value: "TO_DO",
        },
        {
            label: "IN PROGRESS",
            value: "IN_PROGRESS",
        },
        {
            label: "DONE",
            value: "DONE",
        },
    ]
};

function modify_ch(data) {
    data.value = data.ch
    data.label = data.ch
    if (data.children) {
        for (let i of data.children) {
            modify_ch(i)
        }
    }
}

function modify_en(data) {
    data.value = data.en
    data.label = data.en
    if (data.children) {
        for (let i of data.children) {
            modify_en(i)
        }
    }
}
function get_tara_data(lang) {
    let tara_data = {
        impact_map,
        timeConsuming,
        professionalKnowledge,
        projectKnowledge,
        opportunity,
        equipment,
        attackRoute,
        strategy,
        state,
        measures1
    };
    if (lang == 'ch') {
        tara_data['threat_map'] = threat_map['ch']
        tara_data['impact_map'] = impact_map['ch']
        tara_data['timeConsuming'] = timeConsuming['ch']
        tara_data['professionalKnowledge'] = professionalKnowledge['ch']
        tara_data['projectKnowledge'] = projectKnowledge['ch']
        tara_data['equipment'] = projectKnowledge['ch']
        tara_data['opportunity'] = opportunity['ch']
        tara_data['state'] = state['ch']
        tara_data['strategy'] = strategy['ch']
        for (let i of attackRoute) {
            modify_ch(i)
        }
        for (let i of measures1) {
            modify_ch(i)
        }
    } else {
        tara_data['threat_map'] = threat_map['en']
        tara_data['impact_map'] = impact_map['en']
        tara_data['timeConsuming'] = timeConsuming['en']
        tara_data['professionalKnowledge'] = professionalKnowledge['en']
        tara_data['projectKnowledge'] = projectKnowledge['en']
        tara_data['equipment'] = projectKnowledge['en']
        tara_data['opportunity'] = opportunity['en']
        tara_data['state'] = state['en']
        tara_data['strategy'] = strategy['en']
        for (let i of attackRoute) {
            modify_en(i)
        }
        for (let i of measures1) {
            modify_en(i)
        }
    }

    return tara_data;
}
export default get_tara_data;
