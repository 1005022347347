<template>
  <div class="topbar_header">
    <el-collapse-transition>
      <div v-show="show_top">
        <div>
          <p id="global_variable_file_test" style="display: none">
            {{ file_test }}
          </p>
          <p id="global_variable_fileId" style="display: none">{{ fileId }}</p>
          <p id="global_variable_select_testplan" style="display: none">
            {{ select_testplan }}
          </p>
          <p id="global_variable_select_testplan_nodes" style="display: none">
            {{ JSON.stringify(global_variable_select_testplan_nodes) }}
          </p>
          <p id="global_variable_in_review" style="display: none">
            {{ is_state_review }}
          </p>
          <p id="global_variable_in_process_review"  style="display: none">
            {{ isProcessReviewer }}
          </p>
          <p id="global_variable_outstanding_type" style="display: none">
            {{ outstanding_type }}
          </p>
          <div class="topbar-header"></div>
          <div class="topbar-main-w">
            <!--  图标链接  -->
            <link
              rel="stylesheet"
              href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
              integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
              crossorigin="anonymous"
            />

            <el-row class="dingw">
              <div class="divhead">
                <el-tooltip
                  :disabled="true"
                  effect="dark"
                  :content="name"
                  placement="bottom"
                >
                  <span
                    class="1"
                    @mouseover="tooltip_show_judge('name_content')"
                  >
                    <span style="display: flex; align-items: center">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="file_icon_name"
                        placement="top"
                      >
                        <div class="box_item_l">
                          <span style="background-color: #014caa">
                            <i
                              v-if="file_icon !== '-1'"
                              class="iconfont"
                              :class="'icon-a-' + file_icon"
                              style="color: #fff"
                            ></i>
                            <span
                              v-else
                              style="color: white; font-weight: 700"
                              >{{ file_icon_name.slice(0, 1) }}</span
                            >
                          </span>
                        </div>
                      </el-tooltip>
                      <div
                        class="box_item_r"
                        @mouseenter="copy_zdh_key_open"
                        @mouseleave="copy_zdh_key_close"
                      >
                        <span class="box_font">
                          <el-tooltip
                            class="item"
                            effect="dark"
                            placement="top"
                            :disabled="!is_show_origin_file"
                          >
                            <div
                              slot="content"
                              style="cursor: pointer"
                              @click="route_origin"
                            >
                              {{ "Copy from " + origin_file }}
                            </div>
                            <div>
                              {{ $route.params.file_key }}

                              <i
                                v-show="show_copy_zdh_key_file_node"
                                class="iconfont icon-a-Frame1 copy_zdh_key"
                                @click.stop="
                                  copy_zdh_key_file_node($route.params.file_key)
                                "
                              />
                            </div>
                          </el-tooltip>
                        </span>
                      </div>
                    </span>
                    <i
                      v-if="!isCollected"
                      class="iconfont icon-shoucang"
                      @click="toggleShoucang"
                    />
                    <i
                      v-else
                      class="iconfont icon-shoucangClick"
                      style="
                        color: rgb(255, 195, 0);
                        margin-left: 20px;
                        cursor: pointer;
                      "
                      @click="toggleShoucang"
                    />
                    <el-tooltip
                      :disabled="tooltiop_show"
                      effect="dark"
                      :content="name"
                      placement="bottom"
                    >
                      <span
                        v-if="flag"
                        ref="name_content"
                        class="name_span"
                        style="font-size: large; cursor: pointer"
                        @dblclick="edit()"
                      >
                        {{
                          name.length > 25 ? name.slice(0, 25) + "..." : name
                        }}
                      </span>
                      <input
                        v-else
                        ref="input_panel"
                        v-model="input_name"
                        type="text"
                        @keyup.enter="away"
                        @blur="away"
                      />
                    </el-tooltip>
                  </span>
                </el-tooltip>
                <span style="font-size: 12px; color: rgb(122, 122, 122)"></span>
                <div v-if="!modelView">
                  <el-button
                    v-if="isReviewer"
                    type="info"
                    style="margin-left: 20px; text-align: center"
                    size="mini"
                    @click="out_review($event)"
                    >{{ $t("node.message.message4") }}</el-button
                  >
                  <el-button
                    v-else
                    type="primary"
                    style="margin-left: 20px; text-align: center"
                    size="mini"
                    @click="in_review($event)"
                    >{{ $t("node.message.message5") }}</el-button
                  >
                </div>
              </div>
            </el-row>
            <div class="topbar_con">
              <!--          操作菜单-->
              <div class="topbar_left">
                <div class="test">
                  <el-menu
                    :default-active="activeIndex"
                    text-color="#333"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="handleSelect"
                  >
                    <el-submenu index="2">
                      <template slot="title">
                        <span>
                          <!-- <i
                            class="icon-small el-icon-folder"
                            style="margin-right: 10px; font-size: 18px"
                          ></i> -->
                          <span>{{ $t("canvas.btn.file") }}</span>
                        </span>
                      </template>
                      <el-menu-item
                        v-limits-of-authority="'NODE_CREATE'"
                        index="2-1"
                        @click.native="copySelf"
                        >{{ $t("canvas.fileDropDown.paste") }}</el-menu-item
                      >

                      <el-submenu index="2-4">
                        <template slot="title">{{
                          $t("canvas.fileDropDown.export")
                        }}</template>
                        <el-menu-item
                          v-limits-of-authority="'FILE_DOWNLOAD'"
                          index="2-4-3"
                          @click.native="exportExcel(false)"
                          >excel</el-menu-item
                        >
                        <el-menu-item
                          v-limits-of-authority="'FILE_DOWNLOAD'"
                          index="2-4-4"
                          @click.native="exportWord"
                          >word</el-menu-item
                        >
                        <el-menu-item
                          v-limits-of-authority="'FILE_DOWNLOAD'"
                          index="2-4-6"
                          @click.native="exportExcel(true)"
                          >csv</el-menu-item
                        >
                        <el-menu-item
                          v-limits-of-authority="'FILE_DOWNLOAD'"
                          index="2-4-5"
                          @click.native="exportImg"
                          >png</el-menu-item
                        >
                      </el-submenu>
                      <el-menu-item index="2-5" @click.native="archiveFile">{{
                        $t("baseline.archive.title1"),
                      }}</el-menu-item>
                      <el-menu-item
                        index="2-6"
                        @click.native="getArchiveFile"
                        >{{ $t("knowledge.HistoricalVersions") }}</el-menu-item
                      >
                      <el-menu-item index="2-2" @click.native="deleteSelf">{{
                        $t("canvas.fileDropDown.delete")
                      }}</el-menu-item>
                      <el-menu-item
                        v-if="!lineWord"
                        index="2-3"
                        @click.native="creatTemplate"
                        >{{
                          $t("canvas.fileDropDown.creatTemplate")
                        }}</el-menu-item
                      >
                    </el-submenu>
                    <el-submenu index="3" :disabled="isReviewer ? true : false">
                      <template slot="title">
                        <span id="topbar-view">
                          <!-- <i
                            class="iconfont icon-shitu"
                            style="margin-right: 10px"
                          ></i> -->
                          {{ $t("canvas.btn.view") }}
                        </span>
                      </template>
                      <el-menu-item
                        v-if="!treeView"
                        index="3-1"
                        @click.native="handle_state_outstanding_click"
                      >
                        <span
                          :style="`${
                            is_state_outstanding
                              ? 'color: rgba(48, 100, 143, 1);font-weight:bold'
                              : ''
                          }`"
                          >{{ $t("canvas.viewDropDown.showStatus") }}</span
                        >
                        <span
                          :style="{
                            color: 'rgb(144, 150, 159)',
                            float: 'right',
                            fontSize: '12px',
                          }"
                        >
                          {{ showShortcutkeys("showStatus_shortcutKey") }}
                        </span>
                      </el-menu-item>
                      <el-menu-item
                        v-if="!treeView"
                        index="3-6"
                        :style="`${
                          is_state_response
                            ? 'color: rgba(48, 100, 143, 1);font-weight:bold'
                            : ''
                        }`"
                        @click.native="handle_state_response_click"
                      >
                        {{ $t("canvas.viewDropDown.responsibility") }}
                        <div
                          :style="{
                            color: 'rgb(144, 150, 159)',
                            float: 'right',
                            fontSize: '12px',
                          }"
                        >
                          {{ showShortcutkeys("responsibility_shortcutKey") }}
                        </div>
                      </el-menu-item>
                      <el-menu-item
                        v-if="!treeView"
                        index="3-5"
                        :style="`${
                          is_state_Sprint
                            ? 'color: rgba(48, 100, 143, 1);font-weight:bold'
                            : ''
                        }`"
                        @click.native="handle_Sprint_click"
                      >
                        Sprint
                        <span
                          :style="{
                            color: 'rgb(144, 150, 159)',
                            float: 'right',
                            fontSize: '12px',
                          }"
                        >
                          {{ showShortcutkeys("Sprint_shortcutKey") }}
                        </span>
                      </el-menu-item>
                      <!-- 其他字段 -->
                      <el-submenu
                        v-if="!treeView"
                        index="3-7"
                        :style="`${
                          is_state_otherFile
                            ? 'color: rgba(48, 100, 143, 1);font-weight:bold'
                            : ''
                        }`"
                      >
                        <template slot="title">
                          {{ $t("node.message.message6") }}
                        </template>
                        <el-menu-item
                          v-for="value in otherFile_list"
                          :key="value[0]"
                          :style="`${
                            is_state_otherFile && value[0] == otherData_fieldId
                              ? 'color: rgba(48, 100, 143, 1);font-weight:bold'
                              : ''
                          }`"
                          @click.native="
                            handle_state_otherFile_click(value[0], true)
                          "
                          >{{ value[1].name }}</el-menu-item
                        >
                      </el-submenu>
                      <el-menu-item
                        v-if="!modelView"
                        index="3-2"
                        @click.native="handle_required_check_click"
                      >
                        <span
                          :style="`${
                            is_check_required
                              ? 'color: rgba(48, 100, 143, 1);font-weight:bold'
                              : ''
                          }`"
                          >{{ $t("canvas.viewDropDown.detectRequired") }}</span
                        >
                        <span
                          :style="{
                            color: 'rgb(144, 150, 159)',
                            float: 'right',
                            fontSize: '12px',
                          }"
                        >
                          {{ showShortcutkeys("detectRequired_shortcutKey") }}
                        </span>
                      </el-menu-item>
                      <el-menu-item
                        v-if="!modelView"
                        index="3-3"
                        @click.native="handle_reuse_situation_click"
                      >
                        <span
                          :style="`${
                            is_check_reuse
                              ? 'color: rgba(48, 100, 143, 1);font-weight:bold'
                              : ''
                          }`"
                          >{{ $t("canvas.viewDropDown.Reusesituation") }}</span
                        >
                      </el-menu-item>

                      <!-- 覆盖度检测 -->

                      <el-menu-item
                        v-if="!modelView"
                        index="3-4"
                        @click.native="handle_check_coverage_click"
                      >
                        <span
                          :style="`${
                            is_check_coverage
                              ? 'color: rgba(48, 100, 143, 1);font-weight:bold'
                              : ''
                          }`"
                          >{{ $t("canvas.viewDropDown.coverage") }}</span
                        >
                      </el-menu-item>

                      <!--

                      <el-submenu v-if="!modelView" index="3-4">
                        <template slot="title">{{
                          $t("canvas.viewDropDown.coverage")
                        }}</template>
                        <el-menu-item
                          v-for="coverage_item in coverage_list"
                          :key="coverage_item.fileTypeId"
                          :style="`${
                            is_check_coverage &&
                            relationFileTypeId == coverage_item.fileTypeId
                              ? 'color: rgba(48, 100, 143, 1);font-weight:bold'
                              : ''
                          }`"
                          @click.native="
                            handle_coverage_check_click(
                              coverage_item.name,
                              coverage_item.fileTypeId
                            )
                          "
                          >{{ coverage_item.name }}</el-menu-item
                        >
                      </el-submenu> -->
                      <!-- 安全 -->
                      <el-submenu
                        index="3-5"
                        :style="`${
                          is_state_otherFile
                            ? 'color: rgba(48, 100, 143, 1);font-weight:bold'
                            : ''
                        }`"
                      >
                        <template slot="title">
                          {{ $t("menu.FunctionalSafety") }}
                        </template>
                        <el-menu-item @click.native="open_hara_dialog">
                          HARA
                        </el-menu-item>
                        <el-menu-item
                          v-if="!file_test3"
                          @click.native="DFMEAView"
                          >{{ $t("canvas.viewDropDown.DFMEAView") }}
                        </el-menu-item>
                        <el-menu-item @click.native="open_fta_dialog">
                          {{ $t("fta.title") }}
                        </el-menu-item>
                        <el-menu-item @click.native="open_tara_dialog">
                          TARA
                        </el-menu-item>
                      </el-submenu>

                      <el-submenu index="3-6">
                        <template slot="title">
                          {{ $t("model_view.defult_view1") }}</template
                        >
                        <el-menu-item @click.native="defult_view('myMind')">
                          {{ $t("canvas.tip14") }}
                        </el-menu-item>
                        <el-menu-item @click.native="defult_view('lineword')">
                          {{ $t("canvas.tip13") }}
                        </el-menu-item>
                        <el-menu-item @click.native="defult_view('model')">
                          {{ $t("model_view.defult_view2") }}
                        </el-menu-item>
                        <el-menu-item @click.native="defult_view('tree')">
                          {{ $t("model_view.defult_view3") }}
                        </el-menu-item>
                      </el-submenu>
                      <!--
                      <el-menu-item @click="default_word">
                        {{ $t("canvas.tip14") }}

                        <i
                          class="iconfont icon-a-siweidaotu2"
                          style="font-size: 20px"
                        ></i>
                      </el-menu-item>

                      <el-menu-item @click="default_map">
                      {{ $t("canvas.tip14") }}
                        <i
                          class="iconfont icon-a-word1"
                          style="font-size: 20px"
                        ></i>
                      </el-menu-item> -->
                    </el-submenu>

                    <div id="four_view_switch" class="four_view_switch">
                      <div
                        :class="view_type == 'myMind' ? 'active_swi_zdh' : ''"
                        @click="map_link"
                      >
                        {{ $t("canvas.viewDropDown.mindView1") }}
                      </div>
                      <div
                        :class="view_type == 'lineword' ? 'active_swi_zdh' : ''"
                        @click="word_link"
                      >
                        Word
                      </div>
                      <div
                        :class="view_type == 'model' ? 'active_swi_zdh' : ''"
                        @click="model_link"
                      >
                        {{ $t("canvas.viewDropDown.modelView1") }}
                      </div>
                      <div
                        :class="view_type == 'tree' ? 'active_swi_zdh' : ''"
                        @click="tree_link"
                      >
                        {{ $t("canvas.viewDropDown.treeView1") }}
                      </div>
                    </div>
                    <!-- <el-menu-item
                      v-if="!lineWord && !modelView"
                      index="5"
                      style="padding: 0 20px; border: none"
                    > -->
                    <div v-if="!lineWord && !modelView">
                      <div
                        class="fuchuang_new_zdh"
                        :style="
                          $t('canvas.btn.floating') == '浮窗'
                            ? 'min-width: 70px'
                            : 'min-width: 140px'
                        "
                        @click="chenge_fvalue"
                      >
                        <div>{{ $t("canvas.btn.floating") }}</div>
                        <i
                          v-if="fvalue"
                          class="iconfont icon-pwo"
                          style="font-size: 20px; color: #004caa"
                        ></i>

                        <i
                          v-else
                          class="iconfont icon-pwc"
                          style="font-size: 20px; color: #707070"
                        ></i>
                      </div>
                      <!-- <el-switch
                        v-model="fvalue"
                        active-text
                        :inactive-text="$t('canvas.btn.floating')"
                        class="text_change spacing"
                      ></el-switch> -->
                    </div>
                    <!-- <el-menu-item :disabled="notEditable" index="3">
                     <svg-icon icon-class="back"></svg-icon>
                   </el-menu-item>
                   <el-menu-item :disabled="notEditable" index="4">
                     <svg-icon icon-class="next"></svg-icon>
                   </el-menu-item> -->

                    <el-menu-item
                      v-if="!lineWord && !modelView"
                      :disabled="notEditable"
                    >
                      <div
                        style="
                          display: flex;
                          height: 60px;
                          flex-direction: column;
                          justify-content: center;
                          width:20px
                        "
                      >
                        <el-tooltip
                          class="item"
                          effect="dark"
                          placement="bottom"
                        >
                          <div slot="content">
                            {{ $t("canvas.tip1") }}
                          </div>
                          <!-- 放大icon -->
                          <i
                            @click="amplification"
                            class="iconfont icon-a-zoomin"
                            style="
                              font-size: 20px;
                              transform: scale(0.5, 0.5);
                              line-height: 10px;
                            "
                          ></i>
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          placement="bottom"
                        >
                          <div slot="content">
                            {{ $t("canvas.tip2") }}
                          </div>
                          <!-- 缩小icon -->
                          <i
                            @click="narrow"
                            class="iconfont icon-a-zoomout"
                            style="
                              font-size: 20px;
                              transform: scale(0.5, 0.5);
                              line-height: 10px;
                            "
                          ></i>
                        </el-tooltip>
                      </div>
                    </el-menu-item>
                    <!-- <el-menu-item
                      v-if="!lineWord && !modelView"
                      :disabled="notEditable"
                      
                    >
             
                    </el-menu-item> -->
                    <el-menu-item v-if="!modelView" :disabled="notEditable">
                      <div
                        style="
                          display: flex;
                          height: 60px;
                          flex-direction: column;
                          justify-content: center;
                          width:20px
                        "
                      >
                        <el-tooltip
                          class="item"
                          effect="dark"
                          placement="bottom"
                        >
                          <div slot="content">
                            {{ $t("canvas.tip3") }}
                          </div>
                          <!-- 上移icon -->
                          <i
                            @click="moveUp"
                            class="iconfont icon-a-moveup"
                            style="
                              font-size: 20px;
                              transform: scale(0.5, 0.5);
                              line-height: 10px;
                            "
                          ></i>
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          placement="bottom"
                        >
                          <div slot="content">
                            {{ $t("canvas.tip4") }}
                          </div>
                          <!-- 下移icon -->
                          <i
                            @click="moveDown"
                            class="iconfont icon-a-movedown"
                            style="
                              font-size: 20px;
                              transform: scale(0.5, 0.5);
                              line-height: 10px;
                            "
                          ></i>
                        </el-tooltip>
                      </div>
                    </el-menu-item>
                    <el-menu-item v-if="!modelView">
                      <!-- 覆盖度 -->

                      <div
                        style="
                          display: flex;
                          height: 60px;
                          flex-direction: column;
                          justify-content: center;
                          width:20px
                        "
                      >
                        <el-tooltip
                          id="coverage_yes"
                          class="item"
                          effect="dark"
                          placement="bottom"
                        >
                          <div slot="content">
                            {{ $t("node.message.needCoverage") }}
                          </div>
                          <i
                            @click="needCoverage"
                            class="iconfont icon-a-nocover"
                            style="
                              font-size: 20px;
                              transform: scale(0.5, 0.5);
                              line-height: 10px;
                            "
                          ></i>
                        </el-tooltip>
                        <el-tooltip
                          id="coverage_no"
                          class="item"
                          effect="dark"
                          placement="bottom"
                        >
                          <div slot="content">
                            {{ $t("node.message.noNeedCoverage") }}
                          </div>
                          <i
                            @click="ignoreCoverage"
                            class="iconfont icon-cover"
                            style="
                              font-size: 20px;
                              transform: scale(0.5, 0.5);
                              line-height: 10px;
                            "
                          ></i>
                        </el-tooltip>
                      </div>
                    </el-menu-item>
                    <!-- <el-menu-item
                      v-if="!modelView"
                      :disabled="notEditable"
                      
                    >
                    </el-menu-item> -->
                    <el-menu-item
                      v-if="!lineWord && !modelView"
                      :disabled="notEditable"
                      @click="indent"
                    >
                      <el-tooltip
                        v-if="nodeTextIndent == 'left'"
                        class="item"
                        effect="dark"
                        placement="bottom"
                      >
                        <div slot="content">
                          {{ $t("canvas.tip17") }}
                        </div>
                        <!-- 左对齐或居中对齐icon -->
                        <i
                          class="iconfont icon-indent"
                          style="font-size: 20px"
                        ></i>
                      </el-tooltip>
                      <el-tooltip
                        v-else
                        class="item"
                        effect="dark"
                        placement="bottom"
                      >
                        <div slot="content">
                          {{ $t("canvas.tip5") }}
                        </div>
                        <!-- 左对齐或居中对齐icon -->
                        <i
                          class="iconfont icon-indent"
                          style="font-size: 20px"
                        ></i>
                      </el-tooltip>
                    </el-menu-item>
                    <el-menu-item
                      v-if="!lineWord && !modelView"
                      :disabled="notEditable"
                      @click="full"
                    >
                      <el-tooltip class="item" effect="dark" placement="bottom">
                        <div slot="content">
                          {{ $t("canvas.tip6") }}
                        </div>
                        <!-- 全屏icon -->
                        <i class="iconfont icon-fullscreen"></i>
                      </el-tooltip>
                    </el-menu-item>

                    <!-- 覆盖度 -->

                    <!-- <el-menu-item v-if="!modelView">
                    </el-menu-item> -->
                    <el-menu-item
                      v-if="!modelView"
                      :disabled="
                        notEditable || limits_of_authority_disable('NODE_EDIT')
                      "
                      @click="buchong"
                    >
                      <!-- AI补充 -->
                      <el-tooltip
                        id="dddd7"
                        v-limits-of-authority="'NODE_EDIT'"
                        class="item"
                        effect="dark"
                        placement="bottom"
                      >
                        <div slot="content">
                          {{ $t("canvas.AI.tip2") }}
                        </div>
                        <i
                          class="iconfont icon-AIbuchong"
                          style="font-size: 20px; color: rgb(42, 130, 228)"
                        ></i>
                      </el-tooltip>
                    </el-menu-item>

                    <el-menu-item
                      v-if="view_type == 'myMind' || view_type == 'lineword'"
                      @click="open_search"
                    >
                      <el-tooltip
                        id="coverage_no"
                        class="item"
                        effect="dark"
                        placement="bottom"
                      >
                        <div slot="content">
                          {{ $t("file.top.search") }}
                        </div>
                        <i
                          class="iconfont icon-Union"
                          style="font-size: 20px"
                        ></i>
                      </el-tooltip>
                    </el-menu-item>

                    <el-menu-item
                      v-if="!treeView && !modelView"
                      :disabled="notEditable"
                    >
                      <!-- 提示icon -->
                      <el-tooltip class="item" effect="dark" placement="bottom">
                        <div v-if="!lineWord" slot="content">
                          {{ $t("canvas.tip") }}
                        </div>
                        <div v-else slot="content">{{ $t("canvas.tip9") }}</div>
                        <i
                          id="dddd8"
                          class="iconfont icon-zwen"
                          style="font-size: 20px"
                        ></i>
                      </el-tooltip>
                    </el-menu-item>

                    <el-menu-item v-if="treeView" :disabled="notEditable">
                      <!-- 提示icon -->
                      <el-tooltip class="item" effect="dark" placement="bottom">
                        <div slot="content">
                          {{ $t("canvas.tip15") }}
                        </div>
                        <i
                          id="dddd8"
                          class="iconfont icon-zwen"
                          style="font-size: 20px"
                        ></i>
                      </el-tooltip>
                    </el-menu-item>

                    <el-menu-item
                      v-if="modelView && !treeView"
                      :disabled="notEditable"
                    >
                      <!-- 提示icon -->
                      <el-tooltip class="item" effect="dark" placement="bottom">
                        <div slot="content">
                          {{ $t("canvas.tip16") }}
                        </div>
                        <i
                          id="dddd8"
                          class="iconfont icon-zwen"
                          style="font-size: 20px"
                        ></i>
                      </el-tooltip>
                    </el-menu-item>
                  </el-menu>
                </div>
              </div>
              <div class="topbar_right">
                <div
                  v-if="file_test2 && !isReviewer && $route.name != 'model'"
                  :span="7"
                  style="margin-right: 50px; height: 100%"
                >
                  <el-button
                    v-if="test1btn"
                    v-limits-of-authority="'NODE_EDIT'"
                    class="test_btn1"
                    style="text-align: center"
                    @click="test1btn = false"
                  >
                    <i
                      class="iconfont icon-fasong"
                      style="color: rgba(255, 255, 255, 1)"
                    ></i>
                    {{ $t("canvas.btn.executeTestPlan") }}</el-button
                  >

                  <div v-if="!test1btn" class="downbtnstyle">
                    <el-select
                      :key="selectkey"
                      v-model="select_testplan"
                      clearable
                      filterable
                      :placeholder="$t('canvas.testPlan.selectBtn')"
                      style="width: 250px"
                    >
                      <el-option
                        v-for="ite in test_options"
                        :key="ite.testPlanId"
                        :label="ite.name_display"
                        :disabled="!ite.principal"
                        :value="ite.testPlanId"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <!-- 邀请评审按钮 -->
                <div
                  v-show="$route.path.indexOf('lineword') > 0 ? false : true"
                >
                  <div v-if="!isProcessReviewer & btn_isShow" :span="6">
                    <el-button
                      type="primary"
                      style="text-align: center"
                      size="mini"
                      @click="showCollaborationDialog($event)"
                      >{{ $t("canvas.reviewRequestForm.title") }}</el-button
                    >
                    <!-- <collaboration-dialog :visible.sync="collabDialogVisible"></collaboration-dialog> -->
                  </div>
                </div>
                <!-- 执行评审按钮 -->
                <div v-if="!isProcessReviewer & btn_isShow" :span="6">
                  <el-button
                    type="primary"
                    style="text-align: center"
                    size="mini"
                    @click="process_review($event)"
                    >{{ $t("addMindMap.tip9") }}</el-button
                  >
                  <!-- <collaboration-dialog :visible.sync="collabDialogVisible"></collaboration-dialog> -->
                </div>
                <!--          查看评审结果-->
                <div v-if="isProcessReviewer & btn_isShow" :span="6">
                  <el-button
                    type="primary"
                    style="text-align: center"
                    size="mini"
                    @click="in_review"
                    >{{ $t("canvas.btn.reviewResult") }}</el-button
                  >
                </div>
                <div
                  v-if="
                    is_file_can_share && !is_state_review && !isProcessReviewer
                  "
                  :span="6"
                >
                  <el-tooltip
                    effect="dark"
                    :content="$t('share.sharetip')"
                    placement="top"
                  >
                    <el-button
                      v-if="!modelView"
                      type="primary"
                      style="
                        text-align: center;
                        float: left;
                      "
                      size="mini"
                      @click="share_file_now($event)"
                    >
                      <i
                        class="iconfont icon-go-forward nohover"
                        style="color: rgba(255, 255, 255, 1)"
                      />
                      {{ $t("share.btn") }}</el-button
                    >
                  </el-tooltip>

                  <!-- <collaboration-dialog :visible.sync="collabDialogVisible"></collaboration-dialog> -->
                </div>
              </div>
            </div>

            <div class="under_line"></div>
            <div
              v-if="show_top"
              class="sprint_options_hide line"
              @click="
                show_top = !show_top;
                sent_message();
              "
            >
              <i class="iconfont icon-a-fenzu2" style="color: #909399" />
            </div>
          </div>
        </div>
      </div>
    </el-collapse-transition>
    <div
      v-if="!show_top"
      class="sprint_options_hide hide"
      style="z-index: 1; transform: rotate(180deg)"
      @click="
        show_top = !show_top;
        sent_message();
      "
    >
      <i
        class="iconfont icon-a-fenzu2"
        style="color: #909399; transform: rotate(90deg)"
      />
    </div>
    <el-dialog
      :visible.sync="settingVisible"
      width="25%"
      style="text-align: left"
      :title="$t('canvas.excel.setting')"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
    ></el-dialog>
    <el-dialog
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="excelDialogVisible"
      width="25%"
      style="text-align: left"
    >
      <h2 style="margin-left: 5px">
        {{ $t("canvas.excel.title") }}
      </h2>
      <el-table
        ref="multipleTable"
        class="tb"
        :data="productlist"
        :header-cell-style="{ background: '#F4F4F4' }"
        :row-style="rowClass"
        border
        @select-all="onSelectAll"
        @select="select"
      >
        <el-table-column
          type="selection"
          :selectable="isDisabled"
          width="80"
        ></el-table-column>
        <el-table-column
          :label="$t('canvas.excel.mindmapName')"
          prop="productName"
        ></el-table-column>
        <el-table-column
          :label="$t('canvas.excel.note')"
          prop="content"
        ></el-table-column>
      </el-table>
      <el-button style="margin-top: 10px" type="primary" @click="handleShow">{{
        $t("btn.confirmBtn")
      }}</el-button>
    </el-dialog>
    <!-- 导出excel弹窗 -->
    <el-dialog
      :title="
        isCsv
          ? $t('file.table.options.dialog.title4')
          : $t('file.table.options.dialog.title')
      "
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible="dialogVisible"
      :before-close="handleClose"
      custom-class="excel_export_dialog"
    >
      <div
        v-if="!isCsv"
        style="
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 20px;
        "
      >
        <div
          style="
            margin-right: 20px;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            color: gray;
          "
        >
          {{ $t("btn.export_tem") }}
        </div>
        <el-select
          v-model="template"
          :placeholder="$t('placeholder.dropdown')"
          clearable
        >
          <el-option
            v-for="item in tem_options"
            :key="item.templateId"
            :label="item.name"
            :value="item.templateId"
          >
          </el-option>
        </el-select>
      </div>
      <el-checkbox v-model="all_fields_item" @change="judge_all">{{
        $t("file.table.options.dialog.box1")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>
        {{ $t("file.table.options.dialog.box2") }}
      </el-checkbox>
      <el-checkbox v-model="must_check" disabled>
        {{ $t("file.table.options.dialog.box3") }}
      </el-checkbox>
      <el-checkbox v-model="must_check" disabled>
        {{ $t("file.table.options.dialog.box4") }}
      </el-checkbox>
      <el-checkbox-group
        v-model="submit_list"
        text-color=" rgba(66, 159, 255, 1)"
      >
        <el-checkbox
          v-for="(item, index) in checkList"
          :key="index"
          :label="item.fieldId"
          >{{ item.label }}</el-checkbox
        >
      </el-checkbox-group>
      <span slot="footer">
        <el-button @click="dialogVisible = false">
          {{ $t("file.table.options.dialog.box5") }}
        </el-button>
        <el-button type="primary" @click="submit_Excel">
          {{ $t("file.table.options.dialog.box6") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 另存为新模板 -->
    <el-dialog
      :title="$t('canvas.fileDropDown.creatTemplatePlace1')"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="tempCreatDialogVisible"
    >
      <div style="display: flex; align-items: center">
        <div class="red_star" style="width: 130px; text-align: left">
          {{ $t("canvas.fileDropDown.templatename") }}
        </div>
        <el-input
          v-model="tempCreatName"
          :placeholder="$t('canvas.fileDropDown.creatTemplatePlace')"
        ></el-input>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px">
        <div style="width: 130px; text-align: left">
          {{ $t("templateLibrary.tag") }}
          <el-tooltip class="item" effect="dark" placement="bottom">
            <div slot="content">
              {{ $t("templateLibrary.tagTip1") }}
            </div>
            <i
              id="dddd8"
              class="iconfont icon-help"
              style="font-size: 16px"
            ></i>
          </el-tooltip>
        </div>
        <el-select
          v-model="customTagSelect"
          multiple
          filterable
          :placeholder="$t('placeholder.dropdown')"
          style="width: 100%"
        >
          <el-option
            v-for="item in customTagList"
            :key="item.tagId"
            :label="item.name"
            :value="item.tagId"
          >
          </el-option>
        </el-select>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px">
        <div style="width: 130px; text-align: left">
          {{ $t("templateLibrary.Visibility") }}
        </div>
        <el-select
          v-model="customTagAuthority"
          multiple
          filterable
          :loading="user_loading"
          :filter-method="groupandUserfilterMethod"
          :loading-text="$t('loading.loading2')"
          clearable
          style="width: 100%"
        >
          <el-option-group
            v-for="(group, index) in filterGroupandUser"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="user in group.options"
              :key="user.value"
              :label="user.label"
              :value="user.value"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </div>
      <div style="margin-top: 20px; display: flex; align-items: center">
        <div class="red_star" style="width: 130px; text-align: left">
          {{ $t("canvas.fileDropDown.templatedescript") }}
        </div>
        <el-input
          v-model="description"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5 }"
          resize="none"
          :placeholder="$t('canvas.fileDropDown.descriptplace')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="info"
          size="small"
          @click="tempCreatDialogVisible = false"
          >{{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="templateLoading"
          @click="tempCreatConfirm"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 导出图像 -->
    <el-dialog
      v-if="show_export_png_dialog"
      :title="$t('canvas.fileDropDown.export') + 'Png'"
      :close-on-click-modal="false"
      :show-close="false"
      :visible.sync="show_export_png_dialog"
      top="10vh"
      style="visibility: hidden"
    >
      <export-png></export-png>
    </el-dialog>
    <!-- style="visibility: hidden" -->

    <!-- fta -->
    <el-dialog
      v-if="show_fta_dialog"
      width="80%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="show_fta_dialog"
      top="10vh"
    >
      <template slot="title" slot-scope="scope">
        <div style="display: flex; flex-direction: row; align-items: center">
          <div>{{ $t("fta.title") }}</div>
          <div @click="goKnowledge">
            <i
              class="iconfont icon-a-fenzu6"
              style="
                cursor: pointer;
                margin-left: 15px;
                font-size: 14px;
                font-weight: 300;
                line-height: 28px;
              "
            ></i>
          </div>
        </div>
      </template>
      <Fta></Fta>
    </el-dialog>

    <!-- TARA -->
    <el-dialog
      v-if="show_tara_dialog"
      id="tara_dialog"
      width="80%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="show_tara_dialog"
      top="10vh"
    >
      <Tara></Tara>
    </el-dialog>
    <el-dialog
      v-if="show_hara_dialog"
      id="tara_dialog"
      width="80%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="show_hara_dialog"
      top="10vh"
    >
      <Hara></Hara>
    </el-dialog>
    <el-dialog
      v-if="is_state_outstanding"
      id="outstanding_detail_dialog"
      v-draggable
      class="outstanding_detail_dialog"
      :outstanding_type="outstanding_type"
      custom-class="outstanding_detail_dialog_body"
      :visible.sync="is_state_outstanding"
      width="260px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="outstanding_name"
    >
      <el-table
        :data="sort_array(outstanding_map)"
        class="outstanding_dialog_table_class"
        :show-header="false"
        cell-class-name="outstanding_dialog_table_class_cell"
        @selection-change="handle_select_state_outstanding"
      >
        <el-table-column type="selection" width="30"> </el-table-column>
        <el-table-column prop="name" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="outstanding_name">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="color" width="30">
          <template slot-scope="scope">
            <div
              class="outstanding_dialog_color"
              :style="'background-color:#' + scope.row.color"
            ></div>
          </template>
        </el-table-column>
        <el-table-column prop="num" width="50"> </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      v-if="is_state_response"
      id="outstanding_detail_dialog"
      v-draggable
      :outstanding_type="outstanding_type"
      class="outstanding_detail_dialog"
      custom-class="outstanding_detail_dialog_body"
      :visible.sync="is_state_response"
      width="260px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="outstanding_name"
    >
      <el-table
        :data="sort_array_null(outstanding_map)"
        class="outstanding_dialog_table_class"
        :show-header="false"
        cell-class-name="outstanding_dialog_table_class_cell"
        @selection-change="handle_select_state_outstanding_assignee"
      >
        <el-table-column type="selection" width="30"> </el-table-column>
        <el-table-column prop="name" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="outstanding_name">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="color" width="30">
          <template slot-scope="scope">
            <div :class="'outstanding_dialog_color ' + scope.row.color"></div>
          </template>
        </el-table-column>
        <el-table-column prop="num" width="50"> </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 其他字段状态框 -->
    <el-dialog
      v-if="is_state_otherFile"
      id="outstanding_detail_dialog"
      v-draggable
      class="outstanding_detail_dialog"
      custom-class="outstanding_detail_dialog_body"
      :outstanding_type="outstanding_type"
      :visible.sync="is_state_otherFile"
      width="260px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="getValue_name()"
    >
      <el-table
        :data="sort_array_null(outstanding_map)"
        class="outstanding_dialog_table_class"
        :show-header="false"
        cell-class-name="outstanding_dialog_table_class_cell"
        @selection-change="handle_select_state_otherfile"
      >
        <el-table-column type="selection" width="30"> </el-table-column>
        <el-table-column prop="name" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="outstanding_name">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="color" width="30">
          <template slot-scope="scope">
            <div
              class="outstanding_dialog_color"
              :style="'background-color:' + scope.row.color"
            ></div>
          </template>
        </el-table-column>
        <el-table-column prop="num" width="50"> </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      v-if="is_check_coverage"
      id="outstanding_detail_dialog"
      v-draggable
      class="outstanding_detail_dialog"
      custom-class="outstanding_detail_dialog_body"
      :visible.sync="is_check_coverage"
      :outstanding_type="outstanding_type"
      width="260px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('editFileType.coverage.title')"
    >
      <el-table
        :data="outstanding_map"
        class="outstanding_dialog_table_class"
        :show-header="false"
        cell-class-name="outstanding_dialog_table_class_cell"
        @selection-change="handle_select_state_cover"
      >
        <el-table-column type="selection" width="30"> </el-table-column>
        <el-table-column prop="name" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="outstanding_name">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="color" width="30">
          <template slot-scope="scope">
            <div
              class="outstanding_dialog_color"
              :style="'background-color:' + scope.row.color"
            ></div>
          </template>
        </el-table-column>
        <el-table-column prop="num" width="50"> </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 复用情况弹窗 -->
    <el-dialog
      v-if="is_check_reuse"
      id="outstanding_detail_dialog"
      v-draggable
      class="outstanding_detail_dialog"
      custom-class="outstanding_detail_dialog_body"
      :visible.sync="is_check_reuse"
      :outstanding_type="outstanding_type"
      width="260px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span slot="title"
        >{{ fileTypeName }}
        <i
          class="iconfont icon-synchronization"
          style="cursor: pointer; color: rgb(48, 100, 143); margin-right: 15px"
          @click="to_sourceNodeSync()"
        ></i>
      </span>
      <el-table
        :data="reuse_map"
        class="outstanding_dialog_table_class"
        :show-header="false"
        cell-class-name="outstanding_dialog_table_class_cell"
        @selection-change="handle_select_state_reuse"
      >
        <el-table-column type="selection" width="30"> </el-table-column>
        <el-table-column prop="name" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="outstanding_name">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="color" width="30">
          <template slot-scope="scope">
            <div
              class="outstanding_dialog_color"
              :style="'background-color:' + scope.row.color"
            ></div>
          </template>
        </el-table-column>
        <el-table-column prop="num" width="50"> </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      v-if="is_state_Sprint"
      id="outstanding_detail_dialog"
      v-draggable
      class="outstanding_detail_dialog"
      custom-class="outstanding_detail_dialog_body"
      :visible.sync="is_state_Sprint"
      :outstanding_type="outstanding_type"
      width="260px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="outstanding_name"
    >
      <el-table
        :data="sort_array_null(outstanding_map)"
        class="outstanding_dialog_table_class"
        :show-header="false"
        cell-class-name="outstanding_dialog_table_class_cell"
        @selection-change="handle_select_state_outstanding_sprint"
      >
        <el-table-column type="selection" width="30"> </el-table-column>
        <el-table-column prop="name" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="outstanding_name">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="color" width="30">
          <template slot-scope="scope">
            <div :class="'outstanding_dialog_color ' + scope.row.color"></div>
          </template>
        </el-table-column>
        <el-table-column prop="num" width="50"> </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      v-if="is_state_review"
      v-draggable
      class="outstanding_detail_dialog"
      custom-class="reviewing_detail_dialog_body"
      :visible.sync="is_state_review"
      width="260px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('addNodeDetail.res')"
      :before-close="out_review"
    >
      <el-table
        :data="review_color_map"
        class="outstanding_dialog_table_class"
        :show-header="false"
        cell-class-name="outstanding_dialog_table_class_cell"
        @selection-change="handle_select_review_cover"
      >
        <el-table-column
          v-if="!($route.path.indexOf('lineword') > 0)"
          type="selection"
          width="30"
        >
        </el-table-column>

        <el-table-column prop="name" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="outstanding_name">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="color" width="30">
          <template slot-scope="scope">
            <div
              class="outstanding_dialog_color"
              :style="'background-color:' + scope.row.color"
            ></div>
          </template>
        </el-table-column>
        <el-table-column prop="num" width="50"> </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      :title="$t('file.table.options.dialog.title3')"
      width="25%"
      :visible.sync="worddialogVisible"
      custom-class="excel_export_dialog"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        "
      >
        <div
          style="
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            color: gray;
          "
        >
          {{ $t("btn.export_tem") }}
        </div>
        <el-select
          v-model="template_word"
          :placeholder="$t('placeholder.dropdown')"
          style="width: 80%"
          clearable
        >
          <el-option
            v-for="item in tem_options_word"
            :key="item.templateId"
            :label="item.name"
            :value="item.templateId"
          >
          </el-option>
        </el-select>
      </div>

      <span slot="footer">
        <el-button @click="worddialogVisible = false">{{
          $t("file.table.options.dialog.box5")
        }}</el-button>
        <el-button type="primary" @click="handle_word">{{
          $t("file.table.options.dialog.box6")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="show_check_dialog" width="25%">
      <div slot="title">
        {{ $t("canvas.tip18") }}
        &nbsp;

        <el-tooltip class="item" effect="dark" placement="bottom">
          <div slot="content">
            {{ $t("canvas.tip19") }}
          </div>
          <i class="iconfont icon-zwen" style="font-size: 20px"></i>
        </el-tooltip>
      </div>

      <el-table
        ref="multipleTable"
        :data="coverage_list"
        tooltip-effect="dark"
        style="width: 100%"
        height="250"
        @selection-change="handleSelectionChange_coverage"
      >
        <el-table-column type="selection"> </el-table-column>

        <el-table-column :label="$t('canvas.tip20')">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer">
        <el-button @click="show_check_dialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="get_new_check_coverage_btn">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('baseline.archive.title1')"
      :visible.sync="nodeVersionDialog"
      width="25%"
    >
      <el-form
        ref="nodeVersionform"
        :model="nodeVersionForm"
        label-width="140px"
        label-position="left"
      >
        <el-form-item
          :label="$t('node.version.versionName')"
          prop="version"
          :rules="{
            required: true,
            trigger: 'blur',
            message: $t('placeholder.notNull'),
          }"
        >
          <el-input v-model="nodeVersionForm.version"></el-input>
        </el-form-item>
        <el-form-item :label="$t('baseline.archive.remark')" prop="desc">
          <el-input v-model="nodeVersionForm.desc" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="nodeVersionDialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          :loading="saveVersionBtnLoading"
          @click="saveVersion"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('knowledge.HistoricalVersions')"
      :visible.sync="historyDialog"
      width="60%"
      :before-close="historyDialogClose"
    >
      <el-table :data="historyList" :max-height="500">
        <el-table-column
          prop="title"
          :label="$t('node.version.versionName')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.archiveName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          :label="$t('baseline.archive.remark')"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.remark }}
          </template>
        </el-table-column>
        <el-table-column prop="authorName" :label="$t('baseline.archive.user')">
          <template slot-scope="scope">
            {{ matchUserNickname(scope.row.createdBy) }}
          </template>
        </el-table-column>
        <el-table-column prop="timestamp" :label="$t('baseline.archive.time')">
          <template slot-scope="scope">
            <span>{{
              new Date(scope.row.createdAt).Format("yyyy-MM-dd hh:mm:ss")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="timestamp"
          :label="$t('file.table.options.download')"
        >
          <template slot-scope="scope">
            <a :href="scope.row.excelUrl" style="margin-right: 10px">Excel</a>
            <a :href="scope.row.wordUrl">Word</a>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
import Driver from "@/plugins/driver.js";
import config from "@/../config/index";
import { get_templates } from "@/network/template/index.js";
import "@/plugins/driver.css";
import vmson from "@/utils/vmson";
import HomeTopbar from "@/components/homeTopbar";
import Fta from "@/views/fta";
import Tara from "@/views/tara";
import Hara from "@/views/hara";
import exportPng from "@/components/exportPng";
import { Message } from "element-ui";
import {
  refileName,
  getFile,
  removeFile,
  refileCopy,
  fileOrigin,
  nodeOrigins,
} from "@/network/home/index.js";
import { get_file_review, get_review_config } from "@/network/review/index.js";
import {
  get_fileType_coverage,
  get_file_coverage,
} from "@/network/coverage/index.js";
import {
  import_excel,
  import_others,
  download_model_excel,
} from "@/network/fileOperation/index.js";
import { removeToken } from "@/utils/auth";
import CollaborationDialog from "@/components/collaborationDialog";
import "@/assets/css/jmnodeStyle.css";
import {
  getSystemFields,
  all_fields_get,
  get_filetype_List,
} from "@/network/fileType/index.js";
import { effective } from "@/network/node/index.js";
import { mapGetters } from "vuex";
import { get_test_nodes } from "@/network/test/index.js";
import {
  addIntoCollection,
  deleteCollection,
  getUserCollections,
} from "@/network/collections/index.js";
import {
  get_excel_field,
  post_excel_fieldSelect,
  exportWordFile,
  exportCsvFile,
} from "@/network/exportExcel/index.js";
import { myCreations, getFileTemplateTagList } from "@/network/templateLibrary";
import { buy_tara } from "@/network/tara/index.js";
import { buy_hara } from "@/network/hara/index.js";
import { downloadLink } from "@/utils/download.js";
import {
  get_fileShareSetting,
  shrae_file,
  get_status,
  change_file_share,
} from "@/network/share";

import { fileArchive, getFileArchive } from "@/network/nodeVersion";
import { ossAuthorization } from "@/utils/oss";

import { limits_of_authority_disable } from "@/utils/limits";

import { pinyin } from "pinyin-pro";
export default {
  name: "Topbar",
  comments: { HomeTopbar },
  components: { CollaborationDialog, Fta, exportPng, Tara, Hara },
  props: {
    lineWord: {
      type: Boolean,
      default: false,
    },
    modelView: {
      type: Boolean,
      default: false,
    },
    treeView: {
      type: Boolean,
      default: false,
    },
    ignoreList: {
      type: Array,
      default() {
        return [];
      },
    },
    fileData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      view_type: "",
      internetLink: "",
      internetLinkVisible: false,
      is_file_can_share: false,
      outstanding_map: [],
      reuse_map: [],
      noreused: [],
      outstanding_name: "",
      outstanding_type: "",
      outstanding_detail_dialog_show: false,
      show_export_png_dialog: false,
      operatingSystem: "",
      contactNumberDialog: false,
      show_check_dialog: false,
      coverage_list_Selection: [],
      coverage_check_data: [],
      show_fta_dialog: false,
      show_tara_dialog: false,
      show_hara_dialog: false,
      review_color_map: {},
      is_state_review: false,
      show_top: true,
      is_tip: false,
      isCollected: false,
      sprint_color: {},
      is_state_Sprint: false,
      selectkey: 1,
      show_copy_zdh_key_file_node: false,
      getId: "",
      global_variable_select_testplan_nodes: [],
      test1btn: true,
      colormap: {},
      is_state_otherFile: false,
      is_state_response: false,
      test_options: [],
      select_testplan: "",
      coverge_hasNum: 0,
      coverge_noNum: 0,
      coverge_ignore: 0,
      template: "",
      worddialogVisible: false,
      word_row: "",
      template_word: "",
      tem_options: [],
      tem_options_word: [],
      fileId: "",
      unAppoint_num: 0,
      file_test: false,
      file_test2: false,
      file_test3: false,
      file_icon: "",
      file_icon_name: "",
      fileTypeName: "",
      // 状态突出 name 是否显示tooltip
      isShowTooltip: false,
      tooltiptest: true,
      //文件名超出显示tooptip
      tooltiop_show: false,
      /**缩小倍数 */
      itemsmall: 0,
      /**放大倍数 */
      itembig: 0,
      item: 0,
      // 是否状态突出
      is_state_outstanding: false,
      // 是否查看必填项
      is_check_required: false,
      // 是否覆盖度检测
      is_check_coverage: false,
      // 是否复用情况检车
      is_check_reuse: false,
      // 当前检测的文件类型Id
      relationFileTypeId: "",
      /**是否开启全屏 */
      isFull: true,
      isViewer: false,
      isOwner: false,
      isReviewer: false,
      isProcessReviewer: false,
      modeString: this.$t("canvas.mode.edit"),
      loadReview: false,
      input_name: "",
      flag: true,
      fvalue: true,
      settingVisible: false,
      otherData_fieldId: "",
      // 查看评审结果开关
      reviewable: false,
      // 思维导图名称
      name: "Mindmap",
      activeIndex: "1",
      collaborators: [],
      haveUnread: false,
      notEditable: false,
      isSending: false,
      fileTypeId: "",
      ruleForm: {
        collabInvitation: "",
        collabChoice: "",
      },
      collabDialogVisible: false,
      excelDialogVisible: false,
      is_show_origin_file: "",
      origin_file: "",
      origin_pid: "",
      productlist: [],
      isShow: true,
      mind: {},
      node_map: {},
      options: {
        container: "jsmind_container",
        editable: true,
        theme: "orange", // [可选] 主题
      },
      rules: {
        collabInvitation: [
          {
            required: true,
            message: this.$t("canvas.messages.emailError"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("canvas.messages.emailError2"),
            trigger: ["blur", "change"],
          },
        ],
        collabChoice: [
          {
            required: true,
            message: this.$t("canvas.messages.authorizationInfo"),
            trigger: "blur",
          },
        ],
      },
      //mac系统快捷键
      mac_keys: {
        showStatus_shortcutKey: "Option+S",
        detectRequired_shortcutKey: "Option+M",
        Sprint_shortcutKey: "Option+P",
        responsibility_shortcutKey: "Option+R",
        wordView_shortcutKey: "Option+W",
        map_shortcutKey: "Option+Q",
        model_shortcutKey: "Option+E",
        tree_shortcutKey: "Option+T",
      },
      //windows系统快捷键
      windows_keys: {
        showStatus_shortcutKey: "Ctrl+Alt+S",
        detectRequired_shortcutKey: "Alt+M",
        Sprint_shortcutKey: "Alt+P",
        responsibility_shortcutKey: "Alt+R",
        wordView_shortcutKey: "Alt+W",
        map_shortcutKey: "Alt+Q",
        model_shortcutKey: "Alt+O",
        tree_shortcutKey: "Alt+T",
      },
      // 覆盖度列表
      coverage_list: [],
      // 其他字段
      otherFile_list: [],
      value_arr: [],
      color_list: {
        c1: "#66ccff",
        c2: "#E87A90",
        c3: "#86C166",
        c4: "#FF4D4F",
        c5: "#4B7BA6",
        c6: "#FF7A45",
        c7: "#2651A6",
        c8: "#4FB076",
        c9: "#D75455",
        c10: "#2121A6",
        c11: "#4968A6",
        c12: "#FF4D4F",
        c13: "#597EF7",
        c14: "#F759AB",
        c15: "#73D13D",
        c16: "#6B7FF2",
      },
      must_check: true,
      dialogVisible: false,
      all_fields_item: "",
      checkList: [],
      all_key_map: {},
      submit_list: [],
      excel_dialog_fields: {},
      systemFields_list: [],
      allFields_list: [],
      allkeyList: [],
      btn_isShow: false, //调控按钮是否显示
      // 节点文字对齐方式 left或者center
      nodeTextIndent: "left",
      // 另存为模板
      tempCreatDialogVisible: false,
      tempCreatName: "",
      templateLoading: false,
      export_png_loading: "",
      description: "",
      default_word_or_map: "myMind",
      customTagSelect: [],
      customTagList: [],
      // 用户可见

      customTagAuthority: [],
      user_loading: false,
      groupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      filterGroupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      // 文件存档相关
      nodeVersionDialog: false,
      saveVersionBtnLoading: false,
      nodeVersionForm: {
        version: "",
        desc: "",
      },
      // 历史存档
      historyDialog: false,
      historyList: [],
      isCsv: false,
    };
  },
  computed: {
    ...mapGetters(["status", "user_list", "fileType"]),
    loadingReview: {
      get: function () {
        if (
          this.$store.getters.loading_review &&
          this.$store.getters.review_res_flag
        )
          return true;
        else return false;
      },
      set: function (val) {
        //return val;
      },
    },
    judgeMode: function () {
      if (this.isReviewer) return this.$t("canvas.mode.review");
      else if (this.isViewer) return this.$t("canvas.mode.read");
      else return this.$t("canvas.mode.edit");
    },
    // 当前页面的节点信息
    exist_nodes: function () {
      return this.$store.getters.exist_mindmap.data;
    },
    // 去除已被删除节点的页面节点信息
    exist_nodes_not_deleted: function () {
      let id_list = this.exist_nodes
        ? this.exist_nodes.map((item) => {
            return item.id;
          })
        : [];
      let ans = [];
      this.exist_nodes
        ? this.exist_nodes.forEach((item) => {
            if (id_list.includes(item.parentid) || item.id == "root") {
              ans.push(item);
            }
          })
        : "";
      return ans;
    },
    // 状态信息
    state_info: function () {
      const TODO_colors = ["E87A90", "F759AB", "D75455", "FF4D4F", "FF7A45"];
      let todo_index = 0;
      const Inprograss_colors = [
        "2651A6",
        "4968A6",
        "4B7BA6",
        "6B7FF2",
        "597EF7",
      ];
      let inprograss_index = 0;
      const Done_colors = ["19BF5B", "55AA55", "0CAD0C", "23B85E", "2D882D"];
      let done_index = 0;

      const result = new Map();
      let status_name = this.status;
      this.exist_nodes_not_deleted.forEach((item) => {
        if (result.has(item.status)) {
          result.set(item.status, {
            name: status_name[item.status] ? status_name[item.status].name : "",
            num: result.get(item.status).num + 1,
            color: result.get(item.status).color,
          });
        } else {
          let status_color = null;
          switch (
            this.status[item.status]
              ? this.status[item.status].statusCategory
              : ""
          ) {
            case "TODO":
              status_color = TODO_colors[todo_index];
              todo_index++;
              if (todo_index >= TODO_colors.length) todo_index = 0;
              break;
            case "IN_PROGRESS":
              status_color = Inprograss_colors[inprograss_index];
              inprograss_index++;
              if (inprograss_index >= Inprograss_colors.length)
                inprograss_index = 0;
              break;
            case "DONE":
              status_color = Done_colors[done_index];
              done_index++;
              if (done_index >= Done_colors.length) done_index = 0;
              break;
            default:
              break;
          }
          result.set(item.status, {
            name: status_name[item.status] ? status_name[item.status].name : "",
            num: 1,
            color: status_color,
          });
        }
      });
      return result;
    },
    // 当前文件类型ID
    file_type_id: function () {
      return this.$store.getters.file_type_id;
    },
    // 当前文件key
    file_key: function () {
      return this.$store.getters.file_key;
    },
  },
  watch: {
    exist_nodes_not_deleted: {
      handler: function () {
        if (this.is_state_outstanding) {
          this.node_state_outstanding();
        }
        if (this.is_check_required) {
          this.required_check();
        }
        if (this.is_check_coverage) {
          this.coverage_check();
        }
        if (this.is_state_otherFile) {
          this.handle_otherFile_click();
        }
        if (this.is_check_reuse) {
          this.handle_reuse_situation_click();
        }
      },
      deep: true,
    },
    is_state_Sprint: function () {
      if (this.is_state_Sprint) {
        this.node_sprint_outstanding();
      } else {
        if (!this.is_state_Sprint) {
          this.node_state_reset();
        }
      }
    },
    is_state_outstanding: function () {
      if (this.is_state_outstanding) {
        this.node_state_outstanding();
      } else {
        if (!this.is_state_outstanding) {
          this.node_state_reset();
        }
      }
    },
    is_check_reuse: function () {
      if (this.is_check_reuse) {
        this.node_state_outstanding();
      } else {
        if (!this.is_check_reuse) {
          this.node_state_reset();
        }
      }
    },
    is_state_response: function () {
      if (this.is_state_response) {
        this.responseman();
      } else {
        if (!this.is_state_response && !this.is_state_outstanding) {
          this.node_state_reset();
        }
      }
    },
    is_state_otherFile: function () {
      if (this.is_state_otherFile) {
        this.handle_otherFile_click();
      } else {
        if (!this.is_state_otherFile && !this.is_state_outstanding) {
          this.node_state_reset();
        }
      }
    },
    is_check_coverage: function () {
      if (this.is_check_coverage) {
        this.coverage_check();
      } else {
        if (
          !this.is_check_coverage &&
          !this.is_state_Sprint &&
          !this.is_state_outstanding &&
          !this.is_state_response &&
          !this.is_state_otherFile
        ) {
          this.node_state_reset();
        }
      }
    },
    select_testplan() {
      if (this.select_testplan) {
        this.test_plan_outstanding();
        this.$message({
          message: this.$t("canvas.testPlan.info"),
          type: "success",
          duration: "5000",
        });
      } else {
        this.global_variable_select_testplan_nodes = [];
        let all_file_nodes = this.$store.getters.exist_mindmap.data;
        all_file_nodes.forEach((node) => {
          const target_node = document.querySelector(`.nodeid-${node.id}`);
          target_node.style.border = "none";
        });
      }
    },
    ignoreList(newVal) {
      this.ignoreList = newVal;
      if (this.is_check_coverage) {
        this.coverage_check();
      }
    },
    fileData(newVal) {
      if (newVal.data.length) {
        this.allkeyList = [];
        this.fileData.data.forEach((item) => {
          this.allkeyList.push(item.key);
        });
        this.$store.commit("SET_EXIST_MINDMAP", this.fileData); //文件内容
        this.$store.commit("SET_FILE_ID", this.fileData.data[0].fileId);
        this.fileId = this.fileData.data[0].fileId;
        this.$store.commit("SET_FILE_KEY", this.$route.params.file_key);
        this.$store.commit("SET_MINDMAP_NAME", this.fileData.meta.name);
        this.name = this.fileData.meta.name;
        this.fileTypeId = this.fileData.fileTypeId;
        this.$store.commit("SET_FILETYPE_ID", this.fileData.fileTypeId);
        // 获得当前文件类型的覆盖度
        if (this.fileType) {
          for (let i of Object.values(this.fileType)) {
            if (i["fileTypeId"] == this.$store.getters.file_type_id) {
              this.file_icon = i.icon;
              this.file_icon_name = i.name;
              this.file_test = i.testCase;
              if (this.file_test) {
                this.get_test();
              }
              this.file_test2 = i.testCase;
              if (this.$route.path.indexOf("lineword") > -1) {
                this.file_test2 = false;
              }
              if (this.$route.path.indexOf("myMind") > -1) {
                this.file_test3 = false;
              } else {
                this.file_test3 = true;
              }
            }
          }
        }

        // 获取其他字段数据
        let data = new Map();
        this.exist_nodes_not_deleted.forEach((item1) => {
          item1.content.forEach((item) => {
            if (
              item.fieldType == "SINGLE_USER_SELECT" ||
              item.fieldType == "SELECT"
            ) {
              if (data.has(item.fieldId)) {
                if (item.value == null) {
                  let arr = [...data.get(item.fieldId).noId];
                  arr.push(item1.id);
                  data.set(item.fieldId, {
                    name: item.name,
                    noNum: data.get(item.fieldId).noNum + 1,
                    noId: arr,
                    hasNum: data.get(item.fieldId).hasNum,
                    hasId: data.get(item.fieldId).hasId,
                  });
                } else {
                  let arr = [...data.get(item.fieldId).hasId];
                  arr.push(item1.id);
                  data.set(item.fieldId, {
                    name: item.name,
                    noNum: data.get(item.fieldId).noNum,
                    noId: data.get(item.fieldId).noId,
                    hasNum: data.get(item.fieldId).hasNum + 1,
                    hasId: arr,
                  });
                }
              } else {
                if (item.value == null) {
                  data.set(item.fieldId, {
                    name: item.name,
                    noNum: 1,
                    noId: [item1.id],
                    hasNum: 0,
                    hasId: [],
                  });
                } else {
                  data.set(item.fieldId, {
                    name: item.name,
                    noNum: 0,
                    noId: [],
                    hasNum: 1,
                    hasId: [item1.id],
                  });
                }
              }
            }
          });
        });
        this.otherFile_list = data;
        this.$nextTick(() => {
          setTimeout(() => {
            if (Object.keys(this.$route.query).indexOf("in_review") > -1) {
              this.in_review();
            }
          }, 2000);
        });
      }
    },
  },
  created() {
    if (this.$route.path.indexOf("myMind") > -1) {
      this.view_type = "myMind";
    }
    if (this.$route.path.indexOf("lineword") > -1) {
      this.view_type = "lineword";
    }
    if (this.$route.path.indexOf("model") > -1) {
      this.view_type = "model";
    }
    if (this.$route.path.indexOf("tree") > -1) {
      this.view_type = "tree";
    }

    this.default_word_or_map = localStorage.getItem("default_word_or_map")
      ? localStorage.getItem("default_word_or_map")
      : "myMind";
    get_fileShareSetting().then((re) => {
      this.is_file_can_share = re;
    });
    const self = this;
    window.addEventListener("message", (e) => {
      if (e.data) {
        if (e.data == self.$t("addJs.close")) {
          sessionStorage.setItem("istip", "999");
          localStorage.setItem("istip", "999");
          sessionStorage.setItem("wordviewtip", "true");
          localStorage.setItem("wordviewtip", "true");
        }
      }
    });
    //收藏相关
    const collectionKeyList = [];
    getUserCollections("file").then((res) => {
      if (res.data) {
        res.data.forEach((collection) => {
          collectionKeyList.push(collection.key);
        });
        if (collectionKeyList.indexOf(this.file_key) !== -1) {
          this.isCollected = true;
        } else {
          this.isCollected = false;
        }
      } else {
        this.isCollected = false;
      }
    });

    this.name = this.$store.getters.mindmap_name;
    const permission = this.$store.getters.permission;
    if (permission === "owner") {
      this.isOwner = true;
      this.notEditable = false;
    } else if (permission === "edit") {
      this.notEditable = false;
    } else if (permission === "review") {
      this.isReviewer = true;
    } else if (permission === "view") {
      this.isViewer = true;
    }
    if (this.fileData.data && this.fileData.data.length) {
      this.allkeyList = [];
      this.fileData.data.forEach((item) => {
        this.allkeyList.push(item.key);
      });
      this.$store.commit("SET_EXIST_MINDMAP", this.fileData); //文件内容
      this.$store.commit("SET_FILE_ID", this.fileData.data[0].fileId);
      this.fileId = this.fileData.data[0].fileId;
      this.$store.commit("SET_FILE_KEY", this.$route.params.file_key);
      this.$store.commit("SET_MINDMAP_NAME", this.fileData.meta.name);
      this.name = this.fileData.meta.name;
      this.fileTypeId = this.fileData.fileTypeId;
      this.$store.commit("SET_FILETYPE_ID", this.fileData.fileTypeId);
      // 获得当前文件类型的覆盖度
      if (this.fileType) {
        for (let i of Object.values(this.fileType)) {
          if (i["fileTypeId"] == this.$store.getters.file_type_id) {
            this.file_icon = i.icon;
            this.file_icon_name = i.name;
            this.file_test = i.testCase;
            if (this.file_test) {
              this.get_test();
            }
            this.file_test2 = i.testCase;
            if (this.$route.path.indexOf("lineword") > -1) {
              this.file_test2 = false;
            }
            if (this.$route.path.indexOf("myMind") > -1) {
              this.file_test3 = false;
            } else {
              this.file_test3 = true;
            }
          }
        }
      }

      // 获取其他字段数据
      let data = new Map();
      this.exist_nodes_not_deleted.forEach((item1) => {
        item1.content.forEach((item) => {
          if (
            item.fieldType == "SINGLE_USER_SELECT" ||
            item.fieldType == "SELECT"
          ) {
            if (data.has(item.fieldId)) {
              if (item.value == null) {
                let arr = [...data.get(item.fieldId).noId];
                arr.push(item1.id);
                data.set(item.fieldId, {
                  name: item.name,
                  noNum: data.get(item.fieldId).noNum + 1,
                  noId: arr,
                  hasNum: data.get(item.fieldId).hasNum,
                  hasId: data.get(item.fieldId).hasId,
                });
              } else {
                let arr = [...data.get(item.fieldId).hasId];
                arr.push(item1.id);
                data.set(item.fieldId, {
                  name: item.name,
                  noNum: data.get(item.fieldId).noNum,
                  noId: data.get(item.fieldId).noId,
                  hasNum: data.get(item.fieldId).hasNum + 1,
                  hasId: arr,
                });
              }
            } else {
              if (item.value == null) {
                data.set(item.fieldId, {
                  name: item.name,
                  noNum: 1,
                  noId: [item1.id],
                  hasNum: 0,
                  hasId: [],
                });
              } else {
                data.set(item.fieldId, {
                  name: item.name,
                  noNum: 0,
                  noId: [],
                  hasNum: 1,
                  hasId: [item1.id],
                });
              }
            }
          }
        });
      });
      this.otherFile_list = data;
      this.$nextTick(() => {
        setTimeout(() => {
          if (Object.keys(this.$route.query).indexOf("in_review") > -1) {
            this.in_review();
          }
        }, 2000);
      });
    }
    get_fileType_coverage(this.get_pid(), this.file_type_id).then((res) => {
      this.coverage_list = res;
    });
  },
  mounted() {
    get_templates().then((res) => {
      this.tem_options = [
        ...res.filter((obj) => obj.exportTemplateType === "EXCEL"),
      ];

      this.tem_options_word = [
        ...res.filter((obj) => obj.exportTemplateType != "EXCEL"),
      ];
      this.template_word = "";
      this.template = "";
    });
    this.getGroupList();
    if (this.$route.query.tara) {
      this.open_tara_dialog();
    }
    if (this.$route.query.hara) {
      this.open_hara_dialog();
    }
    if (/macintosh|mac os x/i.test(navigator.userAgent)) {
      this.operatingSystem = "Mac";
    } else {
      this.operatingSystem = "Windows";
    }
    window.addEventListener("keydown", this.handleKeyDown);
    this.getFileOrigin();
    if (Object.keys(this.$route.query).indexOf("process_review") > -1) {
      if (this.$route.path.indexOf("myMind") > -1) {
        this.isProcessReviewer = true;
        this.isReviewer = true;
      } else {
        this.$nextTick(() => {
          setTimeout(() => {
            this.in_review();
            // this.$nextTick(() => {
            //   setTimeout(() => {
            //     this.process_review();
            //   }, 1000);
            // });
          }, 2000);
        });
      }
    }
    if (this.$route.query.showFileVersion == "true") {
      this.getArchiveFile();
    }
    const steps = [];
    let is_mymind = false;
    if (this.$route.path.indexOf("myMind") > -1) {
      is_mymind = true;
    }
    if (!(sessionStorage.getItem("istip") || localStorage.getItem("istip"))) {
      steps.push({
        element: "#dddd8",
        popover: {
          title: this.$t("canvas.fileDropDown.deleteForm.title"),
          description: is_mymind
            ? this.$t("canvas.tip")
            : this.$t("canvas.tip9"),
          position: "bottom",
          className: "istip",
          closeBtnText: this.$t("btn.jumpBtn"),
          foreverBtnText: this.$t("btn.noLongerDisplayed"),
          nextBtnText: this.$t("btn.next"), // Next button text for this step 下一步按钮的文字
          prevBtnText: this.$t("btn.prev"), // Previous button text for this step 上一步按钮文字
        },
      });
    }
    // 用户引导提示
    if (
      !(
        sessionStorage.getItem("wordviewtip") ||
        localStorage.getItem("wordviewtip")
      ) &&
      !this.lineWord
    ) {
      steps.push({
        element: "#topbar-view",
        popover: {
          title: this.$t("canvas.fileDropDown.deleteForm.title"),
          description: this.$t("canvas.word"),
          position: "bottom-left",
          className: "istip",
          showButtons: true,
          closeBtnText: this.$t("btn.jumpBtn"),
          nextBtnText: this.$t("btn.next"), // Next button text for this step 下一步按钮的文字
          prevBtnText: this.$t("btn.prev"), // complete button text for this step 上一步按钮文字
        },
      });
    }
    setTimeout(() => {
      let driver = null;
      driver = new Driver();
      steps.length ? driver.defineSteps(steps) : "";
      steps.length ? driver.start() : "";
    }, 1000);
    vmson.$on("fta_illegal", (val) => {
      this.fta_illegal = val;
    });
    vmson.$on("close_tara_dialog", () => {
      this.show_tara_dialog = false;
    });
    vmson.$on("close_hara_dialog", () => {
      this.show_hara_dialog = false;
    });
    vmson.$on("jmshow", (val) => {
      if (this.is_state_outstanding) {
        this.node_state_outstanding(val);
      }
      if (this.is_state_response) {
        this.responseman(val);
      }
      if (this.is_state_Sprint) {
        this.node_sprint_outstanding(val);
      }
      if (this.select_testplan) {
        this.test_plan_outstanding();
      }
      if (this.is_state_review) {
        this.in_review();
      }
      if (this.is_state_otherFile) {
        this.handle_otherFile_click();
      }
      if (this.isProcessReviewer) {
        if (this.$route.path.indexOf("myMind") > -1) {
          this.clear_review_circle();
          vmson.$emit("processReview");
        }
      }
    });
    vmson.$on("operation", (val) => {
      let opti = Object.values(JSON.parse(sessionStorage.getItem("_testPlan")));
      let has_man = [];
      let no_man = [];
      for (let i of opti) {
        if (i.principal) {
          has_man.push(i);
        } else {
          no_man.push(i);
        }
      }
      this.test_options = [
        ...(has_man || []),
        {
          principal: null,
          name_display: this.$t("canvas.testPlan.warning"),
          testPlanId: 111,
          status: 55,
        },
        ...(no_man || []),
      ];
      this.test_options.forEach((item) => {
        if (item.status == "NOT_STARTED") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.notStarted") +
            ")";
        } else if (item.status == "IN_PROGRESS") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.inProgress") +
            ")";
        } else if (item.status == "COMPLETE") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.completed") +
            ")";
        }
      });
      this.selectkey += 1;
    });

    vmson.$on("select_plan", (val) => {
      this.select_testplan = "";
    });

    //是否开启浮窗
    vmson.$on("get_fvalue", (val) => {
      this.fvalue = val;
    });
    // 监听节点关联关系是否发生变化
    vmson.$on("node_relation_change", () => {
      if (this.is_check_coverage) {
        this.coverage_check();
      }
      if (this.is_state_otherFile) {
        getFile(this.get_pid(), this.$route.params.file_key).then((res) => {
          this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
        });
      }
    });
    // 监听fta购买发生变化
    vmson.$on("fta_buy_change", () => {
      this.show_fta_dialog = false;
      this.$message({
        duration: 0,
        showClose: true,
        dangerouslyUseHTMLString: true,
        message: `<span>${this.$t("certificate.Please")}<a href="${
          window.location.origin
        }/profile/certificate_management?target=FTA" target="_blank" style="color: blue">${this.$t(
          "certificate.CertificateManagement"
        )}</a>${this.$t("certificate.InstallComponents")}</span>`,
      });
    });
    // 监听导出png成功
    vmson.$on("export_png_success", () => {
      this.show_export_png_dialog = false;
      this.export_png_loading.close();
    });
    if (this.$route.path.indexOf("myMind") > -1) {
      setTimeout(() => {
        this.$emit("changeNodeTextIndent", "left");
      }, 500);
    }
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    // 获取文件存档
    getArchiveFile() {
      const params = {
        projectId: this.get_pid(),
        fileKey: this.$route.params.file_key,
      };
      getFileArchive(params).then((res) => {
        this.historyDialog = true;
        this.historyList = res;
        // 获取当前URL
        var url = window.location.href;
        // 修改query参数
        var newQuery = "showFileVersion=true";
        // 拼接新的URL
        var newUrl = url.split("?")[0] + "?" + newQuery;
        // 修改地址栏URL
        history.pushState(null, null, newUrl);
      });
    },
    historyDialogClose() {
      // 获取当前URL
      var url = window.location.href;
      // 使用URL API解析URL
      const urlObj = new URL(url);
      // 删除指定的查询参数
      urlObj.searchParams.delete("showFileVersion");
      // 修改地址栏URL
      history.pushState(null, null, urlObj);
      this.historyDialog = false;
    },
    // 文件存档
    archiveFile() {
      this.nodeVersionDialog = true;
      this.nodeVersionForm = {
        version: "",
        desc: "",
      };
    },
    saveVersion() {
      this.$refs["nodeVersionform"].validate(async (valid) => {
        if (valid) {
          this.saveVersionBtnLoading = true;
          const params = {
            projectId: this.get_pid(),
            fileKey: this.$route.params.file_key,
            data: {
              archiveName: this.nodeVersionForm.version,
              remark: this.nodeVersionForm.desc,
            },
          };
          fileArchive(params)
            .then((res) => {
              this.saveVersionBtnLoading = false;
              this.nodeVersionDialog = false;
              this.$message({
                type: "success",
                message: this.$t("homeTopBar.asyncTask.message"),
              });
            })
            .catch((err) => {
              this.saveVersionBtnLoading = false;
            });
          // this.getImgUrl();
        } else {
          return false;
        }
      });
    },
    get_new_check_coverage_btn() {
      // let l=''
      let l = [];
      if (this.coverage_list_Selection.length < 1) {
        this.$message({
          type: "warning",
          message: this.$t("canvas.tip18"),
        });
        return;
      }
      this.show_check_dialog = false;

      this.coverage_list_Selection.forEach((ek) => {
        l += "relationFileTypeIds=" + ek.fileTypeId + "&";
      });
      l = l.slice(0, l.length - 1);

      this.outstanding_type = "coverage@" + " ";
      if (this.$route.name == "model") {
        vmson.$emit("model_view_outstanding", "coverage@" + l + "@" + " ");
        return;
      }
      if (this.$route.name == "lineword") {
        vmson.$emit(
          "handle_lineword_outstanding_click",
          "coverage@" + l + "@" + " "
        );
        return;
      }
      this.fileTypeName = " ";

      this.relationFileTypeId = l;

      this.is_state_outstanding = false;
      this.is_check_required = false;
      this.is_state_Sprint = false;
      this.is_state_response = false;
      this.is_state_otherFile = false;
      this.is_check_reuse = false;

      this.coverage_check_data = l;
      this.relationFileTypeId = "all";
      this.is_check_coverage = true;
    },
    handleSelectionChange_coverage(a, b) {
      this.coverage_list_Selection = a;
    },
    handle_check_coverage_click() {
      this.is_state_outstanding = false;
      this.is_check_required = false;
      this.is_state_Sprint = false;
      this.is_state_response = false;
      this.is_state_otherFile = false;
      this.is_check_reuse = false;
      this.is_check_coverage = false;

      this.show_check_dialog = true;
    },
    copy_zdh_key_open() {
      this.show_copy_zdh_key_file_node = true;
    },
    copy_zdh_key_close() {
      this.show_copy_zdh_key_file_node = false;
    },
    copy_zdh_key_file_node(key) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(key);
      } else {
        const textarea = document.createElement("textarea");
        textarea.value = key;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      }

      this.$message({
        type: "success",
        message: this.$t("AI.tip14"),
      });
    },
    getGroupList() {
      const group = Object.values(this.$store.getters.user_group_list);
      const user = Object.values(this.$store.getters.user_list);
      const filteredArray = user.filter((obj) => obj.deleted == 0);
      filteredArray.map((item, index) => {
        this.groupandUser[0].options.push({
          value: item.accountId,
          label: item.nickname,
        });
        this.filterGroupandUser[0].options.push({
          value: item.accountId,
          label: item.nickname,
        });
      });
      group.map((item, index) => {
        this.groupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
        this.filterGroupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
      });
    },
    groupandUserfilterMethod(val) {
      if (!val) {
        this.filterGroupandUser = JSON.parse(JSON.stringify(this.groupandUser));
      } else {
        this.$set(
          this.filterGroupandUser[0],
          "options",
          this.getPinyinMatch(val, this.groupandUser[0].options, "label")
        );
        this.$set(
          this.filterGroupandUser[1],
          "options",
          this.getPinyinMatch(val, this.groupandUser[1].options, "label")
        );
      }
    },
    // 过滤方法
    getPinyinMatch(inputValue, list, label) {
      /* 输入内容拼音转换 */
      // 完整拼音
      const pyInput = pinyin(inputValue, {
        toneType: "none",
        type: "array",
      }).join("");
      // 拼音首字母
      const headerPyInput = pinyin(inputValue, {
        pattern: "first",
        type: "array",
      }).join("");
      return list.filter((e) => {
        const pyE = pinyin(e[label ? label : "nickname"], {
          toneType: "none",
          type: "array",
        })
          .join("")
          .slice(0, pyInput.length);
        // 列表中每项的拼音首字母
        const headerPyE = pinyin(e[label ? label : "nickname"], {
          pattern: "first",
          type: "array",
        })
          .join("")
          .slice(0, headerPyInput.length);
        // 过滤匹配
        return (
          e[label ? label : "nickname"].includes(inputValue) || pyE === pyInput
          // 是否提取首字母匹配
          // ||
          // headerPyE === headerPyInput
        );
      });
    },
    save_model_view() {
      vmson.$emit("save_model_view", "");
    },
    map_word_model(type) {
      let d = {
        lineword: {
          word: false,
          map: true,
          model: true,
          tree: true,
        },
        model: {
          tree: true,
          word: true,
          map: true,
          model: false,
        },
        myMind: {
          tree: true,
          word: true,
          map: false,
          model: true,
        },
        tree: {
          tree: false,
          word: true,
          map: true,
          model: true,
        },
      };
      return d[this.$route.name][type];
    },
    to_sourceNodeSync() {
      this.$emit("asyncNodes", this.noreused);
    },
    defult_view(val) {
      this.$message({
        type: "success",
        message: this.$t("model_view.tip5"),
      });
      this.default_word_or_map = val;
      localStorage.setItem("default_word_or_map", val);
      this.$router.push({
        path: `/${this.get_pid()}/${val}/${this.$route.params.file_key}`,
      });
    },
    default_map() {
      this.default_word_or_map == "lineword";
      localStorage.setItem("default_word_or_map", "lineword");
      this.word_link();
    },
    default_word() {
      this.default_word_or_map == "myMind";
      localStorage.setItem("default_word_or_map", "myMind");
      this.map_link();
    },
    sort_array_null(outstanding_map) {
      let arr = JSON.parse(JSON.stringify(outstanding_map));
      const valueToPutLast = "grey";

      arr.sort(function (a, b) {
        let aIsLast = a.color === valueToPutLast;
        let bIsLast = b.color === valueToPutLast;

        if (aIsLast && bIsLast) {
          return b.num - a.num;
        } else if (aIsLast) {
          return 1;
        } else if (bIsLast) {
          return -1;
        } else {
          return b.num - a.num;
        }
      });
      return arr;
    },
    sort_array(outstanding_map) {
      let arr = JSON.parse(JSON.stringify(outstanding_map));
      arr.sort(function (a, b) {
        return b.num - a.num;
      });
      return arr;
    },
    share_file_now(e) {
      this.buttonBlur(e);

      shrae_file(this.get_pid(), this.$route.params.file_key).then((res) => {
        get_status(res).then((r) => {
          if (!r.effective) {
            change_file_share(
              this.get_pid(),
              this.$route.params.file_key,
              true
            );
          }
        });
        this.internetLinkVisible = true;
        let hua =
          "【MappingSpace】" +
          " " +
          this.name +
          " " +
          config.authentication_url +
          "pubMindmap/" +
          res +
          " " +
          this.$t("share.shareword");

        this.internetLink = hua;
        let textarea = document.createElement("textarea");
        textarea.style.position = "fixed";
        textarea.style.opacity = 0;
        textarea.value = hua;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        this.$message({
          type: "success",
          message: this.$t("knowledge.copied"),
        });
      });
    },
    // 复制外部链接
    copyInternetLink() {
      let text = this.$refs["text_area"];
      text.innerText = this.internetLink;
      text.select();
      document.execCommand("copy");
      this.$message({
        type: "success",
        message: this.$t("knowledge.copied"),
      });
    },
    limits_of_authority_disable,
    // 取消验证手机号弹窗
    cancelDialog(value) {
      this.contactNumberDialog = value;
    },
    handle_select_state_otherfile(e) {
      let select_list = [];

      for (let i of e) {
        for (let j of i.value) {
          select_list.push(j);
        }
      }
      vmson.$emit("select_list_outstanding", select_list);
    },
    handle_select_review_cover(e) {
      let select_list = [];

      for (let i of e) {
        select_list = [...select_list, ...i.value];
      }
      vmson.$emit("select_list_outstanding", select_list);
    },
    handle_select_state_reuse(e) {
      let select_list = [];
      for (let i of e) {
        for (let j of i.value) {
          select_list.push(j.id);
        }
      }
      vmson.$emit("select_list_outstanding", select_list);
    },
    handle_select_state_cover(e) {
      let select_list = [];
      for (let i of e) {
        for (let j of i.value) {
          if (j.id) {
            select_list.push(j.id);
          } else {
            select_list.push(this.all_key_map[j]);
          }
        }
      }
      vmson.$emit("select_list_outstanding", select_list);
    },
    handle_select_state_outstanding_sprint(e) {
      let sprint_map_val = {};
      let sprint_list = Object.values(
        JSON.parse(sessionStorage.getItem("_sprint"))
      );
      sprint_list.forEach((item) => {
        sprint_map_val[item.sprintId] = item.name;
      });
      let list = [];
      let flag = false;
      for (let i of e) {
        list.push(i.value);
        if (i.value == this.$t("canvas.status.st7")) {
          flag = true;
        }
      }

      let select_list = [];
      this.exist_nodes_not_deleted.forEach((node) => {
        if (node.sprintId) {
          if (list.indexOf(sprint_map_val[node.sprintId]) > -1) {
            select_list.push(node.id);
          }
        } else {
          if (flag) {
            select_list.push(node.id);
          }
        }
      });
      vmson.$emit("select_list_outstanding", select_list);
    },
    handle_select_state_outstanding_assignee(e) {
      let list = [];
      let flag = false;
      for (let i of e) {
        list.push(i.value);
        if (i.value == "Undefined") {
          flag = true;
        }
      }

      let select_list = [];

      this.exist_nodes_not_deleted.forEach((node) => {
        if (node.assignee) {
          if (list.indexOf(node.assignee) > -1) {
            select_list.push(node.id);
          }
        } else {
          if (flag) {
            select_list.push(node.id);
          }
        }
      });
      vmson.$emit("select_list_outstanding", select_list);
    },
    handle_select_state_outstanding(e) {
      let status_list = [];
      for (let i of e) {
        status_list.push(i.name);
      }

      let select_list = [];

      this.exist_nodes_not_deleted.forEach((node) => {
        if (status_list.indexOf(this.state_info.get(node.status).name) > -1) {
          select_list.push(node.id);
        }
      });
      vmson.$emit("select_list_outstanding", select_list);
    },
    showShortcutkeys(e) {
      if (this.operatingSystem == "Mac") {
        return this.mac_keys[e];
      } else {
        return this.windows_keys[e];
      }
    },
    open_hara_dialog() {
      buy_hara(this.$store.getters.file_id).then((res) => {
        if (!res.effective) {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=HARA" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        } else {
          this.show_hara_dialog = true;
        }
      });
      // this.show_hara_dialog = true;
    },
    open_search() {
      vmson.$emit("open_map_seaech_dig");
    },
    open_tara_dialog() {
      buy_tara(this.$store.getters.file_id).then((res) => {
        if (!res.effective) {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=TARA" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        } else {
          this.show_tara_dialog = true;
        }
      });
      // this.show_tara_dialog = true;
    },
    open_fta_dialog() {
      effective(this.$store.getters.file_id).then((res) => {
        if (!res.effective) {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=FTA" style="color: blue" target="_blank">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        } else {
          this.show_fta_dialog = true;
        }
      });
    },
    // 另存为新模板
    async tempCreatConfirm() {
      if (!this.tempCreatName) {
        this.$message.warning(this.$t("placeholder.name"));
        return;
      }
      if (!this.description) {
        this.$message.warning(
          this.$t("templateLibrary.PleaseEnterTemplateDescription")
        );
        return;
      }
      this.templateLoading = true;
      vmson.$emit("getjmData", (jm) => {
        const templateContent = {};
        templateContent.meta = jm.meta;
        templateContent.format = jm.format;
        templateContent.data = jm.data.map((item) => {
          return {
            id: item.id,
            index: item.index,
            layer: item.layer,
            topic: item.topic,
            isroot: item.isroot,
            parentid: item.parentid,
            description: item.description,
          };
        });
        vmson.$emit("shootAsDataURL", async (val) => {
          await ossAuthorization.call(this);
          function dataURItoBlob(dataURI) {
            let byteString = atob(dataURI.split(",")[1]);
            let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
            let ab = new ArrayBuffer(byteString.length);
            let ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
          }
          const file = dataURItoBlob(val);
          let myDate = Date.now().toString().slice(2, -1);
          let j = "template" + myDate;
          const res = await this.client.put(
            `${this.$store.state.project.tenantId}/template/${j}`,
            file
          );
          const params = {
            fileTemplateName: this.tempCreatName,
            overviewImage: res.url,
            templateContent: templateContent,
            description: this.description,
            fileTemplateTags: this.customTagSelect,
            allUserGroups: this.customTagAuthority.filter((item) => {
              return item.length > 21;
            }),
            allowUsers: this.customTagAuthority.filter((item) => {
              return item.length < 21;
            }),
          };
          myCreations(params)
            .then((res) => {
              this.$message.success(
                this.$t("templateLibrary.SuccessfullySaved")
              );
              this.templateLoading = false;
              this.tempCreatDialogVisible = false;
            })
            .catch((e) => {
              this.templateLoading = false;
            });
        });
      });
    },
    handle_word() {
      exportWordFile(
        this.get_pid(),
        this.$route.params.file_key,
        this.template_word
      ).then((res) => {
        this.template_word = "";

        this.worddialogVisible = false;
        this.$message({
          showClose: true,
          message: this.$t("homeTopBar.asyncTask.message"),
          type: "success",
          duration: 5000,
        });
      });
    },
    exportWord() {
      this.worddialogVisible = true;
    },

    // 覆盖度ignore
    ignoreCoverage() {
      if (this.lineWord) {
        vmson.$emit("ignoreCoverage", true);
      } else {
        this.$emit("ignoreCoverage", true);
      }
    },
    needCoverage() {
      if (this.lineWord) {
        vmson.$emit("ignoreCoverage", false);
      } else {
        this.$emit("ignoreCoverage", false);
      }
    },
    // 节点补充
    buchong() {
      this.$emit("nodebuchong");
    },
    // 上移节点
    moveUp() {
      if (this.lineWord) {
        vmson.$emit("nodeLocationChange", "up");
      } else {
        this.$emit("nodeLocationChange", "up");
      }
    },
    // 下移节点
    moveDown() {
      if (this.lineWord) {
        vmson.$emit("nodeLocationChange", "down");
      } else {
        this.$emit("nodeLocationChange", "down");
      }
    },
    route_origin() {
      const { href } = this.$router.resolve({
        path: `/${this.origin_pid}/myMind/${this.origin_file}`,
      });
      window.open(href, "_blank");
    },
    // 获取源节点
    getFileOrigin() {
      const params = {
        projectId: this.get_pid(),
        fileKey: this.$route.params.file_key,
      };
      fileOrigin(params).then((res) => {
        this.is_show_origin_file = res.fileKey;
        this.origin_file = res.fileKey;
        this.origin_pid = res.projectId;
      });
    },
    showCollaborationDialog(e) {
      this.buttonBlur(e);
      let _this = this;
      get_review_config(this.get_pid()).then((res) => {
        let fl = false;
        let user = [];
        for (let i of res) {
          i.fileTypeList.forEach((item) => {
            if (item.fileTypeId == _this.fileTypeId) {
              fl = true;
              user = i.reviewerList;
            }
          });
        }
        if (fl) {
          vmson.$emit("showReviewDialog", user);
        } else {
          this.$message.warning(this.$t("canvas.reviewRequestForm.tip6"));
        }
      });
      //
    },
    process_review(e) {
      if (e) {
        this.buttonBlur(e);
      }
      this.is_state_review = false;
      this.isProcessReviewer = true;
      vmson.$emit("processReview");
      this.node_state_reset();
    },
    clear_review_circle() {
      let father = document.querySelectorAll("jmnodes")[0];
      let circles = document.querySelectorAll("circle");
      circles.forEach((circle) => {
        try {
          father.removeChild(circle);
        } catch (error) {
          console.log(error);
        }
      });
    },
    out_review(e) {
      this.is_state_review = false;
      this.btn_isShow = false;
      this.isReviewer = false;
      this.isProcessReviewer = false;
      vmson.$emit("closeReview");
      this.node_state_reset();
      this.clear_review_circle();
      if (e) {
        this.buttonBlur(e);
      }
    },
    in_review(e) {
      this.btn_isShow = true;
      this.isReviewer = true;
      vmson.$emit("closeReview");
      this.isProcessReviewer = false;
      if (e) {
        this.buttonBlur(e);
      }
      this.is_state_outstanding = false;
      this.is_check_required = false;
      this.is_check_coverage = false;
      this.is_state_response = false;
      (this.is_check_reuse = false), (this.relationFileTypeId = "");
      this.is_state_Sprint = false;
      this.is_state_otherFile = false;
      this.node_state_reset();
      this.clear_review_circle();
      this.review_color_map = [];
      let tongguo = [0, "rgba(79, 176, 118, 1)", []];
      let pingshen = [0, "rgba(255, 141, 26, 1)", []];
      let notongguo = [0, "rgba(255, 87, 51, 1)", []];
      let nopingshen = [0, "rgba(48, 100, 143, 1)", []];
      getFile(this.get_pid(), this.$route.params.file_key).then((file_res) => {
        let all_key_map = [];
        for (let i of file_res.data) {
          all_key_map[i.key] = i.id;
        }
        get_file_review(this.get_pid(), this.fileId).then((res) => {
          let nn = 0;
          let one_key_list = [];

          for (let i of res) {
            one_key_list.push(all_key_map[i.nodeKey]);
            if (i.status == "APPROVED") {
              tongguo[0]++;
              tongguo[2].push(all_key_map[i.nodeKey]);
              nn++;
            }
            if (i.status == "REVIEWING") {
              pingshen[0]++;
              pingshen[2].push(all_key_map[i.nodeKey]);
              nn++;
            }
            if (i.status == "UN_APPROVED") {
              notongguo[0]++;
              notongguo[2].push(all_key_map[i.nodeKey]);
              nn++;
            }
          }
          let all_key_list = [];

          for (let i of file_res.data) {
            all_key_list.push(i.id);
          }

          nopingshen[0] = file_res.data.length - nn;
          nopingshen[2] = all_key_list.filter(
            (item) => !one_key_list.includes(item)
          );
          this.review_color_map = [];

          this.review_color_map.push({
            name: this.$t("canvas.viewResult.pass"),
            color: "rgba(79, 176, 118, 1)",
            num: tongguo[0],
            value: tongguo[2],
          });
          this.review_color_map.push({
            name: this.$t("canvas.viewResult.reviewing"),
            color: "rgba(255, 141, 26, 1)",
            num: pingshen[0],
            value: pingshen[2],
          });
          this.review_color_map.push({
            name: this.$t("canvas.viewResult.nopass"),
            color: "rgba(255, 87, 51, 1)",
            num: notongguo[0],
            value: notongguo[2],
          });
          this.review_color_map.push({
            name: this.$t("canvas.viewResult.noreviewing"),
            color: "rgba(48, 100, 143, 1)",
            num: nopingshen[0],
            value: nopingshen[2],
          });

          this.is_state_review = true;
          vmson.$emit("showReviewNode", res);
        });
      });
    },
    buttonBlur(ev) {
      if ((ev.target.nodeName == "SPAN") | (ev.target.nodeName == "I")) {
        ev.target.parentNode.blur();
      } else {
        ev.target.blur();
      }
    },
    sent_message() {
      vmson.$emit("change_topbar", this.show_top);
    },
    exportImg() {
      this.show_export_png_dialog = true;
      this.export_png_loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    creatTemplate() {
      getFileTemplateTagList().then((res) => {
        this.customTagList = res;
        this.tempCreatDialogVisible = true;
        this.tempCreatName = "";
        this.description = "";
        this.customTagSelect = [];
      });
    },
    exportExcel(iscsv) {
      this.isCsv = iscsv;
      Promise.all([
        get_excel_field(this.$route.params.projectId, this.fileTypeId),
        getSystemFields(),
        all_fields_get(this.$route.params.projectId),
      ]).then(
        (res) => {
          // 需展示字段
          this.excel_dialog_fields = res[0] || {};
          // 所有系统字段
          this.systemFields_list = res[1] || [];
          // 所有自定义字段
          this.allFields_list = res[2] || [];

          // 筛选系统字段
          this.systemFields_list.forEach((item) => {
            if (
              this.excel_dialog_fields.systemFieldsIdList.indexOf(
                item.fieldId
              ) != -1
            ) {
              this.checkList.push({ label: item.name, fieldId: item.fieldId });
            }
          });

          // 筛选自定义字段
          this.allFields_list.forEach((item) => {
            if (
              this.excel_dialog_fields.costomFieldsIdList.indexOf(
                item.fieldId
              ) != -1
            ) {
              this.checkList.push({ label: item.name, fieldId: item.fieldId });
            }
          });

          this.dialogVisible = true;
        },
        (error) => {}
      );
    },
    submit_Excel() {
      let filekey = this.$route.params.file_key;
      let proid = this.$route.params.projectId;
      let post_data = {
        systemFieldsIdList: [],
        costomFieldsIdList: [],
        exportTemplateId: this.template,
      };
      this.systemFields_list.forEach((item) => {
        if (this.submit_list.indexOf(item.fieldId) != -1) {
          post_data.systemFieldsIdList.push(item.fieldId);
        }
      });
      this.allFields_list.forEach((item) => {
        if (this.submit_list.indexOf(item.fieldId) != -1) {
          post_data.costomFieldsIdList.push(item.fieldId);
        }
      });
      if (this.isCsv) {
        let params = {
          projectId: this.get_pid(),
          fileKey: this.$route.params.file_key,
          data: {
            systemFieldsIdList: post_data.systemFieldsIdList,
            costomFieldsIdList: post_data.costomFieldsIdList,
            exportTemplateId: "",
          },
        };
        exportCsvFile(params).then((res) => {
          this.dialogVisible = false;
          this.submit_list = [];
          this.template = "";
          this.checkList = [];
          this.all_fields_item = false;
          this.$message({
            showClose: true,
            message: this.$t("homeTopBar.asyncTask.message"),
            type: "success",
            duration: 5000,
          });
        });
      } else {
        post_excel_fieldSelect(proid, filekey, post_data).then((res) => {
          this.dialogVisible = false;
          this.submit_list = [];
          this.template = "";
          this.checkList = [];
          this.all_fields_item = false;
          this.$message({
            showClose: true,
            message: this.$t("homeTopBar.asyncTask.message"),
            type: "success",
            duration: 5000,
          });
        });
      }
    },
    get_test() {
      let opti = Object.values(JSON.parse(sessionStorage.getItem("_testPlan")));
      let has_man = [];
      let no_man = [];
      for (let i of opti) {
        if (i.principal) {
          has_man.push(i);
        } else {
          no_man.push(i);
        }
      }
      this.test_options = [
        ...(has_man || []),
        {
          principal: null,
          name_display: this.$t("canvas.testPlan.warning"),
          testPlanId: 111,
          status: 55,
        },
        ...(no_man || []),
      ];
      this.test_options.forEach((item) => {
        if (item.status == "NOT_STARTED") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.notStarted") +
            ")";
        } else if (item.status == "IN_PROGRESS") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.inProgress") +
            ")";
        } else if (item.status == "COMPLETE") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.completed") +
            ")";
        }
      });
    },
    copySelf() {
      refileCopy(this.get_pid(), this.file_key).then((res) => {
        const { href } = this.$router.resolve({
          path: `/${this.get_pid()}/myMind/${res}`,
        });
        window.open(href, "_blank");
      });
    },
    deleteSelf() {
      this.$confirm(
        this.$t("canvas.fileDropDown.deleteForm.desc"),
        this.$t("canvas.fileDropDown.deleteForm.title"),
        {
          confirmButtonText: this.$t("btn.deleteBtn"),
          confirmButtonClass: "delete_button_class",
          cancelButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "delete_cancel_class",
          type: "warning",
        }
      )
        .then(() => {
          removeFile(this.get_pid(), this.file_key).then((res) => {
            this.$message({
              type: "success",
              message: this.$t("canvas.messages.deleteSuccess"),
            });
            this.$router.push("/home/my_file");
          }); //删除文件
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("canvas.messages.deleteInfo"),
          });
        });
    },
    toggleShoucang() {
      const data = [{ type: "file", value: this.file_key }];
      if (this.isCollected) {
        //取消收藏
        deleteCollection(this.get_pid(), data);
      } else {
        //添加收藏
        addIntoCollection(this.get_pid(), data);
      }
      this.isCollected = !this.isCollected;
    },
    node_sprint_outstanding(val) {
      this.outstanding_type = "sprint";

      this.node_state_reset();
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        jmnode.classList.add(`nodeid-${jmnode.getAttribute("nodeid")}`);
      });
      this.sprint_color = {};
      let nodedata = this.$store.getters.exist_mindmap.data;

      if (val && val != "***") {
        nodedata = val.data;
      }

      let sprint_list = Object.values(
        JSON.parse(sessionStorage.getItem("_sprint"))
      );
      let translate_map = {};
      sprint_list.forEach((sp) => {
        translate_map[sp.sprintId] = sp.name;
      });

      // 未分配
      let Unassigned = this.$t("canvas.status.st7");
      let sprint_map = { [Unassigned]: [] };
      for (let i of nodedata) {
        if (i.sprintId) {
          if (translate_map[i.sprintId] in sprint_map) {
            sprint_map[translate_map[i.sprintId]].push(i);
          } else {
            sprint_map[translate_map[i.sprintId]] = [i];
          }
        } else {
          sprint_map[Unassigned].push(i);
        }
      }
      let color_num = 0;
      for (let i of Object.keys(sprint_map)) {
        if (i == Unassigned && sprint_map[i].length > 0) {
          this.sprint_color[i] = ["grey", sprint_map[i].length];
          for (let j of sprint_map[i]) {
            const target_node = document.querySelector(`.nodeid-${j.id}`);
            this.$nextTick(() => {
              target_node.classList.add("grey");
            });
          }
        } else {
          if (sprint_map[i].length > 0) {
            if (color_num > 15) {
              color_num = 0;
            }
            color_num = color_num + 1;
            this.sprint_color[i] = ["c" + color_num, sprint_map[i].length];
            sprint_map[i].forEach((item) => {
              const target_node = document.querySelector(`.nodeid-${item.id}`);
              (function (num) {
                setTimeout(() => {
                  target_node.classList.add("c" + num);
                }, 0);
              })(color_num);
            });
          }
        }
      }
      this.sprint_color = JSON.parse(JSON.stringify(this.sprint_color));
      this.outstanding_name = "Sprint";

      this.outstanding_map = [];
      Object.keys(this.sprint_color).forEach((item) => {
        this.outstanding_map.push({
          name: item,
          color: this.sprint_color[item][0],
          num: this.sprint_color[item][1],
          value: item,
        });
      });
    },

    get_author(uid) {
      return this.matchUserNickname(uid, this.$t("board.status.unAssigned"));
    },
    get_author_change(uid) {
      return this.matchUserNickname(uid, "");
    },
    responseman(val) {
      this.outstanding_type = "assignee";

      this.node_state_reset();
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        jmnode.classList.add(`nodeid-${jmnode.getAttribute("nodeid")}`);
      });
      this.colormap = {};
      let nodedata = this.$store.getters.exist_mindmap.data;
      if (val && val != "***") {
        nodedata = val.data;
      }
      //构建map，责任人是键，data是值
      let assign_map = { Undefined: [] };
      for (let i of nodedata) {
        if (i.assignee) {
          if (i.assignee in assign_map) {
            assign_map[i.assignee].push(i);
          } else {
            assign_map[i.assignee] = [i];
          }
        } else {
          assign_map["Undefined"].push(i);
        }
      }
      let color_num = 0;
      for (let i of Object.keys(assign_map)) {
        if (i == "Undefined" && assign_map[i].length > 0) {
          this.colormap[i] = ["grey", assign_map[i].length];
          for (let j of assign_map[i]) {
            const target_node = document.querySelector(`.nodeid-${j.id}`);
            target_node.classList.add("grey");
          }
        } else {
          if (assign_map[i].length > 0) {
            if (color_num > 15) {
              color_num = 0;
            }
            color_num = color_num + 1;
            this.colormap[i] = ["c" + color_num, assign_map[i].length];
            for (let j of assign_map[i]) {
              const target_node = document.querySelector(`.nodeid-${j.id}`);
              (function (num) {
                setTimeout(() => {
                  target_node.classList.add("c" + num);
                }, 0);
              })(color_num);
            }
          }
        }
      }

      this.outstanding_map = [];
      this.outstanding_name = this.$t("canvas.wordView.assignee");

      Object.keys(this.colormap).forEach((item) => {
        this.outstanding_map.push({
          name: this.user_list[item]
            ? this.user_list[item].nickname
            : this.$t("canvas.wordView.null"),
          color: this.colormap[item][0],
          num: this.colormap[item][1],
          value: item,
        });
      });
    },
    test_plan_outstanding() {
      // 获取测试计划下所有节点
      get_test_nodes(this.get_pid(), this.select_testplan).then((res) => {
        let all_test_nodes = res;
        // 获取界面中所有节点
        let all_file_nodes = this.$store.getters.exist_mindmap.data;
        // 匹配，如果相等加上样式
        let do_list = [];
        let undo_list = [];
        for (let i in all_file_nodes) {
          for (let j in all_test_nodes) {
            if (all_file_nodes[i]["key"] == all_test_nodes[j]["nodeKey"]) {
              let testStatus = JSON.parse(
                sessionStorage.getItem("_execution_status")
              );
              if (testStatus[all_test_nodes[j].testCaseStatus].complete) {
                do_list.push(all_file_nodes[i]["key"]);
              } else {
                undo_list.push(all_file_nodes[i]["key"]);
              }
            }
          }
        }
        this.global_variable_select_testplan_nodes = [
          ...(do_list || []),
          ...(undo_list || []),
        ];
        let jmnodes = document.querySelectorAll("jmnode");
        jmnodes.forEach((jmnode) => {
          jmnode.classList.add(`nodeid-${jmnode.getAttribute("nodeid")}`);
        });
        all_file_nodes.forEach((node) => {
          const target_node = document.querySelector(`.nodeid-${node.id}`);
          if (undo_list.includes(node.key)) {
            target_node.style.border = "4px solid rgba(255, 87, 51, 1)";
            target_node.style.boxSizing = "content-box";
          } else {
            if (do_list.includes(node.key)) {
              target_node.style.border = "4px solid #67C23A";
              target_node.style.boxSizing = "content-box";
            } else {
              target_node.style.border = "none";
            }
          }
        });
      });
    },
    map_link() {
      if (this.$route.query.tool_tip == "global_tip_4") {
        return;
      }

      this.$router.push({
        path: `/${this.get_pid()}/myMind/${this.$route.params.file_key}`,
      });
    },
    word_link() {
      // if (
      //   this.$route.query.tool_tip == "global_tip_4" &&
      //   this.$route.path.indexOf("myMind") > -1
      // ) {
      //   vmson.$emit("global_tip", "4@next");
      // }
      if (this.$route.query.tool_tip == "global_tip_4") {
        vmson.$emit("global_tip", "4@word");
      }
      let d = document.querySelectorAll("#custom_style");
      this.$router.push({
        path: `/${this.get_pid()}/lineword/${this.$route.params.file_key}`,
        hash: d.length == 1 ? d[0].attributes["nodeid"].nodeValue : "",
        query: this.$route.query,
      });
    },

    model_link() {
      if (this.$route.query.tool_tip == "global_tip_4") {
        return;
      }
      // let d = document.querySelectorAll("#custom_style");
      this.$router.push({
        path: `/${this.get_pid()}/model/${this.$route.params.file_key}`,
      });
    },
    tree_link() {
      if (this.$route.query.tool_tip == "global_tip_4") {
        return;
      }
      // let d = document.querySelectorAll("#custom_style");
      this.$router.push({
        path: `/${this.get_pid()}/tree/${this.$route.params.file_key}`,
      });
    },
    DFMEAView() {
      this.$emit("DFMEAView");
    },
    // 判断是否开启tooltip功能
    tooltip_show_judge(str) {
      let parentWidth = this.$refs[str].parentNode.offsetWidth;
      let contentWidth = this.$refs[str].offsetWidth;

      // if (contentWidth > parentWidth) {
      if (this.name.length > 25) {
        this.tooltiop_show = false;
      } else {
        this.tooltiop_show = true;
      }
    },

    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },

    /** 突出显示节点状态 */
    node_state_outstanding() {
      this.outstanding_type = "status";

      this.node_state_reset();
      // 加一个classname，方便后面直接查找
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        jmnode.classList.add(`nodeid-${jmnode.getAttribute("nodeid")}`);
      });

      let co = [
        "19BF5B",
        "55AA55",
        "0CAD0C",
        "23B85E",
        "2D882D",
        "2651A6",
        "4968A6",
        "4B7BA6",
        "6B7FF2",
        "597EF7",
        "E87A90",
        "F759AB",
        "D75455",
        "FF4D4F",
        "FF7A45",
      ];

      let l = Array.from(this.state_info.values());
      this.outstanding_detail_dialog_show = true;
      this.outstanding_name = this.$t("canvas.wordView.status");
      this.outstanding_map = [];
      this.outstanding_map = l;

      this.exist_nodes_not_deleted.forEach((node) => {
        const target_node = document.querySelector(`.nodeid-${node.id}`);
        let color = this.state_info.get(node.status).color;
        target_node.classList.add("d" + (co.indexOf(color) + 1));
      });
    },
    /** 节点状态恢复 */
    node_state_reset() {
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        jmnode.getAttribute("nodeid") == "root"
          ? (jmnode.classList = "root")
          : (jmnode.classList = "");
      });
      // 对所有节点，恢复颜色
      jmnodes.forEach((jmnode) => {
        jmnode.classList.add(`nodeid-${jmnode.getAttribute("nodeid")}`);
      });
      this.exist_nodes_not_deleted.forEach((node) => {
        const target_node = document.querySelector(`.nodeid-${node.id}`);
        if (target_node) {
          target_node.style["background-color"] = "#428bca";
          target_node.style["border"] = "none";
        }
      });
    },
    //快捷键
    handleKeyDown(event) {
      if (this.isReviewer) {
        return;
      }
      if (this.operatingSystem == "Windows") {
        if (
          event.ctrlKey &&
          event.altKey &&
          (event.key === "S" || event.key === "s")
        ) {
          this.handle_state_outstanding_click();
        }
        if (event.altKey && (event.key === "R" || event.key === "r")) {
          this.handle_state_response_click();
        }
        if (event.altKey && (event.key === "p" || event.key === "P")) {
          this.handle_Sprint_click();
        }
        if (event.altKey && (event.key === "M" || event.key === "m")) {
          this.handle_required_check_click();
        }
        if (event.altKey && (event.key === "Q" || event.key === "q")) {
          this.map_link();
        }
        if (event.altKey && (event.key === "O" || event.key === "o")) {
          this.model_link();
        }
        if (event.altKey && (event.key === "T" || event.key === "t")) {
          this.tree_link();
        }
        if (event.altKey && (event.key === "w" || event.key === "W")) {
          this.word_link();
        }
      } else {
        if (event.altKey && event.code === "KeyS") {
          this.handle_state_outstanding_click();
        }
        if (event.altKey && event.code === "KeyR") {
          this.handle_state_response_click();
        }
        if (event.altKey && event.code === "KeyP") {
          this.handle_Sprint_click();
        }
        if (event.altKey && event.code === "KeyM") {
          this.handle_required_check_click();
        }
        if (event.altKey && event.code === "KeyW") {
          this.word_link();
        }
        if (event.altKey && event.code === "KeyQ") {
          this.map_link();
        }
        if (event.altKey && event.code === "KeyO") {
          this.model_link();
        }
        if (event.altKey && event.code === "KeyT") {
          this.tree_link();
        }
      }
    },
    /** 点击"状态突出" */
    handle_state_outstanding_click() {
      this.outstanding_type = "status";
      if (this.$route.name == "model") {
        vmson.$emit("model_view_outstanding", "status");
        return;
      }
      if (this.$route.name == "lineword") {
        vmson.$emit("handle_lineword_outstanding_click", "status");
        return;
      }
      this.is_check_required = false;
      this.is_check_coverage = false;
      this.is_state_response = false;
      this.is_state_Sprint = false;
      this.relationFileTypeId = "";
      this.is_state_outstanding = !this.is_state_outstanding;
      this.is_state_otherFile = false;
      this.is_check_reuse = false;
    },
    handle_Sprint_click() {
      this.outstanding_type = "sprintId";
      if (this.$route.name == "model") {
        vmson.$emit("model_view_outstanding", "sprintId");
        return;
      }
      if (this.$route.name == "lineword") {
        vmson.$emit("handle_lineword_outstanding_click", "sprintId");
        return;
      }
      this.is_state_outstanding = false;
      this.is_check_required = false;
      this.is_check_coverage = false;
      this.is_state_response = false;
      this.relationFileTypeId = "";
      this.is_state_Sprint = !this.is_state_Sprint;
      this.is_state_otherFile = false;
      this.is_check_reuse = false;
    },
    handle_state_response_click() {
      this.outstanding_type = "assignee";
      if (this.$route.name == "model") {
        vmson.$emit("model_view_outstanding", "assignee");
        return;
      }
      if (this.$route.name == "lineword") {
        vmson.$emit("handle_lineword_outstanding_click", "assignee");
        return;
      }
      this.is_state_outstanding = false;
      this.is_check_required = false;
      this.is_check_coverage = false;
      this.is_state_Sprint = false;
      this.is_state_response = !this.is_state_response;
      this.relationFileTypeId = "";
      this.is_state_otherFile = false;
      this.is_check_reuse = false;
    },
    handle_state_otherFile_click(fieldId) {
      this.outstanding_type = "otherFile@" + fieldId;
      if (this.$route.name == "model") {
        vmson.$emit("model_view_outstanding", "otherFile@" + fieldId);
        return;
      }
      if (this.$route.name == "lineword") {
        vmson.$emit(
          "handle_lineword_outstanding_click",
          "otherFile@" + fieldId
        );
        return;
      }
      this.is_state_outstanding = false;
      this.is_check_required = false;
      this.is_check_coverage = false;
      this.is_state_Sprint = false;
      this.is_state_response = false;
      this.is_check_reuse = false;
      this.relationFileTypeId = "";
      this.otherData_fieldId = fieldId;
      if (this.is_state_otherFile) {
        this.handle_otherFile_click();
      } else {
        this.is_state_otherFile = true;
      }
    },

    /** 显示必填项 */
    required_check() {
      let has_required = false;
      // 加一个classname，方便后面直接查找
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        jmnode.classList.add(`nodeid-${jmnode.getAttribute("nodeid")}`);
      });
      // 对所有节点，判断是否有必填项未填,并改变外框
      this.exist_nodes_not_deleted.forEach((node) => {
        let is_this_node_required = false;
        let target_node = document.querySelector(`.nodeid-${node.id}`);
        node.content.forEach((item) => {
          if (item.required && (!item.value || item.value.length == 0)) {
            has_required = true;
            is_this_node_required = true;
          }
        });
        if (is_this_node_required) {
          target_node.style.border = "2px solid red";
        } else {
          target_node.style.border = "none";
        }
      });
      return has_required;
    },

    /** 显示必填项恢复 */
    required_check_reset() {
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        jmnode.style.border = "none";
      });
    },

    /** 点击"必填项检测" */
    handle_required_check_click() {
      if (this.$route.name == "model") {
        vmson.$emit("model_view_outstanding", "required");
        return;
      }
      if (this.$route.name == "lineword") {
        vmson.$emit("handle_lineword_outstanding_click", "required");
        return;
      }
      this.is_check_required = !this.is_check_required;
      this.is_state_outstanding = false;
      this.is_check_coverage = false;
      this.is_state_Sprint = false;
      this.is_state_response = false;
      this.is_state_otherFile = false;
      this.is_check_reuse = false;
      this.relationFileTypeId = "";
      setTimeout(() => {
        if (this.is_check_required) {
          let has_required = this.required_check();
          Message({
            message: has_required
              ? this.$t("canvas.messages.requiredWarn1")
              : this.$t("canvas.messages.requiredWarn2"),
            type: has_required ? "warning" : "success",
            duration: 5 * 1000,
          });
        } else {
          this.required_check_reset();
        }
      }, 0);
    },
    // 点击复用情况
    handle_reuse_situation_click() {
      //    if (this.$route.name == "model") {
      //   vmson.$emit("model_view_outstanding", "status");
      //   return;
      // }
      if (this.$route.name == "lineword") {
        vmson.$emit("handle_lineword_outstanding_click", "reuse");
        return;
      }
      this.is_state_outstanding = false;
      this.is_check_required = false;
      this.is_state_Sprint = false;
      this.is_state_response = false;
      this.is_state_otherFile = false;
      this.is_check_reuse = true;
      this.fileTypeName = this.$t("canvas.viewDropDown.Reusesituation");
      const params = {
        projectId: this.get_pid(),
        data: {
          nodeKeyList: this.allkeyList,
        },
      };
      nodeOrigins(params).then((res) => {
        this.node_state_reset();
        const suspect = [];
        const hassuspect = [];
        const consistent = [];
        const hasconsistent = [];
        let noreused = [];
        if (res.nodeOrigins) {
          this.exist_nodes_not_deleted.forEach((nodeInfo) => {
            let flag = false;
            res.nodeOrigins.forEach((item) => {
              if (item.newNodeKey === nodeInfo.key) {
                flag = true;
                if (item.diff && hassuspect.indexOf(nodeInfo.key) == -1) {
                  suspect.push(nodeInfo);
                  hassuspect.push(nodeInfo.key);
                } else if (
                  !item.diff &&
                  hasconsistent.indexOf(nodeInfo.key) == -1
                ) {
                  consistent.push(nodeInfo);
                  hasconsistent.push(nodeInfo.key);
                }
              }
            });
            if (!flag) {
              noreused.push(nodeInfo);
            }
          });
        } else {
          noreused = JSON.parse(JSON.stringify(this.exist_nodes_not_deleted));
        }
        this.reuse_map = [];
        this.reuse_map.push({
          name: this.$t("node.message.message7"),
          color: "rgb(232,122,144)",
          num: suspect.length,
          value: suspect,
        });
        this.reuse_map.push({
          name: this.$t("node.message.message8"),
          color: "#66ccff",
          num: consistent.length,
          value: consistent,
        });
        this.reuse_map.push({
          name: this.$t("node.message.message9"),
          color: "grey",
          num: noreused.length,
          value: noreused,
        });
        this.noreused = noreused;
        // 加一个classname，方便后面直接查找
        let jmnodes = document.querySelectorAll("jmnode");
        jmnodes.forEach((jmnode) => {
          jmnode.classList.add(`nodeid-${jmnode.getAttribute("nodeid")}`);
        });
        this.exist_nodes_not_deleted.forEach((node) => {
          const target_node = document.querySelector(`.nodeid-${node.id}`);
          if (hassuspect.includes(node.key)) {
            target_node ? target_node.classList.add("c2") : "";
          } else if (hasconsistent.includes(node.key)) {
            target_node ? target_node.classList.add("c1") : "";
          } else {
            target_node.classList.add("grey");
          }
        });
      });
    },
    /** 突出显示未覆盖的节点 */
    async coverage_check() {
      this.node_state_reset();
      if (!this.relationFileTypeId || !this.is_check_coverage) {
        return;
      }
      this.outstanding_type = "coverage";
      let hasCoverageNodesKeyList = await get_file_coverage(
        this.get_pid(),
        this.$route.params.file_key,
        this.coverage_check_data
      );
      // 去重
      let ignoreList = this.ignoreList;
      if (!ignoreList) {
        ignoreList = [];
      }
      // let hasCoverageNodesKeyList = this.coverage_check_data
      hasCoverageNodesKeyList = Array.from(new Set(hasCoverageNodesKeyList));
      hasCoverageNodesKeyList = hasCoverageNodesKeyList.filter(
        (node) => !ignoreList.includes(node)
      );
      const filteredExistNodes = this.exist_nodes_not_deleted.filter(
        (node) => !ignoreList.includes(node.key)
      );
      const filteredIgnoreNodes = this.exist_nodes_not_deleted.filter((node) =>
        ignoreList.includes(node.key)
      );

      this.coverge_ignore = filteredIgnoreNodes.length;
      this.coverge_hasNum = hasCoverageNodesKeyList.length;
      this.coverge_noNum = filteredExistNodes.length - this.coverge_hasNum;
      this.outstanding_map = [];

      this.outstanding_map.push({
        name: this.$t("node.message.message2"),
        color: "rgb(232,122,144)",
        num: this.coverge_noNum,
        value: filteredExistNodes.filter(
          (node) => !hasCoverageNodesKeyList.includes(node.key)
        ),
      });
      this.outstanding_map.push({
        name: this.$t("node.message.message3"),
        color: "#66ccff",
        num: this.coverge_hasNum,
        value: hasCoverageNodesKeyList,
      });
      this.outstanding_map.push({
        name: this.$t("node.message.noNeedCoverage"),
        color: "grey",
        num: this.coverge_ignore,
        value: filteredIgnoreNodes,
      });

      // 加一个classname，方便后面直接查找
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        jmnode.classList.add(`nodeid-${jmnode.getAttribute("nodeid")}`);
      });
      // 对没有覆盖的节点，突出显示
      let result = false;
      this.exist_nodes_not_deleted.forEach((node) => {
        const target_node = document.querySelector(`.nodeid-${node.id}`);
        if (ignoreList.includes(node.key)) {
          target_node ? target_node.classList.add("grey") : "";
        } else if (!hasCoverageNodesKeyList.includes(node.key)) {
          result = true;
          // target_node.style.border = "2px solid red";
          target_node ? target_node.classList.add("c2") : "";
        } else {
          // target_node.style.border = "none";
          target_node.classList.add("c1");
        }
      });
      return result;
    },

    /** 显示未覆盖节点恢复 */
    coverage_check_reset() {
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        jmnode.style.border = "none";
      });
    },

    /** 点击"覆盖度检测" */
    async handle_coverage_check_click(fileTypeName, relationFileTypeId) {
      this.outstanding_type = "coverage@" + relationFileTypeId;
      if (this.$route.name == "model") {
        vmson.$emit(
          "model_view_outstanding",
          "coverage@" + relationFileTypeId + "@" + fileTypeName
        );
        return;
      }
      if (this.$route.name == "lineword") {
        vmson.$emit(
          "handle_lineword_outstanding_click",
          "coverage@" + relationFileTypeId + "@" + fileTypeName
        );
        return;
      }
      this.fileTypeName = fileTypeName;

      this.relationFileTypeId = relationFileTypeId;
      this.is_state_outstanding = false;
      this.is_check_required = false;
      this.is_state_Sprint = false;
      this.is_state_response = false;
      this.is_state_otherFile = false;
      this.is_check_reuse = false;
      // if (relationFileTypeId == this.relationFileTypeId) {
      //   // 说明当前点击的是已经选中的
      //   this.is_check_coverage = false;
      //   this.relationFileTypeId = "";
      // } else {
      //   this.is_check_coverage = true;
      //   this.relationFileTypeId = relationFileTypeId;
      // }
      if (this.is_check_coverage) {
        this.coverage_check();
      } else {
        this.is_check_coverage = true;
      }
      // setTimeout(() => {
      //   if (this.is_check_coverage) {
      //     this.coverage_check().then((has_noCoverage) => {
      //       Message({
      //         message: has_noCoverage
      //           ? this.$t("canvas.messages.coverageWarn1") +
      //             `(${fileTypeName}).` +
      //             this.$t("canvas.messages.coverageWarn11")
      //           : this.$t("canvas.messages.coverageWarn2") +
      //             `(${fileTypeName}).`,
      //         type: has_noCoverage ? "warning" : "success",
      //         duration: 5 * 1000,
      //       });
      //     });
      //   } else {
      //     this.coverage_check_reset();
      //   }
      // }, 0);
    },
    /* 节点文字左对齐或者居中 */
    indent() {
      this.nodeTextIndent = "left" === this.nodeTextIndent ? "center" : "left";
      this.$emit("changeNodeTextIndent", this.nodeTextIndent);
    },
    /**全屏模式 */
    full() {
      if (this.isFull) {
        let docElm = document.documentElement;
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
        this.isFull = false;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.isFull = true;
      }
    },
    /**点击放大 */
    amplification() {
      //优化放大缩小方法
      this.$emit("canvasbox");
    },
    /**点击缩小 */
    narrow() {
      this.$emit("canvasbut");
    },
    isDisabled(row) {
      if (row.content != this.$t("canvas.import")) {
        return 0;
      }
      return 1;
    },
    onSelectAll() {
      this.$refs.multipleTable.clearSelection(); //这个是清除所有选择项，当选择全部时，清除所有选择项
    },
    cellClass(row) {
      if (row.columnIndex === 0) {
        return "DisableSelection";
      }
    },
    rowStyle(row, column, rowIndex, columnIndex) {
      // 状态列字体颜色
      if (row.row.content != this.$t("canvas.import")) {
        return "color: #EA1B29";
      }
      return "";
    },
    edit() {
      this.flag = false;
      this.input_name = this.$store.getters.mindmap_name;
      this.$nextTick(() => {
        this.$refs["input_panel"].focus();
      });
    },
    async away(e) {
      let value = e.target.value;
      if (value == "") {
        this.input_name = this.$store.getters.mindmap_name;
        this.flag = true;
        return;
      }
      if (value != this.$store.getters.mindmap_name) {
        let originname = this.$store.getters.mindmap_name;
        try {
          let res = await refileName(
            this.get_pid(),
            this.$store.getters.file_key,
            {
              name: this.input_name,
            }
          );
        } catch (error) {
          this.name = originname;
          this.flag = true;
          return;
        }
        this.$message({
          type: "success",
          message: this.$t("canvas.messages.renameSuccess"),
        });
        this.$store.commit("SET_MINDMAP_NAME", this.input_name);
        this.name = this.input_name;
      }
      setTimeout(() => {
        this.flag = true;
      }, 200);
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件,确保只能选中一项数据
    select(selection, row) {
      this.$refs.multipleTable.clearSelection();
      if (selection.length == 0) return;
      this.$refs.multipleTable.toggleRowSelection(row, true);
      return row.id;
    },
    handleShow() {
      this.excelDialogVisible = false;
      vmson.$emit("set_screen", this.$refs.multipleTable.selection[0].file);
      //vmson.$emit("save",this.$store.getters.name);
      Message({
        message: this.$t("canvas.messages.importSuccess"),
        type: "success",
        duration: 5 * 1000,
      });
      this.productlist = [];
    },
    changeFile(file) {
      let fd = new FormData();
      fd.append("email", this.$store.getters.email);
      fd.append("type", this.$store.getters.type);
      //fd.append('sheet',this.$store.getters.sheet);
      fd.append("file", file.file);
      if (this.$store.getters.sheet == "0") {
        import_others(fd).then((res) => {
          if (res.flag == "0") {
            vmson.$emit("set_screen", res.file);
            //vmson.$emit("save",this.$store.getters.name);
            Message({
              message: this.$t("canvas.messages.importSuccess"),
              type: "success",
              duration: 5 * 1000,
            });
          } else {
            if (res.flag == "400") {
              Message({
                message: this.$t("canvas.messages.importWarn1"),
                type: "error",
                duration: 5 * 1000,
              });
            } else if (res.flag == "401") {
              Message({
                message: this.$t("canvas.messages.importWarn2"),
                type: "error",
                duration: 5 * 1000,
              });
            } else if (res.flag == "402") {
              Message({
                message: this.$t("canvas.messages.importWarn3"),
                type: "error",
                duration: 5 * 1000,
              });
            } else if (res.flag == "403") {
              Message({
                message: this.$t("canvas.messages.importWarn4"),
                type: "error",
                duration: 5 * 1000,
              });
            } else if (res.flag == "406") {
              Message({
                message: this.$t("canvas.messages.importWarn5"),
                type: "error",
                duration: 5 * 1000,
              });
            }
          }
        });
      } else {
        this.productlist = [];
        this.excelDialogVisible = true;
        import_excel(fd).then((res) => {
          for (let rs in res.flag) {
            if (res.flag[rs] == "1") {
              var tt = {
                productName: res.names[rs],
                file: res.sheets[rs],
                content: this.$t("canvas.import"),
              };
              this.productlist.push(tt);
            } else if (res.flag[rs] == "400") {
              var tt = {
                productName: res.names[rs],
                file: res.sheets[rs],
                content: this.$t("canvas.messages.importWarn1"),
              };
              this.productlist.push(tt);
            } else if (res.flag[rs] == "401") {
              var tt = {
                productName: res.names[rs],
                file: res.sheets[rs],
                content: this.$t("canvas.messages.importWarn2"),
              };
              this.productlist.push(tt);
            } else if (res.flag[rs] == "402") {
              var tt = {
                productName: res.names[rs],
                file: res.sheets[rs],
                content: this.$t("canvas.messages.importWarn3"),
              };
              this.productlist.push(tt);
            } else if (res.flag[rs] == "405") {
              var tt = {
                productName: res.names[rs],
                file: res.sheets[rs],
                content: this.$t("canvas.messages.importWarn4"),
              };
              this.productlist.push(tt);
            } else if (res.flag[rs] == "406") {
              var tt = {
                productName: res.names[rs],
                file: res.sheets[rs],
                content: this.$t("canvas.messages.importWarn5"),
              };
              this.productlist.push(tt);
            }
          }
        });
      }
    },
    /*导入excel文件时不可导入时标红*/
    rowClass(row, index) {
      if (
        row.row.file == "400" ||
        row.row.file == "401" ||
        row.row.file == "402" ||
        row.row.file == "405"
      ) {
        return { color: "#FF0000" };
      }
    },
    // 处理操作菜单事件
    handleSelect(key, keyPath) {
      // 设为模板
      if (key === "1-3") {
        vmson.$emit("set_template", this.$t("canvas.testStepForm.template"));
      }
      // 导入word
      if (key === "1-1-1") {
        this.$store.commit("SET_TYPE", "word");
        this.$store.commit("SET_SHEET", "0");
        this.$store.commit("SET_TYPED", ".docx");
        this.$refs["upload"].$refs["upload-inner"].handleClick();
      }
      // 导入excel
      if (key === "1-1-2") {
        this.$store.commit("SET_TYPE", "excel");
        this.$store.commit("SET_TYPED", ".xlsx");
        this.$store.commit("SET_SHEET", "1");
        this.$refs["upload"].$refs["upload-inner"].handleClick();
      }
      // 导入csv
      if (key === "1-1-3") {
        this.$store.commit("SET_TYPE", "csv");
        this.$store.commit("SET_TYPED", ".csv");
        this.$store.commit("SET_SHEET", "0");
        this.accept = ".csv";
        this.$refs["upload"].$refs["upload-inner"].handleClick();
      }
      //下载模板
      if (key === "1-1-4") {
        download_model_excel().then((res) => {
          let data = res;
          let val =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const url = window.URL.createObjectURL(
            new Blob([data], { type: val })
          );
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", this.$t("addTopBar.excel") + ".xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          Message({
            message: this.$t("canvas.messages.exportSuccess"),
            type: "success",
            duration: 5 * 1000,
          });
        });
      }
      // 导出excel
      if (key === "1-2-1") {
        this.exportExcel();
      }
      // 导出word
      if (key === "1-2-2") {
        this.$store.commit("SET_TYPE", "excel");
        this.$store.commit("SET_TYPED", ".xlsx");
        vmson.$emit(
          "export_mindmap",
          "application/vnd.ms-excel;charset='utf-8'"
        );
      }
      // 导出csv
      if (key === "1-2-3") {
        this.$store.commit("SET_TYPE", "csv");
        this.$store.commit("SET_TYPED", ".csv");
        vmson.$emit("export_mindmap", ".csv");
      }
      // 设置页
      if (key === "2") {
        this.settingVisible = true;
      }
      // 撤销
      if (key === "3") {
        vmson.$emit("go_back");
      }
      // 重做
      if (key === "4") {
        vmson.$emit("go_on");
      }
      if (key === "5") {
        vmson.$emit("set_fvalue", this.fvalue);
      }
    },
    chenge_fvalue() {
      this.fvalue = !this.fvalue;
      vmson.$emit("set_fvalue", this.fvalue);
    },
    download(blobUrl, name) {
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = blobUrl;
      link.download = `${name}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 处理用户资料点击事件
    handleClickUser() {
      this.$router.push("/profile/");
    },
    // 处理用户悬浮菜单点击事件
    handleClick(command) {
      if (command === "logout") {
        //置空
        this.$store.commit("SET_EMAIL", "");
        this.$store.commit("SET_TOKEN", "");
        removeToken();
        this.$router.push("/login/");
      } else if (command === "profile") {
        this.$router.push("/profile/");
      }
    },
    handleChangeName() {},
    // 返回事件
    goBack() {
      //vmson.$emit("back", this.name);
    },
    handleNotification() {
      this.$router.push("/notification");
    },
    //
    handle_otherFile_click() {
      // 恢复原状态
      this.node_state_reset();
      // 获取其他字段数据
      let data = new Map();
      this.exist_nodes_not_deleted.forEach((item1) => {
        item1.content.forEach((item) => {
          if (
            item.fieldType == "SINGLE_USER_SELECT" ||
            item.fieldType == "SELECT"
          ) {
            if (data.has(item.fieldId)) {
              if (item.value == null) {
                let arr = [...data.get(item.fieldId).noId];
                arr.push(item1.id);
                data.set(item.fieldId, {
                  name: item.name,
                  noNum: data.get(item.fieldId).noNum + 1,
                  noId: arr,
                  hasNum: data.get(item.fieldId).hasNum,
                  hasId: data.get(item.fieldId).hasId,
                  value: data.get(item.fieldId).value,
                });
              } else {
                let arr = [...data.get(item.fieldId).hasId];
                let value_arr = [...data.get(item.fieldId).value];
                arr.push(item1.id);
                value_arr.push(item.value);
                data.set(item.fieldId, {
                  name: item.name,
                  noNum: data.get(item.fieldId).noNum,
                  noId: data.get(item.fieldId).noId,
                  hasNum: data.get(item.fieldId).hasNum + 1,
                  hasId: arr,
                  value: value_arr,
                });
              }
            } else {
              if (item.value == null) {
                data.set(item.fieldId, {
                  name: item.name,
                  noNum: 1,
                  noId: [item1.id],
                  hasNum: 0,
                  hasId: [],
                  value: [],
                });
              } else {
                data.set(item.fieldId, {
                  name: item.name,
                  noNum: 0,
                  noId: [],
                  hasNum: 1,
                  hasId: [item1.id],
                  value: [item.value],
                });
              }
            }
          }
        });
      });
      this.otherFile_list = data;
      // this.is_state_otherFile = true;
      this.unAppoint_num = 0;
      // 加一个classname，方便后面直接查找
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        jmnode.classList.add(`nodeid-${jmnode.getAttribute("nodeid")}`);
      });

      this.otherFile_list.forEach((value, key) => {
        if (key == this.otherData_fieldId) {
          // 未分配的上色
          value.noId.forEach((id) => {
            const target_noNode = document.querySelector(`.nodeid-${id}`);
            target_noNode.classList.add("grey");
          });

          let value_arr = new Map();
          // 已分配的进行区分
          value.value.forEach((item, index1) => {
            if (value_arr.has(item)) {
              let arr_index = value_arr.get(item).index;
              arr_index.push(index1);
              value_arr.set(item, {
                num: value_arr.get(item).num + 1,
                index: arr_index,
              });
            } else {
              value_arr.set(item, { num: 1, index: [index1] });
            }
          });

          this.value_arr = value_arr;
          let count = 1;
          let color = "";
          // 分配的上色
          value_arr.forEach((value1, key) => {
            value1.index.forEach((index) => {
              let color_id = value.hasId[index];
              const target_hasNode = document.querySelector(
                `.nodeid-${color_id}`
              );
              target_hasNode.classList.add("c" + count);
              color = "c" + count;
              // color = this.getRealStyle(target_hasNode, "background-color");
              color = this.color_list[color];
            });
            value_arr.set(key, {
              num: value_arr.get(key).num,
              index: value_arr.get(key).index,
              color: color,
            });
            count++;
            if (count > Object.keys(this.color_list).length) {
              count = 1;
            }
          });
        }
      });

      let otherFile_map = {};
      let stack = JSON.parse(JSON.stringify(this.exist_nodes_not_deleted));

      let result_map = {};
      while (stack.length > 0) {
        let n = stack.shift();
        let flag = true;
        for (let i of n.content) {
          if (i.fieldId == this.otherData_fieldId) {
            flag = false;
            if (i.value in otherFile_map) {
              otherFile_map[i.value].push(n.id);
            } else {
              otherFile_map[i.value] = [n.id];
            }

            if (i.value in result_map) {
              result_map[i.value] += 1;
            } else {
              result_map[i.value] = 1;
            }
          }
        }
        if (flag) {
          if (this.$t("node.message.message1") in otherFile_map) {
            otherFile_map[this.$t("node.message.message1")].push(n.id);
          } else {
            otherFile_map[this.$t("node.message.message1")] = [n.id];
          }

          if (this.$t("node.message.message1") in result_map) {
            result_map[this.$t("node.message.message1")] += 1;
          } else {
            result_map[this.$t("node.message.message1")] = 1;
          }
        }
      }

      this.outstanding_map = [];
      for (let i of Object.keys(result_map)) {
        if (i != "null") {
          this.outstanding_map.push({
            name: i,
            color: this.value_arr.get(i)
              ? this.value_arr.get(i).color
              : "rgb(66, 139, 202)",
            num: result_map[i],
            value: otherFile_map[i],
          });
        }
      }
      if ("null" in result_map) {
        this.outstanding_map.push({
          name: this.$t("canvas.wordView.null"),
          color: "grey",
          num: result_map["null"],
          value: otherFile_map["null"],
        });
      }
    },
    //获取元素样式
    getRealStyle(element, styleName) {
      let realStyle = null;
      if (element.currentStyle) {
        realStyle = element.currentStyle[styleName]; //IE
      } else if (window.getComputedStyle) {
        realStyle = window.getComputedStyle(element, null)[styleName]; //W3C
      }
      return realStyle;
    },
    // 根据value查找名字
    getValue_name() {
      return this.otherFile_list.get(this.otherData_fieldId).name;
    },

    handleClose() {
      this.dialogVisible = false;
      this.submit_list = [];
      this.checkList = [];
      this.all_fields_item = false;
    },

    judge_all(val) {
      if (val) {
        // 点击全选
        this.checkList.forEach((item) => {
          this.submit_list.push(item.fieldId);
        });
      } else {
        this.submit_list = [];
      }
    },
    goKnowledge() {
      const nowLang = localStorage.getItem("lang") || "zh";
      if (nowLang == "zh") {
        window.open(
          "https://ms.ytdevops.com/pubKnowledge/1db7562f-6f55-4d30-907c-390163fe43b8?elementId=01HHB97N1E3EZNQHTJW07HMJ6Z",
          "_blank"
        );
      } else if (nowLang == "en") {
        window.open(
          "https://ms.ytdevops.com/pubKnowledge/c53738e5-f7bb-4b39-a194-843f86659a96?elementId=01HHB97N1E3EZNQHTJW07HMJ6Z",
          "_blank"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.red_star {
  position: relative;

  &::before {
    content: "*";
    color: red;
    position: absolute;
    left: -8px;
    top: 0px;
  }
}
::v-deep .el-table tbody tr:hover > td {
  background-color: transparent !important;
}
.topbar_header {
  position: relative;
}

.sprint_options_hide {
  position: absolute;
  left: 50%;
  width: 100px !important;
  height: 26px !important;
  text-align: center;
  line-height: 26px;
  bottom: 4px !important;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: #409eff;
  }
}

.hide {
  top: 0;
}

.icon-shoucang {
  margin: 0 0 0 20px;
  cursor: pointer;
}

/* .topbar-header {
  height: 50px;
} */
.topbar-main-w {
  /* position: fixed; */
  /* top: 150px; */
  display: flex;
  flex-direction: column;
  height: 120px;
  background-color: white;
  z-index: 99;
  position: relative;

  .under_line {
    position: absolute;
    width: calc(100% - 40px);
    left: 20px;
    bottom: 0;
    z-index: 100;
    content: "";
  }

  .line {
    cursor: pointer;
    width: calc(100% - 40px);
    height: 15px;
    bottom: 0px;
    z-index: 100;
  }

  ::v-deep .el-menu.el-menu--horizontal {
    border-bottom: none !important;
  }

  ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
    height: 60px !important;
    border: none;
  }

  ::v-deep .el-submenu {
    height: 60px !important;
  }

  ::v-deep .menuitem {
    height: 60px !important;
  }
}

/**状态窗口 */
.state_window {
  width: 260px;
  position: absolute;
  top: 180px;
  right: 50px;
  font-size: 18px;
  color: #606060;
}

.state_window_header {
  display: flex;
  align-items: center;
  justify-content: end;
}

.state_window_header .close:hover {
  cursor: pointer;
}

.state_window_item {
  display: flex;
  align-items: center;
  justify-content: start;

  .name {
    // width: 180px;
    text-align: left;
    // 单行显示省略号
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .legend {
    width: 40px;
    display: flex;
    align-items: center;
  }

  .legend1 {
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
    align-items: center;

    p {
      margin: 5px 0;
    }
  }
}

.el-menu-demo {
  min-width: 600px;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > li {
    overflow: visible;
  }
}

.el-menu-demo .spacing {
  padding-right: 20px;
}

.el-menu-item {
  height: 60px;
  padding: 0 10px;
  overflow: hidden;
}

.dropdown_menu {
  padding: 0 !important;
}

.new_menu01 {
  color: #898989;
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.el-dropdown-link:hover .drop_down {
  border-left-color: transparent;
  border-top-color: #797979;
  transition: border-top-color 1s;
  margin-bottom: -1px;
}

.drop_down {
  display: inline-block;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-left-color: #797979;
  margin-left: 10px;
}
.copy_zdh_key {
  padding-left: 5px;
  color: #004caa;
  cursor: pointer;
}
.sprint1 {
  width: 15px;
  height: 15px;
  background: red;
  display: inline-block;
}

.sprint2 {
  width: 15px;
  height: 15px;
  background: rgb(242, 255, 0);
  display: inline-block;
}

.sprint3 {
  width: 15px;
  height: 15px;
  background: rgb(0, 255, 64);
  display: inline-block;
}

.el-dropdown-menu__item:hover {
  font-size: 18;
  font-weight: 600;
  color: rgba(48, 100, 143);
  background: white;
}

.view_style {
  display: inline-block;
  margin-top: 24px;
}

.text_change {
  display: inline-block;
  margin-top: -5px;
}

.dingw {
  display: block;
  height: 56px;
  /* position: fixed; */
  top: 0;
  z-index: 99;
  background-color: white;
}
.topbar_con {
  display: flex;
  height: 56px;
  justify-content: space-between;
  /* position: fixed; */
  top: 0;
  z-index: 99;
  background-color: white;
  .topbar_left {
    display: flex;
  }
  .topbar_right {
    display: flex;
    align-items: center;
    margin-right: 25px;
    div {
      margin-left: 25px;
    }
  }
}

.test_btn1 {
  border: none;
  background-color: #174fa9;
  // width: 1rem;
  // height: 40px;
  // width: 147px;
  height: 35px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
}
.test_btn1:hover {
  color: #fff !important;
  background-color: #568cb8 !important;
  border-color: #568cb8 !important;
}

.d-right {
  /* position: fixed; */
  right: 0;
  align-content: center;
  height: 56px;
}

.icons {
  font-size: 30px;
  color: #797979;
}

.icon-small {
  color: #797979;
}

.fas {
  color: #797979;
}

.fa-circle {
  color: red;
}

.fas:hover {
  color: #60a3d9;
}

.fa-lg {
  padding-top: 10px;
}

.items {
  text-align: right;
  margin: 5px;
}

.radio-grp {
  margin: 2px;
}

.box-card {
  margin: 2px;
}

.email {
  float: left;
  margin-bottom: 10px;
}

.permission {
  float: right;
  font-size: 10px;
}

.el-dropdown-menu {
  padding: 20px;
}

.menu-item {
  font-family: "思源黑体";
}

.tb .el-table__header .el-table-column--selection .cell .el-checkbox {
  display: none;
}

.name_span {
  // max-width: 60%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 10px;
  // width: 100%;
  // margin: 0 !important;
  // display: block;
}

.divhead {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;

  .el-tooltip {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.box_item_l {
  height: 30px;
  border: 1px solid #014caa;
  border-radius: 5px 0 0 5px;
  padding: 6px 14px;
  background-color: #014caa;
  display: flex;
  justify-items: center;
  align-items: center;
}

.box_item_r {
  height: 30px;
  border: 1px solid rgba(219, 219, 219, 1);
  border-radius: 0 5px 5px 0;
  padding: 0 14px;
  display: flex;
  justify-items: center;
  align-items: center;

  .box_font {
    font-size: 18px;
    color: rgba(255, 195, 0, 1);
    font-weight: 700;
    height: 100%;

    > div {
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.fuchuang_new_zdh {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  border-radius: 15px;
  // padding: 10px;
  width: 70px;
  font-size: 14px;
  margin-left: 15px;
  height: 30px;
  cursor: pointer;
  i {
    margin-left: 5px;
  }
}
.four_view_switch {
  display: flex;
  background: #e1e3e6;
  border-radius: 15px;
  color: #707070;
  font-size: 14px;
  height: 35px;
  align-items: center;
  cursor: pointer;
  min-width: 300px;
  div {
    width: 25%;
    display: flex;

    align-items: center;
    justify-content: center;
  }
  .active_swi_zdh {
    background: white;
    border-radius: 13px;
    height: 30px;
    color: #004caa;
    margin: 0 2px;
  }
}
</style>

<style>
/* 边框闪烁提醒 */
.jmnode-border-glow {
  animation: glow 500ms ease-out infinite alternate;
}

/* 边框闪烁动画 */
@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(252, 94, 94, 0.2);
    border: 1.5px solid red;
  }
  100% {
    box-shadow: 0 0 10px rgba(252, 94, 94, 0.6);
    border: 1.5px solid red;
  }
}
</style>
<style lang="scss">
.downbtnstyle {
  input {
    background-color: #174fa9;
    height: 35px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: white;
  }

  .el-input__suffix {
    border-left: 1px solid white;
  }
}

// el-popover样式不可以放在scoped中
.pop_template {
  padding: 0px;

  .template {
    display: flex;

    .template_menu {
      padding-bottom: 30px;
      // width: 140px; /*no*/
      background: rgba(229, 229, 229, 0.25);

      .title {
        font-size: 16px;
        margin: 25px 30px;
        color: #000;
      }

      .menu_item {
        display: flex;
        flex-direction: column;
        height: 250px; /*no*/
        .el-scrollbar__wrap {
          overflow-x: hidden;
        }

        p {
          width: 140px; /*no*/
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0 20px;
          font-size: 14px;
          text-align: right;
          height: 32px; /*no*/
          line-height: 32px; /*no*/
          margin: 0;
          color: rgba(128, 128, 128, 1);

          &:hover {
            background-color: #ecf5ff;
            color: #66b1ff;
          }
        }

        .active {
          background: #fff;
        }
      }
    }

    .template_preview {
      width: 460px; /*no*/
      position: relative;

      .footer {
        position: absolute;
        bottom: 30px;
        right: 30px;
      }
    }
  }
}

.view {
  margin-left: 15px;

  span {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.excel_export_dialog {
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: hidden scroll;
  }

  .el-checkbox {
    padding-bottom: 16px;
    display: flex;
    color: rgba(128, 128, 128, 1);

    .el-checkbox__label {
      font-size: 16px;
      font-weight: 400;
      // color: rgba(56, 56, 56, 1);
      text-align: left;
    }
  }
}
</style>
<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>

<style lang="scss">
.outstanding_dialog_color {
  width: 18px;
  height: 18px;
  // background-color: #0090f1;
  margin-right: 10px;
}
.outstanding_dialog_table_class td,
.outstanding_dialog_table_class th.is-leaf {
  border: none;
}

.outstanding_dialog_table_class::before {
  width: 0;
}

.outstanding_dialog_table_class {
  margin-bottom: 10px;
}
.outstanding_dialog_table_class_cell {
  text-align: start;
  font-size: 18px;
  .cell {
    padding: 0 !important;
  }
  .outstanding_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.reviewing_detail_dialog_body {
  .el-dialog__body {
    padding-right: 0 !important;
  }
  .el-dialog__header {
    padding-bottom: 0 !important;
  }
}
.outstanding_detail_dialog_body {
  .el-dialog__body {
    padding-right: 0 !important;
  }
  .el-dialog__header {
    padding-bottom: 0 !important;
  }
}
.outstanding_detail_dialog {
  pointer-events: none;
}
.reviewing_detail_dialog_body {
  pointer-events: auto !important;
  top: 50px;
  right: 50px;
  position: absolute;
}
.outstanding_detail_dialog_body {
  pointer-events: auto !important;
  top: 100px;
  right: 50px;
  position: absolute;
}
#tara_dialog {
  .el-dialog__body {
    padding: 24px !important;
  }
  .el-dialog__header {
    display: none;
  }
}
.test .el-submenu__icon-arrow {
  display: none;
}

.el-menu--popup .el-menu-item {
  &:hover {
    background-color: #ecf5ff;
  }
}
.nohover :hover {
  color: white !important;
}
</style>
