<template>
  <div id="knowledge-home-wrap" class="home-wrap">
    <div v-if="!knowledge_edit" class="left-wrap">
      <left-menu
        ref="leftMenu"
        :menu-list="menuList"
        :checked-node="checkedNode"
        @checked="checked"
        @updateMenu="getDocuments"
        @draggable="draggableFn"
        @isCollapse="isCollapse"
      ></left-menu>
    </div>
    <div
      v-if="!knowledge_edit"
      class="resize"
      :title="$t('addAlert.collapse')"
    ></div>
    <div v-if="!knowledge_edit" class="edit-wrap">
      <div
        v-if="authList.length && checkedNode"
        style="display: flex; width: 100%; height: 100%; overflow-x: hidden"
      >
        <knowledge-not-edit
          :checked-node="checkedNode"
          :draggable="draggable"
          :auth-list="authList"
          :catalogue-data="catalogueData"
          @getAuth="getAuthFn"
          @updateMenu="getDocuments"
          @deleteDoc="deleteDoc"
        ></knowledge-not-edit>
        <!-- <right-comment
          ref="rightComment"
          :checked-node="checkedNode"
          @commentinline="commentinline"
        ></right-comment> -->
      </div>
      <div v-else-if="!checkedNode" class="no-pr">
        <img src="@/assets/img/docNoSelect.png" alt="" />
        <p>{{ $t("knowledge.NoPagesSelected") }}</p>
      </div>
      <div
        v-else-if="!authList.length && completeGetSetting"
        class="no-pr"
        style="position: relative"
      >
        <div
          style="
            font-size: 32px;
            font-weight: 500;
            position: absolute;
            top: 30px;
            left: 30px;
          "
        >
          {{ checkedNode.label.slice(0, 5) + "...." }}
        </div>
        <img src="@/assets/img/nothing.png" alt="" />
        <p>{{ $t("knowledge.SorryNoPermission") }}</p>
        <el-button type="primary" @click="apply">{{
          $t("knowledge.RequestPermission")
        }}</el-button>
      </div>
    </div>
    <div v-else style="width: 100%; height: 100%">
      <knowledge-edit
        :checked-node="checkedNode"
        @updateMenu="getDocuments"
      ></knowledge-edit>
    </div>
  </div>
</template>

<script>
import leftMenu from "./leftMenu.vue";
import knowledgeNotEdit from "./knowledgeNotEdit.vue";
import knowledgeEdit from "./edit/knowledgeEdit.vue";
// import rightComment from "./notEdit/rightComment.vue";
import { mapGetters, mapMutations } from "vuex";
import { getDocuments, getAuth } from "@/network/knowledge";
import { apply_access } from "../../../network/knowledge";

export default {
  components: {
    leftMenu,
    knowledgeNotEdit,
    knowledgeEdit,
    // rightComment,
  },
  data() {
    return {
      menuList: [],
      checkedNode: null,
      draggable: true,
      authList: [],
      catalogueData: [],
      rightCommentList: [],
      completeGetSetting: false,
    };
  },
  computed: {
    ...mapGetters(["knowledge_edit"]),
  },
  mounted() {
    // 获取所有目录
    this.SET_KNOWLEDGE_EDIT({
      knowledge_edit: false,
      knowledge_edit_mode: "edit",
    });
    this.getDocuments();
  },
  updated() {
    // 这里要写在updated函数里面，要不然有问题，不知道为什么
    this.bindMoveLine();
  },
  methods: {
    ...mapMutations(["SET_KNOWLEDGE_EDIT"]),
    async apply() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };
      await apply_access(params).then(() => {
        this.$message.success(this.$t("knowledge.ViewAccessRequested"));
      });
    },
    //绑定中间的拉伸条
    bindMoveLine() {
      const resizeFn = (element, position) => {
        let maxAllowWidth = window.innerWidth / 2;
        let resize = document.querySelector(".resize");
        if (resize) {
          resize.style[position] = "0";
          const that_ = this;
          resize.addEventListener("mousedown", function (e) {
            let startX = e.pageX;
            let width = element.offsetWidth;
            resize.classList.add("active");
            document.addEventListener("mousemove", move);
            let timer = null;
            const leftMenu = that_.$refs.leftMenu;

            function move(e) {
              let moveX; // 左右这里切换计算顺序即可
              if (position === "left") moveX = startX - e.pageX;
              if (position === "right") moveX = e.pageX - startX;
              clearTimeout(timer);
              timer = setTimeout(() => {
                // element.style.maxWidth = width + moveX + "px";
                if (maxAllowWidth >= width + moveX) {
                  if (width + moveX <= 150) {
                    leftMenu.showToolPopoverBox = true;
                  } else {
                    leftMenu.showToolPopoverBox = false;
                  }
                  element.style.width = width + moveX + "px";
                  element.style.minWidth = width + moveX + "px";
                }
              }, 5);
            }

            document.addEventListener(
              "mouseup",
              function () {
                document.removeEventListener("mousemove", move);
                resize.classList.remove("active");
              },
              { once: true }
            );
          });
        }
      };
      let left = document.querySelector(".left-wrap");
      resizeFn(left, "right");
    },
    // 收起展开折叠事件
    isCollapse(flag) {
      if (flag) {
        document.querySelector(".left-wrap").style.minWidth = "30px";
        document.querySelector(".left-wrap").style.width = "30px";
      } else {
        document.querySelector(".left-wrap").style.minWidth = "300px";
        document.querySelector(".left-wrap").style.width = "300px";
      }
    },
    // filterRight(id) {
    //   this.$refs.rightComment.filterRight(id);
    // },
    // getComments() {
    //   this.$refs.rightComment.getComments();
    // },
    // 更新文章大纲
    catalogueUpdate(params) {
      this.catalogueData = params;
    },
    // 右侧评论
    // commentinline(list) {
    //   this.rightCommentList = list;
    // },
    draggableFn() {
      this.draggable = false;
    },
    checked(node) {
      this.authList = [];
      this.completeGetSetting = false;
      this.checkedNode = node;
      node ? this.getSetting() : "";
    },
    getSetting() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };
      getAuth(params).then((res) => {
        this.authList = res;
        this.completeGetSetting = true;
      });
    },
    getAuthFn() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };
      getAuth(params).then((res) => {
        this.authList = res;
      });
    },
    getDocuments(callBack) {
      const params = {
        projectId: this.get_pid(),
      };
      getDocuments(params).then((res) => {
        this.menuList = res;
        this.$nextTick(() => {
          callBack ? callBack() : "";
          this.$emit("hideLoading");
        });
      });
    },
    deleteDoc() {
      const params = {
        projectId: this.get_pid(),
      };
      getDocuments(params).then((res) => {
        // 查找两次的删掉的对象 进行删除 不会重新渲染左菜单
        const idList = [];
        const buildIdList = (list) => {
          list.forEach((item) => {
            idList.push(item.documentId);
            item.children ? buildIdList(item.children) : "";
          });
        };
        const deleteObj = (list) => {
          for (let i = 0; i < list.length; i++) {
            if (idList.indexOf(list[i].documentId) === -1) {
              list.splice(i, 1);
              return;
            }
            list[i].children ? deleteObj(list[i].children) : "";
          }
        };
        buildIdList(res);
        deleteObj(this.menuList);
        this.checkedNode = null;
      });
    },
  },
  provide: function () {
    return {
      menuList: () => {
        return this.menuList;
      },
    };
  },
};
</script>

<style scoped lang="scss">
/*拖拽区div样式*/
.resize {
  cursor: e-resize;
  min-width: 6px; /*no*/
  font-size: 32px;
  user-select: none;
}

.resize.active {
  background-color: #0090f1;
}

.resize:hover {
  background-color: #0090f1;
}

.home-wrap {
  display: flex;
  justify-content: left;
  width: 100%;
  height: 100%;

  .left-wrap {
    height: 100%;
    width: 300px;
    min-width: 300px;
  }

  .edit-wrap {
    height: 100%;
    //flex: 1 1 auto;
    flex: 1;
    overflow-y: hidden;

    .no-pr {
      width: 100%;
      height: 100%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      & > p {
        font-size: 20px;
        color: rgba(128, 128, 128, 1);
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 8px;
      background-color: #ebeef5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(207, 207, 207, 1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: rgba(255, 255, 255, 1);
    }
  }
}
</style>
