<template>
  <div
    v-loading="loading"
    :element-loading-text="$t('loading.loading5')"
    style="height: 100%"
  >
    <release-default-vue
      v-if="!cur_test_id"
      :type="'testPlan'"
      @createClick="open_new_test_dialog"
    >
    </release-default-vue>
    <div
      v-if="cur_test_id"
      style="height: 100%; display: flex; flex-direction: column"
    >
      <!-- 头部选择当前测试计划和按钮组 -->
      <div class="header">
        <div v-show="!is_edit && !is_excecuting" class="header__test-select">
          <el-select
            v-model="cur_test_id"
            size="small"
            filterable
            popper-class="select_own"
            @change="change_test_plan"
          >
            <el-option
              :key="'add_new'"
              class="test-add-option"
              :value="cur_test_id"
            >
              <div style="width: 100%">
                <div
                  v-limits-of-authority="'TEST_PLAN_CREATE_EDIT'"
                  style="width: 100%; display: flex; justify-content: center"
                  class="test-add"
                  @click="is_dialog_visible = true"
                >
                  <i class="iconfont icon-add"></i>
                </div>
              </div>
            </el-option>
            <el-option
              v-for="item in test_options"
              :key="item.testPlanId"
              :label="item.name_display"
              :value="item.testPlanId"
            >
            </el-option>
          </el-select>
        </div>
        <div v-show="is_edit" class="header__test-edit">
          <el-input
            v-show="true"
            v-model="test_name"
            size="small"
            @blur="edit_test_plan"
          >
          </el-input>
          <span
            v-show="false"
            style="font-size: 16px; color: rgba(166, 166, 166, 1)"
            >{{ test_name }}</span
          >
        </div>
        <!-- 底部执行按钮 -->
        <div v-if="!is_edit && !is_excecuting" class="excecute">
          <el-button type="primary" size="small" @click="enter_excecute_mode">
            <i
              class="iconfont"
              :class="
                test_status == 'COMPLETE' ? 'icon-a-left_arrow1' : 'icon-fasong'
              "
              style="margin-right: 5px"
            ></i
            >{{
              test_status == "COMPLETE"
                ? $t("btn.returnExeBtn")
                : $t("btn.executeBtn")
            }}
          </el-button>
        </div>
        <div v-show="is_excecuting" class="header__test-edit">
          <span style="font-size: 16px; color: rgba(166, 166, 166, 1)">{{
            test_name
          }}</span>
        </div>
        <div v-show="!is_edit && !is_excecuting" class="header__btns">
          <span v-if="test_status == 'COMPLETE'" style="margin-right: 10px">
            <span v-limits-of-authority="'TEST_PLAN_EXPORT'">
              <el-button
                :disabled="limits_of_authority_disable('TEST_PLAN_EXPORT')"
                type="info"
                size="small"
                @click="export_pdf"
              >
                <i
                  class="iconfont icon-export_primary"
                  style="margin-right: 5px"
                ></i
                >{{ $t("btn.exportBtn") }}
              </el-button>
            </span>
          </span>
          <span v-if="isCoraxAvailable && hasCorax">
            <el-button
              type="info"
              :disabled="!isCoraxAvailable"
              size="small"
              style="margin-right: 10px"
              @click="coraxDialog = true"
            >
              {{ $t("corax.ViewCoraxReport") }}
            </el-button>
          </span>
          <span
            v-if="
              isCedarAvailable &&
              hasCedar &&
              ($store.state.project.tenantId ===
                '586ee243-5501-4712-bf0b-0e36b5ea2ff6' ||
                $store.state.project.tenantId ===
                  '7db3b28d-c80f-4fbe-895e-6209c6c4362b' ||
                $store.state.project.tenantId ===
                  '91fee1e5-8b83-4b57-bb77-6903d7e1361d')
            "
          >
            <el-button
              type="info"
              :disabled="!isCedarAvailable"
              size="small"
              style="margin-right: 10px"
              @click="cedarDialog = true"
            >
              {{ $t("corax.ViewCedarReport") }}
            </el-button>
          </span>
          <span
            v-if="
              isCedarAvailable &&
              hasCedar &&
              ($store.state.project.tenantId ===
                '586ee243-5501-4712-bf0b-0e36b5ea2ff6' ||
                $store.state.project.tenantId ===
                  '7db3b28d-c80f-4fbe-895e-6209c6c4362b' ||
                $store.state.project.tenantId ===
                  '91fee1e5-8b83-4b57-bb77-6903d7e1361d')
            "
            style="width: 100px; display: inline-block"
          >
            <el-button size="small" type="primary" @click="openCedarDialog">{{
              $t("corax.upload")
            }}</el-button>
          </span>
          <!-- ceader开始 -->
          <el-dialog
            :visible.sync="uploadCedarDialog"
            width="30%"
            style="text-align: left"
            :close-on-click-modal="false"
            :show-close="true"
          >
            <el-form
              label-width="120px"
              label-position="left"
              :model="uploadCedar"
            >
              <el-form-item label="gitProjectId">
                <el-input v-model="uploadCedar.gitProjectId"></el-input>
              </el-form-item>
              <el-form-item label="branch ">
                <el-input v-model="uploadCedar.branch"></el-input>
              </el-form-item>
              <el-form-item>
                <el-upload
                  action=""
                  :http-request="upload_attachment_handler"
                  :show-file-list="true"
                  :file-list="cedarFileList"
                >
                  <el-button size="small" type="primary">{{
                    $t("nodeDetail.description.info2")
                  }}</el-button>
                </el-upload>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button type="info" @click="uploadCedarDialog = false"
                >{{ $t("btn.cancelBtn") }}
              </el-button>
              <el-button
                type="primary"
                size="small"
                class="button-confirm"
                @click="uploadCedarDialogComfirm"
                >{{ $t("btn.confirmBtn") }}
              </el-button>
            </span>
          </el-dialog>
          <el-dialog
            :visible.sync="cedarDialog"
            class="dialogClass"
            width="80%"
            style="text-align: left"
            :title="$t('corax.CedarReports')"
            :close-on-click-modal="false"
            :show-close="true"
          >
            <div
              class="test-nodes corax-container"
              style="
                flex: 1;
                overflow-y: scroll;
                max-height: 600px;
                overflow-x: scroll;
                margin-bottom: 20px;
              "
            >
              <el-table
                v-loading="isCedarLoading"
                :data="cedarReport"
                element-loading-background="rgba(0, 0, 0, 0.5)"
                :element-loading-text="$t('loading.loading3')"
                element-loading-spinner="el-icon-loading"
                :header-cell-style="{
                  color: '#383838',
                  'font-weight': '700',
                }"
                :cell-style="set_test_cell_style"
                @row-click="openCedarReport"
              >
                <el-table-column
                  prop="gitProjectName"
                  :label="$t('cedar.gitProjectName')"
                  align="left"
                  header-align="left"
                  min-width="230"
                >
                  <template slot-scope="scope">
                    <span style="color: rgb(0, 0, 238)">
                      {{ scope.row.gitProjectName }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="branch"
                  :label="$t('cedar.branch')"
                  align="left"
                  header-align="left"
                  min-width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="createdBy"
                  :label="$t('cedar.TriggeredBy')"
                  align="left"
                  header-align="left"
                  min-width="200"
                >
                  <template slot-scope="scope">
                    <div>{{ matchUserNickname(scope.row.createdBy) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createdAt"
                  :label="$t('cedar.time')"
                  align="left"
                  header-align="left"
                  min-width="200"
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.createdAt }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-dialog>
          <el-dialog
            :visible.sync="cedarTestRunDialog"
            class="dialogClass"
            width="80%"
            style="text-align: left"
            :title="$t('corax.CedarDetailReports')"
            :close-on-click-modal="false"
            :show-close="true"
          >
            <div
              class="test-nodes corax-container"
              style="
                flex: 1;
                overflow-y: scroll;
                max-height: 600px;
                overflow-x: scroll;
                margin-bottom: 20px;
                cursor: auto;
              "
            >
              <el-table
                v-loading="isCedarLoading"
                :data="selectedCedarRow"
                element-loading-background="rgba(0, 0, 0, 0.5)"
                :element-loading-text="$t('loading.loading3')"
                element-loading-spinner="el-icon-loading"
                :header-cell-style="{
                  color: '#383838',
                  'font-weight': '700',
                }"
                :cell-style="set_test_cell_style"
              >
                <el-table-column
                  :label="$t('cedar.Uncovered') + 'commit ID'"
                  align="left"
                  header-align="left"
                  min-width="160"
                >
                  <template slot-scope="scope">
                    <a
                      :href="
                        selectedCedarGitProjectWebUrl +
                        '/-/commit/' +
                        scope.row.commit.id
                      "
                      target="_blank"
                      >{{ scope.row.commit.id.substring(0, 8) }}...</a
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="Commit message"
                  align="left"
                  header-align="left"
                  min-width="300"
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.commit.message }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('cedar.submitter')"
                  align="left"
                  header-align="left"
                  min-width="100"
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.commit.committer_name }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('cedar.SubmissionTime')"
                  align="left"
                  header-align="left"
                  min-width="200"
                >
                  <template slot-scope="scope">
                    <div>{{ formatDate(scope.row.commit.committed_date) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="left"
                  header-align="left"
                  min-width="200"
                >
                  <template slot="header" slot-scope="scope">
                    <span>{{
                      $t("cedar.AssociatedSourceCodeFilesAndLineCount")
                    }}</span>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <p
                        v-for="(item, index) in scope.row.fileLinesDetailList"
                        :key="index"
                      >
                        {{ item.filePath }}({{ item.lines.join(",") }})
                      </p>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('cedar.RelatedNode')"
                  align="left"
                  header-align="left"
                  min-width="200"
                >
                  <template slot-scope="scope">
                    <div>
                      <a
                        v-for="(item, index) in scope.row.nodeKeyList"
                        :key="index"
                        :href="'/' + get_pid() + '/nodes/key/' + item"
                        target="_blank"
                      >
                        {{ item }}
                        <span v-if="index !== scope.row.nodeKeyList.length - 1"
                          >,</span
                        >
                      </a>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-dialog>
          <!-- cedar结束 -->
          <el-dialog
            :visible.sync="coraxDialog"
            class="dialogClass"
            width="80%"
            style="text-align: left"
            :title="$t('corax.CoraxReports')"
            :close-on-click-modal="false"
            :show-close="true"
          >
            <div
              class="test-nodes corax-container"
              style="
                flex: 1;
                overflow-y: scroll;
                max-height: 600px;
                overflow-x: scroll;
                margin-bottom: 20px;
              "
            >
              <!--Corax-->
              <el-table
                v-loading="isCoraxLoading"
                :data="coraxReport"
                element-loading-background="rgba(0, 0, 0, 0.5)"
                :element-loading-text="$t('loading.loading3')"
                element-loading-spinner="el-icon-loading"
                :header-cell-style="{
                  color: '#383838',
                  'font-weight': '700',
                }"
                :cell-style="set_cell_style"
                @row-click="openRowReport"
              >
                <el-table-column
                  prop="task_name"
                  :label="$t('corax.ScanningTasks')"
                  align="left"
                  header-align="left"
                  min-width="200"
                >
                </el-table-column>

                <el-table-column
                  prop="standards"
                  :label="$t('corax.ScanningCriteria')"
                  align="left"
                  header-align="left"
                  min-width="200"
                >
                </el-table-column>

                <el-table-column
                  prop="bug_number"
                  :label="$t('corax.DefectDistribution')"
                  align="left"
                  header-align="left"
                  min-width="300"
                >
                  <template slot-scope="scope">
                    <div class="custom-tag high">
                      <span class="kind">{{ $t("corax.High") }}</span>
                      <span class="number">{{
                        scope.row.bug_number.high
                      }}</span>
                    </div>
                    <div class="custom-tag medium">
                      <span class="kind">{{ $t("corax.Medium") }}</span>
                      <span class="number">{{
                        scope.row.bug_number.medium
                      }}</span>
                    </div>
                    <div class="custom-tag low">
                      <span class="kind">{{ $t("corax.Low") }}</span>
                      <span class="number">{{ scope.row.bug_number.low }}</span>
                    </div>
                    <div class="custom-tag info">
                      <span class="kind">{{ $t("corax.Note") }}</span>
                      <span class="number">{{
                        scope.row.bug_number.info
                      }}</span>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="end_time"
                  :label="$t('corax.LastUpdated')"
                  align="left"
                  header-align="left"
                  min-width="200"
                >
                  <template slot-scope="scope">
                    <div>{{ formatDate(scope.row.end_time) }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-dialog>
          <el-dialog
            :visible.sync="rowDialog"
            class="dialogClass"
            width="70%"
            :title="$t('corax.ReportingChart')"
            :close-on-click-modal="false"
            :show-close="true"
          >
            <CoraxReport
              style="overflow-y: scroll; max-height: 700px; margin-bottom: 20px"
              :row-data="selectedRow"
            />
          </el-dialog>
          <span v-limits-of-authority="'TEST_PLAN_CREATE_EDIT'">
            <el-button
              type="info"
              :disabled="limits_of_authority_disable('TEST_PLAN_CREATE_EDIT')"
              size="small"
              style="margin-right: 10px"
              @click="
                copy_form_visiable = true;
                copy_form.plan_name = '';
              "
            >
              <i class="iconfont icon-copy" style="margin-right: 5px"></i
              >{{ $t("btn.copyBtn") }}
            </el-button>
          </span>
          <el-dialog
            :visible.sync="copy_form_visiable"
            class="dialogClass"
            width="25%"
            style="text-align: left"
            :title="$t('test.topBar.pasteTestForm.title')"
            :close-on-click-modal="false"
            :show-close="true"
          >
            <el-form
              ref="ruleForm"
              :rules="rules"
              label-position="left"
              :model="copy_form"
              label-width
            >
              <el-form-item prop="plan_name" label-width="130px">
                <template slot="label">
                  <span style="font-size: 14px; font-weight: 400">
                    {{ $t("test.topBar.pasteTestForm.name") }}</span
                  >
                </template>
                <el-input v-model="copy_form.plan_name"></el-input>
              </el-form-item>
              <p
                style="
                  width: 324px;
                  height: 18px;
                  font-size: 12px;
                  color: rgba(166, 166, 166, 1);
                "
              >
                {{ $t("test.topBar.pasteTestForm.note") }}
              </p>
            </el-form>
            <span slot="footer">
              <el-button @click="copy_testPlan_cancel()">{{
                $t("btn.cancelBtn")
              }}</el-button>
              <el-button type="primary" @click="copy_testPlan()">{{
                $t("btn.newBtn")
              }}</el-button>
            </span>
          </el-dialog>
          <div style="display: inline-block">
            <span v-limits-of-authority="'TEST_PLAN_CREATE_EDIT'">
              <el-button
                :disabled="limits_of_authority_disable('TEST_PLAN_CREATE_EDIT')"
                type="primary"
                size="small"
                @click="edit_click"
              >
                <i
                  class="iconfont icon-branch_edit"
                  style="margin-right: 5px"
                ></i
                >{{ $t("btn.editBtn") }}
              </el-button>
            </span>
          </div>
        </div>
        <div v-show="is_edit" class="header__btns-edit">
          <div v-show="is_auto_save">
            <i class="iconfont icon-refresh" style="margin-right: 5px"> </i>
            <span
              style="
                margin-right: 10px;
                font-size: 12px;
                color: rgba(166, 166, 166, 1);
              "
              >{{ $t("baseline.topBar.autoSave") }}</span
            >
          </div>
          <div style="display: inline-block; margin-right: 10px">
            <span v-limits-of-authority="'TEST_PLAN_DELETE'">
              <el-button
                :disabled="limits_of_authority_disable('TEST_PLAN_DELETE')"
                type="info"
                size="small"
                @click="delete_test_plan_click"
              >
                <i class="iconfont icon-delete" style="margin-right: 5px"></i
                >{{ $t("btn.deleteBtn") }}
              </el-button>
            </span>
          </div>
          <el-button type="info" size="small" @click="exit_edit_click">
            <i class="iconfont icon-exit" style="margin-right: 5px"></i
            >{{ $t("btn.exitEditBtn") }}
          </el-button>
        </div>
        <div v-show="is_excecuting" class="header__btns-edit">
          <span style="margin-right: 10px">
            <span v-limits-of-authority="'TEST_PLAN_EXPORT'">
              <el-button
                :disabled="limits_of_authority_disable('TEST_PLAN_EXPORT')"
                type="info"
                size="small"
                @click="exportresult"
              >
                <i class="el-icon-upload2" style="margin-right: 5px"></i
                >{{ $t("test.export.ExportResults") }}
              </el-button>
            </span>
          </span>
          <span style="margin-right: 10px">
            <span
              v-limits-of-authority="'TEST_PLAN_EXPORT'"
              style="display: inline-block"
            >
              <el-upload
                action=""
                :http-request="upload_result_attachment_handler"
                :show-file-list="false"
              >
                <el-button
                  :disabled="limits_of_authority_disable('TEST_PLAN_EXPORT')"
                  type="info"
                  size="small"
                >
                  <i class="el-icon-download" style="margin-right: 5px"></i
                  >{{ $t("test.export.ImportResults") }}
                </el-button>
              </el-upload>
            </span>
          </span>
          <el-button type="primary" size="small" @click="out_excecuting">
            <i class="iconfont icon-exit" style="margin-right: 5px"></i
            >{{ $t("btn.exitExecuteBtn") }}
          </el-button>
        </div>
      </div>

      <!-- 测试计划的基本信息 -->
      <test-info-vue
        ref="test-info"
        :test_plan="cur_test_plan"
        :is_auto_save.sync="is_auto_save"
        :is_edit="is_edit"
        :test_status="test_status"
        :is_excecuting="is_excecuting"
        @auto-save="get_test_plans_without_change_id"
        @change_versions="get_relate_versions"
        @get_current_version="get_current_version"
        @refresh_table_data="refresh_table_data"
      >
      </test-info-vue>

      <!-- 测试计划节点相关的信息 -->
      <div
        v-if="show_main"
        style="flex: 1"
        :style="{
          overflow:
            test_status === 'COMPLETE' && !is_excecuting ? 'scroll' : 'hidden',
        }"
      >
        <!-- 执行状态下 -->
        <test-excecuting-mode-vue
          v-if="is_excecuting"
          ref="test-excecuting"
          :cur_test_id="cur_test_id"
          :test_status="test_status"
          :update_node="drawer"
          @show_drawer="show_drawer"
        >
        </test-excecuting-mode-vue>

        <!-- 未开始 -->
        <test-not-start-vue
          v-else-if="test_status == 'NOT_STARTED'"
          ref="not_start"
          :cur_test_id="cur_test_id"
          :is_edit="is_edit"
          :relate_versions="relate_versions"
          :update_node="drawer"
          @show_drawer="show_drawer"
        >
        </test-not-start-vue>

        <!-- 进行中 -->
        <test-in-process-vue
          v-else-if="test_status == 'IN_PROGRESS'"
          ref="in_progress"
          :cur_test_id="cur_test_id"
          :is_edit="is_edit"
          :relate_versions="relate_versions"
          :corax_report="coraxReport"
          :update_node="drawer"
          @show_drawer="show_drawer"
        >
        </test-in-process-vue>
        <!-- 已完成 -->
        <test-complete-vue
          v-else-if="test_status == 'COMPLETE'"
          ref="test-complete"
          :cur_test_id="cur_test_id"
          :is_edit="is_edit"
          :row-data="lastRow"
          :has-corax="isCoraxAvailable"
          :update_node="drawer"
          :exporting="exporting"
          @hide_test_report="hide_test_report"
          @show_drawer="show_drawer"
        >
        </test-complete-vue>
      </div>
    </div>
    <!-- 新建测试计划弹窗 -->
    <el-dialog
      :visible.sync="is_dialog_visible"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">
        <span class="title-text">{{ $t("test.addTestForm.title") }}</span>
      </div>
      <div class="dialog-content">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="130px"
          label-position="left"
        >
          <el-form-item prop="name">
            <template slot="label">
              <span>{{ $t("test.addTestForm.name") }}</span>
            </template>
            <el-input
              v-model="ruleForm.name"
              size="small"
              :maxlength="255"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('test.addTestForm.baseline')"
            prop="baseline"
          >
            <el-select
              v-model="ruleForm.baseline"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
              size="small"
              style="width: 100%"
              :loading="ruleForm.baseline_options_loading"
              @visible-change="get_baseline_options"
            >
              <el-option
                v-for="item in ruleForm.baseline_options"
                :key="item.baselineId"
                :label="item.name"
                :value="item.baselineId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('test.addTestForm.version')" prop="version">
            <el-select
              v-model="ruleForm.version"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
              size="small"
              style="width: 100%"
              :loading="ruleForm.version_options_loading"
              @visible-change="get_version_options"
            >
              <el-option
                v-for="item in ruleForm.version_options"
                :key="item.versionId"
                :label="item.name"
                :value="item.versionId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="is_dialog_visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="create_test_plans_click">{{
          $t("btn.newBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      v-loading="export_loading"
      :element-loading-text="$t('loading.loading5')"
      :title="$t('test.export.title')"
      :visible.sync="exprot_dialog_visible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      @close="export_beforeclose"
    >
      <el-form
        ref="export_form"
        :rules="export_rules"
        :model="export_form"
        label-width="130px"
        label-position="left"
      >
        <el-form-item :label="$t('test.export.name')" prop="name">
          <el-input v-model="export_form.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('test.export.project')" prop="checked">
          <div class="check_box_wrap">
            <div>
              <el-checkbox
                v-model="export_form.checkedall"
                :indeterminate="isIndeterminate"
                @change="handleCheckAllChange"
              >
                {{ $t("test.export.all") }}</el-checkbox
              >
            </div>
            <div>
              <el-checkbox-group
                v-model="export_form.checked"
                @change="handleCheckedChange"
              >
                <el-checkbox
                  v-for="item in checked_options"
                  :key="item"
                  :label="item"
                  >{{ item }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            exprot_dialog_visible = false;
            exporting = false;
          "
          >{{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button type="primary" @click="confirm_exprot">{{
          $t("btn.exportBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 导出excel弹窗 -->
    <el-dialog
      :title="$t('file.table.options.dialog.title')"
      width="25%"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
      custom-class="excel_export_dialog"
    >
      <div
        style="
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 20px;
        "
      >
        <div
          style="
            margin-right: 20px;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            color: gray;
          "
        >
          {{ $t("btn.export_tem") }}
        </div>
        <el-select
          v-model="template"
          :placeholder="$t('placeholder.dropdown')"
          clearable
        >
          <el-option
            v-for="item in tem_options"
            :key="item.templateId"
            :label="item.name"
            :value="item.templateId"
          >
          </el-option>
        </el-select>
      </div>
      <el-checkbox v-model="all_fields_item" @change="judge_all">{{
        $t("file.table.options.dialog.box1")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("nodeDetail.testStep.title")
      }}</el-checkbox>
      <el-checkbox-group
        v-model="submit_list"
        text-color=" rgba(66, 159, 255, 1)"
      >
        <el-checkbox
          v-for="(item, index) in checkList"
          v-show="item.fieldId !== 'test_details'"
          :key="index"
          :label="item.fieldId"
          >{{ item.label }}</el-checkbox
        >
      </el-checkbox-group>
      <span slot="footer">
        <el-button @click="closeDialog">{{
          $t("file.table.options.dialog.box5")
        }}</el-button>
        <el-button
          type="primary"
          v-loading="exportLoading"
          @click="submit_Excel"
          >{{ $t("file.table.options.dialog.box6") }}</el-button
        >
      </span>
    </el-dialog>
    <NodeDrawer
      :drawer.sync="drawer"
      :current_node="current_node"
      :file_icon_name="file_icon_name"
      :drawer_loading="drawer_loading"
      :file_icon="file_icon"
      :members-show="membersShow"
      :members="members"
      :show-test-plan="showTestPlan"
      @close="before_close_drawer"
      @loading-finished="drawer_loading = false"
      @update_description_content="update_description_content"
      @update_current_node="update_current_node"
    />
  </div>
</template>

<script>
import html2Canvas from "html2canvas";
import { mapGetters } from "vuex";
import JsPDF from "jspdf";
import { limits_of_authority_disable } from "@/utils/limits";
import ReleaseDefaultVue from "@/components/release";
import TestInfoVue from "./components/TestInfo.vue";
import TestNotStartVue from "./components/TestNotStart.vue";
import TestInProcessVue from "./components/TestInProcess.vue";
import TestExcecutingModeVue from "./components/TestExcecutingMode.vue";
import TestCompleteVue from "../testComplete";
import CoraxReport from "@/components/coraxReport";
import {
  get_test_plans,
  create_test_plans,
  delete_test_plan,
  edit_test_plan,
  enter_process,
  copy_test_plan,
  pdfFile,
  exportTestResult,
  importTestResult,
} from "@/network/testPlan/index.js";
import { getCoraxConfig, getCoraxReportList } from "@/network/corax";
import {
  getcedarConfig,
  getcedarReportList,
  updateCedarReportList,
} from "@/network/cedar";
import { mapActions } from "vuex";
import NodeDrawer from "@/components/nodeDrawer";
import {
  get_filetype_List,
  all_fields_get,
  getSystemFields,
} from "@/network/fileType/index.js";
import { inProgressBaselines } from "@/network/merge/index";
import { get_node_use_node_key } from "@/network/node/index.js";
import { get_excel_field } from "@/network/exportExcel/index.js";
import { get_templates } from "@/network/template/index.js";
// 临时
import { ossAuthorization } from "@/utils/oss";
import { downloadLink } from "@/utils/download.js";
export default {
  name: "TestPlan",
  components: {
    TestInfoVue,
    TestNotStartVue,
    TestInProcessVue,
    TestExcecutingModeVue,
    TestCompleteVue,
    ReleaseDefaultVue,
    CoraxReport,
    NodeDrawer,
  },
  data() {
    return {
      show_main: true,
      // 复制弹窗表单数据
      copy_form: {
        plan_name: "",
      },

      //复制测试计划弹窗
      copy_form_visiable: false,
      null_show: false,
      has_testPlan: true,
      //页面初始化加载
      loading: true,
      // 导出弹窗loading
      export_loading: false,
      // 当前测试计划
      cur_test_id: "",
      // 测试计划下拉框中的值
      test_options: [],
      // 页面是否在编辑
      is_edit: false,
      // 是否处于执行模式
      is_excecuting: false,
      // 当前测试计划名
      test_name: "",
      // 是否打开新建测试计划弹窗
      is_dialog_visible: false,
      // 导出设置弹窗
      exprot_dialog_visible: false,
      // 导出相关数据
      export_form: {
        name: "",
        checked: [],
        checkedall: false,
      },
      checked_options: [
        this.$t("test.checked_options.bug"),
        this.$t("test.checked_options.tesecase"),
        this.$t("test.checked_options.coverage"),
        this.$t("test.checked_options.result"),
        this.$t("test.checked_options.detailBug"),
        this.$t("test.checked_options.api"),
      ],
      export_rules: {
        name: [
          {
            required: true,
            message: this.$t("test.message.info1"),
            trigger: "blur",
          },
        ],
        checked: [
          {
            required: true,
            message: this.$t("test.message.info2"),
            trigger: "blur",
          },
        ],
      },
      // 创建测试计划弹窗
      ruleForm: {
        name: "",
        baseline: [],
        baseline_options_loading: false,
        baseline_options: [],
        version: [],
        version_options_loading: false,
        version_options: [],
      },
      isIndeterminate: true,
      // 创建测试计划弹窗的规则
      rules: {
        name: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "blur",
          },
        ],
      },
      // 是否正在自动保存
      is_auto_save: false,
      //关联版本
      relate_versions: [],
      coraxReport: [],
      cedarReport: [],
      isCoraxAvailable: false,
      isCedarAvailable: false,
      coraxDialog: false,
      cedarDialog: false,
      uploadCedarDialog: false,
      cedarTestRunDialog: false,
      rowDialog: false,
      selectedRow: null,
      selectedCedarRow: null,
      selectedCedarGitProjectWebUrl: null,
      lastRow: null,
      isCoraxLoading: false,
      isCedarLoading: false,
      drawer: false,
      drawer_loading: false,
      workflow_dialog_visible: false,
      input_name: "",
      is_show_name: true,
      current_node: {},
      file_icon_name: "",
      file_icon: "",
      // 是否导出中
      exporting: false,
      uploadCedar: {
        gitProjectId: "",
        branch: "",
        gapFilePath: "",
      },
      cedarFileList: [],
      // 导出测试计划结果弹窗
      exportLoading: false,
      dialogVisible: false,
      tem_options: [],
      template: "",
      all_fields_item: false,
      submit_list: [],
      checkList: [],
      must_check: true,
    };
  },
  computed: {
    ...mapGetters([
      "execution_status",
      "testPlan",
      "fileType",
      "project_user_list",
    ]),
    cur_test_plan() {
      return this.test_options.find(
        (item) => item.testPlanId == this.cur_test_id
      );
    },
    //  当前测试计划名
    test_status() {
      if (this.cur_test_plan) {
        return this.cur_test_plan.status;
      }
      return "";
    },
    hasEoLink() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name.toLowerCase() === "eolink"
      );
    },
    hasCorax() {
      return this.$store.getters.project_third_apps.some(
        (item) => item.name.toLowerCase() === "corax"
      );
    },
    hasCedar() {
      return true;
      // return this.$store.getters.project_third_apps.some(
      //   (item) => item.name.toLowerCase() === "cedar"
      // );
    },
    showTestPlan() {
      if (this.current_node && this.current_node.fileTypeId && this.fileType) {
        return this.fileType[this.current_node.fileTypeId]
          ? this.fileType[this.current_node.fileTypeId].testCase
          : false;
      }
      return false;
    },
    members() {
      return Object.values(this.project_user_list);
    },
    membersShow() {
      return this.members.length ? true : false;
    },
  },
  watch: {
    testPlan: function () {
      this.test_options = Object.values(
        JSON.parse(sessionStorage.getItem("_testPlan"))
      );
      this.test_options.forEach((item) => {
        if (item.status == "NOT_STARTED") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.notStarted") +
            ")";
        } else if (item.status == "IN_PROGRESS") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.inProgress") +
            ")";
        } else if (item.status == "COMPLETE") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.completed") +
            ")";
        }
      });
      const cur_test = this.test_options.find(
        (item) => item.testPlanId === sessionStorage.getItem("test_plan_id")
      );
      if (cur_test) {
        this.cur_test_id = cur_test.testPlanId;
      } else if (this.test_options[0]) {
        this.cur_test_id = this.test_options[0].testPlanId;
      }
      if (!this.test_options.length) {
        this.cur_test_id = "";
      }
      this.loading = false;
    },
    cur_test_id: function () {
      // 存入sessionStorage
      this.show_main = false;
      sessionStorage.setItem("test_plan_id", this.cur_test_id);
      setTimeout(() => {
        this.show_main = true;
      });
      this.$router.push({
        name: "test",
        params: { cur_id: this.cur_test_id },
      });
      if (this.hasCorax) {
        this.getCoraxInfo();
      }
      if (this.hasCedar) {
        this.getCedarInfo();
      }
    },
    cur_test_plan: function () {
      if (this.cur_test_plan) {
        this.test_name = this.cur_test_plan.name;
      }
    },
  },
  created() {
    if (this.$route.params.testPlanId) {
      sessionStorage.setItem("test_plan_id", this.$route.params.testPlanId);
    } else if (this.$route.params.cur_id) {
      sessionStorage.setItem("test_plan_id", this.$route.params.cur_id);
    }
    const test_plan_id = sessionStorage.getItem("test_plan_id");
    this.$nextTick(() => {
      this.get_test_plans(test_plan_id);
    });
    !this.hasEoLink && this.hide_test_report();
  },
  mounted() {
    get_templates().then((res) => {
      this.tem_options = [
        ...res.filter((obj) => obj.exportTemplateType === "EXCEL"),
      ];
      this.template = "";
    });
  },
  methods: {
    ...mapActions(["setTestPlan"]),
    limits_of_authority_disable,
    // 导出测试结果
    exportresult() {
      const fileTypeIdList = [];
      const promiseList = [];
      this.$refs["test-excecuting"].nodes_list.forEach((node) => {
        if (fileTypeIdList.indexOf(node.fileTypeId) === -1) {
          fileTypeIdList.push(node.fileTypeId);
          promiseList.push(get_excel_field(this.get_pid(), node.fileTypeId));
        }
      });

      Promise.all([
        getSystemFields(),
        all_fields_get(this.get_pid()),
        ...promiseList,
      ]).then(
        (res) => {
          // 需展示字段
          this.excel_dialog_fields = {
            systemFieldsIdList: [],
            costomFieldsIdList: [],
          };
          for (let i = 2; i < 2 + promiseList.length; i++) {
            this.excel_dialog_fields.systemFieldsIdList.push(
              ...res[i].systemFieldsIdList
            );
            this.excel_dialog_fields.costomFieldsIdList.push(
              ...res[i].costomFieldsIdList
            );
          }
          // 所有系统字段
          this.systemFields_list = res[0] || [];
          // 所有自定义字段
          this.allFields_list = res[1] || [];

          // 筛选系统字段
          this.systemFields_list.forEach((item) => {
            if (
              this.excel_dialog_fields.systemFieldsIdList.indexOf(
                item.fieldId
              ) != -1
            ) {
              this.checkList.push({ label: item.name, fieldId: item.fieldId });
            }
          });

          // 筛选自定义字段
          this.allFields_list.forEach((item) => {
            if (
              this.excel_dialog_fields.costomFieldsIdList.indexOf(
                item.fieldId
              ) != -1
            ) {
              this.checkList.push({ label: item.name, fieldId: item.fieldId });
            }
          });

          this.dialogVisible = true;
        },
        (error) => {}
      );
    },
    judge_all(val) {
      if (val) {
        // 点击全选
        this.checkList.forEach((item) => {
          this.submit_list.push(item.fieldId);
        });
      } else {
        this.submit_list = [];
      }
    },
    closeDialog() {
      this.template = "";
      this.submit_list = [];
      this.all_fields_item = false;
      this.dialogVisible = false;
    },
    submit_Excel() {
      this.exportLoading = true;
      const params = {
        projectId: this.get_pid(),
        testPlanId: this.cur_test_id,
        data: {
          exportTemplateId: this.template,
          systemFieldsIdList: ["test_details"],
          costomFieldsIdList: [],
        },
      };
      this.systemFields_list.forEach((item) => {
        if (this.submit_list.indexOf(item.fieldId) != -1) {
          params.data.systemFieldsIdList.push(item.fieldId);
        }
      });
      this.allFields_list.forEach((item) => {
        if (this.submit_list.indexOf(item.fieldId) != -1) {
          params.data.costomFieldsIdList.push(item.fieldId);
        }
      });
      exportTestResult(params).then((res) => {
        downloadLink(res, true);
        this.exportLoading = false;
        this.dialogVisible = false;
      });
    },
    toProject(row) {
      window.open(row.gitProjectWebUrl);
    },
    // 临时上传文件
    async upload_attachment_handler(params) {
      //上传excel
      const self = this;
      let file = params.file;
      await ossAuthorization.call(self);
      self.client
        .put(`node_accessors/${file.name}`, file)
        .then((ossres) => {
          self.uploadCedar.gapFilePath = ossres.name;
          this.cedarFileList = [
            {
              name: file.name,
            },
          ];
        })
        .error((r) => {});
    },
    // 上传测试结果
    async upload_result_attachment_handler(params) {
      this.$refs["test-excecuting"].node_list_loading = true;
      //上传excel
      const self = this;
      let file = params.file;
      await ossAuthorization.call(self);
      self.client
        .put(`node_accessors/${file.name}`, file)
        .then((ossres) => {
          const params = {
            projectId: this.get_pid(),
            testPlanId: this.cur_test_id,
            data: {
              filePath: ossres.name,
            },
          };
          importTestResult(params).then((res) => {
            this.$refs["test-excecuting"].get_table_date();
          });
        })
        .error((r) => {});
    },
    openCedarDialog() {
      this.uploadCedar = {
        gitProjectId: "",
        branch: "",
        gapFilePath: "",
      };
      this.cedarFileList = [];
      this.uploadCedarDialog = true;
    },
    uploadCedarDialogComfirm() {
      const params = {
        projectId: this.get_pid(),
        data: this.uploadCedar,
      };
      updateCedarReportList(params).then((res) => {
        if (this.hasCedar) {
          this.getCedarInfo();
          this.uploadCedarDialog = false;
          this.$message({
            type: "success",
            message: "success",
          });
        }
      });
    },
    // 是否显示api测试报告复选框
    hide_test_report() {
      this.checked_options.length = 5;
    },
    export_beforeclose() {
      this.$refs["test-info"].des_max_height = "90px";
    },
    getCoraxInfo() {
      const params = {
        projectId: this.get_pid(),
      };
      this.isCoraxLoading = true;
      getCoraxConfig(params).then((res) => {
        if (res.testPlanId == this.cur_test_id) {
          this.isCoraxAvailable = true;
          getCoraxReportList(params).then((res2) => {
            this.coraxReport = res2;
            if (res2.length > 0) {
              this.lastRow = res2[0];
            }
          });
        } else {
          this.coraxReport = [];
          this.isCoraxAvailable = false;
          this.lastRow = null;
        }
      });
      this.isCoraxLoading = false;
    },
    getCedarInfo() {
      const params = {
        projectId: this.get_pid(),
      };
      this.isCedarLoading = true;
      getcedarConfig(params).then((res) => {
        if (res.testPlanId == this.cur_test_id) {
          this.isCedarAvailable = true;
          getcedarReportList(params).then((res2) => {
            this.cedarReport = res2.content;
          });
        } else {
          this.cedarReport = [];
          this.isCedarAvailable = false;
        }
      });
      this.isCedarLoading = false;
    },
    openRowReport(row) {
      this.rowDialog = true;
      this.selectedRow = row;
    },
    openCedarReport(row) {
      this.cedarTestRunDialog = true;
      this.selectedCedarRow = row.commitFileLinesList
        ? row.commitFileLinesList
        : [];
      this.selectedCedarGitProjectWebUrl = row.gitProjectWebUrl;
    },
    formatDate(date) {
      const d = new Date(date);
      return `${d.getFullYear()}-${
        d.getMonth() + 1
      }-${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
    },
    set_cell_style({ columnIndex }) {
      let style = "";
      if (columnIndex == 0) {
        style += "color:rgba(255, 195, 0, 1);font-weight: 700";
      } else {
        style += "color:'#636363';font-weight: 700";
      }
      return style;
    },
    set_test_cell_style({ columnIndex }) {
      let style = "";
      style += "color:'#636363';font-weight: 700";
      return style;
    },
    // 复选框单选事件
    handleCheckedChange(val) {
      let checkedCount = val.length;
      this.export_form.checkedall =
        checkedCount === this.checked_options.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.checked_options.length;
    },
    // 复选框全选事件
    handleCheckAllChange(val) {
      this.export_form.checked = val ? this.checked_options : [];
      this.isIndeterminate = false;
    },
    buildMutilCanvas() {},
    // 导出pdfdom 获取canvas方法
    get_canvas(dom) {
      return new Promise((resolve) => {
        const totalHeight = dom.scrollHeight;
        const maxheight = 10000;
        if (totalHeight > maxheight) {
          const numberOfCanvases = Math.ceil(dom.scrollHeight / maxheight);
          const canvases = [];
          const promiseList = [];
          for (let i = 0; i < numberOfCanvases; i++) {
            const topOffset = i * maxheight;
            promiseList.push(
              html2Canvas(dom, {
                allowTaint: false,
                useCORS: true, // allowTaint、useCORS只能够出现一个
                imageTimeout: 0,
                scale: 1, // 图片大小
                x: 0,
                y: topOffset,
                width: dom.scrollWidth,
                height:
                  i === numberOfCanvases - 1
                    ? totalHeight - topOffset
                    : maxheight,
              })
            );
          }
          Promise.all(promiseList).then((res) => {
            res.forEach((item) => {
              let pageData = item.toDataURL("image/jpeg", 1.0),
                canvasWidth = item.width,
                canvasHeight = item.height;
              canvases.push({
                pageData,
                canvasWidth,
                canvasHeight,
              });
              resolve(canvases);
            });
          });
        } else {
          html2Canvas(dom, {
            allowTaint: false,
            useCORS: true, // allowTaint、useCORS只能够出现一个
            imageTimeout: 0,
            scale: 1, // 图片大小
          }).then(function (canvas) {
            // 用于将canvas对象转换为base64位编码
            let pageData = canvas.toDataURL("image/jpeg", 1.0),
              canvasWidth = canvas.width,
              canvasHeight = canvas.height;
            resolve({
              pageData,
              canvasWidth,
              canvasHeight,
            });
          });
        }
      });
    },
    // 导出确认
    confirm_exprot() {
      this.export_loading = true;
      setTimeout(() => {
        this.$refs["export_form"].validate((valid) => {
          if (valid) {
            const defaultdom = this.$refs["test-info"].$el;
            const map = [
              this.$refs["test-complete"].$refs["bug-overview"].$el,
              this.$refs["test-complete"].$refs["test-caseview"].$el,
              this.$refs["test-complete"].$refs["coverage-report"].$el,
              this.$refs["test-complete"].$refs["test-result"].$el,
              this.$refs["test-complete"].$refs["bug-detail"].$el,
              this.$refs["test-complete"].$refs["api-test-result"]
                ? this.$refs["test-complete"].$refs["api-test-result"].$el
                : {},
            ];
            const promiseList = [];
            promiseList.push(this.get_canvas(defaultdom));
            this.export_form.checked.forEach((item) => {
              promiseList.push(
                this.get_canvas(map[this.checked_options.indexOf(item)])
              );
            });
            Promise.all(promiseList)
              .then((res) => {
                let PDF = new JsPDF("p", "pt", "", true); // 不预设页面大小，后续根据图片大小动态设置
                res.forEach((item, index) => {
                  if (Array.isArray(item)) {
                    item.forEach((page, index2) => {
                      let imgData = page.pageData;
                      let imgWidth = page.canvasWidth;
                      let imgHeight = page.canvasHeight;
                      // 设置PDF页面大小为图片大小
                      PDF.internal.pageSize.setWidth(imgWidth);
                      PDF.internal.pageSize.setHeight(imgHeight);
                      // 添加图片到PDF，图片完整占据一页
                      PDF.addImage(
                        imgData,
                        "JPEG",
                        0,
                        0,
                        imgWidth,
                        imgHeight,
                        undefined,
                        "FAST"
                      );
                      if (index2 < item.length - 1) {
                        PDF.addPage();
                      }
                    });
                  } else {
                    let imgData = item.pageData;
                    let imgWidth = item.canvasWidth;
                    let imgHeight = item.canvasHeight;
                    // 设置PDF页面大小为图片大小
                    PDF.internal.pageSize.setWidth(imgWidth);
                    PDF.internal.pageSize.setHeight(imgHeight);
                    // 添加图片到PDF，图片完整占据一页
                    PDF.addImage(
                      imgData,
                      "JPEG",
                      0,
                      0,
                      imgWidth,
                      imgHeight,
                      undefined,
                      "FAST"
                    );
                    // 如果不是最后一张图片，添加新页面
                    if (index < res.length - 1) {
                      PDF.addPage();
                    }
                  }
                });
                PDF.save(`${this.export_form.name}.pdf`);
                this.exporting = false;
                this.export_loading = false;
                this.exprot_dialog_visible = false;
              })
              .catch((err) => {
                this.exporting = false;
                this.export_loading = false;
                this.exprot_dialog_visible = false;
              });
          } else {
            this.export_loading = false;
            return false;
          }
        });
      }, 200);
    },
    // 导出pdf文件
    export_pdf() {
      this.$refs["test-info"].activeNames = ["1"];
      this.$refs["test-complete"].activeNames = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
      ];
      this.exprot_dialog_visible = true;
      this.exporting = true;
      this.$refs["test-info"].des_max_height = "max-content";
      this.export_form = {
        name: "",
        checked: [],
        checkedall: false,
      };
    },
    refresh_table_data() {
      if (this.test_status === "NOT_STARTED") {
        this.$refs["not_start"].get_test_nodes();
      } else {
        this.$refs["in_progress"].get_test_nodes();
      }
    },
    out_excecuting() {
      this.is_excecuting = false;
      // const test_plan_id = sessionStorage.getItem("test_plan_id");
      this.get_test_plans_without_change_id();
    },
    get_current_version(data) {},
    //获取关联版本
    get_relate_versions(data) {
      this.relate_versions = data;
    },
    change_test_plan(test_plan_id) {},
    //复制测试计划取消按钮
    copy_testPlan_cancel() {
      this.copy_form_visiable = false;
    },
    //复制测试计划确定按钮
    copy_testPlan() {
      const params = {
        projectId: this.get_pid(),
        testPlanId: this.cur_test_id,
        data: {
          name: this.copy_form.plan_name,
        },
      };
      copy_test_plan(params)
        .then((res) => {
          if (this.$route.params.testPlanId) {
            sessionStorage.setItem(
              "test_plan_id",
              this.$route.params.testPlanId
            );
          }
          const test_plan_id = sessionStorage.getItem("test_plan_id");
          this.get_test_plans(test_plan_id);
          this.$message({
            type: "success",
            message: this.$t("test.message.pasteSuccess"),
          });
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: this.$t("test.message.pasteError"),
          });
        });
      this.copy_form_visiable = false;
    },

    open_new_test_dialog() {
      this.is_dialog_visible = true;
    },
    // 点击编辑按钮
    edit_click() {
      this.is_edit = true;
    },
    // 点击退出编辑按钮
    exit_edit_click() {
      this.is_edit = false;
    },
    // 获得测试计划列表
    get_test_plans(test_plan_id) {
      this.setTestPlan(this.get_pid());
    },
    // 获得测试计划列表而不更新cur_test_id
    get_test_plans_without_change_id() {
      get_test_plans(this.get_pid()).then((res) => {
        this.test_options = [
          ...(res.NOT_STARTED || []),
          ...(res.IN_PROGRESS || []),
          ...(res.COMPLETE || []),
        ];
        const obj = {};
        const list = [
          ...(res.NOT_STARTED || []),
          ...(res.IN_PROGRESS || []),
          ...(res.COMPLETE || []),
        ];
        list.forEach((item) => {
          obj[item.testPlanId] = item;
        });
        this.$store.commit("setTestPlan", obj);
        this.test_options.forEach((item) => {
          if (item.status == "NOT_STARTED") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.notStarted") +
              ")";
          } else if (item.status == "IN_PROGRESS") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.inProgress") +
              ")";
          } else if (item.status == "COMPLETE") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.completed") +
              ")";
          }
        });
        this.is_auto_save = false;
      });
    },
    // 清理新建计划弹窗
    create_test_plans_clear() {
      this.ruleForm.name = "";
      this.ruleForm.baseline = [];
      this.ruleForm.baseline_options_loading = false;
      this.ruleForm.version = [];
      this.ruleForm.version_options_loading = false;
    },
    // 新建测试计划点击
    create_test_plans_click() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          create_test_plans(this.get_pid(), {
            name: this.ruleForm.name,
            baselineIds: this.ruleForm.baseline,
            versionIds: this.ruleForm.version,
          }).then((res) => {
            this.cur_test_id = res;
            this.get_test_plans_without_change_id();
            this.is_dialog_visible = false;
            this.$message({
              type: "success",
              message: this.$t("test.message.addSuccess"),
            });
            // 清理新建测试计划弹窗
            this.create_test_plans_clear();
          });
        }
      });
    },
    // 删除测试计划点击
    delete_test_plan_click() {
      if (this.test_status != "NOT_STARTED") {
        this.$confirm(
          this.$t("corax.confirmDeletion"),
          this.$t("knowledge.wanning"),
          {
            confirmButtonClass: "delete_button_class",
            confirmButtonText: this.$t("btn.deleteBtn"),
            cancelButtonText: this.$t("btn.cancelBtn"),
            cancelButtonClass: "delete_cancel_class",
            type: "warning",
          }
        ).then(() => {
          this.confirmDelete();
        });
      } else {
        this.confirmDelete();
      }
    },
    confirmDelete() {
      delete_test_plan(this.get_pid(), this.cur_test_id)
        .then((res) => {
          this.$message({
            type: "success",
            message: this.$t("test.message.deleteSuccess"),
          });
          this.get_test_plans();
          this.is_edit = false;
        })
        .catch((err) => {
          console.error(err); // handle error from delete_test_plan here
        });
    },

    // 获得基线列表
    get_baseline_options() {
      this.ruleForm.baseline_options = Object.values(
        JSON.parse(sessionStorage.getItem("_baseLine"))
      );
    },
    // 获得版本列表
    get_version_options() {
      this.ruleForm.version_options = Object.values(
        JSON.parse(sessionStorage.getItem("_version"))
      );
    },
    // 编辑测试计划信息
    edit_test_plan() {
      this.is_auto_save = true;
      edit_test_plan(this.get_pid(), this.cur_test_id, {
        name: this.test_name,
        principal: this.cur_test_plan.principal,
        target: this.cur_test_plan.target,
        remark: this.cur_test_plan.remark,
        baselineIds: this.$refs["test-info"].baseline,
        versionIds: this.$refs["test-info"].version,
      }).then((res) => {
        this.get_test_plans_without_change_id();
      });
    },

    // 进入执行模式
    enter_excecute_mode() {
      if (!this.$refs["test-info"].responsible) {
        this.$message({
          type: "warning",
          message: this.$t("test.message.executeWarn"),
        });
        return;
      }
      if (this.cur_test_plan.status == "NOT_STARTED") {
        enter_process(this.get_pid(), this.cur_test_id).then((res) => {
          this.get_test_plans_without_change_id();
        });
      }
      this.is_excecuting = true;
    },
    before_close_drawer() {
      this.drawer = false;
    },
    show_drawer(node) {
      const jobAuthorities = this.$store.getters.jobAuthorities;
      if (jobAuthorities.indexOf("NODE_DETAIL_READ") === -1) {
        this.$message({
          message: this.$t("board.message.error"),
          type: "warning",
        });
        return;
      }
      this.drawer_loading = true;
      this.$nextTick(() => {
        get_node_use_node_key(this.get_pid(), node).then((res) => {
          this.current_node = res;
          this.drawer = true;
        });
      });
    },
    update_description_content(description) {
      this.current_node.description = description;
    },
    update_current_node(e) {
      this.current_node = e;
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.excel_export_dialog {
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: hidden scroll;
  }

  .el-checkbox {
    padding-bottom: 16px;
    display: flex;
    color: rgba(128, 128, 128, 1);

    .el-checkbox__label {
      font-size: 16px;
      font-weight: 400;
      // color: rgba(56, 56, 56, 1);
      text-align: left;
    }
  }
}
.rotate-animate {
  animation: rotate 0.8s linear infinite;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__test-select {
    ::v-deep .el-input__inner {
      width: 260px;
      // background-color: transparent;
    }
  }
  &__test-edit {
    .test-name {
      font-size: 18px;
      color: rgba(128, 128, 128, 1);
    }
    ::v-deep .el-input {
      width: 260px;
      .el-input__inner {
        font-size: 16px;
        // background-color: transparent;
      }
    }
  }
  &__btns-edit {
    display: flex;
    align-items: center;
  }
}

.test-add {
  font-size: 18px;
  width: 100%;
}

::v-deep.el-select-dropdown__item.test-add-option::after {
  content: "";
}

.excecute {
  position: fixed;
  margin-left: 270px;
}

// 按钮的样式
// ::v-deep .el-button {
//   font-size: 16px;
//   padding: 6px 10px;
// }
.null_option:hover {
  background-color: white;
}
.test-nodes {
  margin-top: 8px;
  padding: 8px 0;
  border-radius: 8px;
  background-color: #fff;
  .el-table__body-wrapper {
    overflow: hidden !important;
  }
}
.custom-tag {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  border: 1px solid;
  border-radius: 4px;
  font-size: 0.8em;
}
.kind {
  color: white;
  padding: 3px 6px;
}

.custom-tag.high {
  border-color: #f56c6c;
}

.custom-tag.high .kind {
  background-color: #f56c6c;
}

.custom-tag.medium {
  border-color: #e6a23c;
}

.custom-tag.medium .kind {
  background-color: #e6a23c;
}

.custom-tag.low {
  border-color: #67c23a;
}

.custom-tag.low .kind {
  background-color: #67c23a;
}

.custom-tag.info {
  border-color: #909399;
}

.custom-tag.info .kind {
  background-color: #909399;
}
.number {
  min-width: 30px;
  text-align: center;
}
.check_box_wrap {
  border: 1px solid #e5e5e5;
  text-align: left;
  padding: 0 0 0 10px;
  & > div {
    &:first-of-type {
      border-bottom: 1px solid #e5e5e5;
    }
  }
}
::v-deep .el-select {
  .el-select__tags-text {
    display: inline-block;
    max-width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .el-tag__close {
    margin-top: -12px;
  }
}
</style>
<style lang="scss">
.select_own .el-select-dropdown__list {
  padding: 0;
  margin: 0;
  li:nth-child(1) {
    background-color: rgba(77, 160, 255, 0.35);
    border-radius: 6px 6px 0px 0px;
  }
}
</style>
