<template>
  <div
    v-loading="loading"
    class="projectmanger"
    :element-loading-text="$t('loading.loading1')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
    style="height: 79vh"
  >
    <el-row type="flex" justify="left">
      <div class="header-project" @click="Changeroot">
        <div :class="{ 'el-link-active': isroot == 1 }" :data-root="1">
          {{ $t("personal.projectManagement.tab1") }}
        </div>
        <!-- <div v-if="role === 'SYSTEM_MANAGER'" :class="{ 'el-link-active': isroot == 2 }" :data-root="2">
          {{ $t('personal.projectManagement.tab2') }}
        </div> -->
      </div>
    </el-row>
    <el-row style="margin-top: 20px; height: 75vh">
      <!--  -->
      <el-input
        v-model="search_name"
        class="search_input"
        size="small"
        :placeholder="$t('placeholder.input')"
        prefix-icon="el-icon-search"
        @input="searchName"
      >
      </el-input>
      <el-table
        v-if="userList.length > 0"
        :data="userList"
        :empty-text="$t('placeholder.table1')"
        height="66vh"
      >
        <el-table-column
          :label="$t('personal.projectManagement.table.avatar')"
          align="center"
          header-align="center"
          width="120"
        >
          <template slot-scope="scope">
            <div class="table-avatar">
              <personal-avatar
                :size="35"
                :avatar="scope.row.avatar"
                :nickname="scope.row.projectName"
                :colorid="scope.row.projectId"
              ></personal-avatar>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="projectName"
          :label="$t('personal.projectManagement.table.name')"
          align="center"
          header-align="center"
          min-width="20%"
        ></el-table-column>
        <el-table-column
          :label="$t('personal.projectManagement.table.type')"
          align="center"
          header-align="center"
          prop="type"
          width="120"
        >
        </el-table-column>
        <el-table-column
          v-if="isroot == 1"
          prop="updatedAt"
          :label="$t('personal.projectManagement.table.lastUpdated')"
          align="center"
          header-align="center"
          min-width="20%"
        ></el-table-column>
        <el-table-column
          v-if="isroot == 2"
          prop="updatedAt"
          :label="$t('personal.projectManagement.table.deleteAt')"
          align="center"
          header-align="center"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="memberCount"
          :label="$t('personal.projectManagement.table.memberNum')"
          align="center"
          header-align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="createdBy"
          :label="$t('personal.projectManagement.table.creator')"
          align="center"
          header-align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          :label="$t('personal.projectManagement.table.leader')"
          align="center"
          header-align="center"
          width="200"
        >
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              width="200"
              :trigger="scope.row.managerList.length > 2 ? 'hover' : 'manual'"
              :content="scope.row.managerListFullString"
            >
              <div slot="reference">{{ scope.row.managerListString }}</div>
            </el-popover> </template
          >>
        </el-table-column>
        <el-table-column label="" width="80" align="right">
          <template slot-scope="scope">
            <el-dropdown>
              <el-button type="text" style="min-height: 16px">
                <i
                  class="iconfont icon-a-216gengduo-shuxiang"
                  style="color: rgb(48, 100, 143)"
                ></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('fileType.permission.table.label2')"
                  placement="top-start"
                  :disabled="(role === 'SYSTEM_MANAGER')"
                >
                  <div
                    :style="
                      !(role === 'SYSTEM_MANAGER')
                        ? 'cursor: not-allowed;color: gainsboro !important;'
                        : ''
                    "
                    v-if="isroot == 1"
                    class="operate_button"
                    @click="
                      role === 'SYSTEM_MANAGER'
                        ? Open_Member_Setting_Dialog(scope.row.projectId)
                        : null
                    "
                  >
                    <i style="font-size: 16px" class="iconfont icon-bianji"></i>
                    &nbsp;&nbsp;
                    {{ $t("personal.projectManagement.table.options.editBtn") }}
                  </div>
                </el-tooltip>

                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('fileType.permission.table.label2')"
                  placement="top-start"
                  :disabled="(role === 'SYSTEM_MANAGER')"
                >
                  <div
                    :style="
                      !(role === 'SYSTEM_MANAGER')
                        ? 'cursor: not-allowed;color: gainsboro !important;'
                        : ''
                    "
                    v-if="isroot == 1"
                    class="operate_button"
                    @click="
                      role === 'SYSTEM_MANAGER' ? Open_Remove(scope.row) : null
                    "
                  >
                    <i
                      style="font-size: 16px"
                      class="iconfont icon-projectmanger_delbin"
                    ></i>
                    &nbsp;&nbsp;&nbsp;{{ $t("btn.deleteBtn") }}
                  </div>
                </el-tooltip>

                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('fileType.permission.table.label2')"
                  placement="top-start"
                  :disabled="(role === 'SYSTEM_MANAGER')"
                >
                  <div
                    :style="
                      !(role === 'SYSTEM_MANAGER')
                        ? 'cursor: not-allowed;color: gainsboro !important;'
                        : ''
                    "
                    v-if="isroot == 2"
                    class="operate_button"
                    @click="
                      role === 'SYSTEM_MANAGER' ? Recover(scope.row) : null
                    "
                  >
                    <i
                      style="font-size: 16px"
                      class="iconfont icon-projectmanger_recover"
                    ></i>
                    &nbsp;&nbsp;&nbsp;{{
                      $t("personal.projectManagement.table.options.revertBtn")
                    }}
                  </div>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('fileType.permission.table.label2')"
                  placement="top-start"
                  :disabled="(role === 'SYSTEM_MANAGER')"
                >
                  <div
                    :style="
                      !(role === 'SYSTEM_MANAGER')
                        ? 'cursor: not-allowed;color: gainsboro !important;'
                        : ''
                    "
                    v-if="isroot == 2"
                    class="operate_button"
                    @click="
                      role === 'SYSTEM_MANAGER' ? Open_Remove(scope.row) : null
                    "
                  >
                    <i
                      style="font-size: 16px"
                      class="iconfont icon-projectmanger_delbin"
                    ></i
                    >&nbsp;&nbsp;&nbsp;{{ $t("btn.forceDeleteBtn") }}
                  </div>
                </el-tooltip>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :total="pageObj.total"
        :current-page="pageObj.currentPage"
        :page-size="10"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-row>

    <!-- 弹窗 -->
    <div class="promanger-dialog">
      <!-- 用户管理弹窗 -->
      <el-dialog
        :visible.sync="memberSettingDialogVisble"
        :close-on-click-modal="false"
        :show-close="true"
        width="60%"
        center
        top="5vh"
        :modal-append-to-body="false"
      >
        <project-member-setting :p_id="memberSettingDialogProjectId" />
      </el-dialog>
      <!-- 删除弹窗 -->
      <el-dialog
        :visible.sync="centerDialogVisible"
        :close-on-click-modal="false"
        :show-close="true"
        width="25%"
        :title="deleteTitleMap"
        top="30vh"
      >
        <div v-if="system_manager_list.length <= 1">
          <p>
            {{ $t("deletProject.deletProjectBefore")
            }}<span style="font-weight: bold; font-size: 18px">{{
              proinfo.name
            }}</span
            >{{ $t("deletProject.deletProjectAfter") }}
          </p>
          <p style="margin-bottom: 0">
            {{ $t("deletProject.inputProjectName") }}:
            <el-input
              v-model="delete_name"
              size="small"
              style="width: 300px"
              @paste.native.capture.prevent="handlePaste"
            ></el-input>
          </p>
        </div>
        <div v-else>
          <p>{{ $t("deletProject.deletProjectStep2") }}</p>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="centerDialogVisible = false">{{
            $t("btn.cancelBtn")
          }}</el-button>
          <el-button type="primary" class="button-confirm" @click="Remove">{{
            $t("btn.confirmBtn")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  remove_Project,
  get_removeProject,
  recover_removeProject,
  removefover_Project,
  allSystemManager,
  startProcess,
} from "@/network/personal/index.js";
import {
  request_allPageProjects,
  request_allDeletedPageProjects,
  request_joinedProjects,
} from "@/network/personal/index.js";
import PersonalAvatar from "@/components/personal";
import ProjectMemberSetting from "@/views/projectmemberSetting";

export default {
  components: {
    PersonalAvatar,
    ProjectMemberSetting,
  },
  data() {
    return {
      userList: [
        //列表里的信息
      ],
      proinfo: {
        //   确认删除那个对话框里的信息
        picture: "",
        name: "xxx项目1",
        edit: "20220501",
        number: "3",
        id: 0,
      },
      deleteTitleMap: this.$t(
        "personal.projectManagement.table.options.deleteForm.title"
      ),
      isroot: 1, // 1是当前项目列表   2是已删除项目列表
      loading: false,
      centerDialogVisible: false,
      system_manager_list: [],
      delete_name: "",
      memberSettingDialogVisble: false,
      memberSettingDialogProjectId: null,
      // 分页信息
      pageObj: {
        total: 0,
        currentPage: 1,
      },
      search_name: "",
      // 权限相关
      role: "",
    };
  },
  created() {
    this.role = JSON.parse(sessionStorage.getItem("role"))[0].name;
    this.isroot = true; //刚进来默认为当前项目列表
    this.getinfo();
  },
  methods: {
    handlePaste() {}, //禁止粘贴
    searchName() {
      this.pageObj.currentPage = 1;
      if (this.isroot == 1) {
        this.getinfo(); //获取当前项目列表
      } else {
        this.getremoveinfo(); //获取已删除的项目的项目列表
      }
    },
    async Changeroot(event) {
      //更改 当前项目 与 已删除的项目
      let root = event.target.dataset.root; //判断点击的是哪一个
      this.isroot = root;
      if (root == 1) {
        this.pageObj.currentPage = 1;
        this.getinfo(); //获取当前项目列表
      } else {
        this.pageObj.currentPage = 1;
        this.getremoveinfo(); //获取已删除的项目的项目列表
      }
    },

    Open_Member_Setting_Dialog(p_id) {
      this.memberSettingDialogProjectId = p_id;
      this.memberSettingDialogVisble = true;
    },

    async getinfo() {
      //获取项目列表
      this.loading = true;
      try {
        const params = {
          pageNumber: this.pageObj.currentPage - 1,
          pageSize: 10,
          fuzzyName: this.search_name,
        };
        let res =
          this.role === "SYSTEM_MANAGER"
            ? await request_allPageProjects(params)
            : await request_joinedProjects(params);
        let ress = await request_joinedProjects();
        this.$store.commit("set_joined_projects", ress);
        this.userList = this.role === "SYSTEM_MANAGER" ? res.content : res;
        this.userList.forEach((item) => {
          item.managerListString =
            item.managerList.slice(0, 2).join(", ") +
            (item.managerList.length > 2 ? "..." : "");
          item.managerListFullString = item.managerList.join(",");
        });
        this.pageObj.total = res.totalElements;
        this.loading = false; //loading
      } catch (error) {
        console.log(error);
      }
    },

    async getremoveinfo() {
      //获取删除项目列表
      try {
        const params = {
          pageNumber: this.pageObj.currentPage - 1,
          pageSize: 10,
          fuzzyName: this.search_name,
        };
        this.loading = true;
        let res = await request_allDeletedPageProjects(params);
        this.userList = res.content;
        this.userList.forEach((item) => {
          item.managerListString =
            item.managerList.slice(0, 2).join(", ") +
            (item.managerList.length > 2 ? "..." : "");
          item.managerListFullString = item.managerList.join(",");
        });
        this.pageObj.total = res.totalElements;
        this.loading = false;
      } catch (error) {
        console.log(error);
      } //loading
    },
    handleCurrentChange(page) {
      this.pageObj.currentPage = page;
      if (this.isroot == 1) {
        this.getinfo(); //获取当前项目列表
      } else {
        this.getremoveinfo(); //获取已删除的项目的项目列表
      }
    },
    Open_Remove(row) {
      //打开删除对话框
      // console.log(row);
      this.proinfo.picture = row.avatar;
      this.proinfo.name = row.projectName;
      this.proinfo.edit = row.updatedAt;
      this.proinfo.number = row.memberCount;
      this.proinfo.projectId = row.projectId;

      if (this.isroot == 1) {
        this.deleteTitleMap = this.$t(
          "personal.projectManagement.table.options.deleteForm.title"
        );
      } else {
        this.deleteTitleMap = this.$t(
          "personal.projectManagement.table.options.deleteForm.title2"
        );
      }
      allSystemManager().then((res) => {
        this.system_manager_list = res;
        this.delete_name = "";
        this.centerDialogVisible = true; //setTimeout 要不要无所谓
      });
    },

    async Remove() {
      //删除  永久删除
      if (this.isroot == 1) {
        //删除
        try {
          if (this.system_manager_list.length <= 1) {
            if (this.delete_name === this.proinfo.name) {
              let res = await removefover_Project(this.proinfo.projectId);
              this.$message({
                type: "success",
                message: this.$t("personal.projectManagement.message.p1"),
              });
              this.getinfo();
              this.centerDialogVisible = false;
            } else {
              this.$message({
                type: "warning",
                message: this.$t("personal.projectManagement.message.p3"),
              });
            }
          } else {
            startProcess(this.proinfo.projectId).then((res) => {
              this.$message({
                type: "success",
                message: this.$t("personal.projectManagement.message.p4"),
              });
              this.centerDialogVisible = false;
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async Recover(row) {
      //恢复
      let res = await recover_removeProject(row.projectId);
      this.getremoveinfo();
      this.$message({
        type: "success",
        message: this.$t("personal.projectManagement.message.p2"),
      });
    },
  },
};
</script>

<style lang="scss">
.el-table {
  margin-left: -2px !important;
  overflow: hidden;
  max-height: 78vh;
  font-size: 14px;
}
.search_input {
  float: left;
}
.table-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-project {
  display: flex;
  justify-content: flex-start;
  color: rgba(166, 166, 166, 1);
  font-size: 19px;
  margin-left: 5px;
  cursor: pointer;
}
.header-project div {
  margin-right: 20px;
}

.el-link-active {
  color: #409eff;
}

.operate_button {
  display: block;
  font-size: 14px;
  margin: 15px 20px;
  text-align: center;
  cursor: pointer;
  color: #656667;
  position: relative;
  text-align: left;
}
.operate_button:hover {
  color: #46b4f4;
}
.el-icon-more {
  color: #263281;
}
.with-box-shadow {
  box-shadow: 0px 2px 4px rgba(128, 128, 128, 0.61);
}

//
.promanger-dialog {
  background-color: rgba(128, 128, 128, 0.61);
  text-align: left;
}

.remove-dialog {
  margin-top: 30px;
  display: flex;
  justify-content: left;
}
.remove-dialog-right {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
//
</style>

<style lang="scss" scoped>
/* 表格透明与分割线：https://www.jianshu.com/p/888946e641db */
.projectmanger {
  ::v-deep .el-table,
  ::v-deep .el-table__expanded-cell {
    background-color: transparent !important;
  }

  ::v-deep .el-table th,
  ::v-deep .el-table tr,
  ::v-deep .el-table td {
    background-color: transparent !important;
    font-weight: 400;
    font-size: 14px;
    color: rgba(112, 112, 112, 1);
  }
  .el-table th {
    font-size: 16px;
  }
}
</style>
