<template>
  <div class="test" style="padding-bottom: 40px">
    <el-table :data="tableData" border style="90vw">
      <el-table-column
        prop="title"
        :label="$t('addMyColla.table.title1')"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="initPerson"
        :label="$t('addMyColla.table.title3')"
        width="140"
      >
        <template slot-scope="scope">
          {{
            getUser(scope.row.createdBy) &&
            getUser(scope.row.createdBy).nickname
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="reviewProcessEndTime"
        :label="$t('review.result.info1')"
        width="160"
      >
      </el-table-column>
      <el-table-column
        prop="reviewStatus"
        :label="$t('review.result.info2')"
        width="120"
      >
        <template slot-scope="scope">
          <div
            class="pingshen-status-tag"
            :style="{ backgroundColor: getTheme(scope.row.reviewStatus) }"
          >
            {{ getStatusText(scope.row.reviewStatus) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('review.result.info3')" width="120">
        <template slot-scope="scope">
          <div
            class="pingshen-status-tag"
            style="cursor: pointer; width: 100%"
            @click="openDetailDialog(scope.row.nodeReviewResultInfos)"
          >
            {{ $t("btn.view") }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="$t('review.result.info3')"
      :visible.sync="pingshenDetailDialog"
      :close-on-click-modal="false"
      :show-close="true"
      :modal="false"
    >
      <div class="pingshen-result-area">
        <div class="pass">
          <div class="info">
            <div class="green-btn info__btn">
              {{ $t("review.result.info5") }}
            </div>
            <span class="green">({{ detailReviewInfo.approveNumber }})人</span>
          </div>
          <div class="person-list">
            <div
              v-for="(item, idx) in detailReviewInfo.approvePersonList"
              :key="idx"
              class="person"
            >
              <personal-avatar
                :placement="'top'"
                :size="40"
                :avatar="item ? item.avatar : ''"
                :nickname="item ? item.nickname : ''"
                :colorid="item ? item.accountId : ''"
                style="margin: 0 auto"
              ></personal-avatar>
              <span>{{ item ? item.nickname : "" }}</span>
            </div>
          </div>
        </div>
        <div class="not-pass">
          <div class="info">
            <div class="red-btn info__btn">{{ $t("review.result.info7") }}</div>
            <span class="red">({{ detailReviewInfo.disapproveNumber }})人</span>
          </div>
          <div class="person-list">
            <div
              v-for="(item, idx) in detailReviewInfo.disapprovePersonList"
              :key="idx"
              class="person"
            >
              <personal-avatar
                :placement="'top'"
                :size="40"
                :avatar="item ? item.avatar : ''"
                :nickname="item ? item.nickname : ''"
                :colorid="item ? item.accountId : ''"
                style="margin: 0 auto"
              ></personal-avatar>
              <!-- <span style="font-size: 14px;">{{ item ? (item.nickname && item.nickname.length > 6 ? item.nickname.slice(0, 6) + '...' : item.nickname) : "" }}</span> -->
              <span style="font-size: 14px">{{
                item ? item.nickname : ""
              }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="
            detailReviewInfo.commentList &&
            detailReviewInfo.commentList.length > 0
          "
          class="comment"
        >
          <ul class="comment-list">
            <li v-for="(item, idx) in detailReviewInfo.commentList" :key="idx">
              <span class="k">{{ item.name }}</span>
              <span class="v">{{ item.content }}</span>
            </li>
          </ul>
        </div>
        <div>
          <el-table
            :data="detailReviewInfo.pingshen_rate"
            style="width: 100%"
            max-height="200"
            :show-header="false"
          >
            <el-table-column type="index" width="25" align="left">
            </el-table-column>
            <el-table-column show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.l }}
              </template>
            </el-table-column>
            <el-table-column width="75">
              <template slot-scope="scope"> {{ scope.row.num }}% </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getNodeReviewInfo } from "@/network/collaboration/index.js";
import { get_review_config } from "@/network/review/index.js";
import PersonalAvatar from "../../personal";
export default {
  components: {
    PersonalAvatar,
  },
  inject: ["nodeKey"],
  props: {
    nodeInfo: {
      type: Object,
    },
  },
  data() {
    return {
      tableData: [],
      pingshenDetailDialog: false,
      dict: {
        REVIEWING: {
          color: "rgba(44, 152, 226, 1)",
          text: this.$t("addNodeDetail.message12"),
        },
        APPROVED: {
          color: "rgba(79, 176, 118, 1)",
          text: this.$t("review.result.info5"),
        },
        UN_APPROVED: {
          color: "rgba(255, 87, 51, 1)",
          text: this.$t("review.result.info7"),
        },
      },
      detailReviewInfo: {},
      review_list: [],
    };
  },
  computed: {
    ...mapGetters(["user_list"]),
  },
  mounted() {
    getNodeReviewInfo(this.get_pid(), this.nodeKey).then((res) => {
      this.tableData = res;
    });
    get_review_config(this.get_pid()).then((res) => {
      res.forEach((item) => {
        item.fileTypeList.forEach((type) => {
          if (type.fileTypeId == this.nodeInfo.fileTypeId) {
            this.review_list = item.itemList;
          }
        });
      });
    });
  },
  methods: {
    getTheme(status) {
      return this.dict[status].color;
    },
    getStatusText(status) {
      return this.dict[status].text;
    },
    openDetailDialog(detailReviewInfo) {
      let pingshen_rate = {};
      this.review_list.forEach((ele) => {
        pingshen_rate[ele.itemId] = 0;
      });
      let approveNumber = 0;
      let disapproveNumber = 0;
      const approvePersonList = [];
      const disapprovePersonList = [];
      const commentList = [];
      detailReviewInfo.forEach((item) => {
        item.reviewItems &&
          item.reviewItems.forEach((ele) => {
            if (ele in pingshen_rate) {
              pingshen_rate[ele]++;
            }
          });
        if (item.approved) {
          approveNumber++;
          approvePersonList.push(this.getUser(item.createdBy));
        } else {
          disapproveNumber++;
          disapprovePersonList.push(this.getUser(item.createdBy));
        }
        if (item.comment) {
          commentList.push({
            name:
              this.getUser(item.createdBy) &&
              this.getUser(item.createdBy).nickname,
            content: item.comment,
          });
        }
      });

      console.log(approvePersonList, disapprovePersonList, commentList);

      let ll = [];
      this.review_list.forEach((el) => {
        ll.push({
          num: Math.round(
            (100 * pingshen_rate[el.itemId]) /
              (approvePersonList.length + disapprovePersonList.length)
          ),
          l: el.content,
        });
      });
      this.detailReviewInfo.approveNumber = approveNumber;
      this.detailReviewInfo.disapproveNumber = disapproveNumber;
      this.detailReviewInfo.approvePersonList = approvePersonList;
      this.detailReviewInfo.disapprovePersonList = disapprovePersonList;
      this.detailReviewInfo.commentList = commentList;
      this.detailReviewInfo.pingshen_rate = ll;
      this.pingshenDetailDialog = true;
    },
    getUser(id) {
      return this.matchUserInfo(id);
    },
  },
};
</script>
<style scoped lang="scss">
.pingshen-status-tag {
  padding: 6px 12px;
  border-radius: 6px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(48, 100, 143, 1);
}
.pingshen-result-area {
  margin-bottom: 24px;
  .comment {
    width: 100%;
    border: 1px solid black;
    min-height: 40px;
    border-radius: 9px;

    .comment-list {
      max-height: 200px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 10px;
        height: 8px;
        background-color: #ebeef5;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 3px;
        background: rgba(255, 255, 255, 1);
      }
      padding: 0 20px;
      list-style: none;
      li {
        font-size: 14px;
        .k {
          margin-right: 10px;
        }

        .v {
          color: #777;
        }
      }
    }
  }
  .pass {
    margin-bottom: 30px;
  }
  .not-pass {
    margin-bottom: 25px;
  }

  .green-btn {
    background-color: rgba(79, 176, 118, 1);
  }
  .green {
    color: rgba(79, 176, 118, 1);
  }
  .red-btn {
    background-color: rgba(255, 87, 51, 1);
  }
  .red {
    color: rgba(255, 87, 51, 1);
  }
  .info {
    display: flex;
    align-items: center;
    &__btn {
      margin-right: 20px;
      padding: 4px 8px;
      color: #fff;

      border-radius: 4px;
    }
    span {
      letter-spacing: 2px;
    }
  }
  .person-list {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    gap: 25px;
    max-width: 100%;
    overflow-x: scroll;
    .person {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: #ebeef5;
    }
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #ccc;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: rgba(255, 255, 255, 1);
    }
  }
}
</style>
<style lang="scss">
.test {
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: none;
    & > div {
      word-break: keep-all;
    }
  }
}
.demo {
  color: rgba(79, 176, 118, 1);
}
</style>
