var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"test_report_wrap",staticClass:"test_report_wrap"},[_c('div',{staticClass:"test_report_main"},[_c('div',{staticClass:"text_report_left"},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("corax.StaticCodeScanPieChart")))]),_c('div',{ref:"pieBug",attrs:{"id":"pieBug"}})])]),_c('div',{staticClass:"text_report_right"},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("corax.LinesOfCode")))]),_c('p',[_vm._v(_vm._s(_vm.rowData.line_number))])]),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("corax.NumberOfFiles")))]),_c('p',[_vm._v(_vm._s(_vm.rowData.file_number))])]),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("corax.ScanningCriteria")))]),_c('p',[_vm._v(_vm._s(_vm.rowData.standards))])]),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("corax.ScanningTime")))]),_c('p',[_vm._v(_vm._s(_vm.rowData.scan_time))])]),_c('div',{staticClass:"link"},[_c('h3',[_vm._v(_vm._s(_vm.$t("corax.PlatformLinks")))]),_c('a',{attrs:{"href":_vm.rowData.link,"target":"_blank"}},[_vm._v(_vm._s(_vm.rowData.link))])])])]),_c('div',{staticClass:"test_report_table_wrap"},[_c('h3',[_vm._v(_vm._s(_vm.$t("corax.DefectDetails")))]),_c('div',{staticClass:"test_report_table"},[_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.rowData.bug_list,"header-cell-style":{
            color: '#383838',
            'font-weight': '700',
          },"cell-style":{
            color: '#636363',
            'font-weight': '700',
          }}},[_c('el-table-column',{attrs:{"prop":"bug_id","label":_vm.$t('corax.GlobalNumber'),"width":"100"}}),_c('el-table-column',{attrs:{"prop":"bug_code","show-overflow-tooltip":"","label":_vm.$t('corax.BugNumber')}}),_c('el-table-column',{attrs:{"prop":"bug_name","show-overflow-tooltip":"","label":_vm.$t('corax.DefectName')}}),_c('el-table-column',{attrs:{"prop":"bug_description","show-overflow-tooltip":"","label":_vm.$t('corax.DefectDescription')}}),_c('el-table-column',{attrs:{"prop":"bug_position","show-overflow-tooltip":"","label":_vm.$t('corax.DefectLocation')}}),_c('el-table-column',{attrs:{"prop":"severity","label":_vm.$t('corax.Severity')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(scope.row.severity === 'high')?_c('div',{staticStyle:{"height":"10px","width":"10px","border-radius":"50%","background":"red","margin-right":"5px"}}):_vm._e(),(scope.row.severity === 'medium')?_c('div',{staticStyle:{"height":"10px","width":"10px","border-radius":"50%","background":"orange","margin-right":"5px"}}):_vm._e(),(scope.row.severity === 'low')?_c('div',{staticStyle:{"height":"10px","width":"10px","border-radius":"50%","background":"yellow","margin-right":"5px"}}):_vm._e(),(scope.row.severity === 'info')?_c('div',{staticStyle:{"height":"10px","width":"10px","border-radius":"50%","background":"blue","margin-right":"5px"}}):_vm._e(),_c('div',[_vm._v(_vm._s(scope.row.severity))])])]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"sortable-column-header",on:{"click":_vm.severity_sort_toggle}},[_vm._v(" "+_vm._s(_vm.$t("corax.Severity"))+" "),_c('div',{staticClass:"caret-wrapper"},[_c('i',{class:{
                      'el-icon-caret-top': true,
                      'el-icon-caret-colored':
                        _vm.sortby == 'severity' && _vm.desc == false,
                    }}),_c('i',{class:{
                      'el-icon-caret-bottom': true,
                      'el-icon-caret-colored':
                        _vm.sortby == 'severity' && _vm.desc == true,
                    }})])])])],2)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }