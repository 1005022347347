<template>
  <div>
    <el-header style="height: 60px">
      <home-topbar :is-home="true" :is-personal="false"></home-topbar>
    </el-header>
    <div id="word_view_drawio_node_key" style="display: none"></div>
    <p id="global_variable_allowedBatchExec_word" style="display: none">
      {{ allowedBatchExec }}
    </p>
    <div id="line_word_wrap" class="line_word_wrap">
      <top-bar
        :line-word="true"
        :fileData="fileData"
        @nodebuchong="nodebuchong"
      ></top-bar>
      <div class="line_word_content">
        <!--        <div class="left">-->
        <div class="catalog_wrap">
          <el-tree
            :data="tree_data_line_word"
            :default-expand-all="true"
            :highlight-current="true"
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
          >
            <div slot-scope="{ node, data }">
              <span
                v-show="data.layer < 5"
                style="color: #2a82e4; margin-right: 5px"
                >{{ index_map[data.id] }}</span
              >

              <span
                v-if="see_review || process_review"
                :key="f6"
                :style="high_ligth_review(data)"
                >{{ data.topic }}</span
              >
              <span v-else>{{ data.topic }}</span>
            </div>
          </el-tree>
        </div>
        <!--        </div>-->

        <div class="resize" :title="$t('addAlert.collapse')"></div>
        <!--        <div class="right">-->
        <div id="line_word_right">
          <el-button
            style="margin-bottom: 10px"
            @click="expand_all($event)"
            id="zdh_guide_expand_all_tip4"
            >{{ collapse_button_text }}
          </el-button>
          <el-button style="margin-bottom: 10px" @click="resetChecked($event)"
            >{{ $t("canvas.btn.clear") }}
          </el-button>
          <div class="line_word">
            <el-tree
              id="word_tree"
              ref="myTree"
              :key="f5"
              :data="tree_data_line_word"
              node-key="id"
              :current-node-key="current_key"
              default-expand-all
              :expand-on-click-node="false"
              :show-checkbox="!see_review"
              check-strictly
              draggable
              :allow-drop="allowDrop"
              :allow-drag="allowDrag"
              @node-click="choose"
              @check="treeNodeClick"
              @node-contextmenu="open_right_meau"
              @node-drag-end="handleDragEnd"
              @node-drag-over="handleDragOver"
            >
              <div
                :id="data.id"
                slot-scope="{ node, data }"
                style="width: 100%; margin: 10px 0"
              >
                <div style="display: flex; align-items: center">
                  <el-tooltip
                    effect="dark"
                    :content="data.topic"
                    :disabled="is_show_topic"
                  >
                    <div
                      class="node_topic"
                      @click="show_detail(data, false)"
                      @dblclick="edit_topic(data)"
                      @mouseenter="visibilityChange($event)"
                    >
                      <span v-if="!edit_map[data.id]">
                        <span
                          v-if="see_review || process_review"
                          :key="f6"
                          :class="high_ligth_review_class(data)"
                          >{{ data.topic }}</span
                        >

                        <span v-else>
                          {{ data.topic }}
                        </span>
                      </span>

                      <el-input
                        v-else
                        ref="input_panel"
                        v-model="data.topic"
                        style="width: 300px"
                        size="small"
                        type="text"
                        @blur="save_topic(data)"
                        @keydown.enter.native="save_topic(data)"
                      />
                    </div>
                  </el-tooltip>
                  <div class="icon">
                    <i
                      class="iconfont icon-drawer"
                      @click="show_drawer(data)"
                    ></i>
                  </div>
                  <div v-if="see_review" :class="get_review_class(data)"></div>
                  <div
                    v-if="
                      outstanding_detail_dialog_show &&
                      outstanding_type !== 'reuse'
                    "
                    class="outstanding_node"
                    :style="
                      'background-color:#' +
                      (outstanding_type.split('@')[0] == 'otherFile' ||
                      outstanding_type.split('@')[0] == 'coverage'
                        ? outstanding_map[otherFile_map[data.key]].color
                        : outstanding_map[data[outstanding_type]].color)
                    "
                  ></div>
                  <div
                    v-if="
                      outstanding_detail_dialog_show &&
                      outstanding_type === 'reuse'
                    "
                    class="outstanding_node"
                    :style="'background-color:' + getReuseColor(data.key)"
                  ></div>
                  <div
                    v-if="
                      only_show_node &&
                      outstanding_map[otherFile_map[data.key]].color == 'fff'
                    "
                    class="outstanding_node"
                    :style="'background-color:red'"
                  ></div>

                  <div :key="f6" style="display: flex; align-items: center">
                    <div
                      v-if="
                        process_review && user_review_list.includes(data.key)
                      "
                    >
                      <i
                        v-if="is_review_check_green(data.key)"
                        @click="approve_review_reason(data)"
                        class="el-icon-success green_review_check"
                      ></i>
                      <i
                        v-else
                        class="el-icon-success green_review"
                        @click="approve_review_reason(data)"
                      ></i>
                    </div>

                    <div
                      v-if="
                        process_review && user_review_list.includes(data.key)
                      "
                    >
                      <i
                        v-if="is_review_check_red(data.key)"
                        class="el-icon-error red_review_check"
                        @click="reject_review(data)"
                      ></i>
                      <i
                        v-else
                        class="el-icon-success red_review"
                        @click="reject_review(data)"
                      ></i>
                    </div>
                  </div>
                </div>
                <div
                  v-show="show_detail_map[data.id] || false"
                  class="custom-tree-node"
                >
                  <div
                    :style="
                      'width:' +
                      (data.architectureDiagrams &&
                      data.architectureDiagrams.length > 0
                        ? '50%'
                        : '90%')
                    "
                  >
                    <node-description-row
                      :line-id="data.id"
                      :lock="data.lock"
                      :default="true"
                      :show-collapse="false"
                      :show_scroll="false"
                      :project-id="projectId"
                      :node-key="data.key"
                      :description="data.description"
                      :attachments="data.attachments"
                      :show_file="true"
                      @update_description_content="
                        update_description_content($event, data)
                      "
                    ></node-description-row>
                  </div>
                  <div
                    :style="
                      'width:' +
                      (data.architectureDiagrams &&
                      data.architectureDiagrams.length > 0
                        ? '50%'
                        : '10%')
                    "
                  >
                    <Drawio
                      :node-key="data.key"
                      :limit-height="true"
                      :line-word-node-data="data"
                      :parent-client="client"
                      @updateDrawio="updateDrawio($event, data)"
                    ></Drawio>
                  </div>
                </div>
              </div>
            </el-tree>
          </div>
        </div>

        <!--        </div>-->
      </div>
    </div>

    <el-card v-show="show_review_num" id="process_review">
      <div
        v-if="review_num_all > 0"
        style="
          padding-top: 30px;
          text-align: left;
          font-size: 16px;
          font-weight: 500;
          padding-left: 20px;
          display: flex;
          align-items: center;
        "
      >
        <span style="padding-right: 10px; font-weight: 500">
          {{ $t("addMindMap.tip1") }}
          <span
            style="
              font-size: 16px;
              font-weight: 700;
              color: rgba(48, 100, 143, 1);
            "
            >{{ review_num_process }}</span
          >
          {{ "/ " + review_num_all }}
        </span>
        <div
          style="
            height: 15px;
            border-radius: 20px;
            background-color: rgba(201, 201, 201, 1);
            color: white;
            width: 190px;
            display: flex;
            align-items: center;
          "
        >
          <div
            v-show="review_num_process > 0"
            :style="
              'height:15px;border-radius: 20px;display: flex;justify-content: center;align-items: center;background-color:rgba(48, 100, 143, 0.63);color:white;width:' +
              (review_num_process / review_num_all) * 100 +
              '%'
            "
          >
            {{ review_num_process }}
          </div>
          <div
            v-show="review_num_all - review_num_process > 0"
            :style="
              'height:15px;display: flex;justify-content: center;align-items: center;width:' +
              (100 - (review_num_process / review_num_all) * 100) +
              '%'
            "
          >
            {{ review_num_all - review_num_process }}
          </div>
        </div>
      </div>
      <div style="display: flex; align-items: center">
        <div
          style="
            padding-left: 20px;
            color: rgba(48, 100, 143, 1);
            font-size: 20px;
            font-weight: 700;
            text-align: left;
            height: 60px;
            display: flex;
            align-items: center;
          "
        >
          {{ $t("addMindMap.tip2") }}
        </div>
        <img
          v-if="is_show_no_review_nodes"
          src="../../assets/img/tip2.png"
          style="
            width: 20px;
            height: 20px;
            cursor: pointer;
            margin-top: -2px;
            margin-left: 5px;
          "
          @click="clear_show_no_review_nodes()"
        />
        <img
          v-else
          src="../../assets/img/tip3.png"
          style="
            width: 20px;
            height: 20px;
            cursor: pointer;
            margin-top: -2px;
            margin-left: 5px;
          "
          @click="show_no_review_nodes()"
        />
      </div>
      <div v-if="review_list.length > 0">
        <div
          v-for="(o, index) in review_list"
          :key="index"
          class="process_review_item"
          style="
            color: rgba(128, 128, 128, 1);
            word-break: break-all;
            padding: 15px;
            padding-left: 20px;
            font-size: 18px;
            text-align: left;
            min-height: 50px;
            display: flex;
            align-items: center;
          "
        >
          {{ index + 1 + "、" + o.content }}
        </div>
      </div>
    </el-card>

    <el-dialog
      :visible.sync="show_reject_form"
      width="50%"
      class="reject"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">
        <span class="title-text" style="font-size: 18px">
          <em style="color: red">*</em>
          {{ $t("addMindMap.tip11") }}
        </span>
      </div>
      <el-table
        :data="review_list"
        tooltip-effect="dark"
        style="width: 100%; margin-bottom: 20px"
        :key="refresh_table"
      >
        <el-table-column width="100">
          <template slot="header" slot-scope="scope">
            <div
              style="display: flex; align-items: center; cursor: pointer"
              @click="click_yes_all()"
            >
              <div
                class="tongguo_check"
                style="background: rgb(218, 255, 221)"
              ></div>
              &nbsp;{{ $t("canvas.reviewRequestForm.yes") }}
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="tongguo_check"
              :style="
                'background :' +
                (item_require_list[scope.row.itemId] == 'yes'
                  ? 'rgb(67, 207, 124)'
                  : 'rgb(218, 255, 221)')
              "
              @click="click_yes(scope.row.itemId)"
            ></div>
          </template>
        </el-table-column>

        <el-table-column width="100">
          <template slot="header" slot-scope="scope">
            <div
              style="display: flex; align-items: center; cursor: pointer"
              @click="click_no_all()"
            >
              <div
                class="butongguo_check"
                style="background: rgb(255, 230, 225)"
              ></div>
              &nbsp;{{ $t("canvas.reviewRequestForm.no") }}
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="butongguo_check"
              :style="
                'background :' +
                (item_require_list[scope.row.itemId] == 'no'
                  ? 'rgb(255, 87, 51)'
                  : 'rgb(255, 230, 225)')
              "
              @click="click_no(scope.row.itemId)"
            ></div>
          </template>
        </el-table-column>

        <el-table-column prop="content" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div class="dialog-content">
        <el-form :model="reject_form">
          <el-form-item>
            <el-input
              v-model="reason_zdh"
              type="textarea"
              :autosize="{ minRows: 4 }"
              :placeholder="$t('addMindMap.tip11')"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="show_reject_form = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="process_reject_review">
          {{ $t("btn.confirmBtn") }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="show_approved_form"
      width="50%"
      class="reject"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">
        <span class="title-text" style="font-size: 18px">
          <em style="color: red">*</em>
          {{ $t("addMindMap.tip18") }}
        </span>
      </div>
      <el-table
        :data="review_list"
        tooltip-effect="dark"
        style="width: 100%; margin-bottom: 20px"
        :key="refresh_table"
      >
        <el-table-column width="100">
          <template slot="header" slot-scope="scope">
            <div
              style="display: flex; align-items: center; cursor: pointer"
              @click="click_yes_all()"
            >
              <div
                class="tongguo_check"
                style="background: rgb(218, 255, 221)"
              ></div>
              &nbsp;{{ $t("canvas.reviewRequestForm.yes") }}
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="tongguo_check"
              :style="
                'background :' +
                (item_require_list[scope.row.itemId] == 'yes'
                  ? 'rgb(67, 207, 124)'
                  : 'rgb(218, 255, 221)')
              "
              @click="click_yes(scope.row.itemId)"
            ></div>
          </template>
        </el-table-column>

        <el-table-column width="100">
          <template slot="header" slot-scope="scope">
            <div
              style="display: flex; align-items: center; cursor: pointer"
              @click="click_no_all()"
            >
              <div
                class="butongguo_check"
                style="background: rgb(255, 230, 225)"
              ></div>
              &nbsp;{{ $t("canvas.reviewRequestForm.no") }}
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="butongguo_check"
              :style="
                'background :' +
                (item_require_list[scope.row.itemId] == 'no'
                  ? 'rgb(255, 87, 51)'
                  : 'rgb(255, 230, 225)')
              "
              @click="click_no(scope.row.itemId)"
            ></div>
          </template>
        </el-table-column>

        <el-table-column prop="content" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div class="dialog-content">
        <el-form :model="approved_form">
          <el-form-item>
            <el-input
              v-model="reason_zdh"
              type="textarea"
              :autosize="{ minRows: 4 }"
              :placeholder="$t('addMindMap.tip18')"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="show_approved_form = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="process_approved_review">
          {{ $t("btn.confirmBtn") }}
        </el-button>
      </div>
    </el-dialog>

    <NodeDrawer
      :drawer.sync="drawer"
      :current_node="handle_node"
      :file_icon_name="file_icon_name"
      :drawer_loading="drawer_loading"
      :file_icon="file_icon"
      :members-show="membersShow"
      :members="members"
      :show-test-plan="showTestPlan"
      @close="before_close_drawer"
      @loading-finished="drawer_loading = false"
      @update_description_content="update_description_content"
      @update_current_node="update_current_node"
    />

    <view-workflow
      v-if="
        workflow_dialog_visible &&
        current_node[0].layerNumber &&
        current_node[0].fileTypeId
      "
      :file-type-id="current_node[0].fileTypeId"
      :layer-number="current_node[0].layerNumber"
    ></view-workflow>

    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="rootVisible"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i
      ><span>&nbsp;{{ $t("addMindMap.p1") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="rootVisible = false">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="nodeVisible"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i
      ><span>&nbsp;{{ $t("addMindMap.p2") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="Delete_node">{{
          $t("btn.deleteBtn")
        }}</el-button>
        <el-button @click="nodeVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="childVisible"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i
      ><span>&nbsp;{{ $t("addMindMap.p3") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="Delete_node">{{
          $t("btn.deleteBtn")
        }}</el-button>
        <el-button @click="childVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="caseVisible"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i
      ><span>&nbsp;{{ caseword }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="Delete_node">{{
          $t("btn.deleteBtn")
        }}</el-button>
        <el-button @click="caseVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      v-if="review_detail_show"
      v-draggable
      class="review_detail_dialog"
      custom-class="review_detail_dialog_body"
      :visible.sync="review_detail_show"
      :title="$t('canvas.viewResult.res')"
      width="300px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="pingshen">
        <div class="btn-green">{{ $t("canvas.viewResult.pass") }}</div>
        <div class="num-green">
          {{
            review_detail_data.approve ? review_detail_data.approve.length : 0
          }}
        </div>
        <div id="pingshen-avatar-green" class="avatar">
          <personal-avatar
            v-for="(item, ind) in review_detail_data.approve"
            :key="ind"
            :size="30"
            :placement="'top'"
            :avatar="user_list[item.person].avatar"
            :nickname="user_list[item.person].nickname"
            :colorid="user_list[item.person].accountId"
            style="margin-left: 5px"
          ></personal-avatar>
        </div>
        <div v-show="calc_dot('pingshen-avatar-green')">
          <el-tooltip
            class="item"
            effect="dark"
            :content="get_calc_green()"
            placement="top-start"
          >
            <i
              class="el-icon-more"
              style="cursor: pointer; font-size: 20px; color: grey"
            ></i>
          </el-tooltip>
        </div>
      </div>
      <div class="pingshen">
        <div class="btn-red">{{ $t("canvas.viewResult.fail") }}</div>
        <div class="num-red">
          {{ review_detail_data.reject ? review_detail_data.reject.length : 0 }}
        </div>

        <div id="pingshen-avatar-red" class="avatar">
          <personal-avatar
            v-for="(item, ind) in review_detail_data.reject"
            :key="ind"
            :size="30"
            :placement="'top'"
            :avatar="user_list[item.person].avatar"
            :nickname="user_list[item.person].nickname"
            :colorid="user_list[item.person].accountId"
            style="margin-left: 5px"
          ></personal-avatar>
        </div>
        <div v-show="calc_dot('pingshen-avatar-red')">
          <el-tooltip
            class="item"
            effect="dark"
            :content="get_calc_red()"
            placement="top-start"
          >
            <i
              class="el-icon-more"
              style="cursor: pointer; font-size: 20px; color: grey"
            ></i>
          </el-tooltip>
        </div>
      </div>
      <div class="pingshen_reason">
        <div v-for="(item, ind) in review_detail_data.reject" :key="ind">
          <strong>{{ user_list[item.person].nickname + ":" }}&nbsp;</strong>
          {{ item.commit }}
        </div>
        <div v-for="(item, ind) in review_detail_data.approve" :key="ind">
          <strong>{{ user_list[item.person].nickname + ":" }}&nbsp;</strong>
          {{ item.commit }}
        </div>
      </div>
      <div
        style="
          font-size: 18px;
          font-weight: bold;
          color: #303133;
          text-align: left;
        "
      >
        {{ $t("canvas.reviewRequestForm.tip8") }}
      </div>
      <div>
        <el-table
          :data="review_detail_data.rea_list"
          style="width: 100%"
          max-height="200"
          :show-header="false"
        >
          <el-table-column type="index" width="25" align="left">
          </el-table-column>
          <el-table-column show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.l }}
            </template>
          </el-table-column>
          <el-table-column width="75">
            <template slot-scope="scope">
              {{ scope.row.num.toFixed(4) * 100 }}%
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog
      v-if="outstanding_detail_dialog_show"
      id="outstanding_detail_dialog"
      :outstanding_type="outstanding_type"
      v-draggable
      class="outstanding_detail_dialog"
      custom-class="outstanding_detail_dialog_body"
      :visible.sync="outstanding_detail_dialog_show"
      width="260px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span slot="title">
        {{ outstanding_name }}
        <i
          v-if="outstanding_type == 'reuse'"
          class="iconfont icon-synchronization"
          style="cursor: pointer; color: rgb(48, 100, 143); margin-right: 15px"
          @click="to_sourceNodeSync()"
        ></i>
      </span>
      <el-table
        ref="outstanding_dialog_table_word"
        :data="modify_table_data(outstanding_map)"
        class="outstanding_dialog_table_class"
        :show-header="false"
        cell-class-name="outstanding_dialog_table_class_cell"
        :row-key="
          (row) => {
            return row.color;
          }
        "
        @selection-change="handle_select_outstanding"
      >
        <el-table-column type="selection" width="30" :reserve-selection="true">
        </el-table-column>
        <el-table-column prop="name" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="outstanding_name">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="color" width="30">
          <template slot-scope="scope">
            <div
              v-if="outstanding_type == 'reuse'"
              class="outstanding_dialog_color"
              :style="'background-color:' + scope.row.color"
            ></div>
            <div
              v-else
              class="outstanding_dialog_color"
              :style="'background-color:#' + scope.row.color"
            ></div>
          </template>
        </el-table-column>
        <el-table-column prop="num" width="50"> </el-table-column>
      </el-table>
    </el-dialog>
    <right-click
      id="right_c_w"
      ref="rightWindow"
      :node-data="get_right_data(current_node)"
      @closeRight="closeRightMethod"
    ></right-click>

    <div id="seacrh-wrap" class="seacrh-wrap" @dragend="enddrag">
      <transition name="slide-fade">
        <div v-show="showDialog" id="seacrh-dialog" class="seacrh-dialog">
          <input
            id="seacrh-dialog-search_input"
            v-model="search_input"
            style="font-size: 16px; border: none; text-indent: 16px"
            :placeholder="$t('mindmap.search')"
          />
          <span v-show="search_input.length > 0" style="font-size: 16px"
            >{{ search_value_now + 1 }} / {{ search_value_list.length }}</span
          >
          <div style="border-left: 2.5px solid #dddddd">
            <i class="el-icon-arrow-down" @click="serach_down"></i>
            <i class="el-icon-arrow-up" @click="serach_up"></i>
            <i class="el-icon-close" @click="serach_clear(false)"></i>
          </div>
        </div>
      </transition>
    </div>
    <!-- 移动文件弹窗 -->
    <source-node-sync-dialog
      ref="movedialog"
      :sourceNode="sourceNode"
      :nodeInfo="node_info"
    ></source-node-sync-dialog>

    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="show_butongguo_all"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i>
      <!-- <span>&nbsp;全部选择了通过，确定继续吗？</span> -->
      <span>&nbsp;{{ $t("canvas.reviewRequestForm.all_yes") }}</span>

      <!-- <span>&nbsp;{{ $t("addMindMap.p1") }}</span> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="show_butongguo_all = false">
          {{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button type="primary" @click="reject_review_butongguo">
          {{ $t("btn.confirmBtn") }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="show_tongguo_all"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i>
      <!-- <span>&nbsp;全部选择了不通过，确定继续吗？</span> -->
      <span>&nbsp;{{ $t("canvas.reviewRequestForm.all_no") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show_tongguo_all = false">
          {{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button type="primary" @click="process_approved_review_tongguo">
          {{ $t("btn.confirmBtn") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TopBar from "@/components/topbar";
import RightClick from "@/views/mindWord/RightClick";
import NodeStatusSelect from "@/components/mindmap/node_detail/NodeStatusSelect.vue";
import {
  getFile,
  getReleaseElements,
  nodeOrigins,
} from "@/network/home/index.js";
import NodeDescriptionRow from "@/components/mindmap/node_detail/NodeDescriptionRow.vue";
import Drawio from "@/components/mindmap/node_detail/Drawio.vue";
import HomeTopbar from "@/components/homeTopbar";
import { aiAutoExtendPayFeatureStatus } from "@/network/ai/index.js";
import { nodeAutoExtend, delete_node } from "@/network/mindmap/index.js";
import { ossAuthorization } from "@/utils/oss";
import { get_filetype_List } from "@/network/fileType/index.js";
import ViewWorkflow from "@/components/mindmap/ViewWorkflow";
import NodeDrawer from "@/components/nodeDrawer";
import { mapGetters } from "vuex";
import { node_location_change } from "@/network/node/index.js";
import { get_node_testPlans, only_set_property } from "@/network/node/index.js";
import {
  get_fileType_coverage,
  get_file_coverage,
} from "@/network/coverage/index.js";
import { ed } from "@/network/guide/index.js";
import {
  set_workflow,
  set_workflow_myself,
  get_workflow_list,
  get_node_use_node_key,
  get_allProjectMember,
  get_node_nodeOriginInfo,
} from "@/network/node/index.js";
import { add_node } from "@/network/mindmap";
import NodeReleaseInfoRow from "@/components/mindmap/node_detail/NodeReleaseInfoRow.vue";
import NodeBaseInfoRow from "@/components/mindmap/node_detail/NodeBaseInfoRow.vue";
import { getNodeReviewInfo } from "@/network/collaboration/index.js";
import PersonalAvatar from "@/components/personal";
import {
  ignoreNodeCoverage,
  getNodeCoverage,
  deleteNodeCoverage,
} from "@/network/coverage/index.js";
import vmson from "@/utils/vmson";
import {
  get_file_review,
  get_review_config,
  processReview,
  get_file_process,
  get_user_review,
} from "@/network/review/index.js";
import sourceNodeSyncDialog from "@/components/sourceNodeSyncDialog";
export default {
  name: "Lineword",
  components: {
    TopBar,
    NodeDescriptionRow,
    Drawio,
    HomeTopbar,
    NodeStatusSelect,
    NodeReleaseInfoRow,
    ViewWorkflow,
    PersonalAvatar,
    NodeDrawer,
    NodeBaseInfoRow,
    RightClick,
    sourceNodeSyncDialog,
  },
  data() {
    return {
      reason_zdh: "",
      show_butongguo_all: false,
      show_tongguo_all: false,
      // 批量复用相关
      sourceNode: {},
      node_info: {},
      noreused: [],
      //
      f5: 0,
      data: {},
      node: {},
      allowedBatchExec: false,
      itemdata: "",
      once: [],
      current: 0,
      visiblelist: false,
      showCollapse: false,
      original_data: [],
      fileData: {},
      show_detail_map: {},
      edit_map: {},
      timer: null,
      collapse_button_text: this.$t("canvas.wordView.unfoldBtn"),
      collapse_flag: false,
      basedatalength: 0,
      current_key: "",
      word_all_nodes: {},
      current_node: [],
      handle_node: {},
      client: null,
      // ai补充
      drawer: false,
      drawer_loading: false,
      // 被锁定的基线列表
      file_icon_name: "",
      file_icon: "",
      is_show_name: true,
      is_show_topic: true,
      node_name_span: true,
      input_name: "",
      workflow_dialog_visible: false,
      membersShow: false,
      members: [],
      rootVisible: false,
      nodeVisible: false,
      childVisible: false,
      caseVisible: false,
      caseword: "",
      is_edit: false,
      index_map: {},
      review_map: {},
      process_review_map: {},
      f6: 0,
      f7: 0,
      show_reject_form: false,
      reject_form: {},
      show_approved_form: false,
      approved_form: {},
      see_review: false,
      process_review: false,
      user_review_list: [],
      show_review_num: false,
      review_list: [],
      review_num_process: 0,
      review_num_all: 0,
      is_show_no_review_nodes: false,
      review_detail_show: false,
      review_detail_data: {},
      review_detail_list: [],
      outstanding_detail_dialog_show: false,
      outstanding_map: {},
      outstanding_type: "",
      outstanding_name: "",
      otherFile_map: {},
      only_show_node: false,
      // 保存编辑节点的值
      input_value: "",
      showDialog: false,
      search_input: "",
      search_value_list: [],
      search_value_now: -1,
      clicktimeOut: null,
      item_require_list: {},
      item_require_list_cjeckout: {},
      refresh_table: 0,
      multi_pingshen_data: null,
      has_multi_pingshen: false,
    };
  },
  computed: {
    ...mapGetters(["status", "fileType", "user_list", "jobAuthorities"]),
    fileId() {
      return document
        .getElementById("global_variable_fileId")
        .innerText.replace(/^\s*|\s*$/g, "");
    },
    showTestPlan() {
      if (
        this.original_data[0] &&
        this.original_data[0].fileTypeId &&
        this.fileType
      ) {
        return this.fileType[this.original_data[0].fileTypeId]
          ? this.fileType[this.original_data[0].fileTypeId].testCase
          : false;
      }
      return false;
    },
    delete_dig() {
      return (
        this.rootVisible ||
        this.nodeVisible ||
        this.childVisible ||
        this.caseVisible
      );
    },
    projectId() {
      return this.get_pid();
    },
    tree_data_line_word() {
      const arr = [];
      function search_children(data, root) {
        const items = [];
        for (let i = 0; i < data.length; i++) {
          if (root == data[i].parentid) {
            data[i].children = search_children(data, data[i].id);
            const temp_item = Object.assign(data[i], {
              label: data[i].topic,
              attachments: data[i].attachments,
            });
            items.push(temp_item);
          }
        }
        return items;
      }
      this.original_data.forEach((item) => {
        if (item.isroot) {
          arr.push(
            Object.assign(item, {
              label: item.topic,
              attachments: item.attachments,
            })
          );
          arr[0].children = search_children(this.original_data, "root");
        }
      });
      return arr;
    },
  },
  watch: {
    search_input() {
      this.highlightText();
    },
    current_node() {},
    tree_data_line_word() {
      let d =
        this.tree_data_line_word.length > 0
          ? this.tree_data_line_word[0].children
          : [];
      let m = {};
      m["root"] = "1";
      d = JSON.parse(JSON.stringify(d));
      while (d.length > 0) {
        let n = d.shift();
        if (n.layer < 5) {
          m[n.id] = m[n.parentid] + "." + n.index;
          d = [...d, ...(n.children || [])];
        }
      }
      this.index_map = m;
      this.f6 += 1;
    },
  },
  mounted() {
    let that = this;
    document.onkeydown = (e) => {
      if (this.see_review || this.process_review) {
        return;
      }
      if (
        e.key == "Enter" &&
        !that.drawer &&
        !that.is_edit &&
        e.target.nodeName != "TEXTAREA" &&
        that.current_node.length > 0 &&
        (e.target.nodeName != "INPUT" || e.target.type == "checkbox")
      ) {
        if (that.current_node.length > 1) {
          that.$message({
            message: that.$t("canvas.clone.tip4"),
            type: "warning",
          });
          return false;
        }
        let flag = false;
        if (that.rootVisible) {
          flag = true;
          that.rootVisible = false;
        }
        if (that.nodeVisible) {
          flag = true;
          that.Delete_node();
          that.nodeVisible = false;
        }
        if (that.childVisible) {
          flag = true;
          that.Delete_node();
          that.childVisible = false;
        }
        if (that.caseVisible) {
          flag = true;
          that.Delete_node();
          that.caseVisible = false;
        }
        if (!flag) {
          if (that.current_node[0]) {
            if (that.current_node[0].id == "root") {
              that.$message({
                message: that.$t("addMindMap.message21"),
                type: "warning",
              });
            } else {
              that.Add_node(e.key);
            }
          }
        }
      }
      if (
        e.key == "Tab" &&
        !that.drawer &&
        !that.is_edit &&
        that.current_node[0] &&
        e.target.nodeName != "TEXTAREA" &&
        that.current_node.length > 0 &&
        (e.target.nodeName != "INPUT" || e.target.type == "checkbox")
      ) {
        if (that.current_node.length > 1) {
          that.$message({
            message: that.$t("canvas.clone.tip4"),
            type: "warning",
          });
          return false;
        }
        that.Add_node(e.key);
      }
      if (
        (e.key == "Delete" || e.key == "Backspace") &&
        that.current_node.length > 0 &&
        !that.drawer &&
        !that.is_edit &&
        e.target.nodeName != "TEXTAREA" &&
        that.current_node[0] &&
        (e.target.nodeName != "INPUT" || e.target.type == "checkbox")
      ) {
        if (that.current_node.length > 1) {
          that.$message({
            message: that.$t("canvas.clone.tip3"),
            type: "warning",
          });
          return false;
        }
        that.delete_dialog();
      }
      if (this.see_review || this.process_review || this.is_edit) {
        return;
      }

      if ((e.key == "a" || e.key == "A ") && e.ctrlKey) {
        if (this.current_node.length > 0) {
          let parent_map = {};

          for (let i of Object.keys(this.word_all_nodes)) {
            if (
              this.word_all_nodes[i].parentid &&
              this.word_all_nodes[i].parentid.length > 1
            ) {
              if (this.word_all_nodes[i].parentid in parent_map) {
                parent_map[this.word_all_nodes[i].parentid].push(
                  this.word_all_nodes[i]
                );
              } else {
                parent_map[this.word_all_nodes[i].parentid] = [
                  this.word_all_nodes[i],
                ];
              }
            }
          }
          let stack = [];
          for (let i of this.current_node) {
            stack.push(i.id);
          }

          let l = [];
          let pointer = 0;
          while (stack.length > pointer) {
            let node = this.word_all_nodes[stack[pointer]];
            if (node.id in parent_map) {
              for (let i of parent_map[node.id]) {
                if (stack.indexOf(i.id) < 0) {
                  stack.push(i.id);
                }
              }
            }
            l.push(node);
            pointer++;
          }
          this.current_node = l;
          let ll = [];
          for (let i of this.current_node) {
            ll.push(i.id);
          }
          this.$refs.myTree.setCheckedKeys(ll);
        } else {
          let l = [];
          this.current_node = [];
          for (let i of Object.keys(this.word_all_nodes)) {
            l.push(i);
            this.current_node.push(this.word_all_nodes[i]);
          }
          this.$refs.myTree.setCheckedKeys(l);
        }
        e.preventDefault();
      }
    };
    document.getElementById("line_word_right").onkeydown = function (e) {
      if (this.see_review || this.process_review) {
        return;
      }
    };

    document.getElementById("line_word_wrap").addEventListener("click", (e) => {
      this.closeRightMethod();
    });

    ossAuthorization.call(this);
    this.get_file_data();
    this.bindMoveLine();
    this.get_file_type_list();
    get_allProjectMember(this.get_pid()).then((res) => {
      this.members = res;
      this.membersShow = true;

      if (this.$route.hash.length > 0 || this.$route.query.node_id) {
        let queryid = this.$route.query.node_id
          ? this.$route.query.node_id
          : this.$route.hash.slice(1);
        let timer = setInterval(() => {
          if (this.$refs.myTree.store._getAllNodes().length > 1) {
            clearInterval(timer);
            this.$refs.myTree.setCurrentKey(queryid);
            this.$set(this.show_detail_map, queryid, true);
            window.location.hash = queryid;
            this.$nextTick(() => {
              let elements = document.querySelectorAll(
                ".el-tree-node__content"
              );
              let result = [];
              elements.forEach(function (element) {
                if (element.parentNode.classList.contains("is-current")) {
                  result.push(element);
                }
              });
              if (result.length < 1) {
                return;
              }
              let click_dom = result[0];

              click_dom.scrollIntoView({
                block: "end",
                behavior: "smooth",
              });

              click_dom.classList.remove("light_back_cas");
              setTimeout(() => {
                click_dom.classList.add("light_back_cas");
              });
            });
          }
        }, 1000);
      }
    });

    vmson.$on("open_map_seaech_dig", () => {
      this.showDialogFn();
    });
    vmson.$on("many_pingshen_word", (data) => {
      let choose_key = [];

      console.log(this.current_node);

      for (let i of this.current_node) {
        choose_key.push(i.key);
      }

      const commonElements = choose_key.filter((element) =>
        this.user_review_list.includes(element)
      );

      if (commonElements.length == 0) {
        this.$message({
          message: this.$t("canvas.reviewRequestForm.tip11"),
          type: "warning",
        });
        return;
      }

      this.multi_pingshen_data = commonElements;
      this.has_multi_pingshen = true;
      if (data.type == "pass") {
        this.item_require_list = {};
        this.reason_zdh = "";
        this.show_approved_form = true;
      }

      if (data.type == "fail") {
        this.item_require_list = {};
        this.reason_zdh = "";
        this.show_reject_form = true;
      }
    });
    vmson.$on("change_topbar", (val) => {
      if (!val) {
        document.getElementsByClassName("catalog_wrap")[0].style["height"] =
          "calc(100vh - 60px)";
        document.getElementsByClassName("line_word")[0].style["height"] =
          "calc(100vh - 60px)";
      } else {
        document.getElementsByClassName("catalog_wrap")[0].style["height"] =
          "calc(88vh - 98px)";
        document.getElementsByClassName("line_word")[0].style["height"] =
          "calc(88vh - 98px)";
      }
    });

    vmson.$on("showReviewNode", (res) => {
      this.review_detail_list = [];
      this.is_show_no_review_nodes = false;
      res.forEach((item) => {
        this.review_map[item.nodeKey] = item;
        if (item.status == "UN_APPROVED" || item.status == "APPROVED") {
          this.review_detail_list.push(item.nodeKey);
        }
      });
      console.log(this.review_map);

      this.see_review = true;
      this.process_review = false;
      this.show_review_num = false;
      if (!this.fileId) {
        return;
      }
      get_user_review(this.get_pid(), this.fileId).then((res) => {
        this.user_review_list = res;
        this.review_num_all = res.length;
      });
      get_review_config(this.get_pid()).then((res) => {
        res.forEach((item) => {
          item.fileTypeList.forEach((type) => {
            if (type.fileTypeId == this.$store.getters.file_type_id) {
              this.allowedBatchExec = item.allowedBatchExec;
              this.review_list = item.itemList;
            }
          });
        });
      });
    });

    vmson.$on("closeReview", () => {
      this.see_review = false;
      this.process_review = false;
      this.show_review_num = false;
      this.is_show_no_review_nodes = false;
      this.review_detail_show = false;
    });

    vmson.$on("processReview", () => {
      this.review_detail_show = false;
      get_file_process(this.get_pid(), this.fileId).then((ress) => {
        ress.forEach((item) => {
          this.process_review_map[item.nodeKey] = item;
          this.item_require_list_cjeckout[item.nodeKey] = item.reviewItems;
        });

        get_user_review(this.get_pid(), this.fileId).then((res) => {
          this.user_review_list = res;
          this.review_num_all = res.length;
        });
        this.review_num_process = ress.length;
        this.process_review = true;
        this.show_review_num = true;
      });
      this.see_review = false;
      this.is_show_no_review_nodes = false;
    });

    vmson.$on("ignoreCoverage", (isIgnore) => {
      const func = isIgnore ? ignoreNodeCoverage : deleteNodeCoverage;
      let nodeKeys = [];
      for (let i of this.current_node) {
        nodeKeys.push(i.key);
      }
      if (nodeKeys.length == 0) {
        this.$message({
          message: this.$t("canvas.AI.tip5"),
          type: "warning",
        });
      } else {
        getNodeCoverage({
          projectId: this.get_pid(),
        }).then((res) => {
          // Filtering the node keys based on whether they should be ignored or not
          const filteredNodeKeys = nodeKeys.filter((nodeKey) =>
            isIgnore ? !res.includes(nodeKey) : res.includes(nodeKey)
          );

          if (filteredNodeKeys.length > 0) {
            const params = {
              projectId: this.get_pid(),
              body: filteredNodeKeys,
            };

            func(params).then((res) => {
              this.$message({
                message: this.$t("node.message.operationSuccess"),
                type: "success",
              });
              if (this.only_show_node || this.outstanding_detail_dialog_show) {
                vmson.$emit(
                  "handle_lineword_outstanding_click",
                  "$" + this.outstanding_type
                );
              }
            });
          } else {
            const message = isIgnore
              ? this.$t("node.message.nodeNotCovered")
              : this.$t("node.message.nodeCovered");
            this.$message({
              message,
              type: "warning",
            });
          }
        });
      }
    });

    vmson.$on("close_right_word", () => {
      this.get_file_data();
    });

    vmson.$on("nodeLocationChange", (val) => {
      this.change_location(val);
    });

    vmson.$on("handle_lineword_outstanding_click", (val) => {
      if (
        this.outstanding_type == val &&
        (this.outstanding_detail_dialog_show || this.only_show_node)
      ) {
        this.outstanding_type = "";
        this.only_show_node = false;
        this.outstanding_detail_dialog_show = false;
        return;
      }
      if (val[0] == "$") {
        val = val.slice(1, val.length);
      }
      this.only_show_node = false;
      this.outstanding_detail_dialog_show = false;
      this.outstanding_type = val;
      let stack = [this.tree_data_line_word[0]];
      let result_map = {};
      let all_nodes = [];
      while (stack.length > 0) {
        let n = stack.shift();
        all_nodes.push(n.key);
        if (n.children && n.children.length > 0) {
          stack = [...stack, ...n.children];
        }
        if (n[val] in result_map) {
          result_map[n[val]] += 1;
        } else {
          result_map[n[val]] = 1;
        }
      }
      this.outstanding_map = {};

      if (val == "status") {
        this.outstanding_name = this.$t("canvas.wordView.status");
        let color_map = {
          TODO: ["E87A90", "F759AB", "D75455", "FF4D4F", "FF7A45"],
          IN_PROGRESS: ["2651A6", "4968A6", "4B7BA6", "6B7FF2", "597EF7"],
          DONE: ["19BF5B", "55AA55", "0CAD0C", "23B85E", "2D882D"],
        };
        for (let i of Object.keys(result_map)) {
          this.outstanding_map[i] = {
            name: this.status[i].name,
            color: color_map[this.status[i].statusCategory].shift(),
            num: result_map[i],
            val: i,
          };
        }
        this.outstanding_detail_dialog_show = true;
      }

      if (val == "assignee") {
        this.outstanding_name = this.$t("canvas.wordView.assignee");

        let color_map = [
          "f07c82",
          "5c2223",
          "eeb8c3",
          "813c85",
          "0f59a4",
          "101f30",
          "134857",
          "1ba784",
          "248067",
          "8cc269",
          "fed71a",
          "835e1d",
          "f26b1f",
          "cf4813",
          "862617",
        ];
        for (let i of Object.keys(result_map)) {
          if (i != "null") {
            this.outstanding_map[i] = {
              name: this.user_list[i].nickname,
              color: color_map.shift(),
              num: result_map[i],
              val: i,
            };
          }
        }
        if ("null" in result_map) {
          this.outstanding_map["null"] = {
            name: this.$t("canvas.wordView.null"),
            color: "5f5e5f",
            num: result_map["null"],
            val: "null",
          };
        }
        this.outstanding_detail_dialog_show = true;
      }

      if (val == "sprintId") {
        this.outstanding_name = this.$t("canvas.wordView.sprintId");

        let sprint_list = Object.values(
          JSON.parse(sessionStorage.getItem("_sprint"))
        );
        let sprint_map = {};
        for (let i of sprint_list) {
          sprint_map[i.sprintId] = i;
        }
        let color_map = [
          "f07c82",
          "5c2223",
          "eeb8c3",
          "813c85",
          "0f59a4",
          "101f30",
          "134857",
          "1ba784",
          "248067",
          "8cc269",
          "fed71a",
          "835e1d",
          "f26b1f",
          "cf4813",
          "862617",
        ];
        for (let i of Object.keys(result_map)) {
          if (i != "undefined") {
            this.outstanding_map[i] = {
              name: sprint_map[i].name,
              color: color_map.shift(),
              num: result_map[i],
              val: i,
            };
          }
        }
        if ("undefined" in result_map) {
          this.outstanding_map["undefined"] = {
            name: this.$t("canvas.wordView.null"),
            color: "5f5e5f",
            num: result_map["undefined"],
            val: "null",
          };
        }
        this.outstanding_detail_dialog_show = true;
      }

      if (val.split("@")[0] == "otherFile") {
        this.otherFile_map = {};
        let stack = [this.tree_data_line_word[0]];
        let result_map = {};
        while (stack.length > 0) {
          let n = stack.shift();
          if (n.children && n.children.length > 0) {
            stack = [...stack, ...n.children];
          }
          let flag = true;
          for (let i of n.content) {
            if (i.fieldId == val.split("@")[1]) {
              this.outstanding_name = i.name;
              flag = false;
              this.otherFile_map[n.key] = i.value;
              if (i.value in result_map) {
                result_map[i.value] += 1;
              } else {
                result_map[i.value] = 1;
              }
            }
          }
          if (flag) {
            this.otherFile_map[n.key] = this.$t("node.message.message1");
            if (this.$t("node.message.message1") in result_map) {
              result_map[this.$t("node.message.message1")] += 1;
            } else {
              result_map[this.$t("node.message.message1")] = 1;
            }
          }
        }
        let color_map = [
          "f07c82",
          "5c2223",
          "eeb8c3",
          "813c85",
          "0f59a4",
          "101f30",
          "134857",
          "1ba784",
          "248067",
          "8cc269",
          "fed71a",
          "835e1d",
          "f26b1f",
          "cf4813",
          "862617",
        ];
        for (let i of Object.keys(result_map)) {
          if (i != "null") {
            this.outstanding_map[i] = {
              name: i,
              color: color_map.shift(),
              num: result_map[i],
              val: i,
            };
          }
        }
        if ("null" in result_map) {
          this.outstanding_map["null"] = {
            name: this.$t("canvas.wordView.null"),
            color: "5f5e5f",
            num: result_map["null"],
            val: null,
          };
        }
        this.outstanding_detail_dialog_show = true;
      }

      if (val == "required") {
        this.otherFile_map = {};
        let stack = [this.tree_data_line_word[0]];
        let all_node = 0;
        let has_required = 0;
        while (stack.length > 0) {
          let n = stack.shift();
          this.otherFile_map[n.key] = "no";
          all_node++;
          if (n.children && n.children.length > 0) {
            stack = [...stack, ...n.children];
          }
          for (let item of n.content) {
            if (item.required && (!item.value || item.value.length == 0)) {
              this.otherFile_map[n.key] = "has";
              has_required++;
              break;
            }
          }
        }
        this.outstanding_map["no"] = {
          name: "null",
          color: "66ccff",
          num: all_node - has_required,
        };
        this.outstanding_map["has"] = {
          name: "null",
          color: "fff",
          num: has_required,
        };
        this.$message({
          message:
            has_required > 0
              ? this.$t("canvas.messages.requiredWarn1")
              : this.$t("canvas.messages.requiredWarn2"),
          type: has_required > 0 ? "warning" : "success",
          duration: 5 * 1000,
        });
        this.only_show_node = true;
      }

      if (val.split("@")[0] == "coverage") {
        this.outstanding_name = this.$t("editFileType.coverage.title");
        this.otherFile_map = {};
        const params = {
          projectId: this.get_pid(),
        };
        getNodeCoverage(params).then((res) => {
          if (!res) {
            res = [];
          }
          // 获得已覆盖节点
          get_file_coverage(
            this.get_pid(),
            this.$route.params.file_key,
            val.split("@")[1]
          ).then((ress) => {
            let no_need = all_nodes.filter((node) => res.includes(node));
            let need = all_nodes.filter((node) => !res.includes(node));

            let need_has = need.filter((node) => ress.includes(node));
            let need_no = need.filter((node) => !ress.includes(node));

            this.outstanding_map["need_has"] = {
              name: this.$t("canvas.wordView.need_has"),
              color: "0CAD0C",
              num: need_has.length,
              val: "need_has",
            };
            this.outstanding_map["need_no"] = {
              name: this.$t("canvas.wordView.need_no"),
              color: "D75455",
              num: need_no.length,
              val: "need_no",
            };
            this.outstanding_map["no_need"] = {
              name: this.$t("canvas.wordView.no_need"),
              color: "5f5e5f",
              num: no_need.length,
              val: "no_need",
            };
            for (let i of no_need) {
              this.otherFile_map[i] = "no_need";
            }
            for (let i of need_no) {
              this.otherFile_map[i] = "need_no";
            }
            for (let i of need_has) {
              this.otherFile_map[i] = "need_has";
            }
            this.outstanding_detail_dialog_show = true;
          });
        });
      }
      if (val == "reuse") {
        this.outstanding_name = this.$t("canvas.viewDropDown.Reusesituation");
        const params = {
          projectId: this.get_pid(),
          data: {
            nodeKeyList: all_nodes,
          },
        };
        nodeOrigins(params).then((res) => {
          const suspect = [];
          const hassuspect = [];
          const consistent = [];
          const hasconsistent = [];
          let noreused = [];
          if (res.nodeOrigins) {
            all_nodes.forEach((nodeKey) => {
              let flag = false;
              res.nodeOrigins.forEach((item) => {
                if (item.newNodeKey === nodeKey) {
                  flag = true;
                  if (item.diff && hassuspect.indexOf(nodeKey) == -1) {
                    suspect.push(nodeKey);
                    hassuspect.push(nodeKey);
                  } else if (
                    !item.diff &&
                    hasconsistent.indexOf(nodeKey) == -1
                  ) {
                    consistent.push(nodeKey);
                    hasconsistent.push(nodeKey);
                  }
                }
              });
              if (!flag) {
                noreused.push(nodeKey);
              }
            });
          } else {
            noreused = JSON.parse(JSON.stringify(all_nodes));
          }
          this.outstanding_map["suspect"] = {
            name: this.$t("node.message.message7"),
            color: "rgb(232,122,144)",
            num: suspect.length,
            val: suspect,
          };
          this.outstanding_map["consistent"] = {
            name: this.$t("node.message.message8"),
            color: "#66ccff",
            num: consistent.length,
            val: consistent,
          };
          this.outstanding_map["noreused"] = {
            name: this.$t("node.message.message9"),
            color: "grey",
            num: noreused.length,
            val: noreused,
          };
          this.outstanding_detail_dialog_show = true;
          this.noreused = noreused;
        });
      }
      this.$nextTick(() => {
        this.$refs.outstanding_dialog_table_word.clearSelection();
      });
    });

    this.$nextTick(() => {
      if (
        this.$route.query.tool_tip == "global_tip_4" &&
        this.$route.path.indexOf("lineword") > -1
      ) {
        vmson.$emit("global_tip", "4@next");
      }
    });
  },
  beforeDestroy() {
    vmson.$off("many_pingshen_word");
    vmson.$off("handle_lineword_outstanding_click");
    vmson.$off("nodeLocationChange");
    vmson.$off("close_right_word");
    vmson.$off("ignoreCoverage");
    vmson.$off("processReview");
    vmson.$off("closeReview");
    vmson.$off("showReviewNode");
    vmson.$off("change_topbar");

    this.$vmson.$off("closeReview");
    this.$vmson.$off("processReview");
  },
  methods: {
    click_no_all() {
      this.review_list.forEach((item) => {
        this.item_require_list[item.itemId] = "no";
      });
      this.refresh_table++;
    },
    click_yes_all() {
      this.review_list.forEach((item) => {
        this.item_require_list[item.itemId] = "yes";
      });
      this.refresh_table++;
    },
    click_yes(itemId) {
      this.item_require_list[itemId] == "yes"
        ? (this.item_require_list[itemId] = "")
        : (this.item_require_list[itemId] = "yes");
      this.refresh_table++;
    },
    click_no(itemId) {
      this.item_require_list[itemId] == "no"
        ? (this.item_require_list[itemId] = "")
        : (this.item_require_list[itemId] = "no");
      this.refresh_table++;
    },
    check_all(a, b) {
      if (a == "yes") {
        this.review_list.forEach((item) => {
          this.item_require_list[item.itemId] = "yes";
        });
      }
      if (a == "no") {
        this.review_list.forEach((item) => {
          this.item_require_list[item.itemId] = "no";
        });
      }
      this.refresh_table++;
    },
    isElementInNonScrollableArea(el) {
      // 在可视区域内就不出发移动节点
      let nonScrollablePercentage = 60;
      let viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      let viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;

      let nonScrollableHeight =
        viewportHeight * (nonScrollablePercentage / 100);
      let nonScrollableWidth = viewportWidth * (nonScrollablePercentage / 100);

      let nonScrollableTop = (viewportHeight - nonScrollableHeight) / 2;
      let nonScrollableLeft = (viewportWidth - nonScrollableWidth) / 2;

      let rect = el.getBoundingClientRect();

      return (
        rect.top >= nonScrollableTop &&
        rect.left >= nonScrollableLeft &&
        rect.bottom <= nonScrollableTop + nonScrollableHeight &&
        rect.right <= nonScrollableLeft + nonScrollableWidth
      );
    },
    showDialogFn() {
      this.showDialog = !this.showDialog;
      this.serach_clear(this.showDialog);
      if (this.showDialog) {
        setTimeout(() => {
          document.getElementById("seacrh-dialog-search_input").focus();
        }, 100);
      }
      // 确定浮窗出现的位置
      let btn = document
        .getElementById("seacrh-button")
        .getBoundingClientRect();

      if (btn.left < 400 / (50 / btn.width)) {
        document.getElementById("seacrh-dialog").style.left = btn.width + "px";
      } else {
        document.getElementById("seacrh-dialog").style.left = "-450px";
      }
      document.getElementById("seacrh-dialog").style.top = "0";
    },
    enddrag(a, b) {
      document.getElementById("seacrh-wrap").style.top = a.pageY - 25 + "px";
      document.getElementById("seacrh-wrap").style.left = a.pageX - 25 + "px";
      document.getElementById("seacrh-wrap").style.bottom = a.pageY + 25 + "px";
      document.getElementById("seacrh-wrap").style.right = a.pageX + 25 + "px";
    },
    serach_clear(show_dig) {
      // 关闭搜索框
      this.showDialog = show_dig;
      this.search_input = "";
      let jmnodes = this.original_data;
      this.search_value_list = [];
      this.search_value_now = -1;

      jmnodes.forEach((ele) => {
        let jmnode = document.getElementById(ele.id);
        let array = [...jmnode.classList];
        array = array.filter(
          (item) =>
            item !== "serach_mindmap_node_highlight" &&
            item !== "serach_mindmap_node_highlight_selected"
        );
        jmnode.className = array.join(" ");
      });
    },
    serach_down() {
      // 搜索框  下一个

      if (this.search_value_list.length == 0) {
        // 没有搜索到内容
        return;
      }
      if (this.search_value_now > -1) {
        // 已经有搜索选中的节点的情况
        let array = [
          ...this.search_value_list[this.search_value_now].classList,
        ];
        array = array.filter(
          (item) => item !== "serach_mindmap_node_highlight_selected"
        );
        this.search_value_list[this.search_value_now].className =
          array.join(" ");
        this.search_value_now++;
        this.search_value_now == this.search_value_list.length
          ? (this.search_value_now = 0)
          : "";
        array = [...this.search_value_list[this.search_value_now].classList];
        array.push("serach_mindmap_node_highlight_selected");

        this.search_value_list[this.search_value_now].className =
          array.join(" ");
      } else {
        // 没有搜索选中的节点的情况

        this.search_value_now = 0;
        let array = [
          ...this.search_value_list[this.search_value_now].classList,
        ];
        array.push("serach_mindmap_node_highlight_selected");

        this.search_value_list[this.search_value_now].className =
          array.join(" ");
      }
      if (
        !this.isElementInNonScrollableArea(
          this.search_value_list[this.search_value_now]
        )
      ) {
        this.search_value_list[this.search_value_now].scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      }
    },

    serach_up() {
      // 搜索框  上一个

      if (this.search_value_list.length == 0) {
        return;
      }
      if (this.search_value_now > -1) {
        let array = [
          ...this.search_value_list[this.search_value_now].classList,
        ];
        array = array.filter(
          (item) => item !== "serach_mindmap_node_highlight_selected"
        );
        this.search_value_list[this.search_value_now].className =
          array.join(" ");
        this.search_value_now--;
        this.search_value_now == -1
          ? (this.search_value_now = this.search_value_list.length - 1)
          : "";
        array = [...this.search_value_list[this.search_value_now].classList];
        array.push("serach_mindmap_node_highlight_selected");

        this.search_value_list[this.search_value_now].className =
          array.join(" ");
      } else {
        this.search_value_now = this.search_value_list.length - 1;

        let array = [
          ...this.search_value_list[this.search_value_now].classList,
        ];
        array.push("serach_mindmap_node_highlight_selected");

        this.search_value_list[this.search_value_now].className =
          array.join(" ");
      }
      if (
        !this.isElementInNonScrollableArea(
          this.search_value_list[this.search_value_now]
        )
      ) {
        this.search_value_list[this.search_value_now].scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      }
    },
    highlightText() {
      // 高亮搜索到的节点
      let jmnodes = this.original_data;
      this.search_value_list = [];
      this.search_value_now = -1;
      jmnodes.forEach((ele) => {
        let jmnode = document.getElementById(ele.id);
        let array = [...jmnode.classList];
        array = array.filter(
          (item) =>
            item !== "serach_mindmap_node_highlight" &&
            item !== "serach_mindmap_node_highlight_selected"
        );
        if (
          jmnode.innerText.includes(this.search_input) &&
          this.search_input.length > 0
        ) {
          array.push("serach_mindmap_node_highlight");
          this.search_value_list.push(jmnode);
        }
        jmnode.className = array.join(" ");
      });
    },
    // 同步节点
    to_sourceNodeSync() {
      if (this.current_node.length) {
        let hasNouse = false;
        this.current_node.forEach((item) => {
          if (this.noreused.indexOf(item.key) !== -1) {
            hasNouse = true;
          }
        });
        if (hasNouse) {
          this.$message.warning(this.$t("node.message.message10"));
          return;
        }
        get_node_nodeOriginInfo(this.get_pid(), this.current_node[0].key).then(
          (res) => {
            if (res.sourceNode) {
              this.sourceNode = res.sourceNode;
              this.node_info = this.current_node[0];
              this.$nextTick(() => {
                this.$refs.movedialog.open(
                  this.current_node.map((item) => item.key)
                );
              });
            }
          }
        );
      } else {
        this.$message.warning(this.$t("canvas.AI.tip5"));
      }
    },
    getReuseColor(key) {
      for (let objkey in this.outstanding_map) {
        for (let i = 0; i < this.outstanding_map[objkey].val.length; i++) {
          if (this.outstanding_map[objkey].val[i] === key) {
            return this.outstanding_map[objkey].color;
          }
        }
      }
    },
    log(data) {
      return data;
    },
    sort_array_null(outstanding_map) {
      let arr = JSON.parse(JSON.stringify(outstanding_map));
      const valueToPutLast = "grey";

      arr.sort(function (a, b) {
        let aIsLast = a.color === valueToPutLast;
        let bIsLast = b.color === valueToPutLast;

        if (aIsLast && bIsLast) {
          return b.num - a.num;
        } else if (aIsLast) {
          return 1;
        } else if (bIsLast) {
          return -1;
        } else {
          return b.num - a.num;
        }
      });
      return arr;
    },
    sort_array(outstanding_map) {
      let arr = JSON.parse(JSON.stringify(outstanding_map));
      arr.sort(function (a, b) {
        return b.num - a.num;
      });
      return arr;
    },
    handle_select_outstanding(e) {
      this.$refs.myTree.setCheckedKeys([]);
      this.current_node = [];

      if (e.length == 0) {
        return;
      }
      if (this.outstanding_type == "status") {
        let val = [];
        for (let i of e) {
          val.push(i.val);
        }
        for (let i of Object.keys(this.word_all_nodes)) {
          if (val.indexOf(this.word_all_nodes[i].status) > -1) {
            this.current_node.push(this.word_all_nodes[i]);
          }
        }
        let l = [];
        for (let i of this.current_node) {
          l.push(i.id);
        }
        this.$refs.myTree.setCheckedKeys(l);
      }

      if (this.outstanding_type == "assignee") {
        let val = [];
        for (let i of e) {
          val.push(i.val);
        }
        for (let i of Object.keys(this.word_all_nodes)) {
          if (val.indexOf(this.word_all_nodes[i].assignee) > -1) {
            this.current_node.push(this.word_all_nodes[i]);
          }
          if (!this.word_all_nodes[i].assignee && val.indexOf("null") > -1) {
            this.current_node.push(this.word_all_nodes[i]);
          }
        }
        let l = [];
        for (let i of this.current_node) {
          l.push(i.id);
        }
        this.$refs.myTree.setCheckedKeys(l);
      }

      if (this.outstanding_type == "sprintId") {
        let val = [];
        for (let i of e) {
          val.push(i.val);
        }
        for (let i of Object.keys(this.word_all_nodes)) {
          if (val.indexOf(this.word_all_nodes[i].sprintId) > -1) {
            this.current_node.push(this.word_all_nodes[i]);
          }
          if (!this.word_all_nodes[i].sprintId && val.indexOf("null") > -1) {
            this.current_node.push(this.word_all_nodes[i]);
          }
        }
        let l = [];
        for (let i of this.current_node) {
          l.push(i.id);
        }
        this.$refs.myTree.setCheckedKeys(l);
      }

      if (this.outstanding_type.split("@")[0] == "coverage") {
        let val = [];
        for (let i of e) {
          val.push(i.val);
        }
        for (let i of Object.keys(this.word_all_nodes)) {
          if (
            val.indexOf(this.otherFile_map[this.word_all_nodes[i].key]) > -1
          ) {
            this.current_node.push(this.word_all_nodes[i]);
          }
        }

        let l = [];
        for (let i of this.current_node) {
          l.push(i.id);
        }
        this.$refs.myTree.setCheckedKeys(l);
      }

      if (this.outstanding_type.split("@")[0] == "otherFile") {
        let val = [];
        for (let i of e) {
          val.push(i.val);
        }
        for (let i of Object.keys(this.word_all_nodes)) {
          if (
            val.indexOf(this.otherFile_map[this.word_all_nodes[i].key]) > -1
          ) {
            this.current_node.push(this.word_all_nodes[i]);
          }
        }

        let l = [];
        for (let i of this.current_node) {
          l.push(i.id);
        }
        this.$refs.myTree.setCheckedKeys(l);
      }
      if (this.outstanding_type == "reuse") {
        let val = [];
        for (let i of e) {
          val.push(...i.val);
        }
        for (let i of Object.keys(this.word_all_nodes)) {
          if (val.indexOf(this.word_all_nodes[i].key) > -1) {
            this.current_node.push(this.word_all_nodes[i]);
          }
        }
        let l = [];
        for (let i of this.current_node) {
          l.push(i.id);
        }
        this.$refs.myTree.setCheckedKeys(l);
      }
    },
    handleDragOver(node, dropNode, event) {},
    handleDragEnd(node, dropNode, type, event) {
      let flag = true;
      let l = [];
      let ll = [...this.tree_data_line_word];
      let child_list = [];

      const params = {
        projectId: this.get_pid(),
        nodeKey: node.data.key,
        data: {
          parentId: "",
          fileKey: this.$route.params.file_key,
          previousId: "",
        },
      };

      while (flag) {
        l = ll;
        ll = [];
        for (let i of l) {
          for (let j of i.children) {
            if (j.id == node.data.id) {
              child_list = i.children;
              params.data.parentId = i.id;
              flag = false;
            }
          }
          ll = [...ll, ...i.children];
        }
      }

      l = child_list;

      let index = -1;
      for (let i in l) {
        if (l[i].id == node.data.id) {
          index = i;
        }
      }
      index = Number(index);

      if (index == 0) {
        params.data.previousId = "";
      } else {
        params.data.previousId = l[index - 1].id;
      }

      node_location_change(params).then(() => {
        this.get_file_data();
      });
    },
    allowDrag(node) {
      if (node.data.lock) {
        // 锁定的节点不能移动
        this.$message({
          message: this.$t("canvas.tip10"),
          type: "warning",
        });
        return false;
      }
      if (node.data.id == "root") {
        // 锁定的节点不能移动
        this.$message({
          message: this.$t("canvas.tip11"),
          type: "warning",
        });
        return false;
      }
      return true;
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.id == "root" && type == "prev") {
        return false;
      }
      return true;
    },
    change_location(direction) {
      setTimeout(() => {
        let node = this.current_node[0];
        //请选中  一   个节点
        if (this.current_node.length > 1) {
          this.$message({
            message: this.$t("addMindMap.message24"),
            type: "warning",
          });
          return;
        }
        if (!(this.current_node.length == 1)) {
          this.$message({
            message: this.$t("canvas.tip12"),
            type: "warning",
          });
          return;
        }

        //根节点不能移动
        if (node.id == "root") {
          this.$message({
            message: this.$t("canvas.tip11"),
            type: "warning",
          });
          return;
        }
        // 锁定的节点不能移动
        if (this.$refs.myTree.getNode(node.id).data.lock) {
          this.$message({
            message: this.$t("canvas.tip10"),
            type: "warning",
          });
          return;
        }

        let flag = true;
        let l = [];
        let ll = [...this.tree_data_line_word];

        let child_list = [];

        while (flag) {
          l = ll;
          ll = [];
          for (let i of l) {
            for (let j of i.children) {
              if (j.id == node.id) {
                child_list = i.children;
                flag = false;
              }
            }
            ll = [...ll, ...i.children];
          }
        }

        const params = {
          projectId: this.get_pid(),
          nodeKey: node.key,
          data: {
            parentId: node.parentid,
            fileKey: this.$route.params.file_key,
            previousId: "",
          },
        };

        l = child_list;

        let index = -1;
        for (let i in l) {
          if (l[i].id == node.id) {
            index = i;
          }
        }
        index = Number(index);

        if (direction == "down") {
          if (index == l.length - 1) {
            return;
          } else {
            params.data.previousId = l[index + 1].id;
          }
        }

        if (direction == "up") {
          if (index == 0) {
            return;
          } else if (index == 1) {
            params.data.previousId = "";
          } else {
            params.data.previousId = l[index - 2].id;
          }
        }

        // this.clearCtrlXFn();

        node_location_change(params).then(() => {
          this.get_file_data();
        });
      }, 200);
    },
    get_right_data(data) {
      let l = [];
      for (let i of data) {
        l.push({ data: i });
      }
      return l;
    },
    open_right_meau(e, data, node) {
      if (this.see_review) {
        return;
      }
      if (this.current_node.length < 1) {
        this.current_node.push(node.data);
        this.$refs.myTree.setCheckedKeys([node.data.id]);
      }
      this.$refs.rightWindow.$el.style.display = "block";
      const docx = document.body.clientWidth;
      const docy = document.body.clientHeight;
      if (e.clientY + 400 > docy) {
        this.$refs.rightWindow.$el.style.top = e.clientY + 35 - 400 + "px";
      } else {
        this.$refs.rightWindow.$el.style.top = e.clientY + 35 + "px";
      }
      if (e.clientX + 300 > docx) {
        this.$refs.rightWindow.$el.style.left = e.clientX - 10 - 300 + "px";
      } else {
        this.$refs.rightWindow.$el.style.left = e.clientX - 10 + "px";
      }

      this.$refs.rightWindow.buildTree(node);
    },
    clear_checked() {
      this.$refs.myTree.setCheckedKeys([]);
      this.current_node = [];
    },
    reset_checked() {
      let l = [];
      for (let i of this.current_node) {
        l.push(i.id);
      }
      this.$refs.myTree.setCheckedKeys(l);
    },
    choose(data) {
      this.closeRightMethod();
      // let event = window.event || arguments.callee.caller.arguments[0];
      // this.current_node = [data];
    },
    handleNodeKeydown(node, event) {
      event.preventDefault();
    },
    treeNodeClick(data) {
      let event = window.event || arguments.callee.caller.arguments[0];

      if (
        event.key == "Enter" ||
        event.srcElement.tagName == "I" ||
        this.see_review
      ) {
        this.reset_checked();
        return;
      }

      this.current_node = [];
      for (let i of this.$refs.myTree.getCheckedKeys()) {
        this.current_node.push(this.word_all_nodes[i]);
      }
    },
    closeRightMethod() {
      let right_cl = document.getElementById("right_c_w");
      right_cl.style.display = "none";
    },
    resetChecked() {
      if (event.target.nodeName === "SPAN") {
        event.target.parentNode.blur();
      } else {
        event.target.blur();
      }
      this.current_node = [];
      this.$refs.myTree.setCheckedKeys([]);
    },
    handleCheckChange(data, checked, indeterminate) {
      this.$refs.myTree.setCheckedKeys([data.id]);
    },
    modify_table_data(data) {
      let l = [];
      if (this.outstanding_type == "reuse") {
        l.push(data.suspect);
        l.push(data.consistent);
        l.push(data.noreused);
        return l;
      }
      let null_flag = null;
      for (let i of Object.keys(data)) {
        if (data[i].color == "5f5e5f") {
          null_flag = data[i];
        } else {
          l.push(data[i]);
        }
      }
      l.sort(function (a, b) {
        return b.num - a.num;
      });
      if (null_flag) {
        l.push(null_flag);
      }
      return l;
    },
    get_calc_red() {
      let container = document.querySelector("#pingshen-avatar-red");
      if (container) {
        let items = container.children;
        if (items.length > 0) {
          let count = 0;
          let offset = items[0].offsetTop;
          for (let i = 0; i < items.length; i++) {
            let currentOffset = items[i].offsetTop;
            if (currentOffset == offset) {
              count++;
            } else {
              break;
            }
          }
          let n = 0;
          let res = "";
          if (this.review_detail_data) {
            this.review_detail_data.reject.forEach((item) => {
              if (n >= count) {
                res += this.user_list[item.person].nickname + ", ";
              }
              n++;
            });
          }
          res = res.slice(0, -2);
          return res;
        }
      }
      return "";
    },
    get_calc_green() {
      let container = document.querySelector("#pingshen-avatar-green");
      if (container) {
        let items = container.children;
        if (items.length > 0) {
          let count = 0;
          let offset = items[0].offsetTop;
          for (let i = 0; i < items.length; i++) {
            let currentOffset = items[i].offsetTop;
            if (currentOffset == offset) {
              count++;
            } else {
              break;
            }
          }
          let n = 0;
          let res = "";
          if (this.review_detail_data) {
            this.review_detail_data.approve.forEach((item) => {
              if (n >= count) {
                res += this.user_list[item].nickname + ", ";
              }
              n++;
            });
          }
          res = res.slice(0, -2);
          return res;
        }
      }
      return "";
    },
    calc_dot(id) {
      let dom = document.getElementById(id);
      if (dom) {
        if (dom.clientHeight > 30) {
          return true;
        }
      }
      return false;
    },
    clear_show_no_review_nodes() {
      this.is_show_no_review_nodes = false;
    },

    show_no_review_nodes() {
      this.is_show_no_review_nodes = true;

      this.$nextTick(() => {
        let dom = document.querySelectorAll(".review_orange");
        if (dom.length > 0) {
          dom[0].scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }
      });
    },
    high_ligth_review(d) {
      if (this.see_review || this.process_review) {
        if (this.user_review_list && this.user_review_list.includes(d.key)) {
          if (this.is_show_no_review_nodes) {
            if (!this.process_review_map[d.key]) {
              return "color:orange";
            } else {
              return "";
            }
          } else {
            return "";
          }
        }
      }
      return "";
    },
    high_ligth_review_class(d) {
      if (this.see_review || this.process_review) {
        if (this.user_review_list && this.user_review_list.includes(d.key)) {
          if (this.is_show_no_review_nodes) {
            if (!this.process_review_map[d.key]) {
              return "review_orange";
            } else {
              return "";
            }
          } else {
            return "";
          }
        }
      }
      return "";
    },
    is_review_check_red(key) {
      // false : kongbai
      if (this.process_review_map[key]) {
        if (this.process_review_map[key].approved) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    is_review_check_green(key) {
      // false : kongbai
      if (this.process_review_map[key]) {
        if (this.process_review_map[key].approved) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    process_reject_review() {
      let falg = 0;

      this.review_list.forEach((item) => {
        if (item.itemId in this.item_require_list) {
          if (this.item_require_list[item.itemId].length > 0) {
            falg++;
          }
        }
      });
      if (falg < this.review_list.length) {
        this.$message.warning(this.$t("canvas.reviewRequestForm.tip7"));
        return false;
      }
      // if (this.reject_form.reason.length < 1) {
      //   this.$message.warning(this.$t("canvas.reviewRequestForm.tip1"));
      //   return false;
      // }
      let l = [];
      for (let i of Object.keys(this.item_require_list)) {
        if (this.item_require_list[i] == "yes") {
          l.push(i);
        }
      }
      if (this.review_list.length == l.length) {
        this.show_butongguo_all = true;
        return;
      }
      this.reject_form.reviewItems = l;
      this.show_reject_form = false;

      let d = {};
      if (this.has_multi_pingshen) {
        let zdh_reviewItems = {};

        for (let i in this.review_map) {
          zdh_reviewItems[this.review_map[i].reviewProcessId] = [];
        }

        for (let i of this.multi_pingshen_data) {
          zdh_reviewItems[this.review_map[i].reviewProcessId].push(i);
        }

        let zdh_review = [];

        for (let i in zdh_reviewItems) {
          zdh_review.push({
            reviewProcessId: i,
            nodeKeyList: zdh_reviewItems[i],
          });
        }

        d = {
          fileId: this.fileId,
          nodeKey: this.multi_pingshen_data,
          reviewNodeList: zdh_review,
          reason: this.reason_zdh,
          approved: false,
          reviewItems: l,
        };
      } else {
        d = {
          fileId: this.fileId,
          nodeKey: [this.reject_form.nodeKey],
          reviewNodeList: [
            {
              reviewProcessId: this.reject_form.reviewProcessId,
              nodeKeyList: [this.reject_form.nodeKey],
            },
          ],
          reason: this.reason_zdh,
          approved: false,
          reviewItems: l,
        };
      }

      // console.log(d);
      // if (true){
      //   return
      // }

      processReview(this.get_pid(), d).then((r) => {
        for (let i of r) {
          if (i.ended) {
            if (this.$route.query.process_review) {
              this.$router.go(0);
            } else {
              this.$router.push({
                path: `/${this.get_pid()}/myMind/${
                  this.$route.params.file_key
                }`,
                query: { process_review: "process_review" },
              });
            }
          }
        }
        get_file_process(this.get_pid(), this.fileId).then((ress) => {
          ress.forEach((item) => {
            this.process_review_map[item.nodeKey] = item;
            this.item_require_list_cjeckout[item.nodeKey] = item.reviewItems;
          });
          this.review_num_process = ress.length;

          this.f6++;
        });
      });
    },
    reject_review_butongguo() {
      this.show_reject_form = false;
      this.show_butongguo_all = false;
      let l = [];
      for (let i of Object.keys(this.item_require_list)) {
        if (this.item_require_list[i] == "yes") {
          l.push(i);
        }
      }
      this.reject_form.reviewItems = l;
      let d = {};
      if (this.has_multi_pingshen) {
        let zdh_reviewItems = {};

        for (let i in this.review_map) {
          zdh_reviewItems[this.review_map[i].reviewProcessId] = [];
        }

        for (let i of this.multi_pingshen_data) {
          zdh_reviewItems[this.review_map[i].reviewProcessId].push(i);
        }

        let zdh_review = [];

        for (let i in zdh_reviewItems) {
          zdh_review.push({
            reviewProcessId: i,
            nodeKeyList: zdh_reviewItems[i],
          });
        }

        d = {
          fileId: this.fileId,
          nodeKey: this.multi_pingshen_data,
          reviewNodeList: zdh_review,
          reason: this.reason_zdh,
          approved: false,
          reviewItems: l,
        };
      } else {
        d = {
          fileId: this.fileId,
          nodeKey: [this.reject_form.nodeKey],
          reviewNodeList: [
            {
              reviewProcessId: this.reject_form.reviewProcessId,
              nodeKeyList: [this.reject_form.nodeKey],
            },
          ],
          reason: this.reason_zdh,
          approved: false,
          reviewItems: l,
        };
      }
      processReview(this.get_pid(), d).then((r) => {
        for (let i of r) {
          if (i.ended) {
            if (this.$route.query.process_review) {
              this.$router.go(0);
            } else {
              this.$router.push({
                path: `/${this.get_pid()}/myMind/${
                  this.$route.params.file_key
                }`,
                query: { process_review: "process_review" },
              });
            }
          }
        }
        get_file_process(this.get_pid(), this.fileId).then((ress) => {
          ress.forEach((item) => {
            this.process_review_map[item.nodeKey] = item;
            this.item_require_list_cjeckout[item.nodeKey] = item.reviewItems;
          });
          this.review_num_process = ress.length;

          this.f6++;
        });
      });
    },
    approve_review(data) {
      let d = {
        fileId: this.fileId,
        nodeKey: data.key,
        reviewProcessId: this.review_map[data.key].reviewProcessId,
        reason: "",
        approved: true,
      };

      processReview(this.get_pid(), d).then((r) => {
        if (!r) {
          get_file_process(this.get_pid(), this.fileId).then((ress) => {
            ress.forEach((item) => {
              this.process_review_map[item.nodeKey] = item;
              this.item_require_list_cjeckout[item.nodeKey] = item.reviewItems;
            });
            this.review_num_process = ress.length;

            this.f6++;
          });
        } else {
          if (this.$route.query.process_review) {
            this.$router.go(0);
          } else {
            this.$router.push({
              path: `/${this.get_pid()}/lineword/${
                this.$route.params.file_key
              }`,
              query: { process_review: "process_review" },
            });
            this.$router.go(0);
          }
        }
      });
    },
    process_approved_review() {
      let falg = 0;

      this.review_list.forEach((item) => {
        if (item.itemId in this.item_require_list) {
          if (this.item_require_list[item.itemId].length > 0) {
            falg++;
          }
        }
      });
      if (falg < this.review_list.length) {
        this.$message.warning(this.$t("canvas.reviewRequestForm.tip7"));
        return false;
      }
      // if (this.approved_form.reason.length < 1) {
      //   this.$message.warning(this.$t("canvas.reviewRequestForm.tip1"));
      //   return false;
      // }
      let l = [];
      for (let i of Object.keys(this.item_require_list)) {
        if (this.item_require_list[i] == "yes") {
          l.push(i);
        }
      }
      if (0 == l.length) {
        this.show_tongguo_all = true;
        return;
      }
      this.approved_form.reviewItems = l;
      this.show_approved_form = false;
      console.log(this.approved_form);

      let d = {};
      if (this.has_multi_pingshen) {
        let zdh_reviewItems = {};

        for (let i in this.review_map) {
          zdh_reviewItems[this.review_map[i].reviewProcessId] = [];
        }

        for (let i of this.multi_pingshen_data) {
          zdh_reviewItems[this.review_map[i].reviewProcessId].push(i);
        }

        let zdh_review = [];

        for (let i in zdh_reviewItems) {
          zdh_review.push({
            reviewProcessId: i,
            nodeKeyList: zdh_reviewItems[i],
          });
        }

        d = {
          fileId: this.fileId,
          nodeKey: this.multi_pingshen_data,
          reviewNodeList: zdh_review,
          reason: this.reason_zdh,
          approved: true,
          reviewItems: l,
        };
      } else {
        d = {
          fileId: this.fileId,
          nodeKey: [this.approved_form.nodeKey],
          reviewNodeList: [
            {
              reviewProcessId: this.approved_form.reviewProcessId,
              nodeKeyList: [this.approved_form.nodeKey],
            },
          ],
          reason: this.reason_zdh,
          approved: true,
          reviewItems: l,
        };
      }
      processReview(this.get_pid(), d).then((r) => {
        for (let i of r) {
          if (i.ended) {
            if (this.$route.query.process_review) {
              this.$router.go(0);
            } else {
              this.$router.push({
                path: `/${this.get_pid()}/myMind/${
                  this.$route.params.file_key
                }`,
                query: { process_review: "process_review" },
              });
            }
          }
        }
        get_file_process(this.get_pid(), this.fileId).then((ress) => {
          ress.forEach((item) => {
            this.process_review_map[item.nodeKey] = item;
            this.item_require_list_cjeckout[item.nodeKey] = item.reviewItems;
          });
          this.review_num_process = ress.length;

          this.f6++;
        });
      });
    },
    process_approved_review_tongguo() {
      this.show_tongguo_all = false;

      this.show_approved_form = false;
      let l = [];
      for (let i of Object.keys(this.item_require_list)) {
        if (this.item_require_list[i] == "yes") {
          l.push(i);
        }
      }
      this.approved_form.reviewItems = l;
      let d = {};
      console.log(this.approved_form);

      if (this.has_multi_pingshen) {
        let zdh_reviewItems = {};

        for (let i in this.review_map) {
          zdh_reviewItems[this.review_map[i].reviewProcessId] = [];
        }

        for (let i of this.multi_pingshen_data) {
          zdh_reviewItems[this.review_map[i].reviewProcessId].push(i);
        }

        let zdh_review = [];

        for (let i in zdh_reviewItems) {
          zdh_review.push({
            reviewProcessId: i,
            nodeKeyList: zdh_reviewItems[i],
          });
        }

        d = {
          fileId: this.fileId,
          nodeKey: this.multi_pingshen_data,
          reviewNodeList: zdh_review,
          reason: this.reason_zdh,
          approved: true,
          reviewItems: l,
        };
      } else {
        d = {
          fileId: this.fileId,
          nodeKey: [this.approved_form.nodeKey],
          reviewNodeList: [
            {
              reviewProcessId: this.approved_form.reviewProcessId,
              nodeKeyList: [this.approved_form.nodeKey],
            },
          ],
          reason: this.reason_zdh,
          approved: true,
          reviewItems: l,
        };
      }
      processReview(this.get_pid(), d).then((r) => {
        for (let i of r) {
          if (i.ended) {
            if (this.$route.query.process_review) {
              this.$router.go(0);
            } else {
              this.$router.push({
                path: `/${this.get_pid()}/myMind/${
                  this.$route.params.file_key
                }`,
                query: { process_review: "process_review" },
              });
            }
          }
        }
        get_file_process(this.get_pid(), this.fileId).then((ress) => {
          ress.forEach((item) => {
            this.process_review_map[item.nodeKey] = item;
            this.item_require_list_cjeckout[item.nodeKey] = item.reviewItems;
          });
          this.review_num_process = ress.length;

          this.f6++;
        });
      });
    },
    approve_review_reason(data) {
      let d = {
        fileId: this.fileId,
        nodeKey: data.key,
        reviewProcessId: this.review_map[data.key].reviewProcessId,
        reason: "",
        approved: true,
      };
      this.approved_form = d;
      this.item_require_list = {};
      this.reason_zdh = "";
      if (d.nodeKey in this.item_require_list_cjeckout) {
        this.reason_zdh = this.process_review_map[d.nodeKey].reason;
        if (
          this.item_require_list_cjeckout[d.nodeKey] &&
          this.item_require_list_cjeckout[d.nodeKey].length > 0
        ) {
          this.review_list.forEach((item) => {
            this.item_require_list[item.itemId] = "no";
          });
          this.item_require_list_cjeckout[d.nodeKey].forEach((item) => {
            this.item_require_list[item] = "yes";
          });
        }
      }
      this.has_multi_pingshen = false;
      this.show_approved_form = true;
    },
    reject_review(data) {
      let d = {
        fileId: this.fileId,
        nodeKey: data.key,
        reviewProcessId: this.review_map[data.key].reviewProcessId,
        reason: "",
        approved: false,
      };
      this.reject_form = d;
      this.item_require_list = {};
      this.reason_zdh = "";
      if (d.nodeKey in this.item_require_list_cjeckout) {
        if (
          this.item_require_list_cjeckout[d.nodeKey] &&
          this.item_require_list_cjeckout[d.nodeKey].length > 0
        ) {
          this.review_list.forEach((item) => {
            this.item_require_list[item.itemId] = "no";
          });
          this.item_require_list_cjeckout[d.nodeKey].forEach((item) => {
            this.item_require_list[item] = "yes";
          });
        }
        this.reason_zdh = this.process_review_map[d.nodeKey].reason;
      }
      this.has_multi_pingshen = false;
      this.show_reject_form = true;
    },
    get_review_class(data) {
      return this.review_map[data.key]
        ? this.review_map[data.key].status
        : "review_no";
    },
    update_current_node(e) {
      this.current_node = [e];
    },
    Add_node(key) {
      let mynode = this.current_node[0];
      if (this.jobAuthorities.indexOf("NODE_CREATE") === -1) {
        this.$message({
          type: "warning",
          message: this.$t("tip"),
        });
        return;
      }
      let parentid = mynode.id;
      let upNodeKey = null;
      if (key == "Enter") {
        // 获取当前节点
        const parentNode = this.$refs.myTree.getNode(
          this.current_node[0].id
        ).parent;
        upNodeKey = this.$refs.myTree.getNode(this.current_node[0].id).data.key;
        parentid = parentNode.data.id;
      }
      let newNode = {
        fileKey: this.$route.params.file_key,
        parentid: parentid,
        dataList: [
          {
            name: "New Node",
          },
        ],
      };
      if (upNodeKey) {
        newNode.upNodeKey = upNodeKey;
      }
      add_node(this.get_pid(), newNode).then((r) => {
        const get_file = getFile(this.get_pid(), this.$route.params.file_key);
        const get_release = getReleaseElements(
          this.get_pid(),
          this.$route.params.file_key
        );
        this.word_all_nodes = {};
        Promise.all([get_file, get_release]).then((result) => {
          result[0].data.forEach((item) => {
            result[1].forEach((i) => {
              if (i.nodeKey === item.key) {
                if (i.sprintId) {
                  item["sprintId"] = i.sprintId;
                }
                i.baselineItemDtoList &&
                  i.baselineItemDtoList.forEach((baseline) => {
                    if (
                      baseline.lock &&
                      baseline.status === "ALREADY_STARTED"
                    ) {
                      item["lock"] = baseline.lock;
                    }
                  });
              }
              this.word_all_nodes[item.id] = item;
            });
          });
          result[0].data.sort(function (m, n) {
            if (m.index < n.index) return -1;
            else if (m.index > n.index) return 1;
            else return 0;
          });
          this.original_data = result[0].data;
          this.edit_topic(r[0], true);
          window.location.hash = r[0].id;
          this.current_node = [r[0]];
          this.reset_checked();
          vmson.$emit("jmshow");
          if (this.show_review_num) {
            vmson.$emit("processReview");
          }
          if (this.only_show_node || this.outstanding_detail_dialog_show) {
            vmson.$emit(
              "handle_lineword_outstanding_click",
              "$" + this.outstanding_type
            );
          }
        });
      });
    },
    Delete_node() {
      delete_node(this.get_pid(), this.current_node[0].key).then((r) => {
        this.get_file_data();
        this.current_node = [];
        this.$message({
          message: this.$t("addMindMap.message17"),
          type: "success",
        });
        vmson.$emit("jmshow");
        if (this.show_review_num) {
          vmson.$emit("processReview");
        }
      });
      this.rootVisible = false;
      this.nodeVisible = false;
      this.childVisible = false;
      this.caseVisible = false;
    },
    delete_dialog() {
      let node = this.current_node[0];
      if (node.isroot == true) {
        this.rootVisible = true;
      } else {
        get_node_testPlans(this.get_pid(), this.current_node[0].key).then(
          (res) => {
            let status = new Set();
            for (let i of res) {
              status.add(i.status);
            }
            if (status.has("COMPLETE")) {
              if (node.isroot == true) {
                this.rootVisible = true;
              } else {
                this.caseVisible = true;
                if (node.children && node.children.length != 0) {
                  this.caseword = this.$t("addMindMap.message7");
                } else {
                  this.caseword = this.$t("addMindMap.message8");
                }
              }
            } else if (status.has("IN_PROGRESS")) {
              if (node.isroot == true) {
                this.rootVisible = true;
              } else {
                this.caseVisible = true;
                if (node.children && node.children.length != 0) {
                  this.caseword = this.$t("addMindMap.message9");
                } else {
                  this.caseword = this.$t("addMindMap.message10");
                }
              }
            } else {
              this.delete();
            }
          }
        );
      }
    },
    delete() {
      let node = this.current_node[0];
      if (node.isroot == true) {
        this.rootVisible = true;
      } else {
        if (node.children && node.children.length != 0) {
          this.nodeVisible = true;
        } else {
          if (!this.relation_dialog) {
            this.childVisible = true;
          }
        }
      }
    },

    mounted_fn() {
      this.get_file_data();
      this.bindMoveLine();
      this.get_file_type_list();
      get_allProjectMember(this.get_pid()).then((res) => {
        this.members = res;
        this.membersShow = true;
      });
    },
    get_file_type_list() {
      get_filetype_List(this.get_pid()).then((res) => {
        this.file_type_list = [];
        for (let i in res) {
          let obj = {
            file_type: res[i].name,
            file_layer: res[i].layerCount,
            fileTypeId: res[i].fileTypeId,
            icon: res[i].icon,
            bug: res[i].bug,
            testCase: res[i].testCase,
          };
          this.file_type_list.push(obj);
        }
      });
    },
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.is_show_topic = false;
      } else {
        // 否则为不溢出
        this.is_show_topic = true;
      }
    },
    //判断三行出...
    isShowTooltip(e, refname) {
      const clientHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;
      const pWidth = refname
        ? this.$refs[refname].parentNode.offsetWidth
        : e.target.children[0].offsetWidth;
      const cWidth = refname
        ? this.$refs[refname].parentNode.offsetWidth
        : e.target.children[0].offsetWidth;
      // 文字超出隐藏并弹出tooltip提示，文字未超出则不弹出tooltip提示的判断条件
      if (scrollHeight > clientHeight && pWidth >= cWidth) {
        this.is_show_name = false;
        this.is_show_topic = false;
      } else {
        this.is_show_name = true;
        this.is_show_topic = true;
      }
    },
    //跳转节点
    jump_node(item) {
      const { href } = this.$router.resolve({
        name: "node_detail",
        params: {
          projectId: this.get_pid(),
          nodeKey: item.key,
        },
      });
      window.open(href, "_blank");
    },
    addbr(topic) {
      // 设置变量存储返回值
      let newstr = "";
      // 如果长度超过20，就要截取插入字符
      if (topic.length > 20) {
        // 第一次截取
        newstr = topic.slice(0, 20) + "<br/>";
        // 闭包再次调用，如果截取后的字段长度依然超过20，再次调用，如果没有直接返回当前值
        return newstr + this.addbr(topic.slice(20));
      } else {
        // 直接返回当前值
        return topic;
      }
    },
    /*双击节点名进行编辑 */
    edit_nodename(name) {
      this.node_name_span = false;
      this.input_name = name;
      this.$nextTick(() => {
        this.$refs["input_panel"].focus();
      });
    },
    // 更新描述内容
    update_description_content($event, data) {
      this.$set(data, "description", $event);
    },
    // 更新drawio内容
    updateDrawio($event, data) {
      this.$set(data, "architectureDiagrams", $event);
    },
    //绑定中间的拉伸条
    bindMoveLine() {
      const resizeFn = (element, position) => {
        let maxAllowWidth = window.innerWidth / 2;
        let resize = document.querySelector(".resize");
        resize.style[position] = "0";
        resize.addEventListener("mousedown", function (e) {
          let startX = e.pageX;
          let width = element.offsetWidth;

          resize.classList.add("active");
          document.addEventListener("mousemove", move);
          let timer = null;

          function move(e) {
            let moveX; // 左右这里切换计算顺序即可
            if (position === "left") moveX = startX - e.pageX;
            if (position === "right") moveX = e.pageX - startX;
            clearTimeout(timer);
            timer = setTimeout(() => {
              // element.style.maxWidth = width + moveX + "px";
              if (maxAllowWidth >= width + moveX)
                element.style.minWidth = width + moveX + "px";
            }, 5);
          }

          document.addEventListener(
            "mouseup",
            function () {
              document.removeEventListener("mousemove", move);
              resize.classList.remove("active");
            },
            { once: true }
          );
        });
      };
      let left = document.querySelector(".catalog_wrap");
      resizeFn(left, "right");
    },
    des_show(data, flag) {
      data.forEach((item) => {
        this.des_show_all(item, flag);
        if (item.children.length) {
          this.des_show(item.children, flag);
        }
      });
    },
    expand_all(event) {
      if (event.target.nodeName === "SPAN") {
        event.target.parentNode.blur();
      } else {
        event.target.blur();
      }
      this.collapse_button_text =
        this.collapse_button_text === this.$t("canvas.wordView.unfoldBtn")
          ? this.$t("canvas.wordView.foldBtn")
          : this.$t("canvas.wordView.unfoldBtn");
      this.collapse_flag = !this.collapse_flag;
      for (let i = 0; i < this.$refs.myTree.store._getAllNodes().length; i++) {
        this.$refs.myTree.store._getAllNodes()[i].expanded = true;
      }
      this.des_show(this.tree_data_line_word, this.collapse_flag);
      this.get_file_data();
    },
    get_file_data() {
      const get_file = getFile(this.get_pid(), this.$route.params.file_key);
      const get_release = getReleaseElements(
        this.get_pid(),
        this.$route.params.file_key
      );
      this.word_all_nodes = {};

      Promise.all([get_file, get_release]).then((result) => {
        this.reset_checked();
        result[0].data.forEach((item) => {
          result[1].forEach((i) => {
            if (i.nodeKey === item.key) {
              if (i.sprintId) {
                item["sprintId"] = i.sprintId;
              }
              i.baselineItemDtoList &&
                i.baselineItemDtoList.forEach((baseline) => {
                  if (baseline.lock && baseline.status === "ALREADY_STARTED") {
                    item["lock"] = baseline.lock;
                  }
                });
            }
          });
          this.word_all_nodes[item.id] = item;
        });
        result[0].data.sort(function (m, n) {
          if (m.index < n.index) return -1;
          else if (m.index > n.index) return 1;
          else return 0;
        });
        this.original_data = result[0].data;
        this.fileData = result[0];
        if (this.only_show_node || this.outstanding_detail_dialog_show) {
          vmson.$emit(
            "handle_lineword_outstanding_click",
            "$" + this.outstanding_type
          );
        }
      });
    },
    des_show_all(data, flag) {
      this.$set(this.show_detail_map, data.id, flag);
    },
    before_close_drawer() {
      this.mounted_fn();
      this.drawer = false;
    },
    headCallBack() {
      this.drawer_loading = false;
    },
    blur_all(event) {
      if (event.target.nodeName === "DIV") {
        this.$refs["base-info"].item_blur();
        this.$refs["release-info"].item_blur();
        this.$refs["node_status_select"].showWorkflow = false;
      }
    },
    show_detail(data, flag) {
      clearTimeout(this.clicktimeOut);
      this.clicktimeOut = setTimeout(() => {
        this.review_detail_show = false;
        if (
          !this.is_edit &&
          document
            .getElementById("global_variable_in_review")
            .innerText.replace(/^\s*|\s*$/g, "") == "true" &&
          this.review_detail_list.includes(data.key)
        ) {
          getNodeReviewInfo(this.get_pid(), data.key).then((res) => {
            if (res.length > 0) {
              let rea_list = {};
              this.review_list.forEach((el) => {
                rea_list[el.itemId] = 0;
              });
              let approve = [];
              let reject = [];
              let person_set = new Set();

              res[0].nodeReviewResultInfos.forEach((item) => {
                person_set.add(item.createdBy);

                if (item.approved) {
                  approve.push({
                    person: item.createdBy,
                    commit: item.comment,
                  });
                } else {
                  reject.push({
                    person: item.createdBy,
                    commit: item.comment,
                  });
                }
                if (item.reviewItems) {
                  item.reviewItems.forEach((ee) => {
                    if (ee in rea_list) {
                      rea_list[ee]++;
                    }
                  });
                }
              });
              this.review_detail_data["approve"] = approve;
              this.review_detail_data["reject"] = reject;
              let ll = [];
              this.review_list.forEach((el) => {
                ll.push({
                  num: rea_list[el.itemId] / person_set.size,
                  l: el.content,
                });
              });
              this.review_detail_data["rea_list"] = ll;

              this.review_detail_show = true;
            }
          });
        }
        const self = this;
        clearTimeout(self.timer); //清除未执行的定时器
        this.$refs.myTree.setCurrentKey(data.id);
        self.timer = setTimeout(function () {
          self.$set(
            self.show_detail_map,
            data.id,
            flag ? true : !self.show_detail_map[data.id]
          );
        }, 0);
        setTimeout(() => {
          if (data.id) {
            // 获取想要滚动的dom元素
            const rollDom = document.getElementById(data.id);
            rollDom.scrollIntoView({ behavior: "smooth", block: "center" });
            if (flag) {
              rollDom.classList.remove("light_click_cas");
              setTimeout(() => {
                rollDom.classList.add("light_click_cas");
              });
            }
          }
        });
      }, 200);
    },

    show_drawer(node) {
      if (this.$route.query.tool_tip == "global_tip_4") {
        vmson.$emit("global_tip", "4@end");
      }
      ed({ guideType: "MINDMAP_WORD_MODEL" }).then((b) => {
        if (!b.success) {
          return;
        }
        let new_route = this.$router.resolve({
          path: `/${this.get_pid()}/home/guide`,
          query: { popupDialogVisible: b.reward },
        });
        window.open(new_route.href, "_blank");
      });
      const jobAuthorities = this.$store.getters.jobAuthorities;
      if (jobAuthorities.indexOf("NODE_DETAIL_READ") === -1) {
        this.$message({
          message: this.$t("board.message.error"),
          type: "warning",
        });
        return;
      }
      this.drawer_loading = true;
      this.$nextTick(() => {
        get_node_use_node_key(this.get_pid(), node.key).then((res) => {
          this.handle_node = res;
          this.drawer = true;
        });
      });
    },
    out_review() {
      this.see_review = false;
      this.process_review = false;
      this.show_review_num = false;
      this.is_show_no_review_nodes = false;
      this.review_detail_show = false;
    },
    show_workflow() {
      this.workflow_dialog_visible = true;
    },
    edit_topic(data, select) {
      clearTimeout(this.clicktimeOut);
      if (data.lock) {
        this.$message({
          type: "warning",
          message: this.$t("addMindWord.message"),
        });
        return;
      }
      this.is_edit = true;
      clearTimeout(this.timer);
      this.$set(this.edit_map, data.id, !this.edit_map[data.id]);
      this.$nextTick(() => {
        this.$refs["input_panel"].focus();
        if (select) {
          this.$refs["input_panel"].select();
        }
      });
      this.input_value = data.label;
    },
    save_topic(data) {
      if (this.jobAuthorities.indexOf("NODE_EDIT") === -1) {
        this.$message({
          type: "warning",
          message: this.$t("tip"),
        });
        this.get_file_data();
        this.$set(this.edit_map, data.id, false);
        this.is_edit = false;
        return;
      }
      if (/^\s*$/.test(data.topic)) {
        data.topic = this.input_value;
      }
      /*访问接口进行节点名称修改 */
      get_node_use_node_key(this.get_pid(), data.key).then((res) => {
        const obj = res;
        obj["topic"] = data.topic;
        const params = {
          projectId: this.get_pid(),
          nodeKey: res.key,
          data: {
            isCustom: false,
            fieldId: "topic",
            value: data.topic,
          },
        };
        only_set_property(params).then((res) => {
          this.$set(this.edit_map, data.id, false);
          this.is_edit = false;
        });
      });
      // 添加高亮背景
      this.$refs.myTree.setCurrentKey(data.id);
    },
    // 点击目录部分
    handleNodeClick(data) {
      for (let i = 0; i < this.$refs.myTree.store._getAllNodes().length; i++) {
        if (this.$refs.myTree.store._getAllNodes()[i].data.id == data.id) {
          let p = this.$refs.myTree.store._getAllNodes()[i].parent;
          while (p) {
            p.expanded = true;
            p = p.parent;
          }
        }
      }
      this.show_detail(data, true);
    },
    //AI自动补充
    nodebuchong() {
      aiAutoExtendPayFeatureStatus().then((res) => {
        if (res) {
          let currentNode = this.$refs.myTree.getCurrentNode();
          if (currentNode) {
            this.loading = this.$loading({
              target: "#mindmap-loading",
              lock: true,
              text: this.$t("canvas.AI.tip1"),
              spinner: "ai-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            nodeAutoExtend({
              projectId: this.get_pid(),
              data: {
                fileKey: this.$route.params.file_key,
                nodeKey: currentNode.key,
              },
            })
              .then((status) => {
                this.get_file_data();
                this.loading.close();
              })
              .catch((err) => {
                this.$message({
                  type: "warning",
                  message: this.$t("canvas.AI.tip3"),
                });
                this.loading.close();
              });
          } else {
            this.$message({
              message: this.$t("canvas.AI.tip5"),
            });
          }
        } else {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=ai" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table tbody tr:hover > td {
  background-color: transparent !important;
}
.word_view_outstanding {
  width: 15px;
  height: 15px;
  background-color: rgba(48, 100, 143, 1);
  border-radius: 50%;
  margin-left: 25px;
}
.green_review_check {
  margin-top: 1px;
  font-size: 23px;
  margin-left: 25px;
  color: rgb(67, 207, 124);
}

.red_review_check {
  font-size: 23px;
  margin-left: 15px;
  color: rgb(255, 87, 51);
}
.green_review {
  font-size: 18px;
  background-color: rgb(218, 255, 221);
  border: 1px solid rgb(67, 207, 124);
  border-radius: 50%;
  margin-left: 25px;
  color: rgb(218, 255, 221);
}
.red_review {
  font-size: 18px;
  background-color: rgb(255, 230, 225);
  border: 1px solid rgb(255, 87, 51);
  border-radius: 50%;
  margin-left: 15px;
  color: rgb(255, 230, 225);
}
.UN_APPROVED {
  width: 15px;
  height: 15px;
  background-color: rgba(255, 87, 51, 1);
  border-radius: 50%;
  margin-left: 25px;
}
.APPROVED {
  width: 15px;
  height: 15px;
  background-color: rgba(79, 176, 118, 1);
  border-radius: 50%;
  margin-left: 25px;
}
.REVIEWING {
  width: 15px;
  height: 15px;
  background-color: rgba(255, 87, 51, 1);
  border-radius: 50%;
  margin-left: 25px;
}
.review_no {
  width: 15px;
  height: 15px;
  background-color: rgba(48, 100, 143, 1);
  border-radius: 50%;
  margin-left: 25px;
}
.line_word_wrap {
  ::v-deep .el-tree-node__content:hover {
    background-color: rgb(216, 224, 237) !important;
  }

  text-align: start;

  ::v-deep .el-tree-node__content {
    height: fit-content;
    align-items: baseline;
    // padding: 10px 0;
  }

  .line_word_content {
    display: flex;
    //justify-content: space-around;
    .catalog_wrap {
      //width: 300px; /*no*/
      width: 200px;
      // width: 300px;
      max-width: 50vw;
      overflow-x: auto;
      height: calc(88vh - 98px);
      overflow-y: scroll;
      padding-left: 10px;
      border-right: 4px solid #f0f2f5;

      &::-webkit-scrollbar {
        //width: 6px;
        width: 0px;
        height: 0px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 3px;
        background: rgba(255, 255, 255, 1);
      }

      ::v-deep.el-tree-node.is-current > .el-tree-node__content {
        color: rgba(42, 130, 228, 1);
      }
    }
  }

  /*拖拽区div样式*/
  .resize {
    cursor: e-resize;
    min-width: 6px;
    font-size: 32px;
    user-select: none;
  }

  .resize.active {
    background-color: #0090f1;
  }

  .resize:hover {
    background-color: #0090f1;
  }
  #line_word_right {
    width: 100%;
  }
  .line_word {
    //width: calc(100% - 300px);
    flex: 1;
    overflow-y: scroll;
    height: calc(88vh - 150px);

    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      background-color: #ebeef5;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #ccc;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: rgba(255, 255, 255, 1);
    }
  }

  .node_topic {
    max-width: 1000px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: 500;
    text-indent: 16px;
    text-align: left;
    margin-right: 50px;

    &:hover {
      color: rgba(48, 100, 143, 1);
    }
  }

  .custom-tree-node {
    height: fit-content; /*no*/
    padding: 18px 0 0 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > div {
      // width: 50%;
      padding: 10px;
    }
  }
}
.seacrh-wrap {
  position: absolute;
  right: 75px;
  top: 255px;
  z-index: 1999;
  .seacrh-button {
    z-index: 1999;
    position: absolute;
    cursor: pointer;
    display: flex;
    background-color: rgba(24, 79, 169, 1);
    align-items: center;
    color: white;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: none;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .seacrh-dialog {
    z-index: 1998;

    transition: all 0.2s ease; /* 可选的，用于更平滑的过渡 */
    width: 400px;
    font-size: 25px;
    height: 50px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 0;
    left: -450px;
    padding: 10px 5px;
    border-radius: 10px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #dddddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    i {
      cursor: pointer;

      margin: 0 5px;
    }
  }
}

#seacrh-dialog-search_input:focus {
  outline: none;
  border: none; /* 自定义边框颜色和宽度 */
}
.line_word {
  padding: 10px 42px 0 42px;
}

.grid_tem {
  display: grid;
  grid-template-columns: 8% 84% 8%;
  align-items: center;
  justify-items: center;
}

.con {
  width: 95%;
  height: 500px;

  .el-carousel__container {
  }
}

.box {
  width: 100%;
  height: 500px;
  border: 1px solid rgba(207, 207, 207, 1);
  border-radius: 6px 6px 6px 6px;

  .tool {
    img {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }

    .p {
      font-size: 16px;
      color: rgba(128, 128, 128, 1);
    }

    .line {
      height: 100%;
      width: 1px;
      background-color: rgba(204, 204, 204, 1);
    }

    height: 40px;
    width: 100%;
    border-bottom: 1px solid rgba(204, 204, 204, 1);
    background-color: rgba(230, 230, 230, 1);
    display: grid;
    grid-template-columns: 8% 1px 8% 1px 8% 1px auto;
    align-items: center;
    justify-items: center;
  }

  .img {
    width: 100%;
    height: 220px;
    padding: 10px;
    display: flex;
    justify-content: center;
    // align-items: center;
    overflow: auto;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.drawer_wrap {
  padding: 20px;
  // height: 100%;
  .header_content {
    display: flex;
    justify-content: flex-start;
    align-items: top;
    text-align: left;
    margin: 20px auto 0 auto;
    .header_node {
      display: flex;
      align-items: top;
      margin-left: 0 !important;
      // margin-right: 20px;
    }
    ::v-deep .el-input__inner {
      width: 180px;
    }
  }
  .top-node-id {
    font-size: 16px;
    font-weight: 700;
    color: rgba(255, 195, 0, 1);
    background-color: rgba(229, 229, 229, 1);
    width: 120px;
    height: 28px;
    border-radius: 2px;
    text-align: center;
    span {
      vertical-align: middle;
      line-height: 28px;
    }
  }

  .node-name {
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -webkit-ellipsis-lastline;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .baseline_lock {
    color: rgba(166, 166, 166, 1);
    font-size: 14px;
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
  }
  .box_item_r {
    height: 30px;
    border: 1px solid rgba(219, 219, 219, 1);
    border-radius: 0 5px 5px 0;
    padding: 0 14px;
    display: flex;
    justify-items: center;
    align-items: center;
    .box_font {
      font-size: 18px;
      color: rgba(255, 195, 0, 1);
      font-weight: 700;
    }
  }
}

.tongguo_check {
  width: 15px;
  height: 15px;
  border: 2px solid rgb(67, 207, 124);
  color: white;
  border-radius: 50%;
  cursor: pointer;
}

.butongguo_check {
  width: 15px;
  height: 15px;
  color: white;
  border: 2px solid rgb(255, 87, 51);
  border-radius: 50%;
  cursor: pointer;
}
</style>

<style lang="scss">
.con {
  .el-carousel__container {
    height: 100%;
  }
}

/* 隐藏滚动条并允许内容滚动 */
.scrollable-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll; /* All other browsers */
}

/* 隐藏滚动条的外观 */
.scrollable-content::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

#process_review {
  border-radius: 20px;
  max-height: 500px;
  overflow: auto;

  .process_review_item:last-child {
    border-bottom: 1px solid rgba(229, 229, 229, 1) !important;
  }

  .process_review_item {
    border-top: 1px solid rgba(229, 229, 229, 1) !important;
  }

  // }{
  //     border: 1px solid black !important;
  //   }
  .el-card__body {
    padding: 0;
  }

  background-color: rgba(245, 245, 245);
  width: 380px;
  position: absolute;
  top: 200px;
  right: 50px;
  z-index: 99;
}

.review_orange {
  color: orange;
}
.review_blue {
  color: #2a82e4;
}
.review_detail_dialog {
  .pingshen_reason {
    margin-bottom: 15px;

    padding: 5px;
    text-align: start;
    width: 100%;
    height: 200px;
    overflow-y: auto;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid rgb(128, 128, 128);
  }
  .avatar {
    display: flex;
    overflow-y: hidden;
    width: 100%;
    flex-wrap: wrap;
  }
  .pingshen {
    display: grid;
    grid-template-columns: 60px 50px auto 25px;
    overflow-y: hidden;
    align-items: center;
    justify-items: start;
    font-size: 16px;
    margin-bottom: 20px;
    height: 30px;

    .num-green {
      color: rgb(79, 176, 118);
      width: 100%;
    }
    .btn-green {
      height: 30px;

      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: rgb(79, 176, 118);
      width: 100%;
      padding: 5px;
      border: 1px solid rgb(79, 176, 118);
      border-radius: 5px;
    }
    .num-red {
      color: rgb(255, 87, 51);
      width: 100%;
    }
    .btn-red {
      height: 30px;

      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: rgb(255, 87, 51);
      width: 100%;
      padding: 5px;
      border: 1px solid rgb(255, 87, 51);
      border-radius: 5px;
    }
  }
}
.review_detail_dialog {
  pointer-events: none;
}
.outstanding_detail_dialog {
  pointer-events: none;
}
.outstanding_line {
  margin-bottom: 15px;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
}

.outstanding_dialog_table_class td,
.outstanding_dialog_table_class th.is-leaf {
  border: none;
}

.outstanding_dialog_table_class::before {
  width: 0;
}

.outstanding_dialog_table_class {
  margin-bottom: 10px;
}
.outstanding_dialog_table_class_cell {
  text-align: start;
  font-size: 18px;
  .cell {
    padding: 0 !important;
  }
  .outstanding_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.outstanding_dialog_color {
  width: 18px;
  height: 18px;
  // background-color: #0090f1;
  margin-right: 10px;
}
.outstanding_detail_dialog_body {
  .el-dialog__body {
    padding-right: 0 !important;
  }
  .el-dialog__header {
    padding-bottom: 0 !important;
  }
}
.outstanding_node {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 25px;
}
</style>

<style>
.review_detail_dialog_body {
  pointer-events: auto !important;
  top: 50px;
  left: 50px;
  position: absolute;
}
.outstanding_detail_dialog_body {
  pointer-events: auto !important;
  top: 100px;
  right: 50px;
  position: absolute;
}
.serach_mindmap_node_highlight {
  color: orange !important;
}
.serach_mindmap_node_highlight_selected {
  background: #f1c40f !important;
  color: white !important;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-enter-to,
.slide-fade-leave {
  transform: translateX(0);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
}
</style>
