<template>
  <div ref="chart-wrap" class="chart-wrap">
    <div style="display: flex; align-items: center">
      <el-tooltip
        :disabled="currentChartData.name && currentChartData.name.length < 24"
        effect="dark"
        placement="top-start"
      >
        <div slot="content">
          <span>{{ currentChartData.name }}</span>
        </div>
        <h1 class="chart_title">{{ currentChartData.name }}</h1>
      </el-tooltip>
      <el-tooltip
        v-show="
          currentChartData.type === 'STATUS_TIMES' ||
          currentChartData.type === 'STATUS_DURATION' ||
          currentChartData.type === 'VMODEL_CHART'
        "
        class="item"
        placement="top"
      >
        <div slot="content">
          <span>
            {{
              currentChartData.type === "STATUS_TIMES"
                ? $t("chart.message.ms1")
                : currentChartData.type === "STATUS_DURATION"
                ? $t("chart.message.ms2")
                : $t("chart.message.ms3")
            }}</span
          >
        </div>
        <i
          class="iconfont icon-zwen"
          style="font-size: 16px; margin-left: 10px; color: rgb(127, 127, 128)"
        ></i>
      </el-tooltip>
      <div class="top-button-wrap">
        <!-- <el-button
          v-show="currentChartData.type === 'VMODEL_CHART'"
          type="primary"
          size="mini"
          @click="show_cover"
        >
          {{ $t("chart.message.ms29") }}</el-button
        > -->
        <i
          v-show="
            currentChartData.type === 'VMODEL_CHART' ||
            currentChartData.type === 'WMODEL_CHART'
          "
          class="iconfont icon-save-list"
          style="font-size: 24px; cursor: pointer; color: #174fa9"
          @click="show_cover"
        ></i>
        <i
          v-show="
            currentChartData.type === 'VMODEL_CHART' ||
            currentChartData.type === 'WMODEL_CHART'
          "
          class="el-icon-full-screen"
          style="
            font-size: 24px;
            cursor: pointer;
            color: #174fa9;
            margin-left: 10px;
          "
          @click="zoomChart"
        ></i>
        <el-button
          v-show="isEditing"
          type="primary"
          class="setting_button"
          style="margin-left: 10px"
          size="mini"
          @click="edit_chart"
        >
          {{ $t("board.settingPage.title") }}</el-button
        >
        <div v-show="isEditing" class="remove_button" @click="remove_chart">
          <i
            class="el-icon-circle-close"
            style="font-size: 30px; margin-left: 10px"
          ></i>
        </div>
      </div>
    </div>
    <!-- 饼状图两个文字定位 -->
    <span v-if="currentChartData.type === 'PIE'" class="chart_pie_x2"
      >{{ fieldMap.get(currentChartData.fieldId) }}-{{
        $t("chart.total") + currentChartData.paralleledCount
      }}</span
    >
    <!-- X轴提示 -->
    <div :id="'axis-tip' + currentChartData.chartId" class="axis-tip"></div>
    <!-- 柱状图 折线图 雷达图 饼状图 -->
    <baseChart
      v-if="
        currentChartData.type &&
        currentChartData.type !== 'FILTER_LIST' &&
        currentChartData.type !== 'TABLE' &&
        currentChartData.type !== 'VMODEL_CHART' &&
        currentChartData.type !== 'WMODEL_CHART' &&
        currentChartData.type !== 'TWO_DIMENSION_TABLE' &&
        currentChartData.type !== 'TWO_DIMENSION_COVERAGE'
      "
      ref="basechart"
      :current-chart-data="currentChartData"
      @initBasechart="initBasechart"
    ></baseChart>
    <!-- 二维报表 -->
    <twoDimension
      v-if="currentChartData.type === 'TWO_DIMENSION_TABLE'"
      :current-chart-data="currentChartData"
      :field-map="fieldMap"
    >
    </twoDimension>
    <!-- 筛选结果列表 -->
    <fileList
      v-if="
        currentChartData.type === 'FILTER_LIST' &&
        currentChartData.statisticObject == 'FILE'
      "
      :current-chart-data="currentChartData"
      :field-list="fieldList"
    >
    </fileList>
    <!-- 筛选结果列表 -->
    <filterList
      v-if="
        currentChartData.type === 'FILTER_LIST' &&
        currentChartData.statisticObject == 'NODE'
      "
      :current-chart-data="currentChartData"
      :field-list="fieldList"
    >
    </filterList>
    <!-- v模型 -->
    <vmodelChart
      v-if="currentChartData.type === 'VMODEL_CHART'"
      ref="zoomChart"
      :current-chart-data="currentChartData"
      :chartSources="chartSources"
    >
    </vmodelChart>
    <!-- w模型 -->
    <wmodelChart
      v-if="currentChartData.type === 'WMODEL_CHART'"
      ref="zoomChart"
      :current-chart-data="currentChartData"
      :chartSources="chartSources"
    >
    </wmodelChart>
    <addChartDialog
      :dialog-show="dialogShow"
      :field-list="fieldList"
      :is-editing-chart="true"
      :current-select="$route.params.cur_dashboard"
      :chart-sources="chartSources"
      :chart-form="chartForm"
      @changeDialogShow="changeDialogShow"
      @partChangeChart="partChangeChart"
    ></addChartDialog>
    <!--  无需覆盖节点 弹窗 -->
    <el-dialog
      :title="$t('chart.message.ms29')"
      width="50%"
      class="vmodel-dialog"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="dialogVmodel"
      append-to-body
    >
      <el-table
        :stripe="false"
        :header-cell-style="{ border: 'none' }"
        class="vmodel-table"
        :data="tableDataCover"
        :row-style="{ height: '70px' }"
        style="width: 100%; position: relative"
      >
        <el-table-column
          prop="key"
          class="first-col-vmodel"
          align="center"
          label="Key"
          width="160"
        >
          <template slot-scope="scope">
            <span
              class="key_vmodel"
              style="text-align: left; cursor: pointer"
              @click="rowClicked(scope.row)"
            >
              <em>{{ scope.row.nodeKey }}</em>
            </span>
            <i
              style="
                font-size: 15px;
                margin-left: 10px;
                cursor: pointer;
                color: rgb(48, 100, 143);
              "
              class="iconfont icon-node_link"
              @click="nodeLink(scope.row)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column :label="$t('chart.message.ms30')">
          <template slot-scope="scoped">
            <el-tooltip
              effect="dark"
              :content="fileType[scoped.row.fileTypeId].name"
              placement="top"
              :disabled="!isShowTooltip"
            >
              <p
                style="
                  margin: 0;
                  width: 480px;
                  text-align: left;
                  font-size: 18px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  color: rgba(38, 50, 129, 1);
                "
                @mouseenter="visibilityChange($event)"
              >
                {{ fileType[scoped.row.fileTypeId].name }}
              </p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column :label="$t('chart.message.ms31')">
          <template slot-scope="scoped">
            <el-tooltip
              effect="dark"
              :content="scoped.row.topic"
              placement="top"
              :disabled="!isShowTooltip"
            >
              <p
                style="
                  margin: 0;
                  width: 480px;
                  text-align: left;
                  font-size: 18px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  color: rgba(38, 50, 129, 1);
                "
                @mouseenter="visibilityChange($event)"
              >
                {{ scoped.row.topic }}
              </p>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div
        class="vmodelPage"
        style="padding-bottom: 20px; display: flex; justify-content: center"
      >
        <div class="block">
          <el-pagination
            background
            :current-page.sync="current_page"
            :page-size="page_size"
            layout="total, prev, pager, next, jumper"
            :total="total_data"
            @current-change="current_change"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="zoomDialog" width="80%">
      <span slot="title"> </span>
      <!-- v模型 -->
      <vmodelChart
        v-if="currentChartData.type === 'VMODEL_CHART'"
        :current-chart-data="currentChartData"
        :chartSources="chartSources"
      >
      </vmodelChart>
      <!-- w模型 -->
      <wmodelChart
        v-if="currentChartData.type === 'WMODEL_CHART'"
        :current-chart-data="currentChartData"
        :chartSources="chartSources"
      ></wmodelChart>
      <span slot="footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import filterList from "./charts/filterList.vue";
import fileList from "./charts/fileList.vue";
import vmson from "@/utils/vmson";
import twoDimension from "./charts/twoDimension.vue";
import baseChart from "./charts/basechart.vue";
import vmodelChart from "./charts/vmodelChart.vue";
import wmodelChart from "./charts/wmodelChart.vue";
import addChartDialog from "./addChartDialog.vue";
import { getFile } from "@/network/home/index.js";
import { get_file } from "@/network/baseline/index";
import {
  get_chart_by_id,
  delete_chart_by_id,
} from "@/network/dataChart/index.js";
import { getNodeCoverage_page } from "@/network/coverage/index.js";
import { baseChartOptions } from "./chartOptions.js";
export default {
  components: {
    baseChart,
    addChartDialog,
    twoDimension,
    filterList,
    fileList,
    vmodelChart,
    wmodelChart,
  },
  props: {
    fieldList: {
      type: Array,
      default() {
        return [];
      },
    },
    fieldMap: {
      type: Map,
      default() {
        return new Map();
      },
    },
    chartData: {
      type: Object,
      default() {
        return {};
      },
    },
    chartSources: {
      type: Array,
      default() {
        return [];
      },
    },
    dashboardId: {
      type: String,
      default: "",
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //因为会删除和编辑所以暂存自己的最新准确数据
      currentChartData: {},
      chart: null,
      dialogShow: false,
      chartForm: {},
      dialogVmodel: false,
      tableDataCover: [],
      isShowTooltip: false,
      current_page: 1,
      total_data: 0,
      page_size: 7,
      paramsVmodel: {
        queryConditions: [],
        mql: "",
      },
      zoomDialog: false,
    };
  },
  computed: {
    ...mapGetters(["user_list", "status", "fileType"]),
  },
  watch: {
    chartData: {
      handler(newValue, oldValue) {
        this.get_chart_by_id();
      },
      deep: true,
    },
  },
  mounted() {
    this.get_chart_by_id();
    // resize图表
    let element = this.$refs["chart-wrap"];
    this.observer = new ResizeObserver(() => {
      this.resizeChart(); // 触发resize后执行
    });
    this.observer.observe(element);
  },
  methods: {
    zoomChart() {
      this.zoomDialog = true;
    },
    //节点点击事件
    rowClicked(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${row.nodeKey}`,
      });
      window.open(href, "_blank");
    },
    nodeLink(row) {
      get_file(this.get_pid(), row.fileUuid).then((result) => {
        getFile(this.get_pid(), result.key)
          .then((res) => {
            this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
            this.$store.commit("SET_FILE_ID", row.fileUuid);
            this.$store.commit("SET_FILE_KEY", res.key);
            this.$store.commit("SET_MINDMAP_NAME", row.fileName);
            this.$store.commit("SET_OPEN_STATE", "exist");
            this.$store.commit("SET_FILETYPE_ID", row.fileTypeId);
            //跳转到思维导图
            const { href } = this.$router.resolve({
              path: `/${this.get_pid()}/myMind/${result.key}`,
              query: {
                node_id: row.nodeId,
              },
            });
            window.open(href, "_blank");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    current_change(val) {
      this.current_page = Number(val);
      this.get_cover_data();
    },
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },
    // 获取当前v模型的筛选条件内容
    get_chart_queryCondition() {
      for (let i = 0; i < this.chartSources.length; i++) {
        if (
          this.currentChartData.queryConditionId ==
          this.chartSources[i].queryConditionId
        ) {
          if (
            this.chartSources[i].conditions &&
            this.chartSources[i].conditions.length > 0
          ) {
            this.paramsVmodel.queryConditions = this.chartSources[i].conditions;
          }
          if (this.chartSources[i].mqlCondition) {
            this.paramsVmodel.mql = this.chartSources[i].mqlCondition.mql;
          }
        }
      }
    },
    // 获取分页数据
    get_cover_data() {
      this.get_chart_queryCondition();
      let params = {
        page: this.current_page - 1,
        size: this.page_size,
        data: this.paramsVmodel,
      };
      getNodeCoverage_page(this.get_pid(), params).then((res) => {
        this.tableDataCover = res.ignoreNodeList;
        this.total_data = res.total;
        this.paramsVmodel = {
          queryConditions: [],
          mql: "",
        };
      });
    },
    // 查看v模型无需覆盖的节点弹窗
    show_cover() {
      this.dialogVmodel = true;
      this.current_page = 1;
      this.get_cover_data();
    },
    // 初始化基本图表类型数据
    initBasechart(chartData) {
      // 针对饼状图合计错误问题
      if (chartData.entryList) {
        chartData.paralleledCount = chartData.entryList.reduce((prev, cur) => {
          return prev + cur.count;
        }, 0);
      }
      if (chartData.fieldId == "file_type_id") {
        chartData.entryList.map((item, index) => {
          item.actualValue = this.fileType[item.value].name;
          // item.value = this.fileType[item.value].name;
        });
      }
      // 初始化 X轴数据
      const option = Object.assign({}, baseChartOptions[chartData.type]);
      const xData =
        chartData.type === "STATUS_DURATION" ||
        chartData.type === "STATUS_TIMES"
          ? chartData.entryList
            ? chartData.entryList.map((item) => {
                return this.status[item.status].name;
              })
            : null
          : chartData.entryList.map((item) =>
              this.user_list[item.value]
                ? this.user_list[item.value].nickname
                : item.actualValue
                ? item.actualValue
                : item.value
                ? item.value
                : "null"
            );
      option.xAxis ? (option.xAxis.data = xData) : "";
      // 初始化Y轴数据
      let yData;
      if (chartData.type === "STATUS_TIMES") {
        yData = chartData.entryList
          ? chartData.entryList.map((item) => {
              return item.aveCount / 1000;
            })
          : null;
      } else if (chartData.type === "STATUS_DURATION") {
        yData = chartData.entryList
          ? chartData.entryList.map((item) => {
              return item.aveTime;
            })
          : null;
      } else {
        yData = chartData.entryList.map((item) => item.count);
      }
      option.series[0].data = yData;
      // 初始化饼状图数据
      const pieData = chartData.entryList
        ? chartData.entryList.map((item) => {
            return {
              value: item.count,
              name: this.user_list[item.value]
                ? this.user_list[item.value].nickname
                : item.actualValue
                ? item.actualValue
                : item.value
                ? item.value
                : "null",
            };
          })
        : null;
      // 初始化雷达图数据
      const radarData = chartData.entryList
        ? chartData.entryList.map((item) => item.count)
        : null;
      const radarMax = radarData ? Math.max(...radarData) : null;
      const radarIndicator = chartData.entryList
        ? chartData.entryList.map((item) => {
            return {
              name: this.user_list[item.value]
                ? this.user_list[item.value].nickname
                : item.actualValue
                ? item.actualValue
                : item.value
                ? item.value
                : "null",
              max: radarMax,
            };
          })
        : null;
      // 不同图表类型 做不同处理
      if (chartData.type === "BAR" || chartData.type === "LINE") {
        option.xAxis.name = this.fieldMap.get(chartData.fieldId);
      } else if (chartData.type === "PIE") {
        option.series[0].data = pieData;
        option.legend.formatter = function (params) {
          let legendIndex = 0;
          pieData.forEach(function (v, i) {
            if (v.name === params) {
              legendIndex = i;
            }
          });
          return (
            "{title|" +
            params +
            "}" +
            "{value|" +
            pieData[legendIndex].value +
            "}"
          );
        };
      } else if (chartData.type === "RADAR") {
        option.radar.indicator = radarIndicator;
        const buildSeries = function () {
          const data = radarData;
          const helper = data.map((item, index) => {
            const arr = new Array(data.length);
            arr.splice(index, 1, item);
            return arr;
          });
          return [data, ...helper].map((item, index) => {
            return {
              type: "radar",
              symbol: index === 0 ? "circle" : "none",
              symbolSize: 4,
              lineStyle: {
                color: index === 0 ? "#5470c6" : "transparent",
              },
              areaStyle: {
                color: index === 0 ? "#5470c6" : "transparent",
                opacity: 0.5,
              },
              tooltip: {
                show: index === 0 ? false : true,
                formatter: () => {
                  let res =
                    radarIndicator[index - 1].name + "：" + data[index - 1];
                  return res;
                },
              },
              z: index === 0 ? 1 : 2,
              data: [item],
            };
          });
        };
        const series = [];
        series.push(...buildSeries());
        option.series = series;
      } else if (chartData.type === "STATUS_DURATION") {
        option.xAxis.name = this.$t("chart.message.ms25");
        option.yAxis.name = this.$t("chart.message.ms26");
        let maxVal = 0; // 最大值
        let interval = 86400000; // 步长
        if (chartData.entryList) {
          chartData.entryList.forEach((item) => {
            if (item.aveTime > maxVal) {
              maxVal = item.aveTime;
            }
          });
        }
        let days = Math.floor(maxVal / 86400000) + 1;
        for (let i = 0; i < 5; i++) {
          if (days % 5 != 0) {
            days++;
          }
        }
        maxVal = days * 86400000; // (天数+1)*毫秒数
        interval = maxVal / 5;
        option.yAxis.min = 0;
        option.yAxis.max = maxVal;
        option.yAxis.interval = interval;
      } else if (chartData.type === "STATUS_TIMES") {
        option.xAxis.name = this.$t("chart.message.ms25");
        option.yAxis.name = this.$t("chart.message.ms27");
      }
      this.$nextTick(() => {
        this.initDom(
          this.$refs.basechart.$refs["data_chart" + chartData.chartId],
          option,
          chartData
        );
      });
    },
    // 渲染图表
    initDom(dom, option, chartData) {
      // 判断是否是饼状图 如果不是添加X轴的tootip
      if (!dom) return;
      this.chart = this.$echarts.init(dom);
      if (chartData.type !== "PIE") {
        this.chart.on("mouseover", "xAxis.category", function (e) {
          let axisTip = document.querySelector("#axis-tip" + chartData.chartId);
          axisTip.innerText = e.value;
          axisTip.style.left = e.event.offsetX - 20 + "px";
          axisTip.style.top = e.event.offsetY + 20 + "px";
          if (e.value && e.value.length > 10) {
            axisTip.style.display = "block";
          }
        });
        this.chart.on("mouseout", "xAxis.category", function (e) {
          let axisTip = document.querySelector("#axis-tip" + chartData.chartId);
          axisTip.innerText = "";
          axisTip.style.display = "none";
        });
      }
      this.chart.setOption(option, true);
      this.chart.on("click", (params) => {
        this.chart_click(params, chartData);
      });
    },
    //图表添加点击事件
    chart_click(params, chartData) {
      const queryConditionId = chartData.queryConditionId;
      let fieldId = chartData.fieldName || chartData.fieldId;
      const value =
        chartData.type === "RADAR"
          ? chartData.entryList[params.seriesIndex - 1].value
          : chartData.entryList[params.dataIndex].value;
      if (fieldId == "sprint_id") fieldId = "sprint";
      if (fieldId == "test_plan_id") fieldId = "testPlan";
      if (fieldId == "version_id") fieldId = "version";
      if (fieldId == "baseline_id") fieldId = "baseline";
      if (chartData.statisticObject == "NODE") {
        this.$router.push({
          path: `/${this.get_pid()}/home/node`,
          query: {
            queryConditionId,
            fieldId,
            value,
          },
        });
      } else {
        this.$router.push({
          path: `/${this.get_pid()}/home/my_file`,
          query: {
            queryConditionId,
            fieldId,
            value,
          },
        });
      }
    },
    // resize图表
    resizeChart() {
      this.chart ? this.chart.resize() : "";
    },
    get_chart_by_id() {
      const params = [this.get_pid(), this.dashboardId, this.chartData.chartId];
      const chartFnMap = {
        LINE: this.initBasechart,
        BAR: this.initBasechart,
        PIE: this.initBasechart,
        RADAR: this.initBasechart,
        STATUS_DURATION: this.initBasechart,
        STATUS_TIMES: this.initBasechart,
      };
      get_chart_by_id(...params).then((res) => {
        this.currentChartData = res;
        chartFnMap[res.type] ? chartFnMap[res.type](res) : "";
      });
    },
    // 编辑chart
    edit_chart() {
      if (this.chartData.statisticObject == "NODE") {
        this.chartForm = {
          name: this.chartData.name,
          type: this.chartData.type,
          chartId: this.chartData.chartId,
          statisticObject: "NODE",
          queryConditionId: this.chartData.queryConditionId
            ? this.chartData.queryConditionId
            : "all",
          fieldId: this.chartData.fieldId,
          fieldId02: this.chartData.fieldId02,
          frontExtraData:
            this.chartData.frontExtraData &&
            typeof this.chartData.frontExtraData === "string"
              ? JSON.parse(this.chartData.frontExtraData)
              : "",
        };
      } else {
        this.chartForm = {
          name: this.chartData.name,
          type: this.chartData.type,
          chartId: this.chartData.chartId,
          statisticObject: "FILE",
          file_queryConditionId: this.chartData.queryConditionId
            ? this.chartData.queryConditionId
            : "all",
          file_fieldId: this.chartData.fieldId,
          file_fieldId02: this.chartData.fieldId02,
          file_frontExtraData:
            this.chartData.frontExtraData &&
            typeof this.chartData.frontExtraData === "string"
              ? JSON.parse(this.chartData.frontExtraData)
              : "",
        };
      }

      this.dialogShow = true;
    },
    //删除chart
    remove_chart() {
      delete_chart_by_id(
        this.get_pid(),
        this.dashboardId,
        this.chartData.chartId
      )
        .then((result) => {
          this.$message({
            type: "success",
            message: this.$t("chart.message.deleteSuccess"),
          });
          this.$emit(
            "partChangeChart",
            this.$route.params.cur_dashboard,
            "delete"
          );
          vmson.$emit("partChangeChart");
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: this.$t("chart.message.deleteError"),
          });
        });
    },
    // 添加图表弹窗切换显示状态
    changeDialogShow(flag) {
      this.dialogShow = flag;
    },
    partChangeChart() {
      this.$emit("partChangeChart", this.$route.params.cur_dashboard, "update");
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-wrap {
  break-inside: avoid;
  text-align: center;
  border-radius: 8px;
  padding: 12px 0 10px 20px;
  background: rgba(255, 255, 255, 1);
  margin-bottom: 20px;
  position: relative;
  height: fit-content;
  .top-button-wrap {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    width: 200px;
    justify-content: flex-end;
    .remove_button {
      cursor: pointer;
    }
  }
  .chart_pie_x {
    position: absolute;
    left: 400px;
    height: 30px;
    bottom: 20px;
    color: rgba(128, 128, 128, 1);
  }
  .chart_pie_x2 {
    position: absolute;
    left: calc(100% - 221px);
    bottom: 165px;
    height: 30px;
    // top: 50px;
    color: rgba(128, 128, 128, 1);
  }
  .axis-tip {
    display: none;
    position: absolute;
    padding: 5px 5px;
    font-size: 12px;
    line-height: 18px;
    background: #303133;
    color: #fff;
    z-index: 1000;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  .chart_title {
    max-width: 60%;
    text-align: left;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    margin-left: 10px;
    display: inline-block;
  }
}
</style>
<style lang="scss" scoped>
em {
  font-size: 18px;
  font-weight: 700;
  color: rgba(255, 195, 0, 1);
  font-style: normal;
  text-align: left !important;
}
</style>
<style lang="scss">
.vmodel_table {
  margin-right: 20px;
  .el-table {
    line-height: 2px;
  }
  .el-table--border::after,
  .el-table--group::after {
    display: none;
  }
  .el-table td {
    padding: 0;
  }
  .el-table__row > td {
    border-bottom: none;
  }
  .el-table::before {
    height: 0px;
  }
  .el-table--border,
  .el-table--group {
    border: none;
  }

  .el-table th {
    overflow: initial;
    padding: 0;
  }
  .el-table .cell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .el-table thead.is-group th {
    background-color: #fff;
    border-right: none;
    border-bottom: none;
  }
  .el-table th > .cell {
    font-size: 14px;
    color: rgba(128, 128, 128, 1);
    font-weight: 400;
  }

  .el-table thead tr:first-of-type th:first-of-type {
    border-bottom: none;
    padding: 0;
    padding-top: 5px;
  }
  .el-table thead tr:nth-of-type(2) th:first-of-type {
    border-bottom: none;
    padding: 0;
    padding-bottom: 8px;
  }
  .el-table tbody tr td:first-of-type .cell {
    font-size: 14px;
    color: rgba(128, 128, 128, 1);
    text-align: center;
  }
  .el-table thead tr:first-of-type th:last-of-type > .cell,
  .el-table tbody tr td:last-of-type .cell,
  .el-table__footer-wrapper tbody tr td:first-of-type .cell {
    color: white;
    font-weight: 600;
  }
}
</style>
