<template>
  <div class="mytype">
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="25%"
      :show-close="true"
      :title="$t('fieldLab.addNewFieldForm.title')"
      style="text-align: left"
    >
      <div class="addField">
        <el-input
          v-model="input_name"
          :placeholder="$t('fieldLab.addNewFieldForm.placeholder1')"
          size="medium"
          style="width: 47%"
        ></el-input>
        <el-select
          v-model="new_type"
          style="width: 51%; padding-left: 10px"
          :placeholder="$t('fieldLab.addNewFieldForm.placeholder2')"
          filterable
          @change="file_type_change"
        >
          <el-option
            v-for="item in types"
            :key="item.ch"
            :label="item.ch"
            :value="item.ch"
          ></el-option>
        </el-select>
        <div
          v-show="
            new_type === $t('fieldLab.addNewFieldForm.fieldTypeList.number')
          "
          class="noticeDiv"
        >
          <el-input
            v-model="unit"
            :placeholder="$t('fieldLab.addNewFieldForm.placeholder4')"
            size="medium"
            style="width: 100%"
          ></el-input>
        </div>
        <div
          v-show="
            new_type ===
              $t('fieldLab.addNewFieldForm.fieldTypeList.multiUser') ||
            new_type === $t('fieldLab.addNewFieldForm.fieldTypeList.user')
          "
          class="noticeDiv"
        >
          <span>{{ $t("fieldLab.message.ms1") }}</span>
          <div>
            <el-radio v-model="isNoticeOther" :label="true">
              {{ $t("fileType.addFileTypeForm.yes") }}
            </el-radio>
            <el-radio v-model="isNoticeOther" :label="false">
              {{ $t("fileType.addFileTypeForm.no") }}
            </el-radio>
          </div>
        </div>
      </div>
      <span></span>
      <div v-if="flag" style="clear: both">
        <span style="color: red; font-size: 10px"
          >*{{ $t("fieldLab.addNewFieldForm.info") }}</span
        >
      </div>
      <div v-else style="clear: both">
        <span style="color: red">&nbsp;</span>
      </div>
      <div>
        <el-input
          v-model="description"
          type="textarea"
          :placeholder="$t('fieldLab.addNewFieldForm.placeholder3')"
          size="medium"
          style="width: 100%"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="plain"
          @click="
            dialogVisible = false;
            flag = false;
            new_type = '';
          "
          >{{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button type="primary" class="button-cancel" @click="create_field">
          {{ $t("btn.newBtn") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="edit_dialog"
      width="25%"
      :show-close="true"
      :close-on-click-modal="false"
      :title="$t('fieldLab.fieldTable.options.editForm.title')"
      style="text-align: left"
      :before-close="handleEditClose"
    >
      <div class="cus_options_class">
        <div style="margin-top: 4px; width: 70%">
          <el-input
            v-model="cus_input_value"
            size="small"
            :placeholder="
              $t('fieldLab.fieldTable.options.editForm.placeholder')
            "
            @keydown.enter.native="add_cus_options"
          ></el-input>
        </div>
        <div style="cursor: pointer; width: 30%; padding-left: 10px">
          <el-button size="small" type="primary" @click="add_cus_options">{{
            $t("btn.addBtn")
          }}</el-button>
        </div>
        <div
          style="
            margin: 10px 0 0 0;
            width: 100%;
            font-color: #c0c4cc;
            font-size: 16px;
            padding-left: 5px;
          "
        >
          {{ $t("fieldLab.default_tip") }}
        </div>
        <div style="margin: 10px 0 24px 0; width: 100%">
          <el-checkbox-group
            v-model="cus_options_checked"
            size="mini"
            @change="save_cus_options_checked"
          >
            <div
              v-for="(item, index) in cus_options"
              :key="index"
              class="check_box_wrap"
            >
              <div
                v-if="!edit_cus_options[index]"
                class="check_box_wrap_delete"
                @click="delete_cus_options_checked(index)"
              >
                x
              </div>
              <el-popover
                v-if="!edit_cus_options[index]"
                placement="top-start"
                trigger="hover"
              >
                <div style="text-align: center">
                  <el-button @click="edit_cus_opt(item, index)">{{
                    $t("btn.editBtn")
                  }}</el-button>
                </div>
                <template slot="reference">
                  <el-checkbox :label="item.label" border></el-checkbox>
                </template>
              </el-popover>
              <el-input
                v-else
                v-model="eidt_cus_input_value"
                size="small"
                :placeholder="
                  $t('fieldLab.fieldTable.options.editForm.placeholder')
                "
                @blur="edit_single_options"
              ></el-input>
            </div>
          </el-checkbox-group>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="edit_dialog = false">{{$t('btn.cancelBtn')}}</el-button>
            <el-button type="primary" @click="edit_dialog_confirm">{{$t('btn.newBtn')}}</el-button>
      </span>-->
    </el-dialog>
    <el-dialog
      :visible.sync="notice_edit_dialog"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('fieldLab.message.ms4')"
      style="text-align: left"
    >
      <div class="noticeDiv" style="margin-bottom: 20px">
        <span>{{ $t("fieldLab.message.ms1") }}</span>
        <div>
          <el-radio v-model="isNoticeOther" :label="true">
            {{ $t("fileType.addFileTypeForm.yes") }}
          </el-radio>
          <el-radio v-model="isNoticeOther" :label="false">
            {{ $t("fileType.addFileTypeForm.no") }}
          </el-radio>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="plain"
          @click="
            notice_edit_dialog = false;
            isNoticeOther = false;
          "
          >{{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button type="primary" class="button-cancel" @click="notice_edit">
          {{ $t("btn.confirmBtn") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 编辑unit -->
    <el-dialog
      :visible.sync="unit_edit_dialog"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('fieldLab.message.ms6')"
      style="text-align: left"
    >
      <div class="noticeDiv" style="margin-bottom: 20px">
        <el-input v-model="currentUnit" placeholder="请输入内容"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="plain" @click="unit_edit_dialog = false">
          {{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button type="primary" class="button-cancel" @click="saveUnit">
          {{ $t("btn.confirmBtn") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 绑定文件类型 -->
    <el-dialog
      :visible.sync="bindFileTypeDialog"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      :title="$t('fieldLab.bindingFileType')"
      style="text-align: left"
    >
      <div>
        <el-form
          :model="bindFileTypeData"
          :rules="bindRules"
          ref="bindForm"
          label-width="100px"
          label-position="left"
        >
          <el-form-item
            :label="$t('fileType.fileTypeTable.type')"
            prop="fileType"
          >
            <el-cascader
              v-model="bindFileTypeData.fileType"
              style="width: 100%"
              :props="{ multiple: true }"
              :options="bindFileTypeOptions"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            :label="$t('editFileType.field.table.floatWindow')"
            prop="isShow"
          >
            <el-select v-model="bindFileTypeData.isShow" style="width: 100%">
              <el-option
                :label="$t('fileType.addFileTypeForm.no')"
                value="0"
              ></el-option>
              <el-option
                :label="$t('fileType.addFileTypeForm.yes')"
                value="1"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('editFileType.field.table.required')"
            prop="isMust"
          >
            <el-select v-model="bindFileTypeData.isMust" style="width: 100%">
              <el-option
                :label="$t('fileType.addFileTypeForm.no')"
                value="0"
              ></el-option>
              <el-option
                :label="$t('fileType.addFileTypeForm.yes')"
                value="1"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="plain" @click="bindFileTypeDialog = false">
          {{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button
          type="primary"
          class="button-cancel"
          @click="bindFileTypeConfirm"
        >
          {{ $t("btn.confirmBtn") }}
        </el-button>
      </span>
    </el-dialog>
    <div style="float: right; display: flex; align-items: center">
      <el-input
        v-model="search_PROJECT_zdh"
        style="width: 200px; margin-right: 20px"
        class="search_ipt"
        size="mini"
        suffix-icon="el-icon-search"
        clearable
      >
      </el-input>
      <el-button
        icon="el-icon-plus"
        type="primary"
        size="small"
        @click="clickAddButton"
        >{{ $t("fieldLab.addNewFieldBtn") }}</el-button
      >
    </div>
    <el-table
      ref="mytable"
      v-loading="loading"
      height="78vh"
      :element-loading-text="$t('loading.loading1')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.1)"
      :data="shou_table_list"
      style="width: 100%; margin-top: 28px; float: right"
      class="clickable-rows"
      :header-cell-style="{
        color: '#383838',
      }"
      :cell-style="changeCellStyle"
    >
      <el-table-column
        prop="field_name"
        :label="$t('fieldLab.fieldTable.name')"
        min-width="30%"
        cursor="pointer"
      >
        <template slot-scope="scope">
          <span v-if="flag != scope.row.fieldId" @click="edit(scope.row)">
            {{ scope.row.field_name }}
          </span>
          <el-input
            v-else
            ref="input_panel"
            v-model="input_name"
            type="text"
            @keyup.enter="away($event, scope.row, 'name')"
            @blur="away($event, scope.row, 'name')"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="field_type"
        :label="$t('fieldLab.fieldTable.type')"
        min-width="30%"
      ></el-table-column>
      <el-table-column
        prop="field_name"
        :label="$t('homeTopBar.newFileForm.field2')"
        min-width="40%"
        cursor="pointer"
      >
        <template slot-scope="scope">
          <div v-if="flag2 != scope.row.fieldId" @click="editDes(scope.row)">
            <span v-if="scope.row.description == null || !scope.row.description"
              >&nbsp;&nbsp;&nbsp;</span
            >
            <span
              v-for="(item, index) in setMultiline(scope.row.description)"
              :key="index"
              style="min-width: 200px; min-height: 26px; display: block"
            >
              <span>{{ item }}</span>
            </span>
          </div>
          <el-input
            v-else
            ref="input_panel2"
            v-model="description"
            type="textarea"
            @keyup.enter="away($event, scope.row, 'description')"
            @blur="away($event, scope.row, 'description')"
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('fieldLab.fieldTable.action')"
        min-width="10%"
      >
        <template slot-scope="scope">
          <el-dropdown v-if="scope.row.isCustom" style="cursor: pointer">
            <span class="el-dropdown-link" style="color: #84aace">
              <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
              &nbsp;&nbsp;
              <i
                class="iconfont icon-a-216gengduo-shuxiang"
                style="color: rgb(48, 100, 143); cursor: default !important"
              />
              &nbsp;&nbsp;
            </span>
            <el-dropdown-menu slot="dropdown">
              <div
                v-if="
                  scope.row.fieldType === 'SELECT' ||
                  scope.row.fieldType === 'SELECT_MULTI'
                "
                class="operate_button"
                @click="editOnly(scope.row)"
              >
                <i>
                  <i
                    class="iconfont icon-edit"
                    style="vertical-align: bottom"
                  />&nbsp;&nbsp;&nbsp;
                </i>
                {{ $t("btn.editBtn") }}&nbsp;&nbsp;&nbsp;
              </div>
              <div
                v-if="
                  scope.row.fieldType === 'SINGLE_USER_SELECT' ||
                  scope.row.fieldType === 'MULTI_USER_SELECT'
                "
                class="operate_button"
                @click="noticeEdit(scope.row)"
              >
                <i>
                  <i
                    class="iconfont icon-edit"
                    style="vertical-align: bottom"
                  />&nbsp;&nbsp;&nbsp;
                </i>
                {{ $t("btn.editBtn") }}&nbsp;&nbsp;&nbsp;
              </div>
              <div
                v-if="scope.row.fieldType === 'NUMBER'"
                class="operate_button"
                @click="editUnit(scope.row)"
              >
                <i>
                  <i
                    class="iconfont icon-edit"
                    style="vertical-align: bottom"
                  />&nbsp;&nbsp;&nbsp;
                </i>
                {{ $t("btn.editBtn") }}&nbsp;&nbsp;&nbsp;
              </div>
              <div class="operate_button" @click="bindFileType(scope.row)">
                <i>
                  <i
                    class="iconfont icon-a-bindingfile"
                    style="font-size: 18px; vertical-align: bottom"
                  />&nbsp;&nbsp;&nbsp;
                </i>
                {{ $t("fieldLab.bindingFileType") }}&nbsp;&nbsp;&nbsp;
              </div>
              <div class="operate_button" @click="deleteOnly(scope.row)">
                <i>
                  <i
                    class="iconfont icon-projectmanger_delbin"
                    style="vertical-align: bottom"
                  />&nbsp;&nbsp;&nbsp;
                </i>
                {{ $t("btn.deleteBtn") }}&nbsp;&nbsp;&nbsp;
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  all_fields_get,
  field_rename,
  field_create,
  delete_field_single,
  add_extraDataList,
  delete_extraDataList,
  edit_extraDataList,
  getSystemFields,
  notice_edit_filed,
  resetUnit,
  change_fields_defaultValue,
  get_notice_filed,
  relatedFileType,
} from "@/network/fileType/index.js";
import { get_filetype_List } from "@/network/fileType/index.js";
import { async } from "@antv/x6/lib/registry/marker/async";

export default {
  name: "FieldLabVue",
  inject: ["reload"],
  data() {
    return {
      // 默认选择否
      isNoticeOther: false,
      notice_edit_dialog: false,
      unit_edit_dialog: false,
      flag: false,
      flag2: false,
      new_type: "",
      input_name: "",
      description: "",
      unit: "",
      currentUnit: "",
      select_type: "",
      types: [
        {
          ch: this.$t("fieldLab.addNewFieldForm.fieldTypeList.text"),
          en: "TEXT",
        },
        {
          ch: this.$t("fieldLab.addNewFieldForm.fieldTypeList.textarea"),
          en: "TEXTAREA",
        },
        {
          ch: this.$t("fieldLab.addNewFieldForm.fieldTypeList.number"),
          en: "NUMBER",
        },
        {
          ch: this.$t("fieldLab.addNewFieldForm.fieldTypeList.select"),
          en: "SELECT",
        },
        {
          ch: this.$t("fieldLab.addNewFieldForm.fieldTypeList.multiSelect"),
          en: "SELECT_MULTI",
        },
        {
          ch: this.$t("fieldLab.addNewFieldForm.fieldTypeList.user"),
          en: "SINGLE_USER_SELECT",
        },
        {
          ch: this.$t("fieldLab.addNewFieldForm.fieldTypeList.multiUser"),
          en: "MULTI_USER_SELECT",
        },
        {
          ch: this.$t("fieldLab.addNewFieldForm.fieldTypeList.date"),
          en: "DATE",
        },
        {
          ch: this.$t("fieldLab.addNewFieldForm.fieldTypeList.label"),
          en: "LABEL",
        },
        {
          ch: this.$t("fieldLab.addNewFieldForm.fieldTypeList.link"),
          en: "LINK",
        },
        { ch: "Drawio", en: "DRAWIO" },
      ],
      dialogVisible: false,
      edit_dialog: false,
      flag_name: "",
      cus_input_value: "",
      eidt_cus_input_value: "",
      old_eidt_cus_input_value: "",
      cus_options: [],
      edit_cus_options: [],
      current_edit_index: null,
      cus_options_checked: [],
      loading: false,
      tableData: [],
      old_cus_options_checked: [],
      // 绑定文件类型
      bindFileTypeDialog: false,
      bindFileTypeData: {
        fileType: [],
        isShow: "0",
        isMust: "0",
      },
      bindFileTypeOptions: [],
      currentFieldId: "",
      bindRules: {
        fileType: [{ required: true, trigger: "blur" }],
      },
      openBindDialog: false,
      search_PROJECT_zdh: "",
      shou_table_list: [],
      zdh_change_data: 0,
    };
  },
  watch: {
    search_PROJECT_zdh() {
      if (this.search_PROJECT_zdh.length > 0) {
        let b = this.search_PROJECT_zdh.toLowerCase();
        let a = this.tableData.filter((programName) => {
          return programName.field_name.toLowerCase().includes(b);
        });
        this.shou_table_list = a;
      } else {
        this.shou_table_list = this.tableData;
      }
    },
  },
  mounted() {},
  created() {
    this.get_data();
    this.getFileTypeLayerData();
  },
  methods: {
    handleEditClose() {
      if (this.openBindDialog) {
        this.bindFileTypeDialog = true;
      }
      this.openBindDialog = false;
      this.edit_dialog = false;
    },
    getFileTypeLayerData() {
      this.bindFileTypeOptions = [];
      get_filetype_List(this.get_pid()).then((res) => {
        res.forEach((type) => {
          const obj = {
            value: type.fileTypeId,
            label: type.name,
            children: [],
          };
          for (let i = 0; i < type.layerCount; i++) {
            const childrenObj = {
              value: i + 1,
              label:
                this.$t("canvas.rightClick.statusChangeForm.num") +
                (i + 1) +
                this.$t("canvas.rightClick.statusChangeForm.layer"),
            };
            obj.children.push(childrenObj);
          }
          this.bindFileTypeOptions.push(obj);
        });
      });
    },
    bindFileTypeConfirm() {
      this.$refs["bindForm"].validate((valid) => {
        if (valid) {
          const params = {
            projectId: this.get_pid(),
            fieldId: this.currentFieldId,
            data: {
              required: this.bindFileTypeData.isMust === "0" ? false : true,
              show: this.bindFileTypeData.isShow === "0" ? false : true,
              fileTypeAndLayerDtoList: [],
            },
          };
          console.log(this.bindFileTypeData);
          const map = {};
          this.bindFileTypeData.fileType.forEach((item) => {
            if (map[item[0]]) {
              map[item[0]].push(item[1] - 1);
            } else {
              map[item[0]] = [item[1] - 1];
            }
          });

          Object.keys(map).forEach((key) => {
            const obj = {
              fileTypeId: key,
              layerList: map[key],
            };
            params.data.fileTypeAndLayerDtoList.push(obj);
          });
          relatedFileType(params).then((res) => {
            this.bindFileTypeDialog = false;
            this.$message.success(this.$t("fieldLab.message.ms7"));
          });
        } else {
          return false;
        }
      });
    },
    bindFileType(row) {
      this.currentFieldId = row.fieldId;
      this.bindFileTypeDialog = true;
    },
    save_cus_options_checked() {
      if (this.select_type == "SELECT") {
        // 对于单选，old_cus_options_checked记录改变之前的值，old_cus_options_checked是改变后的值，若old_cus_options_checked的长度>1，那么就将之前的值从checked里面删去
        if (this.cus_options_checked.length > 1) {
          this.cus_options_checked[0] == this.old_cus_options_checked[0]
            ? this.cus_options_checked.shift()
            : this.cus_options_checked.pop();
        }
        this.old_cus_options_checked = this.cus_options_checked;
      }

      change_fields_defaultValue(
        this.get_pid(),
        this.field_type_id,
        this.cus_options_checked
      ).then(async (res) => {
        let list = [];
        const systemFields = await getSystemFields();
        all_fields_get(this.get_pid()).then((res) => {
          const arr = [...res, ...systemFields];
          for (let i in arr) {
            let obj = {
              field_name: arr[i].name,
              field_type: this.toTranslate(arr[i].fieldType) || arr[i].name,
              fieldType: arr[i].fieldType,
              extraData: arr[i].extraData,
              fieldId: arr[i].fieldId,
              isCustom: arr[i].isCustom,
              description: arr[i].description,
              unit: arr[i].unit,
              defaultValue: arr[i].defaultValue,
            };
            list.push(obj);
          }
          this.tableData = list;
          this.shou_table_list = list;
        });
      });
    },
    setMultiline(e) {
      return e ? e.split("\n") : e;
    },
    saveUnit() {
      const params = {
        projectId: this.get_pid(),
        fieldId: this.field_type_id,
        data: {
          unit: this.currentUnit,
        },
      };
      resetUnit(params).then((res) => {
        this.$message({
          type: "success",
          message: this.$t("canvas.messages.renameSuccess"),
        });
        this.currentRow.unit = this.currentUnit;
        this.unit_edit_dialog = false;
      });
    },
    // 编辑单位相关
    editUnit(row) {
      this.currentRow = row;
      this.currentUnit = row.unit;
      this.field_type_id = row.fieldId;
      this.unit_edit_dialog = true;
    },
    async get_notice() {
      const params = {
        projectId: this.get_pid(),
        fieldId: this.field_type_id,
      };
      await get_notice_filed(params).then((res) => {
        this.isNoticeOther = res;
        this.notice_edit_dialog = true;
      });
    },
    async notice_edit() {
      const params = {
        projectId: this.get_pid(),
        fieldId: this.field_type_id,
        needNotify: this.isNoticeOther,
      };
      await notice_edit_filed(params).then(() => {
        this.$message.success(this.$t("fieldLab.message.ms3"));
        this.notice_edit_dialog = false;
      });
    },
    edit_single_options() {
      this.edit_cus_options = [];
      const params = {
        projectId: this.get_pid(),
        fieldId: this.field_type_id,
        data: {
          newValue: this.eidt_cus_input_value,
          oldValue: this.old_eidt_cus_input_value,
        },
      };
      let flag = false;
      this.cus_options_checked.forEach((item, index) => {
        if (item === params.data.oldValue) {
          this.cus_options_checked[index] = params.data.newValue;
          flag = true;
          if (this.select_type == "SELECT") {
            this.old_cus_options_checked = [params.data.oldValue];
          }
        }
      });

      edit_extraDataList(params).then((res) => {
        this.cus_options[this.current_edit_index].value =
          this.eidt_cus_input_value;
        this.cus_options[this.current_edit_index].label =
          this.eidt_cus_input_value;
        if (flag) {
          this.save_cus_options_checked();
        }
      });
    },
    edit_cus_opt(item, index) {
      this.$set(this.edit_cus_options, index, true);
      this.current_edit_index = index;
      this.eidt_cus_input_value = item.label;
      this.old_eidt_cus_input_value = item.label;
    },
    delete_cus_options_checked(index) {
      const params = {
        projectId: this.get_pid(),
        fieldId: this.field_type_id,
        data: {
          value: this.cus_options[index].value,
        },
      };
      let flag = false;
      const arr = this.cus_options_checked;
      const ind = arr.indexOf(params.data.value);
      if (ind > -1) {
        arr.splice(ind, 1);
        flag = true;
      }

      delete_extraDataList(params).then((res) => {
        this.cus_options.splice(index, 1);
        if (flag) {
          if (this.select_type == "SELECT") {
            this.old_cus_options_checked = [];
          }

          this.save_cus_options_checked();
        }
      });
    },
    file_type_change() {
      this.cus_options = [];
      this.cus_input_value = "";
    },
    // 添加自定义选项
    add_cus_options() {
      if (this.cus_input_value) {
        const has = this.cus_options.find((item) => {
          return item.label === this.cus_input_value;
        });
        if (has) {
          this.$message({
            type: "warning",
            message: this.$t("fieldLab.info"),
          });
        } else {
          const params = {
            projectId: this.get_pid(),
            fieldId: this.field_type_id,
            data: [
              {
                label: this.cus_input_value,
                value: this.cus_input_value,
              },
            ],
          };
          add_extraDataList(params).then(async (res) => {
            this.cus_options.push({
              label: this.cus_input_value,
              value: this.cus_input_value,
            });
            let list = [];
            const systemFields = await getSystemFields();
            all_fields_get(this.get_pid()).then((res) => {
              const arr = [...res, ...systemFields];
              for (let i in arr) {
                let obj = {
                  field_name: arr[i].name,
                  field_type: this.toTranslate(arr[i].fieldType) || arr[i].name,
                  fieldType: arr[i].fieldType,
                  extraData: arr[i].extraData,
                  fieldId: arr[i].fieldId,
                  isCustom: arr[i].isCustom,
                  description: arr[i].description,
                  unit: arr[i].unit,
                  defaultValue: arr[i].defaultValue,
                };
                list.push(obj);
              }
              this.tableData = list;
              this.shou_table_list = list;
            });
          });
        }
      } else {
        this.$message({
          type: "warning",
          message: this.$t("fieldLab.message.addError"),
        });
      }
    },
    create_field() {
      if (this.input_name == "" || this.new_type == "") {
        this.flag = true;
        return;
      } else {
        let extraData = null;
        // 数字类型单位相关
        if (
          this.new_type ==
            this.$t("fieldLab.addNewFieldForm.fieldTypeList.select") ||
          this.new_type ==
            this.$t("fieldLab.addNewFieldForm.fieldTypeList.multiSelect")
        ) {
          extraData = this.cus_options;
        }
        let obj = {
          extraData: extraData,
          fieldType: this.toreTranslate(this.new_type),
          name: this.input_name,
          needNotify: this.isNoticeOther,
          description: this.description,
          unit: this.unit,
        };
        field_create(this.get_pid(), obj).then((res) => {
          this.currentFieldId = res;
          if (
            this.new_type ==
              this.$t("fieldLab.addNewFieldForm.fieldTypeList.select") ||
            this.new_type ==
              this.$t("fieldLab.addNewFieldForm.fieldTypeList.multiSelect")
          ) {
            this.openBindDialog = true;
            this.get_data(res);
          } else {
            this.get_data();
            this.bindFileTypeDialog = true;
          }
          this.dialogVisible = false;
          this.$nextTick(() => {
            this.$refs["mytable"].bodyWrapper.scrollTop =
              this.$refs["mytable"].bodyWrapper.scrollHeight;
          });
        });
      }
    },
    clickAddButton() {
      this.cus_options = [];
      this.cus_input_value = "";
      this.dialogVisible = true;
      // 清空上一次输入
      this.new_type = "";
      this.input_name = "";
      this.description = "";
      this.unit = "";
      this.isNoticeOther = false;
    },
    away(e, row, type) {
      let cur_id;
      const obj = {
        name: "",
        description: "",
      };
      if (type === "name") {
        obj.name = e.target.value;
        obj.description = row.description;
        cur_id = this.flag;
      } else if (type === "description") {
        obj.name = row.field_name;
        obj.description = e.target.value;
        cur_id = this.flag2;
      }
      if (obj.name == "") {
        this.flag = "";
        this.flag2 = "";
      } else if (cur_id != "") {
        field_rename(this.get_pid(), cur_id, obj).then((res) => {
          row.field_name = obj.name;
          row.description = obj.description;
          this.$message({
            type: "success",
            message: this.$t("canvas.messages.renameSuccess"),
          });
          this.input_name = "";
          this.flag = "";
          this.flag2 = "";
        });
      }
    },

    edit(row) {
      if (!row.isCustom) {
        this.$message({
          type: "warning",
          message: this.$t("fieldLab.message.ms2"),
        });
        return;
      }
      this.flag_name = row.field_name;
      this.input_name = row.field_name;
      this.flag = row.fieldId;
      setTimeout(() => {
        this.$refs.input_panel.focus();
      }, 220);
    },
    editDes(row) {
      if (!row.isCustom) {
        this.$message({
          type: "warning",
          message: this.$t("fieldLab.message.ms2"),
        });
        return;
      }
      this.flag2 = row.fieldId;
      this.description = row.description;
      setTimeout(() => {
        this.$refs.input_panel2.focus();
      }, 220);
    },
    //删除单个字段
    deleteOnly(row) {
      const params = {
        projectId: this.get_pid(),
        fieldId: row.fieldId,
      };

      // localStorage.removeItem
      delete_field_single(params).then(
        (res) => {
          this.get_data();
          this.$nextTick(() => {
            this.$refs["mytable"].bodyWrapper.scrollTop =
              this.$refs["mytable"].bodyWrapper.scrollHeight;
          });
          localStorage.removeItem(`dropCol:${this.get_pid()}`);
        },
        (error) => {}
      );
    },
    editOnly(row) {
      const arr1 = [];
      (row.extraData || []).forEach((item) => {
        arr1.push(item.value);
      });
      const arr2 = row.defaultValue || [];
      const intersection = arr1.filter((num) => arr2.includes(num));

      this.cus_options = row.extraData || [];
      this.select_type = row.fieldType;
      this.cus_options_checked = intersection;
      if (this.select_type == "SELECT") {
        this.old_cus_options_checked = intersection;
      }

      this.cus_input_value = "";
      this.edit_dialog = true;
      this.field_type_id = row.fieldId;
      this.edit_cus_options = [];
      this.current_edit_index = null;
    },
    noticeEdit(row) {
      this.cus_options = row.extraData || [];
      this.field_type_id = row.fieldId;

      this.get_notice();
    },
    async get_data(id) {
      this.loading = true;
      this.tableData = [];
      const systemFields = await getSystemFields();
      all_fields_get(this.get_pid()).then((res) => {
        const arr = [...res, ...systemFields];
        for (let i in arr) {
          let obj = {
            field_name: arr[i].name,
            field_type: this.toTranslate(arr[i].fieldType) || arr[i].name,
            fieldType: arr[i].fieldType,
            extraData: arr[i].extraData,
            fieldId: arr[i].fieldId,
            isCustom: arr[i].isCustom,
            description: arr[i].description,
            unit: arr[i].unit,
            defaultValue: arr[i].defaultValue,
          };
          this.tableData.push(obj);
        }
        if (id) {
          this.tableData.forEach((item) => {
            if (item.fieldId === id) {
              this.editOnly(item);
            }
          });
        }
        this.shou_table_list = this.tableData;
        this.loading = false;
      });
    },
    toreTranslate(src) {
      if (src == this.$t("fieldLab.addNewFieldForm.fieldTypeList.text")) {
        return "TEXT";
      } else if (
        src == this.$t("fieldLab.addNewFieldForm.fieldTypeList.textarea")
      ) {
        return "TEXTAREA";
      } else if (
        src == this.$t("fieldLab.addNewFieldForm.fieldTypeList.number")
      ) {
        return "NUMBER";
      } else if (
        src == this.$t("fieldLab.addNewFieldForm.fieldTypeList.select")
      ) {
        return "SELECT";
      } else if (
        src == this.$t("fieldLab.addNewFieldForm.fieldTypeList.multiSelect")
      ) {
        return "SELECT_MULTI";
      } else if (
        src == this.$t("fieldLab.addNewFieldForm.fieldTypeList.user")
      ) {
        return "SINGLE_USER_SELECT";
      } else if (
        src == this.$t("fieldLab.addNewFieldForm.fieldTypeList.multiUser")
      ) {
        return "MULTI_USER_SELECT";
      } else if (
        src == this.$t("fieldLab.addNewFieldForm.fieldTypeList.date")
      ) {
        return "DATE";
      } else if (
        src == this.$t("fieldLab.addNewFieldForm.fieldTypeList.label")
      ) {
        return "LABEL";
      } else if (
        src == this.$t("fieldLab.addNewFieldForm.fieldTypeList.version")
      ) {
        return "VERSION";
      } else if (
        src == this.$t("fieldLab.addNewFieldForm.fieldTypeList.link")
      ) {
        return "LINK";
      } else if (src == "Drawio") {
        return "DRAWIO";
      }
    },
    toTranslate(src) {
      if (src == "TEXT") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.text");
      } else if (src == "TEXTAREA") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.textarea");
      } else if (src == "NUMBER") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.number");
      } else if (src == "SELECT") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.select");
      } else if (src == "SELECT_MULTI") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.multiSelect");
      } else if (src == "SINGLE_USER_SELECT") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.user");
      } else if (src == "MULTI_USER_SELECT") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.multiUser");
      } else if (src == "DATE") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.date");
      } else if (src == "LABEL") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.label");
      } else if (src == "VERSION") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.version");
      } else if (src == "LINK") {
        return this.$t("fieldLab.addNewFieldForm.fieldTypeList.link");
      } else if (src == "DRAWIO") {
        return "Drawio";
      }
    },
    changeCellStyle(row, column, rowIndex, columnIndex) {
      return "color:#636363;font-weight: 700";
    },
  },
};
</script>
<style scoped>
.check_box_wrap {
  position: relative;
  width: fit-content;
  display: inline-block;
}

.check_box_wrap_delete {
  position: absolute;
  display: inline-block;
  width: 12px;
  height: 12px;
  text-align: center;
  line-height: 12px;
  background-color: #a6a6a6;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  right: 0;
  top: 0;
  z-index: 9;
  font-size: 12px;
}

.clickable-rows::before {
  height: 0px;
}

.clickable-rows {
  cursor: default;
  width: 100%;
  height: 74vh;
  overflow: auto;
  background: white;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.operate_button {
  display: block;
  /* width: 60px; */
  margin: 15px 20px;
  text-align: left;
  cursor: pointer;
  color: #656667;
}

.operate_button:hover {
  color: #46b4f4;
}

.cus_options_class {
  margin: 10px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
}

/deep/ .el-tooltip__popper {
  display: none;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 70vh;
}

.addField {
  float: left;
  padding-top: 0px;
}

.cus_options_class >>> .el-input__inner {
  height: auto;
}

.cus_options_class >>> .el-checkbox.is-bordered.el-checkbox--mini {
  margin: 6px 4px;
}

.addField >>> .el-dialog.el-dialog__body {
  padding-top: 0px;
}

i {
  cursor: pointer;
}

.noticeDiv {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
