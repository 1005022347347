<template>
  <div v-loading="loading">
    <!-- myDiv 计算文本宽度高度 -->
    <div id="myDiv"></div>
    <!-- myCanvas 也来计算文本宽度高度 -->
    <canvas id="myCanvas" style="display: none"></canvas>

    <!-- 剩余几个门未填写/fta计算 按钮 -->
    <div style="display: flex" class="fta_style">
      <el-button
        v-if="fta_illegal || fta_stop"
        type="primary"
        size="small"
        @click="tip"
        icon="el-icon-edit"
        style="display: flex"
      >
        <div>
          {{
            fta_door_num > 0
              ? " " + fta_door_num + $t("fta.text2")
              : $t("fta.calculate")
          }}
        </div>
      </el-button>
      <el-dropdown
        v-else
        trigger="click"
        placement="bottom-start"
        class="fta_dropdown"
        @command="handleCommand"
      >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-edit"
          style="display: flex"
        >
          <div>
            {{
              fta_door_num > 0
                ? " " + fta_door_num + $t("fta.text2")
                : $t("fta.calculate")
            }}
          </div>
        </el-button>

        <el-dropdown-menu slot="dropdown" class="fta_style">
          <el-dropdown-item command="mincut">
            {{ $t("fta.mincut") }}
          </el-dropdown-item>
          <el-dropdown-item command="minpath">
            {{ $t("fta.minpath") }}
          </el-dropdown-item>
          <el-dropdown-item command="structural">
            {{ $t("fta.structural") }}
          </el-dropdown-item>
          <el-dropdown-item command="topevent">
            {{ $t("fta.topevent") }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div style="width: 100%; height: 70vh">
      <!-- antv-x6 -->
      <div id="container"></div>
      <!-- 最小割 最小径 弹窗-->
      <el-dialog
        width="30%"
        :title="calculate_title"
        :visible.sync="calculateVisible"
        append-to-body
      >
        <el-table
          :data="fta_table"
          style="
            width: 100%;
            margin-bottom: 40px;
            max-height: 50vh;
            overflow: auto;
          "
          :show-header="false"
        >
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <span
                v-for="(item, index) in scope.row"
                :key="index"
                class="fta_res"
                >{{
                  "EV" + (leafCounter - topic_to_ids[item]) + " " + item
                }}</span
              >
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>

      <!-- 节点重要度 弹窗-->
      <el-dialog
        width="30%"
        :title="calculate_title"
        :visible.sync="importanceVisible"
        append-to-body
      >
        <el-table
          :data="fta_table"
          style="
            width: 100%;
            margin-bottom: 40px;
            max-height: 50vh;
            overflow: auto;
          "
        >
          <el-table-column :label="$t('fta.node')">
            <template slot-scope="scope">
              <span>{{
                "EV" +
                (leafCounter - topic_to_ids[scope.row[0]]) +
                " " +
                scope.row[0]
              }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('fta.text3')">
            <template slot-scope="scope">
              <span>{{ scope.row[1] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>

      <!-- 顶事件概率 弹窗-->
      <el-dialog
        :title="calculate_title"
        :visible.sync="topeventVisible"
        width="30%"
        append-to-body
      >
        <div style="display: flex; width: 100%">
          <div style="width: 50%; margin-bottom: 20px; font-weight: 600">
            {{ $t("fta.text4") }}
          </div>
          <div style="width: 48%; font-weight: 600">{{ $t("fta.text5") }}</div>
        </div>
        <el-form
          id="ftaForm"
          ref="ftaForm"
          class="owz"
          label-position="left"
          label-width="50%"
          style="max-height: 40vh !important; overflow: auto"
          :model="fta_form"
        >
          <el-form-item
            v-for="item in fta_leaf_data"
            :key="item.id"
            :label="
              'EV' + (leafCounter - topic_to_ids[item.topic]) + ' ' + item.topic
            "
            :prop="item.id"
            :rules="fta_rules"
            style="display: flex; align-items: center"
          >
            <el-input
              v-model="fta_form[item.id]"
              :placeholder="$t('fta.text6')"
              @input="fta_change"
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
          style="
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-end;
          "
        >
          <div style="display: flex; align-items: flex-end">
            <div style="margin-right: 10px">
              {{ calculate_res > -1 ? $t("fta.text10") : "" }}
            </div>
            <div style="font-size: 32px; color: #ff5733; font-weight: 800">
              {{ calculate_res > -1 ? calculate_res : "" }}
            </div>
          </div>
          <div>
            <el-button id="fta_calculate_reset_btn" @click="resetForm()">
              {{ $t("fta.text7") }}
            </el-button>
            <el-button type="primary" @click="show_error()">
              {{
                fta_btn_num > 0
                  ? $t("fta.text1") + " " + fta_btn_num + $t("fta.text8")
                  : $t("fta.text9")
              }}
            </el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Graph } from "@antv/x6";
import Hierarchy from "@antv/hierarchy";
import { getFile } from "@/network/home/index.js";
import vmson from "@/utils/vmson";
import {
  change_node_fta_door,
  new_node_fta_door,
  get_file_fta_door,
  get_file_fta_probability,
  put_file_fta_probability,
  put_file_fta_leaf_door,
  get_file_fta_leaf_door,
} from "@/network/node/index.js";

export default {
  props: {},
  data() {
    // 检查顶事件概率 弹窗用户输入是否合法
    let check_num = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("fta.text11")));
      } else {
        if (this.is_valid_input(value)) {
          callback();
        } else {
          callback(new Error(this.$t("fta.text12")));
        }
      }
    };
    return {
      fta_rules: {
        validator: check_num,
        trigger: ["blur", "change"],
      },
      fta_btn_num: 0, // 顶事件概率弹窗不合法概率的个数
      fta_door_num: 0, // 未填写门的个数
      fta_form: {}, // 顶事件概率弹窗 key是node id value是用户输入的概率
      topeventVisible: false,
      fta_illegal: false, // fta视图可以修改这个错误，非叶子节点未选择门?true:false
      fta_stop: false, // fta视图无法修改这个错误，非叶子节点后只有一个节点?true:false
      calculate_title: "",
      calculateVisible: false,
      importanceVisible: false,
      calculate_res: -1, // 顶事件概率计算值
      graph: "", // antv x6
      line_width: 25,
      node_padding_width: 25,
      node_width: 250,
      btn_width: 85,
      show_meau: false,
      click_node: "",
      fta_leaf_data: [],
      fta_not_leaf_data: [],
      fta_tree_data: {},
      fta_door_list: [],
      eventTypes: [],
      fta_door_map: {},
      fta_table: [],
      // id key 映射对象
      id_to_key: {},
      key_to_id: {},
      loading: true,
      treeIds: {},
      leafCounter: 0,
      nonLeafCounter: 0,
      topic_to_ids: {},
    };
  },
  mounted() {
    //获取当前文件节点信息
    getFile(this.get_pid(), this.$route.params.file_key).then((res) => {
      //获取当前文件fta门
      get_file_fta_door(this.get_pid(), this.$route.params.file_key)
        .then((door_res) => {
          get_file_fta_leaf_door(
            this.get_pid(),
            this.$route.params.file_key
          ).then((leaf_door_res) => {
            this.eventTypes = leaf_door_res.eventTypes;
            // 下面全是准备数据

            res.data.forEach((item) => {
              this.id_to_key[item.id] = item.key;
            });
            res.data.forEach((item) => {
              this.key_to_id[item.key] = item.id;
            });

            this.fta_door_list = [];

            let fta_d = door_res ? door_res : [];
            fta_d.forEach((element) => {
              this.fta_door_list.push({
                //后端数据1是and，2是or
                type: element.gateType + "" == "1" ? "and" : "or",
                id: this.key_to_id[element.nodeKey],
              });
            });
            this.fta_tree_data = this.list_2_tree(res.data);
            this.fta_leaf_data = this.find_leaf_nodes(res.data);
            this.findLeafNodes(this.fta_tree_data);
            this.fta_not_leaf_data = this.find_not_leaf_nodes(res.data);
            this.fta_btn_num = this.fta_leaf_data.length;
            this.fta_door_list.forEach((item) => {
              this.fta_door_map[item.id] = item.type;
            });

            this.init(res.data);
          });
        })
        .catch((err) => {
          //get_file_fta_door接口后端会判断用户有没有使用fta的权限，没有的话接口报错关闭fta并弹出购买弹窗
          vmson.$emit("fta_buy_change");
        });
    });
  },
  methods: {
    findLeafNodes(node) {
      let leafCounter = 0;
      let nonLeafCounter = 0;
      let idToNumber = {};
      let that = this;

      function numberNodes(node) {
        if (node.children.length === 0) {
          // 叶子节点
          leafCounter += 1;
          idToNumber[node.id] = leafCounter;
          that.topic_to_ids[node.topic] = leafCounter;
        } else {
          // 非叶子节点
          for (let i = node.children.length - 1; i >= 0; i--) {
            numberNodes(node.children[i]);
          }
          nonLeafCounter += 1;
          idToNumber[node.id] = nonLeafCounter;
          that.topic_to_ids[node.topic] = nonLeafCounter;
        }
      }

      numberNodes(node);
      this.treeIds = idToNumber;
      this.leafCounter = leafCounter;
      this.nonLeafCounter = nonLeafCounter;
    },
    format_input(input) {
      // 规范用户输入，把分数转化成小数计算
      input = input.toString();
      let l = input.split("/");
      if (l.length == 2) {
        let a = Number(l[0]);
        let b = Number(l[1]);
        if (!isNaN(a) && !isNaN(b) && b != 0) {
          let value = a / b;
          return value;
        }
      }
      let n = Number(input);
      if (!isNaN(n)) {
        return n;
      }
    },
    is_valid_input(input) {
      // 判断用户输入是否合法
      input = input.toString();
      let l = input.split("/");
      if (l.length == 2) {
        let a = Number(l[0]);
        let b = Number(l[1]);
        if (!isNaN(a) && !isNaN(b) && b != 0) {
          let value = a / b;
          return value >= 0 && value <= 1;
        }
      }
      let n = Number(input);
      if (!isNaN(n)) {
        return n >= 0 && n <= 1;
      }
      return false;
    },
    tip() {
      // fta_stop 非叶子节点后只有一个节点?true:false
      if (this.fta_stop) {
        this.$message({
          message: this.$t("fta.warning"),
          type: "warning",
          duration: "5000",
        });
        return false;
      }
      // fta_illegal 非叶子节点未选择门?true:false
      if (this.fta_illegal) {
        this.$message({
          message:
            this.$t("fta.text1") +
            " " +
            this.fta_door_num +
            this.$t("fta.text2"),
          type: "warning",
          duration: "5000",
        });
        return false;
      }
    },
    check_door() {
      // 计算fta_door_num，未填写门的个数
      let n = 0;
      this.fta_not_leaf_data.forEach((item) => {
        if (item.id in this.fta_door_map) {
          if (this.fta_door_map[item.id]) {
            n++;
          }
        }
      });
      this.fta_door_num = this.fta_not_leaf_data.length - n;

      this.fta_door_num == 0
        ? (this.fta_illegal = false)
        : (this.fta_illegal = true);
    },
    get_min_cut(node) {
      // 计算最小割，递归
      if (node.children.length == 0) {
        return [node.topic];
      } else {
        let res = [];
        for (let i of node.children) {
          res.push(this.get_min_cut(i));
        }

        if (this.fta_door_map[node.id] == "and") {
          if (typeof res[0] == "object") {
            let l = [];
            res.forEach((item) => {
              l = [...l, ...item];
            });
            return l;
          } else {
            return res;
          }
        }
        if (this.fta_door_map[node.id] == "or") {
          let result = [""];
          for (let i in res) {
            let l = [];
            result.forEach((item1) => {
              res[i].forEach((item2) => {
                l.push(item1 + "*" + item2);
              });
            });
            result = l;
          }
          return result;
        }
      }
    },
    get_min_path(node) {
      // 计算最小径，递归
      if (node.children.length == 0) {
        return [node.topic];
      } else {
        let res = [];
        for (let i of node.children) {
          res.push(this.get_min_path(i));
        }

        if (this.fta_door_map[node.id] == "or") {
          if (typeof res[0] == "object") {
            let l = [];
            res.forEach((item) => {
              l = [...l, ...item];
            });
            return l;
          } else {
            return res;
          }
        }
        if (this.fta_door_map[node.id] == "and") {
          let result = [""];
          for (let i in res) {
            let l = [];
            result.forEach((item1) => {
              res[i].forEach((item2) => {
                l.push(item1 + "*" + item2);
              });
            });
            result = l;
          }
          return result;
        }
      }
    },
    get_importance(ind, p_list, node) {
      // 计算节点重要度，递归
      if (node.children.length == 0) {
        return p_list[ind[node.topic]];
      } else {
        let res = [];
        for (let i of node.children) {
          res.push(this.get_importance(ind, p_list, i));
        }

        if (this.fta_door_map[node.id] == "or") {
          let p = res[0];
          res.forEach((item) => {
            p = p & item;
          });
          return p;
        }

        if (this.fta_door_map[node.id] == "and") {
          let p = res[0];
          res.forEach((item) => {
            p = p | item;
          });
          return p;
        }
      }
    },
    get_top_p(node) {
      // 计算顶事件概率，递归
      if (node.children.length == 0) {
        return this.fta_form[node.id].toString();
      } else {
        let res = [];
        for (let i of node.children) {
          res.push(this.get_top_p(i));
        }

        if (this.fta_door_map[node.id] == "or") {
          let p = 1;
          res.forEach((item) => {
            p = p * (1 - this.format_input(item));
          });
          p = 1 - p;
          return p;
        }

        if (this.fta_door_map[node.id] == "and") {
          let p = 1;
          res.forEach((item) => {
            p = p * this.format_input(item);
          });
          return p;
        }
      }
    },
    resetForm() {
      // 重置顶事件概率弹窗用户输入
      this.fta_form = {};
      this.calculate_res = -1;
      this.fta_change();
      document.getElementById("fta_calculate_reset_btn").blur();
      this.$nextTick(() => {
        this.$refs["ftaForm"] ? this.$refs["ftaForm"].clearValidate() : "";
      });
    },
    fta_change() {
      // 计算顶事件概率表单不合法输入的个数
      let num = 0;

      Object.keys(this.fta_form).forEach((item) => {
        if (item in this.fta_form) {
          if (this.fta_form[item]) {
            if (this.fta_form[item].length > 0) {
              if (this.is_valid_input(this.fta_form[item])) {
                num++;
              }
            }
          }
        }
      });
      this.fta_btn_num = this.fta_leaf_data.length - num;
    },

    show_error() {
      // 用户点击顶事件概率表单的提交按钮

      this.$refs["ftaForm"].validate((valid) => {
        if (valid) {
          // 输入合法的话，先计算，然后调接口把数据发给后台，没报错的话说明用户可以用fta，然后展示计算结果
          let d = [];
          Object.keys(this.fta_form).forEach((item) => {
            d.push({
              nodeKey: this.id_to_key[item],
              probability: this.fta_form[item],
            });
          });
          let cal_res = this.get_top_p(this.fta_tree_data).toFixed(2);
          put_file_fta_probability(
            this.get_pid(),
            this.$route.params.file_key,
            d
          )
            .then((res) => {
              this.calculate_res = cal_res;
            })
            .catch((err) => {
              vmson.$emit("fta_buy_change");
            });
        } else {
          //输入不合法的话，可以自动滚动到第一个不合法的地方
          this.$nextTick(() => {
            let isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              block: "center",
              behavior: "smooth",
            });
          });
          return false;
        }
      });
    },
    is_array_contained(arr1, arr2) {
      // 判断arr1 是否包含 arr2
      return arr2.every((elem) => arr1.includes(elem));
    },
    remove_array(arr) {
      // 从arr中移除所有被其他数组包含的数组
      let i = 0;
      while (i < arr.length) {
        let j = 0;
        while (j < arr.length) {
          if (i !== j && this.is_array_contained(arr[i], arr[j])) {
            arr.splice(i, 1);
            break;
          }
          j++;
        }
        if (j === arr.length) {
          i++;
        }
      }
      return arr;
    },
    generate_combinations(n) {
      // 生成所有可能的 n 位二进制组合
      let result = [];
      for (let i = 0; i < Math.pow(2, n); i++) {
        let bin = (i >>> 0).toString(2).padStart(n, "0");
        let tempArr = [];
        for (let j = 0; j < bin.length; j++) {
          tempArr.push(parseInt(bin[j]));
        }
        result.push(tempArr);
      }
      return result;
    },
    find_arr(arr, i) {
      //在二维数组 arr 中找出所有在第 i 个位置上元素不同，但在其他位置上元素相同的数组对。
      let result = [];
      for (let j = 0; j < arr.length; j++) {
        for (let k = j + 1; k < arr.length; k++) {
          if (
            arr[j][i] !== arr[k][i] &&
            arr[j]
              .slice(0, i)
              .concat(arr[j].slice(i + 1))
              .join("") ===
              arr[k]
                .slice(0, i)
                .concat(arr[k].slice(i + 1))
                .join("")
          ) {
            result.push([arr[j], arr[k]]);
          }
        }
      }
      return result;
    },
    handleCommand(text) {
      // 左上角按钮点击事件
      this.calculate_title = this.$t("fta." + text);
      if (text == "minpath") {
        let r = [];
        let res = [];
        let result = this.get_min_cut(this.fta_tree_data);

        if (typeof result[0] == "string") {
          r = result;
        } else {
          result.forEach((item) => {
            r = [...r, ...item];
          });
        }

        r.forEach((item) => {
          let s = new Set();
          let arr = item.split("*");
          for (let i = 0; i < arr.length; ++i) {
            if (arr[i].length > 0) {
              s.add(arr[i]);
            }
          }
          res.push([...s]);
        });
        this.fta_table = this.remove_array(res);
        this.calculateVisible = true;
      }
      if (text == "mincut") {
        let r = [];
        let res = [];
        let result = this.get_min_path(this.fta_tree_data);

        if (typeof result[0] == "string") {
          r = result;
        } else {
          result.forEach((item) => {
            r = [...r, ...item];
          });
        }

        r.forEach((item) => {
          let s = new Set();
          let arr = item.split("*");
          for (let i = 0; i < arr.length; ++i) {
            if (arr[i].length > 0) {
              s.add(arr[i]);
            }
          }
          res.push([...s]);
        });
        this.fta_table = this.remove_array(res);
        this.calculateVisible = true;
      }
      if (text == "structural") {
        let res = {};
        let ind = {};
        let importance = {};
        let all_event = new Set();
        this.fta_leaf_data.forEach((item) => {
          all_event.add(item.topic);
        });
        all_event = [...all_event];
        let num = 0;
        all_event.forEach((item) => {
          ind[item] = num;
          num++;
        });

        let n = all_event.length;

        let l = this.generate_combinations(n);
        l.forEach((item) => {
          res[item] = this.get_importance(ind, item, this.fta_tree_data);
        });

        for (let i of Object.keys(ind)) {
          let lis = this.find_arr(l, ind[i]);
          let num = 0;
          lis.forEach((item) => {
            num += Math.abs(res[item[0]] - res[item[1]]);
          });
          importance[i] = (num / 2 ** (n - 1)).toFixed(2);
        }
        this.fta_table = Object.entries(importance).sort((a, b) => a[1] - b[1]);
        this.fta_table = this.fta_table.reverse();
        this.importanceVisible = true;
      }
      if (text == "topevent") {
        this.topeventVisible = true;
        this.$nextTick(() => {
          get_file_fta_probability(
            this.get_pid(),
            this.$route.params.file_key
          ).then((res) => {
            this.fta_form = {};
            let d = [];
            this.fta_leaf_data.forEach((item) => {
              d.push(item.key);
            });

            res.probabilities.forEach((item) => {
              if (d.includes(item.nodeKey) && item.probability) {
                this.$set(
                  this.fta_form,
                  this.key_to_id[item.nodeKey],
                  item.probability
                );
              }
            });

            this.fta_change();
          });
        });
      }
    },
    find_not_leaf_nodes(list) {
      let map = {};
      for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        list[i].isLeaf = true;
      }
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].parentid !== "") {
          list[map[list[i].parentid]].isLeaf = false;
        }
      }
      let a = list.filter((node) => !node.isLeaf);
      let b = [];
      a.forEach((element) => {
        b.push(element);
      });
      return b;
    },
    find_leaf_nodes(list) {
      let map = {};
      for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        list[i].isLeaf = true;
      }
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].parentid !== "") {
          list[map[list[i].parentid]].isLeaf = false;
        }
      }
      let a = list.filter((node) => node.isLeaf);
      let b = [];
      a.forEach((element) => {
        b.push(element);
      });
      return b;
    },
    list_2_tree(list) {
      let map = {},
        node,
        roots = [];
      for (let i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        list[i].children = [];
      }
      for (let i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentid !== "") {
          list[map[node.parentid]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots[0];
    },
    get_graph_data(tree) {
      // 创建fta节点数据
      let data = [];
      let leaf_nodes = this.find_leaf_nodes(tree);
      let leaf_nodes_id = [];
      leaf_nodes.forEach((item) => {
        leaf_nodes_id.push(item.id);
      });
      tree.forEach((item) => {
        leaf_nodes_id.includes(item.id)
          ? data.push(this.create_leaf_node(item))
          : data.push(this.create_not_leaf_node(item));
      });
      // Hierarchy可以计算tree结构的数据每个节点的位置
      const result = Hierarchy.mindmap(this.list_2_tree(data), {
        direction: "H",
        getHeight(d) {
          return d.height;
        },
        getWidth(d) {
          return d.width;
        },
        getHGap(d) {
          return 40;
        },
        getVGap() {
          return 15;
        },
        getSide: () => {
          return "right";
        },
      });
      let box = document.getElementById("myDiv");
      box.parentNode.removeChild(box);
      return result;
    },
    calculate_node_width_height(text) {
      // 根据文本，计算每个节点的宽
      const canvas = document.getElementById("myCanvas");
      const ctx = canvas.getContext("2d");
      ctx.font = "18px Arial";
      ctx.fillText(text, 0, 0);
      const width = ctx.measureText(text).width;

      return width;
    },
    new_calculate_node_width_height(text) {
      // 根据文本，计算每个节点的高
      const div = document.getElementById("myDiv");
      div.innerText = text;

      return div.clientHeight;
    },
    create_not_leaf_node(tree_node) {
      // 创建非叶子节点
      // 非叶子节点宽度=节点宽度+节点和门之间的连接线宽度+门节点宽度
      // width: node_width + this.line_width + this.btn_width
      let that = this;
      let width = this.calculate_node_width_height(tree_node.topic);
      let node_height =
        10 + this.new_calculate_node_width_height(tree_node.topic); //行高是25，有几行就25*几，10=padding top+bottom
      // node_width，如果计算出的width>this.node_width(规定的节点最大宽度)，那么就让节点=规定的最大宽度
      let node_width =
        (width > this.node_width ? this.node_width : width) +
        this.node_padding_width;
      let data = {
        leaf: false,
        layer: tree_node.layer,
        width: node_width + this.line_width + this.btn_width,
        raw_width: width,
        topic: tree_node.topic,
        height: node_height,
        parentid: tree_node.parentid,
        shape: "html",
        id: tree_node.id,
        html() {
          const con = document.createElement("div"); // 节点+线+门
          const node = document.createElement("div"); // 节点
          const line = document.createElement("div"); // 线
          const btn = document.createElement("div"); // 门
          const gt = document.createElement("div"); // gt
          const door = document.createElement("div"); // gt

          con.style.display = "flex";
          con.style.alignItems = "center";
          con.style.width =
            node_width + that.line_width + that.btn_width + "px";
          con.style.height = node_height + "px";

          node.id = "node" + tree_node.id;
          node.style.width = node_width + "px";
          node.style.background = "#428bca";
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.alignItems = "center";
          node.style.border = "1px solid #357ebd";
          node.style.borderRadius = "5px";
          node.style.padding = "5px 10px";
          node.style.wordBreak = "break-all";
          node.style.font = "18px Arial";
          node.style.color = "white";
          node.style.lineHeight = "25px";
          node.innerText = tree_node.topic;
          // node.innerText =
          //   "GT" + (that.nonLeafCounter - 1 - that.treeIds[tree_node.id]);

          line.style.width = that.line_width + "px";
          line.style.height = "1px";
          line.style.background = "green";

          btn.style.width = that.btn_width + "px";
          btn.style.cursor = "pointer";
          btn.id = "img" + tree_node.id;
          btn.style.height = "35px";
          // btn.src = that.null_img;
          btn.className = "null_door";
          gt.innerText =
            "GT" + (that.nonLeafCounter - that.treeIds[tree_node.id]);
          gt.id = "img" + tree_node.id + "gt";
          gt.className = "gt";
          door.innerText = that.$t("fta.null_door");
          door.id = "img" + tree_node.id + "door";
          door.className = "door";

          btn.appendChild(gt);
          btn.appendChild(door);

          con.appendChild(node);
          con.appendChild(line);
          con.appendChild(btn);
          return con;
        },
      };
      return data;
    },
    create_leaf_node(tree_node) {
      // 创建叶子节点
      // 叶子节点宽度=节点宽度
      // width: node_width
      let that = this;
      let width = this.calculate_node_width_height(tree_node.topic);
      let node_height =
        10 + this.new_calculate_node_width_height(tree_node.topic); //行高是25，有几行就25*几，10=padding top+bottom
      // node_width，如果计算出的width>this.node_width(规定的节点最大宽度)，那么就让节点=规定的最大宽度
      let node_width =
        (width > this.node_width ? this.node_width : width) +
        this.node_padding_width;
      let data = {
        leaf: true,
        layer: tree_node.layer,
        parentid: tree_node.parentid,
        raw_width: width,
        topic: tree_node.topic,
        shape: "html",
        width: node_width + 60,
        height: node_height,
        id: tree_node.id,
        html() {
          const con = document.createElement("div");
          const node = document.createElement("div");
          const btn_img = document.createElement("div");

          con.style.display = "flex";
          con.className = "aaa";
          con.style.alignItems = "center";
          con.style.justifyContent = "center";

          node.id = "node" + tree_node.id;
          node.style.width = node_width + "px";
          node.style.lineHeight = "25px";
          node.style.background = "#428bca";
          node.style.color = "white";
          node.style.display = "flex";
          node.style.justifyContent = "center";
          node.style.padding = "5px 10px";
          node.style.alignItems = "center";
          node.style.border = "1px solid #357ebd";
          node.style.borderRadius = "5px";
          node.style.font = "18px Arial";
          node.style.wordBreak = "break-all";
          node.innerText = tree_node.topic;

          btn_img.style.width = "58px";
          btn_img.style.cursor = "pointer";
          btn_img.id = "leaf" + tree_node.id;
          btn_img.style.height = "40px";
          btn_img.innerText =
            "EV" + (that.leafCounter - that.treeIds[tree_node.id]);
          btn_img.style.display = "flex";
          btn_img.style.justifyContent = "center";
          btn_img.style.alignItems = "center";
          btn_img.className = "basic_leaf";

          con.appendChild(node);
          con.appendChild(btn_img);

          return con;
        },
      };
      return data;
    },
    create_edge(source_node, target_node) {
      let data = {
        source: source_node, // 起始节点 id
        target: target_node, // 目标节点 id
        markup: [
          {
            tagName: "path",
            selector: "stroke",
          },
        ],
        attrs: {
          stroke: {
            fill: "none",
            stroke: "#8f8f8f",
            connection: true,
            strokeWidth: 2,
            strokeLinecap: "round",
          },
        },

        router: {
          name: "er",
          args: {
            direction: "R",
            offser: "center",
          },
        },
        connector: "rounded",
      };
      return data;
    },
    init(tree) {
      let that = this;
      this.graph = new Graph({
        // 具体参数见官方文档 https://x6.antv.vision/zh/docs/api/graph/graph
        container: document.getElementById("container"),
        scroller: true,
        interacting: false,
        mousewheel: {
          enabled: true,
          modifiers: ["ctrl", "meta"],
        },
        // panning: {
        //   enabled: true,
        //   modifiers: ["ctrl", "meta"]
        // }
        // translating: {
        //   restrict: false
        // }
        // width: 800,
        // height: 600,
      });

      let l = [];
      let illegal_nodes = [];
      l.push(this.get_graph_data(tree));
      // 循环添加节点
      while (l.length > 0) {
        let n = l.shift();
        if (n.children.length < 2 && !n.data.leaf) {
          // 同时判断是否有fta_stop情况的节点
          illegal_nodes.push(n.data.id);
        }
        let d = n.data;
        d.x = n.x;
        d.y = n.y;

        this.graph.addNode(d); // 添加节点
        if (n.data.parentid) {
          this.graph.addEdge(this.create_edge(n.data.id, n.data.parentid)); // 添加边
        }
        l = [...l, ...n.children];
      }

      this.graph.centerContent(); // 视图居中

      // 点击空白处，关闭选择门弹窗
      this.graph.on("blank:click", ({ e, x, y, node, view }) => {
        this.graph.getNodes().forEach((item) => {
          if (item.id == "meau") {
            item.remove();
          }
        });
      });

      // 点击节点事件
      this.graph.on("cell:click", ({ e, x, y, node, view }) => {
        let that = this;

        // 关闭meau
        this.graph.getNodes().forEach((item) => {
          if (item.id == "meau") {
            item.remove();
          }
        });

        // 如果点的是and按钮
        if (e.target.id.slice(0, 6) == "meauan") {
          if (!that.fta_door_map[that.click_node]) {
            new_node_fta_door(
              that.get_pid(),
              that.id_to_key[that.click_node],
              "1"
            ).then((res) => {
              document.getElementById("img" + that.click_node).className =
                "and_door";
              document.getElementById(
                "img" + that.click_node + "door"
              ).innerText = this.$t("fta.and_door");
              that.fta_door_map[that.click_node] = "and";
              that.check_door();
            });
          } else {
            change_node_fta_door(
              that.get_pid(),
              that.id_to_key[that.click_node],
              "1"
            ).then((res) => {
              document.getElementById("img" + that.click_node).className =
                "and_door";
              document.getElementById(
                "img" + that.click_node + "door"
              ).innerText = this.$t("fta.and_door");
              that.fta_door_map[that.click_node] = "and";
              that.check_door();
            });
          }
        }

        // 如果点的是or按钮
        if (e.target.id.slice(0, 6) == "meauor") {
          // 如果当前节点存在门，则掉change接口，否则调new接口
          if (!that.fta_door_map[that.click_node]) {
            new_node_fta_door(
              that.get_pid(),
              that.id_to_key[that.click_node],
              "2"
            ).then((res) => {
              document.getElementById("img" + that.click_node).className =
                "or_door";
              document.getElementById(
                "img" + that.click_node + "door"
              ).innerText = this.$t("fta.or_door");

              that.fta_door_map[that.click_node] = "or";
              that.check_door();
            });
          } else {
            change_node_fta_door(
              that.get_pid(),
              that.id_to_key[that.click_node],
              "2"
            ).then((res) => {
              document.getElementById("img" + that.click_node).className =
                "or_door";
              document.getElementById(
                "img" + that.click_node + "door"
              ).innerText = this.$t("fta.or_door");

              that.fta_door_map[that.click_node] = "or";
              that.check_door();
            });
          }
        }
        // 如果点的是门按钮
        if (e.target.id.slice(0, 3) == "img") {
          // 记录当前点击的节点
          this.click_node = view.cell.store.data.id;

          let data = {
            shape: "html",
            width: this.btn_width,
            height: 81,
            id: "meau",
            x:
              1 +
              view.cell.store.data.position.x +
              view.cell.store.data.size.width -
              this.btn_width,
            y:
              view.cell.store.data.position.y -
              40 -
              10 -
              (40 - (view.cell.store.data.size.height / 2 - 20)),
            html() {
              const con = document.createElement("div");
              const and_node = document.createElement("div");
              const or_node = document.createElement("div");
              const line = document.createElement("div");

              and_node.style.cursor = "pointer";
              and_node.style.width = that.btn_width + "px";
              and_node.style.height = 40 + "px";
              and_node.id = "meauan" + view.cell.store.data.id;
              and_node.innerText = that.$t("fta.and_door");
              and_node.style.display = "flex";
              and_node.style.justifyContent = "center";
              and_node.style.alignItems = "center";
              and_node.style.fontSize = "18px";

              or_node.style.cursor = "pointer";
              or_node.style.width = that.btn_width + "px";
              or_node.style.height = 40 + "px";
              or_node.id = "meauor" + view.cell.store.data.id;
              or_node.innerText = that.$t("fta.or_door");
              or_node.style.display = "flex";
              or_node.style.justifyContent = "center";
              or_node.style.alignItems = "center";
              or_node.style.fontSize = "18px";

              line.style.height = 1 + "px";
              line.style.width = that.btn_width * 0.75 + "px";
              line.style.backgroundColor = "#B8B8B8";

              con.style.backgroundColor = "white";
              con.style.border = "2px solid #B8B8B8";
              con.style.borderRadius = "5px";
              con.style.display = "flex";
              con.style.justifyContent = "center";
              con.style.alignItems = "center";
              con.style.flexDirection = "column";

              con.appendChild(and_node);
              con.appendChild(line);
              con.appendChild(or_node);

              return con;
            },
          };

          this.graph.addNode(data);
        }

        // 如果点的是底事件按钮
        if (e.target.id.slice(0, 4) == "leaf") {
          let data = {
            nodeKey: that.id_to_key[view.cell.store.data.id],
          };
          if (
            document.getElementById(e.target.id).className == "undeveloped_leaf"
          ) {
            data.type = "Basic";
            put_file_fta_leaf_door(
              that.get_pid(),
              that.$route.params.file_key,
              data
            ).then((res) => {
              document.getElementById(e.target.id).className = "basic_leaf";
            });
          } else {
            data.type = "Undeveloped";
            put_file_fta_leaf_door(
              that.get_pid(),
              that.$route.params.file_key,
              data
            ).then((res) => {
              document.getElementById(e.target.id).className =
                "undeveloped_leaf";
            });
          }
        }
      });

      // 添加fta门
      this.fta_door_list.forEach((item) => {
        if (item.type == "or" && document.getElementById("img" + item.id)) {
          document.getElementById("img" + item.id).className = "or_door";
          document.getElementById("img" + item.id + "door").innerText =
            this.$t("fta.or_door");
        }
        if (item.type == "and" && document.getElementById("img" + item.id)) {
          document.getElementById("img" + item.id).className = "and_door";
          document.getElementById("img" + item.id + "door").innerText =
            this.$t("fta.and_door");
        }
      });

      // 添加fta底事件类型
      (this.eventTypes || []).forEach((item) => {
        let node_id = this.key_to_id[item.nodeKey];
        if (
          item.type == "Undeveloped" &&
          document.getElementById("leaf" + node_id)
        ) {
          document.getElementById("leaf" + node_id).className =
            "undeveloped_leaf";
        }
      });
      // 不合法节点变红
      illegal_nodes.forEach((item) => {
        document.getElementById("node" + item).style.background = "#FC3408";
        document.getElementById("node" + item).style.border = "2px solid red";
      });

      // 提示存在不合法节点
      if (illegal_nodes.length > 0) {
        this.fta_stop = true;
        this.$message({
          message: this.$t("fta.warning"),
          type: "warning",
          duration: "5000",
        });
      } else {
        this.fta_stop = false;
      }

      this.check_door(); // 计算未填写门的个数
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.or_door {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #2d5d87;
  font-size: 16px; /*no*/
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
  background: url("https://automind-oss.ytdevops.com/7db3b28d-c80f-4fbe-895e-6209c6c4362b/node_accessors/1700709977145%E5%87%8F%E5%8E%BB%E9%A1%B6%E5%B1%82%20(3).png");
  .door {
    margin-right: 2px; /*no*/
  }
}
.and_door {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #2d874b;
  font-size: 16px; /*no*/
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
  background: url("https://automind-oss.ytdevops.com/7db3b28d-c80f-4fbe-895e-6209c6c4362b/node_accessors/1700709977139%E5%88%86%E7%BB%84%2032%20(2).png");
}

.null_door {
  // background-color: red;
  border: 1px solid #872d2d;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #872d2d;
  font-size: 16px; /*no*/
}

.basic_leaf {
  color: #4968a6;
  background: url("https://automind-oss.ytdevops.com/7db3b28d-c80f-4fbe-895e-6209c6c4362b/node_accessors/1700641519275%E5%88%86%E7%BB%84%201.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.undeveloped_leaf {
  color: #4968a6;
  background: url("https://automind-oss.ytdevops.com/7db3b28d-c80f-4fbe-895e-6209c6c4362b/node_accessors/1700644572133%E5%88%86%E7%BB%84%201%EF%BC%881%EF%BC%89.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
#ftaForm {
  .el-form-item__content {
    width: 49%;
    margin-left: 0 !important;
  }
}
.fta_style {
  .el-dropdown-menu__item {
    padding: 0 10px !important;
  }
  .popper__arrow {
    left: 0px !important;
  }
  padding: 0 !important;
}
</style>
<style scoped>
#myDiv {
  font: 18px Arial; /*no*/
  max-width: 250px; /*no*/
  line-height: 25px; /*no*/
}
.myDiv {
  font: 18px Arial; /*no*/
  max-width: 250px; /*no*/
  line-height: 25px; /*no*/
}
.fta_res {
  background-color: rgb(236, 236, 236);
  margin-right: 25px;
}
#container {
  width: 100%;
  height: 100%;
}
</style>
