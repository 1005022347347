import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
// 获取配置规则
export function getchangeRequestConfig(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/changeRequestConfig`,
    method: "get",
  });
}
// 修改配置委员会
export function changeCommittee(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequestConfig/changeCommittee`,
    method: "put",
    data: params.data
  });
}
// 修改通过规则
export function changeRule(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequestConfig/rule`,
    method: "put",
    data: params.data
  });
}
// 新增相关字段
export function changeRequestAddfield(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequestConfig/field`,
    method: "post",
    data: params.data
  });
}
// 删除相关字段
export function changeRequestDeletefield(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequestConfig/field/${params.fieldReferenceId}`,
    method: "delete",
  });
}
// 修改相关字段
export function changeRequestChangefield(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequestConfig/field/${params.fieldReferenceId}`,
    method: "put",
    data: params.data
  });
}
// 关联字段排序
export function changeRequestSortfield(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/changeRequestConfig/field/sort`,
    method: "put",
    data: params.data
  });
}
