<template>
  <div>
    <el-row style="padding-bottom: 40px">
      <div class="textarea">
        <div class="textarea_main">
          <div class="at-vue-container">
            <at-vue
              ref="atVue"
              @focus="onAtFocus('')"
              @getInfo="sendComment"
            ></at-vue>
          </div>
        </div>
        <div class="textarea_oprations">
          <el-button type="info" size="small" @click="cancel_click"
            >{{ $t("btn.cancelBtn") }}
          </el-button>
          <el-button
            type="primary"
            size="small"
            :loading="loading && reply_comment_id == ''"
            @click="sendButtonClick('')"
          >
            {{ $t("btn.sendBtn") }}
          </el-button>
        </div>
      </div>
      <div style="padding-top: 30px">
        <el-row
          v-for="(item, index) in comments_list_display"
          :key="index"
          :class="{
            'comment-row': true,
            'comment-row-topborder': !item.parent_uuid,
          }"
        >
          <div class="comment-row__main">
            <div v-if="item.parent_uuid" style="width: 67px; flex: none"></div>
            <personal-avatar
              :avatar="item.authorInfo.avatar"
              :colorid="item.authorInfo.accountId"
              :nickname="item.authorInfo.nickname"
              :size="40"
              :tool-tip-disabled="true"
            ></personal-avatar>
            <div class="comment-row__info">
              <div class="username_and_comment-text">
                <div class="username">{{ item.authorInfo.nickname }}：</div>
                <div class="reply_info">
                  <span v-if="item.reply_nickname"
                    >{{ $t("btn.replyBtn") }}
                  </span>
                  <span v-if="item.reply_nickname" style="color: #409eff">
                    {{ `@${item.reply_nickname}` }}</span
                  >
                  <span v-if="item.reply_nickname">： </span>
                </div>
                <div class="comment-text">
                  <span
                    v-for="(content_item, idx) in item.content"
                    :key="idx"
                    :style="{
                      color: content_item.type == 'at' ? '#409EFF' : '',
                    }"
                  >
                    <span v-if="content_item.type === 'file'" class="imgbox">
                      <img :src="content_item.data" alt="" class="copyimg" />
                    </span>
                    <!--                    <span v-else>{{content_item.type == 'at'? '@' + content_item.data.nickname : content_item.data}}</span>-->
                    <div
                      v-else
                      style='white-space:pre-wrap'
                      v-html="
                        content_item.type == 'at'
                          ? '@' + content_item.data.nickname
                          : linkify(content_item.data)
                      "
                    ></div>
                  </span>
                </div>
              </div>
              <div class="time_and_reply">
                <div class="time">{{ item.formativeTime }}</div>
                <i
                  v-if="item.IsLike === false"
                  class="iconfont icon-a-31dianzan"
                  style="margin-left: 20px; cursor: pointer"
                  @click="like_click(item.comment_uuid)"
                ></i>
                <i
                  v-if="item.IsLike === true"
                  class="iconfont icon-k_zan"
                  style="margin-left: 20px; cursor: pointer; color: #000"
                  @click="dislike_click(item.comment_uuid)"
                ></i>
                <div class="like_num">{{ item.TotalLike }}</div>
                <div class="reply" @click="reply_click(item.comment_uuid)">
                  {{ $t("btn.replyBtn") }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="reply_comment_id == item.comment_uuid"
            class="comment-row__reply"
          >
            <div v-if="item.parent_uuid" style="width: 67px; flex: none"></div>
            <div class="textarea" style="width: 100%">
              <div class="textarea_reply">
                <span style="margin-right: 5px">{{ $t("btn.replyBtn") }}</span>
                <span style="color: #409eff">{{ reply_nickname }}</span>
                <span>:</span>
              </div>
              <div class="textarea_main">
                <div class="at-vue-container">
                  <at-vue
                    :ref="`atVue${item.comment_uuid}`"
                    @focus="onAtFocus(item.comment_uuid)"
                    @getInfo="sendComment"
                  ></at-vue>
                </div>
              </div>
              <div class="textarea_oprations">
                <el-button type="info" size="small" @click="cancel_click"
                  >{{ $t("btn.cancelBtn") }}
                </el-button>
                <el-button
                  type="primary"
                  size="small"
                  :loading="loading && reply_comment_id == item.comment_uuid"
                  @click="sendButtonClick(item.comment_uuid)"
                  >{{ $t("btn.sendBtn") }}
                </el-button>
              </div>
            </div>
          </div>
        </el-row>
      </div>
    </el-row>
  </div>
</template>

<script>
import "@/components/svgIcon";
import PersonalAvatar from "../../personal";
import AtVue from "@/components/at";
import { get_node_use_node_key } from "@/network/node/index.js";
import {
  add_comment,
  dislike_comments,
  get_comments,
  like_comments,
} from "@/network/comment/index.js";
import { mapGetters } from "vuex";
export default {
  components: { PersonalAvatar, AtVue },
  inject: ["nodeKey", "members"],
  data() {
    return {
      loading: false,
      comments_list: [],
      comments_list_display: [],
      reply_comment_id: "",
      is_sticky: false,
      sticky_comment_list: [],
    };
  },
  computed: {
    ...mapGetters({
      all_users: "user_list",
    }),
    user_list() {
      return Object.values(this.all_users);
    },
    reply_nickname() {
      const target_comment = this.comments_list.find(
        (item) => item.comment_uuid == this.reply_comment_id
      );
      if (target_comment) {
        return this.user_list.find(
          (item) => item.accountId == target_comment.author
        ).nickname;
      }
      return "";
    },
  },
  watch: {
    comments_list: function () {
      let maxLike = Math.max.apply(
        Math,
        this.comments_list.map((i) => {
          return i.TotalLike;
        })
      ); // 最大点赞数
      const comments_list_temp = [];
      this.comments_list.forEach((comment) => {
        const parent_comment = this.comments_list.find(
          (item) => item.comment_uuid == comment.parent_uuid
        );
        if (parent_comment) {
          if (parent_comment.children) {
            parent_comment.children.push(comment);
          } else {
            parent_comment.children = [comment];
          }
          comment.reply_nickname = this.user_list.find(
            (item) => item.accountId == parent_comment.author
          ).nickname;
        } else {
          comments_list_temp.push(comment);
        }
      });

      const find_sticky_list = (parent, root) => {
        if (
          root.TotalLike === maxLike &&
          this.sticky_comment_list.indexOf(parent.comment_uuid) === -1
        ) {
          this.sticky_comment_list.push(parent.comment_uuid);
        } else if (root.children) {
          root.children.forEach((item) => {
            find_sticky_list(parent, item);
          });
        }
      };

      // 找到要置顶的评论list
      if (!this.is_sticky && maxLike !== 0) {
        comments_list_temp.forEach((item) => {
          find_sticky_list(item, item);
        });
      }
      this.is_sticky = true;

      // 置顶评论
      let maxNum = 0; // 已经置顶的评论数量
      comments_list_temp.forEach((item, index) => {
        if (this.sticky_comment_list[maxNum] === item.comment_uuid) {
          comments_list_temp.splice(index, 1);
          comments_list_temp.splice(maxNum, 0, item);
          maxNum++;
        }
      });

      // dfs深度优先搜索
      const dfs = (root) => {
        this.comments_list_display.push(root);
        if (root.children) {
          root.children.forEach((item) => {
            dfs(item);
          });
        }
      };
      this.comments_list_display = [];
      comments_list_temp.forEach((item) => {
        dfs(item);
      });
    },
  },
  created() {
    this.get_comments_list();
  },
  methods: {
    linkify(text) {
      // 判断是否是网络链接
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, '<a href="$&" target="_blank">$&</a>');
    },
    // At框聚焦
    onAtFocus(id) {
      this.reply_comment_id = id;
    },
    // 获得评论信息
    async get_comments_list() {
      const res = await get_comments(this.get_pid(), this.nodeKey);
      if (!res) return;
      res.forEach((item) => {
        // 评论发布者信息
        const target_user = this.user_list.find(
          (info) => info.accountId == item.author
        );
        item.authorInfo = target_user;
        // content
        item.content = JSON.parse(item.content);
        // 格式化时间
        let formativeTime = new Date(item.created_at);
        item.formativeTime =
          `${formativeTime.getFullYear()}` +
          "." +
          `${formativeTime.getMonth() + 1}` +
          "." +
          `${formativeTime.getDate()}` +
          `
            ${
              formativeTime.getHours() > 9
                ? formativeTime.getHours()
                : "0" + formativeTime.getHours()
            }:${
            formativeTime.getMinutes() > 9
              ? formativeTime.getMinutes()
              : "0" + formativeTime.getMinutes()
          }:${
            formativeTime.getSeconds() > 9
              ? formativeTime.getSeconds()
              : "0" + formativeTime.getSeconds()
          }`;
      });
      this.comments_list = res;
      this.comments_list.sort((a, b) => {
        const aDate = new Date(a.created_at);
        const bDate = new Date(b.created_at);
        return bDate.getTime() - aDate.getTime();
      });
    },

    // 点击发送
    sendButtonClick(id) {
      if (id == "") {
        this.$refs[`atVue${id}`].sendAtInfo();
      } else {
        this.$refs[`atVue${id}`][0].sendAtInfo();
      }
    },

    // 发送评论
    sendComment(commentInfo) {
      if (commentInfo.length === 0) {
        this.$message({
          type: "warning",
          message: this.$t("nodeDetail.activity.comment.info1"),
        });
        return;
      }
      this.loading = true;
      get_node_use_node_key(this.get_pid(), this.nodeKey)
        .then((res) => {
          const data = {
            nodeKey: this.nodeKey,
            nodeUuid: res.nodeUuid,
            parentUuid: this.reply_comment_id,
            content: JSON.stringify(commentInfo),
            TenantId: "",
          };
          add_comment(this.get_pid(), data)
            .then((res) => {
              this.$message({
                type: "success",
                message: this.$t("nodeDetail.activity.comment.info2"),
              });
              // 清除文本框内容
              if (this.reply_comment_id == "") {
                this.$refs["atVue"].input_clear();
              } else {
                this.$refs[`atVue${this.reply_comment_id}`][0].input_clear();
              }
              // 取消回复状态
              this.reply_comment_id = "";
              // 更新当前评论列表
              this.get_comments_list()
                .then((res) => {
                  this.loading = false;
                })
                .catch((err) => {
                  this.loading = false;
                });
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: this.$t("nodeDetail.activity.comment.info3"),
              });
              this.loading = false;
            });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 回复评论点击
    reply_click(comment_id) {
      this.reply_comment_id = comment_id;
    },
    // 点赞按钮点击
    like_click(comment_id) {
      like_comments(this.get_pid(), comment_id).then((res) => {
        // 更新当前评论列表
        this.get_comments_list()
          .then((res) => {
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      });
    },
    // 取消点赞按钮点击
    dislike_click(comment_id) {
      dislike_comments(this.get_pid(), comment_id).then((res) => {
        // 更新当前评论列表
        this.get_comments_list()
          .then((res) => {
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      });
    },
    // 取消按钮点击
    cancel_click() {
      this.$refs["atVue"].input_clear();
      this.$refs[`atVue${this.reply_comment_id}`][0].input_clear();
      this.reply_comment_id = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-row-topborder {
  border-top: 1px solid rgba(229, 229, 229, 1);
}

.comment-row:first-child {
  border-top: none;
}

.comment-row {
  align-items: start;
  padding: 13px 0 15px;
  width: 80%;

  &__main {
    display: flex;
    align-items: start;
  }

  &__reply {
    display: flex;
  }

  &__info {
    margin-left: 17px;

    .username_and_comment-text {
      display: flex;
      align-content: flex-start;

      .username {
        flex-shrink: 0;
        font-size: 16px;
        color: rgba(128, 128, 128, 1);
        font-weight: 400;
      }

      .reply_info {
        flex-shrink: 0;
        align-self: flex-start;
      }

      .comment-text {
        align-self: flex-end;
        color: #333;
        font-size: 16px;
        font-weight: 400;
        word-break: break-all;
      }
    }

    .time_and_reply {
      display: flex;
      align-items: center;
      margin-top: 10px;
      color: rgba(128, 128, 128, 1);
      font-size: 12px;
      font-weight: 400;

      .like_num {
        margin-left: 10px;
      }

      .reply {
        margin-left: 30px;
        cursor: pointer;
      }
    }
  }
}

.textarea {
  width: 95%;
  padding: 10px 0;
}

.textarea_reply {
  font-size: 16px;
  padding: 0 0 10px 5px;
  color: rgba(128, 128, 128, 1);
}

.textarea_oprations {
  margin-top: 15px;
  float: right;
}

.at-vue-container {
  font-size: 16px;
  line-height: 20px;
  padding: 10px 20px;
  border: 1px solid rgba(166, 166, 166, 1);
  border-radius: 4px;
}

::v-deep.el-button {
  font-size: 16px;
  padding: 6px 10px;
}

.imgbox {
  height: 100%;

  .copyimg {
    max-width: 100%;
    max-height: 100%;
    object-fit: scale-down;
  }
}
</style>
