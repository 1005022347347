<template>
  <div class="bg">
    <collapse :title="$t('nodeDetail.activity.title')">
      <activity
        ref="ac"
        :node-key="nodeKey"
        :node-uuid="nodeUuid"
        :fileTest="fileTest"
        :node-info="nodeInfo"
      ></activity>
    </collapse>
  </div>
</template>

<script>
import Activity from "./Activity.vue";
import Collapse from "./Collapse.vue";
export default {
  components: { Collapse, Activity },
  props: {
    members: {
      type: [],
    },
    nodeInfo: {
      type: Object,
    },
    nodeKey: {
      type: String,
      default: "",
    },
    nodeUuid: {
      type: String,
      default: "",
    },
    fileTest: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      members: this.members,
      nodeKey: this.nodeKey,
    };
  },
  mounted() {},
  methods: {
    reloadNodeVersion() {
      this.$refs["ac"].reloadNodeVersion();
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: #fff;
  padding: 20px 10px;
  border-radius: 8px;
  margin-bottom: 80px;
}
</style>
