import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
// 获取导出字段
export function get_excel_field(projectId, fileTypeId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/export/excel/${fileTypeId}`,
    method: "get",
  });
}

// 获取当前文件类型所有的可选字段

export function post_excel_fieldSelect(projectId, fileKey, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/${fileKey}/excel`,
    method: "post",
    data,
  });
}

// 节点页查询可选字段
export function get_excel_nodeField(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/export/excel/fields`,
    method: "get",
  });
}

// 节点页批量导出
export function upload_blur_excelField(projectId, totalNum, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/excel`,
    method: "post",
    params: {
      totalNum,
    },
    data
  });
}

// 后台导出word
export function exportWordFile(projectId, fileKey, tem) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/${fileKey}/wordFile`,
    method: "get",
    params: {
      exportTemplateId: tem,
    },
  });
}
// 后台导出csv
export function exportCsvFile(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/files/${params.fileKey}/csv`,
    method: "post",
    data: params.data,
  });
}


