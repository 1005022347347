<template>
  <div id="mindmap-loading" class="element">
    <div id="jsminddrag" style="display: none">false</div>
    <div id="xnum" style="display: none">0</div>
    <div id="ynum" style="display: none">0</div>
    <div id="ex" style="display: none">0</div>
    <div id="ey" style="display: none">0</div>
    <div id="offset_x" style="display: none">0</div>
    <div id="offset_y" style="display: none">0</div>
    <p id="global_variable_allowedBatchExec">{{ allowedBatchExec }}</p>
    <el-card v-if="card_isShow && is_process_review" id="process_review">
      <div
        v-if="review_num_all > 0"
        style="
          padding-top: 30px;
          text-align: left;
          font-size: 16px;
          font-weight: 500;
          padding-left: 20px;
          display: flex;
          align-items: center;
        "
      >
        <span style="padding-right: 10px; font-weight: 500">
          {{ $t("addMindMap.tip1") }}
          <span
            style="
              font-size: 16px;
              font-weight: 700;
              color: rgba(48, 100, 143, 1);
            "
            >{{ review_num_process }}</span
          >
          {{ "/ " + review_num_all }}
        </span>
        <div
          style="
            height: 15px;
            border-radius: 20px;
            background-color: rgba(201, 201, 201, 1);
            color: white;
            width: 190px;
            display: flex;
            align-items: center;
          "
        >
          <div
            v-if="review_num_process > 0"
            :style="
              'height:15px;border-radius: 20px;display: flex;justify-content: center;align-items: center;background-color:rgba(48, 100, 143, 0.63);color:white;width:' +
              (review_num_process / review_num_all) * 100 +
              '%'
            "
          >
            {{ review_num_process }}
          </div>
          <div
            v-show="review_num_all - review_num_process > 0"
            :style="
              'height:15px;display: flex;justify-content: center;align-items: center;width:' +
              (100 - (review_num_process / review_num_all) * 100) +
              '%'
            "
          >
            {{ review_num_all - review_num_process }}
          </div>
        </div>

        <!-- <i
          v-if="is_show_no_review_nodes"
          class="el-icon-document-copy"
          style="cursor: pointer"
          @click="clear_show_no_review_nodes()"
        />
        <i
          v-else
          class="el-icon-document-copy"
          style="cursor: pointer"
          @click="show_no_review_nodes()"
        /> -->
      </div>
      <div style="display: flex; align-items: center">
        <div
          style="
            padding-left: 20px;
            color: rgba(48, 100, 143, 1);
            font-size: 20px;
            font-weight: 700;
            text-align: left;
            height: 60px;
            display: flex;
            align-items: center;
          "
        >
          {{ $t("addMindMap.tip2") }}
        </div>
        <img
          v-if="is_show_no_review_nodes"
          src="../../../assets/img/tip2.png"
          style="
            width: 20px;
            height: 20px;
            cursor: pointer;
            margin-top: -2px;
            margin-left: 5px;
          "
          @click="clear_show_no_review_nodes()"
        />
        <img
          v-else
          src="../../../assets/img/tip3.png"
          style="
            width: 20px;
            height: 20px;
            cursor: pointer;
            margin-top: -2px;
            margin-left: 5px;
          "
          @click="show_no_review_nodes()"
        />
      </div>
      <div v-if="review_list.length > 0">
        <div
          v-for="(o, index) in review_list"
          :key="index"
          class="process_review_item"
          style="
            color: rgba(128, 128, 128, 1);
            word-break: break-all;
            padding: 15px;
            padding-left: 20px;
            font-size: 18px;
            text-align: left;
            min-height: 50px;
            display: flex;
            align-items: center;
          "
        >
          {{ index + 1 + "、" + o.content }}
        </div>
      </div>
    </el-card>
    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="rootVisible"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i>
      <span>&nbsp;{{ $t("addMindMap.p1") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="rootVisible = false">
          {{ $t("btn.confirmBtn") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="nodeVisible"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i>
      <span>&nbsp;{{ $t("addMindMap.p2") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="Delete_node">
          {{ $t("btn.deleteBtn") }}
        </el-button>
        <el-button @click="nodeVisible = false">
          {{ $t("btn.cancelBtn") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="caseone"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i>
      <span>&nbsp;{{ caseword }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="Delete_node">
          {{ $t("btn.deleteBtn") }}
        </el-button>
        <el-button @click="caseone = false">
          {{ $t("btn.cancelBtn") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="childVisible"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i>
      <span>&nbsp;{{ $t("addMindMap.p3") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="Delete_node">
          {{ $t("btn.deleteBtn") }}
        </el-button>
        <el-button @click="childVisible = false">
          {{ $t("btn.cancelBtn") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 浮窗 -->
    <node-popover
      v-if="popover_visible"
      id="hoverWindow"
      ref="nodePopover"
      :layer="layer"
      :node-uuid="nodeUuid"
      :dialog-visible.sync="andDialog_isShow"
      @change_single_node_status="change_single_node_status"
      @showRelationDialog="showRelationDialog"
      @getTreeData="showtestbug"
      @change_text="changeText"
      @showDes="showDes"
    ></node-popover>
    <!-- 查看工作流弹窗 -->
    <view-workflow
      v-if="workflow_dialog_visible"
      :file-type-id="fileTypeId"
      :layer-number="layerNumber"
    ></view-workflow>
    <right-click
      id="right_c_w"
      ref="rightWindow"
      :last_node="last_node"
      :node-data="current_node_List"
      :jm="jm"
      @closeRight="closeRightMethod"
      @clearchecked="clearchecked"
    ></right-click>
    <!-- 关联图弹窗 -->
    <relation-dialog
      v-if="relation_dialog"
      :relation_dialog="relation_dialog"
      :cur_node_key="cur_node_key"
      :file-type-id="fileTypeId"
      @closeRelation="closeRelation"
    ></relation-dialog>
    <!-- 且验证条件不满足弹窗 -->
    <workflowAndDialog
      :dialog-visible.sync="andDialog_isShow"
      :and-condition-text="andCondition_text"
    ></workflowAndDialog>

    <el-row class="saving-state" type="flex" justify="end" style="width: 98vw">
      <!--      保存状态-->
      <el-col :span="2">
        <div
          disabled
          style="color: #888888; padding-top: 10px; font-size: 14px"
        >
          {{ savingState }}
          <!-- 所有保存 -->
        </div>
      </el-col>
      <!--      分割线-->
      <div style="color: #dcdfe6; font-size: 20px; padding-top: 7px">|</div>
      <!--      帮助列表-->
      <el-col :span="2">
        <el-dropdown size="medium" @command="handleClick">
          <i
            class="el-icon-question"
            style="padding-top: 12px; margin-left: -55px"
          ></i>
          <el-dropdown-menu slot="dropdown" class="menu-item">
            <el-dropdown-item command="help_key">{{
              $t("addMindMap.p4")
            }}</el-dropdown-item>
            <el-dropdown-item command="feedback">{{
              $t("addMindMap.p5")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
    <el-button
      v-show="node_add_button_visible"
      id="node_add_button"
      ref="nodeAdd"
      type="primary"
      icon="el-icon-plus"
      circle
      @mouseover.native="onmouseover_add_button"
      @click="onmouseover_add_node"
    ></el-button>
    <!--    思维导图-->
    <div
      id="js_m_box"
      ref="jsbox"
      :style="{ opacity: jsmind_show ? 1 : 0 }"
      class="js_m_box"
      :class="{
        dfmea_model: showDFMEA,
      }"
      @mousedown.left="onmousedownClick"
      @mouseover="onmouseover_jmnode"
    >
      <js-mind
        id="topbar_can"
        ref="jsMind"
        class="topbar_can"
        height
        :values="mind"
        :options="options"
      ></js-mind>
      <iframe ref="resizeFrame" class="resizeFrame" frameborder="0"></iframe>
    </div>
    <!--    快捷键列表-->
    <el-drawer
      :title="$t('addMindMap.p4')"
      :visible.sync="help_key"
      direction="ltr"
    >
      <div>
        <el-row
          v-for="item in keys"
          :key="item.name"
          style="padding-top: 10px; padding-left: 15px; padding-right: 15px"
        >
          <el-col :span="8" style="text-align: left">
            <span class="keys">{{ item.name }}</span>
          </el-col>
          <el-col :span="8" :offset="8" style="text-align: right">
            <span>{{ item.note }}</span>
          </el-col>
        </el-row>
      </div>
    </el-drawer>

    <div v-show="left_btn_visible" class="dialog_to" style="cursor: pointer">
      <div
        class="dialog_to_box"
        @click="
          left_dialog_visible = true;
          left_btn_visible = false;
        "
      >
        <p>{{ $t("addMindMap.lab1") }}</p>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <div v-if="left_dialog_visible" class="dialog_on">
      <div class="head">
        <div style="display: flex; align-items: center">
          <i
            class="el-icon-arrow-left"
            @click="
              left_dialog_visible = false;
              left_btn_visible = true;
            "
          ></i>
          <p>{{ $t("addMindMap.lab2") }}</p>
        </div>
        <div v-if="isedit" style="display: flex; align-items: center">
          <div class="svg_sty">
            <el-tooltip
              :content="$t('addNodeTestPlan.addnull')"
              transition=""
              :open-delay="850"
              placement="top"
            >
              <i
                class="iconfont icon-add"
                style="
                  font-size: 24px;
                  cursor: pointer;
                  margin-right: 15px;
                  color: rgb(48, 100, 143);
                "
                @click="add_plan"
              ></i>
            </el-tooltip>
          </div>
          <el-button type="info" size="mini" @click="init_test_window">{{
            $t("btn.cancelBtn")
          }}</el-button>
          <el-button
            type="primary"
            size="mini"
            style="margin-right: 20px; margin-left: 20px"
            @click="change_test_window"
            >{{ $t("addMindMap.btn1") }}</el-button
          >
        </div>
        <el-tooltip
          v-else
          class="item"
          :disabled="!lock"
          effect="dark"
          :content="$t('canvas.testPlan.lock')"
          placement="top"
        >
          <span>
            <el-button
              v-limits-of-authority="'NODE_EDIT'"
              type="info"
              size="mini"
              :disabled="lock || limits_of_authority_disable('NODE_EDIT')"
              style="margin: 10px 0; margin-right: 20px"
              @click="edit_plan()"
              >{{ $t("addMindMap.btn2") }}</el-button
            >
          </span>
        </el-tooltip>
      </div>
      <div class="bo">
        <draggable
          v-model="testdata"
          animation="300"
          :scroll="true"
          :disabled="!isedit"
          handle=".mover"
          @end="on_drag_end"
        >
          <transition-group>
            <div v-for="data in testdata" :key="data.index" class="hang">
              <div class="mover">
                <div v-if="isedit" class="index">
                  <div>{{ data.index + 1 }}</div>

                  <el-tooltip
                    :content="$t('addNodeTestPlan.del')"
                    transition=""
                    :open-delay="750"
                    placement="top"
                  >
                    <i
                      class="iconfont icon-delete-red"
                      style="font-size: 20px; cursor: pointer; color: red"
                      @click="del_plan(data.index)"
                    ></i
                  ></el-tooltip>
                  <br />
                  <el-tooltip
                    :content="$t('addNodeTestPlan.copy')"
                    transition=""
                    :open-delay="750"
                    placement="top"
                  >
                    <i
                      class="el-icon-document-copy"
                      style="cursor: pointer"
                      @click="copy_step(data.index)"
                    />
                  </el-tooltip>
                </div>
                <div v-else class="index">{{ data.index + 1 }}</div>
              </div>
              <div class="ne">
                <div class="t">
                  <div class="heng">
                    <b>{{ $t("addMindMap.lab3") }}</b>
                    <el-input
                      v-if="isedit"
                      v-model="data.preConditions"
                      style="word-break: break-all; margin-right: 5px"
                      autosize
                      type="textarea"
                      :placeholder="$t('addNodeTestPlan.place1')"
                    ></el-input>
                    <p
                      v-else
                      style="word-break: break-all"
                      v-html="
                        data.preConditions
                          ? data.preConditions.replace(/\n/g, '<br>')
                          : ''
                      "
                    ></p>
                  </div>
                </div>
                <div class="b">
                  <div class="heng">
                    <b>{{ $t("addMindMap.lab4") }}</b>
                    <el-input
                      v-if="isedit"
                      v-model="data.testingProcedure"
                      style="word-break: break-all; margin-right: 5px"
                      autosize
                      type="textarea"
                      :placeholder="$t('addNodeTestPlan.place2')"
                    ></el-input>
                    <p
                      v-else
                      style="word-break: break-all"
                      v-html="
                        data.testingProcedure
                          ? data.testingProcedure.replace(/\n/g, '<br>')
                          : ''
                      "
                    ></p>
                  </div>
                  <div class="heng">
                    <b>{{ $t("addMindMap.lab5") }}</b>
                    <el-input
                      v-if="isedit"
                      v-model="data.expectedResult"
                      style="word-break: break-all; margin-right: 5px"
                      autosize
                      type="textarea"
                      :placeholder="$t('addNodeTestPlan.place3')"
                    ></el-input>
                    <p
                      v-else
                      style="word-break: break-all"
                      v-html="
                        data.expectedResult
                          ? data.expectedResult.replace(/\n/g, '<br>')
                          : ''
                      "
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>

    <!-- 关联已有bug -->
    <el-dialog
      :visible.sync="ownVisible"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      @close="closeHandler('ownRuleForm')"
    >
      <div slot="title">
        <span class="title-text">{{ $t("test.nodeTable.bug") }}</span>
      </div>
      <div class="dialog-content">
        <el-form
          ref="ownRuleForm"
          :model="ownRuleForm"
          hide-required-asterisk
          :rules="rule2s"
        >
          <el-form-item prop="list">
            <el-select
              v-model="ownRuleForm.list"
              :placeholder="$t('canvas.testPlan.selectRelationBugMess')"
              popper-class="relationOwnBug"
              size="small"
              style="width: 100%"
              multiple
              collapse-tags
              filterable
              remote
              :remote-method="fuzzyBugNodes"
              :loading="bugLoading"
              @visible-change="changeSelectBugNodes"
            >
              <el-option :value="'add'">
                <template #default>
                  <div
                    style="
                      width: 100%;
                      height: 100%;
                      text-align: center;
                      color: rgb(48, 100, 143);
                    "
                    @click.stop="newBugFile()"
                  >
                    {{ $t("canvas.testPlan.newBug") }} +
                  </div>
                </template>
              </el-option>
              <el-option
                v-for="(item, index) in bugNodesListShow"
                :key="item.nodeKey"
                :label="item.nodeKey + ':' + item.topic"
                :value="item.nodeKey + ':' + item.projectId"
                >{{ item.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="closeAddBugNodes">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="confirmAddBugNodes">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 关联新建bug -->
    <el-dialog
      :visible.sync="is_dialog_visible"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
      @close="closeHandler('ruleForm')"
    >
      <div slot="title">
        <span class="title-text">{{ $t("test.newFile.title") }}</span>
      </div>
      <div class="dialog-content">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          hide-required-asterisk
        >
          <el-form-item prop="name">
            <el-input
              v-model="ruleForm.name"
              size="small"
              :placeholder="$t('test.newFile.place')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="type">
            <el-select
              v-model="ruleForm.type"
              :placeholder="$t('test.newFile.place2')"
              size="small"
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in ruleForm.type_options"
                :key="index"
                :label="item.name"
                :value="item.fileTypeId"
                >{{ item.name }}</el-option
              >
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <el-select
              v-model="ruleForm.test_plan"
              :placeholder="$t('test.newFile.place3')"
              class="createpro-select"
              clearable
              filterable
              size="small"
              style="width: 100%"
              :disabled="true"
            >
              <el-option
                v-for="opt in ruleForm.test_plan_list"
                :key="opt.testPlanId"
                :label="opt.name_display"
                :value="opt.testPlanId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="ruleForm.test_case"
              :placeholder="$t('test.newFile.place4')"
              class="createpro-select"
              size="small"
              filterable
              style="width: 100%"
              :disabled="true"
            >
              <el-option
                v-for="(option, index) in ruleForm.test_case_list"
                :key="index"
                :label="`${option.nodeKey} ${option.topic}`"
                :value="option.nodeKey"
              ></el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="is_dialog_visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="createProgram_comfirm">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="is_review_visible"
      width="30%"
      class="review"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">
        <span class="title-text">{{ $t("addMindMap.tip3") }}</span>
      </div>
      <div class="dialog-content">
        <el-form :model="review_form" label-width="150px">
          <el-form-item :label="$t('addMindMap.tip4')" class="star">
            <el-input
              v-model="review_form.title"
              size="small"
              :placeholder="$t('addMindMap.tip5')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('addMindMap.tip6')" class="star">
            <el-date-picker
              v-model="review_form.time"
              :picker-options="pickerOptions"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH"
              popper-class="tpc"
              type="datetime"
              :placeholder="$t('addMindMap.tip7')"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            v-if="!review_form.chooseuser"
            :label="$t('addMindMap.tip8')"
          >
            <div class="reviewer_list">
              <span
                v-for="(item, index) in review_form.stable"
                :key="index"
                class="reviewer"
                >{{ get_author(item) }}</span
              >
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('addMindMap.tip10')"
            :class="review_form.chooseuser ? 'star' : ''"
          >
            <el-select
              v-model="review_form.person"
              popper-class="user_select"
              multiple
              filterable
            >
              <el-option-group
                v-for="(group, index) in groupandUser"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="user in group.options"
                  :key="user.value"
                  :label="user.label"
                  :value="user.value + index"
                  :disabled="review_form.stable.indexOf(user.value) > -1"
                >
                </el-option>
              </el-option-group>
              <!-- <el-option
                v-for="opt in sort_user_list"
                v-show="!opt.deleted"
                :key="opt.value"
                :label="opt.label"
                :value="opt.value"
                :disabled="review_form.stable.indexOf(opt.value) > -1"
              >
                <personal-avatar
                  class="avatar"
                  :avatar="opt.avatar"
                  :colorid="opt.userAccountId"
                  :nickname="opt.label"
                  :size="20"
                ></personal-avatar>
                <span class="select_item">{{ opt.label }}</span>
              </el-option> -->
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="is_review_visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="inviteReview">
          {{ $t("btn.confirmBtn") }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="reject_review_visible"
      width="50%"
      class="reject"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">
        <span class="title-text" style="font-size: 18px">
          <em style="color: red">*</em>
          {{ $t("addMindMap.tip11") }}
        </span>
      </div>
      <el-table
        :data="review_list"
        tooltip-effect="dark"
        style="width: 100%; margin-bottom: 20px"
        :key="refresh_table"
      >
        <el-table-column width="100">
          <template slot="header" slot-scope="scope">
            <div
              style="display: flex; align-items: center; cursor: pointer"
              @click="click_yes_all()"
            >
              <div
                class="tongguo_check"
                style="background: rgb(218, 255, 221)"
              ></div>
              &nbsp;{{ $t("canvas.reviewRequestForm.yes") }}
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="tongguo_check"
              :style="
                'background :' +
                (item_require_list[scope.row.itemId] == 'yes'
                  ? 'rgb(67, 207, 124)'
                  : 'rgb(218, 255, 221)')
              "
              @click="click_yes(scope.row.itemId)"
            ></div>
          </template>
        </el-table-column>

        <el-table-column width="100">
          <template slot="header" slot-scope="scope">
            <div
              style="display: flex; align-items: center; cursor: pointer"
              @click="click_no_all()"
            >
              <div
                class="butongguo_check"
                style="background: rgb(255, 230, 225)"
              ></div>
              &nbsp;{{ $t("canvas.reviewRequestForm.no") }}
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="butongguo_check"
              :style="
                'background :' +
                (item_require_list[scope.row.itemId] == 'no'
                  ? 'rgb(255, 87, 51)'
                  : 'rgb(255, 230, 225)')
              "
              @click="click_no(scope.row.itemId)"
            ></div>
          </template>
        </el-table-column>

        <el-table-column prop="content" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div class="dialog-content">
        <el-form :model="reject_form">
          <el-form-item>
            <el-input
              v-model="reason_zdh"
              type="textarea"
              :autosize="{ minRows: 4 }"
              :placeholder="$t('addMindMap.tip11')"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="reject_review_visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="reject_review">
          {{ $t("btn.confirmBtn") }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="show_approved_form"
      width="50%"
      class="reject"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">
        <span class="title-text" style="font-size: 18px">
          <em style="color: red">*</em>
          {{ $t("addMindMap.tip18") }}
        </span>
      </div>
      <el-table
        :data="review_list"
        tooltip-effect="dark"
        style="width: 100%; margin-bottom: 20px"
        :key="refresh_table"
      >
        <el-table-column width="100">
          <template slot="header" slot-scope="scope">
            <div
              style="display: flex; align-items: center; cursor: pointer"
              @click="click_yes_all()"
            >
              <div
                class="tongguo_check"
                style="background: rgb(218, 255, 221)"
              ></div>
              &nbsp;{{ $t("canvas.reviewRequestForm.yes") }}
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="tongguo_check"
              :style="
                'background :' +
                (item_require_list[scope.row.itemId] == 'yes'
                  ? 'rgb(67, 207, 124)'
                  : 'rgb(218, 255, 221)')
              "
              @click="click_yes(scope.row.itemId)"
            ></div>
          </template>
        </el-table-column>

        <el-table-column width="100">
          <template slot="header" slot-scope="scope">
            <div
              style="display: flex; align-items: center; cursor: pointer"
              @click="click_no_all()"
            >
              <div
                class="butongguo_check"
                style="background: rgb(255, 230, 225)"
              ></div>
              &nbsp;{{ $t("canvas.reviewRequestForm.no") }}
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="butongguo_check"
              :style="
                'background :' +
                (item_require_list[scope.row.itemId] == 'no'
                  ? 'rgb(255, 87, 51)'
                  : 'rgb(255, 230, 225)')
              "
              @click="click_no(scope.row.itemId)"
            ></div>
          </template>
        </el-table-column>

        <el-table-column prop="content" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div class="dialog-content">
        <el-form :model="approved_form">
          <el-form-item>
            <el-input
              v-model="reason_zdh"
              type="textarea"
              :autosize="{ minRows: 4 }"
              :placeholder="$t('addMindMap.tip18')"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="show_approved_form = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="process_approved_review">
          {{ $t("btn.confirmBtn") }}
        </el-button>
      </div>
    </el-dialog>

    <dfmea-drawer
      v-if="showDFMEA"
      ref="dfmeaDrawer"
      :jm="jm"
      @closeDFMEA="closeDFMEA"
    ></dfmea-drawer>
    <el-dialog
      v-if="review_detail_show"
      v-draggable
      class="review_detail_dialog"
      custom-class="review_detail_dialog_body"
      :visible.sync="review_detail_show"
      :title="$t('canvas.viewResult.res')"
      width="300px"
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="pingshen">
        <div class="btn-green">{{ $t("canvas.viewResult.pass") }}</div>
        <div class="num-green">
          {{
            review_detail_data.approve ? review_detail_data.approve.length : 0
          }}
        </div>
        <div id="pingshen-avatar-green" class="avatar">
          <personal-avatar
            v-for="(item, ind) in review_detail_data.approve"
            :key="ind"
            :size="30"
            :placement="'top'"
            :avatar="user_list[item.person].avatar"
            :nickname="user_list[item.person].nickname"
            :colorid="user_list[item.person].accountId"
            style="margin-left: 5px"
          ></personal-avatar>
        </div>
        <div v-show="calc_dot('pingshen-avatar-green')">
          <el-tooltip
            class="item"
            effect="dark"
            :content="get_calc_green()"
            placement="top-start"
          >
            <i
              class="el-icon-more"
              style="cursor: pointer; font-size: 20px; color: grey"
            ></i>
          </el-tooltip>
        </div>
      </div>
      <div class="pingshen">
        <div class="btn-red">{{ $t("canvas.viewResult.fail") }}</div>
        <div class="num-red">
          {{ review_detail_data.reject ? review_detail_data.reject.length : 0 }}
        </div>

        <div id="pingshen-avatar-red" class="avatar">
          <personal-avatar
            v-for="(item, ind) in review_detail_data.reject"
            :key="ind"
            :size="30"
            :placement="'top'"
            :avatar="user_list[item.person].avatar"
            :nickname="user_list[item.person].nickname"
            :colorid="user_list[item.person].accountId"
            style="margin-left: 5px"
          ></personal-avatar>
        </div>
        <div v-show="calc_dot('pingshen-avatar-red')">
          <el-tooltip
            class="item"
            effect="dark"
            :content="get_calc_red()"
            placement="top-start"
          >
            <i
              class="el-icon-more"
              style="cursor: pointer; font-size: 20px; color: grey"
            ></i>
          </el-tooltip>
        </div>
      </div>
      <div class="pingshen_reason">
        <div v-for="(item, ind) in review_detail_data.reject" :key="ind">
          <strong>{{ user_list[item.person].nickname + ":" }}&nbsp;</strong>
          {{ item.commit }}
        </div>
        <div v-for="(item, ind) in review_detail_data.approve" :key="ind">
          <strong>{{ user_list[item.person].nickname + ":" }}&nbsp;</strong>
          {{ item.commit }}
        </div>
      </div>
      <div
        style="
          font-size: 18px;
          font-weight: bold;
          color: #303133;
          text-align: left;
        "
      >
        {{ $t("canvas.reviewRequestForm.tip8") }}
      </div>
      <div>
        <el-table
          :data="review_detail_data.rea_list"
          style="width: 100%"
          max-height="200"
          :show-header="false"
        >
          <el-table-column type="index" width="25" align="left">
          </el-table-column>
          <el-table-column show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.l }}
            </template>
          </el-table-column>
          <el-table-column width="75">
            <template slot-scope="scope">
              {{ scope.row.num.toFixed(4) * 100 }}%
            </template>
          </el-table-column>
        </el-table>
        <!-- 
        <div
          v-for="(item, ind) in review_detail_data.rea_list"
          :key="ind"
          style="
            text-align: start;
            font-size: 18px;
            display: flex;
            color: #808080;
            justify-content: space-between;
          "
        >
          <span>{{ ind + 1 + "、" + item.l }}</span>
          <span style="width  "> {{ item.num.toFixed(2) }}%</span>
        </div> -->
      </div>
    </el-dialog>
    <el-drawer
      :visible.sync="showDesDrawer"
      :with-header="false"
      :modal="false"
      @closed="closedDes"
    >
      <node-description-row
        :lock="drawerNodeInfo.lock"
        :project-id="drawerNodeInfo.projectId"
        :node-key="drawerNodeInfo.key"
        :description="drawerNodeInfo.description"
        :attachments="drawerNodeInfo.attachments"
        @update_description_content="update_description_content"
      ></node-description-row>
    </el-drawer>

    <div id="seacrh-wrap" class="seacrh-wrap" @dragend="enddrag">
      <transition name="slide-fade">
        <div v-show="showDialog" id="seacrh-dialog" class="seacrh-dialog">
          <input
            id="seacrh-dialog-search_input"
            v-model="search_input"
            style="font-size: 16px; border: none; text-indent: 16px"
            :placeholder="$t('mindmap.search')"
          />
          <span v-show="search_input.length > 0" style="font-size: 16px"
            >{{ search_value_now + 1 }} / {{ search_value_list.length }}</span
          >
          <div style="border-left: 2.5px solid #dddddd">
            <i
              class="iconfont icon-replace"
              style="font-size: 23px"
              @click="replaceStr"
            ></i>
            <i class="el-icon-arrow-down" @click="serach_down"></i>
            <i class="el-icon-arrow-up" @click="serach_up"></i>
            <i class="el-icon-close" @click="serach_clear(false)"></i>
          </div>
        </div>
      </transition>
    </div>
    <!-- 移动文件弹窗 -->
    <source-node-sync-dialog
      ref="movedialog"
      :sourceNode="sourceNode"
      :nodeInfo="node_info"
    ></source-node-sync-dialog>

    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="show_butongguo_all"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i>
      <!-- <span>&nbsp;全部选择了通过，确定继续吗？</span> -->
      <span>&nbsp;{{ $t("canvas.reviewRequestForm.all_yes") }}</span>

      <!-- <span>&nbsp;{{ $t("addMindMap.p1") }}</span> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="show_butongguo_all = false">
          {{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button type="primary" @click="reject_review_butongguo">
          {{ $t("btn.confirmBtn") }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('bin.table.forceDeleteForm.title')"
      :visible.sync="show_tongguo_all"
      width="25%"
      height="8vh"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
    >
      <i
        style="font-size: 20px; color: rgb(255, 195, 0)"
        class="iconfont icon-jingshi-tianchong"
      ></i>
      <!-- <span>&nbsp;全部选择了不通过，确定继续吗？</span> -->
      <span>&nbsp;{{ $t("canvas.reviewRequestForm.all_no") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show_tongguo_all = false">
          {{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button type="primary" @click="process_approved_review_tongguo">
          {{ $t("btn.confirmBtn") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      width="25%"
      :title="$t('mindmap.replacetext')"
      :visible.sync="replaceDialogVisible"
    >
      <el-input
        v-model="relpaceValue"
        type="textarea"
        :placeholder="$t('AI.tip4')"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="replaceDialogVisible = false">
          {{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button type="primary" @click="replaceConfirm">
          {{ $t("btn.confirmBtn") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import NodeDescriptionRow from "@/components/mindmap/node_detail/NodeDescriptionRow.vue";
import {
  get_file_review,
  get_review_config,
  processReview,
  get_file_process,
  get_user_review,
} from "@/network/review/index.js";
import vmson from "@/utils/vmson";
import "@/assets/css/jmnodeStyle.css";
import {
  add_node,
  delete_node,
  nodeAutoExtend,
  replaceTopic,
} from "@/network/mindmap/index.js";
import {
  get_test_nodes,
  getBugNodes,
  postTestcaseBugRelations,
} from "@/network/test/index.js";
import {
  node_location_change,
  get_node_use_node_key,
  only_set_property,
  get_node_testPlans,
  get_allProjectMember,
  copy_node,
  copyMultiSelectNodeList,
  get_node_nodeOriginInfo,
} from "@/network/node/index.js";
import {
  ignoreNodeCoverage,
  getNodeCoverage,
  deleteNodeCoverage,
} from "@/network/coverage/index.js";
import { get_group_user_list } from "@/network/addGroup/index.js";
import { set_to_template } from "@/network/template/index.js";
import { getNodeReviewInfo } from "@/network/collaboration/index.js";
import { Message } from "element-ui";
import { export_mindMap } from "@/network/fileOperation/index.js";
import { get_filetype, createNewFile } from "@/network/home/index.js";
import { getFile, getReleaseElements } from "@/network/home/index.js";
import { mapGetters } from "vuex";
import { get_filetype_List } from "@/network/fileType/index.js";
import { inviteReview } from "@/network/review/index.js";
import { aiAutoExtendPayFeatureStatus } from "@/network/ai/index.js";
import { effective } from "@/network/dfmea";
import { limits_of_authority_disable } from "@/utils/limits";
import workflowAndDialog from "./workflowAndDialog";
import Driver from "@/plugins/driver.js";
import ViewWorkflow from "@/components/mindmap/ViewWorkflow";
import PersonalAvatar from "@/components/personal";
import NodePopover from "./NodePopover.vue";
import RelationDialog from "./RelationDialog.vue";
import RightClick from "./RightClick.vue";
import dfmeaDrawer from "./dfmeaDrawer.vue";
import Stomp from "stompjs";
import draggable from "vuedraggable";
import SockJS from "sockjs-client";
import sourceNodeSyncDialog from "@/components/sourceNodeSyncDialog";
// 引入引导框样式
import "@/plugins/driver.css";

export default {
  inject: ["reload"],
  provide() {
    return {
      reloadPopover: this.reloadPopover,
    };
  },
  name: "MindMap",
  components: {
    NodePopover,
    draggable,
    RelationDialog,
    RightClick,
    ViewWorkflow,
    PersonalAvatar,
    workflowAndDialog,
    dfmeaDrawer,
    NodeDescriptionRow,
    sourceNodeSyncDialog,
  },
  props: {
    fileData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // 替换文本相关
      replaceDialogVisible: false,
      relpaceValue: "",
      show_butongguo_all: false,
      show_tongguo_all: false,
      refresh_table: 0,
      // 批量复用相关
      sourceNode: {},
      node_info: {},
      //
      showDesDrawer: false,
      drawerNodeInfo: {},
      ctrl_v_loading: "",
      ctrl_c_node: "",
      ctrl_x_node: "",
      ctrl_x_node_obj: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      groupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      is_process_review: false,
      reject_form: {},
      approved_form: {},
      show_approved_form: false,
      caseword: "",
      reject_review_visible: false,
      caseone: false,
      jsmind_show: false,
      andDialog_isShow: false,
      rules: {
        name: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "change",
          },
        ],
      },
      rule2s: {
        list: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "change",
          },
        ],
      },
      is_dialog_visible: false,
      // 创建文件弹窗
      ruleForm: {
        name: "",
        test_plan: "",
        test_case: "",
        type: "",
        type_options: [],
        test_plan_list: [],
        test_case_list: [],
      },
      testdata: [],
      left_btn_visible: false,
      left_dialog_visible: false,
      mouse_h: 0,
      mouse_w: 0,
      target: "",
      loading_e: false,
      aiLoading: false,
      last_node: undefined,
      rootVisible: false,
      nodeVisible: false,
      childVisible: false,
      status: [],
      current_node_key: "", //需要操作的节点key
      current_node_id: "", //当前需要操作的节点
      current_node_key_List: [], //需要操作的节点keyList 用于批量操作
      current_node_id_List: [], //当前需要操作的节点List 用于批量操作
      current_node_List: [], //当前需要操作的节点List 用于批量操作
      domupdateKey: 0,
      cur_ppid: "",
      cur_node_parentId: "",
      cur_pid: "",
      pofDomUpdater: true,
      isZoomIn: false,
      isZoomOut: false,
      andCondition_text: [],
      isfvalue: true, // 是否开启浮窗功能
      originfvalue: true, // 记录之前的浮窗状态
      // 评审人角色
      isReviewer: false,
      // 快捷键列表
      keys: [
        {
          name: "Enter",
          note: this.$t("addMindMap.info1"),
        },
        {
          name: "Tab",
          note: this.$t("addMindMap.info2"),
        },
        {
          name: "F2",
          note: this.$t("addMindMap.info3"),
        },
        {
          name: "F1",
          note: this.$t("addMindMap.info4"),
        },
      ],
      isEnterPopover: false,
      curNode: "",
      lastState: {},
      allowedBatchExec: false,
      multi_reviewProcessId: "",
      has_multi_pingshen: false,
      multi_pingshen_data: null,
      /*控制定时器*/
      isouts: null,
      isleave: null,
      // 根据permission判定对思维导图的评审权限
      canReview: false,
      // 是否是思维导图拥有者
      isOwner: true,
      // 初始化思维导图数据
      mind: {},
      filename: "",
      // 保存状态
      savingState: this.$t("addMindMap.message1"),
      // 快捷键页
      help_key: false,
      // 浮窗属性
      properties: [
        {
          propertyName: "Priority",
          propertyVal: "",
          isPrimary: true,
        },
      ],
      // 当前浮窗对应节点id
      activeNodeId: "",
      // 新增浮窗属性名
      addName: "",
      // 新增浮窗属性必要性
      addPrimary: true,
      // 操作状态数组
      actionsState: [],
      // 后退和前进操作列表
      backList: [],
      goList: [],
      options: {
        text_align: "center",
        container: "topbar_can",
        editable: true,
        theme: "primary",
        support_html: true,
        view: {
          engine: "svg",
          draggable: true,
        },
        shortcut: {
          enable: false,
          handles: {},
          mapping: {
            editnode: 113,
            delnode: 46,
            toggle: 112,
            left: 37,
            up: 38,
            right: 39,
            down: 40, // <Down>
          },
        },
      },
      popover_content: {
        content: [],
      },
      popover_visible: false,
      relation_dialog: false,
      isEnterNode: false,
      count_mouseover_time: 0,
      prevent_multi_req: null,
      reason_zdh: "",
      cur_node_key: null,
      //传入浮窗字段
      nodeUuid: null,
      layer: null,
      layerNumber: null,
      workflow_dialog_visible: false,
      showDialog: false,
      search_input: "",
      search_value_list: [],
      search_value_now: -1,
      fileTypeId: null,
      nodeclass: "",
      file_test: "",
      poslist: {},
      zoom_: 1,
      isedit: false,
      se_test_node: "",
      is_review_visible: false,
      sort_user_list: [],
      review_data: [],
      reject_data: {},
      review_list: [],
      review_num_all: 0,
      review_num_process: 0,
      review_form: {
        time: this.get_time,
        person: [],
        title: "",
        chooseuser: true,
        stable: [],
      },
      card_isShow: false, // 调控卡片是否显示
      ignoreCoverageList: [],
      // 选中节点是否被锁定
      lock: false,
      // 是否展示dfmea
      showDFMEA: false,
      mouse_clientX: "",
      mouse_clientY: "",
      div_scrollWidth: "",
      div_scrollHeight: "",
      timer_scroll: "",
      node_add_button_visible: false,
      mouse_out_timer: "",
      mouse_out_timer_hide_popover: "",
      jm: null,
      user_reviewed: [],
      user_can_review: [],
      is_show_no_review_nodes: false,
      review_detail_show: false,
      review_detail_data: {},
      ownVisible: false,
      ownRuleForm: {
        list: [],
      },
      ownRules: [],
      bugNodesList: [],
      bugNodesListShow: [],
      bugNodesFuzzyKey: "",
      bugLoading: false,
      currentRowBugList: [],
      item_require_list: {},
      item_require_list_cjeckout: {},
      hasAattachmentsList: [],
    };
  },
  computed: {
    ...mapGetters([
      "file_type_id",
      "exist_mindmap",
      "token",
      "userAccountId",
      "user_list",
      "user_group_list",
      "jobAuthorities",
      "project_user_list",
      "fileType",
    ]),
    fileId() {
      return document
        .getElementById("global_variable_fileId")
        .innerText.replace(/^\s*|\s*$/g, "");
    },
    get_time() {
      let a = new Date(new Date().getTime() + 86400000 * 5);
      let date = a.getDate() > 9 ? a.getDate() : "0" + a.getDate();
      let month = a.getMonth() + 1;
      return (
        a.getFullYear() +
        "-" +
        (month > 9 ? month : "0" + month) +
        "-" +
        date +
        " 12"
      );
    },
    fvalue() {
      return this.isfvalue;
    },
  },
  watch: {
    search_input() {
      this.highlightText();
    },
    $route() {
      this.reload();
    },
    current_node_key_List() {},
    exist_mindmap: {
      handler(newVal) {
        this.hasAattachmentsList = [];
        newVal.data.forEach((item) => {
          if (
            (item.attachments && item.attachments.length) ||
            item.description
          ) {
            this.hasAattachmentsList.push(item.id);
          }
        });
        this.addFileIcon();
      },
      immediate: true,
    },
  },
  created() {
    this.nodeclass = this.$route.query.node_id;
    this.initMap();
    this.$vmson.$on("layerNumber", (val) => {
      this.layerNumber = val;
    });
    if (this.project_user_list) {
      let my = {};
      let li = [];
      Object.values(this.project_user_list).forEach((ele) => {
        let obj = {
          label: ele.nickname,
          value: ele.accountId,
          userAccountId: ele.accountId,
          avatar: ele.avatar,
          deleted: ele.deleted,
        };
        ele.accountId == this.$store.getters.userAccountId
          ? (my = obj)
          : li.push(obj);
      });
      this.sort_user_list = [];
      this.sort_user_list.push(my);
      this.sort_user_list.push(...li);
      const group = Object.values(this.user_group_list);
      this.sort_user_list.map((item, index) => {
        this.groupandUser[0].options.push({
          value: item.value,
          label: item.label,
          avatar: item.avatar,
          colorid: item.userAccountId,
          nickname: item.label,
        });
      });
      group.map((item, index) => {
        this.groupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
      });
    }
  },
  mounted() {
    // 获取当前项目下已有的可关联的bug
    this.getBugNodes();
    let _this = this;
    this.initWebsocket();
    //删除多余画布
    let a = document.getElementsByClassName("jsmind-inner");
    setTimeout(() => {
      if (a[0].childNodes[2]) {
        a[0].childNodes[2].classList.add("under_canvas");
      }
      this.$nextTick(() => {
        if (!this.nodeclass) {
          const div = document.getElementById("js_m_box");
          div.scrollTop = div.scrollHeight / 4;
        }
      });
    }, 500);
    document.addEventListener("keydown", this.handle_search_enter_key);
    vmson.$on("many_pingshen", (data) => {
      let choose_key = [];

      for (let i of data.data) {
        choose_key.push(i.data.key);
      }

      const commonElements = choose_key.filter((element) =>
        this.user_can_review.includes(element)
      );

      if (commonElements.length == 0) {
        this.$message({
          message: this.$t("canvas.reviewRequestForm.tip11"),
          type: "warning",
        });
        return;
      }

      this.multi_pingshen_data = commonElements;
      this.has_multi_pingshen = true;

      if (data.type == "pass") {
        this.item_require_list = {};
        this.reason_zdh = "";
        this.show_approved_form = true;
      }

      if (data.type == "fail") {
        this.item_require_list = {};
        this.reason_zdh = "";
        this.reject_review_visible = true;
      }
    });
    vmson.$on("change_topbar", (val) => {
      if (!val) {
        document.getElementById("js_m_box").style["height"] = "90vh";
        document.getElementById("js_m_box").style["margin-top"] = "0";
      } else {
        document.getElementById("js_m_box").style["height"] =
          "calc(90vh - 98px)";
        document.getElementById("js_m_box").style["margin-top"] = "98px";
      }
    });
    vmson.$on("export_img", async () => {
      await this.jm.screenshot.shootDownload();
    });
    vmson.$on("shootAsDataURL", (callback) => {
      this.jm.screenshot.shootAsDataURL(callback);
    });
    vmson.$on("getjmData", (callback) => {
      callback(this.$store.getters.exist_mindmap);
    });
    vmson.$on("showRelationDialog", (val) => {
      this.cur_node_key = val;
      this.relation_dialog = true;
      this.popover_visible = false;
    });
    vmson.$on("showReviewDialog", (val) => {
      let flag = false;
      if (_this.current_node_key_List.length > 0) {
        let nu = 0;
        for (let item of _this.review_data) {
          if (_this.current_node_key_List.indexOf(item.nodeKey) > -1) {
            if (item.status == "APPROVED" || item.status == "REVIEWING") {
              nu++;
            }
          }
        }
        if (nu == _this.current_node_key_List.length) {
          _this.$message.warning(this.$t("addMindMap.tip14"));
          return;
        }
        flag = true;
      }
      if (_this.current_node_key.length > 0) {
        for (let item of _this.review_data) {
          if (item.nodeKey == _this.current_node_key) {
            if (item.status == "APPROVED") {
              _this.$message.warning(this.$t("addMindMap.tip15"));
              return;
            } else if (item.status == "REVIEWING") {
              _this.$message.warning(this.$t("addMindMap.tip16"));
              return;
            }
          }
        }
        flag = true;
      }
      if (!flag) {
        _this.$message.warning(this.$t("addMindMap.tip17"));
      } else {
        _this.review_form = {
          time: _this.get_time,
          person: [],
          title: _this.filename + _this.get_title(),
          chooseuser: val.length > 0 ? false : true,
          stable: val,
        };
        _this.is_review_visible = true;
      }
    });
    vmson.$on("showReviewNode", (res) => {
      this.review_outstanding(res);
    });
    vmson.$on("closeReview", () => {
      _this.review_detail_show = false;
      _this.is_process_review = false;
    });
    vmson.$on("processReview", () => {
      console.log("zyz_pro_zzz");

      _this.review_detail_show = false;
      _this.process_review();

      _this.is_process_review = true;
      _this.is_show_no_review_nodes = false;
    });
    vmson.$on("clearCtrlX", () => {
      _this.clearCtrlXFn();
    });
    vmson.$on("select_list_outstanding", (val) => {
      this.clear_all_checkced_style();
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        if (jmnode.getAttribute("nodeid")) {
          let node = this.jm.get_node(jmnode.getAttribute("nodeid"));
          if (val.indexOf(node.id) > -1) {
            this.current_node_id_List.push(node.id);
            this.current_node_key_List.push(node.data.key);
            this.current_node_List.push(node);
            this.jm.get_node(node.id)._data.view.element.id = "custom_style";
          }
        }
      });
    });
    this.getIgnoreNode();
    this.initShowDFMEA();
    this.$refs.radio &&
      this.$refs.radio.$children.forEach((item) => {
        item.$refs.radio.removeAttribute("  ");
      });
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handle_search_enter_key);

    Object.getPrototypeOf(this.$refs.jsMind.jm).update_node =
      this.originalUpdateNode;
    if (this.notify) {
      this.notify.close();
    }
    document.body.onmousemove = null;
    document.body.onmouseup = null;
    document.onkeydown = null;
    this.closeWebsocket();
    // 取消eventBus的事件绑定
    this.$vmson.$off("layerNumber");
    this.$vmson.$off("change_topbar");
    this.$vmson.$off("export_img");
    this.$vmson.$off("shootAsDataURL");
    this.$vmson.$off("getjmData");
    this.$vmson.$off("showRelationDialog");
    this.$vmson.$off("showReviewDialog");
    this.$vmson.$off("showReviewNode");
    this.$vmson.$off("closeReview");
    this.$vmson.$off("processReview");
    this.$vmson.$off("clearCtrlX");
    this.$vmson.$off("set_fvalue");
    this.$vmson.$off("save");
    this.$vmson.$off("back");
    this.$vmson.$off("et_clear");

    vmson.$off("many_pingshen");
  },
  methods: {
    replaceConfirm() {
      const modifyItemList = [];
      this.exist_mindmap.data.forEach((node) => {
        if (node.topic.indexOf(this.search_input) !== -1) {
          const obj = {
            nodeKey: node.key,
            topic: node.topic.replaceAll(this.search_input, this.relpaceValue),
          };
          modifyItemList.push(obj);
        }
      });
      const params = {
        projectId: this.get_pid(),
        data: {
          modifyItemList,
        },
      };
      replaceTopic(params).then((res) => {
        this.initMap("getNewData");
        this.relpaceValue = "";
        this.replaceDialogVisible = false;
      });
    },
    replaceStr() {
      if (!this.search_input) {
        this.$message({
          type: "warning",
          message: this.$t("mindmap.Empty"),
        });
        return;
      } else if (!this.search_value_list.length) {
        this.$message({
          type: "warning",
          message: this.$t("mindmap.noFit"),
        });
        return;
      }
      this.relpaceValue = "";
      this.replaceDialogVisible = true;
    },
    addFileIcon() {
      this.hasAattachmentsList.forEach((item) => {
        var child = document.createElement("i");
        child.classList.add("iconfont", "icon-fujian");
        child.setAttribute("id", item);
        if (
          !document.querySelector(`jmnode[nodeid="${item}"]`).children.length
        ) {
          document.querySelector(`jmnode[nodeid="${item}"]`).appendChild(child);
          child.addEventListener("click", this.clickHasFileIcon);
        }
        document
          .querySelector(`jmnode[nodeid="${item}"]`)
          .setAttribute("hasFile", "true");
      });
    },
    clickHasFileIcon(event) {
      const node = this.jm.get_node(event.target.id);
      this.showDes(node.data);
    },
    closedDes() {
      this.refreshMindMap();
    },
    handle_search_enter_key(e) {
      if (this.showDialog && e.key == "Enter") {
        this.serach_down();
        return;
      }
    },
    // 同步节点
    asyncNodes(noreused) {
      if (this.current_node_List.length || this.current_node_key) {
        const noreusedKey = noreused.map((item) => item.key);
        if (noreusedKey.indexOf(this.current_node_key) !== -1) {
          this.$message.warning(this.$t("node.message.message10"));
          return;
        }
        let hasNouse = false;
        this.current_node_List.forEach((item) => {
          if (noreusedKey.indexOf(item.data.key) !== -1) {
            hasNouse = true;
          }
        });
        if (hasNouse) {
          this.$message.warning(this.$t("node.message.message10"));
          return;
        }
        get_node_nodeOriginInfo(
          this.get_pid(),
          this.current_node_List.length
            ? this.current_node_List[0].data.key
            : this.current_node_key
        ).then((res) => {
          if (res.sourceNode) {
            this.sourceNode = res.sourceNode;
            this.node_info = this.current_node_List.length
              ? this.current_node_List[0].data
              : this.jm.get_node(this.current_node_id).data;
            this.$nextTick(() => {
              this.$refs.movedialog.open(
                this.current_node_List.length
                  ? this.current_node_key_List
                  : [this.current_node_key]
              );
            });
          }
        });
      } else {
        this.$message.warning(this.$t("canvas.AI.tip5"));
      }
    },
    isElementInNonScrollableArea(el) {
      // 在可视区域内就不出发移动节点
      let nonScrollablePercentage = 60;
      let viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      let viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;

      let nonScrollableHeight =
        viewportHeight * (nonScrollablePercentage / 100);
      let nonScrollableWidth = viewportWidth * (nonScrollablePercentage / 100);

      let nonScrollableTop = (viewportHeight - nonScrollableHeight) / 2;
      let nonScrollableLeft = (viewportWidth - nonScrollableWidth) / 2;

      let rect = el.getBoundingClientRect();

      return (
        rect.top >= nonScrollableTop &&
        rect.left >= nonScrollableLeft &&
        rect.bottom <= nonScrollableTop + nonScrollableHeight &&
        rect.right <= nonScrollableLeft + nonScrollableWidth
      );
    },
    showDialogFn() {
      this.showDialog = !this.showDialog;
      this.serach_clear(this.showDialog);
      if (this.showDialog) {
        setTimeout(() => {
          document.getElementById("seacrh-dialog-search_input").focus();
        }, 100);
      }
      // 确定浮窗出现的位置
      let btn = document
        .getElementById("seacrh-button")
        .getBoundingClientRect();

      if (btn.left < 400 / (50 / btn.width)) {
        document.getElementById("seacrh-dialog").style.left = btn.width + "px";
      } else {
        document.getElementById("seacrh-dialog").style.left = "-450px";
      }
      document.getElementById("seacrh-dialog").style.top = "0";
    },
    enddrag(a, b) {
      document.getElementById("seacrh-wrap").style.top = a.pageY - 25 + "px";
      document.getElementById("seacrh-wrap").style.left = a.pageX - 25 + "px";
      document.getElementById("seacrh-wrap").style.bottom = a.pageY + 25 + "px";
      document.getElementById("seacrh-wrap").style.right = a.pageX + 25 + "px";
    },
    serach_clear(show_dig) {
      // 关闭搜索框
      this.showDialog = show_dig;

      this.search_input = "";
      let jmnodes = document.querySelectorAll("jmnode");
      this.search_value_list = [];
      this.search_value_now = -1;
      jmnodes.forEach((jmnode) => {
        let array = [...jmnode.classList];
        array = array.filter(
          (item) =>
            item !== "serach_mindmap_node_highlight" &&
            item !== "serach_mindmap_node_highlight_selected"
        );
        jmnode.className = array.join(" ");
      });
    },
    serach_down() {
      // 搜索框  下一个

      if (this.search_value_list.length == 0) {
        // 没有搜索到内容
        return;
      }
      if (this.search_value_now > -1) {
        // 已经有搜索选中的节点的情况
        let array = [
          ...this.search_value_list[this.search_value_now].classList,
        ];
        array = array.filter(
          (item) => item !== "serach_mindmap_node_highlight_selected"
        );
        this.search_value_list[this.search_value_now].className =
          array.join(" ");
        this.search_value_now++;
        this.search_value_now == this.search_value_list.length
          ? (this.search_value_now = 0)
          : "";
        array = [...this.search_value_list[this.search_value_now].classList];
        array.push("serach_mindmap_node_highlight_selected");

        this.search_value_list[this.search_value_now].className =
          array.join(" ");
      } else {
        // 没有搜索选中的节点的情况

        this.search_value_now = 0;
        let array = [
          ...this.search_value_list[this.search_value_now].classList,
        ];
        array.push("serach_mindmap_node_highlight_selected");

        this.search_value_list[this.search_value_now].className =
          array.join(" ");
      }
      if (
        !this.isElementInNonScrollableArea(
          this.search_value_list[this.search_value_now]
        )
      ) {
        this.search_value_list[this.search_value_now].scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      }
    },

    serach_up() {
      // 搜索框  上一个

      if (this.search_value_list.length == 0) {
        return;
      }
      if (this.search_value_now > -1) {
        let array = [
          ...this.search_value_list[this.search_value_now].classList,
        ];
        array = array.filter(
          (item) => item !== "serach_mindmap_node_highlight_selected"
        );
        this.search_value_list[this.search_value_now].className =
          array.join(" ");
        this.search_value_now--;
        this.search_value_now == -1
          ? (this.search_value_now = this.search_value_list.length - 1)
          : "";
        array = [...this.search_value_list[this.search_value_now].classList];
        array.push("serach_mindmap_node_highlight_selected");

        this.search_value_list[this.search_value_now].className =
          array.join(" ");
      } else {
        this.search_value_now = this.search_value_list.length - 1;

        let array = [
          ...this.search_value_list[this.search_value_now].classList,
        ];
        array.push("serach_mindmap_node_highlight_selected");

        this.search_value_list[this.search_value_now].className =
          array.join(" ");
      }
      if (
        !this.isElementInNonScrollableArea(
          this.search_value_list[this.search_value_now]
        )
      ) {
        this.search_value_list[this.search_value_now].scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      }
    },
    highlightText() {
      // 高亮搜索到的节点
      let jmnodes = document.querySelectorAll("jmnode");
      this.search_value_list = [];
      this.search_value_now = -1;
      jmnodes.forEach((jmnode) => {
        let array = [...jmnode.classList];
        array = array.filter(
          (item) =>
            item !== "serach_mindmap_node_highlight" &&
            item !== "serach_mindmap_node_highlight_selected"
        );
        if (
          jmnode.innerText.includes(this.search_input) &&
          this.search_input.length > 0
        ) {
          array.push("serach_mindmap_node_highlight");
          this.search_value_list.push(jmnode);
        }
        jmnode.className = array.join(" ");
      });
    },
    limits_of_authority_disable,
    // 展示描述
    showDes(nodeInfo) {
      this.showDesDrawer = true;
      this.drawerNodeInfo = nodeInfo;
    },
    update_description_content(description) {
      this.drawerNodeInfo.description = description;
    },
    closeDFMEA() {
      this.showDFMEA = false;
    },
    // 刷新画布数据封装
    refreshMindMap() {
      const get_file = this.getFile();
      const get_release = this.getReleaseElements();
      let res = {};
      Promise.all([get_file, get_release]).then((result) => {
        result[0].data.forEach((item) => {
          result[1].forEach((i) => {
            if (i.nodeKey === item.key) {
              if (i.sprintId) {
                item["sprintId"] = i.sprintId;
              }
              i.baselineItemDtoList &&
                i.baselineItemDtoList.forEach((baseline) => {
                  if (baseline.lock && baseline.status === "ALREADY_STARTED") {
                    item["lock"] = baseline.lock;
                  }
                });
            }
          });
        });
        res = result[0];
        this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
        this.jm.show(res);
        vmson.$emit("jmshow", res);
      });
    },
    // 递归遍历判断有无节点是被锁定状态
    findLock(arr, lockList) {
      arr.forEach((item) => {
        if (item.data.lock) {
          lockList.push(item.data.key);
        }
        if (item.children.length) {
          this.findLock(item.children, lockList);
        }
      });
    },
    findBaseLine(arr, baseLineList, matchData) {
      arr.forEach((item) => {
        matchData.forEach((match) => {
          if (match.nodeKey === item.data.key) {
            match.baselineItemDtoList &&
              match.baselineItemDtoList.forEach((baseLine) => {
                if (baseLine.status === "ALREADY_STARTED") {
                  if (baseLineList.indexOf(baseLine.name) == -1) {
                    baseLineList.push(baseLine.name);
                  }
                }
              });
          }
        });
        if (item.children.length) {
          this.findBaseLine(item.children, baseLineList, matchData);
        }
      });
    },
    clear_show_no_review_nodes() {
      this.is_show_no_review_nodes = false;
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        let array = [...jmnode.classList];
        if (array.includes(`show_no_review`)) {
          let element = `show_no_review`;
          let index = array.indexOf(element);
          if (index !== -1) {
            array.splice(index, 1);
          }
          jmnode.classList = array;
        }
      });
    },

    // 未评审节点高亮
    show_no_review_nodes() {
      this.clear_show_no_review_nodes();
      this.is_show_no_review_nodes = true;
      let result = this.user_can_review.filter(
        (item) => !this.user_reviewed.some((aItem) => aItem.nodeKey === item)
      );
      if (result.length > 0) {
        let jmnodes = document.querySelectorAll("jmnode");
        jmnodes.forEach((jmnode) => {
          if (jmnode.getAttribute("nodeid")) {
            let node = this.jm.get_node(jmnode.getAttribute("nodeid"));
            if (node && node.data && result.includes(node.data.key)) {
              jmnode.classList.add(`show_no_review`);
            }
          }
        });
      }
    },
    // 复制测试计划
    copy_step(index) {
      this.testdata.push({
        index: 0,
        expectedResult: this.testdata[index].expectedResult,
        preConditions: this.testdata[index].preConditions,
        testingProcedure: this.testdata[index].testingProcedure,
      });
      let ind = 0;
      this.testdata.forEach((item) => {
        item.index = ind;
        ind++;
      });
      // 滚动到新添加的地方
      this.$nextTick(() => {
        let isError = document.getElementsByClassName("hang");
        isError[isError.length - 1].scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      });
    },
    //测试计划拖拽结束
    on_drag_end() {
      let ind = 0;
      this.testdata.forEach((item) => {
        item.index = ind;
        ind++;
      });
    },
    // 判断路由跳转是否展示dfmea
    initShowDFMEA() {
      if (this.$route.query && this.$route.query.dfmea) {
        const params = {
          fileId: this.$store.getters.file_id,
        };
        effective(params).then((res) => {
          if (
            res.freeFileId !== this.$store.getters.file_id &&
            !res.effective
          ) {
            this.$message({
              duration: 0,
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: `<span>${this.$t("certificate.Please")}<a href="${
                window.location.origin
              }/profile/certificate_management?target=DFMEA" target="_blank" style="color: blue">${this.$t(
                "certificate.CertificateManagement"
              )}</a>${this.$t("certificate.InstallComponents")}</span>`,
            });
          } else {
            this.showDFMEA = true;
          }
        });
      }
    },
    // 添加透明样式
    addOpacity(obj) {
      if (obj.children.length) {
        obj.children.forEach((child) => {
          this.addOpacity(child);
        });
      }
      obj._data.view.element.classList.add("opacity_style");
      obj._data.view.expander.classList.add("opacity_style");
    },
    on_mouse_up() {
      if (document.getElementById("selectDiv")) {
        clearInterval(this.timer_scroll);
        document
          .getElementById("selectDiv")
          .parentNode.removeChild(document.getElementById("selectDiv"));
      }
    },
    // 点击事件
    async onkeydown(e) {
      if (e.target.tagName == "TEXTAREA" || e.target.tagName == "INPUT") {
        return;
      }
      const _this = this;
      // 哪些情况不考虑快捷键
      // reject_review_visible评审拒绝弹窗
      if (
        this.reject_review_visible ||
        this.$refs["rightWindow"].dialogVisible ||
        this.$refs["rightWindow"].otherDialogVisible ||
        this.$refs["rightWindow"].relation_visible ||
        this.$refs["rightWindow"].moveNodesDialogVisible
      ) {
        return;
      }
      // 监听ctrl + a 事件
      if (
        (event.ctrlKey || event.metaKey) &&
        window.event.keyCode == 65 &&
        !_this.jm.view.get_editing_node() &&
        !this.showDFMEA &&
        !this.isedit
      ) {
        if (_this.current_node_id) {
          if (_this.current_node_List.length > 0) {
            let s = new Set();
            for (let i of _this.current_node_List) {
              let l = [i];
              while (l.length > 0) {
                let nn = l.shift();

                s.add(nn.id);
                l = [...l, ...nn.children];
              }
            }
            s = [...s];

            let jmnodes = document.querySelectorAll("jmnode");
            _this.last_node = {};
            _this.current_node_id_List = [];
            _this.current_node_key_List = [];
            _this.current_node_List = [];
            jmnodes.forEach((jmnode) => {
              if (jmnode.getAttribute("nodeid")) {
                let node = _this.jm.get_node(jmnode.getAttribute("nodeid"));
                if (s.indexOf(node.id) > -1) {
                  _this.current_node_id_List.push(node.id);
                  _this.current_node_key_List.push(node.data.key);
                  _this.current_node_List.push(node);
                  _this.jm.get_node(node.id)._data.view.element.id =
                    "custom_style";
                }
              }
            });
          } else {
            let node = _this.jm.get_node(_this.current_node_id);
            let zhan = [];
            zhan.push(node);
            let i = 0;
            while (i < zhan.length) {
              zhan = [...zhan, ...(zhan[i].children ? zhan[i].children : [])];
              i++;
            }
            _this.last_node = {};
            _this.current_node_id_List = [];
            _this.current_node_key_List = [];
            _this.current_node_List = [];
            _this.current_node_id = "";
            _this.current_node_key = "";
            zhan.forEach((jmnode) => {
              let node = _this.jm.get_node(jmnode.id);

              _this.current_node_id_List.push(node.id);
              _this.current_node_key_List.push(node.data.key);
              _this.current_node_List.push(node);
              _this.jm.get_node(node.id)._data.view.element.id = "custom_style";
            });
          }
        } else {
          let jmnodes = document.querySelectorAll("jmnode");
          _this.last_node = {};
          _this.current_node_id_List = [];
          _this.current_node_key_List = [];
          _this.current_node_List = [];
          jmnodes.forEach((jmnode) => {
            if (jmnode.getAttribute("nodeid")) {
              let node = _this.jm.get_node(jmnode.getAttribute("nodeid"));
              _this.current_node_id_List.push(node.id);
              _this.current_node_key_List.push(node.data.key);
              _this.current_node_List.push(node);
              _this.jm.get_node(node.id)._data.view.element.id = "custom_style";
            }
          });
        }

        //禁用原生ctrl + a 功能
        e.preventDefault();
        return false;
      }
      // 监听ctrl + c 事件
      if (
        (event.ctrlKey || event.metaKey) &&
        window.event.keyCode == 67 &&
        !_this.jm.view.get_editing_node() &&
        !_this.is_review_visible &&
        !_this.isedit
      ) {
        if (
          _this.current_node_key_List &&
          _this.current_node_key_List.length > 1
        ) {
          _this.ctrl_c_node_list = _this.current_node_key_List;
          _this.clearCtrlXFn();
          _this.$message.success(_this.$t("canvas.messages.Copied"));
        } else if (_this.jm.get_selected_node()) {
          _this.ctrl_c_node = _this.jm.get_selected_node().data.key;
          _this.clearCtrlXFn();
          _this.$message.success(_this.$t("canvas.messages.Copied"));
        }
        //禁用原生ctrl + c 功能
        return false;
      }
      // 监听ctrl + x 事件
      if (
        (event.ctrlKey || event.metaKey) &&
        window.event.keyCode == 88 &&
        !_this.jm.view.get_editing_node() &&
        !_this.isedit
      ) {
        if (this.showDFMEA) {
          this.$message({
            message: this.$t("DFMEA.ms4"),
            type: "warning",
          });
          return;
        }
        if (
          _this.current_node_key_List &&
          _this.current_node_key_List.length > 1
        ) {
          _this.$message.warning(_this.$t("canvas.messages.CutNotAvailable"));
          return false;
        }
        if (_this.jm.get_selected_node()) {
          if (_this.jm.get_selected_node().isroot) {
            _this.$message.warning(
              _this.$t("canvas.messages.RootNodeNotSupportCutting")
            );
            return;
          }
          _this.ctrl_c_node = "";
          _this.clearCtrlXFn();
          _this.ctrl_x_node = _this.jm.get_selected_node().data.key;
          _this.ctrl_x_node_obj = _this.jm.get_selected_node();
          _this.current_node_id = "";
          _this.jm.select_clear();
          this.addOpacity(_this.ctrl_x_node_obj);
          _this.$message.success(_this.$t("canvas.messages.Cut"));
        }
        //禁用原生ctrl + x 功能
        return false;
      }
      // 监听esc 事件
      if (window.event.keyCode == 27 && !_this.jm.view.get_editing_node()) {
        _this.clearCtrlXFn();
        return false;
      }
      // 监听ctrl + v 事件
      if (
        (event.ctrlKey || event.metaKey) &&
        window.event.keyCode == 86 &&
        !_this.jm.view.get_editing_node() &&
        _this.jm.get_selected_node() &&
        !_this.isedit
      ) {
        if (_this.jm.get_selected_node() && _this.ctrl_c_node) {
          _this.ctrl_v_loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          let params = {
            fileKey: _this.$route.params.file_key,
            currentNodeKey: _this.ctrl_c_node,
            newParentNodeKey: _this.jm.get_selected_node().data.key,
          };
          copy_node(_this.get_pid(), params).then((r) => {
            const get_file = getFile(
              _this.get_pid(),
              _this.$route.params.file_key
            );
            const get_release = _this.getReleaseElements();
            let res = {};
            Promise.all([get_file, get_release]).then((result) => {
              result[0].data.forEach((item) => {
                result[1].forEach((i) => {
                  if (i.nodeKey === item.key) {
                    if (i.sprintId) {
                      item["sprintId"] = i.sprintId;
                    }
                    i.baselineItemDtoList &&
                      i.baselineItemDtoList.forEach((baseline) => {
                        if (
                          baseline.lock &&
                          baseline.status === "ALREADY_STARTED"
                        ) {
                          item["lock"] = baseline.lock;
                        }
                      });
                  }
                });
              });
              res = result[0];
              _this.$store.commit("SET_EXIST_MINDMAP", res);
              _this.jm.show(_this.$store.getters.exist_mindmap);
              vmson.$emit("jmshow", res);
              _this.ctrl_c_node = "";
              _this.ctrl_v_loading.close();
            });
          });
        } else if (_this.ctrl_c_node_list && _this.ctrl_c_node_list.length) {
          _this.ctrl_v_loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          let params = {
            sourceProjectId: _this.get_pid(),
            sourceFileKey: _this.$route.params.file_key,
            currentNodeKeyList: this.ctrl_c_node_list,
            targetProjectId: _this.get_pid(),
            targetFileKey: _this.$route.params.file_key,
            newParentNodeKey: _this.jm.get_selected_node().data.key,
          };
          copyMultiSelectNodeList(_this.get_pid(), params).then((r) => {
            const get_file = getFile(
              _this.get_pid(),
              _this.$route.params.file_key
            );
            const get_release = _this.getReleaseElements();
            let res = {};
            Promise.all([get_file, get_release]).then((result) => {
              result[0].data.forEach((item) => {
                result[1].forEach((i) => {
                  if (i.nodeKey === item.key) {
                    if (i.sprintId) {
                      item["sprintId"] = i.sprintId;
                    }
                    i.baselineItemDtoList &&
                      i.baselineItemDtoList.forEach((baseline) => {
                        if (
                          baseline.lock &&
                          baseline.status === "ALREADY_STARTED"
                        ) {
                          item["lock"] = baseline.lock;
                        }
                      });
                  }
                });
              });
              res = result[0];
              _this.$store.commit("SET_EXIST_MINDMAP", res);
              _this.jm.show(_this.$store.getters.exist_mindmap);
              vmson.$emit("jmshow", res);
              _this.ctrl_c_node_list = [];
              _this.ctrl_v_loading.close();
            });
          });
        }

        if (_this.jm.get_selected_node() && _this.ctrl_x_node) {
          _this.ctrl_v_loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          _this.jm.move_node(
            _this.ctrl_x_node_obj.id,
            "",
            _this.jm.get_selected_node().id
          );
          const node = _this.jm.get_node(_this.current_node_id);
          const before = _this.jm.find_node_before(_this.ctrl_x_node_obj.id);
          const params = {
            projectId: _this.get_pid(),
            nodeKey: _this.ctrl_x_node,
            data: {
              parentId: node.id,
              fileKey: _this.$route.params.file_key,
              previousId: before ? before.id : "",
            },
          };
          const lockList = [];
          this.findLock([node], lockList);
          if (lockList.length) {
            this.$message({
              message: this.$t("addMindMap.message23") + lockList.join(", "),
              type: "warning",
            });
            this.refreshMindMap();
            this.$nextTick(() => {
              vmson.$emit("jmshow", "***");
            });
            _this.ctrl_x_node = "";
            _this.ctrl_x_node_obj = "";
            _this.ctrl_v_loading.close();
            return;
          }
          node_location_change(params)
            .then((r) => {
              const get_file = _this.getFile();
              const get_release = _this.getReleaseElements();
              let res = {};
              Promise.all([get_file, get_release]).then((result) => {
                result[0].data.forEach((item) => {
                  result[1].forEach((i) => {
                    if (i.nodeKey === item.key) {
                      if (i.sprintId) {
                        item["sprintId"] = i.sprintId;
                      }
                      i.baselineItemDtoList &&
                        i.baselineItemDtoList.forEach((baseline) => {
                          if (
                            baseline.lock &&
                            baseline.status === "ALREADY_STARTED"
                          ) {
                            item["lock"] = baseline.lock;
                          }
                        });
                    }
                  });
                });
                res = result[0];
                res.data.forEach((node) => {
                  _this.current_node_List.forEach((current) => {
                    if (node.id === current.id) {
                      Object.assign(current.data, node);
                    }
                  });
                  if (_this.last_node && node.id === _this.last_node.id) {
                    Object.assign(_this.last_node.data, node);
                  }
                });
                _this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
                _this.jm.show(_this.$store.getters.exist_mindmap);
                this.addFileIcon();
                vmson.$emit("jmshow", "***");
                _this.ctrl_v_loading.close();
              });
            })
            .catch((e) => {
              _this.getFile().then((res) => {
                _this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
                _this.jm.show(res);
                _this.ctrl_v_loading.close();
              });
            });
          _this.ctrl_x_node = "";
          _this.ctrl_x_node_obj = "";
        }
        if (
          _this.current_node_key_List &&
          _this.current_node_key_List.length > 1
        ) {
          _this.$message.warning(_this.$t("canvas.clone.tip2"));
          return false;
        }
        //禁用原生ctrl + v 功能
        return false;
      }
      // 插入操作
      if (e.key == "Tab" || e.key == "Enter") {
        // 清除ctrlX选中
        _this.clearCtrlXFn();
        // 编辑状态则直接返回
        if (_this.jm.view.get_editing_node()) {
          return false;
        }
        // 未选中节点则直接返回
        if (_this.jm.get_selected_node() === null) {
          return;
        }
        // Enter键  同时  删除界面打开则进行删除功能 @朱东辉
        else if (
          e.key == "Enter" &&
          (_this.childVisible || _this.nodeVisible || _this.caseone)
        ) {
          _this.Delete_node();
          return;
        } else if (
          e.key == "Tab" &&
          (_this.childVisible || _this.nodeVisible || _this.caseone)
        ) {
          return;
        }
        // Enter键根节点界面则直接关闭窗口
        else if (e.key == "Enter" && _this.rootVisible) {
          _this.rootVisible = false;
          return;
        } else if (e.key == "Tab" && _this.rootVisible) {
          return;
        }
        // 打开关联窗时禁用enter和tab @朱东辉
        else if (_this.relation_dialog) {
          return;
        }
        // Enter键新增同级节点时选中根节点则直接返回
        else if (
          e.key === "Enter" &&
          _this.jm.get_selected_node().id === "root"
        ) {
          return;
        } else if (_this.current_node_List.length) {
          _this.$message({
            message: _this.$t("addMindMap.message2"),
            type: "warning",
          });
          return;
        }
        // 否则进行节点插入
        else {
          vmson.$emit("select_plan", "***");
          // 操作状态数组存储当前操作状态
          _this.actionsState.push(_this.deepCopy(_this.jm.mind));
          _this.Add_node(e.key);
          // 保存更改
          _this.savingState = _this.$t("addMindMap.message3");
          if (_this.backList.length == 0) {
            _this.backList.push(_this.$refs.jsMind.jm.get_data("node_array"));
            _this.goList = [];
            _this.jm.get_selected_node() == null;
          } else {
            if (
              _this.backList[_this.backList.length - 1] !=
              _this.$refs.jsMind.jm.get_data("node_array")
            ) {
              if (_this.backList.length > 6) {
                _this.backList.shift();
                _this.backList.push(
                  _this.$refs.jsMind.jm.get_data("node_array")
                );
              } else {
                _this.backList.push(
                  _this.$refs.jsMind.jm.get_data("node_array")
                );
              }
              _this.goList = [];
              _this.jm.get_selected_node() == null;
            }
          }
          return;
        }
      }
      // 删除节点操作
      if (
        (e.key === "Delete" || e.key === "Backspace") &&
        !_this.jm.view.get_editing_node() &&
        !_this.isedit &&
        !sessionStorage.getItem("relation_visible") &&
        !_this.reject_review_visible &&
        !_this.is_review_visible &&
        !_this.$refs.rightWindow.moveNodesDialogVisible
      ) {
        // 清除ctrlX选中
        _this.clearCtrlXFn();
        // 未选中节点则直接返回
        const jobAuthorities = _this.$store.getters.jobAuthorities;
        if (jobAuthorities.indexOf("NODE_DELETE_LEAF") === -1) {
          _this.$message({
            message: _this.$t("addMindMap.message4"),
            type: "warning",
          });
          return;
        }
        if (
          jobAuthorities.indexOf("NODE_DELETE_NON_LEAF") === -1 &&
          _this.jm.get_selected_node().children.length
        ) {
          _this.$message({
            message: _this.$t("addMindMap.message5"),
            type: "warning",
          });
          return;
        }

        // 多选禁止删除节点 弹框提示 @cjj
        if (_this.current_node_List.length > 1) {
          _this.$message.warning(_this.$t("canvas.clone.tip3"));
          return false;
        }
        // 如果含有节点被锁定禁止删除
        if (_this.jm.get_selected_node()) {
          const baseLineList = [];
          const releaseElements = await this.getReleaseElements();
          this.findBaseLine(
            [_this.jm.get_selected_node()],
            baseLineList,
            releaseElements
          );
          if (baseLineList.length) {
            _this.$message({
              message:
                _this.$t("addMindMap.message6") + baseLineList.join(", "),
              type: "warning",
            });
            return;
          }
        }
        if (_this.jm.get_selected_node() === null) {
          return;
        } else {
          get_node_testPlans(
            _this.get_pid(),
            _this.jm.get_selected_node().data.key
          ).then((res) => {
            let status = new Set();
            for (let i of res) {
              status.add(i.status);
            }
            let node_ = _this.jm.get_node(_this.current_node_id);
            if (status.has("COMPLETE")) {
              if (node_.isroot == true) {
                _this.rootVisible = true;
              } else {
                _this.caseone = true;
                if (node_.children.length != 0) {
                  _this.caseword = _this.$t("addMindMap.message7");
                } else {
                  _this.caseword = _this.$t("addMindMap.message8");
                }
              }
            } else if (status.has("IN_PROGRESS")) {
              if (node_.isroot == true) {
                _this.rootVisible = true;
              } else {
                _this.caseone = true;
                if (node_.children.length != 0) {
                  _this.caseword = _this.$t("addMindMap.message9");
                } else {
                  _this.caseword = _this.$t("addMindMap.message10");
                }
              }
            } else {
              _this.delete_dialog();
            }
          });
        }
      }
    },
    // DFMEA视图
    DFMEAView() {
      const params = {
        fileId: this.$store.getters.file_id,
      };
      effective(params).then((res) => {
        if (res.freeFileId !== this.$store.getters.file_id && !res.effective) {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=DFMEA" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        } else {
          this.showDFMEA = !this.showDFMEA;
          if (!this.showDFMEA) {
            const jmDom = document.querySelector(".js_m_box");
            jmDom.style = "{ opacity:  1 }";
          }
        }
      });
    },
    // 清除ctrlX数据和样式
    clearCtrlXFn() {
      this.removeOpacity(this.ctrl_x_node_obj);
      this.ctrl_x_node = "";
      this.ctrl_x_node_obj = "";
    },
    // 删除透明样式
    removeOpacity(obj) {
      if (obj.children && obj.children.length) {
        obj.children.forEach((child) => {
          this.removeOpacity(child);
        });
      }
      if (obj) {
        obj._data.view.element.classList.remove("opacity_style");
        obj._data.view.expander.classList.remove("opacity_style");
      }
    },
    // 更改节点文字对齐方式
    changeNodeTextIndent(type) {
      this.options.text_align = type;
      this.$refs.jsMind.set_text_align(this.options.text_align);
      // 重新渲染比下面的方法更耗时
      // this.mounted_next();
      let els = document.getElementsByTagName("jmnode");
      for (let i = 0; i < els.length; i++) {
        els[i].style.textAlign = this.options.text_align;
      }
      els = null;
    },
    node_location_changeFn(direction) {
      setTimeout(() => {
        this.addFileIcon();
        // jm.move_node
        if (this.current_node_List.length > 1) {
          if (direction === "up" || direction === "down") {
            this.$message({
              message: this.$t("addMindMap.message24"),
              type: "warning",
            });
          }
          return;
        }
        if (!this.current_node_id) {
          if (direction === "up") {
            this.$message({
              message: this.$t("addMindMap.message22"),
              type: "warning",
            });
          }
          if (direction === "down") {
            this.$message({
              message: this.$t("addMindMap.message22"),
              type: "warning",
            });
          }
          return;
        }
        let node = this.jm.get_node(this.current_node_id);
        if (!node) {
          if (direction === "up") {
            this.$message({
              message: this.$t("addMindMap.message22"),
              type: "warning",
            });
          }
          if (direction === "down") {
            this.$message({
              message: this.$t("addMindMap.message22"),
              type: "warning",
            });
          }
          return;
        }
        if (node) {
          if (!node.parent) return;
        }
        // 如果前后节点id不一致就返回 因为就不是移动节点了
        if (this.berfornode.id !== node.id) {
          return;
        }
        if (this.berfornode && node.data && !this.current_node_id == "root") {
          if (
            this.berfornode.index === node.index &&
            this.berfornode.parent.id === node.parent.id
          ) {
            return;
          }
        }
        if (this.berfornode) {
          if (this.berfornode.parent) {
            if (
              this.berfornode.parent.id === node.parent.id &&
              this.berfornode.index === node.index &&
              !direction
            ) {
              return;
            }
          }
          const lockList = [];
          this.findLock([this.berfornode], lockList);
          if (lockList.length) {
            this.$message({
              message: this.$t("addMindMap.message23") + lockList.join(", "),
              type: "warning",
            });
            this.refreshMindMap();
            this.$nextTick(() => {
              vmson.$emit("jmshow", "***");
            });
            return;
          }
        }
        let p = true;
        if (this.showDFMEA) {
          this.$message({
            message: this.$t("DFMEA.ms4"),
            type: "warning",
          });
          this.jm.show(this.$store.getters.exist_mindmap);
          return;
        }

        const before = this.jm.find_node_before(node.id);
        const after = this.jm.find_node_after(node.id);
        const params = {
          projectId: this.get_pid(),
          nodeKey: node.data.key,
          data: {
            parentId: node.parent.id,
            fileKey: this.$route.params.file_key,
            previousId: before ? before.id : "",
          },
        };
        if (direction === "up") {
          if (before) {
            const beforeFront = this.jm.find_node_before(before.id);
            if (beforeFront) {
              params.data.previousId = beforeFront.id;
            } else {
              params.data.previousId = "";
            }
          } else {
            return;
          }
        }
        if (direction === "down") {
          if (after) {
            params.data.previousId = after.id;
          } else {
            return;
          }
        }
        if (direction === "up" || direction === "down") {
          const lockList = [];
          this.findLock([node], lockList);
          if (lockList.length) {
            this.$message({
              message: this.$t("addMindMap.message23") + lockList.join(", "),
              type: "warning",
            });
          }
        }
        this.clearCtrlXFn();
        node_location_change(params)
          .then((r) => {
            const get_file = this.getFile();
            const get_release = this.getReleaseElements();
            let res = {};
            Promise.all([get_file, get_release]).then((result) => {
              result[0].data.forEach((item) => {
                result[1].forEach((i) => {
                  if (i.nodeKey === item.key) {
                    if (i.sprintId) {
                      item["sprintId"] = i.sprintId;
                    }
                    i.baselineItemDtoList &&
                      i.baselineItemDtoList.forEach((baseline) => {
                        if (
                          baseline.lock &&
                          baseline.status === "ALREADY_STARTED"
                        ) {
                          item["lock"] = baseline.lock;
                        }
                      });
                  }
                });
              });
              res = result[0];
              res.data.forEach((node) => {
                this.current_node_List.forEach((current) => {
                  if (node.id === current.id) {
                    Object.assign(current.data, node);
                  }
                });
                if (this.last_node && node.id === this.last_node.id) {
                  Object.assign(this.last_node.data, node);
                }
              });
              this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
              this.jm.show(this.$store.getters.exist_mindmap);
              this.addFileIcon();
              this.jm.select_node(node.id);
              vmson.$emit("jmshow", res);
            });
          })
          .catch((e) => {
            this.getFile().then((res) => {
              this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
              this.jm.show(res);
            });
          });
        if (this.jm.get_node(node.parent.id).isroot) {
          p = true;
        } else {
          p = this.jm.get_node(node.parent.id).parent.id;
        }
        if (this.cur_pid != node.parent.id && p != this.cur_ppid) {
          if (node.parent.children.length != 1) {
            for (let i in node.parent.children) {
              if (node.parent.children[i].id != this.current_node_id) {
                this.jm.get_node(this.current_node_id).data.content =
                  node.parent.children[i].data.content;
                break;
              }
            }
          }
        }
      }, 200);
    },
    changeText(val) {
      this.andCondition_text = val;
      this.andDialog_isShow = true;
    },
    get_title() {
      let d = new Date();
      let mounth =
        d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
      let date = d.getDate() > 9 ? d.getDate() : "0" + d.getDate();
      let hour = d.getHours() > 9 ? d.getHours() : "0" + d.getHours();
      let minute = d.getMinutes() > 9 ? d.getMinutes() : "0" + d.getMinutes();
      let s = d.getSeconds() > 9 ? d.getSeconds() : "0" + d.getSeconds();
      return (
        d.getFullYear().toString().slice(2, 4) +
        mounth +
        date +
        "-" +
        hour +
        minute +
        s
      );
    },
    process_review() {
      get_review_config(this.get_pid()).then((res) => {
        res.forEach((item) => {
          item.fileTypeList.forEach((type) => {
            if (type.fileTypeId == _this.fileTypeId) {
              _this.allowedBatchExec = item.allowedBatchExec;
              _this.review_list = item.itemList;
            }
          });
        });
      });
      let _this = this;
      // 获取用户可以评审的节点
      get_user_review(this.get_pid(), this.fileId).then((result) => {
        _this.user_can_review = result;
        _this.review_num_all = result.length;
        let user_review = result;
        get_file_process(this.get_pid(), this.fileId).then((res) => {
          res.forEach((el) => {
            _this.item_require_list_cjeckout[el.nodeKey] = el.reviewItems;
          });
          _this.user_reviewed = res;
          _this.review_num_process = res.length;

          // 在评审全部完成后自动跳转到 查看评审结果
          _this.card_isShow = true;
          _this.$emit("topbarBtnIshow");
          // _this.$route.query.process_review === "process_review" 用于限定其功能只能用于 从信息通知跳转到审查页面才可执行
          if (
            (_this.review_num_process == _this.review_num_all) &
            (_this.$route.query.process_review === "process_review")
          ) {
            _this.$emit("closeReview");
          }
          // let father = document.getElementsByClassName("jsmind-inner")[0];
          let father = document.querySelectorAll("jmnodes")[0];
          let jmnodes = document.querySelectorAll("jmnode");
          let arr = [];
          let yesrr = [];
          let norr = [];
          jmnodes.forEach((jmnode) => {
            let node = _this.jm.get_node(jmnode.getAttribute("nodeid"));
            if (node) {
              for (let i of res) {
                if (i.nodeKey == node.data.key) {
                  if (i.approved) {
                    yesrr.push(node.id);
                  } else {
                    _this.reject_data[node.id] = i;
                    norr.push(node.id);
                  }
                }
              }
              if (user_review.indexOf(node.data.key) > -1) {
                arr.push(jmnode);
              }
            }
          });

          arr.forEach((jmnode) => {
            if (jmnode.getAttribute("nodeid")) {
              let g = document.createElement("circle");
              g.style["height"] = "15px";
              g.style["width"] = "15px";
              g.style["z-index"] = "998";
              g.style["background-color"] = "rgba(218, 255, 221, 1)";
              g.style["border"] = "1px solid rgba(67, 207, 124, 1)";
              g.style["border-radius"] = "50%";
              g.style["position"] = "absolute";
              g.style["top"] = parseInt(jmnode.style.top) + "px";
              g.style["left"] =
                parseInt(jmnode.style.left) + jmnode.offsetWidth + "px";
              g.style["color"] = "rgba(218, 255, 221, 1)";
              g.innerHTML = "√";
              g.dataset.circleid = "g" + jmnode.getAttribute("nodeid");
              g.id = "g" + jmnode.getAttribute("nodeid");
              // g.className='circle_g'
              father.appendChild(g);

              let r = document.createElement("circle");
              r.style["height"] = "15px";
              r.style["width"] = "15px";
              r.style["z-index"] = "998";
              r.style["background-color"] = "rgba(255, 230, 225, 1)";
              r.style["border"] = "1px solid rgba(255, 87, 51, 1)";
              r.style["border-radius"] = "50%";
              r.style["position"] = "absolute";
              r.style["top"] =
                parseInt(jmnode.style.top) + jmnode.offsetHeight - 15 + "px";
              r.style["left"] =
                parseInt(jmnode.style.left) + jmnode.offsetWidth + "px";
              r.style["color"] = "rgba(255, 230, 225, 1)";
              r.innerHTML = "×";
              r.dataset.circleid = "r" + jmnode.getAttribute("nodeid");
              r.id = "r" + jmnode.getAttribute("nodeid");
              // r.className='circle_r'
              father.appendChild(r);
            }
          });

          if (arr.length > 0) {
            norr.forEach((c) => {
              document.getElementById("r" + c).className = "circle_r";
            });
            yesrr.forEach((c) => {
              document.getElementById("g" + c).className = "circle_g";
            });
          }
        });
      });
      // 获取用户之前评审数据
    },
    click_no_all() {
      this.review_list.forEach((item) => {
        this.item_require_list[item.itemId] = "no";
      });
      this.refresh_table++;
    },
    click_yes_all() {
      this.review_list.forEach((item) => {
        this.item_require_list[item.itemId] = "yes";
      });
      this.refresh_table++;
    },
    click_yes(itemId) {
      this.item_require_list[itemId] == "yes"
        ? (this.item_require_list[itemId] = "")
        : (this.item_require_list[itemId] = "yes");
      this.refresh_table++;
    },
    click_no(itemId) {
      this.item_require_list[itemId] == "no"
        ? (this.item_require_list[itemId] = "")
        : (this.item_require_list[itemId] = "no");
      this.refresh_table++;
    },
    check_all(a, b) {
      if (a == "yes") {
        this.review_list.forEach((item) => {
          this.item_require_list[item.itemId] = "yes";
        });
      }
      if (a == "no") {
        this.review_list.forEach((item) => {
          this.item_require_list[item.itemId] = "no";
        });
      }
      this.refresh_table++;
    },
    process_approved_review() {
      let falg = 0;

      this.review_list.forEach((item) => {
        if (item.itemId in this.item_require_list) {
          if (this.item_require_list[item.itemId].length > 0) {
            falg++;
          }
        }
      });
      if (falg < this.review_list.length) {
        this.$message.warning(this.$t("canvas.reviewRequestForm.tip7"));
        return false;
      }
      // if (this.approved_form.reason.length < 1) {
      //   this.$message.warning(this.$t("canvas.reviewRequestForm.tip1"));
      //   return false;
      // }
      let l = [];
      for (let i of Object.keys(this.item_require_list)) {
        if (this.item_require_list[i] == "yes") {
          l.push(i);
        }
      }
      if (l.length == 0) {
        this.show_tongguo_all = true;
        return;
      }
      this.show_approved_form = false;

      this.approved_form.reviewItems = l;

      let d = {};
      if (this.has_multi_pingshen) {
        let key_2_reviewProcessId = {};
        let zdh_reviewItems = {};

        for (let i of this.review_data) {
          key_2_reviewProcessId[i.nodeKey] = i.reviewProcessId;
          zdh_reviewItems[i.reviewProcessId] = [];
        }

        for (let i of this.multi_pingshen_data) {
          zdh_reviewItems[key_2_reviewProcessId[i]].push(i);
        }

        let zdh_review = [];

        for (let i in zdh_reviewItems) {
          zdh_review.push({
            reviewProcessId: i,
            nodeKeyList: zdh_reviewItems[i],
          });
        }

        d = {
          fileId: this.fileId,
          nodeKey: this.multi_pingshen_data,
          reviewNodeList: zdh_review,
          reason: this.reason_zdh,
          approved: true,
          reviewItems: l,
        };
      } else {
        d = {
          fileId: this.fileId,
          nodeKey: [this.approved_form.nodeKey],
          reviewNodeList: [
            {
              reviewProcessId: this.approved_form.reviewProcessId,
              nodeKeyList: [this.approved_form.nodeKey],
            },
          ],
          reason: this.reason_zdh,
          approved: true,
          reviewItems: l,
        };
      }

      processReview(this.get_pid(), d).then((r) => {
        let jmnodes = document.querySelectorAll("jmnode");
        let key_2_id = {};
        jmnodes.forEach((jmnode) => {
          let node = this.jm.get_node(jmnode.getAttribute("nodeid"));
          if (node) {
            console.log(node);
            key_2_id[node.data.key] = node.id;
          }
        });
        console.log(r);

        for (let i of d.nodeKey) {
          let nid = key_2_id[i];
          document.getElementById("g" + nid).className = "circle_g";
          document.getElementById("r" + nid).className = "";
        }

        for (let i of r) {
          if (i.ended) {
            if (this.$route.query.process_review) {
              this.$router.go(0);
            } else {
              this.$router.push({
                path: `/${this.get_pid()}/myMind/${
                  this.$route.params.file_key
                }`,
                query: { process_review: "process_review" },
              });
            }
          }
        }
        get_file_process(this.get_pid(), this.fileId).then((res) => {
          res.forEach((el) => {
            this.item_require_list_cjeckout[el.nodeKey] = el.reviewItems;
          });
          this.user_reviewed = res;
          if (this.is_show_no_review_nodes) {
            this.show_no_review_nodes();
          }
          this.review_num_process = res.length;
        });

        // if (!r) {
        //   get_file_process(this.get_pid(), this.fileId).then((res) => {
        //     res.forEach((el) => {
        //       this.item_require_list_cjeckout[el.nodeKey] = el.reviewItems;
        //     });
        //     if (this.is_show_no_review_nodes) {
        //       this.user_reviewed = res;
        //       this.show_no_review_nodes();
        //     }
        //     this.review_num_process = res.length;
        //   });
        // } else {
        //   if (this.$route.query.process_review) {
        //     this.$router.go(0);
        //   } else {
        //     this.$router.push({
        //       path: `/${this.get_pid()}/myMind/${this.$route.params.file_key}`,
        //       query: { process_review: "process_review" },
        //     });
        //   }
        // }
        // get_file_process(this.get_pid(), this.fileId).then((res) => {
        //   res.forEach((el) => {
        //     this.item_require_list_cjeckout[el.nodeKey] = el.reviewItems;
        //   });
        //   if (this.is_show_no_review_nodes) {
        //     this.user_reviewed = res;
        //     this.show_no_review_nodes();
        //   }
        //   this.review_num_process = res.length;
        // });
      });
    },
    process_approved_review_tongguo() {
      this.show_approved_form = false;
      this.show_tongguo_all = false;
      let l = [];
      for (let i of Object.keys(this.item_require_list)) {
        if (this.item_require_list[i] == "yes") {
          l.push(i);
        }
      }

      this.approved_form.reviewItems = l;

      let d = {};
      if (this.has_multi_pingshen) {
        let key_2_reviewProcessId = {};
        let zdh_reviewItems = {};

        for (let i of this.review_data) {
          key_2_reviewProcessId[i.nodeKey] = i.reviewProcessId;
          zdh_reviewItems[i.reviewProcessId] = [];
        }

        for (let i of this.multi_pingshen_data) {
          zdh_reviewItems[key_2_reviewProcessId[i]].push(i);
        }

        let zdh_review = [];

        for (let i in zdh_reviewItems) {
          zdh_review.push({
            reviewProcessId: i,
            nodeKeyList: zdh_reviewItems[i],
          });
        }

        d = {
          fileId: this.fileId,
          nodeKey: this.multi_pingshen_data,
          reviewNodeList: zdh_review,
          reason: this.reason_zdh,
          approved: true,
          reviewItems: l,
        };
      } else {
        d = {
          fileId: this.fileId,
          nodeKey: [this.approved_form.nodeKey],
          reviewNodeList: [
            {
              reviewProcessId: this.approved_form.reviewProcessId,
              nodeKeyList: [this.approved_form.nodeKey],
            },
          ],
          reason: this.reason_zdh,
          approved: true,
          reviewItems: l,
        };
      }

      processReview(this.get_pid(), d).then((r) => {
        let jmnodes = document.querySelectorAll("jmnode");
        let key_2_id = {};
        jmnodes.forEach((jmnode) => {
          let node = this.jm.get_node(jmnode.getAttribute("nodeid"));
          if (node) {
            console.log(node);
            key_2_id[node.data.key] = node.id;
          }
        });
        console.log(r);

        for (let i of d.nodeKey) {
          let nid = key_2_id[i];
          document.getElementById("g" + nid).className = "circle_g";
          document.getElementById("r" + nid).className = "";
        }

        for (let i of r) {
          if (i.ended) {
            if (this.$route.query.process_review) {
              this.$router.go(0);
            } else {
              this.$router.push({
                path: `/${this.get_pid()}/myMind/${
                  this.$route.params.file_key
                }`,
                query: { process_review: "process_review" },
              });
            }
          }
        }
        get_file_process(this.get_pid(), this.fileId).then((res) => {
          res.forEach((el) => {
            this.item_require_list_cjeckout[el.nodeKey] = el.reviewItems;
          });
          this.user_reviewed = res;
          if (this.is_show_no_review_nodes) {
            this.show_no_review_nodes();
          }
          this.review_num_process = res.length;
        });

        // let nid = this.approved_form.nodeID;
        // document.getElementById("g" + nid).className = "circle_g";
        // document.getElementById("r" + nid).className = "";
        // get_file_process(this.get_pid(), this.fileId).then((res) => {
        //   res.forEach((el) => {
        //     this.item_require_list_cjeckout[el.nodeKey] = el.reviewItems;
        //   });
        //   if (this.is_show_no_review_nodes) {
        //     this.user_reviewed = res;
        //     this.show_no_review_nodes();
        //   }
        //   this.review_num_process = res.length;
        // });
      });
    },
    approve_review_reason(data) {
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        let node = this.jm.get_node(jmnode.getAttribute("nodeid"));
        if (node) {
          if (node.id == data.slice(1, data.length)) {
            this.review_data.forEach((ele) => {
              if (ele.nodeKey == node.data.key) {
                let d = {
                  fileId: this.fileId,
                  nodeKey: node.data.key,
                  reviewProcessId: ele.reviewProcessId,
                  reason: "",
                  approved: true,
                  nodeID: node.id,
                };
                this.approved_form = d;
                this.show_approved_form = true;
                this.has_multi_pingshen = false;
                this.item_require_list = {};
                this.reason_zdh = "";
                if (d.nodeKey in this.item_require_list_cjeckout) {
                  for (let iii of this.user_reviewed) {
                    if (iii.nodeKey == d.nodeKey) {
                      this.reason_zdh = iii.reason;
                    }
                  }
                  if (
                    this.item_require_list_cjeckout[d.nodeKey] &&
                    this.item_require_list_cjeckout[d.nodeKey].length > 0
                  ) {
                    this.review_list.forEach((item) => {
                      this.item_require_list[item.itemId] = "no";
                    });
                    this.item_require_list_cjeckout[d.nodeKey].forEach(
                      (item) => {
                        this.item_require_list[item] = "yes";
                      }
                    );
                  }
                }
              }
            });
          }
        }
      });
    },
    reject_review() {
      let falg = 0;
      this.review_list.forEach((item) => {
        if (item.itemId in this.item_require_list) {
          if (this.item_require_list[item.itemId].length > 0) {
            falg++;
          }
        }
      });
      if (falg < this.review_list.length) {
        this.$message.warning(this.$t("canvas.reviewRequestForm.tip7"));
        return false;
      }
      let nid = this.reject_form.nodeID;
      let l = [];
      for (let i of Object.keys(this.item_require_list)) {
        if (this.item_require_list[i] == "yes") {
          l.push(i);
        }
      }
      if (this.review_list.length == l.length) {
        this.show_butongguo_all = true;
        return;
      }

      let d = {};
      if (this.has_multi_pingshen) {
        let key_2_reviewProcessId = {};
        let zdh_reviewItems = {};

        for (let i of this.review_data) {
          key_2_reviewProcessId[i.nodeKey] = i.reviewProcessId;
          zdh_reviewItems[i.reviewProcessId] = [];
        }

        for (let i of this.multi_pingshen_data) {
          zdh_reviewItems[key_2_reviewProcessId[i]].push(i);
        }

        let zdh_review = [];

        for (let i in zdh_reviewItems) {
          zdh_review.push({
            reviewProcessId: i,
            nodeKeyList: zdh_reviewItems[i],
          });
        }

        d = {
          fileId: this.fileId,
          nodeKey: this.multi_pingshen_data,
          reviewNodeList: zdh_review,
          reason: this.reason_zdh,
          approved: false,
          reviewItems: l,
        };
      } else {
        d = {
          fileId: this.fileId,
          nodeKey: [this.reject_form.nodeKey],
          reviewNodeList: [
            {
              reviewProcessId: this.reject_form.reviewProcessId,
              nodeKeyList: [this.reject_form.nodeKey],
            },
          ],
          reason: this.reason_zdh,
          approved: false,
          reviewItems: l,
        };
      }

      this.reject_form.reviewItems = l;
      processReview(this.get_pid(), d).then((r) => {
        let jmnodes = document.querySelectorAll("jmnode");
        let key_2_id = {};
        jmnodes.forEach((jmnode) => {
          let node = this.jm.get_node(jmnode.getAttribute("nodeid"));
          if (node) {
            console.log(node);
            key_2_id[node.data.key] = node.id;
          }
        });
        console.log(r);
        this.reject_review_visible = false;

        for (let i of d.nodeKey) {
          let nid = key_2_id[i];
          document.getElementById("r" + nid).className = "circle_r";
          document.getElementById("g" + nid).className = "";
          this.reject_data[nid] = d;
        }
        for (let i of r) {
          if (i.ended) {
            if (this.$route.query.process_review) {
              this.$router.go(0);
            } else {
              this.$router.push({
                path: `/${this.get_pid()}/myMind/${
                  this.$route.params.file_key
                }`,
                query: { process_review: "process_review" },
              });
            }
          }
        }
        get_file_process(this.get_pid(), this.fileId).then((res) => {
          res.forEach((el) => {
            this.item_require_list_cjeckout[el.nodeKey] = el.reviewItems;
          });
          this.user_reviewed = res;
          if (this.is_show_no_review_nodes) {
            this.show_no_review_nodes();
          }
          this.review_num_process = res.length;
        });
        // if (!rr) {
        //   get_file_process(this.get_pid(), this.fileId).then((res) => {
        //     res.forEach((el) => {
        //       this.item_require_list_cjeckout[el.nodeKey] = el.reviewItems;
        //     });
        //     if (this.is_show_no_review_nodes) {
        //       this.user_reviewed = res;
        //       this.show_no_review_nodes();
        //     }
        //     this.review_num_process = res.length;
        //   });
        // } else {
        //   if (this.$route.query.process_review) {
        //     this.$router.go(0);
        //   } else {
        //     this.$router.push({
        //       path: `/${this.get_pid()}/myMind/${this.$route.params.file_key}`,
        //       query: { process_review: "process_review" },
        //     });
        //   }
        // }
      });
      // this.reject_data[nid] = this.reject_form;
      // document.getElementById("r" + nid).className = "circle_r";
      // document.getElementById("g" + nid).className = "";
      // this.reject_review_visible = false;
    },
    reject_review_butongguo() {
      let falg = 0;
      this.review_list.forEach((item) => {
        if (item.itemId in this.item_require_list) {
          if (this.item_require_list[item.itemId].length > 0) {
            falg++;
          }
        }
      });
      let nid = this.reject_form.nodeID;
      let l = [];
      for (let i of Object.keys(this.item_require_list)) {
        if (this.item_require_list[i] == "yes") {
          l.push(i);
        }
      }
      this.reject_form.reviewItems = l;

      let d = {};
      if (this.has_multi_pingshen) {
        let key_2_reviewProcessId = {};
        let zdh_reviewItems = {};

        for (let i of this.review_data) {
          key_2_reviewProcessId[i.nodeKey] = i.reviewProcessId;
          zdh_reviewItems[i.reviewProcessId] = [];
        }

        for (let i of this.multi_pingshen_data) {
          zdh_reviewItems[key_2_reviewProcessId[i]].push(i);
        }

        let zdh_review = [];

        for (let i in zdh_reviewItems) {
          zdh_review.push({
            reviewProcessId: i,
            nodeKeyList: zdh_reviewItems[i],
          });
        }

        d = {
          fileId: this.fileId,
          nodeKey: this.multi_pingshen_data,
          reviewNodeList: zdh_review,
          reason: this.reason_zdh,
          approved: false,
          reviewItems: l,
        };
      } else {
        d = {
          fileId: this.fileId,
          nodeKey: [this.reject_form.nodeKey],
          reviewNodeList: [
            {
              reviewProcessId: this.reject_form.reviewProcessId,
              nodeKeyList: [this.reject_form.nodeKey],
            },
          ],
          reason: this.reason_zdh,
          approved: false,
          reviewItems: l,
        };
      }
      processReview(this.get_pid(), d).then((r) => {
        let jmnodes = document.querySelectorAll("jmnode");
        let key_2_id = {};
        jmnodes.forEach((jmnode) => {
          let node = this.jm.get_node(jmnode.getAttribute("nodeid"));
          if (node) {
            console.log(node);
            key_2_id[node.data.key] = node.id;
          }
        });
        console.log(r);

        for (let i of d.nodeKey) {
          let nid = key_2_id[i];
          document.getElementById("r" + nid).className = "circle_r";
          document.getElementById("g" + nid).className = "";
          this.reject_data[nid] = d;
        }
        this.reject_review_visible = false;
        for (let i of r) {
          if (i.ended) {
            if (this.$route.query.process_review) {
              this.$router.go(0);
            } else {
              this.$router.push({
                path: `/${this.get_pid()}/myMind/${
                  this.$route.params.file_key
                }`,
                query: { process_review: "process_review" },
              });
            }
          }
        }
        get_file_process(this.get_pid(), this.fileId).then((res) => {
          res.forEach((el) => {
            this.item_require_list_cjeckout[el.nodeKey] = el.reviewItems;
          });
          this.user_reviewed = res;
          if (this.is_show_no_review_nodes) {
            this.show_no_review_nodes();
          }
          this.review_num_process = res.length;
        });
        // if (!rr) {

        // } else {
        //   if (this.$route.query.process_review) {
        //     this.$router.go(0);
        //   } else {
        //     this.$router.push({
        //       path: `/${this.get_pid()}/myMind/${this.$route.params.file_key}`,
        //       query: { process_review: "process_review" },
        //     });
        //   }
        // }
      });
      this.show_butongguo_all = false;
      this.reject_review_visible = false;
    },
    process_choose_r(e, target) {
      let _this = this;
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        let node = _this.jm.get_node(jmnode.getAttribute("nodeid"));
        if (node) {
          if (node.id == e.slice(1, e.length)) {
            _this.review_data.forEach((ele) => {
              if (ele.nodeKey == node.data.key) {
                let d = {
                  fileId: _this.fileId,
                  nodeKey: node.data.key,
                  reviewProcessId: ele.reviewProcessId,
                  reason: _this.reject_data[node.id]
                    ? _this.reject_data[node.id].reason
                    : "",
                  approved: false,
                  nodeID: node.id,
                };
                _this.reject_form = d;
                _this.has_multi_pingshen = false;

                _this.reject_review_visible = true;

                _this.item_require_list = {};
                _this.reason_zdh = "";
                if (d.nodeKey in _this.item_require_list_cjeckout) {
                  for (let iii of _this.user_reviewed) {
                    if (iii.nodeKey == d.nodeKey) {
                      _this.reason_zdh = iii.reason;
                    }
                  }
                  if (
                    _this.item_require_list_cjeckout[d.nodeKey] &&
                    _this.item_require_list_cjeckout[d.nodeKey].length > 0
                  ) {
                    _this.review_list.forEach((item) => {
                      _this.item_require_list[item.itemId] = "no";
                    });
                    _this.item_require_list_cjeckout[d.nodeKey].forEach(
                      (item) => {
                        _this.item_require_list[item] = "yes";
                      }
                    );
                  }
                }
              }
            });
          }
        }
      });
    },
    process_choose_g(e) {
      let _this = this;
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        let node = _this.jm.get_node(jmnode.getAttribute("nodeid"));
        if (node) {
          if (node.id == e.slice(1, e.length)) {
            _this.review_data.forEach((ele) => {
              if (ele.nodeKey == node.data.key) {
                let d = {
                  fileId: _this.fileId,
                  nodeKey: node.data.key,
                  reviewProcessId: ele.reviewProcessId,
                  reason: "",
                  approved: true,
                };
                processReview(this.get_pid(), d).then((r) => {
                  if (!r) {
                    get_file_process(this.get_pid(), this.fileId).then(
                      (res) => {
                        res.forEach((el) => {
                          this.item_require_list_cjeckout[el.nodeKey] =
                            el.reviewItems;
                        });
                        this.user_reviewed = res;
                        if (this.is_show_no_review_nodes) {
                          this.show_no_review_nodes();
                        }
                        this.review_num_process = res.length;
                      }
                    );
                  } else {
                    if (this.$route.query.process_review) {
                      this.$router.go(0);
                    } else {
                      this.$router.push({
                        path: `/${this.get_pid()}/myMind/${
                          this.$route.params.file_key
                        }`,
                        query: { process_review: "process_review" },
                      });
                    }
                  }
                });
              }
            });
          }
        }
      });
    },
    review_outstanding(res) {
      let _this = this;
      if (res == 1) {
        get_file_review(this.get_pid(), _this.fileId).then((ress) => {
          _this.review_data = ress;
          for (let i of ress) {
            let jmnodes = document.querySelectorAll("jmnode");
            jmnodes.forEach((jmnode) => {
              let node = _this.jm.get_node(jmnode.getAttribute("nodeid"));
              if (node) {
                if (node.data.key == i.nodeKey) {
                  jmnode.classList.add(i.status);
                }
              }
            });
          }
        });
      } else {
        _this.review_data = res;
        for (let i of res) {
          let jmnodes = document.querySelectorAll("jmnode");
          jmnodes.forEach((jmnode) => {
            let node = _this.jm.get_node(jmnode.getAttribute("nodeid"));
            if (node) {
              if (node.data.key == i.nodeKey) {
                jmnode.classList.add(i.status);
              }
            }
          });
        }
      }
    },
    async mergedUsergroup(arr, stable, projectId) {
      let account = [];
      let group = [];
      arr.map((item, index) => {
        if (item.substr(-1) == 0) {
          account.push(item.substr(0, item.length - 1));
        } else {
          group.push(item.substr(0, item.length - 1));
        }
      });
      const params = {
        projectId: projectId,
        accountIdList: [...account, ...stable],
        groupIdList: group,
      };
      return await get_group_user_list(params);
    },
    async inviteReview() {
      const melist = await this.mergedUsergroup(
        this.review_form.person,
        this.review_form.stable,
        this.get_pid()
      );
      let userlist = [...this.review_form.person, ...this.review_form.stable];
      if (userlist.length < 1) {
        this.$message.warning(this.$t("canvas.reviewRequestForm.tip2"));
        return;
      }
      let d = {
        fileId: this.fileId,
        nodeKeys: [],
        title: this.review_form.title
          ? this.review_form.title
          : this.filename + this.get_title(),
        endDateTime: this.review_form.time
          ? this.review_form.time
          : this.get_time,
        reviewers: melist,
      };
      let nonum = [];
      let no = 0;
      let keylist = [];
      if (this.current_node_key_List.length > 0) {
        for (let item of this.review_data) {
          if (this.current_node_key_List.indexOf(item.nodeKey) > -1) {
            if (item.status == "REVIEWING" || item.status == "APPROVED") {
              nonum.push(item.nodeKey);
            }
          }
        }
        for (let i of this.current_node_key_List) {
          if (nonum.indexOf(i) > -1) {
            no++;
          } else {
            keylist.push(i);
          }
        }
        d.nodeKeys = keylist;
      } else {
        d.nodeKeys = [this.current_node_key];
      }
      inviteReview(this.get_pid(), d).then((res) => {
        if (no > 0) {
          this.$message.success(
            this.$t("canvas.reviewRequestForm.tip3") +
              no +
              this.$t("canvas.reviewRequestForm.tip4")
          );
        } else {
          this.$message.success(this.$t("canvas.reviewRequestForm.tip5"));
        }
        this.review_outstanding(1);
        vmson.$emit("jmshow", "***");
      });
      this.is_review_visible = false;
    },
    edit_plan() {
      if (this.testdata.length == 0) {
        this.testdata.push({
          index: 0,
          expectedResult: "",
          preConditions: "",
          testingProcedure: "",
        });
      }
      let n = 0;
      this.testdata.forEach((item) => {
        item.index = n++;
      });
      this.isedit = true;
    },
    del_plan(index) {
      this.testdata.splice(index, 1);
      let n = 0;
      this.testdata.forEach((item) => {
        item.index = n++;
      });
      // this.change();
    },
    add_plan() {
      let ind = this.testdata.length;
      this.testdata.push({
        index: ind,
        expectedResult: "",
        preConditions: "",
        testingProcedure: "",
      });
      // 滚动到新添加的地方
      this.$nextTick(() => {
        let isError = document.getElementsByClassName("hang");
        isError[isError.length - 1].scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      });
    },
    get_author(uid) {
      return this.matchUserNickname(uid, this.$t("board.status.unAssigned"));
    },
    change_test_window() {
      get_node_use_node_key(this.get_pid(), this.se_test_node).then((res) => {
        let d = [];
        for (let i of this.testdata) {
          d.push({
            expectedResult: i.expectedResult,
            preConditions: i.preConditions,
            testingProcedure: i.testingProcedure,
          });
        }
        res.testDetails = d;
        const params = {
          projectId: this.get_pid(),
          nodeKey: res.key,
          data: {
            isCustom: false,
            fieldId: "test_details",
            value: res.testDetails,
          },
        };
        only_set_property(params).then((r) => {
          this.isedit = false;
        });
      });
    },
    init_test_window() {
      this.select_nodes(this.se_test_node);
      this.isedit = false;
    },
    onmouseover_add_node() {
      clearTimeout(this.mouse_out_timer);
      if (this.jobAuthorities.indexOf("NODE_CREATE") === -1) {
        this.$message({
          type: "warning",
          message: this.$t("tip"),
        });
        return;
      }
      this.node_add_button_visible = false;
      let parentid = document.getElementById("node_add_button").nodeId;
      let newNode = {
        fileKey: this.$route.params.file_key,
        parentid: parentid,
        dataList: [
          {
            name: "New Node",
          },
        ],
      };
      add_node(this.get_pid(), newNode).then((r) => {
        const get_file = this.getFile(
          this.get_pid(),
          this.$route.params.file_key
        );
        const get_release = this.getReleaseElements();
        let res = {};
        Promise.all([get_file, get_release]).then((result) => {
          result[0].data.forEach((item) => {
            result[1].forEach((i) => {
              if (i.nodeKey === item.key) {
                if (i.sprintId) {
                  item["sprintId"] = i.sprintId;
                }
                i.baselineItemDtoList &&
                  i.baselineItemDtoList.forEach((baseline) => {
                    if (
                      baseline.lock &&
                      baseline.status === "ALREADY_STARTED"
                    ) {
                      item["lock"] = baseline.lock;
                    }
                  });
              }
            });
          });
          res = result[0];
          this.$store.commit("SET_EXIST_MINDMAP", res);
          this.current_node_id_List = [];
          this.current_node_List = [];
          this.current_node_key_List = [];
          this.jm.show(this.$store.getters.exist_mindmap);
          this.current_node_id = r[0].id;
          this.jm.select_node(r[0].id);
          this.jm.begin_edit(r[0].id);
          vmson.$emit("jmshow", res);
        });
      });
    },
    onmouseover_add_button(e) {
      if (e.target.id == "node_add_button") {
        clearTimeout(this.mouse_out_timer);
        this.node_add_button_visible = true;
      }
    },
    clear_scroll() {
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        if (jmnode.getAttribute("nodeid")) {
          jmnode.style.overflow = "hidden";
        }
      });
    },
    onmouseover_jmnode(e) {
      if (e.target.attributes.nodeId && e.target.tagName == "JMNODE") {
        e.target.style.overflow = "auto";
        clearTimeout(this.mouse_out_timer);
        let window_height =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;
        let window_width =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        let half_window_h = window_height * 0.5;
        let half_window_w = window_width * 0.5;
        let scale = this.jm && this.jm.view ? this.jm.view.actualZoom : 1;
        document.getElementById("node_add_button").nodeId =
          e.target.attributes.nodeId.nodeValue;
        document.getElementById("node_add_button").style.width =
          20 * scale + "px";
        document.getElementById("node_add_button").style.height =
          20 * scale + "px";
        let mouse_h = e.clientY;
        let mouse_w = e.clientX;
        const view = this.jm ? this.jm.view : "";
        if (mouse_h < half_window_h && mouse_w >= half_window_w) {
          document.getElementById("node_add_button").style.top =
            e.target.getBoundingClientRect().top +
            e.target.getBoundingClientRect().height / 2 -
            10 * view.actualZoom +
            "px" +
            "/*no*/";
          document.getElementById("node_add_button").style.left =
            e.target.getBoundingClientRect().right + "px" + "/*no*/";

          this.node_add_button_visible = true;
        }
        if (mouse_h >= half_window_h && mouse_w >= half_window_w) {
          document.getElementById("node_add_button").style.top =
            e.target.getBoundingClientRect().top +
            e.target.getBoundingClientRect().height / 2 -
            10 * view.actualZoom +
            "px" +
            "/*no*/";
          document.getElementById("node_add_button").style.left =
            e.target.getBoundingClientRect().right + "px" + "/*no*/";

          this.node_add_button_visible = true;
        }

        if (mouse_h < half_window_h && mouse_w < half_window_w) {
          document.getElementById("node_add_button").style.top =
            e.target.getBoundingClientRect().top -
            20 * view.actualZoom +
            "px" +
            "/*no*/";
          document.getElementById("node_add_button").style.left =
            e.target.getBoundingClientRect().right + "px" + "/*no*/";
          this.node_add_button_visible = true;
        }
        if (mouse_h >= half_window_h && mouse_w < half_window_w) {
          document.getElementById("node_add_button").style.top =
            e.target.getBoundingClientRect().bottom + "px" + "/*no*/";
          document.getElementById("node_add_button").style.left =
            e.target.getBoundingClientRect().right + "px" + "/*no*/";
          this.node_add_button_visible = true;
        }
      } else {
        this.clear_scroll();
        clearTimeout(this.mouse_out_timer);
        this.mouse_out_timer = setTimeout(() => {
          this.node_add_button_visible = false;
        }, 500);
      }
    },
    get_calc_red() {
      let container = document.querySelector("#pingshen-avatar-red");
      if (container) {
        let items = container.children;
        if (items.length > 0) {
          let count = 0;
          let offset = items[0].offsetTop;
          for (let i = 0; i < items.length; i++) {
            let currentOffset = items[i].offsetTop;
            if (currentOffset == offset) {
              count++;
            } else {
              break;
            }
          }
          let n = 0;
          let res = "";
          if (this.review_detail_data) {
            this.review_detail_data.reject.forEach((item) => {
              if (n >= count) {
                res += this.user_list[item.person].nickname + ", ";
              }
              n++;
            });
          }
          res = res.slice(0, -2);
          return res;
        }
      }
      return "";
    },
    get_calc_green() {
      let container = document.querySelector("#pingshen-avatar-green");
      if (container) {
        let items = container.children;
        if (items.length > 0) {
          let count = 0;
          let offset = items[0].offsetTop;
          for (let i = 0; i < items.length; i++) {
            let currentOffset = items[i].offsetTop;
            if (currentOffset == offset) {
              count++;
            } else {
              break;
            }
          }
          let n = 0;
          let res = "";
          if (this.review_detail_data) {
            this.review_detail_data.approve.forEach((item) => {
              if (n >= count) {
                res += this.user_list[item].nickname + ", ";
              }
              n++;
            });
          }
          res = res.slice(0, -2);
          return res;
        }
      }
      return "";
    },
    calc_dot(id) {
      let dom = document.getElementById(id);
      if (dom) {
        if (dom.clientHeight > 30) {
          return true;
        }
      }
      return false;
    },
    //鼠标左键按下方法
    onmousedownClick(e) {
      this.review_detail_show = false;

      // 若有节点处在编辑状态，则禁用此功能
      if (this.jm && this.jm.view && this.jm.view.get_editing_node()) {
        return false;
      }
      if (
        document
          .getElementById("global_variable_in_review")
          .innerText.replace(/^\s*|\s*$/g, "") == "true" &&
        e.target.attributes.nodeId
      ) {
        let jmnodes = document.querySelectorAll("jmnode");
        jmnodes.forEach((jmnode) => {
          if (
            jmnode.getAttribute("nodeid") ==
            e.target.attributes.nodeId.nodeValue
          ) {
            let flag = false;
            jmnode.classList.forEach((item) => {
              if (item == "APPROVED" || item == "UN_APPROVED") {
                flag = true;
              }
            });
            if (flag) {
              let node = this.jm.get_node(e.target.attributes.nodeId.nodeValue);
              getNodeReviewInfo(this.get_pid(), node.data.key).then((res) => {
                if (res.length > 0) {
                  get_review_config(this.get_pid()).then((resss) => {
                    resss.forEach((item) => {
                      item.fileTypeList.forEach((type) => {
                        if (type.fileTypeId == _this.fileTypeId) {
                          this.review_list = item.itemList;
                        }
                      });
                    });
                    let rea_list = {};

                    this.review_list.forEach((el) => {
                      rea_list[el.itemId] = 0;
                    });
                    let approve = [];
                    let reject = [];

                    let person_set = new Set();

                    res[0].nodeReviewResultInfos.forEach((item) => {
                      person_set.add(item.createdBy);
                      if (item.approved) {
                        approve.push({
                          person: item.createdBy,
                          commit: item.comment,
                        });
                      } else {
                        reject.push({
                          person: item.createdBy,
                          commit: item.comment,
                        });
                      }
                      if (item.reviewItems) {
                        item.reviewItems.forEach((ee) => {
                          rea_list[ee]++;
                        });
                      }
                    });
                    this.review_detail_data["approve"] = approve;
                    this.review_detail_data["reject"] = reject;

                    let ll = [];

                    this.review_list.forEach((el) => {
                      ll.push({
                        num: rea_list[el.itemId] / person_set.size,
                        l: el.content,
                      });
                    });
                    this.review_detail_data["rea_list"] = ll;
                    this.review_detail_show = true;
                  });
                }
              });
            } else {
              this.review_detail_show = false;
            }
          }
        });
      }
      let _this = this;
      if (!e.target.attributes.nodeId) {
        // 若鼠标在节点上按下，则禁用此功能
        if (!e.ctrlKey) {
          // 若没按ctrl键，且此时在空白地方点击，则清空已选中的节点
          _this.last_node = {};
          _this.current_node_id_List = [];
          _this.current_node_key_List = [];
          _this.current_node_List = [];
          _this.current_node_key = "";
          _this.current_node_id = "";
          _this.clear_all_checkced_style();
        }
        let selectContainer = document.getElementById("js_m_box");

        // 修改了缩放导致框选失效的问题，解决方法是挨个计算节点缩放后的位置（坐标变换法）
        let jmnodes = document.querySelectorAll("jmnode");
        const view = _this.jm ? _this.jm.view : "";
        let zoom_con = document.getElementsByClassName("jsmind-inner")[0];
        jmnodes.forEach((jmnode) => {
          if (jmnode.getAttribute("nodeid")) {
            let 上 = parseInt(jmnode.style.top);
            let 左 = parseInt(jmnode.style.left);
            let new_left =
              (左 + jmnode.offsetWidth / 2 - zoom_con.offsetWidth / 2) *
                view.actualZoom +
              zoom_con.offsetWidth / 2 -
              (jmnode.offsetWidth * view.actualZoom) / 2;

            let new_top =
              (上 + jmnode.offsetHeight / 2 - zoom_con.offsetHeight / 2) *
                view.actualZoom +
              zoom_con.offsetHeight / 2 -
              (jmnode.offsetHeight * view.actualZoom) / 2;
            let id = jmnode.getAttribute("nodeid");
            _this.poslist[id] = [
              new_left + document.getElementById("topbar_can").offsetLeft,
              new_top,
              jmnode.offsetWidth * view.actualZoom,
              jmnode.offsetHeight * view.actualZoom,
            ];
          }
        });

        let startX = 0;
        let startY = 0;
        startX =
          e.clientX - selectContainer.offsetLeft + selectContainer.scrollLeft;
        startY =
          e.clientY - selectContainer.offsetTop + selectContainer.scrollTop;
        let selDiv = document.createElement("div");
        selDiv.style.cssText =
          "position:absolute;width:0;height:0;margin:0;padding:0;border:1px dashed #eee;background-color:#aaa;z-index:1000;opacity:0.6;display:none;";
        selDiv.id = "selectDiv";
        selectContainer.appendChild(selDiv);
        selDiv.style.left = e.offsetX + "px";
        selDiv.style.top = e.offsetY + "px";
        _this.mouse_clientX = e.clientX;
        _this.mouse_clientY = e.clientY;

        _this.div_scrollHeight =
          document.getElementById("js_m_box").scrollHeight;
        _this.div_scrollWidth = document.getElementById("js_m_box").scrollWidth;
        let start_timer = true;
        //添加定时器--检测鼠标是否靠近浏览器边框。
        document.body.onmousemove = function (e) {
          _this.mouse_clientX = e.clientX;
          _this.mouse_clientY = e.clientY;
          if (e.buttons == 1) {
            if (
              start_timer &&
              document.getElementById("jsminddrag").innerText == "false"
            ) {
              start_timer = false;
              _this.timer_scroll = setInterval(function () {
                let div = document.getElementById("js_m_box");
                let windowWidth = window.innerWidth;
                let windowHeight = window.innerHeight;
                let mouseX = _this.mouse_clientX;
                let mouseY = _this.mouse_clientY;
                let scrollWidth = windowWidth * 0.05;
                let scrollHeight = windowHeight * 0.08;

                if (windowWidth - mouseX < scrollWidth) {
                  if (
                    div.scrollLeft <
                    _this.div_scrollWidth -
                      document.getElementById("js_m_box").offsetWidth
                  ) {
                    div.scrollLeft += 5;
                  }
                }

                if (mouseX < scrollWidth) {
                  div.scrollLeft -= 5;
                }

                if (windowHeight - mouseY < scrollHeight) {
                  if (
                    div.scrollTop <
                    _this.div_scrollHeight -
                      document.getElementById("js_m_box").offsetHeight
                  ) {
                    div.scrollTop += 5;
                  }
                }

                if (
                  mouseY -
                    (document.getElementsByClassName("topbar-main-w")[0]
                      .offsetHeight +
                      document.getElementsByClassName("topbar-wrap")[0]
                        .clientHeight) <
                  scrollHeight
                ) {
                  div.scrollTop -= 5;
                }

                let selectContainer = document.getElementById("js_m_box");
                let _x =
                  _this.mouse_clientX -
                  selectContainer.offsetLeft +
                  selectContainer.scrollLeft;
                let _y =
                  _this.mouse_clientY -
                  selectContainer.offsetTop +
                  selectContainer.scrollTop;

                let selDiv = document.getElementById("selectDiv");
                if (selDiv) {
                  selDiv.style.display = "block";
                  selDiv.style.left = Math.min(_x, startX) + "px";
                  selDiv.style.top = Math.min(_y, startY) + "px";
                  selDiv.style.width = Math.abs(_x - startX) + "px";
                  selDiv.style.height = Math.abs(_y - startY) + "px";
                }
              }, 10);
            }

            let selectContainer = document.getElementById("js_m_box");
            let _x =
              e.clientX -
              selectContainer.offsetLeft +
              selectContainer.scrollLeft;
            let _y =
              e.clientY - selectContainer.offsetTop + selectContainer.scrollTop;
            let selDiv = document.getElementById("selectDiv");
            if (selDiv) {
              selDiv.style.display = "block";
              selDiv.style.left = Math.min(_x, startX) + "px";
              selDiv.style.top = Math.min(_y, startY) + "px";
              selDiv.style.width = Math.abs(_x - startX) + "px";
              selDiv.style.height = Math.abs(_y - startY) + "px";
            }
          }
        };
        document.body.onmouseup = function () {
          clearInterval(_this.timer_scroll);
          let selDiv = document.getElementById("selectDiv");
          let s = 0;
          if (selDiv) {
            let p1 = [
              parseInt(selDiv.style.left) - s,
              parseInt(selDiv.style.top),
            ];
            let p2 = [
              parseInt(selDiv.style.left) - s + parseInt(selDiv.style.width),
              parseInt(selDiv.style.top),
            ];
            let p3 = [
              parseInt(selDiv.style.left) - s + parseInt(selDiv.style.width),
              parseInt(selDiv.style.top) + parseInt(selDiv.style.height),
            ];
            let p4 = [
              parseInt(selDiv.style.left) - s,
              parseInt(selDiv.style.top) + parseInt(selDiv.style.height),
            ];
            let choose = [];
            for (let i of Object.keys(_this.poslist)) {
              let n1 = [_this.poslist[i][0], _this.poslist[i][1]];
              let n2 = [
                _this.poslist[i][0] + _this.poslist[i][2],
                _this.poslist[i][1],
              ];
              let n3 = [
                _this.poslist[i][0] + _this.poslist[i][2],
                _this.poslist[i][1] + _this.poslist[i][3],
              ];
              let n4 = [
                _this.poslist[i][0],
                _this.poslist[i][1] + _this.poslist[i][3],
              ];
              let flag = false;
              if (
                p1[0] <= n1[0] &&
                n1[0] <= p2[0] &&
                p1[1] <= n1[1] &&
                n1[1] <= p4[1]
              ) {
                flag = true;
              }
              if (
                p1[0] <= n2[0] &&
                n2[0] <= p2[0] &&
                p1[1] <= n2[1] &&
                n2[1] <= p4[1]
              ) {
                flag = true;
              }
              if (
                p1[0] <= n3[0] &&
                n3[0] <= p2[0] &&
                p1[1] <= n3[1] &&
                n3[1] <= p4[1]
              ) {
                flag = true;
              }
              if (
                p1[0] <= n4[0] &&
                n4[0] <= p2[0] &&
                p1[1] <= n4[1] &&
                n4[1] <= p4[1]
              ) {
                flag = true;
              }
              if (
                n1[0] <= p1[0] &&
                p1[0] <= n2[0] &&
                n1[0] <= p3[0] &&
                p3[0] <= n2[0] &&
                p1[1] <= n3[1] &&
                n1[1] <= p1[1]
              ) {
                flag = true;
              }
              if (
                n1[0] <= p1[0] &&
                p1[0] <= n2[0] &&
                n1[0] <= p3[0] &&
                p3[0] <= n2[0] &&
                p3[1] <= n3[1] &&
                n1[1] <= p3[1]
              ) {
                flag = true;
              }
              if (
                n1[0] <= p1[0] &&
                p1[0] <= n2[0] &&
                n1[0] <= p3[0] &&
                p3[0] <= n2[0] &&
                p3[1] >= n3[1] &&
                p1[1] <= n1[1]
              ) {
                flag = true;
              }
              if (
                n1[1] <= p1[1] &&
                p1[1] <= n3[1] &&
                n1[0] <= p3[0] &&
                p3[0] <= n2[0] &&
                p3[1] <= n3[1] &&
                n1[1] <= p3[1]
              ) {
                flag = true;
              }
              if (
                n1[1] <= p1[1] &&
                p1[1] <= n3[1] &&
                n1[0] <= p1[0] &&
                p1[0] <= n2[0] &&
                p3[1] <= n3[1] &&
                n1[1] <= p3[1]
              ) {
                flag = true;
              }
              if (
                n1[1] <= p1[1] &&
                p1[1] <= n3[1] &&
                p1[0] <= n1[0] &&
                p3[0] >= n2[0] &&
                p3[1] <= n3[1] &&
                n1[1] <= p3[1]
              ) {
                flag = true;
              }
              if (flag) {
                choose.push(i);
              }
            }
            let jmnodes = document.querySelectorAll("jmnode");
            let num = 0;
            let nodekey_single = "";
            jmnodes.forEach((jmnode) => {
              if (choose.indexOf(jmnode.getAttribute("nodeid")) > -1) {
                num++;
                let node = _this.jm.get_node(jmnode.getAttribute("nodeid"));
                nodekey_single = node;
                // 框选有时失效 加入定时器就不会出现失效 原因未知
                if (
                  !node._data.view.element.classList.contains("opacity_style")
                ) {
                  setTimeout(() => {
                    _this.jm.get_node(node.id)._data.view.element.id =
                      "custom_style";
                  });
                  _this.current_node_id_List.push(node.id);
                  _this.current_node_key_List.push(node.data.key);
                  _this.current_node_List.push(node);
                }
              }
            });
            if (num == 1) {
              jmnodes.forEach((jmnode) => {
                let node = _this.jm.get_node(jmnode.getAttribute("nodeid"));
                if (node) {
                  if (node.id == nodekey_single.id) {
                    if (
                      !node._data.view.element.classList.contains(
                        "opacity_style"
                      )
                    ) {
                      jmnode.click();
                      _this.jm.select_node(node.id);
                    }
                  }
                }
              });
            }
            selDiv.parentNode.removeChild(selDiv);
          }
        };
      }
    },

    //  清空选中项
    clearchecked() {
      this.current_node_id_List = [];
      this.current_node_List = [];
      this.current_node_key_List = [];
    },
    showtestbug(data) {
      this.open_dialog(data);
    },
    open_dialog(ney) {
      this.ownVisible = true;
      this.ruleForm.name = "";
      this.ruleForm.type = "";
      get_filetype(this.get_pid()).then((res) => {
        this.ruleForm.type_options = res.filter((item) => {
          return item.bug;
        });
      }); //获取这个项目组的文件类型
      this.ruleForm.test_plan_list = Object.values(
        JSON.parse(sessionStorage.getItem("_testPlan"))
      );
      this.ruleForm.test_plan_list.forEach((item) => {
        if (item.status == "NOT_STARTED") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.notStarted") +
            ")";
        } else if (item.status == "IN_PROGRESS") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.inProgress") +
            ")";
        } else if (item.status == "COMPLETE") {
          item.name_display =
            `${item.name} (` +
            this.$t("canvas.testPlan.status.completed") +
            ")";
        }
      });
      this.ruleForm.test_plan = document
        .getElementById("global_variable_select_testplan")
        .innerText.replace(/^\s*|\s*$/g, "");
      get_test_nodes(this.get_pid(), this.ruleForm.test_plan).then((res) => {
        this.ruleForm.test_case_list = res;
        this.ruleForm.test_case = ney;
        this.currentRowBugList = [];
        this.ruleForm.test_case_list.forEach((item) => {
          item.bugNodes.map((item) => {
            this.currentRowBugList.push(item.nodeKey);
          });
        });
        if (Array.isArray(this.bugNodesList)) {
          this.bugNodesListShow = this.bugNodesList.filter((item) => {
            return this.currentRowBugList.indexOf(item.nodeKey) == -1;
          });
        }
      });
    },
    async createProgram_comfirm() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          //新建文件 确定
          this.ownVisible = false;
          this.is_dialog_visible = false;
          try {
            let res = await createNewFile(
              //创建文件
              this.get_pid(),
              this.ruleForm.name,
              this.ruleForm.type,
              this.ruleForm.test_case,
              this.ruleForm.test_plan
            );
            this.$message({
              message: this.$t("homeTopBar.newFileForm.message"),
              type: "success",
              duration: 5 * 1000,
            });
            const new_route = this.$router.resolve(
              `/${this.get_pid()}/myMind/${res}`
            );
            window.open(new_route.href, "_blank");
          } catch (error) {
            console.log(error);
            return;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    select_nodes(ney) {
      if (!this.file_test) {
        return;
      }
      this.se_test_node = ney;
      get_node_use_node_key(this.get_pid(), ney).then((res) => {
        this.lock = res.lock;
        if (
          this.left_dialog_visible == false &&
          this.left_btn_visible == false
        ) {
          this.left_dialog_visible = true;
        }
        // this.left_btn_visible = true;
        if (!res.testDetails) {
          this.testdata = [];
        } else {
          this.testdata = [];
          let ind = 0;
          for (let i of res.testDetails) {
            this.testdata.push({
              index: ind,
              expectedResult: i.expectedResult,
              preConditions: i.preConditions,
              testingProcedure: i.testingProcedure,
            });
            ind++;
          }
        }
      });
    },
    testVisible() {
      return true;
    },
    select() {
      let aa = document.getElementsByTagName("jmnode");
      for (let i of aa) {
        if (i.attributes["nodeid"]["nodeValue"] == this.nodeclass) {
          i.click();
          let view_data = this.jm.get_node(this.nodeclass)._data.view;
          let node_center_point = {
            x: view_data.abs_x + view_data.width / 2,
            y: view_data.abs_y + view_data.height / 2,
          };
          this.$nextTick(() => {
            const div = document.getElementById("js_m_box");
            div.scrollTo(
              node_center_point.x - div.offsetWidth / 2,
              node_center_point.y - div.offsetHeight / 2
            );
          });
          return;
        }
      }
    },
    word_link() {
      this.$router.push({
        path: `/${this.get_pid()}/lineword/${this.$route.params.file_key}`,
      });
    },
    closeRightMethod() {
      let right_cl = document.getElementById("right_c_w");
      right_cl.style.display = "none";
      vmson.$emit("get_fvalue", true);
      this.isfvalue = true;
    },
    async initWebsocket() {
      let socket = new SockJS(
        `${this.wsurl}${
          this.wsurl ? "" : "/prod-api"
        }/file-manage-service/sendServer`
      );
      this.stompClient = Stomp.over(socket);
      this.stompClient.connect(
        {
          token: this.token,
          projectId: this.get_pid(),
          scene: "MIND_MAP_MODIFY",
        }, //传递token
        (frame) => {
          // 测试topic
          this.stompClient.subscribe(
            `/topic/MIND_MAP_MODIFY/${this.get_pid()}/${
              this.$store.getters.file_id
            }`,
            (res) => {
              if (res.body !== this.userAccountId) {
                this.polling();
              }
            }
          );
        },
        (err) => {
          console.log("错误：" + err);
        }
      );
      this.stompClient.heartbeat.outgoing = 20000; //若使用STOMP 1.1 版本，默认开启了心跳检测机制（默认值都是10000ms）
      this.stompClient.heartbeat.incoming = 0; //客户端不从服务端接收心跳包
    },
    closeWebsocket() {
      if (this.stompClient) {
        try {
          this.stompClient.disconnect(() => {});
        } catch (e) {
          console.log(e);
        }
      }
    },
    // 修改单个节点信息
    change_single_node_status(obj) {
      const node = this.jm.get_node(obj.node_id);
      node.data.status = obj.status_id;
      node.data.statusName = obj.status_name;
    },
    // mounted 事件提出
    async mounted_next(type) {
      const get_release = this.getReleaseElements();
      let res = {};
      if (type === "getNewData") {
        const get_file = this.getFile();
        await Promise.all([get_file, get_release])
          .then((result) => {
            result[0].data.forEach((item) => {
              result[1].forEach((i) => {
                if (i.nodeKey === item.key) {
                  if (i.sprintId) {
                    item["sprintId"] = i.sprintId;
                  }
                  i.baselineItemDtoList &&
                    i.baselineItemDtoList.forEach((baseline) => {
                      if (
                        baseline.lock &&
                        baseline.status === "ALREADY_STARTED"
                      ) {
                        item["lock"] = baseline.lock;
                      }
                    });
                }
              });
            });
            res = result[0];
          })
          .catch((err) => {
            this.$router.push("/home");
          });
      } else {
        await get_release.then((res1) => {
          const result = [this.fileData, res1];
          result[0].data.forEach((item) => {
            result[1].forEach((i) => {
              if (i.nodeKey === item.key) {
                if (i.sprintId) {
                  item["sprintId"] = i.sprintId;
                }
                i.baselineItemDtoList &&
                  i.baselineItemDtoList.forEach((baseline) => {
                    if (
                      baseline.lock &&
                      baseline.status === "ALREADY_STARTED"
                    ) {
                      item["lock"] = baseline.lock;
                    }
                  });
              }
            });
          });
          res = result[0];
        });
      }

      if (res) {
        this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
        this.$store.commit("SET_FILE_ID", res.data[0].fileId);
        this.$store.commit("SET_FILE_KEY", this.$route.params.file_key);
        this.filename = res.meta.name;
        this.fileTypeId = res.fileTypeId;
        this.$store.commit("SET_MINDMAP_NAME", res.meta.name);
        this.$store.commit("SET_FILETYPE_ID", res.fileTypeId);
        if (this.fileType) {
          for (let i of Object.values(this.fileType)) {
            if (i["fileTypeId"] == this.$store.getters.file_type_id) {
              this.file_test = i.testCase;
              if (this.nodeclass) {
                setTimeout(() => {
                  this.select_nodes(this.jm.get_node(this.nodeclass).data.key);
                });
              }
            }
          }
        }
      }
      this.mind = this.$store.getters.exist_mindmap;
      this.jsmind_show = true;
      // 初始化思维导图控制对象
      this.jm = this.$refs.jsMind.jm;
      this.current_node_id_List = [];
      this.current_node_List = [];
      this.current_node_key_List = [];
      try {
        this.jm.show(this.mind);
        this.addFileIcon();
      } catch {
        this.$router.go(0);
      }
      // 操作状态数组存储初始值
      this.actionsState.push(this.deepCopy(this.jm.mind));
      // 思维导图可编辑性判定
      if (this.$store.getters.open_state === "exist") {
        const permission = this.$store.getters.permission;
        if (permission == "review") {
          this.isReviewer = true;
        }
        if (
          typeof permission !== "undefined" &&
          permission !== "owner" &&
          permission !== "edit"
        ) {
          //非创建者与非编辑者
          this.jm.enable_edit();
        } else {
          this.jm.enable_edit();
        }
      } else {
        this.jm.enable_edit();
      }
      // 思维导图监听属性
      this.editor = this.jm.view.e_editor;
      this.e_nodes = this.jm.view.e_nodes;
      // 全局键盘事件监听
      let _this = this;
      document.onkeydown = this.onkeydown;
      document.onmouseup = this.on_mouse_up;

      this.jm.view.add_event(this.editor, "dblclick", (e) => {
        if (this.jm.get_selected_node()) {
          if (this.jm.get_selected_node().data.lock) {
            this.$message.warning(this.$t("addMindMap.message11"));
            this.jm.show(this.mind);
            this.$nextTick(() => {
              vmson.$emit("jmshow", "***");
            });
            return false;
          }
        }

        let textarea = document.querySelector("textarea");
        textarea.addEventListener("input", (e) => {
          document.getElementById("textarea").rows = 2;
          while (-e.target.clientWidth + e.target.offsetWidth > 6) {
            document.getElementById("textarea").rows += 1;
          }
        });
      });
      /**鼠标右键点击 */
      this.jm.view.add_event(this.editor, "contextmenu", (e) => {
        document.oncontextmenu = function (e) {
          return false; //阻止浏览器的默认弹窗行为
        };
        /** 没有选中节点，直接返回 */
        if (!e.target.attributes.nodeId) {
          return false;
        }
        /** 右键窗口显示 */
        this.$refs.rightWindow.$el.style.display = "block";
        /** 隐藏浮窗 */
        this.popover_visible = false;
        vmson.$emit("get_fvalue", false);
        this.isfvalue = false;
        /** 确定右键窗口出现位置 */
        const docx = document.body.clientWidth;
        const docy = document.body.clientHeight;
        if (e.clientY + 400 > docy) {
          this.$refs.rightWindow.$el.style.top = e.clientY + 35 - 400 + "px";
        } else {
          this.$refs.rightWindow.$el.style.top = e.clientY + 35 + "px";
        }
        if (e.clientX + 300 > docx) {
          this.$refs.rightWindow.$el.style.left = e.clientX - 10 - 300 + "px";
        } else {
          this.$refs.rightWindow.$el.style.left = e.clientX - 10 + "px";
        }
        const node = this.jm.get_node(e.target.attributes.nodeId.nodeValue);
        let flag = true;
        this.current_node_List.forEach((item) => {
          if (item.data.nodeUuid === node.data.nodeUuid) {
            flag = false;
          }
        });
        if (flag) {
          this.clear_all_checkced_style(node);
          this.current_node_id_List.push(node.id);
          this.current_node_key_List.push(node.data.key);
          this.current_node_List.push(node);
        } else {
          if (this.last_node && this.last_node.id) {
            this.jm
              .get_node(this.last_node.id)
              ._data.view.element.classList.remove("selected");
          }
        }
        this.jm.get_node(node.id)._data.view.element.id = "custom_style";
        this.last_node = node;
        // this.batchOperation(node);
        this.$refs.rightWindow.buildTree(node);
      });
      // 鼠标悬浮事件
      this.jm.view.add_event(this.editor, "mouseover", (e) => {
        this.count_mouseover_time = 0;
        clearTimeout(this.isouts); // 清除节点定时器
        clearTimeout(this.isleave); // 清除浮窗定时器
        clearInterval(this.prevent_multi_req);
        if (!this.fvalue || !e.target.attributes.nodeId) {
          // 浮窗功能关闭
          return;
        }

        this.nodeUuid = this.jm.get_node(
          e.target.attributes.nodeId.nodeValue
        ).data.nodeUuid;
        this.layer = this.jm.get_node(
          e.target.attributes.nodeId.nodeValue
        ).data.layer;
        this.fileTypeId = this.jm.get_node(
          e.target.attributes.nodeId.nodeValue
        ).data.fileTypeId;
        // 防抖：mouseover超过半秒显示浮窗
        this.prevent_multi_req = setInterval(() => {
          this.count_mouseover_time++;
          if (this.count_mouseover_time == 5) {
            this.isEnterNode = true;
            // 显示属性浮窗
            if (this.fvalue == true) {
              this.popover_visible = true;
              this.$nextTick(() => {
                this.mouse_h = e.clientY;
                this.mouse_w = e.clientX;
                this.target = e.target;
                this.set_popover_position(); // 设置浮窗展示位置
              });
            }
          }
        }, 100);
        // 获取当前节点
        if (typeof e.target.attributes.nodeId === "undefined") {
          return;
        }
        let node = this.jm.get_node(e.target.attributes.nodeId.nodeValue);
        // 若无属性字段则设为缺省值
        if (typeof node.data.content === "undefined") {
          node.data.content = [
            {
              propertyName: "Priority",
              propertyVal: "",
              isPrimary: true,
            },
          ];
        }
        this.properties = node.data.content;

        // 属性浮窗坐标移动定位
        this.curNode = e.target.attributes.nodeId;
        this.activeNodeId = node.id;
      });
      this.jm.view.add_event(this.editor, "mousedown", (e) => {
        if (!e.target.attributes.nodeId) {
          return;
        }
        if (!this.jm.get_node(e.target.attributes.nodeId.value)) {
          return;
        }
        this.cur_pid = this.jm.get_node(e.target.attributes.nodeId.value).parent
          ? this.jm.get_node(e.target.attributes.nodeId.value).parent.id
          : null;
        // 隐藏浮窗
        this.originfvalue = this.isfvalue;
        this.$nextTick(() => {
          // this.popover_visible = false;
          // vmson.$emit("get_fvalue", false);
          // this.isfvalue = false;
        });
        this.current_node_id = e.target.attributes.nodeId.value;
        let node = this.jm.get_node(this.current_node_id);
        // 点击节点如果是dfmea模式的高亮树结构的某个点
        if (this.showDFMEA) {
          this.$refs["dfmeaDrawer"].highLight(node);
        }
        if (node.parent) {
          this.cur_node_parentId = node.parent.id;
        } else {
          this.cur_node_parentId = "";
        }
        if (this.cur_pid) {
          if (this.jm.get_node(this.cur_pid)) {
            if (this.jm.get_node(this.cur_pid).isroot) {
              this.cur_ppid = true;
            } else {
              this.cur_ppid = this.jm.get_node(this.cur_pid).parent.id;
            }
          }
        }
        this.berfornode = Object.assign({}, node);
        this.$nextTick(() => {
          const node = this.jm.get_node(e.target.attributes.nodeId.nodeValue);
          this.jm
            .get_node(node.id)
            ._data.view.element.classList.remove("selected");
        });
      });
      this.jm.view.add_event(this.editor, "mouseup", (e) => {
        // vmson.$emit("get_fvalue", this.originfvalue);
        // this.isfvalue = this.originfvalue;
        this.node_location_changeFn();
      });

      this.jm.view.add_event(this.editor, "mouseout", (e) => {
        clearTimeout(this.mouse_out_timer_hide_popover);
        this.mouse_out_timer_hide_popover = null;
        //修复移动过快消失
        const popdown = document.querySelector(
          ".el-select-dropdown.el-popper.user_select"
        );
        // 清除防抖定时器;
        clearInterval(this.prevent_multi_req);
        this.count_mouseover_time = 0;
        // this.isEnterNode = false;
        // 移出节点，超时隐藏浮窗
        this.isouts = setTimeout(() => {
          let node = e.target.attributes.nodeId;
          if (this.isEnterPopover == false && node == this.curNode) {
            //修复移动过快消失
            if (!popdown) {
              this.popover_visible = false;
            }
          }
          this.curNode = "";
        }, 1000);
      });
      this.jm.view.add_event(this.e_nodes, "mouseout", (e) => {
        if (
          e.srcElement.nodeName === "JMNODE" &&
          e.toElement &&
          (e.toElement.nodeName === "JMNODES" || e.toElement.nodeName === "I")
        ) {
          clearTimeout(this.mouse_out_timer_hide_popover);
          // 隐藏浮窗的定时器
          this.mouse_out_timer_hide_popover = setTimeout(() => {
            this.popover_visible = false;
          }, 1000);
        }
      });
      // 鼠标点击事件
      document.querySelector(".js_m_box").addEventListener("click", (e) => {
        if (e.target.tagName == "CIRCLE") {
          if (
            e.target.className == "circle_g" ||
            e.target.className == "circle_r"
          ) {
            if (e.target.className == "circle_g") {
              _this.approve_review_reason(e.target.dataset.circleid, e.target);
            }
            if (e.target.className == "circle_r") {
              _this.process_choose_r(e.target.dataset.circleid, e.target);
            }
          } else {
            if (e.target.dataset.circleid[0] == "g") {
              _this.approve_review_reason(e.target.dataset.circleid, e.target);
            }
            if (e.target.dataset.circleid[0] == "r") {
              _this.process_choose_r(e.target.dataset.circleid, e.target);
            }
          }
          return;
        }
        /**右键浮窗消失 */
        let right_cl = document.getElementById("right_c_w");
        if (right_cl) {
          if (right_cl.style.display == "block") {
            vmson.$emit("get_fvalue", true);
            this.isfvalue = true;
          }
          right_cl.style.display = "none";
        }
        // 获取当前节点
        if (!e.target.attributes.nodeId) {
          return;
        }
        const node = this.jm.get_node(e.target.attributes.nodeId.nodeValue);
        if (typeof e.target.attributes.nodeId !== "undefined") {
          this.select_nodes(node.data.key);
          if (e.ctrlKey || e.metaKey) {
            this.batchOperation(node);
          } else {
            if (this.current_node_id_List.length > 1) {
              vmson.$emit("get_fvalue", true);
              this.isfvalue = true;
            }
            this.clear_all_checkced_style(node);
          }
          this.$nextTick(() => {
            this.jm
              .get_node(node.id)
              ._data.view.element.classList.remove("selected");
          });
          this.current_node_id = node.id;
          this.current_node_key = node.data.key;
          this.lastNodeId = node.id; //为了文本域失焦的时候丢失id  记录一下上个节点id
        }
        this.$nextTick(() => {
          this.jm
            .get_node(node.id)
            ._data.view.element.classList.remove("selected");
        });
        this.current_node_id = node.id;
        this.current_node_key = node.data.key;
      });
      // zoomIn zoomOut 相关操作
      const listenerDom = document.getElementById("topbar_can");
      listenerDom.addEventListener("mousewheel", this.onMouseWheel, {
        passive: false,
      });
      listenerDom.addEventListener("DOMMouseScroll", this.onMouseWheel, {
        passive: false,
      });
      window.addEventListener("scroll", this.scrollHandler, true);
      // 修改思维导图节点内容事件
      this.originalUpdateNode = Object.getPrototypeOf(
        this.$refs.jsMind.jm
      ).update_node;
      // 缓存当前this
      let self = this;
      Object.getPrototypeOf(this.$refs.jsMind.jm).update_node = function (
        nodeid,
        topic
      ) {
        // 执行原生的方法
        // 这里this指的this.$refs.jsMind.jm对象
        self.originalUpdateNode.call(this, nodeid, topic);
        // 以下执行我们的逻辑
        self.savingState = self.$t("addMindMap.message3");
        // 延时保存 避免数据未同步保存为旧数据
        self.$nextTick(() => {
          setTimeout(() => {
            self.Edit_node(self.jm.get_node(nodeid));
            //保存
          }, 40);
        });
      };
      //是否开启浮窗
      vmson.$on("set_fvalue", (val) => {
        this.isfvalue = val;
      });
      //是否开启浮窗
      vmson.$on("open_map_seaech_dig", () => {
        this.showDialogFn();
      });
      // 监听保存事件 val为思维导图名称
      vmson.$on("save", (val) => {
        // 避免重复的保存
        let nowTime = new Date();
        if (
          nowTime.getTime() - this.$store.getters.current_time.getTime() >
          5
        ) {
          this.save(
            this.$store.getters.email,
            this.$refs.jsMind.jm.get_data("node_array"),
            val
          );
        }
        this.$store.commit("SET_CURRENT_TIME", nowTime);
      });
      // 监听返回事件 val为思维导图名称
      vmson.$on("back", (val) => {
        // 避免重复的保存
        let nowTime = new Date();
        if (
          nowTime.getTime() - this.$store.getters.current_time.getTime() >
          5
        ) {
          this.save(
            this.$store.getters.email,
            this.$refs.jsMind.jm.get_data("node_array"),
            val
          );
        }
        this.$store.commit("SET_CURRENT_TIME", nowTime);
        this.$router.push("/home/my_file");
      });
      vmson.$on("set_clear", (val) => {
        this.$refs.jsMind.jm.select_clear();
      });
      vmson.$on("go_back", (val) => {
        var num = this.backList.length;
        if (num == 0) {
          console.log("error");
          return;
        }
        if (num == 1) {
          Message({
            message: this.$t("addMindMap.message13"),
            type: "info",
            duration: 1 * 1000,
          });
          return;
        } else {
          var num = this.backList.length;
          let cur = this.backList[num - 2];
          this.$store.commit("SET_EXIST_MINDMAP", cur);
          this.current_node_id_List = [];
          this.current_node_List = [];
          this.current_node_key_List = [];
          this.$refs.jsMind.jm.show(cur);
          this.goList.push(this.backList[num - 1]);
          this.backList.pop();
        }
      });
      vmson.$on("go_on", (val) => {
        let num = this.goList.length;
        if (num == 0) {
          Message({
            message: this.$t("addMindMap.message14"),
            type: "info",
            duration: 5 * 1000,
          });
        } else {
          this.backList.push(this.goList[num - 1]);
          let cur = this.goList[num - 1];
          this.current_node_id_List = [];
          this.current_node_List = [];
          this.current_node_key_List = [];
          this.$refs.jsMind.jm.show(cur);
          this.goList.pop();
        }
      });
      vmson.$on("set_screen", (val) => {
        this.mind = val;
        this.$store.commit("SET_EXIST_MINDMAP", val);
        this.jm.show(this.mind);
        this.$refs.jsMind.jm.show(this.mind);
        this.current_node_id_List = [];
        this.current_node_List = [];
        this.current_node_key_List = [];
      });
      // 监听导出思维导图事件
      vmson.$on("export_mindmap", (val) => {
        export_mindMap(
          this.$store.getters.email,
          this.$store.getters.type,
          this.$refs.jsMind.jm.get_data("node_array")
        ).then((res) => {
          let data = res;
          const url = window.URL.createObjectURL(
            new Blob([data], { type: val })
          );
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute(
            "download",
            this.$store.getters.mindmap_name + this.$store.getters.typed
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          Message({
            message: this.$t("addMindMap.message15"),
            type: "success",
            duration: 5 * 1000,
          });
        });
      });
      // 监听设为模板事件
      vmson.$on("set_template", (val) => {
        // 模板名称输入弹窗
        this.$prompt(
          this.$t("addMindMap.place1"),
          this.$t("addMindMap.title1"),
          {
            confirmButtonText: this.$t("btn.confirmBtn"),
            cancelButtonText: this.$t("btn.cancelBtn"),
          }
        )
          .then(({ value }) => {
            let newTemplate = this.jm.get_data("node_array");
            // 模板中的节点名称设为XXX 模板无评审结果 保留模板属性名但属性值为空
            newTemplate.data.forEach((row) => {
              row.topic = "XXX";
              delete row.reviews;
              if (
                row.content.length >= 1 &&
                typeof row.content !== "undefined"
              ) {
                row.content.forEach((property) => {
                  property.propertyVal = "";
                });
              }
            });
            // 将模板数据传递给后端保存 value为模板名称
            this.set_template(this.$store.getters.email, newTemplate, value);
          })
          .catch((err) => {
            console.log(err);
          });
      });

      // 监听导图刷新
      vmson.$on("mindmap_update", () => {
        this.current_node_id_List = [];
        this.current_node_List = [];
        this.current_node_key_List = [];
        this.jm.show(this.$store.getters.exist_mindmap);
      });

      // this.focusNode(this.jm);
      if (Object.keys(this.$route.query).indexOf("process_review") > -1) {
        get_file_review(this.get_pid(), _this.fileId).then((ress) => {
          _this.review_data = ress;
          _this.process_review();
        });
        get_review_config(this.get_pid()).then((res) => {
          res.forEach((item) => {
            item.fileTypeList.forEach((type) => {
              if (type.fileTypeId == _this.fileTypeId) {
                _this.review_list = item.itemList;
              }
            });
          });
        });
        _this.is_process_review = true;
        _this.is_show_no_review_nodes = false;
      }
      if (this.nodeclass) {
        this.select();
      }
    },
    getFile() {
      return getFile(this.get_pid(), this.$route.params.file_key);
    },
    getReleaseElements() {
      return getReleaseElements(this.get_pid(), this.$route.params.file_key);
    },
    //初始化
    initMap(type) {
      this.$store.commit("SET_RR", false);
      this.$store.commit("SET_LR", false);
      // // 初始化当前时间
      this.$store.commit("SET_CURRENT_TIME", new Date());
      // 初始化当前思维导图
      this.mind = this.$store.getters.exist_mindmap;
      if (this.$store.getters.open_state !== "exist") {
        // 获取当前思维导图
        // this.mind = this.$store.getters.mindmaps[this.$route.params.mindMapId];
      } else {
        //页面是从数据库中读取出来的已有文件
        const permission = this.$store.getters.permission;
        // 根据permission判定思维导图编辑/评审权限
        if (permission !== "owner") {
          this.isOwner = true;
        }
        if (permission !== "owner" && permission !== "edit") {
          //非创建者与非编辑者
          this.options.editable = true;
        }
        if (permission === "review") {
          this.canReview = true;
        }
      }
      if (this.backList.length == 0) {
        this.backList.push(this.mind);
      }
      // 初始化
      this.mounted_next(type);
    },
    // 画布加载时判断是否聚焦node
    focusNode(jm) {
      if (!this.$route.query.id) return;
      const node = jm.get_node(this.$route.query.id);
      this.last_node = node;
      node._data.view.element.id = "custom_style";
    },
    // 创建轮询定时器
    polling() {
      const self = this;
      const get_file = getFile(this.get_pid(), this.$route.params.file_key);
      const get_release = this.getReleaseElements();
      let res = {};
      Promise.all([get_file, get_release]).then((result) => {
        result[0].data.forEach((item) => {
          result[1].forEach((i) => {
            if (i.nodeKey === item.key) {
              if (i.sprintId) {
                item["sprintId"] = i.sprintId;
              }
              i.baselineItemDtoList &&
                i.baselineItemDtoList.forEach((baseline) => {
                  if (baseline.lock && baseline.status === "ALREADY_STARTED") {
                    item["lock"] = baseline.lock;
                  }
                });
            }
          });
        });
        res = result[0];
        if (this.notify) {
          this.notify.close();
        }
        this.notify = this.$notify({
          title: this.$t("addMindMap.title2"),
          type: "warning",
          dangerouslyUseHTMLString: true,
          message: `<span style="text-align: left;
    display: inline-block;">${this.$t(
      "addMindMap.message16"
    )}<span style="color: blue; cursor: pointer"> ${this.$t(
            "addMindMap.btn3"
          )}</span></span>`,
          duration: 0,
          onClick: function () {
            if (res !== undefined) {
              self.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
              //跳转到思维导图
              self.$router.go(0);
            }
          },
        });
      });
      // getFile(this.get_pid(), this.$route.params.file_key).then((res) => {
      //   if (this.notify) {
      //     this.notify.close();
      //   }
      //   this.notify = this.$notify({
      //     title: this.$t("addMindMap.title2"),
      //     type: "warning",
      //     dangerouslyUseHTMLString: true,
      //     message: `<span>${this.$t(
      //       "addMindMap.message16"
      //     )}<span style="color: blue; cursor: pointer">${this.$t(
      //       "addMindMap.btn3"
      //     )}</span></span>`,
      //     duration: 0,
      //     onClick: function () {
      //       if (res !== undefined) {
      //         self.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
      //         //跳转到思维导图
      //         self.$router.go(0);
      //       }
      //     },
      //   });
      // });
    },
    // 批量选择
    batchOperation(node) {
      if (this.last_node && this.last_node.id) {
        this.jm.get_node(this.last_node.id)._data.view.element.id = "";
        this.jm
          .get_node(this.last_node.id)
          ._data.view.element.classList.remove("selected");
      }
      if (this.current_node_id_List.indexOf(node.id) !== -1) {
        this.current_node_key_List.splice(
          this.current_node_id_List.indexOf(node.id),
          1
        );
        // 存入选中节点信息便于后续操作
        this.current_node_List.splice(
          this.current_node_id_List.indexOf(node.id),
          1
        );
        this.current_node_id_List.splice(
          this.current_node_id_List.indexOf(node.id),
          1
        );
        this.jm.get_node(node.id)._data.view.element.id = "";
        this.jm
          .get_node(node.id)
          ._data.view.element.classList.remove("selected");
      } else {
        this.current_node_id_List.push(node.id);
        this.current_node_key_List.push(node.data.key);
        this.current_node_List.push(node);
        if (this.current_node_id_List.length) {
          this.current_node_id_List.forEach((item) => {
            this.jm.get_node(item)._data.view.element.id = "custom_style";
          });
        }
      }
      if (this.current_node_id_List.length > 1) {
        this.last_node = {};
        this.last_node.id = null;
        vmson.$emit("get_fvalue", false);
        this.isfvalue = false;
        this.popover_visible = false;
      } else {
        vmson.$emit("get_fvalue", true);
        this.isfvalue = true;
      }
    },
    clear_all_checkced_style(node) {
      let jmnodes = document.querySelectorAll("jmnode");
      jmnodes.forEach((jmnode) => {
        if (jmnode.id == "custom_style") {
          jmnode.removeAttribute("id");
        }
      });
      this.current_node_id_List = [];
      this.current_node_List = [];
      this.current_node_key_List = [];
      if (node) {
        this.jm.get_node(node.id)._data.view.element.id = "custom_style";
        this.last_node = node;
      }
    },
    clickChangeSize(flag) {
      const view = this.jm.view;
      const innerDom = document.getElementById("topbar_can");
      let zoom;
      if (flag) {
        if (view.actualZoom >= view.maxZoom) {
          return false;
        }
        view.zoomIn();

        zoom = view.actualZoom + view.zoomStep;
      } else {
        if (view.actualZoom <= view.minZoom) {
          return false;
        }
        view.zoomOut();

        zoom = view.actualZoom - view.zoomStep;
      }
      // view.actualZoom = zoom;
      this.zoom_ = zoom;

      // innerDom.style.transform = "scale(" + zoom + ")";
    },

    onMouseWheel(event) {
      if (event.ctrlKey) {
        event.preventDefault();
        const view = this.jm.view;
        // const innerDom = document.getElementById("topbar_can");
        let zoom;
        if (-event.wheelDelta < 0 || event.detail < 0) {
          if (view.actualZoom >= view.maxZoom) {
            return false;
          }
          zoom = view.actualZoom + view.zoomStep;
          view.zoomIn();
          this.$nextTick(() => {
            vmson.$emit("jmshow", "***");
          });
        } else {
          if (view.actualZoom <= view.minZoom) {
            return false;
          }
          view.zoomOut();

          zoom = view.actualZoom - view.zoomStep;
          this.$nextTick(() => {
            vmson.$emit("jmshow", "***");
          });
        }
        // view.actualZoom = zoom;
        this.zoom_ = zoom;
        // innerDom.style.transform = "scale(" + zoom + ")";
      }
    },
    // 覆盖度
    getIgnoreNode() {
      const params = {
        projectId: this.get_pid(),
      };
      getNodeCoverage(params).then((res) => {
        this.ignoreCoverageList = res;
        this.$emit("ignoreList", this.ignoreCoverageList);
        // list可能会被GET返回的值设为空
        if (!this.ignoreCoverageList) {
          this.ignoreCoverageList = [];
        }
      });
    },
    nodeCoverage(isIgnore) {
      const func = isIgnore ? ignoreNodeCoverage : deleteNodeCoverage;
      const nodeKeys = [...this.current_node_key_List];
      if (this.current_node_key) nodeKeys.push(this.current_node_key);
      if (nodeKeys.length == 0) {
        this.$message({
          message: this.$t("canvas.AI.tip5"),
          type: "warning",
        });
      } else {
        // Filtering the node keys based on whether they should be ignored or not
        const filteredNodeKeys = nodeKeys.filter((nodeKey) =>
          isIgnore
            ? !this.ignoreCoverageList.includes(nodeKey)
            : this.ignoreCoverageList.includes(nodeKey)
        );

        if (filteredNodeKeys.length > 0) {
          const params = {
            projectId: this.get_pid(),
            body: filteredNodeKeys,
          };

          func(params).then((res) => {
            this.$message({
              message: this.$t("node.message.operationSuccess"),
              type: "success",
            });
            // update ignoreCoverageList
            if (isIgnore) {
              this.ignoreCoverageList.push(...filteredNodeKeys);
            } else {
              this.ignoreCoverageList = this.ignoreCoverageList.filter(
                (nodeKey) => !filteredNodeKeys.includes(nodeKey)
              );
            }
            this.$emit("ignoreList", this.ignoreCoverageList);
          });
        } else {
          const message = isIgnore
            ? this.$t("node.message.nodeNotCovered")
            : this.$t("node.message.nodeCovered");
          this.$message({
            message,
            type: "warning",
          });
        }
      }
    },

    //AI自动补充
    nodebuchong() {
      aiAutoExtendPayFeatureStatus().then((res) => {
        if (res) {
          let mynode = this.jm.get_node(this.current_node_id);
          if (mynode) {
            this.loading = this.$loading({
              target: "#mindmap-loading",
              lock: true,
              text: this.$t("canvas.AI.tip1"),
              spinner: "ai-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            nodeAutoExtend({
              projectId: this.get_pid(),
              data: {
                fileKey: this.$route.params.file_key,
                nodeKey: mynode.data.key,
              },
            })
              .then((status) => {
                const get_file = this.getFile(
                  this.get_pid(),
                  this.$route.params.file_key
                );
                const get_release = this.getReleaseElements();
                let res = {};
                Promise.all([get_file, get_release]).then((result) => {
                  result[0].data.forEach((item) => {
                    result[1].forEach((i) => {
                      if (i.nodeKey === item.key) {
                        if (i.sprintId) {
                          item["sprintId"] = i.sprintId;
                        }
                        i.baselineItemDtoList &&
                          i.baselineItemDtoList.forEach((baseline) => {
                            if (
                              baseline.lock &&
                              baseline.status === "ALREADY_STARTED"
                            ) {
                              item["lock"] = baseline.lock;
                            }
                          });
                      }
                    });
                  });
                  res = result[0];
                  this.$store.commit("SET_EXIST_MINDMAP", res);
                  this.current_node_id_List = [];
                  this.current_node_List = [];
                  this.current_node_key_List = [];
                  this.jm.show(this.$store.getters.exist_mindmap);
                  this.current_node_id = null;
                  vmson.$emit("jmshow", res);
                  this.loading.close();
                  if (
                    !(
                      sessionStorage.getItem("wordviewtip") ||
                      localStorage.getItem("wordviewtip")
                    )
                  ) {
                    setTimeout(() => {
                      let driver = null;
                      if (window.Driver) {
                        driver = new window.Driver();
                      } else {
                        driver = new Driver();
                      }
                      driver.highlight({
                        element: "#topbar-view",
                        popover: {
                          title: this.$t(
                            "canvas.fileDropDown.deleteForm.title"
                          ),
                          description: this.$t("canvas.word"),
                          position: "bottom-left",
                          className: "istip",
                          closeBtnText: this.$t("btn.closeBtn"),
                          nextBtnText: this.$t("btn.next"), // Next button text for this step 下一步按钮的文字
                          prevBtnText: this.$t("btn.prev"), // Previous button text for this step 上一步按钮文字
                        },
                      });
                    }, 1000);
                  }
                });
              })
              .catch((err) => {
                this.$message({
                  type: "warning",
                  message: this.$t("canvas.AI.tip3"),
                });
                this.loading.close();
              });
          } else {
            if (this.current_node_id_List.length) {
              this.$message({
                type: "warning",
                message: this.$t("canvas.AI.tip4"),
              });
            } else {
              this.$message({
                type: "warning",
                message: this.$t("canvas.AI.tip5"),
              });
            }
          }
        } else {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=ai" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        }
      });
    },
    async Add_node(key) {
      if (this.jobAuthorities.indexOf("NODE_CREATE") === -1) {
        this.$message({
          type: "warning",
          message: this.$t("tip"),
        });
        return;
      }
      let mynode = this.jm.get_node(this.current_node_id);
      let parentid = this.current_node_id;
      let upNodeKey = null;
      if (key == "Enter") {
        parentid = mynode.parent.id;
        upNodeKey = mynode.data.key;
      }
      let newNode = {
        fileKey: this.$route.params.file_key,
        parentid: parentid,
        dataList: [
          {
            name: "New Node",
          },
        ],
      };
      if (upNodeKey) {
        newNode.upNodeKey = upNodeKey;
      }
      add_node(this.get_pid(), newNode).then((r) => {
        const get_file = this.getFile(
          this.get_pid(),
          this.$route.params.file_key
        );
        const get_release = this.getReleaseElements();
        let res = {};
        Promise.all([get_file, get_release]).then((result) => {
          result[0].data.forEach((item) => {
            result[1].forEach((i) => {
              if (i.nodeKey === item.key) {
                if (i.sprintId) {
                  item["sprintId"] = i.sprintId;
                }
                i.baselineItemDtoList &&
                  i.baselineItemDtoList.forEach((baseline) => {
                    if (
                      baseline.lock &&
                      baseline.status === "ALREADY_STARTED"
                    ) {
                      item["lock"] = baseline.lock;
                    }
                  });
              }
            });
          });
          res = result[0];
          this.$store.commit("SET_EXIST_MINDMAP", res);
          this.current_node_id_List = [];
          this.current_node_List = [];
          this.current_node_key_List = [];
          this.jm.show(this.$store.getters.exist_mindmap);
          this.current_node_id = r[0].id;
          this.jm.select_node(r[0].id);
          this.jm.begin_edit(r[0].id);
          vmson.$emit("jmshow", res);
        });
      });
    },
    Delete_node() {
      delete_node(this.get_pid(), this.current_node_key).then((r) => {
        this.jm.remove_node(this.current_node_id);

        const get_file = this.getFile(
          this.get_pid(),
          this.$route.params.file_key
        );
        const get_release = this.getReleaseElements();
        let res = {};
        Promise.all([get_file, get_release]).then((result) => {
          result[0].data.forEach((item) => {
            result[1].forEach((i) => {
              if (i.nodeKey === item.key) {
                if (i.sprintId) {
                  item["sprintId"] = i.sprintId;
                }
                i.baselineItemDtoList &&
                  i.baselineItemDtoList.forEach((baseline) => {
                    if (
                      baseline.lock &&
                      baseline.status === "ALREADY_STARTED"
                    ) {
                      item["lock"] = baseline.lock;
                    }
                  });
              }
            });
          });
          res = result[0];
          this.$store.commit("SET_EXIST_MINDMAP", res);
          vmson.$emit("jmshow", "***");
          this.$message({
            message: this.$t("addMindMap.message17"),
            type: "success",
          });
        });
      });
      this.rootVisible = false;
      this.nodeVisible = false;
      this.childVisible = false;
      this.caseone = false;
    },
    delete_dialog() {
      let node = this.jm.get_node(this.current_node_id);
      if (node.isroot == true) {
        this.rootVisible = true;
      } else {
        if (node.children.length != 0) {
          this.nodeVisible = true;
        } else {
          if (!this.relation_dialog) {
            this.childVisible = true;
          }
        }
      }
    },
    async Edit_node(node) {
      if (this.jobAuthorities.indexOf("NODE_EDIT") === -1) {
        this.$message({
          type: "warning",
          message: this.$t("tip"),
        });
        this.jm.show(this.$store.getters.exist_mindmap);
        return;
      }
      const nodeInfo = await get_node_use_node_key(
        this.get_pid(),
        node.data.key
      );
      let obj = nodeInfo;
      obj["id"] = node.id;
      obj["expanded"] = node.expanded;
      obj["isroot"] = node.isroot;
      if (node.isroot == false) {
        obj["parentid"] = node.parent.id;
      }

      obj["topic"] = node.topic;
      const params = {
        projectId: this.get_pid(),
        nodeKey: nodeInfo.key,
        data: {
          isCustom: false,
          fieldId: "topic",
          value: node.topic,
        },
      };
      only_set_property(params).then((r) => {});
    },
    // 判断节点数组nodes是否存在节点node
    isContain(node, nodes) {
      for (let i = 0; i < nodes.length; ++i) {
        if (node.id === nodes[i].id) {
          return true;
        }
      }
      return false;
    },
    // 设置模板方法 调用封装好的api向后端发送请求 参数为email 模板内容 模板名
    set_template(email, content, name) {
      set_to_template(email, content, name).then((res) => {
        if (res.flag === 1) {
          Message({
            message: this.$t("addMindMap.message18"),
            type: "success",
            duration: 5 * 1000,
          });
        } else {
          Message({
            message: this.$t("addMindMap.message19"),
            type: "error",
            duration: 5 * 1000,
          });
        }
      });
    },
    // 深复制对象
    deepCopy(data, hash = new WeakMap()) {
      if (typeof data !== "object" || data === null) {
        throw new TypeError(this.$t("addMindMap.message20"));
      }
      // 判断传入的待拷贝对象的引用是否存在于hash中
      if (hash.has(data)) {
        return hash.get(data);
      }
      let newData = {};
      const dataKeys = Object.keys(data);
      dataKeys.forEach((value) => {
        const currentDataValue = data[value];
        // 基本数据类型的值和函数直接赋值拷贝
        if (typeof currentDataValue !== "object" || currentDataValue === null) {
          newData[value] = currentDataValue;
        } else if (Array.isArray(currentDataValue)) {
          // 实现数组的深拷贝
          newData[value] = [...currentDataValue];
        } else if (currentDataValue instanceof Set) {
          // 实现set数据的深拷贝
          newData[value] = new Set([...currentDataValue]);
        } else if (currentDataValue instanceof Map) {
          // 实现map数据的深拷贝
          newData[value] = new Map([...currentDataValue]);
        } else {
          // 将这个待拷贝对象的引用存于hash中
          hash.set(data, data);
          // 普通对象则递归赋值
          newData[value] = this.deepCopy(currentDataValue, hash);
        }
      });
      return newData;
    },
    // 帮助组件的点击事件处理方法
    handleClick(command) {
      // help_key指令显示快捷键说明
      if (command === "help_key") {
        this.help_key = true;
      }
      // feedback指令反馈（未实现）
      else if (command === "feedback") {
      }
    },
    scrollHandler(e) {
      try {
        this.$refs.jsMind ? this.$refs.jsMind.jm.select_clear() : "";
        if (!this.isReviewer) {
          if (this.$store.getters.review_res_flag) {
            this.$store.commit("SET_RR", false);
            this.$store.commit("SET_LR", false);
            vmson.$emit("review", this.$store.getters.review_res_flag);
            this.$store.commit("SET_RR", true);
          } else {
            this.$store.commit("SET_RR", false);
            this.$store.commit("SET_LR", false);
          }
          vmson.$emit("review", this.$store.getters.review_res_flag);
        }
      } catch {}
    },
    showRelationDialog(node_key) {
      this.cur_node_key = node_key;
      this.relation_dialog = true;
      this.popover_visible = false;
    },
    closeRelation() {
      this.relation_dialog = false;
    },
    reloadPopover() {
      this.popover_visible = false;
    },
    // loading_end(e){
    //   this.set_popover_position()
    // },
    // 设置浮窗显示位置
    set_popover_position() {
      let mouse_h = this.mouse_h;
      let mouse_w = this.mouse_w;
      let element = this.target;

      let window_height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      let window_width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      let half_window_h = window_height * 0.5;
      let half_window_w = window_width * 0.5;

      let pover_w = document.getElementsByClassName("nodePopover")[0]
        ? document.getElementsByClassName("nodePopover")[0].clientWidth
        : 0;
      let pover_h = document.getElementsByClassName("nodePopover")[0]
        ? document.getElementsByClassName("nodePopover")[0].clientHeight
        : 0;

      if (mouse_h < half_window_h && mouse_w < half_window_w) {
        this.$refs.nodePopover.$el.style.top =
          element.getBoundingClientRect().top + "px" + "/*no*/";
        this.$refs.nodePopover.$el.style.left =
          element.getBoundingClientRect().right + "px" + "/*no*/";
      }
      if (mouse_h >= half_window_h && mouse_w < half_window_w) {
        this.$refs.nodePopover.$el.style.top =
          element.getBoundingClientRect().bottom - pover_h + "px" + "/*no*/";
        this.$refs.nodePopover.$el.style.left =
          element.getBoundingClientRect().right + "px" + "/*no*/";
      }
      if (mouse_h < half_window_h && mouse_w >= half_window_w) {
        this.$refs.nodePopover.$el.style.top =
          element.getBoundingClientRect().top + "px" + "/*no*/";
        this.$refs.nodePopover.$el.style.left =
          element.getBoundingClientRect().left - pover_w + "px" + "/*no*/";
      }
      if (mouse_h >= half_window_h && mouse_w >= half_window_w) {
        this.$refs.nodePopover.$el.style.top =
          element.getBoundingClientRect().bottom - pover_h + "px" + "/*no*/";
        this.$refs.nodePopover.$el.style.left =
          element.getBoundingClientRect().left - pover_w + "px" + "/*no*/";
      }

      this.loading_e = false;
    },
    closeHandler(form) {
      this.$refs[form].resetFields();
    },
    fuzzyBugNodes(value) {
      this.bugNodesFuzzyKey = value;
      this.getBugNodes();
    },
    getBugNodes() {
      this.bugLoading = true;
      getBugNodes(this.get_pid(), this.bugNodesFuzzyKey).then((res) => {
        this.bugNodesList = res;
        if (Array.isArray(this.bugNodesList)) {
          this.bugNodesListShow = this.bugNodesList.filter((item) => {
            return this.currentRowBugList.indexOf(item.nodeKey) == -1;
          });
        }
        this.bugLoading = false;
      });
    },
    newBugFile() {
      this.is_dialog_visible = true;
    },
    closeAddBugNodes() {
      this.ownVisible = false;
    },
    confirmAddBugNodes() {
      this.$refs["ownRuleForm"].validate((valid) => {
        if (valid) {
          this.ownRuleForm.list.forEach((item) => {
            let data = {
              projectId: this.get_pid(),
              testPlanId: this.ruleForm.test_plan,
              testcase: this.ruleForm.test_case,
              bugProjectId: item.split(":")[1],
              bug: item.split(":")[0],
            };
            postTestcaseBugRelations(data).then((res) => {
              this.ownVisible = false;
              this.$refs["ownRuleForm"].resetFields();
            });
          });
        } else {
          return false;
        }
      });
    },
    changeSelectBugNodes(flag) {},
  },
};
</script>

<style lang="scss">
#custom_style[hasfile="true"] {
  & > i {
    color: #000;
    &:hover {
      cursor: pointer;
    }
  }
}
[hasfile="true"] {
  padding-right: 26px;
  & > i {
    position: absolute;
    right: 6px;
    bottom: 8px;
    color: #fff;
    &:hover {
      cursor: pointer;
    }
  }
}
.show_no_review {
  background-color: rgba(255, 141, 26, 1) !important;
}
#node_add_button {
  transition: none;
  position: absolute;
  z-index: 8;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(66, 139, 202);
  border: none !important;
  .el-icon-plus {
    font-size: 18px;
    font-weight: 700 !important;
  }
}
.reject {
  .el-dialog__body {
    padding-bottom: 10px;
  }
}

.review {
  .el-form-item__content {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }

  .el-form-item__label {
    text-align: left;
  }

  .star {
    label::after {
      content: "*";
      color: red;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor {
    width: 100%;
  }
}

.tpc .el-time-spinner__wrapper {
  width: 100% !important;
}

.tpc .el-scrollbar:nth-of-type(2) {
  display: none !important;
}

.el-main {
  overflow: hidden;
}

js-mind {
  overflow: hidden;
}

.resizeFrame {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.js_m_box {
  width: 98.5vw;
  height: calc(90vh - 98px);
  /*no*/
  margin-top: 100px;
  /*no*/
  position: relative;
  overflow-y: scroll;
  overflow-x: scroll;
}
.element .dfmea_model {
  width: 70%;
  height: calc(90vh - 500px);
}
jmnode {
  position: absolute;
  cursor: default;
  width: fit-content;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 400px;
  z-index: 5;
}

jmnodes {
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
  min-width: 800px;
  left: 0;
  ::-webkit-scrollbar-track {
    background: rgb(50, 118, 177);
  }
}

.under_canvas {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.saving-state {
  position: fixed;
  top: 150px;
  z-index: 999;
  display: none;
}

.jsmind-inner {
  // position: fixed;
  // top: -50px;
  // left: -650px;
  // width: 100%;
  width: fit-content;
  // height: 100%;
  overflow: hidden;
  //  overflow-x:scroll
  // transform-origin: center;
}

.topbar_can {
  width: 100%;
  margin: 0 auto;
}

.dialog_on {
  width: 550px;
  height: 400px;
  background-color: rgba(240, 242, 245, 1);
  border-radius: 0px 8px 8px 0px;
  position: fixed;
  left: 1px;
  top: 300px;
  z-index: 9;

  .head {
    color: rgba(48, 100, 143, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;

    i {
      padding: 2px;
      margin-right: 10px;
      margin-left: 20px;
      background-color: rgba(229, 229, 229, 1);
      color: rgba(48, 100, 143, 1);
      cursor: pointer;
    }
  }
  .bo {
    height: 320px;
    overflow: auto;
    background-color: #fff;
    border-radius: 5px;
    margin-left: 20px;
    margin-right: 20px;

    .hang {
      border-bottom: 1px solid rgb(184, 184, 186);
      display: grid;
      grid-template-columns: 10% 90%;
      align-items: center;
      .mover {
        height: 100%;
        display: flex;
        align-items: center;
      }
      .index {
        min-width: 40px;
        font-size: 18px;
        color: rgba(110, 110, 110, 1);
      }

      .ne {
        border-left: 1px solid rgba(240, 242, 245, 1);

        width: 100%;
        font-size: 14px;
        color: rgba(110, 110, 110, 1);

        .t {
          padding-left: 15px;
          border-bottom: 1px solid rgba(240, 242, 245, 1);
          text-align: start;
        }

        .b {
          padding-left: 15px;
          text-align: start;
        }
      }
    }
  }
}

.dialog_to {
  background-color: rgba(240, 242, 245, 1);
  border-radius: 0px 5px 5px 0px;
  padding: 15px 0 10px 0;
  position: fixed;
  left: 1px;
  top: 300px;
  z-index: 999;

  .dialog_to_box {
    display: flex;
    align-items: center;
    color: rgba(48, 100, 143, 1);

    p {
      margin-left: 5px;
      margin-right: 5px;
      font-size: 18px;
      writing-mode: vertical-lr;
      letter-spacing: 4px;
    }
  }
}

/*转为word按钮样式 */
.word_to {
  position: fixed;
  left: 10px;
  top: 300px;
  z-index: 999;
}

.word_to_box {
  width: 40px;
  height: 55px;
  background-color: rgb(252, 252, 252);
  text-align: center;
  line-height: 55px;
  box-shadow: 0 0 2px gray;
  font-size: 22px;
  color: rgb(91, 91, 91);
}

/**切换word提示框 */
.tab_text {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 80px;
  height: 20px;
  font-size: 10px;
  background-color: #fffdb8;
  line-height: 20px;
  transition: left 1s;
  box-shadow: 0 0 1px gray;
  opacity: 0;
}

.word_to_box:hover .tab_text {
  left: 40px;
  width: 80px;
  opacity: 1;
}

i:hover {
  color: #60a3d9;
}

.keys {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 2px 4px;
  margin: 0px 3px;
}

.hover_style {
  z-index: 7;
  position: absolute;
  display: none;
  width: 400px;

  border-style: solid;
  transition: left 0.4s cubic-bezier(0.23, 1, 0.32, 1),
    top 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  background-color: rgba(229, 243, 255, 0.85);
  border-width: 0px;
  border-color: #333333;
  border-radius: 4px;
  color: #000000;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 14px;
  font-family: "Microsoft YaHei";
  line-height: 21px;
  padding: 10px 10px;
}

#process_review {
  border-radius: 20px;
  max-height: 500px;
  overflow: auto;

  .process_review_item:last-child {
    border-bottom: 1px solid rgba(229, 229, 229, 1) !important;
  }

  .process_review_item {
    border-top: 1px solid rgba(229, 229, 229, 1) !important;
  }

  // }{
  //     border: 1px solid black !important;
  //   }
  .el-card__body {
    padding: 0;
  }

  background-color: rgba(245, 245, 245);
  width: 380px;
  position: absolute;
  top: 200px;
  right: 50px;
  z-index: 99;
}

.hide_radio_label {
  .el-radio__label {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.reviewer_list {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  align-items: center;
}

.reviewer {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  background: rgba(229, 229, 229, 1);
  padding: 0 6px;
  margin: 4px;
  border-radius: 4px;
}

/**状态窗口 */
.state_window {
  width: 260px;
  position: absolute;
  top: 130px;
  right: 50px;
  font-size: 18px;
  color: #606060;
}

.state_window_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.state_window_header .close:hover {
  cursor: pointer;
}

.state_window_item {
  display: flex;
  align-items: center;
  justify-content: start;

  .name {
    // width: 180px;
    text-align: left;
    // 单行显示省略号
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .legend {
    width: 40px;
    display: flex;
    align-items: center;
  }

  .legend1 {
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
    align-items: center;

    p {
      margin: 5px 0;
    }
  }
}

.user_select {
  .el-select-dropdown__item {
    display: flex;
    align-items: center;

    .avatar {
      display: flex;
    }

    .select_item {
      margin-left: 14px;
    }
  }
}

.heng {
  padding-right: 5px;

  p {
    margin: 0;
  }

  display: grid;
  grid-template-columns: 20% 80%;

  word-wrap: break-word;
  margin-bottom: 15px;
  margin-top: 15px;
}
.review_detail_dialog {
  .pingshen_reason {
    margin-bottom: 15px;

    padding: 5px;
    text-align: start;
    width: 100%;
    height: 200px;
    overflow-y: auto;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid rgb(128, 128, 128);
  }
  .avatar {
    display: flex;
    overflow-y: hidden;
    width: 100%;
    flex-wrap: wrap;
  }
  .pingshen {
    display: grid;
    grid-template-columns: 80px 50px auto 25px;
    overflow-y: hidden;
    align-items: center;
    justify-items: start;
    font-size: 16px;
    margin-bottom: 20px;
    height: 30px;

    .num-green {
      color: rgb(79, 176, 118);
      width: 100%;
    }
    .btn-green {
      height: 30px;

      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: rgb(79, 176, 118);
      width: 100%;
      padding: 5px;
      border: 1px solid rgb(79, 176, 118);
      border-radius: 5px;
    }
    .num-red {
      color: rgb(255, 87, 51);
      width: 100%;
    }
    .btn-red {
      height: 30px;

      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: rgb(255, 87, 51);
      width: 100%;
      padding: 5px;
      border: 1px solid rgb(255, 87, 51);
      border-radius: 5px;
    }
  }
}
.review_detail_dialog {
  pointer-events: none;
}
.relationOwnBug .el-select-dropdown__list li:nth-child(1) {
  background-color: rgba(77, 160, 255, 0.35);
  border-radius: 6px 6px 0px 0px;
}

.seacrh-wrap {
  position: absolute;
  right: 75px;
  top: 255px;
  z-index: 1999;
  .seacrh-button {
    z-index: 1999;
    position: absolute;
    cursor: pointer;
    display: flex;
    background-color: rgba(24, 79, 169, 1);
    align-items: center;
    color: white;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: none;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .seacrh-dialog {
    z-index: 1998;

    transition: all 0.2s ease; /* 可选的，用于更平滑的过渡 */
    width: 400px;
    font-size: 25px;
    height: 50px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 0;
    left: -450px;
    padding: 10px 5px;
    border-radius: 10px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #dddddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    i {
      cursor: pointer;

      margin: 0 5px;
    }
  }
}

#seacrh-dialog-search_input:focus {
  outline: none;
  border: none; /* 自定义边框颜色和宽度 */
}

.tongguo_check {
  width: 15px;
  height: 15px;
  border: 2px solid rgb(67, 207, 124);
  color: white;
  border-radius: 50%;
  cursor: pointer;
}

.butongguo_check {
  width: 15px;
  height: 15px;
  color: white;
  border: 2px solid rgb(255, 87, 51);
  border-radius: 50%;
  cursor: pointer;
}
</style>

<style>
.serach_mindmap_node_highlight {
  color: yellow !important;
}
.serach_mindmap_node_highlight_selected {
  background: #f1c40f !important;
  color: white !important;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-enter-to,
.slide-fade-leave {
  transform: translateX(0);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
}
.review_detail_dialog_body {
  pointer-events: auto !important;
  top: 50px;
  left: 50px;
  position: absolute;
}
</style>
