import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 查询节点下的功能
export function getDfmeaFunctions(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/dfmea/functions`,
    method: 'get'
  })
}
// 节点下添加功能
export function addDfmeaFunctions(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/dfmea/functions`,
    method: 'post',
    data: params.data
  })
}
// 编辑功能
export function editDfmeaFunctions(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/dfmea/functions/${params.functionId}`,
    method: 'put',
    data: params.data
  })
}
// 删除功能
export function deleteDfmeaFunctions(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/dfmea/functions/${params.functionId}`,
    method: 'delete',
  })
}
// 查询功能下的失效
export function getDfmeaFailures(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/dfmea/functions/${params.functionId}/failures`,
    method: 'get'
  })
}
// 添加功能下的失效
export function addDfmeaFailures(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/dfmea/functions/${params.functionId}/failures`,
    method: 'post',
    data: params.data
  })
}
// 编辑功能下的失效
export function editDfmeaFailures(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/dfmea/failures/${params.failureId}`,
    method: 'put',
    data: params.data
  })
}
// 删除失效
export function deleteDfmeaFailures(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/dfmea/failures/${params.failuresId}`,
    method: 'delete',
  })
}
// 获取dfmea的关联关系详情
export function getRelations(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/file/${params.fileKey}/dfmea/relations`,
    method: 'get',
  })
}
// 添加dfmea的关联关系详情
export function addRelations(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/file/${params.fileKey}/dfmea/relations`,
    method: 'post',
  })
}
// 删除dfmea的关联关系详情
export function deleteRelations(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/dfmea/relations/${params.relationId}`,
    method: 'delete',
  })
}
// 编辑dfmea的结构关联关系
export function editItemRelations(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/dfmea/relations/${params.relationId}/itemRelations`,
    method: 'put',
    data: params.data
  })
}
// 编辑dfmea的功能关联关系
export function editFunctionRelations(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/dfmea/relations/${params.relationId}/functionRelations`,
    method: 'put',
    data: params.data
  })
}
// 编辑dfmea的失效关联关系
export function editFailureRelationsRelations(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/dfmea/relations/${params.relationId}/failureRelations`,
    method: 'put',
    data: params.data
  })
}
// 获取结构树整体dfmea情况
export function getDistribution(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/file/${params.fileKey}/dfmea/distribution`,
    method: 'get'
  })
}
// 添加dfmea优化
export function addRiskAndOptimizations(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/dfmea/relations/${params.relationId}/riskAndOptimizations`,
    method: 'post',
    data: params.data
  })
}
// 编辑dfmea优化
export function editRiskAndOptimizations(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/dfmea/riskAndOptimizations/${params.riskAndOptimizationId}`,
    method: 'put',
    data: params.data
  })
}
// 删除dfmea优化
export function deleteRiskAndOptimizations(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/dfmea/riskAndOptimizations/${params.riskAndOptimizationId}`,
    method: 'delete'
  })
}
// 删除dfmea优化
export function dfmeaExcel(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/file/${params.fileKey}/dfmea/excel`,
    method: 'get'
  })
}
// 判断dfmea是否付费
export function effective(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/dfmeaFeature/effective`,
    method: 'get',
    params: params
  })
}
// S 值一键更正机制
export function feOptimized(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/file/${params.fileKey}/dfmea/feOptimized`,
    method: 'put'
  })
}