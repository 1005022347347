<template>
  <div class="test" style="padding-bottom: 40px; text-align: left">
    <el-button
      type="primary"
      plain
      :disabled="comparisonDisable"
      style="margin-bottom: 4px"
      @click="versionComparison"
      >{{ $t("node.version.VersionComparison") }}</el-button
    >
    <el-table
      :data="tableData"
      border
      style="90vw"
      class="nodeVersionTable"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" :selectable="canSelect">
      </el-table-column>
      <el-table-column
        prop="version"
        :label="$t('node.version.versionName')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <a
            href="javascript:void(0)"
            style="margin-right: 6px; text-decoration: underline; color: #06c"
            @click="showVersion(scope.row)"
            >{{ scope.row.archiveName }}</a
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        :label="$t('baseline.archive.remark')"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="createBy"
        :label="$t('node.table.creator')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ matchUserNickname(scope.row.createBy) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createAt"
        :label="$t('node.table.createdTime')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ new Date(scope.row.createAt).Format("yyyy-MM-dd hh:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('baseline.nodeTable.operation')" width="200">
        <template slot-scope="scope">
          <a
            href="javascript:void(0)"
            style="color: #06c"
            @click="revertVersion(scope.row)"
            >{{ $t("node.version.revertVersion") }}</a
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="$t('node.version.VersionDetails')"
      :visible.sync="dialogVisible"
      :width="singleVersion ? '30%' : '60%'"
      :modal="false"
    >
      <div class="version-detail-com">
        <div
          class="diff-wrap"
          :style="{
            backgroundColor: singleVersion
              ? '#F0F6FC'
              : 'rgba(255, 87, 51, 0.1)',
            borderColor: singleVersion ? 'rgba(0,0,0,0)' : '#ff5733',
          }"
        >
          <div v-if="!singleVersion" class="diff-wrap-title">
            {{ $t("node.version.Differencefield") }}
          </div>
          <div class="diff-wrap-content">
            <div
              :style="{
                width: singleVersion ? '100%' : '48%',
              }"
            >
              <div class="version-title">{{ version1Title }}</div>
              <div class="version-fields">
                <div class="version-value-key">
                  <span>{{ $t("node.version.field") }}</span>
                  <span>{{ $t("node.version.value") }}</span>
                </div>
                <div v-for="(item, index) in version1Diff" :key="index">
                  <span>{{ item.key }}</span>
                  <span v-if="item.type === 'String'">{{ item.value }}</span>
                  <span v-else-if="item.type === 'attachments'">
                    <a
                      v-for="(url, urlindex) in item.value"
                      :key="urlindex"
                      :href="config.oss_url + url.url"
                      style="color: #06c"
                    >
                      {{ url.name }}
                    </a>
                  </span>
                  <span v-else-if="item.type === 'description'">
                    <a
                      v-if="item.value"
                      href="javascript:void(0)"
                      style="color: #06c"
                      @click="showDes(item.value)"
                      >{{ $t("btn.view") }}</a
                    >
                  </span>
                  <span v-else-if="item.type === 'DRAWIO'">
                    <a
                      v-if="item.value && item.value !== '[]'"
                      href="javascript:void(0)"
                      style="color: #06c"
                      @click="showDrawio(item.value)"
                    >
                      {{ $t("btn.view") }}
                    </a>
                  </span>
                  <span v-else-if="item.type === 'baselineIdList'">
                    <span
                      v-for="(baseLineId, bIndex) in item.value"
                      :key="bIndex"
                    >
                      {{
                        baseLine[baseLineId] ? baseLine[baseLineId].name : ""
                      }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'ganttChartUuid'">
                    <span>
                      {{ getGanttName(item.value) }}
                    </span>
                  </span>
                  <span v-else-if="item.type === 'relatedNodeList'">
                    <a
                      v-for="(related, dIndex) in item.value"
                      style="color: #06c"
                      :key="dIndex"
                      :href="
                        '/' +
                        related.targetProjectId +
                        '/nodes/key/' +
                        related.targetNodeKey
                      "
                      target="_blank"
                      >{{ related.targetNodeKey }}
                      <span v-if="dIndex !== item.value.length - 1">,</span></a
                    >
                  </span>
                  <span v-else-if="item.type === 'sprintId'">
                    <span>
                      {{ sprint[item.value] ? sprint[item.value].name : "" }}
                    </span>
                  </span>
                  <span v-else-if="item.type === 'testPlanIdList'">
                    <span
                      v-for="(testPlanId, bIndex) in item.value"
                      :key="bIndex"
                    >
                      {{
                        testPlan[testPlanId] ? testPlan[testPlanId].name : ""
                      }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'versionIdList'">
                    <span
                      v-for="(versionId, bIndex) in item.value"
                      :key="bIndex"
                    >
                      {{ version[versionId] ? version[versionId].name : "" }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'TEXT'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'TEXTAREA'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'NUMBER'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'SELECT'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'SELECT_MULTI'">
                    {{ item.value ? item.value.join(",") : "" }}
                  </span>
                  <span v-else-if="item.type === 'SINGLE_USER_SELECT'">{{
                    matchUserNickname(item.value)
                  }}</span>
                  <span v-else-if="item.type === 'MULTI_USER_SELECT'">
                    <span v-for="(userId, bIndex) in item.value" :key="bIndex">
                      {{ matchUserNickname(userId) }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'DATE'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'LABEL'">
                    {{ item.value ? item.value.join(",") : "" }}
                  </span>
                  <span v-else-if="item.type === 'LINK'">
                    {{ item.value }}
                  </span>
                </div>
              </div>
            </div>
            <div v-if="!singleVersion">
              <div class="version-title">{{ version2Title }}</div>
              <div class="version-fields">
                <div class="version-value-key">
                  <span>{{ $t("node.version.field") }}</span>
                  <span>{{ $t("node.version.value") }}</span>
                </div>
                <div v-for="(item, index) in version2Diff" :key="index">
                  <span>{{ item.key }}</span>
                  <span v-if="item.type === 'String'">{{ item.value }}</span>
                  <span v-else-if="item.type === 'attachments'">
                    <a
                      v-for="(url, urlindex) in item.value"
                      style="color: #06c"
                      :key="urlindex"
                      :href="config.oss_url + url.url"
                    >
                      {{ url.name }}
                    </a>
                  </span>
                  <span v-else-if="item.type === 'description'">
                    <a
                      v-if="item.value"
                      style="color: #06c"
                      href="javascript:void(0)"
                      @click="showDes(item.value)"
                      >{{ $t("btn.view") }}</a
                    >
                  </span>
                  <span v-else-if="item.type === 'DRAWIO'">
                    <a
                      v-if="item.value && item.value !== '[]'"
                      style="color: #06c"
                      href="javascript:void(0)"
                      @click="showDrawio(item.value)"
                    >
                      {{ $t("btn.view") }}
                    </a>
                  </span>
                  <span v-else-if="item.type === 'baselineIdList'">
                    <span
                      v-for="(baseLineId, bIndex) in item.value"
                      :key="bIndex"
                    >
                      {{
                        baseLine[baseLineId] ? baseLine[baseLineId].name : ""
                      }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'ganttChartUuid'">
                    <span>
                      {{ getGanttName(item.value) }}
                    </span>
                  </span>
                  <span v-else-if="item.type === 'relatedNodeList'">
                    <a
                      v-for="(related, dIndex) in item.value"
                      style="color: #06c"
                      :key="dIndex"
                      :href="
                        '/' +
                        related.targetProjectId +
                        '/nodes/key/' +
                        related.targetNodeKey
                      "
                      target="_blank"
                      >{{ related.targetNodeKey }}
                      <span v-if="dIndex !== item.value.length - 1">,</span></a
                    >
                  </span>
                  <span v-else-if="item.type === 'sprintId'">
                    <span>
                      {{ sprint[item.value] ? sprint[item.value].name : "" }}
                    </span>
                  </span>
                  <span v-else-if="item.type === 'testPlanIdList'">
                    <span
                      v-for="(testPlanId, bIndex) in item.value"
                      :key="bIndex"
                    >
                      {{
                        testPlan[testPlanId] ? testPlan[testPlanId].name : ""
                      }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'versionIdList'">
                    <span
                      v-for="(versionId, bIndex) in item.value"
                      :key="bIndex"
                    >
                      {{ version[versionId] ? version[versionId].name : "" }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'TEXT'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'TEXTAREA'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'NUMBER'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'SELECT'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'SELECT_MULTI'">
                    {{ item.value ? item.value.join(",") : "" }}
                  </span>
                  <span v-else-if="item.type === 'SINGLE_USER_SELECT'">{{
                    matchUserNickname(item.value)
                  }}</span>
                  <span v-else-if="item.type === 'MULTI_USER_SELECT'">
                    <span v-for="(userId, bIndex) in item.value" :key="bIndex">
                      {{ matchUserNickname(userId) }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'DATE'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'LABEL'">
                    {{ item.value ? item.value.join(",") : "" }}
                  </span>
                  <span v-else-if="item.type === 'LINK'">
                    {{ item.value }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!singleVersion" class="same-wrap">
          <div class="same-wrap-title">
            {{ $t("node.version.Equalfields") }}
          </div>
          <div class="diff-wrap-content">
            <div>
              <div class="version-title">{{ version1Title }}</div>
              <div class="version-fields">
                <div class="version-value-key">
                  <span>{{ $t("node.version.field") }}</span>
                  <span>{{ $t("node.version.value") }}</span>
                </div>
                <div v-for="(item, index) in version1Same" :key="index">
                  <span>{{ item.key }}</span>
                  <span v-if="item.type === 'String'">{{ item.value }}</span>
                  <span v-else-if="item.type === 'attachments'">
                    <a
                      v-for="(url, urlindex) in item.value"
                      style="color: #06c"
                      :key="urlindex"
                      :href="config.oss_url + url.url"
                    >
                      {{ url.name }}
                    </a>
                  </span>
                  <span v-else-if="item.type === 'description'">
                    <a
                      v-if="item.value"
                      href="javascript:void(0)"
                      @click="showDes(item.value)"
                      >{{ $t("btn.view") }}</a
                    >
                  </span>
                  <span v-else-if="item.type === 'DRAWIO'">
                    <a
                      v-if="item.value && item.value !== '[]'"
                      style="color: #06c"
                      href="javascript:void(0)"
                      @click="showDrawio(item.value)"
                    >
                      {{ $t("btn.view") }}
                    </a>
                  </span>
                  <span v-else-if="item.type === 'baselineIdList'">
                    <span
                      v-for="(baseLineId, bIndex) in item.value"
                      :key="bIndex"
                    >
                      {{
                        baseLine[baseLineId] ? baseLine[baseLineId].name : ""
                      }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'ganttChartUuid'">
                    <span>
                      {{ getGanttName(item.value) }}
                    </span>
                  </span>
                  <span v-else-if="item.type === 'relatedNodeList'">
                    <a
                      v-for="(related, dIndex) in item.value"
                      style="color: #06c"
                      :key="dIndex"
                      :href="
                        '/' +
                        related.targetProjectId +
                        '/nodes/key/' +
                        related.targetNodeKey
                      "
                      target="_blank"
                      >{{ related.targetNodeKey }}
                      <span v-if="dIndex !== item.value.length - 1">,</span></a
                    >
                  </span>
                  <span v-else-if="item.type === 'sprintId'">
                    <span>
                      {{ sprint[item.value] ? sprint[item.value].name : "" }}
                    </span>
                  </span>
                  <span v-else-if="item.type === 'testPlanIdList'">
                    <span
                      v-for="(testPlanId, bIndex) in item.value"
                      :key="bIndex"
                    >
                      {{
                        testPlan[testPlanId] ? testPlan[testPlanId].name : ""
                      }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'versionIdList'">
                    <span
                      v-for="(versionId, bIndex) in item.value"
                      :key="bIndex"
                    >
                      {{ version[versionId] ? version[versionId].name : "" }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'TEXT'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'TEXTAREA'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'NUMBER'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'SELECT'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'SELECT_MULTI'">
                    {{ item.value ? item.value.join(",") : "" }}
                  </span>
                  <span v-else-if="item.type === 'SINGLE_USER_SELECT'">{{
                    matchUserNickname(item.value)
                  }}</span>
                  <span v-else-if="item.type === 'MULTI_USER_SELECT'">
                    <span v-for="(userId, bIndex) in item.value" :key="bIndex">
                      {{ matchUserNickname(userId) }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'DATE'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'LABEL'">
                    {{ item.value ? item.value.join(",") : "" }}
                  </span>
                  <span v-else-if="item.type === 'LINK'">
                    {{ item.value }}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div class="version-title">{{ version2Title }}</div>
              <div class="version-fields">
                <div class="version-value-key">
                  <span>{{ $t("node.version.field") }}</span>
                  <span>{{ $t("node.version.value") }}</span>
                </div>
                <div v-for="(item, index) in version2Same" :key="index">
                  <span>{{ item.key }}</span>
                  <span v-if="item.type === 'String'">{{ item.value }}</span>
                  <span v-else-if="item.type === 'attachments'">
                    <a
                      v-for="(url, urlindex) in item.value"
                      style="color: #06c"
                      :key="urlindex"
                      :href="config.oss_url + url.url"
                    >
                      {{ url.name }}
                    </a>
                  </span>
                  <span v-else-if="item.type === 'description'">
                    <a
                      v-if="item.value"
                      style="color: #06c"
                      href="javascript:void(0)"
                      @click="showDes(item.value)"
                      >{{ $t("btn.view") }}</a
                    >
                  </span>
                  <span v-else-if="item.type === 'DRAWIO'">
                    <a
                      v-if="item.value && item.value !== '[]'"
                      style="color: #06c"
                      href="javascript:void(0)"
                      @click="showDrawio(item.value)"
                    >
                      {{ $t("btn.view") }}
                    </a>
                  </span>
                  <span v-else-if="item.type === 'baselineIdList'">
                    <span
                      v-for="(baseLineId, bIndex) in item.value"
                      :key="bIndex"
                    >
                      {{
                        baseLine[baseLineId] ? baseLine[baseLineId].name : ""
                      }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'ganttChartUuid'">
                    <span>
                      {{ getGanttName(item.value) }}
                    </span>
                  </span>
                  <span v-else-if="item.type === 'relatedNodeList'">
                    <a
                      v-for="(related, dIndex) in item.value"
                      style="color: #06c"
                      :key="dIndex"
                      :href="
                        '/' +
                        related.targetProjectId +
                        '/nodes/key/' +
                        related.targetNodeKey
                      "
                      target="_blank"
                      >{{ related.targetNodeKey }}
                      <span v-if="dIndex !== item.value.length - 1">,</span></a
                    >
                  </span>
                  <span v-else-if="item.type === 'sprintId'">
                    <span>
                      {{ sprint[item.value] ? sprint[item.value].name : "" }}
                    </span>
                  </span>
                  <span v-else-if="item.type === 'testPlanIdList'">
                    <span
                      v-for="(testPlanId, bIndex) in item.value"
                      :key="bIndex"
                    >
                      {{
                        testPlan[testPlanId] ? testPlan[testPlanId].name : ""
                      }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'versionIdList'">
                    <span
                      v-for="(versionId, bIndex) in item.value"
                      :key="bIndex"
                    >
                      {{ version[versionId] ? version[versionId].name : "" }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'TEXT'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'TEXTAREA'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'NUMBER'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'SELECT'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'SELECT_MULTI'">
                    {{ item.value ? item.value.join(",") : "" }}
                  </span>
                  <span v-else-if="item.type === 'SINGLE_USER_SELECT'">{{
                    matchUserNickname(item.value)
                  }}</span>
                  <span v-else-if="item.type === 'MULTI_USER_SELECT'">
                    <span v-for="(userId, bIndex) in item.value" :key="bIndex">
                      {{ matchUserNickname(userId) }}
                      <span v-if="bIndex !== item.value.length - 1">,</span>
                    </span>
                  </span>
                  <span v-else-if="item.type === 'DATE'">
                    {{ item.value }}
                  </span>
                  <span v-else-if="item.type === 'LABEL'">
                    {{ item.value ? item.value.join(",") : "" }}
                  </span>
                  <span v-else-if="item.type === 'LINK'">
                    {{ item.value }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <el-dialog :visible.sync="desDialog" width="80%" :modal="false">
      <span v-html="currentDes"></span>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <el-img-viewer
      v-if="showDrawioDialog"
      class="viewer"
      :z-index="100000"
      preview-teleported
      :url-list="drawioList"
      :on-close="closeImgViewer"
    ></el-img-viewer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  getArchive,
  getArchiveDetail,
  getTwoArchiveDetail,
  rollbackVersion,
} from "@/network/nodeVersion";
import { download_drawio } from "@/network/fileOperation/index.js";
import { getAllGanttChart } from "@/network/gantt/index.js";
import config from "@/../config/index";
export default {
  components: {
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  inject: ["nodeKey"],
  props: {
    nodeInfo: {
      type: Object,
    },
  },
  data() {
    return {
      config: config,
      ganttList: [],
      currentDes: "",
      drawioList: [],
      tableData: [],
      dialogVisible: false,
      desDialog: false,
      showDrawioDialog: false,
      multipleSelection: [],
      singleVersion: true,
      singleVersionData: {},
      version1Title: "",
      version1Same: [],
      version1Diff: [],
      version2Title: "",
      version2Same: [],
      version2Diff: [],
      showNodeDataList: [
        "topic",
        "assignee",
        "architectureDiagrams",
        "description",
        "attachments",
        // "testDetails",
      ],
      showRelationDataList: [
        "baselineIdList",
        "versionIdList",
        "sprintId",
        "testPlanIdList",
        "ganttChartUuid",
        "relatedNodeList",
      ],
      nameMap: {
        topic: this.$t("node.version.topic"),
        assignee: this.$t("node.version.assignee"),
        attachments: this.$t("node.version.attachments"),
        description: this.$t("node.version.description"),
        architectureDiagrams: this.$t("node.version.architectureDiagrams"),
        testDetails: this.$t("node.version.testDetails"),
        baselineIdList: this.$t("node.version.baselineIdList"),
        ganttChartUuid: this.$t("node.version.ganttChartUuid"),
        relatedNodeList: this.$t("node.version.relatedNodeList"),
        sprintId: this.$t("node.version.sprintId"),
        testPlanIdList: this.$t("node.version.testPlanIdList"),
        versionIdList: this.$t("node.version.versionId"),
      },
    };
  },
  computed: {
    ...mapGetters(["user_list", "version", "testPlan", "sprint", "baseLine"]),

    comparisonDisable() {
      return this.multipleSelection.length !== 2;
    },
  },
  mounted() {
    this.getData();
    this.getGanttCharts();
  },
  methods: {
    revertVersion(row) {
      this.$confirm(
        this.$t("node.version.revertTips"),
        this.$t("nodeDetail.third.warn"),
        {
          confirmButtonText: this.$t("btn.rollback"),
          confirmButtonClass: "delete_button_class",
          cancelButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "delete_cancel_class",
          type: "warning",
        }
      )
        .then(() => {
          const params = {
            projectId: this.get_pid(),
            nodeKey: this.nodeInfo.key,
            archiveId: row.archiveId,
          };
          rollbackVersion(params).then((res) => {
            this.$router.go(0);
          });
        })
        .catch(() => {});
    },
    canSelect(row) {
      let flag = false;
      this.multipleSelection.forEach((item) => {
        if (item.archiveId === row.archiveId) {
          flag = true;
        }
      });
      return this.multipleSelection.length < 2 || flag;
    },
    getGanttName(value) {
      let name = "";
      this.ganttList.forEach((item) => {
        if (item.ganttChartId === value) {
          name = item.name;
        }
      });
      return name;
    },
    async getGanttCharts() {
      this.ganttList = await getAllGanttChart(this.get_pid());
    },
    closeImgViewer() {
      this.showDrawioDialog = false;
    },
    async showDrawio(value) {
      const valueObj = typeof value === "string" ? JSON.parse(value) : value;
      const self = this;
      this.drawioList = [];
      valueObj.forEach(async (obj) => {
        let res = await download_drawio(obj.data);
        const reader = new FileReader();
        reader.readAsText(res);
        reader.onload = function (e) {
          self.drawioList.push(e.target.result);
          self.showDrawioDialog = true;
        };
      });
    },
    showDes(value) {
      this.currentDes = value;
      this.desDialog = true;
    },
    getData() {
      this.tableData = [];
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeInfo.key,
      };
      getArchive(params).then((res) => {
        this.tableData = res;
      });
    },
    versionComparison() {
      this.singleVersion = false;
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeInfo.key,
        sourceArchiveId: this.multipleSelection[0].archiveId,
        targetArchiveId: this.multipleSelection[1].archiveId,
      };
      getTwoArchiveDetail(params).then((res) => {
        this.version1Title = res.sourceArchive.archiveName;
        this.version1Diff = [];
        this.version1Same = [];
        this.version2Title = res.targetArchive.archiveName;
        this.version2Diff = [];
        this.version2Same = [];
        this.showNodeDataList.forEach((item) => {
          const obj1 = {
            key: this.nameMap[item],
            value: res.sourceArchive.nodeData[item],
          };
          const obj2 = {
            key: this.nameMap[item],
            value: res.targetArchive.nodeData[item],
          };
          this.setFileType(obj1, item);
          this.setFileType(obj2, item);
          if (
            JSON.stringify(res.sourceArchive.nodeData[item]) !==
            JSON.stringify(res.targetArchive.nodeData[item])
          ) {
            this.version1Diff.push(obj1);
            this.version2Diff.push(obj2);
          } else {
            this.version1Same.push(obj1);
            this.version2Same.push(obj2);
          }
        });
        this.showRelationDataList.forEach((item) => {
          const obj1 = {
            key: this.nameMap[item],
            value: res.sourceArchive.relationData[item],
          };
          const obj2 = {
            key: this.nameMap[item],
            value: res.targetArchive.relationData[item],
          };
          this.setFileType(obj1, item);
          this.setFileType(obj2, item);
          if (
            JSON.stringify(res.sourceArchive.relationData[item]) !==
            JSON.stringify(res.targetArchive.relationData[item])
          ) {
            this.version1Diff.push(obj1);
            this.version2Diff.push(obj2);
          } else {
            this.version1Same.push(obj1);
            this.version2Same.push(obj2);
          }
        });
        res.sourceArchive.nodeData.content.forEach((item1) => {
          let bothHave = false;
          res.targetArchive.nodeData.content.forEach((item2) => {
            let bothHave2 = false;
            if (
              item1.fieldId === item2.fieldId &&
              item1.name === item2.name &&
              JSON.stringify(item1.value) === JSON.stringify(item2.value)
            ) {
              bothHave = true;
              const obj1 = {
                key: item1.name,
                value: item1.value,
                type: item1.fieldType,
              };
              const obj2 = {
                key: item2.name,
                value: item2.value,
                type: item2.fieldType,
              };
              this.version1Same.push(obj1);
              this.version2Same.push(obj2);
            } else if (
              item1.fieldId === item2.fieldId &&
              (item1.name !== item2.name ||
                JSON.stringify(item1.value) !== JSON.stringify(item2.value))
            ) {
              bothHave = true;
              const obj1 = {
                key: item1.name,
                value: item1.value,
                type: item1.fieldType,
              };
              const obj2 = {
                key: item2.name,
                value: item2.value,
                type: item2.fieldType,
              };
              this.version1Diff.push(obj1);
              this.version2Diff.push(obj2);
            }
            res.sourceArchive.nodeData.content.forEach((item3) => {
              if (
                item3.fieldId === item2.fieldId &&
                item3.name === item2.name &&
                JSON.stringify(item3.value) === JSON.stringify(item2.value)
              ) {
                bothHave2 = true;
              } else if (
                item3.fieldId === item2.fieldId &&
                (item3.name !== item2.name ||
                  JSON.stringify(item3.value) !== JSON.stringify(item2.value))
              ) {
                bothHave2 = true;
              }
            });
            if (!bothHave2) {
              const obj2 = {
                key: item2.name,
                value: item2.value,
                type: item2.fieldType,
              };
              this.version2Diff.push(obj2);
            }
          });
          if (!bothHave) {
            const obj1 = {
              key: item1.name,
              value: item1.value,
              type: item1.fieldType,
            };
            this.version1Diff.push(obj1);
          }
        });
        this.dialogVisible = true;
      });
    },
    setFileType(obj, item) {
      switch (item) {
        case "topic":
          obj.type = "String";
          break;
        case "assignee":
          obj.type = "SINGLE_USER_SELECT";
          break;
        case "attachments":
          obj.type = "attachments";
          break;
        case "description":
          obj.type = "description";
          break;
        case "architectureDiagrams":
          obj.type = "DRAWIO";
          break;
        case "testDetails":
          obj.type = "testDetails";
          break;
        case "baselineIdList":
          obj.type = "baselineIdList";
          break;
        case "ganttChartUuid":
          obj.type = "ganttChartUuid";
          break;
        case "relatedNodeList":
          obj.type = "relatedNodeList";
          break;
        case "sprintId":
          obj.type = "sprintId";
          break;
        case "testPlanIdList":
          obj.type = "testPlanIdList";
          break;
        case "versionIdList":
          obj.type = "versionIdList";
          break;
      }
    },
    showVersion(row) {
      this.singleVersion = true;
      const params = {
        projectId: this.get_pid(),
        nodeKey: this.nodeInfo.key,
        archiveId: row.archiveId,
      };
      getArchiveDetail(params).then((res) => {
        this.dialogVisible = true;
        this.singleVersionData = res;
        this.version1Title = res.archiveName;
        this.version1Diff = [];
        this.version1Same = [];
        this.showNodeDataList.forEach((item) => {
          const obj = {
            key: this.nameMap[item],
            value: res.nodeData[item],
          };
          this.setFileType(obj, item);
          this.version1Diff.push(obj);
        });
        this.showRelationDataList.forEach((item) => {
          const obj = {
            key: this.nameMap[item],
            value: res.relationData[item],
          };
          this.setFileType(obj, item);
          this.version1Diff.push(obj);
        });
        res.nodeData.content.forEach((item) => {
          const obj = {
            key: item.name,
            value: item.value,
            type: item.fieldType,
          };
          this.version1Diff.push(obj);
        });
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style scoped lang="scss">
.version-detail-wrap {
  display: flex;
  justify-content: space-between;
}
.version-detail {
  width: 48%;
  border: 2px solid #e1e3e6;
  border-radius: 8px;
  &-title {
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    background-color: #e1e3e6;
    color: #004caa;
    border-radius: 6px 6px 0 0;
  }
  &-main {
    padding: 20px;
  }
  &-table-difference {
    margin: 10px 0;
  }
  &-table-difference,
  &-table-same {
    max-height: 400px;
    min-height: 200px;
    overflow-y: scroll;
  }
  &-table-header,
  &-table-difference,
  &-table-same {
    background-color: #f5f5f5;
    color: #333333;
    & > div {
      width: 100%;
      display: flex;
      min-height: 50px;
      margin-bottom: 10px;
      & > span {
        display: inline-block;
        width: 50%;
        height: 100%;
        text-align: center;
        font-size: 18px;
      }
    }
  }
}
.version-detail-com {
  .same-wrap {
    border: 2px solid #96c926;
    border-radius: 8px;
    padding: 10px;
    background-color: rgba(150, 201, 38, 0.1);
    margin-top: 20px;
    &-title {
      color: #96c926;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 12px;
    }
  }
  .diff-wrap {
    border: 2px solid #ff5733;
    border-radius: 8px;
    padding: 10px;
    background-color: rgba(255, 87, 51, 0.1);
    &-title {
      color: #ff5733;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 12px;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      & > div {
        width: 48%;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        position: relative;
        .version-title {
          position: absolute;
          left: 0;
          top: 0;
          height: 40px;
          line-height: 40px;
          border-radius: 8px 0px 8px 0px;
          background: rgba(240, 246, 252, 1);
          color: #004caa;
          font-weight: bold;
          padding: 0 10px;
        }
      }
      .version-fields {
        margin-top: 40px;
        .version-value-key {
          & > span {
            color: #383838;
            font-size: 16px;
            font-weight: bold;
          }
        }
        & > div {
          width: 100%;
          display: flex;
          align-items: flex-start;
          min-height: 40px;
          & > span {
            &:first-of-type {
              width: 40%;
            }
            &:last-of-type {
              width: 60%;
            }
            display: inline-block;
            text-align: center;
            font-size: 16px;
          }
        }
      }
    }
  }
}
.nodeVersionTable {
  ::v-deep {
    .el-table__header .el-checkbox {
      display: none !important;
    }
  }
}
</style>
<style lang="scss">
.test {
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: none;
    & > div {
      word-break: keep-all;
    }
  }
}
</style>
