<template>
  <div class="dfmea-drawer-wrap">
    <div
      ref="right-drawer"
      class="right-drawer"
      :class="{ rightIsCollapse: !rightIsCollapse }"
    >
      <div
        v-show="rightIsCollapse"
        class="right-resize"
        :title="$t('addAlert.collapse')"
      ></div>
      <div class="right-title">
        DFMEA{{ $t("canvas.dfmea.structureTree") }}
        <i
          class="iconfont icon-a-fenzu6"
          style="font-size: 14px; cursor: pointer"
          @click="goKnowledge"
        ></i>
        <i
          class="el-icon el-icon-close"
          style="float: right; cursor: pointer"
          @click="closeDFMEA"
        ></i>
      </div>
      <div class="right-bottom">
        <div class="search-distribution" @click.stop="checkTree">
          <img src="@/assets/img/dfmea_search.png" alt="" />
        </div>
        <div class="right-collapse" @click="rightCollapseFn">
          <i
            v-if="!rightIsCollapse"
            class="iconfont icon-shouqi1"
            style="font-size: 20px; margin-right: 12px"
          ></i>
          <i v-else class="iconfont icon-zhedie1" style="font-size: 20px"></i>
        </div>
        <div class="right-content">
          <el-tree
            ref="tree"
            :data="treeData"
            node-key="id"
            class="tree"
            :props="defaultProps"
            :expand-on-click-node="false"
            :default-expanded-keys="defaultExpandedKeys"
            @node-expand="nodeExpand"
          >
            <span slot-scope="{ node, data }" class="custom-tree-node">
              <span
                v-if="data.type === 'structure'"
                class="tree-structure-add"
                @click.stop="addFunction(data)"
              >
                <i
                  class="iconfont icon-add"
                  style="font-size: 16px; color: #184fa9"
                ></i>
              </span>
              <span
                v-if="data.type === 'function'"
                class="tree-function"
                :class="{
                  'tree-function-link-before': data.previous,
                  'tree-function-link-after': data.next,
                }"
              ></span>
              <span
                v-if="data.type === 'function'"
                class="tree-function-add"
                @click.stop="addDefect(data)"
              >
                <i
                  class="iconfont icon-add"
                  style="font-size: 16px; color: #ff5733"
                ></i>
              </span>
              <span
                v-if="data.type === 'defect'"
                class="tree-defect"
                :class="{
                  'tree-defect-link-before': data.previous,
                  'tree-defect-link-after': data.next,
                }"
              ></span>
              <span
                v-if="data.type === 'defect' && !data.severity"
                class="tree-defect-add"
                @click.stop="showDefectDialog(data)"
              >
                S
              </span>
              <span
                v-if="data.type === 'defect' && data.severity"
                style="color: #ff5733"
                @click.stop="showDefectDialog(data)"
              >
                {{ `(S=${data.severity})` }}
              </span>
              <span :title="node.label" class="tree-label-content">
                <el-button
                  v-if="currentNodeId === data.id && data.type !== 'structure'"
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  style="
                    height: 24px;
                    position: absolute;
                    right: 10px;
                    padding-top: 4px;
                  "
                  @click.stop="removeNode(data)"
                ></el-button>
                <span
                  v-if="!data.edit"
                  :id="data.type === 'structure' ? data.id : ''"
                  class="detail-span"
                  :class="{
                    function_text: data.type === 'function',
                    defect_text: data.type === 'defect',
                    high_light:
                      (data.type === 'function' || data.type === 'defect') &&
                      checkTreeFlag &&
                      !data.previous &&
                      !data.next,
                  }"
                  @dblclick.stop="editTreeDetail(data)"
                  @click.stop="treecurrentChange(data)"
                  >{{ node.label }}</span
                >
                <span
                  v-else-if="data.edit && data.type === 'function'"
                  class="tree-input-wrap"
                >
                  <el-input
                    ref="label-input"
                    v-model="data.label"
                    style="width: 74%"
                    @blur="editTreeDetailBlur(data)"
                  ></el-input>
                </span>
                <span
                  v-else-if="data.edit && data.type === 'defect'"
                  class="tree-input-wrap"
                >
                  <el-autocomplete
                    ref="label-autocomplete"
                    v-model="data.label"
                    :fetch-suggestions="querySearch"
                    :popper-append-to-body="false"
                    popper-class="defect-autocomplete"
                    style="width: 74%"
                    class="autocomplete_class"
                    @select="handleSelectDefect($event, data, node)"
                    @blur="editTreeDetailBlur(data)"
                  >
                    <!-- <template slot-scope="{ item }">
    <div class="name">{{ item.value }}</div>
  </template>
                   -->
                  </el-autocomplete>
                </span>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
    </div>
    <div
      ref="bottom-drawer"
      class="bottom-drawer"
      :class="{
        bottomIsCollapse: !bottomIsCollapse,
      }"
    >
      <div
        v-show="bottomIsCollapse"
        class="bottom-resize"
        :title="$t('addAlert.collapse')"
      ></div>
      <div class="bottom-collapse" @click="bottomCollapseFn">
        <div>
          <i
            v-if="!bottomIsCollapse"
            class="iconfont icon-shouqi1"
            style="
              font-size: 20px;
              margin-right: 12px;
              transform: rotate(45deg);
            "
          ></i>
          <i
            v-else
            class="iconfont icon-zhedie1"
            style="font-size: 20px; transform: rotate(45deg)"
          ></i>
        </div>
      </div>
      <i
        class="el-icon-circle-plus"
        style="
          font-size: 30px;
          color: #2a82e4;
          top: 50px;
          left: 5px;
          cursor: pointer;
          position: absolute;
        "
        @click="addLine"
      ></i>
      <i
        class="iconfont icon-download"
        style="
          font-size: 26px;
          color: #2a82e4;
          bottom: 50px;
          left: 5px;
          cursor: pointer;
          position: absolute;
        "
        @click="exportDfmea"
      ></i>
      <div class="bottom-table">
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          height="90%"
          style="width: 100%"
          :stripe="false"
          :highlight-current-row="false"
          :header-cell-style="headerCellStyle"
          :cell-style="cellStyle"
          :span-method="arraySpanMethod"
        >
          <el-table-column
            prop="index"
            :label="$t('canvas.dfmea.index')"
            width="80"
            align="center"
            type="index"
            :index="indexMethod"
            fixed
          >
          </el-table-column>
          <el-table-column :label="$t('canvas.dfmea.step2')" align="center">
            <el-table-column
              prop="previousItem"
              :label="$t('canvas.dfmea.lastLevel')"
              width="130"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.previousItemEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'previousItemEdit')"
                  >
                    {{ findTopic(scope.row.previousItem) }}
                  </div>
                  <div v-else>
                    <el-select
                      id="previousItemEdit"
                      v-model="scope.row.previousItem"
                      :loading="optionsLoading"
                      filterable
                      clearable
                      @change="editRow(scope.row, 'structure', 'previousItem')"
                      @visible-change="
                        (val) => buildOptions(val, scope.row, 'previousItem')
                      "
                      @blur="itemBlur(scope.row, 'previousItemEdit')"
                    >
                      <el-option
                        v-for="item in publicOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="currentItem"
              :label="$t('canvas.dfmea.focusElement')"
              width="120"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.currentItemEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'currentItemEdit')"
                  >
                    {{ findTopic(scope.row.currentItem) }}
                  </div>
                  <div v-else>
                    <el-select
                      id="currentItemEdit"
                      v-model="scope.row.currentItem"
                      :loading="optionsLoading"
                      filterable
                      clearable
                      @change="editRow(scope.row, 'structure', 'currentItem')"
                      @visible-change="
                        (val) => buildOptions(val, scope.row, 'currentItem')
                      "
                      @blur="itemBlur(scope.row, 'currentItemEdit')"
                    >
                      <el-option
                        v-for="item in publicOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="nextItem"
              :label="$t('canvas.dfmea.nextLevel')"
              width="180"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.nextItemEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'nextItemEdit')"
                  >
                    {{ findTopic(scope.row.nextItem) }}
                  </div>
                  <div v-else>
                    <el-select
                      id="nextItemEdit"
                      v-model="scope.row.nextItem"
                      :loading="optionsLoading"
                      filterable
                      clearable
                      @change="editRow(scope.row, 'structure', 'nextItem')"
                      @visible-change="
                        (val) => buildOptions(val, scope.row, 'nextItem')
                      "
                      @blur="itemBlur(scope.row, 'nextItemEdit')"
                    >
                      <el-option
                        v-for="item in publicOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column :label="$t('canvas.dfmea.step3')" align="center">
            <el-table-column
              prop="previousFunction"
              :label="$t('canvas.dfmea.lastLevel3')"
              width="180"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.previousFunctionEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'previousFunctionEdit')"
                  >
                    {{
                       scope.row.previousFunction ? scope.row.previousFunction.detail : '',
                    }}
                  </div>
                  <div v-else>
                    <el-select
                      id="previousFunctionEdit"
                      v-model="scope.row.previousFunction"
                      :loading="optionsLoading"
                      filterable
                      clearable
                      @change="
                        editRow(scope.row, 'function', 'previousFunction')
                      "
                      @visible-change="
                        (val) =>
                          buildOptions(val, scope.row, 'previousFunction')
                      "
                      @blur="itemBlur(scope.row, 'previousFunctionEdit')"
                    >
                      <el-option
                        v-for="item in publicOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="currentFunction"
              :label="$t('canvas.dfmea.focusElement3')"
              width="160"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.currentFunctionEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'currentFunctionEdit')"
                  >
                    {{
                       scope.row.currentFunction ? scope.row.currentFunction.detail : '',
                    }}
                  </div>
                  <div v-else>
                    <el-select
                      id="currentFunctionEdit"
                      v-model="scope.row.currentFunction"
                      :loading="optionsLoading"
                      filterable
                      clearable
                      @change="
                        editRow(scope.row, 'function', 'currentFunction')
                      "
                      @visible-change="
                        (val) => buildOptions(val, scope.row, 'currentFunction')
                      "
                      @blur="itemBlur(scope.row, 'currentFunctionEdit')"
                    >
                      <el-option
                        v-for="item in publicOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="nextFunction"
              :label="$t('canvas.dfmea.nextLevel3')"
              width="220"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.nextFunctionEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'nextFunctionEdit')"
                  >
                    {{
                       scope.row.nextFunction ? scope.row.nextFunction.detail : '',
                    }}
                  </div>
                  <div v-else>
                    <el-select
                      id="nextFunctionEdit"
                      v-model="scope.row.nextFunction"
                      :loading="optionsLoading"
                      filterable
                      clearable
                      @change="editRow(scope.row, 'function', 'nextFunction')"
                      @visible-change="
                        (val) => buildOptions(val, scope.row, 'nextFunction')
                      "
                      @blur="itemBlur(scope.row, 'nextFunctionEdit')"
                    >
                      <el-option
                        v-for="item in publicOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column :label="$t('canvas.dfmea.step4')" align="center">
            <el-table-column
              prop="previousFailure"
              :label="$t('canvas.dfmea.fe')"
              width="380"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.previousFailureEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'previousFailureEdit')"
                  >
                    {{
                       scope.row.previousFailure ? scope.row.previousFailure.detail : '',
                    }}
                  </div>
                  <div v-else>
                    <el-select
                      id="previousFailureEdit"
                      v-model="scope.row.previousFailure"
                      :loading="optionsLoading"
                      filterable
                      clearable
                      @change="editRow(scope.row, 'defect', 'previousFailure')"
                      @visible-change="
                        (val) => buildOptions(val, scope.row, 'previousFailure')
                      "
                      @blur="itemBlur(scope.row, 'previousFailureEdit')"
                    >
                      <el-option
                        v-for="item in publicOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="severity"
              :label="$t('canvas.dfmea.severity')"
              width="180"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.severityEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'severityEdit')"
                  >
                    {{ scope.row.severity }}
                  </div>
                  <div v-else>
                    <el-select
                      id="severityEdit"
                      v-model="scope.row.severity"
                      filterable
                      clearable
                      @change="editRow(scope.row, 'severity', 'severity')"
                      @blur="itemBlur(scope.row, 'severityEdit')"
                    >
                      <el-option
                        v-for="i in severityOptions"
                        :key="i.value"
                        :label="i.label"
                        :value="i.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="currentFailure"
              :label="$t('canvas.dfmea.fm')"
              width="200"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.currentFailureEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'currentFailureEdit')"
                  >
                    {{
                       scope.row.currentFailure ? scope.row.currentFailure.detail : '',
                    }}
                  </div>
                  <div v-else>
                    <el-select
                      id="currentFailureEdit"
                      v-model="scope.row.currentFailure"
                      :loading="optionsLoading"
                      filterable
                      clearable
                      @change="editRow(scope.row, 'defect', 'currentFailure')"
                      @visible-change="
                        (val) => buildOptions(val, scope.row, 'currentFailure')
                      "
                      @blur="itemBlur(scope.row, 'currentFailureEdit')"
                    >
                      <el-option
                        v-for="item in publicOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="nextFailure"
              :label="$t('canvas.dfmea.fc')"
              width="300"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.nextFailureEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'nextFailureEdit')"
                  >
                    {{
                       scope.row.nextFailure ? scope.row.nextFailure.detail : '',
                    }}
                  </div>
                  <div v-else>
                    <el-select
                      id="nextFailureEdit"
                      v-model="scope.row.nextFailure"
                      :loading="optionsLoading"
                      filterable
                      clearable
                      @change="editRow(scope.row, 'defect', 'nextFailure')"
                      @visible-change="
                        (val) => buildOptions(val, scope.row, 'nextFailure')
                      "
                      @blur="itemBlur(scope.row, 'nextFailureEdit')"
                    >
                      <el-option
                        v-for="item in publicOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column :label="$t('canvas.dfmea.step5')" align="center">
            <el-table-column
              prop="pc"
              :label="$t('canvas.dfmea.pc5')"
              width="260"
              style="background-color: red"
            >
              <template slot-scope="scope">
                <div style="display: flex">
                  <div style="width: 80%">
                    <div
                      v-if="!scope.row.pcEdit"
                      class="not-edit"
                      @click.stop="showEdit(scope.row, 'pcEdit')"
                    >
                      {{ scope.row.pc }}
                    </div>
                    <div v-else>
                      <el-input
                        id="pcEdit"
                        v-model="scope.row.pc"
                        type="textarea"
                        style="width: 100%"
                        @blur="
                          riskAndOptimizationsBlur(
                            scope.row,
                            'pcEdit',
                            'riskAndOptimizations',
                            'pc'
                          )
                        "
                      ></el-input>
                    </div>
                  </div>
                  <div
                    v-if="showRiskAndOptimizations(scope.row)"
                    style="
                      width: 20%;
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;
                      justify-content: space-between;
                    "
                  >
                    <i
                      v-show="
                        scope.row.riskAndOptimizationId !==
                        scope.row.riskAndOptimizations[0].riskAndOptimizationId
                      "
                      class="el-icon-remove"
                      style="font-size: 20px; color: #ff5733; cursor: pointer"
                      @click="deleteRiskAndOptimizations(scope.row)"
                    ></i>
                    <i
                      class="el-icon-circle-plus"
                      style="font-size: 20px; color: #2a82e4; cursor: pointer"
                      @click="addRiskAndOptimizations(scope.row)"
                    ></i>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="o"
              :label="$t('canvas.dfmea.frequency5')"
              width="160"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.oEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'oEdit')"
                  >
                    {{ scope.row.o }}
                  </div>
                  <div v-else>
                    <el-select
                      id="oEdit"
                      v-model="scope.row.o"
                      filterable
                      clearable
                      @change="editRow(scope.row, 'riskAndOptimizations', 'o')"
                      @blur="itemBlur(scope.row, 'oEdit')"
                    >
                      <el-option
                        v-for="i in oOptions"
                        :key="i.value"
                        :label="i.label"
                        :value="i.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="dc"
              :label="$t('canvas.dfmea.dc5')"
              width="300"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.dcEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'dcEdit')"
                  >
                    {{ scope.row.dc }}
                  </div>
                  <div v-else>
                    <el-input
                      id="dcEdit"
                      v-model="scope.row.dc"
                      type="textarea"
                      style="width: 100%"
                      @blur="
                        riskAndOptimizationsBlur(
                          scope.row,
                          'dcEdit',
                          'riskAndOptimizations',
                          'dc'
                        )
                      "
                    ></el-input>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="d"
              :label="$t('canvas.dfmea.detection5')"
              width="260"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.dEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'dEdit')"
                  >
                    {{ scope.row.d }}
                  </div>
                  <div v-else>
                    <el-select
                      id="dEdit"
                      v-model="scope.row.d"
                      filterable
                      clearable
                      @change="editRow(scope.row, 'riskAndOptimizations', 'd')"
                      @blur="itemBlur(scope.row, 'dEdit')"
                    >
                      <el-option
                        v-for="i in dOptions"
                        :key="i.value"
                        :label="i.label"
                        :value="i.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="ap"
              :label="$t('canvas.dfmea.priority')"
              width="140"
            >
              <template slot-scope="scope">
                <div>
                  <div>
                    {{ scope.row.ap }}
                  </div>
                  <!-- <div v-else>
                    <el-select
                      id="apEdit"
                      v-model="scope.row.ap"
                      filterable
                      clearable
                      @change="editRow(scope.row, 'riskAndOptimizations', 'ap')"
                      @blur="itemBlur(scope.row, 'apEdit')"
                    >
                      <el-option
                        v-for="i in priorityOptions"
                        :key="i"
                        :label="i"
                        :value="i"
                      >
                      </el-option>
                    </el-select>
                  </div> -->
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="principal"
              :label="$t('canvas.dfmea.assignee')"
              width="120"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.principalEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'principalEdit')"
                  >
                    {{ matchUserNickname(scope.row.principal) }}
                  </div>
                  <div v-else>
                    <el-select
                      id="principalEdit"
                      v-model="scope.row.principal"
                      filterable
                      clearable
                      @change="
                        editRow(scope.row, 'riskAndOptimizations', 'principal')
                      "
                      @blur="itemBlur(scope.row, 'principalEdit')"
                    >
                      <el-option
                        v-for="i in Object.values(project_user_list)"
                        :key="i.accountId"
                        :label="i.nickname"
                        :value="i.accountId"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="targetCompleteDate"
              :label="$t('canvas.dfmea.targetDate')"
              width="260"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.targetCompleteDateEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'targetCompleteDateEdit')"
                  >
                    {{ scope.row.targetCompleteDate }}
                  </div>
                  <div v-else>
                    <el-date-picker
                      id="targetCompleteDateEdit"
                      v-model="scope.row.targetCompleteDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      style="width: 100%"
                      @change="
                        editRow(
                          scope.row,
                          'riskAndOptimizations',
                          'targetCompleteDate'
                        )
                      "
                      @blur="itemBlur(scope.row, 'targetCompleteDateEdit')"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              :label="$t('canvas.dfmea.status')"
              width="120"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.statusEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'statusEdit')"
                  >
                    {{ matchStatusToName(scope.row.status) }}
                  </div>
                  <div v-else>
                    <el-select
                      id="statusEdit"
                      v-model="scope.row.status"
                      filterable
                      @change="
                        editRow(scope.row, 'riskAndOptimizations', 'status')
                      "
                      @blur="itemBlur(scope.row, 'statusEdit')"
                    >
                      <el-option
                        v-for="i in statusOptions"
                        :key="i.value"
                        :label="i.label"
                        :value="i.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              :label="$t('canvas.dfmea.method')"
              width="200"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.actionEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'actionEdit')"
                  >
                    {{ scope.row.action }}
                  </div>
                  <div v-else>
                    <el-input
                      id="actionEdit"
                      v-model="scope.row.action"
                      type="textarea"
                      style="width: 100%"
                      @blur="
                        riskAndOptimizationsBlur(
                          scope.row,
                          'actionEdit',
                          'riskAndOptimizations',
                          'action'
                        )
                      "
                    ></el-input>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="nodeKeyList"
              :label="$t('canvas.dfmea.associatedTasks')"
              width="200"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.nodeKeyListEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'nodeKeyListEdit')"
                  >
                    <span
                      v-for="(item, index) in scope.row.nodeKeyList"
                      :key="index"
                      class="node_key_list"
                      @click.stop="jumpNodeDetail(item)"
                      >{{ item }}
                      <span v-if="index !== scope.row.nodeKeyList.length - 1"
                        >,
                      </span></span
                    >
                  </div>
                  <div v-else>
                    <el-select
                      id="nodeKeyListEdit"
                      v-model="scope.row.nodeKeyList"
                      :loading="optionsLoading"
                      filterable
                      clearable
                      remote
                      multiple
                      :remote-method="getNodeList"
                      @visible-change="
                        (val) => {
                          if (!val) {
                            editRow(
                              scope.row,
                              'riskAndOptimizations',
                              'nodeKeyList'
                            );
                          }
                        }
                      "
                    >
                      <el-option
                        v-for="item in publicOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="completeDate"
              :label="$t('canvas.dfmea.completeDate')"
              width="260"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="!scope.row.completeDateEdit"
                    class="not-edit"
                    @click.stop="showEdit(scope.row, 'completeDateEdit')"
                  >
                    {{ scope.row.completeDate }}
                  </div>
                  <div v-else>
                    <el-date-picker
                      id="completeDateEdit"
                      v-model="scope.row.completeDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      style="width: 100%"
                      @change="
                        editRow(
                          scope.row,
                          'riskAndOptimizations',
                          'completeDate'
                        )
                      "
                      @blur="itemBlur(scope.row, 'completeDateEdit')"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column fixed="right" width="50" align="center">
            <template slot-scope="scope">
              <span>
                <i
                  class="el-icon-remove"
                  style="font-size: 20px; color: #ff5733; cursor: pointer"
                  @click="deleteRow(scope.row)"
                ></i>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div class="circle-nav">
          <el-button
            :class="{ 'active-circle': activeCircle === '1' }"
            class="circle-btn"
            circle
            @click="navigateTo('1')"
          >
          </el-button>
          <el-button
            :class="{ 'active-circle': activeCircle === '2' }"
            class="circle-btn"
            circle
            @click="navigateTo('2')"
          >
          </el-button>
          <el-button
            :class="{ 'active-circle': activeCircle === '3' }"
            class="circle-btn"
            circle
            @click="navigateTo('3')"
          >
          </el-button>
          <el-button
            :class="{ 'active-circle': activeCircle === '4' }"
            class="circle-btn"
            circle
            @click="navigateTo('4')"
          >
          </el-button>
          <!-- ... -->
        </div>
      </div>
    </div>
    <!-- 责任人修改弹窗 -->
    <el-dialog
      :visible.sync="defectDialogVisible"
      :modal="false"
      top="30vh"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <span slot="title"> {{ $t("canvas.dfmea.severitySettings") }} </span>
      <div style="text-align: left">
        <span style="margin-bottom: 16px; display: block">{{
          $t("canvas.dfmea.severityIs")
        }}</span>
        <el-form ref="severityRuleForm" :model="currentDefectCopy">
          <el-form-item prop="severity">
            <el-select
              v-model="currentDefectCopy.severity"
              filterable
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="i in severityOptions"
                :key="i.value"
                :label="i.label"
                :value="i.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            defectDialogVisible = false;
            currentDefect = {};
            currentDefectCopy = {};
          "
          >{{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button type="primary" @click="saveDefectSeverity">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDfmeaFunctions,
  addDfmeaFunctions,
  editDfmeaFunctions,
  deleteDfmeaFunctions,
  getDfmeaFailures,
  addDfmeaFailures,
  editDfmeaFailures,
  deleteDfmeaFailures,
  getRelations,
  addRelations,
  deleteRelations,
  editItemRelations,
  editFunctionRelations,
  editFailureRelationsRelations,
  addRiskAndOptimizations,
  deleteRiskAndOptimizations,
  editRiskAndOptimizations,
  getDistribution,
  dfmeaExcel,
  feOptimized,
} from "@/network/dfmea";
import { get_nodes_by_fuzzykey } from "@/network/node";
import { mapGetters } from "vuex";
export default {
  props: {
    jm: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      rightIsCollapse: true,
      bottomIsCollapse: true,
      defaultProps: {
        // 用于修改节点指定标签的属性值
        children: "children",
        label: "label",
      },
      treeData: [],
      currentNodeId: "",
      tableData: [],
      originTableData: [],
      activeCircle: "1",
      currentDefect: {},
      currentDefectCopy: {},
      defectDialogVisible: false,
      currentData: null,
      // 表格内公共下拉选项
      publicOptions: [],
      // 选项构造中
      optionsLoading: false,
      // 措施优先级
      priorityOptions: ["H", "M", "L"],
      // 状态下拉选项
      statusOptions: [
        {
          label: this.$t("DFMEA.Completed"),
          value: "COMPLETED",
        },
        {
          label: this.$t("DFMEA.NotYetDetermined"),
          value: "CONFIRM_NOT_YET",
        },
        {
          label: this.$t("DFMEA.NotYetDecided"),
          value: "DECIDE_NOT_YET",
        },
        {
          label: this.$t("DFMEA.NoExecute"),
          value: "DO_NOTHING",
        },
        {
          label: this.$t("DFMEA.NotYetExecuted"),
          value: "IMPLEMENT_NOT_YET",
        },
      ],
      // 编辑flagList
      editFlagList: [
        "previousItemEdit",
        "currentItemEdit",
        "nextItemEdit",
        "previousFunctionEdit",
        "currentFunctionEdit",
        "nextFunctionEdit",
        "previousFailureEdit",
        "currentFailureEdit",
        "nextFailureEdit",
        "severityEdit",
        "pcEdit",
        "oEdit",
        "dcEdit",
        "dEdit",
        "apEdit",
        "principalEdit",
        "targetCompleteDateEdit",
        "statusEdit",
        "actionEdit",
        "nodeKeyListEdit",
        "completeDateEdit",
      ],
      // 表格loading
      tableLoading: false,
      // oOptions
      oOptions: [
        {
          label: "10-" + this.$t("canvas.dfmea.extremelyHigh"),
          value: "10",
        },
        {
          label: "9",
          value: "9",
        },
        {
          label: "8",
          value: "8",
        },
        {
          label: "7",
          value: "7",
        },
        {
          label: "6",
          value: "6",
        },
        {
          label: "5",
          value: "5",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "1-" + this.$t("canvas.dfmea.extremelyLow"),
          value: "1",
        },
      ],
      dOptions: [
        {
          label: "10-" + this.$t("canvas.dfmea.veryHigh"),
          value: "10",
        },
        {
          label: "9",
          value: "9",
        },
        {
          label: "8",
          value: "8",
        },
        {
          label: "7",
          value: "7",
        },
        {
          label: "6",
          value: "6",
        },
        {
          label: "5",
          value: "5",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "1-" + this.$t("canvas.dfmea.veryLow"),
          value: "1",
        },
      ],
      severityOptions: [
        {
          label: "10-" + this.$t("canvas.dfmea.veryHigh"),
          value: "10",
        },
        {
          label: "9",
          value: "9",
        },
        {
          label: "8",
          value: "8",
        },
        {
          label: "7",
          value: "7",
        },
        {
          label: "6",
          value: "6",
        },
        {
          label: "5",
          value: "5",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "1-" + this.$t("canvas.dfmea.veryLow"),
          value: "1",
        },
      ],
      // 展开节点
      defaultExpandedKeys: [],
      checkTreeFlag: false,
      labelClickTimer: null,
    };
  },
  computed: {
    ...mapGetters(["project_user_list"]),
  },
  watch: {
    "jm.mind.root": {
      // 监听路由参数
      handler(val) {
        const newTree = [this.buildTree(this.jm.mind.root)];
        if (JSON.stringify(this.treeData) !== JSON.stringify(newTree)) {
          this.treeData = newTree;
          this.getRelations();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  updated() {
    // 这里要写在updated函数里面，要不然有问题，不知道为什么
  },
  mounted() {
    this.bindRightMoveLine();
    this.bindBottomMoveLine();
    this.$nextTick(() => {
      const dialogContent = document.querySelector(".el-table__body-wrapper");
      if (dialogContent) {
        dialogContent.addEventListener("scroll", this.checkActiveItem);
      }
    });
  },
  methods: {
    closeDFMEA() {
      this.$emit("closeDFMEA");
    },
    // 高亮相关树节点
    highLight(node) {
      this.defaultExpandedKeys.push(node.data.key);
      this.$nextTick(() => {
        const hightLightDom = document.getElementById(node.data.key);
        hightLightDom.classList.remove("tree_high_light");
        setTimeout(() => {
          hightLightDom.classList.add("tree_high_light");
          hightLightDom.scrollIntoView({
            behavior: "smooth",
          });
        }, 500);
      });
    },
    // 跳转关联节点
    jumpNodeDetail(nodeKey) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${nodeKey}`,
      });
      window.open(href, "_blank");
    },
    // 导出dfmea表格
    exportDfmea() {
      const params = {
        projectId: this.get_pid(),
        fileKey: this.$route.params.file_key,
      };
      dfmeaExcel(params).then((res) => {
        this.$message({
          showClose: true,
          message: this.$t("homeTopBar.asyncTask.message"),
          type: "success",
          duration: 5000,
        });
      });
    },
    // 更新右侧树结构
    updatedTreeIteration(treeData, failurSeverityMap, functionMap, failureMap) {
      treeData.forEach((item) => {
        if (item.type === "function" && functionMap[item.id]) {
          this.$set(item, "previous", false);
          this.$set(item, "next", false);
          if (functionMap[item.id].indexOf("all") !== -1) {
            this.$set(item, "previous", true);
            this.$set(item, "next", true);
          }
          if (functionMap[item.id].indexOf("previous") !== -1) {
            this.$set(item, "previous", true);
          }
          if (functionMap[item.id].indexOf("next") !== -1) {
            this.$set(item, "next", true);
          }
        } else if (item.type === "defect" && failureMap[item.id]) {
          this.$set(item, "previous", false);
          this.$set(item, "next", false);
          if (failureMap[item.id].indexOf("all") !== -1) {
            this.$set(item, "previous", true);
            this.$set(item, "next", true);
          }
          if (failureMap[item.id].indexOf("previous") !== -1) {
            this.$set(item, "previous", true);
          }
          if (failureMap[item.id].indexOf("next") !== -1) {
            this.$set(item, "next", true);
          }
        } else if (
          (item.type === "function" || item.type === "defect") &&
          !functionMap[item.id] &&
          !failureMap[item.id]
        ) {
          this.$set(item, "previous", false);
          this.$set(item, "next", false);
        }
        if (
          item.type === "defect" &&
          Object.prototype.hasOwnProperty.call(failurSeverityMap, item.id)
        ) {
          this.$set(item, "severity", failurSeverityMap[item.id]);
        }
        if (item.children.length) {
          this.updatedTreeIteration(
            item.children,
            failurSeverityMap,
            functionMap,
            failureMap
          );
        }
      });
    },
    // 更新右侧树结构
    updatedTree() {
      const failurSeverityMap = {};
      const functionMap = {};
      const failureMap = {};
      function setMap(map, id, value) {
        if (!map[id]) {
          map[id] = [value];
        } else {
          map[id].push(value);
        }
      }
      this.tableData.forEach((item) => {
        if (item.previousFunction) {
          setMap(functionMap, item.previousFunction.functionId, "next");
        }
        if (item.nextFunction) {
          setMap(functionMap, item.nextFunction.functionId, "previous");
        }
        if (item.previousFailure) {
          setMap(failureMap, item.previousFailure.failureId, "next");
        }
        if (item.nextFailure) {
          setMap(failureMap, item.nextFailure.failureId, "previous");
        }
        if (
          item.currentFunction &&
          item.previousFunction &&
          !item.nextFunction
        ) {
          setMap(functionMap, item.currentFunction.functionId, "previous");
        } else if (
          item.currentFunction &&
          !item.previousFunction &&
          item.nextFunction
        ) {
          setMap(functionMap, item.currentFunction.functionId, "next");
        } else if (
          item.currentFunction &&
          item.previousFunction &&
          item.nextFunction
        ) {
          setMap(functionMap, item.currentFunction.functionId, "all");
        } else if (
          item.currentFunction &&
          !item.previousFunction &&
          !item.nextFunction
        ) {
          setMap(functionMap, item.currentFunction.functionId, "none");
        }
        if (item.currentFailure && item.previousFailure && !item.nextFailure) {
          setMap(failureMap, item.currentFailure.failureId, "previous");
        } else if (
          item.currentFailure &&
          !item.previousFailure &&
          item.nextFailure
        ) {
          setMap(failureMap, item.currentFailure.failureId, "next");
        } else if (
          item.currentFailure &&
          item.previousFailure &&
          item.nextFailure
        ) {
          setMap(failureMap, item.currentFailure.failureId, "all");
        } else if (
          item.currentFailure &&
          !item.previousFailure &&
          !item.nextFailure
        ) {
          this.$nextTick(() => {
            setMap(failureMap, item.currentFailure.failureId, "none");
          });
        }
        if (
          item.previousFailure &&
          !Object.prototype.hasOwnProperty.call(
            failurSeverityMap,
            item.previousFailure.failureId
          )
        ) {
          failurSeverityMap[item.previousFailure.failureId] = item.severity;
        }
      });
      this.updatedTreeIteration(
        this.treeData,
        failurSeverityMap,
        functionMap,
        failureMap
      );
    },
    // 组装完整树结构
    buildCompleteTree(data, res) {
      data.forEach((child) => {
        if (child.children.length) {
          this.buildCompleteTree(child.children, res);
        }
        res.forEach((item) => {
          if (child.id === item.nodeKey) {
            const functions = {
              id: item.functionId,
              label: item.detail,
              type: "function",
              previous: null,
              next: null,
              children: [],
              edit: false,
            };
            item.dfmeaFailureList.forEach((failure) => {
              const obj = {
                id: failure.failureId,
                label: failure.detail,
                type: "defect",
                previous: null,
                next: null,
                children: [],
                edit: false,
                severity: failure.severity,
              };
              functions.children.push(obj);
            });
            child.children.unshift(functions);
          }
        });
      });
    },
    // 展开所有节点
    expandAll() {
      const self = this;
      self.defaultExpandedKeys = [];
      function setDefaultExpandedKeys(arr) {
        arr.forEach((item) => {
          if (item.children.length) {
            setDefaultExpandedKeys(item.children);
          }
          self.defaultExpandedKeys.push(item.id);
        });
      }
      setDefaultExpandedKeys(self.treeData);
    },
    // 检查树
    checkTree() {
      this.checkTreeFlag = !this.checkTreeFlag;
      if (!this.checkTreeFlag) return;
      const treeData = [this.buildTree(this.jm.mind.root)];
      let funcNum = 0;
      let failNum = 0;
      const functionMap = {};
      const failureMap = {};
      const params = {
        projectId: this.get_pid(),
        fileKey: this.$route.params.file_key,
      };
      this.tableData.forEach((tableRow) => {
        if (
          tableRow.currentFunction &&
          (tableRow.previousFunction || tableRow.nextFunction)
        ) {
          functionMap[tableRow.currentFunction.functionId] = true;
        }
        if (
          tableRow.currentFailure &&
          (tableRow.previousFailure || tableRow.nextFailure)
        ) {
          failureMap[tableRow.currentFailure.failureId] = true;
        }
      });
      feOptimized(params).then((number) => {
        getDistribution(params).then(async (res) => {
          this.buildCompleteTree(treeData, res);
          this.treeData = treeData;
          this.expandAll();
          await this.getRelations();
          this.updatedTree();
          res.forEach((func) => {
            if (!functionMap[func.functionId]) {
              funcNum++;
            }
            func.dfmeaFailureList.forEach((fail) => {
              if (!failureMap[fail.failureId]) {
                failNum++;
              }
            });
          });
          this.$message({
            message: `${this.$t(
              "canvas.dfmea.associationMessage4"
            )}<strong>${number}</strong>${this.$t(
              "canvas.dfmea.associationMessage5"
            )}${this.$t(
              "canvas.dfmea.associationMessage1"
            )}<strong>${funcNum}</strong>${this.$t(
              "canvas.dfmea.associationMessage2"
            )}<strong>${failNum}</strong>${this.$t(
              "canvas.dfmea.associationMessage3"
            )}`,
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
        });
      });
    },
    // 模糊搜索节点List
    getNodeList(val) {
      this.optionsLoading = true;
      get_nodes_by_fuzzykey(this.get_pid(), val).then((res) => {
        this.publicOptions = res.slice(0, 20).map((item) => {
          return {
            label: item.nodeKey + " " + item.topic,
            value: item.nodeKey,
          };
        });
        this.optionsLoading = false;
      });
    },
    // 判断是否展示优化操作按钮
    showRiskAndOptimizations(row) {
      if (
        row.previousFailure &&
        row.currentFailure &&
        row.nextFailure &&
        row.riskAndOptimizationId &&
        row.riskAndOptimizations.length &&
        row.riskAndOptimizationId ===
          row.riskAndOptimizations[row.riskAndOptimizations.length - 1]
            .riskAndOptimizationId
      ) {
        return true;
      }
    },
    // 添加优化
    async addRiskAndOptimizations(row) {
      const params = {
        projectId: this.get_pid(),
        relationId: row.relationId,
        data: {},
      };
      await addRiskAndOptimizations(params).then((res) => {
        this.getRelations();
      });
    },
    // 删除优化
    deleteRiskAndOptimizations(row) {
      const params = {
        projectId: this.get_pid(),
        riskAndOptimizationId: row.riskAndOptimizationId,
      };
      deleteRiskAndOptimizations(params).then((res) => {
        this.getRelations();
      });
    },
    // 序号处理方式
    indexMethod(index) {
      let num = index;
      for (let i = index; i >= 0; i--) {
        if (
          this.tableData[i].riskAndOptimizations.length &&
          !this.tableData[i].rowSpan
        ) {
          num--;
        }
      }
      return num + 1;
    },
    // 匹配status
    matchStatusToName(status) {
      const statusMap = {
        COMPLETED: this.$t("DFMEA.Completed"),
        CONFIRM_NOT_YET: this.$t("DFMEA.NotYetDetermined"),
        DECIDE_NOT_YET: this.$t("DFMEA.NotYetDecided"),
        DO_NOTHING: this.$t("DFMEA.NoExecute"),
        IMPLEMENT_NOT_YET: this.$t("DFMEA.NotYetExecuted"),
      };
      return statusMap[status];
    },
    // 第五步第六步失焦
    riskAndOptimizationsBlur(row, item, flag, prop) {
      this.$set(row, item, false);
      this.editRow(row, flag, prop);
    },
    // 表格合并
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 22].indexOf(columnIndex) !== -1 &&
        row.rowSpan &&
        row.riskAndOptimizations.length
      ) {
        return [row.riskAndOptimizations.length, 1];
      } else if (
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 22].indexOf(columnIndex) !== -1 &&
        !row.rowSpan &&
        row.riskAndOptimizations.length
      ) {
        return [0, 0];
      }
    },
    // 点击进入表格某一行编辑模式
    showEdit(row, flag) {
      // 关联节点时清空下拉框
      if (flag === "nodeKeyListEdit") {
        this.publicOptions = [];
      }
      this.editFlagList.forEach((item) => {
        this.$set(row, item, false);
      });
      this.$set(row, flag, true);
      this.$nextTick(() => {
        let item = null;
        item = document.getElementById(flag);
        item.focus();
      });
    },
    // 失焦事件
    itemBlur(row, flag) {
      setTimeout(() => {
        this.$set(row, flag, false);
      }, 100);
    },
    // 编辑某行
    async editRow(row, flag, item) {
      this.tableLoading = true;
      if (flag === "structure") {
        const params = {
          projectId: this.get_pid(),
          relationId: row.relationId,
          data: {
            currentItem: row.currentItem,
            nextItem: row.nextItem,
            previousItem: row.previousItem,
          },
        };
        if (item === "currentItem") {
          params.data.nextItem = "";
          params.data.previousItem = "";
          this.$set(row, "nextItem", "");
          this.$set(row, "previousItem", "");
        }
        await editItemRelations(params).then((res) => {});
      } else if (flag === "function") {
        const params = {
          projectId: this.get_pid(),
          relationId: row.relationId,
          data: {
            currentFunction:
              row.currentFunction && row.currentFunction.functionId
                ? row.currentFunction.functionId
                : row.currentFunction,
            nextFunction:
              row.nextFunction && row.nextFunction.functionId
                ? row.nextFunction.functionId
                : row.nextFunction,
            previousFunction:
              row.previousFunction && row.previousFunction.functionId
                ? row.previousFunction.functionId
                : row.previousFunction,
          },
        };
        await editFunctionRelations(params).then((res) => {});
      } else if (flag === "defect") {
        let same = false;
        let sameIndex = 0;
        let rowIndex = 0;
        let sameId = "";
        let rowId = row.relationId;
        if (row.currentFailure && row.nextFailure && row.previousFailure) {
          for (let i = 0; i < this.tableData.length; i++) {
            if (
              this.tableData[i].relationId !== row.relationId &&
              this.tableData[i].currentItem === row.currentItem &&
              this.tableData[i].previousItem === row.previousItem &&
              this.tableData[i].nextItem === row.nextItem &&
              this.tableData[i].currentFunction &&
              row.currentFunction &&
              this.tableData[i].currentFunction.functionId ===
                row.currentFunction.functionId &&
              this.tableData[i].previousFunction &&
              row.previousFunction &&
              this.tableData[i].previousFunction.functionId ===
                row.previousFunction.functionId &&
              this.tableData[i].nextFunction &&
              row.nextFunction &&
              this.tableData[i].nextFunction.functionId ===
                row.nextFunction.functionId &&
              this.tableData[i].currentFailure &&
              (this.tableData[i].currentFailure.failureId ===
                row.currentFailure ||
                this.tableData[i].currentFailure.failureId ===
                  row.currentFailure.failureId) &&
              this.tableData[i].previousFailure &&
              (this.tableData[i].previousFailure.failureId ===
                row.previousFailure ||
                this.tableData[i].previousFailure.failureId ===
                  row.previousFailure.failureId) &&
              this.tableData[i].nextFailure &&
              (this.tableData[i].nextFailure.failureId === row.nextFailure ||
                this.tableData[i].nextFailure.failureId ===
                  row.nextFailure.failureId)
            ) {
              same = true;
              sameId = this.tableData[i].relationId;
              break;
            }
          }
        }
        this.originTableData.forEach((item, index) => {
          if (sameId === item.relationId) {
            sameIndex = index + 1;
          }
          if (rowId === item.relationId) {
            rowIndex = index + 1;
          }
        });
        if (same) {
          this.$confirm(
            `${this.$t("canvas.dfmea.sameMessage1")}${rowIndex}${this.$t(
              "canvas.dfmea.sameMessage2"
            )}${sameIndex}${this.$t("canvas.dfmea.sameMessage3")}`,
            this.$t("canvas.fileDropDown.deleteForm.title"),
            {
              confirmButtonText: this.$t("btn.deleteBtn"),
              confirmButtonClass: "delete_button_class",
              cancelButtonText: this.$t("canvas.dfmea.reserve"),
              cancelButtonClass: "delete_cancel_class",
              type: "warning",
            }
          )
            .then(async () => {
              const params = {
                projectId: this.get_pid(),
                relationId: row.relationId,
              };
              deleteRelations(params).then(() => {
                this.getRelations();
                this.$message({
                  type: "success",
                  message: this.$t("canvas.fileDropDown.deleteForm.deleted"),
                });
              });
            })
            .catch(async () => {
              const params = {
                projectId: this.get_pid(),
                relationId: row.relationId,
                data: {
                  currentFailure:
                    row.currentFailure && row.currentFailure.failureId
                      ? row.currentFailure.failureId
                      : row.currentFailure,
                  nextFailure:
                    row.nextFailure && row.nextFailure.failureId
                      ? row.nextFailure.failureId
                      : row.nextFailure,
                  previousFailure:
                    row.previousFailure && row.previousFailure.failureId
                      ? row.previousFailure.failureId
                      : row.previousFailure,
                },
              };
              await editFailureRelationsRelations(params).then((res) => {});
              if (
                row.currentFailure &&
                row.nextFailure &&
                row.previousFailure &&
                !row.riskAndOptimizations.length
              ) {
                await this.addRiskAndOptimizations(row);
              }
            });
        } else {
          const params = {
            projectId: this.get_pid(),
            relationId: row.relationId,
            data: {
              currentFailure:
                row.currentFailure && row.currentFailure.failureId
                  ? row.currentFailure.failureId
                  : row.currentFailure,
              nextFailure:
                row.nextFailure && row.nextFailure.failureId
                  ? row.nextFailure.failureId
                  : row.nextFailure,
              previousFailure:
                row.previousFailure && row.previousFailure.failureId
                  ? row.previousFailure.failureId
                  : row.previousFailure,
            },
          };
          await editFailureRelationsRelations(params).then((res) => {});
          if (
            row.currentFailure &&
            row.nextFailure &&
            row.previousFailure &&
            !row.riskAndOptimizations.length
          ) {
            await this.addRiskAndOptimizations(row);
          }
        }
        // 如果FE为空 严重度也置为空
        if (item === "previousFailure" && !row.previousFailure) {
          this.$set(row, "severity", null);
        } else if (item === "previousFailure" && row.previousFailure) {
          const failureMap = {};
          const params = {
            projectId: this.get_pid(),
            fileKey: this.$route.params.file_key,
          };
          await getDistribution(params).then((res) => {
            res.forEach((func) => {
              func.dfmeaFailureList.forEach((failure) => {
                failureMap[failure.failureId] = failure.severity;
              });
            });
          });
          this.$set(row, "severity", failureMap[row.previousFailure]);
        }
      } else if (flag === "riskAndOptimizations") {
        const params = {
          projectId: this.get_pid(),
          riskAndOptimizationId: row.riskAndOptimizationId,
          data: {
            action: row.action,
            ap: row.ap ? row.ap : null,
            completeDate: row.completeDate,
            d: row.d ? row.d : null,
            dc: row.dc,
            nodeKeyList: row.nodeKeyList,
            o: row.o ? row.o : null,
            pc: row.pc,
            principal: row.principal,
            status: row.status,
            targetCompleteDate: row.targetCompleteDate,
          },
        };
        await editRiskAndOptimizations(params);
      } else if (item === "severity") {
        const params = {
          projectId: this.get_pid(),
          failureId: row.previousFailure.failureId,
          data: {
            detail: row.previousFailure.detail,
            severity: row.severity ? row.severity : null,
          },
        };
        await editDfmeaFailures(params).then((res) => {
          this.$set(row, "severityEdit", false);
        });
      }
      // 重新赋值本行数据
      const params = {
        projectId: this.get_pid(),
        fileKey: this.$route.params.file_key,
      };
      const arr = [];
      await getRelations(params).then(async (res) => {
        this.originTableData = res;
        const promiseList = [];
        res.forEach((item) => {
          if (
            item.previousFailure &&
            item.previousFailure.failureId &&
            item.previousFunction &&
            item.previousFunction.functionId
          ) {
            const params = {
              projectId: this.get_pid(),
              functionId: item.previousFunction.functionId,
            };
            promiseList.push(getDfmeaFailures(params));
          }
        });
        if (promiseList.length) {
          const resList = await Promise.all(promiseList);
          res.forEach((item) => {
            resList.forEach((res) => {
              res.forEach((failures) => {
                if (
                  item.previousFailure &&
                  failures.failureId === item.previousFailure.failureId
                ) {
                  this.$set(item, "severity", failures.severity);
                }
              });
            });
          });
        }
        // 如果有多个优化拆分成多条数据
        res.forEach((item) => {
          if (item.riskAndOptimizations.length) {
            item.riskAndOptimizations.forEach((risk, index) => {
              const obj = Object.assign({}, item, risk);
              if (index === 0) {
                obj.rowSpan = true;
              }
              arr.push(obj);
            });
          } else {
            arr.push(item);
          }
        });
        this.tableData.forEach((item, index) => {
          this.$nextTick(() => {
            Object.assign(item, arr[index]);
          });
        });
        this.editFlagList.forEach((item) => {
          this.$set(row, item, false);
        });
        // 表格编辑同步右侧树结构
        this.updatedTree();
        this.tableLoading = false;
      });
    },
    // 寻找展示的topic
    findTopicFn(node, key, arr) {
      if (node.data.key === key) {
        arr.push(node.topic);
        return;
      }
      if (node.children.length) {
        node.children.forEach((item) => {
          this.findTopicFn(item, key, arr);
        });
      }
    },
    findTopic(key) {
      const arr = [];
      if (key) {
        this.findTopicFn(this.jm.mind.root, key, arr);
        return key ? key + " " + arr[0] : "";
      } else {
        return "";
      }
    },
    // 找寻当前结构下拉选项
    findCurrentItemNode(node, arr) {
      if (node.children.length) {
        node.children.forEach((item) => {
          this.findCurrentItemNode(item, arr);
        });
        if (!node.isroot) {
          arr.push({
            label: node.data.key + " " + node.topic,
            value: node.data.key,
          });
        }
      }
    },
    // 找寻下一结构下拉选项
    pushNextItem(node, arr) {
      if (node.children.length) {
        node.children.forEach((item) => {
          this.pushNextItem(item, arr);
        });
      }
      arr.push({
        label: node.data.key + " " + node.topic,
        value: node.data.key,
      });
    },
    findNextItemNode(node, arr, key, flag) {
      if (node.children.length) {
        if (node.data.key === key) {
          node.children.forEach((item) => {
            this.pushNextItem(item, arr);
          });
        } else {
          node.children.forEach((item) => {
            this.findNextItemNode(item, arr, key);
          });
        }
      }
    },
    // 寻找上一级结构
    findPreviousItemNode(node, arr, key) {
      // 递归终止条件：如果当前节点为空或者当前节点的id等于目标id，则返回空数组
      if (!node || node.data.key === key) {
        return [];
      }
      // 递归查找当前节点的父节点
      for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        // 如果当前节点的子节点中包含目标节点，则将当前节点加入到结果中，并返回结果\
        if (child.data.key === key) {
          return [
            {
              label: node.data.key + " " + node.topic,
              value: node.data.key,
            },
          ];
        }
        // 递归查找子节点的父节点
        const result = this.findPreviousItemNode(child, arr, key);
        if (result.length > 0) {
          // 如果找到目标节点，则将当前节点加入到结果中，并返回结果
          return [
            {
              label: node.data.key + " " + node.topic,
              value: node.data.key,
            },
            ...result,
          ];
        }
      }
      // 如果当前节点的子节点中没有找到目标节点，则返回空数组
      return [];
    },
    // 构造下拉框
    async buildOptions(event, row, flag) {
      if (!event) return;
      let arr = [];
      this.publicOptions = [];
      this.optionsLoading = true;
      if (flag === "currentItem") {
        this.findCurrentItemNode(this.jm.mind.root, arr);
      } else if (flag === "nextItem") {
        this.findNextItemNode(this.jm.mind.root, arr, row.currentItem);
      } else if (flag === "previousItem") {
        arr = this.findPreviousItemNode(
          this.jm.mind.root,
          arr,
          row.currentItem
        );
      } else if (
        flag === "previousFunction" ||
        flag === "currentFunction" ||
        flag === "nextFunction"
      ) {
        const map = {
          previousFunction: "previousItem",
          currentFunction: "currentItem",
          nextFunction: "nextItem",
        };
        const params = {
          projectId: this.get_pid(),
          nodeKey: row[map[flag]],
        };
        const res = await getDfmeaFunctions(params);
        res.forEach((item) => {
          const obj = {
            value: item.functionId,
            label: item.detail,
          };
          arr.push(obj);
        });
      } else if (
        flag === "previousFailure" ||
        flag === "currentFailure" ||
        flag === "nextFailure"
      ) {
        const map = {
          previousFailure: "previousFunction",
          currentFailure: "currentFunction",
          nextFailure: "nextFunction",
        };
        const params = {
          projectId: this.get_pid(),
          functionId: row[map[flag]].functionId,
        };
        const res = await getDfmeaFailures(params);
        res.forEach((item) => {
          const obj = {
            value: item.failureId,
            label: item.detail,
          };
          arr.push(obj);
        });
      }
      this.publicOptions = arr;
      this.optionsLoading = false;
    },
    // 获取dfeam的关联关系详情
    async getRelations() {
      this.tableLoading = true;
      this.tableData = [];
      const failureMap = {};
      const params = {
        projectId: this.get_pid(),
        fileKey: this.$route.params.file_key,
      };
      await getDistribution(params).then((res) => {
        res.forEach((func) => {
          func.dfmeaFailureList.forEach((failure) => {
            failureMap[failure.failureId] = failure.severity;
          });
        });
      });
      await getRelations(params).then(async (res) => {
        this.originTableData = res;
        const arr = [];
        res.forEach((item) => {
          if (item.previousFailure && item.previousFailure.failureId) {
            this.$set(
              item,
              "severity",
              failureMap[item.previousFailure.failureId]
            );
          }
        });
        // 如果有多个优化拆分成多条数据
        res.forEach((item) => {
          if (item.riskAndOptimizations.length) {
            item.riskAndOptimizations.forEach((risk, index) => {
              const obj = Object.assign({}, item, risk);
              if (index === 0) {
                obj.rowSpan = true;
              }
              arr.push(obj);
            });
          } else {
            arr.push(item);
          }
        });
        this.tableData = arr;
        this.tableLoading = false;
      });
    },
    // 表格增加一行
    addLine() {
      const params = {
        projectId: this.get_pid(),
        fileKey: this.$route.params.file_key,
      };
      addRelations(params).then((res) => {
        this.getRelations();
      });
    },
    // 删除一行
    deleteRow(row) {
      this.$confirm(
        this.$t("canvas.dfmea.cannotBeRestored"),
        this.$t("canvas.fileDropDown.deleteForm.title"),
        {
          confirmButtonText: this.$t("btn.deleteBtn"),
          confirmButtonClass: "delete_button_class",
          cancelButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "delete_cancel_class",
          type: "warning",
        }
      )
        .then(() => {
          const params = {
            projectId: this.get_pid(),
            relationId: row.relationId,
          };
          deleteRelations(params).then(() => {
            this.getRelations();
            this.$message({
              type: "success",
              message: this.$t("canvas.dfmea.deleted"),
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("canvas.dfmea.cancelDelete"),
          });
        });
    },
    // 表格单元格样式
    cellStyle(obj) {
      if (
        !obj.row.currentItem &&
        (obj.columnIndex === 1 || obj.columnIndex === 3)
      ) {
        return {
          background: "#e5e5e5",
          "pointer-events": "none",
        };
      }
      if (
        (!obj.row.currentItem || !obj.row.previousItem || !obj.row.nextItem) &&
        (obj.columnIndex === 4 ||
          obj.columnIndex === 5 ||
          obj.columnIndex === 6)
      ) {
        return {
          background: "#e5e5e5",
          "pointer-events": "none",
        };
      }
      if (
        (!obj.row.currentFunction ||
          !obj.row.previousFunction ||
          !obj.row.nextFunction) &&
        (obj.columnIndex === 7 ||
          obj.columnIndex === 8 ||
          obj.columnIndex === 9 ||
          obj.columnIndex === 10)
      ) {
        return {
          background: "#e5e5e5",
          "pointer-events": "none",
        };
      }
      if (!obj.row.previousFailure && obj.columnIndex === 8) {
        return {
          background: "#e5e5e5",
          "pointer-events": "none",
        };
      }
      if (
        (!obj.row.currentFailure ||
          !obj.row.previousFailure ||
          !obj.row.nextFailure) &&
        [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21].indexOf(
          obj.columnIndex
        ) !== -1
      ) {
        return {
          background: "#e5e5e5",
          "pointer-events": "none",
        };
      }
      if (
        obj.row.riskAndOptimizations.length &&
        obj.row.riskAndOptimizationId ===
          obj.row.riskAndOptimizations[0].riskAndOptimizationId &&
        [16, 17, 18, 19, 20, 21].indexOf(obj.columnIndex) !== -1
      ) {
        return {
          background: "#e5e5e5",
          "pointer-events": "none",
        };
      }
    },
    // 失效联想
    querySearch(queryString, cb) {
      const list = [
        this.$t("canvas.dfmea.associate1"),
        this.$t("canvas.dfmea.associate2"),
        this.$t("canvas.dfmea.associate3"),
        this.$t("canvas.dfmea.associate4"),
        this.$t("canvas.dfmea.associate5"),
        this.$t("canvas.dfmea.associate6"),
        this.$t("canvas.dfmea.associate7"),
      ];
      const results = [];
      const label = this.$refs.tree.getNode(this.currentData).parent.data.label;
      list.forEach((item) => {
        const obj = {
          value: "..." + item,
        };
        results.push(obj);
      });
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    handleSelectDefect(value, data, node) {
      data.label = node.parent.data.label + value.value.split("...")[1];
    },
    // 保存严重度设置
    saveDefectSeverity() {
      this.$refs["severityRuleForm"].validate((valid) => {
        if (valid) {
          const params = {
            projectId: this.get_pid(),
            failureId: this.currentDefectCopy.id,
            data: {
              detail: this.currentDefectCopy.label,
              severity: this.currentDefectCopy.severity
                ? this.currentDefectCopy.severity
                : null,
            },
          };
          editDfmeaFailures(params).then((res) => {
            this.currentDefect.severity = this.currentDefectCopy.severity;
            this.currentDefect = {};
            this.currentDefectCopy = {};
            this.defectDialogVisible = false;
            this.getRelations();
          });
        }
      });
    },
    // 失效设置严重度
    showDefectDialog(data) {
      this.currentDefect = data;
      this.currentDefectCopy = JSON.parse(JSON.stringify(data));
      this.defectDialogVisible = true;
    },
    // 定位锚点
    checkActiveItem(event) {
      if (event.target.scrollLeft >= 0 && event.target.scrollLeft < 430) {
        this.activeCircle = "1";
      } else if (
        event.target.scrollLeft >= 430 &&
        event.target.scrollLeft < 990
      ) {
        this.activeCircle = "2";
      } else if (
        event.target.scrollLeft >= 990 &&
        event.target.scrollLeft < 2050
      ) {
        this.activeCircle = "3";
      } else if (event.target.scrollLeft >= 2050) {
        this.activeCircle = "4";
      }
    },
    // 锚点跳转
    navigateTo(name) {
      this.activeCircle = name;
      const arr = [0, 430, 990, 2050];
      this.$nextTick(() => {
        const tableBody = document.querySelector(".el-table__body-wrapper");
        tableBody.scrollLeft = arr[name - 1];
      });
    },
    // 表头样式
    headerCellStyle(obj) {
      if (obj.rowIndex == 0) {
        return { background: "#B4D0F0", color: "#000000" };
      } else {
        return { color: "#000000" };
      }
    },
    // 绑定右侧拖拽
    bindRightMoveLine() {
      const jmDom = document.querySelector(".dfmea_model");
      const self = this;
      const resizeFn = (element, position) => {
        let maxAllowWidth = window.innerWidth / 2;
        let resize = document.querySelector(".right-resize");
        let bottomDrawer = document.querySelector(".bottom-drawer");
        resize.style[position] = "0";
        resize.addEventListener("mousedown", function (e) {
          element.style.transition = "none";
          let startX = e.pageX;
          let width = element.offsetWidth;
          resize.classList.add("active");
          document.addEventListener("mousemove", move);
          let timer = null;

          function move(e) {
            let moveX; // 左右这里切换计算顺序即可
            if (position === "left") moveX = startX - e.pageX;
            if (position === "right") moveX = e.pageX - startX;
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (maxAllowWidth >= width + moveX) {
                element.style.width = width + moveX + "px";
                bottomDrawer.style.width =
                  window.innerWidth - parseInt(element.style.width) - 4 + "px";
                jmDom.style.width =
                  "calc(98.5vw - " + element.style.width + ")";
              }
            }, 5);
          }
          document.addEventListener(
            "mouseup",
            function () {
              document.removeEventListener("mousemove", move);
              resize.classList.remove("active");
            },
            { once: true }
          );
        });
        resize.addEventListener("mouseup", function (e) {
          element.style.transition = "all 0.5s";
        });
      };
      let right = document.querySelector(".right-drawer");
      resizeFn(right, "left");
    },
    // 绑定底部拖拽
    bindBottomMoveLine() {
      const self = this;
      const jmDom = document.querySelector(".dfmea_model");
      const resizeFn = (element, position) => {
        let maxAllowHeight = 800;
        let resize = document.querySelector(".bottom-resize");
        resize.style[position] = "0";
        resize.addEventListener("mousedown", function (e) {
          element.style.transition = "none";
          let startY = e.pageY;
          let Height = element.offsetHeight;
          resize.classList.add("active");
          document.addEventListener("mousemove", move);
          let timer = null;

          function move(e) {
            let moveY; // 左右这里切换计算顺序即可
            if (position === "top") moveY = startY - e.pageY;
            if (position === "bottom") moveY = e.pageX - startY;
            clearTimeout(timer);
            timer = setTimeout(() => {
              if (maxAllowHeight >= Height + moveY) {
                element.style.height = Height + moveY + "px";
                jmDom.style.height =
                  "calc((90vh - 98px) - " + element.style.height + ")";
              }
            }, 5);
          }

          document.addEventListener(
            "mouseup",
            function () {
              document.removeEventListener("mousemove", move);
              resize.classList.remove("active");
            },
            { once: true }
          );
        });
        resize.addEventListener("mouseup", function (e) {
          element.style.transition = "all 0.5s";
        });
      };
      let right = document.querySelector(".bottom-drawer");
      resizeFn(right, "top");
    },
    // 删除节点
    removeNode(data) {
      if (data.type === "function") {
        const params = {
          projectId: this.get_pid(),
          functionId: data.id,
        };
        getDfmeaFailures(params).then((res) => {
          if (res && res.length) {
            this.$confirm(
              this.$t("canvas.dfmea.removeNodeMessage"),
              this.$t("canvas.fileDropDown.deleteForm.title"),
              {
                confirmButtonText: this.$t("btn.deleteBtn"),
                confirmButtonClass: "delete_button_class",
                cancelButtonText: this.$t("btn.cancelBtn"),
                cancelButtonClass: "delete_cancel_class",
                type: "warning",
              }
            )
              .then(() => {
                const params = {
                  projectId: this.get_pid(),
                  functionId: data.id,
                };
                deleteDfmeaFunctions(params).then(async (res) => {
                  this.$refs.tree.remove(data);
                  this.$message({
                    type: "success",
                    message: this.$t("canvas.dfmea.deleted"),
                  });
                  await this.getRelations();
                  this.updatedTree();
                });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: this.$t("canvas.dfmea.cancelDelete"),
                });
              });
          } else {
            const params = {
              projectId: this.get_pid(),
              functionId: data.id,
            };
            deleteDfmeaFunctions(params).then(async (res) => {
              this.$refs.tree.remove(data);
              await this.getRelations();
              this.updatedTree();
            });
          }
        });
      } else if (data.type === "defect") {
        const params = {
          projectId: this.get_pid(),
          failuresId: data.id,
        };
        deleteDfmeaFailures(params).then(async (res) => {
          this.$refs.tree.remove(data);
          await this.getRelations();
          this.updatedTree();
        });
      }
    },
    // 隐藏选中节点下的功能或者失效
    hideFunctionOrDefect(data) {
      for (let i = data.children.length - 1; i >= 0; i--) {
        if (
          data.children[i].type === "function" ||
          data.children[i].type === "defect"
        ) {
          data.children.splice(i, 1);
        }
      }
    },
    // 选中节点
    treecurrentChange(data) {
      let self = this;
      // 开启延时器，200ms的间隔用来区分单击和双击，解决双击时执行两次单击事件
      clearTimeout(self.labelClickTimer);
      self.labelClickTimer = setTimeout(() => {
        //单击事件
        self.currentNodeId = data.id;
        let flag = false;
        if (data.children.length) {
          data.children.forEach((item) => {
            if (item.type === "function" || item.type === "defect") {
              flag = true;
            }
          });
        }
        if (flag) {
          self.hideFunctionOrDefect(data);
        } else {
          if (data.type === "structure") {
            self.getDfmeaFunctions(data);
          } else if (data.type === "function") {
            self.getDfmeaFailures(data);
          }
        }
      }, 200);
    },
    // 判断失效是否上下关联
    failuresPreviousOrNext(obj) {
      let previous = false;
      let next = false;
      this.tableData.forEach((item) => {
        if (
          item.currentFailure &&
          item.currentFailure.failureId === obj.id &&
          item.previousFailure &&
          item.previousFailure.failureId
        ) {
          previous = true;
        }
        if (
          item.currentFailure &&
          item.currentFailure.failureId === obj.id &&
          item.nextFailure &&
          item.nextFailure.failureId
        ) {
          next = true;
        }
        if (item.previousFailure && item.previousFailure.failureId == obj.id) {
          next = true;
        }
        if (item.nextFailure && item.nextFailure.failureId == obj.id) {
          previous = true;
        }
      });
      obj.previous = previous;
      obj.next = next;
    },
    // 获取功能下的失效
    getDfmeaFailures(data, type) {
      const params = {
        projectId: this.get_pid(),
        functionId: data.id,
      };
      getDfmeaFailures(params).then((res) => {
        if (!data.children.length) {
          res.forEach((item, index) => {
            const obj = {
              id: item.failureId,
              label: item.detail,
              type: "defect",
              previous: item.previous,
              next: item.next,
              children: [],
              edit: false,
              severity: item.severity,
            };
            this.failuresPreviousOrNext(obj);
            this.$refs.tree.append(obj, data);
            if (type === "add" && index === res.length - 1) {
              this.editTreeDetail(obj);
            }
          });
        } else {
          res.forEach((item, index) => {
            const filter = data.children.filter((child) => {
              return child.id == item.failureId;
            });
            if (!filter.length) {
              const obj = {
                id: item.failureId,
                label: item.detail,
                type: "defect",
                previous: item.previous,
                next: item.next,
                children: [],
                edit: false,
                severity: item.severity,
              };
              this.failuresPreviousOrNext(obj);
              this.$refs.tree.append(obj, data);
              if (type === "add" && index === res.length - 1) {
                this.editTreeDetail(obj);
              }
            }
          });
        }
        this.$nextTick(() => {
          this.$refs.tree.store.nodesMap[data.id].expanded = true;
        });
      });
    },
    // 判断功能是否关联上下
    functionPreviousOrNext(obj) {
      let previous = false;
      let next = false;
      this.tableData.forEach((item) => {
        if (
          item.currentFunction &&
          item.currentFunction.functionId === obj.id &&
          item.previousFunction &&
          item.previousFunction.functionId
        ) {
          previous = true;
        }
        if (
          item.currentFunction &&
          item.currentFunction.functionId === obj.id &&
          item.nextFunction &&
          item.nextFunction.functionId
        ) {
          next = true;
        }
        if (
          item.previousFunction &&
          item.previousFunction.functionId == obj.id
        ) {
          next = true;
        }
        if (item.nextFunction && item.nextFunction.functionId == obj.id) {
          previous = true;
        }
      });
      obj.previous = previous;
      obj.next = next;
    },
    // 获取结构下的功能
    getDfmeaFunctions(data, type) {
      const params = {
        projectId: this.get_pid(),
        nodeKey: data.id,
      };
      getDfmeaFunctions(params).then((res) => {
        if (!data.children.length) {
          res.forEach((item, index) => {
            const obj = {
              id: item.functionId,
              label: item.detail,
              type: "function",
              previous: item.previous,
              next: item.next,
              children: [],
              edit: false,
            };
            this.functionPreviousOrNext(obj);
            this.$refs.tree.append(obj, data);
            if (type === "add" && index === res.length - 1) {
              this.editTreeDetail(obj);
            }
          });
        } else {
          res.forEach((item, index) => {
            const filter = data.children.filter((child) => {
              return child.id == item.functionId;
            });
            if (!filter.length) {
              const obj = {
                id: item.functionId,
                label: item.detail,
                type: "function",
                previous: item.previous,
                next: item.next,
                children: [],
                edit: false,
              };
              this.functionPreviousOrNext(obj);
              for (let i = 0; i < data.children.length; i++) {
                if (data.children[i].type === "structure") {
                  this.$refs.tree.insertBefore(obj, data.children[i]);
                  break;
                } else if (i == data.children.length - 1) {
                  this.$refs.tree.insertAfter(obj, data.children[i]);
                  break;
                }
              }
              if (type === "add" && index === res.length - 1) {
                this.editTreeDetail(obj);
              }
            }
          });
        }
        this.$nextTick(() => {
          this.$refs.tree.store.nodesMap[data.id].expanded = true;
        });
      });
    },
    // 编辑label
    editTreeDetail(data) {
      clearTimeout(this.labelClickTimer);
      this.currentData = data;
      if (data.type !== "structure") {
        data.edit = true;
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.$refs["label-input"]) {
              this.$refs["label-input"].focus();
            }
            if (this.$refs["label-autocomplete"]) {
              this.$refs["label-autocomplete"].focus();
            }
          });
        }, 500);
      }
    },
    // 编辑label失焦事件
    editTreeDetailBlur(data) {
      if (data.type === "function") {
        const params = {
          projectId: this.get_pid(),
          functionId: data.id,
          data: {
            detail: data.label,
          },
        };
        editDfmeaFunctions(params).then((res) => {
          data.edit = false;
          this.getRelations();
        });
      } else if (data.type === "defect") {
        setTimeout(() => {
          const params = {
            projectId: this.get_pid(),
            failureId: data.id,
            data: {
              detail: data.label,
              severity: data.severity,
            },
          };
          editDfmeaFailures(params).then((res) => {
            data.edit = false;
            this.getRelations();
          });
        }, 200);
      }
      this.currentData = null;
    },
    // 展开节点
    nodeExpand(data) {},
    addFunction(data) {
      const params = {
        projectId: this.get_pid(),
        nodeKey: data.id,
        data: {
          detail: this.$t("canvas.dfmea.functionDescription"),
        },
      };
      addDfmeaFunctions(params).then((res) => {
        this.getDfmeaFunctions(data, "add");
      });
    },
    addDefect(data) {
      const params = {
        projectId: this.get_pid(),
        functionId: data.id,
        data: {
          detail: data.label,
          severity: null,
        },
      };
      addDfmeaFailures(params).then((res) => {
        this.getDfmeaFailures(data, "add");
      });
    },
    buildTree(source) {
      const obj = {
        id: source.data.key,
        label: source.topic,
        type: "structure",
      };
      const arr = [];
      if (source.children && source.children) {
        source.children.forEach((item) => {
          arr.push(this.buildTree(item));
        });
      }
      obj.children = arr;
      return obj;
    },
    rightCollapseFn() {
      const jmDom = document.querySelector(".dfmea_model");
      this.rightIsCollapse = !this.rightIsCollapse;
      if (!this.rightIsCollapse) {
        this.$refs["bottom-drawer"].style.width = "98%";
        jmDom.style.width = "98vw";
      } else {
        this.$refs["right-drawer"].style.width = "30%";
        this.$refs["bottom-drawer"].style.width = "69.8%";
        jmDom.style.width = "70%";
      }
    },
    bottomCollapseFn() {
      const jmDom = document.querySelector(".dfmea_model");
      this.bottomIsCollapse = !this.bottomIsCollapse;
      if (!this.bottomIsCollapse) {
        jmDom.style.height = "calc(90vh - 98px)";
      } else {
        jmDom.style.height = "calc(90vh - 498px)";
        this.$refs["bottom-drawer"].style.height = "400px";
      }
    },
    goKnowledge() {
      const nowLang = localStorage.getItem("lang") || "zh";
      if (nowLang == "zh") {
        window.open(
          "https://ms.ytdevops.com/pubKnowledge/1db7562f-6f55-4d30-907c-390163fe43b8?elementId=01HGWCMSY40YCS91Z1T9YVD03W",
          "_blank"
        );
      } else if (nowLang == "en") {
        window.open(
          "https://ms.ytdevops.com/pubKnowledge/c53738e5-f7bb-4b39-a194-843f86659a96?elementId=01HGWCMSY40YCS91Z1T9YVD03W",
          "_blank"
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes light {
  from {
    font-weight: bold;
    color: #000;
  }
  to {
    font-weight: bold;
    color: orange;
  }
}
.tree_high_light {
  animation: light 600ms 5;
}

.autocomplete_class .el-autocomplete-suggestion {
  width: 200px;
}
.high_light {
  background-color: yellow;
}
.function_text {
  color: #184fa9;
}
.defect_text {
  color: #ff5733;
}
.tree-input-wrap {
  ::v-deep .el-input__inner {
    height: 26px;
  }
}
.el-table {
  ::v-deep .cell {
    word-wrap: break-word;
    word-break: normal;
  }
}
.node_key_list {
  color: rgb(0, 0, 238);
  &:hover {
    text-decoration: underline;
  }
}
.not-edit {
  min-height: 24px;
  cursor: pointer;
  &:hover {
    background-color: #e5e5e5;
  }
}
.detail-span {
  height: 100%;
  width: 76%;
  min-width: 0.5208rem;
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    background-color: #8ac0ff;
  }
}
.circle-nav {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  gap: 20px;
}
.circle-btn {
  width: 13px;
  height: 13px;
  margin: 0;
  padding: 0;
  background-color: rgba(204, 204, 204, 0.6);
}
.active-circle {
  background-color: rgba(24, 79, 169, 0.6);
}
/*拖拽区div样式*/
.right-resize {
  cursor: e-resize;
  width: 6px; /*no*/
  height: 100%;
  font-size: 32px;
  user-select: none;
  position: absolute;
  left: -6px !important;
}

.right-resize.active {
  background-color: #0090f1;
}

.right-resize:hover {
  background-color: #0090f1;
}
.bottom-resize {
  cursor: n-resize;
  width: 100%; /*no*/
  height: 6px;
  font-size: 32px;
  user-select: none;
  position: absolute;
  top: -6px;
}

.bottom-resize.active {
  background-color: #0090f1;
}

.bottom-resize:hover {
  background-color: #0090f1;
}
.tree-structure {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #184fa9;
  vertical-align: text-top;
  position: relative;
  &::before {
    content: " ";
    position: absolute;
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #184fa9;
    top: calc(50% - 2px);
    left: calc(50% - 2px);
  }
}
.tree-function {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid #184fa9;
  border-radius: 2px;
  vertical-align: text-top;
  position: relative;
  &::before {
    content: " ";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 50%;
    background-color: #fff;
    top: 0;
    left: 0;
  }
  &::after {
    content: " ";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 50%;
    background-color: #fff;
    bottom: 0;
    left: 0;
  }
}
.tree-defect {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid #ff5733;
  border-radius: 2px;
  vertical-align: text-top;
  position: relative;
  &::before {
    content: " ";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 50%;
    background-color: #fff;
    top: 0;
    left: 0;
  }
  &::after {
    content: " ";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 50%;
    background-color: #fff;
    bottom: 0;
    left: 0;
  }
}
.tree-function-link-before {
  &::before {
    background-color: #184fa9;
  }
}
.tree-function-link-after {
  &::after {
    background-color: #184fa9;
  }
}
.tree-defect-link-before {
  &::before {
    background-color: #ff5733;
  }
}
.tree-defect-link-after {
  &::after {
    background-color: #ff5733;
  }
}
.tree-structure-add,
.tree-function-add,
.tree-defect-add {
  margin: 4px;
}
.tree-defect-add {
  display: inline-block;
  font-size: 12px;
  width: 16px;
  height: 16px;
  color: #ff5733;
  border: 1px solid #ff5733;
  border-radius: 50%;
  text-align: center;
  line-height: 14px;
}
.dfmea-drawer-wrap {
  .rightIsCollapse {
    width: 10px !important;
    min-width: 10px !important;
    border: none !important;
    box-shadow: none !important;
    .right-title {
      opacity: 0;
    }
  }
  .right-drawer {
    position: fixed;
    right: 0;
    top: 1rem;
    height: calc(100% - 1rem);
    width: 30%;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(229, 229, 229, 1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    z-index: 2000;
    transition: all 0.5s;
    background-color: #fff;
    min-width: 200px;
    max-width: 1000px;
    .right-title {
      width: 100%;
      height: 42px;
      background-color: rgba(24, 79, 169, 1);
      border-radius: 5px 5px 0 0;
      color: #fff;
      padding: 10px;
      text-align: left;
      font-size: 16px;
      font-weight: bold;
    }
    .right-bottom {
      width: 100%;
      height: calc(100% - 42px);
      display: flex;
      position: relative;
      .search-distribution {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 99;
        cursor: pointer;
        & > img {
          width: 24px;
          height: 24px;
        }
      }
      .right-collapse {
        width: 20px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .right-content {
        width: calc(100% - 20px);
        height: 100%;
        overflow-y: scroll;
        overflow-x: scroll;
        padding-bottom: 40px;
        position: relative;
        ::v-deep .tree {
          color: #000;
          font-size: 18px;
          background: transparent;
          /* 树形结构节点添加连线 */
          .el-tree-node {
            position: relative;
            padding-left: 16px;
            .el-tree-node__content {
              height: 40px;
              padding-top: 12px;
              padding-left: 0 !important;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: block;
              text-align: left;
              .el-tree-node__expand-icon {
                // display: inline-block;
                // width: 0;
                // height: 0;
                padding: 0;
                font-size: 18px;
                color: #000;
                &.expanded {
                  // transform: none;
                }

                &::before {
                  // content: "";
                }

                &.is-leaf {
                  display: none;
                }
              }

              .el-tree-node__label {
              }
            }

            &:last-child:before {
              height: 50px;
            }

            &:before {
              content: "";
              left: -4px;
              position: absolute;
              right: auto;
              border-width: 1px;
              border-left: 2px solid #a6a6a6;
              bottom: 0px;
              height: 100%;
              top: -26px;
              width: 1px;
            }

            &:after {
              content: "";
              left: -4px;
              position: absolute;
              right: auto;
              border-width: 1px;
              border-top: 2px solid #a6a6a6;
              height: 20px;
              top: 24px;
              width: 18px;
            }
          }

          & > .el-tree-node {
            & > .el-tree-node__content {
              .el-tree-node__expand-icon {
              }
            }

            &::after {
              border: 0px solid;
            }
          }

          .el-tree-node__children {
            padding-left: 12px;
          }
        }
      }
    }
  }
  .bottomIsCollapse {
    height: 40px !important;
    min-height: 40px !important;
    border: none !important;
    .bottom-collapse {
      width: 100% !important;
    }
    .bottom-table {
      width: 0 !important;
    }
  }
  .bottom-drawer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 69.8%;
    height: 400px;
    z-index: 2000;
    display: flex;
    border: 1px solid rgba(229, 229, 229, 1);
    background-color: #fff;
    transition: all 0.5s;
    max-height: 1000px;
    min-height: 100px;
    .bottom-collapse {
      width: 40px;
      padding-top: 20px;
      cursor: pointer;
      & > div {
        transform: rotate(90deg);
      }
    }
    .bottom-table {
      width: calc(100% - 40px);
      padding-top: 20px;
      padding-right: 10px;
      overflow: scroll;
    }
  }
}
</style>
();
