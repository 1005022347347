<template>
  <div style="position: relative">
    <div class="hara_header">
      <div class="menu">
        <div
          class="item pingshenmenu"
          :class="topClass == 0 ? 'bottom-border' : ''"
          @click="changeMode('receiving')"
        >
          {{ $t("DFMEA.ReceivedTasks") }}
        </div>
        <div
          class="item pingshenmenu"
          :class="topClass == 1 ? 'bottom-border' : ''"
          @click="changeMode('sending')"
        >
          {{ $t("tara.MyAssignedTasks") }}
        </div>
        <div
          class="item pingshenmenu"
          :class="topClass == 2 ? 'bottom-border' : ''"
          @click="changeMode('reviewing')"
        >
          {{ $t("tara.OptimizationSteps") }}
        </div>
        <i
          class="iconfont"
          :class="is_show_chart ? 'icon-chart_on' : 'icon-chart hover-icon'"
          style="
            position: relative;
            right: 0.082rem;
            top: 6px;
            color: rgb(48, 100, 143);
            font-size: 16px;
            cursor: pointer;
            height: fit-content;
          "
          @click="show_chart"
        >
        </i>
      </div>

      <div
        v-if="is_man"
        style="margin-bottom: 25px; color: #184fa9; cursor: pointer"
        @click="add_taraVisible = true"
      >
        <i class="iconfont icon-a-setting1"> </i>
      </div>
    </div>
    <!-- 我收到的任务 -->
    <div v-show="(mode === 'receiving') & !is_show_chart">
      <el-table
        ref="tara_filterTable1"
        height="80vh"
        :data="receivingData"
        style="width: 100%; background: white"
        :header-cell-style="{
          color: '#383838',
        }"
        :cell-style="changeCellStyle"
        @filter-change="filterChange"
        @row-click="click_row"
      >
        <el-table-column
          type="index"
          width="200"
          label="Key"
          column-key="nodeKey"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span style="color: #ffc302">{{ scope.row.nodeKey }}</span>
          </template>
        </el-table-column>
        <el-table-column
          type="index"
          width="400"
          :label="$t('tara.t1')"
          column-key="topic"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.topic }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('tara.t2')"
          column-key="strategy"
          width="200"
          :filters="[
            {
              text: $t('tara.t3'),
              value: 'APPROVE',
            },
            {
              text: $t('tara.t4'),
              value: 'LESS',
            },
            {
              text: $t('tara.t5'),
              value: 'AVOID',
            },
            {
              text: $t('tara.t6'),
              value: 'TARNS',
            },
          ]"
        >
          <template slot-scope="scope">
            <span>{{
              strategy_map[$t("tara.tara_lang")][scope.row.strategy]
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :label="$t('tara.风险处置措施')"
          :min-width="240"
          show-overflow-tooltip
          column-key="measure"
        >
          <template slot-scope="scope">{{ scope.row.measures }}</template>
        </el-table-column> -->
        <el-table-column
          prop="status"
          :label="$t('tara.t7')"
          width="150"
          show-overflow-tooltip
          column-key="status"
          :filters="[
            {
              text: $t('tara.t8'),
              value: 'TO_DO',
            },
            {
              text: $t('tara.t9'),
              value: 'IN_PROGRESS',
            },
            {
              text: $t('tara.t10'),
              value: 'DONE',
            },
          ]"
        >
          <template slot-scope="scope">
            <el-dropdown
              trigger="click"
              @command="handleCommand_status($event, scope.row)"
            >
              <span class="el-dropdown-link">
                <el-button
                  size="mini"
                  :class="{
                    status_todo: scope.row.status == 'TO_DO',
                    status_progress: scope.row.status == 'IN_PROGRESS',
                    status_open: scope.row.status == 'DONE',
                  }"
                >
                  <span
                    style="
                      width: 80%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: inline-block;
                    "
                  >
                    {{ status_map[$t("tara.tara_lang")][scope.row.status] }}
                  </span>
                  <i style="font-weight: 800" class="el-icon-arrow-down"></i>
                </el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="TO_DO">{{
                  $t("tara.t8")
                }}</el-dropdown-item>
                <el-dropdown-item command="IN_PROGRESS">{{
                  $t("tara.t9")
                }}</el-dropdown-item>
                <el-dropdown-item command="DONE">{{
                  $t("tara.t10")
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>

        <el-table-column
          prop="createdBy"
          :label="$t('tara.t11')"
          width="150"
          show-overflow-tooltip
          column-key="createdBy"
          :filters="get_filter_person(receivingData)"
        >
          <template slot-scope="scope">{{
            all_users[scope.row.createdBy]
              ? all_users[scope.row.createdBy].nickname
              : scope.row.createdBy
          }}</template>
        </el-table-column>
        <el-table-column
          prop="nodeKeyList"
          :label="$t('tara.t12')"
          :min-width="200"
        >
          <template slot-scope="scope">
            <div
              style="max-height: 100px; overflow-y: auto; overflow-x: hidden"
            >
              <span
                v-for="item in scope.row.nodeKeyList"
                :key="item"
                style="color: #ffc302; margin-right: 5px; cursor: pointer"
                @click="goto_detail(item)"
              >
                {{ item }}
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px"
        :current-page="receivingPageInfo.currentPage + 1"
        background
        :page-sizes="[10, 20, 50]"
        :page-size="receivingPageInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="receivingPageInfo.total"
        @current-change="changeReceivingPage"
      >
      </el-pagination>
    </div>
    <!-- 我指派的任务 -->
    <div v-show="(mode === 'sending') & !is_show_chart">
      <el-table
        ref="tara_filterTable2"
        height="80vh"
        :data="sendingData"
        style="width: 100%; background: white"
        :header-cell-style="{
          color: '#383838',
        }"
        :cell-style="changeCellStyle"
        @filter-change="filterChange"
        @row-click="click_row"
      >
        <el-table-column
          type="index"
          width="200"
          label="Key"
          column-key="nodeKey"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span style="color: #ffc302">{{ scope.row.nodeKey }}</span>
          </template>
        </el-table-column>
        <el-table-column
          type="index"
          width="350"
          :label="$t('tara.t1')"
          column-key="topic"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.topic }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('tara.t2')"
          show-overflow-tooltip
          column-key="strategy"
          width="200"
          :filters="[
            {
              text: $t('tara.t3'),
              value: 'APPROVE',
            },
            {
              text: $t('tara.t4'),
              value: 'LESS',
            },
            {
              text: $t('tara.t5'),
              value: 'AVOID',
            },
            {
              text: $t('tara.t6'),
              value: 'TARNS',
            },
          ]"
        >
          <template slot-scope="scope"
            ><span>{{
              strategy_map[$t("tara.tara_lang")][scope.row.strategy]
            }}</span></template
          >
        </el-table-column>
        <!-- <el-table-column
          :label="$t('风险处置措施')"
          :min-width="240"
          show-overflow-tooltip
          column-key="measure"
        >
          <template slot-scope="scope">{{ scope.row.measures }}</template>
        </el-table-column> -->

        <el-table-column
          prop="status"
          :label="$t('tara.t7')"
          width="150"
          column-key="status"
          show-overflow-tooltip
          :filters="[
            {
              text: $t('tara.t8'),
              value: 'TO_DO',
            },
            {
              text: $t('tara.t9'),
              value: 'IN_PROGRESS',
            },
            {
              text: $t('tara.t10'),
              value: 'DONE',
            },
          ]"
        >
          <template slot-scope="scope">
            <el-dropdown
              trigger="click"
              @command="handleCommand_status($event, scope.row)"
            >
              <span class="el-dropdown-link">
                <el-button
                  size="mini"
                  :class="{
                    status_todo: scope.row.status == 'TO_DO',
                    status_progress: scope.row.status == 'IN_PROGRESS',
                    status_open: scope.row.status == 'DONE',
                  }"
                >
                  <span
                    style="
                      width: 80%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: inline-block;
                    "
                  >
                    {{ status_map[$t("tara.tara_lang")][scope.row.status] }}
                  </span>
                  <i style="font-weight: 800" class="el-icon-arrow-down"></i>
                </el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="TO_DO">{{
                  $t("tara.t8")
                }}</el-dropdown-item>
                <el-dropdown-item command="IN_PROGRESS">{{
                  $t("tara.t9")
                }}</el-dropdown-item>
                <el-dropdown-item command="DONE">{{
                  $t("tara.t10")
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>

        <el-table-column
          prop="assignee"
          :label="$t('tara.t13')"
          width="150"
          show-overflow-tooltip
          column-key="assignee"
          :filters="get_filter_person_assignee(sendingData)"
        >
          <template slot-scope="scope">{{
            all_users[scope.row.assignee]
              ? all_users[scope.row.assignee].nickname
              : ""
          }}</template>
        </el-table-column>
        <el-table-column prop="nodeKeyList" :label="$t('tara.t12')">
          <template slot-scope="scope">
            <div
              style="max-height: 100px; overflow-y: auto; overflow-x: hidden"
            >
              <span
                v-for="item in scope.row.nodeKeyList"
                :key="item"
                style="color: #ffc302; margin-right: 5px; cursor: pointer"
                @click="goto_detail(item)"
              >
                {{ item }}
              </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="title" width="75" :label="$t('DFMEA.More')">
          <template slot-scope="scope">
            <el-dropdown>
              <span
                class="el-dropdown-link"
                style="color: #84aace; display: flex"
              >
                <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                &nbsp;&nbsp;
                <i
                  class="iconfont icon-a-216gengduo-shuxiang"
                  style="color: rgb(48, 100, 143)"
                />
                &nbsp;&nbsp;
              </span>
              <el-dropdown-menu slot="dropdown">
                <div>
                  <div class="operate_button" @click="sendAgain(scope.row)">
                    <i style="margin-right: 20px">
                      <i class="iconfont icon-fasong" />
                    </i>
                    <span>{{ $t("DFMEA.Reminder") }}</span>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px"
        :current-page="sendingPageInfo.currentPage + 1"
        background
        :page-sizes="[10, 20, 50]"
        :page-size="sendingPageInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="sendingPageInfo.total"
        @current-change="changeSendingPage"
      >
      </el-pagination>
    </div>
    <!-- 全部优化任务 -->
    <div v-show="(mode === 'reviewing') & !is_show_chart">
      <el-table
        ref="tara_filterTable3"
        height="80vh"
        :data="reviewingData"
        style="width: 100%; background: white"
        :header-cell-style="{
          color: '#383838',
        }"
        :cell-style="changeCellStyle"
        @filter-change="filterChange"
        @row-click="click_row"
      >
        <el-table-column
          type="index"
          width="200"
          :label="$t('Key')"
          column-key="nodeKey"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span style="color: #ffc302">{{ scope.row.nodeKey }}</span>
          </template>
        </el-table-column>
        <el-table-column
          type="index"
          width="400"
          :label="$t('tara.t1')"
          column-key="topic"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.topic }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('tara.t2')"
          column-key="strategy"
          width="200"
          :filters="[
            {
              text: $t('tara.t3'),
              value: 'APPROVE',
            },
            {
              text: $t('tara.t4'),
              value: 'LESS',
            },
            {
              text: $t('tara.t5'),
              value: 'AVOID',
            },
            {
              text: $t('tara.t6'),
              value: 'TARNS',
            },
          ]"
        >
          <template slot-scope="scope">
            <span>{{
              strategy_map[$t("tara.tara_lang")][scope.row.strategy]
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :label="$t('风险处置措施')"
          :min-width="240"
          show-overflow-tooltip
          column-key="measure"
        >
          <template slot-scope="scope">{{ scope.row.measures }}</template>
        </el-table-column> -->
        <el-table-column
          prop="status"
          :label="$t('tara.t7')"
          width="150"
          show-overflow-tooltip
          column-key="status"
          :filters="[
            {
              text: $t('tara.t8'),
              value: 'TO_DO',
            },
            {
              text: $t('tara.t9'),
              value: 'IN_PROGRESS',
            },
            {
              text: $t('tara.t10'),
              value: 'DONE',
            },
          ]"
        >
          <template slot-scope="scope">
            <el-dropdown
              trigger="click"
              @command="handleCommand_status($event, scope.row)"
            >
              <span class="el-dropdown-link">
                <el-button
                  size="mini"
                  :class="{
                    status_todo: scope.row.status == 'TO_DO',
                    status_progress: scope.row.status == 'IN_PROGRESS',
                    status_open: scope.row.status == 'DONE',
                  }"
                >
                  <span
                    style="
                      width: 80%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: inline-block;
                    "
                  >
                    {{ status_map[$t("tara.tara_lang")][scope.row.status] }}
                  </span>
                  <i style="font-weight: 800" class="el-icon-arrow-down"></i>
                </el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="TO_DO">{{
                  $t("tara.t8")
                }}</el-dropdown-item>
                <el-dropdown-item command="IN_PROGRESS">{{
                  $t("tara.t9")
                }}</el-dropdown-item>
                <el-dropdown-item command="DONE">{{
                  $t("tara.t10")
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>

        <el-table-column
          prop="assignee"
          :label="$t('tara.t13')"
          width="150"
          show-overflow-tooltip
          column-key="assignee"
          :filters="get_filter_person_assignee(reviewingData)"
        >
          <template slot-scope="scope">{{
            all_users[scope.row.assignee]
              ? all_users[scope.row.assignee].nickname
              : ""
          }}</template>
        </el-table-column>
        <el-table-column
          prop="nodeKeyList"
          :label="$t('tara.t12')"
          :min-width="200"
        >
          <template slot-scope="scope">
            <div
              style="max-height: 100px; overflow-y: auto; overflow-x: hidden"
            >
              <span
                v-for="item in scope.row.nodeKeyList"
                :key="item"
                style="color: #ffc302; margin-right: 5px; cursor: pointer"
                @click="goto_detail(item)"
              >
                {{ item }}
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        style="margin-top: 10px"
        :current-page="reviewingPageInfo.currentPage + 1"
        background
        :page-sizes="[10, 20, 50]"
        :page-size="reviewingPageInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="reviewingPageInfo.total"
        @current-change="changeReviewingPage"
      >
      </el-pagination>
    </div>
    <!-- 图表 -->
    <div
      v-show="is_show_chart"
      id="echarts_main_wrap"
      ref="echarts_main_wrap"
      class="echarts_main_wrap"
    >
      <div class="pie1">
        <div id="pie1" ref="pie1"></div>
      </div>
      <div class="pie1">
        <div id="pie2" ref="pie2"></div>
      </div>
      <div class="pie2">
        <div id="pie3" ref="pie3"></div>
      </div>
      <div class="pie2">
        <div id="pie4" ref="pie4"></div>
      </div>
    </div>
    <!-- :cell-style="changeCellStyle_up" -->

    <el-dialog
      :title="$t('tara.set')"
      :visible.sync="add_taraVisible"
      :close-on-click-modal="false"
      width="20%"
    >
      <el-table
        :data="add_taratableData"
        style="width: 100%; margin-bottom: 40px"
        :show-header="false"
        cell-class-name="tara_dialog_table_class_cell"
        class="tara_dialog_table_class"
      >
        <el-table-column style="padding: 0">
          <template slot-scope="scope">
            {{ scope.row.label }}
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.des"
              placement="top-start"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="right" width="75">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="
                show_antv_dialog = true;
                antv_value = scope.row.type;
              "
              >{{ $t("tara.edit") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      v-if="show_antv_dialog"
      id="tara_dialog_zyz"
      width="80%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="show_antv_dialog"
      top="10vh"
      append-to-body
    >
      <antDialog :antv_type="antv_value"></antDialog>
    </el-dialog>
  </div>
</template>

<script>
import vmson from "@/utils/vmson";
import {
  get_allRiskAnalysisPage,
  get_pic,
  chenge_node_tara,
  notice,
} from "@/network/tara/index";
import { get_projectId } from "@/network/hara/index";
import { get_node_use_node_key } from "@/network/node/index";
import { mapGetters } from "vuex";
import { get_file } from "@/network/baseline/index.js";
import antDialog from "./antDialog";
export default {
  name: "TARA",
  components: { antDialog },
  data() {
    return {
      antv_value: "",
      add_taratableData: [
        {
          label: this.$t("tara.t36"),
          des: this.$t("tara.tip1"),
          type: "ATTACK_PATH",
        },
        {
          label: this.$t("tara.t45"),
          des: this.$t("tara.tip2"),

          type: "RISK_VALUE",
        },
      ],
      add_taraVisible: false,
      is_man: false,
      showWorkflow: false,
      show_antv_dialog: false,
      strategy_map: {
        ch: {
          APPROVE: "接受风险",
          LESS: "降低风险",
          AVOID: "规避风险",
          TARNS: "转移风险",
        },
        en: {
          APPROVE: "Acceptance risk",
          LESS: "Reduce risk",
          AVOID: "Risk Averse",
          TARNS: "Transfer risk",
        },
      },
      status_map: {
        ch: {
          TO_DO: "未开始",
          IN_PROGRESS: "进行中",
          DONE: "已完成",
        },
        en: {
          TO_DO: "TO DO",
          IN_PROGRESS: "IN PROGRESS",
          DONE: "DONE",
        },
      },
      topClass: 0,
      receivingData: [],
      sendingData: [],
      reviewingData: [],
      is_show_chart: false,
      mode: "receiving",
      receivingPageInfo: {
        // 我发出的邀请 分页
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      sendingPageInfo: {
        // 我发出的邀请 分页
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      reviewingPageInfo: {
        // 我发出的邀请 分页
        currentPage: 0,
        pageSize: 20,
        total: 0,
      },
      resize_handler: null,
      isPay: false,
      isShowPayDialog: false,
      showStatusOption: false,
      all_filter: {},
    };
  },
  computed: {
    ...mapGetters({ all_users: "user_list", userAccountId: "userAccountId" }),
  },
  watch: {
    mode() {
      this.all_filter = {};
      this.$refs.tara_filterTable1.clearFilter();
      this.$refs.tara_filterTable2.clearFilter();
      this.$refs.tara_filterTable3.clearFilter();
    },
  },
  created() {},
  mounted() {
    this.is_man =
      JSON.parse(localStorage.getItem("store")).user.roles[0].name ==
      "SYSTEM_MANAGER";
    this.getReceivingData();
  },
  beforeDestroy() {
    /* 页面组件销毁的时候，别忘了移除绑定的监听resize事件，否则的话，多渲染几次
      容易导致内存泄漏和额外CPU或GPU占用哦*/
    window.removeEventListener("resize", this.resize_handler0);
    window.removeEventListener("resize", this.resize_handler1);
    window.removeEventListener("resize", this.resize_handler2);
    window.removeEventListener("resize", this.resize_handler3);
  },
  methods: {
    goto_detail(nodeKey) {
      let k = nodeKey.split("-");
      get_projectId(k[0]).then((res) => {
        const { href } = this.$router.resolve({
          path: `/${res.projectId}/nodes/key/${nodeKey}`,
        });
        window.open(href, "_blank");
      });
    },
    click_row(a, b, c) {
      if (
        b.columnKey == "strategy" ||
        b.columnKey == "topic" ||
        b.columnKey == "nodeKey"
      ) {
        this.rowClicked(a);
      }
    },
    get_filter_person_assignee(data) {
      let s = new Set();
      for (let i of data) {
        if (i.assignee) {
          s.add(i.assignee);
        }
      }
      let l = [];
      for (let i of [...s]) {
        if (i.length > 0) {
          l.push({
            text: this.all_users[i]
              ? this.all_users[i].nickname
              : this.$t("tara.null"),
            value: i,
          });
        }
      }
      // l.push({
      //   text: this.$t("tara.null"),
      //   value: "",
      // });
      return l;
    },
    get_filter_person(data) {
      let s = new Set();
      for (let i of data) {
        s.add(i.createdBy);
      }
      let l = [];
      for (let i of [...s]) {
        l.push({
          text: this.all_users[i] ? this.all_users[i].nickname : i,
          value: i,
        });
      }
      return l;
    },
    filterChange(filterObj) {
      let k = Object.keys(filterObj)[0];
      this.all_filter[k] = filterObj[k];
      let params = {
        page: this.receivingPageInfo.currentPage,
        size: this.receivingPageInfo.pageSize,
      };
      if (this.mode === "receiving") {
        params["assignee"] = this.userAccountId;
      }
      if (this.mode === "sending") {
        params["createdBy"] = this.userAccountId;
      }
      k = Object.keys(this.all_filter);
      for (let i of k) {
        let t = "";
        for (let j of this.all_filter[i]) {
          t += j;
          t += ",";
        }
        params[i] = t.slice(0, t.length - 1);
      }

      get_allRiskAnalysisPage(this.get_pid(), params).then((res) => {
        if (this.mode === "receiving") {
          this.receivingData = res.content;
          this.receivingPageInfo.total = res.totalElements;
        }
        if (this.mode === "sending") {
          this.sendingData = res.content;
          this.sendingPageInfo.total = res.totalElements;
        }

        if (this.mode === "reviewing") {
          this.reviewingData = res.content;
          this.reviewingPageInfo.total = res.totalElements;
        }
      });
    },
    handleCommand_status(a, d) {
      let data = {
        assignee: d.assignee,
        measures: d.measures,
        nodeKeyList: d.nodeKeyList,
        objectives: d.objectives,
        requirements: d.requirements,
        riskNum: d.riskNum,
        statement: d.statement,
        status: a,
        strategy: d.strategy,
      };
      chenge_node_tara(
        { projectId: this.get_pid(), nodeKey: d.nodeKey, id: d.detailId },
        data
      ).then(() => {
        vmson.$emit("change_tara_status");
      });
      d.status = a;
    },
    changeCellStyle_up() {
      return "padding:0";
    },
    changeCellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 2 || columnIndex == 1 || columnIndex == 0) {
        return "cursor:pointer;color:#636363; font-weight: 700";
      }
      return "color:#636363; font-weight: 700";
    },
    // 再次发送提醒
    sendAgain(row) {
      notice(row.projectId, row.nodeKey, row.detailId).then((res) => {
        this.$message({
          type: "success",
          message: this.$t("DFMEA.ms3"),
        });
      });
    },
    rowClicked(row) {
      get_node_use_node_key(row.projectId, row.nodeKey).then((res) => {
        get_file(row.projectId, res.fileId).then((ress) => {
          const { href } = this.$router.resolve({
            path: `/${row.projectId}/myMind/${ress.key}`,
            query: {
              node_key: row.nodeKey,
              tara: true,
            },
          });
          window.open(href, "_blank");
        });
      });
    },

    // 获取我收到的任务数据
    getReceivingData() {
      // sort=id%2Cdesc&strategy=xxx&status=xxx&assignee=xxx&createdBy=xxx
      const params = {
        page: this.receivingPageInfo.currentPage,
        size: this.receivingPageInfo.pageSize,
        assignee: this.userAccountId,
      };
      get_allRiskAnalysisPage(this.get_pid(), params).then((res) => {
        this.receivingData = res.content;
        this.receivingPageInfo.total = res.totalElements;
      });
    },
    // 获取我指派的任务数据
    getSendingData() {
      const params = {
        page: this.sendingPageInfo.currentPage,
        size: this.sendingPageInfo.pageSize,
        createdBy: this.userAccountId,
      };
      get_allRiskAnalysisPage(this.get_pid(), params).then((res) => {
        this.sendingData = res.content;
        this.sendingPageInfo.total = res.totalElements;
      });
    },
    // 获取全部优化措施数据
    getReviewingData() {
      const params = {
        page: this.reviewingPageInfo.currentPage,
        size: this.reviewingPageInfo.pageSize,
      };
      get_allRiskAnalysisPage(this.get_pid(), params).then((res) => {
        this.reviewingData = res.content;
        this.reviewingPageInfo.total = res.totalElements;
      });
    },
    getNickName(id) {
      const all_user_list = Object.values(this.all_users);
      all_user_list.forEach((item) => {
        if (item.accountId === id) {
          return item.nickname;
        }
      });
      return false;
    },
    // 点击切换评审页面内容 我收到的邀请 我发出的邀请 全部评审请求
    changeMode(mode) {
      this.mode = mode;
      this.topClass = mode == "receiving" ? 0 : mode == "sending" ? 1 : 2;
      this.is_show_chart = false;
      switch (mode) {
        case "receiving":
          this.getReceivingData();
          break;
        case "sending":
          this.getSendingData();
          break;
        case "reviewing":
          this.getReviewingData();
          break;
      }
    },
    // 控制数据试图显隐
    show_chart() {
      this.mode = "svgimg";
      this.topClass = 3;
      // 处在看板页面
      if (!this.is_show_chart) {
        this.$nextTick(() => {
          // 生成一个Promise对象的数组
          const promises = ["STRATEGY", "ASSIGNEE", "STATUS", "CREATED_BY"].map(
            (dimension) => {
              return get_pic(this.get_pid(), dimension);
            }
          );

          Promise.all(promises).then((posts) => {
            this.init_charts(posts);
          });

          // get_pic(this.get_pid(), "STRATEGY").then((res) => {
          //   this.init_charts(res.statisticsDetail);
          // });
        });
      }
      this.is_show_chart = true;
    },
    changeReceivingPage(pageNumber) {
      this.receivingPageInfo.currentPage = pageNumber - 1;
      this.getReceivingData();
    },
    changeSendingPage(pageNumber) {
      this.sendingPageInfo.currentPage = pageNumber - 1;
      this.getSendingData();
    },
    changeReviewingPage(pageNumber) {
      this.reviewingPageInfo.currentPage = pageNumber - 1;
      this.getReviewingData();
    },
    debounce(fn, delay) {
      let timer = null;
      return function () {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        timer = setTimeout(() => {
          fn.resize();
        }, delay);
      };
    },
    init_charts(optionsList) {
      let myChart_pie = [];

      myChart_pie[0] = this.$echarts.init(document.getElementById("pie1"));
      myChart_pie[1] = this.$echarts.init(document.getElementById("pie2"));
      myChart_pie[2] = this.$echarts.init(document.getElementById("pie3"));
      myChart_pie[3] = this.$echarts.init(document.getElementById("pie4"));

      this.resize_handler0 = this.debounce(myChart_pie[0], 500);
      this.resize_handler1 = this.debounce(myChart_pie[1], 500);
      this.resize_handler2 = this.debounce(myChart_pie[2], 500);
      this.resize_handler3 = this.debounce(myChart_pie[3], 500);
      window.addEventListener("resize", this.resize_handler0);
      window.addEventListener("resize", this.resize_handler1);
      window.addEventListener("resize", this.resize_handler2);
      window.addEventListener("resize", this.resize_handler3);
      const pieChartData = [];
      // const map = {
      //   CONFIRM_NOT_YET: this.$t("DFMEA.NotYetDetermined"),
      //   DECIDE_NOT_YET: this.$t("DFMEA.NotYetDecided"),
      //   IMPLEMENT_NOT_YET: this.$t("DFMEA.NotYetExecuted"),
      //   COMPLETED: this.$t("DFMEA.Completed"),
      //   DO_NOTHING: this.$t("DFMEA.NoExecute"),
      // };
      for (let data_ in optionsList) {
        let options_data = [];
        for (let i of Object.keys(optionsList[data_].statisticsDetail)) {
          let obj = {};
          if (data_ == 0) {
            obj = {
              name: this.strategy_map[this.$t("tara.tara_lang")][i]
                ? this.strategy_map[this.$t("tara.tara_lang")][i]
                : this.$t("tara.null"),
              value: optionsList[data_].statisticsDetail[i],
            };
          }
          if (data_ == 1) {
            obj = {
              name: this.all_users[i]
                ? this.all_users[i].nickname
                : this.$t("tara.null"),
              value: optionsList[data_].statisticsDetail[i],
            };
          }
          if (data_ == 2) {
            obj = {
              name: this.status_map[this.$t("tara.tara_lang")][i]
                ? this.status_map[this.$t("tara.tara_lang")][i]
                : this.$t("tara.null"),
              value: optionsList[data_].statisticsDetail[i],
            };
          }
          if (data_ == 3) {
            obj = {
              name: this.all_users[i]
                ? this.all_users[i].nickname
                : this.$t("tara.null"),
              value: optionsList[data_].statisticsDetail[i],
            };
          }
          options_data.push(obj);
        }

        pieChartData.push(options_data);
      }

      // optionsList.forEach((item) => {
      //   const obj = {
      //     name: map[item.status],
      //     value: item.count,
      //   };
      //   pieChartData.push(obj);
      // });

      let option0 = {
        tooltip: {
          formatter: "{b} <br/> <b>{c}</b> <br/><b>{d}%</b>",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          align: "left",
          right: "5%",
          top: "10%",
          width: "20%",
          height: "80%",
          textStyle: {
            width: 150,
            overflow: "breakAll",
            rich: {
              title: {
                align: "left",
              },
              value: {
                align: "right",
              },
            },
          },
          tooltip: {
            show: true,
          },
        },
        series: [
          {
            left: -95,
            type: "pie",
            minAngle: 5,
            data: pieChartData[0],
            radius: [0, "50%"],
            label: {
              show: true,
              position: "outside",
              formatter: "{b}: {c}\n{d}%",
              width: 80,
              textStyle: {
                fontSize: 12,
                fontWeight: "normal",
              },
            },
            labelLine: {
              show: true,
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
          },
        ],
        title: {
          text: this.$t("tara.t2"),
          textStyle: {
            fontFamily: "Source Han Sans CN",
            color: "rgba(56, 56, 56, 1)",
          },
        },
      };
      let option1 = {
        tooltip: {
          formatter: "{b} <br/> <b>{c}</b> <br/><b>{d}%</b>",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          align: "left",
          right: "5%",
          top: "10%",
          width: "20%",
          height: "80%",
          textStyle: {
            width: 150,
            overflow: "breakAll",
            rich: {
              title: {
                align: "left",
              },
              value: {
                align: "right",
              },
            },
          },
          tooltip: {
            show: true,
          },
        },
        series: [
          {
            left: -95,
            type: "pie",
            minAngle: 5,
            data: pieChartData[1],
            radius: [0, "50%"],
            label: {
              show: true,
              position: "outside",
              formatter: "{b}: {c}\n{d}%",
              width: 80,
              textStyle: {
                fontSize: 12,
                fontWeight: "normal",
              },
            },
            labelLine: {
              show: true,
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
          },
        ],
        title: {
          text: this.$t("tara.t13"),
          textStyle: {
            fontFamily: "Source Han Sans CN",
            color: "rgba(56, 56, 56, 1)",
          },
        },
      };

      let option2 = {
        tooltip: {
          formatter: "{b} <br/> <b>{c}</b> <br/><b>{d}%</b>",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          align: "left",
          right: "5%",
          top: "10%",
          width: "20%",
          height: "80%",
          textStyle: {
            width: 150,
            overflow: "breakAll",
            rich: {
              title: {
                align: "left",
              },
              value: {
                align: "right",
              },
            },
          },
          tooltip: {
            show: true,
          },
        },
        series: [
          {
            left: -95,
            type: "pie",
            minAngle: 5,
            data: pieChartData[2],
            radius: [0, "50%"],
            label: {
              show: true,
              position: "outside",
              formatter: "{b}: {c}\n{d}%",
              width: 80,
              textStyle: {
                fontSize: 12,
                fontWeight: "normal",
              },
            },
            labelLine: {
              show: true,
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
          },
        ],
        title: {
          text: this.$t("DFMEA.Status"),
          textStyle: {
            fontFamily: "Source Han Sans CN",
            color: "rgba(56, 56, 56, 1)",
          },
        },
      };
      let option3 = {
        tooltip: {
          formatter: "{b} <br/> <b>{c}</b> <br/><b>{d}%</b>",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          align: "left",
          right: "5%",
          top: "10%",
          width: "20%",
          height: "80%",
          textStyle: {
            width: 150,
            overflow: "breakAll",
            rich: {
              title: {
                align: "left",
              },
              value: {
                align: "right",
              },
            },
          },
          tooltip: {
            show: true,
          },
        },
        series: [
          {
            left: -95,
            type: "pie",
            minAngle: 5,
            data: pieChartData[3],
            radius: [0, "50%"],
            label: {
              show: true,
              position: "outside",
              formatter: "{b}: {c}\n{d}%",
              width: 80,
              textStyle: {
                fontSize: 12,
                fontWeight: "normal",
              },
            },
            labelLine: {
              show: true,
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
          },
        ],
        title: {
          text: this.$t("tara.t11"),
          textStyle: {
            fontFamily: "Source Han Sans CN",
            color: "rgba(56, 56, 56, 1)",
          },
        },
      };
      myChart_pie[0].setOption(option0);
      myChart_pie[1].setOption(option1);
      myChart_pie[2].setOption(option2);
      myChart_pie[3].setOption(option3);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-table tbody tr:hover > td {
  background-color: transparent !important;
}
::v-deep .tara_dialog_table_class_cell {
  border-bottom: none !important;
  .cell {
    padding: 0 !important;
  }
}
.tara_dialog_table_class::before {
  width: 0;
}
.tara_dialog_table_class {
  font-size: 16px;
}
.tara_dialog_table_class td,
.tara_dialog_table_class th.is-leaf {
  border: none;
}
.hara_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu {
  display: flex;
  gap: 40px;
  margin-bottom: 25px;
  .bottom-border {
    border-bottom: 2px solid rgba(48, 100, 143, 1);
  }
  .item {
    font-size: 18px;
    color: #777;
    cursor: pointer;
  }
  .svgClass {
    position: relative;
    right: 0.082rem;
    top: 0.04rem;
    color: rgba(255, 255, 255, 0);
    width: 0.1042rem;
    height: 0.1042rem;
    cursor: pointer;
  }
}
.operate_button {
  display: block;
  margin: 15px 20px;
  text-align: left;
  cursor: pointer;
  color: #656667;
}
.operate_button:hover {
  color: #46b4f4;
}
.echarts_main_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 81.5vh;
  padding-bottom: 20px;
  overflow: hidden auto;
  margin-top: 55px;
  & > div {
    width: 49%;
    margin-top: 50px;
    height: 450px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 12px 0 0 20px;
    & > div {
      // width: 740px;
      width: 100%;
      height: 430px;
    }
  }
  .pie1 {
    overflow: hidden;
    width: 48%;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    margin-top: 0px;
    padding: 15px;
  }
  .pie2 {
    overflow: hidden;
    width: 48%;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    margin-top: 30px;
    padding: 15px;
  }
}
.pingshen-status-tag {
  padding: 4px 16px;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #fff;
  border-width: 1px;
}
.status_not_confirm {
  border: 1px solid rgba(109, 127, 160, 1);
  color: rgba(109, 127, 160, 1);
}
.status_not_decide {
  color: rgba(245, 192, 34, 1);
  border: 1px solid rgba(245, 192, 34, 1);
}
.status_nothing {
  color: rgba(255, 122, 91, 1);
  border: 1px solid rgba(255, 122, 91, 1);
}
.status_not_implement {
  color: rgba(255, 147, 38, 1);
  border: 1px solid rgba(255, 147, 38, 1);
}
.status_completed {
  color: rgba(1, 186, 173, 1);
  border: 1px solid rgba(1, 186, 173, 1);
}
.el-dropdown {
  width: 100%;
}
.el-dropdown-link {
  .el-button {
    width: 100%;
    height: 32px;
    // line-height: 27px;
    // background: rgba(48, 100, 143, 1);
    font-size: 12px;
    font-weight: 800;
    // color: #fff;
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .status_todo {
    background-color: rgba(166, 166, 166, 1);
    border: 1px solid rgba(166, 166, 166, 1);
    color: white;
  }
  .status_open {
    color: white;
    background-color: rgba(67, 207, 124, 1);
    border: 1px solid rgba(67, 207, 124, 1);
  }
  .status_progress {
    color: white;
    background-color: rgba(42, 130, 228, 1);
    border: 1px solid rgba(42, 130, 228, 1);
  }
  .workflow_select {
    z-index: 12;
    width: 120px;
    background: #fff;
    position: absolute;
    padding: 10px 10px;
    margin-top: 6px;
    border-radius: 6px;
    right: 0;
    text-align: left;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border: 1px solid #e4e7ed;
    .workflow_item,
    .workflow_none {
      height: 32px;
      align-items: center;
      width: 100%;
      span {
        width: 100%;
        height: 100%;
        line-height: 32px;
        cursor: pointer;
      }
    }
    .workflow_item:hover {
      background: rgba(9, 30, 66, 0.06);
    }
  }
}
</style>
