<template>
  <div class="edit_copy_wrap">
    <el-form
      ref="new_info"
      :model="new_info"
      :rules="rules"
      label-position="left"
      label-width="140px"
    >
      <el-form-item prop="name">
        <div slot="label">
      {{ $t("addEditCopy.name") }} 
        </div>
        <el-input
          v-model="new_info.name"
          style="width: 425px"
          :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-for="(item, index) in new_info.customFields"
        :key="index + 'cus'"
        :label="item.name"
      >
        <el-input
          v-if="item.fieldType == 'TEXT' || item.fieldType == 'LINK'"
          v-model="item.value"
          style="width: 425px"
        >
        </el-input>

        <el-input
          v-if="item.fieldType == 'NUMBER'"
          v-model.number="item.value"
          type="number"
          :placeholder="$t('addEditCopy.num')"
          style="width: 425px"
        >
        </el-input>

        <el-input
          v-if="item.fieldType == 'TEXTAREA'"
          v-model="item.value"
          type="textarea"
          autosize
          :placeholder="$t('placeholder.input')"
          style="width: 425px"
        >
        </el-input>

        <el-date-picker
          v-if="item.fieldType == 'DATE'"
          v-model="item.value"
          type="date"
          :placeholder="$t('placeholder.date')"
          style="width: 425px"
        >
        </el-date-picker>

        <el-select
          v-if="item.fieldType == 'SELECT'"
          v-model="item.value"
          style="width: 425px"
          filterable
          clearable
        >
          <el-option
            v-for="opt in item.extraData"
            :key="opt.value"
            style="width: 425px"
            :label="opt.label"
            :value="opt.value"
          >
          </el-option>
        </el-select>

        <el-select
          v-if="item.fieldType == 'SELECT_MULTI' || item.fieldType == 'VERSION'"
          v-model="item.value"
          :placeholder="$t('placeholder.dropdown')"
          style="width: 425px"
          multiple
          filterable
        >
          <el-option
            v-for="opt in item.extraData"
            :key="opt.value"
            :label="opt.label"
            :value="opt.value"
          >
          </el-option>
        </el-select>

        <!-- 单用户下拉框 -->
        <el-select
          v-if="item.fieldType == 'SINGLE_USER_SELECT'"
          v-model="item.value"
          popper-class="user_select"
          :loading-text="$t('loading.loading2')"
          style="width: 425px"
          filterable
          clearable
        >
          <el-option
            v-for="opt in user_list"
            v-show="!opt.deleted"
            :key="opt.userAccountId"
            :label="opt.label"
            :value="opt.userAccountId"
          >
            <personal-avatar
              class="avatar"
              :avatar="opt.avatar"
              :colorid="opt.userAccountId"
              :nickname="opt.label"
              :size="20"
            >
            </personal-avatar>
            <span class="select_item">{{ opt.label }}</span>
          </el-option>
        </el-select>

        <!-- 多用户下拉框 -->
        <el-select
          v-if="item.fieldType == 'MULTI_USER_SELECT'"
          v-model="item.value"
          popper-class="user_select"
          multiple
          filterable
          :loading-text="$t('loading.loading2')"
          style="width: 425px"
          clearable
        >
          <el-option
            v-for="opt in user_list"
            v-show="!opt.deleted"
            :key="opt.userAccountId"
            :label="opt.label"
            :value="opt.userAccountId"
          >
            <personal-avatar
              class="avatar"
              :avatar="opt.avatar"
              :colorid="opt.userAccountId"
              :nickname="opt.label"
              :size="20"
            >
            </personal-avatar>
            <span class="select_item">{{ opt.label }}</span>
          </el-option>
        </el-select>

        <!-- 可创建条目 -->
        <el-select
          v-if="item.fieldType == 'LABEL'"
          v-model="item.value"
          :placeholder="$t('placeholder.dropdown')"
          :loading-text="$t('loading.loading2')"
          style="width: 425px"
          multiple
          allow-create
          filterable
        >
          <el-option
            v-for="opt in project_labels"
            :key="opt.value"
            :label="opt.label"
            :value="opt.value"
          >
          </el-option>
        </el-select>

        <div
          v-if="item.fieldType == 'DRAWIO'"
          v-limits-of-authority="'NODE_EDIT'"
        >
          <DrawioCopy
            :node-key="$route.params.nodeKey || nodeInfo.key"
            :custom="item.fieldReferenceId"
            :value="item.value"
            :is-copy="true"
            class="draw"
            @valueChange="valueChange($event)"
          ></DrawioCopy>
        </div>
      </el-form-item>
      <el-form-item
        v-for="(item, index) in new_info.systemFields"
        :key="index + 'sys'"
        :label="sys_label(item.fieldName)"
      >
        <el-input
          v-if="item.fieldName === 'topic'"
          v-model="item.value"
          style="width: 425px"
        ></el-input>
        <el-input
          v-if="item.fieldName === 'storyPoint'"
          type="number"
          v-model="item.value"
          style="width: 425px"
        ></el-input>
        <node-description-row
          v-if="item.fieldName === 'description'"
          :default="false"
          :change_requests="true"
          style="padding: 0"
          :show-collapse="false"
          :project-id="projectId"
          :node-key="nodeKey"
          :description="item.value"
          :attachments="attachments_list"
          @sendvalue="sendvalue"
          @sendfile="sendfile"
        ></node-description-row>
        <div
          v-if="item.fieldName === 'testDetails'"
          style="display: flex; margin-left: -140px"
        >
          <div style="width: 140px; margin-top: 10px">
            {{ $t("nodeDetail.testStep.title") }}
          </div>
          <node-test-plan
            style="width: 80%; margin-left: -30px"
            :node-key="nodeKey"
            :isEditCopy="true"
            :datalist="datalist"
          >
          </node-test-plan>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import NodeDescriptionRow from "@/components/mindmap/node_detail/NodeDescriptionRow.vue";
import { get_allProjectMember } from "@/network/node/index.js";
import { edit_copies_detail } from "@/network/merge/index";
import PersonalAvatar from "@/components/personal";
import DrawioCopy from "@/components/mindmap/node_detail/DrawioCopy.vue";
import vmson from "@/utils/vmson";
import NodeTestPlan from "@/components/mindmap/node_detail/NodeTestPlan.vue";

export default {
  name: "EditCopy",
  components: {
    NodeDescriptionRow,
    PersonalAvatar,
    DrawioCopy,
    NodeTestPlan,
  },
  props: {
    editForm: {
      type: Object,
      default: () => {},
    },
    projectId: {
      type: String,
      default: "",
    },
    nodeKey: {
      type: String,
      default: "",
    },
    checkedCopyid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      new_info: {},
      user_list: [],
      attachments_list: [],
      // 传递给测试步骤组件的数据
      datalist: [],
      rules: {
        name: [
          {
            required: true,
            message: this.$t("addEditCopy.name1"),
            trigger: "blur",
          },
        ],
        topic: [
          {
            required: true,
            message: this.$t("addEditCopy.node"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  mounted() {
    this.get_user_list();
    this.new_info = Object.assign({}, this.editForm);
    this.Testprocedure();
    let this_ = this;
    this.new_info.systemFields.forEach((item) => {
      if (item.fieldName === "attachments") {
        this_.attachments_list = item.value;
      }
    });
    vmson.$on("update_change_requests_attachments_list", (val) => {
      this_.sendfile(val);
    });
  },
  methods: {
    // 将测试步骤放在最后面
    Testprocedure() {
      // 使用 filter 方法提取出包含特定值的对象
      let objectsWithTestDetails = this.new_info.systemFields.filter(
        (obj) => obj.fieldName === "testDetails"
      );
      let objectsWithoutTestDetails = this.new_info.systemFields.filter(
        (obj) => obj.fieldName !== "testDetails"
      );
      this.datalist = objectsWithTestDetails[0]?.value;

      // 合并数组
      this.new_info.systemFields = [
        ...objectsWithoutTestDetails,
        ...objectsWithTestDetails,
      ];
    },
    valueChange(event) {
      this.new_info.customFields.forEach((item) => {
        item.fieldReferenceId === event.fieldReferenceId
          ? (item.value = event.value)
          : null;
      });
    },
    get_user_list() {
      if (this.user_list.length > 0) return;
      get_allProjectMember(this.projectId)
        .then((res) => {
          let my = {};
          let li = [];
          res.forEach((ele) => {
            let obj = {
              label: ele.nickname,
              value: ele.accountId,
              userAccountId: ele.accountId,
              avatar: ele.avatar,
              deleted: ele.deleted,
            };
            ele.accountId == this.$store.getters.userAccountId
              ? (my = obj)
              : li.push(obj);
          });
          this.user_list = [];
          this.user_list.push(my);
          this.user_list.push(...li);
        })
        .catch((err) => console.log(err));
    },
    sys_label(fieldName) {
      const map_object = {
        topic: this.$t("addEditCopy.nodeName"),
        description: this.$t("addEditCopy.desc"),
        storyPoint: this.$t("addEditCopy.storyPoint"),
      };
      return map_object[fieldName];
    },
    validate_form(callback) {
      this.$refs["new_info"].validate((valid) => {
        callback(valid);
      });
    },
    sendvalue(value) {
      this.new_info.systemFields.forEach((item) => {
        if (item.fieldName === "description") {
          item.value = value;
        }
      });
    },
    sendfile(value) {
      this.attachments_list = value;
      let flag1 = true;
      let flag2 = false;
      this.new_info.systemFields.forEach((item) => {
        if (item.fieldName === "attachments") {
          item.value = value;
          flag1 = false;
        }
        if (item.fieldName === "description") {
          flag2 = true;
        }
      });
      if (flag1 && flag2) {
        this.new_info.systemFields.push({
          fieldName: "attachments",
          value: value,
        });
      }
    },
    edit_params(params, callback) {
      edit_copies_detail(params)
        .then((res) => {
          callback(true);
        })
        .catch((res) => {
          callback(false);
        });
    },
    edit_copies_detail(callback) {
      const params = {
        nodeKey: this.nodeKey,
        projectId: this.projectId,
        copyId: this.checkedCopyid,
        data: {
          name: this.new_info.name,
          customFields: this.new_info.customFields,
          systemFields: this.new_info.systemFields,
        },
      };
      let flag = false;
      params.data.systemFields.forEach((item) => {
        if (item.fieldName === "storyPoint") {
          const regex = /^[0-9]\d*$/;
          if (!regex.test(item.value)) {
            flag = true;
            return;
          }
        }
      });
      if (flag) {
        this.$message.error(this.$t("addEditCopy.limit"));
        return;
      }
      this.edit_params(params, callback);
    },
  },
};
</script>

<style lang="scss" scoped>
.draw {
  width: 100%;
}
.user_select {
  .el-select-dropdown__item {
    display: flex;
    align-items: center;
    .avatar {
      display: flex;
    }
    .select_item {
      margin-left: 14px;
    }
  }
}
.edit_copy_wrap {
  padding: 25px;
  ::v-deep {
    .el-form-item__content {
      text-align: left;
    }
    .el-form-item.is-required:not(.is-no-asterisk)
      > .el-form-item__label:before {
      display: none;
    }
  }
}
</style>
